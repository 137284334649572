import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";
import { DELETE_CATEGORY_MODAL } from "../../../_constants/branding";
import { SINGLE_LANG_INPUT_CODE } from "../../../_constants/constants";
import { ModalLayout } from "../../popups/ModalLayout";

export const DeleteCategoryModal = ({ loading, editConfig, deleteModal, relatedStores, onDelete, onCancel }) =>
{
    const trans = useTranslation().t;
    const renderWarningModal = () => (
        <>
            <span style={{ padding: "10px", wordBreak: "break-word", whiteSpace: "pre-line" }} className="p-modal">{editConfig.body}</span><br />
        </>
    );

    const renderStoreListModal = () => (
        <div className="aligner">
            <p className="p-modal">
                {trans("deleteCategoryModal.Are you sure you want to delete this category?")}
                <br />
                {trans("deleteCategoryModal.Other_stores_are_using")}
            </p>
            <div className="relatedStores">
                <h4><span>{trans("deleteCategoryModal.Stores related to this category")}</span> ({relatedStores.length}):</h4>
                <ol>
                    {relatedStores.map((item) => <li key={item[SINGLE_LANG_INPUT_CODE]}>{item[SINGLE_LANG_INPUT_CODE]}</li>)}
                </ol>
            </div>
        </div>

    );

    const renderModal = () =>
    {
        if (deleteModal === DELETE_CATEGORY_MODAL.STORE_LIST || deleteModal === DELETE_CATEGORY_MODAL.SUB_CATEGORY_STORE_LIST)
        {
            return renderStoreListModal();
        }
        else if (deleteModal === DELETE_CATEGORY_MODAL.WARNING)
        {
            return renderWarningModal();
        }
    };

    return (
        <ModalLayout
            open={deleteModal !== DELETE_CATEGORY_MODAL.NONE}
            onClose={onCancel}
            className={`crop-image-modal deleteGroupModal ${(deleteModal !== DELETE_CATEGORY_MODAL.WARNING) ? "deleteCategoryModal" : ""} `}
            heading={editConfig.title}
            actions={(
                <Button style={{ marginTop: "18px" }} disabled={loading} primary color="grey" content={trans("deleteCategoryModal.delete")} onClick={() => onDelete()} />
            )}>
            {renderModal()}
        </ModalLayout>
    );
};
