import React, { useContext, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Image, Popup } from "semantic-ui-react";
import CategorySection from "./categorySection/CategorySection";
import KeywordSection from "./keywordSection/KeywordSection";
import "./mainSection.css";
import { CategoryContext } from "../../../../store/CategoryProvider";
import LanguageSelector from "./languageSelector/LanguageSelector";
import { CATEGORY_EDIT_TYPE } from "../../../../_constants/branding";
import { EditCategoryModal } from "../../categoriesPortal/EditCategoryModal";
import { TABS } from "../ManageCategory";

const MainSection = ({ selectedGroup, lang, setLang, className, tab, setTab }) => 
{
    const trans = useTranslation().t;
    const [hideAddCategory, setHideAddCategory] = useState(true);
    const { categoryFlatMap } = useContext(CategoryContext);
    const newCategoryRef = useRef();
    
    const getCategoryLanguages = () => 
    {
        const availableCatlangs = new Set();
        Object.keys(categoryFlatMap).forEach((catId) => 
        {
            if (categoryFlatMap[catId].name) 
            {
                Object.keys(categoryFlatMap[catId].name).map((catNameLang) => availableCatlangs.add(catNameLang));
            }
        });
        return Array.from(availableCatlangs);
    };

    return (
        <div className={className}>
            <div className="mainSectionHeading">
                <ButtonGroup>
                    <Button className={`manageCategory-tab ${tab === TABS.CATEGORY ? "active" : ""}`} onClick={() => setTab(TABS.CATEGORY)}>{trans("manageCategory.categoryTab")}</Button>
                    <Button className={`manageCategory-tab ${tab === TABS.KEYWORD ? "active" : ""}`} onClick={() => setTab(TABS.KEYWORD)}>{trans("manageCategory.keywordTab")}</Button>
                </ButtonGroup>



                <div className="heading-rightSide">
                    <LanguageSelector
                        activeLang={lang}
                        setActiveLang={setLang}
                        defaultDisplayedLangs={getCategoryLanguages()}
                    />
                    {
                        tab === TABS.CATEGORY && <div className="addNewCategory" ref={newCategoryRef}>
                            <Button primary className="manageCategoryAddCategory" onClick={() => 
                            {
                                setHideAddCategory(false);
                            }}>
                                {trans("manageCategory.addNew")} <img src="/img/icon-chevron-down-white.svg" alt="expand" />
                            </Button>
                            <Popup
                                className={`addNewCategoryPopup ${hideAddCategory && "hide"}`}
                                open={true}
                                context={newCategoryRef}
                                onClose={() => setHideAddCategory(true)}
                                position="bottom right"
                                on="click"
                            >
                                <div onClick={() => setHideAddCategory(true)}>
                                    <EditCategoryModal
                                        trigger={<div className="addNewCategoryItem">{trans("manageCategory.addNewCategory")}</div>}
                                        editConfig={CATEGORY_EDIT_TYPE.CATEGORY}
                                    />
                                    <EditCategoryModal
                                        trigger={<div className="addNewCategoryItem">{trans("manageCategory.addNewSubCategory")}</div>}
                                        editConfig={CATEGORY_EDIT_TYPE.SUB_CATEGORY}
                                    />
                                </div>
                            </Popup>
                        </div>
                    }

                </div>

            </div>
            <div className="sub-text">{trans("manageCategory.subCategory-subHeading")}</div>
            {categoryFlatMap?.[selectedGroup?._id]?.subCategories 
            && <>
            
                {
                    (categoryFlatMap?.[selectedGroup?._id]?.subCategories?.length || 0) > 0 ? <>
                        {
                            tab === TABS.CATEGORY ? <CategorySection group={selectedGroup} lang={lang} /> : <KeywordSection group={selectedGroup} lang={lang} />
                        }
                    </> :  <NoCategoriesMessage trans={trans} groupId={selectedGroup?._id} tab={tab} />
                }
            </>
            }
            
        </div>
    );
};

export default MainSection;


const NoCategoriesMessage = ({ trans, groupId, tab }) => (
    <div className="keyword-section-no-categories">
        <div className="keyword-section-no-data">
            <Image className="noCategoryImage" as="span" src="/img/icon-no-category.svg" alt="No Categories" />
            <p>{trans("manageKeyword.There_are_no_categories_defined_for_the_selected_group")}</p>
            { tab === TABS.KEYWORD && <p>{trans("manageKeyword.Please_add_select_a_category_to_view_manage_keywords_for_the_properties")}</p>}
            <EditCategoryModal
                editConfig={CATEGORY_EDIT_TYPE.CATEGORY}
                groupId={groupId}
                trigger={
                    <button
                        className="keyword-section-add-btn"
                    >
                        {trans("manageKeyword.Add_New")}
                    </button>
                }
            />
           
        </div>
    </div>
);