import React from "react";
import { Button } from "semantic-ui-react";
import ConfirmationModal from "../../../../common/confirmationModal/ConfirmationModal";
import { useImageDeleteContext } from "../contexts/ImageDeleteContext";

function ImageDeleteConfirmationModal() 
{
    const { handleDeleteImage, handleCloseImageDeleteConfirmationModal } = useImageDeleteContext();

    return (
        <ConfirmationModal onClose={handleCloseImageDeleteConfirmationModal} open>
            <ConfirmationModal.Header>
                <ConfirmationModal.HeaderIcon>
                    <img src={"/img/icon-delete-red.svg"} />

                </ConfirmationModal.HeaderIcon>
                Are you sure you want to delete this image?
            </ConfirmationModal.Header>
            <ConfirmationModal.Content>
This action can’t be undone
            </ConfirmationModal.Content>
            <ConfirmationModal.Actions>
                <Button onClick={handleDeleteImage} color="orange">Delete</Button>
            </ConfirmationModal.Actions>
        </ConfirmationModal>
    );
}

export default ImageDeleteConfirmationModal;