import React, { useContext, useMemo, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { Header, Popup } from "semantic-ui-react";
import { DivideBorderLine, DividerLine } from "../../common/DividerLine";
import { DropdownForm } from "../../common/DropdownForm";
import { TextGroup } from "../../common/TextGroup";
import { StylesAndThemesContext } from "../../../store/StylesAndThemesContext";
import
{
    BASE_MAPS,
    BUILDING_LOCATION_TYPE,
    BUILDING_STYLES_ENTITY_CLASSIFICATIONS,
    PROPERTY_LOCATION_TYPE,
    PROPERTY_STYLES_ENTITY_CLASSIFICATIONS
} from "../../../_constants/stylesAndThemesConstants";

import "./StylesAndThemes.css";
import ThemeCreateAndEditModal from "./modals/ThemeCreateAndEditModal";
import { ButtonIcon } from "../../common/ButtonIcon";
import { useCallback } from "react";
import { CheckboxGroup } from "../../common/CheckboxGroup";
import { useTranslation, Trans } from "react-i18next";
import { DEFAULT_LANGUAGE_CODE, MAPSTED_DARK_THEME, MAPSTED_LIGHT_THEME } from "mapsted.maps/utils/map.constants";
import { cropText } from "../../../_utils/utils";
import DeleteThemeConfirmation from "./modals/DeleteThemeConfirmation";

const StylesAndThemesSidebar = () => 
{
    const {
        state: ctxState,
        updateActiveTheme,
        handleEntityStyleSelection,
        handleThemeCreation: onCreateTheme,
        handleThemeEdit: onUpdateTheme,
        handleHideToggleClick,
        handleThemeActiveStatusChange,
    } = useContext(StylesAndThemesContext);

    const [openThemesCreator, setOpenThemesCreator] = useState(false);
    const [openThemesEditor, setOpenThemesEditor] = useState(false);
    const [showDeleteThemeConfirmation, setShowDeleteThemeConfirmation] = useState(false);
    const trans = useTranslation().t;
    const themesExcludedFromDeletion = [MAPSTED_LIGHT_THEME._id, MAPSTED_DARK_THEME._id, ctxState.propertySettings.primaryTheme];
    const selectedThemeInfo = useMemo(() => ctxState.themes?.find((theme) => theme._id === ctxState.activeTheme), [ctxState.activeTheme, ctxState.themes]); 

    const themesOptions = useMemo(() => ctxState.themes.map((theme) => ({
        key: theme._id,
        text: cropText(theme.name[DEFAULT_LANGUAGE_CODE]),
        value: theme._id,
        content: (
            <>
                <span className={theme.active ? "activeTheme": ""}>{cropText(theme.name[DEFAULT_LANGUAGE_CODE])}</span>

                {
                    <>
                        {theme._id === ctxState.propertySettings?.primaryTheme
                            && <div className="themeOptionInfo"><span className="primary">{trans("StylesAndThemesSidebar.Primary")}</span></div>}
                        {(theme._id === MAPSTED_LIGHT_THEME._id || theme._id === MAPSTED_DARK_THEME._id)
                            && <div className="themeOptionInfo"><span>{trans("StylesAndThemesSidebar.Default")}</span></div>}
                    </>
                }

                
                
            </>)
    })), [ctxState.themes, ctxState.propertySettings?.primaryTheme, trans]);

    const entityClassifications = useMemo(() => 
    {
        let entityClassifications = ctxState.isBuildingSelected ? BUILDING_STYLES_ENTITY_CLASSIFICATIONS : PROPERTY_STYLES_ENTITY_CLASSIFICATIONS;

        // display only entity types which are present in the floor/property
        let filteredEntityClassifications = {};

        Object.entries(entityClassifications).forEach(([key, value]) =>
        {
            value = value.filter((entityType) => ctxState.entityTypeMap[`${entityType.entityType}:${entityType.subEntityType}`]);

            if (value.length)
            {
                filteredEntityClassifications[key] = value;
            }
        });

        return filteredEntityClassifications;

    }, [ctxState.isBuildingSelected, ctxState.entityTypeMap]);

    const locationType = useMemo(() => ctxState.isBuildingSelected ? BUILDING_LOCATION_TYPE : PROPERTY_LOCATION_TYPE, [ctxState.isBuildingSelected]);

    const disabled = !!ctxState.activeStyle;

    const selectedEntityType = useMemo(() =>
    {

        let selectedEntityType = "";

        if (ctxState.activeStyle)
        {
            const { entityType, subEntityType } = ctxState.activeStyle.target;
            selectedEntityType = `${entityType}:${subEntityType}`;
        }

        return selectedEntityType;

    }, [ctxState.activeStyle]);

    const handleCreateTheme = async (themeName, baseMapId, dark) =>
    {
        const result = await onCreateTheme(themeName, baseMapId, dark);

        if (result.success)
        {
            setOpenThemesCreator(false);
        }
    };

    const handleEditTheme = async (themeId, themeName, baseMapId, dark) =>
    {
        const result = await onUpdateTheme(themeId, themeName, baseMapId, dark);

        if (result.success)
        {
            setOpenThemesEditor(false);
        }
    };

    // flag to indicate whether the current active theme is default company theme
    const isActiveThemeDefault = useMemo(() => ctxState.activeTheme === MAPSTED_LIGHT_THEME._id || ctxState.activeTheme === MAPSTED_DARK_THEME._id, [ctxState.activeTheme]);

    const getCurrentThemeName = useCallback(() =>
    {
        let themeName;

        const { activeTheme, themes } = ctxState;

        if (activeTheme && themes?.length)
        {
            const currentTheme = themes.find((theme) => theme._id === activeTheme);
            themeName = currentTheme?.name?.[DEFAULT_LANGUAGE_CODE];
        }

        return themeName;
    }, [ctxState.activeTheme, ctxState.themes]);

    const getCurrentThemeBaseMapId = useCallback(() =>
    {
        let baseMapId;

        const { activeTheme, themes } = ctxState;

        if (activeTheme && themes)
        {
            const currentTheme = themes.find((theme) => theme._id === activeTheme);
            baseMapId = currentTheme?.tileLayer?.baseMapId;
        }

        return baseMapId;
    }, [ctxState.activeTheme, ctxState.themes]);

    const getCurrentThemeDarkStatus = useCallback(() =>
    {
        let dark = false;

        const { activeTheme, themes } = ctxState;

        if (activeTheme && themes)
        {
            const currentTheme = themes.find((theme) => theme._id === activeTheme);
            dark = currentTheme?.dark;
        }

        return dark;
    }, [ctxState.activeTheme, ctxState.themes]);

    const themeStatus = useMemo(() =>
    {
        const { activeTheme, themes } = ctxState;
        if (activeTheme && themes?.length)
        {
            const currentTheme = themes.find((theme) => theme._id === activeTheme);
            return currentTheme?.active;
        }
        else
        {
            return false;
        }
    }, [ctxState.activeTheme, ctxState.themes]);

    const primaryTheme = ctxState.propertySettings.primaryTheme;

    return (
        <div className="mapstyleSidebar">
            {
                showDeleteThemeConfirmation && !!selectedThemeInfo && <DeleteThemeConfirmation
                    open={showDeleteThemeConfirmation}
                    onClose={() => 
                    {
                        setShowDeleteThemeConfirmation(false);
                    }}
                    themeInfo={{
                        themeName: selectedThemeInfo.name.en,
                        themeId: selectedThemeInfo._id
                    }} />
            }
           
            <Header className="headingbold" content={trans("StylesAndThemesSidebar.Map_Styles_Themes")} />
            <Scrollbars autoHeight autoHeightMin="calc(1vh)" autoHeightMax={"calc(100vh - 200px)"}>
                <div className="mapstyleContent">

                    <TextGroup heading={
                        <>
                            <span>{trans("StylesAndThemesSidebar.Theme")}</span>
                            <Popup className="tooltipPopup"
                                content={trans("StylesAndThemesSidebar.Create_New_Theme")}
                                basic
                                trigger={
                                    <ButtonIcon
                                        className="themeAddBtn"
                                        icon="plus-filled"
                                        onClick={() => setOpenThemesCreator(true)}
                                        disabled={!!ctxState.activeStyle}
                                    />
                                }
                            />
                            <ThemeCreateAndEditModal 
                                initialThemeName=""
                                initialBaseMap={BASE_MAPS[0].baseMapId}
                                onCreate={handleCreateTheme}
                                open={openThemesCreator}
                                onClose={() => setOpenThemesCreator(false)}
                                themes={ctxState.themes}
                                initialDarkStatue={false}
                                themesExcludedFromDeletion={themesExcludedFromDeletion}
                            />
                        </>
                    }>
                        <small>
                            {trans("StylesAndThemesSidebar.Theme_Editor_Description")}
                        </small>
                    </TextGroup>

                    <div className="themeSettings">
                        <DropdownForm 
                            className={`dropdownSelectTheme themePageDropdown ${themesExcludedFromDeletion.includes(ctxState.activeTheme) ? "dropdownSelectTheme--mod": "" }`} 
                            placeholder={trans("StylesAndThemesSidebar.Select_Theme")}
                            options={themesOptions}
                            value={ctxState.activeTheme || ""}
                            disabled={disabled || !themesOptions.length}
                            onChange={(e, { value }) => updateActiveTheme(value)}
                        />
                        {ctxState.activeTheme
                            && <>
                                <div className="theme-actions-bx">
                                    {!isActiveThemeDefault
                                    && <Popup className="tooltipPopup"
                                        content={trans("StylesAndThemesSidebar.Edit_Theme")}
                                        basic
                                        trigger={
                                            <ButtonIcon
                                                icon="edit"
                                                onClick={() => setOpenThemesEditor(true)}
                                                disabled={!!ctxState.activeStyle}
                                            />
                                        }
                                    />
                                    }
                                    {
                                        !themesExcludedFromDeletion.includes(ctxState.activeTheme) && <Popup className="tooltipPopup"
                                            content={trans("ThemeCreateAndEditModal.Delete_Theme")}
                                            basic
                                            trigger={
                                                <ButtonIcon
                                                    icon="delete-red"
                                                    className={"delete-icon-btn"}
                                                    onClick={() => 
                                                    {
                                                        setShowDeleteThemeConfirmation(true);
                                                    }}
                                                />
                                            }
                                        />
                                    }
                                 
                                </div>     
                                <ThemeCreateAndEditModal
                                    initialThemeName={getCurrentThemeName()}
                                    initialBaseMap={getCurrentThemeBaseMapId()}
                                    onEdit={handleEditTheme}
                                    open={openThemesEditor}
                                    themeId={ctxState.activeTheme}
                                    onClose={() => setOpenThemesEditor(false)}
                                    themes={ctxState.themes}
                                    initialDarkStatue={getCurrentThemeDarkStatus()}
                                    disableEdit={isActiveThemeDefault}
                                    themesExcludedFromDeletion={themesExcludedFromDeletion}
                                />
                                <div>
                                    <DividerLine isEmpty gap="20px 0 0" />

                                    {!isActiveThemeDefault
                                    && <TextGroup>
                                        {primaryTheme === ctxState.activeTheme ? <Popup className="tooltipPopup tooltipPopupBig themeSidebarPopup"
                                            content={<Trans i18nKey="StylesAndThemesSidebar.Cannot_Disable_Theme" />}
                                            basic
                                            position="bottom center"
                                            trigger={
                                                <span className="checkboxPopupTrigger">
                                                    {/* wrapping this in span since disabled elements would not show popup on hover */}
                                                    <CheckboxGroup
                                                        label={trans("StylesAndThemesSidebar.Display_Theme")}
                                                        checked={themeStatus}
                                                        disabled={true}
                                                    />
                                                </span>
                                            }
                                        /> : <CheckboxGroup
                                            label={trans("StylesAndThemesSidebar.Display_Theme")}
                                            checked={themeStatus}
                                            onChange={(event, { checked }) => handleThemeActiveStatusChange(ctxState.activeTheme, checked)}
                                        />
                                        }
                                        <small>
                                            {trans("StylesAndThemesSidebar.Theme_Activation_Description")}
                                        </small>
                                    </TextGroup>
                                    }
                                </div>
                               
                            </>
                        }
                    </div>

                    {ctxState.activeTheme && <>
                        <DivideBorderLine gap="-10px 0 20px" />

                        <div className="sectionMapStyle">
                            <h6 className="h6 themeClassificationTypeHeading">{trans("StylesAndThemesSidebar.Entity_Types")}</h6>

                            {Object.entries(entityClassifications).map(([key, value]) => (
                                <TextGroup key={key} className="tgMapStyles" heading={key}>
                                    {value.map((entityType) => (
                                        <MapStyleItem
                                            key={entityType.name}
                                            className={selectedEntityType === `${entityType.entityType}:${entityType.subEntityType}` ? "selected" : ""}
                                            content={entityType.name}
                                            disabled={disabled}
                                            onClick={() => !disabled && handleEntityStyleSelection(entityType.entityType, entityType.subEntityType, locationType)}
                                            onHideToggleClick={() => handleHideToggleClick(`${entityType.entityType}:${entityType.subEntityType}`)}
                                            hidden={ctxState.hiddenEntityTypes.includes(`${entityType.entityType}:${entityType.subEntityType}`)}
                                            trans={trans}
                                        />
                                    ))}
                                </TextGroup>
                            ))}

                        </div>
                    </>}

                </div>
            </Scrollbars>
        </div>);
};

export default StylesAndThemesSidebar;

export const MapStyleItem = (
    {
        content,
        onClick,
        className,
        disabled,
        onHideToggleClick,
        hidden,
        trans,
    }) => (
    <div className="mapStyleItem">
        <Popup className="popupLabel"
            content={hidden ? trans("StylesAndThemesSidebar.Unhide_Entity") : trans("StylesAndThemesSidebar.Hide_Entity")}
            basic
            position="left center"
            trigger={
                <div className="styleItemIcon" onClick={onHideToggleClick}>
                    {hidden ? <img
                        src="/img/icon-eye-off.svg"
                        alt=""
                    />: <img 
                        src="/img/icon-opaque-eye.svg" 
                        alt=""
                    />
                    }
                </div>
            }
        />
        <div
            className={`mapStyleItemElem ${className ? className : ""} ${disabled ? "disabled" : ""}`}
            onClick={onClick}>
            <p>{content}</p>
        </div>
    </div>
);