import React from 'react';
import { Icon } from 'semantic-ui-react';

export const BuildingDetailItem = ({ className, title, detail, validated, isValidationChecked = true, onBlur, children }) =>
{
    return (
        <div className={`building-detail-item${className ? " " + className : ""}`}>
            {title && <b>
                <span title={title}> {title}</span>
            </b>}
            <div className="bdi-content">
                {detail && <div className="detail" onBlur={onBlur}>{detail}</div>}
                {
                    (isValidationChecked && validated) && (
                        <Icon name="check" color="green" />
                    )
                }
                {children}
            </div>
        </div>
    );
}