import React from "react";
import { Segment, Button } from "semantic-ui-react";
import { useDrag, useDrop } from "react-dnd";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { DraggableItemTypes, STATUS_DESCRIPTION, SINGLE_LANG_INPUT_CODE } from "../../_constants/constants";
import { UTIL_QUERIES } from "../../_utils/queries";

export const OrganizeFloorPlanRow = ({ short, title, file, status, onDelete }) =>
{
    /** @type {typeof import("../../_intl/resources.json")["en"]["translation"]["organize floor plan row"]} */
    const trans = useTranslation().t("organize floor plan row");
    const { data: config } = useQuery(UTIL_QUERIES.CONFIG());
    const renderStatus = () =>
    {
        if (status !== config.STATUSES.VALIDATING)
        {
            return (
                <Button className="upload-remove" content={<img src="/img/icon-trash.svg" alt="" />} onClick={onDelete} />
            );
        }
        else if (!STATUS_DESCRIPTION[status].incomplete)
        {
            return (
                <span className="floor-status">
                    {trans["success"]}
                    <img src="/img/icon-success.svg" alt="Success" />
                </span>
            );
        }
        else if (STATUS_DESCRIPTION[status].incomplete)
        {
            return (
                <Button className="floor-status reupload">
                    {trans["reupload"]}
                    <img src="/img/icon-reupload.svg" alt="Reupload" />
                </Button>
            );
        }
    };

    return (
        <>
            <Segment basic className="organize-floor-row">
                <span>{short}</span>
                <div>{title}
                    <span>{file && file.name}</span>
                </div>
            </Segment>

            {
                renderStatus()
            }

        </>
    );
};

export const OrganizeFloorPlanRowDraggable = ({ floor, onDragStart }) =>
{
    const [{ isDragging }, drag] = useDrag({
        item: { floor, type: DraggableItemTypes.FLOOR },
        begin: (monitor) => onDragStart(monitor.getItem()),
        collect: (monitor, item) => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    const { shortName: short, longName: title, floorPlan } = floor;

    return (
        <div className="ui segment basic organize-floor-row" ref={drag} style={{ opacity: isDragging ? 0 : 1 }} >
            <span>{short?.[SINGLE_LANG_INPUT_CODE]}</span>
            <div>{title?.[SINGLE_LANG_INPUT_CODE]}
                <span>{floorPlan.name}</span>
            </div>
        </div>

    );
};


export const OrganizeFloorPlanRowSortable = ({ floor, onDragStart, moveFloor }) =>
{
    const ref = React.useRef(null);

    const [, drop] = useDrop({
        accept: DraggableItemTypes.FLOOR,
        hover (item, monitor)
        {

            if (!ref.current)
            {
                return;
            }

            // Don't replace items with themselves
            if (item.id === floor.id)
            {
                return;
            }

            const dragFloorNumber = item.floorNumber;
            const hoverFloorNumber = floor.floorNumber;

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragFloorNumber < hoverFloorNumber && hoverClientY < hoverMiddleY)
            {
                return;
            }
            // Dragging upwards
            if (dragFloorNumber > hoverFloorNumber && hoverClientY > hoverMiddleY)
            {
                return;
            }
            // Time to actually perform the action
            moveFloor(dragFloorNumber, hoverFloorNumber);
        },
    });

    const [{ isDragging }, drag] = useDrag({
        item: { ...floor, type: DraggableItemTypes.FLOOR },
        begin: (monitor) => onDragStart(monitor.getItem()),
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const { shortName: short, longName: title, floorPlan } = floor;

    const opacity = isDragging ? 0 : 1;

    drag(drop(ref));

    return (
        <div className="ui segment basic organize-floor-row" ref={ref} style={{ opacity }} >
            <span>{short?.[SINGLE_LANG_INPUT_CODE]}</span>
            <div>{title?.[SINGLE_LANG_INPUT_CODE]}
                <span>{floorPlan && floorPlan.name}</span>
            </div>
        </div>

    );
};
