import React, { useState, useContext, useEffect } from "react";
import { ModalLayout } from "../../../../popups/ModalLayout";
import { Trans, useTranslation } from "react-i18next";
import { Button, Header, Grid } from "semantic-ui-react";
import { ErrorLabel } from "../../../../elements/ErrorLabel";
import { UploadTab } from "../../../../popups/UploadImageModal";
import BrandingContext from "../../../../../store/BrandingContext";
import LinkButton from "../../../../common/LinkButton";
import LanguageDropdown from "../../../../common/languageDropdown/LanguageDropdown";
import { TextGroup } from "../../../../common/TextGroup";
import { DEFAULT_LANGUAGE_CODE } from "../../../../../_constants/constants";

import "./ListViewUploadCSVModal.css";

export const OnlineKeywordUploadModal = ({
    isOpen,
    close,
    onUpload,
}) =>
{
    const trans = useTranslation().t;

    const bCtx = useContext(BrandingContext);

    const [file, setFile] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [lang, setLang] = useState(DEFAULT_LANGUAGE_CODE);

    useEffect(() =>
    {
        // reset on modal open
        setFile(null);
        setErrorMessage("");
        setLang(DEFAULT_LANGUAGE_CODE);
    }, [isOpen]);

    const handleDownload = (e) =>
    {
        e.preventDefault();
        bCtx.downloadOnlineKeywordsTextFile(lang);
    };

    const handleFileChange = (files) =>
    {
        setErrorMessage("");
        setFile(files[0]);
    };

    const handleFileUpload = async () =>
    {
        if (!file) return;

        const { success, onlineKeywords } = await bCtx.uploadOnlineKeywordsTextFile(file, lang);

        if (!success)
        {
            setErrorMessage(trans("OnlineKeywordUploadModal.Server_Error"));
        }
        else
        {
            onUpload(onlineKeywords, lang);
            close();
        }
    };

    const handleOnClose = () =>
    {
        setErrorMessage("");
        setLang(DEFAULT_LANGUAGE_CODE);
        setFile(null);
        if (close)
        {
            close();
        }
    };

    return (
        <ModalLayout
            open={isOpen}
            onClose={handleOnClose}
            heading={trans("OnlineKeywordUploadModal.Upload_OnlineKeywords")}
            className="listView-csv-modal"
            size="mini"
            actions={
                <Button
                    disabled={!file || errorMessage }
                    color="orange"
                    content={trans("OnlineKeywordUploadModal.Upload")}
                    onClick={handleFileUpload}
                />
            }
        >
            <p className="p-modal csvUploadModalText">
                <Trans i18nKey="OnlineKeywordUploadModal.Text_Template" components={
                    { 
                        templateLink: <LinkButton onClick={handleDownload} />,
                    }}/>
            </p>

            <TextGroup heading={trans("LanguageSelector.Choose_Language")} className="languageSelectorDropdown">
                <LanguageDropdown 
                    allowedLangs={bCtx.getCurrentLangOptions()}
                    activeLang={lang}
                    onChange={(lang) => setLang(lang)}
                />
            </TextGroup>

            <div className="fileUploader" >
                {file ? (
                    <Grid centered>
                        <Grid.Row>
                            <Header content={file.name} />
                        </Grid.Row>
                        <Grid.Row style={{ marginTop: "20px" }}>
                            <Button
                                basic
                                icon="refresh"
                                content={trans("UploadCSVModal.Change")}
                                onClick={() => setFile(null)}
                            />
                        </Grid.Row>
                        <Grid.Row style={{ marginTop: "20px" }}>
                            {errorMessage && (
                                <ErrorLabel>
                                    {errorMessage}
                                </ErrorLabel>
                            )}
                        </Grid.Row>
                    </Grid>
                ) : (
                    <UploadTab
                        acceptableImageFileFormats={".txt"}
                        onChange={handleFileChange}
                        returnRawFile
                        invalidFileTypeErrorMessage={trans("OnlineKeywordUploadModal.Invalid_File_Type_Error")}
                    />
                )}
            </div>
        </ModalLayout>
    );
};

