import React from 'react';
import { Grid } from 'semantic-ui-react';

export const GridTable = ({className,children}) =>
{
    return (
        <Grid columns="equal" className={`grid-table${className ? " " + className : ""}`}>
            {children}
        </Grid>
    );
}