import { useTranslation } from "react-i18next";
import { filerUrl } from "../../../_utils/utils";
import { StyledIcon } from "../../branding/categoriesPortal/StyledIcon";
import "./CategoryGroupItem.css";
import React, { useContext } from "react";
import { CategoryContext } from "../../../store/CategoryProvider";
import { DEFAULT_LANGUAGE_CODE } from "../../../_constants/constants";

const CategoryGroupItem = ({ category, lang, isActive, getIconInfo, onClick, children }) => 
{
    const trans = useTranslation().t;
    const iconInfo = getIconInfo(category);
    const categoryCtx = useContext(CategoryContext);

    const getSubCategoriesCount = (category) =>
    {
        if (category.subCategories.length > 0) 
        {

            const subCategories = categoryCtx.getCategoriesFromIds(category.subCategories);
            return subCategories.reduce((a, e) => e.subCategories.length + a, 0);
        }
        return 0;
    };
    return (
        <div className={`categoryGroupItem-root ${isActive ? "active" : ""}`} onClick={onClick}>
            {
                iconInfo.iconImage && <div className="icon">
                    <StyledIcon imageSrc={filerUrl(iconInfo.iconImage)}
                        backgroundColour={iconInfo.backgroundColour}
                        iconColour={iconInfo.iconColour}
                    />
                </div>
            }
            <div className="middle">
                <div className="name">
                    {category.name[lang] || category.name[DEFAULT_LANGUAGE_CODE]}
                </div>
                <div className="sub-text">
                    {category.subCategories.length} {trans("branding.Categories")} | {getSubCategoriesCount(category)} {trans("branding.SubCategories")}
                </div>
            </div>
            <div className="rightSide">
                {children}
            </div>
        </div>
    );
};

export default CategoryGroupItem;