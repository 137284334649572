import React, { FC } from "react";

import css from "./Avatar.module.css";
import defaultPlaceholder from "./display-placeholder.svg";

interface AvatarProps {
    imageSrc?: string;
    placeholder?: string;
    small?: boolean;
    className?: string;
    style?: React.CSSProperties;
    imgStyle?: React.CSSProperties;
}

const Avatar : FC<AvatarProps> = ({
    imageSrc = "",
    placeholder = defaultPlaceholder,
    small = false,
    className = "",
    style = undefined,
    imgStyle = undefined
}) =>
{
    const [isPlaceholder, setIsPlaceholder] = React.useState<boolean>(true);
    React.useEffect(() => setIsPlaceholder(!imageSrc), [imageSrc]);

    let classString = small ? css.small : css.regular;
    if (isPlaceholder)
    {
        classString += ` ${css.placeholder}`;
    }
    classString += ` ${className}`;

    return (
        <div className={classString} style={style}>
            <img
                className={css.img}
                style={imgStyle}
                src={isPlaceholder ? placeholder : imageSrc}
                alt="avatar"
                onError={() => setIsPlaceholder(true)}
            />
        </div>
    );
};

export default Avatar;
