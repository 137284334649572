
/**
 * @template T
 * @template {keyof T} K
 * @param {Array<T>} array
 * @param {K} key key to use to set the map key
 * @returns {Map<T[K], T>}
 */
exports.arrayToMap = (array, key) =>
{
    let map = new Map();
    array.forEach((element) => map.set(element[key], element));

    return map;
};

/**
 * @template T1
 * @template {keyof T1} K
 * @param {Array<T1>} array
 * @param {K} key key to use to set the hash key
 * @returns {Record<T1[K], T1>}
 */
exports.arrayToHash = (array, key) =>
{
    /** @type {Record<T1[K], T1>} */
    let hash = {};

    if (Array.isArray(array))
    {
        array.forEach((element) => hash[element[key]] = element);
    }

    return hash;
};

/**
 *
 * @template T2
 * @param {Array<T2>} array
 * @param {(t: T2, index: number, array: Array<T2>) => Promise<any>} callback
 */
exports.asyncForEach = async (array, callback) =>
{
    for (let index = 0; index < array.length; index++)
    {
        await callback(array[index], index, array);
    }
};


/**
 * @deprecated Only works if the array elements are sortable. Please use `areEqualMutlisets` instead.
 *
 * Compares two array, by validating that all items exists in both arrays, but might appear in different orders
 * @param {Array} array1
 * @param {Array} array2
 * @param {Function} compareFunction (a, b) => compare a to b for the valid sorting (this is optional for when array1 and array2 contain numbers or strings)
 */
exports.doArraysHaveSameItemsInDifferentOrder = (array1, array2, compareFunction) =>
{
    // If length are not identical, the sets are not equal
    if (array1.length !== array2.length)
    {
        return false;
    }

    // Sort both arrays by the same conventions, this will allow to compare the items in the next while loop
    array1 = [...array1].sort(compareFunction); // This will be n(Log(n))
    array2 = [...array2].sort(compareFunction); // This will be n(Log(n))

    let i = array1.length - 1;

    // This loop will take n*(Complexity to compare two strings)
    while (array1[i] == array2[i] && i-- >= 0)
    {
        continue;
    }

    return i < 0;
};

/**
 *
 * @param {string} field
 * @returns {(a, b) => number}
 */
exports.fieldSortComparator = (field = "name") => (a, b) =>
{

    if (typeof(a[field]) === "number" && typeof(b[field]) === "number")
    {
        return b[field] - a[field];
    }

    if (a[field] < b[field])
    {
        return -1;
    }
    else if (b[field] - a[field])
    {
        return 1;
    }
    return 0;
};

/**
 * Returns true if the 2 arrays have the same objects in the same order O(n)
 * objects aEe compared with the compareFunction (default ===)
 * @template T3
 * @param {Array<T3>} a1
 * @param {Array<T3>} a2
 * @param {(a: T3, b: T3) => boolean} [compare] function to compare values with
 * @returns {boolean}
 */
exports.areEqualOrderings = (a1, a2, compare) =>
{
    const compareFunc = compare || ((a, b) => a === b);
    if (a1.length !== a2.length)
    {
        return false;
    }

    for (let i = 0; i < a1.length; i++)
    {
        if (!compareFunc(a1[i], a2[i]))
        {
            return false;
        }
    }
    return true;
};

/**
 * @deprecated in favour of `areEqualOrderings` for clearer naming.
 *
 * Returns true if the 2 arrays have the some objects
 * objects are compared with the compareFunction (default ===)
 * @template T4
 * @param {Array<T4>} a1
 * @param {Array<T4>} a2
 * @param {(a: T4, b: T4) => boolean} compareFunction function to compare values with
 * @returns {boolean}
 */
exports.arraysAreEqual = exports.areEqualOrderings;

/**
 * Compares 2 arrays to see if they are equal [MultiSets](https://en.wikipedia.org/wiki/Multiset).
 * eg. The numbers of every item are the same, irrespective of ordering.
 * O(N) if compare is undefined. O(N logN) if order is specified and O(N^2) if compare is specified and order is false
 * @template T5
 * @param {Array<T5>} a1 the first array
 * @param {Array<T5>} a2 the second array
 * @param {object} [options]
 * @param {(a: T5, b: T5) => boolean} [options.compare=undefined] function to test if 2 elements are equal, defaults to `===` in `undefined`
 * @param {boolean | ((a: T5, b: T5) => number)} [options.order=false] returns the relative order of 2 elements. See `array.sort`
 * @returns {boolean}
 */
exports.areEqualMultisets = (a1, a2, options) =>
{
    const defaultOptions = { compare: undefined, order: false };
    const { compare, order } = { ...defaultOptions, ...options };

    if (a1.length !== a2.length)
    {
        return false;
    }

    // using identity function to compare so Map is fine O(n)
    if (!compare)
    {
        // the number of occurences of the elements of a1
        const counts = new Map();
        for (const e of a1)
        {
            const count = (counts.get(e) || 0) + 1;
            counts.set(e, count);
        }

        for (const e of a2)
        {
            const currentCount = counts.get(e);
            if (!currentCount)
            {
                return false;
            }
            counts.set(e, currentCount - 1);
        }

        return Array.from(counts.values()).every((c) => c === 0);
    }

    // elements have ordering so can compare the sorted order for O(nlogn)
    if (order)
    {
        // use default ordering val (undefined) if order is true
        const orderFunc =  order === true ? undefined : order;
        const a1Sorted = [...a1].sort(orderFunc);
        const a2Sorted = [...a2].sort(orderFunc);

        for (let i = 0; i < a1Sorted.length; i++)
        {
            if (!compare(a1Sorted[i], a2Sorted[i]))
            {
                return false;
            }
        }

        // a1 and a2 are the same length so can return true at this point
        return true;
    }

    // no ordering and custom compare function
    // check all elements against each other O(n^2)
    const counts = new Map();
    for (const e of a1)
    {
        // check if equal to a previously counted el
        let counted = false;
        for (const prevE of counts.keys())
        {
            if (compare(prevE, e))
            {
                counts.set(prevE, counts.get(prevE) + 1);
                counted = true;
            }
        }
        if (!counted)
        {
            counts.set(e, 1);
        }
    }

    for (const e of a2)
    {
        let foundMatch = false;
        for (const prevE of counts.keys())
        {
            if (compare(prevE, e))
            {
                foundMatch = true;
                const currentCount = counts.get(prevE);
                if (currentCount <= 0)
                {
                    return false;
                }

                counts.set(prevE,  currentCount - 1);
            }
        }

        if (!foundMatch)
        {
            return false;
        }
    }
    return Array.from(counts.values()).every((c) => c === 0);
};

/**
 * Whether or not `a1` and `a2` are equal sets.
 * O(N) if compare is undefined. O(N logN) if order is specified and O(N^2) if compare is specified and order is false
 * @template T7
 * @param {Array<T7>} a1 the first array
 * @param {Array<T7>} a2 the second array
 * @param {object} [options]
 * @param {(a: T7, b: T7) => boolean} [options.compare=undefined] function to test if 2 elements are equal, defaults to `===` in `undefined`
 * @param {boolean | ((a: T7, b: T7) => number)} [options.order=false] returns the relative order of 2 elements. See `array.sort`
 * @returns {boolean}
 */
exports.areEqualSets = (a1, a2, options) =>
{
    const defaultOptions = { compare: undefined, order: false };
    const { compare, order } = { ...defaultOptions, ...options };

    if (!compare)
    {
        const s1 = new Set(a1);
        const s2 = new Set(a2);
        if (s1.size !== s2.size)
        {
            return false;
        }
        for (const e of s1)
        {
            if (!s2.has(e))
            {
                return false;
            }
        }
        return true;
    }

    // elements have ordering so can compare the sorted order for O(nlogn)
    if (order)
    {
        // use default ordering val (undefined) if order is true
        const orderFunc =  order === true ? undefined : order;
        const a1Sorted = [...a1].sort(orderFunc)
            // remove duplicates
            .filter((e, i, arr) => i === 0 || !compare(e, arr[i - 1]));

        const a2Sorted = [...a2].sort(orderFunc)
            // remove duplicates
            .filter((e, i, arr) => i === 0 || !compare(e, arr[i - 1]));

        if (a1Sorted.length !== a2Sorted.length)
        {
            return false;
        }

        for (let i = 0; i < a1Sorted.length; i++)
        {
            if (!compare(a1Sorted[i], a2Sorted[i]))
            {
                return false;
            }
        }

        return true;
    }

    return a1.every((e1) => a2.some((e2) => compare(e1, e2))) && a2.every((e2) => a1.some((e1) => compare(e1, e2)));
};

/**
 * Moves the item to the new position in the array array. Useful for huge arrays where absolute performance is needed. https://github.com/sindresorhus/array-move
 * @param {Array} array
 * @param {number} from Index of item to move. If negative, it will begin that many elements from the end.
 * @param {number} to Index of where to move the item. If negative, it will begin that many elements from the end.
 * @returns {void}
 */
exports.arrayMoveMutate = function arrayMoveMutate(array, from, to)
{
    const startIndex = from < 0 ? array.length + from : from;

    if (startIndex >= 0 && startIndex < array.length)
    {
        const endIndex = to < 0 ? array.length + to : to;

        const [item] = array.splice(from, 1);
        array.splice(endIndex, 0, item);
    }
};

/**
 * Clones the given array, moves the item to a new position in the new array, and then returns the new array.
 * The given array is not mutated. https://github.com/sindresorhus/array-move
 * @template T6
 * @param {Array<T6>} array
 * @param {number} from Index of item to move. If negative, it will begin that many elements from the end.
 * @param {number} to Index of where to move the item. If negative, it will begin that many elements from the end.
 * @returns {Array<T6>}
 */
exports.arrayMove = (array, from, to) =>
{
    array = [...array];
    exports.arrayMoveMutate(array, from, to);
    return array;
};
