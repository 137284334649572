import React from "react";
import { Button } from "semantic-ui-react";
import { ModalBox } from "../../common/ModalBox";
import { CheckboxGroup } from "../../common/CheckboxGroup";
import "./CopyStoreInfoModal.css";

export const CopyStoreInfo = () =>
{
    return (
        <ModalBox
            size="small"
            trigger={<Button className="buttonIcon" content="Copy Store Info" style={{margin : "0 10px 10px 0"}}/>}
            header="Copy Store Info"
            actions={<Button primary floated="right" content="Copy Info" />}>
            <p className="para">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod 
                tempor invidunt ut labore et dolore magna aliquyam</p>
            
            <div className="copyStireInfoGroup">
                <CopyStoreInfoItem title="Phone" content="+1 555 555 5555 " />
                <CopyStoreInfoItem title="Website" content="urbanoutfitters.com" />
                <CopyStoreInfoItem title="Categories">
                    Children’s Apparel & Maternity<br />
                    Luxury Apparel
                </CopyStoreInfoItem>
                <CopyStoreInfoItem title="Subcategories">
                    Women’s Apparel<br />
                    Men’s Apparel<br />
                    Children’s Apparel<br />
                    Women’s Shoes<br />
                    Men’s Shoes<br />
                    Children’s Shoes
                </CopyStoreInfoItem>
            </div>
        </ModalBox>
    );
}

const CopyStoreInfoItem = ({title, content, children}) =>
{
    return (
        <div className="copyStoreInfoItem">
            <CheckboxGroup />
            <p>{title}</p>
            <span>
                {content}
                {children}
            </span>
        </div>
    );
}