import React from 'react';
import Carousel from "./Carousel";
import './GallerySlider.css';
import { imageUrl } from '../../../_utils/utils';

export const GallerySlider = ({ galleryList, onClick }) =>
{

	const [renderKey, setRenderKey] = React.useState(0);
	const srcMap = React.useMemo(() =>
	{
		let map = {};

		Array.isArray(galleryList) && galleryList.forEach((fileId) =>
		{
			map[fileId] = imageUrl(fileId);
		});

		setRenderKey(renderKey + 1);
		return map;
	}, [galleryList]);

	const elements = React.useMemo(() =>
	{
		return Array.isArray(galleryList) && galleryList.map((fileId) =>
		{
			return ({
				render: () => { return <img key={fileId + renderKey} src={srcMap[fileId]} alt="" /> }
			});
		});

	}, [srcMap, renderKey]);

	return (
		<div className="gallerySlider">
			<Carousel
				elements={elements || []}
				animation="drop "
				showNextPrev={true}
				showIndicators={true}
			/>
			<div className="gallerySliderOverlay" onClick={onClick} />
		</div>
	)
}