import React from "react";
import { Button, Modal } from "semantic-ui-react";
import "./ListViewConfirmationModal.css";
import { useTranslation } from "react-i18next";
import { useListViewContext } from "../../../store/ListViewContext";

function ListViewConfirmationModal() 
{
    const { confirmationModalInfo, gridRef, handleCloseConfirmationModalInfo } = useListViewContext();
    const trans = useTranslation().t;
    function handleCancelConfirmationModalInfo() 
    {
        const [column] = gridRef?.current?.api?.getEditingCells() || [];
        gridRef?.current?.api?.stopEditing({
            rowIndex: column.rowIndex,
            colKey: column.column.colDef.headerName,
        });
        handleCloseConfirmationModalInfo();
    }

    return (
        <Modal
            className="list-view-confirmation-modal"
            open={!!confirmationModalInfo}
            onClose={handleCloseConfirmationModalInfo}
        >
            <Modal.Header>{trans(`ListView.${confirmationModalInfo?.type}`)}</Modal.Header>
            <Modal.Content>
                <p>{trans(`ListView.${confirmationModalInfo?.message}`)}</p>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleCloseConfirmationModalInfo}>{trans("ListView.Retry")}</Button>
                <Button className="ui orange" onClick={handleCancelConfirmationModalInfo}>{trans("ListView.Cancel")}</Button>
            </Modal.Actions>
        </Modal>
    );
}

export default ListViewConfirmationModal;
