import React from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { withRouter } from "react-router";
import { Button, Segment, Portal, Form, TextArea, } from "semantic-ui-react";

import { HeaderPortal } from "../HeaderPortal";
import BrandingContext from "../../../store/BrandingContext";
import { DefaultSection } from "./EditSideBarComponents";
import { DEFAULT_LANGUAGE_CODE, MAP_TOOLS, SINGLE_LANG_INPUT_CODE, WARNING_MESSAGES } from "../../../_constants/constants";
import { InformationBox } from "./InformationBox";
import { getDefaultEntityLabel } from "../../../_utils/branding.utils";
import { getSelectedEntity } from "mapsted.maps/mapFunctions/features";
import { deepValue } from "mapsted.utils/objects";
import { ErrorModal } from "../../popups/ErrorModal";

import "./EditSidebarPortal.css";
import css from "./common.module.css";
import { Transl } from "../../../_intl/i18n";
import { useTranslation } from "react-i18next";
import { EntityType } from "mapsted.maps/utils/entityTypes";

import { CopyVenueInfo } from "../../modalsPopups/copyVenueInfo/CopyVenueInfo";


class EditSidebarPortal extends React.Component
{
    static contextType = BrandingContext;

    constructor()
    {
        super();

        this.state = {
            isEdit: false,
            selectedEntityId: undefined,
            entityLabelName: "",
            bypass: true,
            displayLanguage: DEFAULT_LANGUAGE_CODE,
            openCopyVenueInfoModal: false,
            relatedStores: [],
            sourceEntityLabelId: undefined, // this field will be used in copy venue info process
            sourceEntityLabelName: ""
        };
    }


    componentDidUpdate()
    {
        const { selectedEntities, mapData, properties, propertyId, buildingId } = this.context.state;
        const { selectedEntityId, selectedEntityFeature } = getSelectedEntity(selectedEntities);

        const entity = deepValue(mapData, `entities.${selectedEntityId}`);
        const entityLabel = entity?.entityLabel;
        const longName = deepValue(entityLabel, `longName.${SINGLE_LANG_INPUT_CODE}`, "");

        const entityArea = selectedEntityFeature && selectedEntityFeature.get("area");

        let canPlotImage = false;
        if (entity?.entityType === EntityType.POINT_OF_INTEREST)
        {
            canPlotImage = true;
        }
        else
        {
            canPlotImage = (entityArea) ? entityArea >= mapData.thresholdAreaForImages : false;
        }

        if (selectedEntityId !== this.state.selectedEntityId || longName.localeCompare(this.state.entityLabelName) !== 0)
        {
            let state = {};

            state.buildingHours = JSON.parse(JSON.stringify(deepValue(properties, `${propertyId}.buildings.${buildingId}.partialOpeningHours`, [])));

            state.entityLabelName = longName;
            state.selectedEntityId = selectedEntityId;
            state.isEdit = !entityLabel;

            state.bypass = true;
            state.isUnsavedChanges = false;

            state.canPlotImage = canPlotImage;
            state.selectedEntityFeature = selectedEntityFeature;

            this.setState(state);
        }
    }

    handleClose = (isBypass = false) =>
    {
        if (this.state.isEdit && !(isBypass || this.state.bypass))
        {
            this.setState({ isUnsavedChanges: true });
        }
        else
        {
            this.context.setEditUnsavedChanges(false);
            this.setState({ isUnsavedChanges: false });

            this.context.changeSelectedEntity(undefined);
            if (this.props.onClose)
            {
                this.props.onClose();
            }
        }
    }

    handleUpdateEntityLabel = async (entityLabel) =>
    {
        if (this.context.state.buildingId || !entityLabel.buildingId)
        {
            await this.context.updateEntityLabel(entityLabel, this.checkForRelatedStores);
        }
        else
        {
            await this.context.updatePropertyEntityLabel(entityLabel, this.redirectToMaintenancePage);
        }
        this.setState({ isEdit: false });
        this.context.setEditUnsavedChanges(false);
        this.handleClose(false);
    }

    checkForRelatedStores = (success, data, relatedStores = []) =>
    {
        const approvedStores = this.getApprovedStores(relatedStores);
        if (data && approvedStores.length > 0)
        {
            this.setState({
                openCopyVenueInfoModal: true,
                relatedStores: approvedStores,
                sourceEntityLabelId: data._id,
                sourceEntityLabelName: deepValue(data, `longName.${SINGLE_LANG_INPUT_CODE}`, "")
            });
        }
        else
        {
            this.redirectToMaintenancePage();
        }
    };

    /**
     * Selects only approved stores from related stores list.
     * @param {Array} relatedStores
     * @returns {Array}
     */
    getApprovedStores = (relatedStores) =>
    {
        const properties = this.context.state.properties;
        const propertyKeys = Object.keys(properties);

        let approvedStores = [];

        relatedStores.forEach((store) =>
        {
            if (propertyKeys.includes(store.propertyId))
            {
                approvedStores.push(store);   
            }
        });

        return approvedStores;
    };

    handleToggleEdit = () =>
    {
        let { isEdit } = this.state;

        let bypass = true;

        if (isEdit === false)
        {
            bypass = false;
        }

        this.setState({ isEdit: !isEdit, bypass });

        this.context.setEditUnsavedChanges(!isEdit);
    }

    handleCloseWarning = () => this.setState({ isUnsavedChanges: false });
    handleChangeStyle = (patch) => this.context.handleChangeEntityStyle(patch, this.state.displayLanguage);

    getEntityLabelId = () =>
    {
        const { selectedEntities, mapData } = this.context.state;
        const { selectedEntityId } = getSelectedEntity(selectedEntities);
        const entityLabel = deepValue(mapData, `entities.${selectedEntityId}.entityLabel`, undefined);
        return entityLabel?._id;
    };

    handleCopyVenueInfoDone = () =>
    {
        this.setState({
            openCopyVenueInfoModal: false,
            relatedStores: [],
            sourceEntityLabelId: undefined,
            sourceEntityLabelName: ""
        });
        
        this.redirectToMaintenancePage();
    };

    redirectToMaintenancePage = () =>
    {
        // redirect back to maintenance page if restore is set as true
        if (this.context.state.redirectMaintenance)
        {
            this.context.redirectToMaintenancePage();
        }
    }
 
    render()
    {
        const { selectedEntityId, isEdit, isUnsavedChanges, canPlotImage, buildingHours, displayLanguage } = this.state;
        const { selectedTool, mapData, buildingId, properties, propertyId } = this.context.state;

        const entity = deepValue(mapData, `entities.${selectedEntityId}`, undefined);

        let entityStyle = deepValue(entity, "style", {});
        let entityLabel = deepValue(entity, "entityLabel", undefined);

        const isOpen = selectedTool === MAP_TOOLS.EDIT && !!selectedEntityId && entityLabel;

        if (!entityLabel)
        {
            entityLabel = getDefaultEntityLabel();
            entityLabel.partialOpeningHours.periods = deepValue(properties, `${propertyId}.buildings.${buildingId}.partialOpeningHours.periods`, []);
        }

        entityLabel = JSON.parse(JSON.stringify(entityLabel));

        const isStore = !deepValue(entity, "building", undefined);

        return (
            <>
                <Portal open={isOpen} closeOnEscape={false} >

                    <Segment basic className="store-detail-box editSidebarBox">
                        
                        <HeaderPortal
                            header={<Transl componentObjName="edit sidebar portal" keyName="edit header" />}
                            onClick={() => 
                            {   
                                this.handleClose(false);
                                this.redirectToMaintenancePage();
                            }}
                            lastUpdated={entityLabel?.updatedAt}
                        />

                        <Scrollbars className="defaultScroll" autoHeight autoHeightMin={"calc(1vh)"} autoHeightMax={"calc(100vh - 185px)"}>
                            <InformationBox
                                isBuilding={this.context.state.buildingId}
                                isEdit={isEdit}
                                canPlotImage={canPlotImage}
                                entityLabel={entityLabel}
                                entity={entity}
                                entityStyle={entityStyle}
                                onSave={this.handleUpdateEntityLabel}
                                onToggleEdit={this.handleToggleEdit}
                                onChangeStyle={this.handleChangeStyle}
                                buildingHours={buildingHours}
                                displayLanguage={displayLanguage}
                                setDisplayLanguage={(lang) => this.setState({ displayLanguage: lang })}
                                isStore={isStore}
                            />
                        </Scrollbars>

                        <ErrorModal
                            open={isUnsavedChanges}
                            error={{ unsavedChanges: WARNING_MESSAGES.unsavedChanges }}
                            onContinue={() => 
                            {
                                this.handleClose(true);
                                this.redirectToMaintenancePage();
                            }}
                            onBack={this.handleCloseWarning}
                        />

                    </Segment>

                </Portal>
                <CopyVenueInfo
                    open={this.state.openCopyVenueInfoModal}
                    onClose={this.handleCopyVenueInfoDone}
                    relatedStores={this.state.relatedStores}
                    entityLabelName={this.state.sourceEntityLabelName}
                    entityLabelId={this.state.sourceEntityLabelId}
                    propertyId={this.context.state.propertyId}
                    onDone={this.handleCopyVenueInfoDone}
                />
            </>
        );
    }
}

export default withRouter(EditSidebarPortal);

export const Description = () =>
{
    /** @type {typeof import("../../../_intl/resources.json")["en"]["translation"]["edit sidebar portal"]} */
    const trans = useTranslation().t("edit sidebar portal");
    const [editBox, setEditBox] = React.useState(false);

    return (
        <DefaultSection heading={trans["desc heading"]} onClick={() => setEditBox(!editBox)}>
            {(!editBox) && <p className={css.para}>{trans["desc 1"]}</p>}
            {(editBox) && <>
                <Form>
                    <TextArea className={css.textArea}>{trans["desc 1"]}</TextArea>
                </Form>
                <Button color="orange" content={trans["save button"]} />
            </>}
        </DefaultSection>
    );
};



