import React from 'react';
import { Button, Grid, Segment, Label } from 'semantic-ui-react';
import AddPropertyContext from '../../../store/AddPropertyContext';
import { HeadingProperty } from '../../elements/HeadingProperty';
import { AddPropertyLayout } from '../../addProperty/AddPropertyLayout';
import { InputFormGroup } from '../../elements/InputFormGroup';
import { Link, useHistory } from 'react-router-dom';
import { BuildingPreview } from '../../addProperty/BuildingPreview';
import { OrganizeFloorPlanRowDraggable } from '../../addProperty/OrganizeFloorPlanRow';
import { getSearchParams } from "../../../_utils/utils";
import { useTranslation } from 'react-i18next';

export const OrganizeFloorPlans = () =>
{
    const trans = useTranslation().t;
    const addPropertyContext = React.useContext(AddPropertyContext);
    const history = useHistory();
    const [state, setState] = React.useState({ unorganizedFloors: [], groundFloor: undefined, floorsAbove: [], floorsBelow: [], selectedFloor: undefined });
    const [error, setError] = React.useState(undefined);

    const handleGoBack = React.useCallback(() =>
    {
        addPropertyContext.loadUnsavedFloorPlans(true, () =>
        {
            history.push(`./floorplans` + history.location.search);
        });
    }, [history, addPropertyContext]);

    const handleGoToDashboard = React.useCallback(() =>
    {
        history.push("/dashboard");
    }, [history])

    /**
     * On Mount 
     */
    React.useEffect(() =>
    {
        const { buildingId, propertyId } = getSearchParams(["buildingId", "propertyId"]);
        if (propertyId && buildingId)
        {
            // If we loaded building already no need to fetch
            if (addPropertyContext.state.buildingLoaded && (addPropertyContext.state.buildingId === buildingId))
            {
                floorsInit(addPropertyContext.state.floors);
            }
            // Else fetch property
            else
            {
                addPropertyContext.getProperty({ propertyId, buildingId, isFloorPlansEdit: true }, ({ floors }) =>
                {
                    floorsInit(floors);
                });
            }
        }
        else if (addPropertyContext.state.buildingLoaded === false)
        {
            handleGoBack();
        }

    },
        // ON MOUNT - ignore warning. 
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []);

    const floorsInit = React.useCallback((floors) =>
    {
        floors.forEach((floor, index) => floor.id = index);

        const groundFloor = floors.find(f => f.floorNumber === 0);
        const floorsAbove = floors.filter(f => f.floorNumber > 0).sort((f1, f2) => - f1.floorNumber + f2.floorNumber);
        const floorsBelow = floors.filter(f => f.floorNumber < 0).sort((f1, f2) => - f1.floorNumber + f2.floorNumber);
        const unorganizedFloors = floors.filter(f => f.floorNumber === undefined);

        setState({
            unorganizedFloors,
            groundFloor,
            floorsAbove,
            floorsBelow,
            selectedFloor: undefined
        });

    }, []);


    const handleSelectFloor = React.useCallback((selectedFloor) =>
    {
        let newState = { ...state };
        newState.selectedFloor = selectedFloor;
        setState(newState);
    }, [state]);

    const handleOnSetFloorNumber = React.useCallback((floorNumber) =>
    {
        let newState = { ...state };
        newState.unorganizedFloors = [...newState.unorganizedFloors];
        newState.floorsAbove = [...newState.floorsAbove];
        newState.floorsBelow = [...newState.floorsBelow];

        if (!newState.selectedFloor)
        {
            return;
        }

        // Remove the selected floor from the lists
        newState.floorsAbove = newState.floorsAbove.filter(f => f.id !== newState.selectedFloor.id);
        newState.floorsBelow = newState.floorsBelow.filter(f => f.id !== newState.selectedFloor.id);

        if (newState.groundFloor && newState.groundFloor.id === newState.selectedFloor.id) 
        {
            newState.groundFloor = undefined;
        }

        if (floorNumber === 0)
        {
            if (newState.groundFloor)
            {
                newState.floorsAbove.push(newState.groundFloor);
            }

            newState.groundFloor = newState.selectedFloor;
            newState.groundFloor.floorNumber = 0;
        }
        else if (floorNumber > 0)
        {
            newState.floorsAbove.splice(newState.floorsAbove.length - (floorNumber - 1), 0, newState.selectedFloor);
        }
        else
        {
            newState.floorsBelow.splice(- (floorNumber - 1), 0, newState.selectedFloor);
        }

        // Correct floor Numbers
        newState.floorsAbove.forEach((f, i) => f.floorNumber = newState.floorsAbove.length - i);
        newState.floorsBelow.forEach((f, i) => f.floorNumber = - (i + 1));

        newState.unorganizedFloors = newState.unorganizedFloors.filter(f => f.floorNumber === undefined);

        newState.selectedFloor = undefined;

        setState(newState);
        setError(undefined);

    }, [state]);

    const handleMoveFloor = React.useCallback((fromFloorNumber, toFloorNumber) =>
    {
        let newState = { ...state };
        newState.floorsAbove = [...newState.floorsAbove];
        newState.floorsBelow = [...newState.floorsBelow];

        if (fromFloorNumber === 0)
        {
            newState.selectedFloor = newState.groundFloor;
            newState.groundFloor = undefined;
        }
        else if (fromFloorNumber > 0)
        {
            newState.selectedFloor = newState.floorsAbove.find(f => f.floorNumber === fromFloorNumber);
            newState.floorsAbove = newState.floorsAbove.filter(f => f.floorNumber !== fromFloorNumber);
        }
        else if (fromFloorNumber < 0)
        {
            newState.selectedFloor = newState.floorsBelow.find(f => f.floorNumber === fromFloorNumber);
            newState.floorsBelow = newState.floorsBelow.filter(f => f.floorNumber !== fromFloorNumber);
        }

        if (newState.selectedFloor === undefined)
        {
            return;
        }

        if (toFloorNumber === 0)
        {
            if (newState.groundFloor)
            {
                newState.floorsAbove.push(newState.groundFloor);
            }

            newState.groundFloor = newState.selectedFloor;
            newState.groundFloor.floorNumber = 0;
        }
        else if (toFloorNumber > 0)
        {
            newState.floorsAbove.splice(newState.floorsAbove.length - (toFloorNumber - 1), 0, newState.selectedFloor);
        }
        else
        {
            newState.floorsBelow.splice(- (toFloorNumber - 1), 0, newState.selectedFloor);
        }

        // Correct floor Numbers
        newState.floorsAbove.forEach((f, i) => f.floorNumber = newState.floorsAbove.length - i);
        newState.floorsBelow.forEach((f, i) => f.floorNumber = - (i + 1));

        newState.unorganizedFloors = newState.unorganizedFloors.filter(f => f.floorNumber === undefined);

        newState.selectedFloor = undefined;

        setState(newState);
        setError(undefined);

    }, [state]);

    const handleOnSave = React.useCallback(() =>
    {
        let { groundFloor, unorganizedFloors, floorsAbove, floorsBelow } = state;

        if (groundFloor === undefined)
        {
            setError(trans("OrganizeFloorPlans.You_must_specify_the_ground_floor"));
            return false;
        }

        if (unorganizedFloors.length > 0)
        {
            setError(trans("OrganizeFloorPlans.organized error", {floorNumber: unorganizedFloors.length}));
            return false;
        }

        const floors = [...floorsAbove, groundFloor, ...floorsBelow]

        addPropertyContext.saveFloors(floors, false);

        history.push(`/dashboard/upload?propertyId=${addPropertyContext.state.propertyId}&buildingId=${addPropertyContext.state.buildingId}`)
    }, [state, history, addPropertyContext]);

    return (
        <Grid columns="equal" className="property-cover2">
            <Grid.Column width="5">
                <Segment basic className="property-box">
                    <span onClick={handleGoToDashboard} className="close">
                        <Link to="#" />
                    </span>
                    <HeadingProperty heading={trans("OrganizeFloorPlans.Organize_Floor_Plans")} onClick={handleGoBack}
                    />
                    <AddPropertyLayout>
                        <p>
                            {trans("OrganizeFloorPlans.Drag_and_Drop_floor_plans_from_the_list_")}
                            </p>
                        <p className="p"><strong>{trans("OrganizeFloorPlans.Hint_")}</strong> {trans("OrganizeFloorPlans.Start_with_your_main_ground_floor_for_ea")}</p>
                        <div className="form-property">
                            <InputFormGroup label={trans("OrganizeFloorPlans.After_Uploaded")}>
                                {
                                    state.unorganizedFloors && state.unorganizedFloors.map(floor =>
                                        <OrganizeFloorPlanRowDraggable key={floor.id}
                                            floor={floor} onDragStart={() => handleSelectFloor(floor)} />
                                    )
                                }
                            </InputFormGroup>
                        </div>
                        <div className="property-actions">
                            {/* <Button size="small" color="grey" content="Save as Draft" /> */}
                            {
                                error &&
                                <Label pointing="right" basic color="red" content={error} />
                            }
                            <Button color="orange" disabled={(state.unorganizedFloors.length > 0) || (state.groundFloor === undefined) || (!addPropertyContext.state.buildingId)}
                                onClick={handleOnSave} content={trans("OrganizeFloorPlans.Finish")} />
                        </div>
                    </AddPropertyLayout>
                </Segment>
            </Grid.Column>
            <Grid.Column>
                <BuildingPreview
                    groundFloor={state.groundFloor}
                    floorsAbove={state.floorsAbove}
                    floorsBelow={state.floorsBelow}
                    onSelectFloor={handleSelectFloor}
                    onMoveFloor={handleMoveFloor}
                    onSetFloorNumber={handleOnSetFloorNumber} />
            </Grid.Column>
        </Grid>
    );
}

export default OrganizeFloorPlans;