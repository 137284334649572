import { DEFAULT_LANGUAGE_CODE } from "../_constants/constants";

const { webAPI, default: serverApi, serializeQuery } = require("./server.api");

class BrandingApi extends webAPI
{
    get toolPopupPrefrences()
    {
        return this.localStorage.readJSON("tool-popup-prefrences");
    }

    set toolPopupPrefrences(value)
    {
        this.localStorage.writeJSON("tool-popup-prefrences", value);
    }


    /**
     * Updates toolPopUpPrefrences stored in browser.
     * @param {String} toolName - Tool name to update
     * @param {Boolean} value - if set true, popup will not open again until reset.
     */
    handleUpdateToolPopupPrefrences = (toolName, value) =>
    {
        let toolPopupPrefrences = this.toolPopupPrefrences;

        if (toolPopupPrefrences)
        {
            toolPopupPrefrences[toolName] = value;
        }
        else
        {
            toolPopupPrefrences = { [toolName]: value };
        }

        this.toolPopupPrefrences = toolPopupPrefrences;
    }

    async getPropertyMapData(propertyId)
    {
        try
        {
            const data = await this.get(`/api/internal/entities/property/${propertyId}`, true);
            return data;
        }
        catch (err)
        {
            console.log("getPropertyEntities: ", err);
            throw err;
        }
    }

    async getBuildingMapData(buildingId)
    {
        try
        {
            const data = await this.get(`/api/internal/entities/building/${buildingId}`, true);
            return data;
        }
        catch (err)
        {
            console.log("getBuildingMapData: ", err);
            throw err;
        }
    }

    async getPropertyMapDataTopology(propertyId)
    {
        try
        {
            const data = await this.get(`/api/internal/entities/propertyTopology/${propertyId}`, true);
            return data;
        }
        catch (err)
        {
            console.log("getPropertyEntities: ", err);
            throw err;
        }
    }

    async getFloorMapData(buildingId, floorId)
    {
        try
        {
            const data = await this.get(`/api/internal/entities/floor/${floorId}?buildingId=${buildingId}`, true);
            return data;
        }
        catch (err)
        {
            console.log("getPropertyEntities: ", err);
            throw err;
        }
    }

    async getEntityLabelTemplates(longName, propertyType = 10)
    {
        try
        {
            const data = await this.get(`/api/internal/labels/search?propertyType=${propertyType}&longName=${longName}`, true);
            return data;
        }
        catch (err)
        {
            console.log("getEntityLabelTemplate", err);
            throw err;
        }
    }

    async updateEntityLabel(entityId, propertyId, buildingId, floorId, entityLabel, getRelatedStores = false)
    {
        try
        {
            if (buildingId)
            {
                return await this.post(`/api/internal/labels/entity/${entityId}?buildingId=${buildingId}&floorId=${floorId}&getRelatedStores=${getRelatedStores}`, entityLabel, true);
            }
            else
            {
                return await this.post(`/api/internal/labels/entity/${entityId}?propertyId=${propertyId}&getRelatedStores=${getRelatedStores}`, entityLabel, true);
            }
        }
        catch (err)
        {
            console.log("saveEntityLabel", err);
            throw err;
        }
    }


    async updateEntitesAndLabel(entityData)
    {
        try
        {
            return await this.post("/api/internal/entities/multiEntityUpdate", entityData, true);
        }
        catch (err)
        {
            console.log("saveEntityLabel", err);
            throw err;
        }
    }

    updateEntityIconImage(entityId, data)
    {
        return this.patch(`/api/internal/labels/${entityId}/updateEntityLabelIconImage`, data, true);
    }

    updateEntityCoverImages(entityId, data)
    {
        return this.patch(`/api/internal/labels/${entityId}/updateEntityLabelCoverImages`, data, true);
    }

    async vacantEntity(entityId, propertyId, buildingId)
    {
        try
        {
            return await this.post(`/api/internal/labels/entity/${entityId}/vacant?propertyId=${propertyId}`, undefined, true);
        }
        catch (err)
        {
            console.log("saveEntityLabel", err);
            throw err;
        }
    }

    async vacantTransferEntity(entityId, targetEntityId, propertyId)
    {
        try
        {
            return await this.post(`/api/internal/labels/entity/${entityId}/transferVacant?propertyId=${propertyId}&targetEntityId=${targetEntityId}`, undefined, true);
        }
        catch (err)
        {
            console.log("saveEntityLabel", err);
            throw err;
        }
    }

    /**
     * Updates entity style with data that contains one or more of the following:
     * textCoordinate, textRotation, imageExtent, imageRotation
     * @param {*} entityId
     * @param {*} data
     */
    async updateEntityStyle(entityId, data)
    {
        try
        {
            return this.put(`/api/internal/entities/${entityId}`, data, true);
        }
        catch (err)
        {
            console.log("Update text style ", data, err);
        }

        return false;
    }

    async deleteEntityTextStyle(entityId)
    {
        return this.updateEntityStyle(entityId, {
            textCoordinate: null,
            textRotation: null,
        });
    }

    async deleteEntityIconStyle(entityId)
    {
        return this.updateEntityStyle(entityId, {
            imageExtent: null,
            imageRotation: null
        });
    }

    /**
     *
     * @param {String} entityLabelId
     * @param {String} fileId
     * @param {Object} file
     * @param {Boolean} isCoverImage true for coverImage, false for logo
     * @param {Function} callback
     */
    uploadEntityLabelImage = (entityLabelId, fileId, filerId, isCoverImage, languageCode, callback) =>
    {
        if (typeof (entityLabelId) !== "string")
        {
            callback({ success: false });
        }

        try
        {
            let url = `/api/internal/labels/${entityLabelId}/image?languageCode=${languageCode}`;

            const imageType = isCoverImage ? "coverImage" : "iconImage";

            this.sendData(url, "POST", { filerId, imageType }, true, callback, true);
        }
        catch (err)
        {
            callback({ success: false });
            console.log("Failed to upload form data");
        }
    }

    /**
     * Reorder cover images
     * @param {String} entityLabelId
     * @param {Array.<String>} coverImages list of cover image fileIds
     */
    reorderEntityLabelCoverImages = async (entityLabelId, coverImages, languageCode) =>
    {
        try
        {
            const result = await this.post(`/api/internal/labels/${entityLabelId}/image/reorder?languageCode=${languageCode}`, { coverImages }, true);

            return result;
        }
        catch (err)
        {
            console.log("Failed to reorder cover image list", err);
        }

        return { success: false };
    }

    deleteEntityLabelImage = async (entityLabelId, filerId, languageCode = DEFAULT_LANGUAGE_CODE) =>
    {
        try
        {
            const result = await this.post(`/api/internal/labels/${entityLabelId}/image?delete=true&languageCode=${languageCode}`, { filerId }, true);

            return result;
        }
        catch (err)
        {
            console.log("Failed to delete image");
        }

        return { success: false };
    }

    /* Categories */

    
    async categoryKeywordsUpdate(categoryId, keywords, languageCode)
    {
        try
        {
            const { success, data } = await this.put(`/api/internal/categories/keywordsUpdate/${categoryId}`, { keywords, languageCode }, true);

            if (success)
            {
                return data;
            }
        }
        catch (err)
        {
            console.log("categoryKeywordsUpdate", err);
        }

        return [];
    }

    async getEntitesWithKeywords(propertyId)
    {
        try
        {
            const { success, entities } = await this.get(`/api/internal/entities/getEntitesWithKeywords?propertyId=${propertyId}`, true);
            if (success)
            {
                return entities;
            }
        }
        catch (err)
        {
            console.log("getEntitesWithKeywords", err);
        }

        return [];
    }

    async getCategories(propertyId)
    {
        try
        {
            const { success, data } = await this.get(`/api/internal/categories/property/${propertyId}`, true);

            if (success)
            {
                return data;
            }
        }
        catch (err)
        {
            console.log("getCategories", err);
        }

        return [];
    }

    getStyledIcons = async ({ kind, color, theme, searchText, page, limit }) =>
    {
        try
        {
            const { success, data } = await this.get(`/api/internal/styled-icons?color=${color}&theme=${theme}&page=${page}&limit=${limit}&searchText=${searchText}&kind=${kind}`, true);

            return (success) ? data : [];
        }
        catch (err)
        {
            console.log(err);
        }

        return [];
    }

    /**
     * Create or edit a category
     * @param {*} propertyId
     * @param {{name: string, subCategories: string[], _id?: string, iconId?: string, iconColour?: string, backgroundColour?: string, isRoot?: boolean}} categoryData
     */
    createCategory = async (propertyId, categoryData) =>
    {
        try
        {
            const { success, data, isCategoryDeleted } = await this.post(`/api/internal/categories/property/${propertyId}`, categoryData, true);
            return { success, data, isCategoryDeleted };
        }
        catch (err)
        {
            console.log("createCategory", err);
        }
    }

    copyOrMoveCategory = async (categoryId, newParentId, oldParentId, move=false) =>
    {
        try
        {
            const { success, message } = await this.post("/api/v1/categories/copy/category", { categoryId, newParentId, oldParentId, move }, true);
            return { success, message };
        }
        catch (err)
        {
            console.log("copy or move category", err);
        }
    }

    deleteCategory = async (categoryId) =>
    {
        try
        {
            const success = await this.delete(`/api/internal/categories/${categoryId}`, true);
            return success;
        }
        catch (err)
        {
            console.log("deleteCategory", err);
            return false;
        }
    }

    deleteBuildingEntity = async (buildingId, entityId) =>
    {
        try
        {
            const { success } = await this.delete(`/api/internal/entities/${buildingId}/${entityId}`, true);

            return success;
        }
        catch (err)
        {
            console.log("deleteBuildingEntity", err);
        }

    }

    createBuildingEntity = async (buildingId, entity) =>
    {
        try
        {
            const { success, data } = await this.post(`/api/internal/entities/create/${buildingId}`, entity, true);

            return { success, data };
        }
        catch (err)
        {
            console.log("createBuildingEntity", err);
        }
    }

    publishProperty = async (propertyId, db) =>
    {
        try
        {
            console.log("hit");
            const result = await this.post(`/api/internal/navigation/publish/4.7/entireProperty/saveAndPublish/${propertyId}?db=${db}`, {}, true);
            return result;
        }
        catch (err)
        {
            console.log("publishProperty", err);
            return { success: false };
        }

    }

    schedulePublish = (propertyId, date) => this.post(`/api/internal/navigation/publish/schedule/property/${propertyId}`, { date }, true)

    updateBrandingStatus = async (propertyId, brandingStatus) =>
    {
        try
        {
            const result = await this.put(`/api/internal/properties/${propertyId}/brandingStatus`, { brandingStatus }, true);

            return result;
        }
        catch (err)
        {
            console.log("updateBrandingStatus", err);
        }
    }

    getBrandingStatus = (propertyId) => this.get(`/api/internal/properties/${propertyId}/brandingStatus`, true)
        .then((result) => result.brandingStatus)

    changeMobileSettings = async (buildingId, mobileSettings) =>
    {
        try
        {
            const result = await this.put(`/api/internal/buildings/${buildingId}/mobileSettings`, { mobileSettings }, true);
            return result;
        }
        catch (err)
        {
            console.log("changeMobileSettings", err);
        }
    }

    getStoresFromCategories = async (categories) =>
    {
        try
        {
            const result = await this.post("/api/internal/categories/entityLabels", { categories }, true);

            return result;
        }
        catch (err)
        {
            console.log("getStoresFromCategories", err);
        }
    }

    accessGroupsGet = (companyUID) => this.get(`/api/internal/branding/accessGroups/${companyUID}`, true).catch((err) => console.log(err));

    accessGroupCreate = (groupObj) =>
    {
        const user = {
            brandName: serverApi?.userData?.user?.userCompanyInfo?.name,
            firstName: serverApi?.userData?.user?.userInfo?.firstName,
            lastName: serverApi?.userData?.user?.userInfo?.lastName,
            phone: serverApi?.userData?.user?.userInfo?.phoneNumber,
            email: serverApi?.userData?.user?.userInfo?.email,
            companyUID: serverApi?.userData?.user?.userCompanyInfo?.companyUID
        };

        return this.post("/api/internal/branding/accessGroup", { ...groupObj, user }, true).catch((err) => console.log(err));
    };

    accessGroupUpdate = (groupId, patch) => this.patch(`/api/internal/branding/accessGroup/${groupId}`, patch, true)

    accessGroupsDelete = (groupIds) => this.deleteMultiple("/api/internal/branding/accessGroups", { groupIds }, true).catch((err) => console.log(err));

    getAllProperties = () => this.get("/api/internal/properties?buildings=true", true).catch((err) => console.log(err));

    getEntitiesUsingPropertyIds = (propertyIdsArray) => this.post("/api/internal/branding/entityData", { propertyIds: propertyIdsArray }, true).catch((err) => console.log(err));

    getEntitiesUsingFloorIds = (floorIdsArray) => this.post("/api/internal/branding/floorsEntityData", { floorIds: floorIdsArray }, true).catch((err) => console.log(err));

    /**
     * @param {Object} inventoryBody inventory to create
     * @param {String} inventoryBody.entityLabelId entity label id for the inventory
     * @param {Object} inventoryBody.name
     * @param {String} inventoryBody.code
     * @param {Number} inventoryBody.quantity
     * @param {Object} inventoryBody.link
     * @returns {Promise<any>} created inventory
     */
    createInventory = (inventoryBody) => this.post("/api/internal/inventory/create", inventoryBody, true).catch((err) => console.log(err));

    /**
     * @param {String} inventoryId inventory to update
     * @param {Object} inventoryBody inventory body
     * @param {String} inventoryBody.entityLabelId entity label id for the inventory
     * @param {Object} inventoryBody.name
     * @param {String} inventoryBody.code
     * @param {Number} inventoryBody.quantity
     * @param {Object} inventoryBody.link
     * @returns {Promise<any>}
     */
    updateInventory = (inventoryId, inventoryBody) => this.put(`/api/internal/inventory/${inventoryId}`, inventoryBody, true).catch((err) => console.log(err));

    /**
    * @param {String} inventoryId inventory to delete
    * @returns {Promise<any>}
    */
    deleteInventory = (inventoryId) => this.delete(`/api/internal/inventory/${inventoryId}`, true).catch((err) => console.log(err));

    /**
     * Deletes the whole inventory of an entityLabel
     * @param {string} entityLabelId
     * @returns {Promise<any>}
     */
    deleteEntityLabelInventory = (entityLabelId) => this.delete(`/api/internal/inventory/bulk/entityLabelId/${entityLabelId}`, true)
        .catch((err) => console.log(err));

    /**
     * @param {Object} searchQuery search object
     * @param {String} searchQuery.entityLabelId entity label id for the inventory
     * @param {String} searchQuery.name
     * @param {String} searchQuery.code
     * @param {String} searchQuery._id
     * @returns {Promise<any>}
     */
    findInventories = (searchQuery) => this.get(`/api/internal/inventory/getInventories?${serializeQuery(searchQuery)}`, true).catch((err) => console.log(err));


    /**
     * @param {Object} formData
     * @param {String} formData.entityLabelId
     * @param {File} formData.file
     * @returns {Promise<any>}
     */
    uploadCSVFile = (formData) => this.sendFile("/api/internal/inventory/upload", formData, true)
        .then((res) => res.success ? res : Promise.reject(res));

    exportCategoryTree = (propertyIds, categoryTree) => this.post("/api/internal/categories/exportCategoryTree", { propertyIds, categoryTree }, true).catch((err) => console.log(err));
   
    copyTemplate = (body) => this.post("/api/internal/overlayTemplates/copyTemplate", body, true).catch((err) => console.log(err));

    uploadListViewFile = ({ formData, propertyId, countryCode = "CA", buildingId }) => this.sendFile(`/api/internal/labels/entityFileUpload?countryCode=${countryCode}&propertyId=${propertyId}&buildingId=${buildingId}`, formData, true)
        .then((res) => res.success ? res : Promise.reject(res))
        .catch((err) => Promise.reject(err));

    uploadGroupsCSVFile = ({ formData, propertyId, lang = "en" }) => this.sendFile(`/api/internal/categories/uploadCategoriesCsv?lang=${lang}&propertyId=${propertyId}`, formData, true)
        .then((res) => res.success ? res : Promise.reject(res))
        .catch((err) => Promise.reject(err));

    copyVenueInfo = (entityLabelId, relatedStores) => this.post(`/api/internal/labels/copyVenueInfo/${entityLabelId}`, relatedStores, true)
        .then((res) => res.success ? res : Promise.reject(res))
        .catch((err) => Promise.reject(err));
}

export default new BrandingApi();
