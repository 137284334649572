import React from "react";
import { Modal, Button } from "semantic-ui-react";

export const ModalBox = ({
    scrolling = undefined,
    className = "",
    actions,
    children,
    onClose,
    header,
    ...rest
}) => (
    <Modal
        closeIcon={<Button className="close" onClick={onClose} />}
        closeOnEscape={false}
        className={`modalBox ${className}`}
        onClose={onClose}
        {...rest}
    >
        {(header) && <Modal.Header>{header}</Modal.Header>}
        <Modal.Content scrolling={scrolling}>
            {children}
        </Modal.Content>
        {(actions) && <Modal.Actions>{actions}</Modal.Actions>}
    </Modal>
);
