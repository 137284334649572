import React, { useState } from "react";
import { ModalBox } from "../../../common/ModalBox";
import { ButtonIcon } from "../../../common/ButtonIcon";
import { Button } from "semantic-ui-react";
import { Trans, useTranslation } from "react-i18next";
import { DEFAULT_LANGUAGE_CODE } from "mapsted.maps/utils/map.constants";
import { truncateText } from "../../../../_utils/mapUtils";
import {  Popup } from "semantic-ui-react";

const DeleteConfirmationTemplateGroupModal = ({
    onDelete, templateGroupItem, onTrigger = () => 
    {},  disabled = false, templateToBeDeleted=false
}) => 
{
    const { t: trans } = useTranslation();
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
 
    const handleDeleteClick = async () => 
    {
        await onDelete(templateGroupItem._id);
        setDeleteModalOpen(false);
    };

    return (
        <ModalBox
            className="confirmActionModal template-group-delete-modal"
            trigger={
                <div className="action-item">
                    {disabled ? (
                        <Popup
                            content={trans("OverlaysTemplateSideBar.Cannot_Delete_Message")}
                            basic
                            trigger={<span><ButtonIcon icon="delete" disabled /></span>} />
                    ) : (
                        <ButtonIcon
                            icon="delete"
                            onClick={() => 
                            {
                                setDeleteModalOpen(true);
                                onTrigger();
                            }}
                            disabled={disabled}
                        />
                    )}
                </div>
            }
            header={
                <div style={{ margin: "auto" }} >
                    <div className="delete-icon-container">
                        <img width="40px" height="40px" src="/img/icon-delete.svg" alt="Delete Templates"  />
                    </div>
                    <h2 className="modal-header-text">{trans("OverlaysTemplateSideBar.Delete_Confirm_Modal_Header", {
                        overlayItem: templateToBeDeleted? `${trans("OverlaysTemplateSideBar.Template")}` : `${trans("OverlaysTemplateSideBar.Group")}`
                    })}</h2>
                </div>
            }
            open={deleteModalOpen}
            onClose={() => setDeleteModalOpen(false)}
            actions={
                <Button
                    color="orange"
                    content={trans("OverlaysTemplateSideBar.Delete")}
                    onClick={handleDeleteClick}
                />
            }
        >
            <p className="p-modal p-modal-text deleteModal-text">
                <Trans 
                    i18nKey="OverlaysTemplateSideBar.Delete_Confirmation_Message"
                    values={{ overlayTemplateGroupName: truncateText(templateGroupItem.name[DEFAULT_LANGUAGE_CODE], 30) }}
                />
            </p>
        </ModalBox>
    );
};

export default DeleteConfirmationTemplateGroupModal;
