/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-state */

import React from "react";
import { v4 as uuid } from "uuid";
import AddPropertyContext from "./AddPropertyContext";
import serverAPI from "../_api/server.api";
import { toDropDownList } from "../_utils/utils";
import { Loader } from "../components/elements/loader";
import { STATUS_DESCRIPTION } from "../_constants/constants";
import DashboardContext from "./DashboardContext";
import { deepValue } from "mapsted.utils/objects";
import { withConfig } from "../_utils/hoc";

const STATE_RESET = {

    property: { name: "", fullAddress: "", type: 0, website: "", phoneNumbers: "", floorPlan: undefined, centroid: undefined, addressComponents: undefined },
    building: { longName: "", fullAddress: "", type: 0, website: "", phoneNumbers: "", centroid: undefined, addressComponents: undefined },
    floors: [],
    propertyId: undefined,
    buildingId: undefined,
    propertyLoaded: false,
    buildingLoaded: false,
    floorsLoaded: false,

    // Tells us if we should load the users unsaved floors. Used when going back from organize floor plans.
    loadUnsavedFloors: false,

    // Tells us which page we are starting from. 
    isPropertyEdit: false,
    isBuildingEdit: false,
    isFloorPlansEdit: false,

    // Used for loading bar.
    numFloorsToUpload: 0,
    numFloorsUploaded: 0,

    loadingAnimation: true,
}

class AddPropertyProvider extends React.Component 
{
    static contextType = DashboardContext;

    constructor(props)
    {
        super(props);

        this.state = STATE_RESET;
    }

    componentDidMount = async () =>
    {
        this.setState({ loading: true }, async () =>
        {
            const propertyTypesResult = await serverAPI.getTypes("properties");
            const buildingTypesResult = await serverAPI.getTypes("buildings");

            const propertyTypes = toDropDownList({ data: propertyTypesResult.data, value: "type", text: "name" });
            const buildingTypes = toDropDownList({ data: buildingTypesResult.data, value: "type", text: "name" });
            this.setState({ propertyTypes, buildingTypes, loading: false });
        })
    }

    /**
     * When called, sends user to dashboard.
     */
    routeToDashboard = () =>
    {
        let url = window.location.href;
        url = new URL(url);
        window.location.href = url.origin;
    }

    /**
     * Used when a component needs to update the state.
     */
    handleUpdateState = (update) =>
    {
        this.setState(update);
    }


    /**
     * Saves the property in DB.
     * @param {Object} property - Property object to be saved in DB
     * @param {Boolean} waitUntilSaved - keep loading until saved
     * @param {Function} callback - Callback function to be called after creation. Passes true if recieved status 200, false otherwise. 
     */
    saveProperty = (property, waitUntilSaved = false, callBack) =>
    {
        // Set propertyLoaded = true, this allows us to access the building page without redirect.
        // Set loading: isDraft, if isDraft we want to wait to load before redirecting to dashboard to avoid memory leak.
        this.setState({ property, propertyLoaded: true, loading: waitUntilSaved }, () =>
        {
            // In the case of creating a property and navigating back to edit property, assign Id to edit property.
            if (this.state.propertyId)
            {
                property._id = this.state.propertyId;
            }
            else
            {
                property.status = this.props.config.STATUSES.DRAFT;
            }

            // Calls API to save property in DB.
            serverAPI.saveProperty(property, propertyResult =>
            {
                // If property saved successfully in DB.
                if (propertyResult && propertyResult.success)
                {
                    // If new property, update the URL to with the param ?propertyId={propertyId}
                    if (!this.state.propertyId)
                    {
                        this.updateUrl(`propertyId=${propertyResult.data._id}`);
                    }

                    // Update state with new property
                    this.setState({ propertyId: propertyResult.data._id, property, loading: false });

                    // Updates dashboardContext
                    this.context.handleAddProperty(propertyResult.data);

                    // In the case of saving as draft, set loading back to false and call the callBack function.
                    callBack && callBack(true);
                }
                else
                {
                    this.setState({ loading: false, })
                    callBack && callBack(false);
                }
            });
        });
    }

    /**
     * Saves the building in DB.
     * @param {Object} building - Building object to be saved.
     * @param {Boolean} waitUntilSaved - keep loading until saved
     * @param {Function} callBack - Callback function to be called after creation. Passes true if recieved status 200, false otherwise. 
     */
    saveBuilding = async (building, waitUntilSaved = false, callBack) =>
    {
        // Set buildingLoaded = true, this allows us to access the floorplans page without redirect.
        // Set loading: isDraft, if isDraft we want to wait to load before redirecting to dashboard to avoid memory leak.
        this.setState({ building, buildingLoaded: true, loading: waitUntilSaved }, async () =>
        {
            // In the case of creating a building and navigating back to building, assign Id to edit building.
            if (this.state.buildingId)
            {
                building._id = this.state.buildingId
            }
            else
            {
                building.status = this.props.config.STATUSES.DRAFT;
            }

            // create building API call.
            const buildingResult = await serverAPI.saveBuilding(building, this.state.propertyId);

            // If building created.
            if (buildingResult && buildingResult.success === true)
            {
                // Update the floorplans url if buildingId is not already in state.
                if (!this.state.buildingId) 
                {
                    this.updateUrl(`propertyId=${this.state.propertyId}&buildingId=${buildingResult.data._id}`);
                }

                this.setState({ buildingId: buildingResult.data._id, building, loading: false });

                // Update building in dashboard provider.
                this.context.handleAddBuilding(this.state.propertyId, buildingResult.data);


                // In the case of draft, set loading false and call the callBack function
                callBack && callBack(true);
            }
            else
            {
                this.setState({ loading: false })
                callBack && callBack(false);
            }
        });
    }

    /**
     * Saves each floor in floor list to DB.
     * @param {Object} floors - Floors object to be saved.
     * @param {Boolean} isDraft - Whether or not the floors should be saved as draft.
     */
    saveFloors = async (floors, isDraft = false) =>
    {
        // Set loading true, prevents user from going to dashboard before floors are created.
        this.setState({ loading: true, loadingAnimation: false }, async () =>
        {
            // Creates a floor promise list and creats a promise for each floor creation API call.
            let floorsPromise = [];

            const buildingId = this.state.buildingId;

            if (Array.isArray(floors))
            {
                this.setState({ numFloorsToUpload: floors.length });

                let operationId = uuid();

                let emailDataArray = [];

                floors.forEach((floor) =>
                {
                    floor.draft = isDraft;
                    const floorProimse = new Promise((resolve) =>
                    {
                        serverAPI.saveFloor(floor, buildingId, operationId, (result) =>
                        // API Callback When Floor Is Uploaded
                        {
                            this.setState({ numFloorsUploaded: this.state.numFloorsUploaded + 1 });

                            // If a new floor is created it will return email data aswell
                            // save email data to list to be sent to email controller
                            (result?.emailData) && emailDataArray.push(result.emailData);

                            resolve(result.data);
                        });
                    });

                    floorsPromise.push(floorProimse);
                });

                // wait for all floors to finish uploading then set loading to false.
                const floorResults = await Promise.all(floorsPromise);

                // prep user data for email
                const userInfo = serverAPI.userData;

                let userData = {
                    companyName: deepValue(userInfo, "user.userCompanyInfo.name", "N/A"),
                    userFullName: `${deepValue(userInfo, "user.userInfo.firstName", "")} ${deepValue(userInfo, "user.userInfo.lastName", "")}`,
                    userEmail: deepValue(userInfo, "user.userInfo.email", "N/A")
                }

                // send emails
                await serverAPI.sendNewFloorEmail({
                    propertyId: this.state.propertyId,
                    buildingId: this.state.buildingId,
                    propertyTypes: this.state.propertyTypes,
                    buildingTypes: this.state.buildingTypes,
                    emailDataArray,
                    userData
                });

                const floorIds = floorResults.map((f) => f._id);

                serverAPI.finishUploadFloors(buildingId, operationId, floorIds);

                this.context.handleAddFloors(this.state.propertyId, this.state.buildingId);

                this.setState({
                    loading: false,
                    loadingAnimation: true,
                    numFloorsToUpload: 0,
                    numFloorsUploaded: 0
                });
            }
        })
    }

    /**
     * Updates the url without refreshing state.
     * @param {String} param - a String of params in form "value=param&value2=param2..."
     */
    updateUrl = (param) =>
    {
        var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + `?${param}`;
        window.history.pushState({ path: newurl }, "", newurl)
    }

    /**
     * Fetches the property from DB.
     * If isPropertyEdit, property has to be draft, otherwise route to dashboard.
     * If isBuildingEdit, building has to be draft, otherwise rote to dashboard.
     * @param {Object}
     * @param {String} propertyId - property unique ID
     * @param {String} buildingId - building unique ID
     * @param {String} isPropertyEdit - Whether or not the user is starting on property screen.
     * @param {String} isBuildingEdit - Whether or not the user is starting on the building screen.
     * @param {String} isFloorPlansEdit - Whether or not the user is starting on the floorplans screen.
     * 
     * 
     * @param {Function} callBack - Calls function when completed fetch with {property, building, floors}
     * 
     * @returns {Object} {property, building, floors}
     */
    getProperty = async ({ propertyId, buildingId, isPropertyEdit, isBuildingEdit, isFloorPlansEdit }, callBack) =>
    {
        // Get Property from dashboard provider
        const properties = { ...this.context.state.properties };
        let property = undefined;

        if (properties && Object.keys(properties).length > 0)
        {
            property = properties[propertyId];
        }
        else 
        {
            const propertyData = await serverAPI.getProperty(propertyId);
            property = propertyData.data;
        }

        let building = undefined;
        let floors = [];

        // If success and (property is draft OR we are not in property screen) continue
        // We make this additional check because property can not be edited in AddProperty page unless its in draft.
        if (property)
        {
            // TODO: If property is draft and user trys to edit building/floorplans route to dashboard.
            // User can not create a building before property is fully created.

            // If trying to access property when property is not draft, route to dashboard.
            if (!STATUS_DESCRIPTION[property.status].incomplete && isPropertyEdit)
            {
                this.routeToDashboard();
            }

            // If we were given a building ID, get building data.
            if (buildingId)
            {
                // Get building data from the property object.
                building = property.buildings[buildingId];

                floors = building.floors;

                // If building not found.
                if (!building)
                {
                    this.routeToDashboard();
                }
                // If building is not draft and trying to access edit building.
                else if (!STATUS_DESCRIPTION[building.status].incomplete && isBuildingEdit) 
                {
                    this.routeToDashboard();
                };

                building =
                {
                    longName: building.longName,
                    fullAddress: building.fullAddress,
                    type: building.type || 0,
                    website: building.website,
                    phoneNumbers: building.phoneNumbers && building.phoneNumbers[0],
                    centroid: building.centroid,
                    addressComponents: building.addressComponents,
                }
            }

            this.setState({
                property,
                building,
                floors,
                propertyId,
                buildingId,
                propertyLoaded: !!property,
                buildingLoaded: !!building,
                floorsLoaded: !!floors,
                isPropertyEdit,
                isBuildingEdit,
                isFloorPlansEdit,
            });

            callBack({ property, building, floors });
        }
        else
        {
            this.routeToDashboard();
        }
    }

    /**
     * Gets building from list of buildings.
     * @param {Array} buildingList - List of buildings, assumed to have id saved in buildingList[i]._id.
     * @param {String} buildingId - building unique ID.
     */
    getBuilding = (buildingList, buildingId) =>
    {
        for (let i = 0; i < buildingList.length; i++)
        {
            if (buildingList[i]._id === buildingId)
            {
                return buildingList[i];
            }
        }
        this.routeToDashboard();
    }

    /**
     * Used when clicking 'back button' from organize floor plans.
     * Sets bool 'loadUnsavedFloors' and then calls the given callback.
     *
     * When navigating back, set to true then call 'go back' function.
     * When loading floors, set back to false so that you cant load unsaved floors from dashboard, then call 'handle floors init' function.
     * @param {Boolean} loadUnsavedFloors - Whether or not we want to load unsaved floors on next floorPlansAdd page init.
     * @param {Function} callback - Call back function to call after setting state. 
     */
    handleLoadUnsavedFloorPlans = (loadUnsavedFloors, callback) =>
    {
        this.setState({ loadUnsavedFloors }, callback);
    }

    resetState = (callBack) =>
    {
        this.setState(STATE_RESET, callBack);
    }

    render ()
    {
        const value =
        {
            state: this.state,
            handleUpdateState: this.handleUpdateState,
            saveProperty: this.saveProperty,
            saveBuilding: this.saveBuilding,
            saveFloors: this.saveFloors,
            handleSave: this.handleSave,
            getProperty: this.getProperty,
            getBuilding: this.getBuilding,
            resetState: this.resetState,
            loadUnsavedFloorPlans: this.handleLoadUnsavedFloorPlans
        }

        return (
            <AddPropertyContext.Provider value={value}>
                <Loader active={this.state.loading} animation={this.state.loadingAnimation} />
                {this.props.children}
            </AddPropertyContext.Provider>
        );
    }
}

export default withConfig(AddPropertyProvider);