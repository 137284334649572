
const { webAPI } = require("./server.api");

class MapEditorApi extends webAPI
{
    async getPropertyTopologyMapData(propertyId, localObjects)
    {
        try
        {
            const response = await this.post(`/api/internal/mapEditor/entities/propertyTopology/${propertyId}`, { localObjects }, true);
            return response;
        }
        catch (err)
        {
            console.log("getPropertyEntities: ", err);
            throw err;
        }
    }

    async getFloorTopologyMapData(buildingId, floorId, localObjects)
    {
        try
        {
            const response = await this.post(`/api/internal/mapEditor/entities/floorTopology/${floorId}?buildingId=${buildingId}`, { localObjects }, true);
            return response;
        }
        catch (err)
        {
            console.log("getPropertyEntities: ", err);
            throw err;
        }
    }

    async getUserLock()
    {
        try
        {
            const response = await this.get("/api/internal/mapEditLock/userLock", true);
            return response;
        }
        catch (err)
        {
            console.log("getUserLock", err);
            throw err;
        }
    }

    async createLock(lockData)
    {
        try
        {
            const response = await this.post("/api/internal/mapEditLock/createLock", lockData, true);
            return response;
        }
        catch (err)
        {
            console.log("create lock", err);
            throw err;
        }
    }

    async createEntirePropertyLock(lockData)
    {
        try
        {
            const response = await this.post("/api/v1/mapEditLock/createEntirePropertyLock", lockData, true);
            return response;
        }
        catch (err)
        {
            console.log("create Entire Property Lock", err);
            throw err;
        }
    }

    async deleteLock(lockId)
    {
        try
        {
            const response = await this.delete(`/api/internal/mapEditLock/deleteLock/${lockId}`, true);
            return response;
        }
        catch (err)
        {
            console.log("delete lock", err);
            throw err;
        }
    }

    async deletePropertyLock(propertyId)
    {
        try
        {
            const response = await this.delete(`/api/v1/mapEditLock/deletePropertyLock/${propertyId}`, true);
            return response;
        }
        catch (err)
        {
            console.log("delete property lock", err);
            throw err;
        }
    }

    async getCompanyLocks()
    {
        try
        {
            const response = await this.get("/api/internal/mapEditLock/companyLocks", true);
            return response;
        }
        catch (err)
        {
            console.log("get company locks", err);
            throw err;
        }
    }

    async getPropertyTransitions(propertyId)
    {
        try
        {
            const response = await this.get(`/api/internal/transitions/property/${propertyId}`, true);
            return response;
        }
        catch (err)
        {
            console.log("get property transitions", err);
            throw err;
        }
    }

    async saveLocalTransitions(propertyId, transitions)
    {
        try
        {
            const response = await this.post(`/api/internal/transitions/save/${propertyId}`, { transitions }, true);
        }
        catch (err)
        {
            console.log("save local transitions", err);
            throw err;
        }
    }

    async saveLocalNodes(propertyId, buildingId, floorId, nodes)
    {
        try
        {
            const response = await this.post(`/api/internal/nodes/save/${propertyId}`, { buildingId, floorId, nodes }, true);
            return response;
        }
        catch (err)
        {
            console.log("save local nodes", err);
            throw err;
        }
    }

    async saveLocalEntities(propertyId, buildingId, floorId, entities)
    {
        try
        {
            const response = await this.post(`/api/internal/mapEditor/entities/save/${propertyId}`, { buildingId, floorId, entities }, true);
            return response;
        }
        catch (err)
        {
            console.log("save local nodes", err);
            throw err;
        }
    }

    async mapEditPermission()
    {
        return this.get("/api/internal/mapEditor/permissions", true);
    }


    updateFloorPlanGeoReferncingForFloor(cmsFloorId, data)
    {
        return this.post(`/api/internal/floors/floorPlanGeoReferencing/${cmsFloorId}?isDeleteOldReferences=true`, data, true);
    }

    updatePropertyFloorPlanGeoReferncing(cmsPropertyId, data)
    {
        return this.post(`/api/internal/properties/FloorPlanGeoReferencing/${cmsPropertyId}?isDeleteOldReferences=true`, data, true);
    }
    saveLocalFloorPlanGeoReferncing(propertyId, floorCmsId, data)
    {
        if (floorCmsId)
        {
            return this.updateFloorPlanGeoReferncingForFloor(floorCmsId, data);
        }

        if (propertyId)
        {
            return this.updatePropertyFloorPlanGeoReferncing(propertyId, data);
        }

        return new Promise((resolve, reject) => reject("propertyId or floorCmsId is missing"));

    }

    async getAllEntryExitEntitiesAndNodesTopology(cmsPropertyId)
    {
        return this.get(`/api/internal/mapEditor/entryExit-nodesAndEntities/${cmsPropertyId}`, true);
    }

}

export default new MapEditorApi();
