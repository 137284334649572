import React from "react";
import { Popup, Button, Header, Loader } from "semantic-ui-react";
import "./StatusProcessingPopup.css";
import { STATUS_DESCRIPTION } from "../../_constants/constants";
import { useTranslation } from "react-i18next";

export const StatusProcessingPopup = ({ status }) =>
{
    const trans = useTranslation().t; 

    const inputStatus = Object.values(STATUS_DESCRIPTION).find((statusEntry) => statusEntry.statusName === status);

    return (
        <Popup className="popupBox statusProcessingPopup"
            on="click"
            trigger={<Button className="statusButtonTrigger">
                <div className={"status " + getStatusClassName(status)} style={{ marginLeft: "6px" }}>
                    {!!inputStatus && trans(inputStatus.i18nKey)}
                </div>
                <img src="/img/icon-chevron-down.svg" alt="expand" />
            </Button>}>
            <Header content={trans("constants.Status_Progress")} />
            <ProcessingStatus status={status} />
        </Popup>
    );
};

const getStatusClassName = (status) => Object.values(STATUS_DESCRIPTION).filter((statusDescription) => status === statusDescription.statusName)[0].className;

const ProcessingStatus = ({ status }) =>
{
    const trans = useTranslation().t; 

    let displayIsDone = true;
    return (
        <div className="processingStatus">
            {Object.values(STATUS_DESCRIPTION).map((statusDescription) =>
            {
                if (status === statusDescription.statusName)
                {
                    displayIsDone = false;
                }
                return <ProcessingStatusItem 
                    key={statusDescription.code} 
                    content={trans(statusDescription.i18nKey)} 
                    isDone={displayIsDone} 
                    isProcessing={status === statusDescription.statusName} 
                />;
            })}
        </div>
    );
};

const ProcessingStatusItem = ({ isDone, isProcessing, content }) => (
    <p className={`${isDone ? "isCompleted":""}${isProcessing ? "isProcessing":""}`}>
        <span></span>
        {(isProcessing) && <Loader indeterminate active inline size="small" />}
        {content}
    </p>
);
