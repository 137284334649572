import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Portal, Header } from "semantic-ui-react";
import './ChangeAddressPortal.css';

const ChangeAddressPortal = ({ open, onUndo, onConfirm }) =>
{
    const trans = useTranslation().t;
    return (
        <Portal open={open}>
            <div className="changeAddressPopup">
                <Header>
                    <img alt="" src="/img/icon-warning.svg" />
                    {trans("ChangeAddressPortal.Info__Address_Changed")}
                </Header>
                <Button content={trans("ChangeAddressPortal.Undo")} color="grey" onClick={onUndo} />
                <Button content={trans("ChangeAddressPortal.Confirm")} color="orange" onClick={onConfirm} />
            </div>
        </Portal>
    )
}

export default ChangeAddressPortal;