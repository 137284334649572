import {
    Modal,
    Button,
    Header,
    Icon,
    Image
} from "semantic-ui-react";
import React, { useEffect } from "react";
import { useSetState } from "ahooks";
import serverApi from "../../../../_api/server.api";
import BuildingSelector from "./BuildingSelector";
import AuthScreen from "./AuthScreen";
import { useTranslation } from "react-i18next";

import "./ArchibusPropertyImporter.css";

export const ArchibusPropertyImporter = () =>
{
    const [state, setState] = useSetState({
        screen: "AUTH",
        open: false,
        loading: false,
        instanceURL: "",
        buildings: []
    });
    const trans = useTranslation().t;

    useEffect(() =>
    {
        if (state.open)
        {
            setState({ screen: "AUTH" });
            verifyUserAndLoadBuildings();
        }
    }, [state.open]);

    const renderContactAdmin = () => (
        <div className="aligner archibusInactive">
            <Header as="h2" icon textAlign="center">
                <Icon color={"black"} name="lock" circular />
                <Header.Content>{trans("Archibus_Integration.Archibus Integration Inactive")}</Header.Content>
            </Header>
            <p className="p-modal">{trans("Archibus_Integration.Your company has not activated this integration. Please contact your company admin.")}</p>
            <Button color="grey" onClick={() => setState({ open: false, screen: "AUTH" })}>{trans("Archibus_Integration.Go Back")}</Button>
        </div>);

    const verifyUserAndLoadBuildings = async () =>
    {
        setState({ loading: true });

        // check if user is admin
        const isAdmin = await checkIfAdmin();
        
        // if user is admin get token
        if (isAdmin)
        {
            const tokenDetails = await getToken();
            
            // if token retrieval is successful load builidngs
            if (tokenDetails.status === "CREDENTIALS_FOUND")
            {
                const { buildings } = await getBuildingList(tokenDetails.instanceURL);

                setState({ buildings: buildings || [], screen: "SELECT_BUILDINGS", instanceURL: tokenDetails.instanceURL });
            }
            else
            {
                // If credentials are not found redirect to login screen
                setState({ screen: "AUTH" });
            }
        }
        else
        {
            // If not admin redirect to "Contact Admin" screen
            setState({ screen: "CONTACT_ADMIN" });
        }
        setState({ loading: false });
    };

    const checkIfAdmin = async () => serverApi.getQuery("/api/internal/integrations/isCompanyAdmin", true);

    const getToken = async () =>
    {
        const resp = await serverApi.getQuery(`/api/internal/integrations/archibusToken?token=${serverApi.archibusToken ? JSON.stringify(serverApi.archibusToken): "{}"}`, true);
        if (resp.newToken !== undefined)
        {
            serverApi.archibusToken = resp.newToken;
        }
        return resp;
    };

    const handleAuth = () =>
    {
        verifyUserAndLoadBuildings();
    };

    const getBuildingList = async (instanceURL) => await serverApi.post(`/api/internal/integrations/archibusBuildings?instanceURL=${instanceURL}`, serverApi.archibusToken, true);

    const renderIntegrationCompleted = () => 
    {
        const trans = useTranslation().t;
        return <div className="aligner archibusModalFinish">
            <Header as="h2" icon textAlign="center">
                <Image className="archibusFinishIcon" as="span" src="/img/icon-success-circle.svg" />
                <Header.Content>{trans("Archibus_Integration.Integration Completed!")}</Header.Content>
            </Header>
            <p>{trans("Archibus_Integration.Check the dashboard to see your new properties.")}</p>
            <Button color="grey" onClick={() => setState({ open: false, screen: "AUTH" })}>{trans("Archibus_Integration.Done")}</Button>
        </div>;
    };

    const renderAuth = () => (
        <AuthScreen 
            isLoading={state.loading}
            onAuth={handleAuth}
            setLoading={(loading) => setState({ loading })} />
    );

    const renderSelectBuildings = () => (
        <BuildingSelector
            isLoading={state.loading}
            buildings={state.buildings}
            updateParentState={setState}
            instanceUrl={state.instanceURL}
        />
    );
    
    const renderSwitch = () =>
    {
        switch (state.screen)
        {
        case "AUTH":
            return renderAuth();
        case "CONTACT_ADMIN":
            return renderContactAdmin();
        case "SELECT_BUILDINGS":
            return renderSelectBuildings();
        case "COMPLETED":
            return renderIntegrationCompleted();
        default:
            return renderContactAdmin();
        }
    };

    const renderTrigger = () => (
        <div className="archibus-trigger" onClick={() => setState({ open: true })}>
            <img src="/img/archibus-logo.png" alt="" />
            <p>{trans("Archibus_Integration.Integrate with Archibus")}</p> 
        </div>
    );

    return <Modal
        open={state.open}
        closeIcon={<Button className="close" onClick={() => setState({ open: false })} />}
        // trigger={<Button onClick={() => setState({ open: true })}>Archibus</Button>}
        trigger={renderTrigger()}
        size="tiny">
        { renderSwitch() }
    </Modal>;
};