import React, { forwardRef, useEffect, useRef, useState } from "react";
import "./InfiniteScrollGrid.css";
import { useInView } from "react-intersection-observer";
import ImageSkeleton from "../ImagesManagement/ImagesManagementMain/ImageSkeleton/ImageSkeleton";

function InfiniteScrollGrid(props) 
{
    const {
        hasNextPage,
        isLoading,
        data,
        cellToRender,
        isFetchingNextPage,
        fetchNextPage,
        className,
        skeletonCount = 25,
        isSuccess,
        noItemsFallback = "No data",
    } = props;
    const { ref: triggerRef, inView } = useInView({
        threshold: 0,
    });

    useEffect(() => 
    {
        if (inView && hasNextPage && !isFetchingNextPage) 
        {
            fetchNextPage();
        }
    }, [inView, hasNextPage, isFetchingNextPage]);

    if (isSuccess && !data.length)
    {
        return noItemsFallback;
    }

    const items = [...(data || []), ...(hasNextPage ? ["trigger-elem"]: []), ...((isLoading || hasNextPage) ? Array(skeletonCount).fill("") : [])];

    return (
        <div className={`infinite-scroll-grid ${className || ""}`}>
            {items.map((item, index) => 
            {
                if (item === "trigger-elem")
                {
                    return <ImageSkeleton   
                        key={item}
                        ref={triggerRef}
                        className="infinite-scroll-next-set-trigger"
                    ></ImageSkeleton>;  
                }
                else if (items[index])
                {
                    return  cellToRender(item);
                }
                else
                {
                    return  <ImageSkeleton key={index} />;
                }
            })}
        </div>
    );
}

export default InfiniteScrollGrid;
