import React from "react";
import "./ImageDeleteStrategySelector.css";
import { Radio } from "semantic-ui-react";
import { DELETE_IMAGE_STRATEGY_ACTIONS } from "../../../constant";
import { useImageDeleteContext } from "../../contexts/ImageDeleteContext";

function ImageDeleteStrategySelector() 
{
    const {
        selectedDeleteImageStrategy,
        handleChangeSelectedDeleteImageStrategy,
    } = useImageDeleteContext();
    
    return (
        <div className="image-delete-strategy-selector">
            <div className="image-delete-strategy-selector-header">
                You are about to delete an image that is linked to multiple entities.
                This action cannot be undone.
            </div>
            <div className="image-delete-strategy-selector-content">
                <div className="image-delete-strategy-selector-content-header">
                    Please confirm your choice on how you would like to proceed:
                </div>
                <div className="image-delete-strategy-selector-content-options">
                    <div className="image-delete-strategy-selector-content-option">
                        <Radio
                            id={DELETE_IMAGE_STRATEGY_ACTIONS.DELETE_IMAGE_AND_UNLINK_ENTITIES}
                            className="gms-radio"
                            checked={
                                selectedDeleteImageStrategy
                                === DELETE_IMAGE_STRATEGY_ACTIONS.DELETE_IMAGE_AND_UNLINK_ENTITIES
                            }
                            onChange={() => handleChangeSelectedDeleteImageStrategy(
                                DELETE_IMAGE_STRATEGY_ACTIONS.DELETE_IMAGE_AND_UNLINK_ENTITIES
                            )
                            }
                        ></Radio>
                        <label htmlFor={DELETE_IMAGE_STRATEGY_ACTIONS.DELETE_IMAGE_AND_UNLINK_ENTITIES} className="image-delete-strategy-selector-content-option-description">
                            <div className="image-delete-strategy-selector-content-option-description-title">
                                Delete image & unlink entities
                            </div>
                            <div className="image-delete-strategy-selector-content-option-description-text">
                                The image will be deleted, and all links to it will be removed.
                                Associated entities will no longer have any image linked.
                            </div>
                        </label>
                    </div>
                    <div className="image-delete-strategy-selector-content-option">
                        <Radio
                            id={DELETE_IMAGE_STRATEGY_ACTIONS.CHOOSE_NEW_IMAGE_AND_DELETE_OLD_ONE}
                            className="gms-radio"
                            checked={
                                selectedDeleteImageStrategy === DELETE_IMAGE_STRATEGY_ACTIONS.CHOOSE_NEW_IMAGE_AND_DELETE_OLD_ONE
                            }
                            onChange={() => handleChangeSelectedDeleteImageStrategy(
                                DELETE_IMAGE_STRATEGY_ACTIONS.CHOOSE_NEW_IMAGE_AND_DELETE_OLD_ONE
                            )
                            }
                        ></Radio>
                        <label htmlFor={DELETE_IMAGE_STRATEGY_ACTIONS.CHOOSE_NEW_IMAGE_AND_DELETE_OLD_ONE} className="image-delete-strategy-selector-content-option-description">
                            <div className="image-delete-strategy-selector-content-option-description-title">
                                Choose new image & delete
                            </div>
                            <div className="image-delete-strategy-selector-content-option-description-text">
                                Relink the associated entities to another image and then delete
                                this image.
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ImageDeleteStrategySelector;
