import React, { useMemo, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Button, Popup } from "semantic-ui-react";
import { useMutation, useQueryClient } from "react-query";
import { DataListingTableColumn } from "./DataTableColumn";
import { DEFAULT_LANGUAGE_CODE } from "../../../../_constants/constants";
import { useSelectedEntity } from "../../../../store/BrandingContext";
import { AddInventoryModal } from "./modals/AddInventoryModal";
import { ConfirmDeleteModal } from "./modals/ConfirmDeleteModal";
import { PaginationBar } from "../../../branding/listing/Pagination";
import { usePagination } from "../../../../_utils/hooks";
import brandingApi from "../../../../_api/branding.api";
import { Loader } from "../../../elements/loader";
import serverApi from "../../../../_api/server.api";
import { handleNormalizeUrl, utcStringToFormattedDateTime } from "../../../../_utils/utils";
import { useTranslation } from "react-i18next";

export const ListingDataTable = ({ textFilter, inventoryData }) =>
{
    const trans = useTranslation().t;

    const [displayLanguage,] = useState(DEFAULT_LANGUAGE_CODE);
    const entity = useSelectedEntity();

    const { data: inventory, isLoading } = inventoryData;

    const filteredInventory = useMemo(
        () =>
        {
            if (inventory && Array.isArray(inventory))
            {
                return inventory.filter((item) => filterItem(textFilter, item, displayLanguage));
            }
            return [];
        },
        [textFilter, inventory, displayLanguage]
    );

    const pagination = usePagination(10, filteredInventory?.length || 0);
    const { startIndex, endIndex } = pagination;

    const slicedInventory = useMemo(() => filteredInventory.slice(startIndex, endIndex), [filteredInventory, startIndex, endIndex]);


    if (isLoading)
    {
        return <Loader active />;
    }

    if (!entity)
    {
        return null;
    }

    if (inventory.length < 1)
    {
        return null;
    }

    return (
        <>
            <div className="dataListingTableBox listingMain">
                <Scrollbars className="tableScroll" autoHide autoHeight autoHeightMin="calc(1vh)" autoHeightMax="calc(100vh - 350px)" >
                    <DataListingTableHead />
                    {slicedInventory.length > 0 ? slicedInventory.map((item, index) => (
                        <TableRowTemporary
                            key={item._id}
                            index={index}
                            displayLanguage={displayLanguage}
                            item={item}
                        />
                    )) : (

                        <p className="emptyInventoryMessage">{trans("ListingDataTable.No_Result_Found_")}</p>
                    )
                    }
                </Scrollbars>
            </div>

            <PaginationBar
                totalPages={pagination.totalPages}
                activePage={pagination.currentPage}
                setPage={pagination.setPage}
                startIndex={startIndex}
                endIndex={endIndex}
                total={pagination.nElements}
            />
        </>
    );
};
const DataListingTableHead = () =>
{
    const trans = useTranslation().t;

    return (
        <div className="dataListingTableRow dataListingTableRow-head">
            <DataListingTableColumn detail={trans("ListingDataTable.INVENTORY_CODE")} />
            <DataListingTableColumn detail={trans("ListingDataTable.INVENTORY_NAME")} />
            <DataListingTableColumn detail={trans("ListingDataTable.#_OF_UNITS")} />
            {/* <DataListingTableColumn detail="INVENTORY PHOTOS" /> */}
            <DataListingTableColumn detail={trans("ListingDataTable.LAST_UPDATED")} />
            <DataListingTableColumn detail="" />
        </div>
    );
};

export const TableRowTemporary = ({ index, displayLanguage, item: { updatedAt, ...itemData } }) =>
{
    const trans = useTranslation().t;
    const queryClient = useQueryClient();

    // const { deleteItem } = useItemFunctions();
    const deleteMutation = useMutation(brandingApi.deleteInventory, { mutationKey: "DELETE_INVENTORY" });
    const onDelete = () => deleteMutation.mutate(itemData._id, {
        onSuccess: () => queryClient.refetchQueries("FIND_INVENTORIES")
    });

    return (
        <div>
            <div className="dataListingTableRow">
                <DataListingTableColumn detail={itemData.code} />
                <DataListingTableColumn detail={itemData.name?.[displayLanguage]} />
                <DataListingTableColumn detail={itemData.quantity} />
                {/* <DataListingTableColumn>
                    <ButtonTable className="listingUpload" icon="camera" />
                    <ButtonTable className="listingUpload" icon="camera" />
                    <ButtonTable className="listingUpload" icon="camera" />
                </DataListingTableColumn> */}
                <DataListingTableColumn detail={utcStringToFormattedDateTime(serverApi.userData, updatedAt)} />
                <DataListingTableColumn>
                    <div className="dataListingTableActions">
                        <Popup
                            className="defaultPopup"
                            on="hover"
                            position="left center"
                            hideOnScroll={true}
                            trigger={(
                                <ButtonTable icon="shopping-cart" disabled={!itemData.link?.[displayLanguage]} href={handleNormalizeUrl(`${itemData.link?.[displayLanguage]}`)} target="_blank" />
                            )}
                            content={trans("ListingDataTable.Purchase")}
                        />
                        <AddInventoryModal trigger={<ButtonTable icon="edit" />} index={index} inventory={itemData} />

                        <ConfirmDeleteModal
                            className="deleteInventoryModal"
                            onDelete={onDelete}
                            editConfig={{ title: trans("ListingDataTable.Delete"), body: trans("ListingDataTable.Are_you_sure_you_want_to_delete_this_ite") }}
                            trigger={<ButtonTable icon="delete" />}
                        />

                    </div>
                </DataListingTableColumn>
            </div>
            <Loader active={deleteMutation.isLoading} />
        </div>
    );
};

export const ButtonTable = ({ className = "", icon, content = undefined, ...rest }) => (
    <Button as="a" className={`buttonIcon ${className}`} {...rest} >
        <img src={`/img/icon-${icon ? icon : ""}.svg`} alt="" />
        {content}
    </Button>
);


function filterItem(textFilter, item, displayLanguage)
{
    return !textFilter
        || item?.name?.[displayLanguage]?.toLowerCase()?.includes(textFilter.toLowerCase())
        || item?.code?.toLowerCase()?.includes(textFilter.toLowerCase());
}
