import React from "react";
import "./ImagePreview.css";
import { bytesToMB } from "../../../utils";
import { filerUrl } from "../../../../../../_utils/utils";
import { useImagesBaseContext } from "../../contexts/ImageBaseContext";

function ImagePreview() 
{
    const { selectedImageInfo } = useImagesBaseContext();
    const linkedEntitiesCount = selectedImageInfo.usedIn.length;

    return (
        <div className="image-preview">
            <img src={filerUrl(selectedImageInfo.filerId, 1800)} alt="image" />
            <div className="image-preview-info-bx">
                <div className="image-preview-info-name">
                    {selectedImageInfo.name}
                </div>
                <div className="image-preview-additional-info">
                    {bytesToMB(selectedImageInfo.size)} MB | {linkedEntitiesCount} Linked
                </div>
            </div>
        </div>
    );
}

export default ImagePreview;