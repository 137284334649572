import React from "react";
import { Dropdown, DropdownItem, DropdownMenu } from "semantic-ui-react";
import { IMAGE_SORTING_OPTIONS } from "../../../constant";
import { useTranslation } from "react-i18next";
import "./ImagesSortSelector.css";

function ImagesSortSelector({ value, onClick }) 
{
    const trans = useTranslation().t;
    const selectedSortInfo = IMAGE_SORTING_OPTIONS.find(
        (option) => option.value === value
    );


    return (
        <div className="images-sort-selector">
            <div className="images-sort-selector-title">Sort By</div>
            <Dropdown
                text={trans(selectedSortInfo?.i18nKey)}
                icon={"angle down"}
                className="gms-dropdown"
            >
                <DropdownMenu className="gms-dropdown-menu">
                    {IMAGE_SORTING_OPTIONS.map((option) => (
                        <DropdownItem
                            className="gms-dropdown-item"
                            onClick={(e) => onClick(e, option)}
                            key={option.value}
                        >
                            {trans(option.i18nKey)}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </div>
    );
}

export default ImagesSortSelector;
