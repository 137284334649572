import { useEffect } from "react";
import { useRef } from "react";

export const useFocus=() => 
{
    const inputRef=useRef(null);

    useEffect(() => 
    {
        // eslint-disable-next-line no-unused-expressions
        inputRef.current && inputRef.current.focus();
    },[]);

    return inputRef;
};