import React, { useContext, useState } from "react";
import { Button, Popup, Radio } from "semantic-ui-react";

import { CategoryContext } from "../../../store/CategoryProvider";

import { CategoryItemBox } from "./CategoryItemBox";
import { ModalLayout } from "../../popups/ModalLayout";
import { CategoryEditHeading } from "./CategorySidebar";
import { CATEGORY_EDIT_TYPE } from "../../../_constants/branding";
import { EditCategoryModal } from "./EditCategoryModal";
import { ButtonIcon } from "../../common/ButtonIcon";
import { CategoryDeleteModal } from "./CategoryDeleteModal";
import { useTranslation } from "react-i18next";

export const EditGroupsModal = ({ enableExport = false, exportTrigger }) =>
{
    const categoryContext = useContext(CategoryContext);

    const groups = categoryContext.categoryGroups;

    const [selectedGroup, setSelectedGroup] = useState(undefined);

    /** @type {typeof import("../../../_intl/resources.json")["en"]["translation"]["edit groups modal"]} */
    const trans = useTranslation().t("edit groups modal");

    const hasGroups = groups?.length > 0;
    return (
        <ModalLayout
            trigger={<CategoryEditHeading heading={trans["trigger header"]} enableExport={enableExport} exportTrigger={exportTrigger}/>}
            className="categoryModal"
            heading={hasGroups ? trans["header"] : trans["add new trigger"]}
            actions={<>
                <Popup
                    disabled={groups && groups.length < 5}
                    trigger={
                        <div style={{ height: 40, width: 130, float: "left", display: "inline-block" }}>
                            <EditCategoryModal
                                trigger={
                                    <Button className="iconButton button-more" disabled={groups && groups.length >= 5}>
                                        <img src="/img/icon-plus-filled.svg" alt="" />
                                        {trans["add new trigger"]}
                                    </Button>
                                }
                                category={{}}
                                editConfig={CATEGORY_EDIT_TYPE.GROUP}
                            />
                        </div>
                    }
                    content={trans["content"]}
                />
                {(hasGroups) && (
                    <EditCategoryModal
                        category={selectedGroup || {}}
                        editConfig={CATEGORY_EDIT_TYPE.GROUP}
                        onClose={() => setSelectedGroup(undefined)}
                        trigger={<Button primary color="orange" content={trans["next"]} disabled={!selectedGroup} />}
                    />
                )}
            </>}>

            <p className="p-modal">{(hasGroups) ? trans["p"]: trans["group info"]}</p>

            {
                groups.map((cat) => (
                    <EditGroupItem
                        key={cat._id}
                        category={cat}
                        isChecked={selectedGroup === cat}
                        onClick={() => setSelectedGroup(cat)}
                    />
                ))
            }
        </ModalLayout>
    );
};

const EditGroupItem = ({ category = {}, isChecked, onClick }) =>
{
    const { getIconInfo, deleteCategory } = useContext(CategoryContext);
    const iconInfo = getIconInfo(category);

    const handleOnClick = React.useCallback(() => onClick(category._id), [onClick, category]);

    const handleDelete = (categoryId) => deleteCategory(categoryId, true);

    /** @type {typeof import("../../../_intl/resources.json")["en"]["translation"]["edit groups modal"]} */
    const trans = useTranslation().t("edit groups modal");

    return (
        <div className={`groupItemCover${isChecked ? " active":""}`}>
            <Radio className="radioItem" checked={isChecked} onClick={handleOnClick} />
            <CategoryItemBox
                iconImage={iconInfo.iconImage}
                name={category.name}
                iconColour={iconInfo.iconColour}
                backgroundColour={iconInfo.backgroundColour}
            >
                {(isChecked) && (
                    <div className="groupActions">
                        <CategoryDeleteModal
                            onDelete={() => handleDelete(category._id)}
                            editConfig={CATEGORY_EDIT_TYPE.GROUP.deleteModal}
                            trigger={<ButtonIcon icon="trash-blue" content={trans["delete"]} />}
                        />
                    </div>
                )}
            </CategoryItemBox>
        </div>
    );
};
