import React, { useContext, useEffect } from "react";
import { MaintenanceLeftbar } from "../../maintenance/components/MaintenanceLeftbar";
import { SubheaderMaintenance } from "../../maintenance/components/SubheaderMaintenance";
import {  MAINTENANCE_MODES, MAP_OVERLAYS_MODES, RESTORE_QUERY_KEY, RESTORE_QUERY_MAP_OVERLAYS } from "../../../_constants/constants";
import { MaintenanceContext, MaintenanceProvider } from "../../../store/MaintenanceContext";
import { useSetState } from "ahooks";
import "./Maintenance.css";
import { MapOverlaysContext, MapOverlaysProvider } from "../../../store/MapOverlaysContext";
import OverlaysMap from "../../maintenance/mapOverlays/OverlaysMap";
import CreateMapOverlaysSideBar from "../../maintenance/mapOverlays/mapOverlaysSidebars/CreateMapOverlaysSideBar";
import MapOverlaysActivitySideBar from "../../maintenance/mapOverlays/mapOverlaysSidebars/MapOverlaysActivitySideBar";
import { DynamicMapLayersProvider } from "../../../store/DynamicMapLayersContext";
import DynamicLayersMap from "../../maintenance/dynamicMapLayers/DynamicLayersMap";
import { CreateDynamicMapLayerSidebar } from "../../maintenance/dynamicMapLayers/dynamicMapLayersSidebars/CreateDynamicMapLayerSidebar";
import { default as DynamciMapLayerActivitySidebar } from "../../maintenance/dynamicMapLayers/dynamicMapLayersSidebars/ActivitySidebar";
import { default as DynamicMapLayerConfirmationSidebar } from "../../maintenance/dynamicMapLayers/dynamicMapLayersSidebars/ConfirmationSidebar";
import MultiCreationSidebar from "../../maintenance/dynamicMapLayers/dynamicMapLayersSidebars/MultiCreationSidebar";
import { useLocation } from "react-router-dom";
import Zones from "../../maintenance/zones/Zones";
import OverlaysTemplateSidebar from "../../maintenance/mapOverlays/mapOverlaysSidebars/OverlaysTemplateSidebar";

const Maintenance = () =>
{
    const maintenanceCtx = useContext(MaintenanceContext);
    const { search } = useLocation();
    const mapOverlayCtx = useContext(MapOverlaysContext);

    const [state, setState] = useSetState({
        sidebarExtended: false
    });

    useEffect(() => 
    {   
        if (search)
        {
            // check if restore map overlay edit process
            const searchParams = new URLSearchParams(search);

            const restoreKey = searchParams.get(RESTORE_QUERY_KEY);

            if (restoreKey === RESTORE_QUERY_MAP_OVERLAYS)
            {
                maintenanceCtx.handleMapOverlaysRestore();
            }
        }
        return () => maintenanceCtx.handleMaintenanceUnMount();
    }, []);

    const handleSidebarToggle = () => setState(({ sidebarExtended }) => ({ sidebarExtended: !sidebarExtended }));


    let maintenanceDisplay = <></>;
    if (maintenanceCtx.state.mode === MAINTENANCE_MODES.MAP_OVERLAYS)
    {

        maintenanceDisplay = (
            <>
                <SubheaderMaintenance />
                <OverlaysMap />
                <CreateMapOverlaysSideBar />
                <MapOverlaysActivitySideBar />
                {
                    (mapOverlayCtx.state.mode == MAP_OVERLAYS_MODES.TEMPLATES || mapOverlayCtx.state.isOverlaysTemplateSideBar) && <OverlaysTemplateSidebar />
                }
                {/* <ConfirmationSidebar /> */}
            </>
        );
    }

    else if (maintenanceCtx.state.mode === MAINTENANCE_MODES.DYNAMIC_MAP_LAYERS)
    {
        maintenanceDisplay = (
            <>
                <SubheaderMaintenance />
                <DynamicLayersMap />
                <CreateDynamicMapLayerSidebar />
                <DynamciMapLayerActivitySidebar />
                <DynamicMapLayerConfirmationSidebar />
                <MultiCreationSidebar />
            </>
        );
    }

    else if (maintenanceCtx.state.mode === MAINTENANCE_MODES.ZONES) 
    {
        maintenanceDisplay = (
            <Zones/>
        );
    }

    return (
        <>

            {maintenanceDisplay}
            <MaintenanceLeftbar
                sidebarExtended={state.sidebarExtended}
                onSidebarToggle={handleSidebarToggle}
                onModeChange={maintenanceCtx.updateMode}
                mode={maintenanceCtx.state.mode}
            />
        </>
    );
};

const withProvider = () => (
    <MaintenanceProvider>
        <MapOverlaysProvider>
            <DynamicMapLayersProvider>
                <Maintenance />
            </DynamicMapLayersProvider>
        </MapOverlaysProvider>
    </MaintenanceProvider>
);
export default withProvider;
