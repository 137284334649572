const { GeoJsonAccess } = require("./geoJsonAccess");
const { isClose, } = require("./mathExtensions");
const { ShapeTypes } = require("../utils/entityTypes");
const { toMercatorFromArray, toGpsLocation } = require("../utils/map.utils");

const PointAccess = class extends GeoJsonAccess
{
    constructor(coordinates, isMercator = true)
    {
        super();

        this.geometry = {
            type: ShapeTypes.POINT,
            coordinates
        };

        this.isMercator = isMercator;
    }

    /**
     * Retrieves the x-coordinate of the point.
     *
     * @return {number} The x-coordinate of the point.
     */
    getX()
    {
        return this.geometry.coordinates[0];
    }

    /**
     * Retrieves the y-coordinate of the point.
     *
     * @return {number} The y-coordinate of the point.
     */
    getY()
    {
        return this.geometry.coordinates[1];
    }

    /**
     * Checks if two points are close to each other within a certain maximum absolute error.
     *
     * @param {PointAccess} point - The second point in the form of a GeoJSON object.
     * @return {boolean} Returns true if the points are close enough, false otherwise.
     */
    isClose(point)
    {
        return isClose(this.getX(), point.getX()) && isClose(this.getY(), point.getY());
    }

    /**
     * Converts a point from the current coordinate system to the WGS84 coordinate system.
     *
     * @return {PointAccess} A new PointAccess object representing the point in the WGS84 coordinate system.
     */
    toWGS84()
    {
        let coordinates = this.getCoordinates();

        if (!this.isMercator)
        {
            return new PointAccess(coordinates, false);

        }

        const gpsCoords = toGpsLocation(coordinates);
        return new PointAccess(gpsCoords, false);
    }

    /**
     * Converts a point from the current coordinate system to the Mercator coordinate system.
     *
     * @return {PointAccess} A new PointAccess object representing the point in the Mercator coordinate system.
     */
    toMercator()
    {
        let coordinates = this.getCoordinates();

        if (this.isMercator)
        {
            return new PointAccess(coordinates);
        }

        const mercatorCoords = toMercatorFromArray(coordinates);
        return new PointAccess(mercatorCoords);

    }
}

exports.PointAccess = PointAccess;