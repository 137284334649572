import React, { useContext } from "react";
import { useSetState } from "ahooks";
import cloneDeep from "lodash.clonedeep";
import { DEFAULT_LANGUAGE_CODE } from "mapsted.maps/utils/map.constants";
import Scrollbars from "react-custom-scrollbars-2";
import { Button, Header, Input, Popup } from "semantic-ui-react";
import { DynamicMapLayersContext } from "../../../../store/DynamicMapLayersContext";
import { AUTOMATION_RULE_CONDITIONS, AUTOMATION_RULE_CONDITIONS_ICONS, DYNAMIC_MAP_LAYERS_MODES } from "../../../../_constants/constants";
import { ButtonIcon } from "../../../common/ButtonIcon";
import { DividerLine } from "../../../common/DividerLine";
import { TextGroup } from "../../../common/TextGroup";
import { ErrorLabel } from "../../../elements/ErrorLabel";
import DynamicMapLayerCancelConfirmationModal from "../DynamicMapLayerCancelConfirmationModal";
import { validateAutomationRule } from "../utils/dynamicMapLayerValidations";
import DefaultOpacitySettings from "./DefaultOpacitySettings";
import { v4 as uuid } from "uuid";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import CreateMultiDynamicMapLayerButton from "./CreateMultiDynamicMapLayerButton";
import LanguageSelector from "../../mapOverlays/LanguageSelector";
import { getAllAvailableDynamicMapLayerLangs } from "../utils/dynamicMapLayersUtils";
import { useTranslation } from "react-i18next";

const AutomationRulesTab = ({ updateTab }) =>
{
    const dynamicMapLayerCtx = useContext(DynamicMapLayersContext);

    const [state, setState] = useSetState({
        automationRuleValidationErrors: [],
        isIconEditModalOpen: false
    });

    const {
        cancelHandler,
        createNewAutomationRule,
        updateActiveAutomationRule,
        saveActiveAutomationRule,
        editAutomationRuleClickHandler,
        cancelAutomationRuleCreateAndEdit,
        updateAutomationRuleToDisplay,
        updateActiveDynamicMapLayer,
        createDynamicMapLayerHandler,
        editClickHandler,
        deleteAutomationRuleHandler,
        checkIfModifiedInCreationMode,
        state: ctxState,
        updateDisplayLang,
    } = dynamicMapLayerCtx;

    const {
        mode,
        activeDynamicMapLayer,
        activeAutomationRule,
        automationRuleToDisplay,
        displayLang,
        allowedLangs,
    } = ctxState;

    const trans = useTranslation().t;

    const createClickHandler = () =>
    {
        createDynamicMapLayerHandler();
    };

    const updateAutomationRuleField = (key, value) =>
    {
        const updatedAutomationRule = cloneDeep(activeAutomationRule);
        updatedAutomationRule[key] = value;
        updateActiveAutomationRule(updatedAutomationRule);
        setState({
            automationRuleValidationErrors: []
        });
    };

    const cancelClickHandler = () =>
    {
        setState({
            automationRuleValidationErrors: []
        });
        cancelAutomationRuleCreateAndEdit();
    };

    const updateDataPoint = (key, value) =>
    {
        updateAutomationRuleField("dataPointCondition", {
            ...activeAutomationRule.dataPointCondition,
            [key]: value
        });
    };

    const saveAutomationRuleClick = () =>
    {
        const otherAutomationRules = activeDynamicMapLayer.automationRules.filter((automationRule) => automationRule._id !== activeAutomationRule._id);
        const automationRuleValidationErrors = validateAutomationRule(activeAutomationRule, otherAutomationRules, trans);
        if (automationRuleValidationErrors.length)
        {
            setState({
                automationRuleValidationErrors
            });
        }
        else
        {
            saveActiveAutomationRule();
        }
    };

    const updateField = (key, value) =>
    {
        let updatedDynamicMapLayer = cloneDeep(activeDynamicMapLayer);
        updatedDynamicMapLayer[key] = value;
        updateActiveDynamicMapLayer(updatedDynamicMapLayer);
    };

    const createOrEditHandler = () =>
    {
        if (mode === DYNAMIC_MAP_LAYERS_MODES.CREATE_DYNAMIC_MAP_LAYER)
        {
            createClickHandler();
        }
        else
        {
            editClickHandler();
        }
    };

    const renderAutomationRules = () => 
    {
        const automationRules = [...activeDynamicMapLayer.automationRules];

        // Push newly created automation rule to the array
        if (activeAutomationRule)
        {
            const selectedAutomationRule = automationRules.find((automationRule) => automationRule._id === activeAutomationRule._id);
            if (!selectedAutomationRule)
            {
                automationRules.push(activeAutomationRule);
            }
        }

        return automationRules.map((automationRule, index) =>
        {
            if (automationRule._id === activeAutomationRule?._id)
            {
                return (
                    <div className="sidebarSection" id={automationRule._id} >
                        <TextGroup heading={`${trans("MaintenanceCreateDynamicMapSidebar.Rule") } ${index + 1}`}>
                            <div className="ruleConditionBox">
                                <div className="ruleConditionButtons">
                                    <ButtonIcon
                                        active={activeAutomationRule.dataPointCondition.condition === AUTOMATION_RULE_CONDITIONS.EQUAL}
                                        icon="equal"
                                        onClick={() => updateDataPoint("condition", AUTOMATION_RULE_CONDITIONS.EQUAL)} />
                                    <ButtonIcon
                                        active={activeAutomationRule.dataPointCondition.condition === AUTOMATION_RULE_CONDITIONS.GREATER}
                                        icon="greater"
                                        onClick={() => updateDataPoint("condition", AUTOMATION_RULE_CONDITIONS.GREATER)} />
                                    <ButtonIcon
                                        active={activeAutomationRule.dataPointCondition.condition === AUTOMATION_RULE_CONDITIONS.LESSER}
                                        icon="less"
                                        onClick={() => updateDataPoint("condition", AUTOMATION_RULE_CONDITIONS.LESSER)} />
                                </div>
                                <Input
                                    className="automationRuleValue-input"
                                    type="number"
                                    placeholder={trans("MaintenanceCreateDynamicMapSidebar.Enter_Value")} 
                                    value={activeAutomationRule.dataPointCondition.value}
                                    onChange={(e, { value }) => updateDataPoint("value", value)} />
                            </div>
                        </TextGroup>

                        <TextGroup heading={trans("MaintenanceCreateDynamicMapSidebar.Text_Label")}>
                            <Input
                                placeholder={trans("MaintenanceCreateDynamicMapSidebar.Type_Tooptip_Text")}
                                value={activeAutomationRule.textLabel[displayLang] || ""}
                                onChange={(e, { value }) => updateAutomationRuleField("textLabel",
                                    {
                                        ...activeAutomationRule.textLabel,
                                        [displayLang]: value
                                    })} />
                        </TextGroup>

                        <TextGroup className="dynamicMapLayerOpacitySetting" heading={trans("MaintenanceCreateDynamicMapSidebar.Selected_Polygon_Color")}>
                            <DefaultOpacitySettings
                                fillColor={activeAutomationRule.fillColor}
                                fillOpacity={activeAutomationRule.fillOpacity}
                                borderColor={activeAutomationRule.borderColor}
                                borderOpacity={activeAutomationRule.borderOpacity}
                                textColor={activeAutomationRule.textColor}
                                textOpactiy={activeAutomationRule.textOpacity}
                                updateAutomationRuleField={updateAutomationRuleField} />
                        </TextGroup>

                        <TextGroup heading={trans("MaintenanceCreateDynamicMapSidebar.Text_Popup")}>
                            <Input
                                placeholder={trans("MaintenanceCreateDynamicMapSidebar.Type_Popup_Msg")}
                                value={activeAutomationRule.textPopup[displayLang] || ""}
                                onChange={(e, { value }) => updateAutomationRuleField("textPopup",
                                    {
                                        ...activeAutomationRule.textPopup,
                                        [displayLang]: value
                                    })} />
                        </TextGroup>

                        {!!state.automationRuleValidationErrors.length && <ErrorLabel multiple={Object.keys(state.automationRuleValidationErrors).length > 0}>
                            {trans("CreateMapOverlaysSideBar.Validation_Error_Header")}
                            <ul>
                                {state.automationRuleValidationErrors.map((validationError) => <li key={validationError}>{validationError}</li>)}
                            </ul>
                        </ErrorLabel>}

                        <Button
                            style={{ marginLeft: "0" }}
                            color="grey"
                            basic
                            className="saveButton"
                            content={trans("MaintenanceCreateDynamicMapSidebar.Cancel")}
                            onClick={cancelClickHandler} />
                        <Button
                            style={{ marginRight: "0" }}
                            color="orange"
                            basic
                            className="saveButton"
                            content={trans("MaintenanceCreateDynamicMapSidebar.Save")}
                            onClick={saveAutomationRuleClick} />
                    </div>
                );
            }
            else
            {
                return (
                    <div className="sidebarSection"
                        id={automationRule._id} >
                        <TextGroup
                            className="tgRuleHeading"
                            heading={`${trans("MaintenanceCreateDynamicMapSidebar.Rule") } ${index + 1}`}
                            content={
                                <div className="automationRuleActions">
                                    <ButtonIcon
                                        icon="trash-bin"
                                        onClick={() => deleteAutomationRuleHandler(automationRule._id)} />
                                    <ButtonIcon
                                        icon="edit"
                                        onClick={() => editAutomationRuleClickHandler(automationRule._id)} />
                                </div>
                            } />
                        <div className={`mapOverlayPropertybox ${automationRuleToDisplay === automationRule._id ? "active" : ""}`}
                            onClick={() => updateAutomationRuleToDisplay(automationRule._id)}>
                            <TextGroup title={trans("OpacitySettings.Rule_Condition")}>
                                {activeDynamicMapLayer.dataPointName}
                                <img className="conditionalArrow" src={AUTOMATION_RULE_CONDITIONS_ICONS[automationRule.dataPointCondition.condition]} alt="" />
                                {automationRule.dataPointCondition.value}
                            </TextGroup>
                            <TextGroup className="automationRuleTextLabel" title={trans("OpacitySettings.Text_Label")} content={automationRule.textLabel[displayLang]} />
                            <div className="mapOverlayPropertyRow">
                                <TextGroup title={trans("OpacitySettings.Fill_Color")} content={
                                    <span
                                        className="colorFill"
                                        style={{ background: automationRule.fillColor }}>
                                    </span>} />
                                <TextGroup title={trans("OpacitySettings.Opacity")}  content={`${Math.floor((automationRule.fillOpacity * 100).toFixed(2))}%`} />
                            </div>
                            <div className="mapOverlayPropertyRow">
                                <TextGroup title={trans("OpacitySettings.Border_Color")}  content={
                                    <span
                                        className="colorFill"
                                        style={{ background: automationRule.borderColor }}>
                                    </span>} />
                                <TextGroup title={trans("OpacitySettings.Opacity")} content={`${Math.floor((automationRule.borderOpacity * 100).toFixed(2))}%`} />
                            </div>
                            <div className="mapOverlayPropertyRow">
                                <TextGroup title={trans("OpacitySettings.Text_Color")}  content={
                                    <span 
                                        className="colorFill" 
                                        style={{ background: automationRule.textColor }}>
                                    </span>} />
                                <TextGroup title={trans("OpacitySettings.Opacity")} content={`${Math.floor((automationRule.textOpacity * 100).toFixed(2))}%`} />
                            </div>
                            <TextGroup className="tgTextPopup" title={trans("OpacitySettings.Text_Popup")}>
                                {automationRule.textPopup[displayLang]}
                            </TextGroup>
                        </div>
                    </div>
                );
            }
        });
    };

    const disableFinishButton = !(activeDynamicMapLayer.dataStreamName[DEFAULT_LANGUAGE_CODE]
        && activeDynamicMapLayer.automationRules.length
        && activeDynamicMapLayer.dataPointName
        && activeDynamicMapLayer.mapOverlayId
        && !activeAutomationRule);

    const enableCancelConfirmationModal = checkIfModifiedInCreationMode();

    const allAvailableDynamicMapLayerLangs = getAllAvailableDynamicMapLayerLangs(activeDynamicMapLayer, activeAutomationRule);

    return (
        <div className="sidebarPortal dynamicMapLayerSidebar">
            <Scrollbars autoHeight autoHeightMin="calc(100vh - 170px)" autoHeightMax={"calc(100vh - 170px)"}>
                <div className="alignerSidebar">
                    <Header as="h2">
                        <span className="creationTabHeading">{trans("MaintenanceCreateDynamicMapSidebar.Dynamic_Map_Layer")}</span>
                        {enableCancelConfirmationModal ? <DynamicMapLayerCancelConfirmationModal
                            onCancelClick={cancelHandler}
                            isCreate={mode === DYNAMIC_MAP_LAYERS_MODES.CREATE_DYNAMIC_MAP_LAYER}
                        /> : <ButtonIcon icon="cross" className="dynamicMapLayercancelButton" onClick={cancelHandler} />}
                    </Header>
                    
                    <div className="spaceBottom">
                        <CreateMultiDynamicMapLayerButton />
                    </div>
                    
                    <div className="sidebarSection dividerLineUp">
                        <TextGroup heading={trans("MaintenanceCreateDynamicMapSidebar.API_Key")}>
                            <div className="dynamicLayerCodebox">
                                <p>{activeDynamicMapLayer.apiKey}</p>
                                <Popup
                                    content={trans("MaintenanceCreateDynamicMapSidebar.Copy_API_Key")}
                                    basic
                                    trigger={
                                        <CopyToClipboard text={activeDynamicMapLayer.apiKey}>
                                            <ButtonIcon icon="copy2" />
                                        </CopyToClipboard>
                                    } />
                            </div>
                        </TextGroup>
                    </div>

                    <TextGroup
                        heading={trans("LanguageSelector.Preview_in_Language")}
                        className="languageSelector"
                    >
                        <LanguageSelector
                            activeLang={displayLang}
                            defaultDisplayedLangs={allAvailableDynamicMapLayerLangs}
                            setActiveLang={(lang) => updateDisplayLang(lang)}
                            allowedLangs={allowedLangs}
                            allowAdditions={false}
                        />
                    </TextGroup>

                    <div className="sidebarSection">
                        <h6 className="h6"><span className="creationTabHeading">{trans("MaintenanceCreateDynamicMapSidebar.Automation_Rule")}</span></h6>
                        <p className="paraDynamic">
                            {trans("MaintenanceCreateDynamicMapSidebar.Automation_Description")}
                        </p>

                        <TextGroup title={<span className="creationTabHeading">{trans("MaintenanceCreateDynamicMapSidebar.Data_Point_Name")}</span>}>
                            <Input
                                placeholder={trans("MaintenanceCreateDynamicMapSidebar.Type_Data_Point_Name")}
                                value={activeDynamicMapLayer.dataPointName}
                                onChange={(e, { value }) => updateField("dataPointName", value)}
                            />
                        </TextGroup>

                        {renderAutomationRules()}

                        <ButtonIcon
                            disabled={!!activeAutomationRule}
                            icon="plus-circle"
                            content={trans("MaintenanceCreateDynamicMapSidebar.Create_Rule")}
                            onClick={() => createNewAutomationRule(uuid())} />

                        <DividerLine isEmpty gap="0 0 10px" />
                    </div>
                </div>

            </Scrollbars>
            <Button
                color="grey"
                className="saveButton"
                content={trans("MaintenanceCreateDynamicMapSidebar.Back")}
                onClick={() => updateTab(-1)} />
            <Button
                color="orange"
                className="saveButton"
                content={trans("MaintenanceCreateDynamicMapSidebar.Finish")}
                disabled={disableFinishButton}
                onClick={createOrEditHandler} />
        </div>
    );
};

export default AutomationRulesTab;