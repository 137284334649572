export class NodeStepData
{
    addedNodes;
    updatedNodesAfter;
    updatedNodesBefore;
    deletedNodes;
    addedNodeLinks;
    deletedNodeLinks;
    autoLinkAddedNodes;
    autoLinkRemovedNodes;
    // autoLinkId;

    constructor(initObj)
    {
        Object.assign(this, initObj);
    }
}