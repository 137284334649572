import cloneDeep from "lodash.clonedeep";
import { DEFAULT_LANGUAGE_CODE } from "mapsted.maps/utils/map.constants";
import { arrayToHash } from "mapsted.utils/arrays";
import Papa from "papaparse";
import { MAP_OVERLAY_ZOOM_CONSTANTS, NEW_OVERLAY_DEFAULT } from "../../../../_constants/constants";
import { downloadFile, parseFile } from "../../utils";
import { getPercentage, getValueFromPercentage } from "./mapOverlayUtils";
import validateMapOverlay, { validateLinkedMapOverlay } from "./mapOverlayValidation";

const COMMON_FILE_COLUMNS = {
    id: "Id",
    name: "Name",
    nameEn: "Name en",
    template: "Template",
    fillColor: "Fill Color",
    lineColor: "Line Color",
    textColor: "Text Color",
    defaultFillOpacity: "Default Fill Opacity",
    defaultBorderFillOpacity: "Default Border Fill Opacity",
    defaultTextOpacity: "Default Text Opacity",
    textLabel: "Text Label",
    textLabelEn: "Text Label en",
    dynamicOverlaySettingsEnabled: "Dynamic Settings Enabled",
};

const getCommonFileColumns = (lang=DEFAULT_LANGUAGE_CODE) =>
{
    let commonFileColumns = { ...COMMON_FILE_COLUMNS };

    // remove en reference columns if the lang is English
    if (lang === DEFAULT_LANGUAGE_CODE)
    {
        delete commonFileColumns.nameEn;
        delete commonFileColumns.textLabelEn;
    }

    return commonFileColumns;
};

const getNumOfCommonFileColumns = (lang=DEFAULT_LANGUAGE_CODE) => Object.keys(getCommonFileColumns(lang)).length;

const ZOOM_LEVEL_FILE_COLUMNS = {
    value: "Zoom Value",
    fillOpacity: "Fill Opacity",
    borderFillOpacity: "Border Opacity",
    textOpacity: "Text Opacity",
    overrideGlobalTextLabel: "Override Global Text Label",
    textLabel: "Text Label",
    textLabelEn: "Text Label en",
};

const getZoomLevelFileColumns = (lang=DEFAULT_LANGUAGE_CODE) =>
{
    let zoomLevelFileColumns = { ...ZOOM_LEVEL_FILE_COLUMNS };

    // remove en reference columns if the lang is English
    if (lang === DEFAULT_LANGUAGE_CODE)
    {
        delete zoomLevelFileColumns.textLabelEn;
    }

    return zoomLevelFileColumns;
};

const getNumOfZoomLevelFileColumns = (lang=DEFAULT_LANGUAGE_CODE) => Object.keys(getZoomLevelFileColumns(lang)).length;

const DEFAULT_OPACITY_VALIDATION_FIELDS = ["defaultFillOpacity", "defaultBorderFillOpacity", "defaultTextOpacity"];
const ZOOM_LEVEL_OPACITY_VALIDATION_FIELDS = ["fillOpacity", "borderFillOpacity", "textOpacity"];
const DATAROW_BOOLEAN_VALIDATION_FIELDS = ["dynamicOverlaySettingsEnabled"];
const ZOOM_LEVEL_BOOLEAN_VALIDATION_FIELDS = ["overrideGlobalTextLabel"];
const ZOOM_LEVEL_VALUE_FIELDS = ["value"];
const COLOR_VALIDATION_FIELDS = ["fillColor", "lineColor"];

export const MULTI_LANG_MAP_OVERLAY_TEXT_FIELDS = ["name", "toolTipText"];
export const MULTI_LANG_ZOOM_LEVEL_TEXT_FIELDS = ["textLabel"];

export const downloadMapOverlays = (mapOverlays, fileName, consumedMapOverlays, lang=DEFAULT_LANGUAGE_CODE, overlayTemplates) =>
{
    let dataRows = [];

    // map overlays consumed in dynamic map layer creation should be filtered out
    mapOverlays
        .filter((mapOverlay) => !(consumedMapOverlays.includes(mapOverlay._id)))
        .forEach((mapOverlay) =>
        {
            if (mapOverlay.templateId)
            {
                dataRows.push(createDataRowWithTemplate(mapOverlay, lang, overlayTemplates));
            }
            else 
            {
                dataRows.push(createDataRow(mapOverlay, lang));

            }
        });
   
    let data = [];
    dataRows.forEach((dataRow) =>
    {
        let tempDataRow = [];
        Object.keys(getCommonFileColumns(lang)).forEach((header) =>
        {
            tempDataRow.push(dataRow[header]);
        });

        if (dataRow.zoomLevels.length)
        {
            dataRow.zoomLevels.forEach((zoomLevel) =>
            {
                Object.keys(getZoomLevelFileColumns(lang)).forEach((header) =>
                {
                    tempDataRow.push(zoomLevel[header]);
                });
            });
        }
        data.push(tempDataRow);        
    });

    // the number of columns is determined by the map overlay with highest zoom levels
    let columnHeaders = [...Object.values(getCommonFileColumns(lang))];
    let highestNumberOfZoomLevels = Math.max(...dataRows.map((dataRow) => dataRow.numberOfZoomLevels));

    // if none of the map overlays has zoom levels (i.e. all the map overlays are default overlays without dynamic zoom stylings), then have atleast one set of 
    // zoom level columns so that user can use them if they want to make any of the map overlays as dynamic.
    if (highestNumberOfZoomLevels === 0)
    {
        highestNumberOfZoomLevels = 1;
    }

    for (let i=0; i<highestNumberOfZoomLevels;i++)
    {
        columnHeaders.push(...Object.values(getZoomLevelFileColumns(lang)));
    }

    // added "\ufeff" BOM to make sure letters in other scripts are preserved when opening in excel
    const csvData = "\ufeff" + addLangCode(Papa.unparse({ fields: columnHeaders, data }), lang);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    downloadFile(fileName, blob);
};

const addLangCode = (csvData, lang) => `lang: ${lang}\n` + csvData;

const getLangCodeFromCsvData = (csvData) => csvData[0]?.[0]?.split(":")[1]?.trim();

export const createDataRowWithTemplate=(mapOverlay, lang= DEFAULT_LANGUAGE_CODE, overlayTemplates) => 
{
    let dataRow = {
        id: mapOverlay._id,
        name: mapOverlay.name[lang],
        fillColor: "",
        lineColor: "",
        textColor: "",
        template: overlayTemplates.find((template) => template.templateId === mapOverlay.templateId)?.name?.[DEFAULT_LANGUAGE_CODE] || "",
        defaultFillOpacity: "",
        defaultBorderFillOpacity: "",
        defaultTextOpacity: "",
        textLabel: mapOverlay.toolTipText[lang],
        dynamicOverlaySettingsEnabled: "",
        zoomLevels: []
    };
    // add en reference colums if lang is not English
    if (lang !== DEFAULT_LANGUAGE_CODE) 
    {
        dataRow.nameEn = mapOverlay.name[DEFAULT_LANGUAGE_CODE];
        dataRow.textLabelEn = mapOverlay.toolTipText[DEFAULT_LANGUAGE_CODE];
    }

    dataRow.numberOfZoomLevels = 0;

    return dataRow;
};

const createDataRow = (mapOverlay, lang=DEFAULT_LANGUAGE_CODE) =>
{
    let dataRow = {
        id: mapOverlay._id,
        name: mapOverlay.name[lang],
        fillColor: mapOverlay.color,
        lineColor: mapOverlay.lineColor,
        textColor: mapOverlay.textColor,
        defaultFillOpacity: getPercentage(mapOverlay.defaultFillOpacity),
        defaultBorderFillOpacity: getPercentage(mapOverlay.defaultBorderFillOpacity),
        defaultTextOpacity: getPercentage(mapOverlay.defaultTextOpacity),
        textLabel: mapOverlay.toolTipText[lang],
        dynamicOverlaySettingsEnabled: mapOverlay.dynamicOverlaySettings.enabled,
        zoomLevels: []
    };

    // add en reference colums if lang is not English
    if (lang !== DEFAULT_LANGUAGE_CODE)
    {
        dataRow.nameEn = mapOverlay.name[DEFAULT_LANGUAGE_CODE];
        dataRow.textLabelEn = mapOverlay.toolTipText[DEFAULT_LANGUAGE_CODE];
    }

    // update zoom level details if dynamic settings is enabled
    if (mapOverlay.dynamicOverlaySettings.enabled)
    {
        let zoomLevels = [];
        mapOverlay.dynamicOverlaySettings.zoomLevels.forEach((zoomLevel, index) =>
        {
            let zoomLevelDetails = {
                value: zoomLevel.value,
                fillOpacity: getPercentage(zoomLevel.fillOpacity),
                borderFillOpacity: getPercentage(zoomLevel.borderFillOpacity),
                textOpacity: getPercentage(zoomLevel.textOpacity),
                overrideGlobalTextLabel: zoomLevel.overrideGlobalTextLabel,
                textLabel: zoomLevel.textLabel[lang],
                index
            };

            // add en reference colums if lang is not English
            if (lang !== DEFAULT_LANGUAGE_CODE)
            {
                zoomLevelDetails.textLabelEn = zoomLevel.textLabel[DEFAULT_LANGUAGE_CODE];
            }

            zoomLevels.push(zoomLevelDetails);
        });
        dataRow.zoomLevels = zoomLevels;
    }

    dataRow.numberOfZoomLevels = mapOverlay.dynamicOverlaySettings.zoomLevels.length;

    return dataRow;
};

export const validateAndGetUploadedMapOverlays = async (file, mapOverlays, consumedMapOverlays, allowedLangs, overlayTemplates, trans) =>
{
    const csvString = await parseFile(file);

    let errors = [];
    let uploadedMapOverlays = [];

    const result = Papa.parse(csvString);

    if (result.errors.length)
    {
        errors.push( trans("common.Invalid file"));
        return { errors, uploadedMapOverlays };
    }

    let data = result.data;


    if (data.length === 0) 
    {
        errors.push(trans("common.empty_file"));
        return { errors, uploadedMapOverlays };
    }

    const langCode = getLangCodeFromCsvData(data);

    if (!langCode) 
    {
        errors.push(trans("common.lang_code_required"));
        return { errors };
    }

    if (!allowedLangs.includes(langCode)) 
    {
        errors.push(trans("common.invalid_lang_code"));
        return { errors };
    }

    if (!validateHeaders(data, langCode)) 
    {
        errors.push(trans("common.invalid_headers"));
        return { errors, uploadedMapOverlays };
    }

    let dataRows = getDataRows(data, langCode);

    // remove dataRows which does not contain id or has an id not belonging to map overlays from the current floor 
    // or overlays linked to a dynamic map layer
    const mapOverlayIds = mapOverlays.map((mapOverlay) => mapOverlay._id);
    dataRows = dataRows.filter((dataRow) => dataRow.id && mapOverlayIds.includes(dataRow.id) && !consumedMapOverlays.includes(dataRow.id));
    // validate dataRow field values
    errors.push(...validateDataRows(dataRows.filter((dataRow) => !dataRow.template), trans), ...validateDataRowsWithTemplate(dataRows.filter((dataRow) => dataRow.template), overlayTemplates, trans));
    if (errors.length)
    {
        return { errors, uploadedMapOverlays };
    }
    
    // convert dataRows into mapOverlay objects which can be validated
    uploadedMapOverlays = getMapOverlaysFromDataRows(dataRows, langCode, overlayTemplates);

    updateOtherLangCodeData(uploadedMapOverlays, mapOverlays);

    removeEmptyNonDefaultLangFields(uploadedMapOverlays);

    // update vectorLayer and shape to each uploaded map overlay object which is needed for validation
    const mapOverlayToIdMap = arrayToHash(mapOverlays, "_id");
    uploadedMapOverlays.forEach((mapOverlay) =>
    {
        mapOverlay.vectorLayer = mapOverlayToIdMap[mapOverlay._id].vectorLayer;
        mapOverlay.shape = mapOverlayToIdMap[mapOverlay._id].shape;
    });

    errors = validateUploadedMapOverlays(uploadedMapOverlays, trans);

    return { errors, uploadedMapOverlays };
};

const removeEmptyNonDefaultLangFields = (uploadedMapOverlays) =>
{
    // remove all the empty text fields of non default lang codes
    uploadedMapOverlays.forEach((mapOverlay) =>
    {
        MULTI_LANG_MAP_OVERLAY_TEXT_FIELDS.forEach((textField) =>
        {
            Object.keys(mapOverlay[textField]).forEach((langCode) =>
            {
                // check if any non default lang entry for the text field is empty, if so delete the key from text field object
                if (langCode !== DEFAULT_LANGUAGE_CODE && !mapOverlay[textField][langCode])
                {
                    delete mapOverlay[textField][langCode];
                }
            });
        });

        // remove all the empty text fields from zoom levels of non default lang codes
        if (mapOverlay.dynamicOverlaySettings.enabled)
        {
            mapOverlay.dynamicOverlaySettings.zoomLevels.forEach((zoomLevel) =>
            {
                MULTI_LANG_ZOOM_LEVEL_TEXT_FIELDS.forEach((textField) =>
                {
                    Object.keys(zoomLevel[textField]).forEach((langCode) =>
                    {
                        // check if any non default lang entry for the text field is empty, if so delete the key from text field object
                        if (langCode !== DEFAULT_LANGUAGE_CODE && !zoomLevel[textField][langCode])
                        {
                            delete zoomLevel[textField][langCode];
                        }
                    });
                });
            });
        }
    });
};

const validateDataRowsWithTemplate = (dataRows, overlayTemplates, trans) => 
{
    let errors = [];

    for (let i = 0; i < dataRows.length; i++) 
    {
        const dataRow = dataRows[i];
        if (!overlayTemplates.find((overlayTemplate) => overlayTemplate?.name?.[DEFAULT_LANGUAGE_CODE] === dataRow["template"]))
        {
            errors.push(trans("common.error_in_line_invalid_or_missing_template", { index: dataRow.index }));
        }
        // if error is encountered in one of the line break from validation since it 
        // will clog up the error modal if we display error messages for all the lines
        if (errors.length) 
        {
            break;
        }
    }

    return errors;
};

const validateDataRows = (dataRows, trans) =>
{
    let errors = [];

    for (let i=0; i<dataRows.length; i++)
    {
        const dataRow = dataRows[i];

        DATAROW_BOOLEAN_VALIDATION_FIELDS.forEach((fieldName) =>
        {
            if (!validateBooleanFieldValue(dataRow[fieldName]))
            {
                errors.push(trans("common.error_in_line_invalid_fileName", { index: dataRow.index, fieldName }));
            }
        });

        if (dataRows.dynamicOverlaySettingsEnabled !== "true")
        {
            DEFAULT_OPACITY_VALIDATION_FIELDS.forEach((fieldName) =>
            {
                if (!validateOpacityFieldValue(dataRow[fieldName]))
                {
                    errors.push(trans("common.error_in_line_invalid_fileName", { index: dataRow.index, fieldName }));
                }
            });
        }
        
        COLOR_VALIDATION_FIELDS.forEach((fieldName) =>
        {
            if (!validateColorFieldValue(dataRow[fieldName]))
            {
                errors.push(trans("common.error_in_line_invalid_fileName", { index: dataRow.index, fieldName }));
            }
        });

        // validate each zoom level fields
        errors.push(...validateZoomLevels(dataRow, trans));

        // if error is encountered in one of the line break from validation since it 
        // will clog up the error modal if we display error messages for all the lines
        if (errors.length)
        {
            break;
        }
    }

    return errors;
};

const validateZoomLevels = (dataRow, trans) =>
{
    let errors = [];

    if (dataRow.dynamicOverlaySettingsEnabled === "true")
    {
        if (!dataRow.zoomLevels?.length)
        {
            errors.push(trans("common.error_in_line_no_zoom", { index: dataRow.index }));
        }
        else if (dataRow.zoomLevels.length < 2)
        {
            errors.push(trans("common.error_in_line_insufficient_zoom", { index: dataRow.index }));
        }
        else
        {
            // if sufficient number of zoom levels are provided validate each zoom level
            dataRow.zoomLevels.forEach((zoomLevel) =>
            {
        

                ZOOM_LEVEL_OPACITY_VALIDATION_FIELDS.forEach((fieldName) => 
                {
                    if (!validateOpacityFieldValue(zoomLevel[fieldName])) 
                    {
                        errors.push(trans("common.error_in_line_invalid_field", { index: dataRow.index, fieldName }));
                    }
                });

                ZOOM_LEVEL_BOOLEAN_VALIDATION_FIELDS.forEach((fieldName) => 
                {
                    if (!validateBooleanFieldValue(zoomLevel[fieldName])) 
                    {
                        errors.push(trans("common.error_in_line_invalid_or_missing_field", { index: dataRow.index, fieldName }));
                    }
                });

                ZOOM_LEVEL_VALUE_FIELDS.forEach((fieldName) => 
                {
                    if (!validateNumericFieldValue(zoomLevel[fieldName]) || !validateZoomValue(zoomLevel[fieldName])) 
                    {
                        errors.push(trans("common.error_in_line_invalid_numeric_field", { index: dataRow.index, fieldName }));
                    }
                });
            });
        }
    }

    return errors;
};

const validateOpacityFieldValue = (value) =>
{
    let isValid = true;

    if (value)
    {
        // check if value is number and falls between 0 and 100
        if (!(!isNaN(value) && value >=0 && value <= 100))
        {
            isValid = false;
        }
    }

    return isValid;
};

const validateBooleanFieldValue = (value) => value === "true" || value === "false";

const validateNumericFieldValue = (value) =>
{
    let isValid = true;

    if (value)
    {
        if (isNaN(value))
        {
            isValid = false;
        }
    }

    return isValid;
};

const validateZoomValue = (value) => value >= MAP_OVERLAY_ZOOM_CONSTANTS.MIN_ZOOM && value <= MAP_OVERLAY_ZOOM_CONSTANTS.MAX_ZOOM;

const validateColorFieldValue = (value) => value && CSS.supports("color", value);

const validateUploadedMapOverlays = (uploadedMapOverlays, trans) =>
{
    let errors = [];

    for ( let i=0; i<uploadedMapOverlays.length; i++)
    {
        const uploadedMapOverlay = uploadedMapOverlays[i];
        const validationErrors = uploadedMapOverlay.templateId ? validateLinkedMapOverlay(uploadedMapOverlay) : validateMapOverlay(uploadedMapOverlay, trans);

        if (validationErrors.length)
        {
            errors = validationErrors.map((validationError) => trans("common.error_in_line", { index: uploadedMapOverlay.index, validationError }));
            break;
        }
    }

    return errors;
};

const updateOtherLangCodeData = (uploadedMapOverlays, mapOverlays) =>
{
    const mapOverlayIdMap = arrayToHash(mapOverlays, "_id");

    // update all the text fields with other previously existing lang translations
    uploadedMapOverlays.forEach((uploadedMapOverlay) =>
    {
        const mapOverlay = mapOverlayIdMap[uploadedMapOverlay._id];
        if (mapOverlay)
        {
            uploadedMapOverlay.name = {
                ...mapOverlay.name,
                ...uploadedMapOverlay.name
            };

            uploadedMapOverlay.toolTipText = {
                ...mapOverlay.toolTipText,
                ...uploadedMapOverlay.toolTipText
            };

            if (uploadedMapOverlay.dynamicOverlaySettings.enabled)
            {
                updateOtherLangCodeInZoomLevels(uploadedMapOverlay, mapOverlay);
            }
        }
        
    });
};

const updateOtherLangCodeInZoomLevels = (uploadedMapOverlay, mapOverlay) =>
{
    let zoomLevelValueMap = {};

    if (mapOverlay.dynamicOverlaySettings.enabled)
    {
        zoomLevelValueMap = arrayToHash(mapOverlay.dynamicOverlaySettings.zoomLevels, "value");
    }

    // update zoom level text label with other previously existing lang translations
    uploadedMapOverlay.dynamicOverlaySettings.zoomLevels.forEach((zoomLevel) =>
    {
        if (zoomLevelValueMap[zoomLevel.value])
        {
            zoomLevel.textLabel = {
                ...zoomLevelValueMap[zoomLevel.value].textLabel,
                ...zoomLevel.textLabel
            };
        }
    });
};

const getMapOverlaysFromDataRows = (dataRows, lang, overlayTemplates) =>
{
    let mapOverlays = [];

    dataRows.forEach((dataRow) =>
    {
        
        let mapOverlay = cloneDeep(NEW_OVERLAY_DEFAULT);
        mapOverlay._id = dataRow.id;
        mapOverlay.name = {
            [lang]: dataRow.name || ""
        };
        mapOverlay.toolTipText = {
            [lang]: dataRow.textLabel || ""
        };

        if (dataRow.template)
        {
            mapOverlay.templateId = overlayTemplates.find((overlayTemplate) => overlayTemplate?.name?.[DEFAULT_LANGUAGE_CODE] === dataRow.template)?.templateId ;
        }
        else 
        {
            mapOverlay.color = dataRow.fillColor || "";
            mapOverlay.lineColor = dataRow.lineColor || "";
            mapOverlay.textColor = dataRow.textColor || "";
            mapOverlay.templateId = "";
            mapOverlay.defaultFillOpacity = dataRow.defaultFillOpacity ? getValueFromPercentage(dataRow.defaultFillOpacity) : undefined;
            mapOverlay.defaultBorderFillOpacity = dataRow.defaultBorderFillOpacity ? getValueFromPercentage(dataRow.defaultBorderFillOpacity) : undefined;
            mapOverlay.defaultTextOpacity = dataRow.defaultTextOpacity ? getValueFromPercentage(dataRow.defaultTextOpacity) : undefined;

            mapOverlay.dynamicOverlaySettings.enabled = dataRow.dynamicOverlaySettingsEnabled === "true";

            // update zoomLevels if dynamic settings is enabled
            if (mapOverlay.dynamicOverlaySettings.enabled)
            {
                let zoomLevels = [];
                dataRow.zoomLevels.forEach((zoomLevel) =>
                {
                    zoomLevels.push({
                        value: +zoomLevel.value,
                        fillOpacity: zoomLevel.fillOpacity ? getValueFromPercentage(zoomLevel.fillOpacity) : undefined,
                        borderFillOpacity: zoomLevel.borderFillOpacity ? getValueFromPercentage(zoomLevel.borderFillOpacity) : undefined,
                        textOpacity: zoomLevel.textOpacity ? getValueFromPercentage(zoomLevel.textOpacity) : undefined,
                        textLabel: {
                            [lang]: zoomLevel.textLabel || ""
                        },
                        overrideGlobalTextLabel: zoomLevel.overrideGlobalTextLabel === "true"
                    });
                });

                mapOverlay.dynamicOverlaySettings.zoomLevels = zoomLevels;

                // update default settings with startZoom values
                if (zoomLevels.length)
                {
                    const startZoomLevel = zoomLevels[0];

                    mapOverlay.defaultFillOpacity = startZoomLevel.fillOpacity;
                    mapOverlay.defaultBorderFillOpacity = startZoomLevel.borderFillOpacity;
                    mapOverlay.defaultTextOpacity = startZoomLevel.defaultTextOpacity;
                }
            }
            else
            {
                mapOverlay.dynamicOverlaySettings.zoomLevels = [];
            }
        }
        mapOverlay.index = dataRow.index;

        mapOverlays.push(mapOverlay);
    });

    return mapOverlays;
};

const getDataRows = (data, lang=DEFAULT_LANGUAGE_CODE) =>
{
    let dataRows = [];

    // remove last row if it is empty
    if (data[data.length - 1].length === 1 && data[data.length - 1][0].trim() === "")
    {
        data.splice(data.length - 1);
    }

    const numOfCommonFileColumns = getNumOfCommonFileColumns(lang);

    // remove lang code and header row and iterate
    data.splice(2).forEach((dataPoint, index) =>
    {
        let dataRow = {};

        // get common column data
        if (dataPoint.length >= numOfCommonFileColumns)
        {
            let commonColumnHeaders = Object.keys(getCommonFileColumns(lang));
            let commonColumnData = dataPoint.slice(0, numOfCommonFileColumns);
            commonColumnData.forEach((columnValue, index) =>
            {
                if (columnValue.trim())
                {
                    let trimmedColumnValue = columnValue.trim();
                    if (commonColumnHeaders[index] === "dynamicOverlaySettingsEnabled")
                    {
                        trimmedColumnValue = trimmedColumnValue.toLowerCase();
                    }
                    dataRow[commonColumnHeaders[index]] = trimmedColumnValue;
                }
            });
        }

        // get zoom level data
        if (dataPoint.length > numOfCommonFileColumns)
        {
            let zoomLevels = [];
            let zoomLevelHeaders = Object.keys(getZoomLevelFileColumns(lang));
            let zoomLevelColumnData = dataPoint.slice(numOfCommonFileColumns, dataPoint.length);

            // chunck data point columns with number of zoom level columns
            const chunkSize = getNumOfZoomLevelFileColumns(lang);
            for (let i = 0; i < zoomLevelColumnData.length; i += chunkSize)
            {
                const chunck = zoomLevelColumnData.slice(i, i+chunkSize);
                let singleZoomLevelData = {};
                chunck.forEach((columnValue, index) =>
                {
                    if (columnValue.trim())
                    {
                        let trimmedColumnValue = columnValue.trim();
                        if (zoomLevelHeaders[index] === "overrideGlobalTextLabel")
                        {
                            trimmedColumnValue = trimmedColumnValue.toLowerCase();
                        }
                        singleZoomLevelData[zoomLevelHeaders[index]] = trimmedColumnValue;
                    }
                });

                // if all the fields of single zool level data is not empty add it to zoomLevels array
                if (Object.keys(singleZoomLevelData).length)
                {
                    zoomLevels.push(singleZoomLevelData);
                }
            }

            dataRow.zoomLevels = zoomLevels;
        }

        // add index (after offsetting header line) which will be helpful while displaying error message if data validation fails
        dataRow.index = index + 3;

        dataRows.push(dataRow);
    });
    

    return dataRows;
};

const validateHeaders = (data, lang=DEFAULT_LANGUAGE_CODE) =>
{
    const headers = data[1];
    const trimmedHeaders = headers.map((header) => header.trim());
    return validateCommonHeaders(trimmedHeaders, lang) && validateZoomHeaders(trimmedHeaders, lang);
};

const validateCommonHeaders = (headers, lang=DEFAULT_LANGUAGE_CODE) =>
{
    let isValid = false;

    const numOfCommonFileColumns = getNumOfCommonFileColumns(lang);

    if (headers.length >= numOfCommonFileColumns)
    {
        const commonHeaders = headers.slice(0, numOfCommonFileColumns);
        const validCommonHeaders = Object.values(getCommonFileColumns(lang));
        isValid = validCommonHeaders.join(",") === commonHeaders.join(",");
    }

    return isValid;
};

const validateZoomHeaders = (headers, lang=DEFAULT_LANGUAGE_CODE) =>
{
    let isValid = true;

    const numOfCommonFileColumns = getNumOfCommonFileColumns(lang);

    if (headers.length > numOfCommonFileColumns)
    {
        const zoomLevelHeaders = headers.slice(numOfCommonFileColumns, headers.length);
        const validZoomLevelHeaders = Object.values(getZoomLevelFileColumns(lang));
        
        // chunck zoomLevelHeaders with snumber of columns for zoom level details and validate each set
        const chunkSize = getNumOfZoomLevelFileColumns(lang);
        for (let i = 0; i < zoomLevelHeaders.length; i += chunkSize)
        {
            const chunck = zoomLevelHeaders.slice(i, i+chunkSize);
            if (validZoomLevelHeaders.join(",") !== chunck.join(","))
            {
                isValid = false;
                break;
            }
        }
    }

    return isValid;
};