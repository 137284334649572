/* eslint-disable indent */
import classNames from "classnames";
import React, { useEffect, useMemo, useRef } from "react";
import { Button, Image, Modal } from "semantic-ui-react";
import { GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES, GEO_REFERENCING_FLOOR_PLAN_TABS_INFO } from "../../../_constants/mapEditor";
import { ButtonIcon } from "../../common/ButtonIcon";
import PdfToImageViewer from "../../common/PdfToImageViewer/PdfToImageViewer";
import { Loader } from "../../elements/loader";
import "./floorPlanGeoReference.css";
import useFloorPlanCommon from "./hooks/useFloorPlanCommon";
import { Gms } from "../../branding/Gms/Gms";

const PdfImageSelectorModal = ({ open, onClose, onOpen, onAddImage, setLoading }) => <Modal
	className="modalLayout PDFSelectionModal"
	onClose={onClose}
	onOpen={onOpen}
	open={open}
	closeIcon={<Button className="close" onClick={onClose} />}
	closeOnEscape={false}

>
	<Modal.Header>Import FloorPlan From Pdf</Modal.Header>
	<Modal.Content image>
		<PdfToImageViewer onAddImage={onAddImage} setLoading={setLoading} />
	</Modal.Content>
</Modal>;

const FloorPlanImportOrUpload = () =>
{
	const gmsRef = useRef(null);
	const {
		toggleImageUploadModal,
		isImageSelectorOpen,
		onCLoseImageSelectorModal,
		perSelectImageId,
		activeFloorPlanUrl,
		isPdfImportModalIsOpen,
		togglePdfUploadModal,
		onOpenImagePdfSelectorModal,
		onCLosePdfSelectorModal,
		onImportFloorPlan,
		selectedFloorPlanImageId,
		onTabActionWithToast,
		setLoading,
		floorPlanUrlBase64Query
	} = useFloorPlanCommon();


	const imageUrlToLoad = useMemo(() =>
	{
		if (selectedFloorPlanImageId)
		{
			if (floorPlanUrlBase64Query.isLoading)
			{
				return "/img/editor/addfloorplan.svg";
			}
			else
			{
				return floorPlanUrlBase64Query.data;
			}
		}
		return "/img/editor/addfloorplan.svg";
	}, [floorPlanUrlBase64Query.dataUpdatedAt, selectedFloorPlanImageId,]);

	useEffect(() => 
    {
    if (isImageSelectorOpen) 
    {
    const addedImageFilerIds = [];
	if (perSelectImageId) addedImageFilerIds.push(perSelectImageId);
      gmsRef.current.linkIconImage({ addedImageFilerIds });
    }
  }, [isImageSelectorOpen]);

	return (
		<>
			<Gms croppingEnabled={false}
				bulkUploadEnabled={false}
				aspectRatioFilterEnabled={false} ref={gmsRef}
				onAddImage={(filerId) => onImportFloorPlan(filerId)}>
			</Gms>
			<PdfImageSelectorModal
				open={isPdfImportModalIsOpen}
				onClose={onCLosePdfSelectorModal}
				onOpen={onOpenImagePdfSelectorModal}
				onAddImage={({ filerId }) => onImportFloorPlan(filerId)}
				setLoading={setLoading}

			/>
			<div className="mapEditorContentbody floorPlan-body">
				<div className="floorPlanPreview">
					<div className={classNames("image-preview-container", { preparingImageUrl: floorPlanUrlBase64Query.isLoading })}>
						{/* fixed blank image issue */}
						<Image src={imageUrlToLoad} fluid />
					</div>

					<div className="floorplanPreviewActions">
						<ButtonIcon onClick={toggleImageUploadModal}>Import from Gallery</ButtonIcon>
						<ButtonIcon onClick={togglePdfUploadModal}>Import from PDF</ButtonIcon>
						{activeFloorPlanUrl && <Button primary onClick={() => onTabActionWithToast(GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES.geoReference)} >{GEO_REFERENCING_FLOOR_PLAN_TABS_INFO.geoReference.name}</Button>}

					</div>
				</div>
			</div>
			<Loader active={floorPlanUrlBase64Query.isLoading} />
		</>
	);
};

export default FloorPlanImportOrUpload;
