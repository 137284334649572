import React from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Button, Image } from "semantic-ui-react";
import { DataListingTableColumn } from "../../pages/branding/listing/DataTableColumn";
import { PaginationBar } from "../../branding/listing/Pagination";
import { useTranslation } from "react-i18next";
import TemplatesActions from "./TemplatesAction";
import { useOverlaysTemplateContext } from "../../../store/OverlaysTemplateContext";
import { formatDateTime } from "../../../_utils/utils";

export const TemplatesDataTable = ({ displayProperties, pagination }) =>
{
    const trans = useTranslation().t;
    let propertyListDisplay = displayProperties.length ? getPropertyDisplayElementsArray(displayProperties, trans) : <EmptyDashboardList />;

    return (
        <>
            <div className="dataListingTableBox dashboardListing">
                <Scrollbars className="tableScroll tableScrollDashboard" autoHeight autoHeightMin="calc(1vh)" autoHeightMax="calc(80vh - 100px)" >
                    <DataListingTableHead trans={trans}/>
                    {propertyListDisplay}
                </Scrollbars>
            </div>
            {
                displayProperties.length ?  <PaginationBar
                    totalPages={pagination.totalPages}
                    activePage={pagination.currentPage}
                    setPage={pagination.setPage}
                    startIndex={pagination.startIndex}
                    endIndex={pagination.endIndex}
                    total={pagination.nElements} 
                /> : <></>
            }
        </>
    );
};

const getPropertyDisplayElementsArray = (displayProperties, trans) => (
    displayProperties.map((templateDetails) => (
        <TableRow
            key={templateDetails._id}
            templateDetails={templateDetails}
            trans={trans}
        />
    ))
);

const DataListingTableHead = ({ trans }) => (
    <div className="dataListingTableRow dataListingTableRow-head">
        <DataListingTableColumn detail={trans("Templates.Template_Name")} className="tableHeading" medium/>
        <DataListingTableColumn detail={trans("Templates.Group_Name")} className="tableHeading" medium/>
        <DataListingTableColumn detail={trans("Templates.Fill_Color")} className="tableHeading" narrow/>
        <DataListingTableColumn detail={"Fill Color Opacity"} className="tableHeading" narrow/>
        <DataListingTableColumn detail={trans("Templates.Border_Color")} className="tableHeading" narrow/>
        <DataListingTableColumn detail={"Border Color Opacity"} className="tableHeading" narrow/>
        <DataListingTableColumn detail={trans("Templates.Text_Color")} className="tableHeading" narrow/>
        <DataListingTableColumn detail={"Text Color Opacity"} className="tableHeading" narrow/>
        <DataListingTableColumn detail={trans("Templates.Dynamic_Zoom")} className="tableHeading" narrow/>
        <DataListingTableColumn detail={trans("Templates.Created_On")} className="tableHeading" medium/>
        <DataListingTableColumn detail={trans("Templates.Action")} className="tableHeading" narrow/>
    </div>
);

export const TableRow = ({ templateDetails, trans }) => (
    <div className="dataListingTableRow">
        <div className="dataListingTableColumn medium" title={templateDetails.name.en}>
            <DataListingTableColumn detail={templateDetails.name.en}  isPopup={true} medium />
        </div>

        {
            templateDetails.group ? (
                <div className="dataListingTableColumn medium" title={templateDetails.group}>
                    <DataListingTableColumn detail={templateDetails.group ? templateDetails.group : "-"} isPopup={true} medium />
                </div>
            ) : (
                <DataListingTableColumn detail={templateDetails.group ? templateDetails.group : "-"} medium />
            )
        }
        <DataListingTableColumn narrow>
            <div className="templateActionButtons"  style={{ backgroundColor: templateDetails.color }}/>
        </DataListingTableColumn>
        <DataListingTableColumn narrow>
            {templateDetails.dynamicOverlaySettings.enabled ? "-" : <span>{templateDetails.defaultFillOpacity * 100}%</span>}
            
        </DataListingTableColumn>
        <DataListingTableColumn narrow>
            <div className="templateActionButtons"  style={{ backgroundColor: templateDetails.lineColor }}/>
        </DataListingTableColumn>
        <DataListingTableColumn narrow>
            {templateDetails.dynamicOverlaySettings.enabled ? "-" : <span>{templateDetails.defaultBorderFillOpacity * 100}%</span>}
            
        </DataListingTableColumn>
        <DataListingTableColumn narrow>
            <div className="templateActionButtons"  style={{ backgroundColor: templateDetails.textColor }}/>
        </DataListingTableColumn>
        <DataListingTableColumn narrow >
            {templateDetails.dynamicOverlaySettings.enabled ? "-" : <span>{templateDetails.defaultTextOpacity * 100}%</span>}
        </DataListingTableColumn>
        <DataListingTableColumn narrow detail={templateDetails.dynamicOverlaySettings.enabled ? trans("Templates.Yes") : "-" } />
        <DataListingTableColumn detail={formatDateTime(templateDetails.createdAt)} medium/>
        <DataListingTableColumn narrow>
            <div className="actionButton">
                <TemplatesActions templateDetails={templateDetails}/>
            </div>
        </DataListingTableColumn>
    </div>
);

const EmptyDashboardList = () => 
{
    const trans = useTranslation().t;
    const { initiateOverlayTemplate, loadingPool, filteredOverlayTemplates } = useOverlaysTemplateContext();

    return <div className="dataListingTableRow emptyDashboardList">
        {
            loadingPool.loading ? <></>: <>
                <Image src="/img/icon-no-templates.svg" as="span" />
                {
                    !filteredOverlayTemplates.length ? <>
                        <strong>{trans("OverlaysTemplateSideBar.No_Template_Found")}</strong>{trans("Templates.Try_Adjusting_Search")}
                    </> : <> 
                        <strong>{ trans("OverlaysTemplateSideBar.No_Template_Found") }</strong>
                        <Button className={"createNewTemplate"} onClick={initiateOverlayTemplate}>{trans("Templates.Create_New_Template")}</Button> 
                    </>
                }
            </>
        }
    </div>;
};              