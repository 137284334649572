import React from 'react';
import useFloorPlanCommon from "./hooks/useFloorPlanCommon";
import { GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES } from "../../../_constants/mapEditor";
import { ShortCutsPopupItem } from "../mapEditorSideBars/MapEditorLeftbar";

const FloorPlanShoutCutsRender = ({ children }) =>
{
    const { activeTab } = useFloorPlanCommon();
    //if floor plan is not active render children from parent else render shortcuts for floor plan tab
    if (!activeTab)
    {
        return children;
    }
    if (activeTab === GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES.floorPlanImage)
    {
        return <div className="floor-plan-image-sc">
            <ShortCutsPopupItem title="Ctrl + Z" content="Undo" />
            <ShortCutsPopupItem title="Ctrl + Y" content="Redo" />
        </div>;
    }
    if (activeTab === GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES.geoReference)
    {
        return <div className="floor-plan-geo-reference-sc">
            <ShortCutsPopupItem title="Ctrl + Z" content="Undo" />
            <ShortCutsPopupItem title="Ctrl + Y" content="Redo" />
            <div><strong>While Editing</strong></div><br />
            <ShortCutsPopupItem title="Alt" content="Delete Vertex" />

        </div>;
    }
    if (activeTab === GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES.geoReferenceEdit)
    {
        return <div className="floor-plan-geo-reference-edit-sc">
            <ShortCutsPopupItem title="Ctrl + Z" content="Undo" />
            <ShortCutsPopupItem title="Ctrl + Y" content="Redo" />
            <div><strong>While Editing</strong></div><br />
            <ShortCutsPopupItem title="Shift | Ctrl" content="Translate Scale&Rotate" />
        </div>;
    }
    return null;
};

export default FloorPlanShoutCutsRender;
