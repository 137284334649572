import React, { createContext, useContext, useEffect } from "react";
import { useSetState } from "ahooks";
import BrandingContext from "./BrandingContext";
import serverApi from "../_api/server.api";
import { useLoadingPool } from "../_utils/hooks";
import { Loader } from "../components/elements/loader";
import { deepValue } from "mapsted.utils/objects";

export const PublicMapDataContext = createContext({});

export const PublicMapDataProvider = ({ children }) =>
{
    const brandingCtx = useContext(BrandingContext);
    const brandingCtxState = brandingCtx.state;

    const loadingPool = useLoadingPool();

    const [state, setState] = useSetState({
        propertyId: undefined,
        buildingId: undefined,
        floorId: undefined,
        mapData: {},
        propertySettings: null
    });

    async function fetchPropertySettings(propertyId) 
    {
        const loadingId = loadingPool.add();
        try 
        {
            const response = await serverApi.getPublicSettings(propertyId);
            setState({ propertySettings: response });
        }
        catch (err) 
        {
            console.error(err);
        }
        finally 
        {
            loadingPool.remove(loadingId);
        }
    }

    useEffect(() =>
    {
        let updatedState = {
            propertyId: undefined,
            buildingId: undefined,
            floorId: undefined,
        };

        if (brandingCtxState?.propertyId && brandingCtxState?.properties)
        {
            const property = brandingCtxState.properties[brandingCtxState.propertyId];

            if (property?.propertyId)
            {
                updatedState.propertyId = property.propertyId;

                if (brandingCtxState?.buildingId && property?.buildings?.[brandingCtxState.buildingId])
                {
                    const building = property.buildings[brandingCtxState.buildingId];

                    if (building?.buildingId)
                    {
                        updatedState.buildingId = building.buildingId;
                    }

                    if (brandingCtxState?.floorId)
                    {
                        const floor = (building?.floors || []).find((floor) => floor._id === brandingCtxState.floorId);

                        updatedState.floorId = floor?.floorId || undefined;
                    }
                }
            }
        }

        setState({
            ...updatedState
        });
    }, 
    [   
        brandingCtxState?.propertyId,
        brandingCtxState?.buildingId,
        brandingCtxState?.floorId,
        brandingCtxState?.properties
    ]);

    useEffect(() => 
    {
        if (state.propertyId)
        {
            fetchPropertySettings(state.propertyId);
        }
    }, [state.propertyId]);

    useEffect(() =>
    {
        updateMapData(state.propertyId, state.buildingId);
    }, 
    [
        state.propertyId,
        state.buildingId,
    ]);

    const updateMapData = async (propertyId, buildingId) =>
    {
        let mapData = {};

        if (propertyId)
        {
            let resp;

            const loadingId = loadingPool.add();
            try
            {
                if (buildingId)
                {
                    resp = await serverApi.getPublicEntities(propertyId, buildingId);
                }
                else
                {
                    resp = await serverApi.getPublicPropertyEntities(propertyId);
                }
            }
            catch (err)
            {
                console.log("Error while fetching public map data");
            }
            finally
            {
                loadingPool.remove(loadingId);
            }
            

            if (resp?.success)
            {
                mapData = {
                    entities: resp.entities,
                    styles: resp.styles,
                    themeDetails: resp.themeDetails,
                    building: resp.building,
                };
            }
        }

        setState({
            mapData
        });
    };

    const handleFloorChange = (floorId) =>
    {
        const { propertyId, buildingId, properties } = brandingCtxState;

        const building = deepValue(properties, `${propertyId}.buildings.${buildingId}`, undefined);

        if (building?.floors)
        {
            const floor = building.floors.find((floor) => floor.floorId === floorId);

            if (floor)
            {
                brandingCtx.changeSelectedFloor(floor._id);
            }
        }
    };

    const value = {
        state,
        loadingPool,
        handleFloorChange,
    };

    return (
        <PublicMapDataContext.Provider value={value}>
            <Loader active={loadingPool.loading} />
            {children}
        </PublicMapDataContext.Provider>
    );
};