import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Header, Input } from "semantic-ui-react";
import { DropdownForm } from "../common/DropdownForm";
import { TextGroup } from "../common/TextGroup";
import { EntityType, EntityTypes, isEntityNameable } from "mapsted.maps/utils/entityTypes";
import { CMSEntityAccess } from "mapsted.maps/mapFunctions/entityAccess";
import { deepCopy } from "../../_utils/utils";
import LanguageSelector from "../common/LanguageSelector";
import { DEFAULT_LANGUAGE_CODE, SINGLE_LANG_INPUT_CODE } from "../../_constants/constants";
import { useDebounce } from "../../_utils/debounce.hook";

const disabled_entity_type_options = [`${EntityType.POINT_OF_INTEREST}`, `${EntityType.BOUNDARY}`, `${EntityType.UNKNOWN_ENTITY}`,];
const disabled_sub_entity_type_unknown = "0";
/**
 * Function to load options for entity type based on the selected entity type
 *
 * @todo may be we need to load the options based on selected tool lets confirm that with brandon will see in future
 * @param {Object} selectedEntity - the selected entity object
 * @return {Array} - array of entity type options
 */
const loadOptionsBasedOnSelectedEntityType = (selectedEntity) =>
{

    const entityInfo = selectedEntity?.entityInfo;

    const optionsHash = {};

    // loop through all entity types
    Object.keys(EntityTypes).forEach((entityTypeKey) =>
    {
        // if entity type is disabled and not selected, skip it
        if (disabled_entity_type_options.includes(entityTypeKey))
        {
            if (Number(entityInfo.entityType) !== Number(entityTypeKey))
            {
                return;
            }
        }

        // get the entity type object
        const entityType = EntityTypes[entityTypeKey];


        // add entity type option to the list
        optionsHash[entityTypeKey] = ({
            key: entityTypeKey,
            value: entityTypeKey,
            text: entityType.name,
        });
    });


    let isPOIOptionAdded = !!optionsHash[EntityType.POINT_OF_INTEREST];

    // if shape is a point and POI option was not added, add it
    if (entityInfo?.shape?.type === "Point" && !isPOIOptionAdded)
    {
        const OPI_KEY = EntityType.POINT_OF_INTEREST.toString();
        const entityType = EntityTypes[OPI_KEY];
        optionsHash[OPI_KEY] = ({
            key: OPI_KEY,
            value: OPI_KEY,
            text: entityType.name,
        });
    }

    // return the entity type options
    return Object.values(optionsHash).sort((a, b) => a.text - b.text);
};
export const MapEditorEditInfoSidebar = ({ selectedEntity, onSave, onChangeEntityStyle, onChangeEntityText }) =>
{
    // use entity types to get a list of options
    // sub entity type options are based off of entity type
    /**
     * Entity types structure for used to create drop down options
     * {
     *  [entityTypeCode]: {
     *      name: "readable name"
     *      subTypes :[
     *          [subTypeCode]: {
     *              name: "readable name"
     *           }
     *       ]
     *  }
     * }
     */

    const [entityInfo, setEntityInfo] = useState({
        name: "",
        entityType: "0",
        subEntityType: "0",
    });

    const [updateTextInfo, setUpdateTextInfo] = useState(undefined);

    const debouncedUpdateInfo = useDebounce(updateTextInfo, 150);

    const [languageCode, setLanguageCode] = useState(DEFAULT_LANGUAGE_CODE);

    // change to one state `entityInfo`

    // on selected entity change, set local entity type and subtype
    useEffect(() =>
    {
        let entityInfo;

        if (selectedEntity)
        {
            entityInfo = deepCopy(selectedEntity.entityInfo);

            entityInfo.entityType = `${entityInfo.entityType}` || "0";
            entityInfo.subEntityType = `${entityInfo.subEntityType}` || "0";
        }

        if (!entityInfo.entityLabel)
        {
            entityInfo.entityLabel = {
                longName: {
                    [DEFAULT_LANGUAGE_CODE]: "",
                },
                shortName: {
                    [DEFAULT_LANGUAGE_CODE]: "",
                },
                style: {
                    [DEFAULT_LANGUAGE_CODE]:
                    {
                        useText: true,
                    }
                }
            };
        }

        if (!entityInfo.style)
        {
            entityInfo.style = {
                [DEFAULT_LANGUAGE_CODE]:
                {
                    useText: true,
                }
            };
        }

        setEntityInfo(entityInfo);
    }, [selectedEntity]);

    useEffect(() =>
    {
        if (debouncedUpdateInfo)
        {
            callMapUpdateTextCallback(debouncedUpdateInfo);
        }
    }, [debouncedUpdateInfo]);

    /**
     * Memo to pull all available language codes dependent on long name
     */
    const availableLanguageCodes = useMemo(() =>
    {
        let languageCodes = [];
        if (entityInfo?.entityLabel?.longName)
        {
            languageCodes = Object.keys(entityInfo?.entityLabel?.longName);
        }
        else
        {
            languageCodes = [SINGLE_LANG_INPUT_CODE];
        }

        return languageCodes;
    }, [entityInfo]);

    /**
     * Memo for entity type drop down
     */
    const entityTypeDropdownOptions = useMemo(() => loadOptionsBasedOnSelectedEntityType(selectedEntity), [selectedEntity]);

    /**
     * Memo for sub entity type drop down
     */
    const subEntityTypeDropdownOptions = useMemo(() =>
    {
        // need to return an array of {key, text, value}
        let subEntityTypeOptions = [];
        let disabled = false;

        let subTypes = EntityTypes[entityInfo?.entityType].subTypes;

        Object.keys(subTypes).forEach((subEntityTypeKey) =>
        {
            // don't show unknown sub type if its not selected
            if (subEntityTypeKey === disabled_sub_entity_type_unknown && entityInfo.subEntityType !== disabled_sub_entity_type_unknown)
            {
                return;
            }

            subEntityTypeOptions.push({
                key: subEntityTypeKey,
                value: subEntityTypeKey,
                text: subTypes[subEntityTypeKey].name,
                disabled: disabled
            });
        });

        return subEntityTypeOptions;
    }, [entityInfo]);

    const callMapUpdateTextCallback = useCallback((updateTextInfo) =>
    {
        if (updateTextInfo)
        {
            onSave(updateTextInfo);
        }
    }, [onSave]);

    const updateEntityInfo = useCallback((newEntityInfo, isText) =>
    {
        if (!!onChangeEntityStyle && !!onChangeEntityText)
        {
            let prevEntityAccess = new CMSEntityAccess(entityInfo);
            let newEntityAccess = new CMSEntityAccess(newEntityInfo);
            onChangeEntityStyle(prevEntityAccess, newEntityAccess);
            onChangeEntityText(prevEntityAccess, newEntityAccess);

            setEntityInfo(newEntityInfo);

            if (isText)
            {
                setUpdateTextInfo(newEntityInfo);
            }
            else
            {
                onSave(newEntityInfo);
            }
        }

    }, [entityInfo, setEntityInfo, onChangeEntityStyle]);

    const handleNameOnEntityTypeChange = useCallback((newEntityInfo) =>
    {
        if (!entityInfo)
        {
            return;
        }

        const { entityType, subEntityType } = newEntityInfo;
        const isNameableEntity = isEntityNameable(entityType, subEntityType);

        let updatedEntityInfo = deepCopy(newEntityInfo);

        // if entity changed to not nameable and has a long name
        if (!isNameableEntity)
        {
            updatedEntityInfo.entityLabel.longName = { [languageCode]: "" };
            updatedEntityInfo.entityLabel.shortName = { [languageCode]: "" };
        }

        return updatedEntityInfo;

    }, [languageCode]);

    const handleEntityTypeChange = useCallback((e, { value }) =>
    {
        let newEntityInfo = deepCopy(entityInfo);

        newEntityInfo.entityType = value;
        newEntityInfo.subEntityType = "0";

        newEntityInfo = handleNameOnEntityTypeChange(newEntityInfo);

        updateEntityInfo(newEntityInfo);
    }, [entityInfo, updateEntityInfo, handleNameOnEntityTypeChange]);

    const handleSubEntityTypeChange = useCallback((e, { value }) =>
    {
        let newEntityInfo = deepCopy(entityInfo);

        newEntityInfo.subEntityType = value;

        newEntityInfo = handleNameOnEntityTypeChange(newEntityInfo);

        updateEntityInfo(newEntityInfo);
    }, [entityInfo, updateEntityInfo, handleNameOnEntityTypeChange]);

    // Language Handlers
    const handleEntityLabelChange = useCallback((e, { name, value }) =>
    {
        // input calls on change event on ctrl+z/y we have to ignore that to make sure it doesn't interfere with our undo/redo
        if (e.nativeEvent.inputType === "historyUndo" || e.nativeEvent.inputType === "historyRedo")
        {
            return;
        }

        let newEntityInfo = deepCopy(entityInfo);

        if (!newEntityInfo.entityLabel[name])
        {
            newEntityInfo.entityLabel[name] = {};
        }

        newEntityInfo.entityLabel[name][languageCode] = value;

        if (name === "longName" && value === "")
        {
            if (!newEntityInfo.entityLabel["shortName"])
            {
                newEntityInfo.entityLabel["shortName"] = {};
            }
            newEntityInfo.entityLabel["shortName"][languageCode] = "";
        }

        if (!newEntityInfo.style[languageCode])
        {
            newEntityInfo.style[languageCode] = { useText: true };
        }

        updateEntityInfo(newEntityInfo, true);
    }, [entityInfo, languageCode, updateEntityInfo]);

    const handleSetLanguageCode = useCallback((languageCode) =>
    {
        if (entityInfo?.entityLabel)
        {
            if (!entityInfo.entityLabel.longName[languageCode])
            {
                let updatedEntityInfo = deepCopy(entityInfo);
                updatedEntityInfo.entityLabel.longName[languageCode] = "";
                updatedEntityInfo.entityLabel.shortName[languageCode] = "";

                setEntityInfo(updatedEntityInfo);
            }
        }
        setLanguageCode(languageCode);
    }, [setLanguageCode, setEntityInfo, entityInfo]);

    /**
     * Disable entity type option if entity is a boundary
     */
    const isEntityTypeDisable = useMemo(() =>
    {
        const entityType = entityInfo?.entityType;
        return (entityType == EntityType.BOUNDARY);
    }, [entityInfo]);

    /**
     * hide sub options for POI for now since its only "unknown POI"
     */
    const isSubEntityTypeShown = useMemo(() =>
    {
        const entityType = entityInfo?.entityType;
        return (entityType != EntityType.POINT_OF_INTEREST && entityType != EntityType.UNKNOWN_ENTITY);
    }, [entityInfo]);

   
    /**
     * Returns a boolean indicating whether the long name field should be disabled.
     * This is based on whether the entity can be named according to the entity type and
     * sub type. If the entity can be named, and the long name field is currently empty,
     * the field is not disabled. Otherwise, the field is disabled.
     * 
     * @returns {boolean} True if the long name field should be disabled, false otherwise.
     */
    const isLongNameDisabled = useMemo(() =>
    {
        const canNameEntity = isEntityNameable(entityInfo?.entityType, entityInfo?.subEntityType);
        const longName = entityInfo?.entityLabel?.longName?.[languageCode];
        
        if (longName?.length)
        {
            return false;
        }

        return (!canNameEntity);
    },[entityInfo]);


    /**
     * Returns a boolean indicating whether the short name field should be disabled.
     * This is based on whether the entity can be named according to the entity type and
     * sub type, and whether the long name field is currently empty. If the entity can be
     * named, and the long name field is not empty, the short name field is not
     * disabled. Otherwise, the field is disabled.
     *
     * @returns {boolean} True if the short name field should be disabled, false otherwise.
     */
    const isShortNameDisabled = useMemo(()=>
    {
        const canNameEntity = isEntityNameable(entityInfo?.entityType, entityInfo?.subEntityType);
        const longName = entityInfo?.entityLabel?.longName?.[languageCode];
        const shortName = entityInfo?.entityLabel?.shortName?.[languageCode];

        if (shortName?.length)
        {
            return false;
        }

        return (!canNameEntity ||  longName?.length === 0);
    },[entityInfo]);

    return (
        <div className="mapEditorEditInfoSidebar">
            <div className="headerSidebar">
                <Header>Edit Info</Header>
            </div>

            <Scrollbars className="mapEditorScroll" autoHeight autoHeightMin="calc(100vh - 220px)" autoHeightMax="calc(100vh - 220px)" >
                <div className="alignerMapRightbar">
                    <LanguageSelector
                        activeLang={languageCode}
                        defaultDisplayedLangs={availableLanguageCodes}
                        setActiveLang={handleSetLanguageCode}
                    />

                    <TextGroup title="Entity Name">
                        <Input
                            disabled={isLongNameDisabled}
                            placeholder="Entity Name..."
                            value={entityInfo?.entityLabel?.longName?.[languageCode] || ""}
                            name="longName"
                            onChange={handleEntityLabelChange} />
                    </TextGroup>

                    <TextGroup title="Entity Short Name">
                        <Input
                            disabled={isShortNameDisabled}
                            placeholder="Entity Short Name..."
                            value={entityInfo?.entityLabel?.shortName?.[languageCode] || ""}
                            name="shortName"
                            onChange={handleEntityLabelChange} />
                    </TextGroup>

                    <TextGroup title="Entity Type">
                        <DropdownForm
                            options={entityTypeDropdownOptions}
                            disabled={isEntityTypeDisable}
                            value={entityInfo.entityType}
                            onChange={handleEntityTypeChange} />
                    </TextGroup>

                    {(isSubEntityTypeShown) && (
                        <TextGroup title="Sub-Entity Type">
                            <DropdownForm
                                options={subEntityTypeDropdownOptions}
                                value={entityInfo.subEntityType}
                                disabled={isEntityTypeDisable}
                                onChange={handleSubEntityTypeChange} />
                        </TextGroup>
                    )}

                </div>

            </Scrollbars>
        </div>
    );
};
