import { createEntityFeature, createEntityIdFeature, createEntityTextFeature, getGeometryCenter } from "mapsted.maps/mapFunctions/cmsVectorLayers";
import { AbstractEntityAccess, CMSEntityAccess } from "mapsted.maps/mapFunctions/entityAccess";
import { createEntityAccessSelectedStyle, createEntityAccessStyle, createEntityTextStyle } from "mapsted.maps/mapFunctions/entityAccessFunctions";
import { convertFeatureGeometryToGeoJSON } from "mapsted.maps/mapFunctions/features";
import { changeMapCenterWithBoundaryPolygon, changeMapCenterWithMercator, getSelectedEntityFromClick } from "mapsted.maps/mapFunctions/interactions";
import { cartoLayer, createEntityGeometry, createEntityStyle, createPointStyle, createVectorLayer } from "mapsted.maps/mapFunctions/plotting";
import { setMapLayers } from "mapsted.maps/mapFunctions/publicVectorLayers";
import { EntityType, PointOfInterestType, ShapeTypes } from "mapsted.maps/utils/entityTypes";
import { DrawInteraction, modifyEntityStyleFunction, ModifyInteraction, ModifyScaleRotateInteraction, MouseInteraction, SnapInteraction, TranslateInteraction, } from "mapsted.maps/utils/interactionTemplates";
import { DEFAULT_ERROR_HIGHLIGHT_STYLE, DEFAULT_ERROR_HIGHLIGHT_STYLE_POINT, EDITOR_POI_GEOMETRY_OPTIONS, MapEditorConstants, SNAP_PIXEL_TOLERANCE } from "mapsted.maps/utils/map.constants";
import { getTextFeatureIdWithEntityId, mercatorCoordinateArrayToGpsLocations } from "mapsted.maps/utils/map.utils";
import { Map, View, } from "ol";
import { Attribution, defaults as defaultControls } from "ol/control";
import { getCenter } from "ol/extent";
import { LineString, Polygon } from "ol/geom";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "sonner";
import { DELETE_CONDITION, EDITOR_ALERTS, FILTERED_ID_TYPES, MAP_EDITOR_TOOLS, MAP_LAYERS, MODIFY_CONDITION, SCALE_AND_ROTATE_CONDITION, TRANSLATE_CONDITION } from "../../../_constants/mapEditor";
import { areCoordinatesEqual, doesCoordinatesContainAnIntersection, setLayerVisability, setTextFeatureLocationToZero, updateTextFeatureLocation } from "../../../_utils/mapEditorUtils";
import { mercatorToWgs84 } from "../../../_utils/turf.utlils";
import { deepCopy } from "../../../_utils/utils";
import MapEditorContext from "../../../store/MapEditiorContext";
import { MapEditorEditInfoSidebar } from "../MapEditorEditInfoSidebar";
import FloorPlanImagePixelProjectionMap from "../FloorPlanGeoReferencing/FloorPlanImageMap";
import FloorPlanInfoSideBar from "../FloorPlanGeoReferencing/FloorPlanInfoSideBar";
import useGeoReferenceFloorPlanInjector from "../FloorPlanGeoReferencing/hooks/useGeoReferenceFloorPlanInjector";
import { MapEditorMapButtons } from "./MapEditorMapButtons";

export const EntityEditorMap = ({
    isFloor,
    smartFilter,
    selectedFilterItem,
    onDrawEntity,
    onDeleteEntity,
    onEditEntityInfo,
    onSplitEntity,
    onMergeEntities,
    onGetSelectedEntity
}) =>
{
    const mapRef = useRef(null);
    const highlightedFilteredIdsRef = useRef(null); // {entityFeatures}
    const polygonCreationRef = useRef(null);

    const mapEditorContext = useContext(MapEditorContext);
    const [olMap, setOlMap] = useState(undefined);
    const [localEntityLayers, setLocalEntityLayers] = useState(undefined);
    const [activeInteraction, setActiveInteraction] = useState(undefined);
    const [activeEditInteractions, setActiveEditInteractions] = useState([]);
    const [activeSnapInteractions, setActiveSnapInteractions] = useState([]);
    const [selectedEntity, setSelectedEntity] = useState(undefined);
    const selectedEntityRef = useRef(selectedEntity);
    const activeEditInteractionsRef = useRef(activeEditInteractions);


    const mapData = useMemo(() => mapEditorContext?.state?.mapData, [mapEditorContext]);

    const tileLayer = useMemo(() =>
    {
        const tileStyle = mapData?.style?.tileLayer;
        return cartoLayer(tileStyle); // TODO: add translation
    }, [mapData]);

    const interactions = useMemo(() => ({
        [MAP_EDITOR_TOOLS.Polygon]: (source, style, drawEndCallback, finishConditionCallback, geometryFunction) => DrawInteraction({
            type: ShapeTypes.POLYGON,
            source,
            style,
            handleDrawEndEvent: drawEndCallback,
            handleFinishCondition: finishConditionCallback,
            geometryFunction
        }),
        [MAP_EDITOR_TOOLS.Polyline]: (source, style, callback, geometryFunction) => DrawInteraction({
            type: ShapeTypes.LINE_STRING,
            source,
            style,
            handleDrawEndEvent: callback,
            geometryFunction
        }),
        [MAP_EDITOR_TOOLS.Point]: (source, style, callback) => DrawInteraction({
            type: ShapeTypes.POINT,
            source,
            style,
            handleDrawEndEvent: callback
        }),
        [MAP_EDITOR_TOOLS.DeleteEntities]: (source, callback) => MouseInteraction({
            source,
            handleEvent: callback
        }),
        [MAP_EDITOR_TOOLS.EditEntities]: (source, callback) => MouseInteraction({
            source,
            handleEvent: callback
        }),
    }), []);

    // watcher for selected entity will add the respected interactions to the map once a entity is selected while on the edit tool
    const modifyInteractions = useMemo(() => ({
        [MAP_EDITOR_TOOLS.EditEntities]: (features, startCallBack, endCallback) => [
            ModifyInteraction({ features, condition: MODIFY_CONDITION, deleteCondition: DELETE_CONDITION, onModifyStart: startCallBack, onModifyEnd: endCallback }),
            ModifyScaleRotateInteraction({ features, condition: SCALE_AND_ROTATE_CONDITION, onModifyStart: startCallBack, onModifyEnd: endCallback }),
            TranslateInteraction({ features, condition: TRANSLATE_CONDITION, onTranslateStart: startCallBack, onTranslateEnd: endCallback }),
        ],
        [MAP_EDITOR_TOOLS.EditEntitiesPOI]: (features, startCallBack, endCallback) => [
            TranslateInteraction({ features, condition: TRANSLATE_CONDITION, onTranslateStart: startCallBack, onTranslateEnd: endCallback }),
        ]
    }), []);

    /**
     * On Mount
     * -> set up olmap
     */
    useEffect(() =>
    {
        const attribution = new Attribution({
            collapsible: true
        });

        const newOlMap = new Map({
            target: null,
            layers: [tileLayer],
            controls: defaultControls({
                attribution: false,
                zoom: false,
                rotate: false
            }).extend([attribution]),
            view: new View({
                center: [0, 0],
                zoom: 4,
                maxZoom: MapEditorConstants.MAX_ZOOM,
                minZoom: MapEditorConstants.MIN_ZOOM
            })
        });

        newOlMap.setTarget(mapRef.current);

        setOlMap(newOlMap);

    }, []);

    /**
     * on layers change
     * -> plot new layers
     */
    useEffect(() =>
    {
        const layers = mapEditorContext?.state?.layers;

        if (!!layers && !!olMap)
        {
            const { boundaryPolygon } = layers;
            drawEntityLayers(layers);

            changeMapCenterWithBoundaryPolygon({ olMap, boundaryPolygon, padding: MapEditorConstants.FIT_PADDING_MAP_DATA });
        }

    }, [mapEditorContext.state?.layers, olMap]);

    /**
     * on selected tool change
     * -> remove the previous interaction and add the new interaction to the map
     */
    useEffect(() =>
    {
        const { selectedTool } = mapEditorContext.state;

        if (olMap)
        {
            handleSetSelectedEntity(undefined);
            addEntityInteraction(selectedTool);
        }

    }, [mapEditorContext.state?.selectedTool, olMap]);

    /**
     * update layer visability on change
     **/
    useEffect(() =>
    {
        if (!olMap || !localEntityLayers)
        {
            return;
        }

        const mapLayersVisablityMap = mapEditorContext.state.mapLayersVisablityMap;

        setLayerVisability(localEntityLayers, mapLayersVisablityMap);

    }, [mapEditorContext.state?.mapLayersVisablityMap, localEntityLayers, olMap]);


    /**
     * adds edit interactions after an entity is selected
     */
    const handleAddEditInteractions = useCallback((interactionName) =>
    {
        if (selectedEntity?.entityInfo?.shape?.type === ShapeTypes.POINT)
        {
            interactionName = MAP_EDITOR_TOOLS.EditEntitiesPOI;
        }

        let editInteractions = modifyInteractions[interactionName]([selectedEntity.entityFeature], handleEditStartEvent, handleEditEndEvent);

        editInteractions.forEach((interaction) =>
        {
            olMap.addInteraction(interaction);
        });

        // need to add snap interactions after draw interaction is added to map
        // note: I know this looks odd but this has to be done. I think there might be another way if we can place the order of interactions manually, but this works.
        // if you were to comment out the code below, the split line will not snap to any layer
        if (Array.isArray(activeSnapInteractions))
        {
            activeSnapInteractions.forEach((interaction) =>
            {
                olMap.removeInteraction(interaction);
                olMap.addInteraction(interaction);
            });
        }

        setActiveEditInteractions(editInteractions);
    }, [olMap, activeSnapInteractions, selectedEntity]);

    /**
     * once entity is clicked, add split line interaction that will split the entity once the line is finished
     */
    const handleAddSplitInteraction = useCallback((selectedEntity) =>
    {
        // add split interaction to the map
        const splitInteraction = interactions[MAP_EDITOR_TOOLS.Polyline](undefined, undefined, (e) => handleSplitDrawEnd(e, selectedEntity));
        olMap.addInteraction(splitInteraction);

        // remove select interaction until split interaction is completed
        olMap.removeInteraction(activeInteraction);

        // need to add snap interactions after draw interaction is added to map
        // note: I know this looks odd but this has to be done. I think there might be another way if we can place the order of interactions manually, but this works.
        // if you were to comment out the code below, the split line will not snap to any layer
        if (Array.isArray(activeSnapInteractions))
        {
            activeSnapInteractions.forEach((snapInteraction) =>
            {
                olMap.removeInteraction(snapInteraction);
                olMap.addInteraction(snapInteraction);
            });
        }

        setActiveInteraction(undefined);
        setActiveEditInteractions([splitInteraction]);
    }, [activeInteraction, activeSnapInteractions, setActiveEditInteractions, olMap]);

    /**
     * Adds second click interaction that will attempt to merge entities
     */
    const handleAddMergeInteraction = useCallback((selectedEntity) =>
    {
        const interaction = interactions[MAP_EDITOR_TOOLS.EditEntities](undefined, (e) => handleMergeEntitiesEnd(e, selectedEntity));
        olMap.removeInteraction(activeInteraction);

        olMap.addInteraction(interaction);

        setActiveInteraction(undefined);
        setActiveEditInteractions([interaction]);
    }, [activeInteraction, setActiveEditInteractions, olMap]);


    /**
     * edit interaction, watches for selected entity
     * if tool is selected with edit interactions, they will be added to the map
     */
    useEffect(() =>
    {
        const { selectedTool } = mapEditorContext.state;
        let interactionName = selectedTool;

        //  remove edit interactions if available
        if (Array.isArray(activeEditInteractions))
        {
            activeEditInteractions.forEach((interaction) =>
            {
                olMap.removeInteraction(interaction);
            });
        }

        // check if entity is selected
        if (!selectedEntity?.entityFeature)
        {
            return;
        }

        //style the selected entity
        let entityAccess = new CMSEntityAccess(selectedEntity.entityInfo);

        // we still add style to entity feature to keep track of modify geometry
        // empty options means don't plot edit style for edges or lines, this is handled through the cloned feature
        let entityStyle = createEntityAccessSelectedStyle(entityAccess, mapEditorContext.state.mapData.style, {});
        let entityFeature = selectedEntity.entityFeature;

        // handle entity style
        entityFeature.setStyle((feature) => modifyEntityStyleFunction(feature, entityStyle));

        // create and add edit interactions if available
        if (interactionName === MAP_EDITOR_TOOLS.EditEntities)
        {
            handleAddEditInteractions(interactionName);
        }
        else if (interactionName === MAP_EDITOR_TOOLS.Split)
        {
            handleAddSplitInteraction(selectedEntity);
        }
        else if (interactionName === MAP_EDITOR_TOOLS.Merge)
        {
            handleAddMergeInteraction(selectedEntity);
        }

    }, [selectedEntity]);

    //#region SMART FILTER
    const resetEntityFeatureStyles = useCallback(
        /**
         * Removes any highlights on the given entities
         * @param {Array} entityIds
         * @returns
         */
        (entityIds) =>
        {
            if (!entityIds?.length && !entityIds?.size)
            {
                return;
            }

            entityIds.forEach((entityId) =>
            {
                // previously don't reset selected entities style
                // but I don't think it is needed, commented out for now
                // if (!selectedEntity || entityId !== selectedEntity.entityId)
                {
                    // if geometry is point, create point style
                    let entity = onGetSelectedEntity(entityId);

                    if (entity)
                    {
                        const entityAccess = new CMSEntityAccess(entity);

                        let entitySource = getEntitySourceFromEntityAccess(entityAccess, localEntityLayers);
                        let entityFeature = entitySource.getFeatureById(entityAccess.getId());

                        if (entityFeature)
                        {
                            const entityStyle = createEntityAccessSelectedStyle(entityAccess, mapEditorContext.state?.mapData?.style);

                            entityFeature.setStyle(entityStyle);
                        }
                    }
                }
            });
        }, [isFloor, localEntityLayers, selectedEntity]);


    const handleHighlightSelectedFilteredItems = useCallback(
        /**
         * Manages highlight logic on map dependent on smartFilter
         * to be called on smartFilter change
         * -> un highlight previously highlighted entities
         * -> highlight any entities inside of the smart filter
         * -> handles two different highlight styles
         *  --> highlight based on selected entity
         *  --> highlight all items in filter
         * @returns
         */
        () =>
        {
            //  {nodeFeatures, entityFeatures}
            let highlightedFilteredIdsToReset = highlightedFilteredIdsRef.current;

            // reset currently highlighted features
            if (highlightedFilteredIdsToReset)
            {
                resetEntityFeatureStyles(highlightedFilteredIdsToReset);
            }

            // init new highlighted filter ids list
            let highlightedFilteredIds = [];

            if (!smartFilter)
            {
                highlightedFilteredIdsRef.current = highlightedFilteredIds;
                return;
            }

            const { idType, filteredIds, highlightOnlyWhenSelected, entityIdHighlightMap } = smartFilter;

            // highlight features depending on the type of filter
            switch (idType)
            {
                // highlight filtered entities
                case (FILTERED_ID_TYPES.ENTITY):
                    {
                        const highlightStyleEntity = createEntityStyle(DEFAULT_ERROR_HIGHLIGHT_STYLE);
                        const highlightStylePoint = createPointStyle(DEFAULT_ERROR_HIGHLIGHT_STYLE_POINT);
                        let entitiesToHighlight = [];

                        if (highlightOnlyWhenSelected)
                        {
                            if (selectedEntity?.entityInfo?._id)
                            {
                                entitiesToHighlight = entityIdHighlightMap[selectedEntity.entityInfo._id] || [];
                            }
                        }
                        else
                        {
                            // we only want cms ids for map
                            let objectIdArray = filteredIds || [];

                            entitiesToHighlight = objectIdArray.map((idObject) => idObject.cmsId);
                        }

                        entitiesToHighlight.forEach((entityId) =>
                        {
                            let entity = onGetSelectedEntity(entityId);
                            if (entity)
                            {
                                const entityAccess = new CMSEntityAccess(entity);

                                let entityLayerSource = getEntitySourceFromEntityAccess(entityAccess, localEntityLayers);
                                let entityFeature = entityLayerSource.getFeatureById(entityAccess.getId());

                                if (entityFeature)
                                {

                                    let style = highlightStyleEntity;
                                    let geometryType = entityFeature.getGeometry().getType();
                                    if (geometryType === "Point")
                                    {
                                        style = highlightStylePoint;
                                    }

                                    entityFeature.setStyle(style);
                                }
                            }
                        });

                        highlightedFilteredIds = entitiesToHighlight;
                        break;
                    }
            }

            // set highlighted filtered features ref
            highlightedFilteredIdsRef.current = highlightedFilteredIds;
        }, [smartFilter, selectedEntity, resetEntityFeatureStyles, localEntityLayers]);


    // on selectedFilterItemChange
    useEffect(() =>
    {
        let highlightedFilteredIds = highlightedFilteredIdsRef.current;

        if (!selectedFilterItem || !smartFilter || !olMap || !highlightedFilteredIds)
        {
            return;
        }

        if (selectedFilterItem)
        {
            // if (mapEditorContext.state.selectedTool !== MAP_EDITOR_TOOLS.EditEntities)
            // {
            //     mapEditorContext.handleChangeSelectedTool(MAP_EDITOR_TOOLS.EditEntities);

            // }
            let entity = onGetSelectedEntity(selectedFilterItem);
            if (entity)
            {
                const entityAccess = new CMSEntityAccess(entity);

                let entityLayerSource = getEntitySourceFromEntityAccess(entityAccess, localEntityLayers);
                let entityFeature = entityLayerSource.getFeatureById(entityAccess.getId());

                if (entityFeature)
                {
                    // let geometry = entityFeature.getGeometry();
                    handleSetSelectedEntity({
                        entityFeature,
                        entityInfo: entity,
                        entityId: selectedFilterItem,
                    });

                    let centerOfEntity = getCenter(entityFeature.getGeometry().getExtent());
                    changeMapCenterWithMercator({ olMap, mercator: centerOfEntity });
                    // changeMapCenterWithBoundaryPolygon({ olMap, boundaryPolygon: geometry });
                }
            }
            return;
        }

        setSelectedEntity(undefined);
    }, [selectedFilterItem, localEntityLayers, mapEditorContext.state, olMap]);

    //on smart filter change
    useEffect(() =>
    {
        handleHighlightSelectedFilteredItems();
    }, [smartFilter, selectedEntity]);

    //#endregion

    /**
    * removes old layers and adds new layers to map
    * @param {*} layers
    * @param {*} layers.entityLayers
    * @param {*} layers.newEntitiesLayer
    */
    const drawEntityLayers = ({ entityLayers, newEntitiesLayer, idLayers }) =>
    {
        if (!entityLayers)
        {
            return;
        }

        let layersToAdd = [tileLayer];
        let newLocalLayerMap = {};

        // plot edit entity layer before all other layers
        newLocalLayerMap[MAP_LAYERS.edit_entity_layer] = createVectorLayer(MAP_LAYERS.edit_entity_layer);

        // plot new entity layer after edit entity layer
        if (newEntitiesLayer)
        {
            newLocalLayerMap.new_entities_layer = newEntitiesLayer;
        }

        Object.keys(entityLayers).forEach((layerId) =>
        {
            if (layerId !== MapEditorConstants.HIDDEN_LAYER)
            {
                const layer = entityLayers[layerId];

                newLocalLayerMap[layerId] = layer;
            }

        });

        // add entityId layer
        if (idLayers)
        {
            newLocalLayerMap[MapEditorConstants.ENTITY_ID_LAYER] = idLayers[MapEditorConstants.ENTITY_ID_LAYER];
        }

        layersToAdd = layersToAdd.concat(Object.values(newLocalLayerMap));

        setMapLayers(olMap, layersToAdd);

        setLocalEntityLayers(newLocalLayerMap);
    };


    // *** TOOL HANDLERS *** //

    /**
     * If cursor is hovering a node,
     * @param {*} selectedEntity
     * @param {*} selectedEntity.entityId
     */
    const handleHoverOverNodeOnPointerMove = ({ entityId }) =>
    {
        if (entityId)
        {
            olMap.getViewport().style.cursor = "pointer";
        }
        else
        {
            olMap.getViewport().style.cursor = "";
        }
    };

    /**
     *
     * @param {*} param0
     */
    const handleEntityDrawEnd = ({ feature }) =>
    {
        const entity = onDrawEntity(feature);

        polygonCreationRef.current = undefined;

        feature.set("id", entity._id);
        feature.setId(entity._id);
        feature.setGeometry(createEntityGeometry(entity.shape, EDITOR_POI_GEOMETRY_OPTIONS));

        const selectedEntity =
        {
            entityFeature: feature,
            entityInfo: entity,
            entityId: entity._id,
        };

        handleSetSelectedEntity(selectedEntity);
    };

    /**
     * callback for all entity polygon draw events
     * used to check if user tries to complete polygons with a self intersect
     * @param {*} e
     */
    const handlePolygonFinishCondition = (e) =>
    {
        // check if closed polygon has kink
        let coordinates = deepCopy(polygonCreationRef.current);

        if (!Array.isArray(coordinates))
        {
            return;
        }

        coordinates = coordinates[0];

        // for some reason the last point is duplicated, remove that for this check
        coordinates.pop();

        // add first point at end to close polygon
        coordinates.push(coordinates[0]);

        let containsIntersection = doesCoordinatesContainAnIntersection(coordinates);

        return !containsIntersection;
    };

    /**
     * Checks if mouse is on an entity
     * if hovering, changes cursor style
     * if clicked calls 'onClick' callback
     * @param {*} e
     * @param {*} onClick - calls function with selectedEntity on entity click
     */
    const mouseEventHandler = (e, onClick) =>
    {
        // on mouse move
        // - if mouse is hovering a node change cursor
        // - else set cursor to default cursor

        let { type } = e;

        if (!e.pixel)
        {
            return;
        }

        let selectedEntity = getSelectedEntityFromClick({ pointerEvent: e, olMap });
        // on hover
        if (type === "pointermove")
        {
            handleHoverOverNodeOnPointerMove(selectedEntity);
        }
        // on click
        else if (type === "click")
        {
            // - if mouse
            (onClick) && onClick(selectedEntity);
        }

        return true;
    };

    /**
     * Gets and deletes entity on click
     * @param {*} e
     */
    const handleDeleteMouseEvent = (e) =>
    {
        // on mouse move
        // - if mouse is hovering a node change cursor
        // - else set cursor to default cursor

        mouseEventHandler(e, ({ entityId }) =>
        {
            if (entityId)
            {
                // delete logic
                let deleteStep = onDeleteEntity(entityId);

                if (deleteStep)
                {
                    handleOutsideStepAction(deleteStep);
                }
            }
        });

        return true;
    };

    /**
     * Get entity from click
     * set as selected entity
     * pan to selected entity
     * @param {*} e
     */
    const handleSelectEntityEvent = (e) =>
    {
        mouseEventHandler(e, (entity) =>
        {
            let newSelectedEntity;

            // don't select a new entity while splitting an entity
            if (selectedEntityRef.current && mapEditorContext?.state?.selectedTool == MAP_EDITOR_TOOLS.Split)
            {
                return true;
            }

            if (entity.entityId)
            {
                entity.entityInfo = onGetSelectedEntity(entity.entityId);

                const entityFeature = entity.entityFeature;

                const entityAccess = new CMSEntityAccess(entity.entityInfo);
                const entityStyle = createEntityAccessSelectedStyle(entityAccess, mapEditorContext.state?.mapData?.style);

                // change entity style to selected
                entityFeature.setStyle(entityStyle);


                newSelectedEntity = entity;
            }

            handleSetSelectedEntity(newSelectedEntity);
        });

        return true;
    };

    /**
     * on edit start event, hide any attached text features
     * @param {*} e
     */
    const handleEditStartEvent = (e) =>
    {
        let updatedEntityInfo = deepCopy(selectedEntity.entityInfo);
        let entityAccess = new CMSEntityAccess(updatedEntityInfo);

        const textFeatureToEdit = getEntityTextFeatureFromEntityAccess(entityAccess);
        const entityIdTextFeatureToEdit = getEntityIdTextFeatureFromEntityAccess(entityAccess);

        // update text point

        // set entity text location to 0 if available
        if (textFeatureToEdit)
        {
            setTextFeatureLocationToZero(textFeatureToEdit);
        }
        // set entity id text location to 0 if available
        if (entityIdTextFeatureToEdit)
        {
            setTextFeatureLocationToZero(entityIdTextFeatureToEdit);
        }
    };

    /**
     * save updated entity geometry
     * @param {*} e
     */
    const handleEditEndEvent = (e) =>
    {
        // since we are only working with one feature at the time of witting, we get the first item from the feature collection
        let featureArray = e.features.getArray();
        if (featureArray?.[0])
        {
            let updatedEntityInfo = deepCopy(selectedEntity.entityInfo);
            let entityAccess = new CMSEntityAccess(updatedEntityInfo);

            let entityFeature = featureArray[0];
            let updatedEntityFeatureGeometry = entityFeature.getGeometry().clone();

            let updatedEntityGeometry = convertFeatureGeometryToGeoJSON(updatedEntityFeatureGeometry);

            let isValid = true;

            // validate polygon edit
            if (updatedEntityGeometry.type === ShapeTypes.POLYGON)
            {
                // validate geometry
                let containsSelfIntersection = doesCoordinatesContainAnIntersection(updatedEntityGeometry.coordinates[0]);

                if (containsSelfIntersection)
                {
                    isValid = false;
                    toast.error(EDITOR_ALERTS.CANT_CREATE_INTERSECTING_POLYGON);
                }

            }
            // validate polyline edit
            else if (updatedEntityGeometry.type === ShapeTypes.LINE_STRING)
            {
                let firstCoord = updatedEntityGeometry.coordinates[0];
                let lastCord = updatedEntityGeometry.coordinates[updatedEntityGeometry.coordinates.length - 1];

                if (areCoordinatesEqual(firstCoord, lastCord))
                {
                    isValid = false;
                    toast.error(EDITOR_ALERTS.CANT_CREATE_CLOSED_LINESTRING_LOOP);
                }
            }

            // revert change if didn't pass validation
            if (!isValid)
            {
                let originalFeatureGeometry = createEntityGeometry(updatedEntityInfo.shape);
                entityFeature.setGeometry(originalFeatureGeometry);

                let newSelectedEntity = deepCopy(selectedEntity);
                newSelectedEntity.entityFeature = entityFeature;
                setSelectedEntity(newSelectedEntity);

                return;
            }

            // NOTE: the following code should not get hit of edit is not valid
            updatedEntityGeometry.coordinates = mercatorToWgs84(updatedEntityGeometry.coordinates, updatedEntityGeometry.type);

            setSelectedEntity((prevValue) => ({ ...prevValue, entityInfo: updatedEntityInfo }));
            updatedEntityInfo.shape = updatedEntityGeometry;
            updatedEntityInfo.textCoordinate = null;
            updatedEntityInfo.imageExtent = null;

            handleSaveEntityInfo(updatedEntityInfo);

            // update text point
            const textFeatureToEdit = getEntityTextFeatureFromEntityAccess(entityAccess);
            const entityIdTextFeatureToEdit = getEntityIdTextFeatureFromEntityAccess(entityAccess);

            // update attached text feature if available
            if (textFeatureToEdit)
            {
                updateTextFeatureLocation(textFeatureToEdit, entityFeature);
            }

            // update attached entityId text feature if available
            if (entityIdTextFeatureToEdit)
            {
                updateTextFeatureLocation(entityIdTextFeatureToEdit, entityFeature);
            }
        }
    };

    /**
     * Handle the end of split draw event.
     * Sends the entity to split, and split line geometry to entity editor component
     * Expects either split step (entitiesToAdd/Delete) or undefined if the split is invalid
     *
     * @param {Object} e - the event object
     * @param {Object} selectedEntity - the selected entity object
     */
    const handleSplitDrawEnd = (e, selectedEntity) =>
    {
        const splitLineFeature = e.feature;
        const splitLineFeatureGeometry = splitLineFeature.getGeometry();
        const splitLineFeatureCoordinates = mercatorCoordinateArrayToGpsLocations(splitLineFeatureGeometry.getCoordinates());
        const splitLineShape = {
            type: ShapeTypes.LINE_STRING,
            coordinates: splitLineFeatureCoordinates
        };

        // remove line feature from map and unselect selected entity
        handleSetSelectedEntity(undefined);

        // split entity if possible
        const result = onSplitEntity(selectedEntity.entityId, splitLineShape);

        if (result)
        {
            handleOutsideStepAction(result);
        }
        else
        {
            toast(EDITOR_ALERTS.CANT_SPLIT_ENTITY);
        }

        const selectInteraction = interactions[MAP_EDITOR_TOOLS.EditEntities](undefined, handleSelectEntityEvent);
        olMap.addInteraction(selectInteraction);
        setActiveInteraction(selectInteraction);
    };

    /**
     * similar to handle select entity, but we are not setting selected entity, we are instead attempting to merge
     * @param {*} e
     * @param {*} selectedEntity
     */
    const handleMergeEntitiesEnd = (e, selectedEntity) =>
    {
        mouseEventHandler(e, (selectedEntity2) =>
        {
            if (selectedEntity2.entityId)
            {
                // remove active edit interactions
                activeEditInteractionsRef.current.forEach((interaction) => olMap.removeInteraction(interaction));

                // add logic if select the already selected entity
                if (selectedEntity.entityId !== selectedEntity2.entityId)
                {
                    // merge logic if entity IDs are different
                    const result = onMergeEntities(selectedEntity.entityId, selectedEntity2.entityId);
                    if (result)
                    {
                        handleOutsideStepAction(result);
                    }
                    else
                    {
                        toast(EDITOR_ALERTS.CANT_MERGE_ENTITIES);
                    }
                }

                handleSetSelectedEntity(undefined);

                const selectInteraction = interactions[MAP_EDITOR_TOOLS.EditEntities](undefined, handleSelectEntityEvent);
                olMap.addInteraction(selectInteraction);

                setActiveInteraction(selectInteraction);
            }
        });

        return true;
    };

    const handlePolygonGeometryFunction = useCallback(
        /**
         * Custom OL geometry function to check if new point in polygon creates a self intersection
         * on self intersection remove point and display error message
         * @param {*} coordinates
         * @param {*} featureGeometry
         * @returns
         */
        (coordinates, featureGeometry) =>
        {
            let polygonHelper = polygonCreationRef.current;

            // if point added, check if kink exist
            if (!!polygonHelper && polygonHelper[0].length !== coordinates[0].length)
            {
                // check for self intersection
                let containsIntersection = doesCoordinatesContainAnIntersection(coordinates[0]);

                //if kink exist remove last point
                if (containsIntersection)
                {
                    toast.error(EDITOR_ALERTS.CANT_CREATE_INTERSECTING_POLYGON);
                    coordinates[0].pop();
                }

                // check if last point is same as the point before, if so pop coordinate
                if (coordinates[0].length > 2)
                {
                    let length = coordinates[0].length;
                    let lastCoord = coordinates[0][(length - 2)];
                    let secondLastCoord = coordinates[0][(length - 3)];
                    if (areCoordinatesEqual(lastCoord, secondLastCoord))
                    {
                        coordinates[0].pop();
                    }
                }

                polygonCreationRef.current = deepCopy(coordinates);
            }

            if (!polygonCreationRef.current)
            {
                polygonCreationRef.current = deepCopy(coordinates);
            }

            // set the coordinates of the geometry
            let geometry = featureGeometry;

            let entityPoints = featureGeometry?.getCoordinates()?.[0];
            let coordinatePoints = coordinates[0];

            // this is to prevent the odd interaction where finishing the polygon removes the last (first) coordinate
            // this happens when snapping to the last point or double clicking the latest point to auto complete the entity
            if (entityPoints && coordinatePoints.length < entityPoints.length)
            {
                coordinates[0].push(coordinates[0][0]);
            }

            if (!geometry)
            {
                geometry = new Polygon(coordinates);
            }
            else
            {
                geometry.setCoordinates(coordinates);
            }

            return geometry;
        }, [polygonCreationRef]);

    const handlePolylineGeometryFunction = useCallback(
        /**
         * Custom OL geometry function to check if new point creates closed circuit with first point
         * on self intersection remove point and display error message
         * @param {*} coordinates
         * @param {*} featureGeometry
         * @returns
         */
        (coordinates, featureGeometry) =>
        {
            let polygonHelper = polygonCreationRef.current;

            // if point added, check if closed loop exist between first/last point
            if (!!polygonHelper && polygonHelper.length !== coordinates.length)
            {
                let firstCoord = coordinates[0];
                let lastCord = coordinates[coordinates.length - 1];

                if (areCoordinatesEqual(firstCoord, lastCord))
                {
                    toast.error(EDITOR_ALERTS.CANT_CREATE_CLOSED_LINESTRING_LOOP);
                    coordinates.pop();
                }

                polygonCreationRef.current = deepCopy(coordinates);
            }

            if (!polygonCreationRef.current)
            {
                polygonCreationRef.current = deepCopy(coordinates);
            }

            // set the coordinates of the geometry
            let geometry = featureGeometry;

            if (!geometry)
            {
                geometry = new LineString(coordinates);
            }
            else
            {
                geometry.setCoordinates(coordinates);
            }

            return geometry;
        }, [polygonCreationRef]);

    const addEntityInteraction = useCallback(
        /**
         *
         * @param {*} toolName
         */
        (toolName) =>
        {
            // removing any prev interaction from map
            if (activeInteraction)
            {
                olMap.removeInteraction(activeInteraction);
            }

            // remove any prev snap interaction
            if (Array.isArray(activeSnapInteractions))
            {
                activeSnapInteractions.forEach((snapInteraction) =>
                {
                    olMap.removeInteraction(snapInteraction);
                });
            }

            if (toolName)
            {
                // create interaction depending on tool name
                let interaction;
                const POI_layer_name = mapEditorContext.state?.mapData?.style[EntityType.POINT_OF_INTEREST][PointOfInterestType.UNKNOWN_POI].layerIdx;
                let { new_entities_layer: newEntitiesLayer, [POI_layer_name]: POILayer } = localEntityLayers;
                let newEntitiesSource = newEntitiesLayer.getSource();
                let poiSource = POILayer.getSource();
                let style = undefined;

                switch (toolName)
                {
                    case (MAP_EDITOR_TOOLS.Polygon):
                        {
                            interaction = interactions[toolName](newEntitiesSource, style, handleEntityDrawEnd, handlePolygonFinishCondition, handlePolygonGeometryFunction);
                            break;
                        }
                    case (MAP_EDITOR_TOOLS.Polyline):
                        {
                            interaction = interactions[toolName](newEntitiesSource, style, handleEntityDrawEnd, handlePolylineGeometryFunction);
                            break;
                        }
                    case (MAP_EDITOR_TOOLS.Point):
                        {
                            interaction = interactions[toolName](poiSource, style, handleEntityDrawEnd);
                            break;
                        }
                    case (MAP_EDITOR_TOOLS.DeleteEntities):
                        {
                            interaction = interactions[toolName](newEntitiesSource, handleDeleteMouseEvent);
                            break;
                        }
                    case (MAP_EDITOR_TOOLS.EditEntities):
                        {
                            interaction = interactions[toolName](newEntitiesSource, handleSelectEntityEvent);
                            break;
                        }
                    case (MAP_EDITOR_TOOLS.Split):
                        {
                            interaction = interactions[MAP_EDITOR_TOOLS.EditEntities](newEntitiesSource, handleSelectEntityEvent);
                            break;
                        }
                    case (MAP_EDITOR_TOOLS.Merge):
                        {
                            interaction = interactions[MAP_EDITOR_TOOLS.EditEntities](newEntitiesSource, handleSelectEntityEvent);
                            break;
                        }
                }

                // add snap interactions
                // snap interactions
                // add interactions to map
                interaction && olMap.addInteraction(interaction);

                let snapInteractions = [];

                Object.keys(localEntityLayers).forEach((layerId) =>
                {
                    if (layerId !== MapEditorConstants.TEXT_LAYER)
                    {
                        const layer = localEntityLayers[layerId];
                        const source = layer.getSource();

                        let snapInteraction = SnapInteraction({ source, pixelTolerance: SNAP_PIXEL_TOLERANCE });
                        olMap.addInteraction(snapInteraction);
                        snapInteractions.push(snapInteraction);
                    }
                });

                setActiveInteraction(interaction);
                setActiveSnapInteractions(snapInteractions);

            }
            else
            {
                setActiveInteraction(undefined);
                setActiveSnapInteractions([]);
            }

        }, [activeInteraction, activeSnapInteractions, localEntityLayers, setActiveInteraction, setActiveSnapInteractions, olMap, handleEntityDrawEnd, handleEntityDrawEnd, handleSelectEntityEvent]);

    //** OUTSIDE STEP ACTIONS **//

    /**
    *
    * this function can be used to make changes to the map from outside the map component
    * @param {*} step
    */
    const handleOutsideStepAction = (step) =>
    {
        let { text_layer: textLayer, entityIds: entityIdLayer } = localEntityLayers;

        let textLayerSource = textLayer.getSource();
        let entityIdLayerSource = entityIdLayer.getSource();

        const { entitiesToAdd, entitiesToDelete, entitiesToUpdate } = step;

        if (Array.isArray(entitiesToAdd))
        {
            entitiesToAdd.forEach((entity) =>
            {
                let entityAccess = new CMSEntityAccess(entity);
                let style = mapEditorContext.state?.mapData?.style;

                let layerSource = getEntitySourceFromEntityAccess(entityAccess, localEntityLayers);

                try
                {
                    const featureToAdd = createEntityFeature(entityAccess, style, EDITOR_POI_GEOMETRY_OPTIONS);
                    const textFeatureToAdd = createEntityTextFeature(entityAccess, style, featureToAdd);

                    if (entityAccess.getNavId())
                    {
                        const entityIdFeatureToAdd = createEntityIdFeature(entityAccess, style, featureToAdd);
                        entityIdLayerSource.addFeature(entityIdFeatureToAdd);
                    }

                    layerSource.addFeature(featureToAdd);
                    textLayerSource.addFeature(textFeatureToAdd);
                }
                catch (err)
                {
                    console.log("error adding entity", entity, err);
                }
            });
        }

        if (Array.isArray(entitiesToUpdate))
        {
            // update entity styles
            entitiesToUpdate.forEach(({ prevEntity, newEntity }) =>
            {

                let prevEntityAccess = new CMSEntityAccess(prevEntity);
                let newEntityAccess = new CMSEntityAccess(newEntity);

                if (selectedEntity?.entityId === newEntityAccess.getId())
                {
                    let newSelectedEntity = deepCopy(selectedEntity);
                    newSelectedEntity.entityInfo = newEntity;
                    newSelectedEntity.entityFeature = selectedEntity.entityFeature;
                    setSelectedEntity(newSelectedEntity);
                }

                handleChangeEntityGeometry(prevEntityAccess, newEntityAccess);
                handleChangeEntityStyle(prevEntityAccess, newEntityAccess);
                handleChangeEntityText(prevEntityAccess, newEntityAccess);
            });
        }

        if (Array.isArray(entitiesToDelete))
        {
            entitiesToDelete.forEach((entity) =>
            {
                // convert to entity access so we can use built functions to get layer id
                let entityAccess = new CMSEntityAccess(entity);

                let layerSource = getEntitySourceFromEntityAccess(entityAccess, localEntityLayers);

                const featureToDelete = layerSource.getFeatureById(entity._id);
                (featureToDelete) && layerSource.removeFeature(featureToDelete);

                // delete text from text layer if available
                const textFeatureToDelete = textLayerSource.getFeatureById(getTextFeatureIdWithEntityId(entityAccess.getId()));
                (textFeatureToDelete) && textLayerSource.removeFeature(textFeatureToDelete);

                // delete entityId text from entityIds layer if available
                const entityIdTextFeatureToDelete = entityIdLayerSource.getFeatureById(getTextFeatureIdWithEntityId(entityAccess.getId()));
                (entityIdTextFeatureToDelete) && entityIdLayerSource.removeFeature(entityIdTextFeatureToDelete);
            });
        }
    };

    /**
     * Finds the correct entity access using entity access
     * @param {*} entityAccess
     * @param {*} localEntityLayers
     * @returns
     */
    const getEntitySourceFromEntityAccess = (entityAccess, localEntityLayers) =>
    {
        let entityStyleObject = entityAccess.getStyleObject(mapEditorContext.state?.mapData?.style);

        // delete entity from correct layer
        let layerId = entityStyleObject?.layerIdx;

        if (!layerId || layerId === -1)
        {
            layerId = "new_entities_layer";
        }

        let layer = localEntityLayers[layerId];
        let layerSource = layer.getSource();

        return layerSource;
    };

    /**
     * get entity text feature if available
     * @param {*} entityAccess
     * @returns
     */
    const getEntityTextFeatureFromEntityAccess = (entityAccess) =>
    {
        // update text point
        let { text_layer: textLayer } = localEntityLayers;
        const textFeature = textLayer.getSource().getFeatureById(getTextFeatureIdWithEntityId(entityAccess.getId()));

        return textFeature;
    };

    /**
     * get entityId text feature if available
     * @param {*} entityAccess
     * @returns
     */
    const getEntityIdTextFeatureFromEntityAccess = (entityAccess) =>
    {
        // update entityId text point
        let { entityIds: entityIdsLayer } = localEntityLayers;
        const entityIdTextFeature = entityIdsLayer.getSource().getFeatureById(getTextFeatureIdWithEntityId(entityAccess.getId()));

        return entityIdTextFeature;
    };

    useEffect(() =>
    {
        const stepAction = mapEditorContext?.state?.stepAction;

        if (olMap && stepAction)
        {
            // call undo step for correct edit type
            handleOutsideStepAction(stepAction);
            mapEditorContext.handleCompleteStepAction();

        }

    }, [mapEditorContext?.state.stepAction]);

    //** CALLBACK FUNCTIONS **//

    /**
     * Updates selected entity feature with info edited
     * calls `onEditEntity` callback to update indexedDB
     */
    const handleSaveEntityInfo = useCallback((entityInfo) =>
    {
        if (entityInfo?._id)
        {
            onEditEntityInfo(entityInfo._id, entityInfo);
        }
    }, []);


    /**
     * handles updating entity feature with updated geometry from outside this component
     * -- if we want to check if the polygons changed we can use something like this
     * https://stackoverflow.com/questions/30590219/how-to-determine-two-polygons-is-same
     */
    const handleChangeEntityGeometry = useCallback((prevEntityAccess, newEntityAccess) =>
    {
        let newEntityLayerSource = getEntitySourceFromEntityAccess(newEntityAccess, localEntityLayers);
        let entityFeature = newEntityLayerSource.getFeatureById(newEntityAccess.getId());
        let entityTextFeature = getEntityTextFeatureFromEntityAccess(newEntityAccess);
        let entityIdTextFeature = getEntityIdTextFeatureFromEntityAccess(newEntityAccess);

        //TODO check if geometries changed?

        if (entityFeature)
        {
            entityFeature.setGeometry(createEntityGeometry(newEntityAccess.getShape(), EDITOR_POI_GEOMETRY_OPTIONS));

            // update entityId text feature if present
            if (entityTextFeature)
            {
                updateTextFeatureLocation(entityTextFeature, entityFeature);
            }

            // update entity ID text feature if present
            if (entityIdTextFeature)
            {
                updateTextFeatureLocation(entityIdTextFeature, entityFeature);
            }
        }

    }, [localEntityLayers]);

    const handleChangeEntityStyle = useCallback(
        /**
         * Updates entity style and moves entity to correct layer according to types
         * @param {CMSEntityAccess} prevEntityAccess
         * @param {CMSEntityAccess} newEntityAccess
         * @returns
         */
        (prevEntityAccess, newEntityAccess) =>
        {
            // old entityAccess tells us where the entity is currently
            // new entityAccess tells us where the new entity should be and what it should look like

            // old feature is the same entity type / subtype as the new one, no need for change
            if (prevEntityAccess === prevEntityAccess.getEntityType() === newEntityAccess.getEntityType() && prevEntityAccess.getSubEntityType() === newEntityAccess.getSubEntityType())
            {
                return;
            }

            // there are cases where the style will be plotted in the same layer
            let style = mapEditorContext.state?.mapData?.style;

            // get old and new source
            let prevEntityLayerSource = getEntitySourceFromEntityAccess(prevEntityAccess, localEntityLayers);
            let newEntityLayerSource = getEntitySourceFromEntityAccess(newEntityAccess, localEntityLayers);

            let entityFeature = prevEntityLayerSource.getFeatureById(prevEntityAccess.getId());

            if (entityFeature)
            {
                // remove feature from prev layer
                prevEntityLayerSource.removeFeature(entityFeature);

                // create and set new feature style
                let newEntityStyle;

                if (newEntityAccess.getId() === selectedEntity?.entityId)
                {
                    newEntityStyle = createEntityAccessSelectedStyle(newEntityAccess, style);
                    entityFeature.setStyle((feature) => modifyEntityStyleFunction(feature, newEntityStyle));
                    setSelectedEntity((prevValue) => ({ ...prevValue, entityInfo: newEntityAccess.data }));
                }
                else
                {
                    newEntityStyle = createEntityAccessStyle(newEntityAccess, style);
                    entityFeature.setStyle(newEntityStyle);

                }

                newEntityLayerSource.addFeature(entityFeature);
                // const textFeatureToUpdate = textLayerSource.getFeatureById(getTextFeatureIdWithEntityId(newEntityAccess.getId()));
                // textFeatureToUpdate.set("connectedFeature", newFeature);

            }
            else
            {
                console.log("ERROR FEATURE NOT FOUND ON MAP!!");
            }

            //

        }, [mapEditorContext.state.mapData, selectedEntity, localEntityLayers]
    );

    const updateTextFeatureStyle = useCallback(
        /**
         *
         * @param {Feature} textFeature
         * @param {CMSEntityAccess} entityAccess
         */
        (textFeature, entityAccess) =>
        {
            let style = mapEditorContext.state?.mapData?.style;
            let newEntityLayerSource = getEntitySourceFromEntityAccess(entityAccess, localEntityLayers);
            let entityFeature = newEntityLayerSource.getFeatureById(entityAccess.getId());

            let textStyle = createEntityTextStyle(entityAccess, style);
            let centerPoint = getGeometryCenter(entityFeature.getGeometry());
            const entityTextGeometry = createEntityGeometry({ type: "TextPoint", coordinates: centerPoint });
            textStyle.setGeometry(entityTextGeometry);
            textFeature.setStyle(textStyle);
        }, [mapEditorContext?.state?.mapData, localEntityLayers]
    );

    /**
     * @param {AbstractEntityAccess} prevEntityAccess
     * @param {AbstractEntityAccess} newEntityAccess
     */
    const handleChangeEntityText = useCallback((prevEntityAccess, newEntityAccess) =>
    {
        let { text_layer: textLayer } = localEntityLayers;

        let textLayerSource = textLayer.getSource();
        let style = mapEditorContext.state?.mapData?.style;

        // check if entity text has changed
        if (prevEntityAccess.getName() === newEntityAccess.getName())
        {
            return;
        }

        // get existing text feature if exists
        let textFeatureToUpdate = textLayerSource.getFeatureById(getTextFeatureIdWithEntityId(newEntityAccess.getId()));

        if (!textFeatureToUpdate)
        {
            let newEntityLayerSource = getEntitySourceFromEntityAccess(newEntityAccess, localEntityLayers);
            let entityFeature = newEntityLayerSource.getFeatureById(newEntityAccess.getId());
            let newTextFeature = createEntityTextFeature(newEntityAccess, style, entityFeature);
            textLayerSource.addFeature(newTextFeature);
            // create new text feature and add to map
        }
        else
        {
            // text rotation text style
            updateTextFeatureStyle(textFeatureToUpdate, newEntityAccess);
        }

    }, [mapEditorContext.state.mapData, localEntityLayers, updateTextFeatureStyle]);



    //**  RENDER FUNCTIONS **//
    const renderMapEditorSideBar = useCallback(() =>
    {
        if (selectedEntity)
        {
            return <MapEditorEditInfoSidebar
                selectedEntity={selectedEntity}
                onSave={handleSaveEntityInfo}
                onChangeEntityStyle={handleChangeEntityStyle}
                onChangeEntityText={handleChangeEntityText}
            />;
        }
        else
        {
            return <></>;
        }
    }, [selectedEntity, handleSaveEntityInfo, handleChangeEntityStyle]);

    /**
     * updates selected entity and reverts prev selected entity style if available
     */
    const handleSetSelectedEntity = useCallback((newSelectedEntity) =>
    {
        setSelectedEntity((prevEntity) =>
        {
            if (prevEntity)
            {
                // reset feature style
                let entityFeature = prevEntity.entityFeature;
                let entityAccess = new CMSEntityAccess(prevEntity.entityInfo);
                const entityStyle = createEntityAccessStyle(entityAccess, mapEditorContext.state?.mapData?.style);
                entityFeature.setStyle(entityStyle);
            }
            return newSelectedEntity;
        });

        // clear edit layer
        localEntityLayers?.[MAP_LAYERS.edit_entity_layer]?.getSource()?.clear();

    }, [localEntityLayers, selectedEntity, setSelectedEntity, mapEditorContext.state?.mapData]);

    useGeoReferenceFloorPlanInjector({ olMap });


    return (
        <>
            <FloorPlanImagePixelProjectionMap />
            <div className="map-container" ref={mapRef}>
                {/*  floor buttons*/}
                <MapEditorMapButtons />
                {
                    renderMapEditorSideBar()
                }

                <FloorPlanInfoSideBar />

            </div>
        </>
    );
};
