import React from "react";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next, useTranslation } from "react-i18next";
import resources from "./resources.json";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: false,
        resources,
        load: "languageOnly",       // puts en-US to to en
        lng: "en",
        fallbackLng: "en",
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        returnObjects: true
    });

export default i18n;

/** For translating things outside of react components
 *  @type {React.FC<import("../../types/common").TransProps>} */
export const Transl = ({componentObjName, keyName}) =>
{
    const { t } = useTranslation();
    return <>{t(`${componentObjName}.${keyName}`)}</>
}
