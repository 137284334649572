import React from "react";
import { Input } from "semantic-ui-react";
import { useImagesBaseContext } from "../../contexts/ImageBaseContext";

function ImagesSearchBar({  value, onChange }) 
{
    return (
        <Input
            className="gms-search-bar"
            value={value}
            onChange={onChange}
            icon="search"
            placeholder="Search..."
        />
    );
}

export default ImagesSearchBar;
