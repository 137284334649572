import React, { useContext } from "react";
import "./ListViewBasicFilteration.css";
import { LIST_MODES, getColumnOptions } from "../../../../_constants/listView";
import CheckboxDropdown from "../../../common/checkboxDropdown/CheckboxDropdown";
import { useListViewContext } from "../../../../store/ListViewContext";
import { DropdownForm } from "../../../common/DropdownForm";
import {
    getFloorOptions,
    getLanguageDropdownOptions,
    isAdvancedFilterationActive,
} from "../../../../_utils/listView";
import { ButtonIcon } from "../../../common/ButtonIcon";
import { Input } from "semantic-ui-react";
import BrandingContext from "../../../../store/BrandingContext";
import { DEFAULT_LANGUAGE_CODE } from "../../../../_constants/constants";
import { useTranslation } from "react-i18next";

function ListViewBasicFilteration() 
{
    const {
        basicFilteration,
        columnsVisible,
        languageCodes,
        listMode,
        handleColumnVisibility,
        handleUpdateBasicFilteration,
        advancedFilteration,
        handleUpdateAdvancedFilteration,
        handleShowAdvanceFilterationSidebar,
        handleResetAdvancedFilteration,
    } = useListViewContext();
    const { state, changeSelectedFloor } = useContext(BrandingContext);
    const { buildingId, propertyId, properties, floorId } = state || {};
    const floorOptions =
    getFloorOptions(properties?.[propertyId]?.buildings[buildingId]?.floors)
    || [];
    const trans = useTranslation().t;
     
    function handleLangChange(e, { value }) 
    {
        handleUpdateBasicFilteration({ languageCode: value });
        if (!isAdvancedFilterationActive(advancedFilteration))
        {
            handleUpdateAdvancedFilteration({ languageCode: value });
        }
    }

    function handleFloorChange(e, { value }) 
    {
        changeSelectedFloor(value);
    }

    function handleSearchInputChange(e) 
    {
        handleResetAdvancedFilteration();
        handleUpdateBasicFilteration({ searchValue: e.target.value });
    }

    return (
        <div className="listview-n-basic-filteration">
            <Input
                onChange={handleSearchInputChange}
                value={basicFilteration.searchValue}
                className="list-view-filteration-search-input"
                icon="search"
                disabled={listMode === LIST_MODES.BULK_EDIT}
                placeholder={trans("ListViewFilteration.Search_Placeholder")}
            />
            <div className="listview-n-basic-filteration-options-section">
                <div className="listview-n-basic-filteration-lang-bx">
                    <div className="header-title">{trans("ListViewFilteration.Translate_In")}:</div>
                    <DropdownForm
                        defaultValue={DEFAULT_LANGUAGE_CODE}
                        className={"filter-dropdown"}
                        disabled={listMode === LIST_MODES.BULK_EDIT}
                        value={basicFilteration.languageCode}
                        options={getLanguageDropdownOptions(languageCodes)}
                        onChange={handleLangChange}
                    />
                </div>
                <ButtonIcon
                    className={"filter-btn"}
                    icon={"filter-new"}
                    content={trans("ListViewSubHeader.Filter")}
                    disabled={listMode === LIST_MODES.BULK_EDIT}
                    onClick={handleShowAdvanceFilterationSidebar}
                ></ButtonIcon>
                <CheckboxDropdown
                    scrolling
                    className="filter-dropdown column-filteration"
                    disabled={listMode === LIST_MODES.BULK_EDIT}
                    text={
                        <div className="column-visibility-bx">
                            <div className="img-bx">
                                {" "}
                                <img src="/img/icon-column-list.svg" alt="expand" />
                            </div>
                            <div>{trans("ListViewSubHeader.Columns")}</div>
                        </div>
                    }
                    showSelectAllOption
                    options={getColumnOptions(trans)}
                    values={columnsVisible}
                    onChange={handleColumnVisibility}
                />
                {!!floorId && (
                    <DropdownForm
                        disabled={listMode === LIST_MODES.BULK_EDIT}
                        className={"filter-dropdown"}
                        value={floorId || floorOptions[0]?.value}
                        options={floorOptions}
                        onChange={handleFloorChange}
                    />
                )}
            </div>
        </div>
    );
}

export default ListViewBasicFilteration;
