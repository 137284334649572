import React, { createContext, useContext } from "react";
import { useSetState } from "ahooks";
import BrandingContext from "./BrandingContext";
import { Loader } from "../components/elements/loader";
import { useLoadingPool } from "../_utils/hooks";
import { useHistory, useLocation } from "react-router";
import { MAINTENANCE_MODES } from "../_constants/constants";

export const MaintenanceContext = createContext();

export const MaintenanceProvider = ({ children }) =>
{
    const brandingCtx = useContext(BrandingContext);

    const loadingPool = useLoadingPool();
    const { search } = useLocation();
    const history = useHistory();

    const searchParams = new URLSearchParams(search);
    let modeFromUrl = searchParams.get("mode");
    if (modeFromUrl) 
    {
        searchParams.delete("mode");
        if (searchParams.get("t")) searchParams.delete("t");
        history.replace({ search: searchParams.toString() });
    }

    const [state, setState] = useSetState({
        mode: modeFromUrl || MAINTENANCE_MODES.MAP_OVERLAYS,
        restoreMapOverlays: false,
    });

    const handleMaintenanceUnMount = () => brandingCtx.deselectEntities();

    const updateMode = (mode) =>
    {
        setState({ mode });
    };

    const handleMapOverlaysRestore = () =>
    {
        setState({
            mode: MAINTENANCE_MODES.MAP_OVERLAYS,
            restoreMapOverlays: true,
        });
    };

    const value = {
        state,
        handleMaintenanceUnMount,
        updateMode,
        loadingPool,
        handleMapOverlaysRestore,
    };

    return (
        <MaintenanceContext.Provider value={value}>
            <Loader active={loadingPool.loading} />
            {children}
        </MaintenanceContext.Provider>
    );
};

