import React from "react";
import "./EmptyImagesStateFallback.css";

function EmptyImagesStateFallback({ children }) 
{
    return (
        <div className="empty-images-state-fallback-cx">
            <div className="empty-images-state-fallback">
                <img
                    className="empty-images-state-fallback-image"
                    src="/img/icon-no-image.svg"
                    alt="not-found"
                />
                <div>{children || "No Images Uploaded"}</div>
            </div>
        </div>
       
    );
}

export default EmptyImagesStateFallback;