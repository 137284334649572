import tinyColor from "tinycolor2";
import { createStyle, createVectorLayerFromGeometry } from "mapsted.maps/mapFunctions/publicVectorLayers";
import { toGpsLocation } from "mapsted.maps/utils/map.utils";
import { getCenter } from "ol/extent";
import Style from "ol/style/Style";
import Point from "ol/geom/Point";
import Feature from "ol/Feature";
import { DEFAULT_LANGUAGE_CODE, IGNORED_OVERLAY_TEMPLATE_FIELDS, MAP_OVERLAY_DEFAULT_ZOOM_LEVEL_SETTINGS, NEW_OVERLAY_DEFAULT } from "../../../../_constants/constants";
import { extractLanguageCodeFromMapOverlayResult } from "mapsted.maps/utils/publicMetaData";
import { 
    createMapOverlayTextStyleFunction,
    createMapOverlayTextFeature,
    createStyleFunction,
    createMapOverlayLayer,
    createTextStyle
} from "mapsted.maps/mapFunctions/mapOverlay";
import _ from "lodash";
import { deepCopy } from "mapsted.utils/objects";

const MAP_OVERLAY_TEXT_FIELDS = ["name", "toolTipText"];
const MAP_OVERLAY_ZOOM_LEVEL_TEXT_FIELDS = ["textLabel"];

export const createVectorLayer = ({
    geometry,
    toolTipText,
    styleOptions,
    id,
    textOpactiy
}) =>
{
    const textFeature = createTextFeature(geometry, toolTipText, textOpactiy, id);
    const vectorLayer = createVectorLayerFromGeometry(geometry, styleOptions, textFeature, id);
    return vectorLayer;
};

export const getDefaultStyleOptionsFromOverlay = (mapOverlay) =>
{
    let styleOptions = {};

    const color = tinyColor(mapOverlay.color);
    color.setAlpha(mapOverlay.defaultFillOpacity);

    const lineColor = tinyColor(mapOverlay.lineColor);
    lineColor.setAlpha(mapOverlay.defaultBorderFillOpacity);

    styleOptions = {
        stroke: {
            color: lineColor.toRgbString(),
            width: 1
        },
        fill: {
            color: color.toRgbString(),
        }
    }; 

    return styleOptions;
};

export const updateVectorLayerText = (vectorLayer, layerId, text, opacity) =>
{
    if (vectorLayer)
    {
        const overlaySource = vectorLayer.getSource();
        const textFeature = overlaySource.getFeatureById(`${layerId}_text`);
        if (textFeature)
        {
            updateTextFeatureStyle(textFeature, text, opacity);
        }
    }
};

export const updateVectorLayerStyle = (vectorLayer, color, lineColor, fillOpacity, borderFillOpacity) =>
{
    if (vectorLayer)
    {
        const styleOptions = createStyleOptions(color, lineColor, fillOpacity, borderFillOpacity);

        const currentStyle = vectorLayer.getStyle();

        if (currentStyle.fill?.color !== styleOptions.fill?.color)
        {
            vectorLayer.setStyle(createStyle(styleOptions));
        }
    }
};

const updateTextFeatureStyle = (textFeature, text, opacity) =>
{
    const textStyleOptions = createStyle({
        stroke: "rgba(0, 0, 0, 0)",
        fill: "rgba(0, 0, 0, 0)",
    });

    let textFeatureStyle = new Style(textStyleOptions);

    const textFontOptions = getTextFontOptions(opacity);

    textFeatureStyle.setText(createTextStyle(text, 0, textFontOptions));

    textFeature.setStyle(textFeatureStyle);
};

export const createTextFeature = (polygon, text, opacity, id) =>
{
    const textStyleOptions = createStyle({
        stroke: "rgba(0, 0, 0, 0)",
        fill: "rgba(0, 0, 0, 0)",
    });

    const textCoordinates = getCenter(polygon.getExtent());

    let textFeatureStyle = new Style(textStyleOptions);

    const textFontOptions = getTextFontOptions(opacity);

    textFeatureStyle.setText(createTextStyle(text, 0, textFontOptions));

    const textGeometry = new Point(textCoordinates);

    const textFeature = new Feature({
        geometry: textGeometry,
    });

    textFeature.setStyle(textFeatureStyle);
    textFeature.setId(`${id}_text`);

    return textFeature;
};

const createStyleOptions = (color, lineColor, fillOpacity, borderFillOpacity) =>
{
    let style = {};

    let lineColorObj = tinyColor(lineColor);
    lineColorObj.setAlpha(borderFillOpacity);

    style.stroke = {
        color: lineColorObj.toRgbString(),
        width: 1
    };

    let fillColor = tinyColor(color);
    fillColor.setAlpha(fillOpacity);

    style.fill = {
        color: fillColor.toRgbString()
    };

    return style;
};

const getTextFontOptions = (textOpacity) => 
{
    const textColorObj = tinyColor("black");
    textColorObj.setAlpha(textOpacity);

    return {
        size: "25px",
        fill: {
            color: textColorObj.toRgbString()
        },
        fontName: "Arial, sans-serif",
        font: "bold 11px Arial, sans-serif",
        stroke: {
            color: "rgb(0, 0, 0, 0)",
            width: 1
        },
        layerIdx: 6
    };
};

export const getShape = (feature) =>
{
    const geometry = feature.getGeometry();

    const gpsCoordinates = [];

    geometry.getCoordinates().forEach((set, i) => 
    {
        let coords = [];
        set.forEach((coordinate) =>
        {
            coords.push(toGpsLocation(coordinate));
        });

        gpsCoordinates[i] = coords;
    });

    return {
        type: geometry.getType(),
        coordinates: gpsCoordinates
    };
};

export const getShapeFromGeometry = (geometry) =>
{
    const gpsCoordinates = [];

    geometry.getCoordinates().forEach((set, i) => 
    {
        let coords = [];
        set.forEach((coordinate) =>
        {
            coords.push(toGpsLocation(coordinate));
        });

        gpsCoordinates[i] = coords;
    });

    return {
        type: geometry.getType(),
        coordinates: gpsCoordinates
    };
};

export const updateOpacitiesBasedOnZoomSettings = (mapOverlay, currentZoom) =>
{
    const { vectorLayer, dynamicOverlaySettings } = mapOverlay;

    let styleUpdateOptions = {
        color: mapOverlay.color,
        lineColor: mapOverlay.lineColor,
        toolTipText: mapOverlay.toolTipText[DEFAULT_LANGUAGE_CODE],
        fillOpacity: mapOverlay.defaultFillOpacity,
        borderFillOpacity: mapOverlay.defaultBorderFillOpacity,
        textOpacity: mapOverlay.defaultTextOpacity
    };

    if (dynamicOverlaySettings?.enabled)
    {
        const { startZoom, endZoom } = dynamicOverlaySettings;

        if (+currentZoom <= startZoom.value)
        {
            styleUpdateOptions = { 
                ...styleUpdateOptions, 
                fillOpacity: startZoom.fillOpacity, 
                borderFillOpacity: startZoom.borderFillOpacity,
                textOpacity: startZoom.borderFillOpacity 
            };
        }
        else if (+currentZoom >= endZoom.value)
        {
            styleUpdateOptions = { 
                ...styleUpdateOptions, 
                fillOpacity: endZoom.fillOpacity, 
                borderFillOpacity: endZoom.borderFillOpacity,
                textOpacity: endZoom.borderFillOpacity 
            };
        }
        else 
        {
            const newFillOpacity = getLinearInterpolatedOpacity(currentZoom, 
                startZoom.value, 
                startZoom.fillOpacity, 
                endZoom.value, 
                endZoom.fillOpacity);

            const newBorderOpacity = getLinearInterpolatedOpacity(currentZoom, 
                startZoom.value, 
                startZoom.borderFillOpacity, 
                endZoom.value, 
                endZoom.borderFillOpacity);
    

            const newTextOpacity = getLinearInterpolatedOpacity(currentZoom, 
                startZoom.value, 
                startZoom.textOpacity, 
                endZoom.value, 
                endZoom.textOpacity);
                            
            styleUpdateOptions = { 
                ...styleUpdateOptions, 
                fillOpacity: newFillOpacity, 
                borderFillOpacity: newBorderOpacity,
                textOpacity: newTextOpacity 
            };
        }
    }
                
    updateVectorLayerStyle(vectorLayer, 
        styleUpdateOptions.color,
        styleUpdateOptions.lineColor, 
        styleUpdateOptions.fillOpacity, 
        styleUpdateOptions.borderFillOpacity);

    updateVectorLayerText(vectorLayer,
        mapOverlay.id, 
        styleUpdateOptions.toolTipText, 
        styleUpdateOptions.textOpacity);
            
            
};

export const getCenterOfVectorLayer = (vectorLayer, id) =>
{
    // Get geometry extent of the selected map overlay
    const extent = vectorLayer.getSource()?.getFeatures()?.find((feature) => feature.getId() === id)?.getGeometry()?.getExtent();

    if (extent)
    {
        return getCenter(extent);
    }

};

/**
 * Linear Interpolation formula: y = y1 + ((x-x1)*(y2-y1))/(x2-x1)
 * Consider 'x's as zoom values and 'y's as opacities. So need to find the opacity for the given zoom level
 * @param {Number} currentZoom - x
 * @param {Number} startZoom - x1
 * @param {Number} startZoomOpacity - y1
 * @param {Number} endZoom - x2
 * @param {Number} endZoomOpacity - y2
 * @returns newOpacity - y
 */
export const getLinearInterpolatedOpacity = (currentZoom, startZoom, startZoomOpacity, endZoom, endZoomOpacity) => startZoomOpacity + ((currentZoom - startZoom)*(endZoomOpacity - startZoomOpacity))/(endZoom - startZoom);

export const createMapOverlayLayers = (mapOverlays, lang=DEFAULT_LANGUAGE_CODE) =>
{
    let mapOverlayLayers = [];

    const languageExtractedMapOverlays = extractLanguageCodeFromMapOverlayResult(mapOverlays, lang);

    languageExtractedMapOverlays.forEach((mapOverlay) =>
    {
        const textStyleFunction = createMapOverlayTextStyleFunction(mapOverlay);
        const textFeature = createMapOverlayTextFeature(mapOverlay, textStyleFunction);
        const styleFunction = createStyleFunction(mapOverlay);
        const mapOverlayLayer = createMapOverlayLayer(mapOverlay, styleFunction, textFeature);
        mapOverlayLayers.push(mapOverlayLayer);
    });

    return mapOverlayLayers;
};

export const getMapOverlayLayerMapById = (mapOverlayLayers) =>
{
    let mapOverlaysMap = {};

    mapOverlayLayers.forEach((mapOverlayLayer) =>
    {
        if (mapOverlayLayer.values_?.id)
        {
            mapOverlaysMap[mapOverlayLayer.values_.id] = mapOverlayLayer;
        }
    });

    return mapOverlaysMap;
};

export const getMapOverlayIdToIndexMap = (mapOverlays) =>
{
    let mapOverlayIdToIndexMap = {};
    mapOverlays.forEach((mapOverlay, index) =>
    {
        mapOverlayIdToIndexMap[mapOverlay._id] = index;
    });
    return mapOverlayIdToIndexMap;
};

export const getPercentage = (value) => value || value === 0 ? Math.floor((value * 100).toFixed(2)) : undefined;

export const getValueFromPercentage = (value) => value || value === 0 ? (+(parseFloat(value) / 100.0).toFixed(2)) : undefined;

export const getMapOverlayLangs = (mapOverlay) => Object.keys(mapOverlay?.name || []);

export const getAllAvailableMapOverlayLangs = (mapOverlay) =>
{
    let langs = new Set();
    // add all the lang codes present in map overlay text fields
    MAP_OVERLAY_TEXT_FIELDS.forEach((field) =>
    {
        Object.keys(mapOverlay[field]).forEach((langCode) => langs.add(langCode));
    });

    // add all lang codes present in text fields of zoom levels if dynamic overlay settings is enabled
    if (mapOverlay.dynamicOverlaySettings.enabled)
    {
        mapOverlay.dynamicOverlaySettings.zoomLevels.forEach((zoomLevel) =>
        {
            MAP_OVERLAY_ZOOM_LEVEL_TEXT_FIELDS.forEach((field) => 
            {
                Object.keys(zoomLevel[field]).forEach((langCode) => langs.add(langCode));
            });
        });
    }

    return [...langs];
};

// check if entity is type Polygon
export const isPolygon = (entityFeature) => entityFeature?.getGeometry()?.getType() === "Polygon";

export const toHexConvert = (str) => 
{
    var ctx = document.createElement("canvas").getContext("2d");
    ctx.fillStyle = str;
    return ctx.fillStyle;
};

export const checkAndConvertToHex = (color) => color && color.charAt(0) === "#" ? color : toHexConvert(color);

export const prependDefault = (fieldName) => "default" + fieldName.charAt(0).toUpperCase() + fieldName.slice(1);

export const extractOverlayInfoFromTemplateId =({ overlay, overlayTemplates }) => 
{
    let selectedTemplateInfo = overlayTemplates.find(({ templateId }) => templateId === overlay?.templateId);
    if (!selectedTemplateInfo) return overlay;
    selectedTemplateInfo = _.omit(selectedTemplateInfo, [...IGNORED_OVERLAY_TEMPLATE_FIELDS]);
    const newOverlay = { ...deepCopy(overlay), ...deepCopy(selectedTemplateInfo) };
    newOverlay.dynamicOverlaySettings.zoomLevels = newOverlay.dynamicOverlaySettings.zoomLevels.map((zoomLevel) => 
    {
        const newZoomLevel = { ...MAP_OVERLAY_DEFAULT_ZOOM_LEVEL_SETTINGS, ...zoomLevel };
        const remainingZoomLevelValue = newOverlay.templateOverwrites?.dynamicOverlaySettings?.zoomLevels?.find(({ zoomLevelId }) => zoomLevelId === newZoomLevel.zoomLevelId);
        if (remainingZoomLevelValue) 
        {
            return { ...newZoomLevel, ...remainingZoomLevelValue };
        }
        return newZoomLevel;
    });

    return newOverlay;
};

export const mapOverlayToDefaultVal = ({ overlay, selectedOverlayTemplateId }) =>
{
    let newOverlay = {
        templateId: selectedOverlayTemplateId,
    };
    const overlayDefaultVals = _.omit(NEW_OVERLAY_DEFAULT, ["vectorLayer", "name", "toolTipText", "_id"]);
    for (let key in overlay) 
    {
        if (key === "dynamicOverlaySettings") 
        {
            let templateOverwriteZoomLevels = overlay[key].zoomLevels
                .filter((zoomLevel) => zoomLevel.overrideGlobalTextLabel)
                .reduce((prev, zoomLevel) => [...prev, { textLabel: zoomLevel.textLabel, overrideGlobalTextLabel: zoomLevel.overrideGlobalTextLabel, zoomLevelId: zoomLevel.zoomLevelId }], []);

            if (templateOverwriteZoomLevels.length) 
            {
                newOverlay.templateOverwrites = { ...(overlay.templateOverwrites || {}), dynamicOverlaySettings: { enabled: overlay[key].enabled, zoomLevels: templateOverwriteZoomLevels, } };
            }
            else
            {
                newOverlay.templateOverwrites = _.omit((overlay.templateOverwrites || {}), "dynamicOverlaySettings");  
            }

            newOverlay[key] = {
                zoomLevels: [],
                enabled: false
            };
        }
        else if (overlayDefaultVals[key]) 
        {
            newOverlay[key] = overlayDefaultVals[key];
        }
        else 
        {
            newOverlay[key] = overlay[key];
        }
    }

    return newOverlay;
};