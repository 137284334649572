const turf = require("@turf/turf");
const { NODE_LINK_BUFFER_DD } = require("./map.constants");
const { ShapeTypes } = require("./entityTypes");
const { deepCopy } = require("mapsted.utils/objects");
const { Helmert } = require("../mapFunctions/cmshelmerttransformGeoRef");
const { polygon, bbox, bboxPolygon, centroid } = turf;
const { topology } = require("topojson-server");

const THRESHOLD_M = 0.00015;

/**
 * checks if an intersection between the two polygons exists
 * where the intersection is a polygon that is not one of the two polygons given.
 * @param {turf.Polygon} polygon1
 * @param {turf.Polygon} polygon2
 * @returns boolean
 */
exports.doPolygonsPartallyOverlap = (polygon1, polygon2) =>
{
    // null check to prevent errors
    if (!polygon1 || !polygon2)
    {
        return false;
    }

    // intersection can return undefined, multiLine feature, polygon feature
    try
    {
        let intersection = turf.intersect(polygon1, polygon2);

        if (intersection)
        {
            if (turf.getType(intersection) === "Polygon" || turf.getType(intersection) === "MultiPolygon")
            {
                // temporary threshold
                if (isIntersectionWithinThreshold(intersection, polygon1, polygon2))
                {
                    return false;
                }

                // check to make sure the overlap isn't just one of the polygons
                // (if a polygonI full contains polygonJ, the intersection will be the shape of polygonJ )
                return (!turf.booleanEqual(intersection, polygon1) && !turf.booleanEqual(intersection, polygon2));
            }
        }
    }
    catch (err)
    {
        console.log("error calculating overlap", err);
    }
    return false;
};

/**
 * Checks if two GeoJSON shapes are equal.
 *
 * @param {Object} geoJSON1 - The first GeoJSON shape.
 * @param {Object} geoJSON2 - The second GeoJSON shape.
 * @return {boolean} Returns true if the shapes are equal, false otherwise.
 */
exports.areGeoJSONShapesEqual = (geoJSON1, geoJSON2, entityId) =>
{
    // a null check to prevent errors
    if (!geoJSON1 || !geoJSON2)
    {
        return false;
    }

    try
    {
        // we need to first convert the geoJSON to a turf feature based off of geoJSON type
        const turfObject1 = turf.feature(geoJSON1);
        const turfObject2 = turf.feature(geoJSON2);
        const booleanEqual = turf.booleanEqual(turfObject1, turfObject2);

        return booleanEqual;
    }
    catch (err)
    {
        // catch block to make sure code doesn't crash if sent an invalid geoJSON, it instead will return false.
        console.log("error checking if geoJSON shapes are equal... returned false...", entityId, err);
        return false;
    }

};

/**
 * Creates a square with a given radius around a center point.
 *
 * @param {Object} centerPoint - The center point of the square.
 * @param {number} radius - The radius of the square.
 * @return {Object} - The square polygon object.
 */
exports.createSquareWithRadius = (centerPoint, radius) =>
{
    const cross = Math.sqrt(2 * radius ** 2);
    const coordinates = [];

    for (let i = 0; i < 4; i++)
    {
        coordinates.push(turf.destination(centerPoint, cross, i * -360 / 4 + 45, {}).geometry.coordinates);
    }
    coordinates.push(coordinates[0]);

    return turf.polygon([coordinates], {});
};

/**
 * Cuts a polygon with a line in a specified direction.
 * - creates a "thick" line based off of given direction
 * - we are expecting this thick line to cover half of the polygon. If its a large polygon its ok if there is parts in the direction not covered by the thick line.
 * - this "thick" line will be used when calling the difference between given polygon and the thick line
 * - we then use the difference to find any polygons that the thick line doesn't cover
 * - using intersect, we find any polygons that intersect with the passed split line
 * - these polygons will be returned as a lower/upper cut depending on direction passed.
 * call twice with different direction to cut in both directions [1 | -1]
 * this will return multi polygons on complex splits, for the purposes of editor we would consider those splits invalid. (at the time of writing, 03/04/2024)
 * https://gis.stackexchange.com/questions/344068/splitting-a-polygon-by-multiple-linestrings-leaflet-and-turf-js
 * @param {Object} polygon - the polygon to be cut
 * @param {Object} line - the line used for cutting the polygon
 * @param {number} direction - the direction in which the polygon is to be cut
 * @param {string} id - the identifier for the resulting polygon
 * @return {Object} the resulting cut polygon [1 | -1]
 */
exports.cutPolygon = (polygon, line, direction, id) =>
{
    let j;
    let polyCoords = [];
    let cutPolyGeometries = [];
    let retVal = null;

    if ((polygon.type != 'Polygon') || (line.type != 'LineString'))
    {
        return retVal;
    };

    // check if its a clean intersection
    let intersectPoints = turf.lineIntersect(polygon, line);
    let nPoints = intersectPoints.features.length;
    if ((nPoints == 0) || ((nPoints % 2) != 0))
    {
        return retVal;
    }

    // create a polygon with the line that should cover one half of the polygon that we will use to intersect to find one cut of the split
    let offsetLine = turf.lineOffset(line, (0.01 * direction), { units: 'kilometers' });

    for (j = 0; j < line.coordinates.length; j++)
    {
        polyCoords.push(line.coordinates[j]);
    }

    for (j = (offsetLine.geometry.coordinates.length - 1); j >= 0; j--)
    {
        polyCoords.push(offsetLine.geometry.coordinates[j]);
    }
    polyCoords.push(line.coordinates[0]);

    let thickLineString = turf.lineString(polyCoords);
    let thickLinePolygon = turf.lineToPolygon(thickLineString);

    let clipped = turf.difference(polygon, thickLinePolygon);

    // go through the result of the difference to find any polygons that intersect the split line
    // these would be the one cut of the polygon (upper or lower depending on the direction)
    for (j = 0; j < clipped.geometry.coordinates.length; j++)
    {
        if (clipped.geometry.type === "Polygon")
        {
            clipped.geometry.coordinates[j] = [clipped.geometry.coordinates[j]];
        }
        var turfPolygon = turf.polygon(clipped.geometry.coordinates[j]);
        var overlap = turf.lineOverlap(turfPolygon, line, { tolerance: 0.005 });
        if (overlap.features.length > 0)
        {
            cutPolyGeometries.push(turfPolygon.geometry.coordinates);
        }
    }

    if (cutPolyGeometries.length == 1)
    {
        retVal = turf.polygon(cutPolyGeometries[0], { id: id });
    }
    else if (cutPolyGeometries.length > 1)
    {
        retVal = turf.multiPolygon(cutPolyGeometries, { id: id });
    }

    return retVal;
};

/**
 * Merge two polygons if they are of type 'Polygon'.
 *
 * @param {Object} polygon1 - The first polygon to be merged
 * @param {Object} polygon2 - The second polygon to be merged
 * @return {Object} The merged polygon, or undefined if either input is not a 'Polygon'
 */
exports.mergePolygons = (polygon1, polygon2) =>
{
    let retVal;
    if ((polygon1.type != 'Polygon') || (polygon2.type != 'Polygon'))
    {
        return retVal;
    };

    const mergedPolygon = turf.union(polygon1, polygon2);

    return mergedPolygon;
};

/**
 * Shrink line to maintain a buffer from the current endpoints with the specified buffer_DD.
 * E.g., (0, 0) -> (0, 10) with a buffer of 1 would give (0, 1) -> (0, 9)
 * Note that if the 2 * buffer_DD >= line length we return the same line to avoid issues
 *
 * for length measurements we are using decimal degrees, the same format used to express latitude and longitude geographic coordinates
 * more on the precision of DD can be found here https://en.wikipedia.org/wiki/Decimal_degrees
 * @param {Array} startPoint - the starting point coordinates
 * @param {Array} endPoint - the ending point coordinates
 * @param {Number} buffer_DD - buffer in deccimal degrees
 * @return {Array} an array containing the adjusted start and end points
 */
exports.shrinkLineByBuffer = (startPoint, endPoint, buffer_DD = NODE_LINK_BUFFER_DD) =>
{
    // Calculate the angle of the line
    const angle = exports.calculateAngleBetweenTwoPoints(startPoint, endPoint);
    const lineString = turf.lineString([startPoint, endPoint]);
    const lineStringLength_DD = turf.length(lineString, "degrees") / 100;

    if (buffer_DD * 2 > lineStringLength_DD)
    {
        return [startPoint, endPoint];
    }

    // Calculate the offset points on the circumference of the circles
    const xBuffer = buffer_DD * Math.cos(angle);
    const yBuffer = buffer_DD * Math.sin(angle);

    // Add the offset points to the center of the circles
    const point1 = [startPoint[0] + xBuffer, startPoint[1] + yBuffer];
    const point2 = [endPoint[0] - xBuffer, endPoint[1] - yBuffer];

    if (!Number.isNaN(point1[0]) && !Number.isNaN(point1[1]) && !Number.isNaN(point2[0]) && !Number.isNaN(point2[1]))
    {
        return [point1, point2];
    }
    else
    {
        return [startPoint, endPoint];
    }
};

/**
 * Calculates the angle between two points in a 2D plane.
 *
 * @param {Array<number>} point1 - The coordinates of the first point in the form [x, y].
 * @param {Array<number>} point2 - The coordinates of the second point in the form [x, y].
 * @return {number} The angle between the two points in radians.
 */
exports.calculateAngleBetweenTwoPoints = (point1, point2) =>
{
    return Math.atan2(point2[1] - point1[1], point2[0] - point1[0]);
};

/**
 * Cleans the geometry of a shape. Removing any redundant coordinate.
 * See turf.cleanCoords for more info https://turfjs.org/docs/#cleanCoords
 *
 * @param {Object} shape - The shape object to be cleaned.
 * @param {string} shape.type - The type of the shape.
 * @param {Array<Array<number>>} shape.coordinates - The coordinates of the shape.
 * @return {Object|undefined} The cleaned shape object or undefined if the shape is invalid.
 */
exports.cleanShapeGeometry = (shape, entity) =>
{
    let turfFeature;
    let cleanShape = deepCopy(shape);

    if (!shape.type || !shape.coordinates)
    {
        return;
    }

    if (shape.type === ShapeTypes.POLYGON)
    {
        turfFeature = turf.polygon(shape.coordinates);
    }
    else if (shape.type === ShapeTypes.LINE_STRING)
    {

        const coordinates = shape.coordinates.reduce((acc, curr) =>
        {
            if (!acc.some(c => c[0] === curr[0] && c[1] === curr[1]))
            {
                acc.push(curr);
            }
            return acc;
        }, []);
        if (coordinates.length === 1)
        {
            const coord = coordinates[0];
            coordinates.push([coord[0] + 0.001, coord[1]]);

        }

        turfFeature = turf.lineString(coordinates);
    }

    if (turfFeature)
    {
        cleanShape.coordinates = turf.cleanCoords(turfFeature).geometry.coordinates;
    }

    return cleanShape;
};

/**
 * check if all intersection points are within the threshold
 * used when intersection is a polygon to confirm they are within threshold error.
 * biggest use case would be when snapping points
 * @param {turf.Polygon} intersection
 * @param {turf.Polygon} polygon1
 * @param {turf.Polygon} polygon2
 */
const isIntersectionWithinThreshold = (intersection, polygon1, polygon2) =>
{
    // null check to prevent errors
    if (!intersection || !polygon1 || !polygon2)
    {
        return false;
    }

    let polygons;

    if (turf.getType(intersection) === "MultiPolygon")
    {
        polygons = turf.getCoords(intersection);
    }
    else
    {
        polygons = [turf.getCoords(intersection)];
    }

    let polygon1Segments = turf.lineSegment(polygon1);
    let polygon2Segments = turf.lineSegment(polygon2);


    //  calculate distance between point n and all segments not including point n
    for (let i = 0; i < polygons.length; i++)
    {
        let polygon = turf.polygon(polygons[i]);
        let coords = polygons[i][0];

        let segments = turf.lineSegment(polygon);
        segments.features[0];

        for (let j = 0; j < coords.length; j++)
        {
            let point = turf.point(coords[j]);

            const minDistance1_m = findMinDistanceToSegments_m(point, polygon1Segments);
            const minDistance2_m = findMinDistanceToSegments_m(point, polygon2Segments);

            if (Math.max(minDistance1_m, minDistance2_m) > THRESHOLD_M)
            {
                return false;
            }
        }
    }

    return true;


};

/**
 * finds minium distance in meters from point to all given line segments
 * @param {turf.Point} point
 * @param {turf.FeatureCollection<turf.LineString>} segments
 * @returns {number} minimum distance in meters
 */
const findMinDistanceToSegments_m = (point, segments) =>
{
    // null check to prevent errors
    if (!point || !segments)
    {
        return false;
    }

    //  calculate distance between point n and all segments not including point n
    let minDistance_m;

    segments.features.forEach(segment =>
    {
        let pointToLineDistance = turf.pointToLineDistance(point, segment, { units: "meters" });

        if (minDistance_m === undefined || pointToLineDistance < minDistance_m)
        {
            minDistance_m = pointToLineDistance;
        }
    });

    return minDistance_m;
};


/**
 * Returns a bounding box polygon for the given point geometry collection
 * @param {{ geometries: turf.Point[] }} options - options object containing the point geometry collection
 * @returns {turf.Polygon} the bounding box polygon
 */
exports.getBBOXFromPointGeometryCollection = ({ geometries }, newRotationAngle) =>
{
    //extract the coordinates
    const coordinates = geometries.map((p) => p.coordinates);
    //close the polygon to make it a valid polygon
    coordinates.push([...coordinates[0]]);
    const turfPolygon = polygon([coordinates]);

    const _bbox = bbox(turfPolygon);

    // Create a polygon representing the bounding box
    let _bboxPolygon = bboxPolygon(_bbox);

    if (newRotationAngle)
    {
        _bboxPolygon = exports.rotatePolygon(_bboxPolygon, newRotationAngle);
    }



    return _bboxPolygon;
};


/**
 * Transforms coordinates with separate scaleX and scaleY, old and new centers, and rotation angles.
 * @param {Array} coords - The coordinates to be transformed [[x1, y1], [x2, y2], ...].
 * @param {Array} oldScale - The old scale factor [scaleX, scaleY].
 * @param {Array} newScale - The new scale factor [scaleX, scaleY].
 * @param {Array} oldCenter - The old center [cx, cy].
 * @param {Array} newCenter - The new center [cx, cy].
 * @param {Number} oldRotation - The old rotation in radians.
 * @param {Number} newRotation - The new rotation in radians.
 * @returns {Array} Transformed coordinates.
 */
exports.transformCoordinates = (coords, oldScale, newScale, oldCenter, newCenter, oldRotation, newRotation) =>
{


    // Calculate rotation differences and convert to radians
    const rotationDiff = (newRotation - newRotation);

    // Calculate the scaling factor ratios
    const scaleFactorX = newScale[0] / oldScale[0];
    const scaleFactorY = newScale[1] / oldScale[1];

    // Function to rotate a point around the origin
    const rotatePoint = ([x, y], angle) =>
    {
        const cos = Math.cos(angle);
        const sin = Math.sin(angle);
        return [
            cos * x - sin * y,
            sin * x + cos * y
        ];
    };

    // Transform each coordinate
    const transformedCoords = coords.map(([x, y]) =>
    {
        // 1. Translate point to origin (subtract old center)
        let translatedX = x - oldCenter[0];
        let translatedY = y - oldCenter[1];

        // 2. Apply scaling
        translatedX *= scaleFactorX;
        translatedY *= scaleFactorY;

        // 3. Apply rotation
        const [rotatedX, rotatedY] = rotatePoint([translatedX, translatedY], rotationDiff);

        // 4. Translate point to new center
        const finalX = rotatedX + newCenter[0];
        const finalY = rotatedY + newCenter[1];

        return [finalX, finalY];
    });

    return transformedCoords;
};

/**
 * Function to change the scale, center (translation), and rotation of a TopoJSON object
 * @param {Object} topology - The TopoJSON object
 * @param {Number} newScaleX - The new scale value for the X axis
 * @param {Number} newScaleY - The new scale value for the Y axis
 * @param {Array} newCenter - The new center [translateX, translateY]
 * @param {Number} oldRotationAngle - The old rotation angle in radians
 * @param {Number} newRotationAngle - The new rotation angle in radians
 * @return {Object} - The updated TopoJSON object with new scale, center, and rotation
 */
exports.updateTopoJSON = (topo, newScaleX, newScaleY, newCenter, oldRotationAngle, newRotationAngle) =>
{
    const topology = topo;
    const oldScaleX = topology.transform.scale[0];
    const oldScaleY = topology.transform.scale[1];
    const oldTranslateX = topology.transform.translate[0];
    const oldTranslateY = topology.transform.translate[1];

    // // Convert rotation angles from degrees to radians
    const oldAngleRad = (oldRotationAngle);
    const newAngleRad = (newRotationAngle);

    const [translateX, translateY] = newCenter;

    topology.transform.scale = [newScaleX, newScaleY];
    topology.transform.translate = [translateX, translateY];


    topology.arcs.map((arc, i) =>
    {

        let [x, y] = [0, 0];  // Initialize starting point for delta decoding.

        const decodedArc = arc.map(([dx, dy]) =>
        {
            // Calculate the absolute position by summing the deltas.
            x += dx;
            y += dy;

            // Apply the transformation (scale and translate).
            const absX = x * oldScaleX + oldTranslateX;
            const absY = y * oldScaleY + oldTranslateY;

            return [absX, absY];
        });



        const transformedCoordinates = exports.transformCoordinates(decodedArc, [oldScaleX, oldScaleY], [newScaleX, newScaleY], [oldTranslateX, oldTranslateY], [translateX, translateY], oldAngleRad, newAngleRad);

        let previousX = 0;
        let previousY = 0;


        const encodedArc = transformedCoordinates.map(([x, y], index) =>
        {

            // Apply inverse transform (scaling and translation)
            const scaledX = ((x - newCenter[0]) / newScaleX);
            const scaledY = ((y - newCenter[1]) / newScaleY);

            // Apply delta encoding (difference between current and previous point)
            const deltaX = scaledX - previousX;
            const deltaY = scaledY - previousY;

            // Store current point as previous for next iteration
            previousX = scaledX;
            previousY = scaledY;

            // Return the delta encoded point
            return [deltaX, deltaY];
        });

        topology.arcs[i] = encodedArc;

    });



    return topology;
};


/**
 * Rotate a polygon using Turf.js
 * @param {Object} polygon - GeoJSON Polygon Feature
 * @param {Number} angle - Rotation angle in degrees (positive is counterclockwise, negative is clockwise)
 * @param {Array} [pivot] - Optional pivot point [longitude, latitude], defaults to polygon centroid
 * @return {Object} - Rotated GeoJSON Polygon Feature
 */
exports.rotatePolygon = (polygon, angle, pivot = null) =>
{
    // If no pivot is provided, use the centroid of the polygon as the pivot point
    if (!pivot)
    {
        pivot = turf.centroid(polygon).geometry.coordinates;
    }

    // Rotate the polygon
    const rotatedPolygon = turf.transformRotate(polygon, angle, { pivot });

    return rotatedPolygon;
};




/**
 * Returns a bounding box polygon for the given topology.
 * @param {Object} topology - The topology object.
 * @returns {turf.Polygon} the bounding box polygon
 */
exports.bboxPolygonFromTopo = (topology) =>
{
    let minX, minY, maxX, maxY;

    const arcs = Object.values(topology.arcs);
    arcs.forEach((arc) =>
    {
        arc.forEach(([x, y]) =>
        {
            if (minX === undefined || x < minX)
            {
                minX = x;
            }

            if (minY === undefined || y < minY)
            {
                minY = y;
            }

            if (maxX === undefined || x > maxX)
            {
                maxX = x;
            }

            if (maxY === undefined || y > maxY)
            {
                maxY = y;
            }
        });
    });

    const _bboxPolygon = bboxPolygon(([minX, minY, maxX, maxY]));

    return _bboxPolygon;
};

/**
 * Converts an inverse cosine radius to degrees.
 *
 * @param {number} icr - The inverse cosine radius to be converted.
 * @return {number} The angle in degrees.
 */
exports.convertInverseCosineRadiusToDegree = (icr) =>
{
    let angle = (icr * 180) / Math.PI;
    while (angle < 0)
    {
        angle += 360;
    }
    // Ensure angle is between 0 and 360
    while (angle >= 360)
    {
        angle -= 360;
    }

    return angle;
};


exports.convertDegreesToRadians = (degree) =>
{
    let angle = degree * Math.PI / 180;
    while (angle < 0)
    {
        angle += 2 * Math.PI;
    }
    // Ensure angle is between 0 and 2 * PI
    while (angle >= 2 * Math.PI)
    {
        angle -= 2 * Math.PI;
    }

    return angle;
};
