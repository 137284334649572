import React from "react";
import { Button, Header, Checkbox, Popup } from "semantic-ui-react";
import { deepValue } from "mapsted.utils/objects";
import { InputFormGroup } from "../elements/InputFormGroup";
import { getTimeFromMins, getMinsFromTime, getCalanderStart, getCalanderEnd, formatDate, isPeriodOpen24Hours, convertOpeningHoursToPartial } from "../../_utils/utils";
import { CLOSING_TIME_MINS, ERROR_MESSAGES, OPENING_TIME_MINS } from "../../_constants/constants";
import moment from "moment";
import PropTypes from "prop-types";
import { ErrorLabel } from "../elements/ErrorLabel";
import { withTranslation } from "react-i18next";
import "./addEditHoliday.css";
import { PROPERTIES } from "../../_constants/config";

const DATE_FORMAT = "DD-MM-YYYY";

class AddEditHolidayImpl extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            isEditOpen: false,
            name: "",
            openTime: undefined,
            closeTime: undefined,
            isOpen: true,
            isOpen24Hours: false,
            date: undefined,
            isNew: true,
            minDate: undefined,
            maxDate: undefined,
            validationError: false,
            holidayDateValidationError: false,
            disableDateInput: false
        };
    }

    /**
     * Calculate min and max date for calendar based on selectedYear from props
     * Also returns flag to disable dateInput if min date falls over to next year
     * @returns {Object} Object containing minDate, maxDate and disableDateInput
     */
    getMinAndMaxDate() 
    {

        let minDate = undefined;
        let maxDate = undefined;
        let disableDateInput = false;

        const { selectedYear } = this.props;

        maxDate = moment([selectedYear]).endOf("year");
        minDate = this.getInitialSelectableDate(this.props.selectedDates, selectedYear);

        /** Check if calculated min date falls over to next year 
         *  if so decrememt day by one and set flag disableDateInput to true
         **/
        if (minDate.format(DATE_FORMAT) === moment([selectedYear + 1]).startOf("year").format(DATE_FORMAT)) 
        {
            minDate.add(-1, "days");
            disableDateInput = true;
        }

        return { minDate, maxDate, disableDateInput };
    }

    /**
     * Calculates inital selectable date after taking existing holidays into consideration
     * @param {Array} selectedDates 
     * @param {Number} year 
     * @returns 
     */
    getInitialSelectableDate(selectedDates, year) 
    {

        let initDate = moment([year]).startOf("year");

        if (year === moment().year()) 
        {
            initDate =  moment().add(1, "days").startOf("day");
        }

        if (Array.isArray(selectedDates)) 
        {
            for (let i = 0; i < selectedDates.length; i++) 
            {
                const formattedInitDate = initDate.format(DATE_FORMAT);
                if (formattedInitDate === selectedDates[i].format(DATE_FORMAT)) 
                {
                    initDate.add(1, "days");
                }
            }
        }

        return initDate;
    }

    handleUpdateStateWithHoliday ()
    {
        const minDate = getCalanderStart(this.props.selectedDates);
        let updatedState = {};

        if (this.props.holiday)
        {
            const periods = Array.isArray(this.props.holiday.periods) ? this.props.holiday.periods : []; // just in case check
            const isOpen = (Array.isArray(periods)) && periods.length > 0;
            const isOpen24Hours = isPeriodOpen24Hours(periods, true);

            const period = periods[0];
            const openTime = deepValue(period, "open.time", OPENING_TIME_MINS);
            const closeTime = deepValue(period, "close.time", CLOSING_TIME_MINS);


            updatedState = { ...this.props.holiday };
            updatedState.openTime = getTimeFromMins(openTime);
            updatedState.closeTime = getTimeFromMins(closeTime);
            updatedState.date = formatDate(updatedState.date, DATE_FORMAT);
            updatedState.isNew = false;
            updatedState.isOpen = isOpen;
            updatedState.isOpen24Hours = isOpen24Hours;
        }
        else
        {
            updatedState = {
                name: "",
                openTime: getTimeFromMins(OPENING_TIME_MINS),
                closeTime: getTimeFromMins(CLOSING_TIME_MINS),
                isOpen: true,
                isOpen24Hours: false,
                date: formatDate(minDate, DATE_FORMAT),
                isNew: true,
            };
        }

        updatedState.minDate = minDate;
        updatedState.endDate = getCalanderEnd();
        updatedState.allBuildings = false;

        if (updatedState.isNew) 
        {
            const { minDate, maxDate, disableDateInput } = this.getMinAndMaxDate();
            updatedState.minDate = minDate;
            updatedState.maxDate = maxDate;
            updatedState.date = formatDate(minDate, DATE_FORMAT);
            updatedState.disableDateInput = disableDateInput;
        }

        this.setState(updatedState);
    }

    handleSave = async (remove = false) =>
    {
        const newOpenTime = getMinsFromTime(this.state.openTime);
        const newCloseTime = getMinsFromTime(this.state.closeTime);
        const HolidayDateRegExp =/^[0-9/-]*$/;
        const holidayDateValidate = HolidayDateRegExp.test(this.state.date);

        if ((newOpenTime >= newCloseTime) && (this.state.isOpen) && (!this.state.isOpen24Hours) && (!remove))
        {
            this.setState({ validationError: true });
        }
        else if (!holidayDateValidate)
        {
            this.setState({ holidayDateValidationError: true });
        }
        else
        {
            const newDate = moment.utc(this.state.date, "DD-MM-YYYY").toDate();
            const periods = convertOpeningHoursToPartial({ openTime: newOpenTime, closeTime: newCloseTime, isOpen: this.state.isOpen, isOpen24Hours: this.state.isOpen24Hours, date: newDate });

            await this.props.onSave(
                {
                    name: this.state.name,
                    periods: periods,

                    date: newDate,
                    _id: this.props.holiday ? this.props.holiday._id : undefined
                }, this.state.allBuildings, remove);

            this.handleClose(true);
        }
    }

    handleOnOpen = () =>
    {
        this.setState({ isEditOpen: true });
        this.handleUpdateStateWithHoliday();
    };

    handleClose = (close) =>
    {
        //WARNING: THIS IS HERE TO PREVENT CLOSE ON CALENDAR OR TIME CLICK DO NOT REMOVE.
        let isEditOpen = true;
        if (close === true)
        {
            isEditOpen = false;
        }

        this.setState({ isEditOpen: isEditOpen, validationError: false });

    }

    handleChange = (e, data) =>
    {
        let updatedState = { [data.name]: data.value };

        if (data.name === "openTime" || data.name === "closeTime")
        {
            updatedState.validationError = false;
        }
        else if (data.name === "isOpen")
        {
            updatedState[data.name] = !data.checked;
            updatedState.isOpen24Hours = false;
            updatedState.validationError = false;
        }
        else if (data.name === "isOpen24Hours")
        {
            updatedState[data.name] = data.checked;
            updatedState.isOpen = true;
            updatedState.validationError = false;
        }
        else if (data.name === "allBuildings")
        {
            updatedState[data.name] = data.checked;
        }
        else if (data.name === "date")
        {
            updatedState.holidayDateValidationError = false;
        }

        this.setState(updatedState);
    }

    render ()
    {
        const { isEditOpen, name, openTime, closeTime, isOpen, isOpen24Hours, date, isNew, allBuildings, minDate, maxDate, validationError,holidayDateValidationError, disableDateInput } = this.state;
        const { trigger, edit, selectedDates, isProperty, holiday, businessHours, t: trans } = this.props;

        return (
            <>
                {isEditOpen && <div className="trigger-hide" onClick={() => this.handleClose(true)}></div>}
                <Popup
                    className="holiday-popup"
                    on="click"
                    hideOnScroll={false}
                    position="right center"
                    open={isEditOpen}
                    trigger={trigger}
                    onClose={this.handleClose}
                    onOpen={this.handleOnOpen}
                >
                    <Button className="close" onClick={() => this.handleClose(true)} />
                    <Header as="h4">
                        {!businessHours? <>{edit ? trans("AddEditHoliday.Edit_Holiday") : trans("AddEditHoliday.Add_Holiday")}</>: businessHours
                        }
                    </Header>
                    <InputFormGroup label={trans("AddEditHoliday.Holiday_")} placeholder={trans("AddEditHoliday.New_Year's_Day")} value={name} maxLength={PROPERTIES.MAX_HOLIDAY_NAME_LENGTH} name="name" onChange={this.handleChange} />
                    {
                        <InputFormGroup
                            type="date"
                            className={`${holidayDateValidationError ? "error" : ""}`}
                            label={trans("AddEditHoliday.Date_")}
                            placeholderDate="Date"
                            popupPosition="right center" 
                            dateValue={date}
                            iconPosition="right"
                            minDate={minDate}
                            maxDate={maxDate}
                            disabled={!isNew || disableDateInput}
                            selectedDates={selectedDates}
                            name="date"
                            onChange={this.handleChange}
                        />
                    }

                    <InputFormGroup label={trans("AddEditHoliday.Hours_")} className={`hours-group ${validationError ? "error" : ""}`}>

                        <div className="inner-group calender-group">
                            <Checkbox className="check-calendar calender-check" label={trans("AddEditHoliday.Closed")} name="isOpen" checked={!isOpen} onChange={this.handleChange} />
                        </div>
                        <div className="inner-group calender-group">
                            <Checkbox className="check-calendar calender-check" label={trans("AddEditHoliday.Open_24_hours")} name="isOpen24Hours" checked={isOpen24Hours} onChange={this.handleChange} />
                        </div>

                        <div className="hours-group-inner-bx">
                            <div className="inner-group calender-group">
                                <InputFormGroup type="time" disabled={(isOpen24Hours || !isOpen)} name="openTime" timeValue={openTime} onChange={this.handleChange} />
                            </div>

                            <span className="seperate">-</span>

                            <div className="inner-group calender-group">
                                <InputFormGroup type="time" disabled={(isOpen24Hours || !isOpen)} name="closeTime" timeValue={closeTime} onChange={this.handleChange} />
                            </div>
                        </div>
                    </InputFormGroup>

                    {
                        (validationError) && (
                            <ErrorLabel label={ERROR_MESSAGES.invalidOpeningHours} />
                        )
                    }

                    {
                        (holidayDateValidationError) && (
                            <ErrorLabel label={ERROR_MESSAGES.invalidHolidayDate} />
                        )
                    }
                    
                    <div className={`actions-group ${edit || businessHours ? "remove-group" : ""}`}>
                        {
                            (isProperty) && (
                                <Checkbox className="check-calendar" label={trans("AddEditHoliday.Apply_to_all_buildings")} name="allBuildings"
                                    checked={allBuildings} onChange={this.handleChange} />
                            )
                        }
                        {
                            (!isNew && holiday._id) && (
                                <Button color="grey" content={trans("AddEditHoliday.Remove_Holiday")} onClick={() => this.handleSave(true)} />
                            )
                        }

                        <Button disabled={name.length === 0} color="orange" content={isNew ? trans("AddEditHoliday.Add") : trans("AddEditHoliday.Done")} onClick={() => this.handleSave(false)} />
                    </div>
                </Popup >
            </>
        );
    }

}

export const AddEditHoliday = withTranslation()(AddEditHolidayImpl);

AddEditHoliday.propTypes = {
    edit: PropTypes.bool,
    isProperty: PropTypes.bool,
    holiday: PropTypes.object,
    selectedDates: PropTypes.array,
    trigger: PropTypes.any,
    onSave: PropTypes.func,
};

