const EntityType = {
    UNKNOWN_ENTITY: 0,
    BOUNDARY: 100,
    CLUSTER: 200,
    OBSTACLE: 300,
    POINT_OF_INTEREST: 400,
    STRUCTURE: 500,
};

exports.EntityType = EntityType;

const BoundaryEntityType = {
    UNKNOWN_BOUNDARY: 0,
    PROPERTY_BOUNDARY: 100,
    BUILDING_BOUNDARY: 200,
    FLOOR_BOUNDARY: 300,
};

exports.BoundaryEntityType = BoundaryEntityType;

const ClusterEntityType = {
    UNKNOWN_CLUSTER: 0,
    INDOOR_CLUSTER: 10,  // standard cluster
    OUTDOOR_CLUSTER: 20,  // assumes GPS might *not* be useless
    SUB_REGION_CLUSTER: 30,  // for defining smaller clusters (e.g., large stores)
    AISLE_CLUSTER: 40,  // cluster between two racks (e.g., grocery store, department store)
    UNDERGROUND_CLUSTER: 50,  // e.g., the path or subway (different from indoor cluster), assumes GPS is useless
};

exports.ClusterEntityType = ClusterEntityType;

/**
 * Something that does NOT affect the structural integrity of the building (or property)
 */
const ObstacleEntityType = {
    UNKNOWN_OBSTACLE: 0,
    BAGGAGE_CAROUSEL: 100,
    DESK: 400,
    EQUIPMENT: 500,  // factory items
    FURNITURE: 600,  // chairs, tables
    KIOSK: 700,
    OBSTRUCTION: 800,  // basically anything which the user can't pass through that doesn't fit into other categories
    SHELVING: 900,  // e.g., in Canadian Tire
    STAGE: 1000,
    VEGETATION: 1100,  // e.g., a tree in the middle of a pathway, garden, park
    WALL: 1200,
    WATER: 1300,  // e.g., pond
};

exports.ObstacleEntityType = ObstacleEntityType;

/**
 * Something that does affects the structural integrity of the building (or property)
 */
const StructureEntityType = {
    UNKNOWN_STRUCTURE: 0,
    BUILDING: 100,
    ELEVATOR: 200,
    ESCALATOR: 300,
    ENTRANCE_EXIT: 400,
    FLOOR_OPENING: 500,
    INACCESSIBLE: 600, // unknown space or accessible to only some individuals
    MOVING_WALKWAY: 700, // e.g., in airport
    ROOM: 800,
    PARKING: 900,
    PATHWAY: 1000,
    PATHWAY_INNER_BOUNDARY: 1100, // pathway polygon holes e.g., formed by grouping of stores in the middle of the pathway
    PLATFORM: 1200, // e.g., train station
    RAMP: 1300, // handicap accessible ramp on same floor only
    STAIRS: 1400, // Transitions between floors (not same floor)
    STEPS: 1500, // on same floor only (i.e., not stairs)
    WASHROOM: 1600,
};

function fromEntries(entries)
{
    return entries.reduce((acc, [key, value]) =>
    {
        acc[key] = value;
        return acc;
    }, {});
}

exports.StructureEntityTypeNames = fromEntries(Object.entries(StructureEntityType).map(([k, v]) => [v, k]));

exports.StructureEntityType = StructureEntityType;

/**
 * Sub-type not used for POI
 */
const PointOfInterestType = {
    UNKNOWN_POI: 0,
};

exports.PointOfInterestType = PointOfInterestType;

const TransitionType = {
    UNKNOWN_TRANSITION: 0,
    ENTRANCE_EXIT: 10,
    STAIRS: 20,
    ESCALATOR: 30,
    ELEVATOR: 40,
    SUBWAY_STATION: 50,
    TRAIN_STATION: 60,
    BUS_STOP: 70,
    SHUTTLE: 80,
    GATE: 90,
    ACCESSIBLE_RAMP: 100,
    CONVEYOR_BELT: 110,
    STEPS: 120,
};
exports.TransitionType = TransitionType;

exports.TransitionTypes = {
    [TransitionType.UNKNOWN_TRANSITION]: {
        name: "Unknown"
    },
    [TransitionType.ENTRANCE_EXIT]: {
        name: "Entrance Exit"
    },
    [TransitionType.STAIRS]: {
        name: "Stairs"
    },
    [TransitionType.ESCALATOR]: {
        name: "Escalator"
    },
    [TransitionType.ELEVATOR]: {
        name: "Elevator"
    },
    [TransitionType.SUBWAY_STATION]: {
        name: "Subway Station"
    },
    [TransitionType.TRAIN_STATION]: {
        name: "Train Station"
    },
    [TransitionType.BUS_STOP]: {
        name: "Bus Stop"
    },
    [TransitionType.SHUTTLE]: {
        name: "Shuttle"
    },
    [TransitionType.GATE]: {
        name: "Gate"
    },
    [TransitionType.ACCESSIBLE_RAMP]: {
        name: "Accessible Ramp"
    },
    [TransitionType.CONVEYOR_BELT]: {
        name: "Conveyor Belt"
    },
    [TransitionType.STEPS]: {
        name: "Steps"
    },
};


exports.EntityTypes = {
    [EntityType.UNKNOWN_ENTITY]: {
        name: "Unknown",
        subTypes: {}
    },
    [EntityType.BOUNDARY]: {
        name: "Boundary",
        subTypes: {
            [BoundaryEntityType.UNKNOWN_BOUNDARY]: {
                name: "Unknown"
            },
            [BoundaryEntityType.PROPERTY_BOUNDARY]: {
                name: "Property Boundary"
            },
            [BoundaryEntityType.BUILDING_BOUNDARY]: {
                name: "Building Boundary"
            },
            [BoundaryEntityType.FLOOR_BOUNDARY]: {
                name: "Floor Boundary"
            }
        }
    },
    [EntityType.OBSTACLE]: {
        name: "Obstacle",
        subTypes: {
            [ObstacleEntityType.UNKNOWN_OBSTACLE]: {
                name: "Unknown"
            },
            [ObstacleEntityType.BAGGAGE_CAROUSEL]: {
                name: "Baggage Carousel"
            },
            [ObstacleEntityType.DESK]: {
                name: "Desk"
            },
            [ObstacleEntityType.EQUIPMENT]: {
                name: "Equipment"
            },
            [ObstacleEntityType.FURNITURE]: {
                name: "Furniture"
            },
            [ObstacleEntityType.KIOSK]: {
                name: "Kiosk"
            },
            [ObstacleEntityType.OBSTRUCTION]: {
                name: "Obstruction"
            },
            [ObstacleEntityType.SHELVING]: {
                name: "Shelving"
            },
            [ObstacleEntityType.STAGE]: {
                name: "Stage"
            },
            [ObstacleEntityType.VEGETATION]: {
                name: "Vegetation"
            },
            [ObstacleEntityType.WALL]: {
                name: "Wall"
            },
            [ObstacleEntityType.WATER]: {
                name: "Water"
            }
        }
    },
    [EntityType.POINT_OF_INTEREST]: {
        name: "Point of Interest",
        subTypes: {
            [PointOfInterestType.UNKNOWN_POI]: {
                name: "Unknown POI"
            }
        }
    },
    [EntityType.STRUCTURE]: {
        name: "Structure",
        subTypes: {
            [StructureEntityType.UNKNOWN_STRUCTURE]: {
                name: "Unknown Structure"
            },
            [StructureEntityType.BUILDING]: {
                name: "Building"
            },
            [StructureEntityType.ELEVATOR]: {
                name: "Elevator"
            },
            [StructureEntityType.ESCALATOR]: {
                name: "Escalator"
            },
            [StructureEntityType.ENTRANCE_EXIT]: {
                name: "Entrance-Exit"
            },
            [StructureEntityType.FLOOR_OPENING]: {
                name: "Floor Opening"
            },
            [StructureEntityType.INACCESSIBLE]: {
                name: "Inaccessible"
            },
            [StructureEntityType.MOVING_WALKWAY]: {
                name: "Moving Walkway"
            },
            [StructureEntityType.ROOM]: {
                name: "Room"
            },
            [StructureEntityType.PARKING]: {
                name: "Parking"
            },
            [StructureEntityType.PATHWAY]: {
                name: "Pathway"
            },
            [StructureEntityType.PATHWAY_INNER_BOUNDARY]: {
                name: "Pathway - Inner Boundary"
            },
            [StructureEntityType.PLATFORM]: {
                name: "Platform"
            },
            [StructureEntityType.RAMP]: {
                name: "Ramp"
            },
            [StructureEntityType.STAIRS]: {
                name: "Stairs"
            },
            [StructureEntityType.STEPS]: {
                name: "Steps"
            },
            [StructureEntityType.WASHROOM]: {
                name: "Washroom"
            }
        }
    }
};


exports.EntityRefTypes = {
    PROPERTY: "Property",
    FLOOR: "Floor"
};

exports.ShapeTypes = {
    POLYGON: "Polygon",
    POINT: "Point",
    LINE_STRING: "LineString",
    TEXT_POINT: "TextPoint",
    CIRCLE: "Circle",
};

exports.NonNamableObstacleSubEntityTypes = [
    ObstacleEntityType.WALL
];

exports.NonNamableStructureSubEntityTypes = [
    StructureEntityType.FLOOR_OPENING,
    StructureEntityType.INACCESSIBLE,
    StructureEntityType.PATHWAY,
    StructureEntityType.PATHWAY_INNER_BOUNDARY,
    StructureEntityType.RAMP,
    StructureEntityType.STAIRS,
    StructureEntityType.STEPS,
    StructureEntityType.MOVING_WALKWAY
];

exports.RoutableStructureSubEntityTypes = [
    StructureEntityType.ELEVATOR,
    StructureEntityType.ENTRANCE_EXIT,
    StructureEntityType.STAIRS,
    StructureEntityType.ESCALATOR,
    StructureEntityType.MOVING_WALKWAY,
    StructureEntityType.PARKING,
    StructureEntityType.PLATFORM,
    StructureEntityType.RAMP,
    StructureEntityType.ROOM,
    StructureEntityType.BUILDING,
    StructureEntityType.WASHROOM
];

exports.RoutableObstacleSubEntityTypes = [
    ObstacleEntityType.DESK,
    ObstacleEntityType.EQUIPMENT,
    ObstacleEntityType.FURNITURE,
    ObstacleEntityType.KIOSK,
    ObstacleEntityType.OBSTRUCTION,
    ObstacleEntityType.SHELVING,
    ObstacleEntityType.STAGE,
    ObstacleEntityType.UNKNOWN_OBSTACLE,
    ObstacleEntityType.VEGETATION,
    ObstacleEntityType.WATER,
];

// const TransitionType = {
//     UNKNOWN_TRANSITION: 0,
//     ENTRANCE_EXIT: 10,
//     STAIRS: 20,
//     ESCALATOR: 30,
//     ELEVATOR: 40,
//     SUBWAY_STATION: 50,
//     TRAIN_STATION: 60,
//     BUS_STOP: 70,
//     SHUTTLE: 80,
//     GATE: 90,
//     ACCESSIBLE_RAMP: 100,
//     CONVEYOR_BELT: 110,
//     STEPS: 120,
// };

exports.SAME_LEVEL_TRANSITION_TYPES = [
    TransitionType.UNKNOWN_TRANSITION,
    TransitionType.ENTRANCE_EXIT,
    TransitionType.GATE,
    TransitionType.ACCESSIBLE_RAMP,
    TransitionType.CONVEYOR_BELT,
    TransitionType.STEPS,
];

exports.FLOOR_TO_FLOOR_TRANSITION_TYPES = [
    TransitionType.UNKNOWN_TRANSITION,
    TransitionType.ENTRANCE_EXIT,
    TransitionType.STAIRS,
    TransitionType.ESCALATOR,
    TransitionType.ELEVATOR,
    TransitionType.GATE,
    TransitionType.ACCESSIBLE_RAMP,
    TransitionType.CONVEYOR_BELT,
];

exports.PROPERTY_LEVEL_TRANSITION_TYPES = [
    TransitionType.UNKNOWN_TRANSITION,
    TransitionType.ENTRANCE_EXIT,
    TransitionType.STAIRS,
    TransitionType.ESCALATOR,
    TransitionType.ELEVATOR,
    TransitionType.SUBWAY_STATION,
    TransitionType.TRAIN_STATION,
    TransitionType.BUS_STOP,
    TransitionType.SHUTTLE,
    TransitionType.GATE,
    TransitionType.ACCESSIBLE_RAMP,
    TransitionType.CONVEYOR_BELT,
];

exports.isRoutableEntity = (entityType, subEntityType, entityName) =>
{
    let numEntityType = Number(entityType);
    let numSubEntityType = Number(subEntityType);

    if (numEntityType === EntityType.BOUNDARY)
    {
        return false;
    }
    else if (numEntityType === EntityType.STRUCTURE)
    {
        return exports.RoutableStructureSubEntityTypes.includes(numSubEntityType);
    }
    else if (numEntityType === EntityType.OBSTACLE)
    {
        return exports.RoutableObstacleSubEntityTypes.includes(numSubEntityType);
    }
    // POIs should be routable if they have a name
    else if (numEntityType === EntityType.POINT_OF_INTEREST)
    {
        return true;
    }

    return false;
};

exports.isEntityNameable = (entityType, subEntityType) =>
{

    let numEntityType = Number(entityType);
    let numSubEntityType = Number(subEntityType);

    if (numEntityType === EntityType.BOUNDARY)
    {
        return false;
    }
    else if (numEntityType === EntityType.STRUCTURE)
    {
        const isNonNamableStructure = exports.NonNamableStructureSubEntityTypes.includes(numSubEntityType);

        return !isNonNamableStructure;
    }
    else if (numEntityType === EntityType.OBSTACLE)
    {
        const isNonNamableObstacle = exports.NonNamableObstacleSubEntityTypes.includes(numSubEntityType);

        return !isNonNamableObstacle;
    }

    return true;
};
