import React from "react";
import { Dropdown } from "semantic-ui-react";
import "./LanguageSelector.css";
const LanguageSelector = ({
    activeLang,
    setActiveLang,
    defaultDisplayedLangs
}) => (
    <Dropdown
        className="manageCategoryLangSelector"
        value={activeLang}
        options={defaultDisplayedLangs.map((lang) => ({ key: lang, text: lang.toUpperCase(), value: lang, displayed: lang === activeLang }))}
        onChange={(_, { value }) => setActiveLang(value)}
    />
);

export default LanguageSelector;
