import React, { FC } from "react";
import { Dropdown as SDropDown, DropdownProps } from "semantic-ui-react";

const Dropdown : FC<DropdownProps> = ({ className = "", icon = "caret down", options = defaultOptions, ...rest}) => (
    <SDropDown className={`dropdownGroup ${className}`}
        icon={icon}
        options={options}
        {...rest}
    />
);

export default Dropdown;

const defaultOptions = [
    { key: 1, text: "Choice 1", value: 1 },
    { key: 2, text: "Choice 2", value: 2 },
    { key: 3, text: "Choice 3", value: 3 },
];
