import React from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Header, Popup, } from "semantic-ui-react";
import { FILTERED_ID_TYPES, MAP_EDITOR_TOOLS, NODES_TOGGLABLE_LAYERS, SMART_FILTER_OPTIONS } from "../../../_constants/mapEditor";
import { ButtonIcon } from "../../common/ButtonIcon";
import { MapEditorSidebarItem, ShortCutsPopupItem } from "./MapEditorLeftbar";
import MapEditorSidebarAccordion from "./MapEditorSidebarAccordion";
import { GET_NODE_LINK_LEGEND } from "mapsted.maps/utils/defualtStyles";
// import { ValidationErrorInfoToolBar } from '../../branding/ValidationInfo/ValidationInfo';

const legendOptions = GET_NODE_LINK_LEGEND().map((legend) => ({
    ...legend,
    iconUrl: `/img/editor/icon-${legend.label.replace(/([a-z])([A-Z])/g, "$1 $2").split(" ").map((w) => w.toLocaleLowerCase()).join("-")}.svg`
}));
export const MapEditorSidebarNodes = ({ smartFilter, selectedTool, selectedFilterItem, onSelectFilter, onSelectFilterItem, onToolClick, onShowNotLockedByUserAlert }) => (
    <div className="mapEditorSidebar">
        <Header>Nodes</Header>
        <Scrollbars className="mapEditorScroll" autoHeight autoHeightMin="calc(100vh - 220px)" autoHeightMax="calc(100vh - 220px)" >
            <div className="alignerMapEditor">
                <div className="mapEditorSidebarGroup">
                    {/* TODO map this to avoid duplicate code */}
                    <MapEditorSidebarItem
                        icon="icon-editor-create-nodes"
                        name={MAP_EDITOR_TOOLS.Create}
                        content="Nodes"
                        active={selectedTool === MAP_EDITOR_TOOLS.Create}
                        onClick={onToolClick} />

                    <MapEditorSidebarItem
                        icon="icon-editor-leftmenu-1"
                        name={MAP_EDITOR_TOOLS.AutoLink}
                        content="Nodes (Autolink)"
                        active={selectedTool === MAP_EDITOR_TOOLS.AutoLink}
                        onClick={onToolClick} />

                    <MapEditorSidebarItem
                        icon="icon-editor-create-link"
                        name={MAP_EDITOR_TOOLS.Link}
                        content="Link"
                        active={selectedTool === MAP_EDITOR_TOOLS.Link}
                        onClick={onToolClick} />

                    <MapEditorSidebarItem
                        icon="icon-editor-create-delete"
                        name={MAP_EDITOR_TOOLS.Delete}
                        content="Delete"
                        active={selectedTool === MAP_EDITOR_TOOLS.Delete}
                        onClick={onToolClick} />

                    <MapEditorSidebarItem
                        icon="icon-editor-create-delete-reconnect"
                        name={MAP_EDITOR_TOOLS.Delete_Reconnect}
                        active={selectedTool === MAP_EDITOR_TOOLS.Delete_Reconnect}
                        onClick={onToolClick}
                        content="Delete & Reconnect" />

                    <MapEditorSidebarItem
                        icon="icon-edit"
                        name={MAP_EDITOR_TOOLS.Edit_Node}
                        active={selectedTool === MAP_EDITOR_TOOLS.Edit_Node}
                        onClick={onToolClick}
                        content="Edit" />

                    <MapEditorSidebarItem
                        icon="icon-editor-node-link"
                        name={MAP_EDITOR_TOOLS.Edit_Node_Link}
                        active={selectedTool === MAP_EDITOR_TOOLS.Edit_Node_Link}
                        onClick={onToolClick}
                        content="Edit Node Link" />

                    <MapEditorSidebarItem
                        icon="manage-entity-nodes"
                        name={MAP_EDITOR_TOOLS.Manage_Entity_Nodes}
                        active={selectedTool === MAP_EDITOR_TOOLS.Manage_Entity_Nodes}
                        onClick={onToolClick}
                        content="Manage Entity Nodes" />

                </div>

                <MapEditorSidebarAccordion
                    smartFilter={smartFilter}
                    smartFilterOptions={SMART_FILTER_OPTIONS[FILTERED_ID_TYPES.NODE]}
                    mapLayerOptions={NODES_TOGGLABLE_LAYERS}
                    selectedFilterItem={selectedFilterItem}
                    onSelectFilter={onSelectFilter}
                    onSelectFilterItem={onSelectFilterItem}
                    onShowNotLockedByUserAlert={onShowNotLockedByUserAlert}
                    legendTitle={"Node Link Priorities"}
                    legendOptions={legendOptions} />

            </div>
            {/* <div className="validationErrorGroup">
                    <ValidationErrorInfoToolBar isFilterByActiveBuilding />
                </div> */}
        </Scrollbars>
        {/* <MapEditorLegendPopup /> */}
        <ShortCutsPopup />
    </div>
);

const ShortCutsPopup = () => (
    <Popup className="shortcutsPopup"
        trigger={<ButtonIcon className="shortcutsPopupTrigger" icon="editor-control" content="Shortcuts" />}
        basic
        on="click"
    >
        <ShortCutsPopupItem title="Ctrl + Z" content="Undo" />
        <ShortCutsPopupItem title="Ctrl + Y" content="Redo" />
        <ShortCutsPopupItem title="ESC" content="Escape" />
        <ShortCutsPopupItem title="Shift + L" content="Link" />
        <ShortCutsPopupItem title="Shift + N" content="Node" />
        <ShortCutsPopupItem title="Shift + A" content="Auto Link" />
        <ShortCutsPopupItem title="Shift + D" content="Delete" />
        <ShortCutsPopupItem title="Shift + R" content="Delete & Reconnect" />
        {/* <ShortCutsPopupItem title="Alt + Mouse" content="Selection" /> */}
    </Popup>
);
