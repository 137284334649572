
import { Helmert } from "mapsted.maps/mapFunctions/cmshelmerttransformGeoRef";
import { setFloorPlanLayers, updateFloorPlanOnGeoRefImageLayer } from "mapsted.maps/mapFunctions/cmsVectorLayers";
import { getCommonGoeRefFloorPlanStyles, getStylesForGeoRefFloorPlanIncludingTextStyles } from "mapsted.maps/mapFunctions/plotting";
import { ShapeTypes } from "mapsted.maps/utils/entityTypes";
import { OlExtTransformInteraction } from "mapsted.maps/utils/interactionTemplates";
import { FLOOR_PLAN_GEO_REF_MOUSE_INTERACTIONS, FLOOR_PLAN_LAYERS_IDS } from "mapsted.maps/utils/map.constants";
import { getFeatureGeoRefIndex, isFloorPlanGeoReferenceLayer, markAsGeoRef, setIndex } from "mapsted.maps/utils/map.utils";
import { Draw, Modify } from "ol/interaction";
import { useCallback, useContext, useEffect } from "react";
import { DELETE_CONDITION, GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES, SCALE_AND_ROTATE_CONDITION, TRANSLATE_CONDITION } from "../../../../_constants/mapEditor";
import FloorPlanContext from "../FloorPlanContex";
import
{
    addFeaturesToVectorLayer,
    canGeoReferenceTheState,
    cloneImageSrc,
    getPolygonFeaturesFromImage,
    isDeleteActionTriggedForFloorPlanPoint
} from "../utils/floorPlan.utils";
import useFloorPlanCommon from "./useFloorPlanCommon";
const useGeoReferenceFloorPlanInjector = (props) =>
{
    const {
        geoReferencingFloorPlan,
        olMap, setOlMap,
        geoRefMapLayersHash,
        setActiveMapInteractions,


    } = useContext(FloorPlanContext);
    const {
        handleModifyFeatureOnState,
        handleRemoveFeatureFromState,
        handleAddFeatureToState,
        geoRefLayersVisibilityHash,
        onCenterChange,
        onScaleChange,
        onRotateChange,
        activeTab,
        setInjectingLayersDataFromState,
        isInjectingStateDataToLayersAllowed,
        refreshState,
        activeFloorPlanUrl
    } = useFloorPlanCommon();




    //add olmap ref to internal state i.e context state
    useEffect(() =>
    {
        setOlMap(props.olMap);
    }, [props.olMap]);



    // take care of drawing,adding interaction and toggles visibility of layers...
    useEffect(() =>
    {

        if (olMap)
        {
            drawFloorPlanGeoRefLayers(geoRefMapLayersHash);
            addInteractionToVL(olMap, activeTab);
            olMap?.getLayers().forEach((l) =>
            {
                if (isFloorPlanGeoReferenceLayer(l))
                {
                    // console.log(geoRefLayersVisibilityHash[l.get("id")]);
                    l.setVisible(geoRefLayersVisibilityHash[l.get("id")]);
                    // console.log(l, olMap.getLayers());

                }
            });
        }
    }, [geoRefMapLayersHash, activeTab, olMap, geoRefLayersVisibilityHash]);





    const drawFloorPlanGeoRefLayers = (layersHash) =>
    {
        setFloorPlanLayers(olMap, layersHash);
    };


    const handleFloorPlanGeoReferencingDrawEnd = useCallback((e) =>
    {
        const { feature, target } = e;
        markAsGeoRef(feature);
        const index = getFeatureGeoRefIndex(target.source_);
        setIndex(feature, index);
        feature.setStyle(getStylesForGeoRefFloorPlanIncludingTextStyles(feature));

        //strangely react state update is blocking draw completion event and feature's  were not getting updated  on source correctly
        //making this call as async to doesnt block main this event
        setTimeout(() => handleAddFeatureToState(feature));

        return feature;
    }, [handleAddFeatureToState]);

    const handleFloorPlanGeoRefPointModify = useCallback((e) =>
    {
        const { features, mapBrowserEvent } = e;
        let modifiedFeature = features?.getArray()[0];
        if (!modifiedFeature) return;
        if (DELETE_CONDITION(mapBrowserEvent))
        {
            handleRemoveFeatureFromState(modifiedFeature);
            return;
        }
        handleModifyFeatureOnState(modifiedFeature);
        return modifiedFeature;
    }, [handleModifyFeatureOnState, handleRemoveFeatureFromState]);



    const onTransFromInteractionStart = (e) =>
    {
        const { type } = e;
        if (["rotatestart", "translatestart", "scalestart"].includes(type))
        {
            //this will stop the layer from updating from state...
            setInjectingLayersDataFromState(false);
        }
    };


    const onTransFormInteractionEnd = (e) =>
    {
        const { type } = e;
        if (["rotateend", "translateend", "sclaeend"].includes(type))
        {

            setInjectingLayersDataFromState(true);
            //trigger a data snap refersh...
            refreshState({});
        }
    };




    const onTransFromIng = (e) =>
    {
        const { type, feature, target } = e;
        let currentImgSrc = feature.get("currentImageSrc");
        const clonedGeoSrc = cloneImageSrc(currentImgSrc);

        //remove the styles from the feature this will stop creating a flickering effect...;
        // feature?.set("oldStyle", feature.getStyle())
        // feature?.setStyle([]);
        if (type === "rotating")
        {
            clonedGeoSrc.setScale([1, 1]);
            clonedGeoSrc.setRotation(0);
            //creates a new Feature collection with polygon geometry based from the cloned image... bounding extent
            const prevImagePolyFeature = getPolygonFeaturesFromImage(clonedGeoSrc, true);
            //the polygon geometry from the cloned image doesn't match the modified polygon geometry...
            //so adjusting them using control point algorithm
            const h = new Helmert();
            h.setControlPoints(prevImagePolyFeature[0].getGeometry().getCoordinates()[0], feature.getGeometry().getCoordinates()[0]);
            currentImgSrc.setRotation(h.getRotation());
            const adjustedGeometry = getPolygonFeaturesFromImage(currentImgSrc, true)[0].getGeometry();
            feature.setGeometry(adjustedGeometry);
            onRotateChange(h.getRotation());

            // feature.setStyle(feature.get("oldStyle"));
            return;
        }
        if (type === "translating")
        {
            currentImgSrc.setCenter([...e.coordinate]);
            const adjustedGeometry = getPolygonFeaturesFromImage(currentImgSrc, true)[0].getGeometry();
            feature.setGeometry(adjustedGeometry);
            onCenterChange([...e.coordinate]);
            // feature.setStyle(feature.get("oldStyle"));
            return;
        }
        if (type === "scaling")
        {

            //to do need to move this block into separate interaction template

            //update the scale of the cloned image form modified polygon...
            clonedGeoSrc.setScale([1, 1]);
            clonedGeoSrc.setRotation(0);
            //creates a new Feature collection with polygon geometry based from the cloned image... bounding extent
            const prevImagePolyFeature = getPolygonFeaturesFromImage(clonedGeoSrc, true);
            //the polygon geometry from the cloned image doesn't match the modified polygon geometry...
            //so adjusting them using control point algorithm
            const h = new Helmert();
            h.setControlPoints(prevImagePolyFeature[0].getGeometry().getCoordinates()[0], feature.getGeometry().getCoordinates()[0]);
            //after processing the control points it give us new adjust scale based on modified geometry to make the image geometry match the modified polygon geometry
            const adjustedScale = h.getScale();
            //update adjusted scale on the actual image...
            currentImgSrc.setScale(adjustedScale);
            //update this geometry to current interaction geometry
            const adjustedGeometry = getPolygonFeaturesFromImage(currentImgSrc, true)[0].getGeometry();
            feature.setGeometry(adjustedGeometry);
            feature.set("prevScale", adjustedScale);

            // console.log(target.overlayLayer_.getSource());
            // console.log(target.get("overlayLayer_"));
            // target.get("overlayLayer_").getSource().forEach((f) =>
            // {
            //     f.getGeometry().scale(adjustedScale);
            // });
            //restore old styles
            // feature.setStyle(feature.get("oldStyle"));
            //update the state
            target.setSelection([feature]);
            onScaleChange(adjustedScale);

            return;

        }
    };


    //add active interaction based on active tab and remove all floor plan old interactions...
    const addInteractionToVL = useCallback((olMap, activeTab) =>
    {

        let vl = geoRefMapLayersHash[FLOOR_PLAN_LAYERS_IDS.FLOOR_PLAN_VECTOR_LAYER];
        let vs = vl.getSource();

        const interactions = [];

        if (activeTab === GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES.geoReference && vs)
        {
            const drawInteraction = new Draw({
                type: ShapeTypes.POINT,
                source: vs,
                style: getCommonGoeRefFloorPlanStyles,
            });
            drawInteraction.set("id", FLOOR_PLAN_GEO_REF_MOUSE_INTERACTIONS.FLOOR_PLAN_ADD_POINT_INTERACTION);
            const mi = new Modify({
                source: vs,
                deleteCondition: isDeleteActionTriggedForFloorPlanPoint
            });
            mi.set("id", FLOOR_PLAN_GEO_REF_MOUSE_INTERACTIONS.FLOOR_PLAN_GEO_REF_POINT_GROUP_MODIFY_INTERACTION);
            drawInteraction.on("drawend", handleFloorPlanGeoReferencingDrawEnd);
            mi.on("modifyend", handleFloorPlanGeoRefPointModify);
            interactions.push(drawInteraction, mi);
        }
        else if (activeTab === GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES.geoReferenceEdit)
        {


            const t = OlExtTransformInteraction({
                onStartInteraction: onTransFromInteractionStart,
                onEndInteraction: onTransFormInteractionEnd,
                onInteracting: onTransFromIng,
                keepRectangle: false,
                translateFeature: true,
                translate: true,
                scale: true,
                rotate: true,
                stretch: true,
                enableRotatedTransform: true,
                condition: (e) =>
                {
                    if (SCALE_AND_ROTATE_CONDITION(e) || TRANSLATE_CONDITION(e)) return true;
                    return false;
                },
                filter: (f, l) => l === geoRefMapLayersHash[FLOOR_PLAN_LAYERS_IDS.FLOOR_PLAN_VECTOR_LAYER],
            });
            t.set("id", FLOOR_PLAN_GEO_REF_MOUSE_INTERACTIONS.FLOOR_PLAN_TRANSFORM_INTERACTION);
            interactions.push(t);
        }

        setActiveMapInteractions((prev) =>
        {
            //remove all old  interactions and update with new
            if (Array.isArray(prev) && prev.length)
            {
                prev.forEach((i) => olMap.removeInteraction(i));
            }
            interactions.forEach((i) => olMap.addInteraction(i));
            return interactions;
        });

        setOlMap(olMap);
    }, [setActiveMapInteractions, onTransFromIng]);


    /**
     * ===============================
     * inject state data to the vector layer and image layer
     * ===============================
     */

    useEffect(() =>
    {

        if (olMap)
        {
            const { activeTab } = geoReferencingFloorPlan;
            let imageLayer = geoRefMapLayersHash[FLOOR_PLAN_LAYERS_IDS.FLOOR_PLAN_GEO_REF_IMAGE_LAYER];
            let vectorLayer = geoRefMapLayersHash[FLOOR_PLAN_LAYERS_IDS.FLOOR_PLAN_VECTOR_LAYER];
            if (activeTab === GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES.geoReference)
            {
                addFeaturesToVectorLayer(vectorLayer, geoReferencingFloorPlan.geoRefFeatures);
                if (canGeoReferenceTheState(geoReferencingFloorPlan))
                {
                    updateFloorPlanOnGeoRefImageLayer(
                        imageLayer,
                        activeFloorPlanUrl,
                        geoReferencingFloorPlan);
                }
                else
                {
                    updateFloorPlanOnGeoRefImageLayer(imageLayer, "", geoReferencingFloorPlan);
                }
                //we dont have any costly operations in geo reference tab... on the map so we can update the layers from state...
                setInjectingLayersDataFromState(true);
            }
            //we have some costly operations in georeference edit tab... on the map so we are  controlling layers update from state.
            else if (activeTab === GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES.geoReferenceEdit && isInjectingStateDataToLayersAllowed())
            {

                //add the image to correct position...
                imageLayer = updateFloorPlanOnGeoRefImageLayer(
                    imageLayer,
                    activeFloorPlanUrl,
                    geoReferencingFloorPlan);
                //creates a polygon from the image layer...
                const imagePolygonFeaturesCollection = getPolygonFeaturesFromImage(imageLayer);
                imagePolygonFeaturesCollection[0].set("prevScale", geoReferencingFloorPlan.scale);
                imagePolygonFeaturesCollection[0].set("currentImageSrc", imageLayer.getSource());
                //remove the styles from the feature this will stop blue box ... to-do need to add styles...
                //adds the features to the vector layer...
                addFeaturesToVectorLayer(vectorLayer, imagePolygonFeaturesCollection);

                setActiveMapInteractions((ins) =>
                {
                    if (Array.isArray(ins) && ins.length)
                    {
                        let t = ins[0];
                        t.isTouch = false;
                        t.setSelection(imagePolygonFeaturesCollection);
                    }
                    return ins;
                });

            }
            else
            {
                updateFloorPlanOnGeoRefImageLayer(
                    imageLayer,
                    activeFloorPlanUrl,
                    geoReferencingFloorPlan);
                //we dont have any costly operations in geo reference tab... on the map so we can update the layers from state...
                setInjectingLayersDataFromState(true);

            }

        }
    }, [geoReferencingFloorPlan, olMap, activeFloorPlanUrl]);
    return {

    };
};


export default useGeoReferenceFloorPlanInjector;
