import React from "react";
import { ButtonIcon } from "../../common/ButtonIcon";
import { useTranslation } from "react-i18next";
import { Button, Dropdown, Input } from "semantic-ui-react";
import "../../pages/templates/templates.css";
import PropertyTemplateCopyModal from "./PropertyTemplateCopyModal";
import { useOverlaysTemplateContext } from "../../../store/OverlaysTemplateContext";

export default function TemplatesHeader({ options }) 
{
    const trans = useTranslation().t;
    const { selectedFilter, changeFilter } = useOverlaysTemplateContext();
    return (
        <div className="templateHeaderContainer">
            <div className="templatesLeftHeader">
                <ButtonIcon
                    className="headIcon"
                    icon="templates"
                />
                <div className="headerContainer">
                    <div className="heading">
                        {trans("Templates.Templates")}
                    </div>

                    {trans("Templates.Add_Or_Edit_Templates")}

                </div>
                <Input
                    className="dashboardSearch"
                    value={selectedFilter.searchFilter}
                    icon={<img src="/img/icon-search.svg" alt="" />}
                    placeholder={trans("Templates.Search_Templates")}
                    onChange={(e, { value }) => 
                    {
                        changeFilter({ ...selectedFilter, searchFilter: value });  
                    }}
                />
            </div>
            <TemplatesHeaderRight options={options} />
        </div>
    );
}

const TemplatesHeaderRight =  ({ options }) => 
{
    const trans = useTranslation().t;
    const { initiateOverlayTemplate, selectedFilter, changeFilter, overlayTemplates, filteredOverlayTemplates } = useOverlaysTemplateContext();
    const handleChange = (e, { value }) => 
    {
        changeFilter({ ...selectedFilter, sortFilter: value });  
    };
    return (
        <div className="templatesRightHeader">
            {
                filteredOverlayTemplates.length > 1 && <div>
                    <span>{trans("Templates.Sort_By")}</span>
                    <Dropdown placeholder={trans("Templates.Sort_By_Newest")} fluid selection options={options} onChange={handleChange} value={selectedFilter.sortFilter} />
                </div>
            }
            {overlayTemplates.length ? <PropertyTemplateCopyModal/> : <></>}
            <Button onClick={initiateOverlayTemplate} className={"createNewTemplate"}>
                {trans("Templates.Create_New_Template")}
            </Button>
        </div>
    );
};