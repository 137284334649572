const { Cluster: ClusterSource } = require("ol/source");
const { Feature, Collection } = require("ol");
const { Style, Icon, Stroke, Fill, Text } = require("ol/style");
const { getCenter, getArea, getBottomRight } = require("ol/extent");
const { toMercatorFromArray } = require("../utils/map.utils");
const { BoundaryEntityType, ShapeTypes } = require("../utils/entityTypes");

const {
    MapConstants,
    DEFAULT_ICON_STYLE,
    DEFAULT_HEX_RADIUS,
    DEFAULT_CATEGORY_ICON_SIZE,
    DEFAULT_HIGHLIGHT_STYLE_POINT,
} = require("../utils/map.constants");
const { EntityType } = require("../utils/entityTypes");
const {
    createTextStyle,
    createEntityStyle,
    createEntityGeometry,
    createEntityLayers,
    addFeatureToLayer,
    createEntityCategoryLayers,
    createVectorLayer,
    removeFeatureFromLayer,
    createHeatmapLayer,
    getClusterStyle,
    createPointStyle,
} = require("./plotting");
const { deepValue } = require("mapsted.utils/objects");
const { createImageLayer } = require("./cmsVectorLayers");
const { LineString, Point } = require("ol/geom");
const ol_featureAnimation_Path = require("ol-ext/featureanimation/Path");
const { PublicEntityAccess } = require("./entityAccess");
const { convertFeatureGeometryToGeoJSON, getLineStringLength } = require("./features");
const PolygonController = require("./polygons");

const polygonController = new PolygonController({ accessor: PublicEntityAccess });


require("gifler");

const { Vector: VectorSource } = require("ol/source");
const { Vector: VectorLayer } = require("ol/layer");
const { MAP_TEXT_STYLE, STYLE_OPTIONS } = require("../utils/defualtStyles");
const { getTemplateStyle } = require("./mapOverlay");

/**
 * Creates entityLayers to be drawn on the map given mapData.
 * Uses mapData from public db
 * @param {Object} mapData
 * @param {Object} mapData.entities
 * @param {Object} mapData.style
 * @param {Object} mapData.categoryMap
 * @param {string} filerUrl
 * @param {Object} [options]
 * @param {Boolean} [options.category]
 * @param {Boolean} [options.subCategories]
 * @param {string} [options.theme]
 */
exports.createEntityVectorLayers = (mapData, filerUrl, options = {}) =>
{
    if (!mapData)
    {
        return {};
    }

    const { entities, style, categoryMap } = mapData;

    const iconStyle = deepValue(style, "default.icon");

    let entityLayers = createEntityLayers(style.layerStyles);
    let imageLayers = {};
    let categoryLayers = {};
    let entityFeatureMap = {};

    if (options.category)
    {
        categoryLayers = createEntityCategoryLayers(categoryMap);
    }

    let boundaryPolygon = undefined;

    Object.values(entities).forEach((entity) =>
    {
        const entityAccess = new PublicEntityAccess(entity);

        /* 
         if entity is non-nameable prefer vacant style first,
         if vacant style option is not available in the current style object fallback to default style, this is handled in getStyleObject method
        */
        const styleOption = !entityAccess.getCanNameEntity() ? STYLE_OPTIONS.VACANT : STYLE_OPTIONS.DEFAULT;

        // Create Style
        const styleObject = entityAccess.getStyleObject(style, styleOption);
        const textStyleObject = entityAccess.getTextStyleObject(style);

        const entityStyle = createEntityStyle(styleObject);
        const entityTextStyle = createEntityTextStyle(entityAccess, textStyleObject);

        // Create Geometry
        const entityGeometry = createEntityGeometry(entityAccess.getShape());

        const entityArray = getArea(entityGeometry.getExtent());

        const entityTextLocation = entityAccess.getTextLocation();

        // Create Text Geometry
        if (entityTextLocation && Object.keys(entityTextLocation).length === 0)
        {
            entityTextLocation = undefined;
        }
        let textLocation = entityTextLocation && toMercatorFromArray(entityTextLocation);

        // If text coordinate is not given, set to entity center
        if (!textLocation)
        {
            textLocation = getCenter(entityGeometry.getExtent());
        }

        // Create text geometry
        const entityTextGeometry = createEntityGeometry({ type: "TextPoint", coordinates: textLocation });

        // attach it to style
        entityTextStyle.setGeometry(entityTextGeometry);

        // Find boundary polygon
        if (
            entityAccess.getEntityType() === EntityType.BOUNDARY &&
            (entityAccess.getSubEntityType() === BoundaryEntityType.PROPERTY_BOUNDARY ||
                entityAccess.getSubEntityType() === BoundaryEntityType.FLOOR_BOUNDARY)
        )
        {
            boundaryPolygon = entityGeometry;
        }

        //Create feature
        let feature = new Feature({
            geometry: entityGeometry,
            id: entityAccess.getId(),
            canName: entityAccess.getCanNameEntity(),
            area: entityArray,
            entityType: entityAccess.getEntityType(),
            subEntityType: entityAccess.getSubEntityType(),
        });

        let textFeature = new Feature({
            geometry: entityTextGeometry,
            type: "TextPoint",
            coordinates: textLocation,
            id: entityAccess.getId() + "text",
            entityId: entityAccess.getId(),
            canName: entityAccess.getCanNameEntity(),
            isText: true,
            connectedFeature: feature,
        });

        // create image layer;
        const isImageOnMap = entityAccess.getIsImageOnMap();
        let imgLayer = undefined;

        if (isImageOnMap)
        {
            // check if entity has image extent
            // convert to mercator
            const iconStyle = entity.iconStyle;

            // Safety null check
            if (iconStyle)
            {
                imgLayer = createImageLayer({
                    imgId: entityAccess.getImage({ theme: options.theme }),
                    entityShape: entityAccess.getShape(),
                    rotation: entityAccess.getImageRotation(),
                    extent: entityAccess.getImageExtent(),
                    filerUrl,
                });

                // Safety null check
                if (imgLayer)
                {
                    if (entity.overrideAllowIconRotation)
                    {
                        imgLayer.set("overrideAllowIconRotation", entity.overrideAllowIconRotation);
                    }

                    imageLayers[entityAccess.getId()] = imgLayer;
                }
            }
        }

        //Add Style
        entityStyle && feature.setStyle(entityStyle);
        entityTextStyle && textFeature.setStyle(entityTextStyle);

        // Add feature to the correct layer
        if (styleObject && styleObject.layerIdx !== undefined)
        {
            addFeatureToLayer(entityLayers[styleObject.layerIdx], feature);
        } else
        {
            addFeatureToLayer(entityLayers[-1], feature);
        }

        if (textStyleObject)
        {
            // if (entityLayers[MapConstants.TEXT_LAYER].get("declutter") === false)
            {
                // entityLayers[MapConstants.TEXT_LAYER].set("declutter", true);
            }
            addFeatureToLayer(entityLayers[MapConstants.TEXT_LAYER], textFeature);
        }

        // --- OPTIONAL CATEGORY IMAGE --- //

        // create category image geometry if option is selected
        if (!!options.category && entityAccess.getCategoryId())
        {
            // entity has a category and option is picked, create a point geometry and add icon style to it
            const categoryId = entityAccess.getCategoryId();

            const category = categoryMap[categoryId];

            let categoryIconFeature = createCategoryFeature({
                entity,
                category,
                entityGeometry,
                filerUrl,
                iconStyle,
                connectedFeature: feature,
            });

            if (categoryIconFeature)
            {
                // add feature to category layer
                addFeatureToLayer(categoryLayers[categoryId], categoryIconFeature);

                // attach category feature to entity
                entity.categoryFeature = categoryIconFeature;
            }
        }

        if (!!options.subCategories && !!Array.isArray(entity.subCategories))
        {
            entity.subCategoryFeatures = [];

            entity.subCategories.forEach((subCategory) =>
            {
                // create a point geometry and add icon style to it
                const categoryId = subCategory._id;
                const category = categoryMap[categoryId];

                let categoryIconFeature = createCategoryFeature({
                    entity,
                    category,
                    entityGeometry,
                    filerUrl,
                    iconStyle,
                    connectedFeature: feature,
                });

                if (categoryIconFeature)
                {
                    // add feature to category layer
                    addFeatureToLayer(categoryLayers[categoryId], categoryIconFeature);

                    // attach category feature to entity
                    entity.subCategoryFeatures.push(categoryIconFeature);
                }
            });
        }

        // --- OPTIONAL ENTITY FEATURE MAP  --- //
        if (options.entityFeatureMap)
        {
            entityFeatureMap[entityAccess.getId()] = feature;
        }

        // attach feature and text feature to entity
        entity.feature = feature;
        entity.textFeature = textFeature;
    });

    //  ---------- set cluster source ----------
    // setClusterSource(entityLayers[MapConstants.TEXT_LAYER]);

    let returnObject = {
        entityLayers,
        boundaryPolygon,
        imageLayers,
    };

    if (options.category)
    {
        returnObject.categoryLayers = categoryLayers;
    }

    if (options.entityFeatureMap)
    {
        returnObject.entityFeatureMap = entityFeatureMap;
    }
    return returnObject;
};

/**
 * Clear any existing layers and add layers to the map.
 *
 * @param {Map} olMap
 * @param {Array} layers
 */
exports.setMapLayers = (olMap, layers) =>
{
    if (olMap && Array.isArray(layers))
    {
        let layerCollection = new Collection();
        layerCollection.extend(layers);
        olMap.getLayerGroup().setLayers(layerCollection);
    }
};

/**
 *
 * @param {Array} routeNodes - list of route nodes that contain x, y mercator variables
 */
exports.createRouteSegmentLayer = (routeNodes) =>
{
    const routeSegmentGeometry = exports.getRouteSegmentGeometry(routeNodes);

    return exports.createRouteSegmentLayerWithRouteSegmentGeometry(routeSegmentGeometry);
};

exports.getAnimationSpeed = (routeGeometry) =>
{
    const lineString = convertFeatureGeometryToGeoJSON(routeGeometry);
    const length = getLineStringLength(lineString);

    let speed = (length / 300000) * 0.7; // 30% reduced speed
    if (speed > 3)
    {
        speed = 3;
    }

    return speed;
};

/**
 *
 * @param {Array} routeNodes - list of route nodes that contain x, y mercator variables
 */
exports.createRouteSegmentLayerWithRouteSegmentGeometry = (routeSegmentGeometry) =>
{
    // if route segment is two points check if they are the same point
    // dwell segments seem to have two points instead of one
    // then check if there is only one point, is so plot

    let routeSegmentLayer = createVectorLayer("routeSegment");

    /* create feature */
    let feature = new Feature({
        geometry: routeSegmentGeometry,
        style: undefined,
        id: "lineSegment",
    });

    feature.setStyle(new Style({ stroke: new Stroke({ color: "#4aadf0", width: 3 }) }));

    /* add the feature to the layer */
    addFeatureToLayer(routeSegmentLayer, feature);

    return { routeSegmentLayer, routeSegmentGeometry, routeSegmentFeature: feature };
};

/**
 * Animates gifs with frames on a path
 * @param {*} pathFeature
 * @param {*} vectorLayer
 * @param {*} map
 * @param {*} gifSource
 * @param {*} options
 */
exports.animateGifFeatureOnPath = ({ pathFeature, vectorLayer, map, gifSource, options = {} }) =>
{
    // create feature to be animated
    let animatedFeature = new Feature({
        geometry: new Point([0, 0]),
        style: undefined,
    });

    const gif = gifler(gifSource);

    animatedFeature.setStyle(new Style());

    gif.frames(
        document.createElement("canvas"),
        (ctx, frame) =>
        {
            if (!animatedFeature.getStyle().icon)
            {
                let style = new Style({
                    image: new Icon({
                        img: ctx.canvas,
                        crossOrigin: 'Anonymous',
                        imgSize: [frame.width, frame.height],
                    }),
                });

                animatedFeature.setStyle(style);
            }

            ctx.clearRect(0, 0, frame.width, frame.height);
            ctx.drawImage(frame.buffer, frame.x, frame.y);
            map.render();
        },
        true
    );

    // create animation function
    let animation = new ol_featureAnimation_Path.default({
        path: pathFeature,
        rotate: options.rotation || false,
        speed: options.speed || 0.05,
        easing: options.easing || "linear", //linear, easeOut, easeIn, inAndOut
        revers: options.reverse || false,
    });

    addFeatureToLayer(vectorLayer, animatedFeature);

    animation.on("animationend", (e) =>
    {
        removeFeatureFromLayer(vectorLayer, e.feature);
        options.continous && this.animateFeatureOnPath({ pathFeature, vectorLayer, map, gifSource, options });
    });

    vectorLayer.animateFeature(animatedFeature, animation);

    return animation;
};

/**
 * Animates static src images (png, jpg, ...etc) along a path
 * @param {*} param0
 * @returns
 */
exports.animateFeatureOnPath = ({ pathFeature, vectorLayer, map, src, options = {} }) =>
{
    // create feature to be animated
    let animatedFeature = new Feature({
        geometry: new Point([0, 0]),
        style: undefined,
    });

    animatedFeature.unset("style");
    animatedFeature.setStyle(
        new Style({
            image: new Icon({
                src: src,
                crossOrigin: 'Anonymous',
                size: options.size,
                anchor: options.anchor
            }),
        })
    );

    animatedFeature.getStyle().getImage().load();

    // create animation function
    let animation = new ol_featureAnimation_Path.default({
        path: pathFeature,
        rotate: options.rotation || false,
        speed: options.speed || 0.05,
        easing: options.easing || "linear", //linear, easeOut, easeIn, inAndOut
        revers: options.reverse || false,
    });

    addFeatureToLayer(vectorLayer, animatedFeature);

    animation.on("animationend", (e) =>
    {
        removeFeatureFromLayer(vectorLayer, e.feature);
        options.continous && this.animateFeatureOnPath({ pathFeature, vectorLayer, map, src, options });
    });

    vectorLayer.animateFeature(animatedFeature, animation);

    return animation;
};

exports.getRouteSegmentGeometry = (routeNodes) =>
{
    /* create line string geometry */
    let coordinates = [];

    routeNodes.forEach((node) =>
    {
        if (Array.isArray(node))
        {
            coordinates.push(node);
        } else
        {
            coordinates.push([node.x, node.y]);
        }
    });

    return new LineString(coordinates);
};

/**
 *
 * @param {Array} zoneGeofenceArray
 * @param {Objects} options
 * @param {Objects} options.showVisitCount
 */
exports.createZoneGeofenceLayers = (zoneGeofenceArray, options) =>
{
    let zoneGeofenceLayer = createVectorLayer("ZoneGeofenceLayer");
    let zoneGeofenceTextLayer = createVectorLayer("ZoneGeofenceTextLayer", getClusterStyle);
    // let selectedTextFeatures = [];
    let zoneWithFeatureInfoMap = {};
    let customMapOptionsForZoneGeofence = {};
    let customLabel = options.hasOwnProperty("customLabel") && typeof options.customLabel === 'object' ? options.customLabel : {};

    if (options.hasOwnProperty("customMapOptionsForZoneGeofence"))
    {
        customMapOptionsForZoneGeofence = options.customMapOptionsForZoneGeofence;
    }

    if (!Array.isArray(zoneGeofenceArray))
    {
        return null;
    }

    // for each zone geofence, create polygon and add to layer
    zoneGeofenceArray.forEach((zoneGeofence) =>
    {
        let { boundary, zoneGeofenceId, label, visitCount, template, style } = zoneGeofence;
        let isSelected =
            !!options.selectedZoneGeofenceIds && options.selectedZoneGeofenceIds.includes(`${zoneGeofenceId}`);
        /**
            =======Todo=========
                1.we can add more options hiding the label
                2. showVisitCount
        */

        if (isSelected)
        {
            label = `${String.fromCharCode(parseInt(0x2705))}  ${label}`;
        }

        if (options.showVisitCount)
        {
            label += `  |  ${visitCount}`;
        }

        let geometry = createEntityGeometry(boundary);

        //  --- CREATE ZONE GEOFENCE POLYGON --- //

        let feature = new Feature({
            geometry: geometry,
            id: zoneGeofenceId,
            label: label,
            zoneGeofence: zoneGeofence,
        });

        if (!!customMapOptionsForZoneGeofence[zoneGeofenceId] && customMapOptionsForZoneGeofence[zoneGeofenceId].hasOwnProperty("style"))
        {
            const customStyles = customMapOptionsForZoneGeofence[zoneGeofenceId].style;
            style = Object.assign(style, customStyles);
        }

        // if zoneGeofence is selected update style
        if (isSelected)
        {
            // const highlightStyle = createEntityStyle(DEFAULT_HIGHLIGHT_STYLE);
            // feature.setStyle(highlightStyle);

            style.font = "bold 12px sans-serif";
            style.stroke.width = "5";
            style.stroke.lineDash = [8];
        }

        const zoneGeofenceStyle = createEntityStyle(style);
        //if template is provided set template style when current zoneGeofence is not selected and also apply zone template styles only when are not in custom style options #http://192.168.100.222/projects/analytics/work_packages/477/activity
        if (!isSelected && !!template && !(customMapOptionsForZoneGeofence[zoneGeofenceId] && customMapOptionsForZoneGeofence[zoneGeofenceId].hasOwnProperty("style")))
        {
            feature.setStyle(getTemplateStyle(template));
        } else
        {
            feature.setStyle(zoneGeofenceStyle);
        }
        addFeatureToLayer(zoneGeofenceLayer, feature);

        // ---  CREATE ZONE GEOFENCE TEXT --- //

        // If text coordinate is not given, set to entity center
        if (!zoneGeofence.textLocation)
        {
            // textLocation = getCenter(geometry.getExtent());
            textLocation = polygonController.getOLPolygonCenter(geometry);
        }

        // Create text geometry
        const zoneGeofenceTextGeometry = createEntityGeometry({ type: "TextPoint", coordinates: textLocation });

        delete style.stroke.lineDash;

        const zoneGeofenceTextStyle = createZoneGeofenceTextStyle(label, style);

        if (!!customLabel[zoneGeofenceId])
        {
            const customLabelValue = customLabel[zoneGeofenceId];

            const textObj = zoneGeofenceTextStyle.getText();

            textObj.setTextAlign("center");

            textObj.setText(customLabelValue);

        }
        zoneGeofenceTextStyle.setGeometry(zoneGeofenceTextGeometry);

        let textFeature = new Feature({
            geometry: zoneGeofenceTextGeometry,
            type: "TextPoint",
            coordinates: textLocation,
            // style: entityTextStyle,
            id: zoneGeofenceId + "text",
            isText: true,
            connectedFeature: feature,
        });

        textFeature.setStyle(zoneGeofenceTextStyle);
        addFeatureToLayer(zoneGeofenceTextLayer, textFeature);

        zoneWithFeatureInfoMap[zoneGeofenceId] = { zoneGeofenceId, feature };
    });

    setClusterSource(zoneGeofenceTextLayer);

    return { vectorLayers: [zoneGeofenceLayer, zoneGeofenceTextLayer], zoneWithFeatureInfoMap };
};

/**
 * @param {*} heatmapData
 * @param {*} options
 * @returns
 */
exports.createHeatmapPointsLayer = (heatmapData, options) =>
{
    // create heatmap layer
    let heatmapLayer = createHeatmapLayer("HeatmapLayer", options);

    if (Array.isArray(heatmapData))
    {
        heatmapData.forEach((point) =>
        {
            let pointGeometry = createEntityGeometry({ type: ShapeTypes.POINT, coordinates: point.position });

            let feature = new Feature({
                geometry: pointGeometry,
                weight: point.count,
            });

            addFeatureToLayer(heatmapLayer, feature);
        });
    }

    return heatmapLayer;
};

/**
 * @param {*} heatmapData
 * @param {*} heatmapLayer
 * @returns
 */
exports.createAndSetHeatmapPointsSource = (heatmapData, heatmapLayer) =>
{
    // create heatmap layer
    if (Array.isArray(heatmapData))
    {
        heatmapData.forEach((point) =>
        {
            let pointGeometry = createEntityGeometry({ type: ShapeTypes.POINT, coordinates: point.position });

            let feature = new Feature({
                geometry: pointGeometry,
                weight: point.count,
                ...point,
            });

            addFeatureToLayer(heatmapLayer, feature);
        });
    }
};

/**
 * @param {*} textLayerData
 * @param {*} textLayerData.textFeature - the text feature to update
 * @param {*} textLayerData.label - the updated name
 * @param {*} textLayer
 * @returns
 */
exports.updateTextLayerNames = (textLayerData) =>
{
    // create heatmap layer
    if (Array.isArray(textLayerData))
    {
        textLayerData.forEach((textData) =>
        {
            const { textFeature, label } = textData;

            let style = textFeature.getStyle();
            let textStyle = style.getText();
            textStyle.setText(label);

            style.setText(textStyle);
            textFeature.setStyle(style);

            // textFeature.setStyle(style);
        });
    }
};

/**
 * Dynamically sets heatmap layer radius and blur based on pixels -> meters conversion
 * call on map "postRender" event
 * @param {*} param0
 * @param {Number} radius - radius in meters
 * @param {*} heatmapLayer
 * @param {*} olMap
 * @param {Array} centroidMercator - centroid of property/building/floor in mercator array
 */
exports.setHeatmapRadiusAndBlurOnMapRender = ({
    radius = DEFAULT_HEX_RADIUS,
    heatmapLayer,
    olMap,
    centroidMercator = [0, 0],
}) =>
{
    if (!heatmapLayer || !olMap)
    {
        return;
    }

    radius = radius / 1.4;

    // get pixel to radius in meter conversion
    const view = olMap.getView();
    const resolution = view.getResolution();

    const pointResolution = view.getProjection().getPointResolutionFunc_(resolution, centroidMercator); // use property center as point

    // convert radius meters to pixels
    let radius_pixels = radius / pointResolution;

    heatmapLayer.setBlur(radius_pixels);
    heatmapLayer.setRadius(radius_pixels);
};

exports.createIconStyle = (iconImage, iconStyle) =>
{
    let style = new Style({
        image: new Icon({
            ...iconStyle,
            crossOrigin: 'Anonymous',
            src: iconImage,
        }),
    });

    return style;
};

/**
 * Creates an entity's text style given entity information.
 *
 * @param {PublicEntityAccess} entityAccess
 * @param {*} textStyle
 */
const createEntityTextStyle = (entityAccess, textStyle) =>
{
    const name = entityAccess.getName({ useStyle: true });

    let style = createEntityStyle(MAP_TEXT_STYLE);

    if (name)
    {
        const text = createTextStyle(name, entityAccess.getTextRotation() || 0, textStyle);
        style.setText(text);
    }

    return style;
};

/**
 * Creats a style objects based on the params given.
 *
 * @param {Object} style
 * @param {Object} style.stroke
 * @param {Object} style.fill
 */
const createZoneGeofenceTextStyle = (name, style = {}) =>
{
    const { stroke, font } = style;

    let textStyle = exports.createStyle(MAP_TEXT_STYLE);

    let text = new Text({
        text: name,
        fill: new Stroke({ color: "white" }),
        backgroundFill: new Fill(stroke),
        backgroundStroke: new Stroke(Object.assign(stroke, { lineCap: "round", lineJoin: "round", width: 6 })),
        padding: [2, 2, 2, 2],
        scale: 1.1,
        font: font,
    });

    textStyle.setText(text);

    return textStyle;
};

const setClusterSource = (layer) =>
{
    const oldSource = layer.getSource();
    const clusterSource = new ClusterSource({
        source: oldSource,
        distance: MapConstants.CLUSTER_DISTANCE,
    });

    layer.setSource(clusterSource);
};

const createCategoryFeature = ({ entity, category, entityGeometry, filerUrl, iconStyle, connectedFeature }) =>
{
    let categoryImageFeature = undefined;

    if (category)
    {
        // get center point of entity geometry (or we can use text location??)
        const centerPoint = getCenter(entityGeometry.getExtent());

        // Create category geometry
        const entityCategoryGeometry = createEntityGeometry({ type: ShapeTypes.TEXT_POINT, coordinates: centerPoint });

        // Create category icon style
        const categoryIconStyle = exports.createIconStyle(
            filerUrl + category.iconImage,
            iconStyle || DEFAULT_ICON_STYLE
        );

        //Create category image feature
        categoryImageFeature = new Feature({
            geometry: entityCategoryGeometry,
            // style: entityStyle,
            id: entity.entityId + "category" + category,
            isText: true,
            connectedFeature: connectedFeature,
        });

        categoryImageFeature.setStyle(function (feature)
        {
            const size = categoryIconStyle.getImage().getSize();
            if (size)
            {
                const [x, y] = size;
                const [maxX, maxY] = DEFAULT_CATEGORY_ICON_SIZE;
                const scaleX = maxX / x, scaleY = maxY / y;
                categoryIconStyle.getImage().setScale([scaleX, scaleY]);
            }
            return [categoryIconStyle];
        });
    }

    return categoryImageFeature;
};

/**
 * Create vector layer for the given geometry object
 *
 * @param {Object} geometry
 * @param {Object} styleOptions
 * @returns vectorLayer
 */
exports.createVectorLayerFromGeometry = (geometry, styleOptions, textFeature, featureId) =>
{
    const feature = new Feature({
        geometry,
    });

    if (featureId)
    {
        feature.setId(featureId);
    }

    let features = [feature];

    if (textFeature)
    {
        features.push(textFeature);
    }

    const vectorSource = new VectorSource({
        features,
    });
    const vectorLayer = new VectorLayer({
        source: vectorSource,
    });

    const style = styleOptions ? exports.createStyle(styleOptions) : undefined;

    if (style)
    {
        vectorLayer.setStyle(style);
    }

    return vectorLayer;
};

exports.createStyle = (styleOptions) =>
{
    const { fill, stroke } = styleOptions;

    if (!!stroke && !!fill)
    {
        let style = new Style({
            stroke: new Stroke(stroke),
            fill: new Fill(fill),
        });

        return style;
    } else
    {
        return undefined;
    }
};

/**
 * @param {Array} boostDeviceInfoData
 * @param {object} activePointsIds
 * @param {string} iconPath
 * @returns {VectorLayer}
 */
exports.createBoostPointLayer = (boostDeviceInfoData, activePointsIds, iconPath) =>
{
    // styles to differentiate between active & nonactive points
    const styles = {
        'not-active': new Style({
            image: new Icon({
                crossOrigin: 'Anonymous',
                scale: [.7, .7],
                src: iconPath,
            }),
        }),
        'active': new Style({
            image: new Icon({
                crossOrigin: 'Anonymous',
                scale: [1.5, 1.5],
                src: iconPath,
            }),
        })
    };


    let features = new Array(boostDeviceInfoData.length);

    boostDeviceInfoData.forEach((device, index) =>
    {
        const { uid, longitude, latitude } = device;

        features[index] = new Feature({

            geometry: createEntityGeometry({
                type: ShapeTypes.POINT,
                coordinates: [longitude, latitude]
            }
            ),

            i: index,
            meta: device,
            uid: uid,
            styleKey: activePointsIds[uid] ? "active" : "not-active"

        });

    });
    const vectorSource = new VectorSource({
        features: features,
        wrapX: false,
    });
    const vectorLayer = new VectorLayer({
        source: vectorSource,
        style: function (feature)
        {
            return styles[feature.get("styleKey")];
        },
    });


    return vectorLayer;

};


/**
 *
 * @param {Array} pointsData
 * @param {Object} @deprecated activePointsIds
 * @param {Object} meta - options
 * @returns
 */
exports.createPointLayerWithCustomIcons = (pointsData, activePointsIds = {}, meta = {}) =>
{
    const defaultMeta = {
        showLabels: {},
        idKey: "id",
        fileUrlKey: "fileUrl",
        latitudeKey: "latitude",
        longitudeKey: "longitude",
        labelKey: "label",
        activeIconKey: "activeIcon",
        ...meta
    };

    let features = new Array(pointsData.length);

    const { showLabels, idKey, fileUrlKey, latitudeKey, longitudeKey, labelKey, activeIconKey } = defaultMeta;

    // loop through points to create feature for each point
    pointsData.forEach((point, index) =>
    {
        const longitude = point[longitudeKey];
        const latitude = point[latitudeKey];
        const activeIconPath = point[activeIconKey];
        const iconPath = point[fileUrlKey];

        const activeStyle = new Style({
            zIndex: 100,
            image: new Icon({
                scale: [1.7, 1.7],
                crossOrigin: 'Anonymous',
                src: activeIconPath

            }),
        });
        const nonactiveStyle = new Style({
            zIndex: 1,
            image: new Icon({
                crossOrigin: 'Anonymous',
                scale: [.8, .8],
                src: iconPath,
            }),
        });

        const label = point[labelKey];

        const id = point[idKey];

        if (showLabels[id])
        {
            activeStyle.setText(createTextStyle(label));
            nonactiveStyle.setText(createTextStyle(label));
        }

        const pointFeature = new Feature(
            {
                geometry: createEntityGeometry({
                    type: ShapeTypes.POINT,
                    coordinates: [longitude, latitude]
                }),
                i: index,
                meta: point,
                pointId: id,
                activeIconStyle: activeStyle,
                nonactiveIconStyle: nonactiveStyle

                // styleKey: activePointsIds[uid] ? "active" : "not-active",
            });

        pointFeature.setStyle(activePointsIds[id] ? activeStyle : nonactiveStyle);

        features[index] = pointFeature;

    });



    // TODO, we can include styles in overall layer styles, and set point style based on active value in feature
    const pointsLayer = createVectorLayer("pointsLayer", null);
    pointsLayer.getSource().addFeatures(features);

    return pointsLayer;
};
