import React from "react";
import "./BulkImageUploadDetails.css";
import { AspectRatioSection, CropImageSection, ImageNameEditSection } from "../../ImageDetailSections/ImageDetailsSections";
import ContentSection from "../../ContentSection/ContentSection";
import InfoSection from "../../../InfoSection/InfoSection";
import { bytesToMB } from "../../../../utils";
import { useImagesUploadContext } from "../../../contexts/ImagesUploadContext";
import { GmsActionMode, useGmsContext } from "../../../../GmsContext";

function BulkImageUploadDetails() 
{
    const { gmsActionMode } = useGmsContext();

    const {
        handleChangeFileInfoValues,
        cropperRef,
        handleUndoCropImage,
        handleRedoCropImage,
        handleCropImage,
        croppedImagesHistory,
        selectedImageInfoFromBulkUpload: fileInfo,
    } = useImagesUploadContext();
    const croppedImageHistory = croppedImagesHistory?.[fileInfo.id];

    function handleTriggerCropImage() 
    {
        const croppedDataUrl = cropperRef?.current?.cropper
            ?.getCroppedCanvas()
            ?.toDataURL();
        handleCropImage({ id: fileInfo.id, croppedImage: croppedDataUrl, currentImageSrc: fileInfo.imageSrc });
    }

    return <div className="bulk-image-upload-details">
        <CropImageSection
            onUndo={croppedImageHistory?.prevImages?.length ? () => handleUndoCropImage(fileInfo.id) : null}
            onRedo={croppedImageHistory?.futureImages?.length ? () => handleRedoCropImage(fileInfo.id) : null}
            onCrop={handleTriggerCropImage}
        />
        <ImageNameEditSection
            value={fileInfo.name}
            onChange={(e) => handleChangeFileInfoValues({
                id: fileInfo.id,
                value: { name: e.target.value },
            })
            }
        />
        <AspectRatioSection
            disabled={gmsActionMode === GmsActionMode.LINK_COVER_IMAGES 
              || gmsActionMode === GmsActionMode.LINK_ICON_IMAGE}
            value={fileInfo.aspectRatio}
            onChange={(value) => 
            {
                handleChangeFileInfoValues({
                    id: fileInfo.id,
                    value: { aspectRatio: value },
                });
                cropperRef?.current?.cropper?.setAspectRatio(value);
            }}
        />
        <FileInfoSection fileInfo={fileInfo} />
    </div>;
}

function FileInfoSection({ fileInfo }) 
{
    return (
        <ContentSection className="image-details-file-info-section">
            <ContentSection.Header>File Info</ContentSection.Header>
            <ContentSection.Main>
                <div className="info-section-bx">
                    <InfoSection>
                        <InfoSection.Label>Uploaded file&apos;s name</InfoSection.Label>
                        <InfoSection.Value>{fileInfo.originalName}</InfoSection.Value>
                    </InfoSection>
                    <InfoSection>
                        <InfoSection.Label>Size</InfoSection.Label>
                        <InfoSection.Value>{bytesToMB(fileInfo.size)} MB</InfoSection.Value>
                    </InfoSection>
                </div>
            </ContentSection.Main>
        </ContentSection>
    );
}

export default BulkImageUploadDetails;