import React, { useMemo } from 'react';
import { Scrollbars } from "react-custom-scrollbars-2";
import { Header } from "semantic-ui-react";
import { GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES } from "../../../_constants/mapEditor";
import EditSavedFloorPlan from "./EditSavedFloorPlan";
import "./floorPlanGeoReference.css";
import FloorPlanImportOrUpload from "./FloorPlanImportOrUpload";
import useFloorPlanCommon from "./hooks/useFloorPlanCommon";


/**
 * Function to render the FloorPlanInfoSideBar component based on the activeTab value. renders only when FLoor Plan or Geo Referencing edit is active
 *
 * @return {JSX.Element} The rendered FloorPlanInfoSideBar component
 */
const FloorPlanInfoSideBar = () =>
{
    const { activeTab } = useFloorPlanCommon();
    const { headerText, renderedBody } = useMemo(() =>
    {
        let headerText = "";
        let renderedBody = null;
        if (activeTab === GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES.floorPlanImage)
        {
            headerText = "Add Floor Plan";
            renderedBody = <FloorPlanImportOrUpload />;
        }
        if (activeTab === GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES.geoReferenceEdit)
        {
            headerText = "Edit Geo Reference";
            renderedBody = <EditSavedFloorPlan />;
        }
        return { headerText, renderedBody };
    }, [activeTab]);


    if (activeTab === GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES.geoReference || activeTab === "") return null;

    return (
        <div className="mapEditorEditInfoSidebar">
            <div className="headerSidebar">
                <Header>{headerText}</Header>
            </div>

            <Scrollbars className="mapEditorScroll" autoHeight autoHeightMin="calc(100vh - 220px)" autoHeightMax="calc(100vh - 220px)" >
                <div className="alignerMapRightbar">
                    {renderedBody}
                </div>
            </Scrollbars>
        </div>
    );
};

export default FloorPlanInfoSideBar;
