import React from "react";
import PropTypes from "prop-types";
import { InputFormGroup } from "../elements/InputFormGroup";
import { useTranslation } from "react-i18next";
import { SINGLE_LANG_INPUT_CODE } from "../../_constants/constants";


export const BuildingManualEditBox = React.memo(({ building, userAddress, validationErrors, buildingTypes, onChange, onChangeUserAddress }) =>
{
    /** @type {typeof import("../../_intl/resources.json")["en"]["translation"]["building manual edit box"]} */
    const trans = useTranslation().t("building manual edit box");
    return (
        <>
            <InputFormGroup label={trans["building name label"]} placeholder={trans["building name placeholder"]}
                value={building.longName?.[SINGLE_LANG_INPUT_CODE]} onChange={onChange} name={`longName.${SINGLE_LANG_INPUT_CODE}`} error={validationErrors[`longName.${SINGLE_LANG_INPUT_CODE}`]}
                extraLabel={trans["building name extra label"]} />

            <InputFormGroup label={trans["street num label"]} placeholder={trans["street num placeholder"]} type="number"
                value={userAddress.streetNumber} onChange={onChangeUserAddress} name="streetNumber" error={validationErrors["streetNumber"]}
                extraLabel={trans["street num extra label"]} />

            <InputFormGroup label={trans["unit num label"]} placeholder={trans["unit num placeholder"]} type="number"
                value={userAddress.unitNumber} onChange={onChangeUserAddress} name="unitNumber"
                extraLabel={trans["unit number extra label"]} />

            <InputFormGroup label={trans["street name label"]} placeholder={trans["street name placeholder"]}
                value={userAddress.street} onChange={onChangeUserAddress} name="street" error={validationErrors["street"]}
                extraLabel={trans["street name extra label"]} />

            <InputFormGroup label={trans["postal code label"]} placeholder={trans["postal code placeholder"]}
                value={userAddress.postalCode} onChange={onChangeUserAddress} name="postalCode" error={validationErrors["postalCode"]}
                extraLabel={trans["postal code extra label"]} />

            <InputFormGroup label={trans["building type label"]} list={buildingTypes} scrolling search
                onChange={onChange} name="type" listValue={building.type} error={validationErrors["type"]}
                extraLabel={trans["building type extra label"]} />

            <InputFormGroup label={trans["website label"]} placeholder={trans["website placeholder"]} error={validationErrors[`website.${SINGLE_LANG_INPUT_CODE}`]}
                value={building.website?.[SINGLE_LANG_INPUT_CODE]} onChange={onChange} name={`website.${SINGLE_LANG_INPUT_CODE}`}
                extraLabel={trans["website extra label"]} />

            <InputFormGroup label={trans["phone label"]}
                extraLabel={trans["phone extra label"]}
                placeholder={trans["phone placeholder"]} error={validationErrors["phoneCheck"]}
                value={building.phoneNumbers} onChange={onChange} name="phoneNumbers" />
        </>
    );
});

BuildingManualEditBox.displayName = "PropertyEditBox";

BuildingManualEditBox.propTypes = {
    building: PropTypes.object.isRequired,
    userAddress: PropTypes.object.isRequired,
    buildingTypes: PropTypes.array.isRequired,
    validationErrors: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onChangeUserAddress: PropTypes.func.isRequired,
};
