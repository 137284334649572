/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-state */


import React from "react";
import { withConfig } from "../_utils/hoc";
import AppContext from "./AppContext";
import serverAPI, { silentLoginToken } from "../_api/server.api";
import { TOKEN_REFRESHMENT_TIME_INTERVAL } from "../_constants/constants";

class AppProvider extends React.Component
{
    state = {
        userInfo: {},
        validatingToken: true,
        hasProperties: false,
        termsAndConditions: false,
        notifications: [],
        notificationsUntilDate: undefined,
        tokenRefreshmentInterval: undefined
            }

    componentDidMount = async () =>
    {
        const userToken = silentLoginToken();

        if (!userToken)
        {
            await this.validateToken();
        }
        else
        {
            await this.silentLogin(userToken);
        }

        this.tokenRefreshment();
    }

    componentWillUnmount()
    {
        this.clearTokenRefreshment();
    }

    logout = async () =>
    {
        this.clearTokenRefreshment();

        serverAPI.clear();

        this.setState({
            userInfo: {},
        });
        window.location.href = `${this.props.config.HUB_URL}/login?cb=${window.location.href}`;
    }

    openHubSettings = () => window.open(`${this.props.config.HUB_URL}/settings`, "hub-settings");

    silentLogin = async (userToken, inTheBackground = false) =>
    {
        if (!inTheBackground)
        {
            serverAPI.clear();
            this.setState({ validatingToken: true });
        }

        try
        {
            const userData = await serverAPI.silentLogin(userToken);
            const token = userData && serverAPI.token;

            this.setState({ userInfo: serverAPI.userData.user.userInfo });
            this.initUserSettings(token, token && serverAPI.userData.user);
        }
        catch (err)
        {
            console.log("Error", err);
            throw err;
        }
    }

    tokenRefreshment = () =>
    {
        const tokenRefreshmentInterval = setInterval(async () =>
        {
            try
            {
                const userToken = serverAPI.token;
                await serverAPI.validateToken(userToken);

                console.log("refresh token");
                this.setState({ userInfo: serverAPI.userData.user.userInfo });
            }
            catch (err)
            {
                // Failed to refresh token
                console.log("failed to refresh token")
                this.logout();
            }

        }, TOKEN_REFRESHMENT_TIME_INTERVAL);

        this.setState({ tokenRefreshmentInterval });
    }

    clearTokenRefreshment = () =>
    {
        const { tokenRefreshmentInterval } = this.state;

        tokenRefreshmentInterval && clearInterval(tokenRefreshmentInterval);
    }

    validateToken = async () =>
    {
        let token = serverAPI.token;
        this.setState({ validatingToken: true });

        return new Promise((resolve, reject) =>
        {
            serverAPI.validateToken(token)
                .then((isValid) =>
                {
                    if (isValid)
                    {
                        const newToken = serverAPI.token;
                        this.initUserSettings(token, token && serverAPI.userData.user);

                        resolve(newToken);
                    }
                    else
                    {
                        this.initUserSettings();
                        reject("invalid token");
                    }
                })
                .catch((err) =>
                {
                    this.initUserSettings();
                    reject(err);
                });
        });
    }

    initUserSettings = async (token = undefined, user = undefined) =>
    {
        if (token)
        {
            try
            {
                // Check if user accepted terms and conditions
                const permission = await serverAPI.doesUserHaveCMSPermission();

                const termsAndConditions = await serverAPI.areTermsAndConditionsAccepted();
                const notifications = await serverAPI.getUserNotifications();

                this.setState({
                    userInfo: user.userInfo,
                    validatingToken: false,
                    permission: permission,
                    termsAndConditions,
                    notifications,
                });
            }
            catch (err)
            {
                console.log("initUserSettings: ", err);
            }
        }
        else
        {
            serverAPI.clear();

            this.setState({
                userInfo: {},
            });
            window.location.href = `${this.props.config.HUB_URL}?cb=${window.location.href}`;
        }
    }

    /**
     * Accept Mapsted Term And Conditions and Save to Local Storage
     */
    acceptTermsAndConditions = async (url) =>
    {
        const termsAndConditions = await serverAPI.acceptTermsAndConditions(url);
        const notifications = await serverAPI.getUserNotifications();

        this.setState({ termsAndConditions, notifications });
    }

    getUserNotifications = async (callback) =>
    {
        const { notificationsUntilDate, notifications: prevNotifications } = this.state;

                let until = undefined;

        if (notificationsUntilDate instanceof Date)
        {
            until = notificationsUntilDate.toISOString();
        }

        const notifications = await serverAPI.getUserNotifications(until);
        if(JSON.stringify(prevNotifications) === JSON.stringify(notifications))
        {
            this.getMoreNotifications(callback);
            return;
        }

        this.setState({ notifications }, () => callback && callback());
    }

    getMoreNotifications = (callback) =>
    {
        let { notificationsUntilDate } = this.state;
        
        if (notificationsUntilDate)
        {
        // if exists, minus 7 days
            notificationsUntilDate = new Date(notificationsUntilDate.getTime() - 7 * 24 * 60 * 60 * 1000);
        }
        else
        {
            // otherwise minus 14 days from now
            notificationsUntilDate = new Date(Date.now() - 14 * 24 * 60 * 60 * 1000);
        }

        this.setState({ notificationsUntilDate }, () =>
        {
            this.getUserNotifications(callback);
        });
    }

    handleReadNotification = async (notification) =>
    {
        const notifications = [...this.state.notifications];

        const readNotification = notifications.find((n) => n._id === notification._id);

        if (readNotification)
        {
            const isRead = await serverAPI.readNotification(notification);
            readNotification.readAt = isRead ? new Date() : undefined;
        }

        this.setState({ notifications });
    }

    handleReadAllNotifications = async () =>
    {
        const notifications = [...this.state.notifications];

        const isRead = await serverAPI.readAllNotification();

        if (isRead)
        {
            notifications.forEach((n) =>
            {
                if (!n.readAt)
                {
                    n.readAt = new Date();
                }
            });

            this.setState({ notifications });
        }
    }

    userRedirect = (targetURL, target = undefined) =>
    {
        const token = serverAPI.token;
        if (token)
        {

            if (targetURL.startsWith("/"))
            {
                return window.location = targetURL;
            }

            if (target)
            {
                window.open(targetURL, target);
            }
            else if (target === undefined)
            {
                window.location = `${targetURL}?t=${token}`;
            }
            else
            {
                window.location = `${targetURL}`;
            }
        }
    };

    redirectToHub = () =>
    {
        window.location.href = this.props.config.HUB_URL;
    };

    render()
    {
        const value =
        {
            state: this.state,
            logout: this.logout,
            acceptTermsAndConditions: this.acceptTermsAndConditions,
            getUserNotifications: this.getUserNotifications,
            getMoreNotifications: this.getMoreNotifications,
            handleReadNotification: this.handleReadNotification,
            handleReadAllNotifications: this.handleReadAllNotifications,
            userRedirect: this.userRedirect,
            redirectToHub: this.redirectToHub,
            openHubSettings: this.openHubSettings,
        };

        return (
            <AppContext.Provider value={value}>
                {this.props.children}
            </AppContext.Provider>
        );
    }
}

export default withConfig(AppProvider);
