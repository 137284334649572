import { groupBy, merge } from "lodash";
import { EntityType,EntityTypes, StructureEntityType, PointOfInterestType, ObstacleEntityType, StructureEntityTypeNames } from "mapsted.maps/utils/entityTypes";


export const DATE_FORMAT = "DD-MM-YYYY";
export const TIME_FORMAT = "HH:mm";
export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

export const EMERG_ALERT_DEFAULT_HIGHLIGHT_COLOR = "#ffcc33";

export const SELECTABLE_ENTITIES = {
    [EntityType.STRUCTURE]: [
        StructureEntityType.UNKNOWN_STRUCTURE,
        StructureEntityType.BUILDING,
        StructureEntityType.ELEVATOR,
        StructureEntityType.ESCALATOR,
        StructureEntityType.ENTRANCE_EXIT,
        StructureEntityType.MOVING_WALKWAY,
        StructureEntityType.ROOM,
        StructureEntityType.PARKING,
        StructureEntityType.PLATFORM,
        StructureEntityType.RAMP,
        StructureEntityType.STAIRS,
        StructureEntityType.STEPS,
        StructureEntityType.WASHROOM
    ],
    [EntityType.POINT_OF_INTEREST]: [
        PointOfInterestType.UNKNOWN_POI
    ],
    [EntityType.OBSTACLE]: [
        ObstacleEntityType.UNKNOWN_OBSTACLE,
        ObstacleEntityType.BAGGAGE_CAROUSEL,
        ObstacleEntityType.DESK,
        ObstacleEntityType.EQUIPMENT,
        ObstacleEntityType.FURNITURE,
        ObstacleEntityType.OBSTRUCTION,
        ObstacleEntityType.SHELVING,
        ObstacleEntityType.STAGE,
        ObstacleEntityType.VEGETATION,
        ObstacleEntityType.WATER,
    ]
};



export const displayEntitiesForTargets = (entities, targets) =>
{
    let displayEntities = {};

    targets.forEach((target) =>
    {
        const matchedEntity = Object.values(entities).find((entity) => entity.propertyId === target.propertyId
                                                             && entity.buildingId === target.buildingId
                                                             && entity.floorId === target.floorId
                                                             && entity.entityId === target.entityId);

        if (matchedEntity)
        {
            displayEntities[matchedEntity._id] = {
                entityId: matchedEntity.entityId,
                floorId: matchedEntity.floorId,
                _id: matchedEntity._id
            };
        }
    });

    return displayEntities;

};

export const getDateObject = (date, time) =>
{
    const dateParts = date.split("-");
    const timeParts = time.split(":");

    return new Date(dateParts[2], dateParts[1]-1, dateParts[0], timeParts[0], timeParts[1]);
};

export const checkIfSelectable = (entityType, entitySubType) => SELECTABLE_ENTITIES[entityType] && SELECTABLE_ENTITIES[entityType].includes(entitySubType);

export const getEntityTypeName = (entityType, subEntityType) =>
{
    let entityTypeName = "";
    if (entityType && EntityTypes[entityType])
    {
        entityTypeName = EntityTypes[entityType].name || "";
        if (subEntityType && EntityTypes[entityType].subTypes)
        {
            entityTypeName = EntityTypes[entityType].subTypes[subEntityType].name || "";
        }
    }
    return entityTypeName;
};

/**
 *
 * @param {Array<object>} entities
 * @param {Array<number>} types
 * @returns {{[subType: number]: Array<object>}}
 */
export const getEntitiesBySubType = (entities, types = [], floorId = -1) =>
{
    const groups = groupBy(
        entities
            .filter((e) => floorId > -1 ? e.floorId === floorId : true) // nullify this filter if floorId not provided
            .filter((e) => types.includes(e?.subEntityType))
            .map((e) => merge(e, { name: StructureEntityTypeNames[e.subEntityType] })),
        "subEntityType"
    );
    types.forEach((type) =>
    {
        if (!groups[type])
        {
            groups[type] = [];
        }
    });
    return groups;
};


export const POPUP_ZOOM_PADDING = [5, 5, 5, 5];

export const downloadFile = (fileName, blob) =>
{
    const url = window.URL.createObjectURL(blob);
    const aTagElement = document.createElement("a");
    aTagElement.href = url;
    aTagElement.download = fileName;
    document.body.appendChild(aTagElement); // we need to append the element to the dom -> otherwise it will not work in firefox
    aTagElement.click();
    aTagElement.remove();
};

export const parseFile = async (file) =>
{
    const csvString = await new Promise((resolve) =>
    {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsText(file);
    });

    return csvString;
};
