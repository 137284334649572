import React from "react";
import ImagesSearchBar from "./ImagesSearchBar/ImagesSearchBar";
import ImagesRatioSelector from "./ImagesRatioSelector/ImagesRatioSelector";
import ImagesSortSelector from "./ImagesSortSelector/ImagesSortSelector";
import ImagesUploadSelector from "./ImagesUploadSelector/ImagesUploadSelector";
import "./ImagesManagementHeader.css";
import { ButtonIcon } from "../../../../common/ButtonIcon";
import { useImagesBaseContext } from "../contexts/ImageBaseContext";
import { GmsActionMode, useGmsContext } from "../../GmsContext";
import { useTranslation } from "react-i18next";

function ImagesManagementHeader() 
{
    const { imagesSearchAndFilters, handleChangeImagesSearchValue, handleChangeImageFilters, aspectRatioFilterEnabled } =
    useImagesBaseContext();
    const gmsContext = useGmsContext();
    const trans = useTranslation().t;

    return (
        <div className="images-management-header">
            <div className="images-management-header-title">
                {
                    gmsContext.gmsActionMode === GmsActionMode.REPLACE_IMAGE ? trans("gms.Select_img_you_are_deleting"): trans("gms.photos_added_here")
                }
            </div>
            <div className="images-management-header-actions">
                <ImagesSearchBar value={imagesSearchAndFilters.searchValue} onChange={(e) => handleChangeImagesSearchValue(e.target.value)} />
                {aspectRatioFilterEnabled && <ImagesRatioSelector />}
                <ImagesSortSelector value= {imagesSearchAndFilters.filters.sort} onClick={(e, option) => handleChangeImageFilters({ sort: option.value })} />
                {/* <ButtonIcon className={"images-management-header-duplicate-search"} icon="duplicate-search"></ButtonIcon> */}
                <ImagesUploadSelector />
            </div>
        </div>
    );
}

export default ImagesManagementHeader;