export const getActiveOtherZones=({ zones, buildingId, floorId }) => 
{
    let visibleZones = [];
    let otherZones = [];

    let selectedBuildingId = buildingId || -1;
    let selectedFloorId = floorId || -1;

    /* 
    * get all the visible distribution zones for the current property/building/floor
    * if building is selected, filter zones for the current floor
    * if property level is selected, get all the property level zones 
    */
    if (zones?.length) 
    {
        visibleZones = zones.filter((zone) => zone.buildingId === selectedBuildingId && zone.floorId === selectedFloorId);
    }

    // get all the other distribution from other parts of the building
    const visualZoneIdArray = visibleZones.map((zone) => zone.zoneGeofenceId);
    otherZones = otherZones.filter((zone) => !visualZoneIdArray.includes(zone.zoneGeofenceId) && zone.buildingId === selectedBuildingId);

    // if currenly in edit mode remove the zone under edit from visible zones since its rendering is handled elsewhere
    // if (operationMode === ZONE_OPERATION_MODES.EDIT && editZoneInfo?.zoneGeofenceId) {
    //     visibleDistributionZones = visibleDistributionZones.filter((distributionZone) => distributionZone.zoneGeofenceId !== editZoneInfo.zoneGeofenceId);
    // }

    return {
        visibleZones, otherZones
    };
};