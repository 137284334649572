import React from "react";
import { Segment, Icon } from "semantic-ui-react";
import { HeadingProperty } from "../elements/HeadingProperty";
import { GroundFloorModal } from "../popups/GroundFloorModal";
import { OrganizeFloorPlanRowSortable } from "./OrganizeFloorPlanRow";
import { AddFloorButton } from "../elements/AddFloorButton";
import { Transl } from "../../_intl/i18n";
import { useTranslation } from "react-i18next";


export const BuildingPreview = ({ groundFloor, floorsAbove, floorsBelow, onSelectFloor, onMoveFloor, onSetFloorNumber }) =>
{
    /** @type {typeof import("../../_intl/resources.json")["en"]["translation"]["building preview"]} */
    const trans = useTranslation().t("building preview");
    return (
        <Segment basic className="building-preview">
            <BuildingFloorSection heading={trans["heading"]} title={trans["above floor title"]}>
                <Segment basic className="drag-instruction">
                    {trans["drag instruction"]}
                    <Icon name="arrow down" />
                </Segment>
                <AddFloorButton floorNumber={floorsAbove.length + 1} onDrop={onSetFloorNumber} />
                {
                    floorsAbove.map((floor, index) => <OrganizeFloorPlanRowSortable key={floor.id} floor={floor} moveFloor={onMoveFloor} onDragStart={() => onSelectFloor(floor)} />                        
                    )
                }               
            </BuildingFloorSection>
            <BuildingFloorSection title={trans["ground floor title"]}>
                { !groundFloor? <AddFloorButton floorNumber={0} content={trans["ground drag drop instruction"]} onDrop={onSetFloorNumber} />: <OrganizeFloorPlanRowSortable floor={groundFloor} moveFloor={onMoveFloor} onDragStart={() => onSelectFloor(groundFloor)} />
                }
            </BuildingFloorSection>
            <BuildingFloorSection title={trans["below floor title"]}>
                {
                    floorsBelow.map((floor, index) => <OrganizeFloorPlanRowSortable key={floor.id} floor={floor} moveFloor={onMoveFloor} onDragStart={() => onSelectFloor(floor)}   />
                    )
                }
                <AddFloorButton floorNumber={ - 1 - floorsBelow.length} onDrop={onSetFloorNumber}/>
            </BuildingFloorSection>
        </Segment>
    );
};

export const BuildingFloorSection = (props) => (
    <div className="building-floor">
        {props.heading && <HeadingProperty heading={props.heading} />}
        <div className="building-floor-section">
            {props.children}
        </div>
        <TitleFloorLevel title={props.title} />
    </div>
);

export const TitleFloorLevel = ({ title, onClick }) =>
{
    const trans = useTranslation().t;
    return (
        <div className="title-floor" onClick={onClick} >
            {title === `${trans("building preview.ground floor title")}` && <GroundFloorModal />}
            {title} <Transl componentObjName="building preview" keyName="level" />
        </div>
    );
};

