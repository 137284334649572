import React, { useState } from "react";
import { Image } from "semantic-ui-react";
import { ButtonIcon } from "../ButtonIcon";
import "./CategoryItem.css";

const CategoryItem = ({ className, content, iconInfo, children, expanded, sideComponents }) => 
{
    const [showChildren, setShowChildren] = useState(false);
    const { url } = iconInfo;

    return (<>
        <div className={`categoryItemRoot ${className || ""}`}>
            <div className="categoryItem-content">
                {children?.length > 0 && (
                    <ButtonIcon 
                        icon={`chevron-${showChildren ? "up" : "down"}`} 
                        onClick={() => setShowChildren((prev) => !prev)} 
                    />
                )}
                <div className="categoryItem-name">
                    <Image className="categoryItemRoot-icon" as="span" src={url} />
                    <p>{content}</p>
                </div>
                <div className="rightSideComponent">
                    {sideComponents}
                </div>
            </div>
        </div>
        {(expanded || showChildren) && children && (
            <div className="subCategoryCover">{children}</div>
        )}
    </>
    );
};

export default CategoryItem;