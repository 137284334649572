import React, { Component } from "react";
import PropTypes from "prop-types";
import { Map, View, Feature, Collection } from "ol";
import { defaults as defaultControls, Attribution } from "ol/control";
import Point from "ol/geom/Point";
import { Translate, Pointer } from "ol/interaction";
import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import { Style, Icon } from "ol/style";
import { fromLonLat, toLonLat } from "ol/proj";
import Overlay from "ol/Overlay";
import { removeTrailingDigitsFromLatLngCoordinates } from "mapsted.utils/coordinates";
import { cartoLayer } from "mapsted.maps/mapFunctions/plotting";
import css from "./MarkerMap.module.css";

import "ol/ol.css";
import { Trans } from "react-i18next";

const DEFAULT_MAP_CURSOR = "grab";
const DRAG_MAP_CURSOR = "grabbing";
const HOVER_MAP_CURSOR = "pointer";

const iconStyle = new Style({
    image: new Icon({
        anchor: [.5, 54],
        anchorXUnits: "fraction",
        anchorYUnits: "pixels",
        src: "/img/pin-point.svg",
        scale: 1
    })
});

const coordinatesToString = (lngLat) => JSON.stringify(removeTrailingDigitsFromLatLngCoordinates(lngLat));

const mercatorToLonLatWrapper = (coordinates) =>
{
    const lngLat = toLonLat(coordinates);
    return removeTrailingDigitsFromLatLngCoordinates(lngLat);
};

export default class MarkerMap extends Component
{

    constructor(props)
    {
        super(props);
        this.state = {
            center: [0, 0],
            zoom: 3,
            iconFeature: undefined,
            selected: null,
        };

        this.mapRef = React.createRef();
        this.popupRef = React.createRef();

        const attribution = new Attribution({
            collapsible: false
        });

        const map = new Map({
            target: null,
            layers: [
                cartoLayer()
            ],
            controls: defaultControls({ attribution: false }).extend([attribution]),
            view: new View({
                center: this.state.center,
                zoom: this.state.zoom
            })
        });

        this.olmap = map;
    }

    handleHoverOnFeature = (e) =>
    {
        if (!e.dragging)
        {
            let newSelected = null;
            this.olmap.forEachFeatureAtPixel(e.pixel, function (feature)
            {
                newSelected = feature;
                return true;
            });

            // New feature has been selected, change cursor to pointer
            if (newSelected && this.state.selected === null)
            {
                this.olmap.getViewport().style.cursor = HOVER_MAP_CURSOR;
                this.setState({ selected: newSelected });
            }
            // Feature unselected 
            else if (newSelected === null && this.state.selected !== null)
            {
                this.setState({ selected: null });
                this.olmap.getViewport().style.cursor = DEFAULT_MAP_CURSOR;
            }
        }
    }

    componentDidMount ()
    {
        this.olmap.setTarget(this.mapRef.current);

        // Listen to map changes
        this.olmap.on("moveend", (e) =>
        {
            let center = this.olmap.getView().getCenter();
            // TODO: needs change, this changes zoom while cursor is still being moved. 
            //let zoom = this.olmap.getView().getZoom();
            this.setState({ center });
        });

        this.olmap.getViewport().style.cursor = DEFAULT_MAP_CURSOR;

        // On mousedown event
        this.olmap.on("pointerdown", () =>
        {
            this.olmap.getViewport().style.cursor = DRAG_MAP_CURSOR;
        });

        // On mouse up event
        this.olmap.on("pointerup", (e) =>
        {
            if (this.state.selected)
            {
                this.olmap.getViewport().style.cursor = HOVER_MAP_CURSOR;
            }
            else 
            {
                this.olmap.getViewport().style.cursor = DEFAULT_MAP_CURSOR;
            }
        });

        // create popup overlay
        this.popupOverlay = new Overlay({
            element: this.popupRef.current,
            autoPan: false,
            offset: [-97, -80],
        });
        this.olmap.addOverlay(this.popupOverlay);

        // checks if mouse cursor is hobering over a feature 
        // can be used to select ~ https://openlayers.org/en/latest/examples/select-hover-features.html
        this.olmap.addInteraction(new Pointer({
            handleMoveEvent: this.handleHoverOnFeature
        }));

    }

    componentDidUpdate (prevProps)
    {

        let { iconFeature } = this.state;

        if (this.props.markerCoordinates
            && (iconFeature === undefined
                || coordinatesToString(this.state.markerCoordinates) !== coordinatesToString(this.props.markerCoordinates)))
        {
            const zoom = 17;

            let markerCoordinates = fromLonLat(this.props.markerCoordinates);
            this.setState({ markerCoordinates: this.props.markerCoordinates });

            if (!iconFeature)
            {
                iconFeature = new Feature({
                    geometry: new Point([0, 0]),
                });

                iconFeature.setStyle(iconStyle);

                // Create the icon vector layer
                const iconSource = new VectorSource({
                    features: [iconFeature]
                });

                const vectorLayer = new VectorLayer({
                    source: iconSource
                });

                this.olmap.addLayer(vectorLayer);

                if (this.props.editable)
                {
                    iconFeature.on("change", this.onMarkerChange);

                    this.olmap.addInteraction(new Translate({
                        features: new Collection([iconFeature]),
                        style: undefined
                    }));
                }

                this.olmap.getView().setCenter(markerCoordinates);
                this.olmap.getView().setZoom(zoom);

                this.setState({ iconFeature, zoom });
            }

            // Checks if the map should reset, should be true after google lookup is completed and on init location.
            if (this.props.onShouldMapCenterReset())
            {
                this.setState({ zoom });

                this.olmap.getView().setCenter(markerCoordinates);
                this.olmap.getView().setZoom(zoom);

            }

            iconFeature.getGeometry().setCoordinates(markerCoordinates);
        }

        // When the map switched from non editable to editable mode
        if (this.props.editable && !prevProps.editable)
        {
            let iconFeature = this.state.iconFeature;

            if (iconFeature)
            {
                iconFeature.on("change", this.onMarkerChange);

                this.olmap.addInteraction(new Translate({
                    features: new Collection([iconFeature]),
                    style: undefined
                }));

                let position = iconFeature.getGeometry().getCoordinates();
                this.popupOverlay.setPosition(position);
            }
        }
    }

    onMarkerChange = (e) =>
    {
        const feature = e.target;
        const props = this.props;

        const newCoordinates = (feature.getGeometry().getCoordinates());

        let markerCoordinates = mercatorToLonLatWrapper(newCoordinates);

        let didCoordinatesChange = coordinatesToString(markerCoordinates) !== coordinatesToString(this.props.markerCoordinates);

        if (didCoordinatesChange && props.onMarkerCoordinatesChange)
        {
            props.onMarkerCoordinatesChange(markerCoordinates);
        }

        this.popupOverlay.setPosition(newCoordinates);
    }

    renderPopup = () =>
    
        //TODO: ASIF STYLE
        (
            <div className={css.tooltipMarker}>
                {/* <Button className={css.buttonClose}>
                    <img src="/img/icon-cross.svg" alt="" />
                </Button> */}
                <Trans i18nKey="Settings.Drag_Pin_To_Change_Address" />
            </div>
        )
    

    render ()
    {
        return (
            <div className="map-container" ref={this.mapRef}>
                <div ref={this.popupRef}>
                    {
                        this.renderPopup()
                    }
                </div>
            </div>
        );
    }
}

MarkerMap.propTypes = {
    editable: PropTypes.bool,
    markerCoordinates: PropTypes.arrayOf(PropTypes.number),
    onMarkerCoordinatesChange: PropTypes.func,
    onShouldMapCenterReset: PropTypes.func,
};

MarkerMap.defaultProps =
{
    onShouldMapCenterReset: function () 
    {
        return true; 
    }
};