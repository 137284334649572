import React from "react";
import { Footer } from "../../layout/Footer";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Button, Image } from "semantic-ui-react";
import "./GlobalGroup.css";
import { DropdownForm } from "../../common/DropdownForm";
import { GroupCategoriesSidebar } from "../../globalGroup/GroupCategoriesSidebar";
import { CheckboxGroup } from "../../common/CheckboxGroup";

class GlobalGroup extends React.Component
{

    render()
    {
        return (
            <>
                <div className="globalGroupContainer">
                    <div className="groupHeadbar">
                        <DropdownForm text="Global Group" className="pageHeaderGroup" />
                        <Button primary content="Sync" />
                    </div>

                    <div className="globalGroupCover">
                        <div className="globalGroupSidebar">
                            <b>GLOBAL GROUPS</b>
                            <GroupCategoriesSidebar />
                        </div>

                        <div className="globalGroupBody">
                            <Scrollbars autoHeight autoHeightMin={`calc(1vh)`} autoHeightMax={`calc(100vh - 190px)`} className="scrollGroupBody">
                                <div className="mallGroupCover">
                                    <MallGroupSection />
                                    <MallGroupSection />
                                    <MallGroupSection />
                                    <MallGroupSection />
                                    <MallGroupSection />
                                    <MallGroupSection />
                                    <MallGroupSection />
                                </div>

                            </Scrollbars>
                        </div>

                    </div>
                </div>
                <Footer />
            </>
        )
    }
}

export default GlobalGroup;


export const MallGroupSection = () =>
{
    return (
        <div className="mallGroupSection">
            <GroupMallLogo />
            <div className="mallItemsGroup">
                <Scrollbars autoHeight autoHeightMin={`calc(1vh)`} autoHeightMax={`calc(100vh - 260px)`} className="scrollMallGroup">
                    <SelectedCategoryItem />
                    <SelectedCategoryItem />
                    <SelectedCategoryItem />
                    <SelectedCategoryItem />
                    <SelectedCategoryItem />
                    <SelectedCategoryItem />
                    <SelectedCategoryItem />
                    <SelectedCategoryItem />
                    <SelectedCategoryItem />
                    <SelectedCategoryItem />
                    <SelectedCategoryItem />
                    <SelectedCategoryItem />
                    <SelectedCategoryItem />
                    <SelectedCategoryItem />
                    <SelectedCategoryItem />
                </Scrollbars>
            </div>
        </div>
    );
}

const GroupMallLogo = () =>
{
    return (
        <div className="groupMallLogo">
            <Image as="span" src="/img/example-mall-logo.svg" />
            Mall Name
        </div>
    );
}

const SelectedCategoryItem = () =>
{
    return (
        <div className="selectedCategoryItem">
            <CheckboxGroup label="Luxury Apparel" />
            <Image as="span" src="/img/example-icon-group-category.svg" />
        </div>
    );
}