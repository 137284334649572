import React, { useSetState } from "ahooks";
import cloneDeep from "lodash.clonedeep";
import { DEFAULT_LANGUAGE_CODE } from "mapsted.maps/utils/map.constants";
import { arrayToHash } from "mapsted.utils/arrays";
import { useContext } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { Button, Header, Input } from "semantic-ui-react";
import { DynamicMapLayersContext } from "../../../../store/DynamicMapLayersContext";
import { DYNAMIC_MAP_LAYERS_MODES } from "../../../../_constants/constants";
import { ButtonIcon } from "../../../common/ButtonIcon";
import { DropdownForm } from "../../../common/DropdownForm";
import { TextGroup } from "../../../common/TextGroup";
import { ErrorLabel } from "../../../elements/ErrorLabel";
import LanguageSelector from "../../mapOverlays/LanguageSelector";
import DynamicMapLayerCancelConfirmationModal from "../DynamicMapLayerCancelConfirmationModal";
import { removeLangCodeFromAutomationRule, removeLangCodeFromDynamicMapLayer } from "../utils/dynamicMapLayersUtils";
import { validateTab1DynamicMapLayerInfo } from "../utils/dynamicMapLayerValidations";
import CreateMultiDynamicMapLayerButton from "./CreateMultiDynamicMapLayerButton";
import { useTranslation } from "react-i18next";

const CreateDynamicMapLayerSidebarBasicInfoTab = ({ updateTab }) =>
{
    const dynamicMapLayerCtx = useContext(DynamicMapLayersContext);

    const [state, setState] = useSetState({
        validationErrors: []
    });

    const {
        updateActiveDynamicMapLayer,
        cancelHandler,
        redirectToMapOverlayCreation,
        checkIfModifiedInCreationMode,
        state: ctxState,
        updateDisplayLang,
        updateActiveAutomationRule,
    } = dynamicMapLayerCtx;
    const {
        mapOverlays,
        activeDynamicMapLayer,
        mode,
        dynamicMapLayers,
        displayLang,
        allowedLangs,
        activeAutomationRule,
    } = ctxState;

    const trans = useTranslation().t;

    const getMapOverlayOptions = () =>
    {
        const consumedMapOverlays = Object.keys(arrayToHash(dynamicMapLayers, "mapOverlayId"));

        return mapOverlays
            .filter((mapOverlay) => !mapOverlay.dynamicOverlaySettings.enabled)
            .filter((mapOverlay) => !consumedMapOverlays.includes(mapOverlay._id) || activeDynamicMapLayer.mapOverlayId === mapOverlay._id)
            .map((mapOverlay) => ({
                key: mapOverlay._id,
                value: mapOverlay._id,
                text: mapOverlay.name[DEFAULT_LANGUAGE_CODE]
            }));
    };

    const updateField = (key, value) =>
    {
        let updatedDynamicMapLayer = cloneDeep(activeDynamicMapLayer);
        updatedDynamicMapLayer[key] = value;
        updateActiveDynamicMapLayer(updatedDynamicMapLayer);
        setState({
            validationErrors: []
        });
    };

    const clearLangCodeFromDynamicMapLayer = (lang) =>
    {
        let updatedDynamicMapLayer = { ...activeDynamicMapLayer };
        removeLangCodeFromDynamicMapLayer(lang, updatedDynamicMapLayer);
        updateActiveDynamicMapLayer(updatedDynamicMapLayer);

        if (activeAutomationRule)
        {
            let updatedActiveAutomationRule = { ...activeAutomationRule };
            removeLangCodeFromAutomationRule(lang, updatedActiveAutomationRule);
            updateActiveAutomationRule(updatedActiveAutomationRule);
        }
    };

    const nextClickHandler = () =>
    {
        const validationErrors = validateTab1DynamicMapLayerInfo(activeDynamicMapLayer);

        if (validationErrors.length)
        {
            setState({
                validationErrors
            });
        }
        else
        {
            updateTab(1);
        }
    };

    const getMapOverlayDetails = () =>
    {
        const { mapOverlayId } = activeDynamicMapLayer;

        if (mapOverlayId)
        {
            const mapOverlayDetails = mapOverlays.find((mapOverlay) => mapOverlay._id === mapOverlayId);

            return (
                <div className="mapOverlayPropertybox">
                    <TextGroup title={trans("Color_Picker.Text_Tooltip")} content={mapOverlayDetails?.toolTipText[DEFAULT_LANGUAGE_CODE]} />
                    <div className="mapOverlayPropertyRow">
                        <TextGroup title={trans("Color_Picker.Default_Color")} className={"colorLabelContainer"} content={
                            <span
                                className="colorFill"
                                style={{ background: mapOverlayDetails?.color }}>
                            </span>} />
                        <TextGroup title={trans("Color_Picker.Opacity")} content={`${Math.floor((mapOverlayDetails?.defaultFillOpacity * 100).toFixed(2))}%`} />
                    </div>
                    <div className="mapOverlayPropertyRow">
                        <TextGroup title={trans("Color_Picker.Default_Border_Color")} className={"colorLabelContainer"} content={
                            <span
                                className="colorFill"
                                style={{ background: mapOverlayDetails?.lineColor }}>
                            </span>} />
                        <TextGroup title={trans("Color_Picker.Opacity")} content={`${Math.floor((mapOverlayDetails?.defaultBorderFillOpacity * 100).toFixed(2))}%`} />
                    </div>
                    <div className="mapOverlayPropertyRow">
                        <TextGroup title={trans("Color_Picker.Default_Text_Color")} className={"colorLabelContainer"} content={
                            <span 
                                className="colorFill"
                                style={{ background: mapOverlayDetails?.textColor ? mapOverlayDetails?.textColor : "#000000" }}>
                            </span>} />
                        <TextGroup title={trans("Color_Picker.Opacity")} content={`${Math.floor((mapOverlayDetails?.defaultTextOpacity * 100).toFixed(2))}%`} />
                    </div>
                </div>
            );
        }
        else
        {
            return null;
        }
    };

    const disableNextButton = !(activeDynamicMapLayer.dataStreamName[DEFAULT_LANGUAGE_CODE] && activeDynamicMapLayer.mapOverlayId);

    const mapOverlayOptions = getMapOverlayOptions();

    const enableCancelConfirmationModal = checkIfModifiedInCreationMode();

    return (
        <div className="sidebarPortal dynamicMapLayerSidebar">
            <Scrollbars autoHeight autoHeightMin="calc(100vh - 170px)" autoHeightMax={"calc(100vh - 170px)"}>
                <div className="alignerSidebar">
                    <Header as="h2">
                        <span className="creationTabHeading">{trans("MaintenanceCreateDynamicMapSidebar.Dynamic_Map_Layer")}</span>
                        {enableCancelConfirmationModal ? <DynamicMapLayerCancelConfirmationModal
                            onCancelClick={cancelHandler}
                            isCreate={mode === DYNAMIC_MAP_LAYERS_MODES.CREATE_DYNAMIC_MAP_LAYER}
                        /> : <ButtonIcon icon="cross" className="dynamicMapLayercancelButton" onClick={cancelHandler} />}
                    </Header>
                    <div className="sidebarSection">
                        <p className="mapOverlaySidebarTabInfo">
                            {trans("MaintenanceCreateDynamicMapSidebar.Dynamic_Map_Layer_Description")}
                        </p>
                    </div>
                    <div className="sidebarSection spaceBottom">
                        <CreateMultiDynamicMapLayerButton />
                    </div>
                    <div className="sidebarSection">

                        <TextGroup
                            heading={trans("LanguageSelector.Preview_in_Language")}
                            className="languageSelector"
                        >
                            <LanguageSelector
                                activeLang={displayLang}
                                defaultDisplayedLangs={Object.keys(activeDynamicMapLayer.dataStreamName)}
                                setActiveLang={(lang) => updateDisplayLang(lang)}
                                allowedLangs={allowedLangs}
                                onLangAdd={(lang) => updateField("dataStreamName",
                                    {
                                        ...activeDynamicMapLayer.dataStreamName,
                                        [lang]: ""
                                    })}
                                onLangDelete={(lang) => clearLangCodeFromDynamicMapLayer(lang)}
                            />
                        </TextGroup>

                        <TextGroup heading={trans("MaintenanceCreateDynamicMapSidebar.Data_Stream_Name")}>
                            <Input
                                placeholder={trans("MaintenanceCreateDynamicMapSidebar.Type_Name_Here")}
                                value={activeDynamicMapLayer.dataStreamName[displayLang] || ""}
                                onChange={(e, { value }) => updateField("dataStreamName",
                                    {
                                        ...activeDynamicMapLayer.dataStreamName,
                                        [displayLang]: value
                                    })} />
                        </TextGroup>

                        <TextGroup heading={trans("MaintenanceCreateDynamicMapSidebar.Map_Overlay")}>
                            <DropdownForm
                                className="mapOverlayPicker"
                                scrolling
                                selectOnBlur={false}
                                placeholder={trans("MaintenanceCreateDynamicMapSidebar.Choose_Map_Overlay")}
                                options={mapOverlayOptions}
                                disabled={!mapOverlayOptions.length}
                                value={activeDynamicMapLayer.mapOverlayId || ""}
                                onChange={(e, { value }) => updateField("mapOverlayId", value)} />
                            {activeDynamicMapLayer.mapOverlayId
                                && <ButtonIcon
                                    icon="cross"
                                    className="mapOverlayClearButton"
                                    onClick={() => updateField("mapOverlayId", undefined)} />
                            }
                        </TextGroup>
                        {getMapOverlayDetails()}
                        {!activeDynamicMapLayer.mapOverlayId
                            && <TextGroup heading={trans("MaintenanceCreateDynamicMapSidebar.Or")}>
                                <ButtonIcon
                                    icon="plus-filled"
                                    className={"headingButton"}
                                    content={trans("MaintenanceCreateDynamicMapSidebar.Create_New_Map_Overlay")}
                                    onClick={redirectToMapOverlayCreation}
                                />
                            </TextGroup>
                        }
                    </div>

                    {!!state.validationErrors.length && <div className="sidebarSection">
                        <ErrorLabel multiple={Object.keys(state.validationErrors).length > 0}>
                            {trans("CreateMapOverlaysSideBar.Validation_Error_Header")}
                            <ul>
                                {state.validationErrors.map((validationError) => <li key={validationError}>{validationError}</li>)}
                            </ul>
                        </ErrorLabel>
                    </div>}
                </div>
            </Scrollbars>
            <Button
                color="orange"
                className="saveButton"
                content={trans("MaintenanceCreateDynamicMapSidebar.Next")}
                disabled={disableNextButton}
                onClick={nextClickHandler} />
        </div>
    );
};

export default CreateDynamicMapLayerSidebarBasicInfoTab;