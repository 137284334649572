
import React, { useCallback, useMemo, useContext } from "react";
import { Button, Popup, Image } from "semantic-ui-react";
import MapEditorContext from "../../../store/MapEditiorContext";
import { EDIT_TYPES } from "../../../_constants/mapEditor";
import { ButtonIcon } from "../../common/ButtonIcon";
import { MapEditorSidebarEntities } from "./MapEditorSidebarEntities";
import { MapEditorSidebarNodes } from "./MapEditorSidebarNodes";
import { MapEditorSidebarTransitions } from "./MapEditorSidebarTransitions";
import { toast } from "sonner";

export const MapEditorLeftbar = ({ editType, smartFilter, selectedFilterItem, onSelectFilterItem, onChangeEditType }) =>
{
    const mapEditorContext = useContext(MapEditorContext);

    const { isSideBarExpanded, setIsSideBarIsExpanded } = mapEditorContext;

    const handleChangeEditType = useCallback((newEditType) =>
    {
        if (editType === newEditType)
        {
            // seteditType(undefined);
            setIsSideBarIsExpanded(!isSideBarExpanded);
        }
        else
        {
            setIsSideBarIsExpanded(true);
            (onChangeEditType) && onChangeEditType(newEditType);
        }
    }, [editType, isSideBarExpanded, onChangeEditType]);

    // SIDEBAR COMPONENT HELPER FUNCTIONS

    const selectedToolMemo = useMemo(() => mapEditorContext?.state?.selectedTool, [mapEditorContext?.state?.selectedTool]);

    const handleShowNotLockedByUserAlert = useCallback((alertsMessage) =>
    {
        toast.error(alertsMessage);
    }, []);

    const changeSelectedTool = useCallback((toolName) =>
    {
        mapEditorContext.handleChangeSelectedTool(toolName, (alertsMessage) =>
        {
            handleShowNotLockedByUserAlert(alertsMessage);
        });
    }, [mapEditorContext, handleShowNotLockedByUserAlert]);

    const handleOnToolClick = useCallback((e, { name }) =>
    {
        changeSelectedTool(name);
    }, [changeSelectedTool]);


    const renderDisplayEditTypesTools = useCallback(() =>
    {
        switch (editType)
        {
        case (EDIT_TYPES.NODES):
        {
            return (<MapEditorSidebarNodes
                smartFilter={smartFilter}
                selectedTool={selectedToolMemo}
                selectedFilterItem={selectedFilterItem}
                onSelectFilterItem={onSelectFilterItem}
                onToolClick={handleOnToolClick}
                onShowNotLockedByUserAlert={handleShowNotLockedByUserAlert}
            />);
        }
        case (EDIT_TYPES.ENTITIES):
        {
            return (<MapEditorSidebarEntities
                smartFilter={smartFilter}
                selectedTool={selectedToolMemo}
                selectedFilterItem={selectedFilterItem}
                onSelectFilterItem={onSelectFilterItem}
                onToolClick={handleOnToolClick}
                onShowNotLockedByUserAlert={handleShowNotLockedByUserAlert}
            />);

        }

        case (EDIT_TYPES.TRANSITIONS):
        {
            return (<MapEditorSidebarTransitions
                selectedTool={selectedToolMemo}
                onToolClick={handleOnToolClick}
                onShowNotLockedByUserAlert={handleShowNotLockedByUserAlert}
            />);
        }
        default:
        {
            return (<div> </div>);
        }
        }
    }, [editType, smartFilter, selectedToolMemo, selectedFilterItem, onSelectFilterItem, handleOnToolClick, handleShowNotLockedByUserAlert]);

    const renderArrowTriggerButton = useCallback(() =>
    {
        const handleOnClick = () =>
        {
            setIsSideBarIsExpanded(!isSideBarExpanded);
        };

        if (!editType)
        {
            return (<div />);
        }
        else
        {
            if (isSideBarExpanded)
            {
                return (<ButtonIcon icon="arrow-right" className="triggerEditor isActive" onClick={handleOnClick} />);
            }
            else
            {
                return (<ButtonIcon icon="arrow-right" className="triggerEditor" onClick={handleOnClick} />);
            }
        }
    }, [editType, isSideBarExpanded, setIsSideBarIsExpanded]);

    return (
        <div className="mapEditorLeftbar">
            {renderArrowTriggerButton()}
            <MapEditorSidebarMenu editType={editType} onChangeEditType={handleChangeEditType} />
            {
                (isSideBarExpanded) && (
                    renderDisplayEditTypesTools()
                )
            }
        </div>
    );
};

const MapEditorSidebarMenu = ({ editType, onChangeEditType }) =>
{
    const handleSideBarMenuItemClick = useCallback((e, { name }) =>
    {
        onChangeEditType(name);
    }, [onChangeEditType]);

    return (
        <div className="mapEditorSidebarMenu">

            <Popup className="mapLayerPopup"
                trigger={<div><MapEditorSidebarMenuItem icon="icon-editor-leftmenu-2" name={EDIT_TYPES.ENTITIES} active={editType === EDIT_TYPES.ENTITIES} onClick={handleSideBarMenuItemClick} /></div>}
                position="right center"
                on="hover"
                size="small"
                content="Entities" />

            <Popup className="mapLayerPopup"
                trigger={<div><MapEditorSidebarMenuItem icon="icon-editor-leftmenu-1" name={EDIT_TYPES.NODES} active={editType === EDIT_TYPES.NODES} onClick={handleSideBarMenuItemClick} /></div>}
                position="right center"
                on="hover"
                size="small"
                content="Nodes" />

            <MapEditorSidebarMenuItem icon="icon-editor-leftmenu-3" name={EDIT_TYPES.TRANSITIONS} active={editType === EDIT_TYPES.TRANSITIONS} onClick={handleSideBarMenuItemClick} />
            {/* <MapEditorSidebarMenuItem icon="icon-editor-leftmenu-3" disabled />
            <MapEditorSidebarMenuItem icon="icon-editor-leftmenu-4" disabled />
            <MapEditorSidebarMenuItem icon="icon-editor-leftmenu-5" disabled />
            <MapEditorSidebarMenuItem icon="icon-editor-leftmenu-6" disabled /> */}

        </div>
    );
};

const MapEditorSidebarMenuItem = ({ icon, active, name, disabled, onClick }) => (
    <Button className="mapEditorSidebarMenuItem" name={name} onClick={onClick} active={active} disabled={disabled}>
        <img src={`/img/${icon}.svg`} alt="" />
    </Button>
);

export const MapEditorSidebarItem = ({ icon, content, name, onClick, active, disabled }) => (
    <Button className="mapEditorSidebarItem" name={name} onClick={onClick} active={active} disabled={disabled}>
        <Image as="span" src={`/img/${icon}.svg`} alt="" />
        {content}
    </Button>
);

export const ShortCutsPopupItem = ({ title, content }) => (
    <div className="shortcutsPopupItem">
        {title}
        <span>{content}</span>
    </div>
);

// const MapEditorLegendPopup = () =>
// {
//     return (
//         <Popup className="mapEditorLegendPopup"
//             trigger={<ButtonIcon className="shortcutsPopupTrigger mapEditorLegendTrigger"
//                 icon="editor-legend"
//                 content="Legend" />}
//             on="click"
//             basic
//         >
//             <Header>Legend</Header>
//             <MapEditorLegendItem content="Nodes" />
//             <MapEditorLegendItem content="Map Layers" />
//             <MapEditorLegendItem content="Smart Filters" />
//         </Popup>
//     );
// }

const MapEditorLegendItem = ({ content }) => (
    <div className="mapEditorLegendItem">
        <span></span>
        {content}
    </div>
);
