import React, {ReactText, CSSProperties, FC} from 'react';

interface LocalIconProps {
    className?: string;
    name: string;
    marginRight?: ReactText;
    style?: CSSProperties;
};
const LocalIcon: FC<LocalIconProps> = ({
    className = "",
    name,
    marginRight = 0,
    style = {}
}) => (
        <img className={className} src={`/img/icon-${name}.svg`} style={{ marginRight, ...style }} />
    );

export default LocalIcon;
