import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Button, Header, Grid } from "semantic-ui-react";
import { useMutation, useQueryClient } from "react-query";

import brandingApi from "../../../../../_api/branding.api";
import { ModalLayout } from "../../../../popups/ModalLayout";
import { UploadTab } from "../../../../popups/UploadImageModal";
import { ErrorLabel } from "../../../../elements/ErrorLabel";
import serverApi from "../../../../../_api/server.api";
import LinkButton from "../../../../common/LinkButton";

export const UploadCSVModal = ({
    className,
    isOpen,
    trigger,
    close,
    entityLabelId,
}) =>
{
    const queryClient = useQueryClient();
    const trans = useTranslation().t;

    const { mutate, isLoading } = useMutation(
        brandingApi.uploadCSVFile,
        {
            mutationKey: "UPLOAD_CSV_FILE",
        }
    );

    const [file, setFile] = React.useState(null);

    const [hasError, setHasError] = React.useState(false);

    const handleFileChange = (files) =>
    {
        // eslint-disable-next-line no-debugger
        setFile(files[0]);
    };

    const handleFileUpload = () =>
    {

        if (!file) return;

        const formData = new FormData();

        formData.append("file", file, file.name);

        formData.append("entityLabelId", entityLabelId);

        mutate(formData, {
            onSuccess: (res) =>
            {
                console.log("SUCCESS", res);
                queryClient.refetchQueries("FIND_INVENTORIES");
                close();
            },
            onError: () => setHasError(true)
        });
    };

    React.useEffect(() =>
    {
        if (!file)
        {
            setHasError(false);
        }
    },[file]);

    const handleDownload = (e) =>
    {
        e.preventDefault();
        serverApi.download("Inventory-Template.csv");
    };

    return (
        <ModalLayout
            open={isOpen}
            trigger={trigger}
            onClose={close}
            heading={trans("UploadCSVModal.Upload_CSV_Template")}
            className={className}
            size="mini"
            onOpen={() => setFile(null)}
            actions={
                <>
                    <Button
                        loading={isLoading}
                        disabled={isLoading}
                        color="green"
                        content={trans("UploadCSVModal.Upload")}
                        onClick={handleFileUpload}
                    />
                </>
            }>
            <p className="p-modal">
                <Trans i18nKey="UploadCSVModal.CSV_template" components={{ templateLink: <LinkButton onClick={handleDownload} /> }}/>
            </p>

            <div>
                {file ? (
                    <>
                        <Grid centered>
                            <Grid.Row>
                                <Header content={file.name} />
                            </Grid.Row>
                            <Grid.Row style={{ marginTop: "20px" }}>
                                <Button
                                    basic
                                    icon="refresh"
                                    content={trans("UploadCSVModal.Change")}
                                    onClick={() => setFile(null)}
                                />
                            </Grid.Row>
                            <Grid.Row style={{ marginTop: "20px" }}>
                                {hasError && (
                                    <ErrorLabel>
                                        {trans("UploadCSVModal.One_or_more_rows_has_incorrect/missing_d")}
                                    </ErrorLabel>
                                )}
                            </Grid.Row>
                        </Grid>
                    </>
                ) : (
                    <UploadTab
                        acceptableImageFileFormats={".csv"}
                        onChange={handleFileChange}
                        returnRawFile
                    />
                )}
            </div>
        </ModalLayout>
    );
};
