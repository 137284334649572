import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Button, Grid, Segment } from "semantic-ui-react";
import { HeadingProperty } from "../../elements/HeadingProperty";
import { AddPropertyLayout } from "../../addProperty/AddPropertyLayout";
import { InputFormGroup } from "../../elements/InputFormGroup";
import { InfoHeadingPopup } from "../../popups/InfoHeadingPopup";
import { BuildingFloorUploadRow } from "../../addProperty/BuildingFloorUploadRow";
import AddPropertyContext from "../../../store/AddPropertyContext";
import { InitialDetailBox } from "../../addProperty/InitialDetailBox";
import { getSearchParams, getFloorValidation } from "../../../_utils/utils";
import MarkerMap from "../../addProperty/MarkerMap";
import { ErrorModal } from "../../popups/ErrorModal";
import { WARNING_MESSAGES, ERROR_MESSAGES, SINGLE_LANG_INPUT_CODE } from "../../../_constants/constants";
import { ErrorLabel } from "../../elements/ErrorLabel";
import { withTranslation } from "react-i18next";
import { withConfig } from "../../../_utils/hoc";

const MAX_FLOORS = 20;

class AddFloorPlanImpl extends React.Component
{
    static contextType = AddPropertyContext;

    state = {
        floorNum: 0,
        floorNumDisabled: false,
        uploadedFloors: [],
        floorNumError: undefined,
        floorsMissingModalIsOpen: false,
        validationErrors: {},
        error: {} // Warnings
    }

    /**
    * Updates floors state in provider and routes back to AddProperty component.
    */
    handleGoBack = () =>
    {
        const addPropertyContext = this.context;
        const { uploadedFloors } = this.state;

        const { buildingId, propertyId } = getSearchParams(["buildingId", "propertyId"]);

        addPropertyContext.handleUpdateState({ floors: uploadedFloors });

        // Note: we can't use history.location.search here because when the search is updated by the provider with new buildingId it won't be recognized.
        // eslint-disable-next-line react/prop-types
        this.props.history.push(`./building?propertyId=${propertyId}&buildingId=${buildingId}`);
    }

    /**
     * Handles the following cases.
     * 1. If property and building IDs are passed through url search params, load property/building.
     * 2. Else if building is not loaded route back to building page.
     */
    componentDidMount ()
    {
        const addPropertyContext = this.context;
        const { buildingId, propertyId } = getSearchParams(["buildingId", "propertyId"]);
        const { loadUnsavedFloors, floors, building } = addPropertyContext.state;
        const selectedBuildingId = addPropertyContext.state.buildingId;

        if (loadUnsavedFloors && selectedBuildingId === buildingId)
        {
            addPropertyContext.loadUnsavedFloorPlans(false, () =>
            {
                this.handleFloorsInit(floors, building.centroid);
            });
        }
        else if (propertyId && buildingId)
        {
            addPropertyContext.getProperty({ propertyId, buildingId, isFloorPlansEdit: true }, ({ building, floors }) =>
            {
                this.handleFloorsInit(floors, building.centroid);
            });
        }
        else if (addPropertyContext.state.buildingLoaded === true)
        {
            this.handleFloorsInit([], addPropertyContext.state.building.centroid);
        }
        else
        {
            this.handleGoBack();
        }

    }

    handleFloorsInit = (floors, centroid) =>
    {
        this.setState({
            uploadedFloors: floors,
            floorNum: floors.length,
            floorNumDisabled: floors.length > 0,
            centroid
        });
    }

    handleFloorNum = (e, { value }) =>
    {
        let floorNum = parseInt(value);
        let floorNumError = undefined;
        const trans = this.props.t;

        if (floorNum)
        {
            if (floorNum > MAX_FLOORS)
            {
                floorNum = MAX_FLOORS;
                floorNumError = `${trans("AddFloorPlan.Maximum_number_of_floors_is")} ${MAX_FLOORS}.`;
            }
            else if (floorNum < 0)
            {
                floorNum = 0;
            }
        }
        else
        {
            floorNum = 0;
        }

        this.setState({ floorNum, floorNumError });
    }

    handleUploadedFloors = (i, floor) =>
    {
        let newUploadedFloors = [...this.state.uploadedFloors];

        floor.id = i;

        // trim extra whitespace
        floor.shortName[SINGLE_LANG_INPUT_CODE] = floor.shortName[SINGLE_LANG_INPUT_CODE].trim();
        floor.longName[SINGLE_LANG_INPUT_CODE] = floor.longName[SINGLE_LANG_INPUT_CODE].trim();

        newUploadedFloors[i] = floor;

        this.setState({ uploadedFloors: newUploadedFloors, floorNumDisabled: true });
    }

    handleDeleteFloor = (index) =>
    {
        let { floorNum } = this.state;
        let floors = [...this.state.uploadedFloors];
        floors.splice(index, 1);
        floorNum--;

        // Clear
        const validationErrors = getFloorValidation(floors);
        this.setState({ uploadedFloors: floors, floorNum, floorNumDisabled: floorNum === 0, validationErrors });
    }

    handleAddFloor = () =>
    {
        const { floorNum } = this.state;

        this.setState({ floorNum: floorNum < MAX_FLOORS ? floorNum + 1 : floorNum });
    }

    floorPlanContainsDuplicate = () =>
    {
        const { uploadedFloors } = this.state;
        let floorPlans = [];

        for (let i = 0; i < uploadedFloors.length; i++)
        {
            const floor = uploadedFloors[i];

            if (floor !== null)
            {
                if (floor.floorPlan)
                {
                    if (floorPlans.includes(floor.floorPlan.name))
                    {
                        return true;
                    }
                    else
                    {
                        floorPlans.push(floor.floorPlan.name);
                    }
                }

            }
        }

        return false;
    }

    /**
    * Trigger popup before moving to next if floorsUploaded < floorNum
    * @param {Boolean} byPass - Whether or not to bypass floor warning.
    **/
    handleOnNext = (byPass = true) =>
    {
        const addPropertyContext = this.context;
        const { floorNum, uploadedFloors } = this.state;

        const validationErrors = getFloorValidation([...this.state.uploadedFloors]);

        // Check if there are any validation errors.
        if (Object.keys(validationErrors).length > 0)
        {
            this.setState({ validationErrors });
        }
        // If passes validation.
        else
        {
            let duplicateError = this.floorPlanContainsDuplicate();
            let floorNumError = uploadedFloors.length < floorNum;

            let incompleteFloor = uploadedFloors.some((floor) => floor.status === this.props.config.STATUSES.INCOMPLETE);

            if ((duplicateError || floorNumError || incompleteFloor) && !byPass)
            {
                let errors = {};

                // Add any errors to the errors object to be sent to error modal.
                duplicateError && (errors.duplicateError = WARNING_MESSAGES["duplicateFloorError"]);
                (incompleteFloor || floorNumError) && (errors.floorNumError = WARNING_MESSAGES["floorNumError"]);

                this.setState({ error: errors, floorsMissingModalIsOpen: true });

            }
            else
            {
                addPropertyContext.handleUpdateState({ floors: uploadedFloors });

                // eslint-disable-next-line react/prop-types
                this.props.history.push(`./organize-floorplans?propertyId=${addPropertyContext.state.propertyId}&buildingId=${addPropertyContext.state.buildingId}`);
            }
        }
    }

    /**
     * Call to save floors and move to next page
     */
    handleSaveFloors = () =>
    {
        const { uploadedFloors } = this.state;
        let floors = [];
        uploadedFloors.forEach((floor) =>
        {
            if (floor !== null)
            {
                floors.push(floor);
            }
        });

        this.handleOnNext(false);
    }

    handleCloseFloorErrorModal = () =>
    {
        this.setState({ floorsMissingModalIsOpen: false });
    }

    render ()
    {
        const trans = this.props.t;
        const addPropertyContext = this.context;
        const { floorNumDisabled, floorNum, uploadedFloors, floorNumError, error, floorsMissingModalIsOpen, centroid, validationErrors } = this.state;


        return (
            <Grid columns="equal" className="property-cover2">
                <Grid.Column width="5">
                    <Segment basic className="property-box">
                        <span className="close">
                            <Link to="/" />
                        </span>
                        <HeadingProperty onClick={addPropertyContext.state.buildingId && !addPropertyContext.state.isFloorPlansEdit && this.handleGoBack}
                            heading={addPropertyContext.state.isFloorPlansEdit ? trans("AddFloorPlan.Edit_Floor_Plans") : trans("AddFloorPlan.Add_Floor_Plans")}
                            info=
                                {
                                    <InfoHeadingPopup header={addPropertyContext.state.isFloorPlansEdit ? trans("AddFloorPlan.Edit_Floor_Plans") : trans("AddFloorPlan.Add_Floor_Plans")} showLearnMore={false}>
                                        {trans("AddFloorPlan.Fill_in_the_required_fields_below_to_add")}
                                    </InfoHeadingPopup>
                                } />
                        <AddPropertyLayout>
                            <p>
                                {trans("AddFloorPlan.To_add_your_floor_plans,_complete_the_fi")}
                            </p>
                            <p className="p">{trans("AddFloorPlan.Once_you_are_finished,_click_on_the_“Nex")}</p>
                            <div className="form-property">

                                {
                                    floorNumDisabled? <InitialDetailBox>
                                        <InputFormGroup label={trans("AddFloorPlan.Number_of_Floors")} detail={<strong>{floorNum}</strong>}
                                            info=
                                                {
                                                    <InfoHeadingPopup header={trans("AddFloorPlan.Number_of_Floors")} learnMore={trans("AddFloorPlan.Mapsted_Sales_")} contactUsMessageType="floorsMessage">
                                                        {trans("AddFloorPlan.Maximium_number_of_floors_is", { maxFloors: MAX_FLOORS })}. {trans("AddFloorPlan.If_you_need_to_add_more_than", { maxFloors: MAX_FLOORS })}
                                                    </InfoHeadingPopup>
                                                } />

                                    </InitialDetailBox>:<InputFormGroup label={trans("AddFloorPlan.Number_of_Floors")} placeholder={trans("AddFloorPlan.e_g__4")} error={floorNumError}
                                        value={floorNum} onChange={this.handleFloorNum} disabled={floorNumDisabled}
                                        extraLabel={trans("AddFloorPlan.Enter_the_total_number_of_floors_in_your")}
                                        info=
                                            {
                                                <InfoHeadingPopup header={trans("AddFloorPlan.Number_of_Floors")} learnMore={trans("AddFloorPlan.Mapsted_Sales_")} contactUsMessageType="floorsMessage" >
                                                    {trans("AddFloorPlan.Maximium_number_of_floors_is", { maxFloors: MAX_FLOORS })}. {trans("AddFloorPlan.If_you_need_to_add_more_than", { maxFloors: MAX_FLOORS })}
                                                </InfoHeadingPopup>
                                            } />
                                }

                                <InputFormGroup label={trans("AddFloorPlan.Upload_Floor_Plans")}
                                    extraLabel={trans("AddFloorPlan.Label_the_floor_levels_in_your_facility_")}>
                                    {
                                        [...Array(floorNum)].map((e, i) => (
                                            <BuildingFloorUploadRow key={i} floorNum={i} floorObject={uploadedFloors[i]} error={validationErrors[i]}
                                                onUpload={this.handleUploadedFloors} onRemove={this.handleDeleteFloor} />
                                        ))
                                    }
                                    {
                                        (floorNum < MAX_FLOORS) && (
                                            <Button className="trigger-holiday" floated="left" onClick={this.handleAddFloor} >
                                                <img src="/img/icon-plus-filled.svg" alt="" />
                                                <span className="text">{trans("AddFloorPlan.Add_floor")}</span>
                                            </Button>
                                        )
                                    }

                                </InputFormGroup>

                                <ErrorModal open={floorsMissingModalIsOpen} error={error}
                                    onContinue={this.handleOnNext}
                                    onBack={this.handleCloseFloorErrorModal} />
                            </div>
                            <div className="property-actions">
                                {/* <Button size="small" color="grey" content="Save as Draft" /> */}
                                {
                                    (Object.keys(validationErrors).length > 0) && (
                                        <ErrorLabel label={ERROR_MESSAGES.duplicateFloorName} />
                                    )
                                }
                                <Button disabled={uploadedFloors && uploadedFloors.length === 0} color="orange" content={trans("AddFloorPlan.Next")} onClick={this.handleSaveFloors} />
                            </div>
                        </AddPropertyLayout>
                    </Segment>
                </Grid.Column>
                <Grid.Column>
                    <MarkerMap markerCoordinates={(centroid) && centroid.coordinates}
                        editable={false} />
                </Grid.Column>
            </Grid>
        );
    }
}

export const AddFloorPlan = withTranslation()(withRouter(withConfig(AddFloorPlanImpl)));

