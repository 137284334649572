import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Overlay } from "ol";
import { getTopCoordinates, truncateText } from "../../../../_utils/mapUtils";
import { useZonesContext } from "../../../../store/ZonesContext";

import "./zoneNameMarkers.css";
import { useTranslation } from "react-i18next";

const ZoneNameMarkers = ({
    olMap, onSelect = () => 
    {} 
}) => 
{
    const { visibleZones, zonesStatus } = useZonesContext();

    if (zonesStatus === "ready" &&  visibleZones?.length === 0)
    {
        return <NoZonesMessage />;
    }

    return  <div>
        {
            visibleZones.map((zone) => <div key={zone.zoneGeofenceId}>
                <ZoneNameMarker
                    zone={zone}
                    olMap={olMap}
                    onClick={() => onSelect(zone.zoneGeofenceId)}
                />
            </div>)
        }
    </div>;
};

export default ZoneNameMarkers;

const ZoneNameMarker = ({ zone, olMap, onClick }) =>
{
    const popupRef = useRef();

    const { overlay } = usePopupOverlay({
        olMap,
        shape: zone?.boundary
    });

    useEffect(() =>
    {
        overlay.setElement(popupRef.current);
    }, [overlay, popupRef.current]);

    return (
        <div className="zoneNameMarkerPopup" ref={popupRef} onClick={onClick}>
            <div className="zoneNameMarkerPopup-wrapper">
                {truncateText(zone.label, 15)}
            </div>
        </div>
    );
};

const usePopupOverlay = ({ olMap, shape }) =>
{
    const overlay = useMemo(() => new Overlay({
        positioning: "bottom-center",
        stopEvent: false,
    }), [shape]); 

    const repositionPopup = useCallback((shape) =>
    {
        const position = getTopCoordinates(shape);
        if (position)
        {
            overlay.setPosition(position);
        }
    }, [overlay]);
    
    useEffect(() =>
    {
        if (olMap && overlay)
        {
            olMap.addOverlay(overlay);
            repositionPopup(shape);
        }
        return () =>
        {
            if (olMap && overlay)
            {
                olMap.removeOverlay(overlay);
            }
        };
    }, [olMap, overlay, shape]);

    return { overlay };
};

const NoZonesMessage = () => 
{
    const trans = useTranslation().t;

    return (<div className="noZoneMessage">{trans("Maintenance.No_Zones_Available")}</div>);
};