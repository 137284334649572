import React  from "react";
import { useTranslation } from "react-i18next";
import { Image } from "semantic-ui-react";
import { BRANDING_MAP_CONSTANTS } from "../../../_constants/constants";
import { ButtonBasic } from "../../common/ButtonIcon";
import { TextGroup } from "../../common/TextGroup";
import { SettingsHeading } from "../../settings/SettingsHeading";
import { DEFAULT_LANGUAGE_CODE } from "mapsted.maps/utils/map.constants";
import { DropdownForm } from "../../common/DropdownForm";
import { ZOOM_SELECT_MODES } from "../../branding/map/ZoomMap";

const ZoomAndFloorSelector = ({ centerWeb, centerMobile, zoomWeb, zoomMobile, settings, building, buildingSettings, isBuilding, handleChangeDefaultFloor, onChangeZoomMode, setIsBuilding, setZoomData,setRotationData,mapRotation, mapRotationMobile, }) =>
{
    const trans = useTranslation().t;   

    const hasZooms = zoomWeb || zoomMobile;          
    const floors = (building?.floors || []).sort((a, b) => a.floorNumber - b.floorNumber);

    let defaultFloor = floors.find((floor) => floor.floorId === buildingSettings?.defaultFloorId);

    if (!settings.defaultFloor && defaultFloor === undefined) 
    {
        defaultFloor = floors[0];
    }
    
    const floorOptions = floors.map((floor) => ({ key: floor._id, text: floor.shortName[DEFAULT_LANGUAGE_CODE], value: floor }));
    const changeZoomMode = (mode) =>
    {
        onChangeZoomMode(mode);
        setIsBuilding(isBuilding);
        setZoomData({ centerWeb, centerMobile, zoomWeb, zoomMobile });
        setRotationData({ mapRotation,mapRotationMobile });
    }; 
    const handleChange = (e, { value: floor }) =>
    {
        handleChangeDefaultFloor({ defaultFloorId: floor.floorId });
    };
      
    const renderFloorDropdown = () => (
        <TextGroup className="tgZoomLevel" flexible title={trans("Settings.Default_Floor_Level")}>
            <DropdownForm
                placeholder="Select floor"
                icon="caret down"
                selectOnNavigation={false}
                selectOnBlur={false}
                value={defaultFloor}
                options={floorOptions}
                onChange={handleChange}
            />
        </TextGroup>
    );
      

    return (
        <div className="settingsPageTabCover">
            <SettingsHeading heading={isBuilding ? trans("MapstedMapsModule.Building_Zoom_Level"):  trans("MapstedMapsModule.Property_Zoom_Level")}>
                {trans("MapstedMapsModule.Manually_adjust_the_zoom_level_for_your_")}
            </SettingsHeading>
            {(!hasZooms) && (
                <ButtonBasic
                    content={trans("MapstedMapsModule.Select")}
                    onClick={() => changeZoomMode(ZOOM_SELECT_MODES.DESKTOP)}
                />
            )}
            {(hasZooms) && (
                <>
                    <div className="zoomLevelItem">
                        <Image as="span" src="/img/icon-device-web2.svg" alt="" />
                        <p>{trans("MapstedMapsModule.Desktop_Zoom_")} {printZoom(zoomWeb)}</p>

                        {/* <div className="zoomSlideLevelBox">
                            <div className="zoomSlideLevel">
                                <span className="zoomSlidLevelBar" style={{ width: printZoom(zoomWeb) }}></span>
                            </div>
                        </div> */}
                        
                        <ButtonBasic content={trans("MapOverlaysUploadCsvModal.Change")} onClick={() => changeZoomMode(ZOOM_SELECT_MODES.DESKTOP)} />
                    </div>
                    <div className="zoomLevelItem">
                        <Image as="span" src="/img/icon-device-mobile2.svg" alt="" />
                        <p>{trans("MapstedMapsModule.Mobile_Zoom_")} {printZoom(zoomMobile)}</p>

                        {/* <div className="zoomSlideLevelBox">
                            <div className="zoomSlideLevel">
                                <span className="zoomSlidLevelBar" style={{ width: printZoom(zoomMobile) }}></span>
                            </div>
                        </div> */}

                        <ButtonBasic content={trans("MapOverlaysUploadCsvModal.Change")} onClick={() => changeZoomMode(ZOOM_SELECT_MODES.MOBILE)} />
                    </div>

                    {  isBuilding && renderFloorDropdown() }
                  
                </>
            )}
        </div>
    );

};

export default ZoomAndFloorSelector;


const printZoom = (zoom) =>
{
    const rangeSize = BRANDING_MAP_CONSTANTS.MAX_ZOOM - BRANDING_MAP_CONSTANTS.MIN_ZOOM;
    const percentage = (zoom - BRANDING_MAP_CONSTANTS.MIN_ZOOM) / rangeSize;
    return `${(100 * percentage).toFixed(0)}%`;
};
