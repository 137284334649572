import React, { useState, useMemo } from "react";
import { Segment, Sidebar, Portal } from "semantic-ui-react";
import "./ListViewAdvancedFilteration.css";
import { TextGroup } from "../../../common/TextGroup";
import { Button, Input } from "mapsted-components";
import { DropdownForm } from "../../../common/DropdownForm";
import { useListViewContext } from "../../../../store/ListViewContext";
import {
    getDropdownOptions,
    getExtractedValues,
    getLanguageDropdownOptions,
} from "../../../../_utils/listView";
import { ButtonIcon } from "../../../common/ButtonIcon";
import { LIST_VIEW_ADVANCED_FILTERATION_MODEL } from "../../../../_constants/listView";
import { useTranslation } from "react-i18next";

function ListViewAdvancedFilteration({ open, handleClose }) 
{
    const {
        languageCodes,
        rawEntities,
        advancedFilteration: advancedFilterationCtxValue,
        handleUpdateAdvancedFilteration,
        handleUpdateBasicFilteration,
        handleResetBasicFilteration,
        handleResetAdvancedFilteration,
    } = useListViewContext();
    const [advancedFilterationValues, setAdvancedFilterationValues] = useState({
        ...advancedFilterationCtxValue
    });
    const { categories, subCategories, keywords, onlineKeywords, entityTypes } =
    useMemo(
        () => getExtractedValues(rawEntities, advancedFilterationValues.languageCode),
        [advancedFilterationValues.languageCode, rawEntities]
    );
    const trans = useTranslation().t;


    function handleDropdownChange(e, { value, name }) 
    {
        setAdvancedFilterationValues((prev) => ({ ...prev, [name]: value }));
    }

    function handleTextChange(e) 
    {
        setAdvancedFilterationValues((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    }

    return (
        <div className="listview-n-advanced-filteration">
            <Portal open={open} closeOnEscape={false}>
                <Sidebar.Pushable
                    className="listview-n-advanced-filteration-sidebar-bx"
                    as={Segment}
                >
                    <Sidebar
                        direction="right"
                        dimmer
                        className="listview-n-advanced-filteration-sidebar"
                        animation="overlay"
                        icon="labeled"
                        inverted
                        vertical
                        visible={true}
                        width="thin"
                    >
                        <div className="sidebar-bx">
                            <div className="header-bx">
                                <h3 className="header-main">{trans("ListViewSubHeader.Filter")}</h3>
                                <Button
                                    onClick={(e) => 
                                    {
                                        e.stopPropagation();
                                        handleClose();
                                    }}
                                    className="ui button close"
                                ></Button>
                            </div>
                            <div className="main">
                                <TextGroup className={"header-txt"} title={trans("ListViewFilteration.Language")}>
                                    <DropdownForm
                                        className={"filter-dropdown"}
                                        value={advancedFilterationValues.languageCode}
                                        name="languageCode"
                                        placeholder={trans("ListViewFilteration.Select_Language")}
                                        options={getLanguageDropdownOptions(languageCodes)}
                                        onChange={handleDropdownChange}
                                    />
                                </TextGroup>
                                <TextGroup className={"header-txt"} title={trans("ListViewFilteration.Long_Name")}>
                                    <Input
                                        onChange={handleTextChange}
                                        className="input-bx"
                                        name="longName"
                                        value={advancedFilterationValues.longName}
                                        placeholder={trans("ListViewFilteration.Enter_Long_Name")}
                                    />
                                </TextGroup>
                                <TextGroup className={"header-txt"} title={trans("ListViewFilteration.Short_Name")}>
                                    <Input
                                        onChange={handleTextChange}
                                        className="input-bx"
                                        value={advancedFilterationValues.shortName}
                                        name="shortName"
                                        placeholder={trans("ListViewFilteration.Enter_Short_Name")}
                                    />
                                </TextGroup>
                                <TextGroup className={"header-txt"} title={trans("ListViewColumns.Entity_Id")}>
                                    <Input
                                        onChange={handleTextChange}
                                        className="input-bx"
                                        value={advancedFilterationValues.entityId === null ? "": advancedFilterationValues.entityId}
                                        type="number"
                                        placeholder={trans("ListViewFilteration.Enter_EntityId")}
                                        name="entityId"
                                    />
                                </TextGroup>
                                <TextGroup className={"header-txt"} title={trans("ListViewColumns.Phone_Number")}>
                                    <Input
                                        onChange={handleTextChange}
                                        className="input-bx"
                                        value={advancedFilterationValues.phoneNumber}
                                        placeholder={trans("ListViewFilteration.Enter_PhoneNumber")}
                                        name={"phoneNumber"}
                                    />
                                </TextGroup>
                                {/* <TextGroup className={"header-txt"} title={"Website"}>
                                    <Input
                                        onChange={handleTextChange}
                                        className="input-bx"
                                        placeholder="Enter Website link"
                                        name="website"
                                        value={advancedFilterationValues.website}
                                    />
                                </TextGroup> */}
                                <div className="dropdown-group-bx">
                                    <TextGroup className={"header-txt"} title={trans("ListViewColumns.Category")}>
                                        <DropdownForm
                                            className={"dropdown"}
                                            placeholder={trans("ListViewFilteration.Select_Category")}
                                            options={getDropdownOptions(categories)}
                                            onChange={handleDropdownChange}
                                            name="category"
                                            value={advancedFilterationValues.category}
                                        />
                                    </TextGroup>
                                    <TextGroup className={"header-txt"} title={trans("ListViewFilteration.Type")}>
                                        <DropdownForm
                                            name="type"
                                            className={"dropdown"}
                                            placeholder={trans("ListViewFilteration.Select_Type")}
                                            options={getDropdownOptions(entityTypes)}
                                            onChange={handleDropdownChange}
                                            value={advancedFilterationValues.type}
                                        />
                                    </TextGroup>
                                </div>
                                <TextGroup className={"header-txt"} title={trans("ListViewColumns.Sub_Category")}>
                                    <DropdownForm
                                        name="subCategory"
                                        fluid
                                        multiple
                                        selection
                                        search
                                        icon=""
                                        className={"dropdownCategory keywordsDropdown"}
                                        placeholder={trans("ListViewFilteration.Select_SubCategory")}
                                        options={getDropdownOptions(subCategories)}
                                        value={advancedFilterationValues.subCategory}
                                        noResultsMessage={getDropdownOptions(subCategories)?.length ? trans("ListViewFilteration.SubCategory_No_More_Results") : trans("ListViewFilteration.SubCategory_NoResults") }
                                        onChange={handleDropdownChange}
                                    />
                                </TextGroup>
                                <TextGroup className={"header-txt"} title={trans("ListViewColumns.Keywords")}>
                                    <DropdownForm
                                        fluid
                                        multiple
                                        selection
                                        search
                                        icon=""
                                        className={"dropdownCategory keywordsDropdown"}
                                        placeholder={trans("ListViewFilteration.Select_Keywords")}
                                        name="keywords"
                                        options={getDropdownOptions(keywords)}
                                        value={advancedFilterationValues.keywords}
                                        noResultsMessage={getDropdownOptions(keywords)?.length ? trans("ListViewFilteration.Keywords_No_More_Results") : trans("ListViewFilteration.Keywords_NoResults")}
                                        onChange={handleDropdownChange}
                                    />
                                </TextGroup>
                                <TextGroup className={"header-txt"} title={trans("ListViewColumns.Online_Keywords")}>
                                    <DropdownForm
                                        search
                                        selection
                                        fluid
                                        multiple
                                        className={"dropdownCategory keywordsDropdown"}
                                        onChange={handleDropdownChange}
                                        options={getDropdownOptions(onlineKeywords)}
                                        value={advancedFilterationValues.onlineKeywords}
                                        name="onlineKeywords"
                                        noResultsMessage={getDropdownOptions(onlineKeywords)?.length ? trans("ListViewFilteration.Online_Keywords_No_More_Results") : trans("ListViewFilteration.Online_Keywords_NoResults")}
                                        placeholder={trans("ListViewFilteration.Select_Online_Keywords")}
                                    />
                                </TextGroup>
                            </div>
                            <div className="action-btn-bx">
                                <ButtonIcon
                                    className={"ui primary button button-clear"}
                                    content={trans("ListViewFilteration.Clear_All")}
                                    onClick={() => 
                                    {
                                        setAdvancedFilterationValues({
                                            ...LIST_VIEW_ADVANCED_FILTERATION_MODEL,
                                        });
                                        handleResetAdvancedFilteration();
                                    }}
                                ></ButtonIcon>
                                <ButtonIcon
                                    className={"ui primary button button-apply"}
                                    content={trans("ListViewFilteration.Apply_Filter")}
                                    onClick={(e) => 
                                    {
                                        e.stopPropagation();
                                        handleResetBasicFilteration();
                                        handleUpdateAdvancedFilteration(advancedFilterationValues);
                                        handleUpdateBasicFilteration(
                                            {
                                                languageCode: advancedFilterationValues.languageCode
                                            }
                                        );
                                        handleClose();
                                    }}
                                ></ButtonIcon>
                            </div>
                        </div>
                    </Sidebar>
                    <Sidebar.Pusher dimmed={true}></Sidebar.Pusher>
                </Sidebar.Pushable>
            </Portal>
        </div>
    );
}

export default ListViewAdvancedFilteration;
