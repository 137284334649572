import React from "react";
import { Button, Modal, ModalActions, ModalContent, ModalHeader } from "semantic-ui-react";
import ImageUploadMainWrapper from "../ImageUploads/ImageUploadMainWrapper/ImageUploadMainWrapper";
import ImageCropper from "../ImageUploads/ImageCropper/ImageCropper";
import { useTranslation } from "react-i18next";
import { useImagesBaseContext } from "../contexts/ImageBaseContext";
import { useGmsContext } from "../../GmsContext";
import { useImageCopyContext } from "../contexts/ImageCopyContext";
import ImageCopyDetails from "./ImageCopyDetails/ImageCopyDetails";

function ImageCopyModal() 
{
    const gmsContext = useGmsContext();
    const imageCopyContext = useImageCopyContext();
    const {
        imageData,
        croppedImagesHistory,
        handleCloseCopyImageModal,
        cropperRef,
        handleCopyImage,
    } = imageCopyContext;
    const { selectedImageInfo } = useImagesBaseContext();
    const croppedImageHistory = croppedImagesHistory?.[selectedImageInfo?._id]; 
    const imageSrc = croppedImageHistory?.currentImage || imageData.dataUrl;
    const trans = useTranslation().t;
    

    return (
        <Modal             
            closeIcon={<Button onClick={gmsContext.handleCloseGms} className="close" />}
            className="single-image-upload-modal" open>
            <ModalHeader>Copy Image
            </ModalHeader>

            <ModalContent>
                <ImageUploadMainWrapper>
                    <ImageCropper
                        key={selectedImageInfo?._id}
                        ref={cropperRef}
                        aspectRatio={selectedImageInfo?.aspectRatio}
                        imageSrc={imageSrc}
                    />
                    <ImageCopyDetails />
                </ImageUploadMainWrapper>
            </ModalContent>
            <ModalActions>
                <Button onClick={handleCloseCopyImageModal} className="gms-outline-btn" color="orange" floated="right">
                    Cancel
                </Button>
                <Button disabled={!imageData.dataUrl || !imageData.name} onClick={handleCopyImage} color="orange" floated="right">
                    Copy
                </Button>
            </ModalActions>
        </Modal>
    
    );
}

export default ImageCopyModal;