import React from "react";
import { ModalBox } from "../../../common/ModalBox";
import { ButtonIcon } from "../../../common/ButtonIcon";
import { Button } from "semantic-ui-react";
import { DEFAULT_LANGUAGE_CODE } from "mapsted.maps/utils/map.constants";

const DeleteConfirmationModal = ({
    mapOverlay,
    onDeleteClick,
    deleteModalOpen,
    onDeleteIconClick,
    onClose,
    disabled = false,
    trans,
    showTrigger =true
}) => (
    <ModalBox className="confirmActionModal" 
        {
            ...(showTrigger ? (
                { trigger: <ButtonIcon icon="delete" onClick={onDeleteIconClick} /> }
            ) : {})
        }  
        disabled={disabled}
        header={trans("MapOverlaysActivitySideBar.Delete_Confirm_Modal_Header")}
        open={deleteModalOpen}
        onClose={onClose}
        actions={<Button
            color="orange"
            floated="right"
            content={trans("MapOverlaysActivitySideBar.Delete")}
            onClick={onDeleteClick}
        />}>
        <p className="p-modal">
            {trans("MapOverlaysActivitySideBar.Delete_Confirmation_Message", { mapOverlayName: mapOverlay?.name?.[DEFAULT_LANGUAGE_CODE] })}
        </p>
    </ModalBox>);

export default DeleteConfirmationModal;