import { DEFAULT_LANGUAGE_CODE } from "mapsted.maps/utils/map.constants";
import React from "react";
import { ButtonIcon } from "../../../../common/ButtonIcon";
import { Button } from "semantic-ui-react";
import { ModalBox } from "../../../../common/ModalBox";

import "./entityListStyle.css";
import { useTranslation } from "react-i18next";

const EntityList = ({ entities, onEdit, onFocus }) => (
    <div>
        {entities
            .filter((entity) => entity.entityId && entity.entityId !== -1)
            .map((entity) => <div className="entityListItem"  key={entity._id}>
                <p className="entityListItem-name">
                    {`${entity.entityLabel?.longName?.[DEFAULT_LANGUAGE_CODE] || ""} (#${entity.entityId})`}
                </p>
                <div className="entityListItem-actions">
                    <ButtonIcon icon="search-location" onClick={() => onFocus(entity._id)} />
                    <EntityEditConfirmationModal onClick={() => onEdit(entity._id)}/>
                </div>
            </div>)}
    </div>
);

export default EntityList;

const EntityEditConfirmationModal = ({ onClick }) => 
{
    const trans = useTranslation().t;
    return (
        <ModalBox className="confirmActionModal"
            trigger={<ButtonIcon icon="edit"/>}
            header={trans("Cancel_Confirmation_Modal.Confirm_Action")}
            actions={<Button color="orange" floated="right" content={trans("MapOverlaysActivitySideBar.Confirm")} onClick={onClick} />}>
            <p className="p-modal">
                {trans("MapOverlaysActivitySideBar.Edit_Conirmation_Message")}
            </p>
        </ModalBox>
    );
};