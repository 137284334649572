import React, {  createContext, useContext, useMemo, useState } from "react";
import { useGetImages } from "../hooks/image-management-server-hooks";
import { DEFAULT_IMAGES_SEARCH_AND_FILTERS } from "../../constant";
import { useImagesBaseContext } from "./ImageBaseContext";


const ImagesRecycleBinContext = createContext();

export const useImagesRecycleBinContext = () => useContext(ImagesRecycleBinContext);

function ImagesRecycleBinProvider ({ children })
{
    const imagesBaseContext = useImagesBaseContext();
    const [imagesSearchAndFilters, setImagesSearchAndFilters] = useState(
        DEFAULT_IMAGES_SEARCH_AND_FILTERS
    );

    const imagesQuery = useGetImages({
        imagesSearchAndFilters,
        toBeDeleted: true,
        enabled: imagesBaseContext.showImagesRecycleBin
    });

    const imagesData = useMemo(
        () => imagesQuery.data?.pages?.flatMap((page) => page.results) || [],
        [imagesQuery.data]
    );


    function handleChangeImageFilters(value) 
    {
        setImagesSearchAndFilters((prev) => ({
            ...prev,
            filters: { ...prev.filters, ...value },
        }));
    }

    function handleChangeImagesSearchValue(value) 
    {
        setImagesSearchAndFilters((prev) => ({ ...prev, searchValue: value }));
    }

    const contextValue = {
        imagesQuery,
        imagesData,
        imagesSearchAndFilters,
        handleChangeImageFilters,
        handleChangeImagesSearchValue
    };

    return <ImagesRecycleBinContext.Provider value={contextValue}>{children}</ImagesRecycleBinContext.Provider>;
}

export default ImagesRecycleBinProvider;