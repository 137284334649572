const { STYLES } = require("./defualtStyles");
const { EntityRefTypes } = require("./entityTypes");
const turf = require('@turf/turf');
const { FLOOR_PLAN_LAYERS_IDS, FLOOR_PLAN_GEO_REF_MOUSE_INTERACTIONS } = require("./map.constants");

/**
 * From Wgs84 to mercator array
 * @param {object} coord
 * @param {number} coord.lat
 * @param {number} coord.lng
 * @returns {Array<number>}
 */
exports.toMercator = ({ lat, lng }) =>
{
    const dLon = 0.017453292519943295 * lng;
    const x = 6378137.0 * dLon;

    const dLat = 0.017453292519943295 * lat;
    const y = 3189068.5 * Math.log((1.0 + Math.sin(dLat)) / (1.0 - Math.sin(dLat)));

    return [x, y];
};

/**
 * From Wgs84 array to mercator array
 * @param {Array<number>} coordinates
 */
exports.toMercatorFromArray = (coordinates) => exports.toMercator({ lng: coordinates[0], lat: coordinates[1], });

/**
 * @param {Array<number>} param0
 * @returns {Array<number>}
 */
exports.toGpsLocation = ([x, y]) =>
{
    const dx = x / 6378137.0;
    const t4 = dx * 57.295779513082323;
    const t5 = Math.floor((t4 + 180.0) / 360.0);
    const lng = t4 - (t5 * 360.0);

    const t7 = 1.5707963267948966 - (2.0 * Math.atan(Math.exp((-1.0 * y) / 6378137.0)));
    const lat = t7 * 57.295779513082323;

    return [lng, lat];
};

exports.mercatorCoordinateArrayToGpsLocations = (coordinates) =>
{
    let gpsArray = [];

    coordinates.forEach(coord =>
    {
        gpsArray.push(exports.toGpsLocation(coord));
    });

    return gpsArray;
};

exports.gpsCoordinateArrayToMercatorLocations = (coordinates) =>
{
    let mercatorArray = [];

    coordinates.forEach(coord =>
    {
        mercatorArray.push(exports.toMercatorFromArray(coord));
    });

    return mercatorArray;
};

/**
 * Gets default floor id using cms building.
 * @param {*} building
 * @returns {string | undefined} default floor Mongo _id
 */
exports.getDefaultFloorIdFromCMSBuilding = (building) =>
{
    let floorId = undefined;

    if (building)
    {
        if (Array.isArray(building.floors) && building.floors.length > 0)
        {
            const defaultFloorId = building.defaultFloorId || 0;

            let defaultFloor = building.floors.find((floor) => floor.floorId === defaultFloorId);

            if (defaultFloor)
            {
                floorId = defaultFloor._id;
            }
            else
            {
                floorId = building.floors[0]._id;
            }
        }
    }
    return floorId;
};

/**
 * Find defaultFloorId using building, or buildingInfo
 * @param {*} building - must contain either defaultFloorId or levels array
 * @returns
 */
exports.getDefaultFloorIdFromPublicBuilding = (building) =>
{
    // building null check
    if (building)
    {
        // return default floor id if available
        if (building.defaultFloorId !== undefined)
        {
            return building.defaultFloorId;
        }

        const levels = building.levels;

        // if default floorId does not exist, return floorId where floorNumber === 0 (ground floor)
        if (Array.isArray(levels))
        {
            let defaultLevel = building.levels.find(level => level.floorNumber === 0);

            // if floorId with floorNumber 0 does not exist, return first floor of the array
            if (defaultLevel !== undefined)
            {
                return defaultLevel.floorId;

            }
            else
            {
                return building.levels[0].floorId;
            }

        }
    }

    // if no default floorId or levels object, return -1
    return -1;

};

/**
 * V 4.5
 * Takes Heatmap data and normalizes count to be in a range of [0.1,1].
 * Each point should have count and position, if position is in object format it gets converted to array format
 *
 * NOTE: The idea for heatmap processing was to use a min max scaling to normalize from [0,1] but adjusted to normalize from [0.1, 1].
 *          This was done because of the need to plot heatmap values at 0, which OL does not do. I found 0.1 was the lowest I can go for OL Heatmap layer to assign a value to it.
 *          If it is possible to make OL Heatmap layer plot 0 values with the lowest color in the gradient then the processing can be switched back to normal min max processing.
 *
 * NOTE: Nav team requested to have set min and max values such that any value higher/lower than max/min would be capped to the max/min. This can be achieved by providing minCount and
 *          max count in the options.
 *
 * NOTE: this function mutates heatmapData.
 * @param {Array} heatmapData -  a list of points which have three elements: [Longitude, Latitude, Weight]
 * @param {Object} options
 * @param {Number} options.maxCount
 * @param {Number} options.minCount
 * @param {Number} options.shiftMultiplier
 * @returns {Array}
 */
exports.processHeatmapData = (heatmapData, options = {}) =>
{

    // weight must be in range [0,1] for open layers
    // max count set to 10% above max value to normalize (0 - 0.9) then shifted to (0.1 -> 1.0);

    const shiftMultiplier = (options && options.shiftMultiplier) || 0;


    // if heatmapData is empty send back empty array
    if (!Array.isArray(heatmapData) || heatmapData.length === 0)
    {
        return [];
    }

    // sort heatmap data by descending count
    heatmapData = heatmapData.sort((a, b) => exports.extractValueFromPosition(b) - exports.extractValueFromPosition(a));

    // get max and min counts
    const maxCount = getHeatmapDataMaxCount({ heatmapData, maxCount: options.maxCount, shiftMultiplier });

    // MIN COUNT
    const minCount = getHeatmapDataMinCount({ heatmapData, minCount: options.minCount });

    const normalier = maxCount - minCount;

    return heatmapData.map((point) =>
    {
        let pointCount = exports.extractValueFromPosition(point);

        if (pointCount !== undefined)
        {
            // Given min and max values from options, we cap any above or below to the max/min
            let adjustedCount = pointCount;

            if (adjustedCount > maxCount)
            {
                adjustedCount = maxCount;
            }
            else if (adjustedCount < minCount)
            {
                adjustedCount = minCount;
            }

            // normalize
            adjustedCount = adjustedCount - minCount;

            let normalizedCount = ((adjustedCount) / (normalier)) || 0;
            normalizedCount += shiftMultiplier;

            pointCount = normalizedCount;

            if (pointCount > 1)
            {
                pointCount = 1;
            }
        }

        return { position: exports.extractLocationFromPosition(point), count: pointCount };
    });
};


/**
 * extracts the count value from the three element point array
 * @param {*} heatmapPoint - [long, lat, value]
 * @returns count
 */
exports.extractValueFromPosition = (position) =>
{
    return position[2];
};

/**
 * extracts the longitude and latitude values from the three element point array
 * @param {*} position - [long, lat, value]
 * @returns  [long, lat]
 */
exports.extractLocationFromPosition = (position) =>
{
    return [position[0], position[1]];
};

/**
 *
 * @param {*} type - of type ENTITY_REF_TYPES
 * @param {*} theme - of type STYLE_TYPES
 * @returns
 */
exports.getMapStyle = (type, theme) =>
{

    if (EntityRefTypes.PROPERTY === type)
    {
        return STYLES[theme].property;
    }
    else
    {
        return STYLES[theme].building;
    }

};

/**
 *
 * @param {*} type - of type ENTITY_REF_TYPES
 * @param {*} theme - of type STYLE_TYPES
 * @returns
 */
exports.getMapStyle = (type, theme) =>
{
    if (EntityRefTypes.PROPERTY === type)
    {
        return STYLES[theme].property;
    }
    else
    {
        return STYLES[theme].building;
    }

};

/**
 *
 * @param {*} olPolygon - respresent the shape of the property
 * @param {*} options - set of choice between latlng or mercator values
 * @returns - co-ordinates in [lng,lat] or mercator based on options params
 */
exports.calculateCentroidOfOlPolygon = (olPolygon, options = { mercator: false }) =>
{
    if (!olPolygon)
    {
        return;
    }
    const olCoordinates = olPolygon.getCoordinates();
    let centroid;
    if (options.mercator)
    {
        // Calculate centroid in Mercator coordinates
        let turfBoundaryPolygon = turf.polygon([olCoordinates[0]]);
        centroid = turf.centroid(turfBoundaryPolygon);
    } else
    {
        // Calculate centroid in LatLng coordinates
        let coOrdinates = this.mercatorCoordinateArrayToGpsLocations(olCoordinates[0]);
        let turfBoundaryPolygon = turf.polygon([coOrdinates]);
        centroid = turf.centroid(turfBoundaryPolygon);
    }

    return centroid.geometry.coordinates;
};

/**
 * Calculate the map rotation angle using a boundary polygon.
 *
 * @param {*} trajectoryMapLayer - Map layer containing trajectory information.
 * @param {*} boundaryPolygon - Shape of the Property
 * @param {*} options - Set of option wherther to return latlng or mercator values
 * @returns {number} - Calculated rotation angle in radians.
 */
exports.getOlMapRotationAngleUsingBoundaryPolygon = (trajectoryMapLayer, boundaryPolygon, cordsOption) =>
{
    let trajectoryStartPoint;
    if (trajectoryMapLayer)
    {
        trajectoryStartPoint = Object.keys(trajectoryMapLayer.layers[0].values_.source.uidIndex_)[0];
    }
    let startPosition;
    let angle = 0;

    if (trajectoryStartPoint)
    {
        startPosition = trajectoryMapLayer.layers[0].values_.source.uidIndex_[trajectoryStartPoint].values_.position;
        try
        {

            let mapCentroid = this.calculateCentroidOfOlPolygon(boundaryPolygon, cordsOption.options);
            if (mapCentroid)
            {
                const pointBelowCentroid = [mapCentroid[0], mapCentroid[1] - 1];

                // Calculate the angle in degrees
                const angleDegrees = turf.angle(startPosition, mapCentroid, pointBelowCentroid, { explementary: true });
                angle = angleDegrees * (Math.PI / 180);
            }
        } catch (error)
        {
            console.log(error);
            console.error('Cannot convert into radians:', error);
        }
    }
    return angle;

};

exports.getTextFeatureIdWithEntityId = (entityId) =>
{
    return `${entityId}text`;
};

/**
 *
 * @param {*} param0.heatmapData
 * @param {*} param0.maxCount
 * @param {*} param0
 * @returns
 */
const getHeatmapDataMaxCount = ({ heatmapData, maxCount, shiftMultiplier }) =>
{
    let maxCountResponse = maxCount;

    if (maxCount === undefined)
    {
        maxCountResponse = exports.extractValueFromPosition(heatmapData[0]);
    }

    return maxCountResponse + (maxCountResponse * shiftMultiplier);
};

const getHeatmapDataMinCount = ({ heatmapData, minCount }) =>
{
    if (minCount !== undefined)
    {
        return minCount;
    }

    let minCountResponse = exports.extractValueFromPosition(heatmapData[heatmapData.length - 1]);

    if (minCountResponse > 0)
    {
        minCountResponse = 0;
    }

    return minCountResponse;
};


/**====GEO-REFERENCING-FLOOR-PLAN====*/


/**
 *
 * @param {Feature} feature
 * @param {number} index
 * @returns
 */
exports.setIndex = (feature, index) => feature.set("index", index);

/**
 * Marks the feature as geo-referenced.
 *
 * @param {Feature} feature - The feature to mark.
 * @return {void} This function does not return anything.
 */
exports.markAsGeoRef = (feature) => feature.set("isGeoRef", true);

/**
 * Marks the feature as an image feature.
 *
 * @param {Feature} feature - The feature to mark.
 * @return {void} This function does not return anything.
 */
exports.markAsImageFeature = (feature) => feature.set("isPixelCoordinate", true);

/**
 * Unmarks the feature as an image feature.
 *
 * @param {Feature} feature - The feature to unmark.
 * @return {void} This function does not return anything.
 */
exports.unMarkAsImageFeature = (feature) => feature.unset("isPixelCoordinate");

/**
 * Unmarks the feature as geo-referenced.
 *
 * @param {Feature} feature - The feature to unmark.
 * @return {void} This function does not return anything.
 */
exports.unMarkAsGeoRef = (feature) => feature.unset("isGeoRef");

/**
 * Checks if the feature is transformed.
 *
 * @param {Feature} feature - The feature to check.
 * @return {boolean} The result of the check.
 */
exports.isTransformed = (feature) => feature.get("isTransformed");

/**
 * Checks if the feature is an image feature.
 *
 * @param {Feature} feature - The feature to check.
 * @return {boolean} The result of the check.
 */
exports.isImageFeature = (feature) => feature.get("isPixelCoordinate");

/**
 * Checks if the feature is geo-referenced.
 *
 * @param {Feature} feature - The feature to check.
 * @return {boolean} The result of the check.
 */
exports.isGeoRef = (feature) => feature.get("isGeoRef");

/**
 * Gets the index of the feature.
 *
 * @param {Feature} feature - The feature to get the index of.
 * @return {number|undefined} The index of the feature, or undefined if the index is not set.
 */
exports.getIndexOfFeature = (feature) => feature.get("index");


exports.markAsTransformed = (feature) => feature.set("isTransformed", true);

exports.isFloorPlanGeoReferenceLayer = (layer) =>
{
    const floorPlanGeoRefLayers = [FLOOR_PLAN_LAYERS_IDS.FLOOR_PLAN_GEO_REF_IMAGE_LAYER, FLOOR_PLAN_LAYERS_IDS.FLOOR_PLAN_VECTOR_LAYER];
    return !!layer && floorPlanGeoRefLayers.includes(layer.get("id"));
};

exports.isFloorPlanInteraction = (interaction) =>
{
    if (!interaction) return;
    return Object.values(FLOOR_PLAN_GEO_REF_MOUSE_INTERACTIONS).includes(interaction.get("id"));
};

/**
 * Retrieves the index of the geographic reference feature in the vector source.
 *
 * @param {Object} vectorSource - The vector source to retrieve the features from.
 * @return {number} The index of the geographic reference feature plus one.
 */
exports.getFeatureGeoRefIndex = (vectorSource) =>
{
    const onlyGeoRefFeatures = vectorSource.getFeatures().filter((f) => this.isGeoRef(f));

    return onlyGeoRefFeatures.length + 1;

};
