import React from "react";
import { SortableContainer } from "react-sortable-hoc";
import { Button } from "semantic-ui-react";
import { ModalLayout } from "../../../popups/ModalLayout";

function ReorderModal({ onClose, trans, showreorderModal, children, handleOnSortEnd, submitHandler }) 
{
    const SortableList = SortableContainer(({ children }) => <div className="mapOverlaysContainer-modal">{children}</div>);
    const Heading = () => (
        <span>
            {trans("reorder overlay modal.heading")}
        </span>
    );

    return (
        <ModalLayout scrolling
            open={showreorderModal}
            onClose={onClose}
            className="editCategoryModal"
            heading={<Heading />}
            actions={<Button primary color="orange" onClick={submitHandler} content={trans("reorder overlay modal.save")} />}
        >
            <SortableList onSortEnd={handleOnSortEnd} helperClass="draggable-item modal-overlayList" lockAxis="y" lockToContainerEdges>{children}</SortableList>
        </ModalLayout>
    );
}

export default ReorderModal;