import React from "react";
import { Button } from "semantic-ui-react";
import { useIconsManagementContext } from "../IconsManagementContext";
import "./IconsManagementFooter.css";

function IconsManagementFooter() 
{
    const iconsManagementContext = useIconsManagementContext();
    return (
        <div className="icons-management-footer">
            <Button disabled={!iconsManagementContext.selectedIconInfo} onClick={iconsManagementContext.handleAddIcon}  color="orange"  >
            Add
            </Button>
        </div>
    );
}

export default IconsManagementFooter;