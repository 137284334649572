import React, { useContext, useEffect, useState } from "react";
import { CategoryContext } from "../../../store/CategoryProvider";
import { DEFAULT_LANGUAGE_CODE } from "../../../_constants/constants";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./ManageCategory.css";
import { Icon, Popup } from "semantic-ui-react";
import { ButtonIcon } from "../../common/ButtonIcon";
import { ExportGroups, ExportSuccessModal } from "../../modalsPopups/exportGroups/ExportGroups";
import { CategoryCSVModal } from "../categoriesPortal/CategoryCSVModal";
import CategoryGroups from "./categoryGroups/CategoryGroups";
import BrandingContext from "../../../store/BrandingContext";
import MainSection from "./mainSection/MainSection";
import RearrangeCategory from "./rearrangeCategory/RearrangeCategory";

export const TABS = {
    CATEGORY: "category",
    KEYWORD: "keyword"
};

const ManageCategory = () => 
{
    const trans = useTranslation().t;
    const categoryContext = useContext(CategoryContext);
    const { state: { propertyId, properties } } = useContext(BrandingContext);
    const [tab, setTab] = useState(TABS.CATEGORY);
    const [lang, setLang] = useState(DEFAULT_LANGUAGE_CODE);
    const [rearrage, setRearrage] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [openCSVModal, setOpenCSVModal] = useState(false);
    const [openExportModal, setOpenExportModal] = useState(false);
    const [openExportSuccessMessageModal, setOpenExportSuccessMessageModal] = useState(false);

    const { categoryGroups, getIconInfo, categoryFlatMap, categoriesIsLoading } = categoryContext;
    const parent = properties?.[propertyId];

    const today = new Date();

    useEffect(() => 
    {
        if (categoryGroups.length > 0) 
        {
            // check if current selectedGroup exists in the updated categorylist
            let isSelectedGroupValid = false;

            if (selectedGroup) 
            {
                const selectedGroupIndex = categoryGroups.findIndex((group) => group._id === selectedGroup._id);

                if (selectedGroupIndex !== -1) 
                {
                    isSelectedGroupValid = true;
                }
            }

            if (!isSelectedGroupValid) 
            {
                setSelectedGroup({ ...categoryGroups[0] });
            }
        }

        return () => setLang(DEFAULT_LANGUAGE_CODE);
    }, [categoryGroups, propertyId]);

    function shouldDisableRearrange(groups) 
    {
        if (tab === TABS.KEYWORD) return true;

        if (!Array.isArray(groups) || groups.length > 1)
        {
            return false;
        }
        else 
        {
            for (const category of groups)
            {
                if (category.subCategories.length > 1)
                {
                    return false;
                }
                else 
                {
                    for (const subCategoryId of category.subCategories)
                    {
                        if (categoryFlatMap[subCategoryId].subCategories.length > 1)
                        {
                            return false;
                        }
                    }
                }
            }
        }
        return true;
    }


    if (rearrage === true) 
    {
        return <RearrangeCategory setRearrage={setRearrage} lang={lang} />;
    }
    return <>
        { propertyId && !categoriesIsLoading && <>
            <div className="manageCategory-header">
                <div className="manageCategory-leftHeader">
                    <div className="manageCategory-leftHeaderTop">
                        <Link to="/branding">
                            <Icon name="arrow left" className="manageCategory-back" />
                        </Link>
                        <span className="title">{trans("manageCategory.title")}</span>
                        <Popup
                            content={trans("manageCategory.help")}
                            trigger={<img className="help" src="/img/icon-question-circle.svg" />}
                        />
                    </div>
                    <div className="manageCategory-leftHeaderBottom">
                        {trans("manageCategory.subTitle")}
                    </div>
                </div>
                <div className="manageCategory-rightHeader">
                    <ButtonIcon icon="rearrange" disabled={shouldDisableRearrange(categoryGroups)} content={trans("manageCategory.Rearrange")} onClick={() => setRearrage(true)} />
                    <ButtonIcon icon="import" disabled={!categoryGroups?.length} content={trans("common.Export")} onClick={() => setOpenExportModal(true)} />
                    <ButtonIcon icon="upload-csv" disabled={!categoryGroups?.length} content={trans("ListView.Upload_File")} onClick={() => setOpenCSVModal(true)} />
                </div>
            </div>
            <div className="manageCategoryContent">
                <CategoryGroups
                    lang={lang}
                    groups={categoryGroups}
                    selectedGroup={selectedGroup}
                    setSelectedGroup={setSelectedGroup}
                    parent={parent}
                    getIconInfo={getIconInfo}
                />
                <div className="manageCategory-Divider">
                    <div className="vertical-line"></div>
                    <div className="vertical-line"></div>
                </div>
                {categoryFlatMap?.[selectedGroup?._id] && <MainSection className="manageCategory-main" selectedGroup={categoryFlatMap?.[selectedGroup?._id]} lang={lang} setLang={setLang} tab={tab} setTab={setTab} />}

            </div>
            <footer>
                <p className="manageCategoryFooter">{trans("Footer.Copyright_©_2014-{{fullYear}}_Mapsted_Co", { fullYear: today.getFullYear() })}</p>
            </footer>
            <CategoryCSVModal
                isOpen={openCSVModal}
                close={() => setOpenCSVModal(false)}
            />
            <ExportGroups
                openModal={openExportModal}
                onCloseModal={() => setOpenExportModal(false)}
                onSuccess={() => 
                {
                    setOpenExportModal(false);
                    setOpenExportSuccessMessageModal(true);
                }} />
            <ExportSuccessModal
                successMessageModalOpen={openExportSuccessMessageModal}
                onSuccessMessageModalClose={() => setOpenExportSuccessMessageModal(false)} />


        </>
        }
    </>;
};

export default ManageCategory;