import React, { useEffect } from "react";
import "./IconsViewSection.css";
import { useIconsManagementContext } from "../../IconsManagementContext";
import IconComponent from "./IconComponent/IconComponent";
import InfiniteScrollGrid from "../../../InfiniteScrollGrid/InfiniteScrollGrid";

function IconsViewSection() 
{
    const { icons, iconsQuery } = useIconsManagementContext();

    return (
        <InfiniteScrollGrid
            isSuccess={iconsQuery.isSuccess}
            className= "icons-view-infinite-scroller"
            isFetchingNextPage={iconsQuery.isFetchingNextPage}
            fetchNextPage={iconsQuery.fetchNextPage}
            isLoading={iconsQuery.isLoading || iconsQuery.isError || iconsQuery.isIdle ||  iconsQuery.isFetchingNextPage}
            cellToRender={(item) => <IconComponent key={item._id} iconInfo={item}></IconComponent>}
            data={icons}
            noItemsFallback={<div>No Icons</div>}
            skeletonCount={50}
            hasNextPage={iconsQuery.hasNextPage}
        ></InfiniteScrollGrid>
    );
}

export default IconsViewSection;
