import React from "react";
import { BuildingDetailItem } from "../PropertyOverview/BuildingDetailItem";
import { InputFormGroup } from "./InputFormGroup";
import { Checkbox, Button, Icon } from "semantic-ui-react";
import { ErrorLabel } from "./ErrorLabel";
import { isPeriodOpen24Hours } from "../../_utils/utils";
import { weekdays } from "moment";
import { deepValue } from "mapsted.utils/objects";
import { getDefaultPeriod, getOpen24HoursPeriod } from "../../_utils/utils";
import { useTranslation } from "react-i18next";
import { CLOSING_TIME_MINS, OPENING_TIME_MINS } from "../../_constants/constants";


export const ViewWeekdayHours = ({ title, periods, showStatus = true }) =>
{
    const isOpen = React.useMemo(() =>
    {
        if (Array.isArray(periods))
        {
            return (periods.length > 0);
        }
        else
        {
            return false;
        }

    }, [periods]);

    const isOpen24Hours = React.useMemo(() =>
    {
        if (Array.isArray(periods))
        {
            return isPeriodOpen24Hours(periods);
        }
        else
        {
            return false;
        }
    }, [periods]);

    const renderViewHours = React.useCallback(() =>
    {
        if (isOpen)
        {
            let content = [];
            periods.forEach((period, i) =>
            {
                content.push(
                    <OpeningHourBoxes
                        key={title + i}
                        timeFrom={period.open.time}
                        timeTo={period.close.time}
                    />
                );
            });

            return content;
        }
        else
        {

            return (<div />);
        }
    }, [isOpen, periods, title]);

    return (

        <div className="hoursEditWrap" key={title}>

            <BuildingDetailItemHours
                key={title}
                title={title}
                isOpen={isOpen}
                isOpen24Hours={isOpen24Hours}
                showStatus={showStatus}>

                {
                    (isOpen && !isOpen24Hours) && (
                        renderViewHours()
                    )
                }

            </BuildingDetailItemHours>

        </div>
    );
};

export const EditWeekdayHours = ({ title, day, periods, showStatus = true, validationErrors = [], onChange, onAddPeriod, onRemovePeriod }) =>
{
    const isOpen = React.useMemo(() =>
    {
        if (Array.isArray(periods))
        {
            return (periods.length > 0);
        }
        else
        {
            return false;
        }

    }, [periods]);

    const isOpen24Hours = React.useMemo(() =>
    {
        if (Array.isArray(periods))
        {
            return isPeriodOpen24Hours(periods);
        }
        else
        {
            return false;
        }
    }, [periods]);


    const renderEditHours = React.useCallback(() =>
    {
        if (isOpen)
        {
            let content = [];
            periods.forEach((period, i) =>
            {
                content.push(
                    <OpeningHourBoxes
                        key={day + i}
                        error={Array.isArray(validationErrors) && validationErrors[i]}
                        timeFrom={
                            <InputFormGroup
                                type="time"
                                // disabled={openingHoursUpdate[day].isOpen24Hours}
                                // key={day + keyToggle + "open"}
                                key={day + i + "open edit"}
                                timeValue={period.open.time}
                                onChange={(e, { value }) => onChange(day, i, value, "open")}
                            />
                        }
                        timeTo={
                            <InputFormGroup
                                type="time"
                                // disabled={openingHoursUpdate[day].isOpen24Hours}
                                // key={day + keyToggle + "open"}
                                key={day + i + "close"}
                                timeValue={period.close.time}
                                onChange={(e, { value }) => onChange(day, i, value, "close")}
                            />
                        }
                        onRemove={() => onRemovePeriod(day, i)}
                    />
                );
            });

            return content;
        }
        else
        {

            return (<div />);
        }


    }, [isOpen, periods, validationErrors, day, onChange, onRemovePeriod]);

    const trans = useTranslation().t;

    return (
        <div className="hoursEditWrap" key={day}>

            <BuildingDetailItemHours
                key={day}
                title={title}
                isOpen={isOpen}
                isOpen24Hours={isOpen24Hours}
                onSetBusinessHours={() => onAddPeriod(day)}
                showStatus={showStatus}>

                {(isOpen && !isOpen24Hours) && (
                    renderEditHours()
                )}

            </BuildingDetailItemHours>

            {
                (Array.isArray(validationErrors) && validationErrors.length > 0) && (
                    <ErrorLabel large label={trans("OpeningHours.End_time_invalid_")} />
                )
            }

            <div className="hoursBottom">

                {
                    (isOpen && !isOpen24Hours) && (
                        <Button className="btnMore" onClick={() => onAddPeriod(day)}>
                            <img src="/img/icon-plus-filled.svg" alt="" />
                            {trans("OpeningHours.Add_more_hours")}
                        </Button>

                    )
                }

                <Checkbox label={trans("OpeningHours.Open_24_hours")} checked={isOpen24Hours} onChange={(e, data) => onChange(day, 0, data.checked, "isOpen24Hours")} />

            </div>
        </div>
    );
};

export const BuildingDetailItemHours = ({ title, isOpen = true, showStatus = true, className, children, onSetBusinessHours, isOpen24Hours }) =>
{
    const trans = useTranslation().t;
    const renderSetBuisnessHours = React.useCallback(() =>
    {
        if (onSetBusinessHours)
        {
            return (
                <Button className="setHours" content={trans("OpeningHours.Set_business_hours")} onClick={onSetBusinessHours} />
            );
        }
        else
        {
            return (
                <div className="setHours"> {trans("OpeningHours.Set_business_hours_")}</div>
            );
        }

    }, [onSetBusinessHours]);

    if (isOpen24Hours)
    {
        return (
            <BuildingDetailItem className={`bdi-hours${className ? " " + className : ""}`} title={title}>
                {trans("OpeningHours.Open_24_hours")}

                {
                    (showStatus) && (
                        <span title={trans("OpeningHours.Open")} className="statusHours statusOpen">{trans("OpeningHours.Open")}</span>
                    )
                }
                {children}
            </BuildingDetailItem>
        );
    }
    else if (isOpen)
    {
        return (
            <BuildingDetailItem className={`bdi-hours${className ? " " + className : ""}`} title={title}>

                {children}

                {
                    (showStatus) && (
                        <span title={ trans("OpeningHours.Open") } className="statusHours statusOpen">{trans("OpeningHours.Open")}</span>
                    )
                }

            </BuildingDetailItem>
        );
    }
    else
    {
        return (
            <BuildingDetailItem className="bdi-hours" title={title}>
                {renderSetBuisnessHours()}
                {
                    (showStatus) && (
                        <span className="statusHours">{trans("OpeningHours.Closed")}</span>
                    )
                }
                {children}
            </BuildingDetailItem>
        );
    }
};

export const OpeningHourBoxes = ({ timeFrom, timeTo, error, children, onRemove }) => (
    <>
        <div className={error ? "error" : ""} style={{ width: "86%" }}>
            {(timeFrom) && (
                <div className="detail">{timeFrom}</div>
            )}

            <span className="seperateNew"></span>

            {(timeTo) && (
                <div className="detail">{timeTo}</div>
            )}

            {(onRemove) && (
                <Button className="closeHours" onClick={onRemove}>
                    <Icon name="times circle" />
                </Button>
            )}

        </div>

        {children}
    </>
);

/**
 *
 * @param {*} param0
 * @param {Object} param0.openingHours
 * @param {Object} param0.validationErrors,
 * @param {Number} param0.day
 * @param {Boolean} param0.linked
 *
 * @returns {Object} {validationErrors, openingHours}
 */
export const addPeriod = ({ openingHours, validationErrors = {}, day, linked = false }) =>
{
    let newOpeningHours = { ...openingHours };
    let newValidation = { ...validationErrors };

    if (linked)
    {
        weekdays().forEach((weekday, i) =>
        {

            let periods = JSON.parse(JSON.stringify(deepValue(newOpeningHours, `partialOpeningHours.${i}`, [])));

            if (Array.isArray(periods))
            {
                periods.push(getDefaultPeriod(i, OPENING_TIME_MINS, CLOSING_TIME_MINS));
            }
            else
            {
                periods = [getDefaultPeriod(i, OPENING_TIME_MINS, CLOSING_TIME_MINS)];
            }

            newOpeningHours.partialOpeningHours[i] = periods;

            newValidation = {};
        });

    }
    else
    {
        let periods = JSON.parse(JSON.stringify(deepValue(newOpeningHours, `partialOpeningHours.${day}`, [])));

        if (Array.isArray(periods))
        {
            periods.push(getDefaultPeriod(day, OPENING_TIME_MINS, CLOSING_TIME_MINS));
        }
        else
        {
            periods = [getDefaultPeriod(day, OPENING_TIME_MINS, CLOSING_TIME_MINS)];
        }

        newOpeningHours.partialOpeningHours[day] = periods;

        delete newValidation[day];
    }

    return { validationErrors: newValidation, openingHours: newOpeningHours };
};


/**
 * @param {Object} param0
 * @param {Object} param0.openingHours
 * @param {Object} param0.validationErrors,
 * @param {Number} param0.day
 * @param {Boolean} param0.linked
 * @param {Number} param0.periodRemovedIndex
 *
 * @returns {Object} {validationErrors, openingHours}
 */
export const removePeriod = ({ openingHours, validationErrors = {}, day, linked = false, periodIndex }) =>
{
    let newOpeningHours = { ...openingHours };
    let newValidation = { ...validationErrors };

    if (linked)
    {
        weekdays().forEach((weekday, i) =>
        {

            let periods = JSON.parse(JSON.stringify(deepValue(newOpeningHours, `partialOpeningHours.${i}`, [])));

            if (Array.isArray(periods))
            {
                periods.splice(periodIndex, 1);
            }
            newOpeningHours.partialOpeningHours[i] = periods;
        });

        newValidation = {};
    }
    else
    {
        let periods = JSON.parse(JSON.stringify(deepValue(newOpeningHours, `partialOpeningHours.${day}`, [])));

        if (Array.isArray(periods))
        {
            periods.splice(periodIndex, 1);
        }

        newOpeningHours.partialOpeningHours[day] = periods;

        delete newValidation[day];

    }

    return { validationErrors: newValidation, openingHours: newOpeningHours };
};

/**
 *
 * @param {Object} param0
 * @param {Object} param0.openingHours
 * @param {Object} param0.validationErrors,
 * @param {Number} param0.day
 * @param {Boolean} param0.linked
 * @param {Number} param0.periodIndex
 * @param {*} param0.value
 * @param {*} param0.prop
 *
 * @returns {Object} {validationErrors, openingHours}
 */
export const updateOpeningHours = ({ openingHours, validationErrors = {}, day, linked = false, periodIndex, value, prop }) =>
{
    let newOpeningHours = JSON.parse(JSON.stringify(openingHours));
    let newValidation = { ...validationErrors };

    if (!newOpeningHours.partialOpeningHours)
    {
        newOpeningHours.partialOpeningHours = [];
    }

    if (linked)
    {
        weekdays().forEach((weekday, i) =>
        {

            if (prop === "open" || prop === "close")
            {
                newOpeningHours.partialOpeningHours[i][periodIndex][prop].time = value;
            }
            else if (prop === "isOpen24Hours")
            {
                if (value === true)
                {
                    newOpeningHours.partialOpeningHours[i] = [getOpen24HoursPeriod(i)];
                }
                else
                {
                    newOpeningHours.partialOpeningHours[i] = [];
                }
            }

        });

        newValidation = {};
    }
    else
    {
        if (prop === "open" || prop === "close")
        {
            newOpeningHours.partialOpeningHours[day][periodIndex][prop].time = value;
        }
        else if (prop === "isOpen24Hours")
        {
            if (value === true)
            {
                newOpeningHours.partialOpeningHours[day] = [getOpen24HoursPeriod(day)];
            }
            else
            {
                newOpeningHours.partialOpeningHours[day] = [];
            }
        }

        delete newValidation[day];
    }

    return { validationErrors: newValidation, openingHours: newOpeningHours };
};
