import { useQueryClient } from "react-query";
import React, { createContext, useContext, useRef, useState } from "react";
import serverApi from "../../../../../_api/server.api";
import {  useCroppedImagesHistory } from "../hooks/useCroppedImageHistory";
import { DEFAULT_HIDDEN_MODALS, IMAGE_ACTION_MODES } from "../../constant";
import { convertImgUrlToDataURI, userHasImageEditDeleteAccess } from "../../utils";
import { filerUrl } from "../../../../../_utils/utils";
import {  useGmsContext } from "../../GmsContext";
import { useImagesBaseContext } from "./ImageBaseContext";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

/**
 * @typedef {Object} ImageData
 * @property {string} name - Name of the image
 * @property {string} dataUrl - Data URL of the image
 */

/**
 * Context for managing image editing functionality
 * @type {React.Context<ImageEditContextValue>}
 */
const ImageEditContext = createContext();

/**
 * Context for managing image editing functionality
 * @type {React.Context<ImageEditContextValue>}
 */
export const useImageEditContext = () => useContext(ImageEditContext);

/**
 * Custom hook to access the Image Edit context
 * @returns {ImageEditContextValue} The image edit context value
 * @throws {Error} When used outside of ImageEditProvider
 */
export function ImageEditProvider({ children, onEditImage }) 
{
    const DEFAULT_IMAGE_DATA = {
        name: "",
        dataUrl: "",
    };
    const cropperRef = useRef(null);
    const gmsContext = useGmsContext();
    const { loadingPool } = gmsContext;
    const imagesBaseContext = useImagesBaseContext();
    const [imageData, setImageData] = useState(DEFAULT_IMAGE_DATA);
    const queryClient = useQueryClient();
    const trans = useTranslation().t;
    const [imageEditorStatus, setImageEditorStatus] = useState("idle");
    const { croppedImagesHistory, ...croppedImagesHistoryRest } = useCroppedImagesHistory();

    async function handleReplaceImage() 
    {
        const loadingId = loadingPool.add();
        try 
        {
            const currentDataURL =
                croppedImagesHistory?.[imagesBaseContext.selectedImageInfo?._id]?.currentImage;
            const blob = await (await fetch(currentDataURL)).blob();
            const filerId = await serverApi.filerUpload(blob);
            const payload = {
                name: imageData.name,
                filerId,
            };

            await serverApi.replaceImage(imagesBaseContext.selectedImageInfo?._id, payload);
            await imagesBaseContext.imagesQuery.refetch();

            if (onEditImage) await onEditImage();        
            toast.success(trans("gms.image_edited"));
            handleCloseEditModal();
        }
        catch (err) 
        {
            console.log(err);
            toast.error(err.message || trans("gms.image_edit_err"));
            throw err;
        }
        finally 
        {
            loadingPool.remove(loadingId);
        }
    }

    async function handleUpdateImage() 
    {
        const loadingId = loadingPool.add();
        try 
        {
            await serverApi.updateImageReference(imagesBaseContext.selectedImageInfo?._id, {
                name: imageData.name,
            });
            await imagesBaseContext.imagesQuery.refetch();
            toast.success(trans("gms.image_edited"));
            handleCloseEditModal();
        }
        catch (err) 
        {
            console.log(err);
            toast.error(err.message || trans("gms.image_edit_err"));
            throw err;
        }
        finally 
        {
            loadingPool.remove(loadingId);
        }
    }
    
    function handleEditImage() 
    {
        const isCropped =
            croppedImagesHistory?.[imagesBaseContext.selectedImageInfo?._id]?.currentImage;

        if (isCropped) 
        {
            handleReplaceImage();
        }
        else 
        {
            handleUpdateImage();
        }
    }

    function handleChangeImageData(value) 
    {
        setImageData((prev) => ({ ...prev, ...value }));
    }

    async function handleTriggerEditImage(id) 
    {
        const loadingId = loadingPool.add();

        try 
        {
            
            const imageInfo = imagesBaseContext.imagesData.find((image) => image._id === id);
            if (imageInfo?.usedIn?.length) 
            {
                const isBeingUsedAsFloorPlanImage = imageInfo.usedIn.find((v) => v.usedAs === "floorPlanImage");
                if (isBeingUsedAsFloorPlanImage) 
                {
                    toast.error(trans("gms.Action cannot be on floor_plan_img"));
                    return;   
                }
                
                const hasAccess =  await userHasImageEditDeleteAccess({ imageId: id, queryClient });
                if (!hasAccess) 
                {
                    toast.error(trans("gms.No_acces_to_edit"));
                    return;
                }
            }
            imagesBaseContext.setImageActionMode(IMAGE_ACTION_MODES.EDIT);
            imagesBaseContext.setSelectedImageId(id);
            imagesBaseContext.setHideModals({
                ...DEFAULT_HIDDEN_MODALS,
                gmsModal: true,
            });
            const imageInfoDataUrl = await convertImgUrlToDataURI(
                filerUrl(imageInfo?.filerId)
            );
            setImageData({ ...imageData, dataUrl: imageInfoDataUrl, name: imageInfo.name });
        }
        catch (err) 
        {
            console.error(err);
            toast.error(err.message || trans("gms.image_load_err"));
        }
        finally 
        {
            loadingPool.remove(loadingId); 
        }
    }

    function handleCloseEditModal() 
    {
        imagesBaseContext.setSelectedImageId(null);
        imagesBaseContext.setImageActionMode(null);
        imagesBaseContext.setHideModals(DEFAULT_HIDDEN_MODALS);
        croppedImagesHistoryRest.setCroppedImagesHistory(null);
        setImageData(DEFAULT_IMAGE_DATA);
        cropperRef.current = null;

    }


    const value = {
        imageData,
        imageEditorStatus,
        croppedImagesHistory,
        cropperRef,
        handleEditImage,
        handleChangeImageData,
        handleTriggerEditImage,
        handleCloseEditModal,
        ...croppedImagesHistoryRest,
    };

    return <ImageEditContext.Provider value={value}>{children}</ImageEditContext.Provider>;
}

/**
 * @typedef {Object} ImageEditContextValue
 * @property {ImageData} imageData - Current image data being edited
 * @property {'idle' | 'loading' | 'success' | 'failed'} imageEditorStatus - Current status of the image editor
 * @property {Object.<string, { currentImage: string }>} croppedImagesHistory - History of cropped images
 * @property {React.RefObject} cropperRef - Reference to image cropper
 * @property {function(): Promise<void>} handleEditImage - Handle image edit submission
 * @property {function(Partial<ImageData>): void} handleChangeImageData - Update image data
 * @property {function(string): Promise<void>} handleTriggerEditImage - Trigger image edit modal
 * @property {function(): void} handleCloseEditModal - Close edit modal
 */
