import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { ModalBox } from "../../common/ModalBox";
import { Button,Message, Icon } from "semantic-ui-react";
import { Trans, useTranslation } from "react-i18next";
import {  useMapZoomMethods, useOlMap } from "../map/mapHooks";
import BrandingContext from "../../../store/BrandingContext";
import { ModalLayout } from "../../popups/ModalLayout";
import { MouseInteraction } from "mapsted.maps/utils/interactionTemplates";
import { getSelectedEntityFromClick } from "mapsted.maps/mapFunctions/interactions";
import { Vector as VectorLayer } from "ol/layer";
import { createStyle, createStyleOptions } from "mapsted.maps/mapFunctions/mapOverlay";
import { Vector as VectorSource } from "ol/source";
import Feature from "ol/Feature";
import { getMergedPolygon } from "mapsted.maps/mapFunctions/mapOverlay";
import { createEntityGeometry } from "mapsted.maps/mapFunctions/plotting";
import { isPolygon } from "../../maintenance/mapOverlays/utils/mapOverlayUtils";
import { EntityType, isEntityNameable, StructureEntityType } from "mapsted.maps/utils/entityTypes";
import { deepValue } from "mapsted.utils/objects";
import EntityMapController from "mapsted.maps";
import { filerUrl } from "../../../_utils/utils";
import { CMSEntityAccess } from "mapsted.maps/mapFunctions/entityAccess";
import { MAP_THEMES } from "mapsted.maps/utils/map.constants";
import brandingApi from "../../../_api/branding.api";
import {  SINGLE_LANG_INPUT_CODE } from "../../../_constants/constants";
import "../../branding/map/mapStyles/MapButtons.css";


const ENTITY_INTERACTION_FLAG = "TransferInteraction";
const SELECT_LAYER_FLAG = "isTransferSelectLayer";

export const TransferModal = ({ open, onClose }) => 
{
    const brandingContext = useContext(BrandingContext); 
    const { t: trans } = useTranslation();
    const mapRef = useRef(null);
    const { mapRef: brandingMapRef, recenter, mapData, floors, floorId, propertyId, buildingId, fetchMapData } = useCustomMap(mapRef);
    const { zoomIn, zoomOut } = useMapZoomMethods(brandingMapRef);
    const [isVacant, setIsvacant]= useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [targetEntityId, setTargetEntityId] = useState(null);   

    useEffect(() => 
    {
        if (!open || !mapData) return;
        brandingMapRef.current.setTarget(mapRef.current);
        recenter();
        const interaction = createClickInteraction();
        brandingMapRef.current.addInteraction(interaction);
    
        return () => 
        {
            brandingMapRef.current.removeInteraction(interaction);
            removeSelectLayer();
            setTargetEntityId(null);
            setIsvacant(false);
        };
    }, [open, recenter, brandingMapRef, floorId, mapData]);    

    useEffect(() => 
    {       
        removeSelectLayer();
        setTargetEntityId(null);
        setIsvacant(false);      

    },[floorId]);

    const createClickInteraction = useCallback(() => 
    {
        const mousePointInteraction = MouseInteraction({
            olMap: brandingMapRef.current,
            handleEvent: (e) => handleMouseUpEvent(e)
        });
        mousePointInteraction.set(ENTITY_INTERACTION_FLAG, true);
        return mousePointInteraction;
    }, [brandingMapRef, mapData]);

    const isValidEntityType = (entityData) => entityData && isEntityNameable(entityData?.entityType,entityData?.subEntityType);

    const handleMouseUpEvent = useCallback((e) => 
    {    
        
        if (e.type !== "click") return true;
        
        const { entityId, entityFeature } = getSelectedEntityFromClick({ pointerEvent: e, olMap: brandingMapRef.current });
        const entity = deepValue(mapData, `entities.${entityId}`, undefined);            
        const [selectedEntityId] = Object.keys(brandingContext?.state?.selectedEntities || {}) || [null];

        const entityType =  entity?.entityType;
        const subEntityType =  entity?.subEntityType ;        
        let isBuilding = entityType === EntityType.STRUCTURE && subEntityType === StructureEntityType.BUILDING;
    
        if (entityId && entityFeature && isPolygon(entityFeature) && isValidEntityType(entity) && !isBuilding && selectedEntityId !== entityId) 
        {
            const isStore = !deepValue(entity, "building", undefined);
            const isPointOfIntrest = (entity?.entityType === EntityType?.POINT_OF_INTEREST);
            const isVacantstore = isStore && !!entity?.entityLabel?.kind && !isPointOfIntrest;               

            const mergedPolygon = getMergedPolygon([entityFeature]);
            if (mergedPolygon?.geometry?.type === "Polygon") 
            {
                const geometry = createEntityGeometry(mergedPolygon.geometry);
                highlightEntity(geometry);
                setTargetEntityId(entityId);
                setIsvacant(isVacantstore);
            }
        }
        return true;
    }, [isValidEntityType, brandingMapRef]);

    const highlightEntity = useCallback((geometry) => 
    {
        removeSelectLayer();
        const layer = new VectorLayer({ [SELECT_LAYER_FLAG]: true });
        const source = new VectorSource();
        const feature = new Feature({ geometry });
        
        feature.setStyle(createStyle(createStyleOptions("#E5E5FF", "#727AFF", 0.5, 1)));
        source.addFeature(feature);
        layer.setSource(source);
        brandingMapRef.current.addLayer(layer);
    }, [brandingMapRef]);

    const removeSelectLayer = useCallback(() => 
    {
        const layer = brandingMapRef.current.getLayers().getArray().find((layer) => layer.get(SELECT_LAYER_FLAG));
        if (layer) brandingMapRef.current.removeLayer(layer);
    }, [brandingMapRef]);    

    const handleConfirmTransfer = useCallback(async() => 
    {
        let  successMessage = <Trans i18nKey="VacantModal.Data_transferred_successfully" components={{ bolded: <b /> }} />;
        let  errorMessage =   trans("VacantModal.Transaction_failed_Please_try_again");

        brandingContext.handleVacantTransferEntity(targetEntityId,successMessage,errorMessage);
        setShowConfirmation(false);
        onClose();
        
    }, [targetEntityId, brandingContext, trans, onClose]);    
    
    return (
        <>
            {/* Main Transfer Modal */}
            <ModalBox
                open={open}
                onClose={onClose}
                className="transferModal"
                header={trans("VacantModal.Select_the_room_on_the_map_to_transfer_data")}
                actions={
                    <div className="buttonContainer">
                        <Button
                            className="transferButton transfer"
                            content={trans("VacantModal.Cancel")}
                            onClick={onClose}
                        />
                        <Button
                            className="eraseButton transfer"
                            content={trans("VacantModal.Transfer")}
                            onClick={() => setShowConfirmation(true)}
                            disabled={!targetEntityId}
                        />
                    </div>
                }
            >
                <div className="transferModalbody">
                    <span className="instructionTooltip">
                        {trans("Settings.Map_Rotate_Instructions")}
                    </span>
                    <div className="map-container" ref={mapRef}>
                        <MapButtons onZoomIn={zoomIn} onZoomOut={zoomOut} floorId={floorId} floors={floors} changeSelectedFloor={fetchMapData} propertyId={propertyId} buildingId={buildingId} />
                        {(isVacant && targetEntityId) && <Message className="warinigMessage" color="red"><Icon name="warning" className="red-border-icon" circular color="red" /> {trans("VacantModal.Selected_room_already_have_some_data")}</Message> }
                    </div>
                </div>
            </ModalBox>

            <ModalLayout
                open={showConfirmation}
                onClose={() => setShowConfirmation(false)}
                className="deleteGroupModal vacantModal"
                heading={
                    <div className="heading">
                        <img src="/img/vacant-warning.svg" alt="expand" width="50px" />
                        <b>{( trans( isVacant ? "VacantModal.Are_you_sure_you_want_to_overwrite_the_data_already_available_in_this_room" : "VacantModal.Are_you_sure_you_want_to_transfer_the_data_in_this_room"))}</b>
                    </div>
                }
                actions={
                    <div className="buttonContainer">
                        <Button className="eraseButton" content={trans("VacantModal.Yes")} onClick={handleConfirmTransfer} />
                        <Button className="transferButton" content={trans("VacantModal.No")} onClick={() => setShowConfirmation(false)}  />
                    </div>
                }
            >
                <p className="pModal">{trans("VacantModal.This_action_cant_be_undone")}</p>
            </ModalLayout>
        </>
    );
};

const useCustomMap = (mapRef) => 
{
    const { state: brandingState, updateState } = useContext(BrandingContext);
    const { propertyId, buildingId, properties, floorId: initialFloorId, mapData: initialMapData } = brandingState;
    const [floorId, setFloorId] = useState(initialFloorId);
    const [mapData, setMapData] = useState(initialMapData);
    const [entityLayers, setEntityLayers] = useState();
    const [boundaryPolygon, setBoundaryPolygon] = useState();
    const [imageLayers, setImageLayers] = useState();

    const [mapController] = useState(() => new EntityMapController({
        features: { text: true, images: true },
        filerUrl: filerUrl(""),
        accessor: CMSEntityAccess,
        theme: MAP_THEMES.CLASSIC
    }));

    useEffect(() => 
    {
        fetchMapData(floorId,buildingId,propertyId);
    }, []);

    const fetchMapData = async (newFloorId,newBuildingId,newPropertyId) => 
    {

        updateState({ loading: true });
        try 
        {
            let newMapData ;

            if (newFloorId && newBuildingId) 
            {
                // Fetch floor-specific map data
                const { data } = await brandingApi.getFloorMapData(newBuildingId, newFloorId);
                newMapData = data;
            }
            else if (newPropertyId) 
            {
                // Fetch property-level map data
                const { data } = await brandingApi.getPropertyMapData(newPropertyId);
                newMapData = data;
            }

            if (newMapData) 
            {
                const { entityLayers, boundaryPolygon, imageLayers } = mapController.initialize(newMapData);
                setEntityLayers(entityLayers);
                setImageLayers(imageLayers);
                setBoundaryPolygon(boundaryPolygon);
                setMapData(newMapData);
                setFloorId(newFloorId);
            }
        }
        catch (error) 
        {
            console.error("Error fetching map data:", error);
        }
        finally 
        {
            updateState({ loading: false });
        }
    };
    const floors = useMemo(() => properties?.[propertyId]?.buildings?.[buildingId]?.floors || [], [brandingState, buildingId]);   

    const mapOptions = useMemo(() => ({ propertyId, buildingId, floorId, entityLayers, imageLayers, boundaryPolygon  }), [propertyId, buildingId, floorId, entityLayers, imageLayers, boundaryPolygon]);

    const olMapResult = useOlMap(mapRef, mapOptions);
    
    return { ...olMapResult, mapData, floors, floorId, propertyId, buildingId, fetchMapData };
};

export const MapButtons = ({ onZoomIn, onZoomOut, floors,floorId, propertyId, buildingId, changeSelectedFloor }) => (
    <div className="buttons-wrap "> 
          
        <div className="buttons-group staysActive">
            <MapButton content={<span className="zoomIn" />} onClick={onZoomIn} />
            <MapButton content={<span className="zoomOut" />} onClick={onZoomOut} />
        </div>
        <div className="buttons-group">
            {floors?.map((floor) => (
             
                <Button                    
                    key={floor._id}
                    className={floorId === floor._id ? "active" : ""}  
                    onClick={() => changeSelectedFloor(floor._id, buildingId, propertyId)}>
                    {floor.shortName?.[SINGLE_LANG_INPUT_CODE]}
                </Button>
            ))}
        </div>
    </div >
);

export const MapButton = ({ content = undefined, isActive = false, onClick }) => (
    <Button className={isActive ? "active" : ""} onClick={onClick}>
        {content}
    </Button>
);
