import React, { useContext, useEffect } from "react";
import { useSetState } from "ahooks";
import { DynamicMapLayersContext } from "../../../../store/DynamicMapLayersContext";
import { arrayToHash } from "mapsted.utils/arrays";

import "./MapPopup.css";
import { DYNAMIC_MAP_LAYERS_MODES } from "../../../../_constants/constants";
import { addLineBreaksToString } from "mapsted.maps/mapFunctions/mapOverlay";

const MapPopup = () =>
{
    const dynamicMapLayerCtx = useContext(DynamicMapLayersContext);

    const { 
        textPopupAutomationRuleId, 
        activeAutomationRule, 
        activeDynamicMapLayer, 
        dynamicMapLayers,
        mode,
        activeMultiCreation,
        displayLang
    } = dynamicMapLayerCtx.state;

    const [state, setState] = useSetState({
        popupText: ""
    });

    useEffect(() =>
    {
        const automationRules = getAllAutomationRules();
        if (textPopupAutomationRuleId
                && automationRules 
                && automationRules[textPopupAutomationRuleId] 
                && automationRules[textPopupAutomationRuleId].textPopup[displayLang])
        {
            const [isMulti, popupTextString] = addLineBreaksToString(automationRules[textPopupAutomationRuleId].textPopup[displayLang]);
            setState({
                popupText: popupTextString
            });
        }
        else
        {
            setState({
                popupText: ""
            });
        }
    }, [textPopupAutomationRuleId, activeAutomationRule]);

    const getAllAutomationRules = () => 
    {   
        let automationRules = {};

        dynamicMapLayers.forEach((dynamicMapLayer) =>
        {
            automationRules = { ...automationRules, ...arrayToHash(dynamicMapLayer.automationRules, "_id") };
        });

        if (activeDynamicMapLayer)
        {
            automationRules =  { ...automationRules, ...arrayToHash(activeDynamicMapLayer.automationRules, "_id") };
        }
        if (activeAutomationRule)
        {
            automationRules[activeAutomationRule._id] = activeAutomationRule;
        }

        if (mode === DYNAMIC_MAP_LAYERS_MODES.MULTI_CREATION)
        {
            const { dynamicMapLayers: multiCreationDynamicMapLayers } = activeMultiCreation;
            
            multiCreationDynamicMapLayers.forEach((dynamicMapLayer) =>
            {
                dynamicMapLayer.automationRules.forEach((automationRule) =>
                {
                    automationRules[automationRule._id] = automationRule;
                });
            });
        }

        return automationRules;
    };

    if (!state.popupText?.trim())
    {
        return null;
    }

    return (
        <div className="mapPopup">
            <p>{state.popupText}</p>
        </div>
    );

};

export default MapPopup;