/**
 * This is a workaround for the issue we are facing while trying to import util methods from cmsVectorLayers module in mapsted.maps,
 * so we have replicated those methods here
 */

import { 
    createEntityStyle,
    createEntityGeometry,
    createEntityLayers,
    addFeatureToLayer,
    createEntityCategoryLayers,
    createTextStyle
} from "mapsted.maps/mapFunctions/plotting";
import Feature from "ol/Feature";
import { toMercatorFromArray } from "mapsted.maps/utils/map.utils";
import { getCenter, getArea  } from "ol/extent";
import { Style, Icon } from "ol/style.js";
import { deepValue } from "mapsted.utils/objects";
import { PublicEntityAccess } from "mapsted.maps/mapFunctions/entityAccess";
import { BoundaryEntityType, EntityType, ShapeTypes } from "mapsted.maps/utils/entityTypes";
import { createImageLayer } from "mapsted.maps/mapFunctions/cmsVectorLayers";
import { DEFAULT_CATEGORY_ICON_SIZE, DEFAULT_ICON_STYLE, MapConstants } from "mapsted.maps/utils/map.constants";
import { MAP_TEXT_STYLE } from "mapsted.maps/utils/defualtStyles";

/**
 * Creates entityLayers to be drawn on the map given mapData.
 * Uses mapData from public db
 * @param {Object} mapData
 * @param {Object} mapData.entities
 * @param {Object} mapData.style
 * @param {Object} mapData.categoryMap
 * @param {string} filerUrl
 * @param {Object} [options]
 * @param {Boolean} [options.category]
 * @param {Boolean} [options.subCategories]
 * @param {string} [options.theme]
 */
export const createEntityVectorLayers = (mapData, filerUrl, options = {}) =>
{
    if (!mapData)
    {
        return {};
    }
        
    const { entities, style, categoryMap } = mapData;
        
    const iconStyle = deepValue(style, "default.icon");
        
    let entityLayers = createEntityLayers(style.layerStyles);
    let imageLayers = {};
    let categoryLayers = {};
    let entityFeatureMap = {};
        
    if (options.category)
    {
        categoryLayers = createEntityCategoryLayers(categoryMap);
    }
        
    let boundaryPolygon = undefined;
        
    Object.values(entities).forEach((entity) =>
    {
        const entityAccess = new PublicEntityAccess(entity);
        // Create Style
        const styleObject = entityAccess.getStyleObject(style);
        const textStyleObject = entityAccess.getTextStyleObject(style);
        
        const entityStyle = createEntityStyle(styleObject);
        const entityTextStyle = createEntityTextStyle(entityAccess, textStyleObject);
        
        // Create Geometry
        const entityGeometry = createEntityGeometry(entityAccess.getShape());
        
        const entityArray = getArea(entityGeometry.getExtent());
        
        let entityTextLocation = entityAccess.getTextLocation();
        
        // Create Text Geometry
        if (entityTextLocation && Object.keys(entityTextLocation).length === 0)
        {
            entityTextLocation = undefined;
        }
        let textLocation = entityTextLocation && toMercatorFromArray(entityTextLocation);
        
        // If text coordinate is not given, set to entity center
        if (!textLocation)
        {
            textLocation = getCenter(entityGeometry.getExtent());
        }
        
        // Create text geometry
        const entityTextGeometry = createEntityGeometry({ type: "TextPoint", coordinates: textLocation });
        
        // attach it to style
        entityTextStyle.setGeometry(entityTextGeometry);
        
        // Find boundary polygon
        if (
            entityAccess.getEntityType() === EntityType.BOUNDARY
                    && (entityAccess.getSubEntityType() === BoundaryEntityType.PROPERTY_BOUNDARY
                        || entityAccess.getSubEntityType() === BoundaryEntityType.FLOOR_BOUNDARY)
        )
        {
            boundaryPolygon = entityGeometry;
        }
        
        //Create feature
        let feature = new Feature({
            geometry: entityGeometry,
            id: entityAccess.getId(),
            canName: entityAccess.getCanNameEntity(),
            area: entityArray,
            entityType: entityAccess.getEntityType(),
            subEntityType: entityAccess.getSubEntityType(),
        });
        
        let textFeature = new Feature({
            geometry: entityTextGeometry,
            type: "TextPoint",
            coordinates: textLocation,
            id: entityAccess.getId() + "text",
            entityId: entityAccess.getId(),
            canName: entityAccess.getCanNameEntity(),
            isText: true,
            connectedFeature: feature,
        });
        
        // create image layer;
        const isImageOnMap = entityAccess.getIsImageOnMap();
        let imgLayer = undefined;
        
        if (isImageOnMap)
        {
            // check if entity has image extent
            // convert to mercator
            const iconStyle = entity.iconStyle;
        
            // Saftey null check
            if (iconStyle)
            {
                imgLayer = createImageLayer({
                    imgId: entityAccess.getImage({ theme: options.theme }),
                    entityShape: entityAccess.getShape(),
                    rotation: entityAccess.getImageRotation(),
                    extent: entityAccess.getImageExtent(),
                    filerUrl,
                });
        
                // Saftey null check
                if (imgLayer)
                {
                    imageLayers[entityAccess.getId()] = imgLayer;
                }
            }
        }
        
        //Add Style
        if (entityStyle)
        {
            feature.setStyle(entityStyle);
        }
    
        if (entityTextStyle)
        {
            textFeature.setStyle(entityTextStyle);
        }
        
        // Add feature to the correct layer
        if (styleObject && styleObject.layerIdx !== undefined)
        {
            addFeatureToLayer(entityLayers[styleObject.layerIdx], feature);
        }
        else
        {
            addFeatureToLayer(entityLayers[-1], feature);
        }
        
        if (textStyleObject)
        {
            addFeatureToLayer(entityLayers[MapConstants.TEXT_LAYER], textFeature);
        }
        
        // --- OPTIONAL CATEGORY IMAGE --- //
        
        // create category image geometry if option is selected
        if (!!options.category && entityAccess.getCategoryId())
        {
            // entity has a category and option is picked, create a point geometry and add icon style to it
            const categoryId = entityAccess.getCategoryId();
        
            const category = categoryMap[categoryId];
        
            let categoryIconFeature = createCategoryFeature({
                entity,
                category,
                entityGeometry,
                filerUrl,
                iconStyle,
                connectedFeature: feature,
            });
        
            if (categoryIconFeature)
            {
                // add feature to category layer
                addFeatureToLayer(categoryLayers[categoryId], categoryIconFeature);
        
                // attach category feature to entity
                entity.categoryFeature = categoryIconFeature;
            }
        }
        
        if (!!options.subCategories && !!Array.isArray(entity.subCategories))
        {
            entity.subCategoryFeatures = [];
        
            entity.subCategories.forEach((subCategory) =>
            {
                // create a point geometry and add icon style to it
                const categoryId = subCategory._id;
                const category = categoryMap[categoryId];
        
                let categoryIconFeature = createCategoryFeature({
                    entity,
                    category,
                    entityGeometry,
                    filerUrl,
                    iconStyle,
                    connectedFeature: feature,
                });
        
                if (categoryIconFeature)
                {
                    // add feature to category layer
                    addFeatureToLayer(categoryLayers[categoryId], categoryIconFeature);
        
                    // attach category feature to entity
                    entity.subCategoryFeatures.push(categoryIconFeature);
                }
            });
        }
        
        // --- OPTIONAL ENTITY FEATURE MAP  --- //
        if (options.entityFeatureMap)
        {
            entityFeatureMap[entityAccess.getId()] = feature;
        }
        
        // attach feature and text feature to entity
        entity.feature = feature;
        entity.textFeature = textFeature;
    });
        
    //  ---------- set cluster source ----------
    // setClusterSource(entityLayers[MapConstants.TEXT_LAYER]);
        
    let returnObject = {
        entityLayers,
        boundaryPolygon,
        imageLayers,
    };
        
    if (options.category)
    {
        returnObject.categoryLayers = categoryLayers;
    }
        
    if (options.entityFeatureMap)
    {
        returnObject.entityFeatureMap = entityFeatureMap;
    }
    return returnObject;
};
    
const createCategoryFeature = ({ entity, category, entityGeometry, filerUrl, iconStyle, connectedFeature }) =>
{
    let categoryImageFeature = undefined;
        
    if (category)
    {
        // get center point of entity geometry (or we can use text location??)
        const centerPoint = getCenter(entityGeometry.getExtent());
        
        // Create category geometry
        const entityCategoryGeometry = createEntityGeometry({ type: ShapeTypes.TEXT_POINT, coordinates: centerPoint });
        
        // Create category icon style
        const categoryIconStyle = createIconStyle(
            filerUrl + category.iconImage,
            iconStyle || DEFAULT_ICON_STYLE
        );
        
        //Create category image feature
        categoryImageFeature = new Feature({
            geometry: entityCategoryGeometry,
            // style: entityStyle,
            id: entity.entityId + "category" + category,
            isText: true,
            connectedFeature: connectedFeature,
        });
        
        categoryImageFeature.setStyle(() =>
        {
            const size = categoryIconStyle.getImage().getSize();
            if (size)
            {
                const [x, y] = size;
                const [maxX, maxY] = DEFAULT_CATEGORY_ICON_SIZE;
                const scaleX = maxX / x, scaleY = maxY / y;
                categoryIconStyle.getImage().setScale([scaleX, scaleY]);
            }
            return [categoryIconStyle];
        });
    }
        
    return categoryImageFeature;
};
    
const createIconStyle = (iconImage, iconStyle) =>
{
    let style = new Style({
        image: new Icon({
            ...iconStyle,
            crossOrigin: "Anonymous",
            src: iconImage,
        }),
    });
            
    return style;
};
            
/**
            * Creates an entity's text style given entity information.
            *
            * @param {PublicEntityAccess} entityAccess
            * @param {*} textStyle
        */
const createEntityTextStyle = (entityAccess, textStyle) =>
{
    const name = entityAccess.getName({ useStyle: true });
            
    let style = new Style(MAP_TEXT_STYLE);
            
    if (name)
    {
        const text = createTextStyle(name, entityAccess.getTextRotation() || 0, textStyle);
        style.setText(text);
    }
            
    return style;
};