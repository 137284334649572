import React from "react";
import { Input } from "semantic-ui-react";
import { useIconsManagementContext } from "../../IconsManagementContext";

function IconsSearch()
{
    const { handleChangeIconsSearchValue, iconsSearchAndFilters } = useIconsManagementContext();
    
    return <Input
        className="gms-search-bar"
        value={iconsSearchAndFilters.searchValue}
        onChange={(e) => handleChangeIconsSearchValue(e.target.value)}
        icon="search"
        placeholder="Search..."
    />;
}

export default IconsSearch;