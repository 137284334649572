import React from "react";
import { useTranslation } from "react-i18next";
import { Dropdown, DropdownItem, DropdownMenu, Portal } from "semantic-ui-react";
import { ACCEPTABLE_IMAGE_FILE_FORMATS } from "../../../constant";
import "./ImagesUploadSelector.css";
import { useImagesUploadContext } from "../../contexts/ImagesUploadContext";
import { useImagesBaseContext } from "../../contexts/ImageBaseContext";

function ImagesUploadSelector() 
{
    const trans = useTranslation().t;
    const { bulkUploadEnabled } = useImagesBaseContext();
    const { handleTriggerSingleImageUpload, handleTriggerBulkImageUpload, handleTriggerUploadFromUrl } = useImagesUploadContext();

    return (
        <Dropdown floating direction="left" text={"Upload  |"} icon={"angle down"} className="images-upload-selector gms-dropdown">
            <DropdownMenu className="gms-dropdown-menu">
                <DropdownItem className="images-upload-selector-item gms-dropdown-item" >
                    <label className="images-upload-selector-item-label" htmlFor="single-image-upload">
                        {trans("Gallery.Upload_Image")}
                    </label>
                    <input className="images-upload-selector-item-input" type="file" id="single-image-upload" accept={ACCEPTABLE_IMAGE_FILE_FORMATS} onChange={handleTriggerSingleImageUpload} />
                </DropdownItem>
                {
                    bulkUploadEnabled &&  <DropdownItem className="images-upload-selector-item gms-dropdown-item">
                        <label className="images-upload-selector-item-label" htmlFor="bulk-image-upload">
                            {trans("Gallery.Bulk_Upload")}
                        </label>
                        <input className="images-upload-selector-item-input" multiple type="file" id="bulk-image-upload" accept={ACCEPTABLE_IMAGE_FILE_FORMATS} onChange={handleTriggerBulkImageUpload} />
                    </DropdownItem>
                }
                <DropdownItem className="images-upload-selector-item gms-dropdown-item" onClick={handleTriggerUploadFromUrl}>
                    <span className="images-upload-selector-item-label">
                        {trans("Gallery.Web_Address")}
                    </span>
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
}

export default ImagesUploadSelector;