import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";
import { ModalLayout } from "../../../../popups/ModalLayout";

export const ConfirmDeleteModal = ({ className, editConfig, isOpen, onDelete, onCancel, trigger }) =>
{
    const trans = useTranslation().t;

    return (
        <ModalLayout
            open={isOpen}
            trigger={trigger}
            onClose={onCancel}
            className={`crop-image-modal deleteGroupModal ${className}`}
            heading={editConfig.title}
            actions={
                <>
                    <Button primary color="grey" content={trans("delete")} onClick={() => onDelete()} />
                </>
            }>
            <span style={{ padding: "10px", wordBreak: "break-word", whiteSpace: "pre-line" }} className="p-modal">{editConfig.body}</span><br />
        </ModalLayout>
    );
};
