import { DEFAULT_LANGUAGE_CODE } from "mapsted.maps/utils/map.constants";
import { printTitleCase } from "./utils";
import _ from "lodash";

const OVERLAY_TEMPLATES_TEXT_FIELDS = ["name"];


const attachLinkedMapOverlaysToTemplates = ({ template, mapOverlays, floorId }) => ({
    ...template,
    mapOverlays: mapOverlays.filter((mapOverlay) => mapOverlay?.templateId === template.templateId && mapOverlay.floorId === floorId).map((mapOverlay) => mapOverlay._id)
});


export const groupOverlayTemplates = ({ overlayTemplates, mapOverlays, floorId }) => overlayTemplates?.filter((template) => template?.group)?.reduce((acc, template) => 
{
    if (!acc[template.group]) 
    {
        acc[template.group] = [attachLinkedMapOverlaysToTemplates({ mapOverlays, template, floorId })];
    }
    else 
    {
        const templates = [...acc[template.group], attachLinkedMapOverlaysToTemplates({ mapOverlays, template, floorId })]
            .toSorted((templateA, templateB) => templateA.name[DEFAULT_LANGUAGE_CODE].localeCompare(templateB.name[DEFAULT_LANGUAGE_CODE]));

        acc[template.group] = templates;
    }
    return acc;
}, {});

export const getUngroupedOverlayTemplates = ({ overlayTemplates, mapOverlays, floorId }) => overlayTemplates?.filter((template) => !template?.group).map((template) => attachLinkedMapOverlaysToTemplates({ template, mapOverlays, floorId }))
        ?.toSorted((templateA, templateB) => templateA.name[DEFAULT_LANGUAGE_CODE].localeCompare(templateB.name[DEFAULT_LANGUAGE_CODE]));


export const formatGroupsForDropdown = (groupArray) => groupArray.map((item) => ({
    key: item._id,
    value: item._id,
    text: printTitleCase(item, true) 
}));

export const getAllAvailableMapOverlayLangs = (overlayTemplate) =>
{
    let langs = new Set();

    // add all the lang codes present in map overlay text fields
    OVERLAY_TEMPLATES_TEXT_FIELDS.forEach((field) =>
    {
        Object.keys(overlayTemplate[field]).forEach((langCode) => langs.add(langCode));
    });

    return [...langs];
};

const validateDynamicOverlaySettings = (dynamicOverlaySettings, trans) =>
{
    let validationErrors = [];

    if (dynamicOverlaySettings?.enabled)
    {
        const zoomLevels = dynamicOverlaySettings.zoomLevels;

        for (let i=0; i<zoomLevels.length; i++)
        {
            const currentZoomLevel = zoomLevels[i];

            // update level name which will be added in error message based on index
            let levelIdentifierMsg = trans("OverlaysTemplateSideBar.At_Index", { index: i });
            if (i === 0)
            {
                levelIdentifierMsg = trans("OverlaysTemplateSideBar.Start_Zoom");
            }
            else if (i === zoomLevels.length - 1)
            {
                levelIdentifierMsg = trans("OverlaysTemplateSideBar.End_Zoom");
            }

            if (!currentZoomLevel.value)
            {
                validationErrors.push(trans("OverlaysTemplateSideBar.Missing_Zoom_Value_Error", {
                    levelIdentifierMsg: `${levelIdentifierMsg}`
                }));
                break;
            }
        
            if (i !== 0 && zoomLevels[i - 1].value >= currentZoomLevel.value)
            {
                validationErrors.push(trans("OverlaysTemplateSideBar.Invalid_Zoom_Value_Error", {
                    levelIdentifierMsg: `${levelIdentifierMsg}`
                }));
                break;
            }

            if (!(currentZoomLevel.fillOpacity || currentZoomLevel.fillOpacity === 0))
            {
                validationErrors.push(trans("OverlaysTemplateSideBar.Invalid_Fill_Capacity", {
                    levelIdentifierMsg: `${levelIdentifierMsg}`
                }));
                break;
            }

            if (!(currentZoomLevel.borderFillOpacity || currentZoomLevel.borderFillOpacity === 0))
            {
                validationErrors.push(trans("OverlaysTemplateSideBar.Invalid_Border_Opacity", {
                    levelIdentifierMsg: `${levelIdentifierMsg}`
                }));
                break;
            }

            if (!(currentZoomLevel.textOpacity || currentZoomLevel.textOpacity === 0))
            {
                validationErrors.push(trans("OverlaysTemplateSideBar.Invalid_Text_Opacity", {
                    levelIdentifierMsg: `${levelIdentifierMsg}`
                }));
                break;
            }
        }
    }

    return validationErrors;
};

const validateOverlayTemplate = (overlayTemplate, overlayTemplates, trans) =>
{
    let validationErrors = [];

    if (!overlayTemplate.name?.[DEFAULT_LANGUAGE_CODE])
    {
        validationErrors.push("Name is mandatory");
    }
    else if (overlayTemplates.filter((template) => template._id !== overlayTemplate._id).find((template) => template?.name?.[DEFAULT_LANGUAGE_CODE] === overlayTemplate.name?.[DEFAULT_LANGUAGE_CODE])) 
    {
        validationErrors.push("Template name already exists, please use a unique template name");
    }
    else 
    {
        
        // check if map overlay name has all the available lang codes which are entered in other text fields in map overlay
        const allAvailableLangCodes = getAllAvailableMapOverlayLangs(overlayTemplate);
        allAvailableLangCodes.forEach((langCode) =>
        {
            if (!(overlayTemplate.name[langCode]))
            {
                validationErrors.push(`Name required in ${langCode.toUpperCase()} language`);
            }
        });
    }

    validationErrors.push(...validateDynamicOverlaySettings(overlayTemplate.dynamicOverlaySettings, trans));

    if (!overlayTemplate.dynamicOverlaySettings?.enabled)
    {
        if (!(overlayTemplate.defaultFillOpacity || overlayTemplate.defaultFillOpacity === 0))
        {
            validationErrors.push("Invalid default fill opacity");
        }

        if (!(overlayTemplate.defaultBorderFillOpacity || overlayTemplate.defaultBorderFillOpacity === 0))
        {
            validationErrors.push("Invalid default border opacity");
        }

        if (!(overlayTemplate.defaultTextOpacity || overlayTemplate.defaultTextOpacity === 0))
        {
            validationErrors.push("Invalid default text opacity");
        }
    }

    return validationErrors;
};

export const validateCurrentOverlayTemplate = (overlayTemplate, overlayTemplates, trans) =>
{
    const validationErrors = validateOverlayTemplate(overlayTemplate, overlayTemplates, trans);

    if (validationErrors.length)
    {
        return validationErrors;
    }
    return false;
};