import React, { useState, useEffect, useMemo, useCallback, useContext } from "react";
import "./keywordSection.css";
import { CategoryContext } from "../../../../../store/CategoryProvider";
import { useTranslation } from "react-i18next";
import { Button,Icon, Dropdown, Image } from "semantic-ui-react";
import { CheckboxGroup } from "../../../../common/CheckboxGroup";
import Scrollbars from "react-custom-scrollbars-2";
import CategoryTreeDisplay from "../../../../common/componentTreeDisplay/CategoryTreeDisplay";
import { ButtonIcon } from "../../../../common/ButtonIcon";
import { DEFAULT_LANGUAGE_CODE } from "../../../../../_constants/constants";
import BrandingContext from "../../../../../store/BrandingContext";


const KeywordSection = ({ group, lang }) => 
{
    const { getEntitiesKeywords, getCategoriesFromIds, categoryFlatMap, lastUpdated, buildingOptions } = useContext(CategoryContext);
    const { t: trans } = useTranslation();

    const [selectedBuilding, setSelectedBuilding] = useState("");
    const categoriesIds = group?.subCategories || [];
    const categoryObject = getCategoriesFromIds(categoriesIds);
    const [sortOption, setSortOption] = useState("asc");
    const brandingContext = useContext(BrandingContext);

    const getKeywords =(category) =>
    { 
        const { keywords, buildingKeywords }=category;  
        if (selectedBuilding) 
        {
            return buildingKeywords?.[selectedBuilding] || keywords;            
        }
        
        return keywords;
    };  
        
    const [selectedCategory, setSelectedCategory] = useState(() => ({
        _id: categoryObject[0]?._id,
        name: categoryObject[0]?.name?.[lang] || categoryObject[0]?.name?.[DEFAULT_LANGUAGE_CODE],
        keywords: getKeywords( categoryObject[0])
    }));

    const entitiesKeywords = useMemo(() => 
    {
        let keywords = [];
        
        if (selectedCategory)
        {
            keywords = getEntitiesKeywords({ categoriesIds: [selectedCategory._id], lang, selectedBuilding: brandingContext.state.buildingId });
        }
        
        return keywords;
        
    }, [getEntitiesKeywords, selectedCategory, lang, brandingContext.state.buildingId]);

        
    useEffect(() => 
    {
        if (group?._id && categoryObject  && categoryObject?.length >0) 
        {
            setSelectedCategory({
                _id: categoryObject[0]?._id,
                name: categoryObject[0]?.name?.[lang] || categoryObject[0]?.name?.[DEFAULT_LANGUAGE_CODE],
                keywords: getKeywords(categoryObject[0])
            });
        }
         
    },[group?._id]);

    // Update selectedCategory when categoryFlatMap or lastUpdated changes
    useEffect(() => 
    {
        if (selectedCategory?._id) 
        {
            const newSelectedCategory = categoryFlatMap[selectedCategory._id];
            if (newSelectedCategory) 
            {
                setSelectedCategory({
                    _id: newSelectedCategory._id,
                    name: newSelectedCategory.name?.[lang] || newSelectedCategory.name?.[DEFAULT_LANGUAGE_CODE],
                    keywords: getKeywords( newSelectedCategory)
                });
            }
        }
    }, [lastUpdated, categoryFlatMap, selectedCategory?._id, lang, selectedBuilding]);   

    const sortOptions = useMemo(() => [
        { key: "asc", text: trans("Sort by -Name (A-Z)"), value: "asc" },
        { key: "desc", text: trans("Sort by -Name (Z-A)"), value: "desc" },
    ], [trans]);

    const handleCategorySelect = useCallback((category) => 
    {
        const newSelectedCategory = categoryFlatMap[category._id];
        if (newSelectedCategory) 
        {
            setSelectedCategory({
                _id: newSelectedCategory._id,
                name: newSelectedCategory.name?.[lang],
                keywords: newSelectedCategory.keywords,
            });
        }
    }, [categoryFlatMap, lang]);

    const categoryKeywords = useMemo(() => (
        selectedCategory?.keywords?.[lang]?.map((keyword) => ({
            name: keyword,
            selected: false,
        })) || []
    ), [selectedCategory, lang]);

    return (
        <div>
            <HeaderSection trans={trans} selectedBuilding={selectedBuilding} setSelectedBuilding={setSelectedBuilding} buildingOptions={buildingOptions} sortOptions={sortOptions} sortOption={sortOption} setSortOption={setSortOption} />

            <div style={{ display: "flex", justifyContent: "space-between", gap: 20 }}>
                <CategoryTreeDisplay
                    className="keywordSection-category"
                    Heading={() => <div className="keywords-subTitle">{trans("manageCategory.keywordsSubTitle")}</div>}
                    CategoryItem={(props) => (
                        <Item
                            {...props}
                            isActive={props.detail._id === selectedCategory?._id}
                            onClick={() => handleCategorySelect(props.detail)}
                        />
                    )}
                    showAllCategories={false}
                    selectedGroup={categoryFlatMap?.[group?._id]}
                    searchable={false}
                    lang={lang}
                    expandAll={true}
                    autoHeightMax="500px"
                    autoHeightMin="420px"
                />

                {entitiesKeywords.length > 0 || categoryKeywords.length > 0 ? (
                    <KeywordsManageSection
                        categoryKeywords={categoryKeywords}
                        entitiesKeywords={entitiesKeywords}
                        sortOption={sortOption}
                        categoryName={selectedCategory?.name}
                        categoryId={selectedCategory?._id}
                        lang={lang}
                        selectedBuilding= {selectedBuilding}
                    />
                ) : (
                    <NoKeywordsMessage trans={trans} />
                )}
            </div>
        </div>
    );
};

export default KeywordSection;

export const KeywordsManageSection = ({ categoryKeywords, entitiesKeywords, sortOption, categoryName, categoryId, lang, selectedBuilding }) => 
{
    const [availableKeywords, setAvailableKeywords] = useState([]);
    const [assignedKeywords, setAssignedKeywords] = useState([]);
    
    const [isAllAssignedSelected, setIsAllAssignedSelected] = useState(false);
    const [isAllAvailableSelected, setIsAllAvailableSelected] = useState(false);

    // New state to track if there are changes
    const [hasChanges, setHasChanges] = useState(false);  
    
    const { t: trans } = useTranslation();
    const {  groupByKeywordAlphabet, updateCategoryKeywords } = useContext(CategoryContext);

    useEffect(() => 
    {
        const entityKeywordNames = new Set(entitiesKeywords?.map((keyword) => keyword.name));
    
        // Preserve existing selections
        const updatedCategoryKeywords = categoryKeywords?.map((keyword) => 
        {
            const existingKeyword = assignedKeywords.find((k) => k.name === keyword.name);
            return {
                ...keyword,
                disabled: !entityKeywordNames.has(keyword.name),
                selected: existingKeyword ? existingKeyword.selected : false,
            };
        }) || [];
    
        const filteredAvailableKeywords = entitiesKeywords?.filter(({ name }) => !categoryKeywords?.some((keyword) => keyword.name === name)
        ).map((keyword) => 
        {
            const existingKeyword = availableKeywords.find((k) => k.name === keyword.name);
            return {
                ...keyword,
                selected: existingKeyword ? existingKeyword.selected : false,
            };
        }) || [];
    
        setAssignedKeywords(updatedCategoryKeywords);
        setAvailableKeywords(filteredAvailableKeywords);
    
        return () => 
        {
            setAssignedKeywords([]);
            setAvailableKeywords([]);
            setIsAllAssignedSelected(false);
            setIsAllAvailableSelected(false);
            setHasChanges(false);
        };
    }, [categoryKeywords, entitiesKeywords, categoryId]);
    
    
    // Move selected keywords from assigned to available and reset their selected state
    const moveToAvailable = () => 
    {
        // Move selected keywords to available, including delinked ones
        const updatedAssigned = assignedKeywords.filter((k) => !k.selected);
        const movedKeywords = assignedKeywords
            .filter((k) => k.selected) // Move both delinked and non-delinked keywords
            .map((k) => ({ ...k, selected: false })); // Reset selected to false after moving
    
        setAssignedKeywords(updatedAssigned);
    
        // Filter out delinked keywords before setting available ones
        const nonDelinkedKeywords = movedKeywords.filter((k) => !k.disabled);
        setAvailableKeywords([...availableKeywords, ...nonDelinkedKeywords]);
    
        // Ensure Select All is unchecked after the move
        setIsAllAssignedSelected(false);
    
        // Mark as changed
        setHasChanges(true);
    };
    
  
    // Move selected keywords from available to assigned and reset their selected state
    const moveToAssigned = () => 
    {
        const updatedAvailable = availableKeywords.filter((k) => !k.selected);
        const movedKeywords = availableKeywords
            .filter((k) => k.selected)
            .map((k) => ({ ...k, selected: false })); // Reset selected to false after moving
  
        setAvailableKeywords(updatedAvailable);
        setAssignedKeywords([...assignedKeywords, ...movedKeywords]);
    
        // Ensure Select All is unchecked after the move
        setIsAllAvailableSelected(false);

        // Mark as changed
        setHasChanges(true);
    };
  
    
    // Toggle individual assigned keyword
    const toggleAssignedKeyword = (globalIndex) => 
    {
        const updatedKeywords = [...assignedKeywords];
        // Enable selection of delinked keywords
        updatedKeywords[globalIndex].selected = !updatedKeywords[globalIndex].selected;
        setAssignedKeywords(updatedKeywords);
    
        // Check if all keywords (including delinked) are selected
        const allSelected = updatedKeywords.every((k) => k.selected);
        setIsAllAssignedSelected(allSelected);
    };
    
    // Toggle individual available keyword
    const toggleAvailableKeyword = (globalIndex) => 
    {
        const updatedKeywords = [...availableKeywords];
        updatedKeywords[globalIndex].selected = !updatedKeywords[globalIndex].selected;
        setAvailableKeywords(updatedKeywords);
    
        // Check if all keywords are selected
        const allSelected = updatedKeywords.every((k) => k.selected);
        setIsAllAvailableSelected(allSelected);
    };
    
    // Select or Deselect all assigned keywords
    const toggleSelectAllAssigned = () => 
    {
        const newSelection = !isAllAssignedSelected;
        const updatedKeywords = assignedKeywords.map((keyword) => ({
            ...keyword,
            selected: newSelection,
        }));
        setAssignedKeywords(updatedKeywords);
        setIsAllAssignedSelected(newSelection);
    };
    
    // Select or Deselect all available keywords
    const toggleSelectAllAvailable = () => 
    {
        const newSelection = !isAllAvailableSelected;
        const updatedKeywords = availableKeywords.map((keyword) => ({
            ...keyword,
            selected: newSelection,
        }));
        setAvailableKeywords(updatedKeywords);
        setIsAllAvailableSelected(newSelection);
    };
    
    // Update keywords API
    const updateKeywords = async () => 
    {
        const selectedAssignedKeywords = assignedKeywords.map((keyword) => keyword.name);
    
        // Update the category by sending only the currently assigned keywords (limited to 20)
        await updateCategoryKeywords(categoryId, {
            keywords: selectedAssignedKeywords,
            languageCode: lang,
            buildingId: selectedBuilding
        });
    
        // Reset the changes state
        setHasChanges(false);
    };   
    

    const groupedAssignedKeywords = useMemo(() => groupByKeywordAlphabet(assignedKeywords, sortOption), 
        [assignedKeywords, sortOption]
    );

    const groupedAvailableKeywords = useMemo(() => groupByKeywordAlphabet(availableKeywords, sortOption), 
        [availableKeywords, sortOption]
    );
       
    return (
        <div className="keyword-management-container">
            <span className="keywordHeading">
                {trans("manageKeyword.Check_keywords_to_assign_them_&_uncheck_them_to_un-assign")}
            </span>
            <div className="keyword-management">

                {/* Assigned Keywords Section */}
                <div className="assigned-keywords-wrapper">
                    <span className="keywords-subTitle">
                        {trans("manageKeyword.Keyword_assigned_to")} ‘{categoryName}’
                    </span>
                    <span className="keywordHeading">
                        {assignedKeywords?.length} {trans("manageKeyword.keywords")}
                    </span>
                    {assignedKeywords?.length === 0 && <div className="keyword-section-no-data">
                        <Image as="span" src="/img/icon-no-category.svg" alt="No Keywords" />
                        <p>{trans("manageKeyword.No_keywords_selected_yet")}</p>
                    </div>
                    }


                    {assignedKeywords?.length !== 0  && <KeywordItem
                        content={trans("manageKeyword.Select_All")}
                        trans={trans}
                        checked={assignedKeywords.every((k) => k.selected)}
                        onChange={toggleSelectAllAssigned}
                        disabled={false}
                    />
                    }

                    {assignedKeywords?.length !== 0  
                    && <Scrollbars
                        autoHeight
                        autoHeightMin="calc(1vh)"
                        autoHeightMax={"325px"}                          
                    >
                        
                        {groupedAssignedKeywords?.map(({ label, options }) => (
                            <div key={label}>
                                <span className="keywordHeading">{label}</span>
                                <hr className="keyword-separator" />
                                {options.map((option) => (
                                    <div className="keyword-item-container" key={option.label}>
                                        <KeywordItem
                                            content={option.label}
                                            checked={option.selected}
                                            disabled={option.disabled}
                                            trans={trans}
                                            onChange={() => toggleAssignedKeyword(
                                                assignedKeywords.findIndex((k) => k.name === option.label)
                                            )}
                                        />
                                    </div>
                                ))}
                            </div>
                        ))}
                    </Scrollbars>   }

                </div>

                {/* Middle Section with Buttons */}
                <div className="keyword-arrow-container">
                   
                    <Button
                        icon
                        disabled={!availableKeywords?.some((keyword) => keyword.selected)}  // Disable if no available keyword is selected
                        onClick={moveToAssigned}
                        className="keyword-arrow-button"                      
                    >
                        <Icon name="chevron left" />
                    </Button>               
                
                    <Button
                        icon
                        onClick={moveToAvailable}
                        disabled={!assignedKeywords?.some((keyword) => keyword.selected )}  
                        className="keyword-arrow-button"
                    >
                        <Icon name="chevron right" />
                    </Button>   
                </div>

                {/* Available Keywords Section */}
                <div className="assigned-keywords-wrapper">
                    <span className="keywords-subTitle">{trans("manageKeyword.Other_Available_Keywords")}</span>
                  
                  
                    <span className="keywordHeading">
                        {availableKeywords?.length} {trans("manageKeyword.keywords")}
                    </span>

                    {availableKeywords?.length === 0 && <div className="keyword-section-no-data">
                        <Image as="span" src="/img/icon-no-category.svg" alt="No Keywords" />
                        <p>{trans("manageKeyword.No_other_keywords_available")}</p>
                    </div>
                    }

                    {availableKeywords?.length !== 0  && <KeywordItem
                        content={trans("manageKeyword.Select_All")}
                        trans={trans}
                        checked={availableKeywords.every((k) => k.selected)}
                        onChange={toggleSelectAllAvailable}
                        disabled={false}
                    />
                    }

                
                    {availableKeywords?.length !== 0   
                       && <Scrollbars
                           autoHeight
                           autoHeightMin="calc(1vh)"
                           autoHeightMax={"325px"}                          
                       >
                        
                           {groupedAvailableKeywords?.map(({ label, options }) => (
                               <div key={label}>
                                   <span className="keywordHeading">{label}</span>
                                   <hr className="keyword-separator" />
                                   {options.map((option) => (
                                       <div className="keyword-item-container" key={option.label}>
                                           <KeywordItem
                                               content={option.label}
                                               checked={option.selected}
                                               disabled={option.disabled}
                                               trans={trans}
                                               onChange={() => toggleAvailableKeyword(
                                                   availableKeywords.findIndex((k) => k.name === option.label)
                                               )}
                                           />
                                       </div>
                                   ))}
                               </div>
                           ))}
                       </Scrollbars>
                    }
                </div>
            </div>

            <div className="button-container">
                <Button
                    content={trans("manageKeyword.Update_Keywords")}
                    className="ui red basic button"
                    onClick={updateKeywords}
                    disabled={!hasChanges}

                />
            </div>
        </div>    
    );
};

export const KeywordItem = ({ content, checked, disabled, onChange, trans }) => (
    <div className="groupCategoryItem">
        <div className="groupCategoryItemButton">
            <CheckboxGroup
                checked={checked}
                onChange={onChange}
            />
            <p>
                {content} {disabled && <span className="keyword-warning-text"> ({trans("manageKeyword.Doesnt_exist_anymore")})</span>}
            </p>
        </div>

    </div>
);

export const HeaderSection = ({ setSelectedBuilding, buildingOptions, trans, sortOptions, sortOption, setSortOption }) => (
    <div >
        <div className="keyword-section-header">           
           
            <div className="header-section ">
                {/* <b className="header-text">{trans("manageKeyword.Building_Name")}</b>
                <Dropdown
                    placeholder="Select"
                    fluid
                    style={{ width: 200, height: "35px" }}
                    selection
                    options={[{ key: "Unselect", text: trans("manageKeyword.Unselect"), value: "" }, ...buildingOptions]}
                    onChange={(e, { value }) => setSelectedBuilding(value)}
                /> */}
            </div>
            <div className="header-section header-right">
                <b >{trans("manageKeyword.Sort_By")}</b>
                <Dropdown
                    placeholder="Sort by - Name (A-Z)"
                    fluid
                    style={{ width: 180, height: "35px" }}
                    selection
                    options={sortOptions}
                    onChange={(e, { value }) => setSortOption(value)}
                    value={sortOption}
                />
            </div>        

        </div>   

        <div className="keyword-section-subtitle"> {trans("manageKeyword.Manage_keywords_for_your_property")} </div>

    </div>
);

export const NoKeywordsMessage = ({ trans }) => (
    <div className="keyword-section-container">
        <div className="keyword-section-no-data">
            <Image as="span" src="/img/icon-no-category.svg" alt="No Keywords" />
            <p>{trans("manageKeyword.There_are_no_keywords_assigned_to_entities_with_the_selected_category_yet")}</p>
        </div>
    </div>
);

const Item = ({ className, isActive, content, iconInfo, children, sideComponents, onClick }) => 
{
    const [showChildren, setShowChildren] = useState(true);
    const { url } = iconInfo;

    return (<div className={`keywordCategoryItemRoot ${className || ""}`}>
        <div className={`keywordCategoryItem-content ${isActive ? "active" : ""}`} onClick={onClick}>
            <div className="keywordCategoryItem-name">
                <Image className="keywordCategoryItemRoot-icon" as="span" src={url} />
                <p>{content}</p>
                {children?.length > 0 && <ButtonIcon className="keyword-chevron-icons" icon={`chevron-${showChildren ? "up" : "down"}`} onClick={(e) => 
                {
                    e.stopPropagation();
                    setShowChildren((prev) => !prev);
                }
                } />}
            </div>
            <div>
                {sideComponents}
            </div>
        </div>
        {showChildren && children && <div className="keyword-subCategoryCover">{children}</div>}
    </div>);
};
