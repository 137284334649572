import zango from "zangodb";
import { LATEST_VERSION, INDEXED_DB_NAME, createZangoCollectionMap, initDB } from "./collections/v1";
import { CollectionNameMap } from "./collections/v1/collection";
import { asyncForEach } from "mapsted.utils/arrays";
import ObjectId from "bson-objectid";
export class localDB
{
    // this.indexedDB
    constructor()
    {
        //connect to DB (dbName, version)
        let request = indexedDB.open(INDEXED_DB_NAME, LATEST_VERSION);

        request.onerror = (e) =>
        {
            console.log("Index DB had an issue connecting", e);
        };
        request.onsuccess = (e) =>
        {
            this.indexedDB = request.result;

            // pull collections from version
            // db name should be constant
            const zangoCollectionMap = createZangoCollectionMap();
            this.zangoDB = new zango.Db(INDEXED_DB_NAME, zangoCollectionMap);
        };
        request.onupgradeneeded = (e) =>
        {
            this.indexedDB = request.result;

            initDB(this.indexedDB);

        };
    }
}

class mapEditorLocalDB_v1 extends localDB
{
    constructor()
    {
        super();
    }

    /**
     *
     * @param {String} collectionName
     * @param {Array} options
     */
    async find(collectionName, options = {})
    {
        try
        {
            let collection = this.zangoDB.collection(collectionName);
            return await collection.find(options)
                .toArray();
        }
        catch (err)
        {
            console.log(`error finding items from ${collectionName}`, options, err);
            return false;
        }
    }

    /**
   *
   * @param {String} collectionName
   * @param {Array} options
   */
    async findOne(collectionName, options = {})
    {
        try
        {
            let collection = this.zangoDB.collection(collectionName);
            return await collection.findOne(options);
        }
        catch (err)
        {
            console.log(`error finding item from ${collectionName}`, options, err);
            return false;
        }
    }

    /**
     *
     * @param {String} collectionName
     * @param {Array} docs
     */
    async insertMany(collectionName, docs)
    {
        try
        {

            if (Array.isArray(docs) && docs.length > 0)
            {
                let collection = this.zangoDB.collection(collectionName);

                await collection.insert(docs);
            }
            else if (!Array.isArray(docs) && !!docs)
            {
                let collection = this.zangoDB.collection(collectionName);

                await collection.insert([docs]);
            }


            return true;
        }
        catch (err)
        {
            console.log(`error inserting items to ${collectionName}`, docs, err);
            return false;
        }
    }

    /**
     *
     * @param {*} collectionName
     * @param {*} docs
     */
    async updateMany(collectionName, docs)
    {
        try
        {
            let collection = this.zangoDB.collection(collectionName);

            await asyncForEach(docs, async (doc) =>
            {
                let filter = { _id: doc._id };
                await collection.update(filter, doc);
            });

            return true;
        }
        catch (err)
        {
            console.log(`error updating items from ${collectionName}`, docs, err);
            return false;
        }
    }

    /**
     *
     * @param {*} collectionName
     * @param {*} docs
     */
    async deleteMany(collectionName, docs)
    {
        try
        {
            let collection = this.zangoDB.collection(collectionName);

            await asyncForEach(docs, async (doc) =>
            {
                let filter = { _id: doc._id };
                await collection.remove(filter);
            });

            return true;
        }
        catch (err)
        {
            console.log(`error deleting items from ${collectionName}`, docs, err);
            return false;
        }
    }

    /**
     *
     * @param {String} collectionName
     * @param {Array} docs
     */
    async deleteAll(collectionName)
    {
        try
        {
            let collection = this.zangoDB.collection(collectionName);

            await collection.remove({});

            return true;
        }
        catch (err)
        {
            console.log(`error inserting items to ${collectionName}`,  err);
            return false;
        }
    }

    /**
     *
     * @param {*} param0
     */
    async handleUpdateLocalNodes({
        propertyId, buildingId, floorId, selectedTool, autoLinkId, nodeStepData,
    })
    {
        if (Array.isArray(nodeStepData.addedNodes) && nodeStepData.addedNodes.length > 0)
        {
            await this.insertMany(CollectionNameMap.nodes, nodeStepData.addedNodes);
        }

        if (Array.isArray(nodeStepData.updatedNodesAfter) && nodeStepData.updatedNodesAfter.length > 0)
        {
            await this.updateMany(CollectionNameMap.nodes, nodeStepData.updatedNodesAfter);
        }

        if (Array.isArray(nodeStepData.deletedNodes) && nodeStepData.deletedNodes.length > 0)
        {
            await this.deleteMany(CollectionNameMap.nodes, nodeStepData.deletedNodes);
        }

        let objectId = ObjectId();
        let step = {
            _id: objectId.toString(),
            propertyId,
            buildingId,
            floorId,
            selectedTool,
            autoLinkId,
            data: nodeStepData,
            updatedAt: new Date()
        };

        await this.insertMany(CollectionNameMap.steps, [step]);
    }

    /**
     *
     * @param {*} param0
     */
    async handleUpdateLocalEntities({
        propertyId, buildingId, floorId, selectedTool, entityStepData,
    })
    {
        if (Array.isArray(entityStepData.addedEntities) && entityStepData.addedEntities.length > 0)
        {
            await this.insertMany(CollectionNameMap.entities, entityStepData.addedEntities);
        }

        if (Array.isArray(entityStepData.updatedEntitiesAfter) && entityStepData.updatedEntitiesAfter.length > 0)
        {
            await this.updateMany(CollectionNameMap.entities, entityStepData.updatedEntitiesAfter);
        }

        if (Array.isArray(entityStepData.deletedEntities) && entityStepData.deletedEntities.length > 0)
        {
            await this.deleteMany(CollectionNameMap.entities, entityStepData.deletedEntities);
        }

        let objectId = ObjectId();
        let step = {
            _id: objectId.toString(),
            propertyId,
            buildingId,
            floorId,
            selectedTool,
            data: entityStepData,
            updatedAt: new Date()
        };

        await this.insertMany(CollectionNameMap.steps, [step]);
    }


    async handleUpdateLocalFloorPlan({ propertyId, buildingId, floorId, selectedTool, floorPlanStepData }, skipAddingToStep = false)
    {
        await this.deleteAll(CollectionNameMap.floorPlan);
        if (Array.isArray(floorPlanStepData.updatedGeoRefAfter.geoRefGeoJsonFeatures.features) && floorPlanStepData.updatedGeoRefAfter.geoRefGeoJsonFeatures.features.length > 0)
        {
            floorPlanStepData.updatedGeoRefAfter.updatedAt = new Date();
            await this.insertMany(CollectionNameMap.floorPlan, [floorPlanStepData.updatedGeoRefAfter]);
        }

        if (!skipAddingToStep)
        {

            let objectId = ObjectId();
            let step = {
                _id: objectId.toString(),
                propertyId,
                buildingId,
                floorId,
                selectedTool,
                data: floorPlanStepData,
                updatedAt: new Date()
            };

            await this.insertMany(CollectionNameMap.steps, [step]);
        }

    }

    async handleUpdateLocalTransitions({ propertyId, buildingId, floorId, georeferenceBuildingId, georeferenceFloorId, selectedTool, transitionStepData })
    {
        if (Array.isArray(transitionStepData.addedTransitions) && transitionStepData.addedTransitions.length > 0)
        {
            await this.insertMany(CollectionNameMap.transitions, transitionStepData.addedTransitions);
        }

        if (Array.isArray(transitionStepData.updatedTransitionsAfter) && transitionStepData.updatedTransitionsAfter.length > 0)
        {
            await this.updateMany(CollectionNameMap.transitions, transitionStepData.updatedTransitionsAfter);
        }

        if (Array.isArray(transitionStepData.deletedTransitions) && transitionStepData.deletedTransitions.length > 0)
        {
            await this.deleteMany(CollectionNameMap.transitions, transitionStepData.deletedTransitions);
        }

        const step = {
            _id: ObjectId().toString(),
            propertyId,
            buildingId,
            floorId,
            georeferenceBuildingId,
            georeferenceFloorId,
            selectedTool,
            data: transitionStepData,
            updatedAt: new Date()
        };

        await this.insertMany(CollectionNameMap.steps, [step]);
    }


    async handleGetLastStep(index = 0)
    {
        let collection = this.zangoDB.collection(CollectionNameMap.steps);
        let steps = await collection.find().toArray();

        steps.sort((a, b) => b.updatedAt.getTime() - a.updatedAt.getTime());

        return steps[index];
    }

    /**
     * Deletes all edit types from various collections.
     *
     * @param {} -
     * @return {Promise<void>}
     */
    async deleteAllAllEditTypes()
    {
        await this.deleteAll(CollectionNameMap.nodes, []);
        await this.deleteAll(CollectionNameMap.entities, []);
        await this.deleteAll(CollectionNameMap.transitions, []);
        await this.deleteAll(CollectionNameMap.steps, []);
        await this.deleteAll(CollectionNameMap.transitions, []);
        await this.deleteAll(CollectionNameMap.lock, []);
    }

}

export default new mapEditorLocalDB_v1();
