import React from "react";
import { Button } from "semantic-ui-react";
import { ModalBox } from "../../../common/ModalBox";
import { useTranslation } from "react-i18next";

const RevertToDefaultConfirmModal = ({
    open,
    onConfirm,
    onClose,
}) =>     
{
    const trans = useTranslation().t;
    return <ModalBox className="confirmActionModal"
        open={open}
        onClose={onClose}
        header={trans("Cancel_Confirmation_Modal.Confirm_Action")}
        actions={<Button color="orange" floated="right" content={trans("common.Confirm")} onClick={onConfirm} />}>
        <p className="p-modal">
            {trans("Cancel_Confirmation_Modal.Apply_Default_Styles")}
        </p>
    </ModalBox>;
};

export default RevertToDefaultConfirmModal;