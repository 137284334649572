import React from "react";
import PropTypes from "prop-types";
import { Header, Button, Input } from "semantic-ui-react";
import BrandingContext from "../../../store/BrandingContext";
import { InputFormGroup } from "../../elements/InputFormGroup";
import { deepValue } from "mapsted.utils/objects";
import { DEFAULT_LANGUAGE_CODE, KEY_CODES } from "../../../_constants/constants";
import { withTranslation } from "react-i18next";

class SelectEntityPopup extends React.Component
{
    static contextType = BrandingContext;

    constructor(props)
    {
        super();

        this.state = {
            storeLongName: "",
            isOpen: !!props.selectedEntityId,
        };
    }

    shortCutHandler = (e) =>
    {
        const keyCode = e.keyCode;

        if (keyCode === KEY_CODES.ESCAPE)
        {
            this.handleClosePopup();
        }
        else if (keyCode === KEY_CODES.ENTER)
        {
            this.handleUpdateEntityLabel();
        }
    }

    componentDidUpdate (prevProps)
    {
        if (prevProps.selectedEntityId !== this.props.selectedEntityId)
        {
            this.setState({
                storeLongName: "",
                isOpen: !!this.props.selectedEntityId,
            });
        }

        if (prevProps.isOpen !== this.props.isOpen)
        {
            if (this.props.isOpen)
            {
                document.addEventListener("keydown", this.shortCutHandler, false);
            }
            else
            {
                document.removeEventListener("keydown", this.shortCutHandler, false);
            }
        }
    }

    handleUpdateEntityLabel = async () =>
    {
        const { storeLongName } = this.state;

        let template = undefined;

        // if the name was added
        if (storeLongName)
        {
            template = { longName: { [DEFAULT_LANGUAGE_CODE]: storeLongName } };
        }

        if (template)
        {
            await this.context.createNewStore(template);
        }
        this.context.setEditUnsavedChanges(false);
    }

    handleStoreNameChange = (name) =>
    {
        this.setState({
            storeLongName: name
        });
    };

    handleClosePopup = () =>
    {
        this.setState({ isOpen: false });
        this.context.deselectVacantStore();
    }

    isButtonDisabled = () =>
    {
        const { storeLongName } = this.state;
        const isvalidStoreLongName = !/^\s{0,2}\S.*\S\s{0,5}$/.test(storeLongName);
      
        return isvalidStoreLongName;
    }

    render ()
    {
        const { storeLongName, isOpen } = this.state;
        const { selectedEntityId, t } = this.props;
        const { mapData, editUnsavedChanges } = this.context.state;

        /** @type {typeof import("../../../_intl/resources.json")["en"]["translation"]["select entity popup"]} */
        const trans = t("select entity popup");

        // Open when entity is selected, but entity has not EntityLabel
        const shouldOpen = !!selectedEntityId && !deepValue(mapData.entities, `${selectedEntityId}.entityLabel`, false);

        return (
            <div className={editUnsavedChanges ? "disabledLayer" : ""}>
                {(isOpen && shouldOpen) && (
                    <div className="customPopup customStore">
                        <Button className="close" onClick={this.handleClosePopup} />
                        <Header>{trans["header"]}</Header>
                        <InputFormGroup flexed label={trans["store name label"]}>
                            <Input
                                placeholder="e.g. Urban Outfitters"
                                value={storeLongName}
                                onChange={(e, { value }) => this.handleStoreNameChange(value)} />
                        </InputFormGroup>

                        <div className="actionsGroup">
                            <Button disabled={this.isButtonDisabled()} primary tabIndex={1} content={trans["save button"]} onClick={this.handleUpdateEntityLabel} />
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default withTranslation()(SelectEntityPopup);

SelectEntityPopup.propTypes = {
    selectedEntityId: PropTypes.string,
};
