import React, { forwardRef, useImperativeHandle } from "react";
import { Button, Modal, ModalHeader, Tab } from "semantic-ui-react";

import IconsManagementProvider, {
    useIconsManagementContext,
} from "./IconsManagement/IconsManagementContext";
import ImagesBaseProvider, {
    useImagesBaseContext,
} from "./ImagesManagement/contexts/ImageBaseContext";
import GmsProvider, {
    GmsActionMode,
    TAB_MENU_ITEMS,
    useGmsContext,
} from "./GmsContext";
import "./Gms.css";
import { ButtonIcon } from "../../common/ButtonIcon";
import { useImageDeleteContext } from "./ImagesManagement/contexts/ImageDeleteContext";

const GmsComponent = forwardRef((props, ref) => 
{
    const gmsContext = useGmsContext();
    const imagesBaseContext = useImagesBaseContext();
    const imageDeleteContext = useImageDeleteContext();
    const iconsManagementContext = useIconsManagementContext();
    const { tabs, gmsActionMode, handleChangeActiveIndex, activeIndex, currentTab } = gmsContext;
    const isImageManagementActive = currentTab.menuItem === TAB_MENU_ITEMS.IMAGES;

    useImperativeHandle(ref, () => ({
        openGms: gmsContext.handleTriggerOpenGms,
        linkCoverImages: imagesBaseContext.handleTriggerLinkCoverImages,
        linkIconImage: imagesBaseContext.handleTriggerLinkIconImage,
        linkIcon: iconsManagementContext.handleTriggerLinkIcon,
    }));

    function getGmsHeaderTitle() 
    {
        if (gmsActionMode === GmsActionMode.VIEW) return "Gallery";
        else if (gmsActionMode === GmsActionMode.LINK_ICON) return "Select Icons";
        else if (gmsActionMode === GmsActionMode.LINK_COVER_IMAGES) return "Select Images";
        else if (gmsActionMode === GmsActionMode.LINK_ICON_IMAGE) return "Select Image";
        else if (gmsActionMode === GmsActionMode.REPLACE_IMAGE) return "Choose New Image";
    }


    return (
        <Modal
            dimmer={{
                className: imagesBaseContext.hiddenModals.gmsModal ? "dimmer-hidden" : "",
            }}
            closeIcon={<Button onClick={gmsContext.handleCloseGms} className="close" />}
            onClose={gmsContext.handleCloseGms}
            className="gms"
            open={!!gmsActionMode}
        >
            <ModalHeader className="gms-header">
                <div  className="gms-header-title">               
                    <div>{getGmsHeaderTitle()}</div>
                </div>

                {isImageManagementActive && (
                    <ButtonIcon
                        onClick={imagesBaseContext.handleTriggerRecycleBin}
                        className={"recycle-bin-trigger"}
                        icon="delete-red"
                    ></ButtonIcon>
                )}
                <Tab
                    activeIndex={activeIndex}
                    onTabChange={(e, data) => 
                    {
                        handleChangeActiveIndex(data.activeIndex);
                    }}
                    menu={{
                        attached: true,
                        tabular: true,
                        style: {
                            display: "flex",
                            justifyContent: "flex-end",
                        },
                    }}
                    className={`gms-tabs ${isImageManagementActive ? "gms-tabs-image-management-active" : ""
                    }`}
                    panes={tabs}
                />
            </ModalHeader>
        </Modal>
    );
});

// aditional wrapper for reset
function GmsAdditionalWrapperComp(props, ref) 
{
    const gmsContext = useGmsContext();
    const { onAddImage, onEditImage, onDeleteImage, onAddIcon, croppingEnabled = true, bulkUploadEnabled = true, aspectRatioFilterEnabled = true } = props;

    return <ImagesBaseProvider
        key={gmsContext.closeGms ? "reset" : "images"}
        onAddImage={onAddImage} onEditImage={onEditImage} 
        onDeleteImage={onDeleteImage} 
        croppingEnabled={croppingEnabled} 
        bulkUploadEnabled={bulkUploadEnabled} 
        aspectRatioFilterEnabled={aspectRatioFilterEnabled}>
        <IconsManagementProvider
            key={gmsContext.closeGms ? "reset" : "icons"}
            onAddIcon={onAddIcon}>
            <GmsComponent ref={ref} {...props}></GmsComponent>
        </IconsManagementProvider>
    </ImagesBaseProvider>;
}

const GmsAdditionalWrapper = forwardRef(GmsAdditionalWrapperComp);

function GmsWrapper(props, ref) 
{
    return (
        <GmsProvider>
            <GmsAdditionalWrapper {...props} ref={ref}></GmsAdditionalWrapper>
        </GmsProvider>
    );
}

GmsComponent.displayName = "GmsComponent";

export const Gms = forwardRef(GmsWrapper);
