import React from "react";
import { useTranslation } from "react-i18next";
import { SINGLE_LANG_INPUT_CODE } from "../../_constants/constants";
import { InputFormGroup } from "../elements/InputFormGroup";
import { InitialDetailBox } from "./InitialDetailBox";

export const BuildingDetailBox = React.memo(({ edit, setEdit, building, buildingTypes }) =>
{
    /** @type {typeof import("../../_intl/resources.json")["en"]["translation"]["building detail box"]} */
    const trans = useTranslation().t("building detail box");
    const handleOnClick = React.useCallback(() => setEdit(!edit), [edit, setEdit]);

    return (
        <InitialDetailBox action="pencil" onClick={handleOnClick}>
            <InputFormGroup
                label={trans["name label"]}
                detail={building?.longName?.[SINGLE_LANG_INPUT_CODE]}
            />
            <InputFormGroup label={trans["address label"]} detail={building.fullAddress} />
            <InputFormGroup
                label={trans["website label"]}
                detail={building?.website?.[SINGLE_LANG_INPUT_CODE]}
            />
            <InputFormGroup label={trans["phone label"]} detail={building.phoneNumbers} />
        </InitialDetailBox>
    );
});

BuildingDetailBox.displayName = "BuildingDetailBox";
