import React, { useContext } from "react";
import { Tab } from "semantic-ui-react";
import { get as getDeepValue } from "lodash";
import { CheckboxGroup } from "../../common/CheckboxGroup";
import { SettingsHeading } from "../SettingsHeading";
import { SettingsContext } from "../SettingsProvider";
import { DividerLine } from "../../common/DividerLine";
import { Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { UNIT_SYSTEM } from "../../../_constants/constants";

const AdvancedCustomizationOptions = ({ isOpen, onChangeValue }) =>
{
    const { mergedSettings: settings, disableGeolocationWebSettings } = useContext(SettingsContext);

    const trans = useTranslation().t;
    const renderDesktopTab = () =>
    {
        const path = "mapOptions.desktop";
        const {
            showSearchMap = true,
            showThemeToggle = true,
            showCategories = true,
            showLogo = true,
            showZoomControls = true,
            showQrCode = true,
            showLanguageSelector = true,
            showFloorSelector = true
        } = getDeepValue(settings, path, {});

        return (
            <Tab.Pane>
                <div className="configureMapOptions">
                    <CheckboxGroup
                        label={trans("Settings.Search_Map")}
                        checked={showSearchMap}
                        onChange={(e, { checked }) => onChangeValue(`${path}.showSearchMap`, checked)}
                    />
                    <CheckboxGroup
                        label={trans("Settings.Themes")}
                        checked={showThemeToggle}
                        onChange={(e, { checked }) => onChangeValue(`${path}.showThemeToggle`, checked)}
                    />
                    <CheckboxGroup
                        label={trans("Settings.Categories")}
                        checked={showCategories}
                        onChange={(e, { checked }) => onChangeValue(`${path}.showCategories`, checked)}
                    />
                    <CheckboxGroup
                        label={trans("Settings.Logo")}
                        checked={showLogo}
                        onChange={(e, { checked }) => onChangeValue(`${path}.showLogo`, checked)}
                    />
                    <CheckboxGroup
                        label={trans("Settings.Zoom_Controls")}
                        checked={showZoomControls}
                        onChange={(e, { checked }) => onChangeValue(`${path}.showZoomControls`, checked)}
                    />
                    <CheckboxGroup
                        label={trans("Settings.QR_Code")}
                        checked={showQrCode}
                        onChange={(e, { checked }) => onChangeValue(`${path}.showQrCode`, checked)}
                    />
                    <CheckboxGroup
                        label={trans("Settings.Languages")}
                        checked={showLanguageSelector}
                        onChange={(e, { checked }) => onChangeValue(`${path}.showLanguageSelector`, checked)}
                    />
                    <CheckboxGroup
                        label={trans("Settings.Floors")}
                        checked={showFloorSelector}
                        onChange={(e, { checked }) => onChangeValue(`${path}.showFloorSelector`, checked)}
                    />
                </div>

                <div className="configureMapBox">
                    <div className="configureMapLeft">
                        <ConfigureMapItem
                            className="SearchMap"
                            img="search"
                            disabled={!showSearchMap}
                        />
                    </div>
                    <div className="configureMapRight">
                        <ConfigureMapItem
                            className="logoMap"
                            img="logo"
                            disabled={!showLogo}
                        />
                        <ConfigureMapItem
                            className="languageMap"
                            img="group"
                            disabled={!showLanguageSelector}
                        />
                        <ConfigureMapItem
                            className="themeModeMap"
                            img="group"
                            disabled={!showThemeToggle}
                        />
                        <ConfigureMapItem
                            className="zoomMap"
                            img="zoom"
                            disabled={!showZoomControls}
                        />
                        <ConfigureMapItem
                            className="floorsMap"
                            img="group"
                            disabled={!showFloorSelector}
                        />
                        <ConfigureMapItem
                            className="categoriesMap"
                            img="group"
                            disabled={!showCategories}
                        />
                        <ConfigureMapItem
                            className="qrMap"
                            img="qr"
                            disabled={!showQrCode}
                        />
                    </div>
                </div>
            </Tab.Pane>
        );
    };

    const renderMobileTab = () =>
    {
        const path = "mapOptions.mobile";
        const {
            showSearchMap = true,
            showThemeToggle = true,
            showCategories = true,
            showLogo = true,
            showZoomControls = true,
            showLanguageSelector = true,
            showFloorSelector = true
        } = getDeepValue(settings, path, {});

        return (
            <Tab.Pane>
                <div className="configureMapOptions">
                    <CheckboxGroup
                        label={trans("Settings.Search_Map")}
                        checked={showSearchMap}
                        onChange={(e, { checked }) => onChangeValue(`${path}.showSearchMap`, checked)}
                    />
                    <CheckboxGroup
                        label={trans("Settings.Themes")}
                        checked={showThemeToggle}
                        onChange={(e, { checked }) => onChangeValue(`${path}.showThemeToggle`, checked)}
                    />
                    {/* <CheckboxGroup
                        label="Categories"
                        checked={showCategories}
                        onChange={(e, { checked }) => onChangeValue(`${path}.showCategories`, checked)}
                    /> */}
                    {/* <CheckboxGroup
                        label="Logo"
                        checked={showLogo}
                        onChange={(e, { checked }) => onChangeValue(`${path}.showLogo`, checked)}
                    /> */}
                    <CheckboxGroup
                        label={trans("Settings.Zoom_Controls")}
                        checked={showZoomControls}
                        onChange={(e, { checked }) => onChangeValue(`${path}.showZoomControls`, checked)}
                    />
                    <CheckboxGroup
                        label={trans("Settings.Languages")}
                        checked={showLanguageSelector}
                        onChange={(e, { checked }) => onChangeValue(`${path}.showLanguageSelector`, checked)}
                    />
                    <CheckboxGroup
                        label={trans("Settings.Floors")}
                        checked={showFloorSelector}
                        onChange={(e, { checked }) => onChangeValue(`${path}.showFloorSelector`, checked)}
                    />
                </div>

                <div className="configureMapBox configureMapBoxMobile">
                    <ConfigureMapItem
                        className="logoMapMobile"
                        img="mobile-logo"
                        disabled={!showLogo}
                    />
                    <ConfigureMapItem
                        className="SearchMapMobile"
                        img="mobile-search"
                        disabled={!showSearchMap}
                    />
                    <ConfigureMapItem
                        className="floorMobile"
                        img="mobile-group"
                        disabled={!showFloorSelector}
                    />
                    <ConfigureMapItem
                        className="themeModeMap"
                        img="mobile-group"
                        disabled={!showThemeToggle}
                    />
                    <ConfigureMapItem
                        className="languageMobile"
                        img="mobile-group"
                        disabled={!showLanguageSelector}
                    />

                    <DividerLine isEmpty gap="70px 0" />

                    <ConfigureMapItem
                        className="zoomMap"
                        img="mobile-zoom"
                        disabled={!showZoomControls}
                    />
                    <ConfigureMapItem
                        className="groupMobile"
                        img="mobile-group"
                        disabled={!showCategories}
                    />

                    {/* <ConfigureMapItem className="footerMapMobile" img="mobile-footer" disabled /> */}
                </div>
            </Tab.Pane>
        );
    };

    const renderTextRotation = () => (
        <div className="settingsPageTabCover">
            <SettingsHeading heading={trans("Settings.Text_Free_Rotation")}>
                {
                    trans("Settings.Automatically_rotate_text_to_match_the_orientation_options_available_for_you")
                }            
            </SettingsHeading>
            <div className="settingsPageHalfCover">
                <CheckboxGroup
                    radio
                    label={trans("Settings.Enable")}
                    checked={settings.allowTextRotation}
                    onChange={() => onChangeValue("allowTextRotation", true)}
                />
                <CheckboxGroup
                    radio
                    label={trans("Settings.Disable")}
                    checked={!settings.allowTextRotation}
                    onChange={() => onChangeValue("allowTextRotation", false)}
                />
            </div>
        </div>
    );

    const renderIconRotation = () => (
        <div className="settingsPageTabCover">
            <SettingsHeading heading={trans("Settings.Icon_Free_Rotation")}>
                {
                    trans("Settings.Automatically_rotate_icon_to_match_the_orientation_options_available_for_you")
                }
            </SettingsHeading>
            <div className="settingsPageHalfCover">
                <CheckboxGroup
                    radio
                    label={trans("Settings.Enable")}
                    checked={settings.allowIconRotation}
                    onChange={() => onChangeValue("allowIconRotation", true)}
                />
                <CheckboxGroup
                    radio
                    label={trans("Settings.Disable")}
                    checked={!settings.allowIconRotation}
                    onChange={() => onChangeValue("allowIconRotation", false)}
                />
            </div>
        </div>
    );

    const renderUnitSystem = () => (
        <div className="settingsPageTabCover">
            <SettingsHeading heading={trans("Settings.Unit System")}>
                {
                    trans("Settings.Unit_System_Desc")
                }
            </SettingsHeading>
            <div className="settingsPageHalfCover">
                <CheckboxGroup
                    radio
                    label={trans("Settings.Metric")}
                    checked={settings.routingDistanceUnitSystem === UNIT_SYSTEM.METRIC}
                    onChange={() => onChangeValue("routingDistanceUnitSystem", UNIT_SYSTEM.METRIC)}
                />
                <CheckboxGroup
                    radio
                    label={trans("Settings.Imperial")}
                    checked={settings.routingDistanceUnitSystem === UNIT_SYSTEM.IMPERIAL}
                    onChange={() => onChangeValue("routingDistanceUnitSystem", UNIT_SYSTEM.IMPERIAL)}
                />
            </div>
        </div>
    );

    const renderGeoLocationWeb = () => (
        <div className="settingsPageTabCover">
            <SettingsHeading 
                className={`${disableGeolocationWebSettings ? "geolocationWebSettingHeading" : "" }`}
                heading={trans("Settings.GPS_Location")}>
                {trans("Settings.Allow_Maps_web_to_get_your_current_location_for_navigation_purpose")}
            </SettingsHeading>
            {disableGeolocationWebSettings && <Message 
                className="settingsInfo"
                size="small" 
                color="yellow">
                {
                    trans("Settings.This_feature_is_not_available_because_one_or_more_buildings_in_this_property_have_more_than_one_floor")
                }            
            </Message>}
            <div className="settingsPageHalfCover">
                <CheckboxGroup
                    radio
                    label={trans("Settings.Enable")}
                    checked={settings.allowGeolocationWeb}
                    onChange={() => onChangeValue("allowGeolocationWeb", true)}
                    disabled={disableGeolocationWebSettings}
                />
                <CheckboxGroup
                    radio
                    label={trans("Settings.Disable")}
                    checked={!settings.allowGeolocationWeb}
                    onChange={() => onChangeValue("allowGeolocationWeb", false)}
                    disabled={disableGeolocationWebSettings}
                />
            </div>
        </div>
    );



    if (!isOpen) return null;
    return (
        <div className="spaceCover">
            <div className="settingsPageTabPane settingsPageTabPaneModule">

                <div className="settingsPageTabCover">
                    <SettingsHeading heading={trans("Settings.Configure_Map_Options")}>
                        {trans("Settings.Select_the_options_that_you_want_to_appear_on_your_map")}
                    </SettingsHeading>

                    <Tab menu={{ secondary: true, pointing: true }}
                        panes={
                            [
                                {
                                    menuItem: trans("Settings.Desktop"),
                                    render: renderDesktopTab
                                },
                                {
                                    menuItem: trans("Settings.Mobile"),
                                    render: renderMobileTab
                                },
                            ]
                        }
                        className="configureTabs" />
                </div>

                {renderTextRotation()}
                {renderIconRotation()}
                {renderUnitSystem()}
                {renderGeoLocationWeb()}

            </div>
        </div>
    );
};

export default AdvancedCustomizationOptions;

const ConfigureMapItem = ({ disabled, className, img }) => (
    <span className={`${className}${disabled ? " disabled":""}`}>
        <img src={`/img/theme-${img}.svg`} alt="" />
    </span>
);
