import React from "react";
import { IconsInfoSection } from "../IconsManagementSidebar";
import IconsManagementSection from "../../IconsManagementSection/IconsManagementSection";
import "./IconsManagementSelectedIcon.css";
import { useIconsManagementContext } from "../../IconsManagementContext";
import { filerUrl } from "../../../../../../_utils/utils";
import ImageComponent from "../../../ImageComponent/ImageComponent";

function IconsManagementSelectedIcon() 
{
    const { selectedIconInfo } = useIconsManagementContext();

    return (
        <div className="icons-management-selected-icon">
            <IconsManagementSection>
                <IconsManagementSection.Header>
          Icon Symbol
                </IconsManagementSection.Header>
                <IconsManagementSection.Content>
                    <div className="icons-management-selected-icon-image">
                        {selectedIconInfo && (
                            <ImageComponent
                                imgSrc={filerUrl(selectedIconInfo.iconImage, 1800)}
                                alt={selectedIconInfo.name}
                            />
                        )}
                    </div>
                </IconsManagementSection.Content>
            </IconsManagementSection>
        </div>
    );
}

export default IconsManagementSelectedIcon;
