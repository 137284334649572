import React from "react";
import { ReactSVG } from "react-svg";
import Color from "color";
import { useState } from "react";
import { useEffect } from "react";
import { filerUrl } from "../../../_utils/utils";
import { DEFAULT_CATEGORY_ICON } from "../../../_constants/branding";

const HexColourRegExp = /^#[0-9A-F]{6}$/i;

export const StyledIcon = ({ imageSrc, title, iconColour, backgroundColour, className, size, style, format = "png" }) =>
{
    if (format === "png")
    {
        return <img src={imageSrc} title={title} style={{ ...style, width: size, height: size }} className={className} alt={title} />;
    }
    return <SvgStyledIcon imageSrc={imageSrc} iconColour={iconColour} backgroundColour={backgroundColour} className={className} size={size} style={style} />;
};

export const SvgStyledIcon = React.memo( ({ imageSrc, iconColour, backgroundColour, className, size, style }) =>
{
    const [url, setUrl] = useState(imageSrc + ".svg");
    useEffect(() =>
    {
        setUrl(imageSrc + ".svg");
    }, [imageSrc]);

    const fill = (elements, fillColor, skip = 0, length = undefined) =>
    {
        if (elements)
        {
            const elementsLength = length || elements.length;
            for (let i = skip; i < elementsLength; i++)
            {
                elements[i].setAttribute("style", fillColor);
            }
        }
    };

    const handleAdjustBackgroundColour = (svg, color) =>
    {
        let elements = svg.querySelectorAll("circle");

        const fillColor = `fill:rgba(${color.r}, ${color.g}, ${color.b}, 1)`;
        fill(elements, fillColor, 0, 1);
    };

    const handleAdjustForegroundColour = (svg, color) =>
    {
        const path = svg.querySelectorAll("path");
        const circle = svg.querySelectorAll("circle");

        const fillColor = `fill:rgba(${color.r}, ${color.g}, ${color.b}, 1)`;

        fill(path, fillColor);
        fill(circle, fillColor, 1);
    };

    const handleAdjustSVG = (svg) =>
    {
        svg.setAttribute("height", size);
        svg.setAttribute("width", size);

        HexColourRegExp.test(backgroundColour) && handleAdjustBackgroundColour(svg, Color(backgroundColour).object());
        HexColourRegExp.test(iconColour) && handleAdjustForegroundColour(svg,  Color(iconColour).object());
    };

    return (
        <ReactSVG
            style={style}
            className={className}
            src={url}
            evalScripts="always"
            loading={() => <span>Loading...</span>}
            beforeInjection={handleAdjustSVG}
            afterInjection={(err) =>
            {
                err && setUrl(filerUrl(DEFAULT_CATEGORY_ICON.iconImage) + ".svg");
            }}
        />
    );
} );

SvgStyledIcon.displayName = "SvgStyledIcon";
