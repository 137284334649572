import { Input } from "semantic-ui-react";
import { TextGroup } from "../TextGroup";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { debounce } from "../../../_utils/utils";
import Scrollbars from "react-custom-scrollbars-2";
import { CheckboxGroup } from "../CheckboxGroup";
import { CategoryContext } from "../../../store/CategoryProvider";
import { useTranslation } from "react-i18next";
import { DEFAULT_LANGUAGE_CODE } from "../../../_constants/constants";
import { isEmpty } from "lodash";
import "./ComponentTreeDisplay.css";

const CategoryTreeDisplay = ({ 
    CategoryItem=null, 
    Heading=null,
    className, 
    searchClassName=null, 
    showAllCategories = true, 
    selectedGroup = null, 
    showCheckBox = false, 
    searchable = false, 
    enableSelectAll = false, 
    hideSubCategories = false,
    autoHeightMin = "250px", 
    autoHeightMax = "250px", 
    lang=DEFAULT_LANGUAGE_CODE,
    removeCateogries=[],
    noResultMessage,
    onChange 
}) => 
{
    const trans = useTranslation().t;
    const [categorySearchQuery, setCategorySearchQuery] = useState(null);
    const [selectAllCheck, setSelectAllCheck] = useState(false);
    const categoryCtx = useContext(CategoryContext);
    const [categoryTree, setCategoryTree] = useState({});
    
    useEffect(() => 
    {
        initializeCategoryTree();
        setSelectAllCheck(false);
    }, [selectedGroup, categoryCtx.categoryFlatMap]);

    useEffect(() => 
    {
        if (onChange) 
        {
            onChange({
                categoryTree: { ...categoryTree }
            });
        }
    }, [categoryTree]);

    const handleCategorySearchChange = useCallback(
        debounce((value) => 
        {
            setCategorySearchQuery(value);
        }, 500),
        []
    );


    /**
     * Update indeterminate flag of category
     * @param {Object} updatedCategoryTree
     * @param {String} groupId
     * @param {String} categoryId
     * @param {Boolean} checked
     */
    const updateCategoryIndeterminateStatus = (updatedCategoryTree, groupId, categoryId, checked) => 
    {
        if (checked && !updatedCategoryTree[groupId].categories[categoryId].checked) 
        {
            updatedCategoryTree[groupId].categories[categoryId].indeterminate = true;
        }

        if (!checked && updatedCategoryTree[groupId].categories[categoryId].indeterminate) 
        {
            updatedCategoryTree[groupId].categories[categoryId].indeterminate = Object.keys(updatedCategoryTree[groupId].categories[categoryId].subCategories)
                .filter((subCategoryId) => updatedCategoryTree[groupId].categories[categoryId].subCategories[subCategoryId].checked)
                .length > 0;
        }
    };

    /**
     * Update indeterminate flag of group
     * @param {Object} updatedCategoryTree
     * @param {String} groupId
     * @param {Boolean} checked
     */
    const updateGroupIndeterminateStatus = (updatedCategoryTree, groupId, checked) => 
    {
        if (checked && !updatedCategoryTree[groupId].checked) 
        {
            updatedCategoryTree[groupId].indeterminate = true;
        }

        if (!checked && updatedCategoryTree[groupId].indeterminate) 
        {
            updatedCategoryTree[groupId].indeterminate = Object.keys(updatedCategoryTree[groupId].categories)
                .filter((categoryId) => updatedCategoryTree[groupId].categories[categoryId].checked
                    || updatedCategoryTree[groupId].categories[categoryId].indeterminate)
                .length > 0;
        }
    };


    /**
     * Updates checked flag of all categories belonging to the given group
     * @param {Boolean} checked
     * @param {String} groupId
     * @param {Object} updatedCategoryTree
     */
    const updateAllCategories = (checked, groupId, updatedCategoryTree) => 
    {
        Object.keys(updatedCategoryTree[groupId].categories).forEach((categoryId) => 
        {
            updatedCategoryTree[groupId].categories[categoryId].checked = checked;
            updatedCategoryTree[groupId].categories[categoryId].indeterminate = false;
            updateAllSubCategories(checked, categoryId, groupId, updatedCategoryTree);
        });
    };

    /**
     * Updates checked flag of all sub categories belonging to the given category and group
     * @param {Boolean} checked
     * @param {String} categoryId
     * @param {String} groupId
     * @param {Object} updatedCategoryTree
     */
    const updateAllSubCategories = (checked, categoryId, groupId, updatedCategoryTree) => 
    {
        Object.keys(updatedCategoryTree[groupId].categories[categoryId].subCategories).forEach((subCategoryId) => 
        {
            updatedCategoryTree[groupId].categories[categoryId].subCategories[subCategoryId].checked = checked;
            updatedCategoryTree[groupId].categories[categoryId].subCategories[subCategoryId].indeterminate = false;
        });
    };

    /**
         * Returns icon info
         * Note: Here categoryId refers to category tree component in general (groups, categories and sub categories)
         * and not specific to category level
         * @param {String} categoryId
         * @returns {Object}
         */
    const getIconInfo = (categoryId) => categoryCtx.getIconInfo(categoryCtx.categoryFlatMap[categoryId]);

    const searchCategories = (queryString, categoriesTree) => 
    {
        if (!queryString || !categoriesTree) return categoriesTree;

        const regex = new RegExp(queryString, "i");

        const result = Object.fromEntries(Object.entries(categoriesTree).map(([key, group]) => 
        {
            let newGroup = null;

            const cat = Object.entries(group?.categories || {})
                .map(([key, category]) => 
                {
                    let newCategory = null;
                    const subCat = Object.entries(category?.subCategories || {}).filter(([, subCategories]) => subCategories && regex.test(subCategories.name?.[lang] || subCategories.name?.[DEFAULT_LANGUAGE_CODE]));

                    if (subCat.length > 0) 
                    {
                        newCategory = {
                            ...category,
                            expanded: true,
                            subCategories: Object.fromEntries(subCat)
                        };
                    }
                    else if (regex.test(category.name?.[lang] || category.name?.[DEFAULT_LANGUAGE_CODE])) 
                    {
                        newCategory = {
                            ...category,
                            subCategories: {}
                        };
                    }

                    return [key, newCategory];
                }).filter(([, cat]) => cat !== null);

            if (cat.length > 0) 
            {
                newGroup = {
                    ...group,
                    categories: Object.fromEntries(cat)
                };
            }
            else if (regex.test(group.name?.[lang] || group.name?.[DEFAULT_LANGUAGE_CODE])) 
            {
                newGroup = {
                    ...group,
                    categories: {}
                };
            }

            return [key, newGroup];
        }).filter(([, category]) => category !== null));

        return result;
    };

    const filteredCategories = searchCategories(categorySearchQuery, categoryTree);
    
    /**
     * Set initial categoryTree value using categoryGroups and categoryFlatMap
     */
    const initializeCategoryTree = () => 
    {
        let categoryTree = {};

        const categoryFlatMap = categoryCtx.categoryFlatMap;
        
        if (showAllCategories === false && selectedGroup && !removeCateogries.includes(selectedGroup._id)) 
        {
            categoryTree[selectedGroup._id] = {
                checked: false,
                indeterminate: false,
                name: selectedGroup.name,
                _id: selectedGroup._id,
                keywords: selectedGroup.keywords,
                subCategories: selectedGroup.subCategories
            };

            // Get categories of group
            const categories = getCategoriesFromGroup(selectedGroup, categoryFlatMap);
            categoryTree[selectedGroup._id].categories = categories;
        }
        else 
        {
            const groups = categoryCtx.categoryGroups.filter((group) => !removeCateogries.includes(group._id));
            // Get groups
            groups.forEach((group) => 
            {
                categoryTree[group._id] = {
                    checked: false,
                    indeterminate: false,
                    _id: group._id,
                    name: group.name
                };

                // Get categories of group
                const categories = getCategoriesFromGroup(group, categoryFlatMap);
                categoryTree[group._id].categories = categories;
            });
        }





        setCategoryTree(categoryTree);
    };

    const getCategoriesFromGroup = (group, categoryFlatMap) => 
    {
        let categories = {};
        group.subCategories.forEach((category) => 
        {
            const categoryDetails = categoryFlatMap[category];

            if (categoryDetails && !removeCateogries.includes(categoryDetails._id)) 
            {

                categories[categoryDetails._id] = {
                    checked: false,
                    indeterminate: false,
                    name: categoryDetails.name,
                    _id: categoryDetails._id,
                    keywords: categoryDetails.keywords,
                    parentCategories: categoryDetails.parentCategories,
                    subCategories: categoryDetails.subCategories
                };

                if (hideSubCategories === false)
                {    
                    // Get sub categories of category
                    const subCategories = getSubcategoriesFromCategory(categoryDetails, categoryFlatMap);

                    categories[categoryDetails._id].subCategories = subCategories;
                }
            }
        });
        return categories;
    };

    const getSubcategoriesFromCategory = (categoryDetails, categoryFlatMap) => 
    {
        let subCategories = {};
        categoryDetails.subCategories.forEach((subCategory) => 
        {
            const subCategoryDetails = categoryFlatMap[subCategory];

            if (subCategoryDetails && !removeCateogries.includes(subCategoryDetails._id)) 
            {
                subCategories[subCategoryDetails._id] = {
                    checked: false,
                    indeterminate: false,
                    _id: subCategoryDetails._id,
                    name: subCategoryDetails.name,
                    keywords: subCategoryDetails.keywords,
                    parentCategories: subCategoryDetails.parentCategories,
                    isSubCategory: true,
                    subCategories: subCategoryDetails.subCategories
                };
            }
        });
        return subCategories;
    };

    /**
     * Checks/Unchecks selected group
     * and its nested categories and subcategories
     * @param {Boolean} checked
     * @param {String} groupId
     */
    const handleGroupCheckboxChange = (checked, groupId) => 
    {
        const updatedCategoryTree = { ...categoryTree };

        updatedCategoryTree[groupId].checked = checked;
        updatedCategoryTree[groupId].indeterminate = false;

        updateAllCategories(checked, groupId, updatedCategoryTree);

        setCategoryTree(updatedCategoryTree);
    };

    /**
     * Checks/Unchecks selected category and the subcategories under it
     * also sets the parent group as indeteminate if it is unchecked
     * @param {Boolean} checked
     * @param {String} categoryId
     * @param {String} groupId - Parent group
     */
    const handleCategoryCheckboxChange = (checked, categoryId, groupId) => 
    {
        const updatedCategoryTree = { ...categoryTree };

        updatedCategoryTree[groupId].categories[categoryId].checked = checked;
        updatedCategoryTree[groupId].categories[categoryId].indeterminate = false;

        if (Object.values(updatedCategoryTree[groupId].categories)?.every(({ checked }) => checked))
        {
            updatedCategoryTree[groupId].checked = true;
            updatedCategoryTree[groupId].indeterminate = false;
        }

        Object.keys(updatedCategoryTree[groupId].categories[categoryId].subCategories).forEach((subCategoryId) => 
        {
            updatedCategoryTree[groupId].categories[categoryId].subCategories[subCategoryId].checked = checked;
            updatedCategoryTree[groupId].categories[categoryId].subCategories[subCategoryId].indeterminate = false;
        });

        updateGroupIndeterminateStatus(updatedCategoryTree, groupId, checked);

        setCategoryTree(updatedCategoryTree);
    };

    /**
     * Checks/Unchecks selected subcategory
     * also sets the parent category and group above it as indeterminate if they are unchecked
     * @param {Boolean} checked
     * @param {String} subCategoryId
     * @param {String} categoryId
     * @param {String} groupId
     */
    const handleSubCategoryCheckboxChange = (checked, subCategoryId, categoryId, groupId) => 
    {
        const updatedCategoryTree = { ...categoryTree };

        updatedCategoryTree[groupId].categories[categoryId].subCategories[subCategoryId].checked = checked;
        updatedCategoryTree[groupId].categories[categoryId].subCategories[subCategoryId].indeterminate = false;

        if (Object.values(updatedCategoryTree[groupId].categories[categoryId].subCategories)?.every(({ checked }) => checked))
        {
            updatedCategoryTree[groupId].categories[categoryId].checked = true;
            updatedCategoryTree[groupId].categories[categoryId].indeterminate = false;

            if (Object.values(updatedCategoryTree[groupId].categories)?.every(({ checked }) => checked))
            {
                updatedCategoryTree[groupId].checked = true;
                updatedCategoryTree[groupId].indeterminate = false;
            }
        }
        updateCategoryIndeterminateStatus(updatedCategoryTree, groupId, categoryId, checked);

        updateGroupIndeterminateStatus(updatedCategoryTree, groupId, checked);

        setCategoryTree(updatedCategoryTree);
    };

    const handleSelectAllToggle = (event, { checked }) => 
    {
        const updatedCategoryTree = { ...categoryTree };

        Object.keys(updatedCategoryTree).forEach((groupId) => 
        {
            updatedCategoryTree[groupId].checked = checked;
            updatedCategoryTree[groupId].indeterminate = false;
            updateAllCategories(checked, groupId, updatedCategoryTree);
        });

        setCategoryTree(updatedCategoryTree);
        setSelectAllCheck(checked);
    };

    const highlightText = (text, term) => 
    {
        if (!term) return text;
            
        // Create a regex to match the search term, case-insensitive
        const regex = new RegExp(`(${term})`, "gi");
        
        // Split the text by the regex match and wrap the matched term with a span
        const parts = text?.split(regex);
        
        return parts?.map((part, index) => regex.test(part) ? (
            <span key={index} style={{ color: "#757fff" }}>
                {part}
            </span>
        ) : (
            part
        )
            );
    };
    return (
        <div className={className}>
            {Heading && <Heading />}
            {searchable && !isEmpty(selectedGroup ? Object.values(categoryTree)?.[0]?.subCategories : categoryTree ) && <TextGroup>
                <Input
                    icon="search"
                    iconPosition="right"
                    className={searchClassName || "manageCategory-CopyCatSearch"}
                    placeholder={trans("ExportGroups.Search_Category")}
                    defaultValue={categorySearchQuery}
                    onChange={(e) => 
                    {
                        const value = e.target.value;
                        handleCategorySearchChange(value);
                    }
                    }
                />
            </TextGroup>}

            {
                CategoryItem && <Scrollbars className="categoryTree-Scrollbar" autoHeight autoHeightMin={autoHeightMin} autoHeightMax={autoHeightMax}>
                    {enableSelectAll && !categorySearchQuery && <div className="category-tree-select-all-checkbox">
                        <CheckboxGroup
                            checked={selectAllCheck || Object.values(categoryTree).every(({ checked }) => checked )}
                            indeterminate={Object.values(categoryTree).some(({ checked, indeterminate }) => checked || indeterminate) && !Object.values(categoryTree).every(({ checked }) => checked )}
                            label={trans("ExportGroups.Select_All")}
                            onChange={handleSelectAllToggle} />
                    </div>}
                    <div className="groupCategoryCover">
                        {
                            isEmpty(Object.keys(filteredCategories)) ? <div className="NoCategorySearchResult">{noResultMessage ? noResultMessage : <>{trans("manageCategory.searchResultNotFound")} <strong style={{ marginLeft: 5 }}>{categorySearchQuery}</strong></>}</div> : <>
                                {
                                    Object.keys(filteredCategories).map((groupId) => 
                                    {
                                        const groupDetails = filteredCategories[groupId];
                                        if (selectedGroup !== null)
                                        {
                                            return <>
                                                {Object.keys(groupDetails.categories).map((categoryId) => 
                                                {
                                                    const categoryDetails = groupDetails.categories[categoryId];
                                                    return (
                                                        <CategoryItem
                                                            key={categoryId}
                                                            detail={categoryDetails}
                                                            lang={lang}
                                                            showCheckBox={showCheckBox}
                                                            content={highlightText(categoryDetails.name?.[lang] || categoryDetails.name?.[DEFAULT_LANGUAGE_CODE], categorySearchQuery)}
                                                            parentCategory={groupDetails.name}
                                                            checked={categoryDetails.checked}
                                                            expanded={categoryDetails.expanded}
                                                            indeterminate={categoryDetails.indeterminate}
                                                            onChange={(event, { checked }) => handleCategoryCheckboxChange(checked, categoryId, groupId)}
                                                            iconInfo={getIconInfo(categoryId)} >
                                                            {!hideSubCategories && Object.keys(categoryDetails?.subCategories || {}).map((subCategoryId) => 
                                                            {
                                                                const subCategoryDetails = categoryDetails.subCategories[subCategoryId];
                                                                return (
                                                                    <CategoryItem
                                                                        key={subCategoryId}
                                                                        detail={subCategoryDetails}
                                                                        lang={lang}
                                                                        showCheckBox={showCheckBox}
                                                                        parentCategory={categoryDetails.name}
                                                                        categoryId={categoryDetails._id}
                                                                        content={highlightText(subCategoryDetails.name?.[lang] || subCategoryDetails.name?.[DEFAULT_LANGUAGE_CODE], categorySearchQuery)}
                                                                        checked={subCategoryDetails.checked}
                                                                        expanded={subCategoryDetails.expanded}
                                                                        indeterminate={subCategoryDetails.indeterminate}
                                                                        onChange={(event, { checked }) => handleSubCategoryCheckboxChange(checked, subCategoryId, categoryId, groupId)}
                                                                        iconInfo={getIconInfo(subCategoryId)} />
                                                                );
                                                            })}
                                                        </CategoryItem>
                                                    );
                                                })}
                                            </>;
                                        }
                                        return (
                                            <CategoryItem
                                                key={groupId}
                                                detail={groupDetails}
                                                lang={lang}
                                                showCheckBox={showCheckBox}
                                                content={highlightText(groupDetails.name?.[lang] || groupDetails.name?.[DEFAULT_LANGUAGE_CODE], categorySearchQuery)}
                                                expanded={groupDetails.expanded}
                                                checked={groupDetails.checked}
                                                indeterminate={groupDetails.indeterminate}
                                                onChange={(event, { checked }) => handleGroupCheckboxChange(checked, groupId)}
                                                iconInfo={getIconInfo(groupId)} >
                                                {Object.keys(groupDetails.categories).map((categoryId) => 
                                                {
                                                    const categoryDetails = groupDetails.categories[categoryId];
                                                    return (
                                                        <CategoryItem
                                                            key={categoryId}
                                                            detail={categoryDetails}
                                                            lang={lang}
                                                            showCheckBox={showCheckBox}
                                                            content={highlightText(categoryDetails.name?.[lang] || categoryDetails.name?.[DEFAULT_LANGUAGE_CODE], categorySearchQuery)}
                                                            checked={categoryDetails.checked}
                                                            parentCategory={groupDetails.name}
                                                            expanded={categoryDetails.expanded}
                                                            indeterminate={categoryDetails.indeterminate}
                                                            onChange={(event, { checked }) => handleCategoryCheckboxChange(checked, categoryId, groupId)}
                                                            iconInfo={getIconInfo(categoryId)} >
                                                            {!hideSubCategories && Object.keys(categoryDetails?.subCategories || {}).map((subCategoryId) => 
                                                            {
                                                                const subCategoryDetails = categoryDetails.subCategories[subCategoryId];
                                                                return (
                                                                    <CategoryItem
                                                                        key={subCategoryId}
                                                                        detail={subCategoryDetails}
                                                                        lang={lang}
                                                                        showCheckBox={showCheckBox}
                                                                        content={highlightText(subCategoryDetails.name?.[lang] || subCategoryDetails.name?.[DEFAULT_LANGUAGE_CODE], categorySearchQuery)}
                                                                        parentCategory={categoryDetails.name}
                                                                        checked={subCategoryDetails.checked}
                                                                        expanded={subCategoryDetails.expanded}
                                                                        indeterminate={subCategoryDetails.indeterminate}
                                                                        onChange={(event, { checked }) => handleSubCategoryCheckboxChange(checked, subCategoryId, categoryId, groupId)}
                                                                        iconInfo={getIconInfo(subCategoryId)} />
                                                                );
                                                            })}
                                                        </CategoryItem>
                                                    );
                                                })}
                                            </CategoryItem>
                                        );
                                    })}
                            </>
                        }
                    </div>
                </Scrollbars>
            }
        </div>
    );
};

export default CategoryTreeDisplay;