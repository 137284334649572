import React from "react";
import "./CategoryGroups.css";
import CategoryGroupItem from "../../../common/categoryGroupItem/CategoryGroupItem";
import { useTranslation } from "react-i18next";
import { Button, Image } from "semantic-ui-react";
import { MAX_GROUPS_ALLOWED } from "../../../../_constants/constants";
import { ButtonIcon } from "../../../common/ButtonIcon";
import { EditCategoryModal } from "../../categoriesPortal/EditCategoryModal";
import { CATEGORY_EDIT_TYPE } from "../../../../_constants/branding";

const CategoryGroups = ({ lang, groups, selectedGroup, setSelectedGroup, getIconInfo }) => 
{
    const trans = useTranslation().t;
    
    return (
        <>
            <div className="manageCategoryGroups">
                <div className="header">
                    <div>
                        <div className="topHeading">
                            <div><strong>{trans("manageCategory.groups")}</strong> ({MAX_GROUPS_ALLOWED} {trans("manageCategory.maxAllowed")})</div>  </div>
                        <div className="sub-text">{trans("manageCategory.subHeading")}</div>
                    </div>
                    <EditCategoryModal
                        editConfig={CATEGORY_EDIT_TYPE.GROUP}
                        trigger={
                            <Button
                                className="addGrpBtn"
                                disabled={groups?.length >= MAX_GROUPS_ALLOWED}
                            >{trans("manageCategory.addGroupBtn")}</Button>
                        } />
                </div>
                {
                    groups?.length > 0? <div className="groupList">
                        {
                            groups.map((group) => <CategoryGroupItem
                                key={`group-item-${group._id}`}
                                category={group}
                                getIconInfo={getIconInfo}
                                lang={lang}
                                onClick={() => 
                                {
                                    setSelectedGroup({ ...group });
                                }}
                                isActive={selectedGroup?._id === group._id}
                            >
                                <div style={{ display: "flex", gap: 5, cursor: "pointer" }}>
                                    <EditCategoryModal
                                        editConfig={CATEGORY_EDIT_TYPE.GROUP}
                                        category={group}
                                        trigger={
                                            <ButtonIcon icon="category-edit" className="group-icons" />
                                        }
                                        addDeleteIcon={true}
                                        deleteIconClass="group-icons"
                                    />
                                </div>
                            </CategoryGroupItem>)
                        }
                    </div> : <NoGroupMessage trans={trans} />
                }
            </div>
        </>
    );
};

export default CategoryGroups;

const NoGroupMessage = ({ trans }) => (
    <div className="keyword-section-no-categories">
        <div className="keyword-section-no-data">
            <Image className="noCategoryImage" as="span" src="/img/icon-no-group.svg" alt="No Groups" />
            <p>{trans("manageCategory.noGroupPresent")}</p>
            <p style={{ textAlign: "center" }}>{trans("manageCategory.pleaseAddGroup")}</p>
            <EditCategoryModal
                editConfig={CATEGORY_EDIT_TYPE.GROUP}
                trigger={
                    <Button
                        className="addGrpBtn"
                    >{trans("manageCategory.addGroupBtn")}</Button>
                } />
           
        </div>
    </div>
);