import React, { useEffect } from "react";
import { useSetState } from "ahooks";
import { ModalBox } from "../../../common/ModalBox";
import { Button, Input } from "semantic-ui-react";
import { TextGroup } from "../../../common/TextGroup";
import { CheckboxGroup } from "../../../common/CheckboxGroup";
import { BASE_MAPS } from "../../../../_constants/stylesAndThemesConstants";
import { ErrorLabel } from "../../../elements/ErrorLabel";
import { 
    DEFAULT_LANGUAGE_CODE, 
    MAPSTED_DARK_THEME, 
    MAPSTED_LIGHT_THEME 
} from "mapsted.maps/utils/map.constants";
import { useTranslation } from "react-i18next";
import DeleteThemeConfirmation from "./DeleteThemeConfirmation";

const ThemeCreateAndEditModal = ({
    initialThemeName,
    initialBaseMap,
    themeId,
    onCreate,
    onEdit,
    open,
    onClose,
    themes,
    initialDarkStatue,
    themesExcludedFromDeletion = []
}) =>
{
    const [state, setState] = useSetState({
        themeName: "",
        baseMap: "",
        openConfirmation: false,
        deleteConfirmation: false,
        errorMessage: "",
    });
    const trans = useTranslation().t;

    useEffect(() =>
    {
        setState({
            themeName: initialThemeName,
            baseMap: initialBaseMap,
            openConfirmation: false,
            dark: initialDarkStatue,
            errorMessage: "",
        });
    }, [open, initialBaseMap, initialBaseMap, initialDarkStatue]);

    const handleDoneClick = () =>
    {
        if (checkIfDefaultThemeName(state.themeName))
        {
            setState({
                errorMessage: trans("ThemeCreateAndEditModal.Default_Theme_Name_Used")
            });
            return;
        }

        if (checkIfDuplicateName(state.themeName))
        {
            setState({
                errorMessage: trans("ThemeCreateAndEditModal.Theme_Already_Exists")
            });
            return;
        }

        if (themeId)
        {
            onEdit(themeId, state.themeName.trim(), state.baseMap, state.dark);
        }
        else
        {
            onCreate(state.themeName.trim(), state.baseMap, state.dark);
        }
    };

    /**
     * Check if theme name is same as Mapsted default theme names
     * @param {String} themeName 
     * @returns {Boolean}
     */
    const checkIfDefaultThemeName = (themeName) => MAPSTED_LIGHT_THEME.name[DEFAULT_LANGUAGE_CODE] === themeName.trim() || MAPSTED_DARK_THEME.name[DEFAULT_LANGUAGE_CODE] === themeName.trim();

    const checkIfDuplicateName = (themeName) =>
    {
        let currentThemes = themes;

        if (themeId)
        {
            currentThemes = currentThemes.filter((theme) => theme._id !== themeId);
        }

        const lowerCaseThemeNames = currentThemes.map((theme) => theme.name[DEFAULT_LANGUAGE_CODE].toLowerCase());
        return lowerCaseThemeNames.includes(themeName.trim().toLowerCase());
    };

    const handleBaseMapChange = (baseMapId) =>
    {
        if (baseMapId !== state.baseMap)
        {
            setState({
                baseMap: baseMapId,
                errorMessage: "",
            });
        }
    };

    return (
        <>
            {
                state.deleteConfirmation && <DeleteThemeConfirmation
                    open={state.deleteConfirmation}
                    onClose={(e, reason) => 
                    {
                        setState({ deleteConfirmation: false });
                        if (reason === "action-btn-click") onClose();
                    }}
                    themeInfo={{
                        themeName: state.themeName,
                        themeId: themeId
                    }} />
            }  
            <ModalBox className="themeCreateEditModal"
                open={open}
                onClose={() => setState({
                    openConfirmation: true
                })}
                header={themeId ? trans("ThemeCreateAndEditModal.Edit_Theme"): trans("ThemeCreateAndEditModal.Create_Theme") }
                actions={
                    <>              
                        <Button 
                            color="orange"
                            disabled={!state.themeName?.trim()} 
                            floated="right" 
                            content={themeId ? trans("ThemeCreateAndEditModal.Edit") : trans("ThemeCreateAndEditModal.Create")} 
                            onClick={handleDoneClick} 
                        />
                        {
                            !themesExcludedFromDeletion.includes(themeId) && !!themeId && <Button
                                disabled={!state.themeName?.trim()}
                                className="theme-delete-button"
                                icon={<img src="/img/delete-icon.svg" alt="Delete Icon" />}
                                floated="right"
                                content={trans("ThemeCreateAndEditModal.Delete_Theme")}
                                onClick={() => setState({ deleteConfirmation: true })}
                            />
                        }

                    </>
                }>
                <div>
                    <div className="themeSettingsRow">
                        <TextGroup flexible title={`${trans("ThemeCreateAndEditModal.Theme_Name")}:`}>
                            <Input
                                className="themeName"
                                value={state.themeName}
                                placeholder={trans("ThemeCreateAndEditModal.Enter_Theme_Name")}
                                onChange={(event, { value }) => setState({
                                    themeName: value,
                                    errorMessage: "",
                                })}
                            />
                        </TextGroup>
                        <TextGroup flexible title={`${trans("ThemeCreateAndEditModal.Property_Map")}:`} className="propertyMapType">
                            <CheckboxGroup
                                label={trans("ThemeCreateAndEditModal.Light")}
                                radio
                                className="themeTypeCheckbox"
                                checked={!state.dark}
                                onChange={(event, { checked }) => setState({ dark: !checked })}
                            />
                            <CheckboxGroup
                                label={trans("ThemeCreateAndEditModal.Dark")}
                                radio
                                className="themeTypeCheckbox themeTypeDarkCheckbox"
                                checked={state.dark}
                                onChange={(event, { checked }) => setState({ dark: checked })}
                            />
                        </TextGroup>
                        
                    </div>
                                        
                    <TextGroup flexible title={`${trans("ThemeCreateAndEditModal.Base_Map")}:`} />

                    <div className="baseMapSelectorGroup">

                        {BASE_MAPS.map((baseMap) => (
                            <BaseMapSelector
                                key={baseMap.baseMapId}
                                label={baseMap.label}
                                imageName={baseMap.imageName}
                                selectedBaseMap={state.baseMap}
                                baseMapId={baseMap.baseMapId}
                                onBaseMapChange={() => handleBaseMapChange(baseMap.baseMapId)}
                            />
                        ))}

                    </div>
                    {!!state.errorMessage && <ErrorLabel small content={state.errorMessage} />}
                </div>
            </ModalBox>
            <CancelConfirmationModal
                open={state.openConfirmation}
                onCancelClick={onClose}
                isCreate={!themeId}
                onClose={() => setState({
                    openConfirmation: false
                })}
                trans={trans}
            />
        </>
    );
};

const BaseMapSelector = ({
    label,
    imageName,
    selectedBaseMap,
    baseMapId,
    onBaseMapChange
}) => (
    <div className="baseMapSelector">    
        <CheckboxGroup
            radio
            className="baseMapSelectorCheckbox"
            label={label}
            checked={selectedBaseMap === baseMapId}
            onChange={onBaseMapChange}/>
        <img 
            src={`/img/${imageName}.png`} 
            alt="" />
    </div>
);

const CancelConfirmationModal = ({ open, onCancelClick, isCreate, onClose, trans }) => (
    <ModalBox className="confirmActionModal"
        open={open}
        onClose={onClose}
        header={trans("ThemeCreateAndEditModal.Confirm_Action")}
        actions={<Button color="orange" floated="right" content={trans("Cancel_Confirmation_Modal.Cancel")} onClick={onCancelClick} />}>
        <p className="p-modal">
            {isCreate ? trans("ThemeCreateAndEditModal.Creation_Cancel_Confirmation"): trans("ThemeCreateAndEditModal.Edit_Cancel_Confirmation")}
        </p>
    </ModalBox>
);

export default ThemeCreateAndEditModal;