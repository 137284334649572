import React, { useContext, useState } from "react";
import { Button, Grid, Header } from "semantic-ui-react";
import { ModalLayout } from "../../popups/ModalLayout";
import { Trans, useTranslation } from "react-i18next";
import brandingApi from "../../../_api/branding.api";
import { useMutation, useQueryClient } from "react-query";
import LinkButton from "../../common/LinkButton";
import { ErrorLabel } from "../../elements/ErrorLabel";
import { UploadTab } from "../../popups/UploadImageModal";
import { DEFAULT_LANGUAGE_CODE } from "mapsted.maps/utils/map.constants";
import BrandingContext from "../../../store/BrandingContext";
import { TextGroup } from "../../common/TextGroup";
import LanguageDropdown from "../../common/languageDropdown/LanguageDropdown";
import { BRANDING_QUERIES } from "../../../_api/branding.queries";


const missingFieldDisplayNames = {
    longName: "Store Long Name",
    "longName.en": "Store Long Name",
    _id: "Entity Id",
    lang: "lang",
    Theme: "Theme",
    Color: "Color",
    Name: "Name",
    IconName: "Icon Name"
};

export const CategoryCSVModal = ({
    trigger,
    isOpen,
    close
}) =>
{
    const trans = useTranslation().t;

    const bCtx = useContext(BrandingContext);
    const { propertyId } = bCtx.state;

    const queryClient = useQueryClient();

    const { mutate, isLoading } = useMutation({
        mutationFn: brandingApi.uploadGroupsCSVFile,
        mutationKey: "GROUPS_UPLOAD_CSV",
    });

    const [file, setFile] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [lang, setLang] = useState(DEFAULT_LANGUAGE_CODE);

    const handleCsvDownload = (e) =>
    {
        e.preventDefault();
        bCtx.downloadCategoriesCSV(propertyId, lang);
    };

    const handleExcelDownload = (e) =>
    {
        e.preventDefault();
        bCtx.downloadCategoriesExcel(propertyId, lang);
    };
    const handleFileChange = (files) =>
    {
        setErrorMessage("");
        setFile(files[0]);
    };

    const handleFileUpload = () =>
    {

        if (!file) return;

        const formData = new FormData();

        formData.append("file", file, file.name);

        mutate({ formData, propertyId, lang }, {
            onSuccess: () =>
            {
                queryClient.invalidateQueries(BRANDING_QUERIES.PROPERTY_CATEGORIES(propertyId).queryKey);
                handleOnClose();
            },
            onError: (res) =>
            {
                console.log(res);
                let errorMessage = trans("GroupsCSVModal.Server_Error");
                if (res.errorType === "InvalidDataError")
                {
                    errorMessage = <div className="error-msg">
                        {trans("GroupsCSVModal.Row_Number_Display", { rowNum: res.row })}
                        <br />
                        {trans("GroupsCSVModal.Invalid_Data_Error", { invalidField: res.invalidFields.length ? res.invalidFields[0] : "" })}
                        {
                            res.invalidFields[0] == "Name" && <div>{res.message}</div>
                        }
                    </div>;
                }
                else if (res.errorType === "MissingMandatoryFieldsError")
                {
                    errorMessage = <div className="error-msg">
                        {trans("GroupsCSVModal.Row_Number_Display", { rowNum: res.row })}
                        <br />
                        {trans("GroupsCSVModal.Missing_Mandatory_Fields_Error", { missingField: missingFieldDisplayNames[res.missingFields] })}
                    </div>;
                }
                else 
                {
                    errorMessage=<div className="error-msg">
                        {trans("GroupsCSVModal.Invalid_Response")}
                    </div>;
                }
                setErrorMessage(errorMessage);
            }
        });
    };

    const handleOnClose = () =>
    {
        setErrorMessage("");
        setLang(DEFAULT_LANGUAGE_CODE);
        setFile(null);
        if (close)
        {
            close();
        }
    };

    return (
        <ModalLayout
            open={isOpen}
            trigger={trigger}
            onClose={handleOnClose}
            heading={trans("GroupsCSVModal.Upload_FILE")}
            className="categoryModal"
            size="mini"
            actions={
                <Button
                    loading={isLoading}
                    disabled={!file || isLoading || errorMessage }
                    color="orange"
                    content={trans("GroupsCSVModal.Upload")}
                    onClick={handleFileUpload}
                />
            }
        >
            {propertyId && (
                <p className="p-modal csvUploadModalText">
                    <Trans i18nKey="GroupsCSVModal.Property_CSV_template"
                        components={{ 
                            templateCsvLink: <LinkButton onClick={handleCsvDownload} />,
                            templateExcelLink: <LinkButton onClick={handleExcelDownload} /> 
                        }}
                    />
                </p>
            )}


            <TextGroup heading={trans("LanguageSelector.Choose_Language")} className="languageSelectorDropdown">
                <LanguageDropdown
                    allowedLangs={bCtx.getCurrentPropertyLangOptions()}
                    activeLang={lang}
                    onChange={(lang) => setLang(lang)}
                />
            </TextGroup>

            <div className="fileUploader" >
                {file ? (
                    <Grid centered>
                        <Grid.Row>
                            <Header content={file.name} />
                        </Grid.Row>
                        <Grid.Row style={{ marginTop: "20px" }}>
                            <Button
                                basic
                                icon="refresh"
                                content={trans("UploadCSVModal.Change")}
                                onClick={() => setFile(null)}
                            />
                        </Grid.Row>
                        <Grid.Row style={{ marginTop: "20px" }}>
                            {errorMessage && (
                                <ErrorLabel>
                                    {errorMessage}
                                </ErrorLabel>
                            )}
                        </Grid.Row>
                    </Grid>
                ) : (
                    <UploadTab
                        acceptableImageFileFormats={".csv,.xlsx"}
                        onChange={handleFileChange}
                        returnRawFile
                        invalidFileTypeErrorMessage={trans("GroupsCSVModal.Invalid_File_Type_Error")}
                    />
                )}
            </div>
        </ModalLayout>
    );
};
