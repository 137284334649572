export const possibleValidationDataIssueErrorsHash = {
    "Unknown,": {
        "Case": "Default",
        "Properties": "",
        "Category": "",

        "Missing_Data_Issues": "Duplicate Data Issues",
        "- Nulls or empty objects": "- Duplicate IDs"
    },
    "Property_Locked_For_Validating": {
        "Case": "",

    },
    // "": {
    //     "Case": "",
    //     "Properties": "",
    //     "Category": "",
    //
    //     "Missing_Data_Issues": "",
    //     "- Nulls or empty objects": ""
    // },
    "Property_Level_Null_Check_Issues": {
        "Case": "",
        "Properties": "",
        "Category": "",

        "Missing_Data_Issues": "Geometry Data Issues",
        "- Nulls or empty objects": "Invalid polygon, polyline, point, - Misaligned edges, etc."
    },
    "Property_Request_Invalid_Id_Error,": {
        "Case": "Property Id in the url is different than the Property id in the api body",
        "Properties": "PropertyId",
        "Category": "Invalid api request",

        "Missing_Data_Issues": "Invalid api request",
        "- Nulls or empty objects": "Invalid Api components"
    },
    "PropertyEntities_Empty_Error,": {
        "Case": "PropertyEntities is empty",
        "Properties": "PropertyId",
        "Category": "Missing_Data_Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "PropertyEntities_Duplicate_Ids_Error,": {
        "Case": "PropertyEntities has duplicate entity ids",
        "Properties": "PropertyId",
        "Category": "Duplicate Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "PropertyNodes_Empty_Warning,": {
        "Case": "PropertyNodes is empty",
        "Properties": "PropertyId",
        "Category": "Missing_Data_Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "PropertyNodes_Duplicate_Ids_Error,": {
        "Case": "PropertyNodes has duplicate node ids",
        "Properties": "",
        "Category": "Duplicate Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "PropertyNodes_Id_Does_Not_Exist_Error,": {
        "Case": "Property Entity/Transition/Node-linkedNodeId has Node Id but Node does not exist in propertyNodes",
        "Properties": "PropertyId, NodeId, (TransitionId), (EntityId)",
        "Category": "Invalid Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "PropertyNodes_Linkage_Missing_Error,": {
        "Case": "Property Node 1 has linked node 2 but node 2 does not have 1 as a link",
        "Properties": "PropertyId, NodeId1, NodeId2",
        "Category": "Invalid Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "PropertyPoints_Empty_Warning,": {
        "Case": "PropertyPoints is empty",
        "Properties": "PropertyId",
        "Category": "Missing_Data_Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "PropertyPoints_Duplicate_Ids_Error,": {
        "Case": "PropertyPoints has duplicate Point ids",
        "Properties": "PropertyId",
        "Category": "Duplicate Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "PropertyPoints_Id_Does_Not_Exist_Error,": {
        "Case": "Property Entity has Point Id but Point does not exist in PropertyPoints",
        "Properties": "PropertyId, PointId, EntityId",
        "Category": "Invalid Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "PropertyPoints_Floating_Error,": {
        "Case": "Property Point does not have a linked entity",
        "Properties": "PropertyId, PointId",
        "Category": "Invalid Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "PropertyPolygons_Empty_Error,": {
        "Case": "PropertyPolygons is empty",
        "Properties": "PropertyId",
        "Category": "Missing_Data_Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "PropertyPolygons_Duplicate_Ids_Error,": {
        "Case": "PropertyPolygons has duplicate Polygon ids",
        "Properties": "PropertyId",
        "Category": "Duplicate Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "PropertyPolygons_Id_Does_Not_Exist_Error,": {
        "Case": "Property Entity has Polygon Id but Polygon does not exist in PropertyPolygons",
        "Properties": "PropertyId, PolygonId, EntityId",
        "Category": "Invalid Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "PropertyPolygons_Floating_Error,": {
        "Case": "Property Polygon does not have a linked entity",
        "Properties": "PropertyId, PolygonId",
        "Category": "Invalid Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "PropertyPolylines_Empty_Warning,": {
        "Case": "PropertyPolylines is empty",
        "Properties": "PropertyId",
        "Category": "Missing_Data_Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "PropertyPolylines_Duplicate_Ids_Error,": {
        "Case": "PropertyPolylines has duplicate Polygon ids",
        "Properties": "PropertyId",
        "Category": "Duplicate Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "PropertyPolylines_Id_Does_Not_Exist_Error,": {
        "Case": "Property Entity has PolylineId Id but Polyline does not exist in PropertyPolylines",
        "Properties": "PropertyId, PolylineId, EntityId",
        "Category": "Invalid Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "PropertyPolylines_Floating_Error,": {
        "Case": "Property Polyline does not have a linked entity",
        "Properties": "PropertyId, PolylineId",
        "Category": "Invalid Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "PropertyTransitions_Empty_Warning,": {
        "Case": "PropertyTransitions is empty",
        "Properties": "PropertyId",
        "Category": "Missing_Data_Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "PropertyTransitions_Duplicate_Ids_Error,": {
        "Case": "PropertyTransitions has duplicate Transition ids",
        "Properties": "PropertyId",
        "Category": "Duplicate Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "PropertyEntityStringArray_Empty_Warning,": {
        "Case": "PropertyEntityStringArray is empty",
        "Properties": "PropertyId",
        "Category": "Missing_Data_Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "PropertyEntityStringArray_EntityId_Error,": {
        "Case": "PropertyEntityStringArray contains -1 or duplicated Ids",
        "Properties": "PropertyId",
        "Category": "Duplicate Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "PropertyEntityStringArray_Entity_Does_Not_Exist_Error,": {
        "Case": "EntityStringArray has entity but the entity does not exist in PropertyEntities",
        "Properties": "PropertyId, EntityId",
        "Category": "Invalid Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "// Building Level Null Check Issues": {
        "Case": "",
        "Properties": "",
        "Category": "",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "Building_Request_Invalid_Error,": {
        "Case": "Building Id / Property Id in the request is invalid",
        "Properties": "BuildingId, (PropertyId)",
        "Category": "Invalid api request",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "BuildingEntities_Empty_Error,": {
        "Case": "BuildingEntities is empty",
        "Properties": "BuildingId, PropertyId",
        "Category": "Missing_Data_Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "BuildingEntities_Duplicate_Ids_Error,": {
        "Case": "BuildingEntities has duplicate entity ids",
        "Properties": "BuildingId, PropertyId",
        "Category": "Duplicate Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "BuildingNodes_Empty_Warning,": {
        "Case": "BuildingNodes is empty",
        "Properties": "BuildingId, PropertyId",
        "Category": "Missing_Data_Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "BuildingNodes_Duplicate_Ids_Error,": {
        "Case": "BuildingNodes has duplicate node ids",
        "Properties": "BuildingId, PropertyId",
        "Category": "Duplicate Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "BuildingNodes_Id_Does_Not_Exist_Error,": {
        "Case": "Building Entity/Transition/Node-linkedNodeId has Node Id but Node does not exist in BuildingNodes",
        "Properties": "BuildingId, PropertyId, NodeId, (TransitionId), (EntityId)",
        "Category": "Invalid Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "BuildingNodes_Linkage_Missing_Error,": {
        "Case": "Building Node 1 has linked node 2 but node 2 does not have 1 as a link",
        "Properties": "BuildingId, PropertyId, NodeId1, NodeId2",
        "Category": "Invalid Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "BuildingPoints_Empty_Warning,": {
        "Case": "BuildingPoints is empty",
        "Properties": "BuildingId, PropertyId",
        "Category": "Missing_Data_Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "BuildingPoints_Duplicate_Ids_Error,": {
        "Case": "BuildingPoints has duplicate Point ids",
        "Properties": "BuildingId, PropertyId",
        "Category": "Duplicate Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "BuildingPoints_Id_Does_Not_Exist_Error,": {
        "Case": "Building Entity has Point Id but Point does not exist in BuildingPoints",
        "Properties": "BuildingId, PropertyId, PointId, EntityId",
        "Category": "Invalid Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "BuildingPoints_Floating_Error,": {
        "Case": "Building Point does not have a linked entity",
        "Properties": "BuildingId, PropertyId, PointId",
        "Category": "Invalid Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "BuildingPolygons_Empty_Error,": {
        "Case": "BuildingPolygons is empty",
        "Properties": "BuildingId, PropertyId",
        "Category": "Missing_Data_Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "BuildingPolygons_Duplicate_Ids_Error,": {
        "Case": "BuildingPolygons has duplicate Polygon ids",
        "Properties": "BuildingId, PropertyId",
        "Category": "Duplicate Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "BuildingPolygons_Id_Does_Not_Exist_Error,": {
        "Case": "Building Entity has Polygon Id but Polygon does not exist in BuildingPolygons",
        "Properties": "BuildingId, PropertyId, PolygonId, EntityId",
        "Category": "Invalid Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "BuildingPolygons_Building_Boundary_Error": {
        "Case": "Building Polygon should have only one building boundary (now 0 or 2+ building boundaries in the api)",
        "Properties": "BuildingId, PropertyId",
        "Category": "Invalid Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "BuildingPolygons_Floating_Error,": {
        "Case": "Building Polygon does not have a linked entity",
        "Properties": "BuildingId, PropertyId, PolygonId",
        "Category": "Invalid Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "BuildingPolylines_Empty_Warning,": {
        "Case": "BuildingPolylines is empty",
        "Properties": "BuildingId, PropertyId",
        "Category": "Missing_Data_Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "BuildingPolylines_Duplicate_Ids_Error,": {
        "Case": "BuildingPolylines has duplicate Polygon ids",
        "Properties": "BuildingId, PropertyId",
        "Category": "Duplicate Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "BuildingPolylines_Id_Does_Not_Exist_Error,": {
        "Case": "Building Entity has PolylineId Id but Polyline does not exist in BuildingPolylines",
        "Properties": "BuildingId, PropertyId, PolylineId, EntityId",
        "Category": "Invalid Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "BuildingPolylines_Floating_Error,": {
        "Case": "Building Polyline does not have a linked entity",
        "Properties": "BuildingId, PropertyId, PolylineId",
        "Category": "Invalid Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "BuildingTransitions_Empty_Warning,": {
        "Case": "BuildingTransitions is empty",
        "Properties": "BuildingId, PropertyId",
        "Category": "Missing_Data_Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "BuildingTransitions_Duplicate_Ids_Error,": {
        "Case": "BuildingTransitions has duplicate Transition ids",
        "Properties": "BuildingId, PropertyId",
        "Category": "Duplicate Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "BuildingEntityStringArray_Empty_Warning,": {
        "Case": "BuildingEntityStringArray is empty",
        "Properties": "BuildingId, PropertyId",
        "Category": "Missing_Data_Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "BuildingEntityStringArray_EntityId_Error,": {
        "Case": "BuildingEntityStringArray contains -1 or duplicated Ids",
        "Properties": "BuildingId, PropertyId",
        "Category": "Duplicate Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "BuildingEntityStringArray_Entity_Does_Not_Exist_Error,": {
        "Case": "EntityStringArray has entity but the entity does not exist in BuildingEntities",
        "Properties": "BuildingId, PropertyId, EntityId",
        "Category": "Invalid Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "// General Data Object Issues": {
        "Case": "",
        "Properties": "",
        "Category": "",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "Entity_Invalid_Ids_Error": {
        "Case": "Entity has invalid property/building/floor/entity Ids",
        "Properties": "PropertyId, BuildingId, FloorId, EntityId",
        "Category": "Invalid Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "Entity_Invalid_Type_Info_Error": {
        "Case": "Entity has invalid Entity Type",
        "Properties": "PropertyId, BuildingId, EntityId",
        "Category": "Invalid Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "Entity_Multiple_Geometry_Error,": {
        "Case": "Entity has more than one geometry type",
        "Properties": "PropertyId, BuildingId, EntityId",
        "Category": "Invalid Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "Entity_Missing_Geometry_Error,": {
        "Case": "Entity does not have geometry type",
        "Properties": "PropertyId, BuildingId, EntityId",
        "Category": "Invalid Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "Node_Invalid_Ids_Error,": {
        "Case": "Node has invalid property/building/floor/node Ids",
        "Properties": "PropertyId, BuildingId, FloorId, NodeId",
        "Category": "Invalid Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "Node_Invalid_Geometry_Error,": {
        "Case": "Node Location is null or empty",
        "Properties": "PropertyId, BuildingId, FloorId, NodeId",
        "Category": "Invalid Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "Polygon_Default_Id_Error,": {
        "Case": "PolygonId is ID_NOT_FOUND",
        "Properties": "PropertyId, BuildingId, PolygonId",
        "Category": "Invalid Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "Polygon_Empty_Geometry_Error,": {
        "Case": "Polygon boudnary is null or empty",
        "Properties": "PropertyId, BuildingId, PolygonId",
        "Category": "Invalid Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "Polygon_Geometry_Not_Closed_Error,": {
        "Case": "Boudnary is not closed, first point does not equal last point",
        "Properties": "PropertyId, BuildingId, PolygonId",
        "Category": "Invalid Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "Point_Default_Id_Error,": {
        "Case": "PointId is ID_NOT_FOUND",
        "Properties": "PropertyId, BuildingId, PointId",
        "Category": "Invalid Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "Point_Empty_Geometry_Error,": {
        "Case": "Point Location is null or empty",
        "Properties": "PropertyId, BuildingId, PointId",
        "Category": "Invalid Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "Polyline_Default_Id_Error,": {
        "Case": "Polyline PolylineId is ID_NOT_FOUND",
        "Properties": "PropertyId, BuildingId, PolylineId",
        "Category": "Invalid Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "Polyline_Empty_Geometry_Error,": {
        "Case": "Polyline Points is null or empty",
        "Properties": "PropertyId, BuildingId, PolylineId",
        "Category": "Invalid Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "Transition_Default_Id_Error,": {
        "Case": "transition TransitionId is ID_NOT_FOUND",
        "Properties": "PropertyId, BuildingId, TransitionId",
        "Category": "Invalid Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "Transition_Unknown_Type_Error,": {
        "Case": "TransitionType is UNKNOWN_TRANSITION",
        "Properties": "PropertyId, BuildingId, TransitionId",
        "Category": "Invalid Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "Transition_Insufficient_Route_Nodes_Error,": {
        "Case": "Route has less than 2 elements.",
        "Properties": "PropertyId, BuildingId, TransitionId",
        "Category": "Invalid Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "Transition_Route_Nodes_Invalid_Ids_Error,": {
        "Case": "Route has element with -1 Ids.",
        "Properties": "PropertyId, BuildingId, TransitionId",
        "Category": "Invalid Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "NameCategory_Missing_English_Warning,": {
        "Case": "EntityId longName does not contain English name",
        "Properties": "PropertyId, BuildingId, EntityId",
        "Category": "Invalid Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "// Geometry Issues": {
        "Case": "",
        "Properties": "",
        "Category": "",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "Polygon_Too_Small_Error,": {
        "Case": "Polygon area is too small",
        "Properties": "PropertyId, BuildingId, PolygonId",
        "Category": "Geometry Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "Polygon_Invalid_Shape_Error,": {
        "Case": "Polygon shape is invalid",
        "Properties": "PropertyId, BuildingId, PolygonId",
        "Category": "Geometry Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "Opening_Exceeding_Polygon_Error,": {
        "Case": "Opening exceed polygon boundary",
        "Properties": "PropertyId, BuildingId, OpeningId",
        "Category": "Geometry Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "Opening_Overlapping_Error,": {
        "Case": "Opening overlapping with other openings",
        "Properties": "PropertyId, BuildingId, OpeningId",
        "Category": "Geometry Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "Opening_Connecting_Obstacle_Error,": {
        "Case": "Opening connecting obstacles",
        "Properties": "PropertyId, BuildingId, OpeningId",
        "Category": "Geometry Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "Opening_Suspiciously_Big_Or_Small_Warning,": {
        "Case": "Opening should not be too big or too small",
        "Properties": "PropertyId, BuildingId, OpeningId",
        "Category": "Geometry Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "Opening_Category_Mismatching_Error,": {
        "Case": "Opening has suspiciously category",
        "Properties": "PropertyId, BuildingId, OpeningId",
        "Category": "Geometry Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "Entities_Missing_Opening_Error,": {
        "Case": "Entities should have connected openings",
        "Properties": "PropertyId, BuildingId, EntityIds",
        "Category": "Geometry Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "Paired_Transitions_Geometry_Alignment_Error,": {
        "Case": "Connected Transitions should have aligned geometry",
        "Properties": "PropertyId, BuildingId, EntityIds",
        "Category": "Geometry Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "Property_Boundary_Invalid_Warning,": {
        "Case": "Property boundary is invalid",
        "Properties": "PropertyId",
        "Category": "Geometry Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "Structure_Entities_Exceed_Floor_Error,": {
        "Case": "Structure Entities should not exceed its floor boundary",
        "Properties": "PropertyId, BuildingId, EntityIds",
        "Category": "Geometry Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "Structure_Entities_Overlapping_Error,": {
        "Case": "Structure Entities should not overlap with each other",
        "Properties": "PropertyId, BuildingId, EntityIds",
        "Category": "Geometry Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "Floor_Not_Fully_Covered_By_Structure_Entities_Error,": {
        "Case": "Floor boundary should be fully covered by Structure Entities",
        "Properties": "PropertyId, BuildingId, FloorId",
        "Category": "Geometry Data Issues",

        "Missing_Data_Issues": "",
        "- Nulls or empty objects": ""
    },
    "BUILDING_RE_VALIDATION_REQUIRED": {
        "Case": "When we have new update on map editor and saved",
        "Properties": "PropertyId, BuildingId",
        "Category": "Re-Validation",
    },
    "PROPERTY_RE_VALIDATION_REQUIRED": {
        "Case": "When we have new update on map editor and saved",
        "Properties": "PropertyId",
        "Category": "Re-Validation",
    },
    "PROPERTY_VALIDATION_SUCCESS": {
        "Case": "When We don't have any errors in validation",
        "Properties": "PropertyId",
        "Category": "Validation SuccessFul",
    },
    "BUILDING_VALIDATION_SUCCESS": {
        "Case": "When We don't have any errors in validation",
        "Properties": "PropertyI BuildingId",
        "Category": "Validation SuccessFul",
    },
    "NO_VALIDATION_INFORMATION_AVAILABLE": {
        "Case": "When Validation information is not available",
        "Properties": "PropertyId, BuildingId",
        "Category": "Validation Info Not Available",
    },
    "NO_RECORDS_FOUND": {
        "Case": "When we don't have any records",
        "Properties": "PropertyId, BuildingId",
        "Category": "Validation Info Not Available",
    },

};


export const POSSIBLE_VALIDATION_STATUS = {
    RECORDS_NOT_FOUND: "RECORDS_NOT_FOUND",
    RECORDS_FOUND_WITH_ERRORS: "RECORDS_FOUND_WITH_ERRORS",
    RECORDS_HAS_SUCCESS_FUL_VALIDATION: "RECORDS_HAS_SUCCESS_FUL_VALIDATION",
    RECORDS_HAS_IN_VALID_VALIDATION: "RECORDS_HAS_IN_VALID_VALIDATION",
};


export const VALIDATION_STATUS_META_DATA = {
    [POSSIBLE_VALIDATION_STATUS.RECORDS_FOUND_WITH_ERRORS]: {
        "icon": "error-heading",
        "color": "red",
        "title": "Validation Errors",
        priory: 1,
        defaultActiveIndex: 0,
        name: "Error"

    },
    [POSSIBLE_VALIDATION_STATUS.RECORDS_HAS_IN_VALID_VALIDATION]: {
        "icon": "validation-invalid",
        "color": "orange",
        "title": "In Valid Validations",
        priory: 2,
        name: "Invalid"

    },
    [POSSIBLE_VALIDATION_STATUS.RECORDS_HAS_SUCCESS_FUL_VALIDATION]: {
        "icon": "validation-success",
        "color": "green",
        "title": "Validation Success",
        priory: 3,
        name: "Successful"
    },
    [POSSIBLE_VALIDATION_STATUS.RECORDS_NOT_FOUND]: {
        "icon": "validation-no-data",
        "color": "orange",
        "title": "Validation info not available",
        priory: 4,
        name: "Not Available"
    }
};
