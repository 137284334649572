import { useContext, useEffect, useRef } from "react";
import { Feature, Map, View } from "ol";
import { defaults as defaultControls, Attribution } from "ol/control";
import BrandingContext from "../../../store/BrandingContext";
import { deepValue } from "mapsted.utils/objects";
import { cartoLayer } from "mapsted.maps/mapFunctions/plotting";
import { changeMapCenterWithBoundaryPolygon } from "mapsted.maps/mapFunctions/interactions";
import { BRANDING_MAP_CONSTANTS } from "../../../_constants/constants";
import { useConstant, useEffectWithTrigger, useMergedObjects } from "../../../_utils/hooks";
import { fromLonLat } from "ol/proj";
import { Point } from "ol/geom";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import * as olStyle from "ol/style";
import { EMERG_ALERT_DEFAULT_HIGHLIGHT_COLOR } from "../../maintenance/utils";
import { isEmpty, cloneDeep } from "lodash";
import { StylesAndThemesContext } from "../../../store/StylesAndThemesContext";
import { styleClassic } from "mapsted.maps/utils/defualtStyles";
import { SettingsContext } from "../../settings/SettingsProvider";


/**
 * Gets a ol map ref with a default configuration
 * @param {{ viewOptions?: object, controlsOptions?: object }} options
 * @returns {React.MutableRefObject}
 */
export function useDefaultMapRef(options = {})
{
    const { viewOptions = {}, controlsOptions = {}, tileLayer = styleClassic.tileLayer } = options;
    const mapRef = useRef(undefined);

    useEffect(() =>
    {
        const map = new Map({
            target: null,
            layers: [cartoLayer(tileLayer)],
            controls: defaultControls({
                attribution: false,
                zoom: false,
                ...controlsOptions
            }).extend([new Attribution({ collapsible: false })]),
            view: new View({
                center: [0, 0],
                zoom: BRANDING_MAP_CONSTANTS.DEFAULT_ZOOM,
                maxZoom: BRANDING_MAP_CONSTANTS.MAX_ZOOM,
                minZoom: BRANDING_MAP_CONSTANTS.MIN_ZOOM,
                constrainRotation: false,
                ...viewOptions
            })
        });
        mapRef.current = map;

    }, []);

    return mapRef;
}


/**
 * @typedef MapState
 * @property {any} propertyId,
 * @property {any} buildingId,
 * @property {any} floorId,
 * @property {any} imageLayers,
 * @property {any} entityLayers,
 * @property {any} boundaryPolygon
 */

/**
 *
 * @param {*} targetRef ref for the map div
 * @param {MapState} [mapState]
 * @param {object} [options = {}]
 * @param {object} [options.mapOptions] options passed to `useDefaultMapRef`
 * @param {(event, map) => any} [options.onMapMoveEvent] function called on 'moveend'
 * @param {Array<number>} [options.zoomPadding] The padding used when centering the map on a geometry
 * @param {boolean} [options.autoCenter=true] Whether or not the map should automatically focus on the building
 * @returns {{ mapRef: React.MutableRefObject<Map>, recenter: () => void }} the ol map in a ref and a function to recenter the map
 */
export function useOlMap(targetRef, mapState, options = {}, cloneLayers=true)
{
    const {
        mapOptions = {},
        onMapMoveEvent = () => null,
        zoomPadding = BRANDING_MAP_CONSTANTS.FIT_PADDING_MAP_DATA,
        autoCenter = true
    } = options;

    const mapRef = useDefaultMapRef(mapOptions);
    const entityLayersRef = useRef(undefined);

    useEffect(() =>
    {
        const handleMapMoveEvent = (event) =>
        {
            const zoom = mapRef.current.getView().getZoom();

            const textLayer = deepValue(entityLayersRef.current, `${BRANDING_MAP_CONSTANTS.TEXT_LAYER}`, undefined);

            let textLayerVisible = undefined;
            if (zoom < BRANDING_MAP_CONSTANTS.MIN_ZOOM_TEXT_LAYER && !!textLayer)
            {
                textLayerVisible = false;
            }
            else if (textLayer)
            {
                textLayerVisible = true;
            }
            if (textLayerVisible !== undefined && entityLayersRef.current)
            {
                textLayer.setVisible(textLayerVisible);
            }

            onMapMoveEvent(event, mapRef.current);
        };
        mapRef.current.on("moveend", handleMapMoveEvent);
        return () => mapRef.current.un("moveend", handleMapMoveEvent);
    }, [onMapMoveEvent]);

    useEffect(() =>
    {
        mapRef.current.setTarget(targetRef.current);
    }, [targetRef]);

    useEffect(() =>
    {
        const { entityLayers, imageLayers } = mapState;
        // remove any previously added layers i.e. layers of previously selected property/building
        removeEntityLayers();

        if (cloneLayers)
        {
            // cloning is done in order to keep all the map instances completely separate and avoid olmap issues
            addEntityLayers(cloneDeep({ ...entityLayers, ...imageLayers }));
        }
        else
        {
            addEntityLayers({ ...entityLayers, ...imageLayers });
        }
        
    }, [mapState.propertyId, mapState.buildingId, mapState.floorId, mapState.entityLayers, mapState.imageLayers]);

    // recenters the map
    const recenter = useEffectWithTrigger(() =>
    {
        if (autoCenter)
        {
            const { boundaryPolygon } = mapState;
            changeMapCenterWithBoundaryPolygon({ olMap: mapRef.current, boundaryPolygon, padding: zoomPadding });
        }

    }, [mapState.propertyId, mapState.buildingId, mapState.boundaryPolygon, zoomPadding, autoCenter]);

    const addEntityLayers = (layers) =>
    {
        if (isEmpty(layers)) return;

        entityLayersRef.current = {};

        // clone array to avoid any possible olMap issues
        const existingLayers = [...mapRef.current.getLayers().getArray()];

        // add layers iff not already on map and layer not equal to -1
        Object.entries(layers).forEach(([layerId, layer]) =>
        {
            if (layerId !== BRANDING_MAP_CONSTANTS.HIDDEN_LAYER && layerId !== BRANDING_MAP_CONSTANTS.BOUNDARY_LAYER)
            {
                const alreadyExists = existingLayers.some((l) => l === layer);
                if (!alreadyExists)
                {
                    mapRef.current.addLayer(layer);
                    // keep track of the newly added layers
                    entityLayersRef.current[layerId] = layer;
                }
            }
        });
    };

    const removeEntityLayers = () =>
    {
        if (isEmpty(entityLayersRef.current)) return;

        Object.values(entityLayersRef.current).forEach((layer) =>
        {
            mapRef.current.removeLayer(layer);
        });

        entityLayersRef.current = undefined;
    };

    return { mapRef, recenter };
}

/**
 * Calls `useOlMap` but with `mapState` prefilled with data from BrandingContext
 * @param {*} targetRef ref for the map div
 * @param {object} [options = {}]
 * @param {object} [options.mapOptions] options passed to `useDefaultMapRef`
 * @param {(event, map) => any} [options.onMapMoveEvent] function called on 'moveend'
 */
export function useBrandingMap(targetRef, options = {})
{
    const { state } = useContext(BrandingContext);
    
    if (state.mapData?.tileLayer)
    {
        const tileLayer = state.mapData.tileLayer;

        if (options.mapOptions)
        {
            options.mapOptions.tileLayer = tileLayer;
        }
        else
        {
            options.mapOptions = {
                tileLayer
            };
        }
    }

    return useOlMap(
        targetRef,
        {
            propertyId: state.propertyId,
            buildingId: state.buildingId,
            floorId: state.floorId,
            entityLayers: state.entityLayers,
            imageLayers: state.imageLayers,
            boundaryPolygon: state.boundaryPolygon
        },
        options
    );
}


export function usePropertyBrandingMap(targetRef, options = {})
{
    const {  PropertySettingMapData } = useContext(SettingsContext);

    const { mapData, entityLayers, boundaryPolygon, imageLayers, propertyId } = PropertySettingMapData ;
    
    if (mapData?.tileLayer)
    {
        const tileLayer = mapData.tileLayer;

        if (options.mapOptions)
        {
            options.mapOptions.tileLayer = tileLayer;
        }
        else
        {
            options.mapOptions = {
                tileLayer
            };
        }
    }

    return useOlMap(
        targetRef,
        {
            propertyId: propertyId,            
            entityLayers: entityLayers,
            imageLayers: imageLayers,
            boundaryPolygon: boundaryPolygon
        },
        options
    );
}

export function useStylesEditorMap(targetRef, options = {})
{
    const { state: brandingCtxState } = useContext(BrandingContext);
    const { state: stylesAndThemesCtxState } = useContext(StylesAndThemesContext);

    return useOlMap(
        targetRef,
        {
            propertyId: brandingCtxState.propertyId,
            buildingId: brandingCtxState.buildingId,
            floorId: brandingCtxState.floorId,
            entityLayers: stylesAndThemesCtxState.entityLayers,
            imageLayers: stylesAndThemesCtxState.imageLayers,
            boundaryPolygon: stylesAndThemesCtxState.boundaryPolygon
        },
        options,
        false
    );
}

/**
 * Functions for controlling the map zoom
 * @param {React.MutableRefObject<Map>} mapRef
 * @returns {{
 *   changeMapZoom: (change: number) => void,
 *   setMapZoom: (value: number) => void,
 *   zoomIn: () => void,
 *   zoomOut: () => void
 *  }}
 */
export function useMapZoomMethods(mapRef)
{
    const changeMapZoom = (zoomAmmount) =>
    {
        const zoom = mapRef.current.getView().getZoom();
        const newZoom = zoom + zoomAmmount;

        setMapZoom(newZoom);
    };

    const setMapZoom = (zoom) =>
    {
        const zoomInRange = zoom >= BRANDING_MAP_CONSTANTS.MIN_ZOOM
          && zoom <= BRANDING_MAP_CONSTANTS.MAX_ZOOM;
        if (zoomInRange)
        {
            mapRef.current.getView().animate({
                zoom,
                duration: BRANDING_MAP_CONSTANTS.ANIMATION_DURATION,
            });
        }
    };

    const zoomIn = () => changeMapZoom(1);
    const zoomOut = () => changeMapZoom(-1);

    return {
        changeMapZoom,
        setMapZoom,
        zoomIn,
        zoomOut
    };
}

/**
 * Takes an array of points and draws circle on the map for them
 * @param {*} mapRef
 * @param {Array<Array<number>>} coordinates array form [long, lat]
 * @param {{ active?: boolean, style?: any }} [options]
 */
export function usePoints(mapRef, coordinates, options)
{
    const defaultOptions = useConstant({
        active: true,
        style: USE_POINTS_STYLE
    });
    const { active, style } = useMergedObjects(defaultOptions, options);
    useEffect(() =>
    {
        if (active)
        {
            const features = coordinates.map((coord) => new Feature({ geometry: new Point(fromLonLat(coord)) }));
            const pointLayer = new VectorLayer({
                source: new VectorSource({ features }),
                style
            });
            mapRef.current.addLayer(pointLayer);
            return () => mapRef.current.removeLayer(pointLayer);
        }
    }, [coordinates, mapRef, active, style]);
}
usePoints.styleFromColor = (color) => new olStyle.Style({
    fill: new olStyle.Fill({
        color: "rgba(255, 255, 255, 0.2)",
    }),
    stroke: new olStyle.Stroke({ color, width: 2 }),
    image: new olStyle.Circle({
        radius: 10,
        fill: new olStyle.Fill({ color }),
    }),
});
const USE_POINTS_STYLE = usePoints.styleFromColor(EMERG_ALERT_DEFAULT_HIGHLIGHT_COLOR);

/**
 * Functions for controlling the map zoom (similar to useMapZoomMethods, but takes olMap instance instead of ref)
 * @param {*} olMap
 * @returns {{
*   changeMapZoom: (change: number) => void,
*   setMapZoom: (value: number) => void,
*   zoomIn: () => void,
*   zoomOut: () => void
*  }}
*/
export function useMapZoom(olMap)
{
    const changeMapZoom = (zoomAmmount) =>
    {
        const zoom = olMap.getView().getZoom();
        const newZoom = zoom + zoomAmmount;

        setMapZoom(newZoom);
    };

    const setMapZoom = (zoom) =>
    {
        const zoomInRange = zoom >= BRANDING_MAP_CONSTANTS.MIN_ZOOM
          && zoom <= BRANDING_MAP_CONSTANTS.MAX_ZOOM;
        if (zoomInRange)
        {
            olMap.getView().animate({
                zoom,
                duration: BRANDING_MAP_CONSTANTS.ANIMATION_DURATION,
            });
        }
    };

    const zoomIn = () => changeMapZoom(1);
    const zoomOut = () => changeMapZoom(-1);

    return {
        changeMapZoom,
        setMapZoom,
        zoomIn,
        zoomOut
    };
}
