// NOTE: I refer to "object stores" as "collections"
import { CollectionList, CollectionNameMap } from "./collection";

export const INDEXED_DB_NAME = "ManageCMSLocal";

// update this version number if any changes to the current collection
export const LATEST_VERSION = 9;


/**
 * @param {IndexedDB} db 
 */
export const initDB = (db) =>
{
    console.log("initDB")
    const collections = CollectionList;

    // check if collections for the version exists 
    if (!collections)
    {
        return;
    }

    // check db version

    // check if DB is missing collections
    collections.forEach((collection) =>
    {
        // if the db is missing the collection
        if (!db.objectStoreNames.contains(collection.name))
        {
            // add collection to DB
            db.createObjectStore(collection.name, collection.options);
        }
    });

    // check if any collections  from the DB have been removed from the collection list
    Object.values(db.objectStoreNames).forEach((objectStoreName) =>
    {
        if (!CollectionNameMap[objectStoreName])
        {
            db.deleteObjectStore(objectStoreName);
        }
    });
}

/**
 * Creates a map from collection name to array of keys
 * @returns collectionMap
 */
export const createZangoCollectionMap = () =>
{
    const collections = CollectionList;

    // check if collections for the version exists 
    if (!collections)
    {
        return;
    }

    let collectionMap = {};

    collections.forEach((collection) =>
    {
        // since key path can be a string or array, we check both casses
        let keyArray;

        let keyPath = collection.options.keyPath;

        if (Array.isArray(keyPath))
        {
            keyArray = keyPath;
        }
        else
        {
            keyArray = [keyPath]
        }

        collectionMap[collection.name] = keyArray;
    });

    return collectionMap;
}