import React from "react";
import "./InputGroupWrap.css";
import { Popup } from "semantic-ui-react";

export const InputGroupWrap = ({ className = "", gap = "", label, children, popupMessage=null }) => 
{
    if (popupMessage)
    {
        return (
            <Popup
                content={popupMessage}
                trigger={<div className={`inputGroupWrap ${className} ${gap}`}>
                    {
                        label && (<p>{label}</p>)
                    }
                    {children}
                </div>} />
        );
    }

    return (
        <div className={`inputGroupWrap ${className} ${gap}`}>
            {
                label && (<p>{label}</p>)
            }
            {children}
        </div>
    );
};
