import React, { useCallback, useContext, useEffect,useState, useRef } from "react";
import { fromLonLat, toLonLat } from "ol/proj";
import { Button, Input } from "semantic-ui-react";
import { isEmpty } from "lodash";
import { MapButtons } from "./MapButtons";
import { BRANDING_MAP_CONSTANTS } from "../../../_constants/constants"; // TODO MOVE TO MAPSTED.MAPS
import "ol/ol.css";
import { ButtonBasic } from "../../common/ButtonIcon";
import { useSetState } from "ahooks";
import { useBrandingMap, useMapZoomMethods, usePropertyBrandingMap } from "./mapHooks";
import { changeMapCenterWithBoundaryPolygon } from "mapsted.maps/mapFunctions/interactions";
import BrandingContext from "../../../store/BrandingContext";
import { TextGroup } from "../../common/TextGroup";
import { useTranslation } from "react-i18next";

export const ZOOM_SELECT_MODES = {
    MOBILE: "mobile",
    DESKTOP: "desktop"
};
const DEFAULT_ZOOM = (BRANDING_MAP_CONSTANTS.MAX_ZOOM - BRANDING_MAP_CONSTANTS.MIN_ZOOM) / 2 + BRANDING_MAP_CONSTANTS.MIN_ZOOM;

const getInitialState = ({ zoomMobile, zoomWeb, centerMobile, centerWeb }) => 
{ 

    const zooms = {
        [ZOOM_SELECT_MODES.MOBILE]: zoomMobile || DEFAULT_ZOOM,
        [ZOOM_SELECT_MODES.DESKTOP]: zoomWeb  || DEFAULT_ZOOM,
    };
  
    const centers = {
        [ZOOM_SELECT_MODES.MOBILE]: centerMobile || undefined,
        [ZOOM_SELECT_MODES.DESKTOP]: centerWeb || undefined,
    };
  
    return { zooms, centers };
};

// since undefined or empty values can't be passed to ol map
const getMapViewOptions = (options) =>
{
    const { zoom, center } = options;

    const mapOptions = {};

    if (zoom > 0)
    {
        mapOptions.zoom = zoom;
    }
    if (!isEmpty(center?.coordinates))
    {
        mapOptions.coordinates = fromLonLat(center.coordinates);
    }
    return mapOptions;
};

const ZoomMap = ({ zoomMode = ZOOM_SELECT_MODES.DESKTOP, onChangeZoomMode = () => null, onClose, onSave, zoomData, isBuilding, rotationData, showModeSwitcher }) =>
{
    const { state: brandingState } = useContext(BrandingContext);

    const trans = useTranslation().t;

    const [state, setState] = useSetState(() => ({
        ...getInitialState( zoomData),
        mode: zoomMode,
    }));
    const { mode, zooms, centers } = state;

    const [zoomValue, setZoomValue] = useState();
    const desktopRef = useRef();
    const mobileRef = useRef();
    const mapRefs = {
        [ZOOM_SELECT_MODES.DESKTOP]: desktopRef,
        [ZOOM_SELECT_MODES.MOBILE]: mobileRef
    };

    const updateZoomsState = useCallback((zoom) =>
    {
        setState(({ zooms: prevZooms }) =>
        {
            const newZooms = { ...prevZooms };
            newZooms[mode] = zoom;            
            return { zooms: newZooms };
        });
    }, [setState, mode]);

    const updateCentersState = useCallback((center) =>
    {
        setState(({ centers: prevCenters }) =>
        {
            const newCenters = { ...prevCenters };
            newCenters[mode] = center;
            return { centers: newCenters };
        });
    }, [setState, mode]);

    const onMapMoveEvent = useCallback((event, map) =>
    {
        const zoom = map.getView().getZoom();
        const center = toLonLat(map.getView().getCenter());

        if (zooms[mode] !== zoom)
        {
            updateZoomsState(zoom);
        }
        if (!centers[mode] || centers[mode][0] !== center[0] || centers[mode][1] !== center[1])
        {
            updateCentersState(center);
        }
    }, [zooms, mode, updateCentersState, updateZoomsState]);

    const brandingMapOptions = 
    {
        mapOptions: { viewOptions: getMapViewOptions({ zoom: zooms[mode], center: centers[mode] }) },
        onMapMoveEvent,
        autoCenter: false,
    };
      
    const { mapRef } = isBuilding? useBrandingMap(mapRefs[mode], brandingMapOptions): usePropertyBrandingMap(mapRefs[mode], brandingMapOptions);
      

    // replaces the default zoom and focus with the correct map view depending on the existing settings
    useEffect(() =>
    {
        const center = centers[mode];
        const zoom = zooms[mode];
        setZoomValue(printZoom(zoom));

        if (!center)
        {
            changeMapCenterWithBoundaryPolygon({
                olMap: mapRef.current,
                boundaryPolygon: brandingState.boundaryPolygon,
                zoom,
                duration: 1
            });
        }
        else
        {
            const { mapRotation, mapRotationMobile }= rotationData;
            const rotation = mode === ZOOM_SELECT_MODES.DESKTOP  ? mapRotation : mapRotationMobile;   
            mapRef.current.getView().setRotation(rotation);

            mapRef.current.getView().animate({ zoom, duration: 1 });
            mapRef.current.getView().animate({ center: fromLonLat(center), duration: 1 });
        }
    }, [mode, brandingState.boundaryPolygon, mapRef]);

    useEffect(() =>
    {
        if (zoomMode)
        {
            setState({ mode: zoomMode });
        }
    }, [zoomMode]);

    useEffect(() =>
    {
        const zoom = zooms[mode];
        setZoomValue(printZoom(zoom));

    }, [zooms[mode]]);

    const { setMapZoom, zoomIn, zoomOut } = useMapZoomMethods(mapRef);

    const handleSliderChange = (e, { value }) =>
    {
        setMapZoom(value);
        setZoomValue(printZoom(value));
    };
    

    const handleModeChange = (newMode) =>
    {
        setState({ mode: newMode });
        onChangeZoomMode(newMode);
    };

    return (
        <div className="branding-map disableRotation ">
            {(mode === ZOOM_SELECT_MODES.DESKTOP) && (
                <div className="map-container" ref={mapRefs[mode]}>
                    <MapButtons onZoomIn={zoomIn} onZoomOut={zoomOut}  floorButtons={ isBuilding ? true : false }  />
                </div>
            )}
            {(mode === ZOOM_SELECT_MODES.MOBILE) && (
                <PhoneOverlay mapRef={mapRefs[mode]} />
            )}
            <div className="zoomScreenViewCover">
                {showModeSwitcher && <ModeButtons mode={mode} setMode={handleModeChange} />}

                <div className="anchorProcessing">
                                        
                    <div className="zoomPercentage">{trans("Settings.Zoom_Percentage")}</div>
                    <div className="processingBox">

                        <TextGroup className="zoomTextGroup">
                            <Input
                                value={ zoomValue}
                                className="zoomPercentageBox"
                                pattern="[0-9]*"
                                onChange={(event, { value }) => setZoomValue(value)}
                                onKeyPress={(event) =>
                                {
                                    if (event.key === "Enter")
                                    {
                                        setMapZoom(percentageToNumber(zoomValue));
                                    } 
                                }}

                                min={BRANDING_MAP_CONSTANTS.MIN_ZOOM}
                                max={BRANDING_MAP_CONSTANTS.MAX_ZOOM}
                            />

                            <div className="overlayTgColumn opacityInput percentSymbol">
                                <p>%</p>
                            </div>
                        </TextGroup>

                    </div> 

                    <div className="processingBox">
                        <Input
                            type="range"
                            className="setZoomSlider"
                            min={BRANDING_MAP_CONSTANTS.MIN_ZOOM}
                            max={BRANDING_MAP_CONSTANTS.MAX_ZOOM}
                            step={0.2}
                            value={zooms[mode]}
                            onChange={handleSliderChange}
                        />
                    </div>
                    <Button primary content={trans("Settings.Save")} onClick={() => onSave({ zooms, centers })} />
                    <Button color="grey" content={trans("Settings.Cancel")} onClick={onClose} />
                </div>

            </div>
            <div style={{ pointerEvents: "none", position: "absolute", left: 0, top: 0, width: "100%", height: "100%" }}>
                {(mode === ZOOM_SELECT_MODES.DESKTOP) && <Crosshairs />}
            </div>
        </div>
    );
};

export default ZoomMap;

const Crosshairs = () => (
    <img style={{
        height: "3rem",
        position: "absolute",
        left: "calc(50% - 1.5rem)",
        top: "calc(50% - 1.5rem)",

    }} src="/img/icon-anchor-point.svg" alt="center" />
);

const PhoneOverlay = ({ mapRef }) => (
    <div className="screenViewPhoneCover">
        <div className="screenViewPhoneBox" ref={mapRef}>
            <div className="screenViewActions">
                <img src="/img/mobile-view-search.svg" alt="" />
                <img src="/img/mobile-view-left.svg" alt="" />
                <img src="/img/mobile-view-right.svg" alt="" />
            </div>
            <span className="actionView"></span>
        </div>
    </div>
);

const ModeButtons = ({ setMode, mode }) =>
{
    const trans = useTranslation().t;

    return (
        <div className="zoomScreenButtonGroup">
            <ButtonBasic
                icon="screen-view-desktop"
                content={trans("Settings.Desktop")}
                className={mode === ZOOM_SELECT_MODES.DESKTOP ? "active" : ""}
                onClick={() => setMode(ZOOM_SELECT_MODES.DESKTOP)}
            />
            <ButtonBasic
                icon="screen-view-mobile"
                content={trans("Settings.Mobile")}
                className={mode === ZOOM_SELECT_MODES.MOBILE ? "active" : ""}
                onClick={() => setMode(ZOOM_SELECT_MODES.MOBILE)}
            />
        </div>
    );
};

const printZoom = (zoom) =>
{
    const rangeSize = BRANDING_MAP_CONSTANTS.MAX_ZOOM - BRANDING_MAP_CONSTANTS.MIN_ZOOM;
    const percentage = (zoom - BRANDING_MAP_CONSTANTS.MIN_ZOOM) / rangeSize;
    return `${(100 * percentage).toFixed(0)}`;
};

const percentageToNumber = (percentage) => 
{
    const rangeSize = BRANDING_MAP_CONSTANTS.MAX_ZOOM - BRANDING_MAP_CONSTANTS.MIN_ZOOM;
    const number = (percentage / 100) * rangeSize + BRANDING_MAP_CONSTANTS.MIN_ZOOM;
    return number;
};
  