import { polygon, bbox } from "@turf/turf";
import RBush from "rbush";
import { EntityType } from "mapsted.maps/utils/entityTypes";
import { doPolygonsPartallyOverlap } from "mapsted.maps/utils/geometry.utils";
import { createLinkedId } from "../_utils/mapEditorUtils";


/**
 * Class for keeping track of overlapping entities using RTree
 */
export class EntityOverlapAccess
{
    constructor(entities)
    {
        this.rTree = new RBush(); // rTree
        this.rTreeEntitiesMap = {}; // id -> rTreeEntity 
        this.overlapMap = {}; //  id -> set<id>
        this.overlapCMSIdToNavIdMap = {};

        if (Array.isArray(entities))
        {
            this.#initializeEntityOverlapAccess(entities);
        }
    }

    /**
    * handles logic when adding a new entity to active "rTree, rTreeEntitiesMap, overlapMap" when adding a new entity 
    * mutates "rTree, rTreeEntitiesMap, overlapMap"
    * @param {*} entity 
    * @param {RBush} rTree 
    * @param {*} rTreeEntitiesMap 
    */
    handleAddNewEntityToActiveOverlapMap = (entity) =>
    {
        let { rTree, rTreeEntitiesMap } = this;
        let entityId = entity._id;

        // check if we can check new entity
        if (!entityId || rTreeEntitiesMap[entityId])
        {
            console.log("CAN'T ADD ENTITY TO R TREE, IT ALREADY EXISTS")
            return false;
        }

        if (!this.#canCheckForPolygonIntersect(entity))
        {
            return false;
        }


        let entityWithBBox = this.#addRTreeVarsToEntity(JSON.parse(JSON.stringify(entity)));

        rTreeEntitiesMap[entityId] = {
            minX: entityWithBBox.bbox[0],
            minY: entityWithBBox.bbox[1],
            maxX: entityWithBBox.bbox[2],
            maxY: entityWithBBox.bbox[3],
            entity: entityWithBBox
        }

        rTree.insert(rTreeEntitiesMap[entityId]);

        // get all overlapping entity IDS
        let overlappingEntities = this.#getAllOverlappingEntities(rTreeEntitiesMap[entityId]);

        overlappingEntities.forEach(overlappingEntity =>
        {
            this.#addEntitiesToOverlapMap(entityId, overlappingEntity);
        });

    }

    /**
    * handles logic when removing a new entity from an active "rTree, rTreeEntitiesMap, overlapMap" when removing a new entity 
    * mutates "rTree, rTreeEntitiesMap, overlapMap"
    * @param {*} entity 
    * @param {RBush} rTree 
    * @param {*} rTreeEntitiesMap 
    */
    handleRemoveEntityFromActiveOverlapMap = (entity) =>
    {
        let { rTree, rTreeEntitiesMap, overlapMap } = this;

        let entityId = entity?._id

        // check if we can check new entity
        if (!entityId || !rTreeEntitiesMap[entityId])
        {
            console.log("CAN'T DELETE ENTITY FROM R TREE, IT DOESN'T EXIST")
            return false;
        }

        // remove entity from RTree using id ref
        rTree.remove(rTreeEntitiesMap[entityId]);

        // remove entity from rTreeMap
        delete rTreeEntitiesMap[entityId]

        //
        let overlappingIdSet = overlapMap[entityId];

        if (!!overlappingIdSet)
        {
            // remove deleted entity ID from any overlapping set
            overlappingIdSet.forEach(overlappingId =>
            {
                // the loop could delete a set that would have previously existed, 
                // therefor we check if it exists first
                if (!!overlapMap[overlappingId])
                {
                    // overlapMap[overlappingId] should be a set
                    overlapMap[overlappingId].delete(entityId)
                    if (overlapMap[overlappingId].size === 0)
                    {
                        delete overlapMap[overlappingId];
                    }
                }
            });
        }

        delete overlapMap[entityId];
    }

    /**
    * handles logic when editing an entity from an active "rTree, rTreeEntitiesMap, overlapMap" when editing an entity 
    * mutates "rTree, rTreeEntitiesMap, overlapMap"
    * @param {*} entity 
    * @param {RBush} rTree 
    * @param {*} rTreeEntitiesMap 
    */
    handleEditEntityInActiveOverlapMap = (entity) =>
    {
        this.handleRemoveEntityFromActiveOverlapMap(entity);
        this.handleAddNewEntityToActiveOverlapMap(entity);
    }

    /**
     * @returns list of all entities that overlap with at least 1 entity
     */
    getOverlappingEntityFilteredIdList = () =>
    {
        const filteredCmsIds = Object.keys(this.overlapMap);

        return filteredCmsIds.map((cmsId) => 
        {
            return {
                cmsId,
                navId: this.overlapCMSIdToNavIdMap[cmsId]
            }
        });
    }

    getOverlapMap = () =>
    {
        return this.overlapMap;
    }

    //#region Private Helpers

    /**
     * Private init function, called when constructor is given entities array
     * @param {*} entities 
     * @returns 
     */
    #initializeEntityOverlapAccess(entities)
    {
        let startTime = performance.now();
        let { rTree, rTreeEntitiesMap, overlapMap } = this;

        // IF Entities is not an array, return...
        if (!Array.isArray(entities))
        {
            return;
        }


        // loop throuh all the entities 
        // add all entities that can check for polygon intersect to rTreeMap that will be loaded into a rTree
        for (let i = 0; i < entities.length; i++)
        {
            let entityI = entities[i];

            if (!this.#canCheckForPolygonIntersect(entityI))
            {
                continue;
            }

            entityI = this.#addRTreeVarsToEntity(entityI);

            // rBush is faster when load inserting by 20-30%
            rTreeEntitiesMap[entityI._id] = {
                minX: entityI.bbox[0],
                minY: entityI.bbox[1],
                maxX: entityI.bbox[2],
                maxY: entityI.bbox[3],
                entity: entityI
            }
        }

        rTree.load(Object.values(rTreeEntitiesMap));

        let numberOfCollisions = 0; // for benchmarking

        // keep track of what entites we already checked to avoid intensive calculations
        let checkedEntitiesSet = new Set();

        Object.values(rTreeEntitiesMap).forEach((rTreeEntity) =>
        {
            // find all entities where the bounding boxes intersect with the current entity's bounding box
            let searchResult = rTree.search(rTreeEntity);

            if (searchResult.length > 0)
            {
                numberOfCollisions += searchResult.length;

                // check if any of the possible overlaping entities actually overlap with the current entity
                searchResult.forEach(possibleOverlappingEntity =>
                {
                    const entity1 = rTreeEntity.entity;
                    const entity2 = possibleOverlappingEntity.entity;

                    const entityId1 = entity1._id;
                    const entityId2 = entity2._id;

                    // make sure we are not wasting time checking entities that we already looked at
                    if (entityId1 !== entityId2 && !checkedEntitiesSet.has(createLinkedId(entityId1, entityId2)))
                    {
                        let overlap = doPolygonsPartallyOverlap(rTreeEntity.entity.polygon, possibleOverlappingEntity.entity.polygon);

                        // if polygons overlap, add to map that keeps track of what polygons overlap with what
                        if (overlap)
                        {
                            this.#addEntitiesToOverlapMap(entity1, entity2);
                        }

                        checkedEntitiesSet.add(createLinkedId(entityId1, entityId2));
                        checkedEntitiesSet.add(createLinkedId(entityId2, entityId1));
                    }
                });
            }
        })

        let endTime = performance.now();
        console.log(`create rtree of size (${Object.keys(rTreeEntitiesMap).length}) with (${numberOfCollisions}) overlap checks took ${endTime - startTime} milliseconds `)

        this.overlapMap = overlapMap;
        this.rTree = rTree;
        this.rTreeEntitiesMap = rTreeEntitiesMap;
    }

    /**
     * Adds two overlapping entities to the overlap map
     * @param {String} entityId1 
     * @param {String} entityId2 
     */
    #addEntitiesToOverlapMap = (entity1, entity2) =>
    {
        const entityId1 = entity1._id;
        const entityId2 = entity2._id;
        const entityNavId1 = entity1.entityId;
        const entityNavId2 = entity2.entityId;

        let overlapMap = this.overlapMap;
        let overlapCMSIdToNavIdMap = this.overlapCMSIdToNavIdMap;
        if (!overlapMap[entityId1])
        {
            overlapMap[entityId1] = new Set();
            overlapCMSIdToNavIdMap[entityId1] = entityNavId1;
        }

        if (!overlapMap[entityId2])
        {
            overlapMap[entityId2] = new Set();
            overlapCMSIdToNavIdMap[entityId2] = entityNavId2;
        }

        overlapMap[entityId1].add(entityId2);
        overlapMap[entityId2].add(entityId1);
    }

    /**
     * Returns a list of entity ids that overlap with the given entity
     * @param {*} rTreeEntity 
     * @returns overlappingEntityIds
     */
    #getAllOverlappingEntities = (rTreeEntity) =>
    {
        let rTree = this.rTree;

        // find all entities where the bounding boxes intersect with the current entity's bounding box
        let searchResult = rTree.search(rTreeEntity);

        let overlappingEntities = [];

        if (searchResult.length > 0)
        {
            // check if any of the possible overlaping entities actually overlap with the current entity
            searchResult.forEach(possibleOverlappingEntity =>
            {
                let entityId1 = rTreeEntity.entity._id;
                let entityId2 = possibleOverlappingEntity.entity._id;


                if (entityId1 !== entityId2)
                {
                    let overlap = doPolygonsPartallyOverlap(rTreeEntity.entity.polygon, possibleOverlappingEntity.entity.polygon);

                    // if polygons overlap, add to overlappingEntities
                    if (overlap)
                    {
                        overlappingEntities.push(possibleOverlappingEntity.entity);
                    }
                }
            });
        }
        return overlappingEntities;
    }

    /**
     * adds polygon and bbox to entity object
     * @param {Object} entity 
     * @returns 
     */
    #addRTreeVarsToEntity = (entity) =>
    {
        entity.polygon = polygon(entity.shape.coordinates);
        entity.bbox = bbox(entity.polygon);

        return entity;
    }


    /**
     * Checks if the entity is valid to check for a polgon intersect
     * @param {*} entity 
     * @returns boolean
     */
    #canCheckForPolygonIntersect = (entity) =>
    {
        if (entity.entityType !== EntityType.BOUNDARY)
        {
            return entity?.shape?.type === "Polygon";
        }
    }

    //#endregion Helpers
}

