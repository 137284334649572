import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';

export const QuestionPopup = ({content}) =>
{
    return (
        <Popup 
            content={content}
            trigger={<Icon name="question circle" color="blue"/>}
        />
    );
}