export class EntityStep
{
    addedEntities;
    deletedEntities;
    updatedEntitiesBefore;
    updatedEntitesAfter;

    constructor(obj)
    {
        Object.assign(this, obj);
    }
}
