import { DEFAULT_LANGUAGE_CODE, IGNORED_OVERLAY_TEMPLATE_FIELDS, MAP_OVERLAY_ZOOM_CONSTANTS } from "../../../../_constants/constants";
import booleanContains from "@turf/boolean-contains";
import { getAllAvailableMapOverlayLangs, getShape, getShapeFromGeometry } from "./mapOverlayUtils";

const validateMapOverlay = (mapOverlay, trans) =>
{
    let validationErrors = [];

    if (!mapOverlay.name?.[DEFAULT_LANGUAGE_CODE])
    {
        validationErrors.push(trans("MapOverlaysUploadCsvModal.Name_Is_Mandatory"));
    }
    else 
    {
        // check if map overlay name has all the available lang codes which are entered in other text fields in map overlay
        const allAvailableLangCodes = getAllAvailableMapOverlayLangs(mapOverlay);
        allAvailableLangCodes.forEach((langCode) =>
        {
            if (!(mapOverlay.name[langCode]))
            { 
                validationErrors.push(trans("MapOverlaysUploadCsvModal.Name_Required_In_Lang", { langCode: langCode.toUpperCase() }));
            }
        });
    }

    if (!mapOverlay.vectorLayer)
    {
        validationErrors.push(trans("MapOverlaysUploadCsvModal.Overlay_Polygon_Required"));
    }

    validationErrors.push(...validateDynamicOverlaySettings(mapOverlay.dynamicOverlaySettings, trans));

    if (!mapOverlay.dynamicOverlaySettings?.enabled)
    {
        if (!(mapOverlay.defaultFillOpacity || mapOverlay.defaultFillOpacity === 0))
        {
            validationErrors.push(trans("MapOverlaysUploadCsvModal.Invalid_default_fill_opacity"));
        }

        if (!(mapOverlay.defaultBorderFillOpacity || mapOverlay.defaultBorderFillOpacity === 0))
        {
            validationErrors.push(trans("MapOverlaysUploadCsvModal.Invalid_default_border_opacity"));
        }

        if (!(mapOverlay.defaultTextOpacity || mapOverlay.defaultTextOpacity === 0))
        {
            validationErrors.push(trans("MapOverlaysUploadCsvModal.Invalid_default_text_opacity"));
        }
    }

    return validationErrors;
};


export const validateLinkedMapOverlay = (mapOverlay) => 
{
    let validationErrors = [];

    if (!mapOverlay.name?.[DEFAULT_LANGUAGE_CODE]) 
    {
        validationErrors.push("Name is mandatory");
    }
    else 
    {
        // check if map overlay name has all the available lang codes which are entered in other text fields in map overlay
        const allAvailableLangCodes = getAllAvailableMapOverlayLangs(mapOverlay);
        allAvailableLangCodes.forEach((langCode) => 
        {
            if (!(mapOverlay.name[langCode])) 
            {
                validationErrors.push(`Name required in ${langCode.toUpperCase()} language`);
            }
        });
    }

    if (!mapOverlay.vectorLayer) 
    {
        validationErrors.push("Overlay polygon is mandatory");
    }

    return validationErrors;
};
/**
 * Validates zoom levels if dynamic settings is enabled and return validaton errors if failed.
 * @param {Object} dynamicOverlaySettings 
 * @returns {Array<String>} validationErrors 
 */
const validateDynamicOverlaySettings = (dynamicOverlaySettings, trans) =>
{
    let validationErrors = [];

    if (dynamicOverlaySettings?.enabled)
    {
        const zoomLevels = dynamicOverlaySettings.zoomLevels;

        for (let i=0; i<zoomLevels.length; i++)
        {
            const currentZoomLevel = zoomLevels[i];

            // update level name which will be added in error message based on index
            let levelIdentifierMsg = trans("OverlaysTemplateSideBar.At_Index", { index: i });
            if (i === 0)
            {
                levelIdentifierMsg = trans("OverlaysTemplateSideBar.Start_Zoom");
            }
            else if (i === zoomLevels.length - 1)
            {
                levelIdentifierMsg = trans("OverlaysTemplateSideBar.End_Zoom");
            }

            if (!currentZoomLevel.value)
            {
                
                validationErrors.push(trans("OverlaysTemplateSideBar.Missing_Zoom_Value_Error", {
                    levelIdentifierMsg: `${levelIdentifierMsg}`
                }));
                break;
            }
            
            if (i !== 0 && zoomLevels[i - 1].value >= currentZoomLevel.value)
            {
                validationErrors.push(trans("OverlaysTemplateSideBar.Invalid_Zoom_Value_Error", {
                    levelIdentifierMsg: `${levelIdentifierMsg}`
                }));
                break;
            }

            if (!(currentZoomLevel.fillOpacity || currentZoomLevel.fillOpacity === 0))
            {
                validationErrors.push(trans("OverlaysTemplateSideBar.Invalid_Fill_Capacity", {
                    levelIdentifierMsg: `${levelIdentifierMsg}`
                }));
                break;
            }

            if (!(currentZoomLevel.borderFillOpacity || currentZoomLevel.borderFillOpacity === 0))
            {
                validationErrors.push(trans("OverlaysTemplateSideBar.Invalid_Border_Opacity", {
                    levelIdentifierMsg: `${levelIdentifierMsg}`
                }));
                break;
            }

            if (!(currentZoomLevel.textOpacity || currentZoomLevel.textOpacity === 0))
            {
                validationErrors.push(trans("OverlaysTemplateSideBar.Invalid_Text_Opacity", {
                    levelIdentifierMsg: `${levelIdentifierMsg}`
                }));
                break;
            }
        }
    }

    return validationErrors;
};

export default validateMapOverlay;

/**
 * Function to check if a polygon extent completely overlaps or is getting overlapped by another set of goemetry extents
 * @param {Object} newPolygon 
 * @param {Object} existingGeometries 
 * @returns {Boolean} isValid
 */
export const validateIfPolygonOverlaps = (newPolygon, existingGeometries) =>
{
    let isValid = true;
    const newOverlayGeometry = getShapeFromGeometry(newPolygon);
    for (let i=0; i<existingGeometries.length; i++)
    {
        const existingGeometry = existingGeometries[i];
        const exisitngOverlayGeometry = getShapeFromGeometry(existingGeometry);
        if (checkIfGeometryOverlaps(newOverlayGeometry, exisitngOverlayGeometry))
        {
            isValid = false;
            break;
        }
    }

    return isValid;
};

export const validateIfOverlayOverlaps = (mapOverlay, existingMapOverlays) =>
{
    let isValid = true;

    const newOverlayZoomRange = getZoomRange(mapOverlay);
    const newOverlayGeometry = getOverlayShape(mapOverlay);

    for (let i=0; i<existingMapOverlays.length; i++)
    {
        const exisitingMapOverlay = existingMapOverlays[i];

        const exisitingOverlayZoomRange = getZoomRange(exisitingMapOverlay);
        const exisitngOverlayGeometry = getOverlayShape(exisitingMapOverlay);

        if (checkIfGeometryOverlaps(newOverlayGeometry, exisitngOverlayGeometry) 
        && checkIfZoomRangeOverlaps(newOverlayZoomRange, exisitingOverlayZoomRange))
        {
            isValid = false;
            break;
        }
    }

    return isValid;
};

/**
 * Function to check if a mapOverlay geometry compeletely overlaps another mapOverlay
 * @param {Object} geometry1 
 * @param {Object} geometry2 
 * @returns {Boolean}
 */
const checkIfGeometryOverlaps = (geometry1, geometry2) => booleanContains(geometry1, geometry2);

/**
 * Function to check if mapOverlay zoom ranges overlap.
 * @param {Array<Number>} zoomRange1 
 * @param {Array<Number>} zoomRange2 
 * @returns {Boolean}
 */
const checkIfZoomRangeOverlaps = (zoomRange1, zoomRange2) => zoomRange1[1] > zoomRange2[0] && zoomRange2[1] > zoomRange1[0];

/**
 * Function to get zoom range (start zoom and end zoom) from mapOverlay in array form.
 * @param {Object} mapOverlay 
 * @returns {Array<Number>}
 */
const getZoomRange = (mapOverlay) =>
{
    if (mapOverlay.dynamicOverlaySettings.enabled)
    {
        const zoomLevels = mapOverlay.dynamicOverlaySettings.zoomLevels;
        return [zoomLevels[0].value, zoomLevels[zoomLevels.length - 1].value];
    }
    else
    {
        return [MAP_OVERLAY_ZOOM_CONSTANTS.MIN_ZOOM, MAP_OVERLAY_ZOOM_CONSTANTS.MAX_ZOOM];
    }
};

/**
 * Function to return geometry shape of mapOverlay
 * @param {Object} mapOverlay 
 * @returns {Object} geometryShape
 */
const getOverlayShape = (mapOverlay) =>
{
    const feature = mapOverlay.vectorLayer.getSource().getFeatureById(mapOverlay._id);
    return getShape(feature);
};