import { useSetState } from "ahooks";
import { createEntityVectorLayersTopology, createTransitionsTextLayer } from "mapsted.maps/mapFunctions/cmsVectorLayers";
import { CMSTopologyEntityAccess } from "mapsted.maps/mapFunctions/entityAccess";
import { STYLE_TYPES } from "mapsted.maps/utils/defualtStyles";
import { EntityRefTypes } from "mapsted.maps/utils/entityTypes";
import { MapEditorConstants } from "mapsted.maps/utils/map.constants";
import { getDefaultFloorIdFromCMSBuilding, getMapStyle } from "mapsted.maps/utils/map.utils";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import mapEditiorApi from "../_api/mapEditior.api";
import serverApi from "../_api/server.api";
import socket from "../_api/socket";
import { DEFAULT_MAP_VISIBILITY, EDITOR_ALERTS, EDIT_TYPES, FILTERED_ID_TYPES, MAP_EDITOR_TOOLS, MAP_LAYERS } from "../_constants/mapEditor";
import { CollectionNameMap } from "../_indexedDB/collections/v1/collection";
import mapEditorLocalDb from "../_indexedDB/mapEditorLocal.db";
import { getDefaultMapLayerVisability, getNodeDataFromTopologyMapData } from "../_utils/mapEditorUtils";
import { deepCopy, filerUrl } from "../_utils/utils";
import { validationBoxInitActiveLevelKeys } from "../components/branding/ValidationInfo/ValidationInfoSection";
import { Loader } from "../components/elements/loader";
import { convertStepDataToBackendFormat } from "../components/mapEditor/FloorPlanGeoReferencing/utils/floorPlan.utils";
import BrandingContext from "./BrandingContext";
import MapEditorContext from "./MapEditiorContext";

let defaultState = {
    loading: true,
    propertyId: undefined,
    buildingId: undefined,
    floorId: undefined,
    georeferenceBuildingId: undefined,
    georeferenceFloorId: undefined,
    georeferenceMapData: undefined,
    georeferenceLayers: undefined,
    mapData: undefined, // contains {topology, style}
    style: undefined,
    layers: undefined, // array of OL layers that are being plotted
    editType: EDIT_TYPES.NODES, // handle edit types
    selectedTool: undefined,
    stepAction: undefined,
    userLock: undefined,
    isLevelLockedByUser: undefined,
    socketConnected: false,
    companyLocks: undefined,
    mapLayersVisablityMap: DEFAULT_MAP_VISIBILITY,
    propertyTransitions:
    {
        propertyId: undefined,
        transitionNodeIds: {} // boolean map
    },
    transitionsData: [],
    selectedFilter: undefined,
    undoRedoButtonClick: undefined, // MAP_EDITOR_SHORTCUTS / undefined
    canRedo: false, // true/false
    canUndo: false, // true/false
    isLockedOnDifferentMachine: false,
    localObjects: [],
    featureSearchResults: [], // [{type, navId, cmsId}] list of search results for entities and nodes
};

/**
 *
 * Note: any exported function should have params as an object.
 * @param {*} props
 * @returns
 */
export const MapEditorProvider = (props) =>
{
    const propertyIdRef = useRef();
    const buildingIdRef = useRef();
    const floorIdRef = useRef();
    const fileUrlRef = useRef();
    const companyLocksRef = useRef();
    const selectedToolRef = useRef();

    const [state, setState] = useSetState(defaultState);
    const [stepActionQueue, setStepActionQueue] = useState([]);
    const [isSideBarExpanded, setIsSideBarIsExpanded] = useState(false);
    const [isValidationBoxOpen, setIsValidationBoxOpen] = useState(false);
    const [validationBoxActiveLevelKeys, setValidationBoxActiveLevelKeys] = useState(validationBoxInitActiveLevelKeys);

    const brandingContext = useContext(BrandingContext);

    propertyIdRef.current = state.propertyId;
    buildingIdRef.current = state.buildingId;
    floorIdRef.current = state.floorId;

    useEffect(() =>
    {
        selectedToolRef.current = state.selectedTool;
    }, [state.selectedTool]);

    const companyLocksMemo = useMemo(() => companyLocksRef.current, [companyLocksRef.current]);

    const initializeMapEditor = async () =>
    {
        // set loading to true
        setState({ loading: true });
        fileUrlRef.current = filerUrl("");

        // pull all locks for companyUID
        const companyLocksResponse = await mapEditiorApi.getCompanyLocks();
        const dashboardData = serverApi.dashboardData;

        // check company locks to see if user has a lock
        // this is to fix a case where user was logged in on two machines, lock data would stay stored if unlocked on a different machine than originally locked
        if (companyLocksResponse.success && Array.isArray(companyLocksResponse.data))
        {
            const userId = serverApi.userData.user.userInfo.id;
            // check if userUID is in companyLocks array
            const userLock = companyLocksResponse.data.find((lock) => lock.userId === userId);

            // if user doesn't have a lock clear any indexDB items that might be stored
            if (!userLock)
            {
                mapEditorLocalDb.deleteAllAllEditTypes();
            }
        }

        let editType = dashboardData?.editType ?? EDIT_TYPES.ENTITIES;

        companyLocksRef.current = companyLocksResponse?.data ?? [];

        setState({ loading: false, editType, });
    };

    useEffect(() =>
    {
        const properties = brandingContext.state.properties;
        setState({ properties });
    }, [brandingContext.state.properties]);

    useEffect(() =>
    {
        setState({
            georeferenceBuildingId: undefined,
            georeferenceFloorId: undefined
        });
    }, [brandingContext.state.propertyId]);

    // componentDidMount
    useEffect(() =>
    {
        initializeMapEditor();
        // connect to socket
        let userData = serverApi.userData.user;
        if (userData)
        {
            const companyUID = userData.userCompanyInfo.companyUID;
            const userId = userData.userInfo.id;

            if (socket.connected)
            {
                socket.emit("handshake", { companyUID, userId });
            }
            else
            {
                socket.connect();
            }

            socket.on("connect", () =>
            {
                setState({ socketConnected: true });
                socket.emit("handshake", { companyUID, userId });
            });

            socket.on("create-lock", (data) =>
            {
                console.log("create-lock", data);
                // ignore socket call if it was made by the same user AND its not validation
                const userUID = serverApi.getUserUID();
                if (data.editType !== EDIT_TYPES.VALIDATION && data.userId === userUID)
                {
                    return;
                }
                handleSocketLocksUpdate(data, true);
            });

            socket.on("remove-lock", (data) =>
            {
                // ignore socket call if it was made by the same user AND its not validation
                const userUID = serverApi.getUserUID();
                if (data.editType !== EDIT_TYPES.VALIDATION && data.userId === userUID)
                {
                    return;
                }
                handleSocketLocksUpdate(data, false);
            });

            socket.on("disconnect", () =>
            {
                setState({ socketConnected: false });
                console.log("socket disconnected");
            });

            socket.on("connect_error", (err) =>
            {
                console.log("connect_error due to ", err.stack);
            });

            return () =>
            {
                socket.disconnect();
            };
        }

    },// eslint-disable-next-line react-hooks/exhaustive-deps
    []);

    //#region  navigation IDs

    const getNavIdsFromMongoIds = useCallback((propertyId, buildingId, floorId) =>
    {
        const properties = brandingContext.state.properties;
        let navPropertyId = -1, navBuildingId = -1, navFloorId = -1;

        if (!!properties && propertyId)
        {
            const property = properties[propertyId];
            navPropertyId = property.propertyId;

            if (buildingId)
            {
                const building = property.buildings[buildingId];
                const floor = building.floors.find((floor) => floor._id === floorId);

                navBuildingId = building.buildingId;
                navFloorId = floor.floorId;
            }
        }

        return {
            propertyId: navPropertyId,
            buildingId: navBuildingId,
            floorId: navFloorId,
        };
    }, [brandingContext.state.properties]);

    const mainNavIdsMemo = useMemo(() =>
    {
        const { propertyId, buildingId, floorId, } = state;

        return getNavIdsFromMongoIds(propertyId, buildingId, floorId,);

    }, [state.propertyId, state.buildingId, state.floorId, getNavIdsFromMongoIds]);

    const georeferenceNavIdsMemo = useMemo(() =>
    {
        const { propertyId, georeferenceBuildingId, georeferenceFloorId } = state;

        return getNavIdsFromMongoIds(propertyId, georeferenceBuildingId, georeferenceFloorId);

    }, [state.propertyId, state.georeferenceBuildingId, state.georeferenceFloorId, getNavIdsFromMongoIds]);

    //#endregion

    /**
     *
     * @param {Object} layerVisablityChanges
     */
    const handleUpdateMapLayersVisablity = (layerVisablityChanges) =>
    {
        let mapLayersVisablityMap = { ...state.mapLayersVisablityMap };

        // remove any property in this object that is not a reference to a map layer
        Object.keys(layerVisablityChanges).forEach((key) =>
        {
            if (!MAP_LAYERS[key])
            {
                delete layerVisablityChanges[key];
            }
        });

        Object.assign(mapLayersVisablityMap, layerVisablityChanges);

        setState({ mapLayersVisablityMap });
    };

    const handleSocketLocksUpdate = async (data, isCreated, isUpdatingLoadingState = true) =>
    {
        let companyLocks = deepCopy(companyLocksRef.current);

        let mapData = {};

        // ignore majority of logic on on validation
        if (data.editType !== EDIT_TYPES.VALIDATION)
        {
            if (!isCreated)
            {
                const propertyId = propertyIdRef.current;
                const buildingId = buildingIdRef.current;
                const floorId = floorIdRef.current;

                let lockIdx = companyLocks.findIndex((lock) => lock._id === data._id);

                if (lockIdx === -1)
                {
                    return;
                }

                if (data.propertyId === propertyId && data.buildingId === buildingId && data.floorId === floorId)
                {
                    if (isUpdatingLoadingState)
                    {
                        setState({ loading: true });
                    }
                    mapData = await handleGetMapDataAsync({ propertyId, buildingId, floorId, });
                }
            }

            // this call needs to be before the locked on different machine check
            const companyLocksResponse = await mapEditiorApi.getCompanyLocks();
            companyLocksRef.current = companyLocksResponse.data;

            let localLock = await mapEditorLocalDb.find(CollectionNameMap.lock) || [];
            let isLockedOnDifferentMachine;

            if (localLock.length === 0)
            {
                // throw warning or ask to delete all unsaved changes....
                isLockedOnDifferentMachine = true;
            }

            if (isUpdatingLoadingState)
            {
                setState({ ...mapData, loading: false, isLockedOnDifferentMachine });
            }
            else
            {
                setState({ ...mapData, isLockedOnDifferentMachine });
            }
        }
        else
        {
            const companyLocksResponse = await mapEditiorApi.getCompanyLocks();
            companyLocksRef.current = companyLocksResponse.data;
        }


    };

    /**
     *
     * @param {String} propertyId
     * @param {String} buildingId
     * @param {String} floorId
     * @param {String} theme
     * @param {String} fileUrl
     *
     * sets {mapData, entityLayers} state
     */
    const handleGetMapDataAsync = async ({ propertyId, buildingId, floorId, theme, }) =>
    {
        const { data: userLock } = await mapEditiorApi.getUserLock();
        let { georeferenceBuildingId, georeferenceFloorId, propertyId: oldPropertyId } = state;

        // this is to prevent a case where property id updates but georeference building id does not
        if (oldPropertyId !== propertyId)
        {
            georeferenceBuildingId = undefined;
            georeferenceFloorId = undefined;
        }

        const editType = state.editType;

        if (!propertyId)
        {
            return;
        }

        let localObjects = {};
        let isLevelLockedByUser = false;
        let propertyTransitions = state.propertyTransitions;
        let transitionsData = state.transitionsData;
        let lastStep = undefined;
        let isLockedOnDifferentMachine = false;

        // -> pull propertyTransitions
        propertyTransitions.propertyId = propertyId;
        propertyTransitions.transitionNodeIds = {};

        let { success: transitionSuccess, data } = await mapEditiorApi.getPropertyTransitions(propertyId);

        transitionsData = data;

        // check if there is a lock record in the current browser, if not the user locked the property on a different browser and we don't have the data

        // check if current level is locked by user
        // -> if it is pull local data
        if (userLock && userLock?.floorId === floorId && userLock.propertyId === propertyId && userLock.buildingId === buildingId)
        {
            let localLock = await mapEditorLocalDb.find(CollectionNameMap.lock) || [];

            if (localLock.length === 0)
            {
                // throw warning or ask to delete all unsaved changes....
                isLockedOnDifferentMachine = true;
            }

            lastStep = await mapEditorLocalDb.handleGetLastStep();
            isLevelLockedByUser = true;

            if (userLock.editType === EDIT_TYPES.NODES)
            {
                // pull nodes from local
                const localNodes = await mapEditorLocalDb.find(CollectionNameMap.nodes);

                localObjects[EDIT_TYPES.NODES] = localNodes;
            }
            else if (userLock.editType === EDIT_TYPES.ENTITIES)
            {
                // pull entities from local
                const localEntities = await mapEditorLocalDb.find(CollectionNameMap.entities);

                localObjects[EDIT_TYPES.ENTITIES] = localEntities;
            }
        }

        if (userLock && userLock.editType === EDIT_TYPES.TRANSITIONS && userLock.propertyId === propertyId)
        {
            let localLock = await mapEditorLocalDb.find(CollectionNameMap.lock) || [];

            if (localLock.length === 0)
            {
                // throw warning or ask to delete all unsaved changes....
                isLockedOnDifferentMachine = true;
            }

            isLevelLockedByUser = true;
            const localTransitions = await mapEditorLocalDb.find(CollectionNameMap.transitions);
            transitionsData = localTransitions;
        }

        if (Array.isArray(transitionsData))
        {
            transitionsData.forEach((transition) =>
            {
                transition.route.forEach((transitionNode) =>
                {
                    propertyTransitions.transitionNodeIds[transitionNode.node] = true;
                });
            });
        }

        // get property/floor topology map data
        const { mapData, layers, style, entryExitTopology } = await handleGetMapDataAndLayers(propertyId, buildingId, floorId, localObjects);
        // get georeference map data
        let georeferenceMapData, georeferenceLayers, transitionsTextLayer, georeferenceTransitionsTextLayer;

        if (editType === EDIT_TYPES.TRANSITIONS)
        {
            let georeferenceData = await handleGetMapDataAndLayers(propertyId, georeferenceBuildingId, georeferenceFloorId, localObjects);
            georeferenceMapData = georeferenceData.mapData;
            georeferenceLayers = georeferenceData.layers;

            const navIds = getNavIdsFromMongoIds(propertyId, buildingId, floorId,);
            const georeferenceNavIds = getNavIdsFromMongoIds(propertyId, georeferenceBuildingId, georeferenceFloorId);

            const transitionLayerObjects = await createTransitionLayers({
                transitionsData,
                georeferenceMapData,
                navIds,
                georeferenceNavIds,
                mapData,
                nodeLayer: layers.nodeLayers.node,
                georeferenceNodeLayer: georeferenceLayers.nodeLayers.node,
            });

            transitionsTextLayer = transitionLayerObjects.transitionsTextLayer;
            georeferenceTransitionsTextLayer = transitionLayerObjects.georeferenceTransitionsTextLayer;
        }

        // using topology create map layers

        // propertyIdRef.current = propertyId;
        // buildingIdRef.current = buildingId;
        // floorIdRef.current = floorId;

        const dashboardData = serverApi.dashboardData;
        const mapLayersVisablityMap = getDefaultMapLayerVisability(dashboardData.editType);

        return {
            propertyId,
            buildingId,
            floorId,
            mapData,
            style,
            layers,
            userLock,
            isLockedOnDifferentMachine,
            georeferenceMapData,
            georeferenceLayers,
            transitionsData,
            transitionsTextLayer,
            georeferenceTransitionsTextLayer,
            isLevelLockedByUser,
            propertyTransitions,
            selectedTool: undefined,
            mapLayersVisablityMap,
            selectedFilter: undefined,
            canUndo: !!lastStep,
            canRedo: false
        };
    };

    /**
     * Changes selectedTool state
     * @param {String} toolName - tool name from MAP_EDITOR_TOOLS constant object list
     */
    const handleChangeSelectedTool = (toolName, alertCallback) =>
    {
        const { selectedTool } = state;

        let alertsMessage;
        if (toolName)
        {
            alertsMessage = getLevelNotLockedAlertMessage();
        }

        if (alertsMessage)
        {
            (alertCallback) && alertCallback(alertsMessage);
            return;
        }
        else if (toolName === selectedTool)
        {
            setState({ selectedTool: undefined });
        }
        else
        {
            setState({ selectedTool: toolName });
        }
    };

    // TODO - add this logic with level lock info
    const getLevelNotLockedAlertMessage = () =>
    {
        let alertsMessage;
        const { userLock, editType, isLockedOnDifferentMachine } = state;
        const levelLockInfo = getLevelLockInfo();

        // get alert message
        if (levelLockInfo.isLevelLockedByAnotherUser)
        {
            alertsMessage = `${EDITOR_ALERTS.LEVEL_IS_LOCKED_BY_ANOTHER_USER} ${levelLockInfo.displayName}.`;
        }
        else if (!levelLockInfo.isLevelLocked)
        {
            alertsMessage = EDITOR_ALERTS.LEVEL_NOT_LOCKED;
        }
        else if (userLock.editType !== editType)
        {
            alertsMessage = EDITOR_ALERTS.EDIT_TYPE_NOT_LOCKED;
        }
        else if (isLockedOnDifferentMachine)
        {
            alertsMessage = EDITOR_ALERTS.LEVEL_IS_LOCKED_ON_DIFFERENT_MACHINE;
        }

        return alertsMessage;
    };

    /**
     * get property level map data for the given propertyId
     * @param {String} propertyId - UID
     * @returns {topology, style}
     */
    const getPropertyLevelTopologyMapDataAsync = async (propertyId, localObjects) =>
    {
        let entryExitTopologyData;
        const { data: mapData } = await mapEditiorApi.getPropertyTopologyMapData(propertyId, localObjects);
        const { data: entryExitTopologyDataRes } = await mapEditiorApi.getAllEntryExitEntitiesAndNodesTopology(propertyId);

        if (entryExitTopologyDataRes?.buildings?.topology)
        {
            entryExitTopologyData = entryExitTopologyDataRes.buildings;
        }

        return { mapData, entryExitTopologyData };
    };

    /**
     * get floor level map data for the given floorId
     * @param {String} buildingId
     */
    const getFloorLevelTopologyMapDataAsync = async (buildingId, floorId, localObjects) =>
    {
        const { data } = await mapEditiorApi.getFloorTopologyMapData(buildingId, floorId, localObjects);

        return data;
    };

    /**
     * Creates a lock for a given property, building, floor, and edit type.
     * Used by handle lock
     *
     * @param {Object} options - The options object.
     * @param {string} options.propertyId - The ID of the property.
     * @param {string} options.buildingId - The ID of the building.
     * @param {string} options.floorId - The ID of the floor.
     * @param {string} options.editType - The type of edit.
     * @return {Promise<createLockResult>} - A promise that resolves when the lock helper is created. Returns undefined if lock failed.
     */
    const createLock = async({ propertyId, buildingId, floorId, editType }) =>
    {
        let displayName = serverApi.getUserDisplayName();
        let initials = serverApi.getUserInitials();

        // update MONGO DB to include userUID , property, building, floor
        const lockData = { propertyId, buildingId, floorId, editType, displayName, initials };

        // create locks
        let createLockResult;

        if (editType === EDIT_TYPES.TRANSITIONS)
        {
            createLockResult = await mapEditiorApi.createEntirePropertyLock(lockData);
        }
        else
        {
            createLockResult = await mapEditiorApi.createLock(lockData);
        }

        // if lock was not successful display error
        if (!createLockResult.isSuccess)
        {
            console.log("failed to create lock");
            // delete local browser data on lock fail
            await mapEditorLocalDb.deleteAllAllEditTypes();
            return;
        }

        // handle locking update
        let data = createLockResult.data;
        if (!Array.isArray(data))
        {
            data = [data];
        }
        data.forEach((lock) => handleSocketLocksUpdate(lock, true, false));

        return createLockResult;
    };

    /**
     * As of time of writing, assume user can only lock one ref at a time and we only have nodes to save
     * this can only be called for the floor the user is currently on
     */
    const handleLock = async (propertyId, buildingId, floorId, alertCallback) =>
    {
        const { mapData, editType, transitionsData } = state;

        // check DB if user has lock
        const userLock = await mapEditiorApi.getUserLock();

        // if user has a lock already
        if (userLock.data)
        {
            if (alertCallback) 
            {
                alertCallback(EDITOR_ALERTS.USER_HAS_ANOTHER_LOCKED_LEVEL);
            } 
            return;
        }

        // save data to tempDB
        let localObjects = [];

        const entities = mapData.topology.objects.entities.geometries;
        const entityAccessArray = entities.map((entity) => new CMSTopologyEntityAccess(entity, mapData.topology));

        // fetch local objects depending on type 
        switch (editType)
        {
        case (EDIT_TYPES.NODES): 
        {

            let nodeData = [];

            if (mapData && mapData.topology)
            {
                let nodeIdToEntityIdMap = {};

                //create a mapping from nodeId to its connected entityId
                entityAccessArray.forEach((entityAccess) =>
                {
                    let entityNodeIds = entityAccess.getNodeIds();
                    let entityId = entityAccess.getId();

                    if (Array.isArray(entityNodeIds))
                    {
                        entityNodeIds.forEach((nodeId) =>
                        {
                            nodeIdToEntityIdMap[nodeId] = entityId;
                        });
                    }
                });

                nodeData = getNodeDataFromTopologyMapData(mapData, nodeIdToEntityIdMap);
            }

            localObjects = nodeData.nodes;
            break;
        }
        case (EDIT_TYPES.ENTITIES): {
            let localEntities = entityAccessArray.map((EA) => EA.getEntityInfo());
            localObjects = localEntities;
            break;
        }
        case (EDIT_TYPES.TRANSITIONS): {
            localObjects = transitionsData;
            break;
        }
        }

        try
        {
            // clear old copy if exists
            await mapEditorLocalDb.deleteAllAllEditTypes();

            // insert local items in browser local storage first. 
            // this prevents the user to have a lock with no browser data just in case something fails during the lock
            await mapEditorLocalDb.insertMany(editType, localObjects);

            // create lock after data has been saved to local state
            const createLockResult = await createLock({ propertyId, buildingId, floorId, editType });

            // if lock was successful continue locking the property adding the lock result into the local browser
            if (createLockResult)
            {
                mapEditorLocalDb.insertMany(CollectionNameMap.lock, createLockResult.data);

                // update state
                setState({ isLevelLockedByUser: true, userLock: createLockResult.data });

                // recall get map data to set local states properly
                let data = await handleGetMapDataAsync({ propertyId, buildingId, floorId });

                return (data); 
            }

           
        }
        catch (err)
        {
            console.log("error with local DB", err);
        }
    };

    /**
     *
     * @param {*} propertyId
     * @param {*} buildingId
     * @param {*} floorId
     * @param {*} isSavingData
     * @returns
     */
    const handleUnlock = async (propertyId, buildingId, floorId, isSavingData) =>
    {
        // check if user has a lock and is on the same level
        // check DB if user has lock
        const userLock = await mapEditiorApi.getUserLock();
        let unlockArray = [];

        // user has no locked floors
        if (!userLock.data)
        {
            return;
        }

        let { propertyId: lockPropertyId, buildingId: lockBuildingId, floorId: lockFloorId, _id, editType } = userLock.data;

        if (editType === EDIT_TYPES.TRANSITIONS)
        {
            // save local transitions data
            if (isSavingData)
            {
                // get all local transitions and send to server
                const localTransitions = await mapEditorLocalDb.find(CollectionNameMap.transitions);
                await mapEditiorApi.saveLocalTransitions(propertyId, localTransitions);
            }

            // remove property wide lock
            const deleteLockResponse = await mapEditiorApi.deletePropertyLock(propertyId);

            if (Array.isArray(deleteLockResponse.data))
            {
                unlockArray = deleteLockResponse.data;
            }

            // flush local data
            mapEditorLocalDb.deleteAll(CollectionNameMap.transitions);
        }
        else if (propertyId === lockPropertyId && buildingId === lockBuildingId && floorId === lockFloorId)
        {
            let isFloorPlanUpdated = false;
            // continue with unlock logic depending on tool type
            if (editType === EDIT_TYPES.NODES)
            {
                if (isSavingData)
                {
                    const localNodes = await mapEditorLocalDb.find(CollectionNameMap.nodes);
                    await mapEditiorApi.saveLocalNodes(propertyId, buildingId, floorId, localNodes);
                }

                // flush local data depending on edit type
                await mapEditorLocalDb.deleteAll(CollectionNameMap.nodes);
            }
            else if (editType === EDIT_TYPES.ENTITIES)
            {

                // save local entities
                if (isSavingData)
                {
                    const localEntities = await mapEditorLocalDb.find(CollectionNameMap.entities);
                    await mapEditiorApi.saveLocalEntities(propertyId, buildingId, floorId, localEntities);
                    const localFloorPlan = await mapEditorLocalDb.find(CollectionNameMap.floorPlan);
                    if (localFloorPlan[0])
                    {
                        isFloorPlanUpdated = true;
                        await mapEditiorApi.saveLocalFloorPlanGeoReferncing(propertyId, floorId, convertStepDataToBackendFormat(localFloorPlan[0]));
                    }
                }

                // flush local data depending on edit type
                await mapEditorLocalDb.deleteAll(CollectionNameMap.entities);
                await mapEditorLocalDb.deleteAll(CollectionNameMap.floorPlan);

            }

            // delete steps
            await mapEditorLocalDb.deleteAll(CollectionNameMap.steps);
            // unlock in DB
            const deleteLockResponse = await mapEditiorApi.deleteLock(_id);
            unlockArray.push(deleteLockResponse.data);
            mapEditorLocalDb.deleteAll(CollectionNameMap.lock);

            if (isFloorPlanUpdated)
            {
                await props.reFetchAllProperties();
            }
        }

        // remove locks
        unlockArray.forEach((lock) => handleSocketLocksUpdate(lock, false, false));
        mapEditorLocalDb.deleteAll(CollectionNameMap.lock);

        let mapLayersVisablityMap = getDefaultMapLayerVisability(editType);
        return { userLock: undefined, selectedTool: undefined, selectedFilter: undefined, mapLayersVisablityMap };
    };

    const callbackWithLoadingWrapper = (asyncCallback) =>
    {
        if (!asyncCallback)
        {
            console.error("Missing callback method");
            return;
        }

        return async (...props) =>
        {
            setState({ loading: true });

            try
            {
                let newState = await asyncCallback(...props);
                newState = Object.assign({ loading: false }, newState);
                setState(newState);
            }
            catch (error)
            {
                console.log("callback error", error);
                setState({ loading: false });
            }
        };
    };

    const handleSetUndoStep = (step) =>
    {
        setState({ undoStep: step });
    };

    const handleSetSelectedFilter = (selectedFilter) =>
    {
        if (selectedFilter === state.selectedFilter)
        {
            selectedFilter = undefined;
        }

        setState({ selectedFilter });
    };

    const handleSetUndoRedoButtonClick = (undoRedoButtonClick) =>
    {
        setState({ undoRedoButtonClick });
    };

    const handleSetCanRedo = (canRedo) =>
    {
        setState({ canRedo });
    };

    const handleSetCanUndo = (canUndo) =>
    {
        setState({ canUndo });
    };

    /**
     * used to break out of current node linkage
     * ref is needed here because escape is an event handler that will look at old state when called
     **/
    const handleEscapeClick = () =>
    {
        const selectedTool = selectedToolRef.current;

        if (selectedTool === MAP_EDITOR_TOOLS.AutoLink)
        {
            setState({ selectedTool: undefined });
            setState({ selectedTool: MAP_EDITOR_TOOLS.AutoLink });
        }
    };

    const setLoading = useCallback((loading) =>
    {
        setState({ loading });
    }, [setState]);

    /**
     *
     * @returns `{ isLevelLocked, isLevelLockedByUser, isLevelLockedByAnotherUser }`
     */
    const getLevelLockInfo = useCallback((propertyId = propertyIdRef.current, buildingId = buildingIdRef.current, floorId = floorIdRef.current, isGeoreference = false) =>
    {
        const companyLocks = companyLocksMemo;
        const userData = serverApi.userData.user;
        const userId = userData.userInfo.id;

        let levelLockInfo = {
            isLevelLocked: false,
            isLevelLockedByUser: false,
            isLevelLockedByAnotherUser: false,
        };

        if (!Array.isArray(companyLocks))
        {
            return levelLockInfo;
        }

        // check if there is a lock for the current floor
        for (let i = 0; i < companyLocks.length; i++)
        {
            let lockData = companyLocks[i];

            if (isGeoreference)
            {
                if (lockData.propertyId === propertyId)
                {
                    if (userId === lockData.userId && lockData.editType !== EDIT_TYPES.VALIDATION)
                    {
                        levelLockInfo.isLevelLockedByUser = true;
                    }
                    else
                    {
                        levelLockInfo.isLevelLockedByAnotherUser = true;
                    }

                    levelLockInfo.isLevelLocked = true;

                    Object.assign(levelLockInfo, lockData);
                }
            }
            else
            {
                if (lockData.propertyId === propertyId && lockData.buildingId === buildingId && lockData.floorId === floorId)
                {
                    if (userId === lockData.userId && lockData.editType !== EDIT_TYPES.VALIDATION)
                    {
                        levelLockInfo.isLevelLockedByUser = true;
                    }
                    else
                    {
                        levelLockInfo.isLevelLockedByAnotherUser = true;
                    }

                    levelLockInfo.isLevelLocked = true;

                    Object.assign(levelLockInfo, lockData);
                }
            }

        }

        return levelLockInfo;
    }, [companyLocksMemo, serverApi.userData.user, propertyIdRef.current, buildingIdRef.current, floorIdRef.current]);

    const handleAddStepToActionQueue = (step) =>
    {
        setStepActionQueue((prevActionQueue) =>
        {
            let newActionQueue = [...prevActionQueue, step];
            if (!state.stepAction)
            {
                setState({ stepAction: newActionQueue[0] });
            }

            return newActionQueue;
        });
    };

    const handleCompleteStepAction = () =>
    {
        setStepActionQueue((prevActionQueue) =>
        {
            prevActionQueue.splice(0, 1);
            setState({ stepAction: prevActionQueue[0] });

            return prevActionQueue;
        });
    };

    const setDefaultLayerVisability = (editType) =>
    {
        let layerVisabilityMap = getDefaultMapLayerVisability(editType);

        handleUpdateMapLayersVisablity(layerVisabilityMap);
    };

    const handleChangeEditType = (editType) =>
    {
        setState({ editType, selectedTool: undefined, selectedFilter: undefined });

        setDefaultLayerVisability(editType);

        // reset layer visibility
        serverApi.handleUpdateDashboardData("editType", editType);
    };

    /**
    * Changes the selected  georeference building, gets default floor and grabs map data.
    * @param {string} buildingId - The new selected buildingId. buildingId=undefined sets current property to be selected for viewing and updates.
    */
    const handleChangeSelectedBuilding = async (georeferenceBuildingId) =>
    {
        const { propertyId, properties } = state;

        let newState = {
            georeferenceBuildingId,
            selectedTool: undefined,
        };

        // get default floorId if new building is selected
        if (georeferenceBuildingId)
        {
            newState.georeferenceFloorId = getDefaultFloorIdFromCMSBuilding(properties[propertyId].buildings[georeferenceBuildingId], "floors");
        }

        // update dashboard data
        serverApi.handleUpdateDashboardData("georeferenceBuildingId", newState.georeferenceBuildingId);
        serverApi.handleUpdateDashboardData("georeferenceFloorId", newState.georeferenceFloorId);

        return newState;
    };

    const handleChangeSelectedFloor = async (georeferenceFloorId) =>
    {

        let newState = { georeferenceFloorId };

        serverApi.handleUpdateDashboardData("georeferenceFloorId", georeferenceFloorId);

        return newState;
    };

    /**
     *
     * @param {*} propertyId
     * @param {*} buildingId
     * @param {*} floorId
     * @param {*} localObjects
     * @returns
     */
    const handleGetMapDataAndLayers = async (propertyId, buildingId, floorId, localObjects) =>
    {
        const { style } = state;
        const fileUrl = fileUrlRef.current;
        let entryExitTopology;
        let mapData, layers, mapStyle;

        if (buildingId)
        {
            mapStyle = style || getMapStyle(EntityRefTypes.FLOOR, STYLE_TYPES.EDITOR);
            mapData = await getFloorLevelTopologyMapDataAsync(buildingId, floorId, localObjects);
        }
        else
        {
            mapStyle = style || getMapStyle(EntityRefTypes.PROPERTY, STYLE_TYPES.EDITOR);
            let res = await getPropertyLevelTopologyMapDataAsync(propertyId, localObjects);
            mapData = res.mapData;
            entryExitTopology = res.entryExitTopologyData;
        }

        let options = {
            minZoom: MapEditorConstants.MIN_ZOOM,
            maxZoom: MapEditorConstants.MAX_ZOOM,
        };

        mapStyle.layerStyles.forEach((layerStyle) =>
        {
            layerStyle.minZoomLevel = MapEditorConstants.MIN_ZOOM;
            layerStyle.maxZoomLevel = MapEditorConstants.MAX_ZOOM;
        });

        layers = createEntityVectorLayersTopology({ topology: mapData.topology, entryExitTopology, style: mapStyle, fileUrl: fileUrl, options });

        return { mapData, layers, mapStyle, entryExitTopology };
    };

    /**
     *
     * @param {*} param0
     * @returns
     */
    const createTransitionLayers = async ({ transitionsData, navIds, georeferenceNavIds, georeferenceMapData, mapData, nodeLayer, georeferenceNodeLayer }) =>
    {
        const { transitionsTextLayer, georeferenceTransitionsTextLayer } = createTransitionsTextLayer({
            transitionsData,
            navIds,
            georeferenceNavIds,
            georeferenceMapData,
            mapData,
            nodeLayer,
            georeferenceNodeLayer
        });

        return { transitionsTextLayer, georeferenceTransitionsTextLayer };
    };


    //#region OBJECT SEARCH

    /**
      * Fetch current entities for property or building.
      * Pull entities from local DB if available
      * Return list of entities
      */
    const entityIdsArrayMemo = useMemo(() =>
    {
        // pull entities from provider
        const { mapData, localObjects, editType, propertyId, buildingId, floorId, userLock } = state;

        if (!mapData)
        {
            return [];
        }

        let entities;

        // if on level/type that is locked, pull from local objects
        if (userLock && userLock?.floorId === floorId && userLock.propertyId === propertyId && userLock.buildingId === buildingId && userLock.editType === editType)
        {
            entities = localObjects;
        }
        {
            entities = mapData?.topology?.objects?.entities?.geometries || [];
        }

        let entityIdArray = entities.map((entity) =>
        {
            const { entityId, _id } = entity.properties;
            return { navId: entityId || -1, _id, type: FILTERED_ID_TYPES.ENTITY };
        });

        return entityIdArray;

    }, [state.mapData, state.editType, state.localObjects, state.userLock, state.propertyId, state.buildingId, state.floorId]);

    /**
     * Fetch current plotting nodes
     * Pull nodes from local DB if available
     * Return list of nodeIds
     */
    const nodeIdsArrayMemo = useMemo(() =>
    {
        // pull entities from provider
        const { mapData, editType, localObjects, propertyId, buildingId, floorId, userLock } = state;

        if (!mapData)
        {
            return [];
        }

        let nodes;

        // if on level/type that is locked, pull from local objects
        if (userLock && userLock?.floorId === floorId && userLock.propertyId === propertyId && userLock.buildingId === buildingId && userLock.editType === editType)
        {
            nodes = localObjects;
        }
        {
            nodes = mapData?.topology?.objects?.nodes?.geometries || [];
        }

        // filter out connection nodes and create a id array
        let nodeIdArray = nodes.filter(((node) => !node.properties.isNodeConnection)).map((node) =>
        {
            const { nodeId, _id } = node.properties;
            return { navId: nodeId, _id, type: FILTERED_ID_TYPES.NODE };
        });

        return nodeIdArray;

    }, [state.mapData, state.editType, state.localObjects]);

    /**
     * sets local objects array used to keep track of local changes for entities/nodes/transitions
     * only set state if level is locked with correct edit type
     * @param {Array} localObjects
     */
    const handleSetLocalObjects = useCallback((localObjects) =>
    {
        const { userLock, editType, propertyId, buildingId, floorId } = state;
        if (userLock && userLock?.floorId === floorId && userLock.propertyId === propertyId && userLock.buildingId === buildingId && userLock.editType === editType)
        {
            setState({ localObjects: localObjects });
        }
    }, [state.userLock, state.editType, state.propertyId, state.buildingId, state.floorId]);

    const handleSetFeatureSearchResults = useCallback((featureSearchResults) =>
    {
        setState({ featureSearchResults: featureSearchResults });
    }, []);

    const shouldUseLocalData = useMemo(() =>
    {
        const { userLock, editType, propertyId, buildingId, floorId } = state;

        return userLock && userLock?.floorId === floorId && userLock.propertyId === propertyId && userLock.buildingId === buildingId && userLock.editType === editType;
    }, [state.userLock, state.editType, state.propertyId, state.buildingId, state.floorId]);

    //#endregion





    return (
        <MapEditorContext.Provider value={{
            state,
            stepActionQueue,
            handleGetMapDataAsync: callbackWithLoadingWrapper(handleGetMapDataAsync),
            handleLock: callbackWithLoadingWrapper(handleLock),
            handleUnlock: callbackWithLoadingWrapper(handleUnlock),
            handleChangeSelectedTool: handleChangeSelectedTool,
            handleSetUndoStep: handleSetUndoStep,
            handleUpdateMapLayersVisablity: handleUpdateMapLayersVisablity,
            handleSetSelectedFilter: handleSetSelectedFilter,
            handleSetUndoRedoButtonClick: handleSetUndoRedoButtonClick,
            handleEscapeClick: handleEscapeClick,
            handleSetCanRedo: handleSetCanRedo,
            handleSetCanUndo: handleSetCanUndo,
            getCompanyLocks: companyLocksMemo,
            getLevelLockInfo: getLevelLockInfo,
            getLevelNotLockedAlertMessage: getLevelNotLockedAlertMessage,
            handleAddStepToActionQueue: handleAddStepToActionQueue,
            handleCompleteStepAction: handleCompleteStepAction,
            handleChangeEditType: handleChangeEditType,
            changeSelectedBuilding: callbackWithLoadingWrapper(handleChangeSelectedBuilding),
            changeSelectedFloor: callbackWithLoadingWrapper(handleChangeSelectedFloor),
            mainNavIdsMemo: mainNavIdsMemo,
            georeferenceNavIdsMemo: georeferenceNavIdsMemo,
            entityIdsArrayMemo: entityIdsArrayMemo,
            nodeIdsArrayMemo: nodeIdsArrayMemo,
            handleSetLocalObjects: handleSetLocalObjects,
            handleSetFeatureSearchResults: handleSetFeatureSearchResults,
            shouldUseLocalData,
            setLoading,
            isSideBarExpanded,
            setIsSideBarIsExpanded,
            validationBoxActiveLevelKeys,
            setValidationBoxActiveLevelKeys,
            isValidationBoxOpen,
            setIsValidationBoxOpen
        }}>
            <Loader active={state.loading} />
            {props.children}
        </MapEditorContext.Provider>
    );
};
