import React, { useContext } from "react";
import { StylesAndThemesProvider } from "../../../../store/StylesAndThemesContext";
import StylesAndThemesMap from "../../../branding/stylesAndThemes/StylesAndThemesMap";
import StylesAndThemesSidebar from "../../../branding/stylesAndThemes/StylesAndThemesSidebar";
import StylesEditorSidebar from "../../../branding/stylesAndThemes/StylesEditorSidebar";
import BrandingContext from "../../../../store/BrandingContext";

const StylesAndThemesPage = () => 
{
    const { state: brandingCtxState } = useContext(BrandingContext);
    const { propertyId } = brandingCtxState;

    return <StylesAndThemesProvider key={propertyId}>
        <StylesAndThemesMap />
        <StylesAndThemesSidebar />
        <StylesEditorSidebar />
    </StylesAndThemesProvider>;
};

export default StylesAndThemesPage;