import React, { useState } from "react";
import { Input } from "semantic-ui-react";
import "./ImagesManagementUploadFromURL.css";
import { useImagesUploadContext } from "../contexts/ImagesUploadContext";

function ImagesManagementUploadFromURL() 
{
    const { handleChangeURL, url, urlCheckStatus } = useImagesUploadContext();

    return (
        <div className="image-management-upload-from-url-bx">
            <Input
                placeholder={"Paste link here..."}
                fluid
                name="url"
                loading={urlCheckStatus === "loading"}
                value={url}
                focus
                onChange={(e) => handleChangeURL(e.target.value)}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="none"
                spellCheck={true}
            />
        </div>
    );
}

export default ImagesManagementUploadFromURL;