import "./PublishPopup.css";

import { useSetState } from "ahooks";
import classNames from "classnames";
import { addDays, format as dateFormat } from "date-fns";
import React, { useCallback, useContext, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import { Button, Header, Icon, Image, Input, Loader, Popup } from "semantic-ui-react";

import { PUBLISH_DATA_SCOPES } from "mapsted.utils/config";
import serverApi from "../../../_api/server.api";
import { ENVS, SINGLE_LANG_INPUT_CODE, VALIDATE_TIME_STAMP_FORMAT } from "../../../_constants/constants";
import { PUBLIC_QUERIES, UTIL_QUERIES } from "../../../_utils/queries";
import { formatDate, getMobileAppDetails } from "../../../_utils/utils";
import BrandingContext, { useCurrentProperty, useSelectedBuilding } from "../../../store/BrandingContext";
import { ButtonBasic } from "../../common/ButtonIcon";
import { TextGroup } from "../../common/TextGroup";
import { KioskPreviewModal } from "./KioskPreviewModal";
import { ValidationErrorBoxLauncher } from "../ValidationInfo/ValidationInfoSection";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
const POPUP_WAIT_TIME = 30000;

const STAGES = {
    CLOSED: "closed",
    INITIAL: "initial",
    VALIDATE: "validate",
    VALIDATION_COMPLETE: "validation_complete",
    PROD: "prod",
    STAGING: "staging",
    SCHED: "schedule",
    LOADING: "loading",
    VALIDATION_LOADING: "validation_loading",
    SCHED_COMPLETE: "scheduled",
    ERROR: "error",
    VALIDATION_ERROR: "validation_error",
    PROD_COMPLETE: "prodComplete",
    STAGING_COMPLETE: "stagingComplete"
};

const CLOSABLE_STAGES = [
    STAGES.INITIAL,
    STAGES.PROD,
    STAGES.STAGING,
    STAGES.SCHED,
    STAGES.SCHED_COMPLETE,
    STAGES.ERROR,
    STAGES.VALIDATION_ERROR,
    STAGES.VALIDATE,
    STAGES.VALIDATION_COMPLETE,
    STAGES.PROD_COMPLETE,
    STAGES.STAGING_COMPLETE
];
export const PublishPopup = ({ onPublish, disabled = false, beforePublish, onPublishError, loadingPool }) =>
{
    const brandingContext = useContext(BrandingContext);
    const [stage, setStage] = useState(STAGES.CLOSED);
    const [errorMessage, setErrorMessage] = useState("");
    const trans = useTranslation().t;
    const contextRef = useRef();

    const location = useLocation();

    const popupCloseTimeOutId = useRef();

    const propertyValidationData = useMemo(() => brandingContext?.state?.propertyValidation, [brandingContext?.state?.propertyValidation]);

    const buildingId = useMemo(() => brandingContext?.state?.buildingId, [brandingContext?.state?.buildingId]);

    const propertyId = useMemo(() => brandingContext?.state?.propertyId, [brandingContext?.state?.propertyId]);

    //MW-2188
    const isEntirePropertyValidated = useMemo(() =>
    {
        if (!propertyValidationData)
        {
            return false;
        }

        let isValid = true;

        let validationArray = [propertyValidationData?.propertyValidation].concat(Object.values(propertyValidationData?.buildingValidationMap || {}));

        for (let i = 0; i < validationArray.length; i++)
        {
            if (!validationArray[i]?.valid)
            {
                isValid = false;
                break;
            }
        }

        return isValid;

    }, [propertyValidationData]);

    const isBuildingValidated = useMemo(() =>
    {
        if (!buildingId || !propertyValidationData)
        {
            return false;
        }

        return propertyValidationData?.buildingValidationMap?.[buildingId]?.valid;
    }, [propertyValidationData, buildingId]);

    const handleOpenButton = () =>
    {
        // clear time out if its timer was started
        if (popupCloseTimeOutId.current)
        {
            clearTimeout(popupCloseTimeOutId.current);
            popupCloseTimeOutId.current = undefined;
        }

        if (stage === STAGES.CLOSED)
        {
            setStage(STAGES.INITIAL);
        }
        else if (CLOSABLE_STAGES.includes(stage))
        {
            setErrorMessage(undefined);
            setStage(STAGES.CLOSED);
        }
    };

    const handleValidateProperty = useCallback(async () =>
    {
        setStage(STAGES.VALIDATION_LOADING);

        const propertyValidationResult = await serverApi.validateEnitreProperty(propertyId);

        if (propertyValidationResult?.isSuccess)
        {
            // go through validations and check if they all succeeded
            setStage(STAGES.VALIDATION_COMPLETE);
        }
        else
        {
            setErrorMessage(propertyValidationResult?.errorMessage);
            setStage(STAGES.VALIDATION_ERROR);
        }
        brandingContext.syncValidationInfoPopup();
    }, [propertyId, brandingContext.syncValidationInfoPopup]);

    const handleValidateBuilding = useCallback(async () =>
    {
        setStage(STAGES.VALIDATION_LOADING);

        const buildingValidationResult = await serverApi.validateBuilding(propertyId, buildingId);

        if (buildingValidationResult?.isSuccess)
        {
            // go through validations and check if they all succeeded
            setStage(STAGES.VALIDATION_COMPLETE);
        }
        else
        {
            setErrorMessage(buildingValidationResult?.errorMessage);
            setStage(STAGES.VALIDATION_ERROR);
        }
        brandingContext.syncValidationInfoPopup();
    }, [propertyId, buildingId, brandingContext.syncValidationInfoPopup]);

    const handlePublish = (db) =>
    {
        setStage(STAGES.LOADING);

        let loadingId;
        if (loadingPool)
        {
            loadingId = loadingPool.add();
        }

        if (beforePublish)
        {
            beforePublish();
        }

        brandingContext.publishProperty(db)
            .then((result) =>
            {
                console.log("publish result", result);

                if (!result?.isSuccess)
                {
                    if (result.errorMessage)
                    {
                        setErrorMessage(result?.errorMessage);
                    }
                    return Promise.reject("Error publishing");
                }

                const newStage = db === PUBLISH_DATA_SCOPES.PROD ? STAGES.PROD_COMPLETE : STAGES.STAGING_COMPLETE;

                setStage(newStage);

                if (loadingPool && loadingId)
                {
                    loadingPool.remove(loadingId);
                }

                if (onPublish)
                {
                    onPublish();
                }

                popupCloseTimeOutId.current = setTimeout(() =>
                {
                    setStage(STAGES.CLOSED);
                    popupCloseTimeOutId.current = undefined;
                }, POPUP_WAIT_TIME);
            })
            .catch((err) =>
            {
                setStage(STAGES.ERROR);

                if (loadingPool && loadingId)
                {
                    loadingPool.remove(loadingId);
                }

                if (onPublishError)
                {
                    onPublishError();
                }
            });
    };

    const handleSchedule = (dateStr, time) =>
    {
        setStage(STAGES.LOADING);
        const date = new Date(`${dateStr} ${time}`);
        brandingContext.schedulePublish(date)
            .then(() => setStage(STAGES.SCHED_COMPLETE));
    };

    const renderValidationErrorBoxLauncher = useCallback(() =>
    {
        if (location.pathname.toLowerCase().includes("mapeditor"))
        {
            return <ValidationErrorBoxLauncher />;
        }
        return null;
    }, [location.pathname]);

    return (
        <>
            <div className="publishContext" ref={contextRef}>
                <Button primary className="publishTrigger" onClick={handleOpenButton} disabled={disabled}>
                    {trans("branding.Publish")} <img src="/img/icon-chevron-down-white.svg" alt="expand" />
                </Button>
                <PublishPopup1
                    contextRef={contextRef}
                    open={stage === STAGES.INITIAL}
                    validateClick={() => setStage(STAGES.VALIDATE)}
                    prodClick={() => setStage(STAGES.PROD)}
                    stagingClick={() => setStage(STAGES.STAGING)}
                    isEntirePropertyValidated={isEntirePropertyValidated}
                    isValidationInProgress={brandingContext.isValidationInProgress()}
                    trans={trans}
                />
                <ValidationMode
                    open={stage === STAGES.VALIDATE}
                    contextRef={contextRef}
                    onBack={() => setStage(STAGES.INITIAL)}
                    onValidateProperty={handleValidateProperty}
                    onValidateBuilding={handleValidateBuilding}
                    isBuilding={!!buildingId}
                    isEntirePropertyValidated={isEntirePropertyValidated}
                    isBuildingValidated={isBuildingValidated}
                    propertyValidationData={propertyValidationData}
                    buildingId={buildingId}
                    propertyId={propertyId}

                />
                <ProductionMode
                    open={stage === STAGES.PROD}
                    contextRef={contextRef}
                    onBack={() => setStage(STAGES.INITIAL)}
                    onPublish={() => handlePublish(PUBLISH_DATA_SCOPES.PROD)}
                    scheduleClick={() => setStage(STAGES.SCHED)}
                />
                <StagingMode
                    open={stage === STAGES.STAGING}
                    contextRef={contextRef}
                    onBack={() => setStage(STAGES.INITIAL)}
                    onPublish={() => handlePublish(PUBLISH_DATA_SCOPES.UAT)}

                />
                <Schedule
                    open={stage === STAGES.SCHED}
                    contextRef={contextRef}
                    onBack={() => setStage(STAGES.PROD)}
                    onSchedule={handleSchedule}
                />
                <Loading open={stage === STAGES.LOADING} contextRef={contextRef} />
                <ValidationLoading open={stage === STAGES.VALIDATION_LOADING} contextRef={contextRef} />
                <ValidationComplete open={stage === STAGES.VALIDATION_COMPLETE} contextRef={contextRef} />
                <Complete open={stage === STAGES.PROD_COMPLETE} contextRef={contextRef} onClick={handleOpenButton} env={ENVS.PROD} />
                <Complete open={stage === STAGES.STAGING_COMPLETE} contextRef={contextRef} onClick={handleOpenButton} env={ENVS.STAGING} />
                <Scheduled
                    open={stage === STAGES.SCHED_COMPLETE}
                    contextRef={contextRef}
                />
                <ValidationError
                    open={stage === STAGES.VALIDATION_ERROR}
                    contextRef={contextRef}
                    errorMessage={errorMessage}
                />
                <Error
                    open={stage === STAGES.ERROR}
                    contextRef={contextRef}
                    errorMessage={errorMessage}
                />
            </div>
            {
                renderValidationErrorBoxLauncher()
            }
        </>
    );
};

const PublishPopup1 = ({ contextRef, open, validateClick, prodClick, stagingClick, isEntirePropertyValidated, isValidationInProgress, trans }) => (
    <Popup
        className="publishPopup"
        open={open}
        context={contextRef}
        position="bottom right"
        on="click"
        hideOnScroll={false}
    >
        <PublishButton content={isValidationInProgress ? "Validating..." : trans("branding.Validate")} onClick={validateClick} disabled={isValidationInProgress} />
        {
            (!isEntirePropertyValidated) ? <Popup className="publishTooltip"
                trigger={
                    <div className="disabledGroup">
                        <PublishButton content={trans("branding.Production_mode")} />
                        <PublishButton content={trans("branding.Staging_Mode")} />
                    </div>
                }
                position="bottom right"
                on="click"
                basic
                content={trans("branding.successfully_validated")} /> : <>
                <PublishButton content={trans("branding.Production_mode")} onClick={prodClick} />
                <PublishButton content={trans("branding.Staging_Mode")} onClick={stagingClick} />
            </>
        }
    </Popup>
);

const PublishButton = ({
    className = "",
    content = undefined,
    children = undefined,
    ...rest
}) => (
    <Button className={classNames("publishButton", className)} {...rest}>
        {content}
        {children}
        <img src="/img/icon-chevron-down.svg" alt="select" />
    </Button>
);

const ApplicationItem = ({ icon, content, href, noLink }) => (
    <div className="applicationItem">
        <Image as="span" src={`/img/icon-${icon}.svg`} />
        {content}
        {
            !noLink && <ButtonBasic icon="open" as="a" href={href} target="_blank" />
        }
    </div>
);

const NonRedirectApplicationItem = ({ icon, content, onClick }) => (
    <div className="applicationItem">
        <Image as="span" src={`/img/icon-${icon}.svg`} />
        {content}
        {
            <ButtonBasic icon="open" onClick={onClick} />
        }
    </div>
);

const ApplicationGroup = ({ env }) =>
{
    const [kioskPopupOpen, setKioskPopupOpen] = useState(false);
    const trans = useTranslation().t;

    const property = useCurrentProperty();
    const { data: config } = useQuery(UTIL_QUERIES.CONFIG());
    const { isLoading, data: mapstedApps } = useQuery(PUBLIC_QUERIES.MAPSTED_APPS(true));

    const { data: publicSettings } = useQuery(PUBLIC_QUERIES.PUBLISHED_PROPERTY_SETTINGS(property?.propertyId, env === ENVS.STAGING ? PUBLISH_DATA_SCOPES.UAT : ""));

    const building = useSelectedBuilding();
    const { isLoading: kioskLoading, data: kiosks } = useQuery(PUBLIC_QUERIES.PUBLISHED_KIOSKS(property?.propertyId, building?.buildingId, env === ENVS.STAGING ? PUBLISH_DATA_SCOPES.UAT : ""));

    // if staging mode use uat datascope while generating maps web link
    const dataScope = env === ENVS.STAGING ? `?data=${PUBLISH_DATA_SCOPES.UAT}` : "";

    const mapBase = config?.MAPSTED_MAPS_URL_MAIN;
    const mapUrl = mapBase && publicSettings?.publicSubdomain && `${mapBase}/${publicSettings?.publicSubdomain}${dataScope}`;

    const kioskUrl = config?.KIOSK_URL;

    const renderMobileAppList = () =>
    {
        if (isLoading) return null;

        const { androidAppName, iosAppName, androidLink, iosLink } = getMobileAppDetails(mapstedApps, publicSettings);

        return (
            <>
                {(androidLink) && <ApplicationItem icon="device-android" content={androidAppName} href={androidLink} />}
                {(iosLink) && <ApplicationItem icon="device-ios" content={iosAppName} href={iosLink} />}
            </>
        );

    };

    const renderKioskList = () =>
    {
        if (kioskLoading)
        {
            return null;
        }

        if (!(kiosks && kiosks.length))
        {
            return null;
        }

        return (
            <>
                <NonRedirectApplicationItem icon="device-kiosk" content="Mapsted Kiosks" onClick={() => setKioskPopupOpen(true)} />
                <KioskPreviewModal
                    isOpen={kioskPopupOpen}
                    kiosks={kiosks}
                    onClose={() => setKioskPopupOpen(false)}
                    kioskUrl={kioskUrl}
                    env={env}
                />
            </>
        );
    };

    if (publicSettings)
    {
        return (
            <>
                <b className="headingPopup">{trans("branding.Visibility")}</b>
                {(mapUrl) && <ApplicationItem icon="device-web" content="Mapsted Maps - Web" href={mapUrl} />}
                {/* {(android) && <ApplicationItem icon="device-android" content="Mall mApp - Android" href={android} />}
                {(ios) && <ApplicationItem icon="device-ios" content="Mall mApp - iOS" href={ios} />} */}
                {/* <ApplicationItem icon="device-kiosk" content={<>Mapsted Kiosks <small>Coming soon</small></>} noLink /> */}
                {renderKioskList()}
                {renderMobileAppList()}
            </>
        );
    }
    return null;
};


const PublishPopupMode = ({
    open,
    contextRef,
    env,
    allowScheduled = false,
    onBack,
    onPublish,
    scheduleClick,
}) => 
{
    const trans = useTranslation().t;
    return  <Popup
        className="publishPopup"
        open={open}
        context={contextRef}
        position="bottom right"
        hideOnScroll={false}
    >
        <Header className="publishHead">
            <ButtonBasic icon="arrow-back" onClick={onBack} />
            {env}
        </Header>
        <ApplicationGroup env={env} />
        <div className="publishActionGroup">
            <Button fluid basic primary content={trans("branding.Publish Changes")} onClick={onPublish} />
            {(allowScheduled) && <Button fluid basic color="yellow" content={trans("branding.Schedule Changes")} onClick={scheduleClick} />}
        </div>
    </Popup>;
};

const ProductionMode = (props) => (
    <PublishPopupMode
        env={ENVS.PROD}
        allowScheduled
        {...props}
    />
);

const StagingMode = (props) => (
    <PublishPopupMode
        env={ENVS.STAGING}
        {...props}
    />
);

const ValidationMode = ({
    open,
    contextRef,
    onBack,
    onValidateProperty,
    onValidateBuilding,
    isBuilding,
    isEntirePropertyValidated,
    isBuildingValidated,
    propertyValidationData,
    buildingId,
    propertyId,
}) =>
{
    const brandingContext = useContext(BrandingContext);
    const trans = useTranslation().t;

    const getPropertyName = useCallback((propertyId) => brandingContext?.state?.properties?.[propertyId]?.name[SINGLE_LANG_INPUT_CODE], [brandingContext.state.properties]);

    const getBuildingName = useCallback((propertyId, buildingId) => brandingContext?.state?.properties?.[propertyId]?.buildings?.[buildingId].longName[SINGLE_LANG_INPUT_CODE], [brandingContext.state.properties]);

    const renderPropertyValidationButton = useCallback((propertyId) =>
    {
        let validatedDate = "";

        let propertyName = getPropertyName(propertyId);

        if (isEntirePropertyValidated && propertyValidationData?.propertyValidation)
        {
            validatedDate = formatDate(propertyValidationData?.propertyValidation.updatedAt, VALIDATE_TIME_STAMP_FORMAT);
        }

        return (
            <Button className={isEntirePropertyValidated ? "buttonSuccess" : ""} onClick={onValidateProperty}>
                <Icon name={(isEntirePropertyValidated) ? "check" : "close"} color={isEntirePropertyValidated ? "green" : "red"} />
                {propertyName}
                <small>{validatedDate}</small>
            </Button>
        );
    }, [propertyValidationData, isEntirePropertyValidated, onValidateProperty, getPropertyName]);

    const renderBuildingValidationButton = useCallback((propertyId, buildingId) =>
    {
        let validatedDate = "";
        let buildingName = getBuildingName(propertyId, buildingId);
        let buildingValidationData = propertyValidationData?.buildingValidationMap?.[buildingId];

        if (isBuildingValidated && buildingValidationData)
        {
            validatedDate = formatDate(buildingValidationData.updatedAt, VALIDATE_TIME_STAMP_FORMAT);
        }

        return (
            <Button className={isBuildingValidated ? "buttonSuccess" : ""} onClick={onValidateBuilding}>
                <Icon name={(isBuildingValidated) ? "check" : "close"} color={(isBuildingValidated) ? "green" : "red"} />
                {buildingName}
                <small>{validatedDate}</small>
            </Button>
        );
    }, [isBuildingValidated, onValidateBuilding, getBuildingName]);

    return (
        <Popup
            className="publishPopup"
            open={open}
            context={contextRef}
            position="bottom right"
            hideOnScroll={false}
        >
            <Header className="publishHead">
                <ButtonBasic icon="arrow-back" onClick={onBack} />
                {trans("branding.Validation_status")}
            </Header>
            {/* <p>VALIDATION STATUS</p> */}
            <div className="publishValidationActions">
                <b>{trans("branding.Entire_Property")}</b>
                {renderPropertyValidationButton(propertyId)}

                {(isBuilding) && (
                    <>
                        <b>{trans("branding.Building")}</b>
                        {renderBuildingValidationButton(propertyId, buildingId)}
                    </>
                )}

            </div>
        </Popup>
    );
};

const Loading = ({ open, contextRef }) => 
{
    const trans = useTranslation().t;
   
    return <Popup className="publishPopup"
        open={open}
        context={contextRef}
        position="bottom right"
        hideOnScroll={false}
    >
        <div className="publishProgress">
            <Loader active content={`${trans("branding.Publishing")}...`} />
        </div>
    </Popup>;
};

const ValidationLoading = ({ open, contextRef }) => 
{
    const trans = useTranslation().t;
    return <Popup className="publishPopup"
        open={open}
        context={contextRef}
        position="bottom right"
        hideOnScroll={false}
    >
        <div className="publishProgress">
            <Loader active content={`${trans("branding.Validating")}...`}/>
        </div>
    </Popup>;
};

const ValidationComplete = ({ open, contextRef }) => 
{
    const trans = useTranslation().t;
    return <Popup className="publishPopup"
        open={open}
        context={contextRef}
        position="bottom right"
        hideOnScroll={false}
    >
        <Header className="publishHead">
            <img src="/img/icon-check-circle.svg" alt="" />
            {trans("branding.Validation_Completed")}
        </Header>

    </Popup>;
};

const Complete = ({ open, contextRef, onClick, env }) => 
{
    const trans = useTranslation().t;
    return <Popup className="publishPopup"
        open={open}
        context={contextRef}
        position="bottom right"
        hideOnScroll={false}
    >
        <Header className="publishHead" onClick={onClick}>
            <img src="/img/icon-check-circle.svg" alt="" />
            {trans("branding.Publish_Completed")}
        </Header>
        <ApplicationGroup env={env} />
    </Popup>;
};

const Scheduled = ({ open, contextRef }) => 
{
    const trans = useTranslation().t;

    return <Popup
        className="publishPopup"
        open={open}
        context={contextRef}
        position="bottom right"
        hideOnScroll={false}
    >
        <Header className="publishHead">
            <img src="/img/icon-check-circle.svg" alt="" />
            {trans("branding.Publish_Scheduled")}
        </Header>
    </Popup>;
};

const Schedule = ({ open, contextRef, onBack, onSchedule }) =>
{
    const today = dateFormat(new Date(), "yyyy-MM-dd");
    const minTime = dateFormat(new Date(), "HH:mm");     // min time if date is today
    const [{ date, time }, setState] = useSetState({ date: today, time: "" });

    const isTimeTooEarly = date === today && time <= minTime;
    const trans = useTranslation().t;

    return (
        <Popup
            className="publishPopup"
            open={open}
            context={contextRef}
            position="bottom right"
            hideOnScroll={false}
        >
            <Header className="publishHead">
                <ButtonBasic icon="arrow-back" onClick={onBack} />
                {trans("branding.Schedule_Changes")}
            </Header>

            <TextGroup title={trans("branding.Select_date")}>
                <Input
                    className="formButton"
                    type="date"
                    min={today}
                    max={dateFormat(addDays(new Date(), 14), "yyyy-MM-dd")}
                    value={date}
                    onChange={(e, { value }) => setState({ date: value })}
                />
            </TextGroup>

            <TextGroup title={trans("branding.Select_time")}>
                <Input
                    className="formButton"
                    type="time"
                    value={time}
                    error={isTimeTooEarly}
                    onChange={(e, { value }) => setState({ time: value })}
                />
            </TextGroup>

            <div className="publishActionGroup">
                <Button fluid primary basic content={trans("branding.Schedule_Changes")} onClick={() => onSchedule(date, time)} disabled={isTimeTooEarly} />
            </div>
        </Popup>
    );
};

const ValidationError = ({ open, errorMessage, contextRef, onClose }) => 
{
    const trans = useTranslation().t;
    return  <Popup
        className="publishPopup"
        open={open}
        context={contextRef}
        position="bottom right"
        hideOnScroll={false}
        onClose={onClose}
    >
        <Header className="publishHead">
            <Icon name="exclamation circle" color="red" />
            {trans("branding.Err_validating_property")}
        </Header>
        {
            (errorMessage) && (
                <TextGroup>
                    <p>{errorMessage}</p>
                </TextGroup>
            )
        }

    </Popup>;
};

const Error = ({ open, contextRef, onClose, errorMessage }) => 
{
    const trans = useTranslation().t;

    return   <Popup
        className="publishPopup"
        open={open}
        context={contextRef}
        position="bottom right"
        hideOnScroll={false}
        onClose={onClose}
    >
        <Header className="publishHead">
            <Icon name="exclamation circle" color="red" />
            {trans("branding.Err_publishing_property")}
        </Header>

        {
            (errorMessage) && (
                <TextGroup>
                    <p>{errorMessage}</p>
                </TextGroup>
            )
        }
    </Popup>;
};
