import React from "react";
import { STROKE_WIDTH_OPTIONS } from "../../../_constants/stylesAndThemesConstants";
import { CheckboxGroup } from "../../common/CheckboxGroup";
import { DivideBorderLine } from "../../common/DividerLine";
import ColorAndOpacitySetter from "./colorAndOpacitySetter/ColorAndOpacitySetter";
import StyleEditorDropdown from "./styleEditorDropdown/StyleEditorDropdown";
import { 
    getFillFields, 
    getStrokeFields, 
    getFillObjFromFields, 
    getStrokeObjFromFields 
} from "mapsted.maps/mapFunctions/stylesAndThemes";
import { useTranslation } from "react-i18next";

const PolygonStyleSettings = ({
    handlePolygonStyleChange,
    heading,
    entityStateKey,
    style,
    disabled,
    onCheckboxClick,
    checked,
}) => 
{

    const { fill, stroke } = style;
    const trans = useTranslation().t;
    const { fillColor, fillOpacity } = getFillFields(fill);
    const { strokeColor, strokeOpacity, strokeWidth } = getStrokeFields(stroke);

    const onFillChange = (field, value) =>
    {
        const fillFields = {
            fillColor,
            fillOpacity,
        };

        fillFields[field] = value;

        const fill = getFillObjFromFields(fillFields);

        handlePolygonStyleChange(entityStateKey, "fill", fill);
    };

    const onStrokeChange = (field, value) =>
    {
        const strokeFields = {
            strokeColor,
            strokeOpacity,
            strokeWidth,
        };

        strokeFields[field] = value;

        const stroke = getStrokeObjFromFields(strokeFields);

        handlePolygonStyleChange(entityStateKey, "stroke", stroke);
    };
 
    return (
        <>
            <div>
                <CheckboxGroup
                    radio
                    checked={checked}
                    onChange={onCheckboxClick}
                />
                <h6 className="h6 styleEditorHeading">{heading}</h6>
            </div>
            <ColorAndOpacitySetter
                disabled={disabled}
                className="colorAndOpacitySetter"
                title={trans("StylesAndThemesSidebar.Fill_color")}
                color={fillColor}
                opacity={fillOpacity}
                onChange={(field, value) => onFillChange(field === "color" ? "fillColor" : "fillOpacity", value)}
            />
            <ColorAndOpacitySetter
                disabled={disabled}
                className="colorAndOpacitySetter"
                title={trans("StylesAndThemesSidebar.Line_color")}
                color={strokeColor}
                opacity={strokeOpacity}
                onChange={(field, value) => onStrokeChange(field === "color" ? "strokeColor": "strokeOpacity", value)}
            />

            <StyleEditorDropdown 
                heading={trans("StylesAndThemesSidebar.Width")}
                className="editorDropdown"
                options={STROKE_WIDTH_OPTIONS}
                value={strokeWidth}
                onChange={(value) => onStrokeChange("strokeWidth", value)}
                disabled={disabled}
            />
            
            <DivideBorderLine />
        </>);
};

export default PolygonStyleSettings;