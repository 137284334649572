const GeoJsonAccess = class
{

    constructor(geometry, isMercator = true)
    {
        this.geometry = geometry;
        this.isMercator = isMercator;
    }

    getShape()
    {
        return this.geometry;
    }

    getCoordinates()
    {
        return this.geometry.coordinates;
    }

}

exports.GeoJsonAccess = GeoJsonAccess;