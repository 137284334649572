import React from "react";
import { FONT_SIZE_OPTIONS, TEXT_FONTS_OPTIONS, TEXT_STROKE_WIDTH_OPTIONS } from "../../../_constants/stylesAndThemesConstants";
import { CheckboxGroup } from "../../common/CheckboxGroup";
import { DivideBorderLine } from "../../common/DividerLine";
import ColorAndOpacitySetter from "./colorAndOpacitySetter/ColorAndOpacitySetter";
import StyleEditorDropdown from "./styleEditorDropdown/StyleEditorDropdown";
import { 
    getFillFields, 
    getStrokeFields, 
    getFillObjFromFields, 
    getStrokeObjFromFields 
} from "mapsted.maps/mapFunctions/stylesAndThemes";
import { useTranslation } from "react-i18next";

const TextStyleSettings = ({
    textStyle,
    onTextStyleChange,
    heading,
    entityStateKey,
    disabled,
    onCheckboxClick,
    checked,
}) => 
{
    const { fill, stroke, fontName, size } = textStyle;

    const sizeInt = parseInt(size.replace("px", ""));
    const trans = useTranslation().t;

    const { fillColor, fillOpacity } = getFillFields(fill);
    const { strokeColor, strokeOpacity, strokeWidth } = getStrokeFields(stroke);

    const onFillChange = (field, value) =>
    {
        const fillFields = {
            fillColor,
            fillOpacity,
        };

        fillFields[field] = value;

        const fill = getFillObjFromFields(fillFields);

        onTextStyleChange(entityStateKey, "fill", fill);
    };

    const onStrokeChange = (field, value) =>
    {
        const strokeFields = {
            strokeColor,
            strokeOpacity,
            strokeWidth,
        };

        strokeFields[field] = value;

        const stroke = getStrokeObjFromFields(strokeFields);

        onTextStyleChange(entityStateKey, "stroke", stroke);
    };

    return (
        <>
            <div>
                <CheckboxGroup
                    radio
                    checked={checked}
                    onChange={onCheckboxClick}
                />
                <h6 className="h6 styleEditorHeading">{heading}</h6>
            </div>

            <StyleEditorDropdown
                disabled={disabled}
                heading={trans("StylesAndThemesSidebar.Size")}
                className="textSizeDropdown editorDropdown"
                options={FONT_SIZE_OPTIONS}
                value={sizeInt}
                onChange={(value) => onTextStyleChange(entityStateKey, "size", `${value}px`)}
            />

            <StyleEditorDropdown
                disabled={disabled}
                heading={trans("StylesAndThemesSidebar.Font")}
                className="textFontDropdown editorDropdown"
                options={TEXT_FONTS_OPTIONS}
                value={fontName}
                onChange={(value) => onTextStyleChange(entityStateKey, "fontName", value)}
            />

            <ColorAndOpacitySetter
                disabled={disabled}
                className="colorAndOpacitySetter"
                title={trans("StylesAndThemesSidebar.Fill_color")}
                color={fillColor}
                opacity={fillOpacity}
                onChange={(field, value) => onFillChange(field === "color" ? "fillColor": "fillOpacity", value)}
            />

            <ColorAndOpacitySetter
                disabled={disabled}
                className="colorAndOpacitySetter"
                title={trans("StylesAndThemesSidebar.Stroke_color")}
                color={strokeColor}
                opacity={strokeOpacity}
                onChange={(field, value) => onStrokeChange(field === "color" ? "strokeColor": "strokeOpacity", value)}
            />

            <StyleEditorDropdown
                disabled={disabled}
                className="editorDropdown"
                heading={trans("StylesAndThemesSidebar.Stroke_width")}
                options={TEXT_STROKE_WIDTH_OPTIONS}
                value={strokeWidth}
                onChange={(value) => onStrokeChange("strokeWidth", value)}
            />
            <DivideBorderLine />
        </>);
};

export default TextStyleSettings;