import React from "react";
import "./ImageDeleteModalWithLinkedEntities.css";
import { Button, Modal } from "semantic-ui-react";
import ImagePreview from "./ImagePreview/ImagePreview";
import ImageDeleteStrategySelector from "./ImageDeleteStrategySelector/ImageDeleteStrategySelector";
import {
    DELETE_IMAGE_STRATEGY_ACTIONS,
    IMAGE_ACTION_MODES,
} from "../../constant";
import { useGmsContext } from "../../GmsContext";
import {  useImageDeleteContext } from "../contexts/ImageDeleteContext";
import { useImagesBaseContext } from "../contexts/ImageBaseContext";

function ImageDeleteModalWithLinkedEntities() 
{
    const gmsContext = useGmsContext();
    const {
        setImageActionMode,
        imageActionMode,
        hiddenModals,
        imagesSelectedForLinking
    } = useImagesBaseContext();

    const imageDeleteContext = useImageDeleteContext();
    const {
        handleSoftDeleteImage,
        handleCloseImageDeleteModalWithLinkedEntities,
    } = imageDeleteContext;

    const canProceed = (imageDeleteContext.selectedDeleteImageStrategy === DELETE_IMAGE_STRATEGY_ACTIONS.DELETE_IMAGE_AND_UNLINK_ENTITIES 
                     || (imageDeleteContext.selectedDeleteImageStrategy === DELETE_IMAGE_STRATEGY_ACTIONS.CHOOSE_NEW_IMAGE_AND_DELETE_OLD_ONE
                         && imagesSelectedForLinking.length));


    return (
        <Modal
            closeIcon={
                <Button
                    onClick={gmsContext.handleCloseGms}
                    className="close"
                />
            }
            dimmer={{
                className: hiddenModals.imageDeleteModalWithLinkedEntities ? "dimmer-hidden": ""
            }}
            
            onClose={() => setImageActionMode(null)}
            className={"image-delete-modal"}
            open={imageActionMode === IMAGE_ACTION_MODES.DELETE_WITH_LINKED_ENTITIES}
        >
            <Modal.Header className="image-delete-modal-header">
                Delete Image
            </Modal.Header>
            <Modal.Content className="image-delete-modal-content">
                <ImagePreview />
                <ImageDeleteStrategySelector />
            </Modal.Content>
            <Modal.Actions className="image-delete-modal-actions">
                <Button disabled={!canProceed} onClick={handleSoftDeleteImage} color="orange">
                    Confirm
                </Button>
                <Button onClick={handleCloseImageDeleteModalWithLinkedEntities}>
                    Cancel
                </Button>
            </Modal.Actions>
        </Modal>
    );
}


export default ImageDeleteModalWithLinkedEntities;
