import "./MapEditor.css";

import ObjectId from "bson-objectid";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { EDIT_TYPES, MAP_EDITOR_TOOLS } from "../../_constants/mapEditor";
import { CollectionNameMap } from "../../_indexedDB/collections/v1/collection";
import mapEditorLocalDb from "../../_indexedDB/mapEditorLocal.db";
import MapEditorContext from "../../store/MapEditiorContext";
import { EntityEditor } from "./editorComponents/EntityEditor";
import { NodeEditor } from "./editorComponents/NodeEditor";
import { TransitionsEditor } from "./editorComponents/TransitionEditor";
import { FloorPlanProvider } from "./FloorPlanGeoReferencing/FloorPlanProvider";
import { MapEditorLeftbar } from "./mapEditorSideBars/MapEditorLeftbar";
import ValidationInfoSection from "../branding/ValidationInfo/ValidationInfoSection";

const UNDO_REDO_TYPES = {
    UNDO: 0,
    REDO: 1,
};

/**
 * https://docs.google.com/document/d/1V2HnBGlSVqgjVsYYUbLMRu-BOrE4LYhBQzNISVcmFPI
 * @param {String} propertyId - UID
 * @param {String} buildingId - UID
 * @param {String} floorId - (optional) Floor Id to start on
 * @param {String} theme
 * @param {String} fileUrl
 * @returns
 */
export const MapEditor = ({ propertyId, buildingId, floorId, theme, fileUrl }) =>
{
    const mapEditorContext = useContext(MapEditorContext);

    const [redoSteps, setRedoSteps] = useState([]);
    const [selectedFilterItem, setSelectedFilterItem] = useState(undefined);
    const [smartFilter, setSmartFilter] = useState(undefined);
    const [undoRedoQueue, setUndoRedoQueue] = useState([]);
    const [undoRedoAction, setUndoRedoAction] = useState(undefined);

    /**
     * On PropertyId, BuildingId, theme, change
     *
     * check if eitherId is different from what is currently being plotted
     *
     * if there are differences, get new data to be plotted and update state
     */
    useEffect(() =>
    {
        // if propertyId is undefined skip bellow logic
        if (!propertyId || !mapEditorContext)
        {
            return;
        }

        mapEditorContext.handleGetMapDataAsync({ propertyId, buildingId, floorId, theme, fileUrl });

    }, [propertyId, buildingId, floorId, mapEditorContext.state?.editType, mapEditorContext.state?.georeferenceFloorId, mapEditorContext.state?.georeferenceBuildingId, theme]);


    /******************************************/
    /************ HELPER FUNCTIONS ************/
    /******************************************/

    const selectedFilter = useMemo(() =>
    {
        return mapEditorContext?.state?.selectedFilter;
    }, [mapEditorContext?.state?.selectedFilter]);

    const localDBUpdatedHelper = useCallback(async () =>
    {
        mapEditorContext.handleSetCanUndo(true);
        mapEditorContext.handleSetCanRedo(false);

        setRedoSteps([]);
    }, [setRedoSteps]);

    /******************************************/
    /********** UNDO / REDO HANDLERS **********/
    /******************************************/

    const handleAddActionToUndoRedoQueue = useCallback((type, callback) =>
    {
        setUndoRedoQueue(prevQueue =>
        {
            let newQueue = [...prevQueue, { type, callback }];
            setUndoRedoAction(prevAction =>
            {
                if (prevAction)
                {
                    return prevAction;
                }

                return newQueue[0];
            });
            return newQueue;
        });
    }, [undoRedoAction, setUndoRedoQueue, setUndoRedoAction]);

    const handleRemoveActionFromUndoRedoQueue = useCallback(() =>
    {
        setUndoRedoQueue(prevQueue =>
        {
            prevQueue.splice(0, 1);
            setUndoRedoAction(prevQueue[0]);
            return prevQueue;
        });
    }, [setUndoRedoQueue, setUndoRedoAction]);

    const handleRedo = useCallback(async (redoCallback) =>
    {
        // if no redo step -> do nothing
        if (redoSteps.length === 0)
        {
            handleRemoveActionFromUndoRedoQueue();
            return;
        }

        let newRedoSteps = JSON.parse(JSON.stringify(redoSteps));

        let lastRedoStep = newRedoSteps.pop();
        let reversedStep = JSON.parse(JSON.stringify(lastRedoStep));

        redoCallback(reversedStep);

        //complete the universal redo logic
        // 1. delete step from redo array
        // 2. add step to undo array
        setRedoSteps(prevRedoSteps =>
        {
            prevRedoSteps.pop();
            return prevRedoSteps;
        });

        lastRedoStep.updatedAt = new Date();
        lastRedoStep._id = ObjectId().toString();
        await mapEditorLocalDb.insertMany(CollectionNameMap.steps, [lastRedoStep]);

        if (newRedoSteps.length === 0)
        {
            mapEditorContext.handleSetCanRedo(true);
        }

        mapEditorContext.handleSetCanRedo(newRedoSteps.length > 0);
        mapEditorContext.handleSetCanUndo(true);

        handleRemoveActionFromUndoRedoQueue();

    }, [redoSteps, setRedoSteps, mapEditorContext, handleRemoveActionFromUndoRedoQueue]);

    const handleUndo = useCallback(async (undoCallback) =>
    {
        let lastStep = await mapEditorLocalDb.handleGetLastStep();

        // if no steps, do nothing
        if (!lastStep)
        {
            handleRemoveActionFromUndoRedoQueue();
            return;
        }

        undoCallback(lastStep);

        // Complete the universal undo logic
        // 1. delete step from undoArray
        // 2. add step to redo array

        // delete step from db
        await mapEditorLocalDb.deleteMany(CollectionNameMap.steps, [lastStep]);

        // update redoSteps
        setRedoSteps(prevRedoSteps => [...prevRedoSteps, lastStep]);

        let secondLastStep = await mapEditorLocalDb.handleGetLastStep();
        mapEditorContext.handleSetCanUndo(!!secondLastStep);
        mapEditorContext.handleSetCanRedo(true);

        handleRemoveActionFromUndoRedoQueue();
    }, [redoSteps, setRedoSteps, handleRemoveActionFromUndoRedoQueue]);

    useEffect(() =>
    {
        if (!undoRedoAction)
        {
            return;
        }

        if (undoRedoAction.type === UNDO_REDO_TYPES.UNDO)
        {
            handleUndo(undoRedoAction.callback);
        }
        else if (undoRedoAction.type === UNDO_REDO_TYPES.REDO)
        {
            handleRedo(undoRedoAction.callback);
        }

    }, [undoRedoAction]);

    const handleUndoClicked = useCallback((callback) =>
    {
        handleAddActionToUndoRedoQueue(UNDO_REDO_TYPES.UNDO, callback);
    }, [handleAddActionToUndoRedoQueue]);

    const handleRedoClicked = useCallback((callback) =>
    {
        handleAddActionToUndoRedoQueue(UNDO_REDO_TYPES.REDO, callback);
    }, [handleAddActionToUndoRedoQueue]);

    /******************************************/
    /************ Sidebar Handlers ************/
    /******************************************/
    const handleSelectFilterItem = useCallback((filterItem) =>
    {
        const { editType, selectedTool } = mapEditorContext.state;
        if (editType === EDIT_TYPES.ENTITIES && selectedTool !== MAP_EDITOR_TOOLS.EditEntities)
        {
            mapEditorContext.handleChangeSelectedTool(MAP_EDITOR_TOOLS.EditEntities);
        }

        if (selectedFilterItem === filterItem)
        {
            filterItem = undefined;
        }

        setSelectedFilterItem(filterItem);
    }, [selectedFilterItem, setSelectedFilterItem, mapEditorContext.state]);

    const handleUpdateSmartFilter = useCallback((smartFilter) =>
    {
        setSmartFilter(smartFilter);
    }, [setSmartFilter]);

    const handleSetSelectedFilterItem = useCallback((item) =>
    {
        setSelectedFilterItem(item);
    }, [setSelectedFilterItem]);

    const renderMapEditor = useCallback(() =>
    {
        const editType = mapEditorContext.state.editType;
        if (editType === EDIT_TYPES.NODES)
        {
            return (
                <NodeEditor
                    propertyId={propertyId}
                    buildingId={buildingId}
                    floorId={floorId}
                    selectedFilter={selectedFilter}
                    onUndo={handleUndoClicked}
                    onRedo={handleRedoClicked}
                    onUpdateSmartFilter={handleUpdateSmartFilter}
                    onLocalDBUpdated={localDBUpdatedHelper}
                    selectedFilterItem={selectedFilterItem}
                    onSetSelectedFilterItem={handleSetSelectedFilterItem}
                />);
        }
        else if (editType === EDIT_TYPES.ENTITIES)
        {
            return (
                <EntityEditor
                    propertyId={propertyId}
                    buildingId={buildingId}
                    floorId={floorId}
                    selectedFilter={selectedFilter}
                    onUndo={handleUndoClicked}
                    onRedo={handleRedoClicked}
                    onUpdateSmartFilter={handleUpdateSmartFilter}
                    onLocalDBUpdated={localDBUpdatedHelper}
                    selectedFilterItem={selectedFilterItem}
                    onSetSelectedFilterItem={handleSetSelectedFilterItem}
                />);
        }
        else if (editType === EDIT_TYPES.TRANSITIONS)
        {
            return (
                <TransitionsEditor
                    onLocalDBUpdated={localDBUpdatedHelper}
                    onUndo={handleUndoClicked}
                    onRedo={handleRedoClicked}
                />
            );
        }

    }, [mapEditorContext.state.editType, propertyId, buildingId, floorId, selectedFilter, handleUndoClicked, handleRedoClicked, handleUpdateSmartFilter, localDBUpdatedHelper, selectedFilterItem, handleSetSelectedFilterItem]);

    const handleChangeEditType = useCallback((editType) =>
    {
        mapEditorContext.handleChangeEditType(editType);
    }, [mapEditorContext.handleChangeEditType]);

    if (mapEditorContext.state.editType === EDIT_TYPES.ENTITIES)
    {
        //floor plan  is been only  used in the entities and
        //when were are switching between edit type
        //the floor plan layers not getting displayed
        //even they have added on the map this is a quick fix
        //need to ingestive the root cause
        return (
            <FloorPlanProvider
                onResetRedo={localDBUpdatedHelper}
                onUndo={handleUndoClicked}
                onRedo={handleRedoClicked}
                propertyId={propertyId}
                buildingId={buildingId}
                floorId={floorId}
                activeTool={mapEditorContext.state.selectedTool}
                shouldUseLocalData={mapEditorContext.shouldUseLocalData}
                onActiveToolChange={mapEditorContext.handleChangeSelectedTool}
                getLevelNotLockedAlertMessage={mapEditorContext.getLevelNotLockedAlertMessage}
                onLayersVisibilityChange={mapEditorContext.handleUpdateMapLayersVisablity}
                editType={mapEditorContext.state.editType}
                defaultLayersVisibility={mapEditorContext.state.mapLayersVisablityMap}
                properties={mapEditorContext.state.properties}
                isLevelLockedByUser={mapEditorContext?.state?.isLevelLockedByUser}
                undoRedoButtonClick={mapEditorContext?.state?.undoRedoButtonClick}
                onSetUndoRedoButtonClick={mapEditorContext?.handleSetUndoRedoButtonClick}
                setLoading={mapEditorContext?.setLoading}

            >
                <div className="map-editor-map">

                    {/* call editor type... ie mapeditor_nodes */}
                    {renderMapEditor()}


                    <MapEditorLeftbar
                        editType={mapEditorContext.state.editType}
                        smartFilter={smartFilter}
                        selectedFilterItem={selectedFilterItem}
                        onSelectFilterItem={handleSelectFilterItem}
                        onChangeEditType={handleChangeEditType}
                    />

                    <ValidationInfoSection />


                </div>


            </FloorPlanProvider>

        );
    }
    else
    {
        return (<div className="map-editor-map">

            {/* call editor type... ie mapeditor_nodes */}
            {renderMapEditor()}


            <MapEditorLeftbar
                editType={mapEditorContext.state.editType}
                smartFilter={smartFilter}
                selectedFilterItem={selectedFilterItem}
                onSelectFilterItem={handleSelectFilterItem}
                onChangeEditType={handleChangeEditType}
            />

            <ValidationInfoSection />

        </div>);
    }


};
