import { getTextFontSettings } from "mapsted.maps/mapFunctions/stylesAndThemes";
import { DEFAULT_LANGUAGE_CODE } from "mapsted.maps/utils/map.constants";
import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import RevertToDefaultConfirmModal from "../modals/RevertToDefaultConfirmModal";
import TextStyleSettings from "../TextStyleSettings";
import TabPane from "./TabPane";
import { useTranslation } from "react-i18next";

const TextStyleEditorTab = ({
    style, 
    onChange,
    onRevertToDefault,
    onSave,
    lang=DEFAULT_LANGUAGE_CODE,
    previewState,
    onPreviewStateChange,
    disableEdit,
}) => 
{
    // state field to manage revert to default style confirmation modal
    const [openRevertModal, setOpenRevertModal] = useState(false);
    const trans = useTranslation().t;

    const handleTextStyleChange = (type, field, value) =>
    {
        let updatedTextStyle = { ...style.styleSettings[lang].text[type] };

        updatedTextStyle[field] = value;

        // update 'font' field if 'fontName' or 'size' is updated, since font field is combination of both
        if (field === "fontName" || field === "size")
        {
            updatedTextStyle.font = getTextFontSettings({
                fontName: updatedTextStyle.fontName,
                size: parseInt(updatedTextStyle.size.replace("px", ""))
            });
        }

        onChange(type, updatedTextStyle);
    };

    const textStyle = style.styleSettings[lang].text;

    return (
        <TabPane>
            <TextStyleSettings
                onTextStyleChange={handleTextStyleChange}
                textStyle={textStyle.default}
                heading={trans("StylesAndThemesSidebar.Default")}
                entityStateKey="default"
                checked={previewState === "default"}
                disabled={previewState !== "default" || disableEdit}
                onCheckboxClick={() => onPreviewStateChange("default")}
            />

            {textStyle.selected && <TextStyleSettings
                onTextStyleChange={handleTextStyleChange}
                textStyle={textStyle.selected}
                heading={trans("StylesAndThemesSidebar.Selected")}
                entityStateKey="selected"
                checked={previewState === "selected"}
                disabled={previewState !== "selected" || disableEdit}
                onCheckboxClick={() => onPreviewStateChange("selected")}
            />}

            <div className="stylesEditorActions">
                <Button 
                    primary 
                    floated="right" 
                    content={trans("StylesAndThemesSidebar.Apply")}
                    onClick={onSave}
                    disabled={disableEdit}
                />
                <Button 
                    basic 
                    color="orange" 
                    floated="left" 
                    className="defaultReverBtn" 
                    content={trans("StylesAndThemesSidebar.Revert_chnges_to_default")}
                    onClick={() => setOpenRevertModal(true)}
                    disabled={disableEdit}
                />
                <RevertToDefaultConfirmModal
                    open={openRevertModal}
                    onConfirm={() => 
                    {
                        onRevertToDefault(lang);
                        setOpenRevertModal(false);
                    }}
                    onClose={() => setOpenRevertModal(false)}
                />
            </div>
        </TabPane>);
};

export default TextStyleEditorTab;