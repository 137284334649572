import { useInfiniteQuery, useMutation } from "react-query";
import serverApi from "../../../../../_api/server.api";
import { getImagesSortValue } from "../utils/imageManagementUtils";
import { IMAGES_LIMIT } from "../../constant";


export const useGetImages = ({ imagesSearchAndFilters, enabled, toBeDeleted = false }) => useInfiniteQuery({
    queryKey: [
        "images",
        imagesSearchAndFilters.searchValue,
        imagesSearchAndFilters.filters,
        { toBeDeleted }
    ],
    queryFn: async ({ pageParam = 1 }) => serverApi.getImagesReference({
        page: pageParam,
        search: imagesSearchAndFilters.searchValue,
        aspectRatio: imagesSearchAndFilters.filters.ratio,
        pageSize: IMAGES_LIMIT,
        toBeDeleted,
        ...getImagesSortValue(imagesSearchAndFilters.filters.sort),
    }),
    getNextPageParam: (lastPage, pages) => 
    {
        const remainingItems = lastPage.count - (pages.length * IMAGES_LIMIT);
        const remainingPages = Math.ceil(remainingItems / IMAGES_LIMIT);
        return remainingPages >= 1 ? pages.length + 1 : undefined;
    },
    enabled,
    cacheTime: 0,
});