import React from "react";
import PropTypes from "prop-types";
import { InputFormGroup } from "../elements/InputFormGroup";
import { InitialDetailBox } from "./InitialDetailBox";
import { useTranslation } from "react-i18next";
import { SINGLE_LANG_INPUT_CODE } from "../../_constants/constants";

export const PropertyDetailBox = React.memo(({ edit, setEdit, property }) =>
{
    /** @type {typeof import("../../_intl/resources.json")["en"]["translation"]["property detail box"]} */
    const trans = useTranslation().t("property detail box");
    const handleOnClick = React.useCallback(() => setEdit(!edit), [edit, setEdit]);

    return (
        <InitialDetailBox action="pencil" onClick={handleOnClick}>
            <InputFormGroup label={trans["name label"]} detail={property.name?.[SINGLE_LANG_INPUT_CODE]} />
            <InputFormGroup label={trans["address label"]} detail={property.fullAddress} />
            <InputFormGroup label={trans["website label"]} detail={property.website?.[SINGLE_LANG_INPUT_CODE]} />
            <InputFormGroup label={trans["phone label"]} detail={property.phoneNumbers} />
        </InitialDetailBox>
    );
});

PropertyDetailBox.displayName = "PropertyDetailBox";

PropertyDetailBox.propTypes = {
    edit: PropTypes.bool.isRequired,
    setEdit: PropTypes.func.isRequired,
    property: PropTypes.object.isRequired
};
