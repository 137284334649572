import cloneDeep from "lodash.clonedeep";
import { DEFAULT_LANGUAGE_CODE } from "mapsted.maps/utils/map.constants";
import Papa from "papaparse";
import { DEFAULT_VERTICES, NEW_AUTOMATION_RULE, NEW_DYNAMIC_MAP_LAYER_DEFAULT, NEW_OVERLAY_DEFAULT } from "../../../../_constants/constants";
import { v4 as uuid } from "uuid";
import { circular } from "ol/geom/Polygon";
import { validateMulitCreationFileDataRows, validatePolygonOverlayIntersection } from "./dynamicMapLayerValidations";
import { getCoordinatesAndRadius } from "./dynamicMapLayersUtils";

export const FILE_COLUMNS = {
    propertyId: "PROPERTY ID",
    apiKey: "API KEY",
    overlayName: "MAP OVERLAY NAME",
    overlayLabel: "MAP OVERLAY LABEL",
    layerName: "MAP LAYER NAME",
    toolTipText: "MAP LAYER TEXT POP-UP",
    dataLabel: "DATA POINT NAME",
    condition: "CONDITION (greater/lesser/equal)",
    value: "VALUE",
    color: "FILL COLOR",
    borderColor: "BORDER COLOR",
    textColor: "TEXT COLOR",
    selectionType: "SELECTION TYPE",
    x: "X (Longitude)",
    y: "Y (Latitude)",
    r: "R (Radius in m)"
};

export const parseFile = async (file) =>
{
    const csvString = await new Promise((resolve) =>
    {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsText(file);
    });

    return csvString;
};

export const parseCSVString = (csvString) => Papa.parse(csvString);

export const validateAndReturnJson = async (csvString, mapOverlays, trans) =>
{
    let errors = [];
    let dataRows = [];

    const result = Papa.parse(csvString);

    if (result.errors.length)
    {
        errors.push(trans("MaintenanceDynamicLayerSidebar.Invalid_file"));
    }
    else
    {
        let data = result.data;
        
        if (data.length === 0)
        {
            errors.push(trans("MaintenanceDynamicLayerSidebar.Empty_file"));
        }
        else if (!validateHeaders(data))
        {
            errors.push(trans("MaintenanceDynamicLayerSidebar.Invalid_headers"));
        }
        else
        {
            // remove last row if it is empty
            if (data[data.length - 1].length === 1 && data[data.length - 1][0].trim() === "")
            {
                data.splice(data.length - 1);
            }

            const columnHeaders = Object.keys(FILE_COLUMNS);
            
            // remove header row and iterate
            data.splice(1)
                .forEach((dataPoint) =>
                {
                    let dataRow = {};
                    dataPoint.forEach((columnValue, index) =>
                    {
                        if (columnValue.trim())
                        {
                            dataRow[columnHeaders[index]] = columnValue.trim();
                        }
                    });
                    dataRows.push(dataRow);
                });
            // removing empty rows by filtering the array
            dataRows = dataRows.filter((r) => Boolean(Object.keys(r)?.length ));

            if (dataRows.length === 0)
            {
                errors.push(trans("MaintenanceDynamicLayerSidebar.No_Data_Rows_Found"));
            }
            else
            {
                // validate dataRows
                errors = await validateMulitCreationFileDataRows(dataRows);
            
                // // if dataRows validation is successfull, check if new polygons intersect with
                // // existing polygons or other polygons in the file
                // if (!errors.length)
                // {
                //     errors = validatePolygonOverlayIntersection(dataRows, mapOverlays);
                // }
            }
        }
    }

    return { errors, dataRows };
};

const validateHeaders = (data) =>
{
    const headers = data[0];
    const trimmedHeaders = headers.map((header) => header.trim());
    const validHeaders = Object.values(FILE_COLUMNS);
    return validHeaders.join(",") === trimmedHeaders.join(",");
};

export const getMapOverlaysAndDynamicMapLayers = (dataRows) =>
{
    let mapOverlays = [];
    let dynamicMapLayers = [];

    dataRows.forEach((dataRow) =>
    {
        const mapOverlay = createMapOverlayFromDataRow(dataRow);
        mapOverlays.push(mapOverlay);

        const dynamicMapLayer = createDynamicMapLayerFromDataRow(dataRow);
        dynamicMapLayer.mapOverlayId = mapOverlay._id;
        dynamicMapLayers.push(dynamicMapLayer);
    });

    return { mapOverlays, dynamicMapLayers };
};

const createMapOverlayFromDataRow = (dataRow) =>
{
    let newMapOverlay = cloneDeep(NEW_OVERLAY_DEFAULT);
    newMapOverlay.name[DEFAULT_LANGUAGE_CODE] = dataRow["overlayName"];
    newMapOverlay.toolTipText[DEFAULT_LANGUAGE_CODE] = dataRow["overlayLabel"];
    // temp id for mapOverlay
    newMapOverlay._id = uuid();
    newMapOverlay.shape = createShape(dataRow);
    return newMapOverlay;
};

const createDynamicMapLayerFromDataRow = (dataRow) =>
{
    let newDynamicMapLayer = cloneDeep(NEW_DYNAMIC_MAP_LAYER_DEFAULT);

    newDynamicMapLayer.dataStreamName[DEFAULT_LANGUAGE_CODE] = dataRow["layerName"];
    newDynamicMapLayer.apiKey = uuid();
    newDynamicMapLayer.dataPointName = dataRow["dataLabel"];
    newDynamicMapLayer.automationRules = [createNewAutomationRuleFromDataRow(dataRow)];

    // temp _id
    newDynamicMapLayer._id = uuid();

    return newDynamicMapLayer;
};

const createNewAutomationRuleFromDataRow = (dataRow) =>
{
    let newAutomationRule = cloneDeep(NEW_AUTOMATION_RULE);

    newAutomationRule.dataPointCondition = {
        condition: dataRow["condition"],
        value: dataRow["value"]
    };
    newAutomationRule.textLabel[DEFAULT_LANGUAGE_CODE] = dataRow["overlayLabel"];
    newAutomationRule.textPopup[DEFAULT_LANGUAGE_CODE] = dataRow["toolTipText"];

    if (dataRow["color"])
    {
        newAutomationRule.fillColor = dataRow["color"];
    }

    if (dataRow["borderColor"])
    {
        newAutomationRule.borderColor = dataRow["borderColor"];
    }

    if (dataRow["textColor"])
    {
        newAutomationRule.textColor = dataRow["textColor"];
    }

    // temp _id
    newAutomationRule._id = uuid();

    return newAutomationRule;
};

const createShape = (dataRow) =>
{
    const polygon = circular([+dataRow["x"], +dataRow["y"]], +dataRow["r"], DEFAULT_VERTICES);
    const shape = {
        type: polygon.getType(),
        coordinates: polygon.getCoordinates()
    };
    if (dataRow["r"])
    {
        shape.radius = +dataRow["r"];
    }
    return shape;
};

export const downloadMultiCreationFile = (dynamicMapLayers, mapOverlays, publicId, fileName) =>
{
    const dataRows = [];

    // get dynamic map layers in object form and set it in dataRows which is an array of objects
    dynamicMapLayers.forEach((dynamicMapLayer) =>
    {
        const mapOverlay = mapOverlays.find((mapOverlay) => mapOverlay._id === dynamicMapLayer.mapOverlayId);
        dataRows.push(createDataRow(dynamicMapLayer, mapOverlay, publicId));
    });

    // get data in Array<Array<String>> format to facilitate unparsing using papa library
    let data = [];
    dataRows.forEach((dataRow) =>
    {
        let tempDataRow = [];
        Object.keys(FILE_COLUMNS).forEach((header) =>
        {
            tempDataRow.push(dataRow[header]);
        });
        data.push(tempDataRow);
    });

    const csvData = Papa.unparse({ fields: Object.values(FILE_COLUMNS), data });
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    downloadFile(fileName, blob);
};

const createDataRow = (dynamicMapLayer, mapOverlay, publicId) =>
{
    const automationRule = dynamicMapLayer.automationRules[0];

    const { x, y, r } = getCoordinatesAndRadius(mapOverlay.shape);

    let dataRow = {
        propertyId: publicId,
        apiKey: dynamicMapLayer.apiKey,
        overlayName: mapOverlay.name[DEFAULT_LANGUAGE_CODE],
        overlayLabel: mapOverlay.toolTipText[DEFAULT_LANGUAGE_CODE],
        layerName: dynamicMapLayer.dataStreamName[DEFAULT_LANGUAGE_CODE],
        toolTipText: automationRule.textPopup[DEFAULT_LANGUAGE_CODE],
        dataLabel: dynamicMapLayer.dataPointName,
        condition: automationRule.dataPointCondition.condition,
        value: automationRule.dataPointCondition.value,
        color: automationRule.fillColor,
        borderColor: automationRule.borderColor,
        textColor: automationRule.textColor,
        selectionType: "circle",
        x,
        y,
        r
    };

    return dataRow;
};

export const downloadFile = (fileName, blob) =>
{
    const url = window.URL.createObjectURL(blob);
    const aTagElement = document.createElement("a");
    aTagElement.href = url;
    aTagElement.download = fileName;
    document.body.appendChild(aTagElement); // we need to append the element to the dom -> otherwise it will not work in firefox
    aTagElement.click();
    aTagElement.remove();
};