import React from "react";
import { Button } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { deepUpdateValue } from "mapsted.utils/objects";
import { InputFormGroup } from "../elements/InputFormGroup";
import { UTIL_QUERIES } from "../../_utils/queries";
import { handleUploadFile } from "../../_utils/utils";
import { OrganizeFloorPlanRow } from "./OrganizeFloorPlanRow";
import { PROPERTIES, floorPlanAcceptableFileFormats } from "../../_constants/config";
import { DEFAULT_LANGUAGE_CODE, SINGLE_LANG_INPUT_CODE, STATUS_DESCRIPTION } from "../../_constants/constants";

export const BuildingFloorUploadRow = ({ floorObject, floorNum, onUpload, onRemove, error }) =>
{
    /** @type {typeof import("../../_intl/resources.json")["en"]["translation"]["building floor upload row"]} */
    const trans = useTranslation().t("building floor upload row");
    const { data: config } = useQuery(UTIL_QUERIES.CONFIG());
    const floorPlanRef = React.useRef();

    const [floor, setFloor] = React.useState(floorObject || {
        longName: { [DEFAULT_LANGUAGE_CODE]: "" },
        shortName: { [DEFAULT_LANGUAGE_CODE]: "" },
        status: config.STATUSES.DRAFT
    });

    const handleFloorChange = React.useCallback((e, { name, value }) =>
    {
        let newFloor = { ...floor };

        deepUpdateValue(newFloor, name, value);
        setFloor(newFloor);
    }, [floor]);

    const handleSelectFloorPlan = React.useCallback(() =>
    {
        handleUploadFile(floorPlanRef.current, undefined, (file) =>
        {
            let newFloor = { ...floor };
            newFloor.floorPlan = file;

            // When updating incomplete floor plan, change the status to validating
            if (newFloor.status === config.STATUSES.INCOMPLETE)
            {
                newFloor.status = config.STATUSES.VALIDATING;
            }

            setFloor(newFloor);

            onUpload && onUpload(floorNum, newFloor);
        });

    }, [floorPlanRef, floor, floorNum, onUpload]);

    const handleOnUploadFloorPlan = React.useCallback(() =>
    {
        floorPlanRef.current.click();
    }, [floorPlanRef]);

    const handleOnRemoveFloorPlan = React.useCallback(() =>
    {
        onRemove && onRemove(floorNum);
    }, [floorNum, onRemove]);

    return (

        <div className={`building-floor-upload ${(error) && "error "}`} >
            {
                (!floor.floorPlan) && (!floor.floorPlanImages || STATUS_DESCRIPTION[floor.status].incomplete) && (
                    <>
                        <InputFormGroup
                            label={trans["floor name label"]}
                            placeholder={trans["floor name placeholder"]}
                            value={floor.longName?.[SINGLE_LANG_INPUT_CODE]}
                            onChange={handleFloorChange}
                            name={`longName.${SINGLE_LANG_INPUT_CODE}`}
                        />

                        <InputFormGroup
                            label={trans["short name"]}
                            placeholder={trans["short name placeholder"]}
                            value={floor.shortName?.[SINGLE_LANG_INPUT_CODE]}
                            onChange={handleFloorChange}
                            name={`shortName.${SINGLE_LANG_INPUT_CODE}`}
                            maxLength={PROPERTIES.MAX_FLOOR_SHORTNAME_LENGTH}
                        />

                        <Button
                            disabled={!floor.longName?.[SINGLE_LANG_INPUT_CODE] || !floor.shortName?.[SINGLE_LANG_INPUT_CODE]}
                            className="upload-floor"
                            size="small"
                            color="grey"
                            content={trans["upload label"]}
                            onClick={handleOnUploadFloorPlan}
                        />

                        <input type="file" ref={floorPlanRef} className="hidden"
                            accept={floorPlanAcceptableFileFormats} onChange={handleSelectFloorPlan} />

                    </>
                )
            }
            {
                (floor.floorPlan) && (
                    <>
                        <OrganizeFloorPlanRow title={floor.longName?.[SINGLE_LANG_INPUT_CODE]} short={floor.shortName?.[SINGLE_LANG_INPUT_CODE]} file={floor.floorPlan} status={floor.status} onDelete={handleOnRemoveFloorPlan} />
                    </>
                )
            }
            {
                (!floor.floorPlan && floor.floorPlanImages && !STATUS_DESCRIPTION[floor.status].incomplete) && (
                    <OrganizeFloorPlanRow title={floor.longName?.[SINGLE_LANG_INPUT_CODE]} short={floor.shortName?.[SINGLE_LANG_INPUT_CODE]} status={floor.status} />
                )
            }
        </div >
    );
};
