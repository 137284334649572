/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-state */

import React from "react";
import DashboardContext from "./DashboardContext";
import { Loader } from "../components/elements/loader";
import { hashSort } from "../_utils/utils";
import { SINGLE_LANG_INPUT_CODE } from "../_constants/constants";
import serverAPI from "../_api/server.api";
import { withConfig, withQueryClient } from "../_utils/hoc";
import { DATA_QUERIES } from "../_utils/queries";
import BrandingContext from "./BrandingContext";
import { DEFAULT_LANGUAGE_CODE } from "mapsted.maps/utils/map.constants";

class DashboardProvider extends React.Component
{
    static contextType = BrandingContext;

    constructor(props)
    {
        super(props);

        this.state = {

            loading: true,
            properties: {},
            propertyId: undefined,
            buildingId: undefined,
            analytics: {
                mobile: {}
            },
            analyticsPercentages: {
                mobile: {}
            },

            analyticsConstants: undefined,

            termsAndConditions: true,
        };
    }

    componentDidMount()
    {
        const dashboardData = serverAPI.dashboardData;

        this.setState({ loading: true }, () => this.getProperties()
            .then(([properties, analyticsConstants]) =>
            {
                let propertyId = undefined;
                let buildingId = undefined;

                if (properties && Object.keys(properties).length === 0)
                {
                    // Do Nothing
                }
                else if (properties && dashboardData)
                {
                    if (dashboardData.propertyId && properties[dashboardData.propertyId])
                    {
                        propertyId = dashboardData.propertyId;

                        if (dashboardData.buildingId && properties[propertyId].buildings[dashboardData.buildingId])
                        {
                            buildingId = dashboardData.buildingId;
                        }
                    }
                }
                else
                {
                    propertyId = properties && Object.keys(properties)[0];
                    serverAPI.handleUpdateDashboardData("propertyId", propertyId);
                }

                this.setState({
                    properties,
                    propertyId,
                    buildingId,
                    analyticsConstants,
                    loading: false,
                });
            }));
    }

    /**
     * Fetches all buildings belonging to property. This function checks local state first to avoid fetching from server unless needed.
     * @param {string} propertyId - Prope
     */
    getBuildingsBelongingToProperty = async (propertyId) =>
    {
        let properties = { ...this.state.properties };

        if (propertyId)
        {
            if (properties[propertyId].buildings)
            {
                return properties[propertyId].buildings;
            }
            else
            {
                properties[propertyId].buildings = await serverAPI.getPropertyBuildings(propertyId);

                this.setState({ properties });

                return properties[propertyId].buidlings;
            }
        }
        else
        {
            return {};
        }
    }

    /**
     * Fetches all properties. Should only be called on dashboard init.
     */
    getProperties = async () =>
    {
        const [properties, analyticsConstants] = await Promise.all([
            this.props.queryClient.fetchQuery(DATA_QUERIES.GET_PROPERTIES()),
            serverAPI.analyticsConstants()
        ]);
        return [properties, analyticsConstants];
    }

    getWebAnalyticsData= async ({ propertyId }) => 
    {

        const webAnalyticsData = await serverAPI.getWebAnalyticsData({ propertyId });
        return webAnalyticsData;
    }
    /**
     * Changes and fetches the new selected property. Then fetches analytics for the property synchronously.
     * @param {string} propertyId - The new selected propertyId
     */
    changeSelectedProperty = async (propertyId) =>
    {
        this.setState({ propertyId: propertyId, buildingId: undefined });

        serverAPI.handleUpdateDashboardData("propertyId", propertyId);

        await this.getBuildingsBelongingToProperty(propertyId);
    }

    /**
     * Changes the selected building.
     * @param {string} buildingId - The new selected buildingId. buildingId=undefined sets current property to be selected for viewing and updates.
     */
    changeSelectedBuilding = (buildingId, propertyId = undefined) =>
    {
        if (propertyId)
        {
            this.setState({ propertyId, buildingId });
            serverAPI.handleUpdateDashboardData("propertyId", propertyId);
        }
        else
        {
            this.setState({ buildingId });
        }

        serverAPI.handleUpdateDashboardData("buildingId", buildingId);
    }

    /**
     * Gets mobile analaytics for given propertyId
     * @param {String} propertyId
     * @param {Function} callBack
     *
     * @returns {Promise} [analyticsMobile, analyticsPercentagesMobile]
     */
    getPropertyMobileAnalytics = async (propertyId, callBack) =>
    {
        //TODO: ERROR CHECK
        //TODO: LOADING

        // API call, after data is fetched, the callback is called.
        const processed = (this.state.properties[propertyId] && this.state.properties[propertyId].status === this.props.config.STATUSES.OPERATIONAL);

        console.log("getPropertyMobileAnalytics - PROCESSED", { processed });

        if (processed)
        {
            const property = this.state.properties[propertyId];
            const navPropertyId = property.propertyId;

            const { success, data } = await serverAPI.getDashboardStats(navPropertyId, this.state.analyticsConstants);

            console.log("getPropertyMobileAnalytics - API RESULT", { success, data });

            if (success)
            {
                const analyticsData = this.handleNewMobileAnalytics(data);
                callBack(analyticsData);
                return analyticsData;
            }
            else
            {
                callBack(undefined);
            }
        }
        else
        {
            callBack(undefined);
        }

    }

    /**
     * Creates analytics and analytics percentage objects for mobile.
     * @param {object} data
     * @param {Object} data.compareAnalytics
     * @param {Object} data.analyticsIos
     * @param {Object} data.analyticsAndroid
     */
    handleNewMobileAnalytics = (data) =>
    {
        try
        {
            const { compareAnalytics, analyticsIos, analyticsAndroid } = data;

            let analyticsMobile = {
                iosUsers: analyticsIos.numberUsers,
                androidUsers: analyticsAndroid.numberUsers,
                users: analyticsIos.numberUsers + analyticsAndroid.numberUsers,
                newUsers: analyticsIos.numberNewUsers + analyticsAndroid.numberNewUsers,
                avgVisitTime: ((analyticsIos.avgVisitTimeMins + analyticsAndroid.avgVisitTimeMins) / 2).toFixed(2),
            };

            let analyticsCompareMobile = {
                users: compareAnalytics.numberUsers,
                newUsers: compareAnalytics.numberNewUsers,
                avgVisitTime: (compareAnalytics.avgVisitTimeMins).toFixed(2),
            };


            return { analyticsMobile, analyticsCompareMobile };
        }
        catch (err)
        {
            return undefined;
        }

    }

    /**
     * Update selected property info
     * @param {Object} updatedInfo - The new property info.
     * @param {String} updatedInfo.name
     * @param {Array} updatedInfo.phoneNumbers
     * @param {String} updatedInfo.website
     * @param {String} updatedInfo.description
     * @param {String} updatedInfo.email
     */
    updateProperty = async (updatedInfo, imageInfo, callback) =>
    {
        let { propertyId, properties } = this.state;

        this.setState({ loading: true }, async () =>
        {
            let state = { loading: false };

            if (propertyId !== undefined)
            {
    
                try 
                {
                    if (imageInfo) 
                    {
                        await this.updateImageReference(imageInfo, propertyId, "Property");
                    }

                    properties = { ...properties };
                    let property = { ...properties[propertyId] };

                    // Copy props from updated info to Property
                    Object.keys(updatedInfo).forEach((prop) => property[prop] = updatedInfo[prop]);

                    const updateCallback = async (response) => 
                    {
                        const { success, data } = response;
                        if (success) 
                        {
                            // Copy props from saved results to property
                            property = Object.assign(property, data);

                            properties[property._id] = { ...property };
                            state.properties = { ...properties };

                            // clear cache
                            this.props.queryClient.invalidateQueries("GET_PROPERTIES");

                            // update the Branding context state
                            await this.context.updatePropertiesWithoutWrapper();
                            this.context.handleRefreshMapData();
                        }

                        if (callback) 
                        {
                            callback(success);
                        }

                        this.setState(state);

                    };

                    serverAPI.updateProperty(property, updateCallback);
                }
                catch (err) 
                {
                    console.log(err?.message);
                    this.setState(state);
                    callback && callback(false);
                }
            }
        });
    }

    /**
     * Update selected property cover and display images
     * @param {Object} updatedInfo - The new property info.
     * @param {String} updatedInfo._id
     * @param {String} updatedInfo.fileId
     * @param {Object} updatedInfo.coverImageFile
     * @param {Boolean} updatedInfo.coverImageDelete
     * @param {Object} updatedInfo.displayImageFile
     * @param {Boolean} updatedInfo.displayImageDelete
     * @param {string} updatedInfo.languageCode
     */
    updatePropertyImages = async (updatedInfo, callback) =>
    {
        let { propertyId, properties } = this.state;

        this.setState({ loading: true }, async () =>
        {
            let state = { loading: false };
            try 
            {
                if (propertyId !== undefined) 
                {
                    properties = { ...properties };
                    let property = { ...properties[propertyId] };


                    if (updatedInfo.coverImageFile || updatedInfo.displayImageFile) 
                    {
                        // replace existing image set up
                        // let coverImages = deepValue(property, "coverImages", undefined);
                        // let indexOfReplacmentImage = undefined;

                        // if (!!updatedInfo.fileId && coverImages)
                        // {
                        //     indexOfReplacmentImage = (Array.isArray(coverImages)? coverImages: coverImages[updatedInfo.languageCode]).indexOf(updatedInfo.fileId);
                        //     await serverAPI.deletePropertyImage(propertyId, updatedInfo.fileId, updatedInfo.languageCode);
                        //     delete updatedInfo.fileId;
                        // }
                        //end of replace existing image setup
                        if (updatedInfo.coverImageFile) 
                        {
                            updatedInfo.imageType = "coverImage";
                        }
                        else 
                        {
                            updatedInfo.imageType = "iconImage";
                        }

                        if (updatedInfo.coverImageFile) 
                        {
                            await this.updateImageReference({ filerId: updatedInfo?.filerId.split(","), imageType: "coverImages", lang: updatedInfo?.languageCode, prevFilerId: null }, propertyId, "Property");
                        }

                        serverAPI.updatePropertyImage(updatedInfo, async ({ success, data }) => 
                        {
                            if (success) 
                            {

                                // replace existing image
                                // if (indexOfReplacmentImage !== undefined)
                                // {
                                //     let newId = data.coverImages[updatedInfo.languageCode].pop();

                                //     data.coverImages[updatedInfo.languageCode].splice(indexOfReplacmentImage, 0, newId);

                                //     await serverAPI.reorderPropertyImages(propertyId, data.coverImages[updatedInfo.languageCode], updatedInfo.languageCode);
                                // }

                                // Copy props from saved results to property
                                Object.keys(data).forEach((prop) => property[prop] = data[prop]);

                                properties[property._id] = { ...property };
                                state.properties = properties;
                            }

                            this.setState(state);
                            if (callback) 
                            {
                                callback(success);
                            }   
                        });
                    }
                    else 
                    {
                        this.setState(state);
                    }
                }
            }
            catch (err)
            {
                console.log(err?.message);
                this.setState(state);
                callback && callback(false);
            }
        });
    }

    /**
     * Save reordered cover image list
     * @param {Array.<String>} coverImageList
     */
    reorderPropertyCoverImages = async (coverImageList) =>
    {
        let { properties, propertyId } = this.state;
        properties = { ...properties };

        const { success, data } = await serverAPI.reorderPropertyImages(propertyId, coverImageList);

        if (success)
        {
            let property = properties[propertyId];

            // Copy props from update result to building
            Object.keys(data).forEach((prop) => property[prop] = data[prop]);

            this.setState({ properties });
        }
    }

    /**
     * Deletes iconImage or coverImage based of the fileId
     * @param {String} propertyId
     * @param {String} fileId
     * @param {*} callback
     */
    deletePropertyImage = (propertyId, fileId, callback) =>
    {
        this.setState({ loading: true }, async () =>
        {
            let properties = { ...this.state.properties };
            let state = { loading: false };

            try 
            {
                await this.updateImageReference({ filerId: null, prevFilerId: fileId, lang: "en", imageType: "coverImages" }, propertyId, "Property");
                const { success, data } = await serverAPI.deletePropertyImage(propertyId, fileId);

                if (!success) 
                {
                    throw new Error("Error deleting property image");
                }

                if (success) 
                {
                    let property = { ...properties[propertyId] };

                    // Copy props from update result to building
                    Object.keys(data).forEach((prop) => property[prop] = data[prop]);

                    properties[propertyId] = { ...property };
                    state.properties = { ...properties };
                }

                if (callback) 
                {
                    callback(success);
                }
            }
            catch (err)
            {
                console.log(err?.message);
                callback && callback(false);
            }
            finally
            {
                this.setState(state);
            }
        });
    }

    /**
     * Update building info
     * @param {Object} updatedInfo - The new building info.
     * @param {string} updatedInfo.longName
     * @param {string} updatedInfo.shortName
     * @param {Array} updatedInfo.phoneNumbers
     * @param {string} updatedInfo.website
     * @param {string} updatedInfo.description
     * @param {string} updatedInfo.email
     */
    updateBuilding = async (updatedInfo, imageInfo, callback) =>
    {
        let { buildingId, propertyId, properties } = this.state;

        if (buildingId === undefined)
        {
            return;
        }

        this.setState({ loading: true }, async () =>
        {
            let state = { loading: false };
            try 
            {
                properties = { ...properties };
                let building = { ...properties[propertyId].buildings[buildingId] };

                building = Object.assign(building, updatedInfo);

                if (imageInfo) 
                {
                    await this.updateImageReference(imageInfo, buildingId, "Building");
                }

                const { success, data } = await serverAPI.updateBuildingWithoutImages(building);

                if (!success)
                {
                    throw new Error("Error updating building");
                }

                if (success === true) 
                {
                    let building = properties[propertyId].buildings[buildingId];
                    building = Object.assign(building, data);

                    properties[propertyId].buildings[buildingId] = { ...building };
                    state.properties = properties;

                    // clear cache
                    this.props.queryClient.invalidateQueries("GET_PROPERTIES");

                    // update the Branding context state
                    await this.context.updatePropertiesNoWrap();
                    this.context.handleRefreshMapData();
                }

                if (callback) 
                {
                    callback(success);
                }
            }
            catch (err)
            {
                console.log(err?.message);
                callback && callback(false);
            }
            finally 
            {
                this.setState(state);
            }
        });
    }

    /**
 * Update building info
 * @param {Object} updatedInfo - The new building info.
 * @param {String} updatedInfo.fileId
 * @param {Object} updatedInfo.coverImageFile
 * @param {Boolean} updatedInfo.coverImageDelete
 * @param {Object} updatedInfo.displayImageFile
 * @param {Boolean} updatedInfo.coverImageDelete
 * @param {string} updatedInfo.languageCode
 */
    updateBuildingImages = async (updatedInfo, callback) =>
    {
        let { buildingId, propertyId, properties } = this.state;

        if (buildingId === undefined)
        {
            return;
        }

        this.setState({ loading: true }, async () =>
        {
            let state = { loading: false };

            try 
            {
                properties = { ...properties };
                let building = { ...properties[propertyId].buildings[buildingId] };

                properties[propertyId].buildings[buildingId] = { ...building };
                this.setState({ properties });

                if (updatedInfo.coverImageFile || updatedInfo.displayImageFile) 
                {
                    // replace existing image set up
                    // let coverImages = deepValue(building, "coverImages", undefined);
                    // let indexOfReplacmentImage = undefined;

                    // if (!!updatedInfo.fileId && coverImages)
                    // {
                    //     indexOfReplacmentImage = (Array.isArray(coverImages)? coverImages: coverImages[updatedInfo.languageCode]).indexOf(updatedInfo.fileId);
                    //     await serverAPI.deleteBuildingImage(building._id, updatedInfo.fileId, updatedInfo.languageCode);
                    //     delete updatedInfo.fileId;

                    // }
                    //end of replace existing image setup

                    if (updatedInfo.coverImageFile) 
                    {
                        updatedInfo.imageType = "coverImage";
                    }
                    else 
                    {
                        updatedInfo.imageType = "iconImage";
                    }

                    if (updatedInfo.coverImageFile) 
                    {
                        await this.updateImageReference({ filerId: updatedInfo?.filerId.split(","), imageType: "coverImages", lang: updatedInfo?.languageCode, prevFilerId: null }, buildingId, "Building");
                    }

                    serverAPI.updateBuildingImage(updatedInfo, async ({ success, data }) => 
                    {
                        if (success) 
                        {
                            // replace existing image
                            // if (indexOfReplacmentImage !== undefined)
                            // {
                            //     let newId = data.coverImages[updatedInfo.languageCode].pop();

                            //     data.coverImages[updatedInfo.languageCode].splice(indexOfReplacmentImage, 0, newId);

                            //     await serverAPI.reorderBuildingImages(buildingId, data.coverImages[updatedInfo.languageCode], updatedInfo.languageCode);
                            // }

                            // Copy props from update result to building
                            Object.keys(data).forEach((prop) => building[prop] = data[prop]);

                            properties[propertyId].buildings[buildingId] = { ...building };
                            state.properties = { ...properties };

                            // update the Branding context state
                            await this.context.updatePropertiesWithoutWrapper();
                            this.context.handleRefreshMapData();
                        }

                        this.setState(state);
                        if (callback) 
                        {
                            callback(success);
                        }
                    });
                }
                else 
                {
                    this.setState(state);
                }
            }
            catch (err)
            {
                this.setState(state);
                console.log(err?.message);
                callback && callback(false);
            }
        });
    }

    /**
     * Update the order of building's cover images
     * @param {Array.<String>} coverImageList
     */
    reorderBuildingCoverImages = async (coverImageList) =>
    {
        let { buildingId, propertyId, properties } = this.state;

        const { success, data } = await serverAPI.reorderBuildingImages(buildingId, coverImageList);

        if (success)
        {
            properties = { ...properties };
            let building = properties[propertyId].buildings[buildingId];

            // Copy props from update result to building
            Object.keys(data).forEach((prop) => building[prop] = data[prop]);

            this.setState({ properties });
        }
    }

    /**
     * Delete building's icon image or cover image based on the fileId
     * @param {String} buildingId
     * @param {String} fileId
     * @param {Function} callback
     */
    deleteBuildingImage = (buildingId, fileId, callback) =>
    {
        this.setState({ loading: true }, async () =>
        {

            let { propertyId, properties } = this.state;
            properties = { ...properties };
            let state = { loading: false };
            try
            {
                await this.updateImageReference({ filerId: null, prevFilerId: fileId, lang: "en", imageType: "coverImages" }, buildingId, "Building");

                const { success, data } = await serverAPI.deleteBuildingImage(buildingId, fileId);
                if (!success)
                {
                    throw new Error("Error deleting building image");
                }

                if (success)
                {
                    let building = properties[propertyId].buildings[buildingId];

                    // Copy props from update result to building
                    Object.keys(data).forEach((prop) => building[prop] = data[prop]);

                    state.properties = properties;

                    // update the Branding context state
                    await this.context.updatePropertiesWithoutWrapper();
                    this.context.handleRefreshMapData();
                }

                if (callback) 
                {
                    callback(success);
                }
            }
            catch (err)
            {
                console.log(err?.message);
                callback && callback(false);
            }
            finally
            {
                this.setState(state);
            }
        });
    }

    /**
     * Updates selected property opening hours
     * @param {*} partialOpeningHoursUpdate - The new opening hours.
     * @param {boolean} allBuildings - Whether or not we update all buildings in the property.
    */
    updatePropertyHours = async (partialOpeningHoursUpdate, allBuildings) =>
    {
        if (this.state.propertyId === undefined)
        {
            return;
        }

        this.setState({ loading: true }, async () =>
        {
            let state = { loading: false };

            let properties = JSON.parse(JSON.stringify(this.state.properties));
            let property = properties[this.state.propertyId];

            const { success, data } = await serverAPI.updatePartialPropertyHours({ _id: this.state.propertyId, partialOpeningHours: partialOpeningHoursUpdate }, allBuildings);

            if (success === true && allBuildings === true)
            {
                let buildings = property.buildings;

                if (data._id)
                {
                    partialOpeningHoursUpdate._id = data._id;
                }

                Object.keys(buildings).forEach((id) =>
                {
                    buildings[id].partialOpeningHours = partialOpeningHoursUpdate;
                });

                property.partialOpeningHours = partialOpeningHoursUpdate;
                property.buildings = buildings;
                properties[this.state.propertyId] = property;

                // update the Branding context state
                await this.context.updatePropertiesWithoutWrapper();
                this.context.handleRefreshMapData();
            }
            else if (success === true)
            {
                property.partialOpeningHours = partialOpeningHoursUpdate;
                properties[this.state.propertyId] = property;
            }
            else
            {
                //TODO: on update fail
            }

            state.properties = properties;
            this.setState(state);
        });
    }

    updateImageReference = async(imageInfo, selectedId, model) =>
    {
        const { filerId, imageType, lang, prevFilerId } = imageInfo;

        try 
        {
            if (Array.isArray(filerId)) 
            {
                const promises = filerId.map((item) => serverAPI.updateImageReference(encodeURIComponent(item), false, {
                    reference: selectedId,
                    model: model,
                    language: lang,
                    usedAs: imageType,
                }));

                const response = await Promise.allSettled(promises);

                let errIds = response.map((res,idx) => res.status === "rejected" ? filerId[idx]: null).filter((filerIds) => filerIds);

                if (errIds.length > 0)
                {
                    throw new Error(errIds.join(","));
                }

                return response;
            }

            if (prevFilerId)
            {
                await serverAPI.updateImageReference(encodeURIComponent(prevFilerId), true, {
                    reference: selectedId,
                    model: model,
                    language: lang,
                    usedAs: imageType,
                });
            }

            if (filerId) 
            {
                await serverAPI.updateImageReference(encodeURIComponent(filerId), false, {
                    reference: selectedId,
                    model: model,
                    language: lang,
                    usedAs: imageType,
                });
            }
        }
        catch (err) 
        {
            throw new Error(`Error updating image reference : ${err.message}`);
        }
    }

    /**
     * Updates selected building opening hours
     * @param {*} partialOpeningHoursUpdate - The new opening hours.
     */
    updateBuildingHours = async (partialOpeningHoursUpdate) =>
    {
        if (this.state.buildingId === undefined)
        {
            return;
        }

        this.setState({ loading: true }, async () =>
        {
            let state = { loading: false };

            let properties = JSON.parse(JSON.stringify(this.state.properties));

            const { success, data } = await serverAPI.updatePartialBuildingHours({ _id: this.state.buildingId, partialOpeningHours: partialOpeningHoursUpdate });

            if (success === true)
            {
                if (data._id)
                {
                    partialOpeningHoursUpdate._id = data._id;
                }

                properties[this.state.propertyId].buildings[this.state.buildingId].partialOpeningHours = partialOpeningHoursUpdate;

                state.properties = properties;
                // need to use the unwrapped version here so we can actually await it.

                // update the Branding context state
                await this.context.updatePropertiesWithoutWrapper();
                this.context.handleRefreshMapData();
            }
            else
            {
                //TODO: on update fail
            }

            this.setState(state);
        });
    }

    /**
     * Updates or creates a property holiday.
     * @param {Object} holiday - holiday object to be saved in DB.
     * @param {Boolean} allBuildings - Whether or not to apply the update to all buildings belonging to the property.
     * @param {Boolean} remove - Whether or not to remove the holiday from DB.
     */
    updatePropertyHoliday = async (holiday, allBuildings = false, remove = false) =>
    {
        this.setState({ loading: true });

        const updateHoliday = {
            _id: this.state.propertyId,
            remove: remove,
            holidayHours: holiday
        };

        const success = await serverAPI.updatePropertyHoliday(updateHoliday, allBuildings);
        this.setState({ loading: false });

        return success;
    }

    /**
     * Updates or creates a building holiday.
     * @param {Object} holiday - holiday object to be saved in DB.
     * @param {Boolean} remove - Whether or not to remove the holiday from DB.
     */
    updateBuildingHoliday = async (holiday, remove = false) =>
    {
        this.setState({ loading: true });

        const updateHoliday = {
            _id: this.state.buildingId,
            remove: remove,
            holidayHours: holiday
        };

        const success = await serverAPI.updateBuildingHoliday(updateHoliday);

        this.setState({ loading: false });

        return success;
    }

    /**
     * Gets property holidays for the given year aswell as the following year.
     * @param {Number} year
     * @param {String} country
     */
    getPropertyHolidays = async (year, country) =>
    {
        this.setState({ loading: true });

        const [holidays, publicHolidays] =
            await Promise.all([
                serverAPI.getPropertyHolidays(this.state.propertyId, year),
                serverAPI.getPublicHolidays(year, country)
            ]);

        this.setState({ loading: false });

        return [holidays, publicHolidays];
    }

    /**
     * Gets building holidays for the given year aswell as the following year.
     * @param {Number} year
     * @param {String} country
     */
    getBuildingHolidays = async (year, country) =>
    {
        this.setState({ loading: true });

        const [holidays, publicHolidays] =
            await Promise.all([
                serverAPI.getBuildingHolidays(this.state.buildingId, year),
                serverAPI.getPublicHolidays(year, country)
            ]);

        this.setState({ loading: false });

        return [holidays, publicHolidays];
    }

    /**
     * Updates building floors information in DB.
     * @param {Array} floors - List of floor objects.
     */
    updateBuildingFloors = async (floors) =>
    {
        if (this.state.buildingId === undefined)
        {
            return;
        }

        this.setState({ loading: true });

        let { buildingId, propertyId, properties } = this.state;

        properties = JSON.parse(JSON.stringify(properties));
        properties[propertyId].buildings[buildingId].floors = floors;

        // filter only floors that were updated
        const updatedFloors = floors.filter((floor) => floor.updated);

        const updateFloorPromises = updatedFloors.map((floor) => serverAPI.updateFloor(floor, buildingId));

        try
        {
            await Promise.all(updateFloorPromises);

            // remove indicator that a floor was updated
            updatedFloors.forEach((floor) => delete floor.updated);

            // update the Branding context state
            await this.context.updatePropertiesNoWrap();
            this.context.handleRefreshMapData();
        }
        finally
        {
            this.setState({ loading: false, properties });
        }
    }

    handleUpdateOpeningHoursLinkedData = (value) =>
    {
        const id = this.state.buildingId || this.state.propertyId;
        serverAPI.handleUpdateOpeningHoursLinkedData(id, value);
    }

    handleGetOpeningHoursLinkedData = () =>
    {
        const id = this.state.buildingId || this.state.propertyId;

        try
        {
            const data = serverAPI.openingHoursLinkedData;
            return data && data[id];
        }
        catch
        {
            return false;
        }
    }

    /**
     * Adds or edits property in state from add property process.
     * @param {Object} property
     */
    handleAddProperty = (property) =>
    {
        let properties = { ...this.state.properties };

        // Update saved propertyId in webbrowser to new propertyId
        serverAPI.handleUpdateDashboardData("propertyId", property._id);

        // property.buildings = {};
        properties[property._id] = property;

        const sortedProperties = hashSort(properties, "_id", (p) => p.name?.[SINGLE_LANG_INPUT_CODE]?.toUpperCase());

        this.setState({ properties: sortedProperties, propertyId: property._id, buildingId: undefined });

        this.changeSelectedProperty(property._id);
    }

    /**
     * Adds or edits building in state from add property process.
     * @param {String} propertyId
     * @param {Object} building
     */
    handleAddBuilding = (propertyId, building) =>
    {
        let properties = { ...this.state.properties };
        let buildings = { ...properties[propertyId].buildings };

        buildings[building._id] = building;
        const sortedBuildings = hashSort(buildings, "_id", (b) => b?.longName?.toUpperCase?.());

        properties[propertyId].buildings = sortedBuildings;

        this.setState({ properties });

        this.changeSelectedBuilding(building._id, propertyId);
    }

    /**
     * When floors added from add property process, fetch property info again to get updated states and floors.
     * @param {String} propertyId
     * @param {String} buildingId
     */
    handleAddFloors = async (propertyId, buildingId) =>
    {
        let properties = { ...this.state.properties };

        // Update saved propertyId buildingId in webbrowser to new buildingId .
        serverAPI.handleUpdateDashboardData("propertyId", propertyId);
        serverAPI.handleUpdateDashboardData("buildingId", buildingId);

        const { success, data: property } = await serverAPI.getProperty(propertyId);

        if (success)
        {
            properties[propertyId] = property;
        }

        this.setState({ properties, propertyId, buildingId });
    }

    handleDeleteProperty = async (propertyId) =>
    {

        this.setState({ loading: true }, async () =>
        {
            const success = await serverAPI.deleteProperty(propertyId);
            let state = { loading: false };

            if (success)
            {
                let properties = { ...this.state.properties };

                // Remove from provider
                delete properties[propertyId];

                // Remove from selected if selected
                if (this.state.propertyId === propertyId)
                {
                    const propertyIds = Object.keys(properties);

                    if (propertyIds.length > 0)
                    {
                        this.changeSelectedProperty(propertyIds[0]);
                    }

                    // Note: dashboard page will handle route to add property on 0 properties.
                }

                state.properties = properties;
            }

            this.setState(state);
        });


    }

    handleDeleteBuilding = async (propertyId, buildingId) =>
    {
        this.setState({ loading: true }, async () =>
        {
            const success = await serverAPI.deleteBuilding(buildingId);
            let state = { loading: false };
            if (success)
            {
                let properties = { ...this.state.properties };
                let selectedProperty = { ...properties[propertyId] };
                let buildings = { ...selectedProperty.buildings };

                // Delete building from state
                delete buildings[buildingId];

                // Update Properties
                selectedProperty.buildings = buildings;
                properties[propertyId] = selectedProperty;

                // Remove from selected if selected
                if (this.state.buildingId === buildingId)
                {
                    this.changeSelectedBuilding(undefined);
                }

                state.properties = properties;
            }

            this.setState(state);
        });

    }

updateCoverImages = (data) => 
{
    let { buildingId } = this.state;
    if (buildingId) 
    {
        this.updateBuildingCoverImages(data);
    }
    else 
    {
        this.updatePropertyCoverImages(data);
    }
}

    updateIconImage = (data) => 
    {
        let { buildingId } = this.state;
        if (buildingId)
        {
            this.updateBuildingIconImage(data);
        }
        else 
        {
            this.updatePropertyIconImage(data);
        }
    }

    updateBuildingCoverImages = async ({ data }) => 
    {
        try 
        {
            this.setState({ loading: true });
            let { buildingId, propertyId, properties } = this.state;

            const { result } = await serverAPI.updateBuildingCoverImages(buildingId, data);

            this.props.queryClient.invalidateQueries("GET_PROPERTIES");

            await this.context.updatePropertiesNoWrap();
            this.context.handleRefreshMapData();
            this.setState({
                properties: {
                    ...properties,
                    [propertyId]: {
                        ...properties[propertyId],
                        buildings: {
                            ...properties[propertyId].buildings,
                            [buildingId]: {
                                ...properties[propertyId].buildings[buildingId],
                                ...result
                            }
                        }
                    }
                }
            });
        }
        catch (err) 
        {
            console.log("Error while updating building icon image", err);
            throw err;
        }
        finally 
        {
            this.setState({ loading: false });
        }
    }

    updatePropertyCoverImages = async ({ data }) => 
    {
        try 
        {
            this.setState({ loading: true });
            let { propertyId, properties } = this.state;
            const property = properties[propertyId];
            const { result } = await serverAPI.updatePropertyCoverImages(propertyId, data);

            this.props.queryClient.invalidateQueries("GET_PROPERTIES");

            await this.context.updatePropertiesWithoutWrapper();
            this.context.handleRefreshMapData();
            this.setState({ properties: { ...properties, [propertyId]: { ...property, ...result } } });
        }
        catch (err) 
        {
            console.log("Error while updating property icon image", err);
            throw err;
        }
        finally 
        {
            this.setState({ loading: false });
        }
    }

    updateBuildingIconImage = async ({ data }) =>
    {
        try 
        {
            this.setState({ loading: true });
            let { buildingId, propertyId, properties } = this.state;

            const { result } = await serverAPI.updateBuildingIconImage(buildingId, data);

            this.props.queryClient.invalidateQueries("GET_PROPERTIES");

            await this.context.updatePropertiesNoWrap();
            this.context.handleRefreshMapData();
            this.setState({
                properties: {
                    ...properties,
                    [propertyId]: {
                        ...properties[propertyId],
                        buildings: {
                            ...properties[propertyId].buildings,
                            [buildingId]: {
                                ...properties[propertyId].buildings[buildingId],
                                ...result
                            }
                        }
                    }
                }
            });
        }
        catch (err) 
        {
            console.log("Error while updating building icon image", err);
            throw err;
        }
        finally 
        {
            this.setState({ loading: false });
        }
    }

    updatePropertyIconImage = async ({ data }) =>
    {
        try 
        {
            this.setState({ loading: true });
            let { propertyId, properties } = this.state;
            const property = properties[propertyId];
            const { result } =  await serverAPI.updatePropertyIconImage(propertyId, data);

            this.props.queryClient.invalidateQueries("GET_PROPERTIES");

            await this.context.updatePropertiesWithoutWrapper();
            this.context.handleRefreshMapData();
            this.setState({ properties: { ...properties, [propertyId]: { ...property, ...result } } });
        }
        catch (err)
        {
            console.log("Error while updating property icon image", err);
            throw err;
        }
        finally
        {
            this.setState({ loading: false });
        }
    }

    render()
    {
        const value =
        {
            state: this.state,

            changeSelectedProperty: this.changeSelectedProperty,
            changeSelectedBuilding: this.changeSelectedBuilding,
            getPropertyHolidays: this.getPropertyHolidays,
            getWebAnalyticsData: this.getWebAnalyticsData,
            getBuildingHolidays: this.getBuildingHolidays,
            updateIconImage: this.updateIconImage,
            updateCoverImages: this.updateCoverImages,
            updateProperty: this.updateProperty,
            updatePropertyImages: this.updatePropertyImages,
            reorderPropertyCoverImages: this.reorderPropertyCoverImages,
            deletePropertyImage: this.deletePropertyImage,
            updateBuilding: this.updateBuilding,
            updateBuildingImages: this.updateBuildingImages,
            reorderBuildingCoverImages: this.reorderBuildingCoverImages,
            deleteBuildingImage: this.deleteBuildingImage,
            updatePropertyHours: this.updatePropertyHours,
            updateBuildingHours: this.updateBuildingHours,
            updatePropertyHoliday: this.updatePropertyHoliday,
            updateBuildingHoliday: this.updateBuildingHoliday,
            updateBuildingFloors: this.updateBuildingFloors,
            handleUpdateOpeningHoursLinkedData: this.handleUpdateOpeningHoursLinkedData,
            handleGetOpeningHoursLinkedData: this.handleGetOpeningHoursLinkedData,
            getPropertyMobileAnalytics: this.getPropertyMobileAnalytics,
            handleAddProperty: this.handleAddProperty,
            handleAddBuilding: this.handleAddBuilding,
            handleAddFloors: this.handleAddFloors,
            handleDeleteProperty: this.handleDeleteProperty,
            handleDeleteBuilding: this.handleDeleteBuilding
        };

        return (
            <div>
                {
                    (<DashboardContext.Provider value={value}>
                        <Loader active={this.state.loading} />
                        {this.props.children}
                    </DashboardContext.Provider>)
                }
            </div>

        );
    }
}

export default withQueryClient(withConfig(DashboardProvider));
