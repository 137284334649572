import * as yup from "yup";
import { DEFAULT_LANGUAGE_CODE as DEF_LANG } from "./constants";

const PHONE_NUMBER_REQUIRED = "validationSchemas.Valid_PhoneNumber_Required";
const WEBSITE_REQUIRED = "validationSchemas.Valid_Website_Required";
const EMAIL_REQUIRED = "validationSchemas.Valid_Email_Required";
const VALID_ADDRESS_REQUIRED = "validationSchemas.Valid_Address_Required";

export const propertySchema = yup.object().shape({
    name: yup.object({ [DEF_LANG]: yup.string().required("validationSchemas.Property_Name_Required") }).required(),
    fullAddress: yup.string().required(VALID_ADDRESS_REQUIRED),
    type: yup.number().positive("validationSchemas.Property_Type_Required").required("validationSchemas.Property_Type_Required"),
    website: yup.object({ [DEF_LANG]: yup.string().url("validationSchemas.Invalid_Website").required(WEBSITE_REQUIRED) }).required(WEBSITE_REQUIRED),
    phoneCheck: yup.boolean().oneOf([true, false], (PHONE_NUMBER_REQUIRED)).required(PHONE_NUMBER_REQUIRED),
});

export const buildingSchema = yup.object().shape({
    longName: yup.object({ [DEF_LANG]: yup.string().required("validationSchemas.BuildingName_Required") }).required(),
    fullAddress: yup.string().required(VALID_ADDRESS_REQUIRED),
    type: yup.number().positive("validationSchemas.Building_Type_Required").required("validationSchemas.Building_Type_Required"),
    website: yup.object({ [DEF_LANG]: yup.string().url().required(WEBSITE_REQUIRED) }).required(),
    phoneCheck: yup.boolean().oneOf([true, false], (PHONE_NUMBER_REQUIRED)).required(PHONE_NUMBER_REQUIRED),
});

export const userManualAddressSchema = yup.object().shape({
    street: yup.string().required("Street name required."),
    city: yup.string().required("City required."),
    country: yup.string().required("Country required.")
});

export const userManualAddressForBuildingSchema = yup.object().shape({
    street: yup.string().required("Street name required."),
});

export const nameCheck = yup.string().required("validationSchemas.Name_Required");
export const shortNameCheck = yup.string().optional();
export const phoneCheck = yup.string().required(PHONE_NUMBER_REQUIRED);
export const websiteCheck = yup.string().url(WEBSITE_REQUIRED).required(WEBSITE_REQUIRED);
export const websiteCheckOptional = yup.string().url().optional();
export const descriptionCheck = yup.string().optional();
export const emailCheck = yup.string().email(EMAIL_REQUIRED).optional();

export const detailSchema = yup.object().shape({
    name: nameCheck,
    shortName: shortNameCheck,
    phoneCheck: yup.boolean().oneOf([true, false], (PHONE_NUMBER_REQUIRED)).required(PHONE_NUMBER_REQUIRED), // use proper validation for phone check save
    website: websiteCheck,
    description: descriptionCheck,
    email: emailCheck,
});

export const entityLabelSchema = yup.object().shape({
    longName: yup.object({ [DEF_LANG]: yup.string().trim().required("validationSchemas.Long_name_required") }).required(),
    shortName: yup.object({ [DEF_LANG]: yup.string().trim().nullable() }).optional(),
    description: yup.object({ [DEF_LANG]: yup.string().nullable() }).optional(),
    phoneCheck: yup.boolean().oneOf([true, false], (PHONE_NUMBER_REQUIRED)).optional(),
    website: yup.object({ [DEF_LANG]: yup.string().url("validationSchemas.URL_is_invalid") }).optional(),
    category: yup.object().shape({
        _id: yup.string().nullable(),
        name: yup.string(),
    }).optional(),
    subCategories: yup.array().optional(),
    type: yup.string().optional(), //TODO CHANGE WHEN TYPE IS ADDED
    keywords: yup.object({ [DEF_LANG]: yup.array() }).optional(),
    onlineKeywords: yup.object({ [DEF_LANG]: yup.array() }).optional(),
    partialOpeningHours: yup.object().optional(),
    isHoursLinkedToRef: yup.bool().optional().default(true),
});

export const entityLabelPOISchema = yup.object().shape({
    longName: yup.object({ [DEF_LANG]: yup.string().trim().required("validationSchemas.Long_name_required") }).required(),
    shortName: yup.object({ [DEF_LANG]: yup.string().trim() }).optional(),
    description: yup.object({ [DEF_LANG]: yup.string() }).optional(),
    website: yup.object({ [DEF_LANG]: yup.string() }).optional(),
    keywords: yup.object({ [DEF_LANG]: yup.array() }).optional(),
    onlineKeywords: yup.object({ [DEF_LANG]: yup.array() }).optional(),
    category: yup.object().shape({
        _id: yup.string(),
        name: yup.string(),
    }).optional().nullable(),
    subCategories: yup.array().optional()
});

