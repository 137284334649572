import React, { FC } from "react";
import style from "./LoadingScreen.module.scss";
import loadingIcon from "./img/loading-m.gif";

interface LoadingScreenProps {
    active?: boolean;
}

const LoadingScreen: FC<LoadingScreenProps> = ({
    active = true
}) => (
    <div className={style.main} hidden={!active} >
        <img src={loadingIcon} alt="Loading..." />
    </div>
);

export default LoadingScreen;
