import React, { useEffect, useState } from "react";
import { Input } from "semantic-ui-react";
import { TextGroup } from "../../../common/TextGroup";
import MapOverlaysOpacitySelector from "../../mapOverlays/mapOverlaysOpacitySelector/MapOverlaysOpacitySelector";
import { PROPERTIES } from "../../../../_constants/config";
import { useTranslation } from "react-i18next";

const getPercentage = (value) => value || value === 0 ? Math.floor((value * 100).toFixed(2)) : undefined;

const getValueFromPercentage = (value) => value || value === 0 ? (parseFloat(value) / 100.0).toFixed(2) : undefined;

const DefaultOpacitySettings = ({ 
    fillColor, 
    borderColor,
    textColor,
    fillOpacity, 
    borderOpacity, 
    textOpactiy,
    updateAutomationRuleField, 
    disabled=false 
}) =>
{
    const [enteredFillOpacity, setEnteredFillOpacity] = useState(undefined);
    const [enteredBorderFillOpacity, setEnteredBorderFillOpacity] = useState(undefined);
    const [enteredTextOpacity, setEnteredTextOpacity] = useState(undefined);

    useEffect(() =>
    {
        setEnteredFillOpacity(undefined);
    }, [fillOpacity]);

    useEffect(() =>
    {
        setEnteredBorderFillOpacity(undefined);
    }, [borderOpacity]);

    useEffect(() =>
    {
        setEnteredTextOpacity(undefined);
    }, [textOpactiy]);

    const getValidOpacityValue = (value) =>
    {
        let validOpacityValue = value;
        if (value < 0)
        {
            validOpacityValue = 0; 
        }
        else if (value > 100)
        {
            validOpacityValue = 100;
        }
        return validOpacityValue;
    };

    let displayFillOpacity = getPercentage(fillOpacity);

    if (enteredFillOpacity || enteredFillOpacity === 0)
    {
        displayFillOpacity = enteredFillOpacity;
    }

    let displayBorderFillOpacity = getPercentage(borderOpacity);

    if (enteredBorderFillOpacity || enteredBorderFillOpacity === 0)
    {
        displayBorderFillOpacity = enteredBorderFillOpacity;
    }

    let displayTextOpacity = getPercentage(textOpactiy);

    if (enteredTextOpacity || enteredTextOpacity === 0)
    {
        displayTextOpacity = enteredTextOpacity;
    }

    const trans = useTranslation().t;

    return (
        <>
            {/* Fill settings */}

            <div className={"tgOverlayConfig"}>
                <p>{trans("OpacitySettings.Fill_Color")}</p>
            </div>
            <div className="overlayTgRow">

                <div className="overlayTgColumn colorOptions">
                    <TextGroup className="tgKiosk defaultInputLabel">
                        <Input 
                            className="colorPicker fillColorPicker" 
                            type="color" 
                            value={fillColor}
                            onChange={(event, { value }) => updateAutomationRuleField("fillColor", value)} />
                    </TextGroup>
                </div>

                <div className="overlayTgColumn defaultInput">
                    <div className="overlayTgRow defaultInputRow">
                        <div className="overlayTgColumn opacityInput">
                            <TextGroup className="tgOverlayConfig">
                                <Input
                                    type="text" 
                                    disabled={disabled}
                                    pattern="[0-9]*"
                                    maxLength={PROPERTIES.MAX_OPACITY_LENGTH}
                                    value={displayFillOpacity} 
                                    onChange={(event, { value }) => setEnteredFillOpacity(+value)}
                                    onKeyPress={(event) =>
                                    {
                                        if (event.key === "Enter" && (enteredFillOpacity || enteredFillOpacity === 0))
                                        {
                                            let validOpacityValue = getValidOpacityValue(enteredFillOpacity);
                                            setEnteredFillOpacity(undefined);
                                            updateAutomationRuleField("fillOpacity", getValueFromPercentage(validOpacityValue));
                                        } 
                                    }}
                                    onBlur={() =>
                                    {
                                        if (enteredFillOpacity || enteredFillOpacity === 0)
                                        {
                                            let validOpacityValue = getValidOpacityValue(enteredFillOpacity);
                                            setEnteredFillOpacity(undefined);
                                            updateAutomationRuleField("fillOpacity", getValueFromPercentage(validOpacityValue));
                                        } 
                                    }}
                                />
                            </TextGroup>
                        </div>
                        <div className="overlayTgColumn opacityInput percentSymbol">
                            <p className="defaultSettingsSymbol">%</p>
                        </div>
                    </div>
                </div>

                <div className="overlayTgColumn defaultOpacitySettings">
                    <MapOverlaysOpacitySelector 
                        backgroundColor={fillColor} 
                        opacity={fillOpacity}
                        disabled={disabled}
                        onOpacityChange={(opacity) => updateAutomationRuleField("fillOpacity", opacity)} />
                </div>
            </div>

            {/* Border settings */}
            <div className={"tgOverlayConfig"}>
                <p>{trans("OpacitySettings.Border_Color")}</p>
            </div>
            <div className="overlayTgRow">
                <div className="overlayTgColumn colorOptions">
                    <TextGroup className="tgKiosk defaultInputLabel">
                        <Input 
                            className="colorPicker borderColorPicker" 
                            type="color" 
                            value={borderColor}
                            onChange={(event, { value }) => updateAutomationRuleField("borderColor", value)} />
                    </TextGroup>
                </div>

                <div className="overlayTgColumn defaultInput">
                    <div className="overlayTgRow defaultInputRow">
                        <div className="overlayTgColumn opacityInput">
                            <TextGroup className="tgOverlayConfig">
                                <Input
                                    type="text"
                                    pattern="[0-9]*"
                                    disabled={disabled}
                                    value={displayBorderFillOpacity}
                                    onChange={(event, { value }) => setEnteredBorderFillOpacity(+value)}
                                    onKeyPress={(event) =>
                                    {
                                        if (event.key === "Enter" && (enteredBorderFillOpacity || enteredBorderFillOpacity === 0))
                                        {
                                            let validOpacityValue = getValidOpacityValue(enteredBorderFillOpacity);
                                            setEnteredBorderFillOpacity(undefined);
                                            updateAutomationRuleField("borderOpacity", getValueFromPercentage(validOpacityValue));
                                        } 
                                    }}
                                    onBlur={() =>
                                    {
                                        if (enteredBorderFillOpacity || enteredBorderFillOpacity === 0)
                                        {
                                            let validOpacityValue = getValidOpacityValue(enteredBorderFillOpacity);
                                            setEnteredBorderFillOpacity(undefined);
                                            updateAutomationRuleField("borderOpacity", getValueFromPercentage(validOpacityValue));
                                        }  
                                    }}
                                />
                            </TextGroup>
                        </div>
                        <div className="overlayTgColumn opacityInput percentSymbol">
                            <p className="defaultSettingsSymbol">%</p>
                        </div>
                    </div>
                </div>

                <div className="overlayTgColumn defaultOpacitySettings">
                    <MapOverlaysOpacitySelector 
                        backgroundColor={borderColor} 
                        opacity={borderOpacity}
                        disabled={disabled}
                        onOpacityChange={(opacity) => updateAutomationRuleField("borderOpacity", opacity)} />
                </div>
            </div>

            {/* Text settings */}
            <div className={"tgOverlayConfig"}>
                <p>{trans("OpacitySettings.Text_Color")} </p>
            </div>
            <div className="overlayTgRow">
                <div className="overlayTgColumn colorOptions">
                    <TextGroup className="tgKiosk defaultInputLabel">
                        <Input 
                            className="colorPicker textColorPicker" 
                            type="color" 
                            value={textColor}
                            onChange={(event, { value }) => updateAutomationRuleField("textColor", value)} />
                    </TextGroup>
                </div>

                <div className="overlayTgColumn defaultInput">
                    <div className="overlayTgRow defaultInputRow">
                        <div className="overlayTgColumn opacityInput">
                            <TextGroup className="tgOverlayConfig">
                                <Input
                                    type="text" 
                                    disabled={disabled}
                                    pattern="[0-9]*"
                                    maxLength={PROPERTIES.MAX_OPACITY_LENGTH}
                                    value={displayTextOpacity} 
                                    onChange={(event, { value }) => setEnteredTextOpacity(+value)}
                                    onKeyPress={(event) =>
                                    {
                                        if (event.key === "Enter" && (enteredFillOpacity || enteredFillOpacity === 0))
                                        {
                                            let validOpacityValue = getValidOpacityValue(enteredFillOpacity);
                                            setEnteredTextOpacity(undefined);
                                            updateAutomationRuleField("textOpacity", getValueFromPercentage(validOpacityValue));
                                        } 
                                    }}
                                    onBlur={() =>
                                    {
                                        if (enteredFillOpacity || enteredFillOpacity === 0)
                                        {
                                            let validOpacityValue = getValidOpacityValue(enteredFillOpacity);
                                            setEnteredTextOpacity(undefined);
                                            updateAutomationRuleField("textOpacity", getValueFromPercentage(validOpacityValue));
                                        } 
                                    }}
                                />
                            </TextGroup>
                        </div>
                        <div className="overlayTgColumn opacityInput percentSymbol">
                            <p className="defaultSettingsSymbol">%</p>
                        </div>
                    </div>
                </div>

                <div className="overlayTgColumn defaultOpacitySettings">
                    <MapOverlaysOpacitySelector 
                        backgroundColor={textColor} 
                        opacity={textOpactiy}
                        disabled={disabled}
                        onOpacityChange={(opacity) => updateAutomationRuleField("textOpacity", opacity)} />
                </div>
            </div>
        </>);
};

export default DefaultOpacitySettings;