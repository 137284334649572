import React from "react";
import { ModalBox } from "../../../common/ModalBox";
import { Button } from "semantic-ui-react";

const MultipleDeleteConfirmationModal = ({
    open,
    deleteList,
    onDeleteClick,
    onClose,
    trans
}) => (
    <ModalBox className="confirmActionModal multipleDeleteConfirmation"
        header={trans("Cancel_Confirmation_Modal.Confirm_Action")}
        open={open}
        onClose={onClose}
        actions={<Button
            color="orange"
            floated="right"
            content={trans("MapOverlaysActivitySideBar.Delete")}
            onClick={onDeleteClick}
        />}>
        <p className="p-modal">
            {trans("MapOverlaysActivitySideBar.Delete_Multiple_Confirmation_Message", 
                { 
                    mapOverlaysCount: `${deleteList.length} ${deleteList.length > 1 ? "map overlays" : "map overlay"}`, 
                })}
        </p>
    </ModalBox>
);

export default MultipleDeleteConfirmationModal;