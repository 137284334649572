import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "semantic-ui-react";
import { MAP_OVERLAY_ZOOM_CONSTANTS } from "../../../../_constants/constants";
import { ButtonIcon } from "../../../common/ButtonIcon";
import { CheckboxGroup } from "../../../common/CheckboxGroup";
import { TextGroup } from "../../../common/TextGroup";
import OpacitySettings from "./OpacitySettings";
import { DEFAULT_LANGUAGE_CODE } from "mapsted.maps/utils/map.constants";


const ZoomLevelSettings = ({ 
    color, 
    lineColor, 
    textColor,
    zoomLevelSettingsValue, 
    updateZoomLevelSettingsValue, 
    title, 
    mapSharingMode,
    onMapShareModeChange,
    onRemove,
    zoomId,
    displayLang=DEFAULT_LANGUAGE_CODE,
    enableOverrideText=true,
    selectedOverlayTemplateId,
    disabled
}) => 
{
    const [enteredZoomLevelValue, setEnteredZoomLevelValue] = useState(undefined);

    const trans = useTranslation().t;

    useEffect(() =>
    {
        setEnteredZoomLevelValue(undefined);
    }, [zoomLevelSettingsValue.value]);

    const handleKeyPress = (event) =>
    {
        if (event.key === "Enter" && (enteredZoomLevelValue || enteredZoomLevelValue === 0))
        {
            validateAndUpdateZoomLevel();
        }
    };

    const validateAndUpdateZoomLevel = () =>
    {
        let validatedZoomLevelValue = enteredZoomLevelValue;
        if (enteredZoomLevelValue < MAP_OVERLAY_ZOOM_CONSTANTS.MIN_ZOOM)
        {
            validatedZoomLevelValue = MAP_OVERLAY_ZOOM_CONSTANTS.MIN_ZOOM;
        }
        else if (enteredZoomLevelValue > MAP_OVERLAY_ZOOM_CONSTANTS.MAX_ZOOM)
        {
            validatedZoomLevelValue = MAP_OVERLAY_ZOOM_CONSTANTS.MAX_ZOOM;
        }

        setEnteredZoomLevelValue(undefined);
        updateZoomLevelSettingsValue(zoomId, "value", validatedZoomLevelValue);
    };
    
    let displayZoomLevelValue = zoomLevelSettingsValue.value;

    if (enteredZoomLevelValue || enteredZoomLevelValue === 0)
    {
        displayZoomLevelValue = enteredZoomLevelValue;
    }

    const disable = zoomId !== mapSharingMode || disabled;

    return (
        <>

            <div className={"overlayTgRow"}>
                <p>{title}</p>
            </div>
            <div className="overlayTgRow">
                <div className="overlayTgColumn zoomLevelSettingsColumn">
                    <TextGroup className="zoomSettings-label">
                        <Input 
                            className="zoomLevelSettings-input"
                            type="number"
                            step=".01"
                            onKeyPress={handleKeyPress}
                            value={displayZoomLevelValue?.toString()}
                            onChange={(event, { value }) => setEnteredZoomLevelValue(+value)}
                            disabled={disable || !!selectedOverlayTemplateId}
                            onBlur={() =>
                            {
                                if (enteredZoomLevelValue || enteredZoomLevelValue === 0)
                                {
                                    validateAndUpdateZoomLevel();
                                }
                            }}
                        />
                    </TextGroup>
                </div>
                <div className="overlayTgColumn opacityRangeContainer">
                    <Input 
                        className="opacityRange"
                        type="range" 
                        min={MAP_OVERLAY_ZOOM_CONSTANTS.MIN_ZOOM} 
                        max={MAP_OVERLAY_ZOOM_CONSTANTS.MAX_ZOOM} 
                        value={zoomLevelSettingsValue.value}
                        step=".01"
                        disabled={disable || !!selectedOverlayTemplateId}
                        onChange={(event, { value }) => updateZoomLevelSettingsValue(zoomId, "value", +value)} />
                    <CheckboxGroup
                        radio
                        className="zoomLevelLock" 
                        checked={!disable} 
                        onChange={() => onMapShareModeChange(zoomId)}/>

                    {onRemove && <ButtonIcon 
                        icon="delete" 
                        onClick={onRemove} 
                        disabled={zoomId === mapSharingMode || !!selectedOverlayTemplateId}
                    />}
                </div>
            </div>

            <OpacitySettings 
                color={color}
                lineColor={lineColor}
                fillOpacity={zoomLevelSettingsValue.fillOpacity}
                borderFillOpacity={zoomLevelSettingsValue.borderFillOpacity}
                textOpactiy={zoomLevelSettingsValue.textOpacity}
                textColor={textColor}
                updateOpacitySettings={(fieldName, opacity) => updateZoomLevelSettingsValue(zoomId, fieldName, opacity) }
                disabled={disable || !!selectedOverlayTemplateId} />
            

            {
                enableOverrideText && <>
                    <div className="overrideGlobalTextLabel">
                        <CheckboxGroup
                            disabled={disable && !selectedOverlayTemplateId}
                            className="dynamicSelectBox ovrrideGlobalTextLabel" 
                            label={trans("ZoomLevelSettings.Override_Global_Text_Label")}
                            checked={zoomLevelSettingsValue.overrideGlobalTextLabel}
                            onChange={(event, { checked }) => updateZoomLevelSettingsValue(zoomId, "overrideGlobalTextLabel", checked)} />
                    </div>

                    {zoomLevelSettingsValue.overrideGlobalTextLabel
                && <TextGroup className="tgOverlayConfig" title={trans("ZoomLevelSettings.Text_Label")}>
                    <Input
                        disabled={disable && !selectedOverlayTemplateId}
                        value={zoomLevelSettingsValue.textLabel?.[displayLang] || ""}
                        placeholder={trans("ZoomLevelSettings.Enter_Text_Label")}
                        onChange={(event, { value }) => updateZoomLevelSettingsValue(zoomId, "textLabel", {
                            ...zoomLevelSettingsValue.textLabel,
                            [displayLang]: value
                        })} />
                </TextGroup>}
                </>
            }
            
        </>);
};

export default ZoomLevelSettings;