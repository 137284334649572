import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";
import { deepValue } from "mapsted.utils/objects";
import { ModalLayout } from "./ModalLayout";

export const ErrorModal = ({
    open = false,
    error = {},
    backButtonContent = undefined,
    continueButtonContent = undefined,
    onBack,
    onContinue
}) =>
{
    const trans = useTranslation().t;

    const [content, setContent] = React.useState({ heading: "", body: "" });
    const [errorNum, setErrorNum] = React.useState(0);
    const [errorNames, setErrorNames] = React.useState([]);

    // On open set error names and content
    React.useEffect(() =>
    {
        const names = Object.keys(error);

        if (open)
        {
            setContent(error[names[0]]);
            setErrorNum(0);
            setErrorNames(names);
        }

    }, [open, error]);

    /**
     * If there are more errors continue to display the next error.
     * Else continue.
     */
    const handleOnContinue = React.useCallback(() =>
    {
        const errorNumUpdate = errorNum + 1;

        if (errorNumUpdate === errorNames.length)
        {
            if (onContinue)
            {
                onContinue();
            }
        }
        else
        {
            setContent(error[errorNames[errorNumUpdate]]);
            setErrorNum(errorNumUpdate);
        }
    }, [errorNum, errorNames, onContinue, error]);

    const defaultBack = trans("ErrorModal.Back");
    const defaultContinue = trans("ErrorModal.Continue");
    return (
        <ModalLayout
            open={open}
            size="mini"
            className="missing-floor"
            heading={deepValue(content, "heading", "")}
            onClose={onBack}
            actions={
                <>
                    <Button color="grey" content={backButtonContent || defaultBack} onClick={onBack} />
                    <Button color="orange" content={continueButtonContent || defaultContinue} onClick={handleOnContinue} />
                </>
            }>
            <p className="p-modal">{content.body || ""}.<br />
                {trans("ErrorModal.Are_you_sure_you_want_to_continue?")}</p>
        </ModalLayout>
    );
};
