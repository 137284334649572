import React from "react";
import { Dropdown } from "semantic-ui-react";
import "./DropdownForm.css";

export const DropdownForm = ({ className, hasCaret, showCustomIcon = true, ...rest }) => (
    <Dropdown
        className={`dropdownForm${hasCaret ? " hasCaret" : ""}${className ? " " + className : ""}`}
        {...(showCustomIcon ? {
            icon: <img src="/img/icon-chevron-down.svg" alt="expand" />
        } : {})}
        {...rest}
    />
);
