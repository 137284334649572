import React from "react";
import { useDrag, useDrop } from "react-dnd";
import { Tab, Button, Segment, Header, Input, Popup } from "semantic-ui-react";
import PropTypes from "prop-types";

import { ModalLayout } from "./ModalLayout";
import { CropImageModal } from "./CropImageModal";
import { handleUploadFile, getBase64FromImageUrl, getFileSizeFromBase64, imageUrl } from "../../_utils/utils";
import { ErrorLabel } from "../elements/ErrorLabel";
import { GallerySlider } from "../branding/gallerySlider/GallerySlider";
import { LoadableImage } from "../elements/LoadableImage";
import { DraggableItemTypes, ERROR_MESSAGES } from "../../_constants/constants";
// import { Divide } from "../common/Divide";

import "./UploadModal.css";
import { MAX_GALLERY_LENGTH } from "../../_constants/config";
import { Trans, useTranslation, withTranslation } from "react-i18next";
// import LinkButton from "../common/LinkButton";
import { withConfig } from "../../_utils/hoc";
import ImageSelectionModal from "../branding/ImageSelectionModal/ImageSelectionModal";
import ImagePicker from "../branding/ImagePicker/ImagePicker";
import EmptyGallery from "../common/EmptyGallery";
import { Gms } from "../branding/Gms/Gms";
import { DEFAULT_LANGUAGE_CODE } from "mapsted.maps/utils/map.constants";
// import { doArraysHaveSameItemsInDifferentOrder } from "mapsted.utils/arrays";

const TabIndexes = {
    Upload: 0,
    WebAddress: 1,
    Gallery: 2
};

class UploadImageModal extends React.Component
{
    constructor(props)
    {
        super(props);
        this.gmsRef = React.createRef();
        this.state = {
            undoImage: undefined,
            image: undefined,
            fileName: undefined,
            open: false,
            cropImageModalOpen: false,
            activeTab: 0,
            isError: false,
            galleryList: props.galleryList ? [...props.galleryList] : [],
            editedFileId: undefined,
            ignoreGalleryFull: false,
            showImageSelectionModal: false
        };
      
    }

    componentDidUpdate()
    {
        if (this.props.galleryList !== this.state.galleryList)
        {
            this.setState({ galleryList: this.props.galleryList });
        }

        if (this.props.open !== this.state.open) 
        {
            this.setState({ open: this.props.open });

            if (this.props.open) 
            {
                this.handleOnOpen();
            }
        }
    }

    static getDerivedStateFromProps = (props, state) =>
    {
        let stateUpdated = false;
        // if (props.galleryList !== state.propGalleryList)
        // {
        //     state.propGalleryList = props.galleryList;
        //     state.galleryList = (props.galleryList) ? [...props.galleryList] : undefined;
        //     stateUpdated = true;
        // }

        return (stateUpdated) ? state : null;
    }


    handleOnChange = (name, value) =>
    {
        this.setState({ [name]: value });

        if (name === "image")
        {
            let undoImage;

            // This method gets called on initial image upload so we have to check that the undo image is not the same as the new image.
            if (this.state.image !== value)
            {
                undoImage = this.state.image;
            }

            this.setState({ [name]: value, undoImage });
            this.handleOnCropModelOpen();
        }
        else
        {
            this.setState({ [name]: value });
        }

    }

    handleOnEdit = (fileId) =>
    {
        this.setState({ editedFileId: fileId });
    }

    handleOnClose = () =>
    {
        this.setState({
            open: false,
            cropImageModalOpen: false,
            isError: false
        });
        if (this.props.onClose)
        {
            this.props.onClose();
        }
    }

    handleOnOpen = () =>
    {
        const { gallery, fileId } = this.props;

        this.setState({ open: true, undoImage: undefined, image: undefined, fileName: undefined, cropImageModalOpen: false, editedFileId: undefined, ignoreGalleryFull: false }, () =>
        {
            if (gallery)
            {
                this.handleUpdateActiveTab(TabIndexes.Gallery);
            }
            else if (fileId)
            {
                this.handleOnCropModelOpen();
            }
        });
    }

    handleUpdateActiveTab = (activeTab) => this.setState({ activeTab })

    handleTabChange = (e, { activeIndex }) => this.setState({ activeTab: activeIndex })

    handleOnCropModelOpen = () =>
    {
        /** @type {typeof import("../../_intl/resources.json")["en"]["translation"]["UploadImageModal"]} */
        const trans = this.props.t("UploadImageModal");

        const { fileId, logo } = this.props;
        const { image } = this.state;
        const src = fileId && imageUrl(fileId);

        if (!image && src)
        {
            if (src.indexOf("/") === 0)
            {
                let fileName = src.split("/");
                fileName = fileName[fileName.length - 1];

                this.setState({ fileName });

                getBase64FromImageUrl(src, (image) => this.setState({ image }));
            }
            else
            {
                this.setState({
                    image: src,
                    fileName: logo ? trans["logo"] : trans["cover"]
                });
            }
        }

        this.setState({ cropImageModalOpen: true, ignoreGalleryFull: false });
    }

    handleOnCropModalBack = () =>
    {
        this.setState({ cropImageModalOpen: false, image: undefined, undoImage: undefined, editedFileId: undefined, isError: false });
    }


    handleOnCropModalClose = () =>
    {
        const { gallery } = this.props;

        if (gallery)
        {
            this.setState({ cropImageModalOpen: false, activeTab: TabIndexes.Gallery, image: undefined, undoImage: undefined, editedFileId: undefined, isError: false });
        }
        else
        {
            this.handleOnClose();
        }
    }

    handleUndoImage = () =>
    {
        const image = this.state.undoImage;

        this.setState({ image, undoImage: undefined });

        this.handleOnCropModelOpen();
    }

    handleMoveGalleryItem = (dragIndex, hoverIndex) =>
    {
        let galleryList = [...this.state.galleryList];

        const draggedImage = galleryList[dragIndex];

        galleryList.splice(dragIndex, 1);
        galleryList.splice(hoverIndex, 0, draggedImage);

        this.setState({ galleryList }, () => this.props.onGalleryReordering({
            coverImages: {
                [DEFAULT_LANGUAGE_CODE]: galleryList
            }
        }));
    }

    handleOnDelete = (fileId) =>
    {
        const { gallery, onDelete } = this.props;
        const deleteCallback = fileId ? () => onDelete(fileId) : () => null;
        if (!gallery)
        {
            this.setState({ image: undefined, fileName: undefined, open: false, cropImageModalOpen: false, editedFileId: undefined }, deleteCallback);
        }
        else
        {
            this.setState({ image: undefined, fileName: undefined, activeTab: TabIndexes.Gallery, cropImageModalOpen: false, editedFileId: undefined }, deleteCallback);
        }
    }


    handleUploadNew = () =>
    {
        const { gallery, galleryList } = this.props;
        // If gallery is full but if we still need to enable image upload to overwrite existing image, ignoreGalleryFull is set as true
        const ignoreGalleryFull = gallery && Array.isArray(galleryList) && galleryList.length >= MAX_GALLERY_LENGTH;
        this.setState({ cropImageModalOpen: false, activeTab: TabIndexes.Upload, ignoreGalleryFull });
    }

    handleOnSave = (filerIds) =>
    {
        const { gallery, onSave } = this.props;
        const galleryList = this.state.galleryList;
        const newGalleryList = [...galleryList, ...filerIds].filter((filerId) => filerId && typeof filerId === "string");

        onSave({
            coverImages: {
                [DEFAULT_LANGUAGE_CODE]: newGalleryList
            }
        });

        if (gallery)
        {
            this.setState({ image: undefined, activeTab: TabIndexes.Gallery, cropImageModalOpen: false, editedFileId: undefined, isError: false });
        }
        else
        {
            this.setState({ open: false, cropImageModalOpen: false, editedFileId: undefined , isError: false });
        }
    }

    renderTrigger = () =>
    {
        const { fileId, logo, gallery, galleryList, defaultSrc, defaultClass, trigger } = this.props;

        if (trigger)
        {
            return (
                <div onClick={this.handleOnOpen}>{trigger}</div>
            );
        }

        if (gallery && Array.isArray(galleryList) && galleryList.length > 0)
        {
            return (
                <GallerySlider galleryList={galleryList} onClick={this.handleOnOpen} />
            );
        }
        else
        {
            const imageSrc = fileId && imageUrl(fileId);

            let className = (logo) ? "display-image " : "cover-image ";

            if (!imageSrc && defaultClass)
            {
                className += defaultClass;
            }

            return (
                <LoadableImage
                    as="span"
                    className={className}
                    src={imageSrc}
                    defaultSrc={defaultSrc}
                    alt=""
                    onClick={this.handleOnOpen}
                />
            );
        }
    }

    handleImageSelectionModalOpen()
    {        
        this.setState({ showImageSelectionModal: true, isError: false });
    }

    handleUnlinkImage(filerIdToRemove)
    {
        const newGalleryList = this.state.galleryList.filter((filerId) => filerId !== filerIdToRemove);
        this.props.onSave({
            coverImages: {
                [DEFAULT_LANGUAGE_CODE]: newGalleryList
            }
        });
    }

    handleSelectionModalClose()
    {
        this.setState({ showImageSelectionModal: false });
    }

    renderCropModalTrigger = (activeTab, image) =>
    {
        /** @type {typeof import("../../_intl/resources.json")["en"]["translation"]["UploadImageModal"]} */
        const trans = this.props.t("UploadImageModal");

        if (activeTab === TabIndexes.Gallery)
        {
            return (<div></div>);
        }

        return (
            <Button color="orange" disabled={!image} content={trans["next"]} onClick={this.handleOnCropModelOpen} />
        );
    }

    render()
    {
        const { image, undoImage, fileName, open, cropImageModalOpen, activeTab, editedFileId, galleryList: galleryListState, isError } = this.state;
        const { logo, heading, description, aspectRatio, fileId ,onOpen, buildingId, propertyId, ...props } = this.props;
        const galleryList = galleryListState.filter((filerId) => filerId && typeof filerId === "string") || [];
        const isGalleryFull = this.props.gallery && Array.isArray(galleryList) && galleryList.length >= MAX_GALLERY_LENGTH;
        const galleryProps={
            ...props,
            image,
            fileName,
            galleryList,
            onMoveGaleryItem: this.handleMoveGalleryItem,
            handleUnlinkImage: this.handleUnlinkImage.bind(this),
            onEdit: this.handleOnEdit,
            onChange: this.handleOnChange,
            onUpdateActiveIndex: this.handleUpdateActiveTab,
            ignoreGalleryFull: this.state.ignoreGalleryFull,
            handleImageSelectionModalOpen: this.handleImageSelectionModalOpen.bind(this)
        };

        return (
            <ModalLayout
                open={open}
                onOpen={onOpen}
                topAligned={true}
                onClose={this.handleOnClose}
                trigger={this.renderTrigger()}
                className={`upload-image-modal${logo ? " upload-logo-modal" : ""}`}
                heading={heading}
            >
                <div>
                    <p className="p-modal" style={{
                        marginBottom: "15px"
                    }}>{description}</p>

                    <Trans
                        i18nKey="UploadImageModal.upload_new"
                        components={{
                            linkNew: <Button
                                className="image-upload-btn"
                                {...(isGalleryFull && { disabled: true ,style: { color: "#d3d2de" } })}
                                onClick={() => this.gmsRef.current.linkCoverImages({ addedImageFilerIds: galleryList })}
                            />,
                        }}
                    />
                </div>
                
                <GalleryTab {...galleryProps} />
                <Gms ref={this.gmsRef} onAddImage={this.handleOnSave.bind(this)} />              
                {isError && <ErrorLabel label={ERROR_MESSAGES.uploadImageModal.deleteImageError} />}

            </ModalLayout>
        );
    }
}

export default withTranslation()(UploadImageModal);

UploadImageModal.propTypes = {
    open: PropTypes.bool,
    logo: PropTypes.bool,
    gallery: PropTypes.bool,
    galleryList: PropTypes.arrayOf(PropTypes.string),
    trigger: PropTypes.node,
    heading: PropTypes.string.isRequired,
    // fileId: PropTypes.string,
    defaultSrc: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    aspectRatio: PropTypes.number.isRequired,
    acceptableImageFileFormats: PropTypes.string.isRequired,
    defaultClass: PropTypes.string,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onGalleryReordering: PropTypes.func,
    onOpen: PropTypes.func,
    onClose: PropTypes.func
};


const panes = (props) =>
{
    /** @type {typeof import("../../../_intl/resources.json")["en"]["translation"]["UploadImageModal"]} */
    const trans = props.t("UploadImageModal");

    const arrayOfPanes = [
        { menuItem: trans["upload_title"], render: () => <UploadTab {...props} /> },
        { menuItem: trans["web_title"], render: () => <WebAddressTab {...props} /> },
    ];
  
    if (props.gallery)
    {
        arrayOfPanes.push({ menuItem: trans["gallery_title"], render: () => <GalleryTab {...props} /> });
    }

    return arrayOfPanes;
};


class UploadTabImpl extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            uploadError: false,
            sizeError: false,
        };

        this.fileRef = React.createRef();
    }

    handleUpdateImage = (image) =>
    {
        this.props.onChange("image", image);
    }


    handleUpdateFile = (file) =>
    {
        this.props.onChange("fileName", file.name);
        this.fileRef.current.value = "";
    }

    handleFileChange = () =>
    {
        this.validateAndUploadFiles(this.fileRef.current.files);
    }

    handleOnBrowseClick = () =>
    {
        this.fileRef.current.click();
    }

    handleDrop = (e) =>
    {
        const { gallery, galleryList, ignoreGalleryFull } = this.props;
        const isGalleryFull = gallery && Array.isArray(galleryList) && galleryList.length >= MAX_GALLERY_LENGTH;

        e.preventDefault();

        if (isGalleryFull && !ignoreGalleryFull)
        {
            return;
        }

        this.validateAndUploadFiles(e.dataTransfer.files);
    }

    /**
     * Checks if uploadFiles are a file type from the acceptable file types.
     * If they are it continues to upload the files.
     * Else it sets error state to true.
     * @param {} uploadFiles
     */
    validateAndUploadFiles = (uploadFiles) =>
    {
        const fileFormats = this.props.acceptableImageFileFormats.split(",");
        const uploadFilesArray = Array.from(uploadFiles);


        // Validate files based on file type and file size
        let files = uploadFilesArray.filter((file) => ((fileFormats.includes(file.type)) || fileFormats.includes(this.getFileExtention(file.name))) && (file.size <= this.props.config.MAX_FILE_SIZE_BYTES));

        if (files.length > 0)
        {
            if (this.props.returnRawFile)
            {
                this.props.onChange(files);
            }
            else
            {
                handleUploadFile({ files }, this.handleUpdateImage, this.handleUpdateFile);
            }
            this.setState({ uploadError: false, sizeError: false });
        }
        else
        {
            // Check for error to display.
            const uploadError = uploadFilesArray.some((file) => !fileFormats.includes(file.type));
            const sizeError = uploadFilesArray.some((file) => file.size >= this.props.config.MAX_FILE_SIZE_BYTES);
            this.setState({ uploadError, sizeError });
        }
    }

    handleDragOver = (e) => e.preventDefault();

    getFileExtention = (fileName) => fileName.substring(fileName.lastIndexOf("."));

    render()
    {
        /** @type {typeof import("../../../_intl/resources.json")["en"]["translation"]["UploadImageModal"]} */
        const trans = this.props.t("UploadImageModal");

        const { gallery, galleryList, acceptableImageFileFormats, ignoreGalleryFull } = this.props;
        const { uploadError, sizeError } = this.state;
        const isGalleryFull = gallery && Array.isArray(galleryList) && galleryList.length >= MAX_GALLERY_LENGTH;

        return (
            <Tab.Pane>
                <Segment placeholder className="brand-upload" disabled={isGalleryFull && !ignoreGalleryFull} onDrop={this.handleDrop} onDragOver={this.handleDragOver} >
                    <Header icon content={trans["drag_n_drop"]} subheader={trans["or"]}>
                    </Header>

                    <Button basic primary size="large" content={trans["browse"]} disabled={isGalleryFull} onClick={this.handleOnBrowseClick} />

                    <input type="file" ref={this.fileRef} className="hidden"
                        accept={acceptableImageFileFormats} onChange={this.handleFileChange} />
                </Segment>

                <ErrorRenderer
                    uploadError={uploadError}
                    sizeError={sizeError}
                    isGalleryFull={isGalleryFull}
                    ignoreGalleryFull={ignoreGalleryFull}
                    errorMessages={ERROR_MESSAGES}
                    invalidFileTypeErrorMessage={this.props.invalidFileTypeErrorMessage}
                />
            </Tab.Pane>
        );
    }
}

const ErrorRenderer = ({ uploadError, sizeError, isGalleryFull, ignoreGalleryFull, errorMessages, invalidFileTypeErrorMessage }) => 
{

    if (uploadError) 
    {
        return <ErrorLabel label={invalidFileTypeErrorMessage ? invalidFileTypeErrorMessage : errorMessages.uploadImageModal.invalidFileType} />;
    }

    if (sizeError) 
    {
        return <ErrorLabel label={errorMessages.uploadImageModal.fileIsTooBig} />;
    }

    if (isGalleryFull && !ignoreGalleryFull) 
    {
        return <ErrorLabel label={errorMessages.uploadImageModal.fullGallery.header} content={errorMessages.uploadImageModal.fullGallery.message} />;
    }

    return null;
};

UploadTabImpl.propTypes = {
    acceptableImageFileFormats: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    returnRawFile: PropTypes.bool,
    invalidFileTypeErrorMessage: PropTypes.string
};

UploadTabImpl.defaultProps = {
    returnRawFile: false
};

export const UploadTab = withTranslation()(withConfig(UploadTabImpl));

class WebAddressTabImpl extends React.Component
{
    state = {
        url: "",
        sizeError: false,
        uploadError: false
    }

    handleChange = (e, { name, value }) =>
    {
        this.setState({ [name]: value });
    }

    handleLoadImage = async () =>
    {
        const { url: link } = this.state;

        // Reset errors
        this.setState({
            sizeError: false,
            uploadError: false
        });

        /**
         * If url link is empty do not proceed.
         * But if just white spaces are entered procced as usual.
         */
        if (!link)
        {
            return;
        }

        // File type validation from url link
        if (this.props.acceptableImageFileFormats)
        {
            const isValidFileExtention = await this.checkIfValidFileFormat(link);
            if (!isValidFileExtention)
            {
                this.setState({ uploadError: true });
                return;
            }
            else
            {
                this.setState({ uploadError: false });
            }
        }

        // Retrieve image using url and perform size validation
        let url = "/api/internal/public/images/url?link=" + link;
        getBase64FromImageUrl(url, (image) =>
        {
            const fileSize = getFileSizeFromBase64(image);

            if (fileSize <= this.props.config.MAX_FILE_SIZE_BYTES)
            {
                this.props.onChange("image", image);
                this.props.onChange("fileName", link);
            }
            else
            {
                this.setState({ sizeError: true });
            }
        }, () =>
        {
            this.setState({ uploadError: true });
        });
    }

    /**
     * Used to check if content-type of image link is one of acceptable logo formats
     * In case content-type could not be determined this check will still pass
     * If the image link is an invalid url then this check will fail
     * @param url
     * @returns
     */
    async checkIfValidFileFormat(url)
    {
        let isValidFileFormat = true;

        const response = await fetch("/api/internal/public/images/responseHeaders?link=" + url);
        const data = await response.json();

        if (data?.error)
        {
            isValidFileFormat = false;
        }
        else if (data?.headers?.["content-type"])
        {
            const imageType = data.headers["content-type"];
            if (!this.props.acceptableImageFileFormats.split(",").includes(imageType))
            {
                isValidFileFormat = false;
            }
        }

        return isValidFileFormat;
    }

    render()
    {
        const { gallery, galleryList, t: trans, ignoreGalleryFull } = this.props;
        const { url, sizeError, uploadError } = this.state;

        const isGalleryFull = gallery && Array.isArray(galleryList) && galleryList.length >= MAX_GALLERY_LENGTH;

        return (
            <Tab.Pane>
                <p className="para">{trans("UploadImageModal.Paste_your_image_URL_in_the_text_field_b")}</p>
                <Input fluid name="url" className="upload-url" placeholder={trans("UploadImageModal.Paste_link_here___")} disabled={isGalleryFull && !ignoreGalleryFull}
                    autoComplete="off" autoCorrect="off" autoCapitalize="none" spellCheck={true}
                    value={url} onChange={this.handleChange} onBlur={this.handleLoadImage} />
                {
                    (uploadError) && (
                        <ErrorLabel label={ERROR_MESSAGES.uploadImageModal.invalidFileType} />
                    )
                }
                {
                    (sizeError) && (
                        <ErrorLabel label={ERROR_MESSAGES.uploadImageModal.fileIsTooBig} />
                    )
                }
                {
                    (isGalleryFull && !ignoreGalleryFull) && (
                        <ErrorLabel label={ERROR_MESSAGES.uploadImageModal.fullGallery.header} content={ERROR_MESSAGES.uploadImageModal.fullGallery.message} />
                    )
                }
            </Tab.Pane>
        );
    }
}

const WebAddressTab = withTranslation()(withConfig(WebAddressTabImpl));

WebAddressTab.propTypes = {
    onChange: PropTypes.func.isRequired,
    acceptableImageFileFormats: PropTypes.string
};

class GalleryTabImpl extends React.Component
{
    handleOnEdit = (fileId) =>
    {
        let url = imageUrl(fileId);

        getBase64FromImageUrl(url, (image) =>
        {
            this.props.onChange("image", image);
            this.props.onChange("fileName", fileId);
            this.props.onEdit(fileId);
        });
    }

    render()
    {
        let { galleryList, handleImageSelectionModalOpen } = this.props;
        // const templateGalleryList = [...Array(MAX_GALLERY_LENGTH - galleryList.length)]
        if (galleryList.length === 0)
        {
            return <EmptyGallery onClick={handleImageSelectionModalOpen}></EmptyGallery>;
        }

        return (
            <>
                {
                    <>                       
                        <div className="galleryGroup">
                            {
                                galleryList.map((fileId, index) => <GalleryThumb key={fileId} isTemplate={!fileId} index={index} fileId={fileId}
                                    onDelete={this.props.handleUnlinkImage} onEdit={this.handleOnEdit}
                                    onMoveGaleryItem={this.props.onMoveGaleryItem}
                                />
                                )
                            }
                            {/* {
                                templateGalleryList.map((_)=>{
                                    return <div className="galleryThumbTemplate"></div>
                                })
                            } */}
                        </div>
                    </>
                }
            </>
        );
    }
}

const GalleryTab = withTranslation()(GalleryTabImpl);

GalleryTab.propTypes = {
    galleryList: PropTypes.arrayOf(PropTypes.string).isRequired,
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onMoveGaleryItem: PropTypes.func.isRequired,
};

// const EmptyGallery = ({ handleImageSelectionModalOpen }) =>
// {
//     const trans = useTranslation().t;
//     return (
//         <div className="emptyGallery">
//             <b>{trans("UploadImageModal.No_Items")}</b>
//             <p>
//                 <Trans
//                     i18nKey="UploadImageModal.empty gallery desc"
//                     components={{
//                         linkNew: <LinkButton onClick={handleImageSelectionModalOpen} />,
//                     }}
//                 />
//             </p>
//         </div>
//     );
// };

const GalleryThumb = ({ index, fileId, onDelete, onEdit, onMoveGaleryItem }) =>
{
    const ref = React.useRef(null);

    const handleOnDelete = React.useCallback(() =>
    {
        onDelete(fileId);
    }, [fileId, onDelete]);

    const handleOnEdit = React.useCallback(() =>
    {
        onEdit(fileId);
    }, [fileId, onEdit]);

    // This implementation is standard from ReactDbD sorting example
    const [{ canDrop }, drop] = useDrop({
        accept: DraggableItemTypes.GALLERY_THUMB_ROW,
        collect: (monitor) => ({
            canDrop: monitor.canDrop(),
        }),
        hover(item, monitor)
        {
            if (!ref.current)
            {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex)
            {
                return;
            }
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY)
            {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)
            {
                return;
            }
            onMoveGaleryItem(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    // This implementation is standard from ReactDbD sorting example
    const [{ isDragging }, drag] = useDrag({
        item: { type: DraggableItemTypes.GALLERY_THUMB_ROW, fileId, index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const opacity = isDragging ? 0 : 1;
    const className = canDrop ? " galleryThumbDrag" : "";


    drag(drop(ref));

    const trans = useTranslation().t;

    return (
        <div ref={ref} className={`galleryThumb${className}`} style={{ opacity }}>
            <div className="thumb">
                <LoadableImage as="img" src={fileId ? imageUrl(fileId): fileId} 
                    alt={`${fileId ? `${trans("UploadImageModal.Gallery_image")}${index + 1}`  :""}`}
                />
            </div>
            <div className="actionsThumb">
                <Popup
                    trigger={<Button onClick={(e) => 
                    {
                        handleOnDelete();
                    }} className="buttonIcon" content={<>
                        <img src={"/img/icon-unlink-img.svg"} alt="" />
                        <span>{trans("UploadImageModal.Unlink")}</span>
                    </>} />}
                    content={`${trans("UploadImageModal.deletePopup")}`}
                    position="right center"
                />
               
                {/* <Divide vertical /> */}
                {/* <ButtonUploadModal icon="icon-pencil" content={trans("UploadImageModal.Edit")} onClick={handleOnEdit} /> */}
            </div>
        </div>
    );
};

const ButtonUploadModal = ({ icon, content, onClick }) => (
    <Button className="actionButton" onClick={onClick} >
        <img src={`/img/${icon}.svg`} alt="" />
        {content}
    </Button>
);

const DeleteImageModal = ({ onDelete }) =>
{
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    const handleOnDelete = React.useCallback(() =>
    {
        setOpen(false);
        onDelete();
    }, [onDelete]);

    const trans = useTranslation().t;

    return (
        <ModalLayout dimmer="blurring"
            open={open}
            onClose={handleClose}
            trigger={
                <ButtonUploadModal icon="icon-trash-blue" content={trans("UploadImageModal.Delete")} onClick={handleOpen} />
            }
            className="crop-image-modal actionModal deleteImg"
            heading={trans("UploadImageModal.Delete_Image")}
            actions={
                <>
                    <Button content={trans("UploadImageModal.Cancel")} onClick={handleClose} />
                    <Button secondary content={trans("UploadImageModal.Delete")} onClick={handleOnDelete} />
                </>
            }>
            <p>{trans("UploadImageModal.Are_you_sure_you_want_to_delete_this_ima")}</p>
        </ModalLayout>
    );
};

DeleteImageModal.propTypes = {
    onDelete: PropTypes.func.isRequired
};
