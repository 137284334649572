import React, { useState, useEffect, useContext, useMemo } from "react";
import { Modal, Button, Checkbox, Input, Grid, Segment } from "semantic-ui-react";
import "../../pages/templates/templates.css";
import { useTranslation, Trans } from "react-i18next";
import { useOverlaysTemplateContext } from "../../../store/OverlaysTemplateContext";
import BrandingContext from "../../../store/BrandingContext";
import { DEFAULT_LANGUAGE_CODE } from "../../../_constants/constants";
import { toast } from "react-toastify";

const PropertyTemplateCopyModal = () => 
{
    const { t } = useTranslation();
    const { overlayTemplates } = useOverlaysTemplateContext();
    const brandingCtx = useContext(BrandingContext);

    const [open, setOpen] = useState(false);
    const [templates, setTemplates] = useState([]);
    const [properties, setProperties] = useState([]);
    const [templateSearch, setTemplateSearch] = useState("");
    const [propertySearch, setPropertySearch] = useState("");
    const [currentSelectedProperty, setcurrentSelectedProperty] = useState(null);

    useEffect(() => 
    {
        if (brandingCtx.state.properties) 
        {
            const propertyList = Object.values(brandingCtx.state.properties).map((property) => ({
                ...property,
                checked: false,
            }));

            setcurrentSelectedProperty(propertyList.filter((property) => property._id === brandingCtx.state.propertyId)[0]);

            let filteredProperties = propertyList.filter((property) => property._id != brandingCtx.state.propertyId);

            setProperties(filteredProperties);
        }

        if (overlayTemplates?.length) 
        {
            setTemplates(overlayTemplates.map((template) => ({ ...template, checked: false })));
        }
    }, [brandingCtx.state.properties, overlayTemplates]);

    // Generic filtering function
    const filterBySearch = (items, searchKey) => items.filter((item) => item.name[DEFAULT_LANGUAGE_CODE].toLowerCase().includes(searchKey.toLowerCase())
    );

    const filteredTemplates = useMemo(() => filterBySearch(templates, templateSearch), [templates, templateSearch]);

    const filteredProperties = useMemo(() => filterBySearch(properties, propertySearch), [properties, propertySearch]);

    const toggleCheckbox = (id, isTemplate) => 
    {
        if (isTemplate) 
        {
            setTemplates((prevTemplates) => prevTemplates.map((template) => template._id === id ? { ...template, checked: !template.checked } : template));
        }
        else 
        {
            setProperties((prevProperties) => prevProperties.map((property) => property.propertyId === id ? { ...property, checked: !property.checked } : property));
        }
    };

    const toggleAll = (items, setItems, allChecked, someChecked) => 
    {
        const newCheckedState = someChecked ? false : !allChecked;
        setItems((prevItems) => prevItems.map((item) => ({ ...item, checked: newCheckedState })));
    };

    const renderCheckboxList = (items, isTemplate = false) => 
    {
        const filteredItems = isTemplate ? filteredTemplates : filteredProperties;
        const allItems = isTemplate ? templates : properties;

        const allChecked = allItems.every((item) => item.checked);
        const someChecked = allItems.some((item) => item.checked);
        const filteredAllChecked = filteredItems.length ? filteredItems.every((item) => item.checked) : false;

        const searchFilter =  isTemplate ? templateSearch : propertySearch;

        return (
            <div className="checkboxList">
                {!searchFilter && <Checkbox
                    className="checkboxItem listHeader"
                    label={t("Templates.Select_All")}
                    checked={filteredAllChecked}
                    onChange={() => toggleAll(allItems, isTemplate ? setTemplates : setProperties, allChecked, someChecked)}
                    indeterminate={someChecked && !allChecked}
                    disabled={!filteredItems.length}
                />}
                {
                    !filteredItems.length && <div>{t("Templates.No_Results_Found")}</div>
                }
                {items.map((item) => (
                    <div key={item._id} className="checkboxItem">
                        <Checkbox
                            label={item.name[DEFAULT_LANGUAGE_CODE]}
                            checked={item.checked}
                            onChange={() => isTemplate ? toggleCheckbox(item._id, isTemplate) : toggleCheckbox(item.propertyId, isTemplate)}
                        />
                    </div>
                ))}
            </div>
        );
    };

    const handleAssign = () => 
    {
        const selectedTemplateIds = templates.filter((t) => t.checked).map((t) => t._id);
        const selectedPropertyIds = properties.filter((p) => p.checked && !p.disabled).map((p) => p._id);

        brandingCtx.copyTemplate(selectedPropertyIds, selectedTemplateIds, (success, validationErrors) => 
        {
            if (success) 
            {
                toast.success(<Trans i18nKey="OverlaysTemplateSideBar.Copy_Template_Success" />);
                setOpen(false);
            }
            else 
            {
                toast.error(validationErrors[0]);
            }
        });
    };

    const resetCheckboxes = () => 
    {
        setTemplates((prevTemplates) => prevTemplates.map((template) => ({
            ...template,
            checked: false
        })));
    
        setProperties((prevProperties) => prevProperties.map((property) => ({
            ...property,
            checked: false
        })));
    };

    const isAnyTemplateChecked = templates.some((t) => t.checked);
    const isAnyPropertyChecked = properties.some((p) => p.checked);

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => 
            {
                setOpen(true);
                resetCheckboxes();  // Reset checkboxes when the modal opens
            }}
            open={open}
            trigger={<Button className="copyTemplates">{t("Templates.Copy_Templates")}</Button>}
            size="small"
            closeIcon={<Button icon="close" className="closeIcon" onClick={() => setOpen(false)} />}
            className="templatePropertyModal"
        >
            <Modal.Header>{t("Templates.Copy_Template_To_Property")}</Modal.Header>
            <Modal.Content>
                <p className="subheader">{t("Templates.Copy_Template_Desc")}</p>

                <div className="segmentHeaderContainer">
                    <div className="segmentHeader">{t("Templates.Select_Template")}</div>
                    <div className="segmentHeader">{t("Templates.Select_Properties_Desc")}</div>
                </div>

                <Grid columns={2} divided>
                    <Grid.Row>
                        <Grid.Column>
                            <Segment>
                                <p className="colHeader">{t("Templates.Templates")}</p>
                                <Input
                                    icon="search"
                                    placeholder={t("Templates.Search")}
                                    fluid
                                    value={templateSearch}
                                    onChange={(e) => setTemplateSearch(e.target.value)}
                                />
                                {renderCheckboxList(filteredTemplates, true)}
                            </Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <Segment>
                                <p className="colHeader">{t("Templates.Property")}</p>
                                <Input
                                    icon="search"
                                    placeholder={t("Templates.Search")}
                                    fluid
                                    value={propertySearch}
                                    onChange={(e) => setPropertySearch(e.target.value)}
                                />
                                {renderCheckboxList(filteredProperties)}
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    className="assign"
                    onClick={handleAssign}
                    disabled={!isAnyTemplateChecked || !isAnyPropertyChecked}
                >
                    {t("Templates.Assign")}
                </Button>
                <Button className="cancel" onClick={() => setOpen(false)}>
                    {t("Templates.Cancel")}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default PropertyTemplateCopyModal;