// functions that use entity access as inputs

const { Style } = require("ol/style");
const { STYLE_OPTIONS, getDefaultPolygonStyle, MAP_TEXT_STYLE } = require("../utils/defualtStyles");
const { ShapeTypes } = require("../utils/entityTypes");
const { AbstractEntityAccess } = require("./entityAccess");
const { createTextStyle, createEntityStyle, createPointStyle } = require("./plotting");

/**
 * Checks if the given parameter is an entity access
 * @param {*} entityAccess 
 * @returns boolean 
 */
exports.isEntityAccess = (entityAccess) =>
{
    return entityAccess instanceof AbstractEntityAccess;
}

/**
 * Creates an entity's text style given entity information.
 * @param {AbstractEntityAccess} entityAccess
 * @param {Object} style - property/building style 
 * @returns textStyle
 */
exports.createEntityTextStyle = (entityAccess, style) =>
{
    if (!exports.isEntityAccess(entityAccess))
    {
        return;
    }

    const textStyleObject = entityAccess.getTextStyleObject(style);

    const name = entityAccess.getName({ useStyle: true });

    // create template style to be edited
    let textStyle = new Style(MAP_TEXT_STYLE);

    if (name)
    {
        const text = createTextStyle(name, entityAccess.getTextRotation(), textStyleObject);
        textStyle.setText(text);
    }

    return textStyle;
};


/**
 * Creates entity style using entityAccess and style 
 * @param {AbstractEntityAccess} entityAccess 
 * @param {Object} style 
 * @returns entityStyle
 */
exports.createEntityAccessStyle = (entityAccess, style, styleOption) =>
{
    if (!exports.isEntityAccess(entityAccess))
    {
        return;
    }

    // Create Style
    const styleObject = entityAccess.getStyleObject(style, styleOption);

    // style is dependent on shape 
    let shapeType = entityAccess.getShape().type;

    let entityStyle = createEntityStyle(styleObject);

    if (shapeType === ShapeTypes.POINT)
    {
        entityStyle = createPointStyle(styleObject);
    }

    return entityStyle;
}


/**
 * Creates entity style using entityAccess and style 
 * sets style to map default if no selected is available
 * if map default not available set style to type default
 * @param {AbstractEntityAccess} entityAccess 
 * @param {Object} style 
 * @returns entityStyle
 */
exports.createEntityAccessSelectedStyle = (entityAccess, style) =>
{
    if (!exports.isEntityAccess(entityAccess))
    {
        return;
    }

    // Create Style
    let styleObject = entityAccess.getStyleObject(style, STYLE_OPTIONS.SELECTED);

    if (!styleObject)
    {
        // check if default has selected 
        styleObject = getDefaultPolygonStyle(style, STYLE_OPTIONS.SELECTED);

        if (!styleObject)
        {
            styleObject = entityAccess.getStyleObject(style);
        }
    }

    // style is dependent on shape 
    let shapeType = entityAccess.getShape().type;

    let entityStyle = createEntityStyle(styleObject);

    // if (shapeType === ShapeTypes.POINT)
    // {
    //     entityStyle = createPointStyle(styleObject);
    // }

    return entityStyle;
}