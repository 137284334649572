import React from "react";
import { Trans } from "react-i18next";
import { RRule } from "rrule";
import cloneDeep from "lodash.clonedeep";
import { BRANDING_QUERIES } from "../_api/branding.queries";
import { MAINTENANCE_QUERIES } from "../components/maintenance/maintenance.queries";
import { SETTINGS_QUERIES } from "../components/settings/settings.queries";

export const ENVS = {
    PROD: "Production Mode",
    STAGING: "Staging Mode",
};

export const TOKEN_REFRESHMENT_TIME_INTERVAL = 2 * 60 * 60 * 1000; // 2 hours

/**
 * List of draggable item types required for React DnD
 */
export const DraggableItemTypes = {
    FLOOR: "floor",
    SORTABLE_FLOOR: "sortable-floor",
    GALLERY_THUMB_ROW: "gallery-thumb-row",
};


export const ASPECT_RATIO_OPTIONS = [{
    label: "1:1",
    value: 1
}, {
    label: "3:1",
    value: 3
}];

export const FILE_UPLOAD_STATUS = {
    NOT_UPLOADED: "not_uploaded",
    UPLOADING: "uploading",
    UPLOADED: "uploaded",
    FAILED: "failed",
};

export const FILE_UPLOAD_LIMIT = 10;

export const DEFAULT_TEMPLATE_NUMBER = 30;

export const DEFAULT_ASPECT_RATIO = 1;

export const IMAGE_ERROR_TYPES = {
    LIMIT_EXCEEDED: "LIMIT_EXCEEDED",
    LIMIT_EXCEEDED_COVER: "LIMIT_EXCEEDED_COVER",
    FILE_SIZE_EXCEEDED: "FILE_SIZE_EXCEEDED",
    FILE_READ: "FILE_READ",
    URL_READ: "URL_READ",
    SELECTION: "SELECTION",
    IMAGE_PRESENT: "IMAGE_PRESENT",
    UPLOADING: "UPLOADING",
    FETCHING: "FETCHING",
    INVALID: "INVALID",
    OTHER: "OTHER",
};


export const IMAGE_ERROR_MESSAGES = {
    [IMAGE_ERROR_TYPES.LIMIT_EXCEEDED]: "constants.Limit_Exceeded",
    [IMAGE_ERROR_TYPES.LIMIT_EXCEEDED_COVER]: "constants.Error_limit",
    [IMAGE_ERROR_TYPES.FILE_SIZE_EXCEEDED]: "constants.File_exceeds_maximum_allowed_size_for_up",
    [IMAGE_ERROR_TYPES.FILE_READ]: "constants.File_read",
    [IMAGE_ERROR_TYPES.UPLOADING]: "constants.Error_uploading_file__Please_try_with_an",
    [IMAGE_ERROR_TYPES.IMAGE_PRESENT]: "constants.Error_Image_Present",
    [IMAGE_ERROR_TYPES.SELECTION]: "constants.Error_selecting_image",
    [IMAGE_ERROR_TYPES.FETCHING]: "constants.Error_getting_images__Please_try_with_an" ,
    [IMAGE_ERROR_TYPES.INVALID]: "constants.Invalid_File_Type" ,
    [IMAGE_ERROR_TYPES.URL_READ]: "constants.Url_read"
};

export const IMAGE_EDITOR_TYPE = {
    BULK_IMAGE_EDITOR: "BULK_IMAGE_EDITOR",
    SINGLE_IMAGE_EDITOR: "SINGLE_IMAGE_EDITOR"
};

export const ACCEPTABLE_FILE_FORMATS = "image/x-png,image/png,image/jpeg,image/jpg,image/webp";


Object.freeze(DraggableItemTypes);

/**
 * Status descriptions such as name, className
 */
export const STATUS_DESCRIPTION = {
    1: { code: 1, name: <Trans i18nKey="constants.Draft" />, className: "draft", incomplete: true, statusName: "Draft", i18nKey: "constants.Draft" },
    2: { code: 2, name: <Trans i18nKey="constants.Validating" />, className: "validating", incomplete: false, statusName: "Validating", i18nKey: "constants.Validating" },
    3: { code: 3, name: <Trans i18nKey="constants.Incomplete" />, className: "incomplete", incomplete: true, statusName: "Incomplete", i18nKey: "constants.Incomplete" },
    4: { code: 4, name: <Trans i18nKey="constants.Processing" />, className: "processing", incomplete: false, statusName: "Processing", i18nKey: "constants.Processing" },
    5: { code: 5, name: <Trans i18nKey="constants.Operational" />, className: "operational", incomplete: false, statusName: "Operational", i18nKey: "constants.Operational" },
};

Object.freeze(STATUS_DESCRIPTION);

export const TERMS_AND_CONDITIONS_URL = "https://mapsted.com/legal/website";
export const PRIVACY_POLICY_URL = "https://mapsted.com/legal/privacy-policy";

export const ERROR_MESSAGES = {
    duplicateFloorName: <Trans i18nKey="constants.Please_use_different_names_for_each_floo" />,
    noFloorName: <Trans i18nKey="constants.Please_verify_your_floor_names__All_fiel" />,
    invalidOpeningHours: <Trans i18nKey="constants.Please_verify_your_working_hours__Openin" />,
    invalidHolidayDate: <Trans i18nKey="constants.Invalid_holiday_date_format" />,    
    uploadImageModal: {
        invalidFileType: <Trans i18nKey="constants.Invalid_Cover_Image_File_Type" />,
        fileIsTooBig: <Trans i18nKey="constants.File_exceeds_maximum_allowed_size_for_up" />,
        uploadImageError: <Trans i18nKey="constants.Error_uploading_file__Please_try_with_an" />,
        getImagesError: <Trans i18nKey="constants.Error_getting_images__Please_try_with_an" />,
        deleteImageError: <Trans i18nKey="constants.Error_deleting_image" />,
        selectImageError: <Trans i18nKey="constants.Error_selecting_image" />,
        limitExceeded: <Trans i18nKey="constants.Error_limit" />,
        imagePresent: <Trans i18nKey="constants.Error_Image_Present" />,
        fullGallery: {
            header: <Trans i18nKey="constants.You’ve_reached_your_image_upload_limit_" />,
            message: <Trans i18nKey="constants.The_maximum_number_of_images_per_gallery" />
        }
    }
};

export const WARNING_MESSAGES = {
    deleteProperty: {
        heading: "constants.Delete_Property",
        body: "constants.will_be_deleted__You_cannot_undo_this_ac"
    },
    deleteBuilding: {
        heading: "constants.Delete_Building",
        body: "constants.will_be_deleted__You_cannot_undo_this_ac"
    },
    floorNumError:
    {
        heading: <Trans i18nKey="constants.Floor_Missing" />,
        body: <Trans i18nKey="constants.We_noticed_some_floor_plans_may_be_missi" />
    },
    duplicateFloorError:
    {
        heading: <Trans i18nKey="constants.Duplicate_Floor_Plans" />,
        body: <Trans i18nKey="constants.Some_floors_have_duplicate_floor_plan_fi" />
    },
    unsavedChanges: {
        heading: <Trans i18nKey="constants.Unsaved_Changes" />,
        body: <Trans i18nKey="constants.You_are_about_to_discard_all_changes" />
    },
    confirmAddressChanges: {
        heading: <Trans i18nKey="constants.Change_Address" />,
        body: <Trans i18nKey="constants.The_property_address_has_been_changed" />
    },
};

export const BRANDING_MAP_CONSTANTS = {
    DEFAULT_ZOOM: 3,
    MAX_ZOOM: 24,
    MIN_ZOOM_TEXT_LAYER: 14,
    MIN_ZOOM: 10,
    HIDDEN_LAYER: "-1",
    BOUNDARY_LAYER: "1",
    TEXT_LAYER: "text_layer",
    ANIMATION_DURATION: 200,
    FIT_PADDING_ENTITIES: [290, 290, 290, 290],
    FIT_PADDING_MAP_DATA: [20, 20, 20, 20],
};

export const MAP_TOOLS = {
    EDIT: "edit",
    ROTATE: "rotate",
    LAYERS: "layers",
    MERGE: "merge",
    SPLIT: "split",
    SPLIT_DRAW: "splitDraw",
    MOVE_TEXT: "moveText",
};

export const SNAP_INTERACTION = "snap";

export const NO_RESULTS_FOUND = <Trans i18nKey="constants.No_Results_Found" />;

export const LANGUAGE_CODE = "en";

export const MAP_POPUP_OFFSETS = {
    [MAP_TOOLS.ROTATE]: [-99, -200],
    [MAP_TOOLS.EDIT]: [-111, -180],
    [MAP_TOOLS.MERGE]: [0, -40],
    [MAP_TOOLS.SPLIT]: [0, 0],
    [MAP_TOOLS.SPLIT_DRAW]: [0, 0],
    [MAP_TOOLS.MOVE_TEXT]: [0, 0]
};

export const LINKED_WORKING_HOURS_DAY = 1;

export const OPEN_24H_MINS = {
    OPEN: 0,
    CLOSE: 1439
};

export const KEY_CODES = {
    ESCAPE: 27,
    ENTER: 13,
};

export const ENTITY_LABEL_TYPES = {
    0: "Building",
    1: "Store",
    2: "Office",
    3: "Room",
};

export const DEFAULT_LANGUAGE_CODE = "en";
export const SINGLE_LANG_INPUT_CODE = "en";      // used for inputs that cannot edit multiple languages

export const DEFAULT_STORE_LOGO = "/img/default-logo.png";
export const DEFAULT_STORE_LOGO_UPLOAD = "/img/default-logo-placeholder.png";

export const WEEKDAYS = ["constants.Monday", "constants.Tuesday", "constants.Wednesday", "constants.Thursday", "constants.Friday", "constants.Saturday", "constants.Sunday"];

export const MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
];

export const ASPECT_RATIOS = {
    COVER_IMAGE: 3,
    ICON_IMAGE: 1,
    LOGO: 1
};

export const MAINTENANCE_TOOLS = {
    SELECTION: "selection",
    ACTIVITY: "activity",
    LAYER_SELECTION: "layerSelection"
};



export const MAINTENANCE_TAB_TYPE = {
    PLANNED: "planned", // only for emergency alerts
    ACTIVE: "active",
    HISTORY: "history",
    ARCHIVE: "archive"
};

export const ACTIVATION_ZONES = {
    "Inside building": "BUILDING",
    "In proximity to location": "VICINITY"
};

export const ACTIVATION_DIRECTION = {
    "On enter": "ON_ENTER",
    "On exit": "ON_EXIT"
};

export const ACTIVATION_LOCATION = {
    BUILDING: "Inside building",
    VICINITY: "In proximity to location"
};

export const ACTIVATION_TRIGGER = {
    ON_ENTER: "On enter",
    ON_EXIT: "On exit"
};


export const MAINTENANCE_MODES = {
    MAP_OVERLAYS: "mapOverlays",
    DYNAMIC_MAP_LAYERS: "dynamicMapLayers",
    ZONES: "zones",
};


export const COLOR_OPTIONS = [
    "#898989",
    "#ACACAC",
    "#D7D7D7",
    "#EBEBEB",
    "#F09300",
    "#F40000",
    "#023473",
    "#1289E5",
    "#4EB543",
    "#FFD800"
];

export const TemplateSortingOptions = [
    { key: "newest", text: "Templates.Newest_Created", value: "newest" },
    { key: "oldest", text: "Templates.Oldest_Created", value: "oldest" },
    { key: "ascending", text: "Templates.Name_A_Z", value: "ascending" },
    { key: "descending", text: "Templates.Name_Z_A", value: "descending" },
];

export const MAP_OVERLAY_TEXT_COLOR_DEFAULT = "#000000";

export const MAP_OVERLAYS_MODES = {
    ACTIVITY_SIDEBAR: "activitySideBar",
    CREATE_OVERLAY: "createOverlay",
    EDIT_OVERLAY: "editOverlay",
    CONFIRMATION_SIDEBAR: "confirmationSidebar",
    BULK_CREATION: "bulkCreation",
    TEMPLATES: "templates",
    UPLOAD_CSV: "uploadCsv",
};

export const MAP_OVERLAYS_DEFAULT_SETTINGS = {
    fillOpacity: 0.5,
    borderFillOpacity: 0.5,
    textOpactiy: 1
};

export const MAP_OVERLAYS_DEFAULT_STYLES = {
    stroke: {
        color: "#4BD70F",
        width: 1
    },
    fill: {
        color: "rgb(107, 255, 124, 0.5)",
    }
};

export const MAP_OVERLAY_DEFAULT_ZOOM_LEVEL_SETTINGS = {
    value: undefined,
    fillOpacity: MAP_OVERLAYS_DEFAULT_SETTINGS.fillOpacity,
    borderFillOpacity: MAP_OVERLAYS_DEFAULT_SETTINGS.borderFillOpacity,
    textOpacity: MAP_OVERLAYS_DEFAULT_SETTINGS.textOpactiy,
    textLabel: { [DEFAULT_LANGUAGE_CODE]: "" },
    overrideGlobalTextLabel: false
};

export const MAP_OVERLAY_DEFAULT_DYNAMIC_SETTINGS = {
    enabled: false,
    zoomLevels: [
        cloneDeep(MAP_OVERLAY_DEFAULT_ZOOM_LEVEL_SETTINGS),
        cloneDeep(MAP_OVERLAY_DEFAULT_ZOOM_LEVEL_SETTINGS)
    ]
};

export const NEW_POLYGON_DEFAULT = {
    polygon: undefined,
    name: { [DEFAULT_LANGUAGE_CODE]: "" },
    color: "#6BFF7C",
    lineColor: "#4BD70F",
    textColor: "#000000",
    defaultFillOpacity: MAP_OVERLAYS_DEFAULT_SETTINGS.fillOpacity,
    defaultBorderFillOpacity: MAP_OVERLAYS_DEFAULT_SETTINGS.borderFillOpacity,
    defaultTextOpacity: MAP_OVERLAYS_DEFAULT_SETTINGS.textOpactiy,
    toolTipText: { [DEFAULT_LANGUAGE_CODE]: "" },
    dynamicOverlaySettings: MAP_OVERLAY_DEFAULT_DYNAMIC_SETTINGS,
    propertyId: undefined,
    buildingId: undefined,
    floorId: undefined
};

export const MAP_OVERLAY_NEW_OVERLAY_FEATURE_ID = "newOverlay";

export const MAP_OVERLAY_NEW_OVERLAY_TEXT_FEATURE_ID = "newOverlay_text";

export const NEW_OVERLAY_DEFAULT = {
    _id: MAP_OVERLAY_NEW_OVERLAY_FEATURE_ID,
    id: MAP_OVERLAY_NEW_OVERLAY_FEATURE_ID,
    vectorLayer: undefined,
    name: { [DEFAULT_LANGUAGE_CODE]: "" },
    color: "#6BFF7C",
    lineColor: "#4BD70F",
    textColor: "#000000",
    defaultFillOpacity: MAP_OVERLAYS_DEFAULT_SETTINGS.fillOpacity,
    defaultBorderFillOpacity: MAP_OVERLAYS_DEFAULT_SETTINGS.borderFillOpacity,
    defaultTextOpacity: MAP_OVERLAYS_DEFAULT_SETTINGS.textOpactiy,
    toolTipText: { [DEFAULT_LANGUAGE_CODE]: "" },
    dynamicOverlaySettings: MAP_OVERLAY_DEFAULT_DYNAMIC_SETTINGS
};

export const MAP_OVERLAY_ZOOM_CONSTANTS = {
    MAX_ZOOM: 24,
    MIN_ZOOM: 10
};

export const MAP_OVERLAY_MAP_SHARING_MODES = {
    START_ZOOM: "startZoom",
    END_ZOOM: "endZoom"
};

export const MAP_OVERLAY_TEXT_MIN_ZOOM = 15;

export const DYNAMIC_MAP_LAYERS_MODES = {
    CREATE_DYNAMIC_MAP_LAYER: "createDynamicMapLayer",
    ACTIVITY_SIDEBAR: "activitySidebar",
    EDIT_DYNAMIC_MAP_LAYER: "editDynamicMapLayer",
    CONFIRMATION_SIDEBAR: "confirmationSidebar",
    MULTI_CREATION: "multiCreation"
};

export const NEW_DYNAMIC_MAP_LAYER_DEFAULT = {
    dataStreamName: { [DEFAULT_LANGUAGE_CODE]: "" },
    mapOverlayId: undefined,
    apiKey: undefined,
    automationRules: [],
    dataPointName: "",
    configurationName: ""
};

export const NEW_AUTOMATION_RULE = {
    dataPointCondition: {
        condition: undefined,
        value: undefined
    },
    textLabel: { [DEFAULT_LANGUAGE_CODE]: "" },
    fillColor: "#6BFF7C",
    fillOpacity: 0.5,
    borderColor: "#4BD70F",
    borderOpacity: 0.5,
    textColor: "#000000",
    textOpacity: 1,
    textPopup: { [DEFAULT_LANGUAGE_CODE]: "" }
};

export const NEW_DYNAMIC_MAP_LAYER_ID = "newDynamicMapOverlay";

export const AUTOMATION_RULE_CONDITIONS = {
    EQUAL: "equal",
    GREATER: "greater",
    LESSER: "lesser"
};

export const AUTOMATION_RULE_CONDITIONS_ICONS = {
    equal: "/img/icon-equal.svg",
    greater: "/img/icon-greater.svg",
    lesser: "/img/icon-less.svg"
};

export const DEFAULT_DYNAMIC_MAP_LAYER_MULTI_CREATION = {
    configurationName: "",
    file: undefined,
    dynamicMapLayers: [],
    mapOverlays: []
};

export const DEFAULT_VERTICES = 40;

export const DYNAMIC_MAP_LAYER_TEMPLATE_NAME = "Map Layers Template";
export const YOUTUBE_IDS = {
    ADD_KIOSK: "jREzvTNnNxc",
};

export const YOUTUBE_BASE_URL = "https://www.youtube-nocookie.com/embed/";

export const DEFAULT_MAP_OVERLAY_BULK_CREATION_STATE = {
    bulkCreationIndex: 0,
    bulkMapOverlays: [],
    bulkCreationTools: false,
    bulkCreationModal: false,
    templateMapOverlay: undefined
};

export const BULK_MAP_OVERLAY_COPY_FROM_TEMPLATE_FIELDS = [
    "color",
    "lineColor",
    "textColor",
    "defaultFillOpacity",
    "defaultBorderFillOpacity",
    "defaultTextOpacity",
    "dynamicOverlaySettings",
];


export const ICON_PICKER_OPTIONS = {
    CATEGORIES: {
        key: "Categories_Icon",
        text: "Categories",
        value: (filters) => BRANDING_QUERIES.GET_STYLED_ICONS({ kind: "CategoryIcon", ...filters }),
        i18nKey: "Gallery.Categories"
    },
    ALERTS: {
        key: "Alerts_Icon",
        text: "Alerts",
        value: (filters) => BRANDING_QUERIES.GET_STYLED_ICONS({ kind: "AlertIcon", ...filters }),
        i18nKey: "Gallery.Alerts"
    },
    EMERGENCY_ALERTS: {
        key: "Emergency_Icon",
        text: "Emergency Alerts",
        value: (filters) => BRANDING_QUERIES.GET_STYLED_ICONS({ kind: "EmergencyIcon", ...filters }),
        i18nKey: "Gallery.Emergency_Alerts"
    },
    KIOSKS: {
        key: "Kiosk_Icon",
        text: "Kiosk",
        value: (filters) => BRANDING_QUERIES.GET_STYLED_ICONS({ kind: "KioskIcon", ...filters }),
        i18nKey: "Gallery.Kiosk"
    }
};

export const VALIDATE_TIME_STAMP_FORMAT = "MMMM D, YYYY";

export const OPENING_TIME_MINS = 540;
export const CLOSING_TIME_MINS = 1260;

export const MAPOVERLAY_DETAILS_FEATURE_KEY = "mapOverlayDetails";

export const REDIRECT_QUERY_KEY = "redirect";
export const REDIRECT_QUERY_MAINTENANCE_VALUE = "maintenance";
export const CACHED_MAPOVERLAY_LOCAL_STORAGE_KEY = "cachedMapOverlay";

export const RESTORE_QUERY_KEY = "restore";
export const RESTORE_QUERY_MAP_OVERLAYS = "mapOverlays";

export const FIELDS_TO_HEX_CONVERT = ["color", "lineColor", "textColor"];

export const ZONE_MAP_OPTION_TYPS = {
    ENTITY: "entity",
    ZONE: "zone"
};

export const IGNORED_OVERLAY_TEMPLATE_FIELDS = ["companyUID", "group", "templateId", "property", "name", "_id", "createdBy", "updatedBy"];

export const MAX_GROUPS_ALLOWED = 5;

export const UNIT_SYSTEM = {
    METRIC: "metric",
    IMPERIAL: "imperial"
};