import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { InputFormGroup } from "../elements/InputFormGroup";
import { toDropDownList } from "../../_utils/utils";
import { useTranslation } from "react-i18next";
import { SINGLE_LANG_INPUT_CODE } from "../../_constants/constants";
import { useQuery } from "react-query";
import { GENERIC_QUERIES } from "../../_utils/queries";


export const PropertyManualEditBox = React.memo(({ property, userAddress, validationErrors, propertyTypes, onChange, onChangeUserAddress }) =>
{
    /** @type {typeof import("../../_intl/resources.json")["en"]["translation"]["property manual edit box"]} */
    const trans = useTranslation().t("property manual edit box");

    const [countrySearchQuery, setCountrySearchQuery] = React.useState("");

    const { data: listCountries } = useQuery({ ...GENERIC_QUERIES.COUNTRIES(), select: (data) => toDropDownList({ data, value: "name", text: "name", key: "alpha2Code" }) });

    return (
        <>
            <InputFormGroup
                label={trans["property name label"]}
                placeholder={trans["property name placeholder"]}
                value={property.name?.[SINGLE_LANG_INPUT_CODE]}
                onChange={onChange}
                name={`name.${SINGLE_LANG_INPUT_CODE}`}
                error={validationErrors[`name.${SINGLE_LANG_INPUT_CODE}`]}
                extraLabel={trans["property name extra label"]}
            />

            <InputFormGroup
                label={trans["street num label"]}
                placeholder={trans["street num placeholder"]}
                type="number"
                value={userAddress.streetNumber}
                onChange={onChangeUserAddress}
                name="streetNumber"
                error={validationErrors["streetNumber"]}
                extraLabel={trans["street num extra label"]}
            />

            <InputFormGroup label={trans["unit num label"]} placeholder={trans["unit num placeholder"]} type="number"
                value={userAddress.unitNumber} onChange={onChangeUserAddress} name="unitNumber"
                extraLabel={trans["unit number extra label"]} />

            <InputFormGroup label={trans["street name label"]} placeholder={trans["street name placeholder"]}
                value={userAddress.street} onChange={onChangeUserAddress} name="street" error={validationErrors["street"]}
                extraLabel={trans["street name extra label"]} />

            <InputFormGroup label={trans["city label"]} placeholder={trans["city placeholder"]}
                value={userAddress.city} onChange={onChangeUserAddress} name="city" error={validationErrors["city"]}
                extraLabel={trans["city extra label"]} />

            <InputFormGroup label={trans["state prov label"]} placeholder={trans["state prov placeholder"]}
                value={userAddress.province} onChange={onChangeUserAddress} name="province"
                extraLabel={trans["state prov extra label"]} />

            <InputFormGroup label={trans["postal code label"]} placeholder={trans["postal code placeholder"]}
                value={userAddress.postalCode} onChange={onChangeUserAddress} name="postalCode" error={validationErrors["postalCode"]}
                extraLabel={trans["postal code extra label"]} />

            {!userAddress.countryAlpha2Code
                && <InputFormGroup
                    label={trans["country label"]}
                    search={true}
                    list={countrySearchQuery ? listCountries.filter((country) => country.value.toUpperCase().includes(countrySearchQuery.toUpperCase())) : listCountries}
                    value={userAddress.country}
                    name="country"
                    error={validationErrors["country"]}
                    extraLabel={trans["country extra label 1"]}
                    placeholder={trans["country placeholder"]}
                    onChange={onChangeUserAddress}
                    onSearchChange={(e, { searchQuery }) => setCountrySearchQuery(searchQuery)} />
            }
            {
                userAddress.countryAlpha2Code
                && <InputFormGroup label={trans["country label"]}
                    value={userAddress.country} name="country" error={validationErrors["country"]}
                    extraLabel={trans["country extra label 2"]} placeholder={trans["country placeholder"]}
                    onChange={onChangeUserAddress} />
            }

            <InputFormGroup label={trans["property type label"]} list={propertyTypes} scrolling search
                onChange={onChange} name="type" listValue={property.type} error={validationErrors["type"]}
                extraLabel={trans["property type extra label"]} />

            <InputFormGroup
                label={trans["website label"]}
                placeholder={trans["website placeholder"]}
                error={validationErrors[`website.${SINGLE_LANG_INPUT_CODE}`]}
                value={property.website?.[SINGLE_LANG_INPUT_CODE]}
                onChange={onChange}
                name={`website.${SINGLE_LANG_INPUT_CODE}`}
                extraLabel={trans["website extra label"]}
            />

            <InputFormGroup label={trans["phone label"]}
                extraLabel={trans["phone extra label"]}
                placeholder={trans["phone placeholder"]} error={validationErrors["phoneCheck"]}
                value={property.phoneNumbers} onChange={onChange} name="phoneNumbers" />
        </>
    );
});

PropertyManualEditBox.displayName = "PropertyEditBox";

PropertyManualEditBox.propTypes = {
    property: PropTypes.object.isRequired,
    userAddress: PropTypes.object.isRequired,
    propertyTypes: PropTypes.array.isRequired,
    validationErrors: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onChangeUserAddress: PropTypes.func.isRequired,
};
