import React, { useCallback, useEffect, useMemo, useState } from "react";
import { MapModalCheckBoxContainer, MapModalContainer, MapModalDropdown, MapModalTitle } from "./MapModalTemplate";
import { Checkbox } from "semantic-ui-react";
import { LinkPriorityMultiplier } from "mapsted.maps/utils/node.constants";
import { TextGroup } from "../../common/TextGroup";
import { DropdownForm } from "../../common/DropdownForm";

/**
 * Node link value handled outside of the component
 * each action will be handled and recorded by our step system
 * @param {*} param0 
 * @returns 
 */
export const EditNodeLinkModal = ({ nodeLinks, onChange }) =>
{
    const [isConnected, setIsConnected] = useState(false);

    useEffect(() =>
    {
        setIsConnected(nodeLinks[0].linkedPriorityMultiplier === nodeLinks[1].linkedPriorityMultiplier);
    }, [nodeLinks]);

    /**
     * dropdown options
     */
    const nodeLinkPriorityOptionsMemo = useMemo(() => Object.keys(LinkPriorityMultiplier).map((priorityName) =>
    {
        const priorityValue = LinkPriorityMultiplier[priorityName];

        return {
            key: priorityValue,
            value: priorityValue,
            text: priorityName
        };
    }), []);

    /**
     * first option value
     */
    const nodeLink1PriorityMemo = useMemo(() =>
    {
        let nodeLink1 = nodeLinks[0];

        return nodeLink1.linkedPriorityMultiplier || LinkPriorityMultiplier.Normal;
    }, [nodeLinks]);

    /**
     * second option value
     */
    const nodeLink2PriorityMemo = useMemo(() =>
    {
        let nodeLink2 = nodeLinks[1];

        return nodeLink2.linkedPriorityMultiplier || LinkPriorityMultiplier.Normal;
    }, [nodeLinks]);

    /**
     * on priority change -> update node linkages using outside handlers
     */
    const handlePriorityChange = useCallback((e, { name, value }) =>
    {
        let updatedNodeLinks = [...nodeLinks];
        let nodeLinkToUpdate = updatedNodeLinks[0];

        // update both links if they are connected
        if (isConnected)
        {
            updatedNodeLinks[1].linkedPriorityMultiplier = value;
        }
        // if updated second link direction 
        else if (name === "nodeLinkPriority2")
        {
            nodeLinkToUpdate = updatedNodeLinks[1];
        }

        nodeLinkToUpdate.linkedPriorityMultiplier = value;

        onChange(updatedNodeLinks);

    }, [isConnected, nodeLinks]);

    /**
     * on is connected check -> set the links to equal if set to true 
     */
    const handleIsConnectedChange = useCallback((e, { checked }) =>
    {
        // if we are connecting the node links, make sure they both equal the same value
        if (checked === true)
        {
            let updatedNodeLinks = [...nodeLinks];
            let nodeLink1 = updatedNodeLinks[0];
            let nodeLink2 = updatedNodeLinks[1];

            // only perform update logic if the link values are different
            if (nodeLink1.linkedPriorityMultiplier !== nodeLink2.linkedPriorityMultiplier)
            {
                nodeLink2.linkedPriorityMultiplier = nodeLink1.linkedPriorityMultiplier;
                onChange(updatedNodeLinks);
            }
        }
        setIsConnected(checked);
    }, [setIsConnected, nodeLinks]);

    return (
        <MapModalContainer 
            className="createTransitionPopupbox" >
            <TextGroup 
                heading={"Edit Node Link"} 
                className="transitionCheckboxRow">
                <Checkbox
                    className="checkboxPrimary"
                    name="connected"
                    checked={isConnected}
                    label="Connected"
                    onChange={handleIsConnectedChange}
                />

                
            </TextGroup>

            <TextGroup>
                <DropdownForm
                    name="nodeLinkPriority1"
                    options={nodeLinkPriorityOptionsMemo}
                    value={nodeLink1PriorityMemo}
                    onChange={handlePriorityChange} />

                {/* show second dropdown if not connected */}
            </TextGroup>
           
           
            {(!isConnected) && (
                <TextGroup>
                    <DropdownForm
                        name="nodeLinkPriority2"
                        options={nodeLinkPriorityOptionsMemo}
                        value={nodeLink2PriorityMemo}
                        onChange={handlePriorityChange} />
                </TextGroup>
            )}

        </MapModalContainer>
    );
};