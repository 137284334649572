import React, { useEffect } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import "../../branding/categoriesPortal/CategoryModal.css";
import EditSideBarPortal from "../../branding/editRightSidebar/EditSidebarPortal";
import BrandingMap from "../../branding/map/BrandingMap";
import { SubheaderBranding, SubheaderMapEdit } from "../../layout/Subheader";
import Inventory from "./listing/Inventory";
import { ACTIVE, ModeButtonGroup } from "./listing/ModeButtonGroup";
// import ListView from "./listing/ListView";
import { MAP_TOOLS, REDIRECT_QUERY_KEY, REDIRECT_QUERY_MAINTENANCE_VALUE } from "../../../_constants/constants";
import BrandingContext from "../../../store/BrandingContext";
import ListViewProvider from "../../../store/ListViewContext";
import { MapEditorProvider } from "../../../store/MapEditiorProvider";
import { MapEditor } from "../../mapEditor/MapEditor";
import ListView from "../listView/ListView";
import StylesAndThemesPage from "./stylesAndThemes/StylesAndThemesPage";
import MapEditPermissionWrapper from "../../mapEditor/PermissionWrappers/MapEditPermissionWrapper";
import ManageCategory from "../../branding/manageCategory/ManageCategory";

const ROUTES = {
    MAP_EDITOR: "/branding/mapeditor",
    LIST: "/branding/list",
    INVENTORY: "/branding/inventory",
    MAP: "/branding",
    STYLES_AND_THEMES: "/branding/stylesAndThemes",
    MANAGE_CATEGORY: "/branding/categories"
};

const ALERT_OPTIONS = {
    // you can also just use 'bottom center'
    timeout: 3000,
    offset: "30px",
    // you can also just use 'scale'
};

const TOOL_DISABLED_ROUTES = [
    ROUTES.LIST,
    ROUTES.STYLES_AND_THEMES,
    ROUTES.MAP_EDITOR
];

const Branding = () => 
{
    const bCtx = React.useContext(BrandingContext);
    const { pathname, search } = useLocation();
    const history = useHistory();

    useEffect(() => 
    {
        bCtx.reInitializeMapData();
        checkIfMaintenanceRedirect();
    }, [pathname]);

    // check if entity edit is initiated from map overlay page
    const checkIfMaintenanceRedirect = () => 
    {
        const searchParams = new URLSearchParams(search);

        const redirect = searchParams.get(REDIRECT_QUERY_KEY);

        if (redirect === REDIRECT_QUERY_MAINTENANCE_VALUE && searchParams.get("entityId")) 
        {
            const entityId = searchParams.get("entityId");

            // update branding ctx state to open edit sidebar
            bCtx.selectSelectedTool(MAP_TOOLS.EDIT);
            bCtx.updateSelectedEntities([entityId]);

            bCtx.setRedirectMaintenance(true);

            // remove query params related to redirect process from current url
            searchParams.delete(REDIRECT_QUERY_KEY);
            searchParams.delete("entityId");

            history.replace({
                search: searchParams.toString()
            });
        }
    };

    useEffect(() => () => bCtx.deselectEntities(), []);

    return (
        <>
            {/* <SubheaderBranding
                showTools={!TOOL_DISABLED_ROUTES.includes(pathname)}
                showStylesAndThemesButton
                showGallery
                isStylesAndThemes={pathname === ROUTES.STYLES_AND_THEMES}
            /> */}
            <Switch>

                <Route path={ROUTES.INVENTORY}>
                    <SubheaderBranding
                        showTools={!TOOL_DISABLED_ROUTES.includes(pathname)}
                        showEditorToggleButton={[ROUTES.LIST, ROUTES.MAP].includes(pathname.toLocaleLowerCase())}
                        showStylesAndThemesButton
                        showGallery
                        isStylesAndThemes={pathname === ROUTES.STYLES_AND_THEMES}
                    />
                    <Inventory />
                </Route>

                <Route path={ROUTES.LIST}>
                    {/* <SubheaderBranding
                        showTools={!TOOL_DISABLED_ROUTES.includes(pathname)}
                        showEditorToggleButton={[ROUTES.LIST, ROUTES.MAP].includes(pathname.toLocaleLowerCase())}
                        showStylesAndThemesButton
                        showGallery
                        isStylesAndThemes={pathname === ROUTES.STYLES_AND_THEMES}
                    />

                    <ListView onClick={() => !bCtx.state.editUnsavedChanges && bCtx.state.selectedTool === MAP_TOOLS.EDIT && bCtx.refreshMap()} />
                    <EditSideBarPortal onClose={() => bCtx.selectSelectedTool(MAP_TOOLS.EDIT)} /> */}
                    <ListViewProvider>
                        <ListView />
                    </ListViewProvider>
                </Route>

                <Route path={ROUTES.MAP_EDITOR}>

                    <MapEditPermissionWrapper redirectToBranding>
                        <MapEditorProvider reFetchAllProperties={bCtx.updateProperties}>
                            <SubheaderMapEdit showEditorToggleButton />
                            <MapEditor
                                propertyId={bCtx.state?.propertyId}
                                buildingId={bCtx.state?.buildingId}
                                floorId={bCtx.state?.floorId}
                                theme={bCtx?.state?.theme} />
                        </MapEditorProvider>
                    </MapEditPermissionWrapper>
                </Route>

                <Route path={ROUTES.STYLES_AND_THEMES}>
                    <SubheaderBranding
                        showTools={!TOOL_DISABLED_ROUTES.includes(pathname)}
                        showEditorToggleButton={[ROUTES.LIST, ROUTES.MAP].includes(pathname.toLocaleLowerCase())}
                        showStylesAndThemesButton
                        isStylesAndThemes={pathname === ROUTES.STYLES_AND_THEMES}
                        showCategories={false}
                    />
                    <StylesAndThemesPage />
                </Route>

                <Route path={ROUTES.MANAGE_CATEGORY}>
                    <SubheaderBranding
                        showTools={false}
                        showEditorToggleButton={[ROUTES.LIST, ROUTES.MAP].includes(pathname.toLocaleLowerCase())}
                        showCategories={false}
                        showShareButton={false}
                        showGallery={false}
                        showActiveBoundaryPolygonArea={false}
                    />
                    <ManageCategory />
                </Route>

                <Route path={ROUTES.MAP}>
                    <SubheaderBranding
                        showTools={!TOOL_DISABLED_ROUTES.includes(pathname)}
                        showEditorToggleButton={[ROUTES.LIST, ROUTES.MAP].includes(pathname.toLocaleLowerCase())}
                        showStylesAndThemesButton
                        showGallery
                        isStylesAndThemes={pathname === ROUTES.STYLES_AND_THEMES}
                    />
                    <ModeButtonGroup active={ACTIVE.MAP} />
                    <div style={{ height: "calc(100vh - 109px)" }}>
                        <BrandingMap />
                    </div>
                    <EditSideBarPortal />
                </Route>
            </Switch >
        </>
    );
};

export default Branding;
