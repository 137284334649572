import React from "react";
import { Button } from "semantic-ui-react";
import { ModalBox } from "../../common/ModalBox";
import { useTranslation } from "react-i18next";

const DynamicMapLayerCancelConfirmationModal = ({ open, onCancel, onClose }) =>
{
    const trans = useTranslation().t;

    return (
        <ModalBox className="confirmActionModal"
            open={open}
            onClose={onClose}
            header={trans("Cancel_Confirmation_Modal.Confirm_Action")}
            actions={<Button color="orange" floated="right" content={trans("Cancel_Confirmation_Modal.Cancel")} onClick={onCancel} />}>
            <p className="p-modal">
                {trans("Cancel_Confirmation_Modal.Dynamic_Map_Layer_Create_Cancel")}
            </p>
        </ModalBox>
    );
};

export default DynamicMapLayerCancelConfirmationModal;