import React from "react";
import "./ImagesManagement.css";
import ImagesManagementHeader from "./ImagesManagementHeader/ImagesManagementHeader";
import ImagesManagementMain from "./ImagesManagementMain/ImagesManagementMain";
import { DELETE_IMAGE_STRATEGY_ACTIONS, IMAGE_ACTION_MODES, IMAGE_UPLOAD_ACTIONS } from "../constant";
import ImageViewModal from "./ImageViewModal/ImageViewModal";
import ImageDeleteModalWithLinkedEntities from "./ImageDeleteModalWithLinkedEntities/ImageDeleteModalWithLinkedEntities";
import ImageDeleteConfirmationModal from "./ImageDeleteConfirmationModal/ImageDeleteConfirmationModal";
import SingleImageUploadModal from "./ImageUploads/SingleImageUploadModal/SingleImageUploadModal";
import BulkImageUploadModal from "./ImageUploads/BulkImageUploadModal/BulkImageUploadModal";
import ImageEditModal from "./ImageEditModal/ImageEditModal";
import ImageUploadProgressModal from "./ImageUploads/ImageUploadProgressModal/ImageUploadProgressModal";
import { GmsActionMode, useGmsContext } from "../GmsContext";
import ImagesManagementFooter from "./ImagesManagementFooter/ImagesManagementFooter";
import { useImagesUploadContext } from "./contexts/ImagesUploadContext";
import { useImageDeleteContext } from "./contexts/ImageDeleteContext";
import { useImagesBaseContext } from "./contexts/ImageBaseContext";
import ImagesRecycleBinModal from "./ImagesRecycleBinModal/ImagesRecycleBinModal";
import ImageCopyModal from "./ImageCopyModal/ImageCopyModal";
import ImagesManagementUploadFromURL from "./ImagesManagementUploadFromURL/ImagesManagementUploadFromURL";

function ImagesManagement() 
{
    const { imageActionMode, showImagesRecycleBin } = useImagesBaseContext();
    const { imageUploadAction, showImageUploadProgressModal } =
      useImagesUploadContext();
    const { selectedDeleteImageStrategy } = useImageDeleteContext();
    const { selectedImageInfo } = useImagesBaseContext();
    const { gmsActionMode } = useGmsContext();

    return (
        <div className="images-management">
            {imageActionMode === IMAGE_ACTION_MODES.VIEW && selectedImageInfo && <ImageViewModal />}
            {imageActionMode === IMAGE_ACTION_MODES.EDIT && selectedImageInfo && <ImageEditModal />}
            {imageActionMode === IMAGE_ACTION_MODES.COPY && selectedImageInfo && <ImageCopyModal />}
            {imageActionMode === IMAGE_ACTION_MODES.DELETE_WITH_NO_LINKED_ENTITIES  && selectedImageInfo && <ImageDeleteConfirmationModal />}
            {imageActionMode === IMAGE_ACTION_MODES.DELETE_WITH_LINKED_ENTITIES  && selectedImageInfo && <ImageDeleteModalWithLinkedEntities />}
            {imageUploadAction === IMAGE_UPLOAD_ACTIONS.UPLOAD_IMAGE && <SingleImageUploadModal />}
            {imageUploadAction === IMAGE_UPLOAD_ACTIONS.BULK_UPLOAD && <BulkImageUploadModal />}
            {showImageUploadProgressModal && <ImageUploadProgressModal />}
            {showImagesRecycleBin && <ImagesRecycleBinModal></ImagesRecycleBinModal>}
            <ImagesManagementHeader />
            {imageUploadAction === IMAGE_UPLOAD_ACTIONS.UPLOAD_FROM_URL && <ImagesManagementUploadFromURL></ImagesManagementUploadFromURL>}
            <ImagesManagementMain />
            {(gmsActionMode === GmsActionMode.LINK_COVER_IMAGES 
              || gmsActionMode === GmsActionMode.LINK_ICON_IMAGE 
              || selectedDeleteImageStrategy === DELETE_IMAGE_STRATEGY_ACTIONS.CHOOSE_NEW_IMAGE_AND_DELETE_OLD_ONE) 
              && <ImagesManagementFooter />}
        </div>
    );
}

export default ImagesManagement;
