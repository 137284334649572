import React from "react";
import { Header } from "semantic-ui-react";

export const ContentGroup = ({ flexible, className="", heading, children, extra }) => (
    <div className={`contentGroup${flexible ? " cgFlexible":""} ${className}`}>
        {(heading) && (<Header as="h6" content={heading} />)}
        <div className="content">
            {children}
        </div>
        {(extra) && (<span className="extraContent">{extra}</span>)}
    </div>
);
