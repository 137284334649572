import React from "react";
import { useTranslation } from "react-i18next";
import "./style.css";
// import { progress } from "semantic-ui-react";

export const Loader = ({ active, animation=true }) =>
{
    const trans = useTranslation().t;
    return (

        <div className="loading-container-archibus" hidden={!active} >
            {
                animation && (
                    <img src="/img/loading-m.gif" alt={trans("index.Loading___")} />
                )
            }
            {/* <div className="archibus-progress">
                <p>Progress</p>
            </div> */}
        </div>
    );
};