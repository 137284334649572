/* eslint-disable jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content */

import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Modal, Button, Image } from "semantic-ui-react";
import { PRIVACY_POLICY_URL, TERMS_AND_CONDITIONS_URL } from "../../_constants/constants";


export const WelcomeModal = ({ onAccepted }) =>
{
    /** @type {typeof import("../../_intl/resources.json")["en"]["translation"]["welcome modal"]} */
    const trans = useTranslation().t("welcome modal");

    const handleOnAccept = React.useCallback(() => onAccepted(TERMS_AND_CONDITIONS_URL), [onAccepted]);

    return (
        <Modal open size="small" className="welcome-modal">
            <Modal.Header>
                <Image src="/img/manage-m.png" as="span" />
                {trans["header"]}
            </Modal.Header>
            <Modal.Content>
                <p>
                    <Trans
                        i18nKey="welcome modal.privacy"
                        components={{
                            tocLink: <a href={TERMS_AND_CONDITIONS_URL} rel="noopener noreferrer" target="_blank" />,
                            privacyLink: <a href={PRIVACY_POLICY_URL} rel="noopener noreferrer" target="_blank"/>
                        }}
                    />
                </p>
            </Modal.Content>
            <Modal.Actions>
                <Button color="orange" size="big" content={trans["button"]} onClick={handleOnAccept} />
            </Modal.Actions>
        </Modal>
    );
};
