import React, { useContext, useMemo } from "react";
import { withTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";
import resources from "../resources.json";
import "./LanguageDropdown.css";
import { GLOBAL_DROPDOWN_LANG_KEYS } from "../constants";
import _ from "lodash";
import { LanguageContext } from "../../store/LanguageContext";
import CustomLangFlag from "../../components/common/customLangFlag/CustomLangFlag";

const LanguageDropdown = ({ t: trans, i18n }) =>
{
    const languageCtx = useContext(LanguageContext);
    const { languages } = languageCtx;

    const languageOptions = useMemo(
        () => Object.keys(resources).map((lang) => 
        {
            if (Object.keys(_.pick(languages, GLOBAL_DROPDOWN_LANG_KEYS)).includes(lang)) 
            {
                const langObj = languages[lang];

                return ({
                    text: langObj.title,
                    value: langObj.value,
                    key: langObj.value,
                    // description: <Transition visible={false}><Button onClick={(e) => console.log("MAGICAL")}>Set as default <Icon name="check" /></Button></Transition>
                    // TODO: Get default language hovering working in the dropdown
                });
            }
            else 
            {
                return null;
            }
        }).filter((val) => val != null), [languages]); 

    const changeLanguage = (lang) => i18n.changeLanguage(lang);

    return (
        <Dropdown button pointing
            className="languageDropdown"
            icon="angle down"
            onChange={(e, { value }) => changeLanguage(value)}
            header={trans("select language label")}
            options={languageOptions}
            text={i18n.language?.toUpperCase()}
            value={i18n.language}
        >
        </Dropdown>
    );
};

export default withTranslation()(LanguageDropdown);
