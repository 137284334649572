import React from "react";
import BrandingContex from "../../../../store/BrandingContext";
import { MapOverlaysContext } from "../../../../store/MapOverlaysContext";
import { Dropdown } from "semantic-ui-react";
import { MapButton } from "./MapButtons";
import { NO_RESULTS_FOUND } from "../../../../_constants/constants";
import { useTranslation } from "react-i18next";
import { DEFAULT_LANGUAGE_CODE } from "mapsted.maps/utils/map.constants";

export const MapSearch = () =>
{
    /** @type {typeof import("../../../_intl/resources.json")["en"]["translation"]["search placeholder"]} */
    const trans = useTranslation().t("search placeholder");
    const brandingCtx = React.useContext(BrandingContex);
    const mapOverlayCtx = React.useContext(MapOverlaysContext);
    const [isOpen, setIsOpen] = React.useState(false);
    const [searchOptions, setSearchOptions] = React.useState([]);
    const [isSearch, setIsSearch] = React.useState(false);


    const dropdownOptions = React.useMemo(() =>
    {
        let entities = {};
        if (brandingCtx.state?.mapData?.entities)
        {
            entities =  brandingCtx.state.mapData.entities;
        }
        
        let mapOverlays = [];
        if (mapOverlayCtx?.state?.mapOverlays)
        {
            mapOverlays = mapOverlayCtx.state.mapOverlays;
        }

        const floorId = brandingCtx.state.floorId || "-1";

        if (entities && mapOverlays)
        {
            let dropdownOptions = [];

            // add entity data to options
            dropdownOptions.push(...Object.values(entities)
                .filter((entity) => entity.entityLabel?.longName?.[DEFAULT_LANGUAGE_CODE] && entity.entityId !== -1)
                .map((entity) => ({
                    key: entity._id,
                    value: `${entity._id}__entity`,
                    text: entity.entityLabel.longName[DEFAULT_LANGUAGE_CODE],
                    description: entity.entityLabel?.shortName?.[DEFAULT_LANGUAGE_CODE] || "",
                    feature: entity.feature,
                    entityId: entity.entityId,
                    buildingId: entity.buildingId,
                    type: "entity"
                })));

            // add map overlays to options
            dropdownOptions.push(...mapOverlays.filter((mapOverlay) => mapOverlay.floorId === floorId)
                .map((mapOverlay) => ({
                    key: mapOverlay._id,
                    value: `${mapOverlay._id}__mapOverlay`,
                    text: mapOverlay.name[DEFAULT_LANGUAGE_CODE],
                    description: "",
                    vectorLayer: mapOverlay.vectorLayer,
                    mapOverlayId: mapOverlay._id,
                    type: "mapOverlay"
                })));

            return dropdownOptions;
        }
        else
        {
            return [];
        }

    }, [brandingCtx.state?.mapData, brandingCtx.state?.floorId, mapOverlayCtx?.state?.mapOverlays]);

    const toggleSearch = () => setIsOpen((current) => !current);

    const handleChange = React.useCallback((e, { value, options }) =>
    {
        const valueSplitParts = value.split("__");
        const optionType = valueSplitParts[valueSplitParts.length - 1];

        // handle entity search
        if (optionType === "entity")
        {
            let feature = options
                .filter((option) => option.type === "entity")
                .find((option) => option.value === value).feature;

            const geometry = feature.getGeometry();

            if (geometry)
            {
                mapOverlayCtx.handleSearch(geometry);
            }
        }
        // handle map overlay search
        else
        {
            let selectedOption = options
                .filter((option) => option.type === "mapOverlay")
                .find((option) => option.value === value);

            if (selectedOption) 
            {
                mapOverlayCtx.recenterMapOnOverlay(selectedOption.mapOverlayId, true);
            }
        }

        setSearchOptions([]);
        toggleSearch();
        setIsSearch(false);


    }, [mapOverlayCtx.handleSearch]);

    const handleSearchChange = React.useCallback((e, { searchQuery }) =>
    {
        if (searchQuery === "")
        {
            setSearchOptions([]);
            setIsSearch(false);

        }
        else
        {
            searchQuery = searchQuery.toLowerCase();

            const searchOptions = dropdownOptions.filter((option) => 
            {
                if (option.type === "entity")
                {
                    return option.text.toLowerCase().includes(searchQuery) 
                    || option.description.toLowerCase().includes(searchQuery)
                    || option.entityId === +searchQuery
                    || (option.buildingId && option.buidlingId === +searchQuery);
                }
                else
                {
                    return option.mapOverlayId === searchQuery
                    || option.text.toLowerCase().includes(searchQuery);
                }
                
            });

            searchOptions.sort((a, b) =>
            {
                let aDist = a.text.toLowerCase().indexOf(searchQuery);
                let bDist = b.text.toLowerCase().indexOf(searchQuery);

                if (aDist === -1)
                {
                    aDist = a.description.toLowerCase().indexOf(searchQuery);
                }

                if (bDist === -1)
                {
                    bDist = b.description.toLowerCase().indexOf(searchQuery);
                }

                return aDist - bDist;
            });

            setSearchOptions(searchOptions);
            setIsSearch(true);

        }

    }, [dropdownOptions]);

    const handleSearchOverwrite = () => searchOptions;

    return (
        <>
            <MapButton icon="search-default" onClick={toggleSearch} /> {/* this is trigger */}

            {
                (isOpen) && (
                    <Dropdown className="searchDropdown"
                        search={handleSearchOverwrite}
                        placeholder={trans}
                        searchInput={{ autoFocus: true }}
                        selectOnBlur={false}
                        onBlur={toggleSearch}
                        options={searchOptions}
                        onChange={handleChange}
                        onSearchChange={handleSearchChange}
                        openOnFocus={false}
                        noResultsMessage={isSearch ? NO_RESULTS_FOUND : null }
                        selectOnNavigation={false}
                    />
                )
            }

        </>
    );
};
