import { useSetState } from "ahooks";
import React from "react";
import { Button, Checkbox, Header, Input, Label, Placeholder } from "semantic-ui-react";
import { StoreSharingContext } from "../../store/StoreSharingProvider";
import { ModalLayout } from "../popups/ModalLayout";
import { areEqual, FixedSizeList as List } from "react-window";
import memoize from "memoize-one";
import "./SyncStoreModal.css";
import brandingApi from "../../_api/branding.api";
import { InputGroupWrap } from "../common/InputGroupWrap";
import { filerUrl } from "../../_utils/utils";
import { DEFAULT_STORE_LOGO, SINGLE_LANG_INPUT_CODE } from "../../_constants/constants";
import { useAllCompanyStores } from "../../_utils/hooks";
import { ErrorLabel } from "../elements/ErrorLabel";
import { useTranslation, Trans } from "react-i18next";
import { PROPERTIES } from "../../_constants/config";

const createStoreData = memoize((stores, setStoreActive) => ({
    stores,
    setStoreActive,
}));

export const SyncStoreModal = ({ open, setOpen, setLinkAndPinPopup, isEditMode, groupToEdit, openWithStore }) =>
{
    const trans = useTranslation().t;
    /** @type {import("../../../types/store").IAccessGroup} */
    const INITIAL_ACCESS_GROUP = {
        name: "",
        pin: ""
    };

    const ctx = React.useContext(StoreSharingContext);
    const { state: { fieldLanguage }, methods: { reinitGroups, setState: setCtxState } } = ctx;
    const allCompanyStores = useAllCompanyStores();

    const [state, setState] = useSetState({
        newGroup: INITIAL_ACCESS_GROUP,
        createButtonClicked: false,
        showStoresDNEMsg: false,
        stores: undefined,
        storesInitialized: false,
        searchQuery: "",
        createButtonDisabled: false
    });

    const { newGroup, newGroup: { name, pin }, createButtonClicked, showStoresDNEMsg, stores, searchQuery, storesInitialized } = state;

    React.useEffect(() =>
    {
        if (open && allCompanyStores.length > 0)
        {
            setState({
                stores: allCompanyStores.map((store) => ({
                    storeName: store?.longName?.[fieldLanguage],
                    mallName: store?.propertyName,
                    store: store,
                    key: `${store?.propertyName}-${store?._id}-${store?.longName?.[fieldLanguage]}`,
                    isSelected: false
                })),
                storesInitialized: true
            });
        }
    }, [allCompanyStores, open]);

    const setStoreActive = (id, checked) =>
    {
        const newStores = [...stores];
        const res = newStores.findIndex(({ store: { _id } }) => _id === id);
        newStores[res].isSelected = checked;
        setState({ stores: newStores });
    };

    const setEditStoresActive = (resources, checked = true) =>
    {
        const newStores = [...stores];
        for (const resource of resources)
        {
            const res = newStores.findIndex(({ store: { entityId, floorObjId, floors } }) => (entityId === resource.entityId) && (floors?.find((obj) => obj?._id === floorObjId)?.floorId === resource.floorId));
            if (res && res !== -1)
            {
                newStores[res].isSelected = checked;
            }
            else
            {
                setState({ showStoresDNEMsg: true });
            }
        }
        setState({ stores: newStores });
    };

    const setEditSidebarSelectedStoreActive = (entity, checked = true) =>
    {
        if (stores)
        {
            const newStores = [...stores];
            const res = newStores.findIndex(({
                store: {
                    entityLabelId
                }
            }) => entityLabelId === entity.entityLabel._id);
            if (res && res !== -1)
            {
                newStores[res].isSelected = checked;
            }
            else
            {
                setState({ showStoresDNEMsg: true });
            }
            setState({ stores: newStores });
        }
    };

    const deselectStore = (storeId) =>
    {
        const newStores = [...stores];
        const res = newStores.findIndex(({ store: { _id } }) => _id === storeId);
        newStores[res].isSelected = false;
        setState({ stores: newStores });
    };

    const filteredStores = React.useMemo(() => stores?.filter((store) => store?.storeName?.toUpperCase()?.includes(searchQuery?.toUpperCase())), [stores, searchQuery]);
    // eslint-disable-next-line multiline-ternary
    const storeData = (filteredStores?.length === 0) ? createStoreData([{
        noResultsFound: true,
        noResultsMessage: trans("SyncStoreModal.We_couldn't_find_any_results_for_that_se")
    }], setStoreActive) 
        : createStoreData(filteredStores, setStoreActive);

    const selectedStores = React.useMemo(() => stores?.filter(({ isSelected }) => isSelected), [stores]);

    React.useEffect(() =>
    {
        if (selectedStores)
        {
            const resourcesObj = selectedStores?.map((store) => ({
                propertyId: store.store.propertyId,
                entityId: store.store.entityId,
                buildingObjId: store.store.buildingObjId,
                floorId: store.store.floors?.find((obj) => obj?._id === store.store.floorObjId)?.floorId
            }));
            setState({ newGroup: { ...newGroup,  resources: resourcesObj } });
        }
    }, [selectedStores]);

    React.useEffect(() =>
    {
        if (stores)
        {
            if (isEditMode)
            {
                setState({ newGroup: groupToEdit });
                // select all stores in resources of group
                setEditStoresActive(groupToEdit.resources);
            }
            else
            {
                setState({ newGroup: INITIAL_ACCESS_GROUP });
                if (openWithStore)
                {
                    setEditSidebarSelectedStoreActive(openWithStore);
                    // setStoreActive(openWithStore, true);
                }
            }
            if (selectedStores.length > 0)
            {
                const resourcesObj = selectedStores.map((store) => ({
                    propertyId: store.store.propertyId,
                    entityId: store.store.entityId,
                    buildingObjId: store.store.buildingObjId,
                    floorId: store.store.floors?.find((obj) => obj?._id === store.store.floorObjId)?.floorId
                }));
                setState({ newGroup: { ...newGroup, resources: resourcesObj } });
            }
        }
    }, [groupToEdit, isEditMode, storesInitialized]);

    const wipeModalData = () =>
    {
        setState({
            newGroup: INITIAL_ACCESS_GROUP,
            showStoresDNEMsg: false,
            searchQuery: "",
            createButtonClicked: false,
            createButtonDisabled: false,
            stores: stores?.length > 0 ? stores?.map((store) => ({ ...store, isSelected: false })): []
        });
        setCtxState({ openWithStore: undefined });
        setOpen({ open: false, isEditMode: false });
    };

    const handleCreateGroup = () =>
    {
        if ((!Array.isArray(newGroup.resources) || !newGroup.resources.length) || !name || !pin || pin?.length < 4)
        {
            return;
        }

        setState({ createButtonDisabled: true });

        if (isEditMode)
        {
            const patchGroup = { ...newGroup };
            const id = patchGroup._id;
            delete patchGroup._id;
            delete patchGroup.__v;
            delete patchGroup.id;
            brandingApi.accessGroupUpdate(id, patchGroup)
                .then(() =>
                {
                    wipeModalData();
                    reinitGroups();
                }).catch((err) => console.log(err));
        }
        else
        {
            const createGroup = { ...newGroup };
            delete createGroup._id;
            delete createGroup.__v;
            delete createGroup.id;
            brandingApi.accessGroupCreate(createGroup)
                .then(({ _id, pin }) =>
                {
                    wipeModalData();
                    setLinkAndPinPopup({
                        open: true,
                        _id,
                        pin
                    });
                }).catch((err) => console.log(err));
        }
    };

    const PlaceholderHeaderImage = () => (
        <Placeholder>
            <Placeholder.Header image>
                <Placeholder.Line />
                <Placeholder.Line />
            </Placeholder.Header>
        </Placeholder>
    );

    const hasError = (allCompanyStores.length === 0) || (!name && createButtonClicked) || ((!pin || pin.length < 4) && createButtonClicked) || ((!Array.isArray(newGroup.resources) || !newGroup.resources.length) && createButtonClicked);
    return (
        <ModalLayout scrolling
            open={open}
            onClose={() => wipeModalData()}
            onOpen={() =>
            {
                if (!isEditMode)
                {
                    setState({
                        newGroup: INITIAL_ACCESS_GROUP,
                        createButtonClicked: false,
                        showStoresDNEMsg: false,
                        searchQuery: ""
                    });
                }
            }}
            className="syncStoreModal syncStoreModalGroup"
            heading={`${isEditMode ? trans("SyncStoreModal.Edit_Store_Group") : trans("SyncStoreModal.Create_Store_Group")}`}
            errors={<>
                {hasError && (allCompanyStores.length > 0) && <div className="defaultSyncListing">
                    <ErrorLabel multiple>
                        {!name && createButtonClicked && <>{trans("SyncStoreModal.Please_give_your_group_a_name_so_you_can")}<br /></>}
                        {!pin && createButtonClicked && <>{trans("SyncStoreModal.Please_secure_your_group_by_providing_a_")}<br /></>}
                        {pin?.length < 4 && createButtonClicked && <>{trans("SyncStoreModal.Pin_number_needs_to_be_at_least_4_digits")}<br /></>}
                        {(!Array.isArray(newGroup.resources) || !newGroup.resources.length) && createButtonClicked && trans("SyncStoreModal.You_haven’t_selected_any_stores__Please_")}
                    </ErrorLabel>
                </div>}
            </>}
            actions={<>
                <Button primary
                    color="orange"
                    content={isEditMode ? trans("SyncStoreModal.Update_Group") : trans("SyncStoreModal.Create_Shareable_Link")}
                    disabled={hasError || state.createButtonDisabled}
                    onClick={() =>
                    {
                        setState({ createButtonClicked: true });
                        // only update db if >=1 stores selected on edit or create
                        if (Array.isArray(newGroup.resources) && newGroup.resources.length)
                        {
                            handleCreateGroup();
                        }
                    }}
                    floated="right"
                />
            </>}>
            <div className="aligner">
                <InputGroupWrap label={trans("SyncStoreModal.Group_Name_")}>
                    <Input
                        placeholder={trans("SyncStoreModal.My_New_Group")}
                        value={name}
                        onChange={(e, { value }) => setState({ newGroup: { ...newGroup, name: value } })}
                        maxLength={PROPERTIES.MAX_GROUPNAME_LENGTH}
                    />
                </InputGroupWrap>

                <InputGroupWrap label={trans("SyncStoreModal.Pin_Number_")}>
                    <Input
                        placeholder="9876"
                        value={pin}
                        onChange={(e, { value }) =>
                        {
                            const parsedInt = parseInt(value);
                            if (value.length === 0)
                            {
                                setState({ newGroup: { ...newGroup, pin: "" } });
                            }
                            else if (parsedInt)
                            {
                                setState({ newGroup: { ...newGroup, pin: `${parsedInt}` } });
                            }
                        }}
                        maxLength={PROPERTIES.MAX_PIN_LENGTH}
                    />
                </InputGroupWrap>

                <p className="p-modal">
                    {trans("SyncStoreModal.From_the_list_below__select_all_the_stor")}
                    {trans("SyncStoreModal.Use_the_search_box_to_find_specific_stor")}
                </p>
                <Input placeholder={trans("SyncStoreModal.Search_Stores")} className="categorySearch" icon="search" onChange={(e, { value }) => setState({ searchQuery: value })} />

                {/* <div className="syncListingBox">
                    { allCompanyStores.map((store) => <SyncListingItem
                        storeName={store?.longName?.[fieldLanguage]}
                        mallName={store?.propertyName}
                        key={`${store?.propertyName}--${store?.longName?.[fieldLanguage]}`}
                    />
                    )}
                </div> */}

                {/* eslint-disable-next-line multiline-ternary */}
                { !storesInitialized
                    // eslint-disable-next-line multiline-ternary
                    ? <><PlaceholderHeaderImage/><PlaceholderHeaderImage/><PlaceholderHeaderImage/><PlaceholderHeaderImage/></>
                    : <List
                        className="syncListingBox"
                        height={320}
                        width={485}
                        itemSize={58}
                        itemData={storeData}
                        itemCount={filteredStores.length === 0 ? 1 : filteredStores.length}
                    >
                        {StoreListRow}
                    </List>}

                <div className="defaultSyncListing">
                    <Header>{trans("SyncStoreModal.Selected_Stores")} <span>({selectedStores?.length || 0})</span></Header>
                    {selectedStores?.map((store) => <StoreLabel
                        key={store?.store._id}
                        content={`${store?.storeName}, ${store?.mallName}`}
                        id={store?.store?._id}
                        deselectStore={deselectStore}
                    />
                    )}
                    {showStoresDNEMsg && <><Trans i18nKey="SyncStoreModal.Warning__Some_store(s)_in_this_group_do_" components={{ bolded: <b /> }} /><br /></>}
                </div>
            </div>
        </ModalLayout>
    );
};

const SyncListingItem = ({ storeName = "Blank", mallName = "Blank", onCheckBoxClick, style, checked, id, logoUrl }) => (
    <div className="syncListingItem" style={style}>
        <Checkbox
            checked={checked}
            onChange={(e, { checked }) => onCheckBoxClick(id, checked)}
        />
        <span className="syncListingImg">
            <img src={logoUrl || DEFAULT_STORE_LOGO} alt="" />
        </span>
        <div className="syncListingBody">
            {storeName}
            <span>{(mallName.length >= 35) ? mallName.substr(0, 35) + "..." : mallName}</span>
        </div>
    </div>
);

const StoreListRow = React.memo(({ data, index, style }) =>
{

    // Data passed to List as "itemData" is available as props.data
    const { stores, setStoreActive } = data;
    const store = stores?.[index];

    const floor = store?.store?.floors?.find((obj) => obj?._id === store?.store?.floorObjId);
    const floorName = floor?.shortName?.[SINGLE_LANG_INPUT_CODE] || floor?.longName?.[SINGLE_LANG_INPUT_CODE];

    return store?.noResultsFound ? <span>{store?.noResultsMessage}</span> : <SyncListingItem
        storeName={store?.storeName}
        mallName={`${store?.mallName} - ${floorName}`}
        onCheckBoxClick={setStoreActive}
        id={store?.store?._id}
        style={style}
        checked={store?.isSelected}
        logoUrl={store.store?.iconImage?.[SINGLE_LANG_INPUT_CODE] ? filerUrl(store.store?.iconImage?.[SINGLE_LANG_INPUT_CODE]) : undefined}
    />;
}, areEqual);

export const StoreLabel = ({ content, id, deselectStore }) => (
    <Label className="labelTag">
        {content}
        <Button basic className="buttonCross" onClick={() => deselectStore(id)}>
            <img src="/img/icon-cross-dark.svg" alt="" />
        </Button>
    </Label>
);
