const { Feature, Collection } = require("ol");
const { Draw, Pointer, Snap, Modify, Translate } = require("ol/interaction");
const { createEntityStyle } = require("../mapFunctions/plotting");
const { getCenter, getHeight, getWidth, clone, containsXY } = require("ol/extent");
const { default: OlExtTransform } = require("ol-ext/interaction/Transform");
const
    {
        never,
        platformModifierKeyOnly,
        primaryAction,
        altKeyOnly,
        shiftKeyOnly, always
    } = require("ol/events/condition");
const { default: VectorSource } = require("ol/source/Vector");
const { Style, Fill, Stroke } = require("ol/style");
const { default: CircleStyle } = require("ol/style/Circle");
const { MultiPoint, LineString, } = require("ol/geom");
const { ShapeTypes } = require("./entityTypes");
const { deepCopy } = require("mapsted.utils/objects");


// https://openlayers.org/en/latest/apidoc/module-ol_interaction_Pointer-PointerInteraction.html
exports.MouseDownInteraction = ({ source, handleDownEvent }) =>
{
    let interaction = new Pointer({
        source: source,
        handleDownEvent: handleDownEvent
    });

    return interaction;
};

/**
 * Note: handle event function should return true to allow propagation.
 * If you would like to stop propagation simply return false.
 * @param {*} param0
 * @returns
 */
exports.MouseInteraction = ({ source, handleEvent }) =>
{
    let interaction = new Pointer({
        source: source,
        handleEvent: handleEvent
    });

    return interaction;
};

exports.MouseMoveInteraction = ({ source, handleMoveEvent }) =>
{
    let interaction = new Pointer({
        source: source,
        handleMoveEvent: handleMoveEvent
    });

    return interaction;
};

exports.MouseDragInteraction = ({ source, handleDownEvent, handleDragEvent, handleUpEvent }) =>
{
    let interaction = new Pointer({
        source: source,
        handleDownEvent: handleDownEvent,
        handleDragEvent: handleDragEvent,
        handleUpEvent: handleUpEvent
    });

    return interaction;
};

exports.DrawInteraction = ({ source, type, maxPoints, style, handleDrawEndEvent, handleMoveEvent, handleFinishCondition, geometryFunction }) =>
{
    if (style)
    {
        drawStyle = createEntityStyle(style);
    }

    let draw = new Draw({
        source: source,
        type: type,
        freehand: false,
        maxPoints: maxPoints,
        handleMoveEvent: handleMoveEvent,
        geometryFunction: geometryFunction,
        finishCondition: handleFinishCondition,
    });

    (handleDrawEndEvent) && draw.addEventListener("drawend", handleDrawEndEvent);
    return draw;
};

exports.SnapInteraction = ({ source, pixelTolerance, edge = true, }) =>
{
    let snap = new Snap({
        source: source,
        pixelTolerance: pixelTolerance,
        edge: edge

    });

    return snap;
};

/**
 * @param {Array<Feature>} param0.features
 * @returns modify interaction
 */
exports.ModifyInteraction = ({ features = [], condition, deleteCondition, onModifyStart, onModifyEnd }) =>
{
    let featureCollection = new Collection(features);
    let modify = new Modify({
        features: featureCollection,
        deleteCondition: deleteCondition,
        condition,
    });

    modify.on('modifyend', function (event)
    {
        (onModifyEnd) && onModifyEnd(event);
    });

    modify.on('modifystart', function (event)
    {
        (onModifyStart) && onModifyStart(event);
    });

    return modify;
};

/**
 * @param {Array<Feature>} param0.features
 * @returns translate interaction
 */
exports.TranslateInteraction = ({ features = [], source, condition, hitTolerance, onTranslateStart, onTranslating, onTranslateEnd }) =>
{
    let featureCollection = new Collection(features);

    let translate = new Translate({
        features: featureCollection,
        condition,
        hitTolerance,
        source: source
    });

    translate.on('translateend', function (event)
    {
        (onTranslateEnd) && onTranslateEnd(event);
    });

    translate.on('translatestart', function (event)
    {
        (onTranslateStart) && onTranslateStart(event);
    });

    translate.on("translating", function (event)
    {
        (onTranslating) && onTranslating(event);
    });

    return translate;
};


/**
 *
 * @param {Feature} feature
 * @param {Style} style
 * @returns
 */
exports.modifyEntityStyleFunction = (feature, style, options = { addVertexStyle: true, addEdgeStyle: true }) =>
{
    if (!style)
    {
        style = new Style({});
    }

    let workingFeature = feature;
    const clonedFeature = feature.get("clonedFeature");

    if (clonedFeature)
    {
        workingFeature = clonedFeature;
    }

    const modifyGeometry = workingFeature.get('modifyGeometry');
    const geometry = modifyGeometry
        ? modifyGeometry.geometry
        : workingFeature.getGeometry();

    const result = calculateCenter(geometry);
    // const center = result.center;
    style.setGeometry(geometry);

    const styles = [style];

    const coordinates = result.coordinates;

    if (coordinates)
    {
        let edgeCoordinates = deepCopy(coordinates);

        // add the first coordinate at the end so that the highlight edge closes to create a polygon
        if (geometry.getType() === ShapeTypes.POLYGON)
        {
            edgeCoordinates.push(edgeCoordinates[0]);
        }

        if (options && options.addVertexStyle)
        {
            styles.push(
                new Style({
                    geometry: new MultiPoint(coordinates),
                    image: new CircleStyle({
                        radius: 4,
                        fill: new Fill({
                            color: '#727AFF',
                        }),
                    }),
                    zIndex: 1000000
                }));
        }
        if (options && options.addEdgeStyle)
        {
            styles.push(
                new Style({
                    geometry: new LineString(edgeCoordinates),
                    stroke: new Stroke({ color: '#727AFF', }),
                }));
        }
    }
    return styles;
};


const defaultStyle = new Modify({ source: new VectorSource() })
    .getOverlay()
    .getStyleFunction();

function calculateCenter(geometry)
{
    let center, coordinates, minRadius;
    const type = geometry.getType();
    if (type === 'Polygon')
    {
        let x = 0;
        let y = 0;
        let i = 0;
        coordinates = geometry.getCoordinates()[0].slice(1);
        coordinates.forEach(function (coordinate)
        {
            x += coordinate[0];
            y += coordinate[1];
            i++;
        });
        center = [x / i, y / i];
    } else if (type === 'LineString')
    {
        center = geometry.getCoordinateAt(0.5);
        coordinates = geometry.getCoordinates();
    } else
    {
        center = getCenter(geometry.getExtent());
    }
    let sqDistances;
    if (coordinates)
    {
        sqDistances = coordinates.map(function (coordinate)
        {
            const dx = coordinate[0] - center[0];
            const dy = coordinate[1] - center[1];
            return dx * dx + dy * dy;
        });
        minRadius = Math.sqrt(Math.max.apply(Math, sqDistances)) / 3;
    } else
    {
        minRadius =
            Math.max(
                getWidth(geometry.getExtent()),
                getHeight(geometry.getExtent())
            ) / 3;
    }
    return {
        center: center,
        coordinates: coordinates,
        minRadius: minRadius,
        sqDistances: sqDistances,
    };
}

/**
 * Creates a ModifyScaleRotateInteraction object that allows for scaling and rotating features on a map.
 *
 * @param {Object} options - An object containing the following properties:
 *   - source: The source of the features to be modified.
 *   - features: An array of features to be modified.
 *   - condition: A function that determines if the modify interaction should be active.
 *   - onModifyStart: A function to be called when the modify interaction starts.
 *   - onModifyEnd: A function to be called when the modify interaction ends.
 * @return {Modify} The ModifyScaleRotateInteraction object.
 */
exports.ModifyScaleRotateInteraction = ({ source, features, condition, onModifyStart, onModifyEnd, }) =>
{
    let featureCollection = new Collection(features);
    let modify = new Modify({
        features: featureCollection,
        source: source,
        condition: function (event)
        {
            return primaryAction(event) && !platformModifierKeyOnly(event);
        },
        deleteCondition: never,
        insertVertexCondition: never,
        condition: condition,
        style: function (feature)
        {
            feature.get('features').forEach(function (modifyFeature)
            {
                const modifyGeometry = modifyFeature.get('modifyGeometry');
                if (modifyGeometry)
                {
                    const point = feature.getGeometry().getCoordinates();
                    let modifyPoint = modifyGeometry.point;
                    if (!modifyPoint)
                    {
                        // save the initial geometry and vertex position
                        modifyPoint = point;
                        modifyGeometry.point = modifyPoint;
                        modifyGeometry.geometry0 = modifyGeometry.geometry;
                        // get anchor and minimum radius of vertices to be used
                        const result = calculateCenter(modifyGeometry.geometry0);
                        modifyGeometry.center = result.center;
                        modifyGeometry.minRadius = result.minRadius;
                    }

                    const center = modifyGeometry.center;
                    const minRadius = modifyGeometry.minRadius;
                    let dx, dy;
                    dx = modifyPoint[0] - center[0];
                    dy = modifyPoint[1] - center[1];
                    const initialRadius = Math.sqrt(dx * dx + dy * dy);
                    if (initialRadius > minRadius)
                    {
                        const initialAngle = Math.atan2(dy, dx);
                        dx = point[0] - center[0];
                        dy = point[1] - center[1];
                        const currentRadius = Math.sqrt(dx * dx + dy * dy);
                        if (currentRadius > 0)
                        {
                            const currentAngle = Math.atan2(dy, dx);
                            const geometry = modifyGeometry.geometry0.clone();
                            geometry.scale(currentRadius / initialRadius, undefined, center);
                            geometry.rotate(currentAngle - initialAngle, center);
                            modifyGeometry.geometry = geometry;
                        }
                    }
                }
            });

            return defaultStyle(feature);
        },
    });

    modify.on('modifystart', function (event)
    {
        event.features.forEach(function (feature)
        {
            feature.set(
                'modifyGeometry',
                { geometry: feature.getGeometry().clone() },
                true,
            );
        });

        (onModifyStart) && onModifyStart(event);
    });

    modify.on('modifyend', function (event)
    {
        event.features.forEach(function (feature)
        {
            const modifyGeometry = feature.get('modifyGeometry');
            if (modifyGeometry)
            {
                feature.setGeometry(modifyGeometry.geometry);
                feature.unset('modifyGeometry', true);
            }
        });

        (onModifyEnd) && onModifyEnd(event);
    });

    return modify;
};



exports.OlExtTransformInteraction = ({
    features,
    onStartInteraction,
    onEndInteraction,
    onInteracting,
    translateFeature,
    scale,
    stretch,
    translate,
    rotate,
    keepAspectRatio = always,
    hitTolerance = 2,
    onSelect,
    keepRectangle,
    filter,
    pointRadius = function (f)
    {
        return f.get("radius") || [1, 1];
    },
    addCondition,
    condition,
    enableRotatedTransform,
    layers,
    selection = true,
    noFlip = true,
    modifyCenter = () => false,///use opposite corner to scale/stretch, (false = use center)

}) =>
{
    const interaction = new OlExtTransform({
        features,
        scale,
        rotate,
        stretch,
        translate,
        keepAspectRatio,
        translateFeature,
        addCondition,
        hitTolerance,
        condition,
        keepRectangle,
        pointRadius,
        enableRotatedTransform,
        filter,
        modifyCenter,
        layers,
        selection,
        noFlip
    });
    interaction.on(['rotatestart', 'translatestart', 'scalestart'], onStartInteraction);
    interaction.on(['rotateend', 'translateend', 'scaleend'], onEndInteraction);
    interaction.on("translating", onSelect);
    interaction.on(["translating", "scaling", "rotating"], onInteracting);
    return interaction;
};
