import React from "react";
import { ModalLayout } from "./ModalLayout";
import { Grid, Button, Header, Popup } from "semantic-ui-react";
import { AddEditHoliday } from "./AddEditHoliday";
import { GridTableColumn } from "../elements/GridTableColumn";
import { GridTable } from "../elements/GridTable";
import { getTimeFromMins, formatDate, isPeriodOpen24Hours } from "../../_utils/utils";
import moment from "moment";
import { QuestionPopup } from "./QuestionPopup";
import { useTranslation } from "react-i18next";
import { LANGUAGE_CODE } from "../../_constants/constants";

/**
 * Holiday hours have been patched to used the new system of partial hours via back end but not front end
 * Because of this we still only allow one opening/close period for each holiday but the system is ready to add multiple
 *
 * Currently this is the flow when a holiday is being edited or created.
 *
 * 1. Convert partial opening hours system to old opening hours system
 * 2. Edit in old system
 * 3. On save, convert to partial opening hours system before sending back to DB
 *
 */

const sortAndMergeHolidays = (holidays) =>
{
    holidays.sort((a, b) =>
    {
        const date1 = moment(a.date).format("YYYYMMDD");
        const date2 = moment(b.date).format("YYYYMMDD");
        if (date1 === date2)
        {
            return (a.public) ? 1 : -1;
        }
        else
        {
            return date1 - date2;
        }
    });

    const removeDates = [];

    for (let i = 0; i < holidays.length - 2; i++)
    {
        const date1 = moment(holidays[i].date).format("YYYYMMDD");
        const date2 = moment(holidays[i + 1].date).format("YYYYMMDD");

        if (date1 === date2)
        {
            removeDates.push(i + 1);
        }
    }

    removeDates.reverse();

    removeDates.forEach((i) => holidays.splice(i, 1));

    return holidays;
};

export const CalendarModal = ({ isProperty, selectedObject, onGetHolidays, onEditHoliday, languageCode = LANGUAGE_CODE, triggerClassName = "" }) =>
{
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [holidays, setHolidays] = React.useState([]);
    const [selectedDates, setSelectedDates] = React.useState([]);
    const [year, setYear] = React.useState(moment().year());

    // Create a list of dates (for selected dates)
    React.useEffect(() =>
    {
        let selectedDates = holidays.map((holiday) => moment(holiday.date));

        setSelectedDates(selectedDates);
    }, [holidays]);

    const handleOpenYearCalendar = React.useCallback(async (selectedYear, isCalanderOpening) =>
    {
        if ((year !== selectedYear) || (isCalanderOpening))
        {
            let [holidays, publicHolidays] = await onGetHolidays(selectedYear);

            //TODO: Push public holidays into holiday list. default empty array to display "Set buisness hours"
            Object.keys(publicHolidays).forEach((strDate) =>
            {
                // check if holiday name exists in given language code
                let holidayName = publicHolidays[strDate][languageCode];

                // if not, use defualt language code
                if (!holidayName)
                {
                    holidayName = publicHolidays[strDate][LANGUAGE_CODE];
                }

                holidays.push({
                    date: moment(strDate).toDate(),
                    name: holidayName,
                    periods: [],
                    public: true
                });
            });

            holidays = sortAndMergeHolidays(holidays);

            setHolidays(holidays);
            setYear(selectedYear);
        }

    }, [onGetHolidays, year, languageCode]);

    const handleOpenCalendar = React.useCallback(async () =>
    {
        let loadYear = moment().year();
        setHolidays([]);
        handleOpenYearCalendar(loadYear, true);
        setIsModalOpen(true);

    }, [handleOpenYearCalendar]);

    const handleSave = React.useCallback(async (holiday) =>
    {
        const { success, data } = await onEditHoliday(holiday.data, holiday.allBuildings, holiday.remove);

        if (success)
        {
            let newHolidays = [...holidays];

            if (holiday.index !== undefined)
            {
                if (holiday.remove)
                {
                    newHolidays.splice(holiday.index, 1);
                }
                else
                {
                    newHolidays[holiday.index] = data;
                }
            }
            else
            {
                newHolidays.push(data);

                newHolidays = sortAndMergeHolidays(newHolidays);
            }

            setHolidays(newHolidays);
        }

    }, [holidays, onEditHoliday]);

    const handleCloseCalendar = React.useCallback(() =>
    {
        setYear(moment().year());
        setIsModalOpen(false);
    }, []);

    const trans = useTranslation().t;

    return (
        <>
            <Button className={`trigger-calendar ${triggerClassName}`} onClick={handleOpenCalendar}>
                <img src="/img/icon-calendar.svg" alt="" />
                {trans("CalendarModal.Update_{{currentYear}}_holiday_calendar", { currentYear: year })}
            </Button>

            <ModalLayout className="modal-calendar" topAligned={true}
                // heading={<CalendarModalHeading year={year} onYearUpdate={handleOpenYearCalendar} />}
                onClose={handleCloseCalendar}
                open={isModalOpen}>
                <div className="aligner">
                    <Header as="h4" className="header-button">
                        <CalendarModalHeading year={moment().year()} selected={year === moment().year()} onYearUpdate={handleOpenYearCalendar} />
                        <CalendarModalHeading year={moment().year() + 1} selected={year === moment().year() + 1} onYearUpdate={handleOpenYearCalendar} />
                    </Header>
                    {
                        (!Array.isArray(holidays) || holidays.length === 0) && (
                            <div className="emptyCalendar">
                                {trans("CalendarModal.Your_country’s_holiday_schedule_is_curre")} <br />
                                {trans("CalendarModal.Please_manually_update_your_holiday_sche")}
                            </div>
                        )
                    }

                </div>

                <div className="aligner">
                    <AddEditHoliday
                        header={`${year} ${trans("CalendarModal.Public_Holiday")}`}
                        onSave={(newHoliday, allBuildings) => handleSave({ data: newHoliday, allBuildings })}
                        isProperty={isProperty}
                        selectedDates={selectedDates}
                        trigger={
                            <Button className="trigger-holiday" >
                                <img src="/img/icon-plus-filled.svg" alt="Icon plus" />
                                <Popup
                                    content={trans("CalendarModal.Add_holiday")}
                                    basic
                                    trigger={
                                        <div className="text">{trans("CalendarModal.Add_holiday")}</div>
                                    } 
                                />
                               
                            </Button>}
                        selectedYear={year}
                    />
                    <CalendarGridTable isProperty={isProperty} holidays={holidays} onSave={(editHoliday) => handleSave(editHoliday)} />
                </div>

            </ModalLayout >
        </>
    );
};


const CalendarModalHeading = ({ year, selected, onYearUpdate }) =>
{
    const className = selected ? "active" : "";
    const trans = useTranslation().t;
    return (
        <>
            <span className={className} onClick={() => onYearUpdate(year)} >
                {year} {trans("CalendarModal.Public_Holidays")}
            </span>
        </>
    );
};

// OLD CODE
// const CalendarModalHeading = ({ year, onYearUpdate }) =>
// {
//     const iconName = moment().year() === year ? "arrow right" : "arrow left";
//     const nextYear = moment().year() === year ? year + 1 : year - 1;

//     return (
//         <Header as="h4" className="header-button">
//             {`${year} Public Holidays `}
//             <Icon name={iconName} onClick={() => onYearUpdate(nextYear)} />
//         </Header>
//     )
// }


const RenderHoursColumn = ({ holiday, index, isProperty, isDateInPast, onSave }) =>
{
    const trans = useTranslation().t;
    const periods = holiday.periods;

    const isOpen24Hours = isPeriodOpen24Hours(periods, true);

    if (!holiday.public || isDateInPast)
    {
        if (isOpen24Hours)
        {
            return trans("CalendarModal.Open_24_Hours");
        }
        else if (Array.isArray(periods) && periods.length > 0)
        {
            // patch fix, using partial hours but assuming each holiday just has one set of open/close times
            const openPeriod = periods[0];
            return getTimeFromMins(openPeriod.open.time) + " - " + getTimeFromMins(openPeriod.close.time);
        }
        else
        {
            return trans("CalendarModal.Closed");
        }
    }
    else
    {
        return (
            <AddEditHoliday
                businessHours={trans("CalendarModal.Set_Business_Hours")}
                isProperty={isProperty}
                holiday={holiday}
                onSave={(editHoliday, allBuildings, remove) => onSave({ index, data: editHoliday, remove, allBuildings })}
                trigger={
                    <Button
                        className="trigger-holiday business-hours"
                        content={trans("CalendarModal.Set_business_hours")} >
                    </Button>}
            />
        );
    }
};


export const CalendarGridTable = ({ isProperty, holidays, onSave }) =>
{
    const trans = useTranslation().t;
    return (
        <GridTable className="grid-modal">
            <Grid.Row>
                <GridTableColumn title={trans("CalendarModal.Date")} />
                <GridTableColumn title={trans("CalendarModal.Hours")} subDetail={
                    <QuestionPopup
                        content={trans("CalendarModal.This_date_is_an_official_Public_Holiday_")} />} />
                <GridTableColumn title={trans("CalendarModal.Holiday")} />
            </Grid.Row>
            {
                holidays.map((holiday, index) =>
                {
                    const isDateInPast = moment().diff(holiday.date, "days") > 0;
                    return (
                        <Grid.Row key={index}>
                            <GridTableColumn detail={formatDate(holiday.date, "DD MMMM,")} subDetail={formatDate(holiday.date, "YYYY")} />
                            <GridTableColumn detail={<RenderHoursColumn holiday={holiday} index={index} isProperty={isProperty} isDateInPast={isDateInPast} onSave={onSave} />} />
                            <GridTableColumn>
                                <AddEditHoliday edit
                                    isProperty={isProperty}
                                    holiday={holiday}
                                    onSave={(editHoliday, allBuildings, remove) => onSave({ index, data: editHoliday, remove, allBuildings })}
                                    trigger={
                                        <Button
                                            disabled={isDateInPast}
                                            className={`trigger-holiday ${isDateInPast ? "trigger-disable" : ""}`}
                                            content={holiday.name} >
                                        </Button>}
                                />

                            </GridTableColumn>
                        </Grid.Row>
                    );
                })
            }
        </GridTable>
    );
};
