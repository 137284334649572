import React from "react";
import { Header } from "semantic-ui-react";
import "./CustomLegend.css";

const LegendItem = ({ bgColor, content, onClick, iconUrl }) => <p onClick={onClick}><span style={{ background: bgColor }}>
    {iconUrl && <img src={`${iconUrl}`} />}
</span>{content}</p>;

/**
 * Generates a custom legend component.
 *
 * @param {Array} legends - An array of legend objects.
 * @param {Function} onLegendClick - A function to handle legend click events.
 * @param {Object} legendMeta - An object containing metadata for the legends.
 * @param {string} legendMeta.labelKey - The key to use for the legend label.
 * @param {string} legendMeta.colorKey - The key to use for the legend color.
 * @return {ReactElement} The custom legend component.
 */
export const CustomLegend = ({ legendOptions = [], onLegendClick, legendMeta = { labelKey: "label", colorKey: "color", }, header, }) =>
{
    const { colorKey, labelKey, iconBaseUrl } = legendMeta;
    return <div className="legendsPopbox">
        {(header) && <Header content={header} />}
        {legendOptions.map((d) => <LegendItem
            key={"charts-legends" + d[colorKey]}
            bgColor={d[colorKey]}
            content={d[labelKey]}
            iconUrl={d.iconUrl}
            iconBaseUrl={iconBaseUrl}
            onClick={(e) => onLegendClick?.(e, d)}
        />)}
    </div>;
};
