import React, { useState, useContext } from "react";
import { ButtonIcon } from "../../../common/ButtonIcon";
import { DEFAULT_LANGUAGE_CODE } from "mapsted.maps/utils/map.constants";
import { useOverlaysTemplateContext } from "../../../../store/OverlaysTemplateContext";
import DeleteConfirmationTemplateGroupModal from "./DeleteConfirmationTemplateGroupModal";
import { truncateText } from "../../../../_utils/mapUtils";
import MapOverlaysList from "./MapOverlaysList";
import { MapOverlaysContext } from "../../../../store/MapOverlaysContext";

const OverlayTemplateItem = ({ templateItem }) => 
{
    const { handleEditOverLayTemplate, deleteOverlaysTemplate, zonesTemplates } =
    useOverlaysTemplateContext();
    const {
        initiateCreation,
        handleOverlayTemplateSelection
    } = useContext(MapOverlaysContext);
    
    const [showLinkedMapOverlays, setShowLinkedMapOverlays] = useState(false);
    const hasLinkedMapOverlays = !!templateItem.mapOverlays.length;

    const hasLinkedZones = zonesTemplates?.includes(templateItem.templateId);

    function handleMapOverlayCreation()
    {
        initiateCreation();
        handleOverlayTemplateSelection(templateItem.templateId);
    }

    return (
        <div className="overlay-template-item-bx">
            <div className="overlay-template-info-bx">
                <div
                    className={
                      templateItem?.group ? "templateHeading" : "templateHeadingIndent"
                    }
                >
                    <img
                        src={"/img/icon-overlay-template.svg"}
                        style={{ height: "17px", width: "17px" }}
                        alt="Group Name"
                    />
                    <p className="activitySidebar-overlayName">
                        {truncateText(templateItem.name[DEFAULT_LANGUAGE_CODE], 25)}
                    </p>
                    {hasLinkedMapOverlays && (
                        <img
                            onClick={() => setShowLinkedMapOverlays((prev) => !prev)}
                            src={"/img/icon-editor-chevron-down.svg"}
                            className={`expand-icon ${
                                showLinkedMapOverlays ? "expanded" : ""
                            }`}
                            alt="Group Name"
                        />
                    )}
                </div>
                <div className="overlayConfigActions templateActionButtons">
                    <ButtonIcon
                        icon="plus-filled"
                        onClick={handleMapOverlayCreation}
                    />
                    <ButtonIcon
                        icon="edit"
                        disabled={false}
                        onClick={() => handleEditOverLayTemplate(templateItem?._id)}
                    />

                    <DeleteConfirmationTemplateGroupModal
                        templateGroupItem={templateItem}
                        onDelete={deleteOverlaysTemplate}
                        templateToBeDeleted={true}
                        disabled={hasLinkedMapOverlays || hasLinkedZones}
                    />
                </div>
            </div>
            {hasLinkedMapOverlays && showLinkedMapOverlays && (
                <MapOverlaysList
                    mapOverlays={templateItem.mapOverlays}
                ></MapOverlaysList>
            )}
        </div>
    );
};

export default OverlayTemplateItem;
