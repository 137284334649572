import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import useMapEditorPermission from "./useMapEditorPermission";
import { Loader } from "../../elements/loader";

const MapEditPermissionWrapper = ({ redirectToBranding = false, children }) =>
{
    const { permissionsQuery } = useMapEditorPermission();
    const history = useHistory();

    useEffect(() =>
    {
        if (redirectToBranding && !permissionsQuery.data?.hasPermissions && !permissionsQuery.isLoading)
        {
            history.push("/branding");
        }

        // map editor  provider was expecting some data from the local storage (on mount) but it was set only from the branding page4
        //even if our permission query is loading navigating to the branding page  will set need full data to local storage
        // if (redirectToBranding && !permissionsQuery.data?.hasPermissions)
        // {
        //     history.push("/branding");
        // }
    }, [redirectToBranding, permissionsQuery.data?.hasPermissions]);

    if (permissionsQuery.isLoading)
    {
        return <Loader active />;
    }

    if (permissionsQuery.data?.hasPermissions)
    {
        return children;
    }
    return null;
};

export default MapEditPermissionWrapper;
