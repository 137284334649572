import React, { useCallback, useContext, useMemo } from "react";
import { Button } from "semantic-ui-react";
import "./mapStyles/MapButtons.css";
import BrandingContext from "../../../store/BrandingContext";
import { MAP_TOOLS, SINGLE_LANG_INPUT_CODE } from "../../../_constants/constants";
import { getSelectedEntity } from "mapsted.maps/mapFunctions/features";
import { MapSearch } from "./MapSearch";
import MapEditorContext from "../../../store/MapEditiorContext";

export const MapButtons = ({
    onZoomIn,
    onZoomOut,
    onSearch = undefined,
    children = undefined,
    floorButtons = true,
    zoomButtons = true,
}) =>
{
    const brandingContext = React.useContext(BrandingContext);

    const isOpen = React.useMemo(() =>
    {
        const { selectedTool, selectedEntities } = brandingContext.state;
        const { selectedEntityId } = getSelectedEntity(selectedEntities);
        let open = true;

        if (selectedTool === MAP_TOOLS.EDIT && selectedEntityId )
        {
            open = false;
        }

        return open;
    }, [brandingContext.state]);

    return (
        <div className={`buttons-wrap ${(!isOpen) ? "buttons-edit-active" : ""}`}>
            {(onSearch) && (
                <div className="buttons-group searchMap">
                    <MapSearch onSearch={onSearch} />
                </div>
            )}
            {(zoomButtons) && (
                <div className="buttons-group staysActive">
                    <MapButton content={<span className="zoomIn" />} onClick={onZoomIn} />
                    <MapButton content={<span className="zoomOut" />} onClick={onZoomOut} />
                </div>
            )}

            {(floorButtons && brandingContext.state.buildingId) && (
                <FloorButtons />
            )}
            {children}
        </div >
    );
};

export const FloorButtons = ({ isGeoreference }) =>
{
    const brandingContext = useContext(BrandingContext);
    const editorContext = useContext(MapEditorContext);

    const buildingId = useMemo(() =>
    {
        if (isGeoreference)
        {
            return editorContext.state.georeferenceBuildingId;
        }
        else
        {
            return brandingContext.state.buildingId;
        }
    }, [brandingContext.state, editorContext.state, isGeoreference]);

    const floorId = useMemo(() =>
    {
        if (isGeoreference)
        {
            return editorContext.state.georeferenceFloorId;
        }
        else
        {
            return brandingContext.state.floorId;
        }
    }, [brandingContext.state, editorContext.state, isGeoreference]);

    const floors = useMemo(() =>
    {
        let { propertyId, properties } = brandingContext.state;

        if (buildingId)
        {
            return properties[propertyId]?.buildings[buildingId]?.floors || [];
        }

        return [];
    }, [brandingContext.state, buildingId]);

    const handleOnClick = useCallback((floorId) =>
    {
        if (isGeoreference)
        {
            editorContext.changeSelectedFloor(floorId);
        }
        else
        {
            brandingContext.changeSelectedFloor(floorId)
        }
    }, [brandingContext]);

    return (
        <div className="buttons-group">
            {floors.map((floor) => (
                <MapButton
                    key={floor._id}
                    content={floor.shortName?.[SINGLE_LANG_INPUT_CODE]}
                    isActive={floorId === floor._id}
                    onClick={() => handleOnClick(floor._id)}
                />
            ))}
        </div>
    );
};

export const MapButton = ({
    icon = undefined,
    content = undefined,
    isActive = false,
    onClick
}) => (
    <Button className={isActive ? "active" : ""} onClick={onClick}>
        {content}
        {(icon) && (<img src={`/img/icon-${icon}.svg`} alt="icon" />)}
    </Button>
);
