const { Feature } = require("ol");
const { Vector: VectorLayer } = require("ol/layer");
const { Vector: VectorSource, Cluster: ClusterSource } = require("ol/source");
const { Style, Fill, Stroke, Text } = require("ol/style");
const { getCenter } = require("ol/extent");
const { toMercatorFromArray } = require("../utils/map.utils");
const { MapConstants } = require("../utils/map.constants");
const PolygonController = require("./polygons");
const { MAP_TEXT_STYLE } = require("../utils/defualtStyles");

class TextController
{
    constructor({ accessor })
    {
        this.accessor = accessor;
        this.polygonController = new PolygonController({ accessor });
    }

    createLayer ()
    {
        // Create the text layer to be clustered.
        const textLayer = new VectorLayer({
            id: [MapConstants.TEXT_LAYER],
            style: this.getClusterStyle.bind(this)
        });
        const textVectorSource = new VectorSource();
        const clusterSource = new ClusterSource({
            source: textVectorSource,
            distance: MapConstants.CLUSTER_DISTANCE,
        });
        textLayer.setSource(clusterSource);

        return textLayer;
    }

    createEntityTextFeature (entity, style, feature)
    {
        const access = new this.accessor(entity);

        const textStyle = access.getTextStyleObject(style);
        const entityTextStyle = this.createEntityTextStyle(entity, textStyle);

        const entityGeometry = this.polygonController.createEntityGeometry(entity);
        let textCoordinate = getCenter(entityGeometry.getExtent());
        if (access.getTextCoordinate())
        {
            textCoordinate = toMercatorFromArray(access.getTextCoordinate());
        }

        // Create text geometry
        const entityTextGeometry = this.polygonController.createEntityGeometry({ shape: { type: "TextPoint", coordinates: textCoordinate } });

        // attach it to style
        entityTextStyle.setGeometry(entityTextGeometry);

        let textFeature = new Feature({
            geometry: entityTextGeometry,
            type: "TextPoint",
            coordinates: textCoordinate,
            id: access.getId() + "text",
            entityId: access.getId(),
            canName: access.getCanNameEntity(),
            isText: true,
            connectedFeature: feature,
        });

        (entityTextStyle) && textFeature.setStyle(entityTextStyle);

        return { textFeature, textCoordinate };
    }

    /**
     * Creates an entity's text style given entity information.
     *
     * @param {object} entity
     * @param {*} textStyle
     */
    createEntityTextStyle (entity, textStyle)
    {
        const access = new this.accessor(entity);
        const name = access.getName({ useStyle: true });

        let style = new Style(MAP_TEXT_STYLE);

        if (name)
        {
            const text = this.createTextStyle(name, access.getTextRotation(), textStyle);
            style.setText(text);
        }

        return style;
    }

    /**
     * Applys placment and decision logic to the clusteres based off of num words and area of entity.
     * @param {Object} feature - feature passed by style function
     */
    getClusterStyle (feature)
    {
        let textFeatures = feature.get("features");
        let displayedTextFeature;

        if (textFeatures.length === 1)
        {
            displayedTextFeature = textFeatures[0];
        }
        else
        {
            const filteredTextFeatures = this.filterTextFeaturesRecursive(textFeatures, 1, 3);
            displayedTextFeature = this.getTextFeatureWithBiggestArea(filteredTextFeatures);
        }


        let style = undefined;
        if (displayedTextFeature)
        {
            style = displayedTextFeature.getStyle();
            textFeatures.connectedFeature = displayedTextFeature.get("connectedFeature");
        }

        textFeatures.displayedTextFeature = displayedTextFeature;

        feature.set("features", textFeatures);

        return style;
    }

    /**
     * Used for text clustering.
     * Finds the text feature that has the biggest area.
     * Looks at the 'connectedFeature' to find property 'area'.
     * @param {*} textFeatures
     */
    getTextFeatureWithBiggestArea (textFeatures)
    {
        if (Array.isArray(textFeatures))
        {
            textFeatures.sort((a, b) =>
            {
                const areaA = a.get("connectedFeature").get("area");
                const areaB = b.get("connectedFeature").get("area");

                if (areaA < areaB)
                {
                    return 1;
                }
                else if (areaA > areaB)
                {
                    return -1;
                }
                else
                {
                    return 0;
                }
            });

            return textFeatures[0];
        }
        else
        {
            return textFeatures;
        }
    }
    /**
     * Used for text clustering.
     * Filters text features based on num of words the text string.
     * If no names are found with number of words === num words, it will call itself recursively increasing word count by one untill it is greater than maxWords.
     *  In the case where the filtered list has no features, the orginal list is returned.
     * @param {*} textFeatures
     * @param {*} numWords
     */
    filterTextFeaturesRecursive (textFeatures, numWords, maxWords = 3)
    {
        if (numWords > maxWords)
        {
            return textFeatures;
        }

        if (Array.isArray(textFeatures))
        {
            let filteredTextFeatures = textFeatures.filter((feature) =>
            {
                let text = feature.getStyle().getText();

                if (!!text && typeof text === "string")
                {
                    text = text.getText();
                    const words = text.trim().split(" ");

                    if (words.length === numWords)
                    {
                        return true;
                    }
                }

                return false;
            });

            if (filteredTextFeatures.length > 0)
            {
                return filteredTextFeatures;
            }
            else
            {
                return this.filterTextFeaturesRecursive(textFeatures, numWords + 1, maxWords);
            }
        }
        else
        {
            return textFeatures;
        }
    }

    createTextStyle (name, textRotation = 0, textStyle)
    {
        const rotationRad = textRotation * Math.PI / 180;
        const [isMulti, text] = this.addLineBreaksToString(name);
        const LARGE_SCALE = 1.1;
        const SMALL_SCALE = 1;
        if (textStyle)
        {
            return new Text({
                text,
                rotation: rotationRad,
                overflow: true,
                fill: new Fill(textStyle.fill),
                font: textStyle.font,
                stroke: new Stroke(textStyle.stroke),
                scale: isMulti ? SMALL_SCALE : LARGE_SCALE
            });
        }
        else
        {
            return new Text({
                text,
                rotation: rotationRad,
                scale: isMulti ? SMALL_SCALE : LARGE_SCALE
            });
        }
    }

    /**
     * functionality that adds line breaks to strings mimicing what is currently done on mobile
     * @param {string} input
     * @returns {[isMult: boolean, result: string]} [isMult]
     */
    addLineBreaksToString (input)
    {
        if (typeof input !== "string")
        {
            return [false, ""];
        }
        const splitByWhite = input.split(/\s+/);
        if (splitByWhite.length > 2)
        {
            // add \n after second word
            return [true, splitByWhite.slice(0, 2).join(" ") + "\n" + splitByWhite.slice(2).join(" ")];
        }
        else if (splitByWhite.length === 2)
        {
            // add \n in between if either word is longer than 6
            if (splitByWhite.some((word) => word.length >= 7))
            {
                return [true, splitByWhite.join("\n")];
            }
        }
        return [false, splitByWhite.join(" ")];
    }

    changeEntityText (textFeature, name, textRotation, textStyle)
    {
        const text = this.createTextStyle(name, textRotation, textStyle);

        let style = textFeature.getStyle();

        style.setText(text);

        textFeature.setStyle(style);
    }
}
module.exports = TextController;
