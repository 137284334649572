import React from "react";
import { useSetState } from "ahooks";
import { ButtonIcon } from "../../../common/ButtonIcon";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import DynamicMapLayerItem from "./DynamicMapLayerItem";
import { useTranslation } from "react-i18next";

import "./DynamicMapLayerSidebar.css";

const ConfigurationItem = ({ 
    configuration, 
    onSelect, 
    onDelete, 
    onDownload,
    selectedDynamicMapLayer
}) =>
{
    const trans = useTranslation().t;
    const [state, setState] = useSetState({
        expanded: false,
        deleteModalOpen: false
    });

    const toggleExpandAndCollapse = () =>
    {
        const { expand } = state;
        setState({
            expand: !expand
        });
    };

    const active = selectedDynamicMapLayer.configurationName === configuration.name 
        && !selectedDynamicMapLayer.dynamicMapLayerId 
        && !selectedDynamicMapLayer.automationRuleId;

    return (
        <>
            <div className={`dynamicMapLayerConfigurationItem ${state.expand? "expanded": ""} ${active? "active": ""}`}>
                <ButtonIcon 
                    icon="angle-down"
                    className={`expandIcon ${state.expand? "": "iconOpen"}`}
                    onClick={toggleExpandAndCollapse}/>
                <p 
                    className="activitySidebar-mapLayerName"
                    onClick={() => onSelect(undefined, undefined, configuration.name)} >{configuration.name}
                </p>
                <div className="overlayConfigActions">
                    {onDownload
                        && <ButtonIcon 
                            icon="download-blue"
                            onClick={() => onDownload(configuration.name)}
                        />
                    }
                    {onDelete 
                    && <DeleteConfirmationModal
                        onDeleteIconClick={() => setState({
                            deleteModalOpen: true,
                        })}
                        onDeleteClick={() => 
                        {
                            setState({
                                deleteModalOpen: false,
                            });
                            onDelete(configuration.name);
                        }} 
                        deleteModalOpen={state.deleteModalOpen}
                        onClose={() => setState({ deleteModalOpen: false })}
                        message={trans("Cancel_Confirmation_Modal.Delete_DML_Config", { configurationName: configuration.name })}
                    />
                    }
                </div>
            </div>
            {state.expand 
            && <div className="automationRuleItems">
                {configuration.dynamicMapLayers.map((dynamicMapLayer) => (
                    <DynamicMapLayerItem 
                        key={dynamicMapLayer._id}
                        dynamicMapLayer={dynamicMapLayer}
                        onSelect={onSelect}
                        className="multiDynamicMapLayerItem"
                        selectedDynamicMapLayer={selectedDynamicMapLayer}
                    />
                ))}
            </div>}
        </>
    );
};

export default ConfigurationItem;