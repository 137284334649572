import React from "react";
import ConfirmationModal from "../../../common/confirmationModal/ConfirmationModal";
import { truncateText } from "../../../../_utils/mapUtils";
import { Trans, useTranslation } from "react-i18next";
import { useStyleAndThemesContext } from "../../../../store/StylesAndThemesContext";
import { Button } from "semantic-ui-react";

function DeleteThemeConfirmation({ open, onClose, themeInfo }) 
{
    const trans = useTranslation().t;
    const { handleDeleteTheme } = useStyleAndThemesContext();

    return (
        <ConfirmationModal open={open} onClose={onClose}>
            <ConfirmationModal.Header>
                <div className="icon-bx">
                    <img src="/img/icon-delete.svg" alt="Delete Icon" />
                </div>
                <div>{trans("ThemeCreateAndEditModal.Delete_Theme")}?</div>
            </ConfirmationModal.Header>
            <ConfirmationModal.Content>
                <Trans
                    i18nKey="ThemeCreateAndEditModal.You are about to delete"
                    values={{ themeName: truncateText(themeInfo.themeName, 22) }}
                />
            </ConfirmationModal.Content>
            <ConfirmationModal.Actions>
                <Button
                    color="orange"
                    onClick={(e) => handleDeleteTheme(themeInfo.themeId).then(() => 
                    {
                        onClose(e, "action-btn-click");
                    })
                    }
                >
                    {trans("ThemeCreateAndEditModal.Delete")}
                </Button>
            </ConfirmationModal.Actions>
        </ConfirmationModal>
    );
}

export default DeleteThemeConfirmation;
