import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useHistory } from "react-router-dom";
import { Button, Header, Input, Icon } from "semantic-ui-react";
import { ButtonIcon } from "../../../common/ButtonIcon";
import Footer from "../../../layout/Footer";
import "./BrandingListing.css";
import { useEntityInvetories } from "./functions";
import { ListingDataTable } from "./ListingDataTable";
import { AddInventoryModal } from "./modals/AddInventoryModal";
import { UploadCSVModal } from "./modals/UploadCSVModal";
import { DEFAULT_LANGUAGE_CODE } from "../../../../_constants/constants";
import { useSelectedEntity, useSelectedFloor } from "../../../../store/BrandingContext";


const Inventory = () =>
{
    const entity = useSelectedEntity();
    const entityName = entity?.entityLabel?.longName?.[DEFAULT_LANGUAGE_CODE];
    const floor = useSelectedFloor();
    const floorName = floor?.shortName?.[DEFAULT_LANGUAGE_CODE] || floor?.longName?.[DEFAULT_LANGUAGE_CODE];
    const [textFilter, setTextFilter] = useState(undefined);
    const inventoryData = useEntityInvetories(entity?.entityLabel?._id);
    const { data: inventory, isLoading } = inventoryData;
    const inventoryLength = inventory?.length || 0;

    const { goBack } = useHistory();
    const trans = useTranslation().t;

    if (!entity?._id)
    {
        return <Redirect to="/branding" />;
    }

    return (
        <div>
            <div className="brandingListingWrapper">
                <ButtonIcon className="backButton" content={trans("Inventory.Back")} icon="arrow-cms" onClick={goBack} />

                <Header as="h3" className="p ageHeader">{`${trans("Inventory.Inventory")}${entityName ? " - " + entityName : ""}${floorName ? ", " + floorName : ""}`}</Header>

                <InventorySubheader
                    noInventory={!isLoading && inventoryLength < 1}
                    textFilter={textFilter}
                    setTextFilter={setTextFilter}
                />

                {!isLoading && <ListingDataTable textFilter={textFilter} inventoryData={inventoryData} />}
            </div>
            <Footer />
        </div>
    );
};
export default Inventory;

export const InventorySubheader = ({ noInventory, textFilter, setTextFilter }) =>
{
    const entity = useSelectedEntity();
    const trans = useTranslation().t;

    const [csvIsOpen, setCSVisOpen] = useState(false);

    return (
        <div className="inventorySubheader">
            <div className="inventorySubheaderLeft">
                {(!noInventory) && (
                    <Input
                        className="searchDashboard"
                        icon="search"
                        placeholder={trans("Inventory.Search")}
                        value={textFilter}
                        onChange={(e, { value }) => setTextFilter(value)}
                    />
                )}
                {(noInventory) && <p className="emptyInventoryMessage">{trans("Inventory.This_inventory_list_doesn’t_have_any_ite")}</p>}
            </div>

            <div className="inventorySubheaderRight">
                <UploadCSVModal
                    isOpen={csvIsOpen}
                    close={() => setCSVisOpen(false)}
                    entityLabelId={entity?.entityLabel?._id}
                    trigger={
                        <Button onClick={() => setCSVisOpen(true)} className="buttonIcon">
                            <Icon name="upload" />
                            {trans("Inventory.Upload_CSV")}
                        </Button>
                    }
                />
                <AddInventoryModal trigger={<Button positive content={trans("Inventory.Add_Inventory")} />} />
            </div>
        </div>
    );
};
