import { Vector as VectorLayer } from "ol/layer";
import { createEntityGeometry } from "mapsted.maps/mapFunctions/plotting";
import { Vector as VectorSource } from "ol/source";
import Feature from "ol/Feature";
import { createStyle as createStyleOverlay, createStyleOptions, getTemplateStyle } from "mapsted.maps/mapFunctions/mapOverlay";
import { createStyle  } from "mapsted.maps/mapFunctions/publicVectorLayers";
import { toGpsLocation } from "mapsted.maps/utils/map.utils";
import { fromCircle } from "ol/geom/Polygon";
import booleanIntersects from "@turf/boolean-intersects";
import { getCenter, getTopRight, getWidth, getHeight } from "ol/extent";
import MultiPoint from "ol/geom/MultiPoint";
import { Circle as CircleStyle, Fill, Style } from "ol/style.js";

export const createPolygonVectorLayer = ({
    polygonShape,
    layerKey,
    fillColor,
    fillOpacity,
    strokeColor,
    strokeOpacity
}) =>
{
    const layer = new VectorLayer({
        [layerKey]: true
    });

    const geometry = createEntityGeometry(polygonShape);

    const source = new VectorSource();

    const feature = new Feature({
        geometry
    });

    let styleOptions = createStyleOptions(fillColor, strokeColor, fillOpacity, strokeOpacity);

    const style = createStyleOverlay(styleOptions);

    source.addFeature(feature);

    layer.setSource(source);

    layer.setStyle(style);

    return layer;
};

export const createZoneLayerFromShape = (shape, zoneId, styleOptions, customFlags = {}) => 
{
    const geometry = createEntityGeometry(shape);

    const feature = new Feature({
        geometry,
        zoneId
    });

    // add whatever custom flag provided for feature for easier identification later
    if (customFlags.feature?.key && customFlags.feature?.value) 
    {
        feature.set(customFlags.feature.key, customFlags.feature.value);
    }

    const vectorSource = new VectorSource({
        features: [feature]
    });

    // add whatever custom flag provided for vector source for easier identification later
    if (customFlags.vectorSource?.key && customFlags.vectorSource?.value) 
    {
        vectorSource.set(customFlags.vectorSource.key, customFlags.vectorSource.value);
    }

    const vectorLayer = new VectorLayer({
        source: vectorSource
    });

    const style = createStyle(styleOptions);

    vectorLayer.setStyle(style);
    vectorLayer.setZIndex(7000);

    vectorLayer.set("isDistributionZone", true);
    vectorLayer.set("zoneId", zoneId);

    // add whatever custom flag provided for vectorLayer for easier identification later
    if (customFlags.vectorLayer?.key && customFlags.vectorLayer?.value) 
    {
        vectorLayer.set(customFlags.vectorLayer.key, customFlags.vectorLayer.value);
    }

    return vectorLayer;
};

export const createZoneFeatureFromShape = (shape, zoneId, styleOptions, linkedTemplate = undefined) => 
{
    const geometry = createEntityGeometry(shape);

    const feature = new Feature({
        geometry,
        zoneId
    });

    let style; 

    if (linkedTemplate) style = getTemplateStyle(linkedTemplate);
    else style = createStyle(styleOptions);

    feature.setStyle(style);

    return feature;
};

export const createZonesLayerFromFeatures = (features) => 
{
    const vectorSource = new VectorSource({
        features
    });
    const vectorLayer = new VectorLayer({
        source: vectorSource
    });
    vectorLayer.setZIndex(7000);
    vectorLayer.set("isDistributionZone", true);
    return vectorLayer;
};

export const getShapeFromGeometry = (geometry) => 
{
    if (geometry.getType() === "Circle") 
    {
        geometry = fromCircle(geometry);
    }

    const gpsCoordinates = [];

    geometry.getCoordinates().forEach((set, i) => 
    {
        let coords = [];
        set.forEach((coordinate) => 
        {
            coords.push(toGpsLocation(coordinate));
        });

        gpsCoordinates[i] = coords;
    });

    return {
        type: geometry.getType(),
        coordinates: gpsCoordinates
    };
};

export const checkIfIntersects = (shape1, shape2) => booleanIntersects(shape1, shape2);

export const getTopCoordinates = (shape) => 
{
    const geometry = createEntityGeometry(shape);

    if (geometry) 
    {
        const extent = geometry.getExtent();
        const topRight = getTopRight(extent);
        const center = getCenter(extent);

        return geometry.getClosestPoint([center[0], topRight[1]]);
    }
};

export const truncateText = (text, maxLength = 20) => (text.length > maxLength) ? text.slice(0, maxLength - 1) + "..." : text;

export const getMappedByFloorDistributionZones = (
    distributionZones,
    searchText
) => 
{
    const mappedDistributionZones = {};
    distributionZones.forEach((zone) => 
    {
        if (zone && zone.label?.trim().toLowerCase().includes(searchText)) 
        {
            mappedDistributionZones[zone.floorId] = [
                ...(mappedDistributionZones[zone.floorId] || []),
                zone,
            ];
        }
    });

    return mappedDistributionZones;
};

export const calculateCenter = (geometry) => 
{
    let center, coordinates, minRadius;
    const type = geometry.getType();
    if (type === "Polygon") 
    {
        let x = 0;
        let y = 0;
        let i = 0;
        coordinates = geometry.getCoordinates()[0].slice(1);
        coordinates.forEach(function (coordinate) 
        {
            x += coordinate[0];
            y += coordinate[1];
            i++;
        });
        center = [x / i, y / i];
    }
    else if (type === "LineString") 
    {
        center = geometry.getCoordinateAt(0.5);
        coordinates = geometry.getCoordinates();
    }
    else 
    {
        center = getCenter(geometry.getExtent());
    }
    let sqDistances;
    if (coordinates) 
    {
        sqDistances = coordinates.map(function (coordinate) 
        {
            const dx = coordinate[0] - center[0];
            const dy = coordinate[1] - center[1];
            return dx * dx + dy * dy;
        });
        minRadius = Math.sqrt(Math.max.apply(Math, sqDistances)) / 3;
    }
    else 
    {
        minRadius =
            Math.max(
                getWidth(geometry.getExtent()),
                getHeight(geometry.getExtent())
            ) / 3;
    }
    return {
        center: center,
        coordinates: coordinates,
        minRadius: minRadius,
        sqDistances: sqDistances,
    };
};

export const getHightlightedVerticesStyle = (geometry, color = "Red") => 
{
    const result = calculateCenter(geometry);
    const center = result.center;
    if (center) 
    {
        const coordinates = result.coordinates;
        if (coordinates) 
        {
            const minRadius = result.minRadius;
            const sqDistances = result.sqDistances;
            const rsq = minRadius * minRadius;
            const points = coordinates.filter(function (coordinate, index) 
            {
                return sqDistances[index] > rsq;
            });
            return new Style({
                geometry: new MultiPoint(points),
                image: new CircleStyle({
                    radius: 4,
                    fill: new Fill({
                        color,
                    }),
                }),
            });
        }
    }
};

export const checkIfOverlaps = (newshape, existingShapes) => 
{
    let overlaps = false;

    for (let i = 0; i < existingShapes.length; i++) 
    {
        const existingShape = existingShapes[i];
        if (existingShape) 
        {
            if (checkIfIntersects(existingShape, newshape)) 
            {
                overlaps = true;
                break;
            }
        }
    }

    return overlaps;
};

