import React, { useContext } from "react";
import { DropdownForm } from "../DropdownForm";
import { LanguageContext } from "../../../store/LanguageContext";
import CustomLangFlag from "../customLangFlag/CustomLangFlag";

import "./LanguageDropdown.css";
import { useTranslation } from "react-i18next";

const LanguageDropdown = ({
    activeLang,
    allowedLangs,
    onChange
}) => 
{
    const languageCtx = useContext(LanguageContext);
    const { languages } = languageCtx;

    const trans = useTranslation().t;

    const langOptions = Object.values(languages)
        .filter((lang) => allowedLangs.includes(lang.value))
        .map((lang) => ({
            key: lang.value,
            text: lang.title,
            icon: lang.customFlag ? <CustomLangFlag flagIconFilerUrl={lang.icon} style={{ marginTop: "0px" }}/> : <i className={`${lang.flag} flag`}></i>,
            value: lang.value,
        }));

    return (
        <DropdownForm
            className="languagePicker"
            scrolling
            selectOnBlur={false}
            placeholder={trans("LanguageSelector.Choose_Language")}
            value={activeLang}
            options={langOptions}
            onChange={(e, { value }) => onChange(value)}
        />);
};

export default LanguageDropdown;