import React, { Suspense } from "react";
import { Footer } from "../../layout/Footer";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Container, Grid } from "semantic-ui-react";
import DashboardContext from "../../../store/DashboardContext";
import { SubheaderDashboard } from "../../layout/Subheader";
import { Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { withConfig } from "../../../_utils/hoc";
const PushNotificationsBox = React.lazy(() => import("../../PropertyOverview/PushNotificationsBox"));
const MobileAnalyticsBox = React.lazy(() => import("../../PropertyOverview/MobileAnalyticsBox"));
const KioskAnalyticsBox = React.lazy(() => import("../../PropertyOverview/KioskAnalyticsBox"));
const WebAnalyticsBox = React.lazy(() => import("../../PropertyOverview/WebAnalyticsBox"));
const BuildingBox = React.lazy(() => import("../../PropertyOverview/BuildingBox"));

class PropertyView extends React.Component
{
    static contextType = DashboardContext;

    render()
    {
        const { state } = this.context;
        const processed = (state.properties[state.propertyId] && state.properties[state.propertyId].status === this.props.config.STATUSES.OPERATIONAL);

        if (state.termsAndConditions && !state.loading && Object.keys(state.properties).length === 0)
        {
            return (
                <Redirect to="/dashboard/property" />
            );
        }
        const trans = this.props.t;
        return (
            <>
                <SubheaderDashboard />

                <Scrollbars autoHeight autoHeightMin={"calc(1vh)"} autoHeightMax={"calc(100vh - 120px)"} className="scrollDash">
                    <Container>
                        <Grid columns="equal" className="main-grid">

                            <Suspense fallback={<div>{trans("Dashboard.loading___")}</div>}>

                                <Grid.Column>
                                    <PushNotificationsBox processed={processed} />
                                    <MobileAnalyticsBox propertyId={state.propertyId} processed={processed} />
                                    {
                                        !state.loading && <WebAnalyticsBox 
                                            processed={processed} 
                                            propertyId={state.properties?.[state.propertyId]?.propertyId}  
                                            getWebAnalyticsData={this.context.getWebAnalyticsData}
                                        />
                                    }
                                    {/* <KioskAnalyticsBox processed={processed} /> */}
                                </Grid.Column>

                                <Grid.Column width="7">
                                    <BuildingBox />
                                </Grid.Column>
                            </Suspense>

                        </Grid>
                    </Container>
                    <Footer />
                </Scrollbars>
            </>
        );
    }
}

export default withTranslation()(withConfig(PropertyView));
