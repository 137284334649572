import * as yup from "yup";
import { DEFAULT_LANGUAGE_CODE } from "mapsted.maps/utils/map.constants";
import { AUTOMATION_RULE_CONDITIONS, DEFAULT_VERTICES } from "../../../../_constants/constants";
import { circular } from "ol/geom/Polygon";
import Polygon from "ol/geom/Polygon";
import { validateIfPolygonOverlaps } from "../../mapOverlays/utils/mapOverlayValidation";
import { getAllAvailableDynamicMapLayerLangs } from "./dynamicMapLayersUtils";
import { useTranslation } from "react-i18next";

export const validateTab1DynamicMapLayerInfo = (dynamicMapLayer) =>
{
    let validationErrors = [];

    if (!dynamicMapLayer.dataStreamName?.[DEFAULT_LANGUAGE_CODE])
    {
        validationErrors.push("Data stream name is mandatory");
    }
    else
    {
        // check if DML data stream name has all the available lang codes which are entered in other text fields in DML
        const allAvailableLangCodes = getAllAvailableDynamicMapLayerLangs(dynamicMapLayer);
        allAvailableLangCodes.forEach((langCode) =>
        {
            if (!dynamicMapLayer.dataStreamName[langCode])
            {
                validationErrors.push(`Data stream name is required in ${langCode.toUpperCase()} language`);
            }
        });
    }

    if (!dynamicMapLayer.mapOverlayId)
    {
        validationErrors.push("Map overlay is mandatory");
    }

    return validationErrors;
};

export const validateAutomationRule = (automationRule, otherAutomationRules, trans) =>
{
    let validationErrors = [];

    if (!automationRule.dataPointCondition.condition)
    {
        validationErrors.push(trans("MaintenanceCreateDynamicMapSidebar.Rule_Condition_Is_Mandatory"));
    }

    if (!automationRule.dataPointCondition.value)
    {
        validationErrors.push(trans("MaintenanceCreateDynamicMapSidebar.Rule_Condition_Value_Is_Mandatory"));
    }

    if (!automationRule.textLabel[DEFAULT_LANGUAGE_CODE])
    {
        validationErrors.push(trans("MaintenanceCreateDynamicMapSidebar.Text_Label_Is_Mandatory"));
    }

    for (let i=0; i<otherAutomationRules.length; i++)
    {
        const otherAutomationRule = otherAutomationRules[i];

        if (otherAutomationRule.dataPointCondition.condition === automationRule.dataPointCondition.condition
            && otherAutomationRule.dataPointCondition.value === automationRule.dataPointCondition.value)
        {
            validationErrors.push("Rule condition and value same as one of the previous automation rule");
            break;
        }
    }

    return validationErrors;
};

export const validateMultiCreation = (multiCreation) =>
{
    let validationErrors = [];

    if (!multiCreation.configurationName)
    {
        validationErrors.push("Configuration name is mandatory");
    }

    if (!multiCreation.mapOverlays?.length || !multiCreation.dynamicMapLayers?.length)
    {
        validationErrors.push("No records were uploaded");
    }

    return validationErrors;
};

const multiDynamicMapLayerDataRowSchema = yup.object().shape({
    propertyId: yup.string().optional(),
    apiKey: yup.string().optional(),
    overlayName: yup.string().required("DynamicMayLayerValidation.OVERLAY_NAME_Required"),
    overlayLabel: yup.string().required("DynamicMayLayerValidation.OVERLAY_LABEL_Required"),
    layerName: yup.string().required("DynamicMayLayerValidation.LAYER_NAME_Required"),
    toolTipText: yup.string().required("DynamicMayLayerValidation.TOOL_TIP_TEXT_Required"),
    dataLabel: yup.string().required("DynamicMayLayerValidation.DATA_LABEL_Required"),
    condition: yup.string().required("DynamicMayLayerValidation.CONDITION_Required").oneOf(Object.values(AUTOMATION_RULE_CONDITIONS), "DynamicMayLayerValidation.Invalid_CONDITION"),
    value: yup.number().typeError("DynamicMayLayerValidation.VALUE_must_number").required("DynamicMayLayerValidation.VALUE_required"),
    color: yup.string().optional()
        .test("test_color", "DynamicMayLayerValidation.Invalid_COLOR", (value) => value ? CSS.supports("color", value) : true),
    borderColor: yup.string().optional()
        .test("test_borderColor", "DynamicMayLayerValidation.Invalid_BORDER_COLOR", (value) => value ? CSS.supports("color", value) : true),
    textColor: yup.string().optional()
        .test("test_borderColor", "DynamicMayLayerValidation.Invalid_TEXT_COLOR", (value) => value ? CSS.supports("color", value) : true),
    selectionType: yup.string().required("DynamicMayLayerValidation.SELECTION_TYPE_required").oneOf(["circle"], "DynamicMayLayerValidation.Invalid_SELECTION_TYPE"),
    x: yup.number().typeError("DynamicMayLayerValidation.X_number").required("DynamicMayLayerValidation.X_required")
        .test("test_X", "DynamicMayLayerValidation.Invalid_X", (value) => value >= -180 && value <= 180),
    y: yup.number().typeError("DynamicMayLayerValidation.Y_number").required("DynamicMayLayerValidation.Y_required")
        .test("test_y", "DynamicMayLayerValidation.Invalid_Y", (value) => value >= -90 && value <= 90),
    r: yup.number().typeError("DynamicMayLayerValidation.R_number").required("DynamicMayLayerValidation.R_required").min(1, "DynamicMayLayerValidation.Invalid_R")
});

export const validateMulitCreationFileDataRows = async (dataRows) =>
{
    let validationErrors = [];

    for (let i = 0; i<dataRows.length; i++)
    {
        try
        {
            await multiDynamicMapLayerDataRowSchema.validate(dataRows[i], { abortEarly: false });
        }
        catch (err)
        {
            // add row number to error message, add 2 to offset header row as well
            let errorMessages = err.errors.map((error) =>  ({
                errorKey: error,
                dynamicKey: "rowNumber",
                rowNumber: i + 2
            }));
            
            validationErrors.push(...errorMessages);
        }        
    }

    return validationErrors;
};

export const validatePolygonOverlayIntersection = (dataRows, mapOverlays) =>
{
    let validationErrors = [];
    const existingPolygons = mapOverlays.map((mapOverlay) => new Polygon(mapOverlay.shape.coordinates));

    for (let i=0; i<dataRows.length; i++)
    {
        const dataRow = dataRows[i];
        const polygon = circular([+dataRow["x"], +dataRow["y"]], +dataRow["r"], DEFAULT_VERTICES);

        if (!validateIfPolygonOverlaps(polygon, existingPolygons))
        {
            validationErrors.push(`Polygon in row ${i + 2} overlaps or is overlapped by existing mapOverlays`);
        }
        else
        {
            existingPolygons.push(polygon);
        }
    }    

    return validationErrors;

};