import React, { useCallback, useMemo } from "react";
import { Form, FormField, Input, Button, Icon } from "semantic-ui-react";
import useFloorPlanCommon from "./hooks/useFloorPlanCommon";
import { convertDegreesTotInverseCosineRadius, convertInverseCosineRadiusToDegree } from "./utils/floorPlan.utils";
import { roundWithPrecision } from "mapsted.utils/numbers";
const EditSavedFloorPlan = () =>
{

    const {
        opacity,
        //rotation value is in inverse cosine radius
        rotation,
        center,
        scale,
        onCenterChange,
        onScaleChange,
        onRotateChange,
        onOpacityChange
    } = useFloorPlanCommon();

    const rangeOpacityValueInDegree = useMemo(() => roundWithPrecision(opacity * 100, 2), [opacity]);

    const handleOpacityChange = useCallback(({ target: { value } }) =>
    {
        onOpacityChange((0 + +value / 100), true);
    }, [onOpacityChange]);

    const handleRotationChange = useCallback(({ target: { value } }) =>
    {
        onRotateChange(convertDegreesTotInverseCosineRadius(0 + +value), true);
    }, [onRotateChange]);


    const rotationDegreeValue = useMemo(() => roundWithPrecision(convertInverseCosineRadiusToDegree(rotation)), [rotation]);



    return <Form>
        <FormField>

            <label>Opacity (%)</label>
            <div className="flex-center">
                <Input
                    className="opacityRange pd-right-15"
                    min={0}
                    step={1}
                    type="range"
                    value={rangeOpacityValueInDegree}
                    max={100}
                    onChange={handleOpacityChange}

                />
                <Input className="width-2p" placeholder="Edit Opacity" type="number" step={1} min={0} max={100} value={rangeOpacityValueInDegree} onChange={handleOpacityChange} />
            </div>

        </FormField>
        <FormField>

            <label>Rotation (Degrees) </label>
            <div className="flex-center">
                <Input
                    className="opacityRange pd-right-15"
                    min={0}
                    max={359}
                    step={1}
                    type="range"
                    value={rotationDegreeValue}

                    onChange={handleRotationChange} />
                <Input className="width-2p" placeholder="Edit Rotation" type="number" max={359} min={0} step={1} value={rotationDegreeValue} onChange={handleRotationChange} />
            </div>
        </FormField>
        {/* <FormField>
            <label>Center</label>
            <Input placeholder="Edit Center X" type="number" value={center[0]} onChange={(e) => onCenterChange([0 + +e.target.value, center[1]], true)} step={.1} />
            <Input placeholder="Edit Center Y" type="number" value={center[1]} onChange={(e) => onCenterChange([center[0], 0 + +e.target.value], true)} step={.1} />
        </FormField>
        <FormField>
            <label>Scale</label>
            <Input placeholder="Edit Scale X" type="number" value={scale[0]} onChange={(e) => onScaleChange([0 + +e.target.value, scale[1]], true)} step={.001} />
            <Input placeholder="Edit Scale Y" type="number" value={scale[1]} onChange={(e) => onScaleChange([scale[0], 0 + +e.target.value], true)} step={.001} />
        </FormField> */}

    </Form>;
};

export default EditSavedFloorPlan;
