import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Loader } from "../components/elements/loader";
import { UTIL_QUERIES } from "./queries";

export const withLoader = (Component) => (props) =>
{

    const [hidden, setHidden] = useState(true);

    const show = () => setHidden(false);

    const hide = () => setHidden(true);

    const toggle = () => setHidden((open) => !open);

    return (
        <>
            <Loader active={!hidden} />
            <Component {...props} loader={{ show, hide, toggle }} />
        </>
    );
};

// adds server config object from react-query for class components
export const withConfig = (Component) => (props) =>
{
    const { data: config } = useQuery(UTIL_QUERIES.CONFIG());
    return <Component {...props} config={config} />;
};
withConfig.displayName = "withConfig";

export const withQueryClient = (Component) => (props) =>
{
    const queryClient = useQueryClient();
    return <Component {...props} queryClient={queryClient} />;
};
