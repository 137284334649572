const { Vector: VectorSource, Cluster: ClusterSource } = require("ol/source");
const { Vector: VectorLayer } = require("ol/layer");
const { Feature } = require("ol");
const { Style, Stroke, Fill, Text } = require("ol/style");
const tinyColor = require("tinycolor2");
const { createEntityGeometry } = require("./plotting");
const { View } = require("ol");
const { getCenter, getTopRight, getArea } = require("ol/extent");
const { Point } = require("ol/geom");
const turf = require("@turf/turf");
const { toGpsLocation } = require("../utils/map.utils");
const { GeoJSON } = require("ol/format");
const { MapConstants, TEXT_LABEL_LENGTH_LIMIT } = require("../utils/map.constants");

const DEFAULT_FONT_SIZE = 11;
const THRESHOLD_MAPOVERLAY_NUMBERS = MapConstants.CLUSTER_DISTANCE;
const DEFAUTL_DYNAMIC_MAP_LAYER_TEXT_COLOR = "#000000";
const MAP_OVERLAYS_TEXT_CLUSTER_GROUP_LIMIT = 150;

exports.createSingleVectorLayerFromFeatures = (features) =>
{
    const vectorSource = new VectorSource({
       features: features
    });

    const vectorLayer = new VectorLayer({
        source: vectorSource,
        isMapOverlay: true
    });
    vectorLayer.setIsMapOverlay;

    return vectorLayer
}

exports.getFeatureOfMapOverlay = (mapOverlay) => 
{
   const geometry = createEntityGeometry(mapOverlay.shape);

    const feature = new Feature({
        geometry,
        mapOverlayDetails: mapOverlay
    });
    
    const view= new View()

    const styleFunction = (feature, resolution) =>
    {
        const currentZoomLevel = view.getZoomForResolution(resolution).toFixed(2);
        const { color, lineColor, dynamicOverlaySettings, defaultFillOpacity, defaultBorderFillOpacity } = mapOverlay;
        let styleOptions = {};

        if (dynamicOverlaySettings.enabled)
        {
            styleOptions = getPolygonStyleOptionsForDynamicSettings(dynamicOverlaySettings, currentZoomLevel, color, lineColor);
        }
        else
        {
            styleOptions = exports.createStyleOptions(color, lineColor, defaultFillOpacity, defaultBorderFillOpacity);
        }

        return exports.createStyle(styleOptions);
    }

    feature.setId(`${mapOverlay._id}_mapOverlay`);
    feature.setStyle(styleFunction)

    return feature
}

exports.createMapOverlayVectorLayer = (mapOverlay, setTextFeature=true) =>
{
    const geometry = createEntityGeometry(mapOverlay.shape);

    const feature = new Feature({
        geometry,
        mapOverlayDetails: mapOverlay
    });

    feature.setId(`${mapOverlay._id}_mapOverlay`);

    let features = [feature];

    const view = new View();

    if (setTextFeature)
    {
        features.push(createTextFeature(mapOverlay, view));
    }
    
    const vectorSource = new VectorSource({
        features
    });
    

    const vectorLayer = new VectorLayer({
        source: vectorSource,
        isMapOverlay: true
    });

    const styleFunction = (feature, resolution) =>
    {
        const currentZoomLevel = view.getZoomForResolution(resolution).toFixed(2);
        const { color, lineColor, dynamicOverlaySettings, defaultFillOpacity, defaultBorderFillOpacity } = mapOverlay;
        let styleOptions = {};

        if (dynamicOverlaySettings.enabled)
        {
            styleOptions = getPolygonStyleOptionsForDynamicSettings(dynamicOverlaySettings, currentZoomLevel, color, lineColor);
        }
        else
        {
            styleOptions = exports.createStyleOptions(color, lineColor, defaultFillOpacity, defaultBorderFillOpacity);
        }

        return exports.createStyle(styleOptions);
    }

    vectorLayer.setStyle(styleFunction);

    vectorLayer.setIsMapOverlay

    return vectorLayer;
};

exports.createStyle = (styleOptions) =>
{
    const { fill, stroke } = styleOptions;

    if (!!stroke && !!fill)
    {
        let style = new Style({
            stroke: new Stroke(stroke),
            fill: new Fill(fill),
        });

        return style;
    }
    else
    {
        return undefined;
    }
};

const createTextFeature = (mapOverlay, view, scaleFontSize=true) =>
{
    const geometry = createEntityGeometry(mapOverlay.shape);
    const textCoordinates = getCenter(geometry.getExtent());
    let area=getArea(geometry.getExtent())
    const textGeometry = new Point(textCoordinates);
    const textFeature = new Feature({
        geometry: textGeometry,
    });

    const styleFunction = (feature, resolution) =>
    {
        const currentZoomLevel = view.getZoomForResolution(resolution).toFixed(2);
        const { dynamicOverlaySettings, defaultTextOpacity, toolTipText } = mapOverlay;

        const textStyleOptions = exports.createStyle({
            stroke: "rgba(0, 0, 0, 0)",
            fill: "rgba(0, 0, 0, 0)",
        });

        let textOpacity;
        let displayTextLabel = toolTipText;
        let fontSize;

        if (currentZoomLevel < 14)
        {
            textOpacity = 0;
        }
        else if (dynamicOverlaySettings.enabled)
        {
            let { 
                textOpacity: resultTextOpacity, 
                displayTextLabel: resultDisplayTextLabel 
            } = getTextOpacityForDynamicSettings(dynamicOverlaySettings, currentZoomLevel, displayTextLabel);

            textOpacity = resultTextOpacity;
            displayTextLabel = resultDisplayTextLabel; 
        }
        else
        {
            textOpacity = defaultTextOpacity;
        }

        if (scaleFontSize)
        {
            let calculatedArea = Math.abs(Math.log(area)/2.5)
            fontSize = parseInt(getLinearInterpolatedFontSize(currentZoomLevel,14,4.5,24,9.5) + calculatedArea)
        }
        let textFeatureStyle = new Style(textStyleOptions);
        const textFontOptions = exports.getTextFontOptions(textOpacity, fontSize, mapOverlay.textColor);

        // mapOverlay.conditionValue is mainly used in dynamic map layers
        textFeatureStyle.setText(createTextStyleWithLineBreak(displayTextLabel, 0, textFontOptions, mapOverlay.conditionValue));
    
        return textFeatureStyle;
    }

    textFeature.setStyle(styleFunction);
    textFeature.setId(`${mapOverlay._id}_text`);

    return textFeature;
};

const createTextFeatureAndStyle = (mapOverlay, view, scaleFontSize=false) =>
{
    const geometry = createEntityGeometry(mapOverlay.shape);
    const textCoordinates = getCenter(geometry.getExtent());
    const textGeometry = new Point(textCoordinates);
    const area= getArea(geometry.getExtent())

    const textFeature = new Feature({
        geometry: textGeometry,
    });

    const styleFunction = (feature, resolution) =>
    {
        const currentZoomLevel = view.getZoomForResolution(resolution).toFixed(2);
        const { dynamicOverlaySettings, defaultTextOpacity, toolTipText } = mapOverlay;

        const textStyleOptions = exports.createStyle({
            stroke: "rgba(0, 0, 0, 0)",
            fill: "rgba(0, 0, 0, 0)",
        });

        let textOpacity;
        let displayTextLabel = toolTipText;
        let fontSize;

        if (currentZoomLevel < 14)
        {
            textOpacity = 0;
        }
        else if (dynamicOverlaySettings.enabled)
        {
            let { 
                textOpacity: resultTextOpacity, 
                displayTextLabel: resultDisplayTextLabel 
            } = getTextOpacityForDynamicSettings(dynamicOverlaySettings, currentZoomLevel, displayTextLabel);

            textOpacity = resultTextOpacity;
            displayTextLabel = resultDisplayTextLabel; 
        }
        else
        {
            textOpacity = defaultTextOpacity;
        }

        if (scaleFontSize)
        {
            let calculatedArea = Math.abs(Math.log(area)/4)
            fontSize = parseInt(getLinearInterpolatedFontSize(currentZoomLevel,14,4.5,24,9.5) + calculatedArea)
        }
        let textFeatureStyle = new Style(textStyleOptions);
        const textFontOptions = exports.getTextFontOptions(textOpacity, fontSize, mapOverlay.textColor);
        textFeatureStyle.setText(createTextStyleWithLineBreak(displayTextLabel, 0, textFontOptions));
    
        return textFeatureStyle;
    }

    textFeature.setId(`${mapOverlay._id}_text`);

    return { textFeature, styleFunction };
};

exports.getTextFontOptions = (textOpacity, fontSize=DEFAULT_FONT_SIZE, textColor) => 
{
    const textColorObj = tinyColor(textColor);
    textColorObj.setAlpha(textOpacity);

    return {
        size: "25px",
        fill: {
            color: textColorObj.toRgbString()
        },
        font: `bold ${fontSize}px Arial, sans-serif`,
        stroke: {
            color: "rgb(0, 0, 0, 0)",
            width: 1
        },
    };
};

exports.createStyleOptions = (mapOverlayColor, mapOverlayLineColor, fillOpacity, borderFillOpacity) =>
{
    {
        let styleOptions = {};
    
        const color = tinyColor(mapOverlayColor);
        color.setAlpha(fillOpacity);
    
        const lineColor = tinyColor(mapOverlayLineColor);
        lineColor.setAlpha(borderFillOpacity);
    
        styleOptions = {
            stroke: {
                color: lineColor.toRgbString(),
                width: 1
            },
            fill: {
                color: color.toRgbString(),
            }
        }; 
    
        return styleOptions;
    };
};

const getPolygonStyleOptionsForDynamicSettings = (dynamicOverlaySettings, currentZoomLevel, color, lineColor) =>
{
    let styleOptions = {};
    const { zoomLevels } = dynamicOverlaySettings;

    const lastZoomLevel = zoomLevels.length - 1;
    const startZoom = zoomLevels[0];
    const endZoom = zoomLevels[lastZoomLevel];

    if (currentZoomLevel <= startZoom.value)
    {
        styleOptions = exports.createStyleOptions(color, lineColor, startZoom.fillOpacity, startZoom.borderFillOpacity);
    }
    else if (currentZoomLevel >= endZoom.value)
    {
        styleOptions = exports.createStyleOptions(color, lineColor, endZoom.fillOpacity, endZoom.borderFillOpacity);
    }
    else
    {

        let interpolationStart = startZoom;
        let interpolationEnd = endZoom;

        for (let i=0; i<zoomLevels.length; i++)
        {
            const zoomLevelSettings = zoomLevels[i];
            const nextZoomLevelSettings = zoomLevels[i + 1];

            if (currentZoomLevel >= zoomLevelSettings.value && currentZoomLevel < nextZoomLevelSettings.value)
            {
                interpolationStart = zoomLevelSettings;
                interpolationEnd = nextZoomLevelSettings;
                break;
            }
                    
        }

        const newFillOpacity = getLinearInterpolatedOpacity(currentZoomLevel, 
            interpolationStart.value,
            interpolationStart.fillOpacity,
            interpolationEnd.value,
            interpolationEnd.fillOpacity);

        const newBorderOpacity = getLinearInterpolatedOpacity(currentZoomLevel, 
            interpolationStart.value,
            interpolationStart.borderFillOpacity,
            interpolationEnd.value,
            interpolationEnd.borderFillOpacity);

        styleOptions = exports.createStyleOptions(color, lineColor, newFillOpacity, newBorderOpacity);
    }

    return styleOptions;
};

const getTextOpacityForDynamicSettings = (dynamicOverlaySettings, currentZoomLevel, textLabel) =>
{
    let textOpacity;
    let displayTextLabel = textLabel;
    const { zoomLevels } = dynamicOverlaySettings;

    const lastZoomLevel = zoomLevels.length - 1;
    const startZoom = zoomLevels[0];
    const endZoom = zoomLevels[lastZoomLevel];
 
    if (currentZoomLevel <= startZoom.value)
    {
        textOpacity = startZoom.textOpacity;
    }
    else if (currentZoomLevel >= endZoom.value)
    {
        textOpacity = endZoom.textOpacity;
        if (endZoom.overrideGlobalTextLabel)
        {
            displayTextLabel = endZoom.textLabel;
        }
    }
    else
    {
        let interpolationStart = startZoom;
        let interpolationEnd = endZoom;

        // Get text opacity based on current zoom
        for (let i=0; i<zoomLevels.length; i++)
        {
            const zoomLevelSettings = zoomLevels[i];
            const nextZoomLevelSettings = zoomLevels[i + 1];

            if (currentZoomLevel >= zoomLevelSettings.value && currentZoomLevel < nextZoomLevelSettings.value)
            {
                interpolationStart = zoomLevelSettings;
                interpolationEnd = nextZoomLevelSettings;

                // override global text label if flag is enabled
                if (zoomLevelSettings.overrideGlobalTextLabel)
                {
                    displayTextLabel = zoomLevelSettings.textLabel;
                }

                break;
            }
        }

        textOpacity = getLinearInterpolatedOpacity(currentZoomLevel, 
            interpolationStart.value,
            interpolationStart.textOpacity,
            interpolationEnd.value,
            interpolationEnd.textOpacity);
    }

    return { textOpacity, displayTextLabel };
};

/**
 * Linear Interpolation formula: y = y1 + ((x-x1)*(y2-y1))/(x2-x1)
 * Consider 'x's as zoom values and 'y's as opacities. So need to find the opacity for the given zoom level
 * @param {Number} currentZoom - x
 * @param {Number} startZoom - x1
 * @param {Number} startZoomOpacity - y1
 * @param {Number} endZoom - x2
 * @param {Number} endZoomOpacity - y2
 * @returns newOpacity - y
 */
 const getLinearInterpolatedOpacity = (currentZoom, startZoom, startZoomOpacity, endZoom, endZoomOpacity) => startZoomOpacity + ((currentZoom - startZoom)*(endZoomOpacity - startZoomOpacity))/(endZoom - startZoom);

 const getLinearInterpolatedFontSize = (currentZoom, startZoom, startZoomOpacity, endZoom, endZoomOpacity) => startZoomOpacity + ((currentZoom - startZoom)*(endZoomOpacity - startZoomOpacity))/(endZoom - startZoom);

exports.createMapOverlayTextStyleFunction = (mapOverlay) =>
{
    const { dynamicOverlaySettings, defaultTextOpacity, toolTipText, textColor} = mapOverlay;
    const view = new View();
    
    return (feature, resolution) =>
     {
         const currentZoomLevel = view.getZoomForResolution(resolution).toFixed(2);
 
         const textStyleOptions = exports.createStyle({
             stroke: "rgba(0, 0, 0, 0)",
             fill: "rgba(0, 0, 0, 0)",
         });
 
         let textOpacity;
         let displayTextLabel = toolTipText;
 
         if (currentZoomLevel < 14)
         {
             textOpacity = 0;
         }
         else if (dynamicOverlaySettings.enabled)
         {
            let { 
                textOpacity: resultTextOpacity, 
                displayTextLabel: resultDisplayTextLabel 
            } = getTextOpacityForDynamicSettings(dynamicOverlaySettings, currentZoomLevel, displayTextLabel);

            textOpacity = resultTextOpacity;
            displayTextLabel = resultDisplayTextLabel;
         }
         else
         {
             textOpacity = defaultTextOpacity;
         }
 
         let textFeatureStyle = new Style(textStyleOptions);
         const textFontOptions = exports.getTextFontOptions(textOpacity, undefined, textColor);
         textFeatureStyle.setText(exports.createTextStyle(displayTextLabel, 0, textFontOptions));
     
         return textFeatureStyle;
     }
};

exports.createMapOverlayTextFeature = (mapOverlay, mapOverlayTextStyleFucntion) =>
 {
     const view = new View();
     const geometry = createEntityGeometry(mapOverlay.shape);
     const textCoordinates = getCenter(geometry.getExtent());
     const textGeometry = new Point(textCoordinates);
     const textFeature = new Feature({
         geometry: textGeometry,
     });

     textFeature.setStyle(mapOverlayTextStyleFucntion);
     textFeature.setId(`${mapOverlay._id}_text`);
 
     return textFeature;
 };

 exports.createMapOverlayLayer = (mapOverlay, mapOverlayStyleFucntion, mapOverlayTextFeature) =>
{
    const geometry = createEntityGeometry(mapOverlay.shape);

    const feature = new Feature({
        geometry,
        mapOverlayDetails: mapOverlay
    });

    feature.setId(`${mapOverlay._id}`);

    let features = [feature];

    features.push(mapOverlayTextFeature);

    const vectorSource = new VectorSource({
        features
    });

    const vectorLayer = new VectorLayer({
        source: vectorSource,
        isMapOverlay: true,
        id: mapOverlay._id
    });

    vectorLayer.setStyle(mapOverlayStyleFucntion);

    vectorLayer.setIsMapOverlay

    return vectorLayer;
};

exports.getTemplateStyle = (template) =>
{
    if(!template) return;
    const view = new View();
    
    const { color, lineColor, defaultFillOpacity, defaultBorderFillOpacity, dynamicOverlaySettings } = template;

    if (dynamicOverlaySettings.enabled) {
        // Dynamic style function generation
        return (feature, resolution) => {
            // Getting current zoom level based on resolution
            const currentZoomLevel = view.getZoomForResolution(resolution).toFixed(2);
            let styleOptions = {};

            // Extracting zoom levels from dynamic overlay settings
            const { zoomLevels } = dynamicOverlaySettings;

            // Determining start and end zoom levels
            const lastZoomLevel = zoomLevels.length - 1;
            const startZoom = zoomLevels[0];
            const endZoom = zoomLevels[lastZoomLevel];

            // Setting style options based on current zoom level
            if (currentZoomLevel <= startZoom.value) {
                styleOptions = exports.createStyleOptions(color, lineColor, startZoom.fillOpacity, startZoom.borderFillOpacity);
            } else if (currentZoomLevel >= endZoom.value) {
                styleOptions = exports.createStyleOptions(color, lineColor, endZoom.fillOpacity, endZoom.borderFillOpacity);
            } else {
                // If current zoom level falls between start and end zoom levels, get style options dynamically
                styleOptions = getPolygonStyleOptionsForDynamicSettings(dynamicOverlaySettings, currentZoomLevel, color, lineColor);
            }

            // Creating and returning style based on style options
            return exports.createStyle(styleOptions);
        };
    } else {
        // Static style object generation when dynamic overlay settings are disabled
        const styleOptions = exports.createStyleOptions(color, lineColor, defaultFillOpacity, defaultBorderFillOpacity);
        return exports.createStyle(styleOptions);
    }
};

exports.createStyleFunction = (mapOverlay) =>
{
    const view = new View();
    const { color, lineColor, dynamicOverlaySettings, defaultFillOpacity, defaultBorderFillOpacity } = mapOverlay;

    return (feature, resolution) =>
    {
        const currentZoomLevel = view.getZoomForResolution(resolution).toFixed(2);
        let styleOptions = {};

        if (dynamicOverlaySettings.enabled)
        {
            const { zoomLevels } = dynamicOverlaySettings;

            const lastZoomLevel = zoomLevels.length - 1;
            const startZoom = zoomLevels[0];
            const endZoom = zoomLevels[lastZoomLevel]

            if (currentZoomLevel <= startZoom.value)
            {
                styleOptions = exports.createStyleOptions(color, lineColor, startZoom.fillOpacity, startZoom.borderFillOpacity);
            }
            else if (currentZoomLevel >= endZoom.value)
            {
                styleOptions = exports.createStyleOptions(color, lineColor, endZoom.fillOpacity, endZoom.borderFillOpacity);
            }
            else
            {
                styleOptions = getPolygonStyleOptionsForDynamicSettings(dynamicOverlaySettings, currentZoomLevel, color, lineColor);
            }

        }
        else
        {
            styleOptions = exports.createStyleOptions(color, lineColor, defaultFillOpacity, defaultBorderFillOpacity);
        }

        return exports.createStyle(styleOptions);
    }
};

exports.createActiveMapOverlayStyle = (mapOverlay, mapSharingMode) =>
{
    const { color, lineColor, dynamicOverlaySettings, defaultFillOpacity, defaultBorderFillOpacity } = mapOverlay;
    let styleOptions = {};

    if (dynamicOverlaySettings.enabled)
    {
        const { zoomLevels } = dynamicOverlaySettings;

        const zoomLevelSettings = zoomLevels.find((zoomLevel) => zoomLevel.id === mapSharingMode);

        if (zoomLevelSettings)
        {
            styleOptions = exports.createStyleOptions(color, lineColor, zoomLevelSettings.fillOpacity, zoomLevelSettings.borderFillOpacity);
        }
    }
    else
    {
        styleOptions = exports.createStyleOptions(color, lineColor, defaultFillOpacity, defaultBorderFillOpacity);
    }

    return exports.createStyle(styleOptions);
};

exports.createActiveMapOverlayTextStyleFunction = (mapOverlay, mapSharingMode) =>
{
    const { dynamicOverlaySettings, defaultTextOpacity, toolTipText, textColor } = mapOverlay;
    const view = new View();

    return (feature, resolution) =>
     {
         const currentZoomLevel = view.getZoomForResolution(resolution).toFixed(2);
 
         const textStyleOptions = exports.createStyle({
             stroke: "rgba(0, 0, 0, 0)",
             fill: "rgba(0, 0, 0, 0)",
         });
 
         let textOpacity;
         let displayTextLabel = toolTipText; 
 
         if (currentZoomLevel < 14)
         {
             textOpacity = 0;
         }
         else if (dynamicOverlaySettings.enabled)
         {
             const { zoomLevels } = dynamicOverlaySettings;

             const zoomLevelSettings = zoomLevels.find((zoomLevel) => zoomLevel.id === mapSharingMode);

             if (zoomLevelSettings)
             {
                textOpacity = zoomLevelSettings.textOpacity;
                if (zoomLevelSettings.overrideGlobalTextLabel)
                {
                    displayTextLabel = zoomLevelSettings.textLabel;
                }
             }
         }
         else
         {
             textOpacity = defaultTextOpacity;
         }
 
         let textFeatureStyle = new Style(textStyleOptions);
         const textFontOptions = exports.getTextFontOptions(textOpacity, undefined, textColor);
         textFeatureStyle.setText(exports.createTextStyle(displayTextLabel, 0, textFontOptions));
     
         return textFeatureStyle;
     }
};

exports.getMergedPolygon = (features) =>
{
    let format = new GeoJSON();

    const polygonGeoJsonList = features.map((feature) => format.writeFeatureObject(feature));
    
    let mergedPolygon = polygonGeoJsonList.length ? polygonGeoJsonList.pop() : undefined;

    if (polygonGeoJsonList && polygonGeoJsonList.length)
    {
        polygonGeoJsonList.forEach((polygon) =>
        {
            mergedPolygon = turf.union(mergedPolygon, polygon);
        });
    }

    if (mergedPolygon)
    {
        mergedPolygon.geometry.coordinates = this.convertToGPS(mergedPolygon.geometry.coordinates);
    }

    return mergedPolygon;
};

exports.convertToGPS = (coordinates) =>
{
    let gpsCoordinates = {};

    coordinates.forEach((set, i) =>
    {
        let coords = [];
        set.forEach((coordinate) =>
        {
            coords.push(toGpsLocation(coordinate));
        });

        gpsCoordinates[i] = coords;
    });

    return gpsCoordinates;
};

exports.createDynamicMapLayer = (
    dynamicMapLayer, 
    mapOverlay, 
    value, 
    format=""
) =>
{
    let vectorLayer = undefined;
    let conditionValue = value || value === 0 ? value : dynamicMapLayer.initialValue;

    if (conditionValue || conditionValue === 0)
    {
        let matchedAutomationRule = undefined;
        for (let i=0; i<dynamicMapLayer.automationRules.length; i++)
        {
            const automationRule = dynamicMapLayer.automationRules[i];
            if (checkIfAutomationRuleMatchesValue(automationRule, conditionValue))
            {
                matchedAutomationRule = automationRule;
                break;
            }
        }

        if (matchedAutomationRule)
        {
            const styleFields = {
                fillColor: matchedAutomationRule.fillColor,
                borderColor: matchedAutomationRule.borderColor,
                fillOpacity: matchedAutomationRule.fillOpacity,
                borderFillOpacity: matchedAutomationRule.borderOpacity,
                textOpacity: matchedAutomationRule.textOpacity,
                textColor: matchedAutomationRule.textColor || DEFAUTL_DYNAMIC_MAP_LAYER_TEXT_COLOR,
            };

            // if display format is given, update condition value according to it
            let formattedConditionValue = conditionValue;
            if (format)
            {
                formattedConditionValue = formatConditionValue(conditionValue, format);
            }

            vectorLayer = createVectorLayerForDynamicMapLayer(
                dynamicMapLayer._id, 
                matchedAutomationRule._id, 
                mapOverlay.shape, 
                styleFields, 
                matchedAutomationRule.textLabel, 
                formattedConditionValue
            );
        }
    }

    // if no condition value or none of the automation rule matches, use map overlay default settings
    if (!vectorLayer)
    {
        if (conditionValue || conditionValue === 0)
        {
            // update condition value according to display format
            if (format)
            {
                mapOverlay.conditionValue = formatConditionValue(conditionValue, format);
            }
            else
            {
                mapOverlay.conditionValue = conditionValue;
            }

        }

        vectorLayer = exports.createMapOverlayVectorLayer(mapOverlay);
    }

    return vectorLayer;
};

const createDynamicMapLayerTextFeature = (dynamicMapLayerId, automationRuleId, shape, textLabel, textOpacity, conditionValue, textColor) =>
{
    const geometry = createEntityGeometry(shape);
    let textCoordinates = getCenter(geometry.getExtent());

    const textGeometry = new Point(textCoordinates);
    const textFeature = new Feature({
        geometry: textGeometry,
    });

    const textStyleOptions = exports.createStyle({
        stroke: "rgba(0, 0, 0, 0)",
        fill: "rgba(0, 0, 0, 0)",
    });

    const view = new View();

    const textFeatureStyleFunction = (feature, resolution) =>
    {
        const currentZoomLevel = view.getZoomForResolution(resolution).toFixed(2);
        let finalTextOpacity = textOpacity;
        if (currentZoomLevel < 14)
        {
            finalTextOpacity = 0;
        }
        let textFeatureStyle = new Style(textStyleOptions);
        const textFontOptions = exports.getTextFontOptions(finalTextOpacity, undefined, textColor);
        textFeatureStyle.setText(createTextStyleWithLineBreak(textLabel, 0, textFontOptions, conditionValue));
        return textFeatureStyle;
    };
    
    textFeature.setStyle(textFeatureStyleFunction);
    textFeature.setId(`${automationRuleId}_text`);
    textFeature.set("isDynamicMapLayerText", true);
    textFeature.set("dynamicMapLayerId", dynamicMapLayerId);
    textFeature.set("automationRuleId", automationRuleId);
 
    return textFeature;
};

const formatConditionValue = (conditionValue, format) =>
{
    const [isMulti, text] = exports.addLineBreaksToString(format.replace("{value}", conditionValue));
    return text;
};

const createTextStyleWithLineBreak = (name, textRotation = 0, textStyle, conditionValue) =>
{
    const rotationRad = textRotation * Math.PI / 180;

    const [isMulti, text] = exports.addLineBreaksToString(name);

    let textLabel = text;

    if (conditionValue || conditionValue === 0)
    {
        textLabel = `${textLabel}\n${conditionValue}`;
    }

    const LARGE_SCALE = 1.1;
    const SMALL_SCALE = 1;
    if (textStyle)
    {
        return new Text({
            text: textLabel,
            rotation: rotationRad,
            overflow: true,
            fill: new Fill(textStyle.fill),
            font: textStyle.font,
            stroke: new Stroke(textStyle.stroke),
            scale: isMulti ? SMALL_SCALE : LARGE_SCALE
        });
    }
    else
    {
        return new Text({
            text: textLabel,
            rotation: rotationRad,
            scale: isMulti ? SMALL_SCALE : LARGE_SCALE
        });
    }
};

const checkIfAutomationRuleMatchesValue = (automationRule, value) =>
{
    let isMatch = false;

    const { dataPointCondition } = automationRule;

    if (dataPointCondition.condition === "greater")
    {
        isMatch = value > +dataPointCondition.value;
    }
    else if (dataPointCondition.condition === "lesser")
    {
        isMatch = value < +dataPointCondition.value;
    }
    else if (dataPointCondition.condition === "equal")
    {
        isMatch = value === +dataPointCondition.value;
    }

    return isMatch;
};

const createVectorLayerForDynamicMapLayer = (dynamicMapLayerId, automationRuleId, shape, styleFields, textLabel, conditionValue=undefined) =>
{
    const { fillColor, borderColor, fillOpacity, borderFillOpacity } = styleFields;
     
    const styleOptions = exports.createStyleOptions(fillColor, borderColor, fillOpacity, borderFillOpacity);

    const geometry = createEntityGeometry(shape);

    const feature = new Feature({
        geometry,
    });

    feature.setId(dynamicMapLayerId);

    const textFeature = createDynamicMapLayerTextFeature(dynamicMapLayerId, automationRuleId, shape, textLabel, styleFields.textOpacity, conditionValue, styleFields.textColor);

    let features = [feature, textFeature];

    const vectorSource = new VectorSource({
        features
    });

    const vectorLayer = new VectorLayer({
        source: vectorSource,
        isMapOverlay: true,
        id: dynamicMapLayerId
    });

    vectorLayer.setStyle(exports.createStyle(styleOptions));

    vectorLayer.set("isDynamicMapLayer", true);

    return vectorLayer;
};

exports.getTopCenterOfExtent = (extent) =>
{
    const topRight = getTopRight(extent);
    const center = getCenter(extent);

    return [center[0], topRight[1]];
};

exports.getTextLayer = (mapOverlays, textLayerId="mapOverlayTextLayer") =>
{

    let features = [];
    const view = new View();

    mapOverlays.forEach((mapOverlay) =>
    {
        const textFeature = createTextFeature(mapOverlay, view);
        features.push(textFeature);
    });

    const textLayer = new VectorLayer({
        source: new VectorSource({
            features
        })
    });

    textLayer.set("id", textLayerId);
    textLayer.set("isMapOverlay", true);

    return textLayer;
};

exports.getClusteredTextLayers = (mapOverlays) =>
{
    let clusteredTextLayers = [];

    // group map overlays based on dynamic zoom levels
    const mapOverlayGroups = groupMapOverlaysBasedOnZoomLevels(mapOverlays);

    Object.values(mapOverlayGroups).forEach((mapOverlayGroup, index) =>
    {
        const textLayerId = `mapOverlayTextLayer_${index}`;
        // create clustered text layer for the group if the number of map overlays it contains it greater that threshold value
        if (mapOverlayGroup.length > THRESHOLD_MAPOVERLAY_NUMBERS)
        {
            // chunk the number of features that would be added to clusters if it exceeds certain limits
            const chunkedArray = chunkArray(mapOverlayGroup, MAP_OVERLAYS_TEXT_CLUSTER_GROUP_LIMIT);
            chunkedArray.forEach((array, index) => clusteredTextLayers.push(exports.getClusteredTextLayer(array, `${textLayerId}_${index}`)));
        }
        // if the number of map overlays is below threshold create the normal text layer (without clustering)
        else
        {
            clusteredTextLayers.push(exports.getTextLayer(mapOverlayGroup, textLayerId));
        }
        
    })

    return clusteredTextLayers;
};

const chunkArray = (array, size) =>
{
    let chuckedArray = [];
    if (size > 0)
    {
        for (let i = 0; i < array.length; i += size) {
            const chunk = array.slice(i, i + size);
            chuckedArray.push(chunk);
        }
    }
    else
    {
        chuckedArray.push(array);
    }
    return chuckedArray;
};

const groupMapOverlaysBasedOnZoomLevels = (mapOverlays) =>
{
    let mapOverlayGroups = {};

    mapOverlays.forEach((mapOverlay) =>
    {
        let groupName="default";
        if (mapOverlay.dynamicOverlaySettings.enabled && mapOverlay.dynamicOverlaySettings.zoomLevels.length)
        {
            const { zoomLevels } = mapOverlay.dynamicOverlaySettings;
            const startZoom = zoomLevels[0];
            const endZoom = zoomLevels[zoomLevels.length - 1];
            groupName = `${startZoom.value}_${startZoom.textOpacity}-${endZoom.value}_${endZoom.textOpacity}`;
        }
       
        if (mapOverlayGroups[groupName])
        {
            mapOverlayGroups[groupName].push(mapOverlay);
        }
        else
        {
            mapOverlayGroups[groupName] = [mapOverlay];
        }
    });

    return mapOverlayGroups;
};

exports.getClusteredTextLayer = (mapOverlays, textLayerId="mapOverlayTextLayer") =>
{
    let features = [];
    let styleFunctionMap = {};

    const view = new View();

    mapOverlays.forEach((mapOverlay) =>
    {
        const { textFeature, styleFunction } = createTextFeatureAndStyle(mapOverlay, view, true);
        textFeature.setId(mapOverlay._id);
        styleFunctionMap[mapOverlay._id] = styleFunction;
        features.push(textFeature);
    });

    const clusterSource = new ClusterSource({
        source: new VectorSource({
            features
        }),
        distance: 30,
    });

    const textLayer = new VectorLayer({
        source: clusterSource,
        style: (feature, resolution) =>
        {
            const visibleFeature = feature.get("features")[0];
            if (styleFunctionMap[visibleFeature.getId()])
            {
                const styleFunction = styleFunctionMap[visibleFeature.getId()];
                return styleFunction(visibleFeature, resolution);
            }
            else
            {
                return null;
            }
        }
    });

    textLayer.set("id", textLayerId);
    textLayer.set("isMapOverlay", true);

    return textLayer;
}

/**
 * Adds text with styling to text style.
 *
 * @param {String} name
 * @param {Number} textRotation
 * @param {Object} textStyle
 */
 exports.createTextStyle = (name, textRotation = 0, textStyle) =>
 {
     const rotationRad = textRotation * Math.PI / 180;
     const [isMulti, text] = exports.addLineBreaksToString(name);
     const LARGE_SCALE = 1.1;
     const SMALL_SCALE = 1;
     if (textStyle)
     {
         return new Text({
             text,
             rotation: rotationRad,
             overflow: true,
             fill: new Fill(textStyle.fill),
             font: textStyle.font,
             stroke: new Stroke(textStyle.stroke),
             scale: isMulti ? SMALL_SCALE : LARGE_SCALE
         });
     }
     else
     {
         return new Text({
             text,
             rotation: rotationRad,
             scale: isMulti ? SMALL_SCALE : LARGE_SCALE
         });
     }
 };

/**
 * Add line break and limits string length after appending ellipses if the input string length exceeds the threshold limit 
 * @param {String} input 
 * @returns {String}
 */
exports.addLineBreaksToString = (input) =>
 {
     if (typeof input !== "string")
     {
         return [false, ""];
     }
     const splitByWhite = input.split(/\s+/);
     let isMulti = false;
     let outputString = splitByWhite.join(" ");

     if (splitByWhite.length > 2)
     {
        isMulti = true;
         // add \n after second word
         outputString = splitByWhite.slice(0, 2).join(" ") + "\n" + splitByWhite.slice(2).join(" ");
     }
     else if (splitByWhite.length === 2)
     {
        isMulti = true;
         // add \n in between if either word is longer than 6
         if (splitByWhite.some((word) => word.length >= 7))
         {
            outputString = splitByWhite.join("\n");
         }
     }

     // if text length exceeds limit append ellipsis and stop displaying rest of the letters
     if (outputString.length > TEXT_LABEL_LENGTH_LIMIT)
     {
        outputString = outputString.slice(0, TEXT_LABEL_LENGTH_LIMIT) + "...";
     }

     return [isMulti, outputString];
 }