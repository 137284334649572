import React, { useContext, useEffect, useRef } from "react";
import { useSetState } from "ahooks";
import { set as setDeepValue, get as getDeepValue, isEmpty, debounce } from "lodash";
import * as olStyle from "ol/style";
import { Feature, Overlay } from "ol";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Point } from "ol/geom";
import { toLonLat, fromLonLat } from "ol/proj";
import { Button } from "semantic-ui-react";
import { MouseInteraction, } from "mapsted.maps/utils/interactionTemplates";
import { ModalBox } from "../../common/ModalBox";
import { useBrandingMap, useMapZoomMethods, usePoints } from "../../branding/map/mapHooks";
import { MapButtons } from "../../branding/map/MapButtons";
import BrandingContext from "../../../store/BrandingContext";
import { SettingsContext } from "../SettingsProvider";
import { KioskDetailsSection } from "./KioskDetailsSection";
import { DEFAULT_LANGUAGE_CODE } from "../../../_constants/constants";
import { useMutation, useQueryClient } from "react-query";
import { SETTINGS_MUTATIONS } from "../settings.queries";
import { ErrorLabel } from "../../elements/ErrorLabel";
import { filerUrl } from "../../../_utils/utils";
import { Scrollbars } from "react-custom-scrollbars-2";
import { ConfirmCloseActionModal } from "./ConfirmCloseActionModal";
import { useTranslation } from "react-i18next";


const CURRENT_KIOSK_POINTER_STYLE = new olStyle.Style({
    image: new olStyle.Icon({ src: "/img/icon-map-pin.svg" })
});

const FEATURE_TYPE = {
    KIOSK: "kiosk"
};

const getInitialState = (selectedKiosk) =>
{
    const state = {
        kiosk: {
            mapRotation: { [DEFAULT_LANGUAGE_CODE]: 0 },
            entity: {
                shape: {
                    type: "Point",
                    coordinates: undefined
                },
                entityLabel: {
                    longName: { [DEFAULT_LANGUAGE_CODE]: "" },
                    iconImage: { [DEFAULT_LANGUAGE_CODE]: "" },
                }
            },
            ...selectedKiosk,
        },

        // used to show kiosk name in popup when clicked
        clickedKioskName: undefined,
        // for now this is unchanged
        selectedLanguage: DEFAULT_LANGUAGE_CODE,

        validationErrors: [],

        confirmCloseModalOpen: false,

        // turns true when any changes made to kiosk
        isDirty: false
    };

    return state;
};

export const AddEditKioskModal = ({ kioskQueryKey, open, onClose, selectedKiosk, existingKiosks = [] }) =>
{
    // context stuff
    const { state: brandingState } = useContext(BrandingContext);
    const { loadingPool } = useContext(SettingsContext);

    // local state
    const [state, setState] = useSetState(getInitialState());

    const trans = useTranslation().t;

    // queries & mutations
    const queryClient = useQueryClient();
    const { mutateAsync: createKiosk, isLoading: isCreateKioskLoading, isError: isCreateKioskError } = useMutation({
        ...SETTINGS_MUTATIONS.CREATE_KIOSK(),
        onSuccess: () =>
        {
            queryClient.invalidateQueries(kioskQueryKey);
            onClose();
        }
    });
    const { mutateAsync: updateKiosk, isLoading: isUpdateKioskLoading, isError: isUpdateKioskError } = useMutation({
        ...SETTINGS_MUTATIONS.UPDATE_KIOSK(),
        onSuccess: () =>
        {
            queryClient.invalidateQueries(kioskQueryKey);
            onClose();
        }
    });

    useEffect(() =>
    {
        if (isCreateKioskLoading || isUpdateKioskLoading)
        {
            const loadingId = loadingPool.add();
            return () => loadingPool.remove(loadingId);
        }

        setState({ validationErrors: isCreateKioskError ? ["Error while creating Kiosk"] : [] });
        setState({ validationErrors: isUpdateKioskError ? ["Error while updating Kiosk"] : [] });

    }, [isCreateKioskLoading, isCreateKioskError, isUpdateKioskLoading, isUpdateKioskError]);

    const mapRef = useRef();
    // to keep track of all misc. things added to map
    const mapDataRef = useRef({ clickInteraction: undefined, rotationInteraction: undefined, vectorLayer: undefined });

    // popup related stuff
    const popupOverlayRef = useRef();
    const kioskNamePopupRef = useRef();

    // map related hooks
    const { mapRef: brandingMapRef, recenter } = useBrandingMap(mapRef);
    const { setMapZoom, zoomIn, zoomOut } = useMapZoomMethods(brandingMapRef);

    const kioskCoordinates = getDeepValue(state, "kiosk.entity.shape.coordinates");
    usePoints(brandingMapRef, kioskCoordinates ? [kioskCoordinates] : [], { active: open, style: CURRENT_KIOSK_POINTER_STYLE });

    useEffect(() =>
    {
        if (!open) return;

        // need to have this useEffect else map doesn't renders
        brandingMapRef.current.setTarget(mapRef.current);

        if (!kioskCoordinates)
        {
            recenter();
        }

        const newState = getInitialState(selectedKiosk);

        setMapRotation(newState.kiosk.mapRotation[newState.selectedLanguage]);

        setState(newState);

        addClickInteraction();

        addRotationInteraction();

        addPopupOverlay();

        // cleanup
        return () =>
        {
            removeClickInteraction();
            removeRotationInteraction();
            closeKioskNamePopup();
        };
    }, [open, selectedKiosk]);

    useEffect(() =>
    {
        if (open)
        {
            addExistingKiosksOnMap();
            return () => removeExistingKiosksOnMap();
        }

    }, [open, brandingState.propertyId, brandingState.floorId]);

    const addClickInteraction = () =>
    {
        const handleClickEventPoint = (event) =>
        {
            if (event.type !== "click")
            {
                return true;
            }

            const { coordinate: coordinates, pixel } = event;

            const feature = brandingMapRef.current.forEachFeatureAtPixel(
                pixel,
                // fields on feature set in addExistingKiosksOnMap()
                (feature) => feature.get("type") === FEATURE_TYPE.KIOSK ? feature : undefined
            );

            // When clicked on existing kiosk
            if (feature)
            {
                setState({ clickedKioskName: feature.get("name") });
                openKioskNamePopup(coordinates);
            }
            // When clicked on a new Location
            else
            {
                setState((prevState) =>
                {
                    const newState = { ...prevState, clickedKioskName: undefined, isDirty: true };
                    setDeepValue(newState, "kiosk.entity.shape.coordinates", toLonLat(coordinates));
                    return newState;
                });
                closeKioskNamePopup();
            }
        };

        const interaction = MouseInteraction({
            source: null,
            handleEvent: handleClickEventPoint,
        });
        brandingMapRef.current.addInteraction(interaction);
        mapDataRef.current.clickInteraction = interaction;
    };

    const addRotationInteraction = () =>
    {
        const interaction = debounce((e) =>
        {
            const rotation = e.target.getRotation() % (2 * Math.PI);

            setState((prevState) =>
            {
                const newState = setDeepValue({ ...prevState, isDirty: true }, `kiosk.mapRotation.${state.selectedLanguage}`, rotation);
                return newState;
            });
        }, 300);
        brandingMapRef.current.getView().on("change:rotation", interaction);
        mapDataRef.current.rotationInteraction = interaction;
    };

    const removeRotationInteraction = () =>
    {
        brandingMapRef.current.getView().un("change:rotation", mapDataRef.current.rotationInteraction);
    };

    const removeClickInteraction = () =>
    {
        brandingMapRef.current.removeInteraction(mapDataRef.current.clickInteraction);
    };

    const addExistingKiosksOnMap = () =>
    {
        let kiosksToPlot = existingKiosks;

        // filter out the current kiosk (when editing)
        if (!isEmpty(selectedKiosk))
        {
            kiosksToPlot = kiosksToPlot.filter((kiosk) => kiosk._id !== selectedKiosk?._id);
        }

        if (brandingState.floorId)
        {
            // kiosk for this floor
            kiosksToPlot = kiosksToPlot.filter((kiosk) => kiosk.floor?._id === brandingState.floorId);
        }
        else
        {
            // all property kiosk
            kiosksToPlot = kiosksToPlot.filter((kiosk) => !kiosk.floor);
        }

        const features = kiosksToPlot.map((kiosk) =>
        {

            const feature = new Feature({
                geometry: new Point(fromLonLat(kiosk.entity.shape.coordinates)),
                name: kiosk.entity.entityLabel.longName[state.selectedLanguage],
                type: FEATURE_TYPE.KIOSK
            });

            feature.setStyle(new olStyle.Style({
                image: new olStyle.Icon({ src: filerUrl(kiosk.entity.entityLabel.iconImage[state.selectedLanguage]), scale: 0.3 })
            }));

            return feature;
        });

        const pointLayer = new VectorLayer({ source: new VectorSource({ features }) });
        brandingMapRef.current.addLayer(pointLayer);
        mapDataRef.current.vectorLayer = pointLayer;
    };

    const removeExistingKiosksOnMap = () =>
    {
        brandingMapRef.current.removeLayer(mapDataRef.current.vectorLayer);
    };

    const addPopupOverlay = () =>
    {
        // Setup KioskDetailsPopup
        popupOverlayRef.current = new Overlay({
            element: kioskNamePopupRef.current,
            autoPan: {
                animation: {
                    duration: 250,
                },
            },
        });
        brandingMapRef.current.addOverlay(popupOverlayRef.current);
    };

    const openKioskNamePopup = (coordinates) =>
    {
        popupOverlayRef.current.setPosition(coordinates);
    };

    const closeKioskNamePopup = () =>
    {
        popupOverlayRef.current.setPosition(undefined);
    };

    const setMapRotation = (rotation) =>
    {
        brandingMapRef.current.getView().setRotation(rotation);
    };

    const handleConfirmClose = () =>
    {
        if (state.isDirty)
        {
            // show confirmation modal
            setState({ confirmCloseModalOpen: true });
        }
        else
        {
            onClose();
        }
    };

    const handleClose = () =>
    {
        setState({ confirmCloseModalOpen: false });
        onClose();
    };

    const handleAddAndUpdate = async () =>
    {
        const validationErrors = validateKiosk({ ...state, trans });
        setState({ validationErrors });

        if (!isEmpty(validationErrors)) return;

        const { propertyId, buildingId, floorId, } = brandingState;
        const { selectedLanguage } = state;

        const data = {
            propertyId: propertyId,
            buildingId: buildingId,
            floorId: floorId,
            ...state.kiosk,
            languageCode: selectedLanguage,
        };
        data.entity.entityLabel.shortName = data.entity.entityLabel.longName;

        if (isEmpty(selectedKiosk))
        {
            await createKiosk(data);
        }
        else
        {
            delete data.floor;
            await updateKiosk(data);
        }


        onClose();
    };

    

    const renderErrors = () => (
        !isEmpty(state.validationErrors) && (
            <ErrorLabel multiple>
                {trans("CreateMapOverlaysSideBar.Validation_Error_Header")}
                <br />
                <br />
                {state.validationErrors.map((error) => <>{error} <br /></>)}
            </ErrorLabel>
        )
    );

    return (
        <>
            <ModalBox
                className="addKioskModal"
                size="fullscreen"
                open={open}
                onClose={handleConfirmClose}
                header={`${isEmpty(selectedKiosk) ? trans("Settings.Add_new_kiosk") : trans("Settings.Edit_kiosk")}`}>
                <div className="modalMapWrap modalKioskWrap">
                    <div className="modalMapBody">
                        <span className="instructionTooltip">{trans("Settings.Map_Rotate_Instructions")}</span>
                        <div className="map-container" ref={mapRef}>
                            <MapButtons onZoomIn={zoomIn} onZoomOut={zoomOut} />
                        </div>
                        <KisoskNamePopup
                            popupRef={kioskNamePopupRef}
                            content={state.clickedKioskName}
                        />
                    </div>
                    <div className="modalMapSidebar">
                        <Scrollbars autoHeight autoHeightMin={"calc(1vh)"} autoHeightMax={"calc(100vh - 320px)"}>
                            <div className="alignerKioskSidebar">
                                <KioskDetailsSection
                                    details={state.kiosk}
                                    language={state.selectedLanguage}
                                    onChangeDetails={(details) => setState({ kiosk: details, isDirty: true })}
                                    onResetRotation={() => setMapRotation(0)}
                                />
                                {renderErrors()}
                            </div>
                        </Scrollbars>
                        <Button
                            className="buttonAddKioskFloat"
                            // disable only when updating a kiosk
                            disabled={!isEmpty(selectedKiosk) && !state.isDirty}
                            color="orange"
                            floated="right"
                            content={selectedKiosk ? trans("KioskDetailsSection.Save") : trans("KioskDetailsSection.Add")}
                            onClick={handleAddAndUpdate}
                        />

                    </div>
                </div>
            </ModalBox>

            <ConfirmCloseActionModal
                open={state.confirmCloseModalOpen}
                onConfirm={handleClose}
                onClose={() => setState({ confirmCloseModalOpen: false })}
            />
        </>
    );
};

const KisoskNamePopup = ({ popupRef, content }) => (
    <div
        ref={popupRef}
        className="kioskNameTooltip">
        <p>{content}</p>
    </div>
);

const validateKiosk = ({ kiosk, selectedLanguage, trans }) =>
{
    const errors = [];

    if (!getDeepValue(kiosk, `entity.entityLabel.longName.${selectedLanguage}`)) errors.push(trans("ValidateKiosk.Name_Validaton"));
    if (!getDeepValue(kiosk, `entity.entityLabel.iconImage.${selectedLanguage}`)) errors.push(trans("ValidateKiosk.Icon_Validation"));
    const kioskCoordinates = getDeepValue(kiosk, "entity.shape.coordinates");
    if (isEmpty(kioskCoordinates)) errors.push(trans("ValidateKiosk.Location_Validation"));

    return errors;
};
