import * as pdfjs from "pdfjs-dist/legacy/build/pdf";
import pdfjsWorker from "pdfjs-dist/legacy/build/pdf.worker.entry";
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
/**
 * Asynchronously prepares and returns image data URLs from the given PDF URL.
 *
 * @param {string} pdfUrl - The URL of the PDF to extract images from.
 * @return {Promise<Array<string>>} An array of image data URLs.
 */
export const prepareImagesFromPdfUrl = async (file, imageType = "image/png") =>
{
    const { name, blobUrl } = file;
    const imagePrefix = "" + name.split(".pdf")[0];
    const pdfToImages = [];
    try
    {
        // Initialize PDF.js worker and loading task
        const loadingTask = pdfjs.getDocument(blobUrl);
        const pdf = await loadingTask.promise;


        // Loop through all pages
        for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++)
        {
            // Fetch the page
            const page = await pdf.getPage(pageNumber);

            // Set up canvas
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            const viewport = page.getViewport({ scale: 1.0 });

            canvas.height = viewport.height;
            canvas.width = viewport.width;

            // Render PDF page on canvas
            const renderContext = {
                canvasContext: context,
                viewport: viewport
            };
            await page.render(renderContext).promise;

            // Convert canvas to Image Data URL
            const data64Url = canvas.toDataURL(imageType, 1);

            const imageCanvas = document.createElement("canvas");
            const ctx = imageCanvas.getContext('2d');
            const img = new Image();
            img.src = data64Url;

            const imageDataGeneratedFromPdfPage = {
                data64Url,
                name: `${imagePrefix}-page-${pageNumber}.png`,
                mimeType: imageType,
                aspectRatio: img.width / img.height,
                fileName: name,
                type: imageType,

            };
            img.onload = function ()
            {
                ctx.drawImage(img, 0, 0);
                //when image is loaded update the aspect ratio
                Object.assign(imageDataGeneratedFromPdfPage, { aspectRatio: img.width / img.height });
            };



            imageCanvas.toBlob((blob) =>
            {
                blob.name = imageDataGeneratedFromPdfPage.name;
                Object.assign(imageDataGeneratedFromPdfPage, { blob });
                return blob;
            }, imageType, 1);

            pdfToImages.push(imageDataGeneratedFromPdfPage);

        }

        return pdfToImages;
    }
    catch (error)
    {
        console.error("Error converting PDF to Image Data URLs:", error);
        return [];
    }
};
