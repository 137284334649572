import React from "react";
import { Button } from "semantic-ui-react";
import "./ImagesManagementFooter.css";
import { DELETE_IMAGE_STRATEGY_ACTIONS } from "../../constant";
import { useImageDeleteContext } from "../contexts/ImageDeleteContext";
import { useImagesBaseContext } from "../contexts/ImageBaseContext";
import { GmsActionMode, useGmsContext } from "../../GmsContext";

function ImagesManagementFooter() 
{
    const { gmsActionMode } = useGmsContext();
    const { handleAddIconImage, imagesSelectedForLinking, selectedImageInfo } =
    useImagesBaseContext();
    const imageDeleteContext = useImageDeleteContext();
    const {
        handleChooseNewImageBasedOnDeleteStrategy,
        selectedDeleteImageStrategy,
    } = useImageDeleteContext();

    const isDisabled =
    (gmsActionMode === GmsActionMode.LINK_ICON_IMAGE
      || gmsActionMode === GmsActionMode.LINK_COVER_IMAGES
      || selectedDeleteImageStrategy
        === DELETE_IMAGE_STRATEGY_ACTIONS.CHOOSE_NEW_IMAGE_AND_DELETE_OLD_ONE)
    && !imagesSelectedForLinking.length;

    return (
        <div className="images-management-footer">
            <Button
                disabled={isDisabled}
                onClick={
                    selectedDeleteImageStrategy === DELETE_IMAGE_STRATEGY_ACTIONS.CHOOSE_NEW_IMAGE_AND_DELETE_OLD_ONE? handleChooseNewImageBasedOnDeleteStrategy: handleAddIconImage
                }
                color="orange"
            >
                {gmsActionMode === GmsActionMode.REPLACE_IMAGE? "Replace": "Add"}
            </Button>
            {gmsActionMode === GmsActionMode.REPLACE_IMAGE 
            && <Button onClick={imageDeleteContext.handleCancelChooseNewImageBasedOnDeleteStrategy} className="cancel-btn">
                Cancel
            </Button>}
        </div>
    );
}

export default ImagesManagementFooter;
