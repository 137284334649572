import { styleClassicNodes } from "mapsted.maps/utils/defualtStyles";
import { FLOOR_PLAN_LAYERS_IDS } from "mapsted.maps/utils/map.constants";
import { altKeyOnly, click, platformModifierKeyOnly, shiftKeyOnly } from "ol/events/condition";
export const GUIDE_LINE_STYLE = {
    stroke: {
        color: "#5944f1",
        width: 1,
        lineDash: [2, 5]
    },
    fill: {
        color: "#5944f1",
    }
};

export const ANGLE_TEXT_FEATURE_STYLE = {
    stroke: {
        color: "rgba(0, 0, 0, 0)",

    },
    fill: {
        color: "rgba(0, 0, 0, 0)",
    }
};

export const ANGLE_TEXT_STYLE = {
    offsetX: 15,
    offsetY: 15,
    font: "12px sans-serif",
    stroke:
    {
        color: "#FFF",
    },
    fill:
    {
        color: "#000",
    }
};

export const ANGLE_TEXT_STYLE_GREEN = {
    offsetX: 15,
    offsetY: 15,
    font: "12px sans-serif",
    stroke:
    {
        color: "#FFF",
    },
    fill:
    {
        color: "#4BB543",
    }
};

export const LINKED_NODES_STYLE = {
    stroke: {
        width: styleClassicNodes.node.stroke.width,
        color: "#44F159"
    },
    fill: {
        color: "#44F159"
    },
    shape: styleClassicNodes.node.shape
};

export const SELECTED_NODES_STYLE = {
    stroke: {
        width: styleClassicNodes.node.stroke.width,
        color: "#5944f1"
    },
    fill: {
        color: "#5944f1"
    },
    shape: styleClassicNodes.node.shape
};

export const FILTERED_NODES_STYLE = {
    stroke: {
        width: styleClassicNodes.node.stroke.width,
        color: "red"
    },
    fill: {
        color: "red"
    },
    shape: styleClassicNodes.node.shape
};

export const EDIT_TYPES = {
    NODES: "nodes",
    ENTITIES: "entities",
    TRANSITIONS: "transitions",
    VALIDATION: "validation",
};

export const NODE_TOOL_SHORTCUTS = {
    NODE: "n",
    AUTO_LINK: "a",
    HAND: "h",
    LINK: "l",
    DELETE: "d",
    DELETE_RECONNECT: "r",
};

export const MAP_EDITOR_SHORTCUTS = {
    UNDO: "z",
    REDO: "y",
    ESCAPE: "esc"
};

export const EDITOR_ALERTS = {
    LEVEL_NOT_LOCKED: "To edit, lock the floor you are working on.",
    LEVEL_IS_LOCKED_BY_ANOTHER_USER: "No edits can be made until level is unlocked by",
    USER_HAS_ANOTHER_LOCKED_LEVEL: "To lock, unlock the current floor you are working on.",
    NOT_VALID_NODE_LINK: "Node linkage is not valid",
    CANT_DELETE_TRANSITION: "Transition nodes can not be deleted at this time",
    EDIT_TYPE_NOT_LOCKED: "Edit type not locked",
    CANT_DELETE_BOUNDARY: "Boundaries can not be deleted at this time",
    CANT_CREATE_INTERSECTING_POLYGON: "Polygons can't be self intersecting",
    CANT_CREATE_CLOSED_LINESTRING_LOOP: "Polylines can't contain a closed loop",
    OVERLAPING_NODES: "Nodes can not overlap other nodes",
    NODE_FORBIDDEN: "Nodes can not be placed over floor openings",
    NODE_USED_IN_ACTIVE_TRANSITION: "A node is currently being used for a transition on the current plotting levels",
    TRANSITION_SAME_NODE: "Transition must be made with two separate nodes",
    CANT_SPLIT_ENTITY: "Entity split is invalid",
    CANT_MERGE_ENTITIES: "Entity merge is invalid",
    CANT_SPLIT_ENTITY_WITH_TRANSITION: "Entities with transition nodes can not be split at this time",
    CANT_MERGE_ENTITIES_WITH_TRANSITION: "Entities with transition nodes can not be merged at this time",
    LEVEL_IS_LOCKED_ON_DIFFERENT_MACHINE: "Unable to edit data. Please unlock the level and then lock it again to proceed with editing.",
    
};

export const FLOOR_PLAN_LAYER_IDS = {
    INTERACTION_VECTOR_LAYER: FLOOR_PLAN_LAYERS_IDS.FLOOR_PLAN_VECTOR_LAYER,
    FLOOR_PLAN_IMAGE_LAYER: FLOOR_PLAN_LAYERS_IDS.FLOOR_PLAN_GEO_REF_IMAGE_LAYER,
};

export const NODE_TRANSITION_LAYER_ID = "node_transition_layer";

// layer id to layer display name
export const MAP_LAYERS = {
    entityIds: "Entity ID Layer",
    nodeIds: "Node ID Layer",
    text_layer: "Text Layer",
    edit_entity_layer: "Edit Entity Layer",
    new_entities_layer: "New Entities Layer",
    node: "Nodes Layer",
    node_transition_layer: "Node Transition Layer",
    node_helper_layer: "Node Helper Layer",
    nodeConnection: "Node Connection Layer",
    exitEntranceNode: "Entrance/Exit Node Layer",
    [FLOOR_PLAN_LAYER_IDS.FLOOR_PLAN_IMAGE_LAYER]: FLOOR_PLAN_LAYER_IDS.FLOOR_PLAN_IMAGE_LAYER,
    [FLOOR_PLAN_LAYER_IDS.INTERACTION_VECTOR_LAYER]: FLOOR_PLAN_LAYER_IDS.INTERACTION_VECTOR_LAYER,
    6: "POI Layer",
    5: "Transitions Layer",
    4: "Obstacles Layer",
    3: "Structures Layer",
    2: "Pathway Layer",
    1: "Boundary Layer",

};

export const NODES_TOGGLABLE_LAYERS = [
    "entityIds",
    "nodeIds",
    "text_layer",
    "node",
    "nodeConnection",
    "exitEntranceNode",
    "6",
    "5",
    "4",
    "3",
    "2",
    "1",
];

export const ENTITIES_TOGGLABLE_LAYERS = [
    "entityIds",
    "text_layer",
    "new_entities_layer",
    FLOOR_PLAN_LAYER_IDS.FLOOR_PLAN_IMAGE_LAYER,
    "6",
    "5",
    "4",
    "3",
    "2",
    "1",
];

export const TRANSITIONS_TOGGLABLE_LAYERS = [
    "entityIds",
    "nodeIds",
    "text_layer",
    "node",
    "node_transition_layer",
    "nodeConnection",
    "6",
    "5",
    "4",
    "3",
    "2",
    "1",
];

export const DEFAULT_MAP_TOGGLABLE_LAYERS = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "text_layer",
];

export const DEFAULT_MAP_VISIBILITY = {
    1: false,
    2: true,
    3: true,
    4: true,
    5: true,
    6: true,
    text_layer: true,
    entityIds: false,
    nodeIds: false,
};

export const DEFAULT_ENTITY_VISABILITY = {
    entityIds: false,
    text_layer: true,
    new_entities_layer: true,
    [FLOOR_PLAN_LAYER_IDS.FLOOR_PLAN_IMAGE_LAYER]: false,
    6: true,
    5: true,
    4: true,
    3: true,
    2: true,
    1: false,
};

export const DEFAULT_NODE_VISABILITY = {
    nodeIds: false,
    entityIds: false,
    text_layer: true,
    node: true,
    nodeConnection: true,
    exitEntranceNode: false,
    6: true,
    5: true,
    4: true,
    3: true,
    2: true,
    1: false,
};

export const DEFAULT_TRANSITION_VISABILITY = {
    nodeIds: false,
    entityIds: false,
    text_layer: true,
    node: true,
    node_transition_layer: true,
    nodeConnection: true,
    exitEntranceNode: false,
    6: true,
    5: true,
    4: true,
    3: true,
    2: true,
    1: false,
};

export const NODE_EDITOR_FILTERS =
{
    SEARCH: "Search",
    ROUTABLE_ENTITIES_WITHOUT_NODES: "Routable entities without nodes",
    FLOATING_NODES: "Floating nodes",
    LEAF_NODES: "Leaf nodes",
};

export const ENTITY_EDITOR_FILTERS =
{
    SEARCH: "Search",
    PARTIALLY_OVERLAPPING_ENTITIES: "Partially Overlapping Entities",
    POLYGONS_WITH_SHARP_ANGLES: "Polygons with Sharp Angle",
    SELF_INTERSECTING_POLYGONS: "Self Intersecting Polygons",
    AREA_FILTER: "Polygon Area",
    SIDE_LENGTH_FILTER: "Polygon Side Length"
};

export const ENTITY_SELF_FILTERS = [
    ENTITY_EDITOR_FILTERS.SEARCH,
    ENTITY_EDITOR_FILTERS.POLYGONS_WITH_SHARP_ANGLES,
    ENTITY_EDITOR_FILTERS.SELF_INTERSECTING_POLYGONS,
    ENTITY_EDITOR_FILTERS.AREA_FILTER,
    ENTITY_EDITOR_FILTERS.SIDE_LENGTH_FILTER
];

export const FILTERED_ID_TYPES = {
    NODE: "Node",
    ENTITY: "Entity",
};

export const ENTITY_SEARCH_OPTIONS = [
    FILTERED_ID_TYPES.ENTITY,
];

export const NODE_SEARCH_OPTIONS = [
    FILTERED_ID_TYPES.ENTITY,
    FILTERED_ID_TYPES.NODE,
];

export const SMART_FILTER_OPTIONS = {
    [FILTERED_ID_TYPES.NODE]: Object.values(NODE_EDITOR_FILTERS),
    [FILTERED_ID_TYPES.ENTITY]: Object.values(ENTITY_EDITOR_FILTERS),
};

export const MAP_EDITOR_TOOLS = {
    // Node Tools
    Create: "create",
    AutoLink: "autoLink",
    Delete: "Delete",
    Delete_Reconnect: "Delete & Reconnect",
    Link: "Link",
    Edit_Node: "Edit Node",
    Edit_Node_Link: "Edit Node Link",
    Assign_To_Entity: "Assign To Entity",
    Manage_Entity_Nodes: "Manage Entity Nodes",

    // Entity Tools
    Polygon: "Polygon",
    Point: "Point",
    Polyline: "Polyline",
    DeleteEntities: "Delete",
    EditEntities: "Edit Entity",
    EditEntitiesPOI: "Edit Entity POI",
    Split: "Split Entity",
    Merge: "Merge Entities",
    FLoorPlan: "FloorPlan",

    // Transition
    Create_Transition: "Create Transition",
    Edit_Transition: "Edit Transition"
};


export const SCALE_AND_ROTATE_CONDITION = (e) => platformModifierKeyOnly(e);

export const TRANSLATE_CONDITION = (e) => shiftKeyOnly(e);

export const DELETE_CONDITION = (e) => altKeyOnly(e) && click(e);

export const MODIFY_CONDITION = (e) => (!TRANSLATE_CONDITION(e) && !SCALE_AND_ROTATE_CONDITION(e));

export const NON_REVERSIBLE_DIRECTION = {
    MAIN_TO_GEOREFERENCE: "right",
    GEOREFERENCE_TO_MAIN: "left",
};

export const NODE_DISTANCE_THRESHOLD_MM = 100;

export const MIN_FEATURES_TO_SELECT_FOR_GEO_REFERENCE = 3;

export const GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES = {
    floorPlanImage: "floorPlanImage",
    geoReference: "geoReference",
    geoReferenceEdit: "geoReferenceEdit"
};


export const GEO_REF_MOUSE_INTERACTIONS = {
    FLOOR_PLAN_ADD_POINT_INTERACTION: "FLOOR_PLAN_ADD_POINT_INTERACTION",
    FLOOR_PLAN_POINT_MODIFY_INTERACTION: "FLOOR_PLAN_POINT_MODIFY_INTERACTION",
    FLOOR_PLAN_GEO_REF_POINT_GROUP_MODIFY_INTERACTION: "FLOOR_PLAN_GEO_REF_POINT_GROUP_MODIFY_INTERACTION",
};



export const GEO_REFERENCING_FLOOR_PLAN_TABS_INFO = {
    [GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES.floorPlanImage]: {
        name: "Add Floor Plan",
        value: GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES.floorPlanImage,

    },
    [GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES.geoReference]: {
        name: "Georeference",
        value: GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES.geoReference
    },
    [GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES.geoReferenceEdit]: {
        name: "Edit Georeferencing",
        value: GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES.geoReferenceEdit
    }
};

export const FLOOR_PLAN_IMAGE_MAP = {
    defaultStateData: {
        center: [0, 0],
        zoom: 8.8,
        imageOpacity: 1,
        imageRotation: 0,
        imageScale: [1, 1]
    },
    imageLayerName: FLOOR_PLAN_LAYERS_IDS.FLOOR_PLAN_IMAGE_MAP_IMAGE_LAYER,
    imageLayerId: FLOOR_PLAN_LAYERS_IDS.FLOOR_PLAN_IMAGE_MAP_IMAGE_LAYER,
    imageSrcName: "imageSrc",
    imageSrcId: "imageSrcId",
    vectorLayerName: FLOOR_PLAN_LAYERS_IDS.FLOOR_PLAN_IMAGE_MAP_VECTOR_LAYER,
    vectorLayerId: FLOOR_PLAN_LAYERS_IDS.FLOOR_PLAN_IMAGE_MAP_VECTOR_LAYER,
};



export const FLOOR_PLAN_ALERT_MESSAGES = {
    MISSING_FLOOR_PLAN_IMAGE: "Complete Adding FloorPlan Image",
    MISSING_GEO_REFERENCES: "Complete GeoReferencing",
};
