//@ts-check
const { MapConstants } = require("./utils/map.constants");
const TextController = require("./mapFunctions/text");
const PolygonController = require("./mapFunctions/polygons");
const DrawController = require("./mapFunctions/draw");
const ImageController = require("./mapFunctions/images");
const { deepValue } = require("mapsted.utils/objects");
class EntityMapController
{
    constructor({ features, accessor, filerUrl, theme })
    {
        this.features = features;
        this.accessor = accessor;
        this.filerUrl = filerUrl;
        this.theme = theme;

        this.polygonController = new PolygonController({ accessor });
        this.textController = new TextController({ accessor });
        this.imageController = new ImageController({ accessor, filerUrl, theme });
        this.drawController = new DrawController();
    }

    initialize(mapData)
    {
        if (!mapData)
        {
            return {};
        }

        const { entities, style } = mapData;

        let entityLayers = this.polygonController.createLayersFromStyle(style);

        if (this.features.text)
        {
            entityLayers[MapConstants.TEXT_LAYER] = this.textController.createLayer();
        }

        let imageLayers = {};

        let boundaryPolygonInfo = this.polygonController.findBoundaryPolygonInformation(Object.values(entities));

        Object.values(entities).forEach((entity) =>
        {
            this.addEntity(entity, style, entityLayers, imageLayers);
        });

        return Object.assign({ entityLayers, imageLayers }, boundaryPolygonInfo);
    }

    addEntity(entity, style, entityLayers, imageLayers)
    {
        const access = new this.accessor(entity);

        const feature = this.polygonController.createEntityFeature(entity, style);


        const styleObject = access.getStyleObject(style);

        // Add feature to the correct layer
        if (styleObject && styleObject.layerIdx !== undefined)
        {
            entityLayers[styleObject.layerIdx].getSource().addFeature(feature);
        }
        else
        {
            entityLayers[-1].getSource().addFeature(feature);
        }
        entity.feature = feature;

        if (this.features.text)
        {
            const textStyleObject = access.getTextStyleObject(style);
            const { textFeature } = this.textController.createEntityTextFeature(entity, style, feature);
            if (textStyleObject)
            {
                entityLayers[MapConstants.TEXT_LAYER].getSource().getSource().addFeature(textFeature);
            }
            entity.textFeature = textFeature;
        }

        if (this.features.images)
        {
            // create image layer;
            const imgLayer = this.imageController.createEntityImageLayer(entity, feature);

            if (imgLayer)
            {
                imageLayers[access.getId()] = imgLayer;
            }
        }
    }

    removeEntity(entities, entityId, entityLayers, imageLayers, style)
    {
        let _entities = { ...entities };
        let _entityLayers = { ...entityLayers };

        const access = new this.accessor(entities[entityId]);
        const previousEntity = _entities[access.getId()];
        const styleObject = access.getStyleObject(style);

        if (styleObject && styleObject.layerIdx)
        {
            this.polygonController.removeFeatureFromLayer(_entityLayers[styleObject.layerIdx], previousEntity.feature);
        }
        else
        {
            _entityLayers[-1].getSource().addFeature(previousEntity.feature);
        }

        const textStyleObject = access.getTextStyleObject(style);
        if (textStyleObject)
        {
            this.polygonController.removeFeatureFromLayer(_entityLayers[MapConstants.TEXT_LAYER].getSource(), previousEntity.textFeature);
        }

        delete _entities[access.getId()].textFeature;
        delete _entities[access.getId()].feature;

        const _imageLayers = this.imageController.removeEntityImage(access.getId(), imageLayers);

        return {
            entities: _entities,
            entityLayers: _entityLayers,
            imageLayers: _imageLayers,
        };
    }

    createImageLayers(entities)
    {
        let imageLayers = {};

        entities.forEach((entity) =>
        {
            const access = new this.accessor(entity);
            if (this.features.images && entity.feature)
            {
                const imgLayer = this.imageController.createEntityImageLayer(entity, entity.feature);

                if (imgLayer)
                {
                    imageLayers[access.getId()] = imgLayer;
                }
            }
        });

        return imageLayers;
    }

    setTheme(theme)
    {
        this.theme = theme;
        this.imageController = new ImageController({ accessor: this.accessor, filerUrl: this.filerUrl, theme });
    }
}
module.exports = EntityMapController;
