import React, { useEffect, useState, Fragment, useRef, useContext } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Popup, Button, Header } from "semantic-ui-react";
import { UploadImageModal } from "mapsted-components";
import "./LogoTypePopup.css";
import "./UploadModalExternal.css";
import { filerUrl } from "../../_utils/utils";
import { acceptableLogoFileFormats } from "../../_constants/config";
import {
    ASPECT_RATIOS,
    DEFAULT_STORE_LOGO_UPLOAD,
} from "../../_constants/constants";
import { UTIL_QUERIES } from "../../_utils/queries";
import { Gms } from "../branding/Gms/Gms";
import BrandingContext from "../../store/BrandingContext";
import { DEFAULT_LANGUAGE_CODE } from "mapsted.maps/utils/map.constants";


const DYNAMIC_LOGO_PROPS = {
    iconImage: {
        heading: <Trans i18nKey="LogoTypePopup.Add_Logo" />,
        aspectRatio: ASPECT_RATIOS.ICON_IMAGE,
        description: (
            <Trans i18nKey="LogoTypePopup.Add_or_update_your_store_logo__Your_logo" />
        ),
    },
    darkIcon: {
        heading: <Trans i18nKey="LogoTypePopup.Add_Logo_for_Dark_Mode" />,
        aspectRatio: ASPECT_RATIOS.LOGO,
        description: (
            <Trans i18nKey="LogoTypePopup.Add_or_update_your_store_logo_to_make_su" />
        ),
    },
    lightIcon: {
        heading: <Trans i18nKey="LogoTypePopup.Add_Logo_for_Light_Mode" />,
        aspectRatio: ASPECT_RATIOS.LOGO,
        description: (
            <Trans i18nKey="LogoTypePopup.Add_or_update_your_store_logo_to_make_su" />
        ),
    },
};

export const LogoTypePopup = ({
    images,
    handleIconUpdate,
    position = "right center",
    ...rest
}) => 
{
    const { data: config } = useQuery(UTIL_QUERIES.CONFIG());
    const [selectedImageType, setSelectedImageType] = useState(null);
    const brandingContext = useContext(BrandingContext);
    const trans = useTranslation().t;
    const gmsRef = useRef(null);

    // always reset open to undefined, just want it to be false temporarily so that is closes when the other portals open
    const [open, setOpen] = useState(undefined);
    useEffect(() => 
    {
        if (open !== undefined) 
        {
            setOpen(undefined);
        }
    }, [open]);

    const text = {
        uploadTab: {
            title: "Upload",
            dragNDrop: "Drag and Drop files here",
            or: "or",
            browse: "Browse",
            filetypeError:
        "This image  format is not supported. Please upload your logo in JPEG, JPG or PNG format.",
            sizeError: "File exceeds maximum allowed size for upload.",
        },
        webTab: {
            title: "Web Address (URL)",
            instruction: "Paste your image URL in the text field below.",
            pasteHere: "Paste link here...",
            sizeError: "File exceeds maximum allowed size for upload.",
            filetypeError:
        "This image  format is not supported. Please upload your logo in JPEG, JPG or PNG format.",
        },
    };


    return (
        <>
            <Popup
                hoverable
                open={open}
                className="logoTypePopup"
                position={position}
                {...rest}
                content={
                    <div className="logoTypeCover">
                        {Object.entries(images).map(([type, data]) => (
                            <Fragment key={type}>
                                {data.header && <Header content={data.header} />}
                                <LogoTypeMode
                                    className={data.value ? "filledPlaceholder" : ""}
                                    key={type}
                                    img={
                                        data.value? filerUrl(data.value): DEFAULT_STORE_LOGO_UPLOAD
                                    }
                                    content={<div className="contentLogoType">{data.name}</div>}
                                    actionButton={
                                        <div className="btn-container">
                                            <Button
                                                className="buttonIcon"
                                                content={
                                                    <img src={"/img/icon-upload-logo.svg"} alt="" />
                                                }
                                            />
                                            {data.value && (
                                                <Button
                                                    onClick={(e) => 
                                                    {
                                                        e.stopPropagation();
                                                        handleIconUpdate({
                                                            [type]: {
                                                                [DEFAULT_LANGUAGE_CODE]: ""
                                                            }
                                                        });
                                                    }}
                                                    className="buttonIcon"
                                                    content={
                                                        <img src={"/img/icon-unlink-img.svg"} alt="" />
                                                    }
                                                />
                                            )}
                                        </div>
                                    }
                                    onClick={() => 
                                    {
                                        gmsRef.current.linkIconImage({ addedImageFilerIds: [data.value] });
                                        setSelectedImageType(type);
                                    }}
                                />
                            </Fragment>
                        ))}
                    </div>
                }
                onClick={() => setOpen(false)}
            ></Popup>
            <Gms
                ref={gmsRef}
                onAddImage={(filerId) => handleIconUpdate({
                    [selectedImageType]: {
                        [DEFAULT_LANGUAGE_CODE]: filerId
                    }
                })}
            ></Gms>
        </>
    );
};

const LogoTypeMode = ({
    img,
    content,
    actionButton,
    className = "",
    ...rest
}) => (
    <div className={`logoTypeMode ${className}`} {...rest}>
        <div className="logoTypePlaceholder">
            <img src={img} alt="" />
        </div>
        <div className="logoTypeContainer">
            {content}
            {actionButton}
        </div>
    </div>
);
