import React from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { Button, Grid, Segment } from "semantic-ui-react";
import { deepUpdateValue } from "mapsted.utils/objects";
import { HeadingProperty } from "../../elements/HeadingProperty";
import { AddPropertyLayout } from "../../addProperty/AddPropertyLayout";
import { InfoHeadingPopup } from "../../popups/InfoHeadingPopup";
import { InputFormGroup } from "../../elements/InputFormGroup";
import AddPropertyContext from "../../../store/AddPropertyContext";
import { buildingSchema, userManualAddressForBuildingSchema } from "../../../_constants/validationSchemas";
import { getSearchParams, handleNormalizeUrl, convertAddressComponentsToUserAddress, convertManualAddressToAddressComponents, convertUserAddressToFullAddress, isAddressComponentValid, debounce } from "../../../_utils/utils";
import GoogleLookup from "../../elements/googleLookup/GoogleLookup";
import { BuildingDetailBox } from "../../addProperty/BuildingDetailBox";
import MarkerMap from "../../addProperty/MarkerMap";
import { InitialDetailBox } from "../../addProperty/InitialDetailBox";
import serverAPI from "../../../_api/server.api";
import { BuildingEditBox } from "../../addProperty/BuildingEditBox";
import { BuildingManualEditBox } from "../../addProperty/BuildingManualEditBox";
import ChangeAddressPortal from "../../popups/ChangeAddressPortal";
import { DEFAULT_LANGUAGE_CODE, SINGLE_LANG_INPUT_CODE, WARNING_MESSAGES } from "../../../_constants/constants";
import { ErrorModal } from "../../popups/ErrorModal";
import { withTranslation } from "react-i18next";

class AddBuilding extends React.Component
{
    static contextType = AddPropertyContext;

    state = {
        building: {
            longName: { [DEFAULT_LANGUAGE_CODE]: "" },
            fullAddress: "",
            type: 0,
            website: { [DEFAULT_LANGUAGE_CODE]: "" },
            phoneNumbers: "",
            centroid: {}
        },
        userAddress: {
            streetNumber: "",
            unitNumber: "",
            street: "",
            city: "",
            province: "",
            postalCode: "",
            country: ""
        },
        validationErrors: {},
        searchCompleted: false,
        edit: false,
        editManually: false,
        lookoutAddress: "",
        googleLookupError: false,
        property: {},
        isMapReset: true,
        undoAddress: undefined,
        showAddressChangeConfirmation: false,
        undoCentroid: undefined,
    }


    /**
     * On mount load property and building accordingly to the conditions below.
     */
    componentDidMount ()
    {
        const { propertyId, buildingId } = getSearchParams(["propertyId", "buildingId",]);

        const isPropertyIdMatch = (this.context.state.propertyId === propertyId);
        const isBuildingIdMatch = (this.context.state.buildingId === buildingId);

        // If PropertyId and BuildingId are passed through url params.
        if (!!propertyId && !!buildingId)
        {
            //If property and building IDs are same as the provider from provider. This handles on "go back from floor page" case. Or update same building.
            if (isBuildingIdMatch && isPropertyIdMatch)
            {
                this.handleBuildingInit(this.context.state.property, this.context.state.building);
            }
            // Else if propertyId && buildingId were passed through but not the same as the the IDs in provider, load fresh objects.
            else
            {
                this.context.getProperty({ propertyId, buildingId, isBuildingEdit: !!buildingId }, ({ property, building }) =>
                {
                    this.handleBuildingInit(property, building);
                });
            }
        }
        // Else if propertyId is passed through but not building, handles new buildingCase.
        else if (!!propertyId && !buildingId)
        {
            // If property matches load from state.
            if (isPropertyIdMatch)
            {
                // Need to reset floors and building in provider.
                this.context.handleUpdateState({ building: {}, floors: [] });

                this.handleBuildingInit(this.context.state.property);
            }
            // Else load fresh objects.
            else
            {
                this.context.getProperty({ propertyId, buildingId, isBuildingEdit: !!buildingId }, ({ property, building }) =>
                {
                    this.handleBuildingInit(property, building);
                });
            }
        }
        // If propertyId is not passed but property is loaded. Handles new property -> new building case.
        else if (!propertyId && this.context.state.propertyLoaded)
        {
            this.handleBuildingInit(this.context.state.property);
        }
        // Else if user manualy routes with no propertyId state should be reset.
        else
        {
            this.handleGoBack();
        }
    }

    /**
     * Updates building state in provider and routes back to AddProperty component.
     */
    handleGoBack = () =>
    {
        const { building } = this.state;
        this.context.handleUpdateState({ building });

        const { propertyId } = getSearchParams(["propertyId"]);

        // Note: we can't use history.location.search here because when the search is updated by the provider with new propertyId it won't be recognized.
        this.props.history.push(`./property?propertyId=${propertyId}`);

    }

    handleGoToDashboard = () =>
    {
        this.props.history.push("/dashboard");
    }

    handleGoFoward = () =>
    {
        let link = `./floorPlans?propertyId=${this.context.state.propertyId}`;
        if (this.context.state.buildingId)
        {
            link += `&buildingId=${this.context.state.buildingId}`;
        }

        this.props.history.push(link);
    }


    //Handles local state initialization.
    handleBuildingInit = (property, building) =>
    {
        console.log("property onBuildingInit", property);
        let searchCompleted = false;
        let lookoutAddress = "";

        if (building)
        {
            lookoutAddress = building.longName?.[SINGLE_LANG_INPUT_CODE];
            searchCompleted = true;
        }
        else
        {
            lookoutAddress = property.name?.[SINGLE_LANG_INPUT_CODE];

            building = {};
            building.type = property.type;
            building.longName = property.name;
            building.centroid = property.centroid;
            building.website = property.website;
            building.fullAddress = property.fullAddress;
            building.phoneNumbers = property.phoneNumbers;
            building.addressComponents = property.addressComponents;
            building.timeZone = property.timeZone;
        }

        let userAddress = convertAddressComponentsToUserAddress(building.addressComponents);

        this.setState({ property, building, userAddress, lookoutAddress, searchCompleted });
    }

    handleBuildingChange = (e, { name, value }) =>
    {
        const building = { ...this.state.building };
        const validationErrors = { ...this.state.validationErrors };

        deepUpdateValue(building, name, value);

        if (name === "phoneNumbers")
        {
            name = "phoneCheck";
        }

        delete validationErrors[name];

        this.setState({ building, validationErrors });
    }

    handleAddressChange = (e, { name, value }) =>
    {
        const userAddress = { ...this.state.userAddress };
        const validationErrors = { ...this.state.validationErrors };

        userAddress[name] = value;

        delete validationErrors[name];

        this.setState({ userAddress, validationErrors });
    }

    handleOnGoogleLookup = ({ address, place, coordinates, timeZone }) =>
    {
        const building = { ...this.state.building };
        const validationErrors = { ...this.state.validationErrors };
        const userAddress = { ...this.state.userAddress };

        const { userAddress: placeUserAddress, isValid: isValidAddress } = isAddressComponentValid(place.address_components || []);

        // Merge user address that came from property info with the search result
        Object.keys(placeUserAddress).forEach((key) => userAddress[key] = placeUserAddress[key] || userAddress[key]);

        building.centroid.coordinates = coordinates;
        building.centroid.type = "Point";

        building.placeid = place.placeid || "";
        building.longName = { [DEFAULT_LANGUAGE_CODE]: place.name || "" };
        building.fullAddress = place.formatted_address || "";
        building.addressComponents = place.address_components || [];
        building.website = { [DEFAULT_LANGUAGE_CODE]: place.website || "" };
        building.phoneNumbers = place.international_phone_number || "";
        building.timeZone = timeZone;

        delete validationErrors[`longName.${SINGLE_LANG_INPUT_CODE}`];
        delete validationErrors.fullAddress;
        delete validationErrors.phoneCheck;
        delete validationErrors[`website.${SINGLE_LANG_INPUT_CODE}`];

        this.setState({
            isMapReset: true,
            building,
            validationErrors,
            lookoutAddress: address,
            userAddress,
            googleLookupError: false,
            searchCompleted: true,
            edit: false,
            editManually: !isValidAddress
        });
    }

    handleCoordinatesChange = (coordinates) =>
    {
        let building = JSON.parse(JSON.stringify(this.state.building));

        let state = {};

        if (!this.state.undoCentroid)
        {
            state.undoCentroid = building.centroid;
        }

        building.centroid = {
            type: "Point",
            coordinates
        };

        state.building = building;

        this.handleCheckAddressByCoordinates(coordinates);



        this.setState(state);
    }

    handleCheckAddressByCoordinates = debounce(async (coordinates) =>
    {
        const latlng = {
            lat: coordinates[1],
            lng: coordinates[0]
        };

        const pinAddress = await serverAPI.getGoogleReverseGeocodingAddress(latlng);

        let building = JSON.parse(JSON.stringify(this.state.building));
        const userAddress = { ...this.state.userAddress };
        let undoAddress = this.state.undoAddress;

        if (!undoAddress)
        {
            undoAddress = {
                placeid: building.placeid,
                fullAddress: building.fullAddress,
                addressComponents: building.addressComponents,
                userAddress: Object.assign({}, userAddress),
            };

        }

        const { userAddress: newUserAddress } = isAddressComponentValid(pinAddress.address_components || []);

        building.placeid = pinAddress.placeid || "";
        building.fullAddress = pinAddress.formatted_address || "";
        building.addressComponents = pinAddress.address_components || [];

        this.setState({
            building,
            validationErrors: {},
            userAddress: newUserAddress,
            undoAddress
        });
    }, 1000);

    handleUndoPinAddress = () =>
    {
        const { undoAddress } = this.state;
        let building = JSON.parse(JSON.stringify(this.state.building));

        building.placeid = undoAddress.placeid || "";
        building.fullAddress = undoAddress.fullAddress || "";
        building.addressComponents = undoAddress.addressComponents || [];
        building.centroid = this.state.undoCentroid || {};

        const userAddress = Object.assign({}, undoAddress.userAddress);

        this.setState({
            building,
            userAddress,
            validationErrors: {},
            undoAddress: undefined,
            showAddressChangeConfirmation: false,
            undoCentroid: undefined,
        });
    }

    handleConfirmPinAddress = () =>
    {
        this.setState({ undoAddress: undefined, showAddressChangeConfirmation: false, undoCentroid: undefined, });
    }

    handleOnSave = async (waitUntilSaved) =>
    {
        const trans = this.props.t;
        const { editManually, undoAddress, property } = this.state;
        const building = { ...this.state.building };
        const userAddress = { ...this.state.userAddress };

        if (undoAddress)
        {
            this.setState({ showAddressChangeConfirmation: true });
            return false;
        }

        try
        {
            building.phoneCheck = await serverAPI.validatePhoneNumber(building.phoneNumbers, property.country);

            if (editManually)
            {
                building.addressComponents = await convertManualAddressToAddressComponents(userAddress);
                building.fullAddress = convertUserAddressToFullAddress(userAddress);

                building.timeZone = undefined;

                userAddress.streetNumber = userAddress.streetNumber || 0;

                await userManualAddressForBuildingSchema.validate(userAddress, { abortEarly: false });
            }

            await buildingSchema.validate(building, { abortEarly: false });

            building.website[SINGLE_LANG_INPUT_CODE] = handleNormalizeUrl(building.website[SINGLE_LANG_INPUT_CODE]);

            //If draft, wait for api call then route back to dashboard
            if (waitUntilSaved)
            {
                this.context.saveBuilding(building, waitUntilSaved, (success) =>
                {
                    if (success)
                    {
                        this.props.history.push("/dashboard");
                    }
                    else //uf the creation fails
                    {
                        window.alert(trans("AddBuilding.Somthing_went_wrong__Please_try_again_"));
                    }
                });
            }
            //If not draft, make the api call while routing to floorplans.
            else
            {
                this.context.saveBuilding(building, waitUntilSaved, (success) =>
                {
                    // If the creation fails
                    if (!success)
                    {
                        window.alert(trans("AddBuilding.Somthing_went_wrong__Please_try_again_"));
                        this.props.history.push("/dashboard/building" + this.props.history.location.search);
                    }
                });

                this.handleGoFoward();
            }
        }
        catch (err)
        {
            console.log("err", err);
            if (err && err.name === "ValidationError")
            {
                const validationErrors = {};

                err.inner.forEach((currentError) =>
                {
                    validationErrors[currentError.path] = currentError.message;
                });

                //updates website on UI if website doesn't have an error
                !validationErrors[`website.${SINGLE_LANG_INPUT_CODE}`] && this.setState({ building });

                this.setState({ validationErrors, edit: true });
            }
        }

        return false;
    }

    handleOnGoogleLookupError = () => this.setState({ googleLookupError: true, searchCompleted: false, edit: false, editManually: false })

    handleShouldMapCenterReset = () =>
    {
        const { isMapReset } = this.state;

        if (isMapReset)
        {
            this.setState({ isMapReset: false });
            return true;
        }
        else
        {
            return false;
        }
    }

    handleManualEditActivation = () =>
    {
        this.setState({ googleLookupError: false, edit: false, editManually: true });
    }

    handleUsePropertyInfo = () =>
    {
        const { buildingId, propertyId } = getSearchParams(["buildingId", "propertyId"]);

        // If propertyId, property was passed through url param, load property fresh. Else use property in state.
        if (propertyId)
        {
            this.context.getProperty({ propertyId, buildingId, isBuildingEdit: !!buildingId }, ({ property, building }) =>
            {
                this.handleBuildingInit(property, building);
            });
        }
        // Else if property is loaded and same propertyId load property from provider. This handles on "go back from floor page" case.
        else if (this.context.state.propertyLoaded && this.context.state.propertyId === propertyId)
        {
            this.handleBuildingInit(this.context.state.property, undefined);
        }
        // Else if no propertyId but property is loaded fetch property from provider. Handles new property -> new building case.
        else if (this.context.state.propertyLoaded)
        {
            this.handleBuildingInit(this.context.state.property, undefined);
        }
    }

    handleSetEdit = () => this.setState({ edit: true });

    render ()
    {
        const { isBuildingEdit, propertyId, propertyLoaded, buildingTypes } = this.context.state;
        const { building, googleLookupError, edit, editManually, lookoutAddress, validationErrors, searchCompleted, userAddress, undoAddress, showAddressChangeConfirmation } = this.state;
        const trans = this.props.t;

        const enableSave = !!propertyId && !!building.longName;

        return (
            <Grid columns="equal" className="property-cover2">
                <Grid.Column width="5">
                    <Segment basic className="property-box">

                        <span onClick={this.handleGoToDashboard} className="close">
                            <Link to="#" />
                        </span>

                        <HeadingProperty onClick={!isBuildingEdit && propertyId && propertyLoaded && this.handleGoBack}
                            heading={isBuildingEdit ? trans("AddBuilding.Edit_Building") : trans("AddBuilding.Add_Building")}
                            info={
                                <InfoHeadingPopup header={isBuildingEdit ? trans("AddBuilding.Edit_Building") : trans("AddBuilding.Add_Building")} showLearnMore={false}>
                                    {trans("AddBuilding.Fill_in_the_required_fields_below_to_add")}
                                </InfoHeadingPopup>
                            } />

                        <AddPropertyLayout>
                            <p>
                                {trans("AddBuilding.Enter_your_building_information_below_to")}
                                {trans("AddBuilding.You_can_make_changes_at_any_time_by_clic")}
                            </p>
                            <p className="p">{trans("AddBuilding.Once_you_are_finished,_click_the_Next”_b")}</p>
                            <div className="form-property">

                                {
                                    (!searchCompleted && !(edit || editManually)) && (
                                        <GoogleLookup label={trans("AddBuilding.Search_Building")} placeholder={trans("AddBuilding.e_g__BEO_Shopping_Mall")} error={validationErrors[`longName.${SINGLE_LANG_INPUT_CODE}`]}
                                            value={lookoutAddress} extraLabel={trans("AddBuilding.Search_the_building_name_by_using_the_fi")}
                                            onChange={this.handleOnGoogleLookup}
                                            onError={this.handleOnGoogleLookupError}
                                        />
                                    )
                                }

                                {
                                    (!edit && !editManually && !googleLookupError) && (
                                        <>
                                            <BuildingDetailBox edit={edit} setEdit={this.handleSetEdit}
                                                building={building} buildingTypes={buildingTypes}
                                            />
                                            <InputFormGroup label={trans("AddBuilding.Building_Type")} list={buildingTypes} error={validationErrors["type"]}
                                                listValue={building.type} onChange={this.handleBuildingChange} name="type" scrolling search
                                                extraLabel={trans("AddBuilding.Select_the_building_type_from_the_dropdo")} />
                                        </>
                                    )
                                }

                                {
                                    (googleLookupError) && (
                                        <InitialDetailBox error>
                                            <p>{trans("AddBuilding.No_results_found")}</p>
                                            <Button color="orange" content={trans("AddBuilding.Add_Building_Manually")} onClick={this.handleManualEditActivation} />
                                            {/* <Button color="orange" content="Use Property Info" onClick={this.handleUsePropertyInfo} /> */}
                                        </InitialDetailBox>
                                    )
                                }

                                {
                                    (edit) && (
                                        <BuildingEditBox building={building} buildingTypes={buildingTypes} validationErrors={validationErrors}
                                            onChange={this.handleBuildingChange} />
                                    )
                                }
                                {
                                    (editManually) && (
                                        <BuildingManualEditBox building={building} userAddress={userAddress} buildingTypes={buildingTypes}
                                            validationErrors={validationErrors} onChange={this.handleBuildingChange} onChangeUserAddress={this.handleAddressChange} />
                                    )
                                }
                            </div>
                            <div className="property-actions">
                                <Button size="small" color="grey" content={trans("AddBuilding.Save_And_Exit")} disabled={!enableSave}
                                    onClick={() => this.handleOnSave(isBuildingEdit ? false : true)} />
                                <Button onClick={() => this.handleOnSave(false)} disabled={!enableSave}
                                    color="orange" content={trans("AddBuilding.Next")} />
                            </div>
                        </AddPropertyLayout>
                    </Segment>
                </Grid.Column>
                <Grid.Column>
                    <MarkerMap markerCoordinates={building.centroid.coordinates}
                        onMarkerCoordinatesChange={this.handleCoordinatesChange}
                        editable={true}
                        onShouldMapCenterReset={this.handleShouldMapCenterReset} />

                    <ChangeAddressPortal open={!!undoAddress} onUndo={this.handleUndoPinAddress} onConfirm={this.handleConfirmPinAddress} />

                    <ErrorModal open={showAddressChangeConfirmation} error={{ confirmAddressChanges: WARNING_MESSAGES.confirmAddressChanges }}
                        backButtonContent={trans("AddBuilding.Undo")} continueButtonContent={trans("AddBuilding.Confirm")}
                        onBack={this.handleUndoPinAddress} onContinue={this.handleConfirmPinAddress} />
                </Grid.Column>
            </Grid>
        );
    }
}

AddBuilding.propTypes = {
    history: PropTypes.object
};

export default withTranslation()(withRouter(AddBuilding));
