import React, { FC, ReactNode } from "react"
import { Button as SemanticButton, ButtonProps } from "semantic-ui-react";
import { useMergedObjects } from "../hooks";

type IconPositions = "before" | "after";

interface ButtonIconProps extends ButtonProps {
    icon?: ReactNode;
    className?: string;
    iconPosition?: IconPositions;
    children?: React.ReactNode;
    content?: React.ReactNode;
    style?: React.CSSProperties;
}

const Button : FC<ButtonIconProps> = ({
    className = "",
    icon = undefined,
    iconPosition = "before",
    children = undefined,
    content = undefined,
    style,
    ...rest
}) => {
    const mergedProps = useMergedObjects({ basic: true }, rest);
    return (
        <SemanticButton className={`buttonIcon ${className}`} style={style} {...mergedProps}>
            {(iconPosition === "before") && icon}
            {children}
            {(iconPosition === "after") && icon}
            {content}
        </SemanticButton>
    );
};

export default Button;
