

import React from "react";
import "./ContentSection.css";

function ContentSection({ children, className = "" })
{
    return (
        <div className={`content-section ${className}`}>
            {children}
        </div>
    );
}

function ContentSectionHeader({ children })
{
    return (
        <div className="content-section-header">
            {children}
        </div>
    );
}

function ContentSectionMain({ children })
{
    return (
        <div className="content-section-main">
            {children}
        </div>
    );
}

ContentSection.Header = ContentSectionHeader;
ContentSection.Main = ContentSectionMain;

export default ContentSection;

