import React from "react";
import { DEFAULT_CATEGORY_ICON } from "../../../_constants/branding";
import { filerUrl } from "../../../_utils/utils";
import { StyledIcon } from "./StyledIcon";
import { useTranslation } from "react-i18next";


export const CategoryItemBox = ({
    onClick=() => 
    {}, iconImage = DEFAULT_CATEGORY_ICON.iconImage, backgroundColour, iconColour, children 
}) => 
{
    const trans = useTranslation().t;
    return (
        <div style={{ position: "relative", width: "fit-content" }}>
            <div className="uploadImgContainer" onClick={onClick}>
                <div className="uploadIconContainer">
                    <span className="ui image">
                        {(iconImage) && (
                            <StyledIcon imageSrc={filerUrl(iconImage)} backgroundColour={backgroundColour} iconColour={iconColour} />
                        )}
                    </span>
                </div>
                <p style={{ cursor: "pointer" }}>{trans("Browse")}</p>
            </div>
            {children}
        </div>
    );
};
