import React, { Component, useContext } from "react";
import { isEqual, cloneDeep } from "lodash";
import { Map, View, Feature } from "ol";
import { Polygon } from "ol/geom";
import { defaults as defaultControls, Attribution } from "ol/control";
import Overlay from "ol/Overlay";
import { Stroke, Style } from "ol/style";
import { MapButtons } from "./MapButtons";
import BrandingContext from "../../../store/BrandingContext";
import SelectEntityPopup from "../editRightSidebar/SelectEntityPopup";
import { RotatePopup } from "../rotate/RotatePopup";
import { RotateImagePopup } from "../rotate/RotateImagePopup";
import { deepValue } from "mapsted.utils/objects";
import { cartoLayer, createVectorLayer } from "mapsted.maps/mapFunctions/plotting";
import
{
    getFeatureTopCoordinate, getGeoJSONTopCoordinate, getSelectedEntity, doesEntityIntersectWithPreviousSelected,
} from "mapsted.maps/mapFunctions/features";
import { getSelectedEntityFromClick, getSelectedTextFromClick, changeMapCenterWithBoundaryPolygon, getSelectedEntityInfoFromClick } from "mapsted.maps/mapFunctions/interactions";
import { MouseDownInteraction, DrawInteraction, SnapInteraction, MouseDragInteraction } from "mapsted.maps/utils/interactionTemplates";
import { BOUNDING_BOX_STYLE, styleClassic } from "mapsted.maps/utils/defualtStyles";
import { BRANDING_MAP_CONSTANTS, MAP_TOOLS, MAP_POPUP_OFFSETS, SNAP_INTERACTION, DEFAULT_LANGUAGE_CODE } from "../../../_constants/constants"; // TODO MOVE TO MAPSTED.MAPS
import { DRAW_LAYER, MAX_MERGE_SIZE } from "../../../_constants/branding";
import { MergePopup } from "../merge/MergePopup";

import "ol/ol.css";
import { EntityType, ShapeTypes } from "mapsted.maps/utils/entityTypes";
import { rotationIsValid } from "../../../_utils/branding.utils";

class BrandingMap extends Component
{
    static contextType = BrandingContext;

    constructor(props)
    {
        super(props);

        this.state = {
            mapInteractions: [],
            selectedEntityText: undefined,
        };

        this.mapRef = React.createRef();
        this.popupRef = React.createRef();

        const attribution = new Attribution({ collapsible: false });

        const map = new Map({
            target: null,
            layers: [cartoLayer(props.tileLayer)],
            controls: defaultControls({
                attribution: false,
                zoom: false,
                rotate: false
            }).extend([attribution]),
            view: new View({
                center: [0, 0],
                zoom: BRANDING_MAP_CONSTANTS.DEFAULT_ZOOM,
                maxZoom: BRANDING_MAP_CONSTANTS.MAX_ZOOM,
                minZoom: BRANDING_MAP_CONSTANTS.MIN_ZOOM
            })
        });

        this.interactions = {
            [MAP_TOOLS.EDIT]: (source) => MouseDownInteraction({
                source,
                handleDownEvent: this.onMouseDownEvent,
            }),
            [MAP_TOOLS.ROTATE]: (source) => MouseDownInteraction({
                source,
                handleDownEvent: (e) => this.onMouseDownEvent(e, true),
            }),
            [MAP_TOOLS.MERGE]: (source) => MouseDownInteraction({
                source,
                handleDownEvent: this.onMouseDownEventMultiple,
            }),
            [MAP_TOOLS.SPLIT]: (source) => MouseDownInteraction({
                source,
                handleDownEvent: this.onMouseDownEvent,
            }),
            [MAP_TOOLS.MOVE_TEXT]: (source) => MouseDragInteraction({
                source,
                handleDownEvent: (e) => this.onMouseDownEvent(e, true),
                handleDragEvent: this.moveToolHelper,
                handleUpEvent: this.handleUpEvent,
            })
        };

        this.drawInteractions = {
            [MAP_TOOLS.SPLIT]: (source) => DrawInteraction({ source: source, type: ShapeTypes.LINE_STRING, maxPoints: 2, handleDrawEndEvent: this.onDrawEndEvent }),
            [SNAP_INTERACTION]: (source) => SnapInteraction({ source: source }),
        };

        this.entityLayers = undefined; // move to state?
        this.drawLayer = undefined;
        this.popupOverlay = undefined;
        this.boundingBoxLayer = undefined;

        this.olmap = map;
    }

    componentDidMount()
    {
        this.olmap.setTarget(this.mapRef.current);

        // create popup overlay
        this.popupOverlay = new Overlay({
            element: this.popupRef.current,
            autoPan: false,
            offset: [0, 0],
            // autoPanAnimation: {
            //     duration: BRANDING_MAP_CONSTANTS.ANIMATION_DURATION,
            //     margin: 5000
            // },
            positioning: "top-center",
        });

        // if building level rotation exists use that instead of property level orientation
        if (this.props.buildingMapSettings?.mapRotation)
        {
            this.changeMapRotation(this.props.buildingMapSettings.mapRotation);
        }
        else if (this.props.settingsConfig?.mapRotation)
        {
            this.changeMapRotation(this.props.settingsConfig.mapRotation);
        }
        this.olmap.addOverlay(this.popupOverlay);

        this.olmap.on("moveend", this.handleMapMoveEvent);
        this.drawEntityLayers(this.context.state.entityLayers, this.props.imageLayers);
        this.centerOnBoundary();
    }

    componentDidUpdate(prevProps)
    {
        const { selectedEntities, editUnsavedChanges } = this.context.state;
        if (this.mapShouldRedraw(prevProps))
        {
            this.drawEntityLayers(this.context.state.entityLayers, this.props.imageLayers);
        }

        const entityCount = Object.keys(this.props.entities).length;
        const prevEntityCount = Object.keys(prevProps.entities).length;

        // check for map change
        if (this.props.propertyId !== prevProps.propertyId || this.props.buildingId !== prevProps.buildingId)
        {
            // if building level rotation exists use that instead of property level orientation
            if (this.props.buildingMapSettings?.mapRotation)
            {
                this.changeMapRotation(this.props.buildingMapSettings.mapRotation);
            }
            else if (this.props.settingsConfig?.mapRotation) 
            {
                this.changeMapRotation(this.props.settingsConfig.mapRotation);
            }
            this.centerOnBoundary();
        }
        // entity count changed through split/merge
        else if (entityCount !== prevEntityCount)
        {
            const { selectedEntityFeature } = getSelectedEntity(selectedEntities);

            // Set map center to new entity
            if (selectedEntityFeature)
            {
                const popupCoord = getFeatureTopCoordinate(selectedEntityFeature);

                this.popupOverlay.setPosition(popupCoord);
                this.fitToPolygon(selectedEntityFeature.getGeometry());
            }
        }

        // check for map tools change
        if (this.props.selectedTool !== prevProps.selectedTool)
        {
            this.changeSelectedTool(this.props.selectedTool);
        }
        else if (this.props.selectedTool === MAP_TOOLS.EDIT && !editUnsavedChanges && this.state.mapInteractions.length === 0)
        {
            // Add interaction back when edit closes but tool is still selected
            const interaction = this.addInteractions(MAP_TOOLS.EDIT);
            this.setState({ mapInteractions: interaction });
        }
        else if ((this.props.selectedTool === MAP_TOOLS.EDIT && editUnsavedChanges && this.state.mapInteractions.length > 0))
        {
            this.removeInteractions();
            this.setState({ mapInteractions: [] });
        }

        // update basemap if tileLayer is changed
        if (prevProps.tileLayer.url !== this.props.tileLayer.url)
        {
            const tileLayerSource = this.olmap.getLayers()?.getArray()?.find((layer) => layer.get("isBaseMapLayer"))?.getSource();

            if (tileLayerSource)
            {
                tileLayerSource.setUrl(this.props.tileLayer.url);
            }
        }
    }

    mapShouldRedraw(prevProps)
    {
        const propsToCheck = ["propertyId", "buildingId", "floorId"];
        const basicChange = propsToCheck.some((propName) => prevProps[propName] !== this.props[propName]);

        if (basicChange)
        {
            return true;
        }

        const imageLayersChanged = !isEqual(prevProps.imageLayers, this.props.imageLayers);
        if (imageLayersChanged)
        {
            return true;
        }

        return false;
    }

    centerOnBoundary = () =>
    {
        const { boundaryPolygon, } = this.context.state;
        changeMapCenterWithBoundaryPolygon({ olMap: this.olmap, boundaryPolygon, padding: BRANDING_MAP_CONSTANTS.FIT_PADDING_MAP_DATA });
    }

    changeMapRotation = (mapRotation) => 
    {
        const rotationAngle = mapRotation[DEFAULT_LANGUAGE_CODE];
        if (rotationAngle || rotationAngle === 0) 
        {
            this.olmap.getView().setRotation(rotationAngle);
        }
    }

    drawEntityLayers = (entityLayers, imageLayers) =>
    {
        let newEntityLayers = { ...entityLayers };

        if (this.entityLayers)
        {
            this.removeEntityLayers(this.entityLayers);
        }

        Object.keys(newEntityLayers).forEach((layerId) =>
        {
            if (layerId !== BRANDING_MAP_CONSTANTS.HIDDEN_LAYER && layerId !== BRANDING_MAP_CONSTANTS.BOUNDARY_LAYER)
            {
                const layer = newEntityLayers[layerId];
                this.olmap.addLayer(layer);
            }
        });

        if (imageLayers)
        {
            Object.keys(imageLayers).forEach((imageLayerId) =>
            {
                const layer = imageLayers[imageLayerId];
                this.olmap.addLayer(layer);
                newEntityLayers[imageLayerId] = layer;
            });
        }

        this.entityLayers = newEntityLayers; //move entity layers to state?

        this.changeSelectedTool(this.props.selectedTool);
    }

    removeEntityLayers = (entityLayers) =>
    {
        Object.values(entityLayers).forEach((layer) =>
        {
            this.olmap.removeLayer(layer);
        });
    }

    // *** MAP BUTTON METHODS *** //

    handleZoomIn = () => this.changeMapZoom(1);

    handleZoomOut = () => this.changeMapZoom(-1);

    changeMapZoom = (zoomAmmount) =>
    {
        const zoom = this.olmap.getView().getZoom();
        const newZoom = zoom + zoomAmmount;

        this.setMapZoom(newZoom);
    }

    setMapZoom = (zoom) =>
    {
        if (
            zoom >= BRANDING_MAP_CONSTANTS.MIN_ZOOM
            && zoom <= BRANDING_MAP_CONSTANTS.MAX_ZOOM
        )
        {
            this.olmap.getView().animate({
                zoom,
                duration: BRANDING_MAP_CONSTANTS.ANIMATION_DURATION,
            });
        }

    }

    // *** INTERACTION/TOOL METHODS *** //

    changeSelectedTool = (toolName) =>
    {
        // Remove all interactions
        this.removeInteractions();

        if (toolName)
        {
            const mapInteractions = this.addInteractions(toolName);
            this.setState({ mapInteractions: mapInteractions });
            this.popupOverlay.setOffset(MAP_POPUP_OFFSETS[toolName] || [0, 0]);
        }
        else
        {
            this.setState({ mapInteractions: [] });
            this.popupOverlay.setOffset([0, 0]);
        }
    }

    addInteractions = (interactionName) =>
    {
        let interactions = [];

        const interactionMethod = this.interactions[interactionName];

        const interaction = interactionMethod();

        interactions.push(interaction);
        this.olmap.addInteraction(interaction);

        return interactions;
    }

    addDrawInteraction = (toolName) =>
    {
        const { entityLayers } = this.context.state;

        this.removeInteractions();

        // -- add draw layer and interaction -- //
        const drawLayer = createVectorLayer(DRAW_LAYER);
        const source = drawLayer.getSource();

        const interactionMethod = this.drawInteractions[toolName];
        const interaction = interactionMethod(source);

        this.olmap.addLayer(drawLayer);
        this.olmap.addInteraction(interaction);

        let interactions = [interaction];

        // add snap to map
        const snapMethod = this.drawInteractions[SNAP_INTERACTION];

        Object.keys(entityLayers).forEach((layerId) =>
        {
            if (layerId !== BRANDING_MAP_CONSTANTS.HIDDEN_LAYER)
            {
                const layer = entityLayers[layerId];
                const source = layer.getSource();
                const snapInteraction = snapMethod(source);

                interactions.push(snapInteraction);
                this.olmap.addInteraction(snapInteraction);
            }
        });

        this.setState({ mapInteractions: interactions });

        this.drawLayer = drawLayer;
    }

    removeDrawLayer = () => this.olmap.removeLayer(this.drawLayer);

    removeInteractions = () =>
    {
        const { mapInteractions } = this.state;

        mapInteractions.forEach((interaction) =>
        {
            this.olmap.removeInteraction(interaction);
        });

        this.removeDrawLayer();
    }

    onMouseDownEvent = (e, selectText) =>
    {
        const { selectedEntities } = this.context.state;
        const { imageLayers, entities } = this.props;

        const { selectedEntityId } = getSelectedEntity(selectedEntities);

        // if we are selected textFeature, search for text feature
        // else get entity feature
        let { entityId, coordinate, entityFeature, canName, imageCheck } = (selectText) ? getSelectedTextFromClick({ pointerEvent: e, olMap: this.olmap, checkForImage: true }) : getSelectedEntityInfoFromClick({ pointerEvent: e, olMap: this.olmap });

        const entity = entities[entityId] || {};
        const isPointOfIntrest = entity.entityType === EntityType.POINT_OF_INTEREST;

        let procceedWithClickEvent = entityId !== selectedEntityId && canName;

        if (procceedWithClickEvent)
        {
            switch (this.props.selectedTool)
            {
                case MAP_TOOLS.EDIT:
                    {
                        this.handleEditEntityChange({ entityId, coordinate, entityFeature });
                        break;
                    }
                case MAP_TOOLS.ROTATE:
                    {
                        if (imageCheck)
                        {
                            if (imageLayers[entityId])
                            {
                                this.handleRotateImageChange({ imageLayer: imageLayers[entityId], entityId, coordinate, entityFeature });
                            }
                        }
                        else
                        {
                            this.handleRotateEntityChange({ entityId, coordinate });
                        }
                        break;
                    }
                case MAP_TOOLS.SPLIT:
                    {
                        if (!isPointOfIntrest)
                        {
                            this.handleSplitEntityChange({ entityId, coordinate, entityFeature });
                        }
                        break;
                    }
                case MAP_TOOLS.MOVE_TEXT:
                    {
                        if (!isPointOfIntrest)
                        {
                            if (imageCheck)
                            {
                                if (imageLayers[entityId])
                                {
                                    let dragStart = this.handleMoveImageChange({ imageLayer: imageLayers[entityId], entityId, coordinate });
                                    return dragStart;
                                }
                            }
                            else
                            {
                                let dragStart = this.handleMoveTextEntityChange({ entityId, entityFeature, coordinate });
                                return dragStart;
                            }
                        }
                        break;
                    }
                default:
                    {
                        break;
                    }
            }
        }
    }

    onMouseDownEventMultiple = (e) =>
    {
        const { selectedEntities } = this.context.state;

        let { entityId, entityFeature, canName } = getSelectedEntityFromClick({ pointerEvent: e, olMap: this.olmap });

        let includesEntityId = (Object.keys(selectedEntities).includes(entityId));
        let shiftKey = deepValue(e, "pointerEvent.shiftKey", false);

        let procceedWithClickEventAdd = (!includesEntityId) && (!shiftKey) && (canName);
        let procceedWithClickEventRemove = (includesEntityId) && (shiftKey) && (canName);

        if (procceedWithClickEventAdd)
        {
            switch (this.props.selectedTool)
            {
                case MAP_TOOLS.MERGE:
                    {
                        if (Object.keys(selectedEntities).length === MAX_MERGE_SIZE)
                        {
                            procceedWithClickEventAdd = false;
                        }
                        else
                        {
                            procceedWithClickEventAdd = doesEntityIntersectWithPreviousSelected(selectedEntities, entityFeature);
                        }
                        break;
                    }
                default:
                    {
                        break;
                    }
            }
        }
    }

    onDrawEndEvent = (e) =>
    {
        this.removeInteractions();
        this.removeDrawLayer();

        this.context.splitSelectedEntity(e.feature);

        // add split interaction in case of fail
        const interaction = this.addInteractions(MAP_TOOLS.SPLIT);
        this.setState({ mapInteractions: interaction });
    }

    handleMoveTextEntityChange = async ({ entityId, entityFeature, coordinate }) =>
    {
        const { selectedEntityText } = this.state;

        if (entityId && deepValue(selectedEntityText, "entityId", undefined) !== entityId)
        {
            let selectedEntityText = {
                entityId,
                entityTextFeature: entityFeature,
                prevCursorCoordinate: coordinate,
                startCoordinate: coordinate,
            };

            this.setState({ selectedEntityText });

            return true;
        }

        return false;
    }

    handleMoveImageChange = ({ imageLayer, entityId, coordinate }) =>
    {
        let entity = this.props.entities[entityId];

        // get image box
        const imgExtent = imageLayer.getSource().getImageExtent();
        const imgBox = this.context.mapController.polygonController.getBoundingBoxPolygonFromExtent(imgExtent);

        const ptsInImgBox = this.context.mapController.polygonController.isPointsInPolygon([coordinate], imgBox);

        if (ptsInImgBox)
        {
            this.drawBorderOnImageLayer(imageLayer, entity.imageRotation?.[DEFAULT_LANGUAGE_CODE]);

            let selectedEntityImage = {
                entityId,
                imageLayer: imageLayer,
                prevCursorCoordinate: coordinate,
                startCoordinate: coordinate,
                imageRotation: entity.imageRotation?.[DEFAULT_LANGUAGE_CODE],
            };

            this.setState({ selectedEntityImage });

            return true;
        }

        return false;
    }

    handleRotateImageChange = async ({ imageLayer, entityId, coordinate, entityFeature }) =>
    {
        // get image box
        const imgExtent = imageLayer.getSource().getImageExtent();
        const imgBox = this.context.mapController.polygonController.getBoundingBoxPolygonFromExtent(imgExtent);

        const ptsInImgBox = this.context.mapController.polygonController.isPointsInPolygon([coordinate], imgBox);

        if (ptsInImgBox)
        {
            const entity = this.props.entities[entityId];
            const imageRotation = deepValue(entity, `imageRotation.${DEFAULT_LANGUAGE_CODE}`, 0);

            this.context.changeSelectedEntity(undefined);

            // set rotation popup
            this.popupOverlay.setPosition(coordinate);

            // change map center
            const geometry = entityFeature.getGeometry();
            await this.fitToPolygon(geometry);

            // set local image object for rotation function
            let source = imageLayer.getSource();
            let canvas = source.image_.image_;
            let ctx = canvas.getContext("2d");

            let orginalImageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

            let selectedEntityImage = {
                entityId,
                imageLayer,
                imageRotation,
                orginalImageData,
            };

            this.setState({ selectedEntityImage });

            // set provider selected entity
            await this.context.changeSelectedEntity(entityId, entityFeature);

            // change map center to selected entity
            await this.fitToPolygon(geometry);
        }
    }

    moveToolHelper = (e) =>
    {
        const { selectedEntityText, selectedEntityImage } = this.state;

        if (selectedEntityText)
        {
            this.handleMoveTextEvent(e);
        }
        else if (selectedEntityImage)
        {
            this.handleMoveImageEvent(e);
        }
    }

    handleMoveTextEvent = (e) =>
    {
        let selectedEntityText = { ...this.state.selectedEntityText };
        const { entityTextFeature, prevCursorCoordinate } = selectedEntityText;

        if (entityTextFeature)
        {
            // get new cursor coord
            let newCoordinate = e.coordinate;

            // translate the entity text feature
            let geometry = entityTextFeature.getGeometry();
            this.context.mapController.polygonController.translateGeometry(geometry, prevCursorCoordinate, newCoordinate);

            // set new prev coord
            selectedEntityText.prevCursorCoordinate = newCoordinate;

            this.setState({ selectedEntityText });
        }
    }

    handleMoveImageEvent = (e) =>
    {
        let selectedEntityImage = { ...this.state.selectedEntityImage };
        const { imageLayer, prevCursorCoordinate, imageRotation } = selectedEntityImage;

        if (imageLayer)
        {
            let newCoordinate = e.coordinate;

            // Function mutates imagelayer
            this.context.mapController.imageController.translateImageLayer(imageLayer, prevCursorCoordinate, newCoordinate);

            selectedEntityImage.prevCursorCoordinate = newCoordinate;

            this.drawBorderOnImageLayer(imageLayer, imageRotation);

            this.setState({ selectedEntityImage });

        }
    }

    handleUpEvent = () =>
    {
        const { selectedEntityText, selectedEntityImage } = this.state;

        if (selectedEntityText)
        {
            this.context.handleMoveEntityTextFeature(selectedEntityText);
            this.setState({ selectedEntityText: undefined, selectedEntityImage: undefined });
        }
        else if (selectedEntityImage)
        {
            // TODO CALL CONTEXT FUNCTION TO CHANGE NEW IMAGE LOCATION;

            this.context.handleMoveEntityImage(selectedEntityImage);

            this.removeBorderOnImageLayer();

            this.setState({ selectedEntityText: undefined, selectedEntityImage: undefined });
        }
    }

    handleEditEntityChange = async ({ entityId, coordinate, entityFeature }) =>
    {
        this.context.changeSelectedEntity(undefined);

        if (entityId)
        {
            await this.context.changeSelectedEntity(entityId, entityFeature);
            this.popupOverlay.setPosition(coordinate);

            this.fitToPolygon(entityFeature.getGeometry());

            // disable map
            if (!this.props.entities[entityId].entityLabel)
            {
                this.context.setEditUnsavedChanges(true);
            }
        }
    }

    async fitToPolygon(geometry, relativePadding = true)
    {
        let padding = undefined;
        if (relativePadding)
        {
            const [width, height] = this.olmap.getSize();
            // pad relative to screen size up to a maximum [top, right, bottom, left]
            padding = [height * 0.1, width * 0.1, height * 0.2, width * 0.1].map((n) => Math.min(n, 290));
        }

        return changeMapCenterWithBoundaryPolygon({ olMap: this.olmap, boundaryPolygon: geometry, padding });
    }

    handleRotateEntityChange = async ({ entityId, coordinate }) =>
    {
        this.setState({ selectedEntityImage: undefined });
        this.context.changeSelectedEntity(undefined);

        const entity = this.props.entities[entityId] || false;

        if (entityId && deepValue(entity, `entityLabel.longName.${DEFAULT_LANGUAGE_CODE}`, false))
        {
            const entityFeature = entity.feature;
            await this.context.changeSelectedEntity(entityId, entityFeature);
            this.popupOverlay.setPosition(coordinate);

            const geometry = entityFeature.getGeometry();

            // change map center to selected entity
            await this.fitToPolygon(geometry);
        }
    }

    handleSplitEntityChange = async ({ entityId, coordinate, entityFeature }) =>
    {
        this.context.changeSelectedEntity(undefined);

        if (entityId)
        {
            await this.context.changeSelectedEntity(entityId, entityFeature);
            this.popupOverlay.setPosition(coordinate);

            this.addDrawInteraction(MAP_TOOLS.SPLIT);

            const geometry = entityFeature.getGeometry();
            await this.fitToPolygon(geometry);
        }
    }

    handleMergeEntitySelect = async ({ entityId, entityFeature }) =>
    {
        if (entityId)
        {
            const selectedEntities = await this.context.changeSelectedEntityMultiple(entityId, entityFeature);
            //todo set position to top of merged polygon
            if (Object.keys(selectedEntities).length >= 2)
            {
                const mergedPolygon = this.context.mapController.polygonController.getMergedPolygon(selectedEntities);

                const topCoord = getGeoJSONTopCoordinate(mergedPolygon);

                this.popupOverlay.setPosition(topCoord);
            }
        }
    }

    handleSearch = (entityId, feature) =>
    {
        this.context.changeSelectedEntity(undefined);
        //center map to selected entity center.
        const geometry = feature.getGeometry();
        const topCenter = getFeatureTopCoordinate(feature);

        this.fitToPolygon(geometry);

        //set popup postion after transition time.
        new Promise((resolve) => setTimeout(resolve, BRANDING_MAP_CONSTANTS.ANIMATION_DURATION))
            .then(() =>
            {
                this.context.changeSelectedEntity(entityId, feature);
                this.popupOverlay.setPosition(topCenter);
            });
    }

    handleMapMoveEvent = () =>
    {
        const zoom = this.olmap.getView().getZoom();

        const textLayer = deepValue(this.entityLayers, `${BRANDING_MAP_CONSTANTS.TEXT_LAYER}`, undefined);

        if (zoom < BRANDING_MAP_CONSTANTS.MIN_ZOOM_TEXT_LAYER && !!textLayer)
        {
            if (this.entityLayers)
            {
                textLayer.setVisible(false);
            }
        }
        else if (textLayer)
        {
            if (this.entityLayers)
            {
                textLayer.setVisible(true);
            }
        }
    }

    //--------- IMAGE HELPER FUNCTIONS ---------//
    drawBorderOnImageLayer = (imageLayer, rotation) =>
    {
        try
        {
            let boundingPolygon = this.context.mapController.imageController.getImageBoundingPolygonWithRotation(imageLayer, rotation);

            const boundingBoxGeom = new Polygon(boundingPolygon.geometry.coordinates);

            if (!this.boundingBoxLayer)
            {
                this.boundingBoxLayer = createVectorLayer();
                this.olmap.addLayer(this.boundingBoxLayer);
            }

            //Create feature
            let feature = new Feature({
                geometry: boundingBoxGeom,
                id: "boundingBox",
            });

            let boundingBoxStyle = new Style({
                stroke: new Stroke(BOUNDING_BOX_STYLE.stroke)
            });

            feature.setStyle(boundingBoxStyle);

            // add feature to source
            const source = this.boundingBoxLayer.getSource();
            source.clear();
            source.addFeature(feature);
        }
        catch (err)
        {
            console.log("border err", err);
            return undefined;
        }
    }

    removeBorderOnImageLayer = () =>
    {
        this.removeEntityLayers([this.boundingBoxLayer]);
        this.boundingBoxLayer = undefined;
    }

    saveRotateImagePopup = (rotation, savedImage) =>
    {
        const { selectedEntityImage } = this.state;

        // save rotation
        if (rotationIsValid(rotation))
        {
            this.context.handleRotateEntityImage(selectedEntityImage, rotation, savedImage, (success) =>
            {
                if (success)
                {
                    this.closeRotateImagePopup();
                }
                else
                {
                    alert("The image is out of bounds");
                }
            });
        }
    }

    closeRotateImagePopup = () =>
    {
        this.removeBorderOnImageLayer();
        this.setState({ selectedEntityImage: undefined });
        this.context.changeSelectedEntity(undefined);
    }

    handleUpdateEntityLabel = async (entityLabel) =>
    {
        if (this.context.state.buildingId || !entityLabel.buildingId)
        {
            await this.context.updateEntityLabel(entityLabel);
        }
        else
        {
            await this.context.updatePropertyEntityLabel(entityLabel);
        }
        this.closeRotateImagePopup();
    }

    //--------- RENDER FUNCTIONS ---------//
    renderOlPopup = () =>
    {
        const { selectedEntityImage } = this.state;
        const { selectedEntities } = this.context.state;
        const { selectedEntityId } = getSelectedEntity(selectedEntities);

        if (this.props.selectedTool === MAP_TOOLS.EDIT)
        {
            return <SelectEntityPopup selectedEntityId={selectedEntityId} />;
        }
        else if (this.props.selectedTool === MAP_TOOLS.ROTATE)
        {
            if (selectedEntityImage)
            {
                const entity = this.props.entities[selectedEntityId] || {};
                const isPointOfIntrest = entity.entityType === EntityType.POINT_OF_INTEREST;
                return (
                    <RotateImagePopup
                        selectedEntityId={selectedEntityId}
                        selectedEntityImage={selectedEntityImage}
                        onSaveRotation={this.saveRotateImagePopup}
                        onClosePopup={this.closeRotateImagePopup}
                        onRotateChange={this.drawBorderOnImageLayer}
                        allowNull={isPointOfIntrest}
                        selectedEntity = {entity.entityLabel}
                        onOverrideIconRotation = {this.handleUpdateEntityLabel}
                    />
                );
            }
            else
            {
                return <RotatePopup selectedEntityId={selectedEntityId} allowNull />;
            }

        }
        else if (this.props.selectedTool === MAP_TOOLS.MERGE)
        {
            return <MergePopup selectedEntities={selectedEntities} />;
        }
    }

    render()
    {
        const { selectedTool } = this.props;
        const { selectedEntities } = this.context.state;

        return (
            <div className="branding-map">
                <div className={`map-container ${(selectedTool === MAP_TOOLS.EDIT && Object.keys(selectedEntities).length !== 0) ? "map-edit-active" : ""}`} ref={this.mapRef}>
                    <MapButtons onZoomIn={this.handleZoomIn} onZoomOut={this.handleZoomOut} onSearch={this.handleSearch} />
                    <div ref={this.popupRef}>{this.renderOlPopup()}</div>
                </div>
            </div>
        );
    }
}

// pass in data from context as props to make componentDidUpdate more manageable
const MapWithDataAsProps = () =>
{
    const { state } = useContext(BrandingContext);

    let tileLayer = styleClassic.tileLayer;

    if (state?.mapData?.tileLayer)
    {
        tileLayer = state.mapData.tileLayer;
    }

    let buildingMapSettings;

    // get the building specific map settings like orientation, zoom etc
    if (state?.buildingId && state?.settingsConfig)
    {
        const { buildingId, settingsConfig } = state;

        buildingMapSettings = settingsConfig?.buildingSettings?.[buildingId]?.mapSettings;
    }

    return <BrandingMap
        propertyId={state?.propertyId}
        buildingId={state?.buildingId}
        floorId={state?.floorId}
        imageLayers={state?.imageLayers || {}}
        selectedTool={state?.selectedTool}
        entities={state?.mapData?.entities || {}}
        settingsConfig={state?.settingsConfig}
        tileLayer={tileLayer}
        buildingMapSettings={buildingMapSettings}
    />;
};

export default MapWithDataAsProps;
