import React from "react";

export const DataListingTableColumn = ({
    className = "",
    detail = undefined,
    children = undefined,
    narrow = false,
    regular = false,
    medium = false,
    wide = false,
    isPopup = false
}) => (
    <div className={
        isPopup? "toolTip" : `dataListingTableColumn${narrow ? " narrow" : ""}${regular ? " regular" : ""}${medium ? " medium" : ""}${wide ? " wide" : ""} ${className}`
    }>
        {
            detail && (<p>{detail}</p>)
        }
        {children}
    </div>
);
