import React from 'react';
import { Header, Button, Icon } from 'semantic-ui-react';

export const HeadingProperty = ({heading, info, onClick}) =>
{
    return (
        <Header as="h3">
            {onClick && 
                <Button className="notify-back" onClick={onClick}>
                    <Icon name="arrow left" color="blue" />
                </Button>
            }
            {heading}
            {info && info}
        </Header>
    );
}