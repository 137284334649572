import { cartoLayer, createEntityGeometry, createEntityStyle, createPointStyle, createTextStyle, createVectorLayer } from "mapsted.maps/mapFunctions/plotting";
import { DEFAULT_ERROR_HIGHLIGHT_STYLE, DEFAULT_HIGHLIGHT_STYLE, MapEditorConstants, NODE_GEOMETRY_OPTIONS, SNAP_PIXEL_TOLERANCE } from "mapsted.maps/utils/map.constants";
import { Map, View, Feature } from "ol";
import { LineString, Point } from "ol/geom";
import { defaults as defaultControls, Attribution } from "ol/control";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { setMapLayers } from "mapsted.maps/mapFunctions/publicVectorLayers";
import { changeMapCenterWithBoundaryPolygon, getNodeDataFromClick, getSelectedEntityFromClick } from "mapsted.maps/mapFunctions/interactions";
import MapEditorContext from "../../../store/MapEditiorContext";
import { MapEditorMapButtons } from "./MapEditorMapButtons";
import { ANGLE_TEXT_STYLE, ANGLE_TEXT_FEATURE_STYLE, GUIDE_LINE_STYLE, MAP_EDITOR_TOOLS, ANGLE_TEXT_STYLE_GREEN, LINKED_NODES_STYLE, SELECTED_NODES_STYLE, FILTERED_ID_TYPES, EDITOR_ALERTS, FILTERED_NODES_STYLE, MAP_LAYERS } from "../../../_constants/mapEditor";
import { DrawInteraction, MouseInteraction, SnapInteraction, TranslateInteraction } from "mapsted.maps/utils/interactionTemplates";
import { styleClassic, styleClassicNodes } from "mapsted.maps/utils/defualtStyles";
import { toMercatorFromArray, gpsCoordinateArrayToMercatorLocations, toGpsLocation } from "mapsted.maps/utils/map.utils";
import { lineString as turfLineString, transformRotate as turfTransformRotate, transformScale as turfTransformScale, angle as turfAngle, } from "@turf/turf";
import { ShapeTypes } from "mapsted.maps/utils/entityTypes";
import { wgs84ToMercator } from "../../../_utils/turf.utlils";
import { createLinkedId, createNewNodeGeometry, createNodeLinkFeaturesFromAddedNodeLinkStepData, extractNodeIdsFromNodeLinkId, setLayerVisability, setTextFeatureLocationToZero, updateTextFeatureLocation } from "../../../_utils/mapEditorUtils";
import { BRANDING_MAP_CONSTANTS } from "../../../_constants/constants";
import { CMSEntityAccess } from "mapsted.maps/mapFunctions/entityAccess";
import { createNodeIdFeature } from "mapsted.maps/mapFunctions/cmsVectorLayers";
import { toast } from "sonner";
import { EditNodeLinkModal } from "../mapOverlayComponents/EditNodeLinkModal";

/**
 *  Notes:
 *  - for highlighting nodes use, highlightConnectedNodes(), this will handle the highlighting logic and management of what nodes are highlighted vs previous highlighted nodes.
 *  */
export const NodeEditorMap = ({
    isFloor,
    smartFilter,
    selectedFilterItem,
    onGetPrevAutoLinkedNode,
    onSnapNode,
    onDrawNode,
    onDrawNodeSnappedToNode,
    onDrawNodeSnappedToNodeLink,
    onValidateAutoLink,
    onDeleteNode,
    onDeleteNodeLink,
    onEditNodes,
    onLinkNodes,
    onUpdateNodeLinks,
    onGetNodeLinksWithNodeIds,
    onGetNodeLinksForSelectedNodes,
    onManageEntityNodes }) =>
{
    const mapRef = useRef(null);

    /**
     * autoLinkTempFeaturesRef.current = 
     *  { 
     *      nodeLinkFeature,
     *      parallelGuideFeature,
     *      perpendicularGuideFeature,
     *      angleTextFeature,
     *      highlightedNodeFeatures,
     *  }
     */
    const autoLinkTempFeaturesRef = useRef(null);
   
    const selectedNodeIdsRef = useRef([]);
    const highlightedNodeFeaturesRef = useRef([]);
    const highlightedEntityFeaturesRef = useRef([]); // at time of writing there could only be one selected entity for node editor but using array provides future proofing support.
    const highlightedFilteredFeaturesRef = useRef(null); // {nodeFeatures, entityFeatures}
    const selectedNodeLinkFeaturesRef = useRef([]);
    const selectedFeaturesRef = useRef([]);

    const mapEditorContext = useContext(MapEditorContext);

    const [olMap, setOlMap] = useState(undefined);
    const [localEntityLayers, setLocalEntityLayers] = useState(undefined);
    const [activeInteraction, setActiveInteraction] = useState(undefined);
    const [activeSnapInteractions, setActiveSnapInteractions] = useState(undefined);
    const [activeModifyInteractions, setActiveModifyInteractions] = useState(undefined);
    const [selectedFeatures, setSelectedFeatures] = useState([]);
    const [selectedNodeLinks, setSelectedNodeLinks] = useState(undefined); // used for node priority modal

    //https://stackoverflow.com/questions/57847594/react-hooks-accessing-up-to-date-state-from-within-a-callback
    selectedFeaturesRef.current = selectedFeatures;
    
    const mapData = useMemo(() => (
        mapEditorContext?.state?.mapData
    ), [mapEditorContext]);

    const tileLayer = useMemo(() =>
    {
        const tileStyle = mapData?.style?.tileLayer;
        return cartoLayer(tileStyle); // TODO: add translation 
    }, [mapData]);

    const interactions = useMemo(() => (
        {
            [MAP_EDITOR_TOOLS.Create]: (source, style, callback) => DrawInteraction({ source: source, type: ShapeTypes.POINT, style, handleDrawEndEvent: callback }),
            [MAP_EDITOR_TOOLS.AutoLink]: (source, style, drawEndCallback, mouseMoveCallback) => DrawInteraction({ source: source, type: ShapeTypes.POINT, style, handleDrawEndEvent: drawEndCallback, handleMoveEvent: mouseMoveCallback }),
            [MAP_EDITOR_TOOLS.Delete]: (source, callback) => MouseInteraction({ source, handleEvent: callback }),
            [MAP_EDITOR_TOOLS.Delete_Reconnect]: (source, callback) => MouseInteraction({ source, handleEvent: callback }),
            [MAP_EDITOR_TOOLS.Link]: (source, callback) => MouseInteraction({ source, handleEvent: callback }),
            [MAP_EDITOR_TOOLS.Edit_Node]: (source, callback) => MouseInteraction({ source, handleEvent: callback }),
            [MAP_EDITOR_TOOLS.Edit_Node_Link]: (source, callback) => MouseInteraction({ source, handleEvent: callback }),
            [MAP_EDITOR_TOOLS.Manage_Entity_Nodes]: (source, callback) => MouseInteraction({ source, handleEvent: callback }),
        }), []);

    const modifyInteractions = useMemo(() => (
        {
            [MAP_EDITOR_TOOLS.Edit_Node]: (features, translatingCallback, endCallback, startCallback) => [
                TranslateInteraction({ features, hitTolerance: 1, onTranslateStart: startCallback, onTranslating: translatingCallback, onTranslateEnd: endCallback }),
            ]
        }),[]);

    const resetNodeFeatureStyles = useCallback((nodeFeatures) =>
    {
        if (!Array.isArray(nodeFeatures))
        {
            return;
        }

        const nodeStyle = createEntityStyle(styleClassicNodes.node);

        if (Array.isArray(nodeFeatures))
        {
            nodeFeatures.forEach((feature) =>
            {
                feature.setStyle(nodeStyle);
            });
        }

    }, []);

    const resetEntityFeatureStyles = useCallback((entityFeatures) =>
    {
        if (!Array.isArray(entityFeatures))
        {
            return;
        }

        const styleTemplate = isFloor
            ? styleClassic.building
            : styleClassic.property;

        entityFeatures.forEach((entityFeature) =>
        {
            if (!entityFeature)
            {
                return;
            }
            const entityAccess = new CMSEntityAccess(entityFeature.getProperties());
            const style = entityAccess.getStyleObject(styleTemplate);

            let entityStyle = createEntityStyle(style);

            // if geometry is point, create point style
            const geometryType = entityFeature.getGeometry().getType();
            if (geometryType === "Point")
            {
                const pointStyle = createPointStyle(style);

                entityStyle = pointStyle;
            }

            entityFeature.setStyle(entityStyle);
        });
    }, [isFloor]);

    const handleHighlightSelectedFilteredItems = useCallback(() =>
    {
        //  {nodeFeatures, entityFeatures}
        let highlightedFilteredFeatures = { ...highlightedFilteredFeaturesRef.current };

        // reset currently highlighted features
        if (highlightedFilteredFeatures)
        {
            resetNodeFeatureStyles(highlightedFilteredFeatures.nodeFeatures);
            resetEntityFeatureStyles(highlightedFilteredFeatures.entityFeatures);
        }

        // reset any highlighted features
        if (highlightedNodeFeaturesRef.current)
        {
            resetNodeFeatureStyles(highlightedNodeFeaturesRef.current);
        }

        highlightedFilteredFeatures = { nodeFeatures: [], entityFeatures: [] };

        if (smartFilter)
        {
            let { idType, filteredIds, filteredFeatures, } = smartFilter;

            filteredFeatures = filteredFeatures.filter((feature) => feature !== undefined);

            highlightedFilteredFeatures = {
                nodeFeatures: [],
                entityFeatures: [],
            };

            // highlight features depending on the type of filter
            switch (idType)
            {
            // highlight filtered entities 
            case (FILTERED_ID_TYPES.ENTITY):
            {
                highlightSelectedEntitiesHelper(filteredFeatures, DEFAULT_ERROR_HIGHLIGHT_STYLE);
                highlightedFilteredFeatures.entityFeatures = filteredFeatures;
                break;
            }
            // highlight filtered nodes
            case (FILTERED_ID_TYPES.NODE):
            {
                let { nodeLayers } = mapEditorContext?.state?.layers;
                let nodeSource = nodeLayers.node.getSource();

                const highlightStyle = createEntityStyle(FILTERED_NODES_STYLE);

                filteredIds.forEach(({ cmsId }) =>
                {
                    // FILTERED NODES IS NULL
                    let filteredNodeFeature = nodeSource.getFeatureById(cmsId);
                    if (filteredNodeFeature)
                    {
                        filteredNodeFeature.setStyle(highlightStyle);
                        highlightedFilteredFeatures.nodeFeatures.push(filteredNodeFeature);
                    }
                    else
                    {
                        console.log("DEBUG: filtered node feature does not exist");
                    }
                });
            }
            }
        }

        // make sure we don't reset any selected nodes
        highlightConnectedNodes(undefined, undefined, selectedFeaturesRef.current, {
            isHighlightNodeLinks: false,
            isAddNodeToArray: false
        });

        // set highlighted filtered features ref
        highlightedFilteredFeaturesRef.current = highlightedFilteredFeatures;

    }, [smartFilter, resetEntityFeatureStyles, resetNodeFeatureStyles, selectedFeaturesRef.current, mapEditorContext?.state?.layers]);


    //on smart filter change
    useEffect(() =>
    {
        handleHighlightSelectedFilteredItems();
        // debug: do we need selected features watcher here?
    }, [smartFilter, selectedFeatures]);

    // on selectedFilterItemChange
    useEffect(() =>
    {
        let highlightedFilteredFeatures = highlightedFilteredFeaturesRef.current;

        if (!selectedFilterItem || !smartFilter || !olMap || !highlightedFilteredFeatures)
        {
            return;
        }

        Object.values(highlightedFilteredFeatures).forEach((featureArray) =>
        {
            featureArray.forEach((feature) =>
            {
                let featureId = feature.getId();

                if (featureId === selectedFilterItem)
                {
                    let geometry = feature.getGeometry();

                    // add padding if geometry is point
                    changeMapCenterWithBoundaryPolygon({ olMap, boundaryPolygon: geometry });
                    return;
                }
            });
        });

    }, [selectedFilterItem, olMap]);

    /**
     * On Mount 
     * -> set up olmap
     */
    useEffect(() =>
    {
        const attribution = new Attribution({
            collapsible: true
        });

        const newOlMap = new Map({
            target: null,
            layers: [tileLayer],
            controls: defaultControls({
                attribution: false,
                zoom: false,
                rotate: false
            }).extend([attribution]),
            view: new View({
                center: [0, 0],
                zoom: 4,
                maxZoom: MapEditorConstants.MAX_ZOOM,
                minZoom: MapEditorConstants.MIN_ZOOM
            })
        });

        newOlMap.setTarget(mapRef.current);

        setOlMap(newOlMap);

    }, []);

    // update layer visibility on change
    useEffect(() =>
    {
        if (!olMap || !localEntityLayers)
        {
            return;
        }

        const mapLayersVisibilityMap = mapEditorContext.state.mapLayersVisablityMap;

        setLayerVisability(localEntityLayers, mapLayersVisibilityMap);

    }, [mapEditorContext.state?.mapLayersVisablityMap, localEntityLayers, olMap]);

    /**
     * on layers change
     * -> plot new layers
     */
    useEffect(() =>
    {
        const layers = mapEditorContext?.state?.layers;

        if (!!layers && !!olMap)
        {
            const { boundaryPolygon, entityLayers, nodeLayers, imageLayers, idLayers } = layers;
            drawEntityLayers(entityLayers, imageLayers, nodeLayers, idLayers);

            changeMapCenterWithBoundaryPolygon({ olMap, boundaryPolygon, padding: MapEditorConstants.FIT_PADDING_MAP_DATA });
        }

    }, [mapEditorContext.state?.layers, olMap]);

    /**
     * on selected tool change
     * -> remove the previous interaction and add the new interaction to the map
     */
    useEffect(() =>
    {
        const { selectedTool } = mapEditorContext.state;
        addNodeInteraction(selectedTool);

        // reset any highlighted features
        if (highlightedNodeFeaturesRef.current)
        {
            resetNodeFeatureStyles(highlightedNodeFeaturesRef.current);
            resetEntityFeatureStyles(highlightedEntityFeaturesRef.current);
            highlightedNodeFeaturesRef.current = [];
            setSelectedFeatures([]);
            setSelectedNodeLinks(undefined);
        }

        // if tool changes while temp guidelines are on the map, remove them from the map 
        if (selectedTool !== MAP_EDITOR_TOOLS.AutoLink && !!autoLinkTempFeaturesRef.current)
        {
            let { [MAP_LAYERS.node_helper_layer]: nodeHelperLayer } = localEntityLayers;

            let nodeHelperLayerSource = nodeHelperLayer.getSource();

            Object.values(autoLinkTempFeaturesRef.current).forEach((tempFeature) =>
            {
                if (tempFeature)
                {
                    nodeHelperLayerSource.removeFeature(tempFeature);
                }
            });

            autoLinkTempFeaturesRef.current = null;
        }

        // reset selected nodes ref
        selectedNodeIdsRef.current = [];

        handleHighlightSelectedFilteredItems();
    }, [mapEditorContext.state?.selectedTool]);

    /**
     * Selected feature callback to attach additional map interactions for edit.
     */
    useEffect(() =>
    {
        const { selectedTool } = mapEditorContext.state;
        const nodeConnectionSource = localEntityLayers?.nodeConnection?.getSource();
        const selectedFeatures = selectedFeaturesRef.current;

        let newActiveModifyInteractions = [];
        let selectedNodeLinkFeatures = [];
        // remove any old interactions
        if (!!activeModifyInteractions && activeModifyInteractions.length > 0)
        {
            activeModifyInteractions.forEach( (modifyInteraction) =>
            {
                olMap.removeInteraction(modifyInteraction);
            });
        }

        // check if edit is selected && we have a selected feature 
        // can make this less static by instead checking if selected tool exists in modify interactions memo
        if (selectedTool === MAP_EDITOR_TOOLS.Edit_Node && !!selectedFeatures && selectedFeatures.length > 0)
        {
            const selectedNodeLinks = onGetNodeLinksForSelectedNodes(selectedFeatures);

            // add selected node link features
            selectedNodeLinks.forEach(({ nodeLinkId }) =>
            {
                const { nodeId1, nodeId2 } = extractNodeIdsFromNodeLinkId(nodeLinkId);
                const nodeLinkFeature1 = nodeConnectionSource.getFeatureById(createLinkedId(nodeId1, nodeId2));
                const nodeLinkFeature2 = nodeConnectionSource.getFeatureById(createLinkedId(nodeId2, nodeId1));

                // node link feature could be undefined as we add both variations of node link id to list
                if (nodeLinkFeature1)
                {
                    selectedNodeLinkFeatures.push(nodeLinkFeature1);
                } 
                if (nodeLinkFeature2)
                {
                    selectedNodeLinkFeatures.push(nodeLinkFeature2);
                }
            });

            // create interactions based off of its set of modify interactions 
            newActiveModifyInteractions = modifyInteractions[selectedTool](selectedFeatures, handleTranslatingNode, handleEditNodeEnd, handleEditNodeStart);

            // add interactions to map 
            newActiveModifyInteractions.forEach((interaction) =>
            {
                olMap.addInteraction(interaction);
            });
        }

        // set active interactions state
        selectedNodeLinkFeaturesRef.current = selectedNodeLinkFeatures;
        setActiveModifyInteractions(newActiveModifyInteractions);
    }, [selectedFeaturesRef.current]);


    /********* MAP CALLBACK HELPERS *********/

    /**
     * Updates the guidelines geometry based on last two nodes in the auto link array
     * 
     * Note: turf uses wgs84 while openlayers uses mercator
     * @param {*} param0 
     */
    const handleDrawAutoLinkGuideLines = ({ node, prevAutoLinkedNode }) =>
    {
        // - add guidelines based off of last two points (move to helper function)
        let { parallelGuideFeature, perpendicularGuideFeature } = autoLinkTempFeaturesRef.current;

        let currentPoint = node.shape.coordinates;
        let prevPoint = prevAutoLinkedNode.shape.coordinates;

        // -- parallel guide
        let parallelLine = turfLineString([prevPoint, currentPoint]);
        parallelLine = turfTransformScale(parallelLine, 100);

        parallelGuideFeature.setGeometry(new LineString(gpsCoordinateArrayToMercatorLocations(parallelLine.geometry.coordinates)));

        // -- perpendicular guide
        let perpendicularLine = turfTransformRotate(parallelLine, 90, { pivot: currentPoint });

        perpendicularGuideFeature.setGeometry((new LineString(gpsCoordinateArrayToMercatorLocations(perpendicularLine.geometry.coordinates))));
    };

    /**
     * creates and draws node auto link line
     * @param {*} object.node 
     * @param {*} object.prevAutoLinkedNode 
     * @param {*} object.nodeLinkId 
     */
    const handleDrawNodeAutoLink = ({ node, prevAutoLinkedNode, nodeLinkId }) =>
    {
        let { nodeConnection: nodeConnectionLayer } = localEntityLayers;

        let currentMercatorPoint = toMercatorFromArray(node.shape.coordinates);
        let prevMercatorPoint = toMercatorFromArray(prevAutoLinkedNode.shape.coordinates);

        let nodeConnectionSource = nodeConnectionLayer.getSource();
        // - create and add node link feature to layer
        const nodeGeometry = new LineString([prevMercatorPoint, currentMercatorPoint]);

        let nodeConnectionFeature = new Feature({
            geometry: nodeGeometry,
            isNodeConnection: true,
            isNode: true,
            id: nodeLinkId,
            nodeId1: prevAutoLinkedNode._id,
            nodeId2: node._id,
        });

        nodeConnectionFeature.setId(nodeLinkId);

        try
        {
            nodeConnectionSource.addFeature(nodeConnectionFeature);
        }
        catch (err)
        {
            nodeConnectionSource.removeFeature(nodeConnectionFeature);
            nodeConnectionSource.addFeature(nodeConnectionFeature);
            console.log(err, "DEBUG - AUTO LINK FEATURE WAS ALREADY ADDED TO SOURCE");
        }
    };

    /**
     * Handles the placement of the angle text feature
     * @param {*} param0 
     */
    const handleDrawAutoLinkAngleFeature = ({ node }) =>
    {
        let { angleTextFeature } = autoLinkTempFeaturesRef.current;
        angleTextFeature.setGeometry(new Point(toMercatorFromArray(node.shape.coordinates)));
    };

    /**
     * Handles the style of the text feature
     * @param {*} param0 
     */
    const handleDrawAutoLinkAngleText = ({ prevAutoLinkedNode, secondPrevAutoLinkedNode, cursorCoordinate }) =>
    {
        let { angleTextFeature } = autoLinkTempFeaturesRef.current;

        let angle = turfAngle(secondPrevAutoLinkedNode.shape.coordinates, prevAutoLinkedNode.shape.coordinates, toGpsLocation(cursorCoordinate));

        angle = Math.round(angle * 10) / 10;

        let angleStyle = angleTextFeature.getStyle();

        let angleTextStyleOptions = (angle % 90 === 0) 
            ? ANGLE_TEXT_STYLE_GREEN 
            : ANGLE_TEXT_STYLE;

        let textStyle = createTextStyle(`${angle}°`, 0, angleTextStyleOptions);
        angleStyle.setText(textStyle);

        angleTextFeature.setStyle(angleStyle);
    };

    /**
     * creates and draws the auto link visual lineString and guidelines 
     * @param {*} object.prevAutoLinkedNode
     * @param {*} object.node
     * @param {*} object.nodeLinkId
     */
    const handleAutoLink = ({ prevAutoLinkedNode, node, nodeLinkId }) =>
    {
        if (prevAutoLinkedNode)
        {
            if (nodeLinkId)
            {
                handleDrawNodeAutoLink({ node, prevAutoLinkedNode, nodeLinkId });
            }
            handleDrawAutoLinkGuideLines({ prevAutoLinkedNode, node });

            handleDrawAutoLinkAngleFeature({ node });
        }
    };

    /**
     * adds node to local node list and updates the feature on map with its ID
     * @param {*} feature 
     * @param {*} isAutoLink 
     */
    const handleDrawNode = (feature, mapClickData, isAutoLink, isValidAutoLink) =>
    {
        const handleIsInvalidHelper = (alertMessage) =>
        {
            feature.setGeometry(new Point([0, 0]));
            toast.error(alertMessage);
        };

        // get prev auto linked node before new node is added
        const prevAutoLinkedNode = onGetPrevAutoLinkedNode();

        if (!isValidAutoLink)
        {
            handleIsInvalidHelper(EDITOR_ALERTS.NOT_VALID_NODE_LINK);
            return;
        }
        if (mapClickData.isNodeForbidden)
        {
            handleIsInvalidHelper(EDITOR_ALERTS.NODE_FORBIDDEN);
        }

        // add node to local list of nodes
        const { node, nodeLinkId, isValid, validationError, nodeStepData } = onDrawNode(feature, mapClickData, isAutoLink);

        if (!isValid)
        {
            handleIsInvalidHelper(validationError);
            return;
        }

        // use step data to handle links
        const linkStepData = {
            addedNodeLinks: nodeStepData.addedNodeLinks,
            deletedNodeLinks: nodeStepData.deletedNodeLinks,
        };
        handleOutsideStepAction(linkStepData);

        const nodeGeometry = createNewNodeGeometry(node);

        feature.setGeometry(nodeGeometry);
        feature.setId(node._id);
        feature.set("isNode", true);

        if (isAutoLink)
        {
            // handle auto link additional draw functionality if prev point is present
            handleAutoLink({ node, prevAutoLinkedNode, nodeLinkId });
        }

        return node;
    };

    /**
     * - Instead of creating a node, it should instead add the already existing node to the auto linked node array.
     * - Linkage logic remains the same.
     * - If node already exists in the linkage chain, Pop the node out of the array and place it at the end of the chain
     * - remove new feature from map 
    * @param {Object} snappedData 
    * @param {boolean} isAutoLink 
    * @param {Feature} feature 
    * @param {boolean} isValidAutoLink 
    * @returns {Object} node
     */
    const handleSnappedToNode = (snappedData, isAutoLink, feature, isValidAutoLink) =>
    {
        // no validation needed if prev node is attached to the node
        const { node, nodeLinkId, isValidAutoLink: isValid, nodeStepData } = onDrawNodeSnappedToNode(snappedData.nodeId, isAutoLink, isValidAutoLink);

        if (!isValid)
        {
            feature.setGeometry(new Point([0, 0]));
            toast.error(EDITOR_ALERTS.NOT_VALID_NODE_LINK);
            return;
        }

        // use step data to handle links
        const linkStepData = {
            addedNodeLinks: nodeStepData.addedNodeLinks,
            deletedNodeLinks: nodeStepData.deletedNodeLinks,
        };
        handleOutsideStepAction(linkStepData);

        if (isAutoLink)
        {
            const prevAutoLinkedNode = onGetPrevAutoLinkedNode(2);

            handleAutoLink({ node, prevAutoLinkedNode, nodeLinkId });
        }

        return node;
    };

    /**
     * - Link node to the two nodes on the node link
     * - The two nodes on the node linkage should now link to the new node and remove the previous linkage
     * - Draw the two new node link features
     * @param {*} e 
     * @param {*} snappedData 
     * @param {*} isAutoLink 
     */
    const handleSnappedToNodeLink = (feature, mapClickData, isAutoLink, isValidAutoLink) =>
    {
        const { nodeId1, nodeId2, nodeFeature: oldNodeLinkFeature } = mapClickData;
        const prevAutoLinkedNode = onGetPrevAutoLinkedNode();

        const { node, nodeLinkId, isValidAutoLink: isValid, nodeStepData } = onDrawNodeSnappedToNodeLink(feature, nodeId1, nodeId2, mapClickData, isAutoLink, isValidAutoLink);

        if (!isValid)
        {
            feature.setGeometry(new Point([0, 0]));
            toast.error(EDITOR_ALERTS.NOT_VALID_NODE_LINK);
            return;
        }

        // set feature id and is node bool for quick search ups
        const nodeGeometry = createNewNodeGeometry(node);

        feature.setGeometry(nodeGeometry);
        feature.setId(node._id);
        feature.set("isNode", true);

        // use step data to handle links
        const linkStepData = {
            addedNodeLinks: nodeStepData.addedNodeLinks,
            deletedNodeLinks: nodeStepData.deletedNodeLinks,
        };
        handleOutsideStepAction(linkStepData);

        // if auto link, add new link to node
        if (isAutoLink)
        {
            handleAutoLink({ node, prevAutoLinkedNode, nodeLinkId });
        }

        return node;
    };

    /**
     * Highlights new node feature and all nodes connected to it
     * @param {*} nodeFeature 
     * @param {*} node 
     */
    const highlightConnectedNodes = (nodeFeature, node, highlightedFeatures, options = { isHighlightNodeLinks: true, isAddNodeToArray: true }) =>
    {
        if (!localEntityLayers)
        {
            return;
        }

        // reset style of prev highlighted nodes
        resetNodeFeatureStyles(highlightedNodeFeaturesRef.current);

        const linkedNodeStyle = createEntityStyle(LINKED_NODES_STYLE);
        const selectedNodeStyle = createEntityStyle(SELECTED_NODES_STYLE);

        let nodeLinks = node?.nodeLinks;

        // find and highlight all linked node features
        let { node: nodeLayer } = localEntityLayers;
        let nodeSource = nodeLayer.getSource();

        let newHighlightedFeatures = highlightedFeatures || [];

        if (options.isAddNodeToArray)
        {
            newHighlightedFeatures.push(nodeFeature);
        }

        newHighlightedFeatures.forEach((feature) =>
        {
            if (feature)
            {
                feature.setStyle(selectedNodeStyle);
            }
        });

        // highlight node links
        if (Array.isArray(nodeLinks) && options.isHighlightNodeLinks)
        {
            nodeLinks.forEach((nodeLink) =>
            {
                let linkedFeature = nodeSource.getFeatureById(nodeLink.linkedNode);
                if (linkedFeature)
                {
                    linkedFeature.setStyle(linkedNodeStyle);
                    newHighlightedFeatures.push(linkedFeature);
                }
            });
        }

        highlightedNodeFeaturesRef.current = newHighlightedFeatures;
    };

    /**
     * Removes previously highlighted entities.
     * Highlights the selected entities on the map.
     * Manages highlighted entity ref state
     * @param {Array} entityFeatures - The array of entity features to be highlighted.
     * @return {void} This function does not return anything.
     */
    const highlightSelectedEntities = (entityFeatures) =>
    {
        if (!Array.isArray(entityFeatures))
        {
            return;
        }
        
        // reset previous highlighted entity
        resetEntityFeatureStyles(highlightedEntityFeaturesRef.current);
        
        // call highlight entity helper 
        highlightSelectedEntitiesHelper(entityFeatures, DEFAULT_HIGHLIGHT_STYLE);

        // update selected entity ref
        highlightedEntityFeaturesRef.current = entityFeatures;
    };

    /**
     * passes translated features to handleMoveNodeLinks function
     * @param {*} e 
     */
    const handleTranslatingNode = (e) =>
    {
        const { features } = e;
        handleTranslateNodeLinks(features);
    };

    /**
     *  on edit start event, hide any attached text features
     */
    const handleEditNodeStart = () =>
    {
        const { nodeConnection: nodeConnectionLayer } = localEntityLayers;

        let nodeConnectionSource = nodeConnectionLayer.getSource();
        const selectedNodeFeatureArray = selectedFeaturesRef.current;
        const selectedNodeLinkFeatures = selectedNodeLinkFeaturesRef.current;

        const nodeIdTextFeatureArray = getNodeIdTextFeatureArrayFromNodeFeatureArray(selectedNodeFeatureArray);

        if (Array.isArray(selectedNodeLinkFeatures))
        {
            selectedNodeLinkFeatures.forEach((nodeLinkFeature) =>
            {
                const connectedPointerFeatures = nodeLinkFeature.get("connectedPointerFeatures");

                if (Array.isArray(connectedPointerFeatures))
                {
                    connectedPointerFeatures.forEach((pointerFeature) =>
                    {
                        removeFeatureIfExist(pointerFeature, nodeConnectionSource);
                    });
                }
            });
        }

        nodeIdTextFeatureArray.forEach((nodeIdFeature) =>
        {
            setTextFeatureLocationToZero(nodeIdFeature);
        });
    };

    /**
     * Handles node translate end logic
     * @param {Event} e - 
     */
    const handleEditNodeEnd = (e) =>
    {
        const features = e.features.getArray();

        const { isValid, nodeStepData, validationError } = onEditNodes(features);

        if (!isValid)
        {
            toast.error(validationError);

            features.forEach((feature) =>
            {
                const nodeId = feature.getId();
                let node = nodeStepData.updatedNodesBefore.find((n) => n._id === nodeId);
                let nodeGeometry = createEntityGeometry(node.shape, NODE_GEOMETRY_OPTIONS);
                feature.setGeometry(nodeGeometry);
            });

            const resetNodeLinkStep = {
                deletedNodeLinks: nodeStepData.deletedNodeLinks,
                addedNodeLinks: nodeStepData.deletedNodeLinks
            }

            handleOutsideStepAction(resetNodeLinkStep);

            return;
        }

        // set node links 
        const nodeLinkSteps = {
            addedNodeLinks: nodeStepData.addedNodeLinks,
            deletedNodeLinks: nodeStepData.deletedNodeLinks,
        };
        handleOutsideStepAction(nodeLinkSteps);

        // set nodeId text locations 
        const nodeIdTextFeatureArray = getNodeIdTextFeatureArrayFromNodeFeatureArray(features);
        const nodeIdToFeatureMap = {};

        // create a mapping from navId to node feature
        features.forEach((nodeFeature) =>
        {
            const navNodeId = nodeFeature.get("nodeId");

            if (navNodeId)
            {
                nodeIdToFeatureMap[navNodeId] = nodeFeature;
            }
        });

        nodeIdTextFeatureArray.forEach((nodeIdTextFeature, i) =>
        {
            const nodeId = nodeIdTextFeature.getId();

            const connectedNodeFeature = nodeIdToFeatureMap[nodeId];

            updateTextFeatureLocation(nodeIdTextFeature, connectedNodeFeature);
        });

        // to reselect the used node features
        // helps grabbing the new node links
        setSelectedFeatures([]);
        setSelectedFeatures(features);
    };

    /**
     * Takes array of node features and updates selected node links with their positions
     * @param {Array} nodeFeatures 
     */
    const handleTranslateNodeLinks = (nodeFeatures, nodeLinkFeatures) =>
    {
        let nodeIdToCoordinateHash = {};

        if (!nodeLinkFeatures)
        {
            nodeLinkFeatures = selectedNodeLinkFeaturesRef.current;
        }

        // first loop through all the features to get all new node geoms
        nodeFeatures.forEach((feature) =>
        {
            const nodeId = feature.getId();
            const coordinates = feature.getGeometry().getCenter();
            nodeIdToCoordinateHash[nodeId] = coordinates;
        });

        // then loop through all features to update node link geometry
        nodeLinkFeatures.forEach((feature) =>
        {
            const nodeId1 = feature.get("nodeId1");
            const nodeId2 = feature.get("nodeId2");
            const node1 = getNodeFeatureWithNodeId(nodeId1);
            const node2 = getNodeFeatureWithNodeId(nodeId2);
            let geometry = feature.getGeometry();
            let coordinates = geometry.getCoordinates();

            // assuming node1 is coord 0 and node2 is coord 1
            if (nodeIdToCoordinateHash[nodeId1])
            {
                coordinates[0] = nodeIdToCoordinateHash[nodeId1];
            }
            else if (node1)
            {
                coordinates[0] = node1.getGeometry().getCenter();
            }

            if (nodeIdToCoordinateHash[nodeId2])
            {
                coordinates[1] = nodeIdToCoordinateHash[nodeId2];
            }
            else if (node2)
            {
                coordinates[1] = node2.getGeometry().getCenter();
            }

            geometry.setCoordinates(coordinates);

            feature.setGeometry(geometry);
        });

        if (!nodeLinkFeatures)
        {
            nodeLinkFeatures.current = nodeLinkFeatures;
        }
    };

    /**
     * Get nodeId text feature array with an array of node features
     * @param {Array} nodeFeatureArray - array of node features
     * @returns 
     */
    const getNodeIdTextFeatureArrayFromNodeFeatureArray = (nodeFeatureArray) =>
    {
        let nodeIdTextFeatureArray = [];
        let { nodeIds: nodeIdsLayer } = localEntityLayers;

        let nodeIdArray = [];

        // get nodeId array from features
        nodeFeatureArray.forEach((nodeFeature) =>
        {
            const nodeId = nodeFeature.get("nodeId");

            if (nodeId)
            {
                nodeIdArray.push(nodeId);
            }
        });

        // get nodeId text feature array with nodeId lookup
        nodeIdArray.forEach((nodeId) =>
        {
            const nodeIdTextFeature = nodeIdsLayer.getSource().getFeatureById(nodeId);
            nodeIdTextFeatureArray.push(nodeIdTextFeature);
        });

        return nodeIdTextFeatureArray;
    };

    /********* END OF MAP CALLBACK HELPERS *********/

    /********* MAP CALLBACKS *********/

    /**
     * 
     * @param {Event} e - Interaction event
     * @param {Function} onClick - ({isNode, isNodeConnection, nodeId,}) - map click data from getNodeDataFromClick
     * @param {Boolean} isNodeConnectionWatched - should hover change pointer for node links
     * @returns 
     */
    const selectNodeHelper = (e, onClick, isNodeConnectionWatched) =>
    {
        let { type } = e;

        if (!e.pixel)
        {
            return;
        }

        const mapClickData = getNodeDataFromClick({ pixel: e.pixel_, olMap });

        // on hover
        if (type === "pointermove")
        {
            handleHoverOverNodeOnPointerMove(mapClickData, isNodeConnectionWatched);
        }
        // on click
        else if (type === "click")
        {
            let shiftKey = e.originalEvent.shiftKey;

            (!!onClick) && (
                onClick(mapClickData, shiftKey)
            );
        }
        else
        {
            return true;
        }
    };


    /**
     * map draw end callback for create node feature
     * @param {*} e 
     */
    const onCreateNode = async (e, isAutoLink) =>
    {
        let isValidAutoLink = true;

        // NOTE: the "getNodeDataFromClick" function will prioritize nodes over node connections (links) 
        let mapClickData = getNodeDataFromClick({ pixel: e.target.downPx_, olMap });
        let { feature, isSnapped } = await onSnapNode(e, mapClickData);

        if (isAutoLink && !isSnapped)
        {
            isValidAutoLink = onValidateAutoLink(e.feature);
        }

        let node;
        // since a node connection (link) feature has the boolean "isNode" = true, we first check if its a node connection
        if (mapClickData.isNodeConnection)
        {
            node = handleSnappedToNodeLink(feature, mapClickData, isAutoLink, isValidAutoLink);
        }
        else if (mapClickData.isNode)
        {
            node = handleSnappedToNode(mapClickData, isAutoLink, e.feature, isValidAutoLink);
        }
        else
        {
            node = handleDrawNode(feature, mapClickData, isAutoLink, isValidAutoLink);
        }

        highlightConnectedNodes(feature, node);
    };

    /**
     * If cursor is hovering a node, 
     * @param {*} mapClickData 
     */
    const handleHoverOverNodeOnPointerMove = (mapClickData, isNodeConnectionWatched = false) =>
    {
        const { isNode, isNodeConnection } = mapClickData;

        if (isNodeConnectionWatched && isNodeConnection)
        {
            olMap.getViewport().style.cursor = "pointer";
        }
        else if (isNode && !isNodeConnection)
        {
            olMap.getViewport().style.cursor = "pointer";
        }
        else
        {
            olMap.getViewport().style.cursor = "";
        }
    };

    const onLinkMouseEvent = (e) =>
    {
        let selectedNodeIds = selectedNodeIdsRef.current;
        let highlightedNodeFeatures = highlightedNodeFeaturesRef.current;

        return selectNodeHelper(e, (mapClickData) =>
        {
            // on click logic for link mouse event
            const { isNode, isNodeConnection, nodeId, nodeFeature } = mapClickData;

            if (isNode && !isNodeConnection)
            {
                selectedNodeIds.push(nodeId);

                // if no selected nodes are in the array, add selected node to array and highlight
                if (selectedNodeIds.length === 2)
                {
                    let linkData = onLinkNodes(selectedNodeIds);

                    if (linkData)
                    {
                        handleOutsideStepAction(linkData);
                    }

                    selectedNodeIds = [];
                    resetNodeFeatureStyles(highlightedNodeFeatures);
                    highlightedNodeFeatures = [];

                }
                // if there is a selected node in the array, send to link logic
                else 
                {
                    highlightConnectedNodes(nodeFeature);
                }
            }

            selectedNodeIdsRef.current = selectedNodeIds;
        }, false);
    };

    /**
     * 
     * @param {*} e 
     * @returns 
     */
    const onDeleteAndReconnectMouseEvent = (e) => (onDeleteMouseEvent(e, true));

    /**
     * on mouse move
     * - if mouse is hovering a node change cursor
     * - else set cursor to default cursor
     *
     * on click logic for delete mouse event
     * -remove node, go through all node links and remove deleted node from respected node links 
     *
     * @param {object} e - the mouse event object
     * @param {boolean} isReconnect - indicates if it's a reconnect event
     * @return {type} the return value description
     */
    const onDeleteMouseEvent = (e, isReconnect) =>(
        // on mouse move
        // - if mouse is hovering a node change cursor
        // - else set cursor to default cursor
        selectNodeHelper(e, (mapClickData) =>
        {
            // on click logic for delete mouse event
            const { isNode, isNodeConnection } = mapClickData;
            let deleteData;

            // delete node link logic
            if (!isReconnect && isNodeConnection)
            {
                // call delete node link 
                deleteData = onDeleteNodeLink(mapClickData);
            }
            // - if mouse clicked a node, continue with delete node logic
            else if (isNode && !isNodeConnection)
            {
                // delete logic
                // -remove node, go through all node links and remove deleted node from respected node links 
                deleteData = onDeleteNode(mapClickData, isReconnect);
            }

            // update map from the performed outside action
            if (deleteData)
            {
                handleOutsideStepAction(deleteData);
            }
        }, !isReconnect)
    );

    const onSelectNodeLinkEvent = (e) => (
        // on mouse move
        // - if mouse is hovering a node change cursor
        // - else set cursor to default cursor
        selectNodeHelper(e, (mapClickData) =>
        {
            // on click logic for delete mouse event
            const { nodeId1, nodeId2, isNodeConnection } = mapClickData;
            let { node: nodeLayer } = localEntityLayers;
            let nodeSource = nodeLayer.getSource();

            if (isNodeConnection)
            {
                const nodeFeature1 = nodeSource.getFeatureById(nodeId1);
                const nodeFeature2 = nodeSource.getFeatureById(nodeId2);

                const nodeLinks = onGetNodeLinksWithNodeIds(nodeId1, nodeId2);

                setSelectedFeatures([nodeFeature1, nodeFeature2]);
                setSelectedNodeLinks(nodeLinks);
            }

        }, true)
    );

    const handleEditNodeLink = (nodeLinks) =>
    {
        // update node link in indexDB and local data
        const stepData = onUpdateNodeLinks(nodeLinks);
        // update map with outside step

        handleOutsideStepAction(stepData);

        // update selected node links with the update
        setSelectedNodeLinks(nodeLinks);
    }

    /**
     * map draw end callback for create autolinked nodes feature
     * @param {*} e 
     */
    const onCreateAutoLinkNode = (e) =>
    {
        onCreateNode(e, true);
    };

    /**
     * map mouse move callback fro create autolinked nodes feature
     *
     */
    const onAutoLinkMouseMove = (e) =>
    {
        const prevAutoLinkedNode = onGetPrevAutoLinkedNode();

        // show the auto link line from prev point to cursor
        if (prevAutoLinkedNode)
        {
            let prevPoint = toMercatorFromArray(prevAutoLinkedNode.shape.coordinates);
            let feature = autoLinkTempFeaturesRef.current.nodeLinkFeature;

            feature.setGeometry(new LineString([prevPoint, e.coordinate]));

            // - draw angle text if last two prevNode present 
            const secondPrevAutoLinkedNode = onGetPrevAutoLinkedNode(2);

            if (secondPrevAutoLinkedNode)
            {
                handleDrawAutoLinkAngleText({ secondPrevAutoLinkedNode, prevAutoLinkedNode, cursorCoordinate: e.coordinate });
            }
        }
    };

    /**
     * select node for edit interaction
     * @param {*} e 
     */
    const onSelectNode = (e) =>
    {
        let selectedNodeFeatures = []; // reset to empty for now, future update will handle multiple selected node features
        let highlightedNodeFeatures = [];

        return selectNodeHelper(e, (mapClickData, shiftKey) =>
        {
            // on select node logic
            const { isNode, isNodeConnection, nodeFeature } = mapClickData;

            // check if map click data contains node
            if (isNode && !isNodeConnection)
            {
                const nodeId = nodeFeature.getId();
                let nodePrevSelectedIdx = undefined;

                // if shift key is pressed we want to select multiple.
                // instead of resetting selected nodes we add to them 
                if (!shiftKey)
                {
                    selectedNodeFeatures = [nodeFeature];
                    highlightedNodeFeatures = [nodeFeature];
                }
                else
                {
                    selectedNodeFeatures = [...selectedFeaturesRef.current];
                    highlightedNodeFeatures = [...highlightedNodeFeaturesRef.current];

                    for (let i = 0; i < selectedNodeFeatures.length; i++)
                    {
                        const prevSelectedFeature = selectedNodeFeatures[i];
                        const prevSelectedNodeId = prevSelectedFeature.getId();
                        if (nodeId === prevSelectedNodeId)
                        {
                            nodePrevSelectedIdx = i;
                            break;
                        }
                    }

                    if (nodePrevSelectedIdx !== undefined)
                    {
                        selectedNodeFeatures.splice(nodePrevSelectedIdx, 1);
                        highlightedNodeFeatures.splice(nodePrevSelectedIdx, 1);
                    }
                    else
                    {
                        selectedNodeFeatures.push(nodeFeature);
                        highlightedNodeFeatures.push(nodeFeature);
                    }
                }

                // highlight node
                highlightConnectedNodes(nodeFeature, undefined, highlightedNodeFeatures, {
                    isHighlightNodeLinks: false,
                    isAddNodeToArray: false,
                });

            }
            // reset old selected features when clicking on the map but not a node
            else
            {
                resetNodeFeatureStyles(highlightedNodeFeaturesRef.current);
                highlightedNodeFeaturesRef.current = [];
            }

            setSelectedFeatures(selectedNodeFeatures);

        });
    };


    /**
     * Handles the click event on the manage nodes map.
     * 
     * 1. On click when no entity is selected or no node is clicked
     *    - fetch routable entity
     *    - send routable entity to node editor 
     *    - node editor finds all attached nodes and returns them to be highlighted 
     * 
     * 2. On node click when an entity is selected
     *    - fetch selected node
     *    - send entity and selected node to node editor 
     *    - node editor adds/removes selected node to the entity
     *    - node editor returns new nodes to be highlighted
     *
     * @param {Event} e - The click event object.
     * @return {void} This function does not return anything.
     */
    const onManageNodesMapClick = (e) =>
    {
        // fetch entity from e 
        // on mouse move
        // - if mouse is hovering a node change cursor
        // - else set cursor to default cursor

        let { type } = e;

        if (!e.pixel)
        {
            return;
        }

        let nodeFeaturesToHighlight = [];
        let entityFeaturesToHighlight = [];

        let prevSelectedEntity = undefined;
        const selectedEntities = highlightedEntityFeaturesRef.current;

        // check if selected entity already exist
        if (Array.isArray(selectedEntities))
        {
            prevSelectedEntity = selectedEntities[0];
        }

        if (type === "click")
        {
            let selectedNodeId;
            // get entityId
            let selectedEntity = getSelectedEntityFromClick({ pointerEvent: e, olMap });
            let selectedNodeData = getNodeDataFromClick({ pixel: e.pixel, olMap });
            let selectedEntityFeature = selectedEntity?.entityFeature;
            let selectedEntityId = selectedEntity?.entityId;

            if (prevSelectedEntity && selectedNodeData && selectedNodeData.isNode && !selectedNodeData.isNodeConnection)
            {
                // TODO CHECK IF SELECTED NODE IS FORBIDDEN, DISPLAY ERROR MESSAGE
                selectedNodeId = selectedNodeData.nodeId;
                selectedEntityFeature = prevSelectedEntity;
                selectedEntityId = prevSelectedEntity.getId();
            }
            
            entityFeaturesToHighlight.push(selectedEntityFeature);

            // get attached node features
            let nodeIdsToHighlight = onManageEntityNodes(selectedEntityId, selectedNodeId);
            
            // if nodeIds to highlight is not null, this means there is a routable entity selected
            // 
            if (nodeIdsToHighlight)
            {
                for (let i = 0; i < nodeIdsToHighlight.length; i++)
                {
                    const nodeId = nodeIdsToHighlight[i];
                    const nodeFeature = getNodeFeatureWithNodeId(nodeId);
                    if (nodeFeature)
                    {
                        nodeFeaturesToHighlight.push(nodeFeature);
                    }
                }

            }

            // handles unhighlighting old nodes/entities and the highlighting of new ones
            highlightConnectedNodes(undefined, undefined, nodeFeaturesToHighlight, {isHighlightNodeLinks: false, isAddNodeToArray: false });
            highlightSelectedEntities(entityFeaturesToHighlight);
        }

        return true;

    };
    /********* END OF MAP CALLBACKS *********/

    /**
     * Removes previous interaction and adds the selected tool's interaction to node layer 
     * @param {String} toolName
     */
    const addNodeInteraction = useCallback((toolName) =>
    {
        // use draw layer? 
        if (!localEntityLayers)
        {
            return;
        }

        // remove previous interaction
        if (activeInteraction)
        {
            olMap.removeInteraction(activeInteraction);
        }

        if (activeSnapInteractions)
        {
            activeSnapInteractions.forEach((interaction) =>
            {
                olMap.removeInteraction(interaction);
            });
        }

        // if tool selected, add interaction to node layer
        if (toolName)
        {
            let { node: nodeLayer, nodeConnection: nodeConnectionLayer, [MAP_LAYERS.node_helper_layer]: nodeHelperLayer } = localEntityLayers;

            let nodeSource = nodeLayer.getSource();
            let nodeConnectionSource = nodeConnectionLayer.getSource();
            let nodeHelperLayerSource = nodeHelperLayer.getSource();

            let newActiveInteraction;

            switch (toolName)
            {
            case (MAP_EDITOR_TOOLS.Create):
            {
                newActiveInteraction = interactions[toolName](nodeSource, styleClassicNodes.node, onCreateNode);
                break;
            }
            case (MAP_EDITOR_TOOLS.Delete):
            {
                newActiveInteraction = interactions[toolName](nodeSource, onDeleteMouseEvent);
                break;
            }
            case (MAP_EDITOR_TOOLS.Edit_Node_Link):
            {
                newActiveInteraction = interactions[toolName](nodeSource, onSelectNodeLinkEvent);
                break;
            }
            case (MAP_EDITOR_TOOLS.Delete_Reconnect):
            {
                newActiveInteraction = interactions[toolName](nodeSource, onDeleteAndReconnectMouseEvent);
                break;
            }
            case (MAP_EDITOR_TOOLS.Link):
            {
                selectedNodeIdsRef.current = [];
                newActiveInteraction = interactions[toolName](nodeSource, onLinkMouseEvent);
                break;
            }
            case (MAP_EDITOR_TOOLS.AutoLink):
            {
                // create feature (ref) to represent the node connections
                let nodeLinkFeature = new Feature({
                    geometry: new LineString([]),
                    isNodeConnection: true,
                    id: "autoLink",
                });

                // create guide features
                let parallelGuideFeature = new Feature({
                    geometry: new LineString([]),
                    isNodeConnection: true,
                    id: "parallelGuideFeature",
                });

                let perpendicularGuideFeature = new Feature({
                    geometry: new LineString([]),
                    isNodeConnection: true,
                    id: "perpendicularGuideFeature",
                });

                let angleTextFeature = new Feature({
                    geometry: new Point([]),
                    id: "angleTextFeature",
                });

                // create style for guides
                const guideStyle = createEntityStyle(GUIDE_LINE_STYLE);
                parallelGuideFeature.setStyle(guideStyle);
                perpendicularGuideFeature.setStyle(guideStyle);

                // create style for text
                let textFeatureStyle = createEntityStyle(ANGLE_TEXT_FEATURE_STYLE);

                angleTextFeature.setStyle(textFeatureStyle);

                // add features to source
                nodeHelperLayerSource.addFeature(nodeLinkFeature);
                nodeHelperLayerSource.addFeature(parallelGuideFeature);
                nodeHelperLayerSource.addFeature(perpendicularGuideFeature);
                nodeHelperLayerSource.addFeature(angleTextFeature);

                autoLinkTempFeaturesRef.current = {
                    nodeLinkFeature: nodeLinkFeature,
                    parallelGuideFeature: parallelGuideFeature,
                    perpendicularGuideFeature: perpendicularGuideFeature,
                    angleTextFeature: angleTextFeature,
                };

                newActiveInteraction = interactions[toolName](nodeSource, styleClassicNodes.node, onCreateAutoLinkNode, onAutoLinkMouseMove);
                break;
            }
            case (MAP_EDITOR_TOOLS.Edit_Node):
            {
                newActiveInteraction = interactions[toolName](nodeSource, onSelectNode);
                break;
            }
            case (MAP_EDITOR_TOOLS.Manage_Entity_Nodes):
            {
                newActiveInteraction = interactions[toolName](nodeSource, onManageNodesMapClick);
                break;
            }
            }

            // snap interactions
            let nodeSnap = SnapInteraction({ source: nodeSource, pixelTolerance: SNAP_PIXEL_TOLERANCE, edge: false });
            let nodeConnectionSnap = SnapInteraction({ source: nodeConnectionSource, pixelTolerance: SNAP_PIXEL_TOLERANCE });
            let guidelineSnap = SnapInteraction({ source: nodeHelperLayerSource, pixelTolerance: SNAP_PIXEL_TOLERANCE });

            olMap.addInteraction(newActiveInteraction);
            olMap.addInteraction(nodeSnap);
            olMap.addInteraction(nodeConnectionSnap);
            olMap.addInteraction(guidelineSnap);

            setActiveSnapInteractions([nodeSnap, nodeConnectionSnap, guidelineSnap]);
            setActiveInteraction(newActiveInteraction);
        }
        else
        {
            setActiveInteraction(undefined);
        }
    }, [localEntityLayers, interactions, onCreateNode, onCreateAutoLinkNode, onAutoLinkMouseMove, activeInteraction, activeSnapInteractions, olMap]);


    /**
     * removes old layers and adds new layers to map
     * @param {*} entityLayers 
     * @param {*} imageLayers 
     * @param {*} nodeLayers 
     */
    const drawEntityLayers = (entityLayers, imageLayers, nodeLayers, idLayers) =>
    {
        if (!entityLayers)
        {
            return;
        }

        let layersToAdd = [tileLayer];
        let newLocalLayerMap = {};

        Object.keys(entityLayers).forEach((layerId) =>
        {
            if (layerId !== MapEditorConstants.HIDDEN_LAYER)
            {
                const layer = entityLayers[layerId];

                newLocalLayerMap[layerId] = layer;
            }
        });

        Object.keys(nodeLayers).forEach((layerId) =>
        {
            const layer = nodeLayers[layerId];
            newLocalLayerMap[layerId] = layer;
        });

        // add id layers to node map
        if (idLayers)
        {
            Object.keys(idLayers).forEach((layerId) =>
            {
                const layer = idLayers[layerId];
                newLocalLayerMap[layerId] = layer;
            });
        }

        const nodeHelperLayer = createVectorLayer(MAP_LAYERS.node_helper_layer);
        newLocalLayerMap[MAP_LAYERS.node_helper_layer] = nodeHelperLayer;
        layersToAdd.push(nodeHelperLayer);

        layersToAdd = layersToAdd.concat(Object.values(newLocalLayerMap));

        setMapLayers(olMap, layersToAdd);
        setLocalEntityLayers(newLocalLayerMap);
    };

    /**
     * Retrieves the node feature with the specified node ID.
     *
     * @param {string} nodeId - The ID of the node.
     * @return {ol.Feature} The node feature with the specified ID.
     */
    const getNodeFeatureWithNodeId = (nodeId) =>
    {
        const { node: nodeLayer } = localEntityLayers;
        const nodeSource = nodeLayer.getSource();

        return nodeSource.getFeatureById(nodeId);
    };

    /** UNDO REDO LOGIC **/
    /**
     * 
     * this function can be used to make changes to the map from outside the map component 
     * @param {*} step 
     */
    const handleOutsideStepAction = async (step) =>
    {
        let { node: nodeLayer, nodeConnection: nodeConnectionLayer, nodeIds: nodeIdsLayer } = localEntityLayers;

        let nodeSource = nodeLayer.getSource();
        let nodeConnectionSource = nodeConnectionLayer.getSource();
        let nodeIdsSource = nodeIdsLayer.getSource();

        const { addedNodes, deletedNodes, updatedNodesAfter, addedNodeLinks, deletedNodeLinks } = step;

        if (highlightedNodeFeaturesRef.current)
        {
            resetNodeFeatureStyles(highlightedNodeFeaturesRef.current);
        }
        if (highlightedEntityFeaturesRef.current)
        {
            resetEntityFeatureStyles(highlightedEntityFeaturesRef.current);
        }

        if (Array.isArray(addedNodes))
        {
            // create and add feature
            addedNodes.forEach((node) =>
            {
                const nodeCoordinates = node.shape.coordinates;
                const nodeId = node.nodeId;
                let geometry = createEntityGeometry(node.shape, NODE_GEOMETRY_OPTIONS);

                let feature = new Feature(
                    {
                        geometry,
                        isNode: true,
                        nodeId: nodeId
                    });
                feature.setId(node._id);

                try
                {
                    nodeSource.addFeature(feature);

                    // add node id text feature if node has nav node id
                    if (nodeId)
                    {
                        const nodeIdFeature = createNodeIdFeature(nodeId, nodeCoordinates);
                        nodeIdsSource.addFeature(nodeIdFeature);
                    }
                }
                catch (err)
                {
                    console.log("error adding undo node", node, err);
                }
            });
        }

        if (Array.isArray(deletedNodes))
        {
            // find and delete node features 
            deletedNodes.forEach((node) =>
            {
                const featureToDelete = nodeSource.getFeatureById(node._id);
                (featureToDelete) && nodeSource.removeFeature(featureToDelete);

                const nodeIdTextFeatureToDelete = nodeIdsSource.getFeatureById(node.nodeId);
                (nodeIdTextFeatureToDelete) && nodeIdsSource.removeFeature(nodeIdTextFeatureToDelete);
            });
        }

        if (Array.isArray(updatedNodesAfter))
        {
            // find and update node geometry
            updatedNodesAfter.forEach((node) =>
            {
                const featureToUpdate = nodeSource.getFeatureById(node._id);
                const nodeIdFeatureToUpdate = nodeIdsSource.getFeatureById(node.nodeId);

                let geometry = createEntityGeometry(node.shape, NODE_GEOMETRY_OPTIONS);
                (featureToUpdate) && featureToUpdate.setGeometry(geometry);

                if (nodeIdFeatureToUpdate)
                {
                    updateTextFeatureLocation(nodeIdFeatureToUpdate, featureToUpdate);
                }
            });
        }

        if (Array.isArray(deletedNodeLinks))
        {
            deletedNodeLinks.forEach((nodeLinkStepData) =>
            {
                let { nodeId1, nodeId2 } = nodeLinkStepData;

                let featureToDelete1 = nodeConnectionSource.getFeatureById(createLinkedId(nodeId1, nodeId2));
                let featureToDelete2 = nodeConnectionSource.getFeatureById(createLinkedId(nodeId2, nodeId1));

                [featureToDelete1, featureToDelete2].forEach((featureToDelete) =>
                {
                    if (featureToDelete)
                    {
                        const connectedPointerFeatures = featureToDelete.get("connectedPointerFeatures");

                        if (Array.isArray(connectedPointerFeatures))
                        {
                            connectedPointerFeatures.forEach((linkedPointerFeature) => 
                            {
                                removeFeatureIfExist(linkedPointerFeature, nodeConnectionSource);
                            });
                        }
                        nodeConnectionSource.removeFeature(featureToDelete);
                    }
                });
            });
        }

        if (Array.isArray(addedNodeLinks))
        {
            addedNodeLinks.forEach((nodeLinkStepData) =>
            {
                const featuresToAdd = createNodeLinkFeaturesFromAddedNodeLinkStepData(nodeLinkStepData);
                featuresToAdd.forEach((featureToAdd) =>
                {
                    nodeConnectionSource.addFeature(featureToAdd);
                });
            });
        }

        const prevAutoLinkedNode = onGetPrevAutoLinkedNode();
        const prevAutoLinkedNode2nd = onGetPrevAutoLinkedNode(2);

        // handle auto link temp features
        if (prevAutoLinkedNode)
        {
            let { parallelGuideFeature, perpendicularGuideFeature, nodeLinkFeature, angleTextFeature } = autoLinkTempFeaturesRef.current;

            // move guidelines if prev node exists

            if (prevAutoLinkedNode2nd)
            {
                handleDrawAutoLinkGuideLines({ prevAutoLinkedNode: prevAutoLinkedNode2nd, node: prevAutoLinkedNode });
                handleDrawAutoLinkAngleFeature({ node: prevAutoLinkedNode });
            }
            else
            {
                parallelGuideFeature.setGeometry(new Point([0, 0]));
                perpendicularGuideFeature.setGeometry(new Point([0, 0]));
                perpendicularGuideFeature.setGeometry(new Point([0, 0]));
                angleTextFeature.setGeometry(new Point([0, 0]));
            }

            if (nodeLinkFeature)
            {
                const nodeGeometry = nodeLinkFeature.getGeometry();
                const autoLinkFeatureCoordinates = nodeGeometry.getCoordinates() || nodeLinkFeature.getGeometry().getCenter();

                if (autoLinkFeatureCoordinates.length > 0)
                {
                    nodeLinkFeature.setGeometry(new LineString([wgs84ToMercator(prevAutoLinkedNode.shape.coordinates, ShapeTypes.POINT), autoLinkFeatureCoordinates[1]]));

                }
            }
        }
        else
        {
            // remove guidelines if removed the last node
            if (autoLinkTempFeaturesRef.current)
            {
                Object.values(autoLinkTempFeaturesRef.current).forEach((feature) =>
                {
                    feature.setGeometry(new Point([0, 0]));
                });
            }
        }
    };

    useEffect(() =>
    {
        const stepAction = mapEditorContext?.state?.stepAction;

        if (olMap && stepAction)
        {
            // call undo step for correct edit type
            handleOutsideStepAction(stepAction);
            mapEditorContext.handleCompleteStepAction();
        }

    }, [mapEditorContext?.state.stepAction]);

    const changeMapZoom = (zoomAmount) =>
    {
        const zoom = olMap.getView().getZoom();
        const newZoom = zoom + zoomAmount;

        if (
            newZoom >= BRANDING_MAP_CONSTANTS.MIN_ZOOM
            && newZoom <= BRANDING_MAP_CONSTANTS.MAX_ZOOM
        )
        {
            olMap.getView().animate({
                zoom: newZoom,
                duration: BRANDING_MAP_CONSTANTS.ANIMATION_DURATION,
            });
        }
    };

    const handleZoomIn = () => changeMapZoom(1);

    const handleZoomOut = () => changeMapZoom(-1);

    const renderNodeLinkPriorityModaL = useCallback(() =>
    {
        const { selectedTool } = mapEditorContext.state;

        let render = (<div></div>);
        if (selectedTool === MAP_EDITOR_TOOLS.Edit_Node_Link && selectedNodeLinks)
        {
            render = (
                <EditNodeLinkModal nodeLinks={selectedNodeLinks} onChange={handleEditNodeLink} />
            );
        }

        return render;
    }, [selectedNodeLinks, mapEditorContext.state.selectedTool]);

    const removeFeatureIfExist = (feature, source) =>
    {
        try
        {
            source.removeFeature(feature);
        }
        catch (err)
        {
            // feature not plotted
        }
    };

    // ------------------------------------------------------------- //
    // ------------------------------------------------------------- //
    // # UTILITY FUNCTIONS, COULD BE MOVED OUTSIDE OF THIS COMPONENT //
    // ------------------------------------------------------------- //
    // ------------------------------------------------------------- //

    /**
     * A function to highlight selected entities with the specified highlight style.
     *
     * @param {Array} entityFeatures - The array of entity features to highlight.
     * @param {Object} highlightStyle - The highlight style to be applied (default: DEFAULT_ERROR_HIGHLIGHT_STYLE).
     */
    const highlightSelectedEntitiesHelper = (entityFeatures, highlightStyle = DEFAULT_ERROR_HIGHLIGHT_STYLE) =>
    {
        const highlightStyleEntity = createEntityStyle(highlightStyle);
        const highlightStylePoint = createPointStyle(highlightStyle);

        entityFeatures.forEach((feature) =>
        {   
            if (!feature)
            {
                return;
            }

            let style = highlightStyleEntity;
            let geometryType = feature.getGeometry().getType();
            if (geometryType === "Point")
            {
                style = highlightStylePoint;
            }

            feature.setStyle(style);
        });
    };

    // RENDER
    return (
        <div className="map-container" ref={mapRef}>

            {/*  floor buttons*/}
            <MapEditorMapButtons onZoomIn={handleZoomIn} onZoomOut={handleZoomOut} />

            {renderNodeLinkPriorityModaL()}

        </div>
    );
};