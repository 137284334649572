import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import serverApi from "../../../_api/server.api";
import { getFilterValidationErrorsBasedOnBuildingId, getTopPriorityValidationInfoMetaHash, processValidationQuery, updateActiveSelectionItemsKeys } from "../../../_utils/ValidationInfo";
import BrandingContext from "../../../store/BrandingContext";
import { useHistory } from "react-router-dom";
import MapEditorContext from "../../../store/MapEditiorContext";
import { validationBoxInitActiveLevelKeys } from "./ValidationInfoSection";




/**
 * Generates the validation information for a given navigation property ID.
 *
 * @param {string} navPropertyId - The ID of the navigation property.
 * @param {boolean} latestUpdatesOnly - Whether to fetch only the latest updates. Defaults to true.
 * @return {object} An object containing the validation information:
 *   - validationInfoQuery: The query object for fetching the validation information.
 *   - validationStatus: The status of the validation.
 *   - preparedValidationSuccessHash: The prepared success data for validation.
 *   - preparedValidationErrorDataHash: The prepared error data hash for validation.
 *   - totalValidationErrors: The total number of validation errors.
 *   - getValidationDataIssueHashBy: A function to get the validation data issue hash.
 */
const UseValidationInfo = ({ latestUpdatesOnly = true }) =>
{
    const brandingContext = useContext(BrandingContext);
    const { validationBoxActiveLevelKeys,
        setValidationBoxActiveLevelKeys,
        isValidationBoxOpen,
        setIsValidationBoxOpen, isSideBarExpanded: isMapEditorMenuExpanded } = useContext(MapEditorContext);

    const cmsPropertyId = useMemo(() => brandingContext?.state?.propertyId, [brandingContext?.state?.propertyId]);

    const navPropertyId = useMemo(() => brandingContext?.state?.allProperties?.[cmsPropertyId]?.propertyId, [cmsPropertyId]);


    const cmsBuildingId = useMemo(() => brandingContext?.state?.buildingId ?? undefined, [brandingContext?.state?.buildingId ?? ""]);

    const cmsFloorId = useMemo(() => brandingContext?.state?.floorId, [brandingContext?.state?.floorId]);


    const navBuildingId = useMemo(() => brandingContext?.state?.allProperties[cmsPropertyId]?.allBuildings?.[cmsBuildingId]?.buildingId, [cmsBuildingId]);





    const validationInfoQuery = useQuery(["validation-info-complete", navPropertyId], async () =>
    {
        const response = await serverApi.getValidationInfo(navPropertyId, latestUpdatesOnly);

        if (response.isSuccess)
        {
            return response.results;
        }
        else
        {
            throw new Error("Backend had responded false success flag");
        }
    }, { enabled: !!navPropertyId }
    );

    const { preparedBuildingHash, validationStatus: possibleValidationStatus } = useMemo(() => processValidationQuery(validationInfoQuery), [validationInfoQuery.dataUpdatedAt]);


    const getActiveBuildingValidationErrors = useCallback(() => getFilterValidationErrorsBasedOnBuildingId(preparedBuildingHash, navBuildingId), [navBuildingId, navPropertyId, preparedBuildingHash]);

    /**
     * Determine if the error item is active.
     *
     * @param {Object} vdItem - vdItem object
     * @return {boolean} true if the error item is active, false otherwise
     */
    const isErrorItemActive = useCallback((vdItem) =>
    {
        const { cmsBuildingId: errorClickCmsBuildingId, cmsFloorId: errorClickCmsFloorId } = vdItem;

        return errorClickCmsBuildingId == cmsBuildingId && errorClickCmsFloorId == cmsFloorId;
    }, [cmsBuildingId, cmsFloorId]);



    /**
     * Handles the click event on validation error.
     *
     * @param {Event} e - The click event
     * @param {Object} vdItem - The validation error item
     *
     * updates building floor state
     */
    const onValidationErrorClick = useCallback((e, vdItem) =>
    {
        const { cmsBuildingId: errorClickCmsBuildingId, cmsFloorId: errorClickCmsFloorId, cmsPropertyId } = vdItem;
        //when user click on validation error, we will navigate to map editor
        //history.push("/branding/MapEditor");
        if (!!errorClickCmsBuildingId && !!errorClickCmsFloorId)
        {
            brandingContext.changeSelectedLevel(cmsPropertyId, errorClickCmsBuildingId, errorClickCmsFloorId);
        }
        else
        {
            if (errorClickCmsBuildingId !== cmsBuildingId)
            {
                brandingContext.changeSelectedBuilding(errorClickCmsBuildingId);
            }

        }
    }, [brandingContext.changeSelectedLevel, brandingContext.changeSelectedBuilding, cmsBuildingId]);



    /**
     * Retrieve the top priority validation information.
     *
     * @return { {} || validationITemHash} validationItemHash - The top priority validation information or undefined if there is no validation information.
     */
    const getTopPriorityValidationInfo = useCallback(() => getTopPriorityValidationInfoMetaHash(possibleValidationStatus), [possibleValidationStatus]);


    const doesHasAnyValidationInfo = useMemo(() => possibleValidationStatus.length > 0, [possibleValidationStatus]);


    useEffect(() =>
    {

        const data = Object.entries(preparedBuildingHash).sort(([, a], [, b]) => a.validationStatusMeta.priory - b.validationStatusMeta.priory);
        if (data?.[0]?.[0])
        {
            onLevelClick(null, { levelIndex: 0, levelKey: data[0][0] });
        }
        else
        {
            setValidationBoxActiveLevelKeys(validationBoxInitActiveLevelKeys);
        }

    }, [validationInfoQuery.dataUpdatedAt]);

    useEffect(() =>
    {
        setValidationBoxActiveLevelKeys(validationBoxInitActiveLevelKeys);
    }, [cmsPropertyId,]);

    const onLevelClick = useCallback((e, { levelKey, levelIndex }) =>
    {
        setValidationBoxActiveLevelKeys((prev) => updateActiveSelectionItemsKeys(prev, levelKey, levelIndex, preparedBuildingHash));
    }, [preparedBuildingHash, setValidationBoxActiveLevelKeys]);

    const checkLevelIsActive = useCallback((key) => validationBoxActiveLevelKeys.includes(key), [validationBoxActiveLevelKeys]);


    const onToggleValidationInfoBox = useCallback((e, val) =>
    {
        setIsValidationBoxOpen((prev) => val === undefined ? !prev : val);

    }, []);



    return {
        onToggleValidationInfoBox,
        onLevelClick,
        checkLevelIsActive,
        validationInfoQuery,
        preparedBuildingHash,
        onValidationErrorClick,
        possibleValidationStatus,
        getActiveBuildingValidationErrors,
        getTopPriorityValidationInfo,
        isErrorItemActive,
        doesHasAnyValidationInfo,
        cmsBuildingId,
        cmsFloorId,
        cmsPropertyId,
        validationBoxActiveLevelKeys,
        setValidationBoxActiveLevelKeys,
        isValidationBoxOpen,
        setIsValidationBoxOpen,
        isMapEditorMenuExpanded

    };
};

export default UseValidationInfo;
