import React from "react";
import { Button, Segment, Input, Header, Label } from "semantic-ui-react";

import "./EditSidebarPortal.css";
import css from "./common.module.css";
import style from "./EditSidebarPortal.module.css";


export const EditPencil = ({ onClick, isInside, isTrigger }) => (
    <Button className={`edit-icon${isInside ? " edit-icon-inside" : ""}`} onClick={onClick}>
        {
            !isTrigger && (<img src="/img/icon-pencil.svg" alt="pencil-icon" />)
        }
        {
            isTrigger && (<img src={`/img/icon-${isTrigger}.svg`} alt={""} />)
        }
    </Button>
);

export const DefaultSection = ({ heading, onClick, children }) => (
    <Segment basic className="default-section">
        {
            (heading) && (
                <Header>
                    {heading}
                    {
                        (heading) && (
                            <EditPencil onClick={onClick} />
                        )
                    }
                </Header>
            )
        }
        {children}
    </Segment>
);

export const DefaultDetailSection = ({
    title,
    subTitle,
    detail,
    isKeywords,
    placeholder,
    icon,
    children,
    success,
    borderLess,
    isEdit,
    className,
    name,
    value,
    onChange,
    maxLength,
    error
}) => (
    <div className={`${style.defaultDetail} ${isEdit ? style.defaultEdit : ""} ${isKeywords ? style.keywordsWrap : ""}  ${className ? className : ""}`}
        style={{ marginBottom: placeholder && "9px", borderBottom: borderLess && "none" }}>
        {(title) && (
            <p className={style.title}>
                {title}
                {(subTitle) && (
                    <span> ({subTitle})</span>
                )}
            </p>
        )}

        <div className={`${style.content} ${icon ? style.iconInput : ""}`}>

            {(detail) && (
                <p className={style.detail}>{detail}</p>
            )}

            {(icon) && (
                <img src={`/img/${icon}.png`} alt="" />
            )}

            {(placeholder) && (
                <Input
                    className={style.input}
                    placeholder={placeholder}
                    value={value}
                    name={name}
                    onChange={onChange}
                    maxLength={maxLength}
                    error={error}
                />
            )}

            {children}

            {(success) && (
                <img className={style.success} src="/img/icon-success.svg" alt="" />
            )}
        </div>
    </div>
);

export const KeywordList = ({ content, isEdit }) => (
    <Label className={css.keywords}>
        {content}
        {isEdit && <Button basic className={css.buttonCross}>
            <img src="/img/icon-cross-dark.svg" alt="" />
        </Button>}
    </Label>
);

export const KeyWordsDisplay = ({ options, isEdit, onRemove }) => (
    <div>
        {
            (Array.isArray(options)) && (
                options.map((option, i) => (
                    <TagItem content={option} key={option + i} isEdit={isEdit} onRemove={onRemove} />
                ))
            )
        }
    </div>
);

export const TagItem = ({ content, isEdit, onRemove }) => (
    <Label className={css.keywords}>
        {content}
        {
            (isEdit) && (
                <Button basic className={css.buttonCross} onClick={onRemove}>
                    <img src="/img/icon-cross-dark.svg" alt="" />
                </Button>
            )
        }
    </Label>
);

