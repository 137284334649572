import React from "react";
import "./CategoryDropdownItem.css";
import CategoryUses from "../categoryUses/categoryUses";
import { SINGLE_LANG_INPUT_CODE } from "../../../../_constants/constants";
import { ButtonIcon } from "../../../common/ButtonIcon";

const CategoryDropdownItem = ({
    category, onCancel = () => 
    {} 
}) => 
{
    const categoryObj = category;

    return ( <div className="categoryDropdownItem">
        {categoryObj.name[SINGLE_LANG_INPUT_CODE]} <CategoryUses category={categoryObj} />
        <ButtonIcon icon="cross-grey" onClick={() => onCancel(category)} />
    </div> );
};
 
export default CategoryDropdownItem;