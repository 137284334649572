import React from "react";
import { Button, Input } from "semantic-ui-react";
import { PaginationBar } from "../../dashboard/PaginationBar";
import { ModalLayout } from "../../popups/ModalLayout";
import StoreGroupsModalAccordion from "./StoreGroupsModalAccordion";

export const StoreGroupsModal = () => (
    <ModalLayout className="modalBox storeGroupsModal"
        trigger={<Button primary content="Store Groups" style={{ margin: "0 10px 10px 0" }}/>}
        heading="Store Groups"
        actions={<Button primary floated="right" content="Save" />}>
        <div className="aligner">
            <p className="para">Share groups composed of one or more stores. Use the list below to view your
                    Store Group or create a new group. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
        </div>
        <div className="storeModalContent">
            <div className="createGroupHeadbar">
                <Input className="searchStoreGroup" placeholder="Search and Select Groups" icon={<img src="/img/icon-search.svg" alt="" />}/>
                <Button primary content="Create Group" />
            </div>
            <b className="headingPopup">Groups Available</b>

            <StoreGroupsModalAccordion />
            <PaginationBar />
        </div>
    </ModalLayout>
);
