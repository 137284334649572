import React from "react";
import { DropdownForm } from "../../../common/DropdownForm";

import "./StyleEditorDropdown.css";

const StyleEditorDropdown = ({ 
    heading,
    options,
    onChange,
    value,
    disabled,
    className,
}) => (
    <div className={`styleEditorRow ${className ? className : ""}`}>
        {heading && <div className="styleEditorColumn">
            <p>{`${heading}:`}</p>
        </div>
        }
        <div className="styleEditorColumn styleEditorDropdownInput">
            <DropdownForm 
                // className="dropdownSelectTheme" 
                disabled={disabled}
                options={options}
                value={value}
                onChange={(e, { value }) => onChange(value)}
            />
        </div>
    </div>
);

export default StyleEditorDropdown;