export const floorPlanAcceptableFileFormats = "image/x-png,image/png,image/jpeg,.dwg,image/svg+xml,image/svg,application/pdf";
// export const acceptableImageFileFormats = "image/x-png,image/png,image/jpeg,image/svg+xml,image/svg,image/gif";
// export const acceptableLogoFileFormats = "image/x-png,image/png,image/jpeg,image/svg+xml,image/svg";
export const acceptableImageFileFormats = "image/x-png,image/png,image/jpeg,image/gif";
export const acceptableLogoFileFormats = "image/x-png,image/png,image/jpeg";

export const MAX_GALLERY_LENGTH = 9;

export const KEYWORDS = {
    MAX_NUMBER: 20,
    MAX_LENGTH: 1000
};

export const CATEGORIES = {
    MAX_NAME_LENGTH: 40
};

export const PROPERTIES = {
    MAX_NAME_LENGTH: 50,
    MAX_SHORTNAME_LENGTH: 25,
    MAX_FLOOR_SHORTNAME_LENGTH: 4,
    MAX_PHONE_NUMBER_LENGTH: 30,
    MAX_HOLIDAY_NAME_LENGTH: 30,
    MAX_GROUPNAME_LENGTH: 25,
    MAX_PIN_LENGTH: 10,
    MAX_OPACITY_LENGTH: 3
};

export const INVENTORY = {
    MAX_NAME_LENGTH: 25,
    MAX_CODE_LENGTH: 25,
    MAX_LINK_LENGTH: 2000
}
export const WEBSITE_URL_REGEX = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
