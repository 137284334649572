import { DEFAULT_LANGUAGE_CODE } from "./constants";

export const PAGE_SIZE = 10;

export const LIST_MODES = {
    VIEW: "VIEW",
    BULK_EDIT: "BULK_EDIT",
    EDIT: "EDIT",
};

export const LIST_VIEW_BASIC_FILTERATION_MODEL = {
    searchValue: "",
    languageCode: DEFAULT_LANGUAGE_CODE,
};

export const LIST_VIEW_ADVANCED_FILTERATION_MODEL = {
    languageCode: DEFAULT_LANGUAGE_CODE,
    longName: "",
    shortName: "",
    entityId: null,
    phoneNumber: "",
    website: "",
    category: "",
    subCategory: [],
    type: "",
    keywords: [],
    onlineKeywords: []
};

export const COLUMN_IDS ={
    ICON: "ICON",
    STORE_LONG_NAME: "STORE_LONG_NAME",
    STORE_SHORT_NAME: "STORE_SHORT_NAME",
    PROPERTY_ID: "PROPERTY_ID",
    BUILDING_ID: "BUILDING_ID",
    ENTITY_ID: "ENTITY_ID",
    DESCRIPTION: "DESCRIPTION",
    PHONE_NUMBER: "PHONE_NUMBER",
    CATEGORY: "CATEGORY",
    SUBCATEGORY: "SUBCATEGORY",
    ENTITY_TYPE: "ENTITY_TYPE",
    KEYWORDS: "KEYWORDS",
    WEBSITE: "WEBSITE",
    ONLINE_KEYWORDS: "ONLINE_KEYWORDS",
    FACEBOOK: "FACEBOOK",
    INSTAGRAM: "INSTAGRAM",
    TWITTER: "TWITTER",
    ICON_ROTATION: "ICON_ROTATION",
    TEXT_ROTATION: "TEXT_ROTATION",
    SHOW_LOGO: "SHOW_LOGO",
    SHOW_TEXT: "SHOW_TEXT",
    EDIT: "EDIT",
};

export const CUSTOM_INTEGRATED_EDITOR_IDS = [COLUMN_IDS.STORE_LONG_NAME, COLUMN_IDS.WEBSITE ,COLUMN_IDS.TEXT_ROTATION, COLUMN_IDS.ICON_ROTATION];



export const DEFAULT_COLUMN_IDS = [
    COLUMN_IDS.ICON,
    COLUMN_IDS.STORE_LONG_NAME,
    COLUMN_IDS.STORE_SHORT_NAME,
    COLUMN_IDS.DESCRIPTION,
    COLUMN_IDS.PHONE_NUMBER,
    COLUMN_IDS.CATEGORY,
    COLUMN_IDS.SUBCATEGORY,
    COLUMN_IDS.ENTITY_TYPE,
    COLUMN_IDS.KEYWORDS,
    COLUMN_IDS.ONLINE_KEYWORDS,
    COLUMN_IDS.ENTITY_ID,
    COLUMN_IDS.PROPERTY_ID,
    COLUMN_IDS.BUILDING_ID,
    COLUMN_IDS.EDIT
]; 

export const getColumnOptions = (trans) => ([
    {
        id: COLUMN_IDS.ICON,
        label: trans("ListViewColumns.Logo"),
        disabled: true,
    },
    {
        id: COLUMN_IDS.STORE_LONG_NAME,
        label: trans("ListViewColumns.Store_Long_Name"),
        disabled: true,
    },
    {
        id: COLUMN_IDS.STORE_SHORT_NAME,
        label: trans("ListViewColumns.Store_Short_Name")
    },
    {
        id: COLUMN_IDS.PROPERTY_ID,
        label: trans("ListViewColumns.Property_Id"),
    },
    {
        id: COLUMN_IDS.BUILDING_ID,
        label: trans("ListViewColumns.Building_Id"),
    },
    {
        id: COLUMN_IDS.ENTITY_ID,
        label: trans("ListViewColumns.Entity_Id"),
        disabled: true
    },
    {
        id: COLUMN_IDS.DESCRIPTION,
        label: trans("ListViewColumns.Description"),
    },
    {
        id: COLUMN_IDS.PHONE_NUMBER,
        label: trans("ListViewColumns.Phone_Number"),
    },
    {
        id: COLUMN_IDS.WEBSITE,
        label: trans("ListViewColumns.Website"),
    },
    {
        id: COLUMN_IDS.CATEGORY,
        label: trans("ListViewColumns.Category"),
    },
    {
        id: COLUMN_IDS.SUBCATEGORY,
        label: trans("ListViewColumns.Sub_Category"),
    },
    {
        id: COLUMN_IDS.ENTITY_TYPE,
        label: trans("ListViewColumns.Type"),
    },
    {
        id: COLUMN_IDS.KEYWORDS,
        label: trans("ListViewColumns.Keywords"),
    },
    {
        id: COLUMN_IDS.ONLINE_KEYWORDS,
        label: trans("ListViewColumns.Online_Keywords"),
    },
    {
        id: COLUMN_IDS.FACEBOOK,
        label: trans("ListViewColumns.Facebook"),
    },
    {
        id: COLUMN_IDS.INSTAGRAM,
        label: trans("ListViewColumns.Instagram"),
    },
    {
        id: COLUMN_IDS.TWITTER,
        label: trans("ListViewColumns.Twitter"),
    },
    {
        id: COLUMN_IDS.ICON_ROTATION,
        label: trans("ListViewColumns.Icon_Rotation"),
    },
    {
        id: COLUMN_IDS.TEXT_ROTATION,
        label: trans("ListViewColumns.Text_Rotation"),
    },
    {
        id: COLUMN_IDS.SHOW_LOGO,
        label: trans("ListViewColumns.Show_Logo"),
    },
    {
        id: COLUMN_IDS.SHOW_TEXT,
        label: trans("ListViewColumns.Show_Text"),
    },
    {
        id: COLUMN_IDS.EDIT,
        label: trans("ListViewColumns.Action"),
        disabled: true
    },
]);

export const ENITITY_LABEL_UPDATE_FIELDS = [
    "longName", 
    "shortName",
    "description",
    "phoneNumber",
    "phoneNumbers",
    "website",
    "category",
    "subCategories",
    "keywords",
    "socialMedia",
    "onlineKeywords",
];

export const ENTITY_UPDATE_FIELDS = [
    "textRotation",
    "imageRotation",
    "style",
];

export const LIST_VIEW_COLUMNS_STORAGE_KEY = "list-view-columns";