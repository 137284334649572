import React from "react";
import { SubheaderMaintenance } from "../components/SubheaderMaintenance";
import ZonesProvider from "../../../store/ZonesContext";
import ZonesMap from "./zonesMap/ZonesMap";
import { PublicMapDataProvider } from "../../../store/PublicMapDataContext";

function Zones() 
{
    return (
        <PublicMapDataProvider>
            <ZonesProvider>
                <SubheaderMaintenance />
                <ZonesMap />
            </ZonesProvider>
        </PublicMapDataProvider>
    );
}

export default Zones;
