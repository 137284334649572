import React, { createContext, useContext, useState } from "react";
import serverApi from "../../../../../_api/server.api";
import { DEFAULT_HIDDEN_MODALS, DEFAULT_IMAGES_SEARCH_AND_FILTERS, DELETE_IMAGE_STRATEGY_ACTIONS, IMAGE_ACTION_MODES } from "../../constant";
import { useQueryClient } from "react-query";
import {  DEFAULT_TABS, GmsActionMode, TAB_MENU_ITEMS, useGmsContext } from "../../GmsContext";
import { useImagesBaseContext } from "./ImageBaseContext";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { userHasImageEditDeleteAccess } from "../../utils";

/**
 * Context for managing image deletion functionality
 * @type {React.Context<ImageDeleteContextValue>}
 */
const ImageDeleteContext = createContext();

/**
 * Custom hook to access the Image Delete context
 * @returns {ImageDeleteContextValue} The image delete context value
 * @throws {Error} When used outside of ImageDeleteProvider
 */
export const useImageDeleteContext = () => useContext(ImageDeleteContext);

const DEFAULT_DELETE_IMAGE_STRATEGY =
    DELETE_IMAGE_STRATEGY_ACTIONS.DELETE_IMAGE_AND_UNLINK_ENTITIES;

/**
 * Provider component for image deletion functionality
 * @param {Object} props
 * @param {React.ReactNode} props.children - Child components
 * @returns {JSX.Element} Provider component
 */
export function ImageDeleteProvider({ children, onDeleteImage }) 
{
    const gmsContext = useGmsContext();
    const { loadingPool } = useGmsContext();
    const imagesBaseContext = useImagesBaseContext();
    const trans = useTranslation().t;
    const [selectedDeleteImageStrategy, setSelectedDeleteImageStrategy] = useState(DEFAULT_DELETE_IMAGE_STRATEGY);
    const queryClient = useQueryClient();

    function handleChangeSelectedDeleteImageStrategy(value) 
    {
        setSelectedDeleteImageStrategy(value);
        if (value === DELETE_IMAGE_STRATEGY_ACTIONS.DELETE_IMAGE_AND_UNLINK_ENTITIES)
        {
            imagesBaseContext.setImagesSelectedForLinking([]);
        }

        if (value === DELETE_IMAGE_STRATEGY_ACTIONS.CHOOSE_NEW_IMAGE_AND_DELETE_OLD_ONE) 
        {
            gmsContext.handleTriggerReplaceImage();
            imagesBaseContext.setHideModals((prev) => ({
                ...DEFAULT_HIDDEN_MODALS,
                imageDeleteModalWithLinkedEntities: true,
            }));
            imagesBaseContext.setPrevImagesSearchAndFilters(imagesBaseContext.imagesSearchAndFilters);
            imagesBaseContext.setImagesSearchAndFilters({ ...imagesBaseContext.imagesSearchAndFilters, filters: { ...imagesBaseContext.imagesSearchAndFilters.filters, ratio: imagesBaseContext.selectedImageInfo.aspectRatio } });
        }
    }

    async function handleDeleteImage()
    {
        const loadingId = loadingPool.add();

        try 
        {
            await serverApi.deleteImageReference(imagesBaseContext.selectedImageInfo._id);
            await imagesBaseContext.imagesQuery.refetch();
            handleCloseImageDeleteConfirmationModal();
            toast.success(trans("gms.image_deleted"));
        }
        catch (err)
        {
            console.log(err);
            toast.error(err.message || trans("gms.image_delete_err"));
        }
        finally 
        {
            loadingPool.remove(loadingId);
        }
    }

    async function handleSoftDeleteImage() 
    {
        const loadingId = loadingPool.add();
        try 
        {
            const payload = {
                deleteStrategy: selectedDeleteImageStrategy,
            };

            if (selectedDeleteImageStrategy === DELETE_IMAGE_STRATEGY_ACTIONS.CHOOSE_NEW_IMAGE_AND_DELETE_OLD_ONE) 
            {
                const imageSelectedForLinkingInfo = imagesBaseContext.imagesData.filter((image) => imagesBaseContext.imagesSelectedForLinking.includes(image._id))?.[0];
                payload.newImageId = imageSelectedForLinkingInfo._id;
            }
            await serverApi.softDeleteImage(imagesBaseContext.selectedImageInfo?._id, payload);
            await imagesBaseContext.imagesQuery.refetch();
            if (onDeleteImage) await onDeleteImage();        
            handleCloseImageDeleteModalWithLinkedEntities();
            gmsContext.setGmsActionMode(GmsActionMode.VIEW);
            if (selectedDeleteImageStrategy === DELETE_IMAGE_STRATEGY_ACTIONS.CHOOSE_NEW_IMAGE_AND_DELETE_OLD_ONE)
            {
                toast.success(trans("gms.image_succesfully_linked"));
            }
            else 
            {
                toast.success(trans("gms.image_deleted"));
            }
        }
        catch (err) 
        {
            console.log(err);
            toast.error(err.message || trans("gms.image_delete_err"));
        }
        finally 
        {
            loadingPool.remove(loadingId);
        }
    }

    async function handleDeleteImageWithLinkedEntities(id) 
    {
        const loadingId = loadingPool.add();

        try 
        {
            const hasAccess =  await userHasImageEditDeleteAccess({ imageId: id, queryClient });
            if (!hasAccess) 
            {
                toast.error(trans("gms.No_acces_to_delete"));
                return;
            }
            imagesBaseContext.setImageActionMode(IMAGE_ACTION_MODES.DELETE_WITH_LINKED_ENTITIES);
            imagesBaseContext.setHideModals({
                ...DEFAULT_HIDDEN_MODALS,
                gmsModal: true,
            });
            imagesBaseContext.setSelectedImageId(id);
        }
        catch (err) 
        {
            console.error(err);
            toast.error(err.message);
        }
        finally 
        {
            loadingPool.remove(loadingId); 
        }
     
    }

    function handleDeleteImageWithNoLinkedEntities(id) 
    {
        imagesBaseContext.setImageActionMode(IMAGE_ACTION_MODES.DELETE_WITH_NO_LINKED_ENTITIES);
        imagesBaseContext.setSelectedImageId(id);
    }

    function handleCloseImageDeleteModalWithLinkedEntities() 
    {
        const tabIdx = DEFAULT_TABS.findIndex((tab) => tab.menuItem === TAB_MENU_ITEMS.IMAGES);
        setSelectedDeleteImageStrategy(DEFAULT_DELETE_IMAGE_STRATEGY);
        gmsContext.setTabs(DEFAULT_TABS);
        gmsContext.setGmsActionMode(GmsActionMode.VIEW);
        gmsContext.setActiveIndex(tabIdx);
        imagesBaseContext.setImageActionMode(null);
        imagesBaseContext.setSelectedImageId(null);
        imagesBaseContext.setImagesSelectedForLinking([]);
        imagesBaseContext.setHideModals(DEFAULT_HIDDEN_MODALS);
        imagesBaseContext.setPrevImagesSearchAndFilters(DEFAULT_IMAGES_SEARCH_AND_FILTERS);
        imagesBaseContext.setImagesSearchAndFilters(imagesBaseContext.prevImagesSearchAndFilters);    
    }

    function handleChooseNewImageBasedOnDeleteStrategy() 
    {
        imagesBaseContext.setHideModals((prev) => ({
            ...DEFAULT_HIDDEN_MODALS,
            gmsModal: true,
        }));
    }

    function handleCancelChooseNewImageBasedOnDeleteStrategy()
    {
        imagesBaseContext.setHideModals((prev) => ({
            ...DEFAULT_HIDDEN_MODALS,
            gmsModal: true,
        }));
        imagesBaseContext.setImagesSelectedForLinking([]);
    }

    function handleCloseImageDeleteConfirmationModal() 
    {
        imagesBaseContext.setImageActionMode(null);
        imagesBaseContext.setSelectedImageId(null);
    }

    function handleTriggerDeleteImage(id)
    {
        const imageInfo = imagesBaseContext.imagesData.find((image) => image._id === id);

        if (imageInfo?.usedIn?.length)
        {
            const isBeingUsedAsFloorPlanImage = imageInfo.usedIn.find((v) => v.usedAs === "floorPlanImage");
            if (isBeingUsedAsFloorPlanImage) 
            {
                toast.error(trans("gms.Action cannot be on floor_plan_img"));
                return;     
            }
            handleDeleteImageWithLinkedEntities(id);
        }
        else 
        {
            handleDeleteImageWithNoLinkedEntities(id);
        }
    }

    const value = {
        selectedDeleteImageStrategy,
        handleDeleteImageWithLinkedEntities,
        handleDeleteImageWithNoLinkedEntities,
        handleChangeSelectedDeleteImageStrategy,
        handleChooseNewImageBasedOnDeleteStrategy,
        handleCloseImageDeleteConfirmationModal,
        handleCancelChooseNewImageBasedOnDeleteStrategy,
        handleSoftDeleteImage,
        handleTriggerDeleteImage,
        handleDeleteImage,
        handleCloseImageDeleteModalWithLinkedEntities,
    };

    return <ImageDeleteContext.Provider value={value}>{children}</ImageDeleteContext.Provider>;
}


/**
 * @typedef {Object} ImageDeleteContextValue
 * @property {string} selectedDeleteImageStrategy - Currently selected deletion strategy
 * @property {function(string): void} handleDeleteImageWithLinkedEntities - Handle deletion of image with linked entities
 * @property {function(string): void} handleDeleteImageWithNoLinkedEntities - Handle deletion of image without linked entities
 * @property {function(string): void} handleChangeSelectedDeleteImageStrategy - Update the selected deletion strategy
 * @property {function(): void} handleChooseNewImageBasedOnDeleteStrategy - Open modal for choosing new image
 * @property {function(): void} handleCloseImageDeleteConfirmationModal - Close delete confirmation modal
 * @property {function(): Promise<void>} handleSoftDeleteImage - Perform soft deletion of selected image
 * @property {function(): void} handleCloseImageDeleteModalWithLinkedEntities - Close delete modal with linked entities
 */