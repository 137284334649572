import React from "react";
import PropTypes from "prop-types";
import { InputFormGroup } from "../elements/InputFormGroup";
import { useTranslation } from "react-i18next";
import { SINGLE_LANG_INPUT_CODE } from "../../_constants/constants";

export const BuildingEditBox = ({ building, buildingTypes, validationErrors, onChange }) =>
{
    /** @type {typeof import("../../_intl/resources.json")["en"]["translation"]["building edit box"]} */
    const trans = useTranslation().t("building edit box");
    return (
        <>
            <InputFormGroup label={trans["name label"]} placeholder={trans["name placeholder"]} error={validationErrors[`longName.${SINGLE_LANG_INPUT_CODE}`]}
                value={building.longName?.en} onChange={onChange} name={`longName.${SINGLE_LANG_INPUT_CODE}`}
                extraLabel={trans["name extra label"]} />

            <InputFormGroup label={trans["address label"]} placeholder={trans["address placeholder"]}
                value={building.fullAddress} onChange={onChange} name="fullAddress" error={validationErrors["fullAddress"]}
                extraLabel={trans["address extra label"]} />

            <InputFormGroup label={trans["building label"]} list={buildingTypes} error={validationErrors["type"]}
                listValue={building.type} onChange={onChange} name="type" scrolling search
                extraLabel={trans["building extra label"]} />

            <InputFormGroup label={trans["website label"]} placeholder={trans["website placeholder"]} error={validationErrors[`website.${SINGLE_LANG_INPUT_CODE}`]}
                value={building.website?.en} onChange={onChange} name={`website.${SINGLE_LANG_INPUT_CODE}`}
                extraLabel={trans["website extra label"]} />

            <InputFormGroup label={trans["phone label"]} placeholder={trans["phone placeholder"]} error={validationErrors["phoneCheck"]}
                value={building.phoneNumbers} onChange={onChange} name="phoneNumbers"
                extraLabel={trans["phone extra label"]} />
        </>
    );
};

BuildingEditBox.displayName = "BuildingEditBox";

BuildingEditBox.propTypes = {
    building: PropTypes.object.isRequired,
    buildingTypes: PropTypes.array.isRequired,
    validationErrors: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
};
