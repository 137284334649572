import React, { useContext, useMemo, useState } from "react";
import { PublicMapDataContext } from "../../../../store/PublicMapDataContext";
import cloneDeep from "lodash.clonedeep";
import usePublicDataMap from "../../../../hooks/usePublicDataMap";
import ZoneMapButtons from "./ZoneMapButtons";
import useZoneMapHandler from "../../../../hooks/useZoneMapHandler";
import ZoneNameMarkers from "../zoneNameMarkers/ZoneNameMarkers";
import { createEntityGeometry } from "mapsted.maps/mapFunctions/plotting";
import { changeMapCenterWithBoundaryPolygon } from "mapsted.maps/mapFunctions/interactions";
import { ZONES_MAP_CONSTANTS } from "../../../../_constants/zones";
import usePolygonHighlight from "../../../../hooks/usePolygonHighlight";
import { DEFAULT_LANGUAGE_CODE } from "mapsted.maps/utils/map.constants";

function ZonesMap() 
{
    const publicMapDataCtx = useContext(PublicMapDataContext);

    const { mapData, floorId, buildingId, propertySettings } = publicMapDataCtx.state;

    // used to highlight polygon on map
    const [focusAreaShape, setFocusAreaShape] = useState();

    const derivedMapData = useMemo (() =>
    {
        let derivedMapData = {
            entities: [],
            style: {
                layerStyles: []
            }
        };

        if (mapData?.entities)
        {
            if (floorId)
            {
                derivedMapData.entities = mapData.entities.filter((entity) => entity.floorId === floorId);
            }
            else
            {
                derivedMapData.entities = mapData.entities;
            }
        }

        if (mapData?.styles)
        {
            if (buildingId)
            {
                derivedMapData.style = cloneDeep(mapData.styles.building);
            }
            else
            {
                derivedMapData.style = cloneDeep(mapData.styles.property);
            }
        }

        return derivedMapData;

    }, [mapData?.entities, mapData?.styles, buildingId, floorId]);

    const rotation = useMemo(() =>
    {
        let rotation = propertySettings?.mapRotation?.[DEFAULT_LANGUAGE_CODE] || 0;

        if (buildingId && mapData?.building?.mapSettings?.mapRotation?.[DEFAULT_LANGUAGE_CODE])
        {
            rotation = mapData.building.mapSettings.mapRotation[DEFAULT_LANGUAGE_CODE];
        }

        return rotation;

    }, [buildingId, mapData?.building, propertySettings]);


    const { olMap, mapRef } = usePublicDataMap({
        mapData: derivedMapData,
        options: {
            mapOptions: {  
                rotation: rotation,
                controlsOptions: { rotate: false } 
            }
        } 
    });

    useZoneMapHandler({
        olMap,
    });

    usePolygonHighlight({
        olMap: olMap,
        polygonShape: focusAreaShape,
        onHighlightComplete: () => setFocusAreaShape()
    });

    const centerOnShape = (shape) =>
    {
        if (shape && olMap)
        {
            const geometry = createEntityGeometry(shape);

            changeMapCenterWithBoundaryPolygon({
                olMap: olMap,
                boundaryPolygon: geometry,
                padding: ZONES_MAP_CONSTANTS.FIT_PADDING_MAP_DATA,
            });
        }
    };

    const onHighlightShape = (shape) =>
    {
        setFocusAreaShape(shape);
        centerOnShape(shape);
    };

    return (
        <div className="maintenance-map">
            <div className="map-container" ref={mapRef}>
                <ZoneMapButtons olMap={olMap} onHighlightShape={onHighlightShape} />
                <ZoneNameMarkers olMap={olMap}/>
            </div>
        </div>
    );
}

export default ZonesMap;