import React from "react";
import { ModalBox } from "../../common/ModalBox";
import { ButtonIcon } from "../../common/ButtonIcon";
import { Button } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

const DynamicMapLayerCancelConfirmationModal = ({ onCancelClick, isCreate=true }) =>
{
    const trans = useTranslation().t;

    return (
        <ModalBox className="confirmActionModal"
            trigger={<ButtonIcon icon="cross" className="dynamicMapLayercancelButton"/>}
            header={trans("Cancel_Confirmation_Modal.Confirm_Action")}
            actions={<Button color="orange" floated="right" content={trans("Cancel_Confirmation_Modal.Cancel")} onClick={onCancelClick} />}>
            <p className="p-modal">
                {isCreate ? trans("Cancel_Confirmation_Modal.Dynamic_Map_Layer_Create_Cancel") : trans("Cancel_Confirmation_Modal.Dynamic_Map_Layer_Update_Cancel")}
            </p>
        </ModalBox>
    );
};

export default DynamicMapLayerCancelConfirmationModal;