import React from "react";
import { Segment, Label } from "semantic-ui-react";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import PropTypes from 'prop-types';

import "./GoogleLookup.css";
import serverApi from "../../../_api/server.api";
import { Loader } from "../loader";
import { withTranslation } from "react-i18next";

function SuggestionInput (props)
{
    const { inputProps, label, placeholder, extraLabel, error } = props;

    return (
        <Segment basic className="form-group">
            <p>{label}</p>
            <div className="content">
                <div className={"ui input" + (!error ? "" : " error")}>
                    <input {...inputProps({ placeholder })} autoComplete="off" autoCorrect="off" autoCapitalize="none" spellCheck={false} />
                </div>
            </div>
            {!!error &&
                <Label basic color='red' pointing>
                    {error}
                </Label>
            }
            <span className="extra-label">{extraLabel}</span>
        </Segment>
    );
}

function SuggestionItem ({ itemProps, suggestion })
{
    const className = `suggestion-item ${suggestion.active ? "active" : "inactive"}`;

    return (
        <div {...itemProps(suggestion, { className })} >
            <span>{suggestion.description}</span>
        </div>
    );
}

class GoogleLookup extends React.Component
{
    state = { address: '', loading: false };

    componentDidMount ()
    {
        this.setState({ address: this.props.value });
    }

    componentDidUpdate (prevProps)
    {
        if (this.props.value && prevProps.value !== this.props.value)
        {
            this.setState({ address: this.props.value });
        }
    }

    handleChange = address => 
    {
        this.setState({ address });
    }

    handleSelect = address => 
    {
        this.setState({ loading: true });
        geocodeByAddress(address)
            .then(results => 
            {
                const result0 = results[0];
                return Promise.all([address, getLatLng(result0), serverApi.getGoogleLookoutPlace(result0.place_id)]);
            })
            .then(([address, latLng, place]) =>
            {
                this.setState({ loading: false });

                const timeZone = serverApi.getGoogleTimeZone(latLng);

                console.log({ timeZone, address, latLng, place })
                return Promise.all([timeZone, address, latLng, place]);

            })
            .then(([timeZone, address, latLng, place]) =>
            {
                const { timeZoneId: id, timeZoneName: name } = timeZone;
                this.props.onChange({
                    address,
                    coordinates: [latLng.lng, latLng.lat],
                    place,
                    timeZone: { id, name }
                });
            })
            .catch(error => 
            {
                this.setState({ loading: false });
                console.error('Error', error);
                this.onError();
            });
    }

    onError = (status, clearSuggestions) => 
    {
        this.props.onError && this.props.onError();
        console.error('Google Maps API returned error with status: ', status)
    }

    renderContent = ({ getInputProps, suggestions, getSuggestionItemProps, loading }) =>
    {
        const { label, placeholder, extraLabel, value, error, t: trans } = this.props;

        return (
            <div className="lookupcover">
                <SuggestionInput inputProps={getInputProps}
                    value={value} label={label} placeholder={placeholder}
                    extraLabel={extraLabel} error={error} />
                {
                    suggestions.length > 0 &&
                    <div className="autocomplete-dropdown-container">
                        {
                            loading && <div>{trans("GoogleLookup.Please_wait_feching_the_data___")}</div>
                        }
                        {
                            suggestions.map((suggestion, index) => <SuggestionItem key={index} itemProps={getSuggestionItemProps} suggestion={suggestion} />)
                        }
                    </div>
                }
            </div>
        )
    }

    render ()
    {
        const { loading, address } = this.state;
        const { onError } = this.props;

        return (
            <>
                <PlacesAutocomplete highlightFirstSuggestion
                    value={address}
                    onError={onError}
                    onChange={this.handleChange}
                    onSelect={this.handleSelect}
                    shouldFetchSuggestions={address.length >= 3} >

                    {this.renderContent}

                </PlacesAutocomplete>
                <Loader active={loading} />
            </>
        );
    }
}

export default withTranslation()(GoogleLookup);

GoogleLookup.propTypes = {
    value: PropTypes.string,
    error: PropTypes.string,
    label: PropTypes.string,
    extraLabel: PropTypes.string,
    placeholder: PropTypes.string,
    onError: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired
}