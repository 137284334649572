import React from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Button, Image, Popup } from "semantic-ui-react";

export const GroupCategoriesSidebar = () =>
{
    return (
        <div className="groupCategoriesSidebar">
            <Scrollbars autoHeight autoHeightMin={`calc(1vh)`} autoHeightMax={`calc(100vh - 225px)`} className="scrollMallGroup">
                <div className="groupCategoriesCover">
                    <GroupCategoryItem content="Shopping" hasSub isOpen syncStatus={<SyncToolTip synced />}>
                        <GroupCategoryItem content="Accessories" syncStatus={<SyncToolTip synced />} />

                        <GroupCategoryItem content="Apparel" hasSub isOpen syncStatus={<SyncToolTip />}>
                            <GroupCategoryItem content="Children’s Apparel & Maternity Maternity Maternity" />
                            <GroupCategoryItem content="Men’s Apparel" />
                            <GroupCategoryItem hasSub content="Women's Apparel" />
                            <GroupCategoryItem content="Unisex Apparel" />
                        </GroupCategoryItem>

                    </GroupCategoryItem>

                    <GroupCategoryItem content="Department Stores" />
                    <GroupCategoryItem content="Books & Gifts" />
                    <GroupCategoryItem content="Convenience" />
                    <GroupCategoryItem content="Electronics & Tech" />
                    <GroupCategoryItem content="Eyewear & Optical" />
                    <GroupCategoryItem content="Health & Beauty" />
                    <GroupCategoryItem content="Electronics & Tech" />
                    <GroupCategoryItem content="Eyewear & Optical" />
                    <GroupCategoryItem content="Health & Beauty" />
                </div>
            </Scrollbars>
        </div>
    );
}


const GroupCategoryItem = ({ hasSub, isOpen, content, children, syncStatus }) =>
{
    return (
        <>
            <Button className={`buttonGroupCategory${hasSub ? " hasSub" : ""}${isOpen ? " isOpen" : ""}`}>
                <span className="titleContent">{content}</span>
                {syncStatus && syncStatus}

                <Image as="span" src="/img/example-icon-group-category.svg" />
            </Button>
            {
                children && (<div className="groupCategorySection">
                    {children}
                </div>)
            }
        </>
    );
}

const SyncToolTip = ({ synced }) =>
{
    return (
        <Popup className="syncTooltipPopup"
            size="small"
            position="top center"
            trigger={<span className={`syncStatus${synced ? " synced" : ""}`}></span>}
            content={synced ? "Synced" : "Content needed"} />
    );
}