import React, { FC } from "react";
import { Checkbox as SemanticCheckbox, CheckboxProps as SemanticCheckProps } from "semantic-ui-react";

export interface CheckboxProps extends SemanticCheckProps {
    className?: string,
    reduce?: boolean,
}

const Checkbox : FC<CheckboxProps> = ({
    className = "",
    reduce = false,
    ...rest
}) => (
    <SemanticCheckbox
        className={`checkboxGroup ${reduce ? "reduced" : ""} ${className}`}
        {...rest}
    />
);

export default Checkbox;
