import React, { useContext, useEffect } from "react";
import { useSetState } from "ahooks";
import Scrollbars from "react-custom-scrollbars-2";
import { Button, Header, Input } from "semantic-ui-react";
import { DynamicMapLayersContext } from "../../../../store/DynamicMapLayersContext";
import { DYNAMIC_MAP_LAYERS_MODES, DYNAMIC_MAP_LAYER_TEMPLATE_NAME } from "../../../../_constants/constants";
import { ButtonIcon } from "../../../common/ButtonIcon";
import { DividerLine } from "../../../common/DividerLine";
import { TextGroup } from "../../../common/TextGroup";
import { ErrorLabel } from "../../../elements/ErrorLabel";
import DynamicMapLayerCancelConfirmationModal from "../DynamicMapLayerCancelConfirmationModal";
import { validateMultiCreation } from "../utils/dynamicMapLayerValidations";
import FileUploader from "./FileUploader";
import DynamicMapLayerItem from "./DynamicMapLayerItem";
import { downloadFile, downloadMultiCreationFile, FILE_COLUMNS } from "../utils/fileProcessing";
import { Trans, useTranslation } from "react-i18next";
import LinkButton from "../../../common/LinkButton";

const MultiCreationSidebar = () =>
{
    const dynamicMapLayerCtx = useContext(DynamicMapLayersContext);

    const trans = useTranslation().t;

    const {
        state: ctxState,
        updateActiveMultiCreation,
        createMultipleClickHandler,
        validateAndParseMultiCreationFile,
        saveMultiCreationDataRows,
        clearMultiCreation,
        updateSelectedDynamicMapLayer,
        checkIfModifiedInCreationMode,
        cancelMultiCreationHandler,
    } = dynamicMapLayerCtx;
    const { mode, activeMultiCreation, navPublicId, selectedDynamicMapLayer } = ctxState;

    const [state, setState] = useSetState({
        errors: []
    });

    useEffect(() =>
    {
        setState({
            errors: []
        });
    }, [mode]);

    const updateField = (key, value) =>
    {
        const updatedActiveMultiCreation = { ...activeMultiCreation };
        updatedActiveMultiCreation[key] = value;
        updateActiveMultiCreation(updatedActiveMultiCreation);
        setState({
            errors: []
        });
    };

    const fileChangeHandler = async (files) =>
    {
        const file = files[0];
        const { errors, dataRows } = await validateAndParseMultiCreationFile(file);
        if (errors.length)
        {
            clearMultiCreation();
            setState({
                errors
            });
        }
        else
        {
            saveMultiCreationDataRows(dataRows, file);
            setState({
                errors: []
            });
        }
    };

    const finishClickHandler = () =>
    {
        const validationErrors = validateMultiCreation(activeMultiCreation);
        if (validationErrors.length)
        {
            setState({
                errors: validationErrors
            });
        }
        else
        {
            createMultipleClickHandler();
        }
    };

    const downloadTemplateHandler = () =>
    {
        const fileName = `${DYNAMIC_MAP_LAYER_TEMPLATE_NAME}.csv`;
        const blob = new Blob([Object.values(FILE_COLUMNS).join(",")], { type: "text/csv;charset=utf-8;" });
        downloadFile(fileName, blob,);
    };

    const deleteFileHandler = () =>
    {
        clearMultiCreation();
        setState({
            errors: []
        });
    };

    const downloadFileHandler = () =>
    {
        downloadMultiCreationFile(activeMultiCreation.dynamicMapLayers, activeMultiCreation.mapOverlays, navPublicId, activeMultiCreation.file.name);
    };

    const fileUploadErrorHander = (errors) =>
    {
        setState({ errors });
        clearMultiCreation();
    };

    const selectionHandler = (dynamicMapLayerId, automationRuleId) =>
    {
        if (dynamicMapLayerId === selectedDynamicMapLayer.dynamicMapLayerId
            && automationRuleId === selectedDynamicMapLayer.automationRuleId)
        {
            updateSelectedDynamicMapLayer(undefined, undefined, undefined);
        }
        else
        {
            updateSelectedDynamicMapLayer(dynamicMapLayerId, automationRuleId, undefined);
        }
    };

    if (mode !== DYNAMIC_MAP_LAYERS_MODES.MULTI_CREATION)
    {
        return null;
    }

    const disableFinishButton = !(activeMultiCreation.configurationName && activeMultiCreation.dynamicMapLayers.length);

    const enableCancelConfirmationModal = checkIfModifiedInCreationMode();

    return (
        <div className="sidebarPortal dynamicMapLayerSidebar">
            <Scrollbars autoHeight autoHeightMin="calc(100vh - 170px)" autoHeightMax={"calc(100vh - 170px)"}>
                <div className="alignerSidebar">
                    <Header as="h2">
                        <span className="creationTabHeading">{trans("MaintenanceCreateDynamicMapSidebar.Dynamic_Map_Layer")}</span>
                        {enableCancelConfirmationModal ? <DynamicMapLayerCancelConfirmationModal
                            onCancelClick={cancelMultiCreationHandler}
                        /> : <ButtonIcon icon="cross" className="dynamicMapLayercancelButton" onClick={cancelMultiCreationHandler} />}
                    </Header>
                    <div className="sidebarSection">
                        <p className="mapOverlaySidebarTabInfo">
                            {trans("MaintenanceCreateDynamicMapSidebar.Dynamic_Map_Layer_Description")}
                        </p>
                    </div>
                    <div className="sidebarSection">
                        <TextGroup heading={trans("MaintenanceDynamicLayerSidebar.Map_Layer_Configuration")}>
                            <Input
                                placeholder={trans("MaintenanceDynamicLayerSidebar.Type_Configuration_Name_Here")}
                                value={activeMultiCreation.configurationName}
                                onChange={(e, { value }) => updateField("configurationName", value)} />
                        </TextGroup>
                    </div>
                    <div className="sidebarSection">
                        <TextGroup className="headingTextGroup" heading={trans("MaintenanceDynamicLayerSidebar.MULTIPLE_MAP_LAYERS")}/>
                        <p className="paraDynamic">
                            <Trans 
                                i18nKey="MaintenanceDynamicLayerSidebar.Multiple_Map_Layer_Msg" 
                                components={{ templateLink: <LinkButton onClick={downloadTemplateHandler} /> }}
                            />
                            
                        </p>

                        <DividerLine isEmpty gap="5px 0" />
                        <TextGroup heading={trans("MaintenanceDynamicLayerSidebar.Upload_CSV")}>
                            {
                                activeMultiCreation.file ? <div className="uploadedFiles">
                                    <p className="uploadedName">{activeMultiCreation.file.name}</p>
                                    <div className="uploadedActions">
                                        {/* {activeMultiCreation.dynamicMapLayers.length} recordings uploaded */}
                                        <ButtonIcon icon="download-blue" onClick={downloadFileHandler} />
                                        <ButtonIcon icon="trash-bin" onClick={deleteFileHandler} />
                                    </div>
                                </div> : <FileUploader
                                    onChange={fileChangeHandler}
                                    onError={fileUploadErrorHander}
                                />
                            }
                        </TextGroup>
                    </div>
                    {!!state.errors.length && <div className="sidebarSection">
                        <ErrorLabel multiple={Object.keys(state.errors).length > 0}>
                            <ul>
                                {state.errors.map((error) => 
                                {
                                    if (typeof error === "string") 
                                    {
                                        return <li key={error}>{trans(error)}</li>;
                                    }
        
                                    const { errorKey, dynamicKey } = error;

                                    if (dynamicKey && error[dynamicKey]) 
                                    {
                                        return <li key={errorKey}>{trans(errorKey, { [dynamicKey]: error[dynamicKey] })}</li>;
                                    }

                                    return <li key={errorKey}>{trans(errorKey)}</li>;
                                })}
                            </ul>
                        </ErrorLabel>
                    </div>}
                    <div className="sidebarSection">
                        {!!activeMultiCreation.dynamicMapLayers.length
                            && <>
                                <div className="uploadedActions">{activeMultiCreation.dynamicMapLayers.length} recordings uploaded</div>
                                <div className="multiCreationSidebar">
                                    {
                                        activeMultiCreation.dynamicMapLayers.map((dynamicMapLayer) => (
                                            <DynamicMapLayerItem
                                                key={dynamicMapLayer._id}
                                                dynamicMapLayer={dynamicMapLayer}
                                                onSelect={selectionHandler}
                                                selectedDynamicMapLayer={selectedDynamicMapLayer}
                                            />
                                        ))
                                    }
                                </div>
                            </>}
                    </div>
                </div>
            </Scrollbars>
            <Button
                color="orange"
                className="saveButton"
                content={trans("MaintenanceCreateDynamicMapSidebar.Finish")}
                disabled={disableFinishButton}
                onClick={finishClickHandler} />
        </div>
    );
};

export default MultiCreationSidebar;