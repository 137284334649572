import React, { useState, useEffect, useContext } from "react";
import { Button, Image } from "semantic-ui-react";
import { ModalBox } from "../../common/ModalBox";
import { CheckboxGroup } from "../../common/CheckboxGroup";
import { filerUrl } from "../../../_utils/utils";
import { Trans } from "react-i18next";
import BrandingContext from "../../../store/BrandingContext";
import { ErrorLabel } from "../../elements/ErrorLabel";
import { arrayToHash } from "mapsted.utils/arrays";
import { useTranslation } from "react-i18next";

import "./CopyVenueInfo.css";

const copyStoreInfoOptions = [
    {
        key: "logoAndImage",
        label: "Logo and Images"
    },
    {
        key: "openingHours",
        label: "Opening Hours"
    },
    {
        key: "basicInformation",
        label: "Basic Information"
    },
    {
        key: "socialLinks",
        label: "Social Links"
    },
    {
        key: "keywords",
        label: "Keywords"
    },
    {
        key: "categories",
        label: "Categories"
    }
];

export const CopyVenueInfo = ({
    open,
    onClose,
    relatedStores,
    entityLabelName,
    entityLabelId,
    propertyId,
    onDone
}) =>
{

    const brandingCtx = useContext(BrandingContext);

    const [storesInfo, setStoresInfo] = useState({});
    const [error, setError] = useState(false);
    const trans = useTranslation().t;

    useEffect(() =>
    {
        initializeStoresInfo();
        setError(false);
    }, [open, relatedStores]);

    const initializeStoresInfo = () =>
    {
        let storesInfo = {};
        relatedStores.forEach((relatedStore) =>
        {
            storesInfo[relatedStore._id] = { ...relatedStore };
            storesInfo[relatedStore._id].options = [];
        });
        setStoresInfo(storesInfo);
    };

    const handleAddOption = (id, option) =>
    {
        let updatedStoresInfo = { ...storesInfo };
        updatedStoresInfo[id].options.push(option);
        setStoresInfo(updatedStoresInfo);
        setError(false);
    };

    const handleRemoveOption = (id, option) =>
    {
        let updatedStoresInfo = { ...storesInfo };
        const index = updatedStoresInfo[id].options.findIndex((selectedOption) => selectedOption === option);
        updatedStoresInfo[id].options.splice(index, 1);
        setStoresInfo(updatedStoresInfo);
        setError(false);
    };

    const handleDone = () =>
    {
        const filteredStores =  arrayToHash(Object.values(storesInfo).filter((store) => store.options.length > 0), "_id");
        brandingCtx.copyVenueInfo(entityLabelId, filteredStores, (success) =>
        {
            if (success)
            {
                onDone();
            }
            else
            {
                setError(true);
            }
        });
    };

    return (
        <ModalBox
            open={open}
            size="small"
            header={trans("CopyVenueInfo.Copy_Venue_Info")}
            onClose={onClose}
            actions={<Button
                primary
                floated="right"
                content={trans("CopyVenueInfo.Done")}
                onClick={handleDone}
                disabled={Object.values(storesInfo).filter((store) => store.options.length > 0).length === 0}/>}>
            <p className="para"><Trans
                i18nKey="CopyVenueInfo.Modal_Text"
                values={{ entityLabelName, numOfLocations: Object.keys(storesInfo).length }} /></p>

            <p className="para"><Trans i18nKey="CopyVenueInfo.Modal_Note_Text" /></p>

            <p className="para paraHeading">{trans("CopyVenueInfo.My_Venues")}</p>
            <div className="venue-items">
                {Object.keys(storesInfo).map((storeInfoId) => <VenueItem
                    storeInfo={storesInfo[storeInfoId]}
                    addOption={handleAddOption}
                    removeOption={handleRemoveOption}
                    propertyId={propertyId}
                    key={storeInfoId} />)}
            </div>
            { error && <div className="copy-venue-error">
                <ErrorLabel label={<Trans i18nKey="CopyVenueInfo.Error"/>} />
            </div> }
        </ModalBox>
    );
};

const VenueItem = ({
    storeInfo,
    addOption,
    removeOption,
    propertyId
}) =>
{
    const storeInfoNameAndFloor = `${storeInfo.name}${storeInfo.floorName? `, ${storeInfo.floorName}` : ""}`;
    const storeBuildingAndProperty = `${storeInfo.buildingName ? `${storeInfo.buildingName}, `:""}${storeInfo.propertyName || ""}`;

    const handleOptionsCheckboxChange = (checked, option) =>
    {
        if (checked)
        {
            addOption(storeInfo._id, option);
        }
        else
        {
            removeOption(storeInfo._id, option);
        }
    };

    return (
        <div className="venueItem">
            <div className="venueItemHead">
                <Image as="span" src={filerUrl(storeInfo.iconImage)} onError={(e) =>
                {
                    e.target.onerror=null;
                    e.target.src="/img/icon-building.jpg";
                }} />
                <div className="venueItemHeadContent">
                    <strong>{storeInfoNameAndFloor}</strong>
                    {storeBuildingAndProperty}
                </div>
            </div>
            <div className="venueItemBody">
                {copyStoreInfoOptions.filter((copyStoreInfoOption) => copyStoreInfoOption.key !==  "categories" || (copyStoreInfoOption.key === "categories" && storeInfo.propertyId === propertyId))
                    .map((copyStoreInfoOption) =>  <CheckboxGroup
                        key={copyStoreInfoOption.key}
                        label={copyStoreInfoOption.label}
                        checked={storeInfo.options.includes(copyStoreInfoOption.key)}
                        onChange={(event, { checked }) => handleOptionsCheckboxChange(checked, copyStoreInfoOption.key)} />)}
            </div>
        </div>
    );
};
