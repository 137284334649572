import React, { useEffect, useContext, useCallback } from "react";
import { useSetState, useKeyPress } from "ahooks";
import { ButtonIcon } from "../../../common/ButtonIcon";
import { Button } from "semantic-ui-react";
import BrandingContext from "../../../../store/BrandingContext";
import { MouseInteraction } from "mapsted.maps/utils/interactionTemplates";
import { getSelectedEntityFromClick } from "mapsted.maps/mapFunctions/interactions";
import { Vector as VectorSource } from "ol/source";
import { Vector as VectorLayer } from "ol/layer";
import Feature from "ol/Feature";
import { createStyle, createStyleOptions } from "mapsted.maps/mapFunctions/mapOverlay";
import { createEntityGeometry } from "mapsted.maps/mapFunctions/plotting";
import { getMergedPolygon } from "mapsted.maps/mapFunctions/mapOverlay";
import { EntityType, StructureEntityType, ObstacleEntityType } from "mapsted.maps/utils/entityTypes";
import { fromCircle } from "ol/geom/Polygon";
import { MapOverlaysContext } from "../../../../store/MapOverlaysContext";
import { ModalBox } from "../../../common/ModalBox";

import "./MapOverlaysBulkDrawingComponent.css";
import { useTranslation } from "react-i18next";
import { isPolygon } from "../utils/mapOverlayUtils";

const TOOLS = {
    POLYGON: "Polygon",
    CIRCLE: "Circle",
    RECTANGLE: "Rectangle",
    SQUARE: "Square",
    CLICK_SELECT: "clickSelect"
};

export const SELECTABLE_ENTITIES = {
    [EntityType.STRUCTURE]: [
        StructureEntityType.UNKNOWN_STRUCTURE,
        StructureEntityType.BUILDING,
        StructureEntityType.ELEVATOR,
        StructureEntityType.ESCALATOR,
        StructureEntityType.ENTRANCE_EXIT,
        StructureEntityType.MOVING_WALKWAY,
        StructureEntityType.ROOM,
        StructureEntityType.PARKING,
        StructureEntityType.PLATFORM,
        StructureEntityType.RAMP,
        StructureEntityType.STAIRS,
        StructureEntityType.STEPS,
        StructureEntityType.WASHROOM,
        StructureEntityType.INACCESSIBLE
    ],
    [EntityType.OBSTACLE]: [
        ObstacleEntityType.UNKNOWN_OBSTACLE,
        ObstacleEntityType.BAGGAGE_CAROUSEL,
        ObstacleEntityType.DESK,
        ObstacleEntityType.EQUIPMENT,
        ObstacleEntityType.FURNITURE,
        ObstacleEntityType.KIOSK,
        ObstacleEntityType.OBSTRUCTION,
        ObstacleEntityType.SHELVING,
        ObstacleEntityType.STAGE,
        ObstacleEntityType.VEGETATION,
        ObstacleEntityType.WATER,
    ]
};

const MAP_OVERLAY_INTERACTION_IDENTIFICATION_FLAG = "mapOverlayInteraction";
const MAP_OVERLAY_SELECT_LAYER_FLAG = "isMapOverlaySelectLayer";

const MapOverlaysBulkDrawingComponent = ({ olMap, mapController }) =>
{
    const brandingCtx = useContext(BrandingContext);
    const { 
        bulkCreationState,
        cancelBulkCreation, 
        addPolygonToBulkList,
        updateBulkIndex,
        handleBulkSelectionDone,
        centerOnNewMapOverlayEntry,
    } = useContext(MapOverlaysContext);

    const [state, setState] = useSetState({
        selectedEntities: {},
        disableSelectAndClose: false,
        tool: undefined,
        error: false
    });

    const trans = useTranslation().t;

    useEffect(() =>
    {
        const { tool } = state;
        if (olMap && tool)
        {
            removeSelectOnClickInteraction();
            removeSelectLayer();
            if (tool !== TOOLS.CLICK_SELECT)
            {
                mapController.drawController.addInteraction(olMap, { type: tool, shapeLimit: 1 }, drawEndHandler);
            }
            else
            {
                olMap.addInteraction(getSelectOnClickInteraction());
            }
        }
        return () => 
        {
            if (olMap)
            {
                mapController.drawController.clearDrawing(olMap);
                removeSelectLayer();
            }
        };
    }, [state.tool, olMap]);

    useEffect(() =>
    {
        const { selectedEntities } = state;
        setState({
            disableSelectAndClose: !Object.keys(selectedEntities).length
        });
    }, [state.selectedEntities]);

    const getSelectOnClickInteraction = () => 
    {
        const mousePointInteraction = MouseInteraction({ olMap, handleEvent: (e) => handleMouseUpEvent(e, olMap) });
        mousePointInteraction.set(MAP_OVERLAY_INTERACTION_IDENTIFICATION_FLAG, true);
        return mousePointInteraction;
    };

    const removeSelectOnClickInteraction = () => 
    {
        let mapOverlayPointerInteraction = undefined;   
        const interactions = olMap.getInteractions();
        interactions.forEach((interaction) =>
        {
            if (interaction.get(MAP_OVERLAY_INTERACTION_IDENTIFICATION_FLAG))
            {
                mapOverlayPointerInteraction = interaction;
            }
        });
        if (mapOverlayPointerInteraction)
        {
            olMap.removeInteraction(mapOverlayPointerInteraction);
        }
    };

    const handleMouseUpEvent = useCallback((e, olMap) =>
    {
        // here click event handler is used mainly in entity selection tool
        if (e.type === "click")
        {
            const filterMapOverlayLayers = (layer) => !layer.get("isMapOverlay") && !layer.get(MAP_OVERLAY_SELECT_LAYER_FLAG);

            let { entityId, entityFeature } = getSelectedEntityFromClick({ pointerEvent: e, olMap, options: { layerFilter: filterMapOverlayLayers } });

            if (entityId && entityFeature && isPolygon(entityFeature) && checkIfValidEntityType(entityId))
            {
                setState((prevState) =>
                {
                    let updatedSelectedEntities = { ...prevState.selectedEntities };

                    // if clicked on already selected entity remove it from the list
                    if (updatedSelectedEntities[entityId])
                    {
                        delete updatedSelectedEntities[entityId];
                    }
                    else
                    {
                        updatedSelectedEntities[entityId] = { feature: entityFeature };
                    }

                    // if all the entities where deselected remove layer
                    if (Object.keys(updatedSelectedEntities).length === 0)
                    {
                        removeSelectLayer();
                        return { selectedEntities: updatedSelectedEntities };
                    }
                    else
                    {
                        // get merged polygon
                        const mergedPolygon = getMergedPolygon(Object.values(updatedSelectedEntities).map((selectedEntities) => selectedEntities.feature));

                        if (mergedPolygon && mergedPolygon.geometry && mergedPolygon.geometry.type === "Polygon")
                        {
                            const geometry = createEntityGeometry(mergedPolygon.geometry);
                            createVectorLayerForSelect(geometry);
                            return { selectedEntities: updatedSelectedEntities };
                        }
                    }
                });
            }
        }
        return true;
    }, [state, setState]);

    const checkIfValidEntityType = (entityId) =>
    {
        let isValid = false;
        if (brandingCtx.state?.mapData?.entities 
            && entityId 
            && brandingCtx.state.mapData.entities[entityId])
        {
            const { entities } = brandingCtx.state.mapData;
            const entityData = entities[entityId];
            const { entityType, subEntityType } = entityData;
            isValid = SELECTABLE_ENTITIES[entityType] && SELECTABLE_ENTITIES[entityType].includes(subEntityType);
        }
        return isValid;
    };

    const drawEndHandler = () =>
    {
        setState({
            disableSelectAndClose: false
        });
    };

    const getDrawGeometry = () =>
    {
        const drawnFeatures = mapController.drawController.getFeatures();
        if (drawnFeatures.length < 1)
        {
            return undefined;
        }
        return drawnFeatures[0].getGeometry();
    };

    const handleSelectClick = () =>
    {
        const { tool } = state;
        let polygonGeometry = undefined;
        if (tool !== TOOLS.CLICK_SELECT)
        {
            polygonGeometry = getDrawGeometry();
            if (polygonGeometry.getType() === TOOLS.CIRCLE)
            {
                // console.log(polygonGeometry.getCenter());
                polygonGeometry = fromCircle(polygonGeometry);
            }
        }
        else
        {
            polygonGeometry = getEntitySelectPolygon();
        }

        if (polygonGeometry)
        {
            addPolygonToBulkList(polygonGeometry);
            clearTools();

            updateBulkIndex(1);
        }
    };

    const getEntitySelectPolygon = () =>
    {
        const selectLayer = getEntitySelectLayer();
        if (selectLayer)
        {
            return selectLayer.getSource().getFeatures()[0].getGeometry();
        }
        return undefined;
    };

    const clearTools = () =>
    {
        mapController.drawController.clearDrawing(olMap);
        resetEntitySelectionTool();
        setState({
            tool: undefined,
            disableSelectAndClose: true
        });
    };

    const createVectorLayerForSelect = (geometry) =>
    {
        removeSelectLayer();

        const layer = new VectorLayer({
            [MAP_OVERLAY_SELECT_LAYER_FLAG]: true
        });

        const source = new VectorSource();

        const feature = new Feature({
            geometry
        });

        let styleOptions = createStyleOptions("#FFFFFF", "#6EB3D2", 0.5, 1);

        const style = createStyle(styleOptions);

        source.addFeature(feature);

        layer.setSource(source);

        layer.setStyle(style);

        olMap.addLayer(layer);
    };

    const removeSelectLayer = () =>
    {
        const selectLayer = getEntitySelectLayer();
        if (selectLayer)
        {
            olMap.removeLayer(selectLayer);
        }
    };

    const getEntitySelectLayer = () =>
    {
        let selectLayer = undefined;

        olMap.getLayers().forEach((layer) =>
        {
            if (layer.get(MAP_OVERLAY_SELECT_LAYER_FLAG))
            {
                selectLayer = layer;
            }
        });

        return selectLayer;
    };

    const resetEntitySelectionTool = () =>
    {
        setState({ selectedEntities: {} });
        removeSelectLayer();
        removeSelectOnClickInteraction();
    };

    const handleClose = () =>
    {
        clearTools();
    };

    const handleNextClick = () =>
    {
        const { bulkMapOverlays, bulkCreationIndex } = bulkCreationState;

        // if mapOverlay exists for next index, recenter map to that overlay
        const mapOverlay = bulkMapOverlays[bulkCreationIndex + 1];
        if (mapOverlay)
        {
            centerOnNewMapOverlayEntry(mapOverlay._id);
        }
        
        updateBulkIndex(1);
    };

    const handlePrevClick = () =>
    {
        const { bulkMapOverlays, bulkCreationIndex } = bulkCreationState;

        // if mapOverlay exists for previous index, recenter map to that overlay
        const mapOverlay = bulkMapOverlays[bulkCreationIndex - 1];
        if (mapOverlay)
        {
            centerOnNewMapOverlayEntry(mapOverlay._id);
        }

        updateBulkIndex(-1);
    };

    const checkNextButtonStatus = () =>
    {
        let isDisabled = true;

        const { bulkCreationIndex, bulkMapOverlays } = bulkCreationState;

        if (bulkMapOverlays[bulkCreationIndex] && bulkMapOverlays[bulkCreationIndex].vectorLayer)
        {
            isDisabled = false;
        }

        return isDisabled;
    };

    // ctrl.alt.a 
    useKeyPress(["ā", "ctrl.alt.a"], () => 
    {
        setState({ tool: TOOLS.CLICK_SELECT });
    });
    // ctrl.alt.s
    useKeyPress(["ś", "ctrl.alt.s"], () => 
    {
        setState({ tool: TOOLS.SQUARE });
            
    });  
    // ctrl.alt.d 
    useKeyPress(["ḍ", "ctrl.alt.d"], () => 
    {
        setState({ tool: TOOLS.RECTANGLE });
            
    });
    // ctrl.alt.f
    useKeyPress("ctrl.alt.f", () => 
    {
        setState({ tool: TOOLS.POLYGON });
    });
    // ctrl.alt.g
    useKeyPress(["ṅ", "ctrl.alt.g"], () => 
    {  
        setState({ tool: TOOLS.CIRCLE });
    }); 
    useKeyPress("esc", () => 
    {   
        if (!state.disableSelectAndClose) handleClose();
    }); 
    useKeyPress("enter", () => 
    {   
        if (!state.disableSelectAndClose) handleSelectClick();
    }); 

    return (
        <>
            <div className="indexCountContainer">
                <IndexIndicator currentIndex={bulkCreationState.bulkCreationIndex + 1} trans={trans} />
                <CountIndicator totalCount={bulkCreationState.bulkMapOverlays.length} trans={trans} />
            </div>
            <div className="drawingComponent">
                <ButtonIcon title="Ctrl + Alt + a" active={state.tool === TOOLS.CLICK_SELECT} icon="tool-cursor" onClick={() => setState({ tool: TOOLS.CLICK_SELECT })} />
                <ButtonIcon title="Ctrl + Alt + s" active={state.tool === TOOLS.SQUARE} icon="tool-square" onClick={() => setState({ tool: TOOLS.SQUARE })} />
                <ButtonIcon title="Ctrl + Alt + d" active={state.tool === TOOLS.RECTANGLE} icon="tool-rectangle" onClick={() => setState({ tool: TOOLS.RECTANGLE })} />
                <ButtonIcon title="Ctrl + Alt + f" active={state.tool === TOOLS.POLYGON} icon="tool-polygon" onClick={() => setState({ tool: TOOLS.POLYGON })} />
                <ButtonIcon title="Ctrl + Alt + g" active={state.tool === TOOLS.CIRCLE} icon="tool-circle" onClick={() => setState({ tool: TOOLS.CIRCLE })} />
                <ButtonIcon 
                    title="Esc"
                    disabled={state.disableSelectAndClose}
                    icon="tool-close" 
                    onClick={handleClose} />
                <Button
                    title="Enter"
                    className={state.disableSelectAndClose? "buttonIcon buttonExpanded" : "buttonIcon buttonExpanded drawingComponentSelect" }
                    disabled={state.disableSelectAndClose}
                    onClick={handleSelectClick} 
                    content={trans("MapOverlaysBulkDrawingComponent.Select")} />
                <Button 
                    className="buttonIcon buttonExpanded"
                    content={trans("MapOverlaysBulkDrawingComponent.Prev")}
                    disabled={bulkCreationState.bulkCreationIndex === 0}
                    onClick={handlePrevClick} />
                <Button 
                    className="buttonIcon buttonExpanded"
                    content={trans("MapOverlaysBulkDrawingComponent.Next")}
                    disabled={checkNextButtonStatus()}
                    onClick={handleNextClick} />
                <Button 
                    className="buttonIcon buttonExpanded"
                    content={trans("MapOverlaysBulkDrawingComponent.Done")}
                    disabled={bulkCreationState.bulkMapOverlays.length === 0 } 
                    onClick={handleBulkSelectionDone} />
                <BulkCreationCancelConfirmation 
                    trigger={<Button className="buttonIcon buttonDrawingCancel"
                        content={trans("MapOverlaysBulkDrawingComponent.Cancel")}
                    />}
                    onCancelClick={cancelBulkCreation}
                    trans={trans}
                />
            </div>
        </>
        
    );
};

const IndexIndicator = ({ currentIndex, trans }) => (
    <div className="indexIndicator">
        {`${trans("MapOverlaysBulkDrawingComponent.Current_Index")} ${currentIndex}`}   
    </div>
);

const CountIndicator = ({ totalCount, trans }) => (
    <div className="countIndicator">
        {`${trans("MapOverlaysBulkDrawingComponent.Total_Count")} ${totalCount}`}   
    </div>
);

const BulkCreationCancelConfirmation = ({ trigger, onCancelClick, trans }) => (
    <ModalBox className="confirmActionModal"
        trigger={trigger}
        header={trans("MapOverlaysBulkDrawingComponent.Cancel_Modal_Header")}
        actions={<Button 
            color="orange" 
            floated="right" 
            content={trans("MapOverlaysBulkDrawingComponent.Cancel")} 
            onClick={onCancelClick} 
        />}>
        <p className="p-modal">
            {trans("MapOverlaysBulkDrawingComponent.Cancel_Confirmation_Message")}
        </p>
    </ModalBox>);

export default MapOverlaysBulkDrawingComponent;