
exports.generateRandomNumber = (length) =>
{
    const randomNum = Math.random().toString().replace(/[^0-9]/, "");
    const start = randomNum.length ? randomNum.length - length : 0;
    return randomNum.substr(start, length).padEnd(length, "0");
}

/**
* Calculates the percentage of change from oldValue to newValue
* @param {*} newValue
* @param {*} oldValue
* @returns {Number}
*/
exports.calculatePercentageOfChange = (newValue, oldValue) =>
{
    // case 1 -> new == old;
    if (newValue === oldValue)
    {
        return 0;
    }
    // case 2 -> no previous value
    else if (oldValue === 0)
    {
        return 0;
    }
    // case 3 -> no current value
    else if (newValue === 0)
    {
        return -100;
    }
    // case 4 -> calculate the % of change
    else
    {
        let change = newValue - oldValue;

        return exports.roundWithPrecision((change / oldValue) * 100);
    }
};

/**
 * rounds the value with up to the given decimal percision.
 * @param {number} value 
 * @param {number} precision 
 * @returns 
 */
exports.roundWithPrecision = (value, precision = 1) =>
{
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}
