import { deepCopy } from "mapsted.utils/objects";
import React, { useCallback } from "react";
import { v4 as uuid } from "uuid";
import { ErrorBoxFinalMessage, ErrorBoxItem, ValidationBoxHeader, ValidationBoxWrap, ValidationContentBox, ValidationLevel1Wrap, ValidationLevelThreeOrAnyWrap, ValidationLevelTwoWrap } from "./ErrorBox";
import UseValidationInfo from "./UseValidationInfo";
import "./ValidationErrorStatusInfo.css";
import { ButtonIcon } from "../../common/ButtonIcon";
import { useLocation } from "react-router-dom";

export const maxPossibleLevels = 5;

export const validationBoxInitActiveLevelKeys = new Array(maxPossibleLevels).fill(null);

const RenderThirdOrAnyLevel = ({ data, levelIndex, onMessageClick, onLevelClick, checkLevelIsActive }) =>
{

    const itemsToRender = useCallback(() =>
    {
        const nextLevel = levelIndex + 1;
        if (Array.isArray(data))
        {

            return <ValidationLevelThreeOrAnyWrap>
                {
                    data.map((info) => <ErrorBoxFinalMessage key={uuid()} info={info} onMessageClick={onMessageClick} />)
                }

            </ValidationLevelThreeOrAnyWrap>;
        }

        const { dataIssuesHash, floorsHash, info } = data;
        const hasBuildingIssues = Object.keys(dataIssuesHash ?? {}).length > 0;
        const hasFloorIssues = Object.keys(floorsHash ?? {}).length > 0;


        //when we have info that mean their will not be any  building issues or floor issues
        if (info)
        {
            return <ValidationLevelThreeOrAnyWrap>
                <ErrorBoxFinalMessage info={info} onMessageClick={onMessageClick} />
            </ValidationLevelThreeOrAnyWrap>;
        }

        let currentLevelItems = [];
        if (hasBuildingIssues)
        {

            currentLevelItems.push(...Object.entries(dataIssuesHash).map(([k, d]) =>
            {
                const { title } = d[0];
                const dataIssueDisplayTitle = `${title} (${d.length})`;

                const levelKey = "dataIssuesHash." + k;


                return <ErrorBoxItem
                    key={uuid()}
                    icon="error"
                    content={dataIssueDisplayTitle}
                    title={dataIssueDisplayTitle}
                    onClick={(e) => onLevelClick(e, { levelIndex: nextLevel, levelKey })}
                    active={checkLevelIsActive(levelKey)}
                />;

            }));
        }

        if (hasFloorIssues)
        {

            currentLevelItems.push(...Object.entries(floorsHash).map(([k, d]) =>
            {

                const levelKey = "floorsHash." + k;

                const { title } = d;
                return <ErrorBoxItem
                    key={uuid()}
                    icon="error"
                    content={title}
                    onClick={(e) => onLevelClick(e, { levelIndex: nextLevel, levelKey })}
                    active={checkLevelIsActive(levelKey)}
                />;

            }));
        }

        return <ValidationLevelThreeOrAnyWrap>
            {
                currentLevelItems
            }
        </ValidationLevelThreeOrAnyWrap>;

    }, [checkLevelIsActive]);

    return <>
        {
            itemsToRender()
        }

    </>;
};
const RenderSecondLevels = ({ data, levelIndex, onMessageClick, onLevelClick, checkLevelIsActive }) =>
{

    const renderLevelGrpups = useCallback(() =>
    {
        const nextLevel = levelIndex + 1;

        const { dataIssuesHash, floorsHash, info } = data;
        const hasBuildingIssues = Object.keys(dataIssuesHash ?? {}).length > 0;
        const hasFloorIssues = Object.keys(floorsHash ?? {}).length > 0;

        //when we have info that mean their will not be any  building issues or floor issues
        if (info)
        {
            return <ValidationLevelTwoWrap>
                <ErrorBoxFinalMessage key={uuid()} info={info} onMessageClick={onMessageClick} />
            </ValidationLevelTwoWrap>;
        }

        let currentLevelItems = [];
        if (hasBuildingIssues)
        {

            currentLevelItems.push(...Object.entries(dataIssuesHash).map(([k, d]) =>
            {
                const { title } = d[0];
                const levelKey = "dataIssuesHash." + k;

                const dataIssueDisplayTitle = `${title} (${d.length})`;

                return <ErrorBoxItem
                    key={uuid()}
                    active={checkLevelIsActive(levelKey)}
                    icon="error"
                    content={dataIssueDisplayTitle}
                    title={dataIssueDisplayTitle}
                    onClick={(e) => onLevelClick(e, { levelIndex: nextLevel, levelKey })}
                />;

            }));
        }

        if (hasFloorIssues)
        {

            currentLevelItems.push(...Object.entries(floorsHash).map(([k, d]) =>
            {

                const { title } = d;
                const levelKey = "floorsHash." + k;
                return <ErrorBoxItem
                    key={uuid()}
                    icon="error"
                    content={title}
                    title={title}
                    onClick={(e) => onLevelClick(e, { levelIndex: nextLevel, levelKey })}
                    active={checkLevelIsActive(levelKey)}

                />;

            }));
        }


        return <ValidationLevelTwoWrap>
            {currentLevelItems}
        </ValidationLevelTwoWrap>;

    }, [data, levelIndex]);
    return <>
        {
            renderLevelGrpups()
        }

    </>;
};


export const ValidationErrorBoxLauncher = () =>
{
    let location = useLocation();
    const { onToggleValidationInfoBox, doesHasAnyValidationInfo, getTopPriorityValidationInfo } = UseValidationInfo({ latestUpdatesOnly: true });
    const { icon } = getTopPriorityValidationInfo();

    if (!doesHasAnyValidationInfo || !location.pathname.toLowerCase().includes("mapeditor")) return null;

    return <ButtonIcon icon={icon} className="validationInfoTrigger" onClick={(e) => onToggleValidationInfoBox(e)} />;
};

const ValidationInfoSection = ({ latestUpdatesOnly = true }) =>
{
    const {
        preparedBuildingHash,
        getTopPriorityValidationInfo,
        doesHasAnyValidationInfo,
        onValidationErrorClick,
        validationBoxActiveLevelKeys,
        isValidationBoxOpen,
        isMapEditorMenuExpanded,
        onToggleValidationInfoBox,
        onLevelClick,
        checkLevelIsActive,

    } = UseValidationInfo({ latestUpdatesOnly });


    const renderValidationErrorPopup = useCallback(() =>
    {
        const { icon: headingIcon } = getTopPriorityValidationInfo();

        if (!doesHasAnyValidationInfo || !isValidationBoxOpen)
        {
            return null;
        }


        const data = Object.entries(preparedBuildingHash).sort(([, a], [, b]) => a.validationStatusMeta.priory - b.validationStatusMeta.priory);

        const levelZeroItems = data.map(([key, item]) => <ErrorBoxItem
            key={item.title}
            content={item.title}
            // icon={validationStatusMeta.icon}
            title={item.title}
            description={item.description}
            onClick={(e) => onLevelClick(e, { levelIndex: 0, levelKey: key })}
            active={checkLevelIsActive(key)}
        />);

        let validationWindowTitle = "Validation Info";
        let prevValue = null;
        const levelOneToAnyLevelsView = [];

        //populate's the activeLevels ui based on the validationBoxActiveLevelKeys
        validationBoxActiveLevelKeys.filter((key) => !!key).forEach((key, index) =>
        {
            if (!prevValue)
            {
                prevValue = preparedBuildingHash;
            }
            const keys = key.split(".");

            const newValue = keys.reduce((a, b) => a[b], prevValue);

            if (newValue)
            {
                let title;
                if (Array.isArray(newValue))
                {
                    //updates the data issue label + dataIssueCount on validation window title
                    title = `${newValue[0].title} (${newValue.length})`;
                }
                else
                {
                    title = newValue.title;
                }
                validationWindowTitle += " → " + title;
                prevValue = newValue;
                const dataCopy = deepCopy(newValue);
                if (index === 0)
                {
                    levelOneToAnyLevelsView[index] = <RenderSecondLevels
                        checkLevelIsActive={checkLevelIsActive}
                        key={uuid()}
                        onLevelClick={onLevelClick}
                        data={dataCopy}
                        levelIndex={index}
                        onMessageClick={onValidationErrorClick} />;
                }
                if (index >= 1)
                {


                    levelOneToAnyLevelsView[index] = <RenderThirdOrAnyLevel
                        checkLevelIsActive={checkLevelIsActive}
                        key={uuid()}
                        data={dataCopy}
                        levelIndex={index}
                        onMessageClick={onValidationErrorClick}
                        onLevelClick={onLevelClick} />;
                }
            }
        });



        return <>
            <ValidationBoxWrap className={isMapEditorMenuExpanded ? "menu-expanded" : ""}>
                <ValidationBoxHeader
                    title={validationWindowTitle}
                    icon={headingIcon}
                    onClose={(e) => onToggleValidationInfoBox(e, false)}
                />
                <ValidationContentBox>
                    <ValidationLevel1Wrap>
                        {
                            levelZeroItems
                        }
                    </ValidationLevel1Wrap>
                    {
                        levelOneToAnyLevelsView
                    }

                </ValidationContentBox>

            </ValidationBoxWrap>
        </>;

    }, [
        doesHasAnyValidationInfo,
        isValidationBoxOpen,
        getTopPriorityValidationInfo,
        onToggleValidationInfoBox,
        onValidationErrorClick,
        validationBoxActiveLevelKeys,
        checkLevelIsActive,
        isMapEditorMenuExpanded,
        preparedBuildingHash
    ]);


    return <>
        {renderValidationErrorPopup()}
    </>;

};

export default ValidationInfoSection;
