import brandingApi from "./branding.api";

export const BRANDING_QUERIES = {
    PROPERTY_CATEGORIES: (propertyId, ...args) => ({
        queryKey: ["PROPERTY_CATEGORIES", propertyId, ...args],
        queryFn: () => brandingApi.getCategories(propertyId)
    }),

    GET_BUILDING_ENTITIES: (buildingId) => ({
        enabled: !!buildingId,
        queryKey: ["GET_BUILDING_ENTITIES", buildingId],
        queryFn: () => brandingApi.getBuildingMapData(buildingId),
        select: (data) => data.entities
    }),
    GET_STYLED_ICONS: ({ kind, color, theme, searchText, limit }) => ({
        enabled: !!(theme && kind),
        queryKey: ["GET_STYLED_ICONS", kind, color, theme, searchText, limit],
        queryFn: ({ pageParam = 1 }) => brandingApi.getStyledIcons({ kind, color, theme, searchText, page: pageParam, limit }),
        initialData: {}
    }),
};
