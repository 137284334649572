import React from "react";
import { Dropdown, DropdownItem, DropdownMenu } from "semantic-ui-react";
import { ICON_TYPE_OPTIONS } from "../../constant";
import { useTranslation } from "react-i18next";
import { useIconsManagementContext } from "../../IconsManagementContext";

function IconsTypeSelector() 
{
    const trans = useTranslation().t;
    const { handleChangeIconsFilters, iconsSearchAndFilters, disableIconTypeSelection } = useIconsManagementContext();
    const selectedIconTypeInfo = ICON_TYPE_OPTIONS.find((option) => option.value === iconsSearchAndFilters.filters.type);
    
    return (
        <div className="icons-type-selector">
            <Dropdown
                text={trans(selectedIconTypeInfo?.i18nKey)}
                icon={"angle down"}
                className="gms-dropdown"
            >
                <DropdownMenu className="gms-dropdown-menu">
                    {ICON_TYPE_OPTIONS.map((option) => (
                        <DropdownItem
                            className="gms-dropdown-item"
                            key={option.value}
                            onClick={() => handleChangeIconsFilters({ type: option.value })}
                            disabled={disableIconTypeSelection}
                        >
                            {trans(option.i18nKey)}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </div>
    );
}

export default IconsTypeSelector;