import React from "react";
import PropTypes from "prop-types";
import { Segment, Dropdown, Input } from "semantic-ui-react";
import { DateInput, TimeInput } from "semantic-ui-calendar-react-yz";
import { ErrorLabel } from "./ErrorLabel";
import ReactFlatPicker from "./ReactFlatPicker";

export const InputFormGroup = ({
    type, list, label, detail, extraLabel, placeholder, value, text, icon, disabled, action,
    name, timeValue, dateValue, placeholderDate, iconPosition, minDate, maxDate, selectedDates, maxLength,
    error, errorPointing = true, hideError, className, flexed, scrolling, search, listValue, children, onChange, info, inputRef, onClose, ...props
}) => (
    <Segment basic className={`form-group${className ? " " + className : ""}${error ? " error" : ""}${flexed ? " dropdownFlexed" : ""}`}>

        {
            label && <p>{label}
                {info && info}
            </p>
        }


        <div className="content">

            {
                detail && <p>{detail}</p>
            }

            {
                ((type !== "number") && (placeholder || value) && !(list || search)) && (
                    <Input placeholder={placeholder} action={action} value={value} onChange={onChange} name={name} error={!!error} disabled={disabled}
                        autoComplete="off" autoCorrect="off" autoCapitalize="none" spellCheck={true} maxLength={maxLength} />
                )
            }

            {
                (type === "number") && (
                    <Input placeholder={placeholder} action={action} value={value} onChange={onChange} name={name} error={!!error} disabled={disabled}
                        type="number" autoComplete="off" autoCorrect="off" autoCapitalize="none" spellCheck={true} maxLength={maxLength} />
                )
            }

            {
                (type === "date") && (
                    <DateInput
                        closable
                        disabled={disabled}
                        duration={0}
                        animation={""}
                        name={name}
                        value={dateValue}
                        placeholder={placeholderDate}
                        iconPosition={iconPosition}
                        minDate={minDate}
                        maxDate={maxDate}
                        disable={selectedDates}
                        popupPosition={props.popupPosition}
                        onChange={(e, { name, value }) => onChange && onChange(e, { name, value })} />        
                )
            }

            {
                (type === "time" && disabled) ? (
                    <TimeInput ref={inputRef} name={name} animation={""} closable disabled={disabled}
                        value={timeValue + ""} timeFormat={"AMPM"} icon="angle down"
                        onChange={(e, { name, value }) => onChange && onChange(e, { name, value })} />
                ):<>
                    {
                        (type === "time") && (
                            <Input style={{ width: "100%" ,maxWidth: "100%" }}>
                                <ReactFlatPicker
                                    ref={inputRef} 
                                    name={name}
                                    value={timeValue}
                                    onChange={(e, { name, value }) => onChange && onChange(e, { name, value })} 
                                />
                            </Input>
                        )
                    }
                </>
            }
            {
                (list || props.options) && (
                    <Dropdown
                        pointing
                        options={list ? list : options}
                        text={text}
                        icon="angle down"
                        className={className}
                        scrolling={scrolling}
                        search={search}
                        loading={props.loading}
                        placeholder={placeholder}
                        value={listValue}
                        name={name}
                        onSearchChange={props.onSearchChange}
                        onChange={onChange} />
                )
            }

            {
                //     (search && !(list || props.options)) && (
                //         <Dropdown className="add-property-drop"
                //             placeholder="e.g. BEO Shopping Mall"
                //             fluid
                //             search
                //             selection
                //             scrolling
                //             options={search}
                //             icon="angle down"
                //         />
                //     )
                //
            }

            {(!!error && !hideError)
                    && <ErrorLabel small label={error} />
            }

            {children}

            {
                extraLabel
                    && <span className="extra-label">{extraLabel}</span>

            }
        </div>
    </Segment >
);

export const DropdownGroup = ({ dropdownProps }) =>
{
    const defaultProps = {
        icon: "angle down",
        pointing: true,
        scrolling: true,
        search: true
    };

    const mergedProps = { ...defaultProps, ...dropdownProps };

    return (<Dropdown {...mergedProps} />);
};
DropdownGroup.propTypes = {
    dropdownProps: PropTypes.object
};
DropdownGroup.defaultProps = {
    dropdownProps: {}
};



const options = [
    { key: 1, text: "Choice 1", value: 1 },
    { key: 2, text: "Choice 2", value: 2 },
    { key: 3, text: "Choice 3", value: 3 },
];
