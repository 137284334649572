const { Feature } = require("ol");
const { getTopRight, getCenter } = require("ol/extent");
const { GeoJSON } = require("ol/format");
const { DEFAULT_HIGHLIGHT_STYLE, MapConstants, DEFAULT_LANGUAGE_CODE } = require("../utils/map.constants");
const { changeEntityStyle, createEntityGeometry, createVectorLayer, addFeatureToLayer, createEntityStyle } = require("./plotting");
const { deepValue } = require("mapsted.utils/objects");
const { toGpsLocation } = require("../utils/map.utils");
const { v4: uuidv4 } = require("uuid");
const { ShapeTypes } = require("../utils/entityTypes");
const { Polygon } = require("ol/geom");
const { createCanvas } = require("canvas");
const turf = require("@turf/turf");
const { PublicEntityAccess } = require("./entityAccess");

/**
 * Finds the top coordinate of a openlayers feature.
 * @param {Object} feature
 */
exports.getFeatureTopCoordinate = (feature) =>
{
    const geometry = feature.getGeometry();
    const extent = geometry.getExtent();

    const topRight = getTopRight(extent);
    const center = getCenter(extent);

    return geometry.getClosestPoint([center[0], topRight[1]]);
};

/**
 * MUTATES the given geometry
 * @param {object} geometry
 * @param {Array<number>} startCoordinate
 * @param {Array<number>} endCoordinate
 */
exports.translateGeometry = (geometry, startCoordinate, endCoordinate) =>
{
    geometry.translate(endCoordinate[0] - startCoordinate[0], endCoordinate[1] - startCoordinate[1]);
};


/**
 * creates a merged polygon from the set of selected polygons.
 *
 * note polygons are expected to be intersecting.
 *
 * @param {Object} entities - selected entities to be merged
 *
 * @returns The merged polygon in GeoJSON format, or undefined if the polygons given are not connected.
 */
exports.getMergedPolygon = (entities) =>
{
    // Object structure of entities;
    //     entities = {
    //          [entityId]:{
    //              feature,
    //              textFeature,
    //         }
    //     }

    let entityIds = Object.keys(entities);
    let format = new GeoJSON();

    let geoPolygonList = [];
    let mergedPolygon = undefined;

    entityIds.forEach((entityId) =>
    {
        const polygon = format.writeFeatureObject(entities[entityId].feature);
        geoPolygonList.push(polygon);
    });

    mergedPolygon = geoPolygonList.pop();

    // merge all polygons one by one.
    while (geoPolygonList.length > 0)
    {
        const intersectionIdx = exports.indexOfIntersectingPolygon(geoPolygonList, mergedPolygon);

        if (intersectionIdx !== undefined)
        {
            mergedPolygon = turf.union(mergedPolygon, geoPolygonList[intersectionIdx]);
            geoPolygonList.splice(intersectionIdx, 1);
        }
        else
        {
            // If the merged polygon does not find an intersection, break from loop.
            break;
        }
    }

    // all polygons merged - return merged polygon.
    if (geoPolygonList.length === 0)
    {
        // convert coords to gps. (loop  through each set if we want to support multipolygon in the future)
        mergedPolygon.geometry.coordinates.forEach((set, i) =>
        {
            let coords = [];
            set.forEach((coordinate) =>
            {
                coords.push(toGpsLocation(coordinate));
            });

            mergedPolygon.geometry.coordinates[i] = coords;
        });

        return mergedPolygon;
    }
    // some polygons where not connected - return undefined.
    else
    {
        return undefined;
    }
};

/**
 * Checks if the two entities given are connected;
 * @param {Array<object>} features [entityFeature1, entityFeature2]
 *
 */
exports.areEntitiesConnected = ([entityFeature1, entityFeature2]) => exports.areFeaturesConnected([entityFeature1.feature, entityFeature2.feature]);

/**
 * Checks if the two features given are connected;
 * @param {Array<object>} features [feature1, feature2]
 * @returns {boolean}
 */
exports.areFeaturesConnected = ([feature1, feature2]) =>
{
    let format = new GeoJSON();
    let entityGeo1 = format.writeFeatureObject(feature1);
    let entityGeo2 = format.writeFeatureObject(feature2);

    let unionGeo = turf.union(entityGeo1, entityGeo2);

    if (unionGeo.geometry.type === ShapeTypes.POLYGON)
    {
        return true;
    }
    else
    {
        return false;
    }
};

/**
 * Returns whether or not the new feature intersects the set of selected features.
 *
 * @param {Object} selectedEntities - Object list of selected entities.
 * @param {Object} entityFeature
 * @param {boolean} [checkEntityType=true]
 * @returns {boolean}
 */
exports.doesEntityIntersectWithPreviousSelected = (selectedEntities, entityFeature, checkEntityType = true) =>
{
    const objectList = Object.values(selectedEntities);

    let format = new GeoJSON();
    let entityGeo = format.writeFeatureObject(entityFeature);

    if (objectList.length === 0)
    {
        return true;
    }

    let unionGeo = entityGeo;
    let typesMatch = true;

    for (let i = 0; i < objectList.length; i++)
    {
        let comparedGeo = format.writeFeatureObject(objectList[i].feature);

        unionGeo = turf.union(comparedGeo, unionGeo);

        if (checkEntityType
            && !(objectList[i].feature.get("entityType") === entityFeature.get("entityType") && objectList[i].feature.get("subEntityType") === entityFeature.get("subEntityType")))
        {
            typesMatch = false;
        }
    }

    //allow merging of Polygon and MultiPolygon;
    if (unionGeo.geometry.type === ShapeTypes.POLYGON && typesMatch)
    {
        return true;
    }
    else
    {
        return false;
    }
};

/**
* Returns the first selected entity ID and feature.
*
* This is for functions that assume there is only one selected entity.
*/
exports.getSelectedEntity = (selectedEntities) =>
{
    const ids = Object.keys(selectedEntities);
    if (ids.length > 0)
    {
        const selectedEntityId = Object.keys(selectedEntities)[0];
        const selectedEntityFeature = selectedEntities[selectedEntityId].feature;
        const selectedEntityTextFeature = selectedEntities[selectedEntityId].textFeature;

        return { selectedEntityId, selectedEntityFeature, selectedEntityTextFeature };
    }
    else
    {
        return {};
    }
};

/**
 *
 * @param {Map} entities - mapping of entity ID to entity to be highlighted
 */
exports.highlightEntities = (entities, highlightStyle = DEFAULT_HIGHLIGHT_STYLE) =>
{

    if (entities)
    {
        Object.values(entities).forEach((entity) =>
        {
            changeEntityStyle(entity.feature, highlightStyle);
        });
    }

};

/**
 * Only use on maps ujsing public data
 * @param {*} entities 
 * @param {*} style 
 */
exports.unHighlightEntities = (entities, style) =>
{
    if (entities)
    {
        Object.values(entities).forEach((entity) =>
        {
            let entityAccess = new PublicEntityAccess(entity);
            const styleObject = entityAccess.getStyleObject(style);
            changeEntityStyle(entity.feature, styleObject);
        });
    }
};

/**
 *
 * @param {Point} centerPoint
 * @param {Number} radius - radius in km.
 */
exports.createBoundingCircleLayer = (centerPoint, radius) =>
{

    const boundingCircle = turf.circle(centerPoint, radius).geometry;
    const boundaryGeometry = createEntityGeometry(boundingCircle);

    const style = createEntityStyle(DEFAULT_HIGHLIGHT_STYLE);

    //Create feature
    const feature = new Feature({
        geometry: boundaryGeometry,
        style: style,
        id: "bounding_circle",
    });

    const boundaryLayer = createVectorLayer(0);
    addFeatureToLayer(boundaryLayer, feature);

    return { boundaryLayer, boundaryGeometry };
};

exports.convertGeoPolygonToEntity = (polygon, entityTemplate) =>
{
    // Create new entity
    let entityId = uuidv4();
    let polygonId = uuidv4();

    return {
        entityType: entityTemplate.entityType,
        subEntityType: entityTemplate.subEntityType,
        textRotation: 0,
        refType: entityTemplate.refType,
        ref: entityTemplate.ref,
        entityId,
        polygonId,
        draft: true,
        shape: polygon.geometry,
        style: {
            [DEFAULT_LANGUAGE_CODE]: {
                useText: true
            }
        }
    };
};

exports.convertFeatureGeometryToGeoJSON = (featureGeometry) =>
{
    // if not circle type
    if (featureGeometry.getType() !== ShapeTypes.CIRCLE)
    {
        let format = new GeoJSON();
        return format.writeGeometryObject(featureGeometry);
    }
    else
    {
        const center = featureGeometry.getCenter();
        let shape = {
            type: "Point",
            coordinates: center
        };

        return shape;
    }
};

/**
 * Mutable function that translates the image layer with the given start and end coordinate.
 * @param {*} imageLayer
 * @param {*} startCoordiante
 * @param {*} endCoordinate
 */
exports.translateImageLayer = (imageLayer, startCoordiante, endCoordinate) =>
{
    // translate image box
    const imageSource = imageLayer.getSource();

    exports.translateImageSource(imageSource, startCoordiante, endCoordinate);

};

exports.translateImageSource = (imageSource, startCoordiante, endCoordinate) =>
{

    // IMPORTANT
    // imageSource.imageExtent_ = orginal image extent size
    // imageSource.image_.extent = warped image extent to fit rotation

    // translate image box
    imageSource.image_.extent = exports.getTranslatedExtent(imageSource.image_.extent, startCoordiante, endCoordinate);
    imageSource.imageExtent_ = exports.getTranslatedExtent(imageSource.imageExtent_, startCoordiante, endCoordinate);
    imageSource.changed();
};

exports.getTranslatedExtent = (extent, startCoordiante, endCoordinate) =>
{
    let imgBox = exports.getBoundingBoxPolygonFromExtent(extent);

    let translatedImgBox = exports.getTranslatedPolygon(imgBox, startCoordiante, endCoordinate);

    if (!translatedImgBox)
    {
        return;
    }

    // set new extent
    const newExtent = turf.bbox(translatedImgBox);

    return newExtent;
};

exports.rotateImageOld = ({ canvas, image, degree, source }) =>
{
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // save the unrotated ctx of the canvas so we can restore it later
    // the alternative is to untranslate & unrotate after drawing
    ctx.save();

    // move to the center of the canvas
    ctx.translate(canvas.width / 2, canvas.height / 2);

    // rotate the canvas to the specified degrees
    ctx.rotate(degree * Math.PI / 180);
    ctx.drawImage(image, 0, 0, image.width, image.height, -image.width / 2, -image.height / 2, canvas.width, canvas.height);

    // we’re done with the rotating so restore the unrotated ctx
    ctx.restore();

    // if (source)
    // {
    //     exports.imageBoundaryTest(source, degree);
    // }
    (!!source) && source.changed();
};

exports.getImageBoundingPolygonWithRotation = (imageLayer, rotation) =>
{
    const imageSource = imageLayer.getSource();
    // check if latlng is needed before rotate
    const imageExtent = imageSource.getImageExtent();

    const WGS84ExtentMin = turf.toWgs84([imageExtent[0], imageExtent[1]]);
    const WGS84ExtentMax = turf.toWgs84([imageExtent[2], imageExtent[3]]);

    let imageBoundingPolygon = turf.bboxPolygon([WGS84ExtentMin[0], WGS84ExtentMin[1], WGS84ExtentMax[0], WGS84ExtentMax[1]]);

    if (rotation)
    {
        imageBoundingPolygon = turf.transformRotate(imageBoundingPolygon, rotation);
    }

    imageBoundingPolygon.geometry.coordinates.forEach((coordSet, i) =>
    {
        let coordSetMercator = [];

        coordSet.forEach((coords) =>
        {
            coordSetMercator.push(turf.toMercator(coords));
        });

        imageBoundingPolygon.geometry.coordinates[i] = coordSetMercator;
    });

    return imageBoundingPolygon;
};

exports.imageRotationOffset = (imageSource, rotation) =>
{
    const rad = rotation * (Math.PI / 180) || 0;
    const imageExtent = imageSource.getImageExtent();
    const orginalWidth = Math.abs(imageExtent[2] - imageExtent[0]);
    const orginalHeight = Math.abs(imageExtent[3] - imageExtent[1]);

    const { width, height } = calcProjectedRectSizeOfRotatedRect({ width: orginalWidth, height: orginalHeight }, rad);

    const xDiff = (orginalWidth - width) / 2;
    const yDiff = (orginalHeight - height) / 2;

    // set new extent of image and call changed event to redraw image.
    const newExtent = [imageExtent[0] + xDiff, imageExtent[1] - yDiff, imageExtent[2] + xDiff, imageExtent[3] - yDiff];
    // imageSource.imageExtent_ = newExtent;
    imageSource.image_.extent = newExtent;
};

exports.rotateImage = ({ canvas, image, degree }) =>
{
    const rad = degree * (Math.PI / 180) || 0;

    // get new canvas width and height
    let width = Math.sqrt(Math.pow(image.width, 2) + Math.pow(image.height, 2));
    let height = width;

    if (!canvas)
    {
        canvas = createCanvas(width, height);
    }
    else
    {
        canvas.width = width;
        canvas.height = height;
    }

    if(!canvas.getContext) return null
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // save the unrotated ctx of the canvas so we can restore it later
    // the alternative is to untranslate & unrotate after drawing
    ctx.save();

    // move to the center of the canvas
    ctx.translate(canvas.width / 2, canvas.height / 2);

    // rotate the canvas to the specified degrees
    ctx.rotate(rad);

    // draw the image
    // since the ctx is rotated, the image will be rotated also
    ctx.drawImage(image, -image.width / 2, -image.height / 2);

    // we’re done with the rotating so restore the unrotated ctx
    ctx.restore();

    return canvas;
};


/**
 * NOTE : When source rect is rotated at some rad or degrees,
 * it's original width and height is no longer usable in the rendered page.
 * So, calculate projected rect size, that each edge are sum of the
 * width projection and height projection of the original rect.
 */
const calcProjectedRectSizeOfRotatedRect = (size, rad) =>
{
    const { width, height } = size;

    const rectProjectedWidth = Math.abs(width * Math.cos(rad)) + Math.abs(height * Math.sin(rad));
    const rectProjectedHeight = Math.abs(width * Math.sin(rad)) + Math.abs(height * Math.cos(rad));

    return { width: rectProjectedWidth, height: rectProjectedHeight };
};

/**************************************************************/
/********************* GEOJSON FUNCTIONS  *********************/
/**************************************************************/

exports.geoJsonToTurfPolygon = (geoJsonPolygon) => turf.polygon(geoJsonPolygon.coordinates);

exports.longLatToTurfPoint = (x, y) => turf.point([x, y]);

exports.getBoundingBoxPolygonFromExtent = (extent) =>
{
    const bbox = turf.bboxPolygon(extent);
    return bbox;
};

/**
 *
 * @param {Array<Array<number>>} points
 * @param {*} polygon
 * @param {undefined | number} scaleFactor scale factor applied to the points before testing them, undefined leaves the points unmodified
 * @returns {boolean}
 */
exports.isPointsInPolygon = (points, polygon, scaleFactor = undefined) =>
{
    const turfPoints = turf.points(points);
    let pointsToTest = turfPoints;
    if (scaleFactor && Number.isFinite(scaleFactor))
    {
        const wgs84Poly = turf.toWgs84(turf.combine(turfPoints));
        const squishedPoints = turf.explode(turf.toMercator(turf.transformScale(wgs84Poly, 0.5, { origin: "center" })));
        pointsToTest = squishedPoints;
    }

    let ptsWithin = turf.pointsWithinPolygon(pointsToTest, polygon);
    if (ptsWithin.features.length === pointsToTest.features.length)
    {
        return true;
    }
    return false;
};

exports.isPointInPolygon = (point, polygon) =>
{
    return turf.booleanPointInPolygon(point, polygon);
}

exports.getTranslatedPolygon = (polygon, startCoordinate, endCoordinate) =>
{
    try
    {
        const geometry = new Polygon(polygon.geometry.coordinates);

        exports.translateGeometry(geometry, startCoordinate, endCoordinate);

        const translatedPolygon = this.convertFeatureGeometryToGeoJSON(geometry);

        return translatedPolygon;
    }
    catch (err)
    {
        console.log("err", err);
        return undefined;
    }
};

exports.getShapeCenterPoint = (shape) =>
{
    try
    {
        let centerPoint = undefined;

        if (shape)
        {
            if (shape.type === "Point")
            {
                centerPoint = shape.coordinates;
            }
            else
            {
                const propertyPolygon = turf.polygon(shape.coordinates);

                // get bounding box
                const boundingBox = turf.bbox(propertyPolygon);


                // get center of bounding box
                const polygonBoundingBox = turf.bboxPolygon(boundingBox);
                centerPoint = turf.center(polygonBoundingBox).geometry.coordinates;
            }
        }

        return centerPoint;
    }
    catch (error)
    {
        console.log(error);
    }
};

exports.getShapeCenterPointAndRadius = (shape) =>
{
    let centerPoint = undefined;
    let radius = undefined;

    if (shape)
    {
        const propertyPolygon = shape.type === "Point" ? turf.point(shape.coordinates) : turf.polygon(shape.coordinates);

        // get bounding box
        const boundingBox = turf.bbox(propertyPolygon);

        // const polygon = format.writeFeatureObject(feature.getGeometry());

        // get center of bounding box
        const polygonBoundingBox = turf.bboxPolygon(boundingBox);
        centerPoint = turf.center(polygonBoundingBox).geometry.coordinates;


        // get radius in km
        const coords = polygonBoundingBox.geometry.coordinates[0];
        const coord1 = coords[0];
        const coord2 = coords[1];

        let radius1 = turf.distance(centerPoint, coord1);
        let radius2 = turf.distance(centerPoint, coord2);

        if (radius1 >= radius2)
        {
            radius = radius1;
        }
        else
        {
            radius = radius2;
        }
    }

    // return center point and radius
    return { centerPoint, radius };
};

/**
 * Finds the top middle coordinate of a geoJSON polygon.
 * @param {Object} geoJsonObject
 */
exports.getGeoJSONTopCoordinate = (geoJsonObject) =>
{
    let boundingBox = turf.bbox(geoJsonObject);

    let minPoint = [boundingBox[0], boundingBox[1]];
    let maxPoint = [boundingBox[2], boundingBox[3]];

    let midX = (minPoint[0] + maxPoint[0]) / 2;

    // return midx and maxY
    return turf.toMercator([midX, maxPoint[1]]);
};

/**
 * finds index of the polygon in polygonList intersects with polygon polygon.
 *
 * @param {Array} polygonList - List of polygons
 * @param {Object} polygon - Polygon looking for an intersection
 */
exports.indexOfIntersectingPolygon = (polygonList, polygon,) =>
{

    if (polygonList.length === 0)
    {
        return undefined;
    }

    for (let i = 0; i < polygonList.length; i++)
    {
        let intersection = turf.intersect(polygon, polygonList[i]);

        if (intersection)
        {
            return i;
        }
    }

    return undefined;
};

exports.getLineStringLength = (lineString) => turf.length(lineString);
