import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import UploadImageModal from "../../popups/UploadImageModal";
import { acceptableImageFileFormats } from "../../../_constants/config";

import css from "./common.module.css";
import BrandingContext from "../../../store/BrandingContext";
import { debounce, filerUrl } from "../../../_utils/utils";
import { ASPECT_RATIOS, DEFAULT_LANGUAGE_CODE, SINGLE_LANG_INPUT_CODE } from "../../../_constants/constants";
import { LoadableImage } from "../../elements/LoadableImage";
import serverApi from "../../../_api/server.api";
import { LogoTypePopup } from "../../common/LogoTypePopup";
import { withLoader } from "../../../_utils/hoc";

import { getSelectedEntity } from "mapsted.maps/mapFunctions/features";
import { Button, Popup } from "semantic-ui-react";

class InformationBoxHeader extends React.Component
{
    static contextType = BrandingContext;

    handleEditCoverImage = (image, fileId) =>
    {
        const { isStore } = this.props;

        fetch(image)
            .then((res) => res.blob())
            .then((file) =>
            {
                if (isStore)
                {
                    this.context.editEntityLabelImage(fileId, file, true, SINGLE_LANG_INPUT_CODE, this.handleUpdateImageCallback);
                }
                else
                {
                    this.context.editPropertyEntityLabelImage(fileId, file, true, SINGLE_LANG_INPUT_CODE, this.handleUpdateImageCallback);
                }
            });
    }


    handleUpdateImageCallback = () =>
    {
        this.setState({ coverImageToEdit: undefined });
    }


    render ()
    {
        const { entityLabel, t: genericTrans, canPlotImage } = this.props;
        const { iconImage, coverImages, lightIcon, darkIcon } = entityLabel;
        /** @type {typeof import("../../../_intl/resources.json")["en"]["translation"]["information box header"]} */

        const trans = genericTrans("information box header");

        const iconImages = {
            iconImage: {
                name: genericTrans("InformationBoxHeader.Classic_Mode"),
                value: iconImage?.[DEFAULT_LANGUAGE_CODE]? iconImage?.[DEFAULT_LANGUAGE_CODE]: null,
                header: genericTrans("InformationBoxHeader.Icon_Logo")
            }
        };
        if (canPlotImage)
        {
            Object.assign(iconImages, {
                lightIcon: {
                    name: genericTrans("InformationBoxHeader.Light_Mode"),
                    value: lightIcon?.[DEFAULT_LANGUAGE_CODE],
                    header: genericTrans("InformationBoxHeader.Map_Logos")
                },
                darkIcon: {
                    name: genericTrans("InformationBoxHeader.Dark_Mode"),
                    value: darkIcon?.[DEFAULT_LANGUAGE_CODE]
                }
            });
        }

        return (
            <div  className={css.coverPlaceHolder}>
                <UploadImageModal
                    aspectRatio={ASPECT_RATIOS.COVER_IMAGE}
                    acceptableImageFileFormats={acceptableImageFileFormats}
                    defaultSrc={`/img/${this.context.state.buildingId ? "default-cover" : "default-building"}.jpg`}
                    defaultClass="defaultCover"
                    heading={trans["add img heading"]}
                    onSave={(data) => this.context.updateCoverImages({ data, entityLabel })}
                    onEditSave={this.handleEditCoverImage.bind(this)}
                    onDelete={this.handleDeleteImage}
                    onGalleryReordering={debounce((data) => 
                    {
                        this.context.updateCoverImages({ data, entityLabel });
                    }, 1500)}
                    description={trans["add img desc"]}
                    gallery={true}
                    galleryList={coverImages?.[SINGLE_LANG_INPUT_CODE] || []}
                />
                <div className="image-block-bx image-block-bx--mod">
                    <LogoTypePopup
                        images={iconImages}
                        handleIconUpdate={(data) => this.context.updateIconImage({ data, entityLabel })}
                        entityLabel={entityLabel}
                        trigger={
                            <LoadableImage
                                as="div"
                                className="image-block display-image"
                                src={filerUrl(iconImages["iconImage"]?.value)}
                                defaultSrc="/img/icon-upload.svg"
                                defaultClass="defaultLogo"
                                alt=""
                            />
                        }
                    />
                </div>
            </div>
        );
    }
}

export default withTranslation()(withLoader(InformationBoxHeader));

InformationBoxHeader.propTypes = {
    edit: PropTypes.bool.isRequired,
    entityLabel: PropTypes.object.isRequired,
    canPlotImage: PropTypes.bool.isRequired
};
