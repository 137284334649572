import React, { createContext, useContext, useState } from "react";
import {  TabPane } from "semantic-ui-react";
import ImagesManagement from "./ImagesManagement/ImagesManagement";
import IconsManagement from "./IconsManagement/IconsManagement";
import { ButtonIcon } from "../../common/ButtonIcon";
import { useLoadingPool } from "../../../_utils/hooks";
import { Loader } from "../../elements/loader";
import { useQueryClient } from "react-query";

const GmsContext = createContext();

export const useGmsContext = () => useContext(GmsContext);

export const GmsActionMode = {
    VIEW: "VIEW",
    LINK_ICON: "LINK_ICON",
    LINK_ICON_IMAGE: "LINK_IMAGE",
    LINK_COVER_IMAGES: "LINK_COVER_IMAGES",
    REPLACE_IMAGE: "REPLACE_IMAGE",
};

export const TAB_MENU_ITEMS = {
    IMAGES: "Images",
    ICONS: "Icons",
};

export const DEFAULT_TABS = [
    {
        menuItem: TAB_MENU_ITEMS.ICONS,
        render: () => (
            <TabPane>
                <IconsManagement></IconsManagement>
            </TabPane>
        ),
    },
    {
        menuItem: TAB_MENU_ITEMS.IMAGES,
        render: () => (
            <TabPane>
                <ImagesManagement></ImagesManagement>
            </TabPane>
        ),
    },
];

function GmsProvider({ children }) 
{
    const [tabs, setTabs] = useState(DEFAULT_TABS);
    const [gmsActionMode, setGmsActionMode] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const currentTab = tabs[activeIndex];
    const loadingPool = useLoadingPool();
    const [closeGms, setCloseGms] = useState(false);
    const queryClient = useQueryClient();


    function handleTriggerOpenGms(actionMode = GmsActionMode.VIEW) 
    {
        setCloseGms(false);
        setGmsActionMode(actionMode);
    }

    function handleTriggerLinkIcon()
    {
        const filteredTabs = tabs.filter((tab) => tab.menuItem === TAB_MENU_ITEMS.ICONS);
        const tabIndex = filteredTabs.findIndex((tab) => tab.menuItem === TAB_MENU_ITEMS.ICONS);
        handleTriggerOpenGms(GmsActionMode.LINK_ICON);
        setTabs(filteredTabs);
        setActiveIndex(tabIndex);
    }

    function handleTriggerLinkIconImage()
    {
        const filteredTabs = tabs.filter((tab) => tab.menuItem === TAB_MENU_ITEMS.IMAGES);
        const tabIndex = filteredTabs.findIndex((tab) => tab.menuItem === TAB_MENU_ITEMS.IMAGES);
        handleTriggerOpenGms(GmsActionMode.LINK_ICON_IMAGE);
        setTabs(filteredTabs);
        setActiveIndex(tabIndex);
    }

    function handleTriggerLinkCoverImage()
    {
        const filteredTabs = tabs.filter((tab) => tab.menuItem === TAB_MENU_ITEMS.IMAGES);
        const tabIndex = filteredTabs.findIndex((tab) => tab.menuItem === TAB_MENU_ITEMS.IMAGES);
        handleTriggerOpenGms(GmsActionMode.LINK_COVER_IMAGES);
        setTabs(filteredTabs);
        setActiveIndex(tabIndex);
    }

    function handleTriggerReplaceImage()
    {
        const filteredTabs = tabs.filter((tab) => tab.menuItem === TAB_MENU_ITEMS.IMAGES);
        const tabIndex = filteredTabs.findIndex((tab) => tab.menuItem === TAB_MENU_ITEMS.IMAGES);
        handleTriggerOpenGms(GmsActionMode.REPLACE_IMAGE);
        setTabs(filteredTabs);
        setActiveIndex(tabIndex);
    }

    function handleCloseGms() 
    {
        setCloseGms(true);
        setGmsActionMode(null);
        setTabs(DEFAULT_TABS);
        setActiveIndex(0);
        setTimeout(() => 
        {
            setCloseGms(false);
        }, 50);
    }

    function handleChangeActiveIndex(index)
    {
        setActiveIndex(index);
    }


    const contextValue = {
        tabs,
        gmsActionMode,
        loadingPool,
        activeIndex,
        currentTab,
        closeGms,
        setTabs,
        setActiveIndex,
        setGmsActionMode,
        handleChangeActiveIndex,
        handleTriggerOpenGms,
        handleTriggerLinkIcon,
        handleTriggerLinkIconImage,
        handleTriggerLinkCoverImage,
        handleTriggerReplaceImage,
        handleCloseGms,
    };

    return (
        <GmsContext.Provider value={contextValue}>
            <Loader active={loadingPool.loading} />
            {children}
        </GmsContext.Provider>
    );
}

export default GmsProvider;
