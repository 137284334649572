import React from "react";
import { SubheaderBranding } from "../../layout/Subheader";
import ListViewFilteration from "../../listView/listViewFilteration/ListViewFilteration";
import ListViewTable from "../../listView/listViewTable/ListViewTable";
import ListViewSubHeader from "../../listView/listViewSubHeader/ListViewSubHeader";
import BrandingContext from "../../../store/BrandingContext";
import { MAP_TOOLS } from "../../../_constants/constants";
import EditSidebarPortal from "../../branding/editRightSidebar/EditSidebarPortal";
import ListViewConfirmationModal from "../../listView/listViewConfirmationModal/ListViewConfirmationModal";
import ListViewMultiInputEditorModal from "../../listView/listViewMultiInputEditorModal/ListViewMultiInputEditorModal";
import Footer from "../../layout/Footer";

import "./listView.css";
import { useListViewContext } from "../../../store/ListViewContext";

function ListView() 
{
    const bCtx = React.useContext(BrandingContext);    
    const closeEditBox = () =>  !bCtx.state.editUnsavedChanges && bCtx.state.selectedTool === MAP_TOOLS.EDIT && bCtx.refreshMap();
    const { showMultiInputEditor } = useListViewContext();

    return (
        <React.Fragment>           
            <ListViewConfirmationModal />
            {
                showMultiInputEditor && <ListViewMultiInputEditorModal />
            }
          
            <EditSidebarPortal onClose={() => bCtx.selectSelectedTool(undefined)} />
            <div className="listview-n" onClick={closeEditBox} >
                <SubheaderBranding
                    showTools={false}
                    showStylesAndThemesButton={false}
                />
                <div className="listview-n-main">
                    <ListViewFilteration />
                    <ListViewSubHeader></ListViewSubHeader>                
                    <ListViewTable />
                </div>
                <Footer/>
            </div>
        </React.Fragment>
    );
}

export default ListView;



