import React from "react";
import "./ImageUploadMainWrapper.css";

function ImageUploadMainWrapper({ children }) 
{
    return (
        <div className="image-upload-main-wrapper">
            {children}
        </div>
    );
}

export default ImageUploadMainWrapper;