const { GeoJsonAccess } = require("./geoJsonAccess");
const { PointAccess } = require("./pointAccess");
const { getAzimuth, getDistanceToThePoint, getPointRelativeToLine, IS_EQUAL_COS_RADIAN_TH, isClose, IS_EQUAL_DELTA_TH } = require("./mathExtensions");
const { ShapeTypes } = require("../utils/entityTypes");
const { PointRelativeToLine } = require("./enums");
const { mercatorCoordinateArrayToGpsLocations, gpsCoordinateArrayToMercatorLocations } = require("../utils/map.utils");

// NOTE: Always import LineStringAccess before PointAccess

const LineStringAccess = class extends GeoJsonAccess
{
    constructor(coordinates, isMercator = true)
    {
        super();

        this.geometry = {
            type: ShapeTypes.LINE_STRING,
            coordinates
        };

        this.isMercator = isMercator;
    }

    /**
     * Retrieves the last coordinate of the LineString geometry.
     *
     * @return {Array<number>} The last coordinate of the LineString geometry.
     */
    getEndCoordinate()
    {
        return this.geometry.coordinates[this.geometry.coordinates.length - 1];
    }

    /**
     * Retrieves the first coordinate of the geometry.
     *
     * @return {Array<number>} The first coordinate of the geometry.
     */
    getStartCoordinate()
    {
        return this.geometry.coordinates[0];
    }

    /**
     * Retrieves the starting point of the LineString geometry.
     *
     * @return {PointAccess} The starting point of the LineString geometry.
     */
    getStartPoint()
    {
        return new PointAccess(this.getStartCoordinate());
    }

    /**
     * Retrieves the last coordinate of the LineString geometry.
     *
     * @return {PointAccess} The last coordinate of the LineString geometry.
     */
    getEndPoint()
    {
        return new PointAccess(this.getEndCoordinate());
    }

    /**
     * Calculates the length of a line string.
     * previously in extensions "getLineStringLength"
     * @return {number} The length of the line string.
     */
    getLength()
    {
        const startPoint = this.getStartPoint();
        const endPoint = this.getEndPoint();
        return getDistanceToThePoint(startPoint, endPoint);
    }

    /**
     * Calculates the azimuth of a line string.
     * @returns {number} The azimuth angle of the line string in the range of 0 to 2π
     */
    getAzimuth()
    {
        const startPoint = this.getStartPoint();
        const endPoint = this.getEndPoint();
        return getAzimuth(startPoint, endPoint);
    }


    /**
     * Calculates the bounding box of a line segment defined by its start and end points.
     * 
     * @return {Array<number>} An array containing the coordinates of the bounding box in the order [xMin, yMin, xMax, yMax].
     */
    getBoundingBox() 
    {
        let xMax, xMin, yMax, yMin;

        const startPoint = this.getStartPoint();
        const endPoint = this.getEndPoint();

        xMax = Math.max(startPoint.getX(), endPoint.getX());
        xMin = Math.min(startPoint.getX(), endPoint.getX());
        yMax = Math.max(startPoint.getY(), endPoint.getY());
        yMin = Math.min(startPoint.getY(), endPoint.getY());

        return [xMin, yMin, xMax, yMax];
    }

    /**
     * Calculates a point to the left of a line string at a specified distance perpendicular to a given point on a line.
     *
     * @param {PointAccess} pointOnLine - The coordinates of the point on the line string.
     * @param {number} distance - The distance from the point to the left.
     * @return {PointAccess} The coordinates of the point to the left of the line string.
     */
    getPointToTheLeftOfLine(pointOnLine, distance = 5) 
    {
        const azimuth = this.getAzimuth();

        const pointToLeftOfLine = [
            pointOnLine.getX() - distance * Math.cos(azimuth),
            pointOnLine.getY() + distance * Math.sin(azimuth)
        ];

        return new PointAccess(pointToLeftOfLine);
    }

    /**
     * Calculates a point to the right of a line string at a specified distance perpendicular to a given point on a line.
     *
     * @param {PointAccess} pointOnLine - The coordinates of the point on the line string.
     * @param {number} distance - The distance from the point to the right.
     * @return {PointAccess} The coordinates of the point to the right of the line string.
     */
    getPointToTheRightOfLine(pointOnLine, distance = 5) 
    {
        const azimuth = this.getAzimuth();

        const pointToRightOfLine = [
            pointOnLine.getX() + distance * Math.cos(azimuth),
            pointOnLine.getY() - distance * Math.sin(azimuth)
        ];

        return new PointAccess(pointToRightOfLine);
    }

    /**
     * Calculates the intersection point between two infinite line segments.
     *
     * @param {LineStringAccess} line - The second line segment object.
     * @return {PointAccess|null} The coordinates of the intersection point as a Point object, or null if the lines are parallel.
     */
    getLineIntersection(line)
    {
        if (this.isParallel(line))
        {
            return null;
        }

        const line1StartPoint = this.getStartPoint();
        const line1EndPoint = this.getEndPoint();
        const line2StartPoint = line.getStartPoint();
        const line2EndPoint = line.getEndPoint();

        // set up Cramer's rule
        const line1A = line1EndPoint.getY() - line1StartPoint.getY();
        const line1B = line1StartPoint.getX() - line1EndPoint.getX();
        const line1C = line1A * line1StartPoint.getX() + line1B * line1StartPoint.getY();

        const line2A = line2EndPoint.getY() - line2StartPoint.getY();
        const line2B = line2StartPoint.getX() - line2EndPoint.getX();
        const line2C = line2A * line2StartPoint.getX() + line2B * line2StartPoint.getY();

        const determinant = line1A * line2B - line1B * line2A;

        if (isClose(determinant, 0))
        {
            return null
        }
        else if (line1StartPoint.isClose(line2StartPoint) || line1StartPoint.isClose(line2EndPoint))
        {
            return line1StartPoint;
        }
        else if (line1EndPoint.isClose(line2StartPoint) || line1EndPoint.isClose(line2EndPoint))
        {
            return line1EndPoint;
        }
        else
        {
            // Cramer's rule
            let x = (line1C * line2B - line2C * line1B) / determinant;
            let y = (line1A * line2C - line2A * line1C) / determinant;

            // check for -0
            if (1 / x === -Infinity)
            {
                x = 0;
            }

            if (1 / y === -Infinity)
            {
                y = 0;
            }

            return new PointAccess([x, y]);
        }
    }

    /**
     * Calculates the intersection point of the line and a line segment.
     * it will treat the line (this) as an infinite line vector and the line segment as a finite line
     * 
     * this will check if the intersection point is on the given line segment
     * 
     * NOTE: this is not used to find the intersections between two line segments
     *
     * @param {LineStringAccess} lineSegment - The line segment object.
     * @return {PointAccess|null} The coordinates of the intersection point as a  Point object, or null if the intersection point is not on the line segment.
     */
    getLineIntersectionWithLineSegment(lineSegment) 
    {
        const lineIntersection = this.getLineIntersection(lineSegment);

        if (lineIntersection)
        {
            if (!lineSegment.isPointOnLineSegment(lineIntersection))
            {
                return null
            }
        }

        return lineIntersection;
    }

    // getSegmentIntersection (segment) 
    // {
    //     return this.getLineIntersectionWithLineSegment(segment);
    // }

    /**
     * Calculates the projected point of a given point onto an infinite line.
     *
     * @param {PointAccess} point - The point object.
     * @return {PointAccess} The projected point onto the line.
     */
    getProjectedPointOntoLine(point)
    {
        if (this.isPointOnLine(point))
        {
            return point;
        }

        const startPoint = this.getStartPoint();
        const endPoint = this.getEndPoint();

        const lVec = new PointAccess([endPoint.getX() - startPoint.getX(), endPoint.getY() - startPoint.getY()]);
        const pVec = new PointAccess([point.getX() - startPoint.getX(), point.getY() - startPoint.getY()]);

        const coefficient = (pVec.getX() * lVec.getX() + pVec.getY() * lVec.getY()) / (lVec.getX() * lVec.getX() + lVec.getY() * lVec.getY());
        const locOnL = new PointAccess([coefficient * lVec.getX(), coefficient * lVec.getY()]);

        const xVal = startPoint.getX() + locOnL.getX();
        const yVal = startPoint.getY() + locOnL.getY();

        return new PointAccess([xVal, yVal]);
    }

    /**
     * Calculates the projected point onto a line segment or the closest end point.
     *
     * @param {PointAccess} point - The point object.
     * @return {PointAccess} The projected point onto the line segment or the closest end point.
     */
    getProjectedPointOntoLineSegmentOrEndPoint(point) 
    {
        const projectedPoint = this.getProjectedPointOntoLine(point);

        if (projectedPoint !== null && this.isPointOnLineSegment(projectedPoint))
        {
            return projectedPoint;
        }

        //else find the closest edge point
        const lineSegmentStart = this.getStartPoint();
        const lineSegmentEnd = this.getEndPoint();

        return getDistanceToThePoint(point, lineSegmentStart) < getDistanceToThePoint(point, lineSegmentEnd)
            ? lineSegmentStart
            : lineSegmentEnd;
    }


    /**
    * Checks if two line segments are parallel.
    * lines are parallel if the dot product is 1 
    * @param {LineStringAccess} line - The second line segment object.
    * @return {boolean} True if the line segments are parallel, false otherwise.
    */
    isParallel(line) 
    {
        const l1StartPoint = this.getStartPoint();
        const l1EndPoint = this.getEndPoint();

        const l2StartPoint = line.getStartPoint();
        const l2EndPoint = line.getEndPoint();

        const line1Length = this.getLength();
        const line2Length = line.getLength();

        const dot = (l1EndPoint.getX() - l1StartPoint.getX()) * (l2EndPoint.getX() - l2StartPoint.getX())
            + (l1EndPoint.getY() - l1StartPoint.getY()) * (l2EndPoint.getY() - l2StartPoint.getY());

        const dot_norm = dot / (line1Length * line2Length);

        return Math.abs(dot_norm) >= IS_EQUAL_COS_RADIAN_TH;
    }

    /**
     * Checks if a point is on a line.
     *
     * @param {Point} point - The point object.
     * @return {boolean} Returns true if the point is on the line, false otherwise.
     */
    isPointOnLine(point) 
    {
        const relativeToLine = getPointRelativeToLine(point, this);

        return relativeToLine === PointRelativeToLine.On;
    }

    /**
     * Checks if a point is on a line segment.
     *
     * @param {PointAccess} point - The point object.
     * @return {boolean} Returns true if the point is on the line segment, false otherwise.
     */
    isPointOnLineSegment(point) 
    {
        const startPoint = this.getStartPoint();
        const endPoint = this.getEndPoint();

        if (startPoint.isClose(point) || endPoint.isClose(point))
        {
            return true;
        }

        if (!this.isPointOnLine(point))
        {
            return false;
        }

        const segmentBB = this.getBoundingBox();

        const pointX = point.getX();
        const pointY = point.getY();
        const minX = segmentBB[0];
        const minY = segmentBB[1];
        const maxX = segmentBB[2];
        const maxY = segmentBB[3];

        // if point is on line, check line segment bounds
        return pointY - minY >= -IS_EQUAL_DELTA_TH &&
            maxY - pointY >= -IS_EQUAL_DELTA_TH &&
            pointX - minX >= -IS_EQUAL_DELTA_TH &&
            maxX - pointX >= -IS_EQUAL_DELTA_TH;
    }

    /**
     * Converts a lineString from the current coordinate system to the WGS84 coordinate system.
     *
     * @return {LineStringAccess} A new LineStringAccess object representing the point in the WGS84 coordinate system.
     */
    toWGS84()
    {
        let coordinates = this.getCoordinates();

        if (!this.isMercator)
        {
            return new LineStringAccess(coordinates, false);

        }

        const gpsCoords = mercatorCoordinateArrayToGpsLocations(coordinates);
        return new LineStringAccess(gpsCoords, false);
    }

    /**
     * Converts a LineString from the current coordinate system to the Mercator coordinate system.
     *
     * @return {LineStringAccess} A new LineStringAccess object representing the point in the Mercator coordinate system.
     */
    toMercator()
    {
        let coordinates = this.getCoordinates();

        if (this.isMercator)
        {
            return new LineStringAccess(coordinates);
        }

        const mercatorCoords = gpsCoordinateArrayToMercatorLocations(coordinates);
        return new LineStringAccess(mercatorCoords);
    }
}

exports.LineStringAccess = LineStringAccess;