import React from "react";
import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars-2";
import moment from "moment";
import { Button, Header, Segment, Portal, Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

export const NotificationsPopup = ({ list, loadingMoreNotifications, onNotificationClick, onReadMoreClick, onMarkAllNotificationsReadClick, onClose }) =>
{
    const trans = useTranslation().t;
    const [styleAdjustment, adjustmentStyle] = React.useState();

    React.useEffect(() =>
    {
        const subheader = document.querySelector(".subheader");
        if (!subheader) adjustmentStyle({ paddingTop: "20px" });
    }, []);

    const notificationByDate = React.useMemo(() =>
    {
        const notificationByDate = {};

        list.forEach((notification) => 
        {

            const dateHeader = moment(notification.createdAt).format("D MMM YYYY");

            if (!notificationByDate[dateHeader])
            {
                const calendarHeader = moment(notification.createdAt).calendar({
                    sameDay: "[Today, ]",
                    lastDay: "[Yesterday, ]",
                    lastWeek: "[Last Week, ]",
                    sameElse: "[]"
                });

                notificationByDate[dateHeader] = {
                    dateHeader,
                    calendarHeader,
                    date: new Date(notification.createdAt).setHours(0, 0, 0, 0, 0),
                    list: []
                };
            }

            notificationByDate[dateHeader].list.push(notification);
        });

        return Object.values(notificationByDate).sort((a, b) => 
        {
            if (a.date < b.date) return 1;
            if (a.date > b.date) return -1;
            return 0;
        });

    }, [list]);

    const hasUnreadNotifications = React.useMemo(() => list.some((n) => n.readAt === undefined), [list]);

    return (
        <Portal open={true} closeOnDocumentClick={true} onClose={(e)=>{
            e.stopPropagation()
            onClose()
        }} >
            <Segment className="notifications-cover">
                <div className="bg-overlay" onClick={onClose}></div>
                <div className="notifications-box" style={styleAdjustment}>
                    <Header>
                        <Button className="notify-back" onClick={onClose}>
                            <Icon name="arrow right" />
                        </Button>
                        {trans("NotificationsPopup.Notifications")}
                        {
                            !hasUnreadNotifications
                            && <img src={"/img/icon-notification-check.svg"} alt={trans("NotificationsPopup.Mark_all_notifications_as_read")} className="notify-check"/>
                        }
                        {
                            hasUnreadNotifications
                            && <img src={"/img/icon-notifications-read.svg"} alt={trans("NotificationsPopup.All_notifications_have_been_read")} className="notify-check" onClick={onMarkAllNotificationsReadClick} />
                        }
                    </Header>
                    <Scrollbars autoHeight autoHeightMin={"calc(1vh)"} autoHeightMax={"calc(100vh - 170px)"}>
                        <Segment basic className="aligner">
                            <div className="notify-group">
                                {
                                    notificationByDate.map((dateList) => (
                                        <div key={dateList.dateHeader} >
                                            <Header as="h6">{dateList.calendarHeader} {dateList.dateHeader}</Header>
                                            {
                                                dateList.list.map((item) => <NotifyItem key={item._id} notification={item} onClick={onNotificationClick} />
                                                )
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                            <div>
                                {!loadingMoreNotifications
                                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                    && <a style={{ cursor: "pointer" }} onClick={onReadMoreClick}>{trans("NotificationsPopup.Load_More")}</a>
                                }
                                {loadingMoreNotifications
                                    && <span>{trans("NotificationsPopup.Loading___")}</span>
                                }
                            </div>
                        </Segment>
                    </Scrollbars>
                </div>
            </Segment>
        </Portal>
    );
};

NotificationsPopup.propTypes =
{
    list: PropTypes.array.isRequired,
    loadingMoreNotifications: PropTypes.bool.isRequired,
    onNotificationClick: PropTypes.func.isRequired,
    onReadMoreClick: PropTypes.func.isRequired,
    onMarkAllNotificationsReadClick: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
};

export const NotifyItem = React.memo(({ notification, onClick }) =>
{
    const unread = !notification.readAt;
    const time = moment(notification.createdAt).format("h:mm a");

    const handleOnClick = React.useCallback(() => onClick(notification), [notification, onClick]);

    return (
        <Segment basic className="notify-item" onClick={handleOnClick}>
            <div dangerouslySetInnerHTML={{ __html: notification.message }} />
            <span className="time">@ {time}</span>
            {unread && <span className="unread-dot" />}
        </Segment>
    );
});

NotifyItem.displayName = "NotifyItem";

NotifyItem.propTypes =
{
    notification: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired
};