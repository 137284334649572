import React, { useContext } from "react";
import { Button, Icon, Popup } from "semantic-ui-react";
import { ModalLayout } from "../../popups/ModalLayout";
import { CategoryContext } from "../../../store/CategoryProvider";
import BrandingContext from "../../../store/BrandingContext";
import { CategoryList } from "./CategorySidebar";
import { arrayMove } from "mapsted.utils/arrays";
import { useMap, useRequest } from "ahooks";
import "./EditCategoryModal2.css";
import { useTranslation } from "react-i18next";

export const ReorderCategoryModal = ({ reorderModalOpen, setReorderModalOpen }) =>
{
    /** @type {typeof import("../../../_intl/resources.json")["en"]["translation"]["reorder settings modal"]} */
    const trans = useTranslation().t("reorder settings modal");

    const categoryContext = useContext(CategoryContext);
    const { state: { propertyId, properties } } = useContext(BrandingContext);
    const { categoryGroups, getChildrenOf, getCategoriesFromIds, reorderCategories } = categoryContext;
    const categoryGroupIds = categoryGroups.map((group) => group._id);
    const parent = properties?.[propertyId];
    /**
     * reorderedListsMap: A map of all lists in the tree that have been reordered so far.
     * - Map is of type {"id": {"order": {category}[], rootType: "category"}, ...}
     * - "order" property contains list of category objects in the new order.
     * - "id", the key of the map, can be one of two things:
     *      If the list that has been reordered is not a group, then key is a category id and rootType="category".
     *      Otherwise, key is the current property id and rootType="property".
     */
    const [reorderedListsMap, mapOps] = useMap();
    /**
     * expandedListsMap: A map storing whether each sublist in the tree is expanded.
     * - Ensures that sublists that the user has open for reordering remain expanded on reorder.
     * - Map is of type Key: id, Value: boolean
     */
    const [, expandedListsMapOps] = useMap([]);

    const onSortEnd = ({ parent, oldIndex, newIndex }) =>
    {
        if (oldIndex == newIndex) return;

        const { _id: parentId, categoryRoots } = parent;
        const rootType = categoryRoots ? "property" : "category";

        /**
         * If key parent's order array exists in map then get it from the map, otherwise get it from the category context:
         * Get parent's subcategory list and sort, otherwise parent is a property id, so get list of groups and sort them.
         */
        const arrayToReorder = (mapOps.get(parentId)?.order) || (getCategoriesFromIds([parentId])[0] ? getChildrenOf(getCategoriesFromIds([parentId])[0]) : categoryGroups);

        const rearranged = arrayMove(arrayToReorder, oldIndex, newIndex);
        const newValue = { order: rearranged, rootType };

        mapOps.set(parentId, newValue);
    };

    const onClose = () =>
    {
        setReorderModalOpen(false);
        mapOps.reset();
        expandedListsMapOps.reset();
    };

    const submitData = () =>
    {
        const putData = [];
        reorderedListsMap.forEach(({ order, rootType }, id) =>
        {
            putData.push({ rootId: id, rootType, order: order.map((c) => c._id) });
        });
        return reorderCategories(putData);
    };

    const { loading, run } = useRequest(() => submitData(), { manual: true, onSuccess: onClose });

    const Heading = () => (
        <span>
            {trans["heading"]}
            <Popup
                content={trans["heading popup content"]}
                position="bottom center"
                style={{ zIndex: "1901" }}
                trigger={<Icon className="infoIconCategory" name="question circle" color="blue" size="small" />}
            />
        </span>
    );

    return (
        <ModalLayout scrolling
            open={reorderModalOpen}
            onClose={onClose}
            className="editCategoryModal"
            heading={<Heading />}
            actions={<Button primary color="orange" content={trans["save"]} onClick={run} loading={loading} />}
        >
            <div className="categoryGroupModal">
                <CategoryList
                    key={"list-0"}
                    categoryList={categoryGroupIds}
                    editingEnabled={false}
                    reorderable={true}
                    onSortEnd={onSortEnd}
                    reorderedListsMap={reorderedListsMap}
                    expandedListsMapOps={expandedListsMapOps}
                    parent={parent}
                />
            </div>
        </ModalLayout>
    );
};
