import React, { useContext } from "react";
import { useSetState } from "ahooks";
import { ButtonIcon } from "../../../common/ButtonIcon";
import { Checkbox, Popup } from "semantic-ui-react";
import { DEFAULT_LANGUAGE_CODE } from "mapsted.maps/utils/map.constants";
import
{
    SortableElement,
    SortableHandle
} from "react-sortable-hoc";
import DeleteErrorModal from "./DeleteErrorModal";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import { MapOverlaysContext } from "../../../../store/MapOverlaysContext";

const OverlayConfigurationItem = (
    {
        showreorderModal = false,
        onEdit,
        mapOverlay,
        recenterMapOnOverlay,
        handleCopyMapOverlay,
        handleDeleteMapOverlay,
        onPreviewClick,
        disableEditAndDelete,
        trans,
        index,
        isChecked,
        onCheck,
        onUnCheck,
    }) => 
{

    const [state, setState] = useSetState({
        deleteModalOpen: false,
        deleteError: false
    });

    const { overlayTemplates } = useContext(MapOverlaysContext);

    const handleDeleteClick = async () => 
    {
        const deleteResult = await handleDeleteMapOverlay(mapOverlay._id);

        setState({
            deleteModalOpen: false
        });

        if (!deleteResult?.success) 
        {
            setState({
                deleteError: true
            });
        }
    };

    function renderOverLayList()
    {
        if (showreorderModal)
        {
            const overlayName = mapOverlay.name[DEFAULT_LANGUAGE_CODE];
            const mapOverlayName = overlayName.length >= 56 ? `${overlayName.slice(0, 57)}...` : overlayName;
            const DragHandle = SortableHandle(() => <ButtonIcon className="overlayItemIcon" icon="settings-item" />);
            const SortableItem = SortableElement(() => <div className="modal-overlayList"><DragHandle></DragHandle><p className="activitySidebar-overlayName">{mapOverlayName}</p></div>);
            return <SortableItem index={index} value={mapOverlay}></SortableItem>;
        }
        return <div className="overlayConfigurationItem">
            {
                disableEditAndDelete ? <Popup
                    content={trans("MapOverlaysActivitySideBar.Cannot_Delete_Message")}
                    basic
                    trigger={<Checkbox 
                        className="checkboxGroup"
                        disabled={disableEditAndDelete}
                    />} />:<Checkbox 
                    className="checkboxGroup"
                    disabled={disableEditAndDelete}
                    checked={isChecked}
                    onChange={(e, data) => 
                    {
                        if (data.checked)
                        {
                            onCheck(mapOverlay._id);
                        }
                        else
                        {
                            onUnCheck(mapOverlay._id);
                        }
                    }}
                />
            }
            <img
                src="/img/icon-search-location.svg"
                className="activitySectionPreview"
                alt=""
                onClick={() => onPreviewClick(mapOverlay._id)} />
            <p className="activitySidebar-overlayName" onClick={() => recenterMapOnOverlay(mapOverlay._id, true)}>
                {mapOverlay.name[DEFAULT_LANGUAGE_CODE]}
                {
                    !!mapOverlay.templateId && <Popup
                        position="top center"
                        className="map-overlay-template-popup"
                        content={overlayTemplates.find((template) => template.templateId === mapOverlay.templateId)?.name?.[DEFAULT_LANGUAGE_CODE] || ""}
                        trigger={<img
                            className="overlay-template-icon"
                            src={"/img/icon-overlay-template.svg"}
                            style={{ height: "14px", width: "14px" }}
                            alt="Group Name"
                        />}></Popup>
                }
            </p>
            <div className="overlayConfigActions">
                <Popup
                    content={trans("MapOverlaysActivitySideBar.Copy_MapOverlay_Message")}
                    basic
                    trigger={<ButtonIcon icon="copy" onClick={() => handleCopyMapOverlay(mapOverlay._id)} />} />
                {
                    disableEditAndDelete ? <>
                        <Popup
                            content={trans("MapOverlaysActivitySideBar.Cannot_Edit_Message")}
                            basic
                            trigger={<span><ButtonIcon icon="edit" disabled /></span>} />
                        <Popup
                            content={trans("MapOverlaysActivitySideBar.Cannot_Delete_Message")}
                            basic
                            trigger={<span><ButtonIcon icon="delete" disabled /></span>} />
                    </> : <>
                        <ButtonIcon icon="edit" onClick={() => onEdit(mapOverlay._id)} />
                        <DeleteConfirmationModal
                            mapOverlay={mapOverlay}
                            onDeleteIconClick={() => setState({
                                deleteModalOpen: true,
                                deleteError: false
                            })}
                            onDeleteClick={handleDeleteClick}
                            deleteModalOpen={state.deleteModalOpen}
                            onClose={() => setState({ deleteModalOpen: false, deleteError: false })}
                            trans={trans}
                        />
                    </>
                }
                <DeleteErrorModal
                    deleteErrorModalOpen={state.deleteError}
                    onClose={() => setState({ deleteModalOpen: false, deleteError: false })}
                    trans={trans}
                />
            </div>
        </div>;
    }

    return (
        renderOverLayList()
    );
};

export default OverlayConfigurationItem;