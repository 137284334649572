export const DEFAULT_MAX_FILE_SIZE_BYTES = 5 * 1024 * 1024;

/**
 * Parses and returns uploaded image.
 * @param {String} fileInputId - ID of the file input.
 * @param {Function} setImageAsDataURL, setFile - A callback to return the parsed image.
 */
export const handleUploadFile = (domObject, setImageAsDataURL, setFile) =>
{
    if (!domObject)
    {
        return;
    }

    const reader = new FileReader();

    reader.addEventListener("load", (e) =>
    {
        setImageAsDataURL && setImageAsDataURL(e.target.result);
        domObject.value = "";

    }, false);

    const files = domObject.files;

    if (files && files.length > 0)
    {
        const file = files[0];

        setFile(file);
        reader.readAsDataURL(file);
    }
};

// https://stackoverflow.com/questions/934012/get-image-data-url-in-javascript
export const getBase64FromImageUrl = (url : string, onLoad : Function, onError: Function) =>
{
    const img = new Image();

    img.setAttribute("crossOrigin", "anonymous");

    img.onload = function onload ()
    {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        const dataURL = canvas.toDataURL("image/png");

        onLoad(dataURL);
    };

    img.onerror = () => {
        if(onError) {
            onError();
        }
    };

    img.src = url;
};

/**
 * Returns file size in bytes
 * https://stackoverflow.com/questions/13378815/base64-length-calculation
 **/
export const getFileSizeFromBase64 = (base64) =>
{
    let n = base64.length - "data:image/png;base64,".length;
    let y = 0;

    // Find paddinf
    if (base64[n - 3] === "=")
    {
        y = 3;
    }
    else if (base64[n - 2] === "=")
    {
        y = 2;
    }
    else if (base64[n - 1] === "=")
    {
        y = 1;
    }

    let sizeInBytes = 4 * (n / 3) - y;
    return sizeInBytes;
};

/**
 * https://chrisboakes.com/how-a-javascript-debounce-function-works/
 * @param {Function} callback
 * @param {Number} wait
 */
export const debounce = (callback, wait) =>
{
    let timeout;
    return (...args) =>
    {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => callback.apply(context, args), wait);
    };
};

export const DEFAULT_MAX_GALLERY_LENGTH = 9;
