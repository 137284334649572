import React from "react";
import classNames from "classnames";
import { Checkbox } from "semantic-ui-react";
import "./CheckboxGroup.css";

export const CheckboxGroup = ({
    className = undefined,
    label,
    reduce = false,
    fluid = false,
    ...rest
}) => (
    <Checkbox className={classNames("checkboxGroup", className, { reduced: reduce }, { fluid: fluid } )} {...rest} label={label}/>
);
