import { DEFAULT_LANGUAGE_CODE } from "mapsted.maps/utils/map.constants";

const { convertFeatureGeometryToGeoJSON } = require("mapsted.maps/mapFunctions/features");
const { fromCircle } = require("ol/geom/Polygon");
const turf = require("@turf/turf");

export const getIntersectingEntities = (geometry, entities) => 
{

    let intersectingGeometry = geometry;
    if (geometry.getType() === "Circle")
    {
        intersectingGeometry = fromCircle(geometry);
    }

    const intersectingGeoJSON = convertFeatureGeometryToGeoJSON(intersectingGeometry);

    return entities
        .filter((entity) => entity && entity.feature)
        .filter((entity) =>
        {
            const entityGeometry = entity.feature.getGeometry();
            const entityGeoJSON = convertFeatureGeometryToGeoJSON(entityGeometry);
            return turf.booleanIntersects(entityGeoJSON, intersectingGeoJSON);
        });
};

export const getOverlayTemplatesDropdownOptions = (overlayTemplates) => overlayTemplates.map(({ name, templateId }) => ({
    key: templateId,
    text: name[DEFAULT_LANGUAGE_CODE],
    value: templateId,
}));