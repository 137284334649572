import React, { useState, useContext } from "react";
import { ModalLayout } from "../../../../popups/ModalLayout";
import { Trans, useTranslation } from "react-i18next";
import { Button, Header, Grid } from "semantic-ui-react";
import { ErrorLabel } from "../../../../elements/ErrorLabel";
import { UploadTab } from "../../../../popups/UploadImageModal";
import BrandingContext, { useCurrentProperty } from "../../../../../store/BrandingContext";
import { useMutation } from "react-query";
import brandingApi from "../../../../../_api/branding.api";

import "./ListViewUploadCSVModal.css";
import LinkButton from "../../../../common/LinkButton";
import LanguageDropdown from "../../../../common/languageDropdown/LanguageDropdown";
import { TextGroup } from "../../../../common/TextGroup";
import { DEFAULT_LANGUAGE_CODE } from "../../../../../_constants/constants";

const missingFieldDisplayNames = {
    longName: "Store Long Name",
    _id: "Entity Id",
    lang: "lang",
    showLogo: "Show Logo flag",
    showName: "Show Name flag"
};

const extractLang = (field) =>
{
    const fieldParts = field?.split(".") || [];
    
    if (fieldParts.length === 2)
    {
        return fieldParts[0];
    }
    else
    {
        return field || "";
    }
};

export const ListViewUploadCSVModal = ({
    isOpen,
    close
}) =>
{
    const trans = useTranslation().t;

    const bCtx = useContext(BrandingContext);
    const { propertyId, buildingId, floorId } = bCtx.state;

    const property = useCurrentProperty();
    const countryCode = property?.country;

    const { mutate, isLoading } = useMutation(
        brandingApi.uploadListViewFile,
        {
            mutationKey: "LIST_VIEW_UPLOAD_CSV",
        }
    );

    const [file, setFile] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [lang, setLang] = useState(DEFAULT_LANGUAGE_CODE);

    const handleCsvDownload = (e) =>
    {
        e.preventDefault();
        bCtx.downloadListViewCSV(propertyId, buildingId, floorId, lang);
    };

    const handleExcelDownload = (e) =>
    {
        e.preventDefault();
        bCtx.downloadListViewExcel(propertyId, buildingId, floorId, lang);
    };

    const handleBuildingLevelCsvDownload = (e) =>
    {
        e.preventDefault();
        bCtx.downloadListViewCSV(propertyId, buildingId, undefined, lang);
    };

    const handleBuildingLevelExcelDownload = (e) =>
    {
        e.preventDefault();
        bCtx.downloadListViewExcel(propertyId, buildingId, undefined, lang);
    };

    const handleFileChange = (files) =>
    {
        setErrorMessage("");
        setFile(files[0]);
    };

    const handleFileUpload = () =>
    {

        if (!file) return;

        const formData = new FormData();

        formData.append("file", file, file.name);

        const { propertyId } = bCtx.state;

        let buildingId = bCtx.state.buildingId || "";

        mutate({ formData, propertyId, countryCode, buildingId }, {
            onSuccess: () =>
            {
                if (buildingId)
                {
                    bCtx.updateFloorData();
                }
                else
                {
                    bCtx.updatePropertyData();
                }
                handleOnClose();
            },
            onError: (res) =>
            {
                let errorMessage = trans("ListViewUploadCSVModal.Server_Error");
                if (res.errorType === "InvalidDataError")
                {
                    errorMessage = <div className="error-msg">
                        {trans("ListViewUploadCSVModal.Row_Number_Display", { rowNum: res.row })}
                        <br />
                        {trans("ListViewUploadCSVModal.Invalid_Data_Error", { invalidField: res.invalidFields.length ? res.invalidFields[0] : "" })}
                    </div>;
                }
                else if (res.errorType === "MissingMandatoryFieldsError")
                {
                    errorMessage = <div className="error-msg">
                        {trans("ListViewUploadCSVModal.Row_Number_Display", { rowNum: res.row })}
                        <br />
                        {trans("ListViewUploadCSVModal.Missing_Mandatory_Fields_Error", { missingField: missingFieldDisplayNames[extractLang(res.missingFields)] })}
                    </div>;
                }
                else 
                {
                    errorMessage=<div className="error-msg">
                        {trans("ListViewUploadCSVModal.Invalid_Response")}
                    </div>;
                }
                setErrorMessage(errorMessage);
            }
        });
    };

    const handleOnClose = () =>
    {
        setErrorMessage("");
        setLang(DEFAULT_LANGUAGE_CODE);
        setFile(null);
        if (close)
        {
            close();
        }
    };

    return (
        <ModalLayout
            open={isOpen}
            onClose={handleOnClose}
            heading={trans("ListView.Upload_File")}
            className="listView-csv-modal"
            size="mini"
            actions={
                <Button
                    loading={isLoading}
                    disabled={!file || isLoading || errorMessage }
                    color="orange"
                    content={trans("ListViewUploadCSVModal.Upload")}
                    onClick={handleFileUpload}
                />
            }
        >
            {propertyId && !buildingId && !floorId ?  <p className="p-modal csvUploadModalText">
                <Trans i18nKey="ListViewUploadCSVModal.Property_CSV_template" components={
                    { 
                        templateCsvLink: <LinkButton onClick={handleCsvDownload} />,
                        templateExcelLink: <LinkButton onClick={handleExcelDownload} />
                    }}/>
            </p> : <p className="p-modal csvUploadModalText">
                <Trans i18nKey="ListViewUploadCSVModal.CSV_template" components={
                    { 
                        templateCsvLink: <LinkButton onClick={handleCsvDownload} />,
                        templateExcelLink: <LinkButton onClick={handleExcelDownload} />,
                        buildingLevelTemplateCsvLink: <LinkButton onClick={handleBuildingLevelCsvDownload} />,
                        buildingLevelTemplateExcelLink: <LinkButton onClick={handleBuildingLevelExcelDownload} />
                    }}/>
            </p>}
            

            <TextGroup heading={trans("LanguageSelector.Choose_Language")} className="languageSelectorDropdown">
                <LanguageDropdown 
                    allowedLangs={bCtx.getCurrentLangOptions()}
                    activeLang={lang}
                    onChange={(lang) => setLang(lang)}
                />
            </TextGroup>

            <div className="fileUploader" >
                {file ? (
                    <Grid centered>
                        <Grid.Row>
                            <Header content={file.name} />
                        </Grid.Row>
                        <Grid.Row style={{ marginTop: "20px" }}>
                            <Button
                                basic
                                icon="refresh"
                                content={trans("UploadCSVModal.Change")}
                                onClick={() => setFile(null)}
                            />
                        </Grid.Row>
                        <Grid.Row style={{ marginTop: "20px" }}>
                            {errorMessage && (
                                <ErrorLabel>
                                    {errorMessage}
                                </ErrorLabel>
                            )}
                        </Grid.Row>
                    </Grid>
                ) : (
                    <UploadTab
                        acceptableImageFileFormats={".csv,.xlsx"}
                        onChange={handleFileChange}
                        returnRawFile
                        invalidFileTypeErrorMessage={trans("ListViewUploadCSVModal.Invalid_File_Type_Error")}
                    />
                )}
            </div>
        </ModalLayout>
    );
};

