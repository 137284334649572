import { isEmpty, get as getDeepValue, set as setDeepValue, kebabCase, toNumber } from "lodash";
import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import BrandingContext from "../../store/BrandingContext";
import { useClearableState, useLoadingPool, useMergedObjects } from "../../_utils/hooks";
import { SETTINGS_QUERIES, usePropertySettings } from "./settings.queries";
import brandingApi from "../../_api/branding.api";
import EntityMapController from "mapsted.maps";
import { useSetState } from "ahooks";
import { MAP_THEMES } from "mapsted.maps/utils/map.constants";
import { filerUrl } from "../../_utils/utils";
import { useTranslation } from "react-i18next";
import { CMSEntityAccess } from "mapsted.maps/mapFunctions/entityAccess";

export const SettingsContext = createContext();

export const SettingsProvider = ({ children }) =>
{
    const queryClient = useQueryClient();
    const brandingContext = useContext(BrandingContext);
    const propertyId = brandingContext?.state?.propertyId;
    const buildingId = brandingContext?.state?.buildingId;
    const properties = brandingContext?.state?.properties;
    const loadingPool = useLoadingPool();
    const trans = useTranslation().t;

    const [settings] = usePropertySettings();
    const [changes, setChanges, clearChanges] = useClearableState({});

    const mergedSettings = useMergedObjects(settings, changes);

    const [PropertySettingMapData, setPropertySettingMapData] = useState({  mapData: undefined, entityLayers: undefined, boundaryPolygon: undefined, imageLayers: undefined });

    const [state] = useSetState({
       
        mapController: new EntityMapController({
            features: { text: true, images: true },
            filerUrl: filerUrl(""),
            accessor: CMSEntityAccess,
            theme: MAP_THEMES.CLASSIC
        })
    });

    const loadingPropertySettingMapData = async (propertyId) => 
    {
        if (!propertyId) return;        
      
        const loadingId = loadingPool.add();

        try 
        {
            const { data, success } = await brandingApi.getPropertyMapData(propertyId);
      
            if (success) 
            {
                const { entityLayers, boundaryPolygon, imageLayers } = state.mapController.initialize(data);
      
                setPropertySettingMapData({
                    mapData: data,
                    entityLayers,
                    imageLayers,
                    boundaryPolygon,
                    propertyId,
                });
            }
        }
        catch (error)
        {
            console.error("loadingPropertySettingMapData:", error);
        } 
        finally 
        {
            loadingPool.remove(loadingId);
        }
    };     

    // clear changes when property is changed   
    useEffect(() =>
    {
        loadingPropertySettingMapData(propertyId);
        clearChanges();
    }, [propertyId]);

    const {
        mutate: saveChanges,
        ...saveMutation
    } = useMutation({
        ...SETTINGS_QUERIES.SAVE_SETTINGS(propertyId),
        onSuccess: (result) =>
        {
            // update cached data
            queryClient.setQueryData(["get_property_settings", propertyId], result);

            // update building's default floor in BrandingContext state
            const { defaultFloor } = mergedSettings;
            if (!isEmpty(defaultFloor))
            {
                const newState = setDeepValue(
                    { ...brandingContext.state },
                    `properties.${propertyId}.buildings.${buildingId}.defaultFloorId`,
                    defaultFloor.floorNavId
                );
                brandingContext.updateState(newState);
            }            
         
            brandingContext.updateMapData();
            brandingContext.updateState({ settingsConfig: result });

            clearChanges();

        },
        onError: (err) => alert(err)
    });

  
    const validateMapModule = () =>
    {
        const allErrors = {};

        // validate public url
        {
            const path = "publicSubdomain";
            const url = getDeepValue(mergedSettings, path);
            if (Number.isFinite(toNumber(url)) || url !== kebabCase(url))
            {
                setDeepValue(allErrors, "publicSubdomain", ["Settings.Valid_numeric_slug"]);
            }
        }

        // validate fields for download popup and trigger
        {
            const path = "mobileAppSync.web.downloadApp";
            const { heading = "", subHeading = "", mobileApp = "",  playStoreLink = "", appStoreLink = "" } = getDeepValue(mergedSettings, `${path}.popup`, {});
            const { text  = "" } = getDeepValue(mergedSettings, `${path}.trigger`, {});

            const errors = [];
            if (!heading.trim())
            {
                errors.push("Settings.Main_heading_req");
            }
            if (!subHeading.trim())
            {
                errors.push("Settings.Sub_heading_req");
            }

            if (!mobileApp)
            {
                errors.push("Settings.Select_Hosted_App");
            }
            else if (mobileApp === "CUSTOM" && !(playStoreLink.trim() || appStoreLink.trim()))
            {
                errors.push("Settings.App_store_link");
            }

            if ((playStoreLink.trim() || appStoreLink.trim()) && !text.trim())
            {
                errors.push("Settings.Dwnld_text_required");
            }

            if (errors.length > 0)
            {
                setDeepValue(allErrors, path, errors);
            }

        }

        // validate custom download popup
        {
            const path = "mobileAppSync.mobile.downloadApp.popup";
            const { mainHeading = "", subHeading = "", logo } = getDeepValue(mergedSettings, path, {});

            const errors = [];
            if (!mainHeading.trim())
            {
                errors.push("Settings.Main_heading_req_value");
            }
            if (!subHeading.trim())
            {
                errors.push("Settings.Sub_heading_req");
            }
            if (isEmpty(logo))
            {
                errors.push("Settings.Logo_req");
            }

            if (errors.length > 0)
            {
                setDeepValue(allErrors, path, errors);
            }
        }

        // validate share ext. link popup & trigger
        {
            const path = "mobileAppSync.web.externalLink";
            const { text = "" } = getDeepValue(mergedSettings, `${path}.trigger`, {});
            const { url = "" } = getDeepValue(mergedSettings, `${path}.popup`, {});

            const errors = [];
            if (!text.trim())
            {
                errors.push("Settings.Dwnld_text_required");
            }
            if (!url.trim())
            {
                errors.push("Settings.External_link_req");
            }

            if (errors.length > 0)
            {
                setDeepValue(allErrors, path, errors);
            }
        }

        return allErrors;
    };

    const handleSaveChanges = () =>
    {
        if (settings.mobileAppSync?.active)
        {
            // validate updated info on "Mapsted Map Module"
            const validationErrors = validateMapModule();
            if (!isEmpty(validationErrors))
            {
                setChanges({ validationErrors });
                return;
            }
        }

        // remove validation errors before making request
        // eslint-disable-next-line no-unused-vars
        const { validationErrors, ...rest } = changes;
        saveChanges(rest);
    };

    const disableGeolocationWebSettings = useMemo(() =>
    {
        let disable = false;

        if (propertyId && properties?.[propertyId]?.buildings)
        {
            const currentProperty = properties[propertyId];
            const buildingIds = Object.keys(currentProperty.buildings);

            for (let i=0; i< buildingIds.length; i++)
            {
                const building = currentProperty.buildings[buildingIds[i]];

                if (building.floors?.length > 1)
                {
                    disable = true;
                    break;
                }
            }
        }

        return disable;
    }, [propertyId, properties]);

    const dirty = Object.values(changes).length > 0;
    const value = {
        settings,
        mergedSettings,
        mutate: setChanges,
        dirty,
        handleSaveChanges,
        saveMutation,
        loadingPool,
        PropertySettingMapData,
        disableGeolocationWebSettings,
    };
    return (
        <SettingsContext.Provider value={value}>
            {children}
        </SettingsContext.Provider>
    );
};
