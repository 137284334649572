
/**
 * Get value inside source object by path or a defualt value
 * @param {object} source
 * @param {string} path
 * @param {any} defaultValue
 */
exports.deepValue = (source, path, defaultValue) =>
{
    if (!source)
    {
        source = {};
    }

    const namePath = path.split(".");
    const pathLength = namePath.length - 1;

    let node = source;
    namePath.forEach((propName, index) =>
    {
        node = node[propName];

        if (index !== pathLength)
        {
            if (node === undefined || node === null)
            {
                node = {};
            }
        }
    });

    return node !== undefined ? node : defaultValue;
};

/**
 * Uses JSON parse and stringify functions to return a deep a copy of the given object
 * @param {*} object 
 * @returns 
 */
exports.deepCopy = (object) =>
{
    return JSON.parse(JSON.stringify(object));
}

exports.deepUpdateValue = (source, path, value) =>
{
    if (source == null) 
    {
        source = {};
    }

    let valuePosition = source;
    const namePath = path.split(".");
    const pathLength = namePath.length - 1;

    namePath.forEach((propName, index) =>
    {
        if (!valuePosition[propName])
        {
            valuePosition[propName] = {};
        }

        if (pathLength === index)
        {
            valuePosition[propName] = value;
        }
        else
        {
            valuePosition = valuePosition[propName];
        }
    });

    return source;
}