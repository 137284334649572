import React from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Image } from "semantic-ui-react";
import { DataListingTableColumn } from "../../pages/branding/listing/DataTableColumn";
import { PaginationBar } from "../../branding/listing/Pagination";
import { filerUrl } from "../../../_utils/utils";
import { StatusProcessingPopup } from "../StatusProcessingPopup";
import { useTranslation } from "react-i18next";

export const DashboardDataTable = ({ displayProperties, pagination, onPropertySelection }) =>
{
    let propertyListDisplay = displayProperties.length ? getPropertyDisplayElementsArray(displayProperties, onPropertySelection) : <EmptyDashboardList />;
    const trans = useTranslation().t;
    return (
        <>
            <div className="dataListingTableBox dashboardListing">
                <Scrollbars className="tableScroll tableScrollDashboard" autoHeight autoHeightMin="calc(1vh)" autoHeightMax="calc(80vh - 100px)" >
                    <DataListingTableHead trans={trans}/>
                    {propertyListDisplay}
                </Scrollbars>
            </div>
            <PaginationBar
                totalPages={pagination.totalPages}
                activePage={pagination.currentPage}
                setPage={pagination.setPage}
                startIndex={pagination.startIndex}
                endIndex={pagination.endIndex}
                total={pagination.nElements} />
        </>
    );
};

const getPropertyDisplayElementsArray = (displayProperties, onPropertySelection) => (
    displayProperties.map((propertyDetails) => (
        <TableRow
            key={propertyDetails._id}
            properyDetails={propertyDetails}
            onPropertySelection={onPropertySelection}
        />
    ))
);
const DataListingTableHead = ({ trans }) => (
    <div className="dataListingTableRow dataListingTableRow-head">
        <DataListingTableColumn detail={trans("Dashboard.property_name")} wide />
        <DataListingTableColumn detail={trans("Dashboard.location")} />
        <DataListingTableColumn detail={trans("Dashboard.date_created")} />
        <DataListingTableColumn detail={trans("Dashboard.date_processed")} />
        <DataListingTableColumn detail={trans("Dashboard.status")} />
    </div>
);

export const TableRow = ({ properyDetails, onPropertySelection }) => (
    <div className="dataListingTableRow">
        <DataListingTableColumn wide>
            {getPropertyIconImage(properyDetails)}
            <span style={{ cursor: "pointer" }} onClick={() => onPropertySelection(properyDetails._id)}>{properyDetails.name}</span>
        </DataListingTableColumn>
        <DataListingTableColumn detail={getPropertyLocation(properyDetails)} />
        <DataListingTableColumn detail={properyDetails.dateCreated} />
        <DataListingTableColumn detail={properyDetails.dateProcessed} />
        <DataListingTableColumn detail={<StatusProcessingPopup status={properyDetails.status} />} />
    </div>
);

const getPropertyLocation = (properyDetails) =>
{
    let propertyLocationComponents = [];

    if (properyDetails.city)
    {
        propertyLocationComponents.push(properyDetails.city);
    }

    if (properyDetails.state)
    {
        propertyLocationComponents.push(properyDetails.state);
    }

    return propertyLocationComponents.join(", ");
};

const getPropertyIconImage = (properyDetails) =>
{
    let iconImageElement = <Image as="span" src="/img/icon-building.jpg" />;

    if (properyDetails.iconImage)
    {
        const onError = (e) =>
        {
            e.target.onerror = null;
            e.target.src = "/img/icon-building.jpg";
        };

        iconImageElement = <Image as="span" src={filerUrl(properyDetails.iconImage)} onError={onError} />;
    }

    return iconImageElement;
};

const EmptyDashboardList = () => 
{
    const trans = useTranslation().t;
    return <div className="dataListingTableRow emptyDashboardList">
        <Image src="/img/icon-no-properties-found.svg" as="span" />
        <p>
            <strong>{        
                trans("Dashboard.no_properties_fnd")
            }
            </strong>
            {
                trans("Dashboard.try_adjusting_search")
            }        
        </p>
    </div>;
};
