import PropTypes from "prop-types";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useTranslation, withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import { Button, Container, Icon, Item, Label, Popup, Segment } from "semantic-ui-react";
import brandingApi from "../../_api/branding.api";
import serverApi from "../../_api/server.api";
import { BRANDING_STATUSES, BRANDING_STATUS_DESCRIPTION } from "../../_constants/branding";
import { ICON_PICKER_OPTIONS, MAP_TOOLS, SINGLE_LANG_INPUT_CODE, STATUS_DESCRIPTION } from "../../_constants/constants";
import { EDIT_TYPES, MAP_EDITOR_SHORTCUTS } from "../../_constants/mapEditor";
import BrandingContext from "../../store/BrandingContext";
import DashboardContext from "../../store/DashboardContext";
import MapEditorContext from "../../store/MapEditiorContext";
import { StoreSharingContext } from "../../store/StoreSharingProvider";
import CategorySideBar from "../branding/categoriesPortal/CategorySidebar";
import ActiveBoundaryPolygonArea from "../branding/map/BondaryPolygonArea/ActiveBoundaryPolygonArea";
import { PublishPopup } from "../branding/publish/PublishPopup";
import { ButtonBasic } from "../common/ButtonIcon";
import { Divide } from "../common/Divide";
import styles from "../pages/branding/branding.module.css";
import { ViewAllPopup } from "../popups/ViewAllPopup";
import { SyncStoreTypeModal } from "../syncStoreModals/SyncStoreTypeModal";
import { UnlockModal } from "./UnlockModal";
import { toast } from "sonner";
import MapEditPermissionWrapper from "../mapEditor/PermissionWrappers/MapEditPermissionWrapper";
import { Gms } from "../branding/Gms/Gms.jsx";

export const SubheaderDashboard = () => 
{
    const trans = useTranslation().t;
    return (
        <Subheader viewAll>
            <div className="status operational">
                <span>{trans("Subheader.All_Versions")} - </span>
                {trans("Subheader.Operational")}
            </div>
            <Button color="orange" className="property-button">
                <Link to="/dashboard/property">{trans("Subheader.Add_Property")}</Link>
            </Button>
        </Subheader>
    );
};

class SubheaderBrandingImpl extends React.Component 
{

    static contextType = BrandingContext;

    constructor() 
    {
        super();

        const toolPopupPrefrences = brandingApi.toolPopupPrefrences;
        this.gmsRef = React.createRef();
        this.state = {
            toolPopupPrefrences: toolPopupPrefrences || {},
            categoryBarOpen: false,
            galleryOpen: false,
        };

    }

    handleToolSelect = (toolName) => 
    {
        const { selectedTool } = this.context.state;

        // Check if tool is being selected not deselected
        if (selectedTool !== toolName) 
        {
            this.handlePopupOpen(toolName);
        }
        else 
        {
            this.handlePopupClose();
        }

        this.context.changeSelectedTool(toolName);
    };

    handlePopupClose = () => 
    {
        const { selectedTool } = this.context.state;
        const toolPopupPrefrences = { ...this.state.toolPopupPrefrences };

        if (toolPopupPrefrences[selectedTool] !== undefined) 
        {
            brandingApi.handleUpdateToolPopupPrefrences(selectedTool, toolPopupPrefrences[selectedTool]);
        }
    };

    handlePopupOpen = (selectedTool) => 
    {
        const { toolPopupPrefrences } = this.state;
        // check prefrence

        if (!toolPopupPrefrences[selectedTool] === true) 
        {
            this.setState({ categoryBarOpen: selectedTool === undefined ? this.state.categoryBarOpen : false });
        }
    };

    handleToolPopupPrefrenceChange = (newPrefrence) => 
    {
        const { selectedTool } = this.context.state;
        const toolPopupPrefrences = { ...this.state.toolPopupPrefrences };
        toolPopupPrefrences[selectedTool] = newPrefrence;

        this.setState({ toolPopupPrefrences });
    };

    handleToggleGallery = () => 
    {
        if (!this.state.categoryBarOpen) 
        {
            this.handleToolSelect(undefined);
        }
        this.gmsRef.current.openGms();
    };

    renderStatusText = () => 
    {
        const { propertyId, properties } = this.context.state;
        const trans = this.props.t;
        const selectedProperty = properties[propertyId];

        if (!!selectedProperty && !!selectedProperty.brandingStatus) 
        {
            return trans(BRANDING_STATUS_DESCRIPTION[selectedProperty.brandingStatus].i18nKey);
        }
        else 
        {
            // TODO REMOVE ONCE DB IS SURE TO HAVE BRANDING STATUS.
            return trans("Subheader.Draft_version_active");
        }
    };

    handleEditorButtonClick = () => 
    {
        this.props.history.push("/branding/MapEditor");
    };

    render() 
    {
        const { subhead, categoryBarOpen, galleryOpen } = this.state;
        const { selectedTool } = this.context.state;
        const trans = this.props.t;
        return (
            <Segment basic className="subheader">
                <Container fluid>
                    <Button className="toggleBtn" onClick={() => this.setState({ subhead: !subhead })}>
                        <Icon name={!subhead ? "bars" : "close"} size="large" color="blue" />
                    </Button>
                    <div className="subheaderToggle" hidden={!subhead}>
                        <SubheaderLeft dynamicContext={this.context} />
                    </div>
                    <div className="subheader-left branding">
                        <SubheaderLeft dynamicContext={this.context} />
                        { this.props.showActiveBoundaryPolygonArea && <ActiveBoundaryPolygonArea /> }
                    </div>
                    <div className="subheader-right">
                        {
                            this.props.showGallery && !this.props.isStylesAndThemes && <>
                                <ToolButton position="top center" onClick={this.handleToggleGallery} icon="gallery" tool={trans("Subheader.Gallery")} />
                                <Gms onDeleteImage={this.context.updateAllEntityData} onEditImage={this.context.updateAllEntityData} ref={this.gmsRef}></Gms>
                            </>
                        }

                        {this.props.showStylesAndThemesButton && <Link to={this.props.isStylesAndThemes ? "/branding" : "/branding/stylesAndThemes"}>
                            <ToolButton
                                position="top center"
                                active={this.props.isStylesAndThemes}
                                tool={trans("Subheader.Styles_Themes")}
                                icon="map-styles"
                            />
                        </Link>}


                        {(this.props.showEditorToggleButton) && (
                            <MapEditPermissionWrapper>
                                <Popup className="tooltipPopup"
                                    trigger={<ButtonBasic className="toolButtonEditor" icon="toolbar-manage-category" onClick={this.handleEditorButtonClick} />}
                                    size="small"
                                    inverted
                                    position="bottom center"
                                    on="hover"
                                    content="map editor" />
                            </MapEditPermissionWrapper>
                        )}


                        {(this.props.showShareButton && !this.props.isStylesAndThemes) && (
                            <>
                                <ShareStoreButton />
                                <SyncStoreTypeModal />
                            </>
                        )}
                        <div className={styles.toolbar}>

                            {
                                (this.props.showTools) && (<>
                                    <ToolButton active={selectedTool === MAP_TOOLS.EDIT} icon={MAP_TOOLS.EDIT} tool={trans("Subheader.Edit")} position="top center" name={MAP_TOOLS.EDIT} onClick={this.handleToolSelect} />
                                    <ToolButton active={selectedTool === MAP_TOOLS.ROTATE} icon={MAP_TOOLS.ROTATE} tool={trans("Subheader.Rotate")} position="top center" name={MAP_TOOLS.ROTATE} onClick={this.handleToolSelect} />
                                    <ToolButton active={selectedTool === MAP_TOOLS.MOVE_TEXT} icon={MAP_TOOLS.MOVE_TEXT} tool={trans("Subheader.Text")} position="top center" name={MAP_TOOLS.MOVE_TEXT} onClick={this.handleToolSelect} />

                                </>)
                            }

                            {this.props.showCategories && <Link to={this.props.isManageCategory ? "/branding" : "/branding/categories"}>
                                <ToolButton
                                    active={this.props.isManageCategory}
                                    position="top center"
                                    icon="categories"
                                    tool={trans("Subheader.Categories")}
                                />
                            </Link>}

                        </div>
                        {
                            this.props.action || (
                                <>
                                    {!this.props.isStylesAndThemes && <>
                                        <Divide vertical />
                                        <div className="status operational">
                                            <span>{this.renderStatusText()}</span>
                                        </div>
                                    </>
                                    }
                                </>
                            )
                        }
                        < PublishPopup />
                    </div>
                </Container>
            </Segment>
        );
    }
}
SubheaderBrandingImpl.propTypes = {
    showTools: PropTypes.bool,
    showCategories: PropTypes.bool,
    action: PropTypes.node,
    showShareButton: PropTypes.bool,
    isStylesAndThemes: PropTypes.bool,
    isManageCategory: PropTypes.bool,
    showStylesAndThemesButton: PropTypes.bool,
    showActiveBoundaryPolygonArea: PropTypes.bool,
};
SubheaderBrandingImpl.defaultProps = {
    showTools: true,
    showCategories: true,
    showShareButton: true,
    showGallery: true,
    isStylesAndThemes: false,
    isManageCategory: false,
    showStylesAndThemesButton: false,
    showActiveBoundaryPolygonArea: true,
};

export const SubheaderBranding = withRouter(withTranslation()(SubheaderBrandingImpl));


const SubheaderMapEditImpl = withRouter(({ history, ...props }) => 
{
    const brandingContext = useContext(BrandingContext);
    const mapEditorContext = useContext(MapEditorContext);

    const renderStatusText = () => 
    {
        const { propertyId, properties } = brandingContext.state;
        const trans = props.t;
        const selectedProperty = properties[propertyId];

        if (!!selectedProperty && !!selectedProperty.brandingStatus) 
        {
            return BRANDING_STATUS_DESCRIPTION[selectedProperty.brandingStatus].name;
        }
        else 
        {
            // TODO REMOVE ONCE DB IS SURE TO HAVE BRANDING STATUS.
            return trans("Subheader.Draft_version_active");
        }
    };

    /**
     * @param {Obeject} userLock - userLock from editor provider
     * @param {String} userLock.propertyId
     * @param {String} userLock.buildingId
     * @param {String} userLock.floorId
     * @param {String} userLock.editType
     */
    const handleJumpToUserLock = useCallback(({ propertyId, buildingId, floorId, editType }) => 
    {
        brandingContext.changeSelectedLevel(propertyId, buildingId, floorId);
        mapEditorContext.handleChangeEditType(editType);

    }, [brandingContext.changeSelectedLevel, mapEditorContext.handleChangeEditType]);

    const renderMapEditorButtonsAndInfo = useCallback(() => 
    {
        const { propertyId, buildingId, floorId } = brandingContext.state;

        return (
            <MapEditorButtonsAndInfo propertyId={propertyId} buildingId={buildingId} floorId={floorId} onJumpToUserLock={handleJumpToUserLock} />
        );
    }, [brandingContext]);

    const handleExitEditorButtonClick = useCallback(() => 
    {
        history.push("/branding");
    }, [history]);

    const handleUndoRedoClick = useCallback((e, { name }) => 
    {
        mapEditorContext.handleSetUndoRedoButtonClick(name);
    }, [mapEditorContext]);

    const isGeoreference = useMemo(() => 
    {
        const editType = mapEditorContext?.state?.editType;

        return (editType === EDIT_TYPES.TRANSITIONS);
    }, [mapEditorContext?.state?.editType]);

    const renderGeoreferenceBuildingSelect =
        /**
         * If edit type requires georeference, we want to display a second building selection for the second map
         */
        useCallback(() => 
        {
            if (!isGeoreference) 
            {
                return (<></>);
            }

            return (
                <SubheaderLeft
                    isBuildingOnly
                    dynamicContext={mapEditorContext}
                    customBuildingIdFetch={(editorContext) => editorContext.state.georeferenceBuildingId}
                    georeferenceBuildingId={brandingContext.state.buildingId}
                />
            );

        }, [mapEditorContext.state, brandingContext.state.buildingId, isGeoreference]);

    const renderPropertyBuildingSelect = useCallback(() => 
    {
        const georeferenceBuildingId = isGeoreference ? mapEditorContext.state.georeferenceBuildingId : undefined;

        return <SubheaderLeft dynamicContext={brandingContext} georeferenceBuildingId={georeferenceBuildingId} />;
    }, [brandingContext, isGeoreference, mapEditorContext?.state?.georeferenceBuildingId]);

    return (
        <Segment basic className="subheader">
            <Container fluid>
                <div className="subheader-left branding">
                    {renderPropertyBuildingSelect()}
                    <ActiveBoundaryPolygonArea />
                    <ActiveUsers propertyId={brandingContext.state.propertyId} buildingId={brandingContext.state.buildingId} floorId={brandingContext.state.floorId} />
                </div>

                <div className="subheader-right">

                    {renderGeoreferenceBuildingSelect()}

                    <UndoGroup onClick={handleUndoRedoClick} canUndo={mapEditorContext?.state?.canUndo} canRedo={mapEditorContext?.state?.canRedo} />

                    <Divide vertical />

                    <Popup className="tooltipPopup"
                        trigger={<ButtonBasic className="toolButtonEditor active" icon="toolbar-manage-category" onClick={handleExitEditorButtonClick} />}
                        size="small"
                        inverted
                        position="bottom center"
                        on="hover"
                        content="exit map editor" />

                    <MapEditPermissionWrapper>
                        {renderMapEditorButtonsAndInfo()}
                    </MapEditPermissionWrapper>

                    {
                        props.action || (
                            <>
                                <div className="status operational">
                                    <span>{renderStatusText()}</span>
                                </div>
                                <PublishPopup />
                            </>
                        )
                    }
                </div>
            </Container>
        </Segment>
    );
});

// handles all the actions/values that use map editor context
export const MapEditorButtonsAndInfo = ({ propertyId, buildingId, floorId, onJumpToUserLock }) => 
{
    const brandingContext = useContext(BrandingContext);
    const mapEditorContext = useContext(MapEditorContext);

    /**
     * Memo to track if current edit type is a georeference edit type
     */
    const isGeoreference = useMemo(() => 
    {
        const { editType } = mapEditorContext.state;

        let isGeoreference = false;

        if (editType === EDIT_TYPES.TRANSITIONS) 
        {
            isGeoreference = true;
        }

        return isGeoreference;
    }, [mapEditorContext]);

    /**
     *  @returns `{ isLevelLocked, isLevelLockedByUser, isLevelLockedByAnotherUser }`
     */
    const levelLockInfoMemo = useMemo(() => 
    {
        const lliM = mapEditorContext.getLevelLockInfo(propertyId, buildingId, floorId, isGeoreference);
        return lliM;
    }, [mapEditorContext.getLevelLockInfo, propertyId, buildingId, floorId, isGeoreference]);

    const handleLock = useCallback(() => 
    {
        mapEditorContext.handleLock(propertyId, buildingId, floorId, (message) => 
        {
            toast.error(message);
        });
    }, [mapEditorContext, propertyId, buildingId, floorId]);


    const handleUnlock = useCallback((isSavingData = true) => 
    {
        mapEditorContext.handleUnlock(propertyId, buildingId, floorId, isSavingData);
        brandingContext.handleSelectedPropertyBrandingStatusUpdate(BRANDING_STATUSES.DRAFT);
    }, [mapEditorContext, propertyId, buildingId, floorId]);

    const handleUnlockWithoutSaving = useCallback(() => 
    {
        handleUnlock(false);
    }, [handleUnlock]);

    const renderDisplayUserLockedLevel = useCallback(() => 
    {
        const { userLock, editType } = mapEditorContext?.state;

        const handleJumpToUserLock = () => 
        {
            (onJumpToUserLock) && onJumpToUserLock(userLock);
        };

        const isDiffEditType = (userLock) && (userLock.editType !== EDIT_TYPES.VALIDATION && userLock.editType !== editType);

        // if different edit type, go to edit type
        if (isDiffEditType) 
        {
            let content = "Go to locked edit type";

            return (
                <Button className="buttonLocked" content={content} onClick={handleJumpToUserLock} />
            );
        }

        // if georeference, only check if property ID is different
        if (isGeoreference) 
        {
            if (!!userLock && (userLock.propertyId !== propertyId)) 
            {
                let content = "Go to locked property";

                return (
                    <Button className="buttonLocked" content={content} onClick={handleJumpToUserLock} />
                );
            }
        }
        // check if current plotting level is different
        else 
        {
            if (!!userLock && (userLock.propertyId !== propertyId || userLock.buildingId !== buildingId || userLock.floorId !== floorId)) 
            {
                let content = "Go to locked level";

                return (
                    <Button className="buttonLocked" content={content} onClick={handleJumpToUserLock} />
                );
            }
        }

    }, [mapEditorContext, propertyId, buildingId, floorId, isGeoreference]);

    const renderLockUnlockButton = useCallback(() => 
    {
        if (!mapEditorContext?.state) 
        {
            return;
        }

        const { userLock, editType, isLockedOnDifferentMachine } = mapEditorContext?.state;

        if (levelLockInfoMemo.isLevelLocked) 
        {
            return (
                // <ButtonBasic className="lockSize" icon="lock-grey" content="Locked" onClick={handleUnlock} disabled={!!levelLockInfoMemo.isLevelLockedByAnotherUser || (userLock && userLock.editType !== editType)} />
                <UnlockModal
                    disabled={!!levelLockInfoMemo.isLevelLockedByAnotherUser || (userLock && userLock.editType !== editType)}
                    isLockedOnDifferentMachine={isLockedOnDifferentMachine}
                    onUnlockAndSave={handleUnlock}
                    onUnlockWithoutSaving={handleUnlockWithoutSaving}
                />
            );
        }
        else 
        {
            return (
                // disable the ability to lock if the user has another locked level or the level is locked by another user
                <ButtonBasic icon="unlock-grey" content="Unlocked" onClick={handleLock} disabled={!!userLock} />
            );
        }

    }, [mapEditorContext, handleLock, handleUnlock, levelLockInfoMemo]);

    return (
        <div className="unlockWrap">
            {renderLockUnlockButton()}
            {renderDisplayUserLockedLevel()}
            <Divide vertical />
        </div>
    );
};

export const SubheaderMapEdit = withTranslation()(SubheaderMapEditImpl);

export const ToolButton = ({ icon, tool, active, disabled, name, onClick, position = "bottom center" }) => (
    <Button className={`toolButton ${styles.ui} ${styles.toolButton} ${active ? " " + styles.active : ""}`}
        disabled={disabled} onClick={() => (onClick) && onClick(name)}>
        <Popup
            className="tooltipPopup"
            inverted
            position={position}
            on="hover"
            trigger={<img src={`/img/icon-toolbar-${icon}.svg`} alt="" />}
            content={tool} />
    </Button>
);

export const Subheader = ({ viewAll, children }) => 
{
    const [subhead, setSubhead] = React.useState(false);
    const dashboardContext = React.useContext(DashboardContext);

    const selectedObject = React.useMemo(() => 
    {
        const { propertyId, buildingId } = dashboardContext.state;

        let selectedObject = dashboardContext.state.properties[propertyId] || {};

        if (buildingId) 
        {
            selectedObject = selectedObject.buildings[buildingId] || {};
        }

        return selectedObject;

    }, [dashboardContext.state]);

    const trans = useTranslation().t;

    return (
        <Segment basic className="subheader">
            <Container fluid>
                <Button className="toggleBtn" onClick={() => setSubhead(!subhead)}>
                    <Icon name={!subhead ? "bars" : "close"} size="large" color="blue" />
                </Button>
                <div className="subheader-left">
                    <SubheaderLeft dynamicContext={dashboardContext} />
                    {viewAll && <ViewAllPopup />}
                </div>
                <div className="subheaderToggle" hidden={!subhead}>
                    <SubheaderLeft dynamicContext={dashboardContext} />
                    {viewAll && <ViewAllPopup />}
                </div>
                <div className="subheader-right">
                    {
                        (selectedObject.status) && (
                            <div className={"status " + STATUS_DESCRIPTION[selectedObject.status].className}>
                                {dashboardContext.state.buildingId ? trans("Subheader.Building") : trans("Subheader.Property")} {trans("Subheader.Status")} - <span>{trans(STATUS_DESCRIPTION[selectedObject.status].i18nKey)}</span>
                            </div>
                        )
                    }
                    <Button color="orange" className="property-button">
                        <Link to="/dashboard/property">{trans("Subheader.Add_Property")}</Link>
                    </Button>
                </div>
            </Container>
        </Segment>
    );
};

export const SubheaderLeft = ({ dynamicContext, isBuildingOnly, customPropertyIdFetch, customBuildingIdFetch, georeferenceBuildingId }) => 
{
    const trans = useTranslation().t;

    const propertyId = useMemo(() => 
    {
        if (customPropertyIdFetch) 
        {
            return customPropertyIdFetch(dynamicContext);
        }
        else 
        {
            return dynamicContext.state.propertyId;
        }
    }, [dynamicContext]);

    const buildingId = useMemo(() => 
    {
        if (customBuildingIdFetch) 
        {
            return customBuildingIdFetch(dynamicContext);
        }
        else 
        {
            return dynamicContext.state.buildingId;
        }
    }, [dynamicContext]);

    const buildingOptions = useMemo(() => 
    {
        let buildingOptions = dynamicContext.state.properties && dynamicContext.state.properties[propertyId]
            && dynamicContext.state.properties[propertyId].buildings;

        // only allow the selected georeference buildingId if available (i.e. the building from the other building selector)
        if (georeferenceBuildingId && buildingOptions) 
        {
            buildingOptions = {
                [georeferenceBuildingId]: buildingOptions[georeferenceBuildingId]
            };
        }

        return buildingOptions;
    }, [dynamicContext.state.properties, georeferenceBuildingId, propertyId]);

    return (
        <>
            {!isBuildingOnly && (
                <DropdownhSubheader
                    title={trans("Subheader.Property")}
                    dropdownContent={dynamicContext.state.properties}
                    nameKey="name"
                    langKey={SINGLE_LANG_INPUT_CODE}
                    selectedKey={dynamicContext.state.propertyId}
                    isActive={!dynamicContext.state.buildingId}
                    disabledThreshold={1}
                    onOptionClick={(propertyId) => (dynamicContext.changeSelectedProperty) && dynamicContext.changeSelectedProperty(propertyId)}
                    idToDisplayKey="propertyId"
                />
            )}

            <DropdownhSubheader
                title={trans("Subheader.Building")}
                dropdownContent={buildingOptions}
                nameKey="longName"
                langKey={SINGLE_LANG_INPUT_CODE}
                selectedKey={buildingId}
                isActive={!!buildingId}
                disabledThreshold={0}
                onOptionClick={(newBuildingId) => (dynamicContext.changeSelectedBuilding) && dynamicContext.changeSelectedBuilding(newBuildingId)}
                disabledText={trans("Subheader.No_Buildings")}
                unselect={true}
                idToDisplayKey="buildingId"
            />
        </>
    );
};

/**
 *
 * @param {Map} dropdownContent - a maping of objects
 * @param {String} nameKey - the name of the property in the objects wished that will be used to display the name of the current option
 * @param {String} selectedKey - the Id of the currently selected option. Undefined for none selected
 * @param {String} title - displayed dropdown title
 * @param {String} disabledText - displayed next to title if the drop down is disabled
 * @param {Boolean} unselect - adds an unselect option to the top of the dropdown
 * @param {Function} onOptionClick - returns selected option key
  */
export const DropdownhSubheader = ({
    dropdownContent = {},
    nameKey, langKey,
    selectedKey,
    isActive,
    title,
    disabledText,
    disabledThreshold,
    unselect = false,
    onOptionClick,
    idToDisplayKey,
}) => 
{
    const trans = useTranslation().t;
    const [isOpen, setIsOpen] = React.useState(false);
    const [autoClose] = useState(true);

    // Represents the amount of options in the list to display.
    const labelCount = dropdownContent && Object.keys(dropdownContent) ? Object.keys(dropdownContent).length : 0;

    // Represents the dropdowns label
    const label = `${title} (${labelCount}):`;

    // Represents whether or not the dropdown is disabled.
    const disabled = labelCount > disabledThreshold ? false : true;

    // Represents what is displayed next to the label. Should be selected object.
    const text = selectedKey ? dropdownContent[selectedKey][nameKey][langKey] : trans("Subheader.None_Selected");

    const handleOpen = () => setIsOpen(true);

    const handleClose = () => setIsOpen(false);

    const handleClick = (id) => 
    {
        handleClose();
        onOptionClick(id);
    };

    return (
        <Popup className={`popup-submenu${disabled ? " disabled" : ""}`}
            open={isOpen}
            hideOnScroll={false}
            on="click"
            pinned
            onClose={handleClose}
            closeOnDocumentClick={autoClose}
            closeOnEscape={autoClose}
            position="bottom left"
            trigger={
                <Label className={`dropdown-submenu${!isActive ? " active" : ""}`} onClick={handleOpen} >
                    <img src="/img/icon-angle-down-filled.svg" alt="" />
                    {label}
                    <strong>
                        {disabled ? disabledText || text : text}
                    </strong>
                </Label>
            }>
            <Scrollbars autoHeight autoHeightMin={"20px"} autoHeightMax={"505px"}>
                <Item.Group>

                    {/* Show an unselect option if requested */}
                    {(unselect) && (
                        <Item key="unselect" onClick={() => handleClick(undefined)}>
                            {trans("Subheader.Unselect")}
                        </Item>
                    )}

                    {/* Show the selected object at the top of the dropdown */}
                    {!!(selectedKey) && (
                        <Item
                            className="active"
                            onClick={() => handleClick(selectedKey)}
                        >
                            {/* <span>{dropdownContent[selectedKey][nameKey][SINGLE_LANG_INPUT_CODE]}</span> */}
                            <div className="locationDropdownItem">
                                <span className="locationName">
                                    {dropdownContent[selectedKey][nameKey]?.[SINGLE_LANG_INPUT_CODE]}
                                </span>
                                {
                                    idToDisplayKey && dropdownContent[selectedKey][idToDisplayKey]
                                    && <div className="locationIdDisplay">
                                        {dropdownContent[selectedKey][idToDisplayKey]}
                                    </div>
                                }
                            </div>
                        </Item>
                    )}

                    {/* Show dropdown options with the selected object omitted */}
                    {Object.values(dropdownContent || {})
                        .filter((option) => option?._id !== selectedKey)
                        .map((option) => (
                            <Item
                                key={option?._id}
                                onClick={() => handleClick(option?._id)}
                            >
                                <div className="locationDropdownItem">
                                    <span className="locationName">
                                        {option[nameKey]?.[SINGLE_LANG_INPUT_CODE]}
                                    </span>
                                    {
                                        idToDisplayKey && option[idToDisplayKey]
                                        && <div className="locationIdDisplay">
                                            {option[idToDisplayKey]}
                                        </div>
                                    }
                                </div>
                            </Item>
                        ))
                    }
                </Item.Group>
            </Scrollbars>
        </Popup>
    );
};

const ShareStoreButton = () => 
{
    const { methods: { setState } } = useContext(StoreSharingContext);
    const trans = useTranslation().t;
    return (
        <ToolButton
            position="top center"
            onClick={() => setState({ openWithStore: undefined, openShareStores: true })}
            icon="share-store"
            tool={trans("Subheader.Share_Store")}
        />
    );
};

const ActiveUsers = ({ propertyId, buildingId, floorId }) => 
{
    const mapEditorContext = useContext(MapEditorContext);
    const lockedByNameData = useMemo(() => 
    {
        const { isLevelLockedByUser, loading } = mapEditorContext?.state;
        const companyLocks = mapEditorContext.getCompanyLocks;
        let userData = serverApi.userData.user;
        const userId = userData.userInfo.id;

        if (!loading && !isLevelLockedByUser) 
        {
            // check if there is a lock for the current floor
            for (let i = 0; i < companyLocks?.length; i++) 
            {
                let lockData = companyLocks[i];

                if (lockData.propertyId === propertyId && lockData.buildingId === buildingId && lockData.floorId === floorId && lockData.userId !== userId) 
                {
                    return {
                        userName: lockData.displayName || lockData.userId,
                        userInitials: lockData.initials
                    };
                }
            }
        }

        return;
    }, [mapEditorContext.state, propertyId, buildingId, floorId, mapEditorContext.getCompanyLocks]);

    const renderLockedBy = useCallback(() => 
    {
        if (lockedByNameData) 
        {
            const { userName, userInitials } = lockedByNameData;

            return (
                <div className="activeUsersGroup tooltip">
                    <p>Active Users:</p>
                    <Popup className="tooltipPopup"
                        trigger={<span>{userInitials}</span>}
                        size="small"
                        inverted
                        position="bottom center"
                        on="hover"
                        content={userName} />
                </div>
            );
        }
        return (<div></div>);
    }, [lockedByNameData]);


    return (
        <>
            {renderLockedBy()}
        </>
    );
};

const UndoGroup = ({ onClick, canRedo, canUndo }) => (
    <div className="undoGroup">
        <ButtonBasic disabled={!canUndo} name={MAP_EDITOR_SHORTCUTS.UNDO} icon="editor-toolbar-undo" onClick={onClick} />
        <ButtonBasic disabled={!canRedo} name={MAP_EDITOR_SHORTCUTS.REDO} icon="editor-toolbar-undo" onClick={onClick} />
    </div>
);
