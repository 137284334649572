import { Button, Image } from "semantic-ui-react";
import "./CopyOrMoveCategory.css";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ModalBox } from "../../../common/ModalBox";
import CategoryTreeDisplay from "../../../common/componentTreeDisplay/CategoryTreeDisplay";
import { ButtonIcon } from "../../../elements/ButtonIcon";
import { DEFAULT_LANGUAGE_CODE } from "../../../../_constants/constants";
import { CategoryContext } from "../../../../store/CategoryProvider";

const CopyOrMoveCategory = ({ category, group, openModal, onCloseModal, lang, action = "copy" }) => 
{
    const trans = useTranslation().t;
    const [copyTo, setCopyTo] = useState(null);
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const { copyOrMoveCategory } = useContext(CategoryContext);
    
    const handleSubmitClick = async (move = false) => 
    {
        const oldParentId = category?.parentCategory?._id || group._id;
        
        const { success } = await copyOrMoveCategory(category._id, copyTo._id, oldParentId, move);
        if (success) 
        {
            setOpenConfirmationModal(false);
            handleClose();
        }
    };

    const handleClose = () => 
    {
        setCopyTo(null);
        onCloseModal();
    };

    return (
        <>
            <ModalBox
                className="copyCategoryModal"
                open={openModal}
                onClose={handleClose}
                header={<div className="copyGroupModal-header">
                    <div className="title">{trans(`CopyCategory.${action}title`)}</div>
                    <div className="subTitle">{trans(`CopyCategory.${action}subTitle`)}</div>
                </div>}
                actions={<Button
                    primary
                    floated="right"
                    content={trans(`CopyCategory.${action}`)}
                    onClick={() => setOpenConfirmationModal(true)}
                    disabled={copyTo === null} />}
            >
                {
                    category && category._id && <CategoryTreeDisplay
                        className="copy-cateogorytree"
                        CategoryItem={(props) => (<Item {...props} isActive={props.detail._id === copyTo?._id} disabled={[category._id, ...category.parentCategories].includes(props?.detail?._id)} onClick={() => setCopyTo({ ...props.detail, parentCategory: props.parentCategory })} />)}
                        showAllCategories={false}
                        searchable={true}
                        lang={lang}
                        removeCateogries={category && [category._id]}
                        hideSubCategories={true}
                        autoHeightMax="200px"
                        autoHeightMin="190px"
                        noResultMessage={trans("No Category Available")}
                    />
                }
            </ModalBox>
            {category && copyTo && group
                && <ModalBox
                    className="copyCategoryConfirmModal"
                    open={openConfirmationModal}
                    onClose={() => setOpenConfirmationModal(false)}
                    header={<div className="copyGroupModal-header">
                        <div className="title">{trans(`CopyCategory.${action}`)} {"'"}{category?.name?.[lang] || category?.name?.[DEFAULT_LANGUAGE_CODE]}{"'"}</div>
                    </div>}
                    actions={
                        <div className="copyCategoryActionBtns">
                            <Button
                                primary
                                content={trans(`CopyCategory.${action}Btn`)}
                                onClick={() => 
                                {
                                    handleSubmitClick(action === "move"); 
                                }}
                                disabled={copyTo === null} />
                            {action === "move" &&<Button
                                className="creatCopy"
                                content={trans("CopyCategory.createCopy")}
                                onClick={() => handleSubmitClick(false)}
                                disabled={copyTo === null} />}
                        </div>
                    }
                >

                    <div className="copyCateogryConfirmText">
                        You are {action === "move" ? "moving" : "copying"} `{category?.name?.[lang] || category?.name?.[DEFAULT_LANGUAGE_CODE]}` - {category?.parentCategory ? "subcategory" : "category"} (From group `{group?.name?.[lang] || group?.name?.[DEFAULT_LANGUAGE_CODE]}`{category?.parentCategory ? `, category ${"`"}${category.parentCategory.name?.[lang] || category.parentCategory.name?.[DEFAULT_LANGUAGE_CODE]}${"`"}` : ""}) to
                        {copyTo.parentCategory?.[lang] || copyTo.parentCategory?.[DEFAULT_LANGUAGE_CODE] ? ` group ${"`"}${copyTo.parentCategory?.[lang] || copyTo.parentCategory?.[DEFAULT_LANGUAGE_CODE]}${"`"} category ${"`"}${copyTo.name?.[lang] || copyTo.name?.[DEFAULT_LANGUAGE_CODE]}${"`"}` : ` group ${"`"}${copyTo.name?.[lang] || copyTo.name?.[DEFAULT_LANGUAGE_CODE]}${"`"}`}.
                    </div>

                    <div className="copyCateogryConfirmText" style={{ marginTop: 20 }}>{trans("CopyCategory.confirmation")}</div>


                </ModalBox>
            }
        </>
    );
};

export default CopyOrMoveCategory;

const Item = ({ className, isActive, content, disabled, iconInfo, children, sideComponents, onClick }) => 
{
    const [showChildren, setShowChildren] = useState(true);
    const { url } = iconInfo;

    const handleOnClick = () => 
    {
        if (!disabled)
        {
            onClick();
        }
    };

    return (<div className={`copyCategoryItemRoot ${className || ""}`}>
        <div className={`copyCategoryItem-content ${isActive ? "active" : ""} ${disabled ? "disabled": ""}`} onClick={handleOnClick}>
            <div className="copyCategoryItem-name">
                <Image className="copyCategoryItemRoot-icon" as="span" src={url} />
                <p>{content}</p>
                {children?.length > 0 && <ButtonIcon className="keyword-chevron-icons" icon={`chevron-${showChildren ? "up" : "down"}`} onClick={(e) => 
                {
                    e.stopPropagation();
                    setShowChildren((prev) => !prev);
                }
                } />}
            </div>
            <div>
                {sideComponents}
            </div>
        </div>
        {showChildren && children && <div className="copy-subCategoryCover">{children}</div>}
    </div>);
};