import
{
    Modal,
    Button,
    Input,
    List,
    Checkbox,
    Icon,
    Dimmer,
    Segment,
    Label,
    Popup
} from "semantic-ui-react";
import { Scrollbars } from "react-custom-scrollbars-2";
import React from "react";
import { useDynamicList, useSetState } from "ahooks";
import { InputGroupWrap } from "../../../common/InputGroupWrap";
import { DEFAULT_STORE_LOGO } from "../../../../_constants/constants";
import { StoreLabel } from "../../../syncStoreModals/SyncStoreModal";
import serverApi from "../../../../_api/server.api";
import { Loader } from "./loader";
import { useTranslation } from "react-i18next";

const BuildingSelector = ({ isLoading, buildings, updateParentState, instanceUrl }) =>
{

    const [state, setState] = useSetState({
        selectedBuildings: {},
        searchText: "",
        filters: {}
    });
    const trans = useTranslation().t;
    const { list: activeFilters, push, remove } = useDynamicList([]);
    const filterEnabled = (type) => activeFilters.some((filter) => filter?.type === type);
    const typesToLabels = {
        location: { icon: "globe", text: "Anywhere", type: "location" },
        type: { icon: "building", text: "Any", type: "type" },
        // status: { icon: "handshake", text: "Any", type: "status" },
        // dateCreated: { icon: "calendar", text: "Any", type: "dateCreated" },
    };

    const addFilter = (type) => !filterEnabled(type) && push(typesToLabels?.[type]);

    const updateSelectedBuildings = (building, checked) =>
    {
        const updatedSelectedBuildings = { ...state.selectedBuildings };

        if (checked)
        {
            updatedSelectedBuildings[building["bl.bl_id"]] = building;
        }
        else
        {
            delete updatedSelectedBuildings[building["bl.bl_id"]];
        }

        setState({ selectedBuildings: updatedSelectedBuildings });
    };

    const renderBuildingList = () => 
    {

        let filteredBuildings = [...buildings];

        if (state.searchText)
        {
            filteredBuildings = filteredBuildings.filter((building) => building["bl.name"]?.toUpperCase().includes(state.searchText.toUpperCase()));
        }

        if (state.filters["location"])
        {
            filteredBuildings = filteredBuildings.filter((building) => building["bl.ctry_id"] === state.filters["location"]);
        }

        if (state.filters["type"])
        {
            filteredBuildings = filteredBuildings.filter((building) => building["bl.use1"] === state.filters["type"]);
        }

        return filteredBuildings.map((building) => (

            <div className={`syncListingItem syncListTypeItem${building.mapstedImported ? " active" : ""}`} key={building["bl.bl_id"]}>

                <Checkbox
                    checked={!!state.selectedBuildings[building["bl.bl_id"]] || !!building.mapstedImported}
                    disabled={building.mapstedImported}
                    onChange={(e, { checked }) => updateSelectedBuildings(building, checked)} />

                <span className="syncListingImg">
                    <img src={DEFAULT_STORE_LOGO} alt="" />
                </span>

                <div className="syncListingBody">
                    {building["bl.name"]} <br /> <span>{`${building["bl.city_id"]}, ${building["bl.ctry_id"]}`}</span>
                </div>

                {building.mapstedImported && <p>Already Imported</p>}

            </div>
        ));
    };

    const addFilterValue = (filter, value) =>
    {
        const updatedFilters = { ...state.filters };
        updatedFilters[filter] = value;
        setState({ filters: updatedFilters });
    };

    const removeFilterValue = (filter) =>
    {
        const updatedFilters = { ...state.filters };
        delete updatedFilters[filter];
        setState({ filters: updatedFilters });
    };

    const getFilterDropdownValues = () =>
    {
        const locationSet = new Set();
        const typeSet = new Set();

        buildings.forEach((building) =>
        {
            if (building["bl.ctry_id"])
            {
                locationSet.add(building["bl.ctry_id"]);
            }

            if (building["bl.use1"])
            {
                typeSet.add(building["bl.use1"]);
            }

        });

        return {
            location: Array.from(locationSet),
            type: Array.from(typeSet)
        };
    };

    const handleImportClick = async () =>
    {
        const buildingsToImport = Object.values(state.selectedBuildings).map((selectedBuilding) => selectedBuilding["bl.bl_id"]);

        let successCount = 0;
        let failedCount = 0;

        updateParentState({ loading: true });

        for (const buildingId of buildingsToImport)
        {
            try
            {
                const resp = await serverApi.importArchibusBuilding(buildingId, instanceUrl);
                if (resp.success)
                {
                    successCount++;
                }
                else
                {
                    failedCount++;
                }
            }
            catch (error)
            {
                failedCount++;
            }

        }

        updateParentState({ loading: false, screen: "COMPLETED" });
    };

    const filterDropdownValues = getFilterDropdownValues();

    const renderFilters = () => (
        <div style={{ marginBottom: "8px" }}>
            {activeFilters.map((filter, i) => (
                <Label style={{ marginBottom: "3px" }} key={filter.type} as="a">
                    <Icon name={filter.icon} />
                    {state.filters[filter.type] ? state.filters[filter.type] : <>
                        {filter.text}
                        <Popup
                            content={
                                (
                                    <div style={{ minWidth: "100px" }}>
                                        <Scrollbars autoHeight autoHeightMin="calc(1vh)" autoHeightMax={300}>
                                            <List link>
                                                {filterDropdownValues[filter.type].map((filterValue) => (
                                                    <List.Item
                                                        key={filterValue}
                                                        onClick={() => addFilterValue(filter.type, filterValue)}
                                                        as="a" >
                                                        {filterValue}
                                                    </List.Item>
                                                ))}
                                            </List>
                                        </Scrollbars>
                                    </div>
                                )}
                            on="click"
                            pinned
                            position="bottom right"
                            trigger={<Icon style={{ marginLeft: "2px", marginRight: "0px" }} name="caret down" />}
                        />
                    </>
                    }
                    <Icon name="delete" onClick={() => 
                    {
                        removeFilterValue(filter.type);
                        remove(i);
                    }} />
                </Label>
            ))}
            <Popup
                content={<List link>
                    <List.Item disabled={filterEnabled("location")} onClick={() => addFilter("location")} as="a"><List.Icon name="globe" />Location</List.Item>
                    <List.Item disabled={filterEnabled("type")} onClick={() => addFilter("type")} as="a"><List.Icon name="building" />Type</List.Item>
                    {/* <List.Item disabled={filterEnabled("status")} onClick={() => addFilter("status")} as="a"><List.Icon name="handshake" />Status</List.Item> */}
                    {/* <List.Item disabled={filterEnabled("dateCreated")} onClick={() => addFilter("dateCreated")} as="a"><List.Icon name="calendar" />Date Created</List.Item> */}
                </List>}
                on="click"
                pinned
                position="bottom right"
                trigger={<Label as="a"><Icon name="plus" />Add Filter</Label>}
            />
        </div>
    );

    return (
        <Dimmer.Dimmable className="dimmableLoader" as={Segment} dimmed={isLoading}>
            <Dimmer active={isLoading} inverted>
                <Loader active={isLoading} />
                {/*<Progress />*/}
            </Dimmer>
            <div className="aligner archibusModal">
                <Modal.Header>{trans("Archibus_Integration.Archibus Integration - Select Buildings")}</Modal.Header>

                <p className="p-modal">{trans("Archibus_Integration.Import_info")}</p>

                <InputGroupWrap label={trans("Archibus_Integration.Buildings")}>
                    <Input
                        placeholder={trans("Archibus_Integration.Search Buildings")}
                        className="categorySearch"
                        icon="search"
                        onChange={(e, { value }) => setState({ searchText: value })} />

                    {renderFilters()}

                    {/* todo: select all buildings checkbox here... */}

                    <Scrollbars className="modalScroll" autoHeight autoHeightMin="calc(1vh)" autoHeightMax={200}>
                        {!!buildings.length && renderBuildingList()}
                    </Scrollbars>
                </InputGroupWrap>

                <InputGroupWrap label={`${trans("Archibus_Integration.Selected Buildings")} ${Object.keys(state.selectedBuildings).length}`}>
                    <Scrollbars className="modalScroll" autoHeight autoHeightMin="calc(1vh)" autoHeightMax={100}>
                        <div className="defaultSyncListing">
                            {
                                Object.values(state.selectedBuildings).map((selectedBuilding) => (
                                    <StoreLabel
                                        key={selectedBuilding["bl.bl_id"]}
                                        content={selectedBuilding["bl.name"]}
                                        id={selectedBuilding["bl.bl_id"]}
                                        deselectStore={() => updateSelectedBuildings(selectedBuilding, false)} />
                                ))
                            }
                        </div>
                    </Scrollbars>
                </InputGroupWrap>

                <Modal.Actions>
                    <Button secondary content={trans("Archibus_Integration.Modify Credentials")} floated="left" onClick={() => updateParentState({ screen: "AUTH" })} />
                    <Button primary content={trans("Archibus_Integration.Import")} floated="right" onClick={handleImportClick} disabled={!Object.keys(state.selectedBuildings).length} />
                </Modal.Actions>
            </div>
        </Dimmer.Dimmable>
    );
};

export default BuildingSelector;