import React, { useCallback, useContext } from "react";
import BrandingContext from "../../../store/BrandingContext";
import { FloorButtons, MapButton } from "../../branding/map/MapButtons";
import MapEditorContext from "../../../store/MapEditiorContext";

export const MapEditorMapButtons = ({ onZoomIn, onZoomOut, isGeoreference, isCenter }) =>
{
    const brandingContext = useContext(BrandingContext);
    const editorContext = useContext(MapEditorContext);

    const renderFloorButtons = useCallback(() =>
    {
        if (isGeoreference)
        {
            return (editorContext.state.georeferenceBuildingId) && (
                <FloorButtons isGeoreference />
            )
        }
        else
        {
            return (brandingContext.state.buildingId) && (
                <FloorButtons />
            )
        }
    }, [isGeoreference, brandingContext.state, editorContext.state]);

    return (
        <div>
            <div className={isCenter
                ? "buttons-wrap-center"
                : "buttons-wrap"}>

                {/* <div className="buttons-group staysActive">
                    <MapButton content={<span className="zoomIn" />} onClick={onZoomIn} />
                    <MapButton content={<span className="zoomOut" />} onClick={onZoomOut} />
                </div> */}

                {renderFloorButtons()}

            </div>

        </div>

    )
}