import React from "react";
import "./ImageUploadProgressModal.css";
import {
    Button,
    Modal,
    ModalContent,
    ModalHeader,
    Progress,
} from "semantic-ui-react";
import { FILE_UPLOAD_STATUS } from "../../../constant";
import { useTranslation } from "react-i18next";
import { useImagesUploadContext } from "../../contexts/ImagesUploadContext";

function ImageUploadProgressModal() 
{
    const {
        uploadedFilesUploadStatus,
        uploadedFilesInfo,
        handleResetUpload,
        bulkImageUploadCompleteStatus
    } = useImagesUploadContext();
    const uploadedFilesUploadStatusArray = Object.entries(
        uploadedFilesUploadStatus
    );
    const uploadedFiles = uploadedFilesUploadStatusArray.filter(
        ([key, value]) => value.status === FILE_UPLOAD_STATUS.UPLOADED
    );
    const failedFiles = uploadedFilesUploadStatusArray.filter(
        ([key, value]) => value.status === FILE_UPLOAD_STATUS.FAILED
    );
    // +1 to count for bg-refetch also
    const total = uploadedFilesUploadStatusArray.length + 1;
    const value = uploadedFiles.length + (bulkImageUploadCompleteStatus === "ready"? 1: 0);
    const percentValue = Math.floor((value / total) * 100);
    const trans = useTranslation().t;

    function renderUploadFilesInfo()
    {
        if (!((bulkImageUploadCompleteStatus === "ready" || bulkImageUploadCompleteStatus === "failed") && uploadedFiles.length)) return;

        return   <div className="files-list success-files-list">
            <div className="files-list-title">Upload Successful: </div>
            <div className="files-list-files-bx">
                {uploadedFiles.map(([key, value], idx, self) => 
                {
                    const fileInfo = uploadedFilesInfo.find((file) => file.id === key);
                    return (
                        <div key={key}>
                            {fileInfo.name}
                            {idx !== self.length - 1 ? ", " : "."}
                        </div>
                    );
                })}
            </div>
                
        </div>;
    }

    function renderFailedFilesInfo()
    {
        if (!((bulkImageUploadCompleteStatus === "ready" || bulkImageUploadCompleteStatus === "failed") && failedFiles.length)) return;

        return  <div className="files-list failed-files-list">
            <div className="files-list-title">Upload Failed: </div>
            <div className="files-list-files-bx">
                {failedFiles.map(([key, value], idx, self) => 
                {
                    const fileInfo = uploadedFilesInfo.find((file) => file.id === key);
                    return (
                        <div key={key}>
                            {fileInfo.name}
                            {idx !== self.length - 1 ? ", " : "."}
                        </div>
                    );
                })}
            </div>
                      
        </div>;
        
    }

    return (
        <Modal
            closeOnDimmerClick={false}
            closeIcon={
                <Button onClick={handleResetUpload} className="close" />
            }
            className="image-upload-progress-modal"
            open
        >
            <ModalHeader>Uploading...</ModalHeader>
            <ModalContent>
                <Progress
                    indicating
                    progress="percent"
                    percent={percentValue}
                />
                {renderUploadFilesInfo()}  
                {renderFailedFilesInfo()}
            </ModalContent>
        </Modal>
    );
}

export default ImageUploadProgressModal;
