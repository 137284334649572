import React, { useContext, useEffect } from "react";
import { useSetState } from "ahooks";
import { DynamicMapLayersContext } from "../../../../store/DynamicMapLayersContext";
import { DYNAMIC_MAP_LAYERS_MODES } from "../../../../_constants/constants";
import BasicInfoTab from "./BasicInfoTab";
import AutomationRulesTab from "./AutomationRulesTab";

import "./DynamicMapLayerSidebar.css";

const MAX_TAB_COUNT = 3;

export const CreateDynamicMapLayerSidebar = () =>
{
    const dynamicMapLayerCtx = useContext(DynamicMapLayersContext);

    const { state: ctxState } = dynamicMapLayerCtx;
    const { mode } = ctxState;

    const [state, setState] = useSetState({
        tab: 1
    });

    useEffect(() =>
    {
        if (mode === DYNAMIC_MAP_LAYERS_MODES.CREATE_DYNAMIC_MAP_LAYER 
            || mode === DYNAMIC_MAP_LAYERS_MODES.EDIT_DYNAMIC_MAP_LAYER)
        {
            setState({
                tab: 1
            });
        }
    }, [mode]);

    const updateTab = (increment) =>
    {
        const { tab } = state;
        let newTabCount = tab + increment;

        if (newTabCount > MAX_TAB_COUNT)
        {
            newTabCount = MAX_TAB_COUNT;
        }
        else if (newTabCount < 1)
        {
            newTabCount = 1;
        }

        setState({
            tab: newTabCount
        });
    };

    const getTab = () =>
    {
        const { tab } = state;

        switch (tab)
        {
        case 1:
            return <BasicInfoTab updateTab={updateTab} />;
        case 2:
            return <AutomationRulesTab updateTab={updateTab} />;
        default:
            return null;
        }
    };

    if (mode !== DYNAMIC_MAP_LAYERS_MODES.CREATE_DYNAMIC_MAP_LAYER 
        && mode !== DYNAMIC_MAP_LAYERS_MODES.EDIT_DYNAMIC_MAP_LAYER)
    {
        return null;
    }

    return getTab();
};