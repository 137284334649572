import React, { useCallback, useContext, useMemo, useState } from "react";
import { Accordion, Button, Popup } from "semantic-ui-react";
import { ENTITY_EDITOR_FILTERS, MAP_LAYERS } from "../../../_constants/mapEditor";
import MapEditorContext from "../../../store/MapEditiorContext";
import { ButtonIcon } from "../../common/ButtonIcon";
import { CheckboxGroup } from "../../common/CheckboxGroup";
import { CustomLegend } from "../../common/CustomLegend";
import FloorGeoReferencingNavigationSideBar from "../FloorPlanGeoReferencing/FloorGeoReferencingNavigationSideBar";
import { FeatureSearch } from "./FeatureSearch";

/**
 * Renders the Map Editor Sidebar Accordion component.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.smartFilter - Flag indicating if smart filtering is enabled.
 * @param {Array} props.smartFilterOptions - The available smart filter options.
 * @param {string} props.selectedFilterItem - The currently selected filter item.
 * @param {Array} props.mapLayerOptions - The available map layer options.
 * @param {Object} props.legend - The legend object.
 * @param {function} props.onSelectFilterItem - The function to handle the selection of a filter item.
 * @param {function} props.onShowNotLockedByUserAlert - The function to handle showing an alert when a level is not locked by the user.
 * @param {boolean} props.showFloorPlanAccordion - Flag indicating if the floor plan accordion should be shown.
 * @return {ReactNode} The rendered Map Editor Sidebar Accordion component.
 */
const MapEditorSidebarAccordion = ({
    smartFilter,
    smartFilterOptions,
    selectedFilterItem,
    mapLayerOptions,
    legendTitle,
    legendOptions,
    onSelectFilterItem,
    onShowNotLockedByUserAlert,
    showFloorPlanAccordion,
}) =>
{
    const mapEditorContext = useContext(MapEditorContext);

    const selectedFilter = useMemo(() => mapEditorContext?.state?.selectedFilter, [mapEditorContext?.state?.selectedFilter]);

    /**
     * @returns `{ isLevelLocked, isLevelLockedByUser, isLevelLockedByAnotherUser }`
     */
    const levelLockInfoMemo = useMemo(() => mapEditorContext.getLevelLockInfo(), [mapEditorContext.getLevelLockInfo]);


    const [state, setState] = useState({
        activeIndex: -1,
    });

    const handleAccordionClick = (e, titleProps) =>
    {
        const { index } = titleProps;
        const { activeIndex } = state;
        const newIndex = activeIndex === index ? -1 : index;

        setState({ activeIndex: newIndex });
    };

    const handleLayerVisabilityClick = useCallback((layerId) =>
    {
        // no need to prevent access to this tool if not locked
        // left code here just in case

        // let alertMessage = mapEditorContext.getLevelNotLockedAlertMessage();

        // if (alertMessage)
        // {
        //     onShowNotLockedByUserAlert(alertMessage);
        //     return;
        // }

        const { mapLayersVisablityMap } = mapEditorContext.state;

        mapEditorContext.handleUpdateMapLayersVisablity({ [layerId]: !mapLayersVisablityMap[layerId] });

    }, [mapEditorContext, levelLockInfoMemo, onShowNotLockedByUserAlert]);

    const renderMapLayerOptions = useCallback(() =>
    {
        const { mapLayersVisablityMap } = mapEditorContext.state;

        return mapLayerOptions.map((mapLayerId) =>
        {
            const mapLayerName = MAP_LAYERS[mapLayerId];

            return (
                <MapLayerItem disabled={!mapLayersVisablityMap[mapLayerId]} key={mapLayerId} layerId={mapLayerId} layerName={mapLayerName} onClick={handleLayerVisabilityClick} />
            );
        });
    }, [mapEditorContext, handleLayerVisabilityClick, mapLayerOptions]);

    const handleSmartFilterItemClick = useCallback((e, { name }) =>
    {
        (onSelectFilterItem) && onSelectFilterItem(name);
    }, [onSelectFilterItem]);


    const handleSmartFilterClick = useCallback((e, { name }) =>
    {
        // no need to prevent access to this tool if not locked
        // left code here just in case

        // let alertMessage = mapEditorContext.getLevelNotLockedAlertMessage();

        // if (alertMessage)
        // {
        //     onShowNotLockedByUserAlert(alertMessage);
        //     return;
        // }

        mapEditorContext.handleSetSelectedFilter(name);
    }, [mapEditorContext, levelLockInfoMemo, onShowNotLockedByUserAlert]);


    const renderSmartFilterItems = useCallback(() =>
    {
        if (!smartFilter)
        {
            return <div />;
        }

        const { idType, filteredIds } = smartFilter;

        if (filteredIds.length === 0)
        {
            return (
                <div> No items match the filter</div>
            );
        }

        return filteredIds.map(({ cmsId, navId }) => (
            <SmartFilterItem
                key={cmsId}
                name={cmsId}
                content={`${idType} ID - ${navId} ${cmsId}`}
                onClick={handleSmartFilterItemClick}
                isActive={cmsId === selectedFilterItem}
            />
        ));
    }, [smartFilter, selectedFilterItem, handleSmartFilterClick]);


    /**
     * Render smart filter options
     */
    const renderSmartFilterOptions = useCallback(() =>
    {
        if (!Array.isArray(smartFilterOptions))
        {
            return (<div />);
        }

        return (
            <AccordionSection active={state.activeIndex === 1} index={1} onClick={handleAccordionClick}
                title="Smart Filters">
                <div className="smartFilterRadioGroup">
                    {
                        smartFilterOptions.map((filterOption) => <CheckboxGroup
                            radio
                            key={filterOption}
                            name={filterOption}
                            checked={filterOption === selectedFilter}
                            label={filterOption}
                            onClick={handleSmartFilterClick} />
                        )
                    }

                </div>
                {/* <Button className="smartFilterSearchButton" color="orange" content="Search" /> */}

                <div className="smartFilterGroup">
                    {smartFilter?.filter === ENTITY_EDITOR_FILTERS.SEARCH && renderFeatureSearch()}
                    {renderSmartFilterItems()}
                </div>
            </AccordionSection>
        );
    }, [smartFilterOptions, state.activeIndex, handleAccordionClick, renderSmartFilterItems, handleSmartFilterClick, selectedFilter]);

    const renderLegend = useCallback(() =>
    {
        if (!legendOptions)
        {
            return (<div />);
        }

        return (
            <AccordionSection active={state.activeIndex === 2} index={2} onClick={handleAccordionClick}
                title={"Legend"}>
                <CustomLegend header={legendTitle} legendOptions={legendOptions} />

            </AccordionSection>
        );
    }, [legendOptions, handleAccordionClick, state.activeIndex]);

    const renderFeatureSearch = useCallback(() => (
        <div>
            <FeatureSearch />
        </div>
    ), []);

    const renderFloorPlanAccordion = useCallback(() =>
    {
        if (!showFloorPlanAccordion)
        {
            return null;
        }
        return (
            <FloorGeoReferencingNavigationSideBar index={3} active={state.activeIndex === 3} onClick={handleAccordionClick} />
        );
    }, [showFloorPlanAccordion, handleAccordionClick, state.activeIndex]);

    // RENDER display layers depending on property level or floor level (property level doesn't have layer 7)
    return (
        <Accordion className="mapEditorSidebarAccordion">
            {renderFloorPlanAccordion()}
            <AccordionSection active={state.activeIndex === 0} index={0} onClick={handleAccordionClick}
                title="Map Layers">
                {
                    renderMapLayerOptions()
                }
            </AccordionSection>

            {renderSmartFilterOptions()}



            {renderLegend()}

        </Accordion>
    );
};

export default MapEditorSidebarAccordion;

const AccordionSection = ({ title, children, active, index, onClick }) => (
    <>
        <Accordion.Title active={active} index={index} onClick={onClick}>
            {title}
        </Accordion.Title>
        <Accordion.Content active={active}>
            {children}
        </Accordion.Content>
    </>
);

const MapLayerItem = ({ layerId, layerName, disabled, pointer, onClick }) => (
    <div className="mapLayerItem">

        <Popup className={`mapLayerPopup ${(disabled) ? "disabled" : ""}`}
            size="small"
            trigger={<ButtonIcon className="buttonEye" icon={(disabled) ? "eye-off" : "eye-grey"} onClick={() => onClick(layerId)} />}
            on="hover"
            position="right center"
            content={(disabled) ? "Hidden" : "Shown"}
        />

        {layerName}

        {
            (pointer)
            && <Popup className="mapLayerPopup"
                size="small"
                trigger={<Button className="selectedLayerPointer" />}
                on="hover"
                position="right center"
                content="Select on map" />
        }
    </div>
);

const SmartFilterItem = ({ name, content, isActive, onClick }) => (
    <ButtonIcon className="smartFilterItem" active={isActive} name={name} onClick={onClick}>
        {content}
    </ButtonIcon>
);
