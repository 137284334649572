const { DEFAULT_LANGUAGE_CODE, MAP_THEMES } = require("../utils/map.constants");
const { EntityType, StructureEntityType, isRoutableEntity, isEntityNameable, ShapeTypes } = require("../utils/entityTypes");
const { deepValue } = require("mapsted.utils/objects");
const topojson = require("topojson-client");
const { STYLE_OPTIONS } = require("../utils/defualtStyles");

class AbstractEntityAccess
{
    constructor(data)
    {
        this.data = data || {};
    }

    getEntityType()
    {
        return this.data.entityType;
    }

    getSubEntityType()
    {
        return this.data.subEntityType;
    }

    getShape()
    {
        return this.data.shape;
    }

    getNodeIds()
    {
        return this.data.nodeIds;
    }

    get(valueName)
    {
        return this.data[valueName];
    }

    /**
     * @param {object} [params]
     * @param {string} [params.languageCode = "en"]
     * @param {boolean} [params.useStyle = false] if true returns the string only if it should be rendered
     * @returns {undefined | string}
     */
    getName({ languageCode = DEFAULT_LANGUAGE_CODE, useStyle = false })
    {
        throw new Error("Abstract method");
    }

    getId()
    {
        throw new Error("Abstract method");
    }

    /** @returns {boolean} */
    getCanNameEntity()
    {
        const entity = this.data;

        return isEntityNameable(entity.entityType, entity.subEntityType);
    }

    getIsRoutableEntity()
    {
        const entity = this.data;

        const entityName = this.getName();

        return isRoutableEntity(entity.entityType, entity.subEntityType, entityName);
    }

    /**
     * @returns  {boolean} - returns if entity type = structure and sub entity type = inaccessible
     */
    getIsEntityInaccessibleStructure()
    {
        const entity = this.data;

        return (entity.entityType === EntityType.STRUCTURE && entity.subEntityType === StructureEntityType.INACCESSIBLE);
    }

    /**
     * at time of writting, only floor opening entities should prevent nodes from being plotted on top of
     * @returns {boolean} - returns if the entity can have a node plotted on top of it
     */
    getIsNodeForbidden()
    {
        const entity = this.data;

        return (entity.entityType === EntityType.STRUCTURE && entity.subEntityType === StructureEntityType.FLOOR_OPENING);
    }

    /**
    * 
    * @param {Object} style - property/building style from map styles
    * @param {String} styleOption - default/selected/vacant
    * 
    * @returns entity style object to be used to create feature styles
    */
    getStyleObject(style, styleOption = STYLE_OPTIONS.DEFAULT) 
    {
        let styleObject;

        if (!style)
        {
            return
        }

        // Create Style
        // check if entity is vacant and not selected
        if (styleOption === STYLE_OPTIONS.DEFAULT && !!this.getCanNameEntity() && (this.getName() === undefined || this.getName() === ""))
        {
            styleOption = STYLE_OPTIONS.VACANT;
        }

        // get entity style options that include {polygon, text, layerIdx, name}
        let entityTypeStyle = deepValue(style, `${this.getEntityType()}.${this.getSubEntityType()}`);

        // if no entity polygon style for that type, use default 
        if (!entityTypeStyle) 
        {
            entityTypeStyle = deepValue(style, `default`, {});
        }

        // get style settings for the style option 
        let styleSettings = deepValue(entityTypeStyle, `polygon.${styleOption}`);

        if (!styleSettings && styleOption === STYLE_OPTIONS.VACANT)
        {
            styleSettings = deepValue(entityTypeStyle, `polygon.${STYLE_OPTIONS.DEFAULT}`);
        }

        if (!!styleSettings)
        {
            styleObject = {
                layerIdx: entityTypeStyle.layerIdx,
                name: entityTypeStyle.name,
                ...styleSettings
            }
        }

        // entitySubStyle should like like the following {default, selected, vacant}
        // return the selected style option if availble otherwise return default style option
        return styleObject;
    }

    /**
     * 
     * @param {Object} style - property/building style from map styles
     * @param {String} styleOption - default/selected
     * 
     * @returns entity style object to be used to create feature styles
     */
    getTextStyleObject(style, styleOption = STYLE_OPTIONS.DEFAULT) 
    {
        if (!style)
        {
            return
        }

        let entityTextStyle = deepValue(style, `${this.getEntityType()}.${this.getSubEntityType()}.text`);

        // if no entity text style for that entity type, use default
        if (!entityTextStyle) 
        {
            entityTextStyle = deepValue(style, `default.text`, {});
        }

        // entitySubStyle should like like the following {default, selected, vacant}
        // return the selected style option if available otherwise return nothing
        return entityTextStyle[styleOption];
    }

}

exports.AbstractEntityAccess = AbstractEntityAccess;
class CMSEntityAccess extends AbstractEntityAccess
{
    getName(options = {})
    {
        const defaultOptions = { languageCode: DEFAULT_LANGUAGE_CODE, useStyle: false };
        const { languageCode, useStyle } = { ...defaultOptions, ...options };

        const entity = this.data;
        let entityLabel = deepValue(entity, "entityLabel");
        if (!entityLabel)
        {
            return undefined;
        }

        const useText = deepValue(entity, `style.${languageCode}.useText`);

        if (!useText && useStyle)
        {
            return undefined;
        }
        else
        {
            let name;
            if (entityLabel.shortName)
            {
                if (typeof entityLabel.shortName === "object" && entityLabel.shortName[languageCode])
                {
                    name = entityLabel.shortName[languageCode];
                }
                else if (typeof entityLabel.shortName === "string")
                {
                    name = entityLabel.shortName;
                }
            }
            if (!name && entityLabel.longName)
            {
                if (typeof entityLabel.longName === "object" && entityLabel.longName[languageCode])
                {
                    name = entityLabel.longName[languageCode];
                }
                else if (typeof entityLabel.longName === "string")
                {
                    name = entityLabel.longName;
                }
            }
            return name || undefined;
        }
    }

    /**
     * @param {object} [options]
     * @param {string} [options.languageCode]
     * @returns {undefined | Array<number>}
     */
    getTextCoordinate(options = {})
    {
        const defaultOptions = { languageCode: DEFAULT_LANGUAGE_CODE };
        const { languageCode } = { ...defaultOptions, ...options };

        const coords = deepValue(this.data.textCoordinate, languageCode);
        if (Array.isArray(coords))
        {
            return coords;
        }
        return undefined;
    }

    /**
     * @param {object} options
     * @param {string} [options.languageCode]
     * @param {string} [options.theme]
     * @returns {undefined | string}
     * */
    getImage(options = {})
    {
        const defaultOptions = { languageCode: DEFAULT_LANGUAGE_CODE, theme: undefined };
        const { languageCode, theme } = { ...defaultOptions, ...options };

        const deLang = (val) =>
        {
            if (deepValue(val, languageCode))
            {
                return deepValue(val, languageCode);
            }
            if (typeof val === "string")
            {
                return val;
            }
            return undefined;
        };

        const light = deLang(deepValue(this.data, "entityLabel.lightIcon"));
        const dark = deLang(deepValue(this.data, "entityLabel.darkIcon"));
        const plain = deLang(deepValue(this.data, "entityLabel.iconImage"));

        if (theme === MAP_THEMES.CLASSIC && light)
        {
            return light;
        }

        if (theme === MAP_THEMES.DARK && dark)
        {
            return dark;
        }
        // return anything if there is no theme
        return plain || light || dark;
    }

    /** @returns {boolean| number} */
    getIsImageOnMap()
    {
        return deepValue(this.data, `style.${DEFAULT_LANGUAGE_CODE}.useIcon`);
    }

    /**
     * @param {object} options
     * @param {string} [options.languageCode]
     * @returns {undefined | number}
     */
    getTextRotation(options = {})
    {
        const defaultOptions = { languageCode: DEFAULT_LANGUAGE_CODE };
        const { languageCode } = { ...defaultOptions, ...options };

        return deepValue(this.data, `textRotation.${languageCode}`);
    }

    getId()
    {
        return this.data._id;
    }

    getNavId()
    {
        return this.data.entityId;
    }

    getImageExtent(options = {})
    {
        const defaultOptions = { languageCode: DEFAULT_LANGUAGE_CODE };
        const { languageCode } = { ...defaultOptions, ...options };
        return deepValue(this.data, `imageExtent.${languageCode}`);
    }

    getImageRotation(options = {})
    {
        const defaultOptions = { languageCode: DEFAULT_LANGUAGE_CODE };
        const { languageCode } = { ...defaultOptions, ...options };
        return deepValue(this.data, `imageRotation.${languageCode}`);
    }

    getRefType()
    {
        return this.data.refType;
    }

}

exports.CMSEntityAccess = CMSEntityAccess;


class CMSTopologyEntityAccess extends AbstractEntityAccess
{
    // overwrite constructor
    constructor(data, topology) 
    {
        super();

        this.data = data.properties || {};
        this.arcs = data.arcs;
        this.type = data.type;

        try
        {
            this.shape = topojson.feature(topology, data).geometry;
        }
        catch (err)
        {
            console.log({ topology, data })
        }
    }

    /**
     * Check if the item is virtual.
     * virtual entities are kiosks that don't need a node and don't get sent to navigation servers.
     *
     * @return {boolean} the virtual status of the item
     */
    isVirtual()
    {
        return this.data.virtual;
    }

    // overwrite get shape method
    getShape()
    {
        return this.shape;
    }

    getName(options = {})
    {
        const defaultOptions = { languageCode: DEFAULT_LANGUAGE_CODE, useStyle: false };
        const { languageCode, useStyle } = { ...defaultOptions, ...options };

        const entity = this.data;
        let entityLabel = deepValue(entity, "entityLabel");
        if (!entityLabel)
        {
            return undefined;
        }

        const useText = deepValue(entity, `style.${languageCode}.useText`);

        if (!useText && useStyle)
        {
            return undefined;
        }
        else
        {
            let name;
            if (entityLabel.shortName)
            {
                if (typeof entityLabel.shortName === "object" && entityLabel.shortName[languageCode])
                {
                    name = entityLabel.shortName[languageCode];
                }
                else if (typeof entityLabel.shortName === "string")
                {
                    name = entityLabel.shortName;
                }
            }
            if (!name && entityLabel.longName)
            {
                if (typeof entityLabel.longName === "object" && entityLabel.longName[languageCode])
                {
                    name = entityLabel.longName[languageCode];
                }
                else if (typeof entityLabel.longName === "string")
                {
                    name = entityLabel.longName;
                }
            }
            return name || undefined;
        }
    }

    /**
     * @param {object} [options]
     * @param {string} [options.languageCode]
     * @returns {undefined | Array<number>}
     */
    getTextCoordinate(options = {})
    {
        const defaultOptions = { languageCode: DEFAULT_LANGUAGE_CODE };
        const { languageCode } = { ...defaultOptions, ...options };

        const coords = deepValue(this.data.textCoordinate, languageCode);
        if (Array.isArray(coords))
        {
            return coords;
        }
        return undefined;
    }

    /**
     * @param {object} options
     * @param {string} [options.languageCode]
     * @param {string} [options.theme]
     * @returns {undefined | string}
     * */
    getImage(options = {})
    {
        const defaultOptions = { languageCode: DEFAULT_LANGUAGE_CODE, theme: undefined };
        const { languageCode, theme } = { ...defaultOptions, ...options };

        const deLang = (val) =>
        {
            if (deepValue(val, languageCode))
            {
                return deepValue(val, languageCode);
            }
            if (typeof val === "string")
            {
                return val;
            }
            return undefined;
        };

        const light = deLang(deepValue(this.data, "entityLabel.lightIcon"));
        const dark = deLang(deepValue(this.data, "entityLabel.darkIcon"));
        const plain = deLang(deepValue(this.data, "entityLabel.iconImage"));

        if (theme === MAP_THEMES.CLASSIC && light)
        {
            return light;
        }

        if (theme === MAP_THEMES.DARK && dark)
        {
            return dark;
        }
        // return anything if there is no theme
        return plain || light || dark;
    }

    /** @returns {boolean| number} */
    getIsImageOnMap()
    {
        return deepValue(this.data, `style.${DEFAULT_LANGUAGE_CODE}.useIcon`);
    }

    /**
     * @param {object} options
     * @param {string} [options.languageCode]
     * @returns {undefined | number}
     */
    getTextRotation(options = {})
    {
        const defaultOptions = { languageCode: DEFAULT_LANGUAGE_CODE };
        const { languageCode } = { ...defaultOptions, ...options };

        return deepValue(this.data, `textRotation.${languageCode}`);
    }

    /**
     * Get the ID from the data.
     *
     * @return {any} The ID from the data
     */
    getId()
    {
        return this.data._id;
    }

    /**
     * Get the navigation ID.
     *
     * @return {type} the entity ID
     */
    getNavId()
    {
        return this.data.entityId;
    }

    getImageExtent(options = {})
    {
        const defaultOptions = { languageCode: DEFAULT_LANGUAGE_CODE };
        const { languageCode } = { ...defaultOptions, ...options };
        return deepValue(this.data, `imageExtent.${languageCode}`);
    }

    getImageRotation(options = {})
    {
        const defaultOptions = { languageCode: DEFAULT_LANGUAGE_CODE };
        const { languageCode } = { ...defaultOptions, ...options };
        return deepValue(this.data, `imageRotation.${languageCode}`);
    }

    getRefType()
    {
        return this.data.refType;
    }

    getEntityInfo()
    {
        let entityInfo = { ...this.data };
        entityInfo.shape = this.shape;
        delete entityInfo.feature;
        return entityInfo;
    }

}

exports.CMSTopologyEntityAccess = CMSTopologyEntityAccess;

class CMSTopologyNodeAccess 
{
    constructor(data, topology, isNode = true)
    {
        if (data.properties)
        {
            this.data = data.properties;
        }
        else
        {
            this.data = data || {};
        }

        if (topology)
        {
            this.arcs = data.arcs;
            this.shape = topojson.feature(topology, data).geometry;
        }
        else
        {
            this.shape = data.shape;
        }
    }

    // uses cms id
    getId()
    {
        return this.data._id
    }

    /**
     * Get the navigation ID.
     *
     * @return {type} the entity ID
     */
    getNavId()
    {
        return this.data.nodeId;
    }

    getShape()
    {
        return this.shape;
    }

    getIsNodeConnection()
    {
        return this.data.getIsNodeConnection;
    }


}

class DigitizationGeoJSONFeatureAccess extends AbstractEntityAccess
{
    // overwrite constructor
    constructor(feature) 
    {
        super();

        this.data = feature.properties;

        try
        {
            this.feature = feature;
            this.shape = this.feature.geometry;
        }
        catch (err)
        {
            console.log({ topology, data })
        }
    }

    // overwrite get shape method
    getShape()
    {
        return this.shape;
    }

    getName(options = {})
    {
        //
    }


    /**
     * Get the ID from the data.
     *
     * @return {any} The ID from the data
     */
    getId()
    {
        // return this.data._id;
    }

    /**
     * Get the navigation ID.
     *
     * @return {type} the entity ID
     */
    getNavId()
    {
        // this.data.entityId;
    }

    getRefType()
    {
        //return this.data.refType;
    }

    getEntityInfo()
    {
        // let entityInfo = { ...this.data };
        // entityInfo.shape = this.shape;
        // delete entityInfo.feature;
        // return entityInfo;
    }

    getTextCoordinate()
    {
        //
    }

    // getStyleObject ()
    // {
    //     return {
    //         layerIdx: 1,
    //         name: "test",
    //     }
    // }

}

exports.DigitizationGeoJSONFeatureAccess = DigitizationGeoJSONFeatureAccess;

exports.CMSTopologyNodeAccess = CMSTopologyNodeAccess;

class CMSTopologyNodeConnectionAccess extends CMSTopologyNodeAccess
{
    // OVERWRITE don't change shape to circle
    constructor(data, topology)
    {
        super(data, topology, false);
    }
    // OVERWRITE use both node ids to create a unique id
    getId()
    {
        const { nodeId1, nodeId2 } = this.data;
        return `${nodeId1}-${nodeId2}`;
    }

    getNodeId1()
    {
        return this.data.nodeId1;
    }

    getNodeId2()
    {
        return this.data.nodeId2;
    }

    getLinkedPriorityMultiplier1()
    {
        return this.data.linkedPriorityMultiplier1;
    }

    getLinkedPriorityMultiplier2()
    {
        return this.data.linkedPriorityMultiplier2;
    }

    getLinkedId1()
    {
        const { nodeId1, nodeId2 } = this.data;
        return `${nodeId1}-${nodeId2}`;
    }

    getLinkedId2()
    {
        const { nodeId1, nodeId2 } = this.data;
        return `${nodeId2}-${nodeId1}`;
    }
}

exports.CMSTopologyNodeConnectionAccess = CMSTopologyNodeConnectionAccess;

class PublicEntityAccess extends AbstractEntityAccess
{
    getId()
    {
        return this.data.entityId;
    }

    getName(options = {})
    {
        const defaultOptions = { languageCode: DEFAULT_LANGUAGE_CODE, useStyle: false };
        const { useStyle } = { ...defaultOptions, ...options };

        const useText = deepValue(this.data, "style.useText");
        if (!useText && useStyle)
        {
            return undefined;
        }
        const shortName = deepValue(this.data, "shortName");
        const longName = deepValue(this.data, "longName", "");
        return shortName || longName;
    }

    /** @returns {undefined | number} */
    getTextRotation()
    {
        return deepValue(this.data, "textStyle.rotation");
    }

    getTextLocation()
    {
        return deepValue(this.data, "textStyle.location");
    }

    /**
     * @param {object} options
     * @param {string} [options.languageCode]
     * @param {string} [options.theme]
     * @returns {undefined | string}
     * */
    getImage(options)
    {
        const defaultOptions = { languageCode: DEFAULT_LANGUAGE_CODE, useStyle: false };
        const { theme } = { ...defaultOptions, ...options };

        let iconId = this.data.iconImage;

        if (theme === MAP_THEMES.CLASSIC && !!this.data.iconLight)
        {
            iconId = this.data.iconLight;
        }
        else if (theme === MAP_THEMES.DARK && !!this.data.iconDark)
        {
            iconId = this.data.iconDark;
        }

        return iconId;
    }

    getImageRotation()
    {
        return deepValue(this.data, "iconStyle.rotation");
    }

    getImageExtent()
    {
        return deepValue(this.data, "iconStyle.extent");
    }

    /** @returns {boolean| number} */
    getIsImageOnMap()
    {
        return deepValue(this.data, "style.useIcon");
    }

    getCategoryId()
    {
        return deepValue(this.data, "category._id");
    }
}
exports.PublicEntityAccess = PublicEntityAccess;
