import React from "react";
import { Button, Header, Modal, Progress } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import AddPropertyContext from "../../../store/AddPropertyContext";
import { useTranslation } from "react-i18next";

export const FloorPlanFinishModal = () =>
{
    const trans = useTranslation().t;
    const history = useHistory();
    const addPropertyContext = React.useContext(AddPropertyContext);
    const [uploadPercentage, setUploadPercentage] = React.useState(0);

    React.useEffect(() =>
    {
        if (!(addPropertyContext.state.propertyId && addPropertyContext.state.buildingId))
        {
            history.push("./")
        }

    },
        // ON MOUNT - ignore warning. 
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []);


    React.useEffect(() =>
    {

        if (addPropertyContext.state.numFloorsToUpload !== 0)
        {
            const percentage = addPropertyContext.state.numFloorsUploaded * 100 / addPropertyContext.state.numFloorsToUpload;

            setUploadPercentage(Math.floor(percentage));
        }
        else
        {
            setUploadPercentage(100);
        }

    }, [addPropertyContext.state])

    const handleOnDone = React.useCallback(() =>
    {
        addPropertyContext.resetState();
        history.push("/dashboard");
    }, [addPropertyContext, history])

    const handleNewBuildingSetup = React.useCallback(() =>
    {
        addPropertyContext.resetState(() =>
        {
            history.push(`./building?propertyId=${addPropertyContext.state.propertyId}`);
        });

    }, [history, addPropertyContext]);

    const renderLoadingIcon = () =>
    {
        if (uploadPercentage === 100)
        {
            return (<span className="ui image"><img src="/img/icon-check-big.svg" alt="" /></span>);
        }
        else
        {
            return (<img className="loading-m" src="/img/loading-m.gif" alt="Loading..." />);
        }
    }

    const renderHeader = () =>
    {
        if (uploadPercentage === 100)
        {
            return (trans("FloorPlanFinish.New_Building_Successfully_Added"));
        }
        else
        {
            return (trans("FloorPlanFinish.Your_Floor_Plans_are_Uploading"));
        }
    }

    const renderModalContent = () =>
    {
        if (uploadPercentage === 100)
        {
            return (
                <div>
                    <p>
                        {trans("FloorPlanFinish.You_will_receive_an_email_from_us_in_2_-")}<br />
                    </p>
                </div>
            );
        }
        else
        {
            return (
                <div>
                    <p>
                        {trans("FloorPlanFinish.This_may_take_a_few_minutes__Thank_you_f")}
                    </p>
                    <Progress percent={uploadPercentage} progress="percent" indicating />
                </div>
            );
        }
    }

    return (
        <Modal className="finish-modal" centered={false} open={true} basic dimmer="inverted" size="mini"
            closeOnDocumentClick={false} closeOnDimmerClick={false} >

            {renderLoadingIcon()}

            <Header content={renderHeader()} />

            <Modal.Content content={renderModalContent()} />

            <Modal.Actions>
                {
                    (uploadPercentage === 100) && (
                        <div>
                            <Button size="large" color="orange" content={trans("FloorPlanFinish.Done")} onClick={handleOnDone} />
                            <Button size="large" color="grey" content={trans("FloorPlanFinish.Add_New_Building")} onClick={handleNewBuildingSetup} />
                        </div>
                    )
                }
            </Modal.Actions>
        </Modal>
    )
}