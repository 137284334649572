import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Image, Header, Segment, Container } from 'semantic-ui-react';
import Footer from '../../layout/Footer';

export const Error404 = () =>
{
    const trans = useTranslation().t;
    return (
        <>
            <HeaderError />
            <Segment basic className="error-body">
                <Header as="h5">{trans("404.We_are_sorry,_the_page_you_requested_can")}</Header>
                <p>{trans("404.The_URL_may_be_misspelled_or_the_page_yo")}</p>
                <Image src="./img/graphic-404.png" as="span" />
                <Footer />
            </Segment>
        </>
    );
}

export const HeaderError = () =>
{
    const trans = useTranslation().t;
    return (
        <Segment basic className="header-error">
            <Container>
                <Image as="a" src="/img/logo.png" alt="" />
                <Button className="button-light">{trans("404.Report_Problem")}</Button>
            </Container>
        </Segment>
    );
}

