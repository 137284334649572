import { useEffect } from "react";

export const useClickAway = (open, handlerFn, target) => 
{
    function clickAwayHandler(e) 
    {
        if (!e.target.classList.contains(target)) 
        {
            handlerFn(false);
        }
    }

    useEffect(() => 
    {
        if (open) 
        {
            document.addEventListener("click", clickAwayHandler);
        }

        return () => 
        {
            document.removeEventListener("click", clickAwayHandler);
        };
    }, [open]);
};

