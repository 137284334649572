import React, { useState, useEffect, useRef } from "react";
import ClipboardJS from "clipboard";
import { ButtonIcon } from "./ButtonIcon";
import { Popup } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

const CopyButton = ({ text, timeoutLength = 2500, popupClassname, btnClassname, popupText=undefined, position="top center" }) =>
{
    const [copied, setCopied] = useState(false);
    const [open, setOpen]= useState(false);

    const trans = useTranslation().t;

    const copyTimer = useRef();
    const openTimer = useRef();

    useEffect(() =>
    {
        const clipboard = new ClipboardJS("#webCopyButton");
        return () => clipboard.destroy();
    }, []);

    const handleCopy = () =>
    {
        setCopied(true);
        clearTimeout(copyTimer.current);
        copyTimer.current = setTimeout(() =>
        {
            setCopied(false);
        }, timeoutLength);
    };

    const handleOpen = () =>
    {
        // Dont update state & timer if already copied since Popup with 'Copied' test is already open
        if (copied) return;

        setOpen(true);
        clearTimeout(openTimer.current);
        openTimer.current = setTimeout(() =>
        {
            setOpen(false);
        }, timeoutLength);
    };

    const handleClose = () =>
    {
        // If not copied then close the popup instantly else leave the Popup open with 'Copied' text
        if (!copied)
        {
            setOpen(false);
            clearTimeout(copyTimer.current);
        }
    };


    return (
        <Popup
            className={`webCopyPopup ${popupClassname ? popupClassname : ""}`}
            content={copied ? trans("SyncStoreTypeModal.Copied!") : `${popupText ? popupText : trans("Misc.Copy_URL_Clipboard")}` }
            open={open}
            basic
            position={position}
            size="small"
            inverted
            on="hover"
            onClose={handleClose}
            onOpen={handleOpen}
            trigger={(
                <ButtonIcon
                    className={`webCopyButton ${btnClassname ? btnClassname : ""}`}
                    icon="copy"
                    onClick={handleCopy}
                    id="webCopyButton"
                    data-clipboard-text={text}
                    data-clipboard-action="copy"
                />
            )}
        />
    );
};

export default CopyButton;
