import { useCounter } from "ahooks";
import React, { useContext, useEffect, useState, useMemo, useCallback, useRef } from "react";
import { deepUpdateValue, deepValue } from "mapsted.utils/objects";
import cloneDeep from "lodash.clonedeep";
import brandingApi from "../_api/branding.api";
import { StoreSharingContext } from "../store/StoreSharingProvider";
import BrandingContext from "../store/BrandingContext";

/**
 * @template T
 * @param {T} initialState
 * @returns {[T, (path: string, value: any) => void, React.Dispatch<T>]}
 */
export function useDeepState(initialState)
{
    const [state, setState] = useState({ ...initialState });

    const updateState = useCallback((path, update) =>
    {
        let newState;
        if (typeof update === "function")
        {
            const prevValue = deepValue(state, path);
            const newValue = update(prevValue);
            newState = deepUpdateValue(state, path, newValue);
        }
        else
        {
            newState = deepUpdateValue(state, path, update);
        }
        setState({ ...newState });
    }, [state]);

    const updateDeepState = useCallback((newObj) =>
    {
        const deepClone = cloneDeep(newObj);
        setState(deepClone);
    }, []);

    return [state, updateState, updateDeepState];
}

/**
 * Used to close modals using triggers.
 * First return is used for open second is close function.
 * @returns {[undefined | false, () => void]}
 */
export function useCloseTriggerModal()
{
    const [open, setOpen] = useState(undefined);
    const close = () =>
    {
        setOpen(false);
    };
    useEffect(() =>
    {
        if (open !== undefined)
        {
            setOpen(undefined);
        }
    }, [open]);
    return [open, close];
}


/**
 * @typedef {Object} UsePaginationReturn
 * @property {number} currentPage
 * @property {number} totalPages
 * @property {number} startIndex index of first element on page
 * @property {number} endIndex index of first element after elements on page
 * @property {(index: number) => void} setPage sets the page
 * @property {(amount:number) => void} incPage increment the page
 * @property {(amount:number) => void} decPage decrement the page
 * @property {number} pageSize
 * @property {number} nElements total number of elements in array
 */
/**
 * Pagination numbers and operations
 * @param {number} pageSize how many elements per page
 * @param {number} nElements total number of elements
 * @param {?number} initialPage start page default 0
 * @returns {UsePaginationReturn}
 */
export function usePagination(pageSize, nElements, initialPage = 0)
{
    const [current, ops] = useCounter(initialPage);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() =>
    {
        ops.set(0);
        setTotalPages(Math.ceil(nElements / pageSize));
    }, [nElements]);

    return {
        currentPage: current,
        totalPages,
        startIndex: current * pageSize,
        endIndex: Math.min(current * pageSize + pageSize, nElements),
        setPage: ops.set,
        incPage: ops.inc,
        decPage: ops.dec,
        pageSize,
        nElements
    };
}

export function useAllCompanyStores()
{
    const { state: { fieldLanguage } } = useContext(StoreSharingContext);
    const { state: { allProperties } } = useContext(BrandingContext);
    const [allCompanyStores, setAllCompanyStores] = useState([]);

    useEffect(() =>
    {
        const propNamesIdsAndBuildings = Object.values(allProperties)
            .filter((property) => property.propertyId)
            .map((prop) => ({ id: prop.propertyId, name: prop.name[fieldLanguage], buildings: prop.buildings }));

        // {{Pid, Pname, [Bid1, Bid2, Bid3, ...]}, ...} => [{Pid, Pname, Bid1, floors}, {Pid, Pname, Bid2, floors}, {Pid, Pname Bid3, floors}, ...]
        // The above map can be removed and replaced with the below flatMap slightly modified, will keep for now
        const flattenedBuildingsWithProperties = propNamesIdsAndBuildings
            .flatMap((obj) => Object.entries(obj?.buildings)?.map(([buildingId, building]) => ({
                buildingId: building.buildingId,
                buildingObjId: buildingId,
                floors: building.floors,
                propertyName: obj.name,
                propertyId: obj.id
            })));

        const allFloors = flattenedBuildingsWithProperties.flatMap((obj) => obj.floors);

        brandingApi.getEntitiesUsingFloorIds(allFloors.map((floor) => floor._id))
            .then((entities) =>
            {
                const entitiesBuildingAndPropertyData = entities.map((entityData) =>
                {
                    const buildingPropertyInfo = flattenedBuildingsWithProperties.find((obj) => obj.floors.map((floor) => floor._id).includes(entityData?.ref));
                    return {
                        _id: entityData?._id,
                        entityId: entityData?.entityId,
                        entityLabelId: entityData?.entityLabel?._id,
                        floorObjId: entityData?.ref,
                        ...entityData?.entityLabel,
                        ...buildingPropertyInfo
                    };
                });
                setAllCompanyStores(entitiesBuildingAndPropertyData);
            }).catch((err) => console.log(err));
    }, [allProperties]);

    return allCompanyStores;
}

/**
 * Uses a set to control the loading flag to avoid race conditions.
 * The `loading` boolean is true if the set is non empty.
 * `wrap` is an higher order function to wrap an async function
 * @template T
 * @template S
 * @returns {{ loading: boolean; add: () => string; remove: (id: string) => void; wrap: (f:(T) => Promise<S>) => ((T) => Promise<S>) }}
 */
export function useLoadingPool()
{
    const [loadingSet, setLoadingSet] = useState(new Set());

    const addLoader = () =>
    {
        const id = `${new Date().valueOf()}-${Math.floor(Math.random() * 1000)}`;
        setLoadingSet((set) =>
        {
            set.add(id);
            return new Set(set);
        });
        return id;
    };

    const removeLoader = (id) =>
    {
        setLoadingSet((set) =>
        {
            set.delete(id);
            return new Set(set);
        });
    };


    const wrapAsync = (asyncFunc) => (...args) =>
    {
        const token = addLoader();
        return asyncFunc(...args)
            .finally(() => removeLoader(token));
    };

    return {
        loading: loadingSet.size > 0,
        add: addLoader,
        remove: removeLoader,
        wrap: wrapAsync
    };
}
/**
 * Returns a memoized version of object. The memo is never triggered
 * Useful for defining objects in-line and not having to worry about them triggering
 * a useEffect
 * @example
 *  const options = { speed: 0 }; useEffect(() => something(options), [options]);   // triggers every render
 *  const options = useConstant({ speed: 0 }); useEffect(() => something(options), [options]);   // won't trigger
 * @template TUseConstant
 * @param {TUseConstant} object
 * @returns {TUseConstant}
 */
export function useConstant(object)
{
    return useMemo(() => object, []);
}

/**
 * Returns the memoized result of {...object1, ...object2}
 * @param {Object} object1
 * @param {Object} object2
 * @returns {Object}
 */
export function useMergedObjects(object1, object2)
{
    return useMemo(() => ({ ...object1, ...object2 }), [object2, object1]);
}

/**
 * Like useSetState but the state can be reset to {}
 * @template T
 * @param {T} initialState
 * @returns {[T, (patch: Partial<T> | ((prevState: T) => Partial<T>)) => void, () => void]}
 */
export function useClearableState(initialState)
{
    const [state, setState] = useState(initialState);

    const setMergeState = useCallback((patch) =>
    {
        setState((prevState) => ({ ...prevState, ...(typeof(patch) === "function" ? patch(prevState) : patch) }));
    }, []);

    const clearState = useCallback(() => setState({}), []);

    return [state, setMergeState, clearState];
}

/**
 * Adds a beforeunload event so the user is notified before closing the tab
 * @param {boolean} condition
 */
export function useBeforeUnload(condition)
{
    const conditionRef = useRef(condition);
    useEffect(() =>
    {
        conditionRef.current = condition;
    }, [condition]);

    const action = (e) =>
    {
        if (conditionRef.current)
        {
            e.preventDefault();

            // standard message bc most browsers ignore it anyways
            const message = "Some changes you've made have not been saved. Are you sure you want to leave?";
            e.returnValue = message;
            return message;
        }
    };

    useEffect(() =>
    {
        window.addEventListener("beforeunload", action);
        return () => window.removeEventListener("beforeunload", action);
    }, []);
}
/**
 * Exactly like `useEffect` but returns a function to manually trigger the effect
 * @param {React.EffectCallback} effect
 * @param {React.DependencyList} deps
 * @returns {() => void}
 */
export function useEffectWithTrigger(effect, deps)
{
    const [triggerFlag, setTriggerFlag] = useState(0);
    let newDeps = deps;
    if (Array.isArray(deps))
    {
        newDeps = [...deps, triggerFlag];
    }

    const trigger = useCallback(() => setTriggerFlag((i) => i+ 1), [setTriggerFlag]);

    useEffect(effect, newDeps);
    return trigger;
}


/**
 * Returns a set and some functions but add and remove are O(n).
 * add, remove and reset are stable as long as the set is unchanged
 * @param {Iterable} initial
 * @returns {{ set: Set, ops: { add: (e) => void, remove: (e) => void, reset: () => void }}}
 */
export function useSlowSet(initial)
{
    const [set, setSet] = useState(new Set(initial));
    const add = useCallback(
        (e) => setSet((set) => new Set(set.add(e))),
        [setSet]
    );

    const remove = useCallback(
        (e) => setSet((set) =>
        {
            set.delete(e);
            return new Set(set);
        }),
        [setSet]
    );
    const reset = useCallback(() => setSet(new Set(initial)), [setSet, initial]);

    return { set, ops: { add, remove, reset } };
}

/**
 * Exactly like `useState` except returns an object instead of an array.
 * Sometime it looks a little cleaner than the regular `useState`
 */
export function useStateObj(initial)
{
    const [state, setState] = useState(initial);
    return { value: state, set: setState };
}


/**
 * Calculate the maximum number of rows that could be accommodated in a table based on screen height
 * @param {{ rowHeight: number, minPageSize: number, maxPageSize: number, minScreenHeight: number }} param0 
 * @returns { { pageSize: number } }
 */
export const useDynamicTablePageSize = ({
    rowHeight, // approx height of a row in px
    minPageSize, // minimum number rows that needs to be displayed in table
    maxPageSize, // max number of rows that can be displayed in table
    minScreenHeight // approx screen height that can fit the min number of rows
}) =>
{
    const { innerHeight: height } = window;

    const [pageSize, setPageSize] = useState(minPageSize);

    useEffect(() =>
    {
        let pageSize = minPageSize;

        if (height > minScreenHeight)
        {
            // get the additional rows that could be accommodated based on the screen height along with the minimum number of rows
            let additionalRows = Math.ceil((height - minScreenHeight) / rowHeight);
            pageSize += additionalRows;

            // set to page size to max limit if the calculated page size exceeds it
            if (pageSize > maxPageSize)
            {
                pageSize = maxPageSize;
            }
        }

        setPageSize(pageSize);
    }, [height]);

    return {
        pageSize
    };
};