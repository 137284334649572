import React, { useEffect } from "react";
import { withRouter, Link , useHistory } from "react-router-dom";
import { Container, Image, Menu } from "semantic-ui-react";
import { NotificationsPopup } from "../popups/NotificationsPopup";
import "./layout.css";
import AppContext from "../../store/AppContext";
import BrandingContext from "../../store/BrandingContext";
import { useTranslation } from "react-i18next";
import LanguageDropdown from "../../_intl/languageDropdown/LanguageDropdown";

export const HeaderBar = withRouter(({ history }) =>
{
    const appContext = React.useContext(AppContext);
    const brandingContext = React.useContext(BrandingContext);

    const [viewNotifications, setViewNotifications] = React.useState(false);
    const [loadingMoreNotifications, setLoadingMoreNotifications] = React.useState(false);

    // const handleOpenNotifications = React.useCallback(() => setViewNotifications(true), []);
    // const handleCloseNotifications = React.useCallback(() => setViewNotifications(false), []);
    const handleToggleNotifications = React.useCallback(() => setViewNotifications((prev) => !prev), []);

    const handleRouteTo = React.useCallback((link) =>
    {
        history.push(link);
    }, [history]);    

    const unreadNotifications = React.useMemo(() => appContext.state.notifications.filter((n) => !n.readAt).length
        , [appContext.state.notifications]);

    const handleNotificationRead = React.useCallback((notification) =>
    {
        if (notification.readAt === undefined)
        {
            appContext.handleReadNotification(notification);
        }
    }, [appContext]);

    const handleReadAllNotification = React.useCallback( () =>
    {
        appContext.handleReadAllNotifications();

    }, [appContext]);

    const handleReadMoreNotifications = React.useCallback(() =>
    {
        setLoadingMoreNotifications(true);
        appContext.getMoreNotifications( () => setLoadingMoreNotifications(false) );
    }, [appContext]);


    // TODO: replace with socket io intergation
    // For now we are retrieving notifications every two minutes
    React.useEffect(() =>
    {

        const intervalId = setInterval(() =>
        {

            appContext.getUserNotifications();

        // Interval of two minutes
        }, 1000 * 2 * 60);

        return () => clearInterval(intervalId); //This is important

    }, [appContext]);

    const trans = useTranslation().t;

    const pages = [
        { title: trans("Header.Dashboard"), link: "/dashboard" },
        { title: trans("Header.Branding"), link: "/branding",  },
        { title: trans("Header.Maintenance"), link: "/maintenance", disabled: !Object.values(brandingContext.state.properties).length },
        { title: trans("Header.Templates"), link: "/templates",  },
    ];

    const queryHistory = useHistory();
    const urlSearchParams = new URLSearchParams(window.location.search);

    const removeQueryParam = () => 
    {       
        urlSearchParams.delete("notification_sidebar");    
        queryHistory.push({ search: urlSearchParams.toString() });
    };

    useEffect(() => 
    {    
        if (urlSearchParams.get("notification_sidebar"))
        {
            handleToggleNotifications();
            removeQueryParam();
        }
    }, []);

    return (
        <Menu secondary className="header-bar">
            <Container fluid>
                <Menu.Item>
                    <Image src="/img/logo.png" alt="" />
                </Menu.Item>
                {
                    pages.map((page) => (
                        <Menu.Item disabled={page.disabled} key={page.link} className={`${history.location.pathname.includes(page.link) ? "active" : ""}`} >
                            <Link to={page.link}>{page.title}</Link>
                        </Menu.Item>
                    ))
                }
                <Menu.Menu position="right">
                    <LanguageDropdown />
                    <HeaderLink icon="notifications" sub={unreadNotifications} onClick={handleToggleNotifications} />

                    <HeaderLink title={trans("Header.Settings")} icon="settings" onClick={() => history.push("/settings")} />
                    <HeaderLink title={trans("Header.Logout")} icon="logout" onClick={appContext.logout} />
                </Menu.Menu>
            </Container>

            {
                viewNotifications === true && (

                    <NotificationsPopup list={appContext.state.notifications}
                        loadingMoreNotifications={loadingMoreNotifications}
                        onNotificationClick={handleNotificationRead}
                        onReadMoreClick={handleReadMoreNotifications}
                        onMarkAllNotificationsReadClick={handleReadAllNotification}
                        onClose={handleToggleNotifications} />
                )
            }

        </Menu>
    );
});

const HeaderLink = ({ sub, icon, disabled, onClick }) => (
    <Menu.Item as="a" disable={disabled} onClick={onClick}>
        {sub > 0 && <span>{sub}</span>}
        <img src={`/img/icon-${icon}.svg`} alt="" />
    </Menu.Item>
);
