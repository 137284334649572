import React, { useEffect, useContext } from "react";
import ShowMoreText from "react-show-more-text";
import { Button, Input, Form } from "semantic-ui-react";
import { BuildingBoxDetail } from "./BuildingBoxDetail";
import { BuildingDetailItem } from "./BuildingDetailItem";
import { handleNormalizeUrl } from "../../_utils/utils";
import { nameCheck, phoneCheck, websiteCheck, descriptionCheck, emailCheck, detailSchema, shortNameCheck } from "../../_constants/validationSchemas";

import serverAPI from "../../_api/server.api";
import { InputFormGroup } from "../elements/InputFormGroup";
import { ErrorLabel } from "../elements/ErrorLabel";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../common/LanguageSelector";
import { getBuildingLanguages } from "../../_intl/constants";
import { ModalBox } from "../common/ModalBox";
import { LanguageContext } from "../../store/LanguageContext";
import { PROPERTIES } from "../../_constants/config";


const detailCheck =
{
    name: nameCheck,
    shortName: shortNameCheck,
    phoneCheck: phoneCheck,
    website: websiteCheck,
    description: descriptionCheck,
    email: emailCheck
};

//TODO:: refactor the object
export const BuildingBoxHead = ({ selectedObject, isProperty, onSaveChanges, displayLang, setDisplayLang }) =>
{
    const [editBox, setEditBox] = React.useState(false);

    const { name, longName, shortName, phoneNumbers, website, description, email } = selectedObject;
    const phone = Array.isArray(phoneNumbers) ? phoneNumbers[0] : "";

    /**
     * Close edit box on selected object change.
     */
    React.useEffect(() => setEditBox(false), [selectedObject._id]);

    const handleSaveChanges = React.useCallback((updatedInfo, callback) =>
    {
        onSaveChanges(updatedInfo, () =>
        {
            callback && callback();
            setEditBox(false);
        });

    }, [onSaveChanges]);

    if (editBox)
    {
        return (
            <EditBoxComponent
                name={name?.[displayLang] || longName?.[displayLang]}
                shortName={shortName?.[displayLang]}
                phone={phone}
                website={website?.[displayLang]}
                description={description?.[displayLang]}
                email={email}
                editBox={editBox}
                isProperty={isProperty}
                setEditBox={setEditBox}
                onSaveChanges={handleSaveChanges}
                displayLang={displayLang}
                setDisplayLang={setDisplayLang}
                getLangs={() => getBuildingLanguages(selectedObject)}
                countryCode={selectedObject.country}
            />
        );
    }
    else
    {
        return (
            <DetailBoxComponent
                name={name?.[displayLang] || longName?.[displayLang]}
                shortName={shortName?.[displayLang]}
                phone={phone}
                website={website?.[displayLang]}
                description={description?.[displayLang]}
                email={email}
                editBox={editBox}
                isProperty={isProperty}
                setEditBox={setEditBox}
                displayLang={displayLang}
                setDisplayLang={setDisplayLang}
                getLangs={() => getBuildingLanguages(selectedObject)}
            />
        );
    }

};

const DetailBoxComponent = ({ name, shortName, phone, website, description, email, editBox, setEditBox, isProperty, displayLang, setDisplayLang, getLangs }) =>
{
    const trans = useTranslation().t;

    return (
        <BuildingBoxDetail
            profile
            trigger={!editBox ? "pencil" : "cross"}
            subHeading={<span>{name}</span>}
            shortHeading={shortName}
            onClick={() => setEditBox(!editBox)}
        >
            <div style={{ marginTop: "3rem" }}>
                <LanguageSelector
                    activeLang={displayLang}
                    setActiveLang={setDisplayLang}
                    defaultDisplayedLangs={getLangs()}
                    allowAdditions={editBox}
                />
            </div>
            <div className="default limit-content">
                <div className="p p-detail" >
                    <ShowMoreText
                        keepNewLines
                        lines={4}
                        more={<ReadMoreButton />}
                        less={<ReadMoreButton collapse />}>
                        {description || ""}
                    </ShowMoreText>
                </div>
                <BuildingDetailItem title={trans("BuildingBoxHead.Phone")} detail={phone} />
                <BuildingDetailItem title={trans("BuildingBoxHead.Email")} detail={email} />
                <BuildingDetailItem title={trans("BuildingBoxHead.Website")} detail={website} />
            </div>
        </BuildingBoxDetail>
    );
};

export const ReadMoreButton = ({ collapse }) => (
    <Button className="arrow-toggle" icon={`chevron ${!collapse ? "down" : "up"}`} basic />
);

// Data reducer
function editDataReducer (state, { type, name, value })
{
    switch (type)
    {
    case "updateData":
        return {
            name: value.name,
            shortName: value.shortName,
            phone: value.phone,
            website: value.website,
            description: value.description,
            email: value.email,
        };
    case "updateValue":
        return {
            ...state,
            [name]: value,
            modified: true // used to show modal
        };
    default: throw new Error("unexpected action");
    }
}

const EditBoxComponent = ({
    name,
    shortName,
    phone,
    website,
    description,
    email,
    editBox,
    isProperty,
    setEditBox,
    onSaveChanges,
    displayLang,
    setDisplayLang,
    getLangs,
    countryCode,
}) =>
{
    const [data, dispatchData] = React.useReducer(
        editDataReducer,
        { name: name || "", shortName: shortName || "", phone: phone || "", website: website || "", description: description || "", email: email || "" }
    );

    const languageCtx = useContext(LanguageContext);
    const { languages } = languageCtx;

    const trans = useTranslation().t;
    const [saveButtonEnabled, setSaveButtonEnabled] = React.useState(true);
    const [validationErrors, setValidationErrors] = React.useState({});
    const [isPhoneValidationChecked, setIsPhoneValidationChecked] = React.useState(true);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const newLang = React.useRef(false);

    // Because we have to wait on an api to check phone validation we use this bool to check if the phone number has actually been validated.
    // This can be used to ignore the display of validation ui when not validating to avoid confusing the user.

    useEffect(() =>
    {
        validateAllData();
    },
    // ON MOUNT - ignore warning.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

    useEffect(() =>
    {
        dispatchData({
            type: "updateData",
            value: {
                name: name || "",
                shortName: shortName || "",
                phone: phone || "",
                website: website || "",
                description: description || "",
                email: email || ""
            }
        });
    }, [displayLang]);

    /**
     * Validates data based on schema and returns object if passed.
     * Else return nothing.
     */
    const validateAllData = React.useCallback(async () =>
    {
        let validateData = { ...data };
        try
        {
            validateData.website = handleNormalizeUrl(validateData.website);
            validateData.phoneCheck = await serverAPI.validatePhoneNumber(validateData.phone, countryCode);

            await detailSchema.validate(validateData, { abortEarly: false });

            setIsPhoneValidationChecked(true);
            return validateData;
        }
        catch (err)
        {
            if (err && err.name === "ValidationError")
            {
                let validationErrors = {};

                err.inner.forEach((currentError) =>
                {
                    validationErrors[currentError.path] = currentError.message;
                });

                // Updates UI website to normalized website
                if (!validationErrors.website)
                {
                    dispatchData({ type: "updateValue", name: "website", value: validateData.website });
                }

                setValidationErrors(validationErrors);
                setIsPhoneValidationChecked(true);

                return;
            }
        }

    }, [data]);

    const handleSaveChanges = async () =>
    {
        setSaveButtonEnabled(false);

        const validateData = await validateAllData();

        if (validateData)
        {

            let props = {
                phoneNumbers: [validateData.phone],
                website: { [displayLang]: validateData.website },
                description: { [displayLang]: validateData.description },
                email: validateData.email,
            };

            if (isProperty)
            {
                props.name = { [displayLang]: validateData.name };
            }
            else
            {
                props.longName = { [displayLang]: validateData.name };
                props.shortName = { [displayLang]: validateData.shortName };
            }

            onSaveChanges(props, () => setSaveButtonEnabled(true));
        }
        else
        {
            setSaveButtonEnabled(true);
        }

    };

    const handleTextChange = React.useCallback(async (e, { name, value }) =>
    {
        dispatchData({ type: "updateValue", name, value });

        let validationErrorsCopy = JSON.parse(JSON.stringify(validationErrors));

        if (name === "phone")
        {
            name = "phoneCheck";
            if (isPhoneValidationChecked)
            {
                setIsPhoneValidationChecked(false);
            }
        }

        delete validationErrorsCopy[name];

        // Validate Field
        try
        {
            if (name === "website")
            {
                value = handleNormalizeUrl(value);
            }

            await detailCheck[name].validate(value, { abortEarly: false });
        }
        catch (err)
        {
            if (err && err.name === "ValidationError")
            {
                validationErrorsCopy[name] = err.message;
            }
        }

        setValidationErrors(validationErrorsCopy);
    }, [validationErrors, isPhoneValidationChecked]);

    const handleChangeDisplayLang = (lang) =>
    {
        if (data.modified)
        {
            newLang.current = lang;
            return setIsModalOpen(true);
        }

        setDisplayLang(lang);
    };

    const handleClickPositive = () =>
    {
        setDisplayLang(newLang.current);
        setIsModalOpen(false);
    };

    const renderErrorLabelMessage = () =>
    {
        const validationErrorKeys = Object.keys(validationErrors);
        if (validationErrorKeys.length === 1)
        {
            return trans(validationErrors[validationErrorKeys[0]]);
        }
        else
        {
            return trans("BuildingBoxHead.Please_make_sure_all_of_the_above_inform");
        }

    };

    return (
        <BuildingBoxDetail profile
            trigger={!editBox ? "pencil" : "cross"}
            onClick={() => setEditBox(!editBox)}
            subHeading={(
                <InputFormGroup
                    label={`${isProperty ? trans("BuildingBoxHead.Property") : trans("BuildingBoxHead.Building")} ${trans("BuildingBoxHead.Name")}`}
                    className="form-group-input"
                    hideError={true}
                    placeholder={trans("BuildingBoxHead.e_g__Building_Name")}
                    type="text"
                    name="name"
                    value={data.name}
                    onChange={handleTextChange}
                    error={!!validationErrors["name"]}
                    maxLength={PROPERTIES.MAX_NAME_LENGTH}
                />
            )}
        >
            <LanguageSelector
                activeLang={displayLang}
                setActiveLang={handleChangeDisplayLang}
                defaultDisplayedLangs={getLangs()}
                allowAdditions={editBox}
            />
            <ConfirmActionModal
                open={isModalOpen}
                activeLang={languages?.[displayLang]?.title}
                buttonContent={trans("common.Yes")}
                onButtonClick={handleClickPositive}
                onClose={() => setIsModalOpen(false)}
            />
            {(!isProperty) && (
                <InputFormGroup
                    label={trans("BuildingBoxHead.Building_Nickname")}
                    className="form-group-input"
                    placeholder={trans("BuildingBoxHead.e_g__Delta")}
                    type="text"
                    name="shortName"
                    hideError={true}
                    value={data.shortName}
                    onChange={handleTextChange}
                    error={!!validationErrors["shortName"]}
                    maxLength={PROPERTIES.MAX_SHORTNAME_LENGTH}
                />
            )}

            <div className="building-default">
                <Form>
                    <p>{trans("BuildingBoxHead.Description")}</p>
                    <Form.TextArea
                        placeholder={`${trans("BuildingBoxHead.Write_a_short")} ${isProperty ? trans("BuildingBoxHead.property") : trans("BuildingBoxHead.building")} ${trans("BuildingBoxHead.description_here")}`}
                        value={data.description}
                        name="description"
                        onChange={handleTextChange}
                        error={!!validationErrors["description"]}
                        rows="4"
                    />
                </Form>

                <BuildingDetailItem
                    title={trans("BuildingBoxHead.Phone")}
                    validated={!validationErrors["phoneCheck"]}
                    onBlur={validateAllData}
                    isValidationChecked={isPhoneValidationChecked}
                    detail={<Input value={data.phone} name="phone" onChange={handleTextChange} error={(!!validationErrors["phoneCheck"])} maxLength={PROPERTIES.MAX_PHONE_NUMBER_LENGTH} />}
                />

                <BuildingDetailItem
                    title={trans("BuildingBoxHead.Email")}
                    validated={data.email && !validationErrors["email"]}
                    detail={<Input value={data.email} name="email" onChange={handleTextChange} placeholder={trans("BuildingBoxHead.name@example_com")} error={!!validationErrors["email"]} />}
                />

                <BuildingDetailItem title={trans("BuildingBoxHead.Website")} validated={!validationErrors["website"]}
                    detail={<Input placeholder={trans("BuildingBoxHead.example_com")} value={data.website} name="website" onChange={handleTextChange} error={!!validationErrors["website"]} />} />

                {(Object.keys(validationErrors).length > 0) && (
                    <ErrorLabel>
                        {renderErrorLabelMessage()}
                    </ErrorLabel>
                )}

                <Button
                    floated="right"
                    color="orange"
                    content={trans("BuildingBoxHead.Save")}
                    onClick={handleSaveChanges}
                    disabled={!saveButtonEnabled}
                />
            </div>
        </BuildingBoxDetail>
    );
};

export const ConfirmActionModal = ({ activeLang, onButtonClick, buttonContent, trigger, buttonIcon, ...rest }) => 
{
    const trans = useTranslation().t;
    return (
        <ModalBox className="confirmActionModal"
            // trigger={trigger || <ButtonIcon icon={buttonIcon} />}
            trigger={trigger}
            header={trans("Cancel_Confirmation_Modal.Confirm_Action")}
            actions={<Button color="orange" floated="right" content={buttonContent} onClick={() => onButtonClick()}/>}
            {...rest}>
            <p className="p-modal">
                {trans("MapOverlaysActivitySideBar.Lost_Confirmation", { name: activeLang })}
            </p>
        </ModalBox>
    );
};
