import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import {  DEFAULT_HIDDEN_MODALS, DEFAULT_IMAGES_SEARCH_AND_FILTERS, ERROR_TYPES, IMAGE_ACTION_MODES, IMAGE_ASPECT_RATIOS, IMAGE_SORTING_ACTIONS, IMAGE_SORTING_SESSION_KEY, MAX_COVER_IMAGES_LIMIT } from "../../constant";
import { GmsActionMode, useGmsContext } from "../../GmsContext";
import { useGetImages } from "../hooks/image-management-server-hooks";
import { ImagesUploadProvider } from "./ImagesUploadContext";
import { ImageDeleteProvider } from "./ImageDeleteContext";
import { ImageEditProvider } from "./ImageEditContext";
import ImageCopyProvider from "./ImageCopyContext";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";

const ImagesBaseContext = createContext(null);

export const useImagesBaseContext = () => useContext(ImagesBaseContext);


function ImageBaseProvider({ children, onAddImage, onEditImage, onDeleteImage, ...rest })
{
    const queryClient = useQueryClient();
    const gmsContext = useGmsContext();
    const { gmsActionMode } = gmsContext;
    const [imageActionMode, setImageActionMode] = useState(null);
    const [imagesSearchAndFilters, setImagesSearchAndFilters] = useState(
        DEFAULT_IMAGES_SEARCH_AND_FILTERS
    );
    const [selectedImageId, setSelectedImageId] = useState(null);
    const [imagesSelectedForLinking, setImagesSelectedForLinking] = useState([]);
    const [preAddedImageFilerIds, setPreAddedImageFilerIds] = useState([]);
    const [showImagesRecycleBin, setShowImagesRecycleBin] = useState(false); 
    const [hiddenModals, setHideModals] = useState(DEFAULT_HIDDEN_MODALS);
    const [prevImagesSearchAndFilters, setPrevImagesSearchAndFilters] = useState(
        DEFAULT_IMAGES_SEARCH_AND_FILTERS
    );

    useEffect(() => 
    {
        const savedFilter = sessionStorage.getItem(IMAGE_SORTING_SESSION_KEY);
        if (savedFilter) 
        {
            handleChangeImageFilters({ sort: savedFilter });
        }
        else handleChangeImageFilters({ sort: IMAGE_SORTING_ACTIONS.NEWEST_UPLOADED });
    }, [gmsActionMode]); 


    useEffect(() => 
    {
        handleChangeImagesSearchValue("");
    }, [gmsContext.activeIndex, showImagesRecycleBin]);
    
    const trans = useTranslation().t;
    const imagesQuery = useGetImages({
        imagesSearchAndFilters,
        enabled: gmsActionMode !== GmsActionMode.LINK_ICON,
    });

    const imagesData = useMemo(
        () => imagesQuery.data?.pages?.flatMap((page) => page.results) || [],
        [imagesQuery.data]
    );

    const selectedImageInfo = useMemo(() => 
    {
        if (!imagesData.length || !selectedImageId) return null;
        return imagesData.find((image) => image._id === selectedImageId);
    }, [imagesData, selectedImageId]);

    function handleChangeImageFilters(value) 
    {
        if (value.sort) sessionStorage.setItem(IMAGE_SORTING_SESSION_KEY, value.sort);
        setImagesSearchAndFilters((prev) => ({
            ...prev,
            filters: { ...prev.filters, ...value },
        }));
    }



    function handleChangeImagesSearchValue(value) 
    {
        setImagesSearchAndFilters((prev) => ({ ...prev, searchValue: value }));
    }

    function handleSelectImage(id) 
    {
        setSelectedImageId(id);
    }

    function handleCloseViewModal() 
    {
        setSelectedImageId(null);
        setImageActionMode(null);
    }

    function handleCloseRecycleBin()
    {
        setShowImagesRecycleBin(false);
        setHideModals({ ...DEFAULT_HIDDEN_MODALS });

    }

    function handleViewImage(id)
    {
        setImageActionMode(IMAGE_ACTION_MODES.VIEW);
        setSelectedImageId(id);
    }

    function handleSelectCoverImagesForLinking({ preAddedImageIds, id })
    {
        const idAlreadyExistsInPreAddedImageIds =
                preAddedImageIds.includes(id);
        const imagesLimitExceeded = !imagesSelectedForLinking.includes(id)
                && imagesSelectedForLinking.length + preAddedImageIds.length 
                >= MAX_COVER_IMAGES_LIMIT;

        if (idAlreadyExistsInPreAddedImageIds) 
        {
            toast.error(trans("gms.image_present"));
            return;
        }

        if (imagesLimitExceeded) 
        {
            toast.error(trans("gms.cover_images_size_limit_err"));
        }

        setImagesSelectedForLinking((prev) => 
        {
            let newIds = [];
            const existingIdIndex = prev.findIndex((p) => p === id);
            if (existingIdIndex !== -1) 
            {
                newIds = prev.toSpliced(existingIdIndex, 1);
            }
            else if (imagesLimitExceeded) 
            {
                newIds = prev;
            }
            else 
            {
                newIds = [...prev, id];
            }

            return newIds;
        });
    }

    function handleSelectIconImageForLinking({ preAddedImageIds, id })
    {
        const idAlreadyExistsInPreAddedImageIds =
                preAddedImageIds.includes(id);
        if (idAlreadyExistsInPreAddedImageIds) 
        {
            toast.error(trans("gms.image_present"));
            return;
        }
        setImagesSelectedForLinking([id]);
    }

    function handleSelectImageForLinking(id) 
    {
        const preAddedImageIds = preAddedImageFilerIds.map((filerId) => 
        {
            const image = imagesData.find((image) => image.filerId === filerId);
            return image?._id;
        });

        if (gmsActionMode === GmsActionMode.LINK_COVER_IMAGES) 
        {
            handleSelectCoverImagesForLinking({ preAddedImageIds, id });
        }

        if (gmsActionMode === GmsActionMode.LINK_ICON_IMAGE || gmsActionMode === GmsActionMode.REPLACE_IMAGE) 
        {
            handleSelectIconImageForLinking({ preAddedImageIds, id });
        }
    }


    function handleAddIconImage() 
    {
        if (gmsActionMode === GmsActionMode.LINK_ICON_IMAGE) 
        {
            const filerId = imagesData.filter((image) => imagesSelectedForLinking.includes(image._id)
            )?.[0]?.filerId;
            onAddImage(filerId);
        }

        if (gmsActionMode === GmsActionMode.LINK_COVER_IMAGES) 
        {
            const filerIds = imagesData
                .filter((image) => imagesSelectedForLinking.includes(image._id))
                .map((image) => image.filerId);
            onAddImage(filerIds);
        }
        gmsContext.handleCloseGms();
    }

    function handleTriggerLinkCoverImages({ addedImageFilerIds = [] } = {})
    {
        gmsContext.handleTriggerLinkCoverImage();
        setPreAddedImageFilerIds(addedImageFilerIds);
        handleChangeImageFilters({ ratio: IMAGE_ASPECT_RATIOS.RATIO_3 });
    }

    function handleTriggerLinkIconImage({ addedImageFilerIds = [] } = {})
    {
        gmsContext.handleTriggerLinkIconImage();
        setPreAddedImageFilerIds(addedImageFilerIds);
        handleChangeImageFilters({ ratio: IMAGE_ASPECT_RATIOS.RATIO_1 });
    }

    function handleTriggerRecycleBin()
    {
        setShowImagesRecycleBin(true);
        setHideModals({ ...DEFAULT_HIDDEN_MODALS, gmsModal: true });
    }

    const contextValue = {
        selectedImageInfo,
        imagesQuery,
        imagesData,
        imageActionMode,
        hiddenModals,
        showImagesRecycleBin,
        imagesSearchAndFilters,
        imagesSelectedForLinking,
        preAddedImageFilerIds,
        prevImagesSearchAndFilters,
        setImagesSearchAndFilters,
        setHideModals,
        setPrevImagesSearchAndFilters,
        handleCloseRecycleBin,
        handleTriggerRecycleBin,
        setImageActionMode,
        setImagesSelectedForLinking,
        setSelectedImageId,
        handleViewImage,
        handleCloseViewModal,
        handleSelectImage,
        handleChangeImageFilters,
        handleChangeImagesSearchValue,
        handleTriggerLinkCoverImages,
        handleTriggerLinkIconImage,
        handleAddIconImage,
        handleSelectImageForLinking,
        ...rest
    };

    return <ImagesBaseContext.Provider value={contextValue}>
        <ImagesUploadProvider >
            <ImageDeleteProvider onDeleteImage={onDeleteImage}>
                <ImageEditProvider onEditImage={onEditImage}>
                    <ImageCopyProvider>
                        {children}
                    </ImageCopyProvider>
                </ImageEditProvider>
            </ImageDeleteProvider>
        </ImagesUploadProvider>
    </ImagesBaseContext.Provider>;
}


export default ImageBaseProvider;
