import React, { Component, useCallback, useContext } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Segment, Portal, Header, Tab, Menu, Icon } from "semantic-ui-react";
import { ButtonIcon } from "../../common/ButtonIcon";
import { HeaderPortal } from "../HeaderPortal";
import "./CategorySidebar.css";
import { CategoryInfoFirstModal } from "./CategoryInfoFirstModal";
import { EditGroupsModal } from "./EditGroupsModal";
import { ReorderCategoryModal } from "./ReorderCategoryModal";
import { CategoryContext } from "../../../store/CategoryProvider";
import { filerUrl } from "../../../_utils/utils";
import { EditCategoryModal } from "./EditCategoryModal";
import { CATEGORY_EDIT_TYPE } from "../../../_constants/branding";
import { StyledIcon } from "./StyledIcon";
import {
    sortableContainer,
    sortableElement,
    sortableHandle,
} from "react-sortable-hoc";
import { useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { DEFAULT_LANGUAGE_CODE } from "../../../_constants/constants";
import LanguageSelector from "../../common/LanguageSelector";
import { ExportGroups, ExportSuccessModal } from "../../modalsPopups/exportGroups/ExportGroups";
import { ButtonBasic } from "../../common/ButtonIcon";
import { CategoryCSVModal } from "./CategoryCSVModal";

class CategorySideBar extends Component 
{
    static contextType = CategoryContext;

    constructor(props) 
    {
        super(props);
        this.state = {
            reorderModalOpen: false,
            displayLang: DEFAULT_LANGUAGE_CODE,
            openExportModal: false,
            openCSVModal: false,
            openExportSuccessMessageModal: false
        };

        this.setReorderModalOpen = this.setReorderModalOpen.bind(this);
    }

    setReorderModalOpen(value) 
    {
        this.setState({ reorderModalOpen: value });
    }

    setDisplayLang = (value) => 
    {
        this.setState({
            displayLang: value
        });
    }

    getCategoryLanguages = () => 
    {
        const { categoryFlatMap } = this.context;
        const availableCatlangs = new Set();
        Object.keys(categoryFlatMap).forEach((catId) => 
        {
            if (categoryFlatMap[catId].name) 
            {
                Object.keys(categoryFlatMap[catId].name).map((catNameLang) => availableCatlangs.add(catNameLang));
            }
        });
        return Array.from(availableCatlangs);
    };

    render() 
    {
        const { open, handleClose, t: trans } = this.props;
        const { categoryGroups, getIconInfo } = this.context;
        const { reorderModalOpen } = this.state;
        return (
            <Portal onClose={handleClose} open={open} closeOnDocumentClick={false}
                closeOnEscape={false}>
                <Segment basic className="store-detail-box categorySidebar">
                    <HeaderPortal className="categoryHeader" header={trans["header"]} onClick={handleClose}
                        info={<>
                            <div style={{ position: "absolute", right: 40 }}>
                                <ButtonIcon icon="settings-blue" className="editCategoryTrigger" onClick={() => this.setReorderModalOpen(true)} />
                            </div>
                            <ReorderCategoryModal reorderModalOpen={reorderModalOpen} setReorderModalOpen={this.setReorderModalOpen} />
                            <CategoryInfoFirstModal />
                        </>
                        } />
                    <div className="aligner">
                        <EditGroupsModal enableExport={true} exportTrigger={
                            <div className="headingActions">
                                <ButtonBasic icon="copy" disabled={!categoryGroups?.length} content={trans("common.Copy")} onClick={() => this.setState({ openExportModal: true })} />
                                <ButtonBasic icon="import" disabled={!categoryGroups?.length} content={trans("common.Export")} onClick={() => this.setState({ openCSVModal: true })} />
                            </div>
                        }
                        />
                        <CategoryCSVModal
                            isOpen={this.state.openCSVModal}
                            close={() => this.setState({ openCSVModal: false })}
                        />
                    </div>
                    <div style={{ padding: "21px" }}>
                        <LanguageSelector
                            activeLang={this.state.displayLang}
                            setActiveLang={this.setDisplayLang.bind(this)}
                            defaultDisplayedLangs={this.getCategoryLanguages()}
                            allowAdditions={false}
                        />
                    </div>
                    <Tab menu={{ secondary: true, pointing: true }} panes={tabPanes(categoryGroups, getIconInfo, this.state.displayLang)} className="categorySidebarTab" />
                    <ExportGroups
                        openModal={this.state.openExportModal}
                        onCloseModal={() => this.setState({ openExportModal: false })}
                        onSuccess={() => this.setState({ openExportModal: false, openExportSuccessMessageModal: true })} />
                    <ExportSuccessModal
                        successMessageModalOpen={this.state.openExportSuccessMessageModal}
                        onSuccessMessageModalClose={() => this.setState({ openExportSuccessMessageModal: false })} />
                </Segment>
            </Portal>
        );
    }
}

export default withTranslation()(CategorySideBar);

export const CategoryEditHeading = ({ heading, onClick, editingEnabled = true, enableExport = false, exportTrigger }) => (
    <Header as="h3" className="categoryEditHeading">
        {heading}
        {(editingEnabled) && <ButtonIcon icon="pencil" onClick={onClick} />}
        {(enableExport) && exportTrigger}
    </Header>
);

const tabPanes = (categoryGroups, getIconInfo, displayLang, editingEnabled) => 
{
    const listOfPanes = categoryGroups.map((cg) => 
    {
        const iconInfo = getIconInfo(cg);
        const catName = cg.name[displayLang] || cg.name[DEFAULT_LANGUAGE_CODE];

        return {
            menuItem: (
                <Menu.Item key={cg._id}>
                    <span className="groupImg">
                        <StyledIcon title={catName} imageSrc={filerUrl(iconInfo.iconImage)} />
                    </span>
                    <span className={cg.name[displayLang] ? "" : " categoryNameHighlight"}>
                        {catName}
                    </span>
                </Menu.Item>
            ),
            render: () => <CategoryTabPane categoryGroup={cg} editingEnabled={editingEnabled} displayLang={displayLang} />
        };
    });

    return listOfPanes;
};


const CategoryTabPane = ({ categoryGroup, editingEnabled, displayLang }) => 
{
    /** @type {typeof import("../../../_intl/resources.json")["en"]["translation"]["settings_tab_pane"]} */
    const trans = useTranslation().t("settings_tab_pane");
    return (
        <Tab.Pane>
            <Scrollbars className="inScroll" autoHide autoHeight autoHeightMin={"calc(1vh)"} autoHeightMax={"calc(100vh - 380px)"}>
                <Segment basic className="aligner">
                    <EditCategoryModal
                        category={categoryGroup}
                        editConfig={CATEGORY_EDIT_TYPE.GROUP}
                        trigger={<CategoryEditHeading heading={trans["heading"]} editingEnabled={editingEnabled} />}
                    />
                    <CategoryList
                        categoryList={categoryGroup.subCategories}
                        editingEnabled={editingEnabled}
                        displayLang={displayLang}
                        parent={categoryGroup}
                    />
                </Segment>
            </Scrollbars>
        </Tab.Pane>
    );
};

export const CategoryList = ({ categoryList, editingEnabled, reorderable = false, onSortEnd, reorderedListsMap = undefined, expandedListsMapOps = undefined, displayLang, parent }) => 
{
    const categoryContext = useContext(CategoryContext);
    const reorderedObjList = reorderable && reorderedListsMap.get(categoryContext.propertyId)?.order;
    const categoryObjList = reorderedObjList ? reorderedObjList : categoryContext.getCategoriesFromIds(categoryList);

    const SortableContainer = sortableContainer(({ children }) => <ul className="categoryList">{children}</ul>);

    if (!reorderable) 
    {
        return (
            <div className="categoryCover">
                <ul className="categoryList">
                    {categoryObjList.map((category) => (
                        <CategoryListItem
                            key={category._id}
                            category={category}
                            editingEnabled={editingEnabled}
                            displayLang={displayLang}
                            parent={parent}
                        />
                    ))}
                </ul>
            </div>
        );
    }

    return (
        <div className="categoryCover">
            <SortableContainer onSortEnd={(args) => onSortEnd({ ...args, parent })} helperClass="draggable-item modal-overlayList" lockAxis="y" lockToContainerEdges useDragHandle>
                {categoryObjList.map((category, index) => (
                    <CategoryListItem
                        key={category._id}
                        category={category}
                        index={index}
                        value={category}
                        onSortEnd={onSortEnd}
                        editingEnabled={editingEnabled}
                        reorderable={true}
                        reorderedListsMap={reorderedListsMap}
                        expandedListsMapOps={expandedListsMapOps}
                        displayLang={displayLang}
                        parent={parent}
                    />
                ))}
            </SortableContainer>
        </div>
    );
};

const CategoryListItem = ({
    category,
    onSortEnd,
    index,
    value,
    editConfig = CATEGORY_EDIT_TYPE.CATEGORY,
    editingEnabled = true,
    reorderable = false,
    reorderedListsMap,
    expandedListsMapOps,
    displayLang,
    parent,
    subCategories,
    subSubCatClass
}) => 
{
    const { getCategoryStyledIcon } = useContext(CategoryContext);
    const [uncontrolledOpen, setOpen] = useState(false);

    let open = reorderable ? expandedListsMapOps.get(category._id) : uncontrolledOpen;

    const updateOpenSublist = (bool) => expandedListsMapOps.set(category._id, bool);

    const handleToggleSubList = useCallback(() => updateOpenSublist(!open), [open]);

    const hasSubCategories = Array.isArray(category.subCategories) && category.subCategories.length > 0;
    const showSubCategories = open && hasSubCategories;

    const DragHandle = sortableHandle(() => <ButtonIcon className="categoryItemIcon" icon="settings-item" disabled={showSubCategories} />);
    const SortableItem = sortableElement(({ reorderedListsMap }) => 
    {

        const catName = category.name[displayLang] || category.name[DEFAULT_LANGUAGE_CODE];

        return (
            <li className="category-list-item">
                <ButtonIcon category={category} className={`itemCategory${(hasSubCategories ? " clickAble" : "")} ${subSubCatClass ? subSubCatClass : ""}`} onClick={handleToggleSubList}>
                    <DragHandle />
                    {catName}
                    {(hasSubCategories) && <Icon name="angle down" className={open ? "iconOpen" : "iconClosed"} />}
                </ButtonIcon>
                {(editingEnabled) && (
                    <EditCategoryModal
                        editConfig={editConfig}
                        category={category}
                        styledIcon={getCategoryStyledIcon(category)}
                        trigger={<ButtonIcon icon="pencil" className="editCate" />}
                    />
                )}
                {(showSubCategories) && (
                    <SubCategoryList
                        onSortEnd={onSortEnd}
                        subCategoryList={category.subCategories}
                        editingEnabled={editingEnabled}
                        reorderable={true}
                        reorderedListsMap={reorderedListsMap}
                        expandedListsMapOps={expandedListsMapOps}
                        displayLang={displayLang}
                        parent={category}
                        subSubCatClass={subCategories ? "subSubCategoryList" : ""}
                    />
                )}
            </li>
        );
    });

    if (!reorderable) 
    {
        const catName = category.name[displayLang] || category.name[DEFAULT_LANGUAGE_CODE];
        return (
            <li className="category-list-item">
                <ButtonIcon category={category} className={`itemCategory${(hasSubCategories ? " clickAble" : "")}`} onClick={() => setOpen(!uncontrolledOpen)}>
                    <span className={category.name[displayLang] ? "" : " categoryNameHighlight"}>{catName}</span>
                    {(hasSubCategories) && <Icon name="angle down" className={open ? "iconOpen" : "iconClosed"} />}
                </ButtonIcon>
                {(editingEnabled) && (
                    <EditCategoryModal
                        editConfig={editConfig}
                        category={category}
                        styledIcon={getCategoryStyledIcon(category)}
                        trigger={<ButtonIcon icon="pencil" className="editCate" />}
                    />
                )}
                {(showSubCategories) && (
                    <SubCategoryList
                        subCategoryList={category.subCategories}
                        editingEnabled={editingEnabled}
                        displayLang={displayLang}
                        parent={category}
                    />
                )}
            </li>
        );
    }

    return (
        <SortableItem
            index={index}
            value={value}
            collection={parent._id}
            reorderedListsMap={reorderedListsMap}
            disabled={showSubCategories}
        />
    );
};

const SubCategoryList = ({ subCategoryList, onSortEnd, editConfig = CATEGORY_EDIT_TYPE.SUB_CATEGORY, editingEnabled, reorderable = false, reorderedListsMap, expandedListsMapOps, displayLang, parent, subSubCatClass }) => 
{
    const categoryContext = useContext(CategoryContext);
    const subCategories = categoryContext.getCategoriesFromIds(subCategoryList);

    const { _id: parentId } = parent;
    const reorderedObjList = reorderable && reorderedListsMap.get(parentId)?.order;
    const subCategoryObjList = reorderedObjList ? reorderedObjList : subCategories;

    const SortableContainer = sortableContainer(({ children }) => <ul className="categoryList categorySubList">{children}</ul>);

    if (!reorderable) 
    {
        return (
            <ul className="categoryList categorySubList">
                {subCategories.map((category) => (
                    <CategoryListItem
                        key={category._id}
                        editConfig={editConfig}
                        category={category}
                        editingEnabled={editingEnabled}
                        displayLang={displayLang}
                        parent={parent}
                    />
                ))}
            </ul>
        );
    }
    return (
        <SortableContainer onSortEnd={(args) => onSortEnd({ ...args, parent })} helperClass="insiderSortableHelper draggable-item modal-overlayList" lockAxis="y" lockToContainerEdges useDragHandle>
            {subCategoryObjList.map((category, index) => (
                <CategoryListItem
                    editConfig={editConfig}
                    index={index}
                    value={category}
                    onSortEnd={onSortEnd}
                    key={category._id}
                    category={category}
                    editingEnabled={editingEnabled}
                    reorderable={true}
                    reorderedListsMap={reorderedListsMap}
                    expandedListsMapOps={expandedListsMapOps}
                    displayLang={displayLang}
                    parent={parent}
                    subCategories={true}
                    subSubCatClass={subSubCatClass}
                />
            ))}
        </SortableContainer>
    );
};
