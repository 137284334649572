import React from "react";
import { Pagination, Icon } from "semantic-ui-react";
import "./Pagination.css";
import { useTranslation } from "react-i18next";

export const PaginationBar = ({
    totalPages,
    activePage,
    setPage,
    startIndex,
    endIndex,
    total
}) => 
{
    const trans = useTranslation().t;
    return <div className="paginationBar">
        <Pagination
            activePage={activePage + 1}
            className="paginationDashboard"
            boundaryRange={1}
            // ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            siblingRange={1}
            totalPages={totalPages}
            prevItem={{ content: <Icon name="angle left" />, icon: true }}
            nextItem={{ content: <Icon name="angle right" />, icon: true }}
            onPageChange={(e, { activePage }) => setPage(parseInt(activePage) - 1)}
        />
        <div className="displayText">
            {(total > 0) ? (
                <span>{trans("Dashboard.Showing")} <strong>{startIndex + 1} - {Math.min(endIndex, total)}</strong> {trans("Dashboard.of")} {total}</span>
            ) : (
                <span>{trans("Dashboard.Showing")} <strong>0</strong> {trans("Dashboard.of")} 0</span>
            )}
        </div>
    </div>;
};
