import React from "react";
import ImagesSearchBar from "../../ImagesManagementHeader/ImagesSearchBar/ImagesSearchBar";
import ImagesSortSelector from "../../ImagesManagementHeader/ImagesSortSelector/ImagesSortSelector";
import "./ImagesRecycleBinHeader.css";
import { useImagesRecycleBinContext } from "../../contexts/ImagesRecycleBinContext";

function ImagesRecycleBinHeader() 
{
    const { imagesSearchAndFilters, handleChangeImagesSearchValue, handleChangeImageFilters } =
    useImagesRecycleBinContext();
    
    return (
        <div className="images-recycle-bin-header">
            <div className="images-recycle-bin-header-actions">
                <ImagesSearchBar value={imagesSearchAndFilters.searchValue} onChange={(e) => handleChangeImagesSearchValue(e.target.value)} />
                <ImagesSortSelector value= {imagesSearchAndFilters.filters.sort} onClick={(e, option) => handleChangeImageFilters({ sort: option.value })} />
            </div>
        </div>
    );
}

export default ImagesRecycleBinHeader;