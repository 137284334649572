import serverApi from "../../../_api/server.api";
import { filerUrl } from "../../../_utils/utils";

export function bytesToMB(bytes, precision = 2) 
{
    if (typeof bytes !== "number" || bytes < 0) 
    {
        throw new Error("Input must be a non-negative number.");
    }
    const megabytes = bytes / 1048576; // 1024 * 1024
    return Number(megabytes.toFixed(precision));
}

export function getFileExtensionFromMimeType(mimeType) 
{
    const mimeTypeToExtension = {
        "image/png": "png",
        "image/jpeg": "jpeg",
        "image/jpg": "jpg",
        "image/webp": "webp",
        "image/gif": "gif"
    };

    return mimeTypeToExtension[mimeType] || null;
}

export const asyncReadFilesAsDataURL = (files) => files.map((file, idx) => new Promise((resolve, reject) => 
{
    const reader = new FileReader();
    reader.onload = ((ev) => resolve(ev.target.result));
    reader.onerror = ((err) => reject(err));
    reader.readAsDataURL(file);
}));


export async function convertImgUrlToDataURI(imgUrl) 
{
    try 
    {
        const response = await fetch(imgUrl);
        const blob = await response.blob();

        return new Promise((resolve, reject) => 
        {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = () => reject("Failed to convert to Data URI");
            reader.readAsDataURL(blob);
        });
    }
    catch (error) 
    {
        console.error("Error fetching image:", error);
        throw error;
    }
}

async function getImageURL({ croppedImagesHistory, selectedImageInfo }) 
{
    if (croppedImagesHistory?.[selectedImageInfo?.id]?.currentImage) 
    {
        return croppedImagesHistory[selectedImageInfo?.id]?.currentImage;
    }
    else 
    {
        const imgUrl = await convertImgUrlToDataURI(filerUrl(selectedImageInfo?.filerId));
        return imgUrl;
    }
}

export async function userHasImageEditDeleteAccess({ imageId, queryClient })
{
    try 
    {
        let hasAccess = true;

        let userData = sessionStorage.getItem("user-data");

        if (!userData) 
        {
            throw new Error("User data not found");
        }

        userData = JSON.parse(userData);

        if (!userData?.properties?.length) 
        {
            hasAccess = false;
        }

        const linkedEntities = await queryClient.fetchQuery(["linkedEntities", imageId], () => serverApi.getLinkedEntities(imageId));

        if (linkedEntities?.results?.length)
        {        
            const propertyIds = linkedEntities.results.map((linkedEntity) => linkedEntity.additionalDetails?.propertyInfo?.propertyId).filter((item) => item);
        
            if (!propertyIds.length) 
            {
                throw new Error("No linked entities propertyIds found.");
            }
        
            hasAccess = propertyIds.every((propertyId) => userData.properties.includes(propertyId));
        }

        return hasAccess;
    }
    catch (err)
    {
        console.error(err);
        throw err;
    }
}
