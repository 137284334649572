/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-state */

import React from "react";
import { deepValue, deepUpdateValue, deepCopy } from "mapsted.utils/objects";
import BrandingContext from "./BrandingContext";
import serverAPI from "../_api/server.api";
import brandingApi from "../_api/branding.api";
import { Loader } from "../components/elements/loader";
import { MAP_TOOLS, DEFAULT_LANGUAGE_CODE, LANGUAGE_CODE, RESTORE_QUERY_KEY, RESTORE_QUERY_MAP_OVERLAYS } from "../_constants/constants";
import { DEFAULT_HIGHLIGHT_STYLE, MAP_THEMES } from "mapsted.maps/utils/map.constants";
import { CMSEntityAccess } from "mapsted.maps/mapFunctions/entityAccess";
import EntityMapController from "mapsted.maps";
import { getDefaultFloorIdFromCMSBuilding, toGpsLocation } from "mapsted.maps/utils/map.utils";
import { filerUrl, openingHoursGroupedListToPeriods } from "../_utils/utils";
import { getSelectedEntity } from "mapsted.maps/mapFunctions/features";
import
{
    addParentCategoriesForCategories,
    getDefaultEntityLabel,
    convertToUpdatedEntityLabelInfo,
    rotationIsValid
} from "../_utils/branding.utils";
import { arrayToHash } from "mapsted.utils/arrays";
import { BRANDING_STATUSES, ENTITY_STYLES } from "../_constants/branding";
import { GeoJSON } from "ol/format";
import { EntityType, StructureEntityType } from "mapsted.maps/utils/entityTypes";
import { withConfig, withQueryClient } from "../_utils/hoc";
import { DATA_QUERIES } from "../_utils/queries";
import socket from "../_api/socket";
import { downloadFile } from "../components/maintenance/utils";
import { PUBLISH_DATA_SCOPES } from "mapsted.utils/config";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
const SOCKET_EMITTERS = {
    CHANGE_PROPERTY_ID: "change-propertyId"
};

class BrandingProvider extends React.Component
{
    state = {
        properties: {},
        allProperties: {},
        propertyId: undefined,
        buildingId: undefined,
        floorId: undefined,
        selectedTool: undefined,
        editUnsavedChanges: false,
        selectedEntities: {},
        mapData: {},
        entityLayers: {},
        imageLayers: {},
        categoryIcons: [],
        loading: true,
        theme: MAP_THEMES.CLASSIC,
        propertyValidation: {},

        redirectMaintenance: false // used for editing entites from map overlay page
    };

    constructor(props)
    {
        super(props);
        this.mapController = new EntityMapController({
            features: { text: true, images: true },
            filerUrl: filerUrl(""),
            accessor: CMSEntityAccess,
            theme: MAP_THEMES.CLASSIC
        });
    }


    componentDidUpdate(prevProps, prevState)
    {
        if (prevState.propertyId !== this.state.propertyId)
        {
            this.setState({ validationInProgress: false });
        }
    }

    /**
     * TODO: On no properties go to dashboard?
     * Branding page initialization, to be called only once after mount.
     * First grabs all operational properties.
     * Then grabs property entities for the first property in the list.
     */
    componentDidMount()
    {
        this.setState({ loading: true }, async () =>
        {
            const dashboardData = serverAPI.dashboardData;

            // First get all operational properties
            const properties = await this.getProperties();

            let state = { loading: false };

            // filter and make new hash
            let operationalProperties = Object.values(properties);

            operationalProperties = operationalProperties.filter((property) => property.status === this.props.config.STATUSES.OPERATIONAL);

            operationalProperties = arrayToHash(operationalProperties, "_id");

            if (Object.keys(operationalProperties).length === 0)
            {
                // TODO: redirect to dashboard
            }
            else
            {
                state.properties = operationalProperties;
                state.allProperties = properties;

                // Filter only operational buildings
                Object.values(state.properties).forEach((property) =>
                {
                    const operationalBuildings = {};
                    // go through each building
                    Object.values(property.buildings).forEach((pb) =>
                    {
                        if (pb.status === this.props.config.STATUSES.OPERATIONAL)
                        {
                            operationalBuildings[pb._id] = pb;
                        }
                    });

                    property.allBuildings = { ...property.buildings };
                    property.buildings = operationalBuildings;
                });

                const {
                    propertyId: urlPropertyId,
                    buildingId: urlBuildingId,
                    floorId: urlFloorId
                } = this.getLocationIdsFromUrl(operationalProperties);

                if (urlPropertyId)
                {
                    state.propertyId = urlPropertyId;
                    state.buildingId = urlBuildingId;
                    state.floorId = urlFloorId;

                    serverAPI.handleUpdateDashboardData("propertyId", state.propertyId);
                    serverAPI.handleUpdateDashboardData("buildingId", state.buildingId);
                    serverAPI.handleUpdateDashboardData("floorId", state.floorId);
                }
                else if (dashboardData && dashboardData.propertyId && operationalProperties[dashboardData.propertyId])
                {
                    state.propertyId = dashboardData.propertyId;
                    const property = operationalProperties[state.propertyId];

                    if (dashboardData.buildingId && property.buildings[dashboardData.buildingId])
                    {
                        state.buildingId = dashboardData.buildingId;

                        const building = property.buildings[state.buildingId];

                        state.floorId = dashboardData.floorId || getDefaultFloorIdFromCMSBuilding(building, "floors");
                    }
                }

                if (!state.propertyId)
                {
                    state.propertyId = Object.keys(operationalProperties)[0];
                }

                // property validation
                const propertyValidationResult = await serverAPI.getPropertyValidation(state.propertyId);

                let propertyValidation = {};

                if (propertyValidationResult.isSuccess)
                {
                    propertyValidation = propertyValidationResult.data;
                }

                state.propertyValidation = propertyValidation;

                if (state.floorId)
                {
                    let stateChanges = await this.changeSelectedFloor(state.floorId, state.buildingId);
                    Object.assign(state, stateChanges);
                }
                else
                {
                    // get property entities.
                    let stateChanges = await this.changeSelectedProperty(state.propertyId, state.properties);
                    Object.assign(state, stateChanges);
                }

                if (state.propertyId)
                {
                    state.settingsConfig = await serverAPI.fetchPropertySettings(state.propertyId);
                }
            }

            let userData = serverAPI.userData.user;

            // listen to socket
            if (userData)
            {
                const companyUID = userData.userCompanyInfo.companyUID;
                const userId = userData.userInfo.id;

                socket.auth = { companyUID, userId };

                if (socket.connected)
                {
                    socket.emit("handshake", { companyUID, userId, });
                }
                else
                {
                    socket.connect();
                }

                socket.emit(SOCKET_EMITTERS.CHANGE_PROPERTY_ID, state.propertyId);

                socket.on("connect", () =>
                {
                    console.log("branding socket connected");
                });

                socket.on("new-property-validation", (data) =>
                {
                    this.handleNewPropertyValidationFromSocket(data);
                });

                socket.on("new-building-validation", (data) =>
                {
                    this.handleNewBuildingValidationFromSocket(data);
                });
                socket.on("new-property-validation-in-progress", (data) =>
                {

                    this.handleValidationProgress(data);

                });

                socket.on("new-building-validation-in-progress", (data) =>
                {

                    this.handleValidationProgress(data);

                });

                socket.on("new-property-validation-finished", (data) =>
                {
                    this.handleValidationProgress(data);

                });

                socket.on("new-building-validation-finished", (data) =>
                {
                    this.handleValidationProgress(data);


                });

                socket.on("disconnect", (reason) =>
                {
                    console.log(`branding socket disconnected -> ${reason}`);
                });

            }

            this.setState(state);
        });
    }
    /**
     * This is a quick fix for syncing the validation-info-popup
     * The work around is
     * -> logic of validation-info-popup should refracted by deriving the validation state data from branding context
     */
    syncValidationInfoPopup = async () =>
    {
        //this query call is only used in map editor so refetchQueries when map editor is active
        if (!this.props.location.pathname.includes("MapEditor")) return;

        let cmsPropertyId = this.state?.propertyId;
        let navPropertyId = this.state?.allProperties?.[cmsPropertyId]?.propertyId;
        //refresh's validation-info-popup-query
        if (navPropertyId)
        {
            await this.props.queryClient.refetchQueries({ queryKey: ["validation-info-complete", navPropertyId], type: "active" });
        }
    };

    getLocationIdsFromUrl = (operationalProperties) =>
    {
        let locationIds = {
            propertyId: "",
            buildingId: "",
            floorId: ""
        };

        if (this.props.location?.search)
        {
            const searchParams = new URLSearchParams(this.props.location.search);

            const propertyNavId = searchParams.has("property") ? +searchParams.get("property") : "";
            const buildingNavId = searchParams.has("building") ? +searchParams.get("building") : "";
            const floorNavId = searchParams.has("floor") ? +searchParams.get("floor") : "";

            if (propertyNavId)
            {
                const property = Object.values(operationalProperties).find((property) => property.propertyId === propertyNavId);

                if (property)
                {
                    locationIds.propertyId = property._id;

                    const building = Object.values(property.buildings).find((building) => building.buildingId === buildingNavId);

                    if (building)
                    {
                        locationIds.buildingId = building._id;

                        const floor = building.floors.find((floor) => floor.floorId === floorNavId);

                        if (floor)
                        {
                            locationIds.floorId = floor._id;
                        }
                        else
                        {
                            locationIds.floorId = getDefaultFloorIdFromCMSBuilding(building, "floors");
                        }
                    }
                }
            }

            // remove location ids from search param
            this.removeLocationIdsFromUrl();
        }

        return locationIds;
    };

    removeLocationIdsFromUrl = () =>
    {
        const searchParams = new URLSearchParams(this.props.location.search);

        searchParams.delete("property");
        searchParams.delete("building");
        searchParams.delete("floor");

        // workaround for an issue where token is getting retained in the search params
        searchParams.delete("t");

        this.props.history.replace({ search: searchParams.toString() });
    };

    handleNewPropertyValidationFromSocket = (data) =>
    {
        let propertyValidation = { ...this.state.propertyValidation };

        if (data && data?.property === this.state.propertyId)
        {
            this.syncValidationInfoPopup();
            propertyValidation.propertyValidation = data;
            this.setState({ propertyValidation });
        }
    };

    handleValidationProgress = (socketData) =>
    {
        clearTimeout(this.validationInProgressTimeout);
        const { inProgress, data: { propertyId } } = socketData;
        if (this.state.propertyId === propertyId)
        {

            this.setState({ validationInProgress: inProgress });

            // If the server doesn't respond within 3 minutes after starting the validation, the UI will assume the validation is not in progress.
            if (inProgress)
            {
                this.validationInProgressTimeout = setTimeout(() =>
                {
                    this.setState({ validationInProgress: false });
                }, 3 * 60 * 1000);
            }

        }
        else
        {
            this.setState({ validationInProgress: false });
        }
    };

    handleNewBuildingValidationFromSocket = (data) =>
    {
        let propertyValidation = { ...this.state.propertyValidation };

        if (data && data?.property === this.state.propertyId)
        {
            this.syncValidationInfoPopup();
            propertyValidation.buildingValidationMap[data.building] = data;
            this.setState({ propertyValidation });
        }
    };
    /**
     * Fetches all properties. Should only be called on dashboard init.
     */
    getProperties = async () => this.props.queryClient.fetchQuery(DATA_QUERIES.GET_PROPERTIES());

    /**
     * Updates properties from the database
     */
    updateProperties = async () =>
    {
        const properties = await this.getProperties();
        const operationalProperties = this.getOperationalProperties(properties);
        this.setState({ properties: operationalProperties });
    };

    updateAllEntityData = async () =>
    {
        // update property meta data
        const properties = await this.getProperties();
        const operationalProperties = this.getOperationalProperties(properties);
        this.setState({ properties: operationalProperties });

        // update map data based on location (property level or building level)
        await this.updateMapData(operationalProperties);
    };

    getOperationalProperties = (properties = {}) =>
    {
        // filter and make new hash
        let operationalProperties = Object.values(properties);
        operationalProperties = operationalProperties.filter((property) => property.status === this.props.config.STATUSES.OPERATIONAL);

        operationalProperties = arrayToHash(operationalProperties, "_id");

        // Filter only operational buildings
        Object.values(operationalProperties).forEach((property) =>
        {
            const operationalBuildings = {};
            // go through each building and check for status
            Object.values(property.buildings).forEach((pb) =>
            {
                if (pb.status === this.props.config.STATUSES.OPERATIONAL)
                {
                    pb.allFloors = deepCopy(pb.floors);
                    pb.floors = Object.values(pb.floors).filter((floor) => floor.status === this.props.config.STATUSES.OPERATIONAL);
                    operationalBuildings[pb._id] = pb;
                }
            });

            property.allBuildings = { ...property.buildings };
            property.buildings = operationalBuildings;
        });

        return operationalProperties;
    };

    refreshMapData = async () =>
    {
        const { propertyId, buildingId, floorId } = this.state;

        let mapData = undefined;

        if (floorId)
        {
            mapData = await this.getFloorMapData(buildingId, floorId);
        }
        else
        {
            mapData = await this.getPropertyMapData(propertyId);
        }

        this.setState({ mapData });

        return;
    };

    /**
     * Fetches property entities from serverAPI
     * @param {String} propertyId
     */
    getPropertyMapData = async (propertyId, properties) =>
    {
        const { data } = await brandingApi.getPropertyMapData(propertyId);

        if (!properties)
        {
            properties = this.state.properties;
        }

        // create entity label using building info
        const property = deepValue(properties, `${propertyId}`, undefined);

        this.mapController.polygonController.linkBuildingsToEntityLabel(data.entities, property);
        return data;
    };

    /**
     * @deprecated pretty sure we can delete this function but just marked it for now
    * Fetches property entities from serverAPI
    * @param {String} propertyId
    */
    getPropertyMapDataTopology = async (propertyId, properties) =>
    {
        const { data } = await brandingApi.getPropertyMapDataTopology(propertyId);

        return data;
    };

    /**
     * Fetches floor entities from serverAPI
     * @param {String} buildingId
     * @param {String} floorId
     */
    getFloorMapData = async (buildingId, floorId) =>
    {
        const { data } = await brandingApi.getFloorMapData(buildingId, floorId);
        return data;
    };

    getPropertyCategories = async (propertyId, roots = undefined) =>
    {
        const categoryList = await brandingApi.getCategories(propertyId);

        const categories = arrayToHash(categoryList, "_id");
        roots && addParentCategoriesForCategories(categories, roots || []);

        return categories;
    };

    callbackWithLoadingWrapper = (asyncCallback, isPropertyBeingUpdated) =>
    {
        if (!asyncCallback)
        {
            console.error("Missing callback method");
            return;
        }

        return (...props) =>
        {
            if (isPropertyBeingUpdated)
            {
                this.handleSelectedPropertyBrandingStatusUpdate(BRANDING_STATUSES.DRAFT);
            }

            this.setState({ loading: true }, async () =>
            {
                try
                {
                    let state = await asyncCallback(...props);
                    state = Object.assign({ loading: false }, state);
                    this.setState(state);
                }
                catch (error)
                {
                    this.setState({ loading: false });
                }

            });
        };
    };

    handleSelectedPropertyBrandingStatusUpdate = (updatedBrandingStatus) =>
    {
        const { properties, propertyId } = this.state;
        let updatedProperties = { ...properties };
        let selectedProperty = { ...updatedProperties[propertyId] };

        if (selectedProperty && selectedProperty.brandingStatus !== updatedBrandingStatus)
        {
            selectedProperty.brandingStatus = updatedBrandingStatus;
            brandingApi.updateBrandingStatus(selectedProperty._id, updatedBrandingStatus);

            updatedProperties[propertyId] = selectedProperty;

            this.setState({ properties: updatedProperties });
        }
    };
    async refreshPropertyBrandingStatus()
    {
        const { properties, propertyId } = this.state;
        let updatedProperties = { ...properties };
        let selectedProperty = { ...updatedProperties[propertyId] };

        const brandingStatus = await brandingApi.getBrandingStatus(propertyId);
        if (selectedProperty && selectedProperty.brandingStatus !== brandingStatus)
        {
            selectedProperty.brandingStatus = brandingStatus;
            updatedProperties[propertyId] = selectedProperty;
            this.setState({ properties: updatedProperties });
        }
    }

    /**
    * Changes and fetches the new selected property, then grabs map data.
    * @param {string} propertyId - The new selected propertyId
    */
    changeSelectedProperty = async (propertyId, properties) =>
    {
        serverAPI.handleUpdateDashboardData("propertyId", propertyId);
        serverAPI.handleUpdateDashboardData("buildingId", undefined);
        serverAPI.handleUpdateDashboardData("floorId", undefined);

        const mapData = await this.getPropertyMapData(propertyId, properties);
        const categories = await this.getPropertyCategories(propertyId);
        const settingsConfig = await serverAPI.fetchPropertySettings(propertyId);
        const propertyValidationResult = await serverAPI.getPropertyValidation(propertyId);

        let propertyValidation = {};

        if (propertyValidationResult.isSuccess)
        {
            propertyValidation = propertyValidationResult.data;
        }

        // create topojson layers

        let theme = MAP_THEMES.CLASSIC;

        if (mapData?.themeDetails.dark)
        {
            theme = MAP_THEMES.DARK;
            this.mapController.setTheme(MAP_THEMES.DARK);
        }
        else
        {
            theme = MAP_THEMES.CLASSIC;
            this.mapController.setTheme(MAP_THEMES.CLASSIC);
        }

        const {
            entityLayers, boundaryPolygon, imageLayers,
            boundaryPolygonAreaInSqMt,
        } = this.mapController.initialize(mapData);

        socket.emit(SOCKET_EMITTERS.CHANGE_PROPERTY_ID, propertyId);

        return {
            propertyId: propertyId,
            buildingId: undefined,
            floorId: undefined,
            selectedTool: undefined,
            selectedEntities: {},
            mapData,
            entityLayers,
            boundaryPolygon,
            boundaryPolygonAreaInSqMt,
            imageLayers,
            categories,
            theme,
            propertyValidation,
            settingsConfig
        };
    };

    /**
     * Changes the selected building, sets gf to selected floor and grabs map data.
     * @param {string} buildingId - The new selected buildingId. buildingId=undefined sets current property to be selected for viewing and updates.
     */
    changeSelectedBuilding = async (buildingId) =>
    {
        const { propertyId, properties } = this.state;

        if (buildingId)
        {
            let state = { buildingId };

            state.floorId = getDefaultFloorIdFromCMSBuilding(properties[propertyId].buildings[buildingId], "floors");

            serverAPI.handleUpdateDashboardData("buildingId", buildingId);
            serverAPI.handleUpdateDashboardData("floorId", state.floorId);

            state.mapData = await this.getFloorMapData(buildingId, state.floorId);

            const {
                entityLayers, boundaryPolygon, imageLayers,
                boundaryPolygonAreaInSqMt,
            } = this.mapController.initialize(state.mapData);

            state.entityLayers = entityLayers;
            state.boundaryPolygon = boundaryPolygon;
            state.boundaryPolygonAreaInSqMt = boundaryPolygonAreaInSqMt;
            state.imageLayers = imageLayers;
            state.selectedEntities = {};
            state.selectedTool = undefined;

            return state;
        }
        else
        {
            const state = await this.changeSelectedProperty(propertyId);
            return state;
        }
    };

    /**
     * Changes the selected floor and grabs mapdata.
     */
    changeSelectedFloor = async (floorId, buildingId = undefined, changeBuilding = false) =>
    {
        if (buildingId === undefined)
        {
            buildingId = this.state.buildingId;
        }



        if (!!floorId && floorId !== this.state.floorId)
        {
            let state = { floorId };

            serverAPI.handleUpdateDashboardData("floorId", state.floorId);

            state.mapData = await this.getFloorMapData(buildingId, state.floorId);


            if (state.mapData?.themeDetails.dark)
            {
                state.theme = MAP_THEMES.DARK;
                this.mapController.setTheme(MAP_THEMES.DARK);
            }
            else
            {
                state.theme = MAP_THEMES.CLASSIC;
                this.mapController.setTheme(MAP_THEMES.CLASSIC);
            }

            const {
                entityLayers, boundaryPolygon, imageLayers,
                boundaryPolygonAreaInSqMt,
            } = this.mapController.initialize(state.mapData);

            state.entityLayers = entityLayers;
            state.boundaryPolygon = boundaryPolygon;
            state.boundaryPolygonAreaInSqMt = boundaryPolygonAreaInSqMt;
            state.imageLayers = imageLayers;
            state.selectedEntities = {};
            if (changeBuilding) state.buildingId = buildingId;

            return state;
        }
    };

    /**
    * Changes the selected floor and grabs mapdata.
    */
    changeSelectedLevel = async (propertyId, buildingId, floorId,) =>
    {
        let state = {};

        if (floorId === undefined)
        {
            state = await this.changeSelectedProperty(propertyId, this.state.properties);
        }
        else if (!!floorId && floorId !== this.state.floorId)
        {
            state = { propertyId, buildingId, floorId };
            serverAPI.handleUpdateDashboardData("floorId", state.floorId);
            serverAPI.handleUpdateDashboardData("buildingId", state.buildingId);
            serverAPI.handleUpdateDashboardData("propertyId", state.propertyId);


            state.mapData = await this.getFloorMapData(buildingId, state.floorId);
            state.categories = await this.getPropertyCategories(propertyId);


            const {
                entityLayers, boundaryPolygon, imageLayers,
                boundaryPolygonAreaInSqMt
            } = this.mapController.initialize(state.mapData);


            state.entityLayers = entityLayers;
            state.boundaryPolygon = boundaryPolygon;
            state.boundaryPolygonAreaInSqMt = boundaryPolygonAreaInSqMt;
            state.imageLayers = imageLayers;
            state.selectedEntities = {};

            state;
        }

        return state;
    };

    /**
     * Refetch current property/floor map data and update state
     */
    updateMapData = async (properties = []) =>
    {
        const { floorId, buildingId } = this.state;

        if (buildingId && floorId)
        {
            await this.updateFloorData();
        }
        else
        {
            await this.updatePropertyData(properties);
        }
    };

    updateFloorData = async () =>
    {
        const { floorId, buildingId } = this.state;
        if (!floorId & !buildingId) return;

        let state = {};

        state.mapData = await this.getFloorMapData(buildingId, floorId);

        const newTheme = state.mapData.themeDetails?.dark ? MAP_THEMES.DARK : MAP_THEMES.CLASSIC;

        this.mapController.setTheme(newTheme);

        const {
            entityLayers, boundaryPolygon, imageLayers,
            boundaryPolygonAreaInSqMt,
        } = this.mapController.initialize(state.mapData);

        state.entityLayers = entityLayers;
        state.boundaryPolygon = boundaryPolygon;
        state.boundaryPolygonAreaInSqMt = boundaryPolygonAreaInSqMt;
        state.imageLayers = imageLayers;
        state.theme = newTheme;


        this.setState({ ...state });
    };

    updatePropertyData = async (properties = []) =>
    {
        const { propertyId } = this.state;

        let state = {};

        state.mapData = await this.getPropertyMapData(propertyId, properties);

        const newTheme = state.mapData.themeDetails?.dark ? MAP_THEMES.DARK : MAP_THEMES.CLASSIC;

        this.mapController.setTheme(newTheme);

        const {
            entityLayers, boundaryPolygon, imageLayers,
            boundaryPolygonAreaInSqMt
        } = this.mapController.initialize(state.mapData);

        state.entityLayers = entityLayers;
        state.boundaryPolygon = boundaryPolygon;
        state.boundaryPolygonAreaInSqMt = boundaryPolygonAreaInSqMt;
        state.imageLayers = imageLayers;
        state.theme = newTheme;

        this.setState({ ...state });
    };

    /**
     * Changes the selected tool. TOGGLES the tool to undefined if already set
     * @param {String} name
     * @param highlightSelectedEntities
     */
    changeSelectedTool = (name, highlightSelectedEntities = true) =>
    {
        const { selectedTool } = this.state;

        highlightSelectedEntities && this.handleHighlightSelectedEntities(this.state.selectedEntities, false);

        if (selectedTool !== name)
        {
            this.setState({ selectedTool: name, selectedEntities: {}, editUnsavedChanges: false });
        }
        else
        {
            this.setState({ selectedTool: undefined, selectedEntities: {}, editUnsavedChanges: false });
        }
    };

    initiateSingleEntityEdit = (selectedEntityId, selectedEntityFeature, highlightSelectedEntities = true) =>
    {
        this.setState({ selectedTool: MAP_TOOLS.EDIT, editUnsavedChanges: false });
        this.changeSelectedEntity(selectedEntityId, selectedEntityFeature, highlightSelectedEntities);
    };

    /** Just selects without toggling */
    selectSelectedTool = (name) =>
    {
        this.setState({ selectedTool: name, selectedEntities: {}, editUnsavedChanges: false });
    };

    /**
     * When map is switched to from list view, map does not appear. This updates the state by simulating a tool selection and tells the map to render.
     */
    refreshMap = () =>
    {
        this.setState({ selectedTool: undefined, selectedEntities: {}, editUnsavedChanges: false });
    };

    /**
     * When Edit popup of vacant store is closed without saving, we want to deselect the vacant store, close overlay and keep edit tool active.
     */
    deselectVacantStore = () =>
    {
        this.handleHighlightSelectedEntities(this.state.selectedEntities, false);
        this.setState({ editUnsavedChanges: false, selectedEntities: {} });
    };

    /**
     * Changes the selected entity ID and entity.
     *
     * This is for singular entity selection.
     *
     * @param {String} selectedEntityId
     * @param selectedEntityFeature
     * @param highlightSelectedEntities
     */
    changeSelectedEntity = (selectedEntityId, selectedEntityFeature, highlightSelectedEntities = true) =>
    {
        highlightSelectedEntities && this.handleHighlightSelectedEntities(this.state.selectedEntities, false);

        const prevSelectedEntityId = getSelectedEntity(this.state.selectedEntities);
        const { mapData } = this.state;

        let state = {
            selectedEntities: {}
        };

        if (selectedEntityId)
        {
            if (prevSelectedEntityId !== selectedEntityId)
            {
                let selectedEntities = {};
                if (highlightSelectedEntities)
                {
                    selectedEntities = {
                        [selectedEntityId]: {
                            feature: selectedEntityFeature,
                            textFeature: deepValue(mapData, `entities.${selectedEntityId}.textFeature`, undefined)
                        }
                    };

                    this.handleHighlightSelectedEntities(selectedEntities, true);
                }
                else
                {
                    selectedEntities = {
                        [selectedEntityId]: {}
                    };
                }

                state.selectedEntities = selectedEntities;
            }
        }

        this.setState(state);
    };

    /**
     * Adds selected entity id to selected list if its not in the list already,
     * else removes from list.
     *
     * SelectedEntites =
     * {
     *  [selectedEntityId]:
     *  {
     *      feature,
     *      textFeature
     *  }
     * }
     *
     * @param {String} selectedEntityId
     * @param {Object} selectedEntityFeature
     */
    changeSelectedEntityMultiple = (selectedEntityId, selectedEntityFeature, highlightStyle = undefined) =>
    {
        this.handleHighlightSelectedEntities(this.state.selectedEntities, false, highlightStyle);

        const { mapData, selectedTool } = this.state;

        let selectedEntities = { ...this.state.selectedEntities } || {};

        let selectedEntityIds = Object.keys(selectedEntities);

        // if it exists already, remove from selected entities.
        if (selectedEntityIds.includes(selectedEntityId))
        {
            delete selectedEntities[selectedEntityId];

            if (selectedTool === MAP_TOOLS.MERGE && Object.keys(selectedEntities).length === 2)
            {
                // if the selected entities are not connected do not remove entity.
                // might want to inform the user via alert.
                let connected = this.mapController.polygonController.areEntitiesConnected(Object.values(selectedEntities));

                if (!connected)
                {
                    this.handleHighlightSelectedEntities(this.state.selectedEntities, true, highlightStyle);
                    return this.state.selectedEntities;
                }
            }
        }
        else
        {
            console.log("not previously present", selectedEntityId, selectedEntities);
            selectedEntities[selectedEntityId] = {
                feature: selectedEntityFeature,
                textFeature: deepValue(mapData, `entities.${selectedEntityId}.textFeature`, undefined)
            };
        }

        // console.log("selectedEntities", selectedEntities)

        this.handleHighlightSelectedEntities(selectedEntities, true, highlightStyle);

        this.setState({ selectedEntities });

        return selectedEntities;
    };

    updateSelectedEntities = (selectedEntities, highlightStyle = undefined) =>
    {
        this.handleHighlightSelectedEntities(this.state.selectedEntities, false, highlightStyle);

        let updatedSelectedEntities = {};

        selectedEntities.forEach((selectedEntityId) =>
        {
            if (this.state?.mapData?.entities?.[selectedEntityId])
            {
                updatedSelectedEntities[selectedEntityId] = {
                    feature: deepValue(this.state.mapData, `entities.${selectedEntityId}.feature`, undefined),
                    textFeature: deepValue(this.state.mapData, `entities.${selectedEntityId}.textFeature`, undefined)
                };
            }
        });

        this.handleHighlightSelectedEntities(updatedSelectedEntities, true, highlightStyle);

        this.setState({ selectedEntities: updatedSelectedEntities });
    };

    handleHighlightSelectedEntities = (selectedEntities, isHighlight, highlightStyle = undefined) =>
    {
        const { mapData } = this.state;

        const { entities, style } = mapData;

        const entityIds = Object.keys(selectedEntities);

        const appliedStyle = highlightStyle ? highlightStyle : DEFAULT_HIGHLIGHT_STYLE;

        entityIds.forEach((entityId) =>
        {
            const entity = entities[entityId];

            const entityAccess = new CMSEntityAccess(entity);

            const styleObject = (isHighlight) ? appliedStyle : entityAccess.getStyleObject(style);

            this.mapController.polygonController.changeEntityStyle(selectedEntities[entityId].feature, styleObject);
        });
    };

    /**
     * Merges the set of selected entities if they are all intersecting.
     * Removes old entities from DB and adds the new merged entity.
     * Redraws map and sets new entity as selected with edit active.
     */
    mergeSelectedEntities = async () =>
    {
        const { selectedEntities, mapData, buildingId, floorId } = this.state;

        const selectedEntityIds = Object.keys(selectedEntities);

        let state = {};

        // If there is one or less entities selected, do nothing.
        if (selectedEntityIds.length <= 1)
        {
            return state;
        }

        // get merged entity
        const mergedPolygon = this.mapController.polygonController.getMergedPolygon(selectedEntities);

        // If merge was succesfull remove old polygons and add the new one.
        if (mergedPolygon)
        {
            const entityTemplate = mapData.entities[selectedEntityIds[0]];

            // Create new entity
            let mergedEntity = this.mapController.polygonController.convertGeoPolygonToEntity(mergedPolygon, entityTemplate);

            // Save to DB
            let createEntityResult = await brandingApi.createBuildingEntity(buildingId, mergedEntity);

            if (createEntityResult.success)
            {
                // update new entity values
                Object.assign(mergedEntity, createEntityResult.data);

                // Remove entites from db and local list
                let deleteEntityPromise = [];
                selectedEntityIds.forEach((entityId) =>
                {
                    // delete entities from DB
                    deleteEntityPromise.push(new Promise(async (resolve) =>
                    {
                        const success = await brandingApi.deleteBuildingEntity(buildingId, entityId);
                        resolve(success);
                    }));
                });

                // Remove old entities from DB
                await Promise.all(deleteEntityPromise);

                // Get new map data from DB
                let newMapData = await this.getFloorMapData(buildingId, floorId);

                // Plot new data with new merged entity
                const {
                    entityLayers, boundaryPolygon, imageLayers,
                    boundaryPolygonAreaInSqMt,

                } = this.mapController.initialize(newMapData);

                let entities = newMapData.entities;


                // Set new entity as selected with edit open.
                state.selectedEntities = {
                    [mergedEntity._id]:
                    {
                        feature: entities[mergedEntity._id].feature,
                        textFeature: entities[mergedEntity._id].textFeature,
                    }
                };

                // Highlights new entity
                this.handleHighlightSelectedEntities(state.selectedEntities, true);

                // Set seleted tool to edit
                state.selectedTool = MAP_TOOLS.EDIT;

                Object.assign(state, { entityLayers, mapData: newMapData, imageLayers, boundaryPolygon, boundaryPolygonAreaInSqMt });
            }
        }

        return state;
    };

    splitSelectedEntity = async (splitLineFeature) =>
    {
        const { selectedEntities, buildingId, floorId } = this.state;

        const { selectedEntityId, selectedEntityFeature } = getSelectedEntity(selectedEntities);

        const splitPolygons = this.mapController.polygonController.getSplitPolygons(selectedEntityFeature, splitLineFeature);

        let newMapData = { ...this.state.mapData };

        let splitEntities = [];
        let createEntitiesPromise = [];

        let state = {
            selectedEntities: {}
        };

        this.handleHighlightSelectedEntities(selectedEntities, false);

        state = {
            selectedEntities: {}
        };
        // Go through each split polygon creating new entities
        if (Array.isArray(splitPolygons) && splitPolygons.length === 2)
        {
            const entityTemplate = newMapData.entities[selectedEntityId];

            splitPolygons.forEach((splitPolygon) =>
            {
                let entity = this.mapController.polygonController.convertGeoPolygonToEntity(splitPolygon, entityTemplate);

                splitEntities.push(entity);

                createEntitiesPromise.push(new Promise(async (resolve) =>
                {
                    const result = await brandingApi.createBuildingEntity(buildingId, entity);
                    resolve(result);
                }));
            });

            // Create new entities
            await Promise.all(createEntitiesPromise);

            // Delete old entity from db.
            await brandingApi.deleteBuildingEntity(buildingId, selectedEntityId);

            // Get new map data from db
            newMapData = await this.getFloorMapData(buildingId, floorId);
            const {
                entityLayers, boundaryPolygon, imageLayers,
                boundaryPolygonAreaInSqMt
            } = this.mapController.initialize(newMapData);

            Object.assign(state, {
                mapData: newMapData,
                entityLayers: entityLayers,
                imageLayers,
                boundaryPolygon,
                boundaryPolygonAreaInSqMt
            });
        }
        else
        {
            Object.assign(state, {
                selectedTool: MAP_TOOLS.SPLIT
            });
        }

        return state;
    };

    updateImageReference = async (imageInfo, selectedId, model) =>
    {
        const { filerId, imageType, lang, prevFilerId } = imageInfo;

        try
        {
            if (Array.isArray(filerId))
            {
                const promises = filerId.map((item) => serverAPI.updateImageReference(encodeURIComponent(item), false, {
                    reference: selectedId,
                    model: model,
                    language: lang,
                    usedAs: imageType,
                }));

                const response = await Promise.allSettled(promises);

                let errIds = response.map((res, idx) => res.status === "rejected" ? filerId[idx] : null).filter((filerIds) => filerIds);

                if (errIds.length > 0)
                {
                    throw new Error(errIds.join(","));
                }

                return response;
            }

            if (prevFilerId)
            {
                await serverAPI.updateImageReference(encodeURIComponent(prevFilerId), true, {
                    reference: selectedId,
                    model: model,
                    language: lang,
                    usedAs: imageType,
                });
            }

            if (filerId)
            {
                await serverAPI.updateImageReference(encodeURIComponent(filerId), false, {
                    reference: selectedId,
                    model: model,
                    language: lang,
                    usedAs: imageType,
                });
            }
        }
        catch (err)
        {
            throw new Error(`Error updating image reference : ${err.message}`);
        }
    };

    /**
     * Calls an api to save the new entity label to the selected entity.
     * On success provider state will be updated and the selected entity style will be updated to the new longName.
     * @param {Object} entityLabel - The new entity label object.
     * @param {Function} callBack - Called after state is set, optional.
     */
    updateEntityLabel = async (entityLabel, callBack, imageInfo) =>
    {
        let state = {};
        try
        {
            this.setState({ loading: true });
            const { propertyId, buildingId, floorId, selectedEntities, mapData, properties } = this.state;
            const { selectedEntityId, selectedEntityTextFeature, selectedEntityFeature } = getSelectedEntity(selectedEntities);
            const entity = deepValue(mapData, `entities.${selectedEntityId}`, {});
            const orginalEntityLabel = { ...entity.entityLabel };

            let building = deepValue(properties, `${propertyId}.buildings.${buildingId}`);
            const entityLabelId = deepValue(entity, "entityLabel._id", undefined);

            let imageOnMapChange = false;

            if (orginalEntityLabel.isImageOnMap !== entityLabel.isImageOnMap)
            {
                imageOnMapChange = true;
            }

            // if this is a property level entity that is not linked to building
            if (!buildingId)
            {
                entityLabel.isHoursLinkedToRef = false;
            }

            let updatedEntityLabel = convertToUpdatedEntityLabelInfo(entityLabel);

            if (imageInfo)
            {
                await this.updateImageReference(imageInfo, entityLabelId, "EntityLabel");
            }

            let { success, data, relatedStores = [] } = await brandingApi.updateEntityLabel(selectedEntityId, propertyId, buildingId, floorId, updatedEntityLabel, true);

            if (!success)
            {
                throw new Error("Error updating entity label");
            }
            // change to update entity label properly
            if (success)
            {
                let stateUpdate = await this.entityLabelSuccessHelper({ data, selectedEntityId, selectedEntityTextFeature, selectedEntityFeature, imageOnMapChange, building });

                Object.assign(state, stateUpdate);

                // clear cache
                this.props.queryClient.invalidateQueries("GET_PROPERTIES");
            }

            callBack && callBack(success, data, relatedStores);

            // used in loader callback
            if (relatedStores.length)
            {
                state.relatedStoresExists = true;
            }

            return state;

        }
        catch (err)
        {
            console.log(err?.message);
            callBack && callBack(false);
            return state;
        }
    };

    /**
     * Updates the entitiy's connected building info in DB.
     * @param {} entityLabel
     * @param {*} callBack
     */
    updatePropertyEntityLabel = async (entityLabel, callBack) =>
    {
        // todo update building opening hours,
        const { propertyId, selectedEntities, mapData } = this.state;

        const { selectedEntityId, selectedEntityTextFeature, selectedEntityFeature } = getSelectedEntity(selectedEntities);
        const entity = deepValue(mapData, `entities.${selectedEntityId}`, {});
        const orginalEntityLabel = entity.entityLabel;

        let imageOnMapChange = false;

        if (orginalEntityLabel.isImageOnMap !== entityLabel.isImageOnMap)
        {
            imageOnMapChange = true;
        }

        if (entity.building)
        {
            let properties = { ...this.state.properties };
            let property = { ...properties[propertyId] };
            let building = { ...property.allBuildings[entity.building] };

            Object.assign(building, entityLabel);

            let state = {};

            let partialOpeningHoursUpdate = building.partialOpeningHours;

            //---
            const basicInfoResult = await serverAPI.updateBuildingWithoutImages(building);

            // hours set up
            const periods = openingHoursGroupedListToPeriods(partialOpeningHoursUpdate.partialOpeningHours);

            partialOpeningHoursUpdate.periods = periods;

            delete partialOpeningHoursUpdate.partialOpeningHours;

            const hoursResult = await serverAPI.updatePartialBuildingHours({ _id: entity.building, partialOpeningHours: partialOpeningHoursUpdate });

            // if success update data
            if (basicInfoResult.success && hoursResult.success)
            {
                // update building data from update.
                building = basicInfoResult.data;

                // Update hours id if given.
                if (hoursResult.data._id)
                {
                    partialOpeningHoursUpdate._id = hoursResult.data._id;
                }

                building.partialOpeningHours = partialOpeningHoursUpdate;

                property.allBuildings[entity.building] = building;
                properties[propertyId] = property;

                state.properties = properties;

                // updates entity label and name on map
                let stateUpdate = await this.entityLabelSuccessHelper({ data: building, selectedEntityId, selectedEntityTextFeature, selectedEntityFeature, imageOnMapChange });
                Object.assign(state, stateUpdate);

                // clear cache
                this.props.queryClient.invalidateQueries("GET_PROPERTIES");
            }
            //---

            callBack && callBack();
            return state;
        }
    };

    /**
     * Update mapdata and entity name on map.
     * */
    entityLabelSuccessHelper = async ({ data, selectedEntityId, building }) =>
    {

        if (!!data.isHoursLinkedToRef && !!building)
        {
            if (!data.partialOpeningHours)
            {
                data.partialOpeningHours = {};
            }

            data.partialOpeningHours.periods = deepValue(building, "partialOpeningHours.periods", []);
        }

        // update
        let entity = { ...this.state.mapData.entities[selectedEntityId] };
        entity.entityLabel = Object.assign(entity?.entityLabel || {}, data);

        let state = this.updateEntityOnMap(entity, false);
        return state;
    };

    entityStyleSuccessHelper = async ({ entity, selectedEntityTextFeature, valuesChanged }) =>
    {
        let state = {};

        const entityAccess = new CMSEntityAccess(entity);
        // gets boolean value if image is on map or not
        let isImageOnMap = entityAccess.getIsImageOnMap();

        // returns undefined if not displayed or a string value if entity name is displayed
        let name = entityAccess.getName({ useStyle: true });

        let entityLabel = entity.entityLabel;

        if (!entityLabel)
        {
            return;
        }

        state.imageLayers = { ...this.state.imageLayers };

        // Update displayed text style
        const textStyle = this.getEntityTextStyle(entity._id);
        const textRotation = deepValue(entity, `textRotation.${DEFAULT_LANGUAGE_CODE}`, 0);


        // IF icon style changed
        if (valuesChanged.includes(ENTITY_STYLES.USE_ICON))
        {
            // If image is displayed on map show image
            if (isImageOnMap && entityAccess.getImage())
            {
                state.imageLayers = await this.updateImageLayers(state.imageLayers, entity, selectedEntityTextFeature);
            }
            else // delete image from layers
            {
                delete state.imageLayers[entity._id];
            }
        }

        // IF text style changed
        if (valuesChanged.includes(ENTITY_STYLES.USE_TEXT))
        {
            let updateText = "";

            // if text is displayed and name exists change updated text to name
            if (name)
            {
                updateText = name;
            }

            // update entity text
            this.mapController.textController.changeEntityText(selectedEntityTextFeature, updateText, textRotation, textStyle);
        }

        return state;
    };

    updateImageLayers = async (imageLayers, entity, selectedEntityTextFeature) =>
    {
        let newImageLayers = { ...imageLayers };
        let entityLabel = entity.entityLabel;
        let imageLayer = imageLayers[entity._id];
        let isImageOnMap = new CMSEntityAccess(entity).getIsImageOnMap();

        // If image is not on map do not change image layers
        if (!isImageOnMap)
        {
            return newImageLayers;
        }

        let imgId;
        if (this.state.theme === MAP_THEMES.DARK && entityLabel?.darkIcon?.[DEFAULT_LANGUAGE_CODE])
        {
            imgId = entityLabel?.darkIcon?.[DEFAULT_LANGUAGE_CODE];
        }
        else if (this.state.theme === MAP_THEMES.CLASSIC && entityLabel?.lightIcon?.[DEFAULT_LANGUAGE_CODE])
        {
            imgId = entityLabel?.lightIcon?.[DEFAULT_LANGUAGE_CODE];
        }
        else
        {
            imgId = entityLabel?.iconImage?.[DEFAULT_LANGUAGE_CODE] || entityLabel?.lightIcon?.[DEFAULT_LANGUAGE_CODE] || entityLabel?.darkIcon?.[DEFAULT_LANGUAGE_CODE];
        }

        if (imgId)
        {

            if (imageLayer)
            {
                let source = this.mapController.imageController.createImageSource({
                    imgId,
                    entityShape: entity.shape,
                    rotation: entity.imageRotation?.[DEFAULT_LANGUAGE_CODE],
                    extent: entity.imageExtent?.[DEFAULT_LANGUAGE_CODE],
                    refType: entity.refType
                });

                imageLayer.setSource(source);
                newImageLayers[entity._id] = imageLayer;
            }
            else
            {
                newImageLayers[entity._id] = this.mapController.imageController.createImageLayer({
                    imgId,
                    entityShape: entity.shape,
                    rotation: entity.imageRotation?.[DEFAULT_LANGUAGE_CODE],
                    extent: entity.imageExtent?.[DEFAULT_LANGUAGE_CODE],
                    refType: entity.refType,
                    feature: entity.feature
                });
            }
        }
        else if (imageLayer)
        {
            return this.deleteImageLayer(imageLayers, entity, selectedEntityTextFeature);
        }

        // if (!!selectedEntityTextFeature)
        // {
        //     const textStyle = deepValue(this.state.mapData, "style.default.text", DEFAULT_ENTITY_TEXT_STYLE);
        //     changeEntityText(selectedEntityTextFeature, "", entity.textRotation, textStyle, false);
        // }

        return newImageLayers;
    };

    deleteImageLayer = (imageLayers, entity, selectedEntityTextFeature) =>
    {
        let newImageLayers = { ...imageLayers };
        // remove image layer from image layers
        delete newImageLayers[entity._id];
        // change label text
        const textStyle = this.getEntityTextStyle(entity._id);

        const name = new CMSEntityAccess(entity).getName({ useStyle: true });
        // update entity text
        if (selectedEntityTextFeature)
        {
            this.mapController.textController.changeEntityText(selectedEntityTextFeature, name, entity.textRotation?.[DEFAULT_LANGUAGE_CODE], textStyle);
        }

        return newImageLayers;
    };

    entitySuccessHelper = ({ data, selectedEntityId, selectedEntityTextFeature }) =>
    {
        let state = {};

        const name = null;
        let mapDataChange = { ...this.state.mapData };
        let textFeature = mapDataChange.entities[selectedEntityId].textFeature;
        data.textFeature = textFeature;

        mapDataChange.entities[selectedEntityId] = data;

        state.mapData = mapDataChange;

        const textStyle = this.getEntityTextStyle(selectedEntityId);
        const textRotation = 0;

        this.mapController.textController.changeEntityText(selectedEntityTextFeature, name, textRotation, textStyle);

        return state;
    };

    updateTextRotation = async (entityId, textRotation) =>
    {
        const { selectedEntityTextFeature } = getSelectedEntity(this.state.selectedEntities);
        let mapData = { ...this.state.mapData };
        const textStyle = this.getEntityTextStyle(entityId);
        const entity = deepValue(mapData, `entities.${entityId}`, undefined);
        const name = new CMSEntityAccess(entity).getName();

        this.mapController.textController.changeEntityText(selectedEntityTextFeature, name, textRotation, textStyle);
    };

    getEntityTextStyle = (entityId) =>
    {
        const { entities, style } = this.state.mapData;
        let textStyle = deepValue(style, "default.text.default", undefined);
        const entity = entities[entityId];
        if (entity)
        {
            const entityType = entity.entityType;
            const subEntityType = entity.subEntityType;
            textStyle = deepValue(style, `${entityType}.${subEntityType}.text.default`, undefined) || textStyle;
        }
        return textStyle;
    };

    saveTextRotation = async (entityId, textRotation) =>
    {
        const { success, data: updatedEntity } = await brandingApi.updateEntityStyle(entityId, { textRotation: { [LANGUAGE_CODE]: textRotation } });

        if (success)
        {
            if (updatedEntity.building)
            {
                const property = this.state?.properties?.[this?.state?.propertyId];
                this.mapController.polygonController.linkBuildingsToEntityLabel([updatedEntity], property);
            }

            this.updateEntityOnMap(updatedEntity);

            const prevSelectedEntities = { ...this.state.selectedEntities };
            // remove all highlights
            this.handleHighlightSelectedEntities(prevSelectedEntities, false);

            return { selectedEntities: {} };
        }
        else
        {
            return {};
        }
    };

    revertTextRotation = (shouldRestSelectedEntity) =>
    {
        const { selectedEntities, mapData } = this.state;
        const { selectedEntityId, selectedEntityTextFeature } = getSelectedEntity(selectedEntities);

        if (selectedEntityId)
        {
            const textStyle = this.getEntityTextStyle(selectedEntityId);
            const entity = deepValue(mapData, `entities.${selectedEntityId}`, undefined);
            const name = new CMSEntityAccess(entity).getName();
            const prevTextRotation = mapData.entities[selectedEntityId].textRotation?.[DEFAULT_LANGUAGE_CODE];

            this.mapController.textController.changeEntityText(selectedEntityTextFeature, name, prevTextRotation, textStyle);

            (shouldRestSelectedEntity) && this.changeSelectedEntity(undefined);

            return prevTextRotation;
        }

    };

    beforeUpdateEntityLabelImageCreateEntityWhenNeeded = async (state, selectedEntityId) =>
    {
        const { entityLabel } = state.mapData.entities[selectedEntityId];

        let createEntityLabelFirst = !entityLabel;

        if (createEntityLabelFirst)
        {
            let newState = await this.updateEntityLabel(getDefaultEntityLabel());

            state = Object.assign(state, newState);
        }

        const entityLabelId = deepValue(state, `mapData.entities.${selectedEntityId}.entityLabel._id`, undefined);

        if (!entityLabelId)
        {
            this.setState(state);
            return undefined;
        }

        return entityLabelId;
    };

    updateEntityLabelImage = async (filerId, isCoverImage, languageCode, callback) =>
    {
        const { mapData, selectedEntities } = this.state;
        const { selectedEntityId, selectedEntityTextFeature } = getSelectedEntity(selectedEntities);

        this.setState({ loading: true });

        let state = {
            mapData: { ...mapData },
            loading: false
        };

        try
        {
            let entityLabelId = await this.beforeUpdateEntityLabelImageCreateEntityWhenNeeded(state, selectedEntityId);

            if (isCoverImage)
            {
                await this.updateImageReference({ filerId: filerId.split(","), imageType: "coverImages", lang: languageCode, prevFilerId: null }, entityLabelId, "EntityLabel");
            }

            await brandingApi.uploadEntityLabelImage(entityLabelId, undefined, filerId, isCoverImage, languageCode, async ({ success, data }) =>
            {
                if (success)
                {
                    let entities = { ...state.mapData.entities };
                    let entity = { ...entities[selectedEntityId] };

                    entity.entityLabel = data;

                    if (!isCoverImage)
                    {
                        // reset image position on new image
                        await brandingApi.deleteEntityIconStyle(selectedEntityId, languageCode);

                        deepUpdateValue(entity, `imageRotation?.${languageCode}`, undefined);
                        deepUpdateValue(entity, `imageExtent?.${languageCode}`, undefined);

                        state.imageLayers = await this.updateImageLayers(this.state.imageLayers, entity, selectedEntityTextFeature);
                    }

                    entities[selectedEntityId] = entity;
                    state.mapData.entities = entities;
                }

                this.setState(state);

                callback && callback({ success, data });
            });
        }
        catch (err)
        {
            console.log(err?.message);
            callback && callback({ success: false });
            this.setState(state);
        }
    };

    editEntityLabelImage = async (fileId, file, isCoverImage, languageCode, callback) =>
    {
        const { mapData, selectedEntities } = this.state;
        const { selectedEntityId, selectedEntityTextFeature } = getSelectedEntity(selectedEntities);

        this.setState({ loading: true });

        let state = {
            mapData: { ...mapData },
            loading: false
        };

        let entityLabelId = await this.beforeUpdateEntityLabelImageCreateEntityWhenNeeded(state, selectedEntityId);

        // replace existing image set up
        let coverImages = deepValue(mapData, `entities.${selectedEntityId}.entityLabel.coverImages.${languageCode}`, undefined);
        let indexOfReplacmentImage = undefined;

        if (!!fileId && coverImages)
        {
            indexOfReplacmentImage = (Array.isArray(coverImages) ? coverImages : coverImages[languageCode]).indexOf(fileId);
            await brandingApi.deleteEntityLabelImage(entityLabelId, fileId, languageCode);
        }
        // end of replace existing image set up

        await brandingApi.editEntityLabelImage(entityLabelId, fileId, file, isCoverImage, languageCode, async ({ success, data }) =>
        {
            if (success)
            {
                // replace existing image
                if (indexOfReplacmentImage !== undefined)
                {
                    let newId = data.coverImages[languageCode].pop();

                    data.coverImages[languageCode].splice(indexOfReplacmentImage, 0, newId);

                    await this.reorderEntityLabelCoverImages(data.coverImages[languageCode], languageCode);
                }

                let entities = { ...state.mapData.entities };
                let entity = { ...entities[selectedEntityId] };


                entity.entityLabel = data;

                if (!isCoverImage)
                {
                    // reset image position on new image
                    await brandingApi.deleteEntityIconStyle(selectedEntityId, languageCode);

                    deepUpdateValue(entity, `imageRotation?.${languageCode}`, undefined);
                    deepUpdateValue(entity, `imageExtent?.${languageCode}`, undefined);

                    state.imageLayers = await this.updateImageLayers(this.state.imageLayers, entity, selectedEntityTextFeature);
                }

                entities[selectedEntityId] = entity;
                state.mapData.entities = entities;
            }

            this.setState(state);

            callback && callback({ success, data });
        });
    };

    resetEntityOnMap = async (newEntityLabel) =>
    {
        const { mapData, selectedEntities, properties, propertyId } = this.state;
        const { selectedEntityId, selectedEntityTextFeature } = getSelectedEntity(selectedEntities);

        const state = { mapData: { ...mapData }, properties: { ...properties } };
        let entities = { ...state.mapData.entities };
        let entity = { ...entities[selectedEntityId] };


        entity.entityLabel = newEntityLabel;
        entities[selectedEntityId] = entity;

        // reset image position on new image
        await brandingApi.deleteEntityIconStyle(selectedEntityId);

        deepUpdateValue(entity, `imageRotation.${DEFAULT_LANGUAGE_CODE}`, undefined);
        deepUpdateValue(entity, `imageExtent.${DEFAULT_LANGUAGE_CODE}`, undefined);

        state.imageLayers = await this.updateImageLayers(this.state.imageLayers, entity, selectedEntityTextFeature);

        state.mapData.entities = entities;

        // if entity is building, update its data in state's properties.buildings and properties.allBuildings
        if (propertyId && newEntityLabel.buildingId)
        {
            let updatedProperties = { ...state.properties };
            deepUpdateValue(updatedProperties, `${propertyId}.buildings.${newEntityLabel._id}`, newEntityLabel);
            deepUpdateValue(updatedProperties, `${propertyId}.allBuildings.${newEntityLabel._id}`, newEntityLabel);
            state.properties = updatedProperties;
        }

        this.setState(state);
    };

    editPropertyEntityLabelImage = async (fileId, file, isCoverImage, languageCode, callBack) =>
    {
        const { propertyId, properties, selectedEntities, mapData } = this.state;

        this.setState({ loading: true });

        let state = {
            mapData: { ...mapData },
            properties: { ...properties },
            loading: false
        };

        const { selectedEntityId, selectedEntityTextFeature } = getSelectedEntity(selectedEntities);
        const entity = deepValue(state.mapData, `entities.${selectedEntityId}`, {});

        if (entity.building)
        {
            let property = { ...state.properties[propertyId] };
            let building = { ...property.allBuildings[entity.building] };

            let updateData = {
                _id: building._id,
                fileId
            };

            if (isCoverImage)
            {
                updateData.coverImageFile = file;
            }
            else
            {
                updateData.displayImageFile = file;
            }

            // replace existing image set up
            let coverImages = deepValue(building, "coverImages", undefined);
            let indexOfReplacmentImage = undefined;

            if (!!fileId && coverImages)
            {
                indexOfReplacmentImage = (Array.isArray(coverImages) ? coverImages : coverImages[languageCode]).indexOf(fileId);
                await serverAPI.deleteBuildingImage(building._id, fileId, languageCode);

            }
            // end of replace existing image set up
            await serverAPI.editBuildingImage({ ...updateData, languageCode }, async ({ success, data }) =>
            {
                if (success)
                {
                    // replace existing image
                    if (indexOfReplacmentImage !== undefined)
                    {
                        let newId = data.coverImages[languageCode].pop();

                        data.coverImages[languageCode].splice(indexOfReplacmentImage, 0, newId);

                        await serverAPI.reorderBuildingImages(building._id, data.coverImages[languageCode]);
                    }

                    state.properties[propertyId].buildings[entity.buildingId] = data;
                    state.mapData.entities[selectedEntityId].entityLabel = data;

                    if (!isCoverImage)
                    {
                        state.imageLayers = await this.updateImageLayers(this.state.imageLayers, entity, selectedEntityTextFeature);
                    }
                }

                this.setState(state);

                callBack && callBack({ success, data });
            });
        }
    };

    updatePropertyEntityLabelImage = async (filerId, isCoverImage, languageCode, callBack) =>
    {
        const { propertyId, properties, selectedEntities, mapData } = this.state;

        this.setState({ loading: true });

        let state = {
            mapData: { ...mapData },
            properties: { ...properties },
            loading: false
        };

        const { selectedEntityId, selectedEntityTextFeature } = getSelectedEntity(selectedEntities);
        const entity = deepValue(state.mapData, `entities.${selectedEntityId}`, {});
        const entityLabelId = deepValue(entity, "entityLabel._id", undefined);

        if (entity.building)
        {
            let property = { ...state.properties[propertyId] };
            let building = { ...property.allBuildings[entity.building] };

            let updateData = {
                _id: building._id,
                filerId
            };


            if (isCoverImage)
            {
                updateData.imageType = "coverImage";
            }
            else
            {
                updateData.imageType = "iconImage";
            }

            try
            {
                if (isCoverImage)
                {
                    await this.updateImageReference({ filerId: filerId.split(","), imageType: "coverImages", lang: "en", prevFilerId: null }, entityLabelId, "EntityLabel");
                }

                await serverAPI.updateBuildingImage({ ...updateData, languageCode }, async ({ success, data }) =>
                {
                    if (success)
                    {

                        state.properties[propertyId].buildings[entity.buildingId] = data;
                        state.mapData.entities[selectedEntityId].entityLabel = data;

                        if (!isCoverImage)
                        {
                            state.imageLayers = await this.updateImageLayers(this.state.imageLayers, entity, selectedEntityTextFeature);
                        }
                    }

                    this.setState(state);

                    callBack && callBack({ success, data });
                });
            }
            catch (err)
            {
                console.log(err?.message);
                callBack && callBack({ success: false });
                this.setState(state);
            }
        }
    };

    // updatePropertyEntityLabelImage = async (fileId, file, isCoverImage, languageCode, callBack) => {
    //     const { propertyId, properties, selectedEntities, mapData } = this.state;

    //     this.setState({ loading: true });

    //     let state = {
    //         mapData: { ...mapData },
    //         properties: { ...properties },
    //         loading: false
    //     };

    //     const { selectedEntityId, selectedEntityTextFeature } = getSelectedEntity(selectedEntities);
    //     const entity = deepValue(state.mapData, `entities.${selectedEntityId}`, {});

    //     if (entity.building) {
    //         let property = { ...state.properties[propertyId] };
    //         let building = { ...property.allBuildings[entity.building] };

    //         let updateData = {
    //             _id: building._id,
    //         };

    //         if (isCoverImage) {
    //             updateData.coverImageFile = file;
    //         }
    //         else {
    //             updateData.displayImageFile = file;
    //         }

    //         // replace existing image set up
    //         let coverImages = deepValue(building, "coverImages", undefined);
    //         let indexOfReplacmentImage = undefined;

    //         if (!!fileId && coverImages) {
    //             indexOfReplacmentImage = (Array.isArray(coverImages) ? coverImages : coverImages[languageCode]).indexOf(fileId);
    //             await serverAPI.deleteBuildingImage(building._id, fileId, languageCode);

    //         }
    //         // end of replace existing image set up

    //         await serverAPI.updateBuildingImage({ ...updateData, languageCode }, async ({ success, data }) => {
    //             if (success) {
    //                 // replace existing image
    //                 if (indexOfReplacmentImage !== undefined) {
    //                     let newId = data.coverImages[languageCode].pop();

    //                     data.coverImages[languageCode].splice(indexOfReplacmentImage, 0, newId);

    //                     await serverAPI.reorderBuildingImages(building._id, data.coverImages[languageCode]);
    //                 }

    //                 state.properties[propertyId].buildings[entity.buildingId] = data;
    //                 state.mapData.entities[selectedEntityId].entityLabel = data;

    //                 if (!isCoverImage) {
    //                     state.imageLayers = await this.updateImageLayers(this.state.imageLayers, entity, selectedEntityTextFeature);
    //                 }
    //             }

    //             this.setState(state);

    //             callBack && callBack({ success, data });
    //         });
    //     }
    // }


    deletePropertyEntityLabelImage = async (fileId, callBack) =>
    {
        const { propertyId, properties, selectedEntities, mapData } = this.state;

        let state = {
            mapData: { ...mapData },
            properties: { ...properties },
            loading: false
        };

        try
        {
            const { selectedEntityId, selectedEntityTextFeature } = getSelectedEntity(selectedEntities);
            const entity = deepValue(state.mapData, `entities.${selectedEntityId}`, {});
            const entityLabelId = deepValue(entity, "entityLabel._id", undefined);

            if (entity.building)
            {
                let property = { ...state.properties[propertyId] };
                // if building has no entities, it will not be in property.buildings, so we find it in allBuildings
                let building = { ...property.allBuildings[entity.building] };
                await this.updateImageReference({ filerId: null, prevFilerId: fileId, lang: "en", imageType: "coverImages" }, entityLabelId, "EntityLabel");
                const { success, data } = await serverAPI.deleteBuildingImage(building._id, fileId);

                if (!success)
                {
                    throw new Error("Error deleting entityLabel Image");
                }

                if (success)
                {
                    state.properties[propertyId].buildings[building._id] = data;
                    state.mapData.entities[selectedEntityId].entityLabel = data;
                    // remove image layer from image layers
                    state.imageLayers = this.deleteImageLayer(this.state.imageLayers, entity, selectedEntityTextFeature);
                }

                callBack && callBack({ success, data });

                return state;
            }
        }
        catch (err)
        {
            console.log(err.message);
            callBack && callBack({ success: false });
            return state;
        }
    };

    reorderPropertyEntityLabelCoverImages = async (coverImageList, languageCode) =>
    {
        const { propertyId, properties, selectedEntities, mapData } = this.state;

        let state = {
            mapData: { ...mapData },
            properties: { ...properties },
            loading: false
        };

        const { selectedEntityId } = getSelectedEntity(selectedEntities);
        const entity = deepValue(state.mapData, `entities.${selectedEntityId}`, {});

        if (entity.building)
        {
            let property = { ...state.properties[propertyId] };
            let building = { ...property.buildings[entity.building] };

            const { success, data } = await serverAPI.reorderBuildingImages(building._id, coverImageList, languageCode);

            if (success)
            {
                state.properties[propertyId].buildings[entity.buildingId] = data;
                state.mapData.entities[selectedEntityId].entityLabel = data;
            }
        }

        this.setState({ mapData });

    };

    reorderEntityLabelCoverImages = async (coverImageList, languageCode) =>
    {
        const { selectedEntityId } = getSelectedEntity(this.state.selectedEntities);
        const entityLabelId = this.state.mapData.entities[selectedEntityId].entityLabel._id;

        const { success, data } = await brandingApi.reorderEntityLabelCoverImages(entityLabelId, coverImageList, languageCode);

        if (success)
        {
            let mapData = { ...this.state.mapData };

            mapData.entities[selectedEntityId].entityLabel = data;

            this.setState({ mapData });
        }
    };

    deleteEntityLabelImage = async (fileId, callBack) =>
    {
        const state = {};
        try
        {
            const { selectedEntityId, selectedEntityTextFeature } = getSelectedEntity(this.state.selectedEntities);
            const entity = deepValue(this.state.mapData, `entities.${selectedEntityId}`, {});
            const entityLabelId = deepValue(entity, "entityLabel._id", undefined);

            await this.updateImageReference({ filerId: null, prevFilerId: fileId, lang: "en", imageType: "coverImages" }, entityLabelId, "EntityLabel");
            const { success, data: newEntityLabel } = await brandingApi.deleteEntityLabelImage(entityLabelId, fileId);

            if (!success)
            {
                throw new Error("Error deleting entityLabel image");
            }

            if (success)
            {
                let mapData = { ...this.state.mapData };
                state.imageLayers = { ...this.state.imageLayers };

                mapData.entities[selectedEntityId].entityLabel = newEntityLabel;

                // remove image layer from image layers
                state.imageLayers = this.deleteImageLayer(this.state.imageLayers, entity, selectedEntityTextFeature);
                state.imageLayers = await this.updateImageLayers(state.imageLayers, { ...entity, entityLabel: newEntityLabel }, selectedEntityTextFeature);
                state.mapData = mapData;
            }

            callBack && callBack({ success, data: newEntityLabel });
            return state;
        }
        catch (err)
        {
            console.log(err?.message);
            callBack && callBack({ success: false });
            return state;
        }
    };

    setEditUnsavedChanges = (isUnsavedChanges) =>
    {
        this.setState({ editUnsavedChanges: isUnsavedChanges });
    };

    publishProperty = async (db = PUBLISH_DATA_SCOPES.PROD) =>
    {
        const { propertyId } = this.state;

        const result = await brandingApi.publishProperty(propertyId, db);

        if (result.isSuccess)
        {
            this.refreshPropertyBrandingStatus();
        }

        return result;
    };

    schedulePublish = (date) =>
    {
        const { propertyId } = this.state;
        return brandingApi.schedulePublish(propertyId, date);
    };

    /**
     * Logic to save or revert new entity text position.
     *
     * @param {Object} selectedEntityText
     * @param {String} selectedEntityText.entityId
     * @param {Object} selectedEntityText.entityTextFeature
     * @param {Array} selectedEntityText.prevCursorCoordinate
     * @param {Array} selectedEntityText.startCoordinate
     */
    handleMoveEntityTextFeature = async (selectedEntityText) =>
    {
        let state = {};

        // check if entity text is in entity polygon
        if (selectedEntityText)
        {
            let entityTextFeature = selectedEntityText.entityTextFeature;
            let entityFeature = entityTextFeature.get("connectedFeature");

            let isUnion = this.mapController.polygonController.areFeaturesConnected([entityFeature, entityTextFeature]);

            let resetTextPosition = true;

            // if true store in db
            if (isUnion)
            {
                // updateDB
                let format = new GeoJSON();
                let geoJsonGeometry = format.writeFeatureObject(entityTextFeature);

                let mercatorCoordinates = geoJsonGeometry.geometry.coordinates;

                // convert mercator to latlng...
                let textCoordinateLatLng = toGpsLocation(mercatorCoordinates);

                // update DB
                const { success, data } = await brandingApi.updateEntityStyle(selectedEntityText.entityId, { textCoordinate: { [LANGUAGE_CODE]: textCoordinateLatLng } });

                if (success)
                {
                    // update local entity location
                    let mapDataChange = { ...this.state.mapData };
                    let entity = { ...mapDataChange.entities[selectedEntityText.entityId] };

                    entity.textCoordinate = data?.textCoordinate;

                    mapDataChange.entities[selectedEntityText.entityId] = entity;

                    state.mapData = mapDataChange;
                    resetTextPosition = false;
                }
            }

            // if update db was not a success or position not in entity, reset text position
            if (resetTextPosition === true)
            {
                let geometry = entityTextFeature.getGeometry();
                this.mapController.polygonController.translateGeometry(geometry, selectedEntityText.prevCursorCoordinate, selectedEntityText.startCoordinate);
            }
        }

        return state;
    };

    /**
    * Logic to save or revert new entity image position.
    *
    * @param {Object} selectedEntityImage
    * @param {String} selectedEntityImage.entityId
    * @param {Object} selectedEntityImage.imageLayer
    * @param {Array} selectedEntityImage.prevCursorCoordinate
    * @param {String} selectedEntityImage.startCoordinate
    */
    handleMoveEntityImage = async (selectedEntityImage) =>
    {
        let state = {};

        // check if entity text is in entity polygon
        if (selectedEntityImage)
        {
            const { mapData } = this.state;
            // Check if image extent is in entity

            // get entity polygon
            let imageRotation = deepValue(mapData, `entities.${selectedEntityImage.entityId}.imageRotation.${LANGUAGE_CODE}`, 0);

            const isImageInEntity = this.isImageInEntity(selectedEntityImage.entityId, selectedEntityImage.imageLayer, imageRotation);

            // if true store in db
            if (isImageInEntity)
            {
                let imageExtent = selectedEntityImage.imageLayer.getSource().getImageExtent();

                let minPoint = [imageExtent[0], imageExtent[1]];
                let maxPoint = [imageExtent[2], imageExtent[3]];

                let convertedExtent = [...toGpsLocation(minPoint), ...toGpsLocation(maxPoint)];

                // update DB
                const { success, data } = await brandingApi.updateEntityStyle(selectedEntityImage.entityId, { imageExtent: { [LANGUAGE_CODE]: convertedExtent } });

                if (success)
                {
                    // update local entity location
                    let mapDataChange = { ...this.state.mapData };
                    let entity = { ...mapDataChange.entities[selectedEntityImage.entityId] };

                    entity.imageExtent = data?.imageExtent;

                    mapDataChange.entities[selectedEntityImage.entityId] = entity;

                    state.mapData = mapDataChange;
                }
            }
            // else revert to old coordinate
            else
            {
                // Function mutates imagelayer
                this.mapController.imageController.translateImageLayer(selectedEntityImage.imageLayer, selectedEntityImage.prevCursorCoordinate, selectedEntityImage.startCoordinate);
            }
        }

        return state;
    };

    handleRotateEntityImage = async (selectedEntityImage, imageRotation, savedImage, callBack) =>
    {
        let state = {};

        if (!!selectedEntityImage && rotationIsValid(imageRotation))
        {
            const isImageInEntity = this.isImageInEntity(selectedEntityImage.entityId, selectedEntityImage.imageLayer, imageRotation);

            if (isImageInEntity)
            {
                // update DB
                const { success, data } = await brandingApi.updateEntityStyle(selectedEntityImage.entityId, { imageRotation: { [LANGUAGE_CODE]: imageRotation } });

                if (success)
                {
                    // update local entity location
                    let mapDataChange = { ...this.state.mapData };
                    let entity = { ...mapDataChange.entities[selectedEntityImage.entityId] };

                    entity.imageRotation = data?.imageRotation;

                    mapDataChange.entities[selectedEntityImage.entityId] = entity;

                    state.mapData = mapDataChange;

                    callBack(true);

                }
            }
            else
            {
                callBack(false);
            }
        }

        return state;
    };

    isImageInEntity = (entityId, imageLayer, imageRotation) =>
    {
        const { mapData } = this.state;
        // Check if image extent is in entity
        let isImageInEntity = false;

        // get entity polygon
        let entity = deepValue(mapData, `entities.${entityId}`, undefined);

        // always return true for POIs
        if (entity?.entityType === EntityType.POINT_OF_INTEREST)
        {
            return true;
        }

        let format = new GeoJSON();

        let entityPolygon = format.writeFeatureObject(entity.feature).geometry;

        let imagePolygon = this.mapController.imageController.getImageBoundingPolygonWithRotation(imageLayer, imageRotation);

        let imageCoordinates = deepValue(imagePolygon, "geometry.coordinates.0");

        isImageInEntity = this.mapController.polygonController.isPointsInPolygon(imageCoordinates, entityPolygon, 0.5);

        return isImageInEntity;

    };

    /**
     * Clears all entity meta data from db.
     * Note this can only be used on building level entities
     */
    handleVacantEntity = async (message) =>
    {
        const { propertyId, buildingId, selectedEntities, imageLayers } = this.state;
        let newImageLayers = { ...imageLayers };

        const { selectedEntityId, selectedEntityTextFeature } = getSelectedEntity(selectedEntities);

        const { success, data } = await brandingApi.vacantEntity(selectedEntityId, propertyId, buildingId);

        delete newImageLayers[selectedEntityId];

        if (success)
        {
            data.style = data.style[DEFAULT_LANGUAGE_CODE];
            let stateUpdate = this.entitySuccessHelper({ data, selectedEntityId, selectedEntityTextFeature });

            stateUpdate.editUnsavedChanges = false;
            stateUpdate.imageLayers = newImageLayers;

            this.changeSelectedEntity(undefined);
            toast.success(message);

            if (this.state.redirectMaintenance)
            {
                this.redirectToMaintenancePage();
            }

            return stateUpdate;
        }
    };

    handleVacantTransferEntity = async (targetEntityId, successMessage, errorMessage) =>
    {
        const { propertyId, buildingId, selectedEntities, imageLayers } = this.state;
        let newImageLayers = { ...imageLayers };
        const { selectedEntityId, selectedEntityTextFeature } = getSelectedEntity(selectedEntities);

        const { success, data } = await brandingApi.vacantTransferEntity(selectedEntityId, targetEntityId, propertyId, buildingId);
        delete newImageLayers[selectedEntityId];

        if (success)
        {
            data.style = data.style[DEFAULT_LANGUAGE_CODE];
            this.setState({ imageLayers: newImageLayers });
            this.entitySuccessHelper({ data, selectedEntityId, selectedEntityTextFeature });

            await this.updateMapData();
            this.setState({ editUnsavedChanges: false, selectedEntities: {} });
            toast.success(successMessage);
        }
        else
        {
            toast.error(errorMessage);
        }
    };

    handleChangeEntityStyle = async (patch, languageCode) =>
    {
        const { selectedEntities } = this.state;
        const mapData = { ...this.state.mapData };

        const { selectedEntityId, selectedEntityTextFeature } = getSelectedEntity(selectedEntities);
        const entity = deepValue(mapData, `entities.${selectedEntityId}`, undefined);

        let style = deepValue(entity, `style.${languageCode}`, {});
        style = { ...style, ...patch };

        const result = await brandingApi.updateEntityStyle(selectedEntityId, { style: { [languageCode]: style } });

        let state = {};

        if (result.success)
        {
            entity.style[languageCode] = style;

            mapData.entities[selectedEntityId] = entity;

            let stateUpdate = await this.entityStyleSuccessHelper({
                entity,
                selectedEntityTextFeature,
                valuesChanged: Object.keys(patch)
            });

            Object.assign(state, stateUpdate);

            state.mapData = mapData;
        }

        return state;
    };

    handleChangeMobileSettings = async (mobileSettings) =>
    {
        const { propertyId, buildingId } = this.state;

        const result = await brandingApi.changeMobileSettings(buildingId, mobileSettings);

        if (result.success)
        {
            let state = {};

            let properties = { ...this.state.properties };

            properties[propertyId].buildings[buildingId].mobileSettings = mobileSettings;

            state.properties = properties;

            return state;
        }
    };

    handleGetStoresFromCategories = async (categories) =>
    {
        this.setState({ loading: true });

        const result = await brandingApi.getStoresFromCategories(categories);

        this.setState({ loading: false });

        if (result.success)
        {
            return result.storeNames;
        }
        else
        {
            return undefined;
        }

    };

    exportCategoryTree = async (propertyIds, categoryTree, callback) =>
    {
        const { success, createdGroups } = await brandingApi.exportCategoryTree(propertyIds, categoryTree);
        if (success)
        {
            const updatedProperties = { ...this.state.properties };
            propertyIds.forEach((propertyId) =>
            {
                if (createdGroups?.[propertyId].length > 0)
                {
                    updatedProperties[propertyId].categoryRoots = createdGroups[propertyId];
                }
            });
            this.setState({
                properties: updatedProperties
            });
        }
        callback(success);
    };

    copyTemplate = async (propertyIds, templateIds, callback) =>
    {
        const { success, validationErrors } = await brandingApi.copyTemplate({ templateIds, propertyIds });
        callback(success, validationErrors);
    };

    copyVenueInfo = async (entityLabelId, relatedStores, callback) =>
    {
        try
        {
            const { success } = await brandingApi.copyVenueInfo(entityLabelId, relatedStores);
            if (success)
            {
                await this.updateFloorData();
            }
            callback(success);
        }
        catch (err)
        {
            console.log("copyVenueInfo error", err);
            callback(false);
        }
    };

    downloadListViewCSV = async (propertyId, buildingId, floorId, lang = DEFAULT_LANGUAGE_CODE) =>
    {
        const filename = this.getCSVFileName(propertyId, buildingId, floorId, lang);

        let apiUrl = `/api/internal/labels/csvDownload?propertyId=${propertyId}&lang=${lang}`;

        if (buildingId)
        {
            apiUrl = apiUrl + `&buildingId=${buildingId}`;
        }

        if (floorId)
        {
            apiUrl = apiUrl + `&floorId=${floorId}`;
        }

        try
        {
            await fetch(apiUrl, { headers: serverAPI.createHeaders(true) })
                .then((res) => res.blob())
                .then((file) =>
                {
                    const url = window.URL.createObjectURL(file);
                    const aTagElement = document.createElement("a");
                    aTagElement.href = url;
                    aTagElement.download = filename;
                    document.body.appendChild(aTagElement); // we need to append the element to the dom -> otherwise it will not work in firefox
                    aTagElement.click();
                    aTagElement.remove();
                });
        }
        catch (err)
        {
            console.error(err);
        }
    };

    downloadListViewExcel = async (propertyId, buildingId, floorId, lang = DEFAULT_LANGUAGE_CODE) =>
    {
        const filename = this.getExcelFileName(propertyId, buildingId, floorId, lang);

        let apiUrl = `/api/internal/labels/excelDownload?propertyId=${propertyId}&lang=${lang}`;

        if (buildingId)
        {
            apiUrl = apiUrl + `&buildingId=${buildingId}`;
        }

        if (floorId)
        {
            apiUrl = apiUrl + `&floorId=${floorId}`;
        }
        try
        {
            await fetch(apiUrl, { headers: serverAPI.createHeaders(true) })
                .then((res) => res.blob())
                .then((file) => downloadFile(filename, file));
        }
        catch (err)
        {
            console.error(err);
        }
    };

    downloadCategoriesCSV = async (propertyId, lang = DEFAULT_LANGUAGE_CODE) =>
    {
        const currentPropertyName = this.state.properties[propertyId].name.en;
        const filename = `${currentPropertyName}_Groups_${lang.toLocaleUpperCase()}.csv`;

        const apiUrl = `/api/internal/categories/downloadCategoriesCsv?propertyId=${propertyId}&lang=${lang}`;

        try
        {
            await fetch(apiUrl, { headers: serverAPI.createHeaders(true) })
                .then((res) => res.blob())
                .then((file) => downloadFile(filename, file));
        }
        catch (err)
        {
            console.error(err);
        }
    };

    downloadCategoriesExcel = async (propertyId, lang = DEFAULT_LANGUAGE_CODE) =>
    {
        const currentPropertyName = this.state.properties[propertyId].name.en;
        const filename = `${currentPropertyName}_Groups_${lang.toLocaleUpperCase()}.xlsx`;

        const apiUrl = `/api/internal/categories/downloadCategoriesExcel?propertyId=${propertyId}&lang=${lang}`;

        try
        {
            await fetch(apiUrl, { headers: serverAPI.createHeaders(true) })
                .then((res) => res.blob())
                .then((file) => downloadFile(filename, file));
        }
        catch (err)
        {
            console.error(err);
        }
    };

    getCSVFileName = (propertyId, buildingId, floorId, lang = DEFAULT_LANGUAGE_CODE, appendString = "") =>
    {
        const { properties } = this.state;
        const currentPropertyName = properties[propertyId].name.en;

        let currentBuildingName = "";
        let currentFloorName = "";

        if (buildingId)
        {
            currentBuildingName = properties[propertyId].buildings[buildingId].longName.en;
        }
        if (floorId)
        {
            currentFloorName = properties[propertyId].buildings[buildingId].floors.filter((floor) => floor["_id"] === floorId)[0].longName.en;
        }

        return `${currentPropertyName}${currentBuildingName ? `_${currentBuildingName}` : ""}${currentFloorName ? `_${currentFloorName}` : ""}${appendString ? `_${appendString}` : ""}_${lang}.csv`;
    };

    getExcelFileName = (propertyId, buildingId, floorId, lang = DEFAULT_LANGUAGE_CODE, appendString = "") =>
    {
        const { properties } = this.state;
        const currentPropertyName = properties[propertyId].name.en;

        let currentBuildingName = "";
        let currentFloorName = "";

        if (buildingId)
        {
            currentBuildingName = properties[propertyId].buildings[buildingId].longName.en;
        }
        if (floorId)
        {
            currentFloorName = properties[propertyId].buildings[buildingId].floors.filter((floor) => floor["_id"] === floorId)[0].longName.en;
        }

        return `${currentPropertyName}${currentBuildingName ? `_${currentBuildingName}` : ""}${currentFloorName ? `_${currentFloorName}` : ""}${appendString ? `_${appendString}` : ""}_${lang}.xlsx`;
    };

    getCurrentBuildingLangs = () =>
    {
        const { propertyId, buildingId, properties } = this.state;

        let langs = [DEFAULT_LANGUAGE_CODE];

        if (buildingId)
        {
            langs = Object.keys(properties[propertyId].buildings[buildingId].longName);
        }

        return langs;
    };

    getCurrentPropertyLangOptions = () =>
    {
        const { propertyId, properties } = this.state;

        let langs = [DEFAULT_LANGUAGE_CODE];

        if (propertyId)
        {
            langs = Object.keys(properties[propertyId].name);
        }

        return langs;
    };

    getCurrentLangOptions = () =>
    {
        const { propertyId, buildingId, properties } = this.state;

        let langs = [DEFAULT_LANGUAGE_CODE];

        // if building is selected use building lang options
        if (buildingId)
        {
            langs = Object.keys(properties[propertyId].buildings[buildingId].longName);
        }
        // else use property level lang options
        else if (propertyId)
        {
            langs = Object.keys(properties[propertyId].name);
        }

        return langs;
    };

    getUpdatedEntityMapData = async () =>
    {
        const { floorId, buildingId, propertyId } = this.state;

        let state = {};

        if (floorId)
        {
            state.mapData = await this.getFloorMapData(buildingId, floorId);
        }
        else
        {
            state.mapData = await this.getPropertyMapData(propertyId);
        }

        const { entityLayers, boundaryPolygon, boundaryPolygonAreaInSqMt, imageLayers } = this.mapController.initialize(state.mapData);

        state = { ...state, entityLayers, boundaryPolygon, boundaryPolygonAreaInSqMt, imageLayers };

        return state;
    };

    createNewStore = async (entityLabel) =>
    {
        // Create new entityLabel
        await this.updateEntityLabel(entityLabel);

        // Get the updated entities map data
        const updatedState = await this.getUpdatedEntityMapData();

        const selectedEntity = Object.keys(this.state.selectedEntities)[0];

        this.setState({ ...updatedState }, () =>
        {
            // highlight the entity
            this.updateSelectedEntities([selectedEntity]);
        });

        return updatedState;
    };

    removeEntityFromMap = (entityId, execute = true) =>
    {
        const {
            entities, entityLayers, imageLayers
        } = this.mapController.removeEntity(this.state.mapData.entities, entityId, this.state.entityLayers, this.state.imageLayers, this.state.mapData.style);

        const newMapData = { ...this.state.mapData };
        newMapData.entities = entities;
        const update = { entityLayers, imageLayers, mapData: newMapData };
        if (execute)
        {
            this.setState(update);
        }
        return update;
    };

    addEntityToMap = async (entity, execute = true) =>
    {
        if (entity)
        {
            const { entityLayers, mapData, imageLayers, selectedEntities } = this.state;
            const _entityLayers = { ...entityLayers };
            const _imageLayers = { ...imageLayers };
            const _mapData = { ...mapData };

            this.mapController.addEntity(entity, mapData.style, _entityLayers, _imageLayers);

            _mapData.entities[entity._id] = entity;

            if (selectedEntities[entity._id])
            {
                selectedEntities[entity._id].feature = entity.feature;
                selectedEntities[entity._id].textFeature = entity.textFeature;
            }

            const update = { entityLayers: _entityLayers, mapData: _mapData, imageLayers: _imageLayers, selectedEntities };
            if (execute)
            {
                this.setState(update);
            }
            return update;
        }
    };

    updateEntityOnMap = (entity, execute = true) =>
    {

        this.removeEntityFromMap(entity?._id, false);
        let update = this.addEntityToMap(entity, execute);
        if (execute)
        {
            this.setState(update);
        }
        return update;
    };

    deselectEntities = () =>
    {
        this.handleHighlightSelectedEntities(this.state.selectedEntities, false);
        this.setState({ selectedEntities: {} });
    };

    reInitializeMapData = () =>
    {
        const { mapData } = this.state;

        if (mapData?.entities && mapData?.style)
        {
            const {
                entityLayers, boundaryPolygon, imageLayers,
                boundaryPolygonAreaInSqMt
            } = this.mapController.initialize(mapData);

            this.setState({ entityLayers, boundaryPolygon, boundaryPolygonAreaInSqMt, imageLayers });
        }
    };

    toggleLoading = (value) =>
    {
        this.setState({ loading: value });
    };

    downloadOnlineKeywordsTextFile = (lang = DEFAULT_LANGUAGE_CODE) =>
    {
        const selectedEntityId = Object.keys(this.state.selectedEntities)[0];
        let onlineKeywords = deepValue(this.state.mapData, `entities.${selectedEntityId}.entityLabel.onlineKeywords.${lang}`, undefined);

        if (!onlineKeywords)
        {
            onlineKeywords = [];
        }

        const fileString = onlineKeywords.join("\n");

        const blob = new Blob([fileString], { type: "text/plain;charset=utf-8;" });
        downloadFile(this.getCurrentStoreName(), blob);
    };

    getCurrentStoreName = () =>
    {
        const selectedEntityId = Object.keys(this.state.selectedEntities)[0];
        let longName = deepValue(this.state.mapData, `entities.${selectedEntityId}.entityLabel.longName.${DEFAULT_LANGUAGE_CODE}`, undefined);

        if (!longName)
        {
            longName = "Mapsted_store";
        }

        return longName;
    };

    uploadOnlineKeywordsTextFile = async (file, lang = DEFAULT_LANGUAGE_CODE) =>
    {
        let result;
        this.setState({ loading: true });
        try
        {
            const fileString = await this.parseFile(file);
            let onlineKeywords = fileString.split(/\r?\n|\r|\n/g);

            // remove all blank strings
            onlineKeywords = onlineKeywords.filter((onlineKeyword) => onlineKeyword.trim());

            // remove all duplicates
            const uniqueOnlineKeywords = Array.from(new Set(onlineKeywords));

            const selectedEntityId = Object.keys(this.state.selectedEntities)[0];

            const selectedEntityLabelId = deepValue(this.state.mapData, `entities.${selectedEntityId}.entityLabel._id`, undefined);

            const entityType = deepValue(this.state.mapData, `entities.${selectedEntityId}.entityType`, undefined);
            const subEntityType = deepValue(this.state.mapData, `entities.${selectedEntityId}.subEntityType`, undefined);

            let isBuilding = entityType === EntityType.STRUCTURE && subEntityType === StructureEntityType.BUILDING;

            const { success } = await serverAPI.updateOnlineKeywords(selectedEntityLabelId, lang, uniqueOnlineKeywords, isBuilding);

            // if online keywords are successfully updated, fetch the newly updated map data
            if (success)
            {
                await this.refreshMapData();
            }

            result = { success, onlineKeywords: uniqueOnlineKeywords };
        }
        catch (error)
        {
            console.log("Error while uploading online keywords", error);
            result = { success: false };
        }
        finally
        {
            this.setState({
                loading: false
            });
        }

        return result;
    };

    updateCoverImages = ({ data, entityLabel }) =>
    {
        const isEntityLabel = !entityLabel.buildingId;
        if (isEntityLabel)
        {
            this.updateEntityCoverImages({ data, entityLabel });
        }
        else
        {
            this.updateBuildingCoverImages({ data, entityLabel });
        }
    };

    updateIconImage = ({ data, entityLabel }) =>
    {
        const isEntityLabel = !entityLabel.buildingId;
        if (isEntityLabel)
        {
            this.updateEntityIconImage({ data, entityLabel });
        }
        else
        {
            this.updateBuildingIconImage({ data, entityLabel });
        }
    };

    updateEntityCoverImages = async ({ data, entityLabel }) =>
    {
        this.setState({ loading: true });

        try
        {
            const { result } = await brandingApi.updateEntityCoverImages(entityLabel._id, data);
            await this.resetEntityOnMap(result);
        }
        catch (err)
        {
            console.log("Error while updating entity cover images", err);
        }
        finally
        {
            this.setState({ loading: false });
        }
    };

    updateBuildingCoverImages = async ({ data, entityLabel }) =>
    {
        this.setState({ loading: true });

        try
        {
            const { result } = await serverAPI.updateBuildingCoverImages(entityLabel._id, data);
            await this.resetEntityOnMap(result);
        }
        catch (err)
        {
            console.log("Error while updating entity cover images", err);
        }
        finally
        {
            this.setState({ loading: false });
        }
    };

    updateBuildingIconImage = async ({ data, entityLabel }) =>
    {
        this.setState({ loading: true });

        try
        {
            const { result } = await serverAPI.updateBuildingIconImage(entityLabel._id, data);
            await this.resetEntityOnMap(result);
        }
        catch (err)
        {
            console.log("Error while adding building icon image", err);
        }
        finally
        {
            this.setState({ loading: false });
        }
    };

    updateEntityIconImage = async ({ data, entityLabel }) =>
    {
        this.setState({ loading: true });

        try
        {
            const { result } = await brandingApi.updateEntityIconImage(entityLabel._id, data);
            await this.resetEntityOnMap(result);
        }
        catch (err)
        {
            console.log("Error while updating entity icon image", err);
            throw err;
        }
        finally
        {
            this.setState({ loading: false });
        }
    };

    parseFile = async (file) =>
    {
        const string = await new Promise((resolve) =>
        {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsText(file);
        });

        return string;
    };

    setRedirectMaintenance = (redirectMaintenance) =>
    {
        this.setState({
            redirectMaintenance
        });
    };

    redirectToMaintenancePage = () =>
    {
        const { history } = this.props;

        if (history)
        {
            history.push(`/maintenance?${RESTORE_QUERY_KEY}=${RESTORE_QUERY_MAP_OVERLAYS}`);
        }

        this.setRedirectMaintenance(false);
    };

    getBuildingName = () =>
    {
        const { properties, buildingId, propertyId } = this.state;
        let buildingName = "";

        if (buildingId)
        {
            buildingName = properties[propertyId].buildings[buildingId].longName.en;
        }
        return buildingName;
    };

    isValidationInProgress = () => this.state.validationInProgress;

    render()
    {
        const value = {
            state: this.state,
            syncValidationInfoPopup: this.syncValidationInfoPopup,
            isValidationInProgress: this.isValidationInProgress,
            updateState: (newState) => this.setState(newState),
            callbackWithLoadingWrapper: this.callbackWithLoadingWrapper,
            changeSelectedProperty: this.callbackWithLoadingWrapper(this.changeSelectedProperty),
            changeSelectedBuilding: this.callbackWithLoadingWrapper(this.changeSelectedBuilding),
            changeSelectedFloor: this.callbackWithLoadingWrapper(this.changeSelectedFloor),
            changeSelectedLevel: this.callbackWithLoadingWrapper(this.changeSelectedLevel),
            changeSelectedTool: this.changeSelectedTool,
            selectSelectedTool: this.selectSelectedTool,
            changeSelectedEntity: this.changeSelectedEntity,
            changeSelectedEntityMultiple: this.changeSelectedEntityMultiple,
            updateEntityLabel: this.callbackWithLoadingWrapper(this.updateEntityLabel, true),
            updatePropertyEntityLabel: this.callbackWithLoadingWrapper(this.updatePropertyEntityLabel, true),
            updateEntityLabelImage: this.updateEntityLabelImage,
            updateImageReference: this.updateImageReference,
            editPropertyEntityLabelImage: this.editPropertyEntityLabelImage,
            updatePropertyEntityLabelImage: this.updatePropertyEntityLabelImage,
            editEntityLabelImage: this.editEntityLabelImage,
            reorderEntityLabelCoverImages: this.reorderEntityLabelCoverImages,
            reorderPropertyEntityLabelCoverImages: this.reorderPropertyEntityLabelCoverImages,
            updateTextRotation: this.updateTextRotation,
            saveTextRotation: this.callbackWithLoadingWrapper(this.saveTextRotation, true),
            deleteEntityLabelImage: this.callbackWithLoadingWrapper(this.deleteEntityLabelImage, true),
            deletePropertyEntityLabelImage: this.callbackWithLoadingWrapper(this.deletePropertyEntityLabelImage, true),
            revertTextRotation: this.revertTextRotation,
            mergeSelectedEntities: this.callbackWithLoadingWrapper(this.mergeSelectedEntities, true),
            updateProperties: this.callbackWithLoadingWrapper(this.updateProperties),
            updatePropertiesWithoutWrapper: this.updateProperties,
            updatePropertiesNoWrap: this.updateProperties, // just to get around 'this.callbackWithLoadingWrapper'
            splitSelectedEntity: this.callbackWithLoadingWrapper(this.splitSelectedEntity, true),
            setEditUnsavedChanges: this.setEditUnsavedChanges,
            publishProperty: this.publishProperty,
            schedulePublish: this.schedulePublish,
            handleMoveEntityTextFeature: this.callbackWithLoadingWrapper(this.handleMoveEntityTextFeature),
            handleMoveEntityImage: this.callbackWithLoadingWrapper(this.handleMoveEntityImage),
            handleVacantEntity: this.callbackWithLoadingWrapper(this.handleVacantEntity),
            handleVacantTransferEntity: this.callbackWithLoadingWrapper(this.handleVacantTransferEntity),
            handleRotateEntityImage: this.callbackWithLoadingWrapper(this.handleRotateEntityImage),
            handleChangeEntityStyle: this.callbackWithLoadingWrapper(this.handleChangeEntityStyle),
            handleChangeMobileSettings: this.callbackWithLoadingWrapper(this.handleChangeMobileSettings),
            handleRefreshMapData: this.refreshMapData,
            handleGetStoresFromCategories: this.handleGetStoresFromCategories,
            refreshMap: this.refreshMap,
            resetEntityOnMap: this.resetEntityOnMap,
            deselectVacantStore: this.deselectVacantStore,
            exportCategoryTree: this.callbackWithLoadingWrapper(this.exportCategoryTree),
            copyTemplate: this.callbackWithLoadingWrapper(this.copyTemplate),
            downloadListViewCSV: this.downloadListViewCSV,
            downloadListViewExcel: this.downloadListViewExcel,
            downloadCategoriesCSV: this.downloadCategoriesCSV,
            downloadCategoriesExcel: this.downloadCategoriesExcel,
            copyVenueInfo: this.callbackWithLoadingWrapper(this.copyVenueInfo),
            updateFloorData: this.updateFloorData,
            mapController: this.mapController,
            createNewStore: this.callbackWithLoadingWrapper(this.createNewStore),
            deselectEntities: this.deselectEntities,
            updateSelectedEntities: this.updateSelectedEntities,
            updateIconImage: this.updateIconImage,
            updateCoverImages: this.updateCoverImages,
            toggleLoading: this.toggleLoading,
            getCSVFileName: this.getCSVFileName,
            getExcelFileName: this.getExcelFileName,
            handleSelectedPropertyBrandingStatusUpdate: this.handleSelectedPropertyBrandingStatusUpdate,
            getCurrentBuildingLangs: this.getCurrentBuildingLangs,
            downloadOnlineKeywordsTextFile: this.downloadOnlineKeywordsTextFile,
            uploadOnlineKeywordsTextFile: this.uploadOnlineKeywordsTextFile,
            getCurrentLangOptions: this.getCurrentLangOptions,
            updateMapData: this.updateMapData,
            updatePropertyData: this.updatePropertyData,
            reInitializeMapData: this.reInitializeMapData,
            setRedirectMaintenance: this.setRedirectMaintenance,
            redirectToMaintenancePage: this.redirectToMaintenancePage,
            getCurrentPropertyLangOptions: this.getCurrentPropertyLangOptions,
            getBuildingName: this.getBuildingName,
            updateAllEntityData: this.updateAllEntityData,
            initiateSingleEntityEdit: this.initiateSingleEntityEdit,
        };

        return (
            <BrandingContext.Provider value={value}>
                <Loader active={this.state.loading} />
                {this.props.children}
            </BrandingContext.Provider>
        );
    }
}

export default withRouter(withQueryClient(withConfig(BrandingProvider)));
