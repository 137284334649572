export class TransitionStep 
{
    addedTransitions;
    deletedTransitions;
    updatedTransitionsBefore;
    updatedTransitionsAfter;

    constructor(obj)
    {
        Object.assign(this, obj);
    }
}