import React, { createContext, useContext, useState, useMemo, useEffect } from "react";
import { OVERLAYS_TEMPLATE_MODES } from "../_constants/overlaysTemplate";
import serverApi from "../_api/server.api";
import BrandingContext from "./BrandingContext";
import { toast } from "react-toastify";
import { Trans, useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { useLoadingPool } from "../_utils/hooks";
import { truncateText } from "../_utils/mapUtils";
import { Loader } from "../components/elements/loader";
import { useLocation } from "react-router-dom";
import { filterTemplates, sortTemplates } from "../_utils/utils";

const OverlaysTemplateContext = createContext(null);

export const useOverlaysTemplateContext = () => useContext(OverlaysTemplateContext);

const OverlaysTemplateProvider = ({ children }) =>
{
    const { state: brandingCtx } = useContext(BrandingContext);
    const [overlayTemplates, setOverlayTemplates] = useState([]);
    const { propertyId, floorId = -1, buildingId = -1 } = brandingCtx || {};

    const loadingPool = useLoadingPool();

    const [filteredOverlayTemplates, setFilteredOverlayTemplates] = useState(overlayTemplates);

    const [overlayCreateModalToggle, setOverlayCreateModalToggle] = useState(false);
    const [overlayTemplateMode, setOverlayTemplateMode] = useState(null);
    const [selectedOverlayTemplateId, setSelectedOverlayTemplateId] = useState(null);
    const selectedOverlayTemplateInfo = useMemo(() => overlayTemplates?.find((template) => template._id === selectedOverlayTemplateId), [selectedOverlayTemplateId]);
    const { t: trans } = useTranslation();
    const [selectedFilter, setSelectedFilter] = useState({ searchFilter: null, sortFilter: null });
    const [mapOverlays, setMapOverlays] = useState(null);
    const [loadingId, setloadingId] = useState(null);

    useEffect(() => 
    {
        setloadingId(loadingPool.add());
        const savedFilter = localStorage.getItem("selectedFilter");
        if (savedFilter) 
        {
            // Set filter from localStorage if it exists
            setSelectedFilter((prev) => ({ ...prev, sortFilter: savedFilter }));
        }
        else 
        {
            // Default to "ascending" if no filter in localStorage
            changeFilter({ ...selectedFilter, sortFilter: "ascending" });
        }
    }, []);

    useEffect(() => 
    {
        let updatedTemplates = [...overlayTemplates];
    
        // Apply sorting first
        if (selectedFilter.sortFilter) 
        {
            updatedTemplates = sortTemplates(updatedTemplates, selectedFilter.sortFilter);
        }
        setOverlayTemplates(updatedTemplates); 
    
        // Apply search filter
        if (selectedFilter.searchFilter?.trim()) 
        {
            updatedTemplates = filterTemplates(updatedTemplates, selectedFilter.searchFilter);
        }
        setFilteredOverlayTemplates(updatedTemplates);
    }, [selectedFilter]);

    const overlayTemplatesGroupNames = useMemo(() =>
    {
        const overlayTemplateGroupNames = [...new Set(overlayTemplates
            .map((template) => template.group)
            .filter((group) => group !== null && group !== undefined && group !== "")
        )];
    
        return overlayTemplateGroupNames;

    }, [overlayTemplates]);

    const [zonesTemplates, setZonesTemplates] = useState(null);

    async function fetchOverlayTemplates(propertyId) 
    {
        try 
        {
            const { overlayTemplates } = await serverApi.getOverlayTemplates({ propertyId });

            overlayTemplates?.forEach((overlayTemplate) => 
            {
                if (overlayTemplate.dynamicOverlaySettings.enabled) 
                {
                    // add a temporary zoom level id which will help manage dynamic zoom settings in client side
                    overlayTemplate.dynamicOverlaySettings.zoomLevels?.forEach((zoomLevel) => 
                    {
                        zoomLevel.id = uuid();
                    });
                }
            });
            let updatedTemplates = [...overlayTemplates];
            if (selectedFilter.sortFilter) 
            {
                updatedTemplates = sortTemplates(updatedTemplates, selectedFilter.sortFilter);
            }
            setOverlayTemplates(updatedTemplates);  
            if (selectedFilter.searchFilter?.trim()) 
            {
                updatedTemplates = filterTemplates(updatedTemplates, selectedFilter.searchFilter);
            }
    
            setFilteredOverlayTemplates(updatedTemplates);     
        }
        catch (err) 
        {
            console.error(err);
        }
    }

    async function fetchZonesTemplates(propertyId)
    {
        try 
        {
            const response = await serverApi.getZonesTemplates({ propertyId });
            setZonesTemplates(response?.zonesTemplates);
            return response;
        }
        catch (err)
        {
            console.error(err);
        }
    }
    async function fetchMapOverlays()
    {
        try 
        {
            const response = await serverApi.getMapOverlays(propertyId, buildingId, floorId);
            setMapOverlays(response?.mapOverlays);
            return response;
        }
        catch (err)
        {
            console.error(err);
        }
    }

    const location = useLocation();
    const currentPath = location.pathname;

    useEffect(() => 
    {
        const fetchInitialData = async () => 
        {
            let loaderId = 0;
            if (selectedFilter.sortFilter && !loadingPool.loading) 
            {
                loaderId = loadingPool.add();
            }
    
            try 
            {
                if (propertyId) 
                {
                    await fetchMapOverlays();
                    await fetchZonesTemplates(propertyId);
                    await fetchOverlayTemplates(propertyId);
                }
            }
            catch (err) 
            {
                console.error(err);
            }
            finally 
            {
                if (loaderId) loadingPool.remove(loaderId); 
                else loadingPool.remove(loadingId);
            }
        };
    
        fetchInitialData();
    }, [propertyId, floorId, buildingId, currentPath]);

    async function createOverlaysTemplate(overlayTemplate) 
    {
        const loadingId = loadingPool.add();
        try 
        {
            cleanupTemplateData(overlayTemplate);
            const { createdOverlayTemplate, success } = await serverApi.createOverlayTemplate({ ...overlayTemplate, property: propertyId }, propertyId);
            setOverlayTemplates((prev) => ([...prev, createdOverlayTemplate]));
            if (success)
            {
                await fetchOverlayTemplates(propertyId);
                setOverlayCreateModalToggle(false);
                setOverlayTemplateMode(null);
                toast.success(<>
                    <Trans 
                        i18nKey="OverlaysTemplateSideBar.Create_Template_Success"
                        values={{ overlayTemplateName: truncateText(overlayTemplate.name.en, 22) }}
                    /></>);
            }
            else 
            { 
                toast.error(`${trans("OverlaysTemplateSideBar.Create_Template_Fail")}`);
            }
            
        }
        catch (err) 
        {
            console.error(err);
        }
        finally 
        {
            loadingPool.remove(loadingId);
        }
    }

    async function deleteOverlaysTemplate(overlayTemplateId) 
    {
        const loadingId = loadingPool.add();
        try 
        {
            const overlayTemplate = overlayTemplates.find((template) => template._id == overlayTemplateId);
            const { success } = await serverApi.deleteOverlayTemplate(overlayTemplateId);
            if (success)
            {
                await fetchOverlayTemplates(propertyId);
                setOverlayTemplateMode(null);
                toast.success(<>
                    <Trans 
                        i18nKey="OverlaysTemplateSideBar.Delete_Template_Success"
                        values={{ overlayTemplateName: truncateText(overlayTemplate.name.en, 22) }}
                    /></>);
            }
            else 
            { 
                toast.error(`${trans("OverlaysTemplateSideBar.Create_Template_Fail")}`);
            }
        }
        catch (err) 
        {
            console.error(err);
        }
        finally 
        {
            loadingPool.remove(loadingId);
        }
    }

    async function updateOverlaysTemplate({ overlayTemplateId, overlayTemplate }) 
    {
        const loadingId = loadingPool.add();
        try 
        {
            cleanupTemplateData(overlayTemplate);
            const { success } = await serverApi.updateOverlayTemplate(overlayTemplateId, overlayTemplate);
            if (success)
            {
                await fetchOverlayTemplates(propertyId);
                setOverlayCreateModalToggle(false);
                setOverlayTemplateMode(null);
                toast.success(<>
                    <Trans 
                        i18nKey="OverlaysTemplateSideBar.Update_Template_Success"
                        values={{ overlayTemplateName: truncateText(overlayTemplate.name.en, 22) }}
                    /></>);
                setSelectedOverlayTemplateId(null);
            }
            else 
            { 
                toast.error(`${trans("OverlaysTemplateSideBar.Create_Template_Fail")}`);
            }
        }
        catch (err) 
        {
            console.error(err);
        }
        finally 
        {
            loadingPool.remove(loadingId);
        }
    }

    const cleanupTemplateData = (overlayTemplate) =>
    {
        // if dynamic zoom styling is disabled, delete all the zoom objects
        if (!overlayTemplate.dynamicOverlaySettings?.enabled)
        {
            overlayTemplate.dynamicOverlaySettings.zoomLevels = [];
        }
        else
        {
            // delete id field from all the zoom objects since its used only for managing zoom levels in client side
            overlayTemplate.dynamicOverlaySettings.zoomLevels.forEach((zoomLevel) =>
            {
                delete zoomLevel.id;
            });
        }
    };

    function initiateOverlayTemplate()
    {
        setOverlayTemplateMode(OVERLAYS_TEMPLATE_MODES.CREATE);
        setOverlayCreateModalToggle(true);
    }

    function handleEditOverLayTemplate(overlayTemplateId) 
    {
        setOverlayTemplateMode(OVERLAYS_TEMPLATE_MODES.EDIT);
        setOverlayCreateModalToggle(true);
        handleSelectOverlayTemplateId(overlayTemplateId);
    }

    function handleViewOverLayTemplate(overlayTemplateId) 
    {
        setOverlayTemplateMode(OVERLAYS_TEMPLATE_MODES.VIEW);
        setOverlayCreateModalToggle(true);
        handleSelectOverlayTemplateId(overlayTemplateId);
    }

    function handleSelectOverlayTemplateId(overlaysTemplateId) 
    {
        setSelectedOverlayTemplateId(overlaysTemplateId);
    }

    function handleOverlayTemplateMode(mode) 
    {
        setOverlayTemplateMode(mode);
    }

    function changeFilter(filter) 
    {
        setSelectedFilter(filter);
        localStorage.setItem("selectedFilter", filter.sortFilter); // Save the filter to localStorage
    }

    const value = useMemo(() => ({
        propertyId,
        overlayTemplateMode,
        selectedFilter,
        zonesTemplates,
        overlayTemplates,
        mapOverlays,
        selectedOverlayTemplateId,
        selectedOverlayTemplateInfo,
        overlayCreateModalToggle,
        createOverlaysTemplate,
        updateOverlaysTemplate,
        deleteOverlaysTemplate,
        handleOverlayTemplateMode,
        handleSelectOverlayTemplateId,
        handleEditOverLayTemplate,
        handleViewOverLayTemplate,
        initiateOverlayTemplate,
        changeFilter,
        loadingPool,
        overlayTemplatesGroupNames,
        filteredOverlayTemplates
    }), [
        overlayTemplateMode, 
        overlayTemplates,
        mapOverlays, 
        selectedOverlayTemplateId, 
        zonesTemplates,
        selectedFilter,
        loadingPool.loading,
        filteredOverlayTemplates
    ]);

    return <OverlaysTemplateContext.Provider value={value}>
        <Loader active={loadingPool.loading} />
        {children}
    </OverlaysTemplateContext.Provider>;
};

export default OverlaysTemplateProvider;