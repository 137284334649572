import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import serverApi from "../_api/server.api";
import { getActiveOtherZones } from "../_utils/zones";
import { PublicMapDataContext } from "./PublicMapDataContext";

const ZonesContext = createContext(null);

export const useZonesContext = () => useContext(ZonesContext);

function ZonesProvider({ children }) 
{
    const { state, loadingPool } = useContext(PublicMapDataContext);
    const { propertyId, buildingId, floorId } = state || {};
    const [zones, setZones] = useState(null);
    const [zonesStatus, setZonesStatus] = useState("uninitialized");
    const [publishedTemplates, setPublishedTemplates] = useState(null);

    async function fetchZones(propertyId)
    {
        try 
        {
            setZonesStatus("loading");
            const response = await serverApi.getZones({ propertyId });
            setZones(response?.zones);
            setZonesStatus("ready");
            return response;
        }
        catch (err)
        {
            console.error(err);
            setZonesStatus("rejected");
        }
    }

    async function fetchPublishedTemplates(propertyId) 
    {
        try 
        {
            const { publishTemplates } = await serverApi.getPulishedTemplates({ propertyId });
            setPublishedTemplates(publishTemplates);       
        }
        catch (err) 
        {
            console.error(err);
        }
    }
    useEffect(() => 
    {
        if (propertyId) 
        {
            const loadingId = loadingPool.add();
            setZonesStatus("loading");
            Promise.all([fetchZones(propertyId), fetchPublishedTemplates(propertyId)])
                .then(() => 
                {
                    setZonesStatus("ready");
                })
                .catch((err) => 
                {
                    setZonesStatus("rejected");
                    console.error("One or both API calls failed", err);
                })
                .finally(() => 
                {
                    loadingPool.remove(loadingId);
                });
        }
    }, [propertyId]);

    const { visibleZones , otherZones } = useMemo(() => getActiveOtherZones({ zones, buildingId, floorId }), [zones, buildingId, floorId]);

    const value = useMemo(() => ({
        zonesStatus, 
        zones,
        visibleZones, 
        otherZones,
        publishedTemplates
    }), [zonesStatus, zones, visibleZones, otherZones, publishedTemplates]);

    return (
        <ZonesContext.Provider value={value}>
            {children}
        </ZonesContext.Provider>
    );
}

export default ZonesProvider;
