import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { GmsActionMode, TAB_MENU_ITEMS, useGmsContext } from "../GmsContext";
import { useInfiniteQuery, useQuery, useQueryClient } from "react-query";
import serverApi from "../../../../_api/server.api";
import { useGetConfig } from "../../../../_api-hooks/config";
import { ICON_COLORS, ICON_THEMES, ICON_TYPES, ICONS_LIMIT } from "./constant";
import lodash from "lodash";

const IconsManagementContext = createContext(null);

export const useIconsManagementContext = () => 
{
    if (!IconsManagementContext) return null;

    return useContext(IconsManagementContext);
};

const DEFAULT_ICONS_SEARCH_AND_FILTERS = {
    searchValue: "",
    filters: {
        color: ICON_COLORS.BLUE,
        theme: ICON_THEMES.LIGHT,
        type: ICON_TYPES.CATEGORIES,
    },
};
function IconsManagementProvider({ children, onAddIcon }) 
{
    const gmsContext = useGmsContext();
    const [iconsSearchAndFilters, setIconsSearchAndFilters] = useState(DEFAULT_ICONS_SEARCH_AND_FILTERS);
    const [selectedIconInfo, setSelectedIconInfo] = useState(null);
    const [disableIconTypeSelection, setDisableIconTypeSelection] = useState(false);
    const { filters, searchValue } = iconsSearchAndFilters;
    const { color, theme, type } = filters;
    const configQuery = useGetConfig();
    const queryClient = useQueryClient();
    const iconsQuery = useInfiniteQuery({
        queryKey: ["icons", iconsSearchAndFilters],
        queryFn: ({ pageParam = 1 }) => serverApi.getIcons({ color, theme, kind: type, searchText: searchValue, page: pageParam, limit: ICONS_LIMIT }),
        getNextPageParam: (lastPage, pages) => 
        {
            const remainingItems = lastPage.data.iconsCount - (pages.length * ICONS_LIMIT);
            const remainingPages = Math.ceil(remainingItems / ICONS_LIMIT);
            return remainingPages ? pages.length + 1 : undefined;
        },
        enabled: gmsContext.gmsActionMode === GmsActionMode.LINK_ICON || gmsContext.gmsActionMode === GmsActionMode.VIEW,
    });

    const icons = useMemo(() => iconsQuery.data?.pages?.flatMap((page) => page.data.icons) || [], [iconsQuery.data]);

    function handleTriggerLinkIcon(value) 
    {
        const { iconType, iconImage, iconTheme, disableIconTypeSelection } = value;
        gmsContext.handleTriggerLinkIcon();
        setSelectedIconInfo({
            iconImage
        });
        setIconsSearchAndFilters((prev) => ({
            ...prev, 
            filters: { ...prev.filters, type: iconType }
        }));
        setDisableIconTypeSelection(disableIconTypeSelection);
    }

    useEffect(() => 
    {
        if (selectedIconInfo && selectedIconInfo.iconImage && icons.length && !selectedIconInfo.color && !selectedIconInfo.theme)
        {
            
            const allIcons =  queryClient.getQueriesData(["icons"]);
            let iconInfo;
            allIcons?.forEach(([key, value]) => 
            {
                let pages = value?.pages;
                const icons = pages?.flatMap((page) => page.data.icons);
                iconInfo = icons?.find((icon) => icon.iconImage === selectedIconInfo.iconImage);
            });
            
            if (iconInfo)
            {
                setIconsSearchAndFilters((prev) => ({
                    ...prev, 
                    filters: { ...prev.filters, theme: iconInfo.theme, color: iconInfo.color }
                }));
            }
        }
    }, [selectedIconInfo, icons]);

    useEffect(() => 
    {
        handleChangeIconsSearchValue("");
    }, [gmsContext.activeIndex]);

    function handleChangeIconsFilters(value) 
    {
        setIconsSearchAndFilters((prev) => ({ ...prev, filters: { ...prev.filters, ...value } }));
    }

    function handleChangeIconsSearchValue(value) 
    {
        setIconsSearchAndFilters((prev) => ({ ...prev, searchValue: value }));
    }

    function handleSelectIcon(value) 
    {
        setSelectedIconInfo(lodash.cloneDeep(value));
    }

    function handleAddIcon()
    {
        onAddIcon(selectedIconInfo);
        gmsContext.handleCloseGms();
    }

    const contextValue = {
        config: configQuery.data,
        isConfigLoading: configQuery.isLoading,
        configQuery,
        iconsQuery,
        icons,
        isIconsLoading: iconsQuery.isLoading,
        iconsSearchAndFilters,
        selectedIconInfo,
        disableIconTypeSelection,
        handleAddIcon,
        handleTriggerLinkIcon,
        handleChangeIconsFilters,
        handleChangeIconsSearchValue,
        handleSelectIcon,
    };

    return (
        <IconsManagementContext.Provider value={contextValue}>
            {children}
        </IconsManagementContext.Provider>
    );
}

export default IconsManagementProvider;