import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "semantic-ui-react";
import { TextGroup } from "../../../common/TextGroup";
import MapOverlaysOpacitySelector from "../mapOverlaysOpacitySelector/MapOverlaysOpacitySelector";
import { getPercentage, getValueFromPercentage } from "../utils/mapOverlayUtils";
import { PROPERTIES } from "../../../../_constants/config";

export const DefaultOpacitySettings = ({ color, textColor, lineColor, fillOpacity, borderFillOpacity, textOpactiy, updateOpacitySettings, updateOverlay, disabled = false, selectedOverlayTemplateId }) =>
{
    const [enteredFillOpacity, setEnteredFillOpacity] = useState(undefined);
    const [enteredBorderFillOpacity, setEnteredBorderFillOpacity] = useState(undefined);
    const [enteredTextOpacity, setEnteredTextOpacity] = useState(undefined);

    const trans = useTranslation().t;

    useEffect(() =>
    {
        setEnteredFillOpacity(undefined);
    }, [fillOpacity]);

    useEffect(() =>
    {
        setEnteredBorderFillOpacity(undefined);
    }, [borderFillOpacity]);

    useEffect(() =>
    {
        setEnteredTextOpacity(undefined);
    }, [textOpactiy]);

    const getValidOpacityValue = (value) =>
    {
        let validOpacityValue = value;
        if (value < 0)
        {
            validOpacityValue = 0; 
        }
        else if (value > 100)
        {
            validOpacityValue = 100;
        }
        return validOpacityValue;
    };

    let displayFillOpacity = getPercentage(fillOpacity);

    if (enteredFillOpacity || enteredFillOpacity === 0)
    {
        displayFillOpacity = enteredFillOpacity;
    }

    let displayBorderFillOpacity = getPercentage(borderFillOpacity);

    if (enteredBorderFillOpacity || enteredBorderFillOpacity === 0)
    {
        displayBorderFillOpacity = enteredBorderFillOpacity;
    }

    let displayTextOpacity = getPercentage(textOpactiy);

    if (enteredTextOpacity || enteredTextOpacity === 0)
    {
        displayTextOpacity = enteredTextOpacity;
    }

    return (
        <>
            <div className={"tgOverlayConfig"}>
                <p>{trans("OpacitySettings.Fill_Color")}</p>
            </div>
            <div className="overlayTgRow">

                <div className="overlayTgColumn colorOptions">
                    <TextGroup flexible className="tgKiosk defaultInputLabel">
                        <Input 
                            disabled={!!selectedOverlayTemplateId}
                            className="colorPicker fillColorPicker" 
                            type="color" 
                            value={color}
                            onChange={(event, { value }) => updateOverlay("color", value)} />
                    </TextGroup>
                </div>

                <div className="overlayTgColumn defaultInput">
                    <div className="overlayTgRow defaultInputRow">
                        <div className="overlayTgColumn opacityInput">
                            <TextGroup className="tgOverlayConfig">
                                <Input
                                    type="text" 
                                    disabled={disabled || !!selectedOverlayTemplateId}
                                    pattern="[0-9]*"
                                    maxLength={PROPERTIES.MAX_OPACITY_LENGTH}
                                    value={displayFillOpacity} 
                                    onChange={(event, { value }) => setEnteredFillOpacity(+value)}
                                    onKeyPress={(event) =>
                                    {
                                        if (event.key === "Enter" && (enteredFillOpacity || enteredFillOpacity === 0))
                                        {
                                            let validOpacityValue = getValidOpacityValue(enteredFillOpacity);
                                            setEnteredFillOpacity(undefined);
                                            updateOpacitySettings("fillOpacity", getValueFromPercentage(validOpacityValue));
                                        } 
                                    }}
                                    onBlur={() =>
                                    {
                                        if (enteredFillOpacity || enteredFillOpacity === 0)
                                        {
                                            let validOpacityValue = getValidOpacityValue(enteredFillOpacity);
                                            setEnteredFillOpacity(undefined);
                                            updateOpacitySettings("fillOpacity", getValueFromPercentage(validOpacityValue));
                                        }
                                    }}
                                />
                            </TextGroup>
                        </div>
                        <div className="overlayTgColumn opacityInput percentSymbol">
                            <p className="defaultSettingsSymbol">%</p>
                        </div>
                    </div>
                </div>

                <div className="overlayTgColumn defaultOpacitySettings">
                    <MapOverlaysOpacitySelector 
                        backgroundColor={color} 
                        opacity={fillOpacity}
                        disabled={disabled || !!selectedOverlayTemplateId}
                        onOpacityChange={(opacity) => updateOpacitySettings("fillOpacity", opacity)} />
                </div>
            </div>

            <div className={"tgOverlayConfig"}>
                <p>{trans("OpacitySettings.Border_Color")}</p>
            </div>

            <div className="overlayTgRow">

                <div className="overlayTgColumn colorOptions">
                    <TextGroup flexible className="tgKiosk defaultInputLabel">
                        <Input 
                            disabled={!!selectedOverlayTemplateId}
                            className="colorPicker borderColorPicker" 
                            type="color" 
                            value={lineColor}
                            onChange={(event, { value }) => updateOverlay("lineColor", value)} />
                    </TextGroup>
                </div>

                <div className="overlayTgColumn defaultInput">
                    <div className="overlayTgRow defaultInputRow">
                        <div className="overlayTgColumn opacityInput">
                            <TextGroup className="tgOverlayConfig">
                                <Input
                                    type="text"
                                    pattern="[0-9]*"
                                    disabled={disabled || !!selectedOverlayTemplateId}
                                    value={displayBorderFillOpacity}
                                    onChange={(event, { value }) => setEnteredBorderFillOpacity(+value)}
                                    onKeyPress={(event) =>
                                    {
                                        if (event.key === "Enter" && (enteredBorderFillOpacity || enteredBorderFillOpacity === 0))
                                        {
                                            let validOpacityValue = getValidOpacityValue(enteredBorderFillOpacity);
                                            setEnteredBorderFillOpacity(undefined);
                                            updateOpacitySettings("borderFillOpacity", getValueFromPercentage(validOpacityValue));
                                        } 
                                    }}
                                    onBlur={() =>
                                    {
                                        if (enteredBorderFillOpacity || enteredBorderFillOpacity === 0)
                                        {
                                            let validOpacityValue = getValidOpacityValue(enteredBorderFillOpacity);
                                            setEnteredBorderFillOpacity(undefined);
                                            updateOpacitySettings("borderFillOpacity", getValueFromPercentage(validOpacityValue));
                                        }
                                    }}
                                />
                            </TextGroup>
                        </div>
                        <div className="overlayTgColumn opacityInput percentSymbol">
                            <p className="defaultSettingsSymbol">%</p>
                        </div>
                    </div>
                </div>

                <div className="overlayTgColumn defaultOpacitySettings">
                    <MapOverlaysOpacitySelector 
                        backgroundColor={lineColor} 
                        opacity={borderFillOpacity}
                        disabled={disabled || !!selectedOverlayTemplateId}
                        onOpacityChange={(opacity) => updateOpacitySettings("borderFillOpacity", opacity)} />
                </div>
            </div>

            <div className={"tgOverlayConfig"}>
                <p>{trans("OpacitySettings.Text_Color")}</p>
            </div>

            <div className="overlayTgRow" style={{ marginBottom: "15px" }}>
                <div className="overlayTgColumn colorOptions">
                    <TextGroup flexible className="tgKiosk defaultInputLabel">
                        <Input
                            className="colorPicker textColorPicker"
                            type="color"
                            value={textColor}
                            disabled={disabled || !!selectedOverlayTemplateId}
                            onChange={(event, { value }) => updateOverlay("textColor", value)} />
                    </TextGroup>
                </div>
                <div className="overlayTgColumn defaultInput">
                    <div className="overlayTgRow defaultInputRow">
                        <div className="overlayTgColumn opacityInput">
                            <TextGroup className="tgOverlayConfig">
                                <Input
                                    type="text"
                                    pattern="[0-9]*"
                                    disabled={disabled || !!selectedOverlayTemplateId}
                                    value={displayTextOpacity}
                                    onChange={(event, { value }) => setEnteredTextOpacity(+value)}
                                    onKeyPress={(event) => 
                                    {
                                        if (event.key === "Enter" && (enteredTextOpacity || enteredTextOpacity === 0)) 
                                        {
                                            let validOpacityValue = getValidOpacityValue(enteredTextOpacity);
                                            setEnteredTextOpacity(undefined);
                                            updateOpacitySettings("textOpacity", getValueFromPercentage(validOpacityValue));
                                        }
                                    }}
                                    onBlur={() => 
                                    {
                                        if (enteredTextOpacity || enteredTextOpacity === 0) 
                                        {
                                            let validOpacityValue = getValidOpacityValue(enteredTextOpacity);
                                            setEnteredTextOpacity(undefined);
                                            updateOpacitySettings("textOpacity", getValueFromPercentage(validOpacityValue));
                                        }
                                    }}
                                />
                            </TextGroup>
                        </div>
                        <div className="overlayTgColumn opacityInput percentSymbol">
                            <p className="defaultSettingsSymbol">%</p>
                        </div>
                    </div>
                </div>
                <div className="overlayTgColumn defaultOpacitySettings">
                    <MapOverlaysOpacitySelector 
                        backgroundColor={textColor} 
                        opacity={textOpactiy}
                        disabled={disabled || !!selectedOverlayTemplateId}
                        onOpacityChange={(opacity) => updateOpacitySettings("textOpacity", opacity)} />
                </div>
            </div>
        </>);
};

export default DefaultOpacitySettings;