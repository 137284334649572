import { getSelectedEntity } from "mapsted.maps/mapFunctions/features";
import React, { useContext } from "react";

const defaultValue = {

};

const BrandingContext = React.createContext(defaultValue);

export default BrandingContext;

export function useCurrentProperty()
{
    const brandingContext = useContext(BrandingContext);
    if (brandingContext?.state?.propertyId && brandingContext?.state?.properties)
    {
        return brandingContext.state.properties[brandingContext.state.propertyId];
    }
    return undefined;
}

export function useSelectedBuilding()
{
    const brandingContext = useContext(BrandingContext);
    const { propertyId, buildingId, allProperties } = brandingContext?.state;
    const building = allProperties?.[propertyId]?.allBuildings?.[buildingId];

    return building;
}

export function useSelectedFloor()
{
    const brandingContext = useContext(BrandingContext);
    const { floorId } = brandingContext?.state;
    const building = useSelectedBuilding();

    return building?.floors?.find((floor) => floor?._id === floorId);
}

export function useSelectedEntity()
{
    const brandingContext = useContext(BrandingContext);
    const { entities } = brandingContext?.state?.mapData;
    const { selectedEntityId } = getSelectedEntity(brandingContext?.state?.selectedEntities || {});

    if (!selectedEntityId || !entities)
    {
        return null;
    }
    return entities[selectedEntityId];
}

export function useIsEntitySelected(entityId)
{
    const brandingContext = useContext(BrandingContext);
    return !!brandingContext?.state?.selectedEntities[entityId];
}
