import React from "react";
import "./ImageBox.css";
import { ButtonIcon } from "../../../../../common/ButtonIcon";
import ImageComponent from "../../../ImageComponent/ImageComponent";
import { filerUrl } from "../../../../../../_utils/utils";
import { GmsActionMode, useGmsContext } from "../../../GmsContext";
import { DELETE_IMAGE_STRATEGY_ACTIONS } from "../../../constant";
import { useImageEditContext } from "../../contexts/ImageEditContext";
import { useImageDeleteContext } from "../../contexts/ImageDeleteContext";
import { useImagesBaseContext } from "../../contexts/ImageBaseContext";
import { useImageCopyContext } from "../../contexts/ImageCopyContext";

function ImageBox({ filerId, name, _id: id, usedIn }) 
{
    const imageDeleteContext = useImageDeleteContext();
    const imagesBaseContext = useImagesBaseContext();
    const imageEditContext = useImageEditContext();
    const imageCopyContext = useImageCopyContext();
    const { gmsActionMode } = useGmsContext();

    const canSelectImageForLinking = (gmsActionMode === GmsActionMode.LINK_COVER_IMAGES
        || gmsActionMode === GmsActionMode.LINK_ICON_IMAGE
        || imageDeleteContext.selectedDeleteImageStrategy === DELETE_IMAGE_STRATEGY_ACTIONS.CHOOSE_NEW_IMAGE_AND_DELETE_OLD_ONE);
    
    const disableImageForLinking = (imageDeleteContext.selectedDeleteImageStrategy === DELETE_IMAGE_STRATEGY_ACTIONS.CHOOSE_NEW_IMAGE_AND_DELETE_OLD_ONE 
        && imagesBaseContext.selectedImageInfo?._id === id);

    return (
        <div
            onClick={() => canSelectImageForLinking ? imagesBaseContext.handleSelectImageForLinking(id) : null}
            className={`image-box ${disableImageForLinking ? "disable" : ""}`}
        >
            <ImageComponent
                selected={imagesBaseContext.imagesSelectedForLinking.includes(id)}
                imgSrc={filerUrl(filerId, 1800)}
                alt={name}
            />
            {
                <div className="image-box-actions-bx">
                    {gmsActionMode === GmsActionMode.VIEW && (
                        <ButtonIcon
                            onClick={(e) => 
                            {
                                e.stopPropagation();
                                imageEditContext.handleTriggerEditImage(id);
                            }}
                            icon="copy2-white"
                        ></ButtonIcon>
                    )}
                    {gmsActionMode === GmsActionMode.VIEW && <ButtonIcon                     
                        onClick={(e) => 
                        {
                            e.stopPropagation();
                            imageCopyContext.handleTriggerCopyImage(id);
                        }}
                        className={"image-box-copy-icon"} icon="copy-white"></ButtonIcon>}
                        
                    <ButtonIcon
                        onClick={(e) => 
                        {
                            e.stopPropagation();
                            imagesBaseContext.handleViewImage(id);
                        }}
                        icon="eye"
                    ></ButtonIcon>
                    {gmsActionMode === GmsActionMode.VIEW && (
                        <ButtonIcon
                            onClick={(e) => 
                            {
                                e.stopPropagation();
                                imageDeleteContext.handleTriggerDeleteImage(id);
                            }}
                            icon="trash-white"
                        ></ButtonIcon>
                    )}
                </div>
            }
           
        </div>
    );
}

export default ImageBox;
