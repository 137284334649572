
import React, { useState, useMemo, useEffect, useCallback, } from "react";
import { isTransitionOnCurrentMap } from "mapsted.maps/utils/georeference.utils";
import { NON_REVERSIBLE_DIRECTION, } from "../../../_constants/mapEditor";
import { Button, Checkbox, Dropdown } from "semantic-ui-react";
import { FLOOR_TO_FLOOR_TRANSITION_TYPES, PROPERTY_LEVEL_TRANSITION_TYPES, SAME_LEVEL_TRANSITION_TYPES, TransitionTypes } from "mapsted.maps/utils/entityTypes";
import { getNodeTextNameFromFeature } from "mapsted.maps/mapFunctions/cmsVectorLayers";
import { deepCopy } from "mapsted.utils/objects";
import { MapModalActionButtonsContainer, MapModalCheckBoxContainer, MapModalContainer, MapModalDropdown, MapModalTitle } from "./MapModalTemplate";
import { TextGroup } from "../../common/TextGroup";
import { DropdownForm } from "../../common/DropdownForm";
import { ButtonIcon } from "../../common/ButtonIcon";

export const CreateEditTransitionModal = ({
    selectedNode,
    selectedNodeGeoreference,
    selectedTransition,
    navIds,
    georeferenceNavIds,
    isEdit,
    onCreateTransition,
    onEditTransition,
    onDeleteTransition 
}) =>
{
    const DEFAULT_STATE = {
        accessible: true,
        reversible: true,
        restricted: false,
        transitionType: 0,
        route: null,
        nonReversibleDirectionForCreation: NON_REVERSIBLE_DIRECTION.MAIN_TO_GEOREFERENCE
    };

    const [transitionOptions, setTransitionOptions] = useState(DEFAULT_STATE);

    useEffect(() =>
    {
        if (selectedTransition)
        {
            const { accessible, reversible, restricted, transitionType, route } = deepCopy(selectedTransition);
            {
                setTransitionOptions({
                    accessible, reversible, restricted, transitionType, route
                });
            }
        }
        else
        {
            setTransitionOptions(DEFAULT_STATE);
        }
    }, [selectedTransition]);

    const transitionTypeOptionsMemo = useMemo(() =>
    {
        let availableTransitionTypes = FLOOR_TO_FLOOR_TRANSITION_TYPES;

        if (!georeferenceNavIds || navIds.floorId === georeferenceNavIds.floorId)
        {
            availableTransitionTypes = SAME_LEVEL_TRANSITION_TYPES;
        }
        else if (navIds.buildingId === -1 || georeferenceNavIds.buildingId === -1)
        {
            availableTransitionTypes = PROPERTY_LEVEL_TRANSITION_TYPES;
        }

        let transitionOptions = availableTransitionTypes.map((transitionTypeKey) => ({
            key: Number(transitionTypeKey),
            value: Number(transitionTypeKey),
            text: TransitionTypes[transitionTypeKey].name,
        }));

        return transitionOptions;
    }, [navIds, georeferenceNavIds]);

    /**
     * Finds the direction of the transition if not reversible based on route order
     */
    const nonReversibleDirectionMemo = useMemo(() =>
    {
        if (!transitionOptions?.route || !selectedTransition)
        {
            return transitionOptions.nonReversibleDirectionForCreation;
        }

        const { routeNodesOnMapArray } = isTransitionOnCurrentMap({ ...selectedTransition, route: transitionOptions.route }, navIds, georeferenceNavIds);

        if (routeNodesOnMapArray[0].isMain)
        {
            return NON_REVERSIBLE_DIRECTION.MAIN_TO_GEOREFERENCE;
        }
        else
        {
            return NON_REVERSIBLE_DIRECTION.GEOREFERENCE_TO_MAIN;
        }

    }, [selectedTransition, navIds, transitionOptions, georeferenceNavIds, selectedNode]);

    /**
     * Checks for any changes to provide the option to edit the transition
     */
    const isEditDisabledMemo = useMemo(() =>
    {
        if (!selectedTransition)
        {
            return true;
        }

        let isDisabled = true;
        Object.keys(transitionOptions).forEach((optionKey) =>
        {
            if (optionKey !== "nonReversibleDirectionForCreation")
            {
                if (optionKey === "route" && transitionOptions.route)
                {
                    if (transitionOptions.route[0].node !== selectedTransition.route[0].node)
                    {
                        isDisabled = false;
                    }
                }
                else if (transitionOptions[optionKey] !== selectedTransition[optionKey])
                {
                    isDisabled = false;
                }
            }
        });
        return isDisabled;
    }, [selectedTransition, transitionOptions]);

    // #region ACTION HANDLERS 
    const handleCreateTransition = useCallback(() =>
    {
        onCreateTransition(transitionOptions);
        setTransitionOptions(DEFAULT_STATE);

    }, [transitionOptions, setTransitionOptions, onCreateTransition]);

    const handleEditTransition = useCallback(() =>
    {
        onEditTransition(selectedTransition, transitionOptions);
    }, [transitionOptions, setTransitionOptions, selectedTransition]);

    const handleDeleteTransition = useCallback(() =>
    {
        onDeleteTransition(selectedTransition,);
    }, [transitionOptions, selectedTransition]);
    //#endregion   

    //#region state handler callbacks 
    const handleValueChange = useCallback((e, { name, value }) =>
    {
        if (!transitionOptions)
        {
            return;
        }

        let newTransitionOptions = { ...transitionOptions };

        if (value) // dropdown
        {
            newTransitionOptions[name] = value;
        }
        else // checkbox
        {
            newTransitionOptions[name] = !newTransitionOptions[name];
        }

        setTransitionOptions(newTransitionOptions);
    }, [transitionOptions, setTransitionOptions]);

    /**
     * Updates route order for edit, updates direction for new routes
     */
    const handleReversibleDirectionChange = useCallback(() =>
    {
        if (!transitionOptions)
        {
            return;
        }

        let newTransitionOptions = { ...transitionOptions };

        if (!newTransitionOptions.route)
        {
            // adjust boolean for creation
            if (newTransitionOptions.nonReversibleDirectionForCreation === NON_REVERSIBLE_DIRECTION.GEOREFERENCE_TO_MAIN)
            {
                newTransitionOptions.nonReversibleDirectionForCreation = NON_REVERSIBLE_DIRECTION.MAIN_TO_GEOREFERENCE;
            }
            else
            {
                newTransitionOptions.nonReversibleDirectionForCreation = NON_REVERSIBLE_DIRECTION.GEOREFERENCE_TO_MAIN;
            }
        }
        else
        {
            newTransitionOptions.route.reverse();
        }

        setTransitionOptions(newTransitionOptions);
    }, [transitionOptions, setTransitionOptions]);
    //#endregion

    //#region RENDER FUNCTIONS 
    /**
     * Render action buttons depending on creation or edit
     */
    const renderActionButtons = useCallback(() =>
    {
        if (isEdit)
        {
            return (
                <MapModalActionButtonsContainer>
                    <Button
                        primary
                        color="red" 
                        floated="left"
                        content="Delete Transition"
                        onClick={handleDeleteTransition} />
                    <Button 
                        primary
                        color="green" 
                        floated="right"
                        content="Edit Transition"
                        disabled={isEditDisabledMemo}
                        onClick={handleEditTransition} />
                    
                </MapModalActionButtonsContainer>
            );
        }
        else
        {
            return (
                <div className="transition-box-actions">
                    <Button 
                        primary
                        floated="right"
                        color="green"
                        content="Create Transition" 
                        disabled={!selectedNode || !selectedNodeGeoreference}
                        onClick={handleCreateTransition} />
                </div>
            );
        }
    }, [isEdit, handleCreateTransition, handleEditTransition, handleDeleteTransition, isEditDisabledMemo]);

    /**
     * render non reversible direction toggle when transition is not reversible
     */
    const renderNonReversibleDirectionToggle = useCallback(() =>
    {
        let icon = `arrow ${nonReversibleDirectionMemo}`;

        if (!transitionOptions.reversible)
        {
            return (
                <ButtonIcon className="directionButton"
                    primary
                    icon={icon}
                    onClick={handleReversibleDirectionChange} />
            );
        }
    }, [  transitionOptions, nonReversibleDirectionMemo, handleReversibleDirectionChange]);

    /**
    * render non reversible direction toggle when transition is not reversible
    */
    const renderRouteOrder = useCallback(() =>
    {
        if (!transitionOptions?.route && (!selectedNode || !selectedNodeGeoreference))
        {
            return;
        }

        if (!transitionOptions.reversible)
        {
            let route = transitionOptions?.route;
            if (!route)
            {
                let nodeIdMain = {
                    nodeId: selectedNode.get("nodeId") || selectedNode.get("_id")

                };
                let nodeIdGeoreference = {
                    nodeId: selectedNodeGeoreference.get("nodeId") || selectedNodeGeoreference.get("_id")
                };

                if (nonReversibleDirectionMemo === NON_REVERSIBLE_DIRECTION.MAIN_TO_GEOREFERENCE)
                {
                    route = [nodeIdMain, nodeIdGeoreference];
                }
                else
                {
                    route = [nodeIdGeoreference, nodeIdMain];
                }
            }

            route = route.filter((r) => !(r?.node === undefined && r?.nodeId === undefined) );

            console.log({route});
            return (
                <TextGroup heading="Route Order">
                    
                    <div className="routeOrderGroup">
                        {
                            route.map((routeNode) => (
                                <RouteOrderItem 
                                    key={`${routeNode.nodeId}, ${routeNode.node}`} 
                                    content={`Node - ${routeNode.nodeId || routeNode.node}`} />
                            ))
                        }
                        <ButtonIcon 
                            icon="shuffle"
                            onClick={handleReversibleDirectionChange} 
                        />
                    </div>
                </TextGroup>
            );
        }
    }, [selectedNode, selectedNodeGeoreference, selectedTransition, transitionOptions, handleReversibleDirectionChange, nonReversibleDirectionMemo]);

    /**
     * render transition id 
     */
    const transitionBoxTitle = useMemo(() =>
    {
        let transitionBoxTitle = "New Transition";
        if (selectedTransition)
        {
            let transitionId = selectedTransition?.transitionId;
            if (transitionId === -1)
            {
                transitionId = selectedTransition?._id;
            }

            transitionBoxTitle = `Transition-${transitionId}`;
        }

        return transitionBoxTitle;
    }, [transitionOptions, selectedTransition]);

    //#endregion

    return (
        <MapModalContainer className="createTransitionPopupbox">
            <TextGroup heading={transitionBoxTitle} className="transitionCheckboxRow">
                <Checkbox
                    name="accessible"
                    checked={transitionOptions.accessible}
                    label="Accessible"
                    className="checkboxPrimary"
                    onChange={handleValueChange} />

                <Checkbox
                    name="reversible"
                    checked={transitionOptions.reversible}
                    label="Reversible"
                    className="checkboxPrimary"
                    onChange={handleValueChange} />

                <Checkbox
                    name="restricted"
                    checked={transitionOptions.restricted}
                    label="Restricted"
                    className="checkboxPrimary"
                    onChange={handleValueChange} />
            </TextGroup>

            <TextGroup heading="Transition Type">
                <DropdownForm 
                    name="transitionType"
                    options={transitionTypeOptionsMemo}
                    value={transitionOptions.transitionType}
                    onChange={handleValueChange} /> 
            </TextGroup>


            {
                renderRouteOrder()
            }
            

            {
                renderActionButtons()
            }
            


            {/* <MapModalDropdown
                name="transitionType"
                options={transitionTypeOptionsMemo}
                value={transitionOptions.transitionType}
                onChange={handleValueChange} /> */}

            {/* {renderNonReversibleDirectionToggle()} */}

            {/* {
                renderRouteOrder()
            }

            {
                renderActionButtons()
            } */}

        </MapModalContainer>
    );
};

const RouteOrderItem = ({ content }) => <div className="routeOrderItem">{content}</div>;
