import React, { useState, useContext, useCallback } from "react";
import BrandingContext from "../../../store/BrandingContext";
import { Button } from "semantic-ui-react";
import { ModalLayout } from "../../popups/ModalLayout";
import "./VacantModal.css";
import { Trans, useTranslation } from "react-i18next";
import { TransferModal } from "./TransferModal";

export const VacantModal = () => 
{
    const brandingContext = useContext(BrandingContext);
    const [isOpen, setIsOpen] = useState(false);
    const [isTransferModalOpen, setTransferModalOpen] = useState(false); // Track transfer modal state
    const trans = useTranslation().t;

    const handleVacantEntity = useCallback(() => 
    {
        let  toastMessage = <Trans i18nKey="VacantModal.Data_Erased_Successfully" components={{ bolded: <b /> }} />;
        brandingContext.handleVacantEntity(toastMessage);
        setIsOpen(false);
    }, [brandingContext, trans]);

    const openTransferModal = () => 
    {
        setTransferModalOpen(true);
        setIsOpen(false); 
    };

    return (
        <>
            <ModalLayout
                open={isOpen}
                trigger={
                    <Button
                        content={trans("VacantModal.Vacant")}
                        className="vacantButton"
                        onClick={() => setIsOpen(true)}
                    />
                }
                className="deleteGroupModal vacantModal"
                heading={
                    <div className="heading">
                        <img src="/img/vacant-warning.svg" alt="expand" width="50px" />
                        <b>{trans("VacantModal.Are_you_sure_you_want_to_mark_this_room_as_vacant")}</b>
                    </div>
                }
                closeOnDimmerClick
                onClose={() => setIsOpen(false)}
                actions={
                    <>
                        <Button
                            className="eraseButton"
                            content={trans("VacantModal.Erase_Data")}
                            onClick={handleVacantEntity}
                        />
                        <Button
                            className="transferButton"
                            content={trans("VacantModal.Transfer_Data")}
                            onClick={openTransferModal}
                        />
                    </>
                }
            >
                <p className="pModal">
                    {trans(
                        "VacantModal.You_can_either_delete_the_data_in_this_room_or_transfer_the_information_to_another_room"
                    )}
                </p>
            </ModalLayout>

            {/* TransferModal Component */}
            { isTransferModalOpen && <TransferModal open={isTransferModalOpen} onClose={() => setTransferModalOpen(false)} />} 
        </>
    );
};
