import React, {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import BrandingContext from "./BrandingContext";
import {
    generateFallbackMessage,
    getCategoriesList,
    getColumnIdsFromStorage,
    getLanguageCodes,
    getListColumnDefs,
    getListViewAdvancedFilterationModel,
    getListViewBasicFilterationModel,
    getProcessedEntities,
    getSubCategoryList,
    getUpdatePayload,
    setColumnIdsToStorage,
} from "../_utils/listView";
import {
    COLUMN_IDS,
    LIST_MODES,
} from "../_constants/listView";
import { DEFAULT_LANGUAGE_CODE } from "mapsted.maps/utils/map.constants";
import { CategoryContext } from "./CategoryProvider";
import { useLoadingPool } from "../_utils/hooks";
import brandingApi from "../_api/branding.api";
import { Loader } from "../components/elements/loader";
import { useTranslation } from "react-i18next";

const ListViewContext = createContext(null);

export const useListViewContext = () => useContext(ListViewContext);

function ListViewProvider({ children }) 
{
    const { state, updateAllEntityData } = useContext(BrandingContext);
    const bCtx = useContext(BrandingContext);
    const categoryContext = useContext(CategoryContext);
    const { categoryFlatMap: categories, getParentOf } = categoryContext;
    const { mapData, propertyId, buildingId, properties } = state || {};
    const gridRef = useRef(null);
    // inject any default vals when component is being initialized
    const defaultFilterationModel = {
        basicFilteration: getListViewBasicFilterationModel(),
        advancedFilteration: getListViewAdvancedFilterationModel(),
    };
    const [filterationValues, setFilterationValues] = useState(defaultFilterationModel);
    const [editedRows, setEditedRows] = useState([]);
    const [columnsVisible, setColumnsVisible] = useState([]);
    const [isGridReady, setIsGridReady] = useState(false);
    const [listMode, setListMode] = useState(LIST_MODES.VIEW);
    const [confirmationModalInfo, setConfirmationModalInfo] = useState(null);
    const [showMultiInputEditor, setShowMultiInputEditor] = useState(false);
    const [showAdvancedFilterationSidebar, setShowAdvancedFilterationSidebar] = useState(false);
    const [selectedEntityId, setSelectedEntityId] = useState(null);
    const [isColumnEditing, setIsColumnEditing] = useState(false);
    const trans = useTranslation().t;

    const loadingPool = useLoadingPool();

    useEffect(() =>
    {
        // set the visible columns based on cached data in storage
        const columnIds = getColumnIdsFromStorage();
        setColumnsVisible(columnIds);
    }, []);

    useEffect(() =>
    {
        setFilterationValues(defaultFilterationModel);
        setListMode(LIST_MODES.VIEW);
    }, [propertyId, buildingId]);

    const entities = useMemo(
        () => Object.values(mapData?.entities || {})
            .filter((entity) => entity?.entityLabel?.longName?.[DEFAULT_LANGUAGE_CODE]&& entity.entityId !== -1),
        [mapData]
    );
    const languageCodes = useMemo(
        () => getLanguageCodes(mapData?.entities, propertyId, buildingId, properties),
        [mapData?.entities, propertyId, buildingId, properties]
    );

    const { basicFilteration, advancedFilteration } = filterationValues;

    let categoryList = useMemo(
        () => getCategoriesList({ categories, getParentOf }),
        [categories]
    );

    const columnDefs = useMemo(
        () => 
        {
        
            let navPropertyId, navBuildingId;

            if (propertyId && properties)
            {
                navPropertyId = properties[propertyId]?.propertyId;

                if (buildingId)
                {
                    navBuildingId = properties[propertyId]?.buildings?.[buildingId]?.buildingId;
                }
            }

            return getListColumnDefs({
                listMode,
                languageCode: basicFilteration.languageCode,
                columnsVisible,
                categories,
                categoryList,
                propertyId: navPropertyId,
                buildingId: navBuildingId,
                trans
            });
        },
        [basicFilteration.languageCode, columnsVisible, listMode, propertyId, buildingId, properties, categories, categoryList, trans]
    );

    const processedEntities = useMemo(
        () => getProcessedEntities({ entities, basicFilteration, advancedFilteration }),
        [entities, basicFilteration, advancedFilteration, listMode, trans]
    );

    const fallbackMessage = useMemo(() => generateFallbackMessage({
        basicFilteration, advancedFilteration, entities: processedEntities, trans
    }), [basicFilteration, advancedFilteration, processedEntities]);


    function handlePageChange(activePage) 
    {
        gridRef.current?.api?.paginationGoToPage(activePage);
    }

    const handleUpdateBasicFilteration = (value) => 
    {
        setFilterationValues((prev) => ({
            ...prev,
            basicFilteration: { ...prev.basicFilteration, ...value },
        }));
    };

    const handleUpdateAdvancedFilteration = (value) => 
    {
        setFilterationValues((prev) => ({
            ...prev,
            advancedFilteration: { ...prev.advancedFilteration, ...value },
        }));
    };

    async function handleSaveBulkEntities() 
    {        
        const editedData = editedRows.filter((rowId) => gridRef.current.api.getRowNode(rowId)).map(
            (rowId) => gridRef.current.api.getRowNode(rowId)?.data
        );

        let payload = [];
        editedData.forEach((data) =>
        {
            payload.push(getUpdatePayload(data));
        });

        let loaderKey = loadingPool.add();

        try
        {
            const result = await brandingApi.updateEntitesAndLabel(payload);
            
            if (result.success)
            {
                await updateAllEntityData();
                handleUpdateListMode(LIST_MODES.VIEW);
            }
            else
            {
                // TODO: show error message toast
            }
        }
        catch (err)
        {
            console.log("Error while performing bulk update", err);
        }
        finally
        {
            loadingPool.remove(loaderKey);
        }
    }

    function handleShowConfirmationModalInfo(value) 
    {
        setConfirmationModalInfo((prev) => ({ ...prev, ...value }));
    }

    function handleCloseConfirmationModalInfo() 
    {
        setConfirmationModalInfo(null);
    }

    function handleEditedRows(id) 
    {
        if (editedRows.includes(id)) return;
        setEditedRows((prev) => [...prev, id]);
    }

    const handleResetBasicFilteration = () => 
    {
        setFilterationValues((prev) => ({
            ...prev,
            basicFilteration: defaultFilterationModel.basicFilteration,
        }));
    };

    const handleResetAdvancedFilteration = () => 
    {
        setFilterationValues((prev) => ({
            ...prev,
            advancedFilteration: defaultFilterationModel.advancedFilteration,
        }));
    };

    function handleGridReady() 
    {
        setIsGridReady(true);
    }

    function handleColumnVisibility(values) 
    {
        setColumnIdsToStorage(values);
        setColumnsVisible(values);
    }

    function handleUpdateListMode(value) 
    {
        setListMode(value);
    }

    function handleEntitySelection(id) 
    {
        setSelectedEntityId(id);
    }

    function handleShowAdvanceFilterationSidebar() 
    {
        setShowAdvancedFilterationSidebar((prev) => !prev);
    }

    function handleShowMultiInputEditor() 
    {
        setShowMultiInputEditor((prev) => !prev);
    }

    function handleCellClicked(params) 
    {
        const { colDef, data } = params;

        // block save when column is being edited
        if (
            listMode === LIST_MODES.BULK_EDIT
            && ((typeof colDef.editable === "boolean" && colDef.editable)
            || (typeof colDef.editable === "function" && colDef.editable(params)))
        ) 
        {
            setIsColumnEditing(true);
        }

        if (colDef.id === COLUMN_IDS.EDIT) 
        {
            handleEditClick(data._id, data.feature);
        }

        if (listMode === LIST_MODES.BULK_EDIT && colDef?.externalEditing) 
        {
            if (colDef.editable 
                && typeof colDef.editable === "function" 
                && !colDef.editable(params))
            {
                return;
            }

            handleShowMultiInputEditor();
        }
    }

    function handleEditClick(_id, feature) 
    {
        bCtx.initiateSingleEntityEdit(_id, feature);
    }

    const value = useMemo(
        () => ({
            basicFilteration,
            advancedFilteration,
            rawEntities: entities,
            entities: processedEntities,
            selectedEntityId: selectedEntityId,
            gridRef,
            listMode,
            languageCodes,
            columnDefs,
            columnsVisible,
            confirmationModalInfo,
            showMultiInputEditor,
            editedRows,
            fallbackMessage,
            showAdvancedFilterationSidebar,
            isColumnEditing,
            setIsColumnEditing,
            handleUpdateAdvancedFilteration,
            handleUpdateBasicFilteration,
            handleShowMultiInputEditor,
            handleResetBasicFilteration,
            handleResetAdvancedFilteration,
            handleCloseConfirmationModalInfo,
            handleShowConfirmationModalInfo,
            handleSaveBulkEntities,
            handleEditedRows,
            handleEntitySelection,
            handleUpdateListMode,
            handleColumnVisibility,
            handleGridReady,
            handleShowAdvanceFilterationSidebar,
            handlePageChange,
            handleCellClicked,
        }),
        [
            filterationValues,
            entities,
            processedEntities,
            listMode,
            columnDefs,
            editedRows,
            columnsVisible,
            selectedEntityId,
            isColumnEditing,
            confirmationModalInfo,
            showMultiInputEditor,
            showAdvancedFilterationSidebar,
            languageCodes,
            fallbackMessage
        ]
    );

    return (
        <ListViewContext.Provider value={value}>
            <Loader active={loadingPool.loading}/>
            {children}
        </ListViewContext.Provider>
    );
}

export default ListViewProvider;
