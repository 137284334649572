import React from "react";
import ZoomMap, { ZOOM_SELECT_MODES } from "../../branding/map/ZoomMap";
import "./ZoomScreen.css";

const ZoomScreen = ({ zoomMode, onChangeZoomMode, onClose, zoomData, handleSaveZoom, settings, isBuilding,rotationData , showModeSwitcher }) =>
{
    const handleSave = ({ zooms, centers }) => 
    {
        const centerToPoint = (center) => (center ? { type: "Point", coordinates: center } : undefined);
      
        const zoomMobile = zooms[ZOOM_SELECT_MODES.MOBILE];
        const zoomWeb = zooms[ZOOM_SELECT_MODES.DESKTOP];
        const centerMobile = centerToPoint(centers[ZOOM_SELECT_MODES.MOBILE]);
        const centerWeb = centerToPoint(centers[ZOOM_SELECT_MODES.DESKTOP]);     

        handleSaveZoom({ zoomMobile, zoomWeb, centerMobile, centerWeb });
           
    };
      
    return (
        <div style={{ height: "calc(100vh - 110px)" }}>
            <ZoomMap
                zoomMode={zoomMode}
                onChangeZoomMode={onChangeZoomMode}
                onSave={handleSave}
                onClose={onClose}
                settings={settings}
                zoomData={zoomData}
                showModeSwitcher={showModeSwitcher}
                isBuilding = {isBuilding}
                rotationData ={rotationData}
            />
        </div>
    );
};

export default ZoomScreen;
