import React from "react";
import { Button } from "semantic-ui-react";
import { ButtonIcon } from "../../../common/ButtonIcon";
import { ModalBox } from "../../../common/ModalBox";
import { useTranslation } from "react-i18next";

const CancelConfirmationModal = ({ onCancelClick }) =>
{
    const trans = useTranslation().t;
    
    return (
        <ModalBox className="confirmActionModal"
            trigger={<ButtonIcon icon="cross" className="cancelButton"/>}
            header={trans("Cancel_Confirmation_Modal.Confirm_Action")}
            actions={<Button color="orange" floated="right" content={trans("Cancel_Confirmation_Modal.Cancel")} onClick={onCancelClick} />}>
            <p className="p-modal">
                {trans("Cancel_Confirmation_Modal.Style_Edit_Cancel")}
            </p>
        </ModalBox>
    );
};

export default CancelConfirmationModal;