import { useContext } from "react";
import serverApi from "../_api/server.api";
import BrandingContext from "./BrandingContext";

export function useHolidayFunctions()
{
    const { state: { propertyId, properties } } = useContext(BrandingContext);
    const property = properties?.[propertyId];

    const getEntityHolidays = (entityLabelId, year) => Promise.all([
        serverApi.getEntityHolidays(entityLabelId, year),
        serverApi.getPublicHolidays(year, property?.country || "US")
    ]);

    const updateEntityHolidays = (entityLabelId, holiday, remove = false) =>
    {
        const updateHoliday = {
            _id: entityLabelId,
            remove: remove,
            holidayHours: holiday
        };
        return serverApi.updateEntityHoliday(updateHoliday);
    };

    const getBuildingHolidays = (buildingId, year) => Promise.all([
        serverApi.getBuildingHolidays(buildingId, year),
        serverApi.getPublicHolidays(year, property?.country || "US")
    ]);

    const updateBuildingHolidays = (buildingId, holiday, remove = false) =>
    {
        const updateHoliday = {
            _id: buildingId,
            remove: remove,
            holidayHours: holiday
        };
        return serverApi.updateBuildingHoliday(updateHoliday);
    };

    return {
        getEntityHolidays,
        updateEntityHolidays,
        getBuildingHolidays,
        updateBuildingHolidays
    };
}
