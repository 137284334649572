import React, { useContext, useState } from "react";
import { ButtonBasic, ButtonIcon } from "../../../common/ButtonIcon";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useTranslation } from "react-i18next";
import { useOverlaysTemplateContext } from "../../../../store/OverlaysTemplateContext";
import { OVERLAYS_TEMPLATE_MODES } from "../../../../_constants/overlaysTemplate";
import OverlayTemplateCreationModal from "../OverlayTemplateCreationModal";
import { MapOverlaysContext } from "../../../../store/MapOverlaysContext";
import OverlayTemplateItem from "./OverlayTemplateItem";
import { truncateText } from "../../../../_utils/mapUtils";
import { CopyTemplates, CopyTemplateSuccessModal } from "../../../modalsPopups/copyTemplates/CopyTemplates";

import "./OverlaysTemplateSidebars.css";

const OverlaysTemplateSidebar = () => 
{
    const {
        overlayTemplates,
        overlayTemplateMode,
        initiateOverlayTemplate,
        overlayCreateModalToggle,
        groupedOverlayTemplates,
        overlayTemplatesGroupNames,
        ungroupedOverlayTemplates,
    } = useOverlaysTemplateContext();

    const { updateMode,setState ,state } = useContext(MapOverlaysContext);
    const { t: trans } = useTranslation();
    const [openExportModal,setOpenExportModal]= useState(false);
    const [openExportSuccessMessageModal,setOpenExportSuccessMessageModal]= useState(false);
  
    const renderListGroupWiseOverlayTemplates = () => (
        <div className="templateGroupContainer">          
            {overlayTemplatesGroupNames?.map((group, idx) => (
                <React.Fragment key={idx}>
                    <div className="templateGroupHeader">
                        <img src={"/img/icon-template-group.svg"} style={{ height: "21px", width: "21px" }} alt="Group Name"/>
                        {<h3 className="activitySidebar-overlayName">{truncateText(group,25)}</h3>}
                    </div>
                 
                    {groupedOverlayTemplates[group]?.map((template) => (
                        <OverlayTemplateItem
                            key={template._id}
                            templateItem={template}
                        />
                    ))}
                </React.Fragment>
            ))}
        </div>
    );

    const renderUngroupedOverlayTemplates = () => (
        <div>
            {ungroupedOverlayTemplates?.map((template) => (
                <OverlayTemplateItem
                    key={template._id}
                    templateItem={template}
                />
            ))}
        </div>
    );

    const exportTemplateSuccess =() => 
    {
        setOpenExportModal(false);
        setOpenExportSuccessMessageModal(true);
    };

    const displayOverlayTemplateCreationModal = (overlayTemplateMode === OVERLAYS_TEMPLATE_MODES.CREATE || overlayTemplateMode === OVERLAYS_TEMPLATE_MODES.EDIT ) && overlayCreateModalToggle;

    return (
        <div className="sidebarPortal mapOverlaysSidebar activitySidebarDynamicLayer template-sideBar" >
            <Scrollbars autoHeight autoHeightMin="calc(100vh - 125px)" autoHeightMax={"calc(100vh - 125px)"}>
                <div>
                    { displayOverlayTemplateCreationModal  && <OverlayTemplateCreationModal />}
                    <div className="overlayTemplateSidebarHeader sidebarSection">
                        <h2 className="overlayTemplateSidebarHeading">
                            {trans("MapOverlaysActivitySideBar.Templates")}
                        </h2>
                        <ButtonIcon
                            icon="cross"
                            className="cancelButton"
                            onClick={() => 
                            {
                                updateMode(undefined);
                                setState({ isOverlaysTemplateSideBar: !state.isOverlaysTemplateSideBar });
                            }}
                        />
                    </div>

                    <div className="sidebarSection">
                        <div className="overlayTemplateSidebarActions">

                            <ButtonIcon
                                icon="plus-filled"
                                onClick={() => 
                                {
                                    initiateOverlayTemplate();
                                }}
                                content={trans("MaintenanceMapOverlaysMenuGroup.Create_New_Template")}
                            />

                            <ButtonBasic icon="copy" disabled={!overlayTemplates?.length } content={trans("ExportGroups.Copy")} onClick={() => setOpenExportModal(true)}/>
                            
                        </div>
                    </div>

                    {!overlayTemplates.length ? (
                        <div className="sidebarSection">
                            <div className="centeralBox">
                                <img src="/img/no-templates.svg" alt="No Templates" />
                                <div className="noTemplateHeading"> {trans("MaintenanceMapOverlaysMenuGroup.No_Templates_Heading")} </div>
                                <div className="noTemplateSubHeading"> {trans("MaintenanceMapOverlaysMenuGroup.No_Templates_Description")} </div>
                            </div>
                        </div>

                    ):(<div className="sidebarSection">
                        {renderListGroupWiseOverlayTemplates()}
                        {renderUngroupedOverlayTemplates()}
                    </div>)
                    }
                </div>
            </Scrollbars>

            <CopyTemplates
                openModal={openExportModal}
                onCloseModal={() => setOpenExportModal(false)}
                onSuccess={() => exportTemplateSuccess()} />
            <CopyTemplateSuccessModal
                successMessageModalOpen={openExportSuccessMessageModal}
                onSuccessMessageModalClose={() => setOpenExportSuccessMessageModal(false)} />
        </div>
    );
};

export default OverlaysTemplateSidebar;
