exports.LinkPriorityMultiplier =
{
    VeryHigh: 20,  // 0.13  x distance
    High: 10,  // 0.25  x distance
    Normal: 0,  // 1.00  x distance
    Low: -10, // 4.00  x distance
    VeryLow: -20, // 8.00  x distance
    Blocked: -100, // 10000 x distance
};

exports.LinkPriorityMultiplierReverseMap =
{
    "20": "VeryHigh",  // 0.13  x distance
    "10": "High",  // 0.25  x distance
    "0": "Normal",  // 1.00  x distance
    "-10": "Low", // 4.00  x distance
    "-20": "VeryLow", // 8.00  x distance
    "-100": "Blocked", // 10000 x distance
}