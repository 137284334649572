import React from "react";
import { Button, Modal, ModalActions, ModalContent, ModalHeader } from "semantic-ui-react";
import "./ConfirmationModal.css";


const ConfirmationModal= ({ className = "", children, onClose,...rest }) => <Modal closeIcon={<Button onClick={onClose} className="close" />}
    className={`confirmation-modal ${className}`} {...rest}>
    {children}
</Modal>;

const ConfirmationModalHeader = ({ className = "", children, ...rest }) => <ModalHeader className={`confirmation-modal-header ${className}`} {...rest}>{children}</ModalHeader>;

const ConfirmationModalActions = ({ className = "", children, ...rest }) => <ModalActions className={`confirmation-modal-actions ${className}`} {...rest}>{children}</ModalActions>;

const ConfirmationModalContent = ({ className = "", children, ...rest }) => <ModalContent className={`confirmation-modal-content ${className}`} {...rest}>{children}</ModalContent>;

const ConfirmationModalHeaderIcon = ({ className = "", children, ...rest }) => <div className={`confirmation-modal-header-icon-bx ${className}`} {...rest}>{children}</div>;


ConfirmationModal.Header = ConfirmationModalHeader;
ConfirmationModal.Content = ConfirmationModalContent;
ConfirmationModal.Actions = ConfirmationModalActions;
ConfirmationModal.HeaderIcon = ConfirmationModalHeaderIcon;

export default  ConfirmationModal;