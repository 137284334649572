import React, { useState, FC } from "react";
import { Tab, Input } from "semantic-ui-react";
import { getBase64FromImageUrl, getFileSizeFromBase64 } from "./utils";
import { ErrorLabel } from './ErrorLabel';

interface WebAddressProps {
    onChange: Function;
    setLoading: (loading: boolean) => void;
    proxyUrl: string;
    text: WebTabText;
    maxFileSizeBytes: number;
    acceptableImageFileFormats?:string;
    responseHeadersRetrievalUrl?: string;
    setError: (error: boolean) => void;
}

export interface WebTabText {
    title: string;
    instruction: string;
    pasteHere: string;
    sizeError: string;
    filetypeError?: string
}

const WebAddress : FC<WebAddressProps> = ({
    onChange,
    setLoading,
    proxyUrl,
    text,
    maxFileSizeBytes,
    acceptableImageFileFormats,
    responseHeadersRetrievalUrl,
    setError
}) =>
{
    const [url, setUrl] = useState("");
    const [sizeError, setSizeError] = useState(false);
    const [filetypeError, setFileTypeError] = useState(false);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>(undefined);

    /**
     * Used to check if content-type of image link is one of acceptable logo formats
     * In case content-type could not be determined this check will still pass
     * If the image link is an invalid url then this check will fail
     * @param url 
     * @returns 
     */
    const checkIfValidFileFormat = async (url: string) => {
        let isValidFileFormat = true;

        const response = await fetch(responseHeadersRetrievalUrl + url);
        const data = await response.json();

        if(data?.error) {
            isValidFileFormat = false;
        } else if(data?.headers?.['content-type']) {
            const imageType = data.headers['content-type'];
            if(!acceptableImageFileFormats.split(',').includes(imageType)) {
                 isValidFileFormat = false;
            }
        }

        return isValidFileFormat;
    };

    const handleUrlChange = (e, { value }) =>
    {
        setUrl(value);

        clearTimeout(timeoutId);
        setTimeoutId(setTimeout(() => handleLoadImage(value), 500));
    };

    const handleLoadImage = async (url) =>
    {
        setSizeError(false);
        setFileTypeError(false);

        setError(false);
        setLoading(true);

        // If url is blank do not procced further
        if(!url) {
            setLoading(false);
            return;
        }

        if(acceptableImageFileFormats) {
            const isValidFileFormat = await checkIfValidFileFormat(url);
            if(!isValidFileFormat) {
                setFileTypeError(true);
                setLoading(false);
                setError(true);
                return;
            }
        }

        if(url) {
            let fullUrl = proxyUrl + url;
            getBase64FromImageUrl(fullUrl, (image) =>
            {
                const fileSize = getFileSizeFromBase64(image);

                if (fileSize <= maxFileSizeBytes)
                {
                    setSizeError(false);
                    setError(false);
                    onChange("image", image);
                    onChange("fileName", url);
                }
                else
                {
                    setSizeError(true);
                    setError(true);
                }
                setLoading(false);
            }, () => {
                setFileTypeError(true);
                setLoading(false);
                setError(true);
            });
        }
    };

    return (
        <Tab.Pane>
            <p className="para" style={{ marginBottom: "1em" }}>{text.instruction}</p>
            <Input
                fluid
                name="url"
                className="uploadUrl"
                placeholder={text.pasteHere}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="none"
                spellCheck={true}
                value={url}
                onChange={handleUrlChange}
                style={{"marginBottom": "15px"}}
            />
            {(sizeError) && <ErrorLabel label={text.sizeError}/>}
            {(filetypeError) && <ErrorLabel label={text.filetypeError}/>}
        </Tab.Pane>
    );
};

export default WebAddress;
