import ObjectId from "bson-objectid";
import { createFloorPlanImageLayer } from "mapsted.maps/mapFunctions/cmsVectorLayers";
import { createVectorLayer } from "mapsted.maps/mapFunctions/plotting";
import { FLOOR_PLAN_LAYERS_IDS } from "mapsted.maps/utils/map.constants";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useDebouncedCallback } from "use-debounce";
import
{
    GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES,
    MAP_EDITOR_SHORTCUTS, MAP_EDITOR_TOOLS
} from "../../../_constants/mapEditor";
import { CollectionNameMap } from "../../../_indexedDB/collections/v1/collection";
import mapEditorLocalDb from "../../../_indexedDB/mapEditorLocal.db";
import { FloorPlanStep } from "../../../models/floorPlanStep";
import FloorPlanContext from "./FloorPlanContex";
import
{
    convertLocalSavedGeoJsonToState,
    convertStateDataToLocalGeoJson,
    floorPlanFilerImageIdToBase64,
    geoRefFeaturesRenderOrder,
    getActiveFloorPlanDataFromEditorState
} from "./utils/floorPlan.utils";

export const defaultGeoReferencingFloorPlan = {
    _id: ObjectId().toString(),
    activeTab: "",
    isImageModalOpen: false,
    isPdfModalOpen: false,
    selectedFloorPlanImageId: "",
    markedImageFeaturesHash: {},
    markedMapFeaturesHash: {},
    center: [0, 0],
    scale: [1, 1],
    rotation: 0,
    opacity: .7,
    geoRefFeatures: []
};


export const DEBOUNCE_CALL_BACK_TIMEOUT = 300;

//state update main actions names
export const ACTIONS = {
    SAVED_FLOOR_PLAN_BY_MONGO: "updatedBySavedFloorPlan",
    USER_ACTIONS: "byUserActions",
    RE_DO_HISTORY: "updatedByReDoHistory",
    UNDO_HISTORY: "updatedByUndoHistory",
    INITIAL_STATE: "updatedByInitialState",
    LOCAL__DB_DATA: "updatedByLocalDBData",
    MISC: "updatedByMisc"
};


/*=======================
EVENTS ON HISTORY STACK
========================*/
//this will reset the redo history
export const ACTIONS_EMIT_RESET_REDO = [ACTIONS.INITIAL_STATE, ACTIONS.SAVED_FLOOR_PLAN_BY_MONGO, ACTIONS.USER_ACTIONS];

/*==================
    LOCAL DB EVENTS;
===================*/
//this will update the active state snap in indexDb and skip adding new step
export const ACTIONS_EMITS_SYNC_TO_LOCAL_DB_AND_SKIP_ADDING_STEP = [ACTIONS.UNDO_HISTORY, ACTIONS.LOCAL__DB_DATA];

//this will ignore index db sync updates both the step and active state snap
export const ACTIONS_NOT_EMIT_INDEX_DB_SYNC_UPDATE = [ACTIONS.SAVED_FLOOR_PLAN_BY_MONGO, ACTIONS.MISC];

//all other actions emit index db sync event
export const ACTIONS_EMIT_INDEX_DB_SYNC_UPDATE = [ACTIONS.USER_ACTIONS, ACTIONS.RE_DO_HISTORY];







export const FloorPlanProvider = ({
    activeTool,
    propertyId,
    buildingId,
    floorId,
    onResetRedo,
    onActiveToolChange,
    onLayersVisibilityChange,
    getLevelNotLockedAlertMessage,
    editType,
    defaultLayersVisibility,
    children,
    onRedo,
    onUndo,
    shouldUseLocalData,
    properties,
    isLevelLockedByUser,
    setLoading
}) =>
{
    const [floorPlanSavedStateToMongo, setFloorPlanSavedStateToMongo] = useState(defaultGeoReferencingFloorPlan);
    const [geoReferencingFloorPlan, setGeoReferencingFloorPlan] = useState(defaultGeoReferencingFloorPlan);
    const refInternalStateUpdatedByActionName = useRef(ACTIONS.INITIAL_STATE);
    const [isUndoLocked, setIsUndoLocked] = useState(false);
    const [activeMapInteractions, setActiveMapInteractions] = useState([]);
    const [geoRefMapLayersHash, setGeoRefMapLayersHash] = useState({
        [FLOOR_PLAN_LAYERS_IDS.FLOOR_PLAN_GEO_REF_IMAGE_LAYER]: createFloorPlanImageLayer({
            id: FLOOR_PLAN_LAYERS_IDS.FLOOR_PLAN_GEO_REF_IMAGE_LAYER,
            opacity: .7,
            imageUrl: "",
            imageRotate: 0,
            imageCenter: [0, 0],
            imageScale: [1, 1],
        }),
        [FLOOR_PLAN_LAYERS_IDS.FLOOR_PLAN_VECTOR_LAYER]: createVectorLayer(
            FLOOR_PLAN_LAYERS_IDS.FLOOR_PLAN_VECTOR_LAYER,
            null,
            {
                renderOrder: geoRefFeaturesRenderOrder
            }
        )
    });

    const [olMap, setOlMap] = useState();

    const [layersVisibility, setLayersVisibility] = useState(defaultLayersVisibility);
    const [olImageMap, setOlImageMap] = useState();
    const [olImageMapLayersHash, setOlImageMapLayersHash] = useState({
        [FLOOR_PLAN_LAYERS_IDS.FLOOR_PLAN_IMAGE_MAP_IMAGE_LAYER]: createFloorPlanImageLayer({
            id: FLOOR_PLAN_LAYERS_IDS.FLOOR_PLAN_IMAGE_MAP_IMAGE_LAYER,
            opacity: 1,
            imageUrl: "",
            imageRotate: 0,
            imageCenter: [0, 0],
            imageScale: [1, 1],
        }),
        [FLOOR_PLAN_LAYERS_IDS.FLOOR_PLAN_IMAGE_MAP_VECTOR_LAYER]: createVectorLayer(
            FLOOR_PLAN_LAYERS_IDS.FLOOR_PLAN_IMAGE_MAP_VECTOR_LAYER,
        )
    });

    const geoReferencingFloorPlanStateRef = useRef(geoReferencingFloorPlan);
    const savedFloorPlanToMongoRef = useRef(floorPlanSavedStateToMongo);


    const activeToolRef = useRef(activeTool);


    const [canUpdateLayerFromStateData, setCanUpdateLayerFromStateData] = useState(true);

    const isInjectingStateDataToLayersAllowed = useCallback(() => canUpdateLayerFromStateData, [canUpdateLayerFromStateData]);

    const setInjectingLayersDataFromState = useCallback((value) => setCanUpdateLayerFromStateData(value), [setCanUpdateLayerFromStateData]);


    //this handles updating and stored floorPlanSavedStateToMongo to savedFloorPlanState
    useEffect(() =>
    {
        const savedStateToDb = getActiveFloorPlanDataFromEditorState({ properties, propertyId, buildingId, floorId });
        setFloorPlanSavedStateToMongo(savedStateToDb);
    }, [floorId, buildingId, propertyId, properties]);

    //updates the savedFloorPlanToMongoRef
    useEffect(() =>
    {
        savedFloorPlanToMongoRef.current = floorPlanSavedStateToMongo;
    }, [floorPlanSavedStateToMongo]);




    //handles updating data to index db and updates the state ref
    useEffect(async () =>
    {
        if (shouldUseLocalData)
        {
            handleAddStateToStep({
                before: convertStateDataToLocalGeoJson(geoReferencingFloorPlanStateRef.current),
                after: convertStateDataToLocalGeoJson(geoReferencingFloorPlan)
            }, refInternalStateUpdatedByActionName.current);
        }
        geoReferencingFloorPlanStateRef.current = geoReferencingFloorPlan;

    }, [geoReferencingFloorPlan]); // This effect will be triggered whenever geoReferencingFloorPlan changes



    //take cares of sync the local state to index db state or mongo state
    useEffect(async () =>
    {
        if (!shouldUseLocalData && floorPlanSavedStateToMongo)
        {
            onStateUpdateTrackActionName(ACTIONS.SAVED_FLOOR_PLAN_BY_MONGO);
            setGeoReferencingFloorPlan(floorPlanSavedStateToMongo);
        }
        else if (shouldUseLocalData && !geoReferencingFloorPlanStateRef.current.activeTab)
        {
            const localData = await mapEditorLocalDb.find(CollectionNameMap.floorPlan);
            //right now we are not navigating to active tool when local data is available
            //const lastStepData = await mapEditorLocalDb.handleGetLastStep();
            //const activeToolFromLocal = getActiveToolFromStepData(lastStepData);
            // const localData = convertLocalSavedGeoJsonToState(lastStepData.data.updatedGeoRefAfter);

            if (localData && Array.isArray(localData) && localData.length > 0)
            {
                const localDataToState = convertLocalSavedGeoJsonToState(localData[0]);
                //when initial load set active tab to georefence
                localDataToState.activeTab = "";
                updateStateAndTrackAction(localDataToState, ACTIONS.LOCAL__DB_DATA);

            }
            else
            {
                updateStateAndTrackAction({ ...floorPlanSavedStateToMongo }, ACTIONS.SAVED_FLOOR_PLAN_BY_MONGO);
            }

            //onActiveToolChange(activeToolFromLocal);

        }
    }, [shouldUseLocalData, floorPlanSavedStateToMongo]);


    //when activeTool changes and it not a floorPlan remove active tab
    useEffect(() =>
    {
        if (activeTool !== MAP_EDITOR_TOOLS.FLoorPlan)
        {
            updateStateAndTrackAction((prev) => ({ ...prev, activeTab: "" }), ACTIONS.MISC);
        }
        activeToolRef.current = activeTool;
    }, [activeTool]);



    //sets visibility of layers based on props or active tab
    useEffect(() =>
    {
        const activeTab = geoReferencingFloorPlan.activeTab;
        if (!activeTab || activeTab === GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES.floorPlanImage)
        {
            setLayersVisibility(defaultLayersVisibility);
        }
        else
        {
            setLayersVisibility((prev) => ({
                ...prev,
                [FLOOR_PLAN_LAYERS_IDS.FLOOR_PLAN_VECTOR_LAYER]: true,
                [FLOOR_PLAN_LAYERS_IDS.FLOOR_PLAN_GEO_REF_IMAGE_LAYER]: true
            }));
        }

    }, [geoReferencingFloorPlan.activeTab, defaultLayersVisibility]);


    //To avoid fetching the image every time the state changes (image layers gets redrawn every time thats how the logic works for now ), we now store it as a base64 string. This reduces the lag in the map and makes interactions with the image smoother and more reliable.
    const floorPlanUrlBase64Query = useQuery(geoReferencingFloorPlan.selectedFloorPlanImageId, async () => floorPlanFilerImageIdToBase64(geoReferencingFloorPlan.selectedFloorPlanImageId), { enabled: !!geoReferencingFloorPlan.selectedFloorPlanImageId });


    const floorPlanUrlBase64 = useMemo(() => floorPlanUrlBase64Query.data, [floorPlanUrlBase64Query.data]);


    //take care of adding step data to index db
    const updateIndexedDB = useCallback(async (floorPlanStepData, stateUpdatedByAction) =>
    {
        setIsUndoLocked(true);
        let skipSavingStep = ACTIONS_EMITS_SYNC_TO_LOCAL_DB_AND_SKIP_ADDING_STEP.includes(stateUpdatedByAction);
        let shouldResetRedoHistory = ACTIONS_EMIT_RESET_REDO.includes(stateUpdatedByAction);
        await mapEditorLocalDb.handleUpdateLocalFloorPlan({
            propertyId,
            buildingId,
            floorId,
            selectedTool: activeToolRef.current,
            floorPlanStepData,
        }, skipSavingStep);

        if (shouldResetRedoHistory)
        {
            onResetRedo();
        }


        setIsUndoLocked(false);
    }, [propertyId, floorId, buildingId, activeTool, onResetRedo, setIsUndoLocked]);

    //triggers update on index db , if multiple updates triggers in a short time DEBOUNCE_CALL_BACK_TIMEOUT=300 will only consider last update of 300ms
    const handleAddStateToStep = useDebouncedCallback(useCallback(async ({ before, after }, stateUpdatedByAction) =>
    {
        //when data is been updated from mongo db saved data there is no need to save on index db if any update happens actual user action then we will store in index db
        if (ACTIONS_NOT_EMIT_INDEX_DB_SYNC_UPDATE.includes(stateUpdatedByAction)) return;

        const floorPlanStep = new FloorPlanStep({
            updatedGeoRefAfter: after,
            updatedGeoRefBefore: before
        });
        await updateIndexedDB(floorPlanStep, stateUpdatedByAction);


    }, [updateIndexedDB]), DEBOUNCE_CALL_BACK_TIMEOUT);

    //take care of processing and updating a state based of history actions
    const handleAddStepToState = useCallback(async (step, isRedo = false) =>
    {
        if (!step) return;
        const skipSettingActiveTab = false;

        if (activeToolRef.current !== MAP_EDITOR_TOOLS.FLoorPlan && step.selectedTool === MAP_EDITOR_TOOLS.FLoorPlan)
        {
            onActiveToolChange(MAP_EDITOR_TOOLS.FLoorPlan);
        }
        //new state from step...
        let newState;
        let actionName;

        if (isRedo)
        {
            newState = convertLocalSavedGeoJsonToState(step.data.updatedGeoRefAfter, skipSettingActiveTab);
            actionName = (ACTIONS.RE_DO_HISTORY);
        }
        else
        {
            newState = convertLocalSavedGeoJsonToState(step.data.updatedGeoRefBefore, skipSettingActiveTab);
            actionName = (ACTIONS.UNDO_HISTORY);
        }
        updateStateAndTrackAction(newState, actionName);

    }, [setGeoReferencingFloorPlan, onActiveToolChange]);

    //filters undo and redo actions based on active tool i.e allows only floor plan shortcut actions
    const handleFloorPlanUndoRedoCallBack = useCallback((shortcut) =>
    {
        if (isUndoLocked || (activeToolRef.current !== MAP_EDITOR_TOOLS.FLoorPlan) || !isLevelLockedByUser) return;
        switch (shortcut)
        {
            case MAP_EDITOR_SHORTCUTS.UNDO:
                {
                    onUndo(handleAddStepToState);
                    break;
                }
            case MAP_EDITOR_SHORTCUTS.REDO:
                {
                    onRedo((step) => handleAddStepToState(step, true));
                    break;
                }

        }
    }, [onRedo, onUndo, handleAddStepToState, isUndoLocked, activeTool, isLevelLockedByUser]);

    const onStateUpdateTrackActionName = useCallback((actionName) =>
    {
        refInternalStateUpdatedByActionName.current = actionName;
    }, [refInternalStateUpdatedByActionName]);

    //this state update handle updates trigged by the user with in the floor plan tools / components
    const handleUpdateFloorPlanState = useCallback(async (payload) =>
    {
        updateStateAndTrackAction(payload, ACTIONS.USER_ACTIONS);
    }, []);

    // //if any shoutcut is triggered from the ui button will map to floor plan shortcut handler
    // useEffect(() =>
    // {
    //     if (undoRedoButtonClick === undefined) return;
    //     else
    //     {
    //         handleFloorPlanUndoRedoCallBack(undoRedoButtonClick);
    //     }
    //     onSetUndoRedoButtonClick(undefined);
    // }, [undoRedoButtonClick]);

    const updateStateAndTrackAction = useCallback((payload, actionName) =>
    {
        onStateUpdateTrackActionName(actionName);
        setGeoReferencingFloorPlan(payload);
    }, [setGeoReferencingFloorPlan, onStateUpdateTrackActionName]);



    //connects keyboard interactions
    //useKeyboardShortcut("Control", [MAP_EDITOR_SHORTCUTS.UNDO, MAP_EDITOR_SHORTCUTS.REDO], handleFloorPlanUndoRedoCallBack);

    return <FloorPlanContext.Provider
        value={
            {
                layersVisibility,
                activeTool,
                geoReferencingFloorPlan,
                handleUpdateFloorPlanState,
                activeMapInteractions,
                setActiveMapInteractions,
                olMap,
                setOlMap,
                geoRefMapLayersHash,
                setGeoRefMapLayersHash,
                olImageMap,
                setOlImageMap,
                olImageMapLayersHash,
                setOlImageMapLayersHash,
                geoReferencingFloorPlanStateRef,
                propertyId,
                buildingId,
                floorId,
                onActiveToolChange,
                onLayersVisibilityChange,
                getLevelNotLockedAlertMessage,
                editType,
                geoRefLayersVisibility: layersVisibility,
                handleFloorPlanUndoRedoCallBack,
                isEditMode: shouldUseLocalData,
                setInjectingLayersDataFromState,
                isInjectingStateDataToLayersAllowed,
                setLoading,
                undoStepHandler: (step) => handleAddStepToState(step),
                redoStepHandler: (step) => handleAddStepToState(step, true),
                floorPlanUrlBase64,
                floorPlanUrlBase64Query


            }
        }> {children}</FloorPlanContext.Provider >;
};
