import React from "react";
import { Link } from "react-router-dom";
import { Button, Popup } from "semantic-ui-react";
import BrandingContext from "../../../../store/BrandingContext";
import { useTranslation } from "react-i18next";

export const ACTIVE = {
    MAP: 0,
    LIST: 1
};

export const ModeButtonGroup = ({ className = "", active, disabled=false }) =>
{
    const trans = useTranslation().t;
    const ctx = React.useContext(BrandingContext);
    React.useEffect(() =>
    {
        if (active === ACTIVE.MAP)
        {
            ctx.refreshMap();
        }
        else if (active === ACTIVE.LIST)
        {
            ctx.changeSelectedEntity(undefined, undefined, true);
        }
    }, [active]);
    return (
        <Button.Group  className={`modeButtonGroup ${className}`}>
            <Popup
                className="modeButtonsPopup"
                hoverable
                position="bottom right"
                size="small"
                trigger={<div><ModeButton disabled={disabled} icon="mapping" isActive={active === ACTIVE.MAP} url="/branding" /></div>}
                content={trans("ModeButtonGroup.Display_your_property_as_a_map_")} />
            <Popup
                className="modeButtonsPopup"
                hoverable
                position="bottom right"
                size="small"
                trigger={<div><ModeButton disabled={disabled} icon="listing" isActive={active === ACTIVE.LIST} url="/branding/list"/></div>}
                content={trans("ModeButtonGroup.Display_your_property_as_a_list_item_")} />
        </Button.Group>
    );
};

const ModeButton = ({ isActive, icon, url, disabled }) => (
    <Link to={url}>
        <Button disabled={disabled} className={`buttonIcon${isActive ? " active":""}`}>
            <img src={`/img/icon-${icon}.svg`} alt="" />
        </Button>
    </Link>
);
