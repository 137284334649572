import React, { FC, ReactNode, useEffect } from "react";
import { Button } from "semantic-ui-react";
import { debounce, DEFAULT_MAX_FILE_SIZE_BYTES, DEFAULT_MAX_GALLERY_LENGTH } from "./utils";
import ModalLayout from "./ModalLayout";
import { useDrop, useDrag } from "react-dnd";
import css from './Divide.module.css';
import "./UploadModal.css";
import { LoadableImage } from "./LoadableImage";
import { useSetState } from "ahooks";
import { useMergedObjects } from "../hooks";
import UploadImageModal, { UploadImageModalText } from "./UploadImageModal";

export const Divide = ({ vertical = undefined, className = undefined }) =>
{
    return <div className={`${css.divide}${vertical ? " " + css.vertical : ""}${className ? " " + className : ""}`}/>;
}

interface GalleryUploadProps {
    galleryList: {fileId: string; imageUrl: string;}[];
    trigger?: ReactNode;
    uploadImageHeading: string;
    uploadImageDescription: string;
    acceptableImageFileFormats: string;
    aspectRatio: number;
    onSave: (data: string, galleryListIndex?: number) => void;
    onReorderCoverImages: (coverImages: {fileId: string; imageUrl: string;}[]) => void;
    onOpen: Function;
    onClose: Function;
    defaultFilename: string;
    proxyUrl: string;
    text?: GalleryTabText;
    open?: boolean;
    maxFileSizeBytes?: number;
    responseHeadersRetrievalUrl?: string;
    uploadModalText?: Partial<UploadImageModalText>;
    maxGalleryLength: number;
}

export interface GalleryTabText {
    title: string;
    instruction: string; //UploadImageModal.Click_and_drag_to_reorder_your_images__T
    emptyHeader: string; //UploadImageModal.No_Items
    emptyInstruction: string; //"UploadImageModal.empty gallery desc"
    uploadNewImage: string;
    edit: string;
    galleryThumbAlt: string;
    delete: string;
    cancel: string;
    areYouSure: string;
    deleteImg: string;
}

const DEFAULT_TEXT: GalleryTabText = {
    title: "Gallery",
    instruction: "Click and drag to reorder your images. The first image will be used as the main image. The rest of the images will appear in the order they are arranged.",
    emptyHeader: "No Items",
    emptyInstruction: "To start adding images, ",
    uploadNewImage: "Upload New Image",
    edit: "Edit",
    galleryThumbAlt: "Gallery image",
    delete: "Delete",
    deleteImg: "Delete Image",
    cancel: "Cancel",
    areYouSure: "Are you sure you want to delete this image?"
}

export const GalleryUpload : FC<GalleryUploadProps> = ({
    galleryList: galleryListProp = [],
    trigger,
    uploadImageHeading,
    uploadImageDescription,
    acceptableImageFileFormats,
    aspectRatio,
    onSave,
    onReorderCoverImages,
    onOpen = () => null,
    onClose = () => null,
    defaultFilename = "Image",
    proxyUrl,
    text = {},
    open: openProp = undefined,
    maxFileSizeBytes = DEFAULT_MAX_FILE_SIZE_BYTES,
    responseHeadersRetrievalUrl,
    uploadModalText = {},
    maxGalleryLength = DEFAULT_MAX_GALLERY_LENGTH
}) =>
{
    const mergedText : GalleryTabText = useMergedObjects(DEFAULT_TEXT, text);
    const [state, setState] = useSetState({
        isOpen: false,
        isUploadModalOpen: false,
        src: undefined,
        galleryListIndex: undefined,
        galleryList: galleryListProp
    });

    const close = () => setState({ isOpen: false });
    const open = () => setState({ isOpen: true });
    const openCloseUploadModal = (action: "open" | "close") => setState({ isUploadModalOpen: action === "open" });

    useEffect(() =>
    {
        if (openProp)
        {
            open();
        }
        else if (openProp === false)
        {
            close();
        }
    }, [openProp]);

    useEffect(() => setState({galleryList: galleryListProp}), [galleryListProp]);

    const handleOnEdit = (fileId, imageUrl, index) =>
        setState({ src: imageUrl, isUploadModalOpen: true, galleryListIndex: index });

    const handleOnNewImage = () =>
        setState({ src: undefined, isUploadModalOpen: true, galleryListIndex: undefined });

    const handleOnDelete = (fileId, index) =>
    {
        setState({ src: undefined, galleryListIndex: index });
        // when data is undefined we call delete function in parent
        onSave(undefined, index);
    };

    const handleReorderCoverImages = debounce((coverImages) => onReorderCoverImages(coverImages), 2000);
    const onMoveGalleryItem = (dragIndex, hoverIndex) =>
    {
        let galleryList = [...state.galleryList];

        const draggedImage = galleryList[dragIndex];

        galleryList.splice(dragIndex, 1);
        galleryList.splice(hoverIndex, 0, draggedImage);

        setState({ galleryList });
        handleReorderCoverImages(galleryList);
    };

    const { galleryList, galleryListIndex } = state;

    let uploadLink = <a onClick={handleOnNewImage} style={{ cursor: "pointer" }}>{mergedText.uploadNewImage} </a>;

    if(galleryList && galleryList.length >= maxGalleryLength) {
        uploadLink = (
            <div title="Gallery limit has been reached" style={{ cursor: "no-drop", width: "130px"}}>
                <a style={{ pointerEvents: "none",  opacity: "0.5"}}>{mergedText.uploadNewImage}</a>
            </div>
        )
    }

    return (<>
        <ModalLayout
            modalProps={{
                open: state.isOpen,
                onClose: close,
                trigger: trigger ? <span onClick={() => open()}>{trigger}</span> : undefined,
                className: "uploadModal"
            }}
            heading={mergedText.title}>
             {
                 (galleryList.length === 0)
                 && <EmptyGallery
                     link={<a style={{ cursor: "pointer" }} onClick={handleOnNewImage}>{mergedText.uploadNewImage}</a>}
                     text={mergedText}
                 />
             }
             {
                 (galleryList.length > 0)
                 && <>
                     <p className="para">{mergedText.instruction}</p>
                     {uploadLink}
                     <div className="galleryGroup">
                         {
                             galleryList.map(({ fileId, imageUrl }, index) =>
                                 <GalleryThumb
                                     key={fileId}
                                     index={index}
                                     fileId={fileId}
                                     imageUrl={imageUrl}
                                     onDelete={handleOnDelete}
                                     onEdit={handleOnEdit}
                                     onMoveGalleryItem={onMoveGalleryItem}
                                     text={mergedText}
                                 />
                             )
                         }
                     </div>
                 </>
             }
         </ModalLayout>
        <UploadImageModal
            src={state.src}
            open={state.isUploadModalOpen}
            heading={uploadImageHeading}
            description={uploadImageDescription}
            aspectRatio={aspectRatio}
            defaultFilename={defaultFilename}
            acceptableImageFileFormats={acceptableImageFileFormats}
            onSave={(data) => onSave(data, galleryListIndex)}
            onOpen={onOpen}
            onClose={() => openCloseUploadModal("close")}
            proxyUrl={proxyUrl}
            maxFileSizeBytes={maxFileSizeBytes}
            responseHeadersRetrievalUrl={responseHeadersRetrievalUrl}
            text={uploadModalText}
        />
    </>);
}

const EmptyGallery = ({ text, link }) =>
{
    return (
        <div className="emptyGallery">
            <b>{text.emptyHeader}</b>
            <p>
                {text.emptyInstruction}
                {link}
            </p>
        </div>
    );
};

/**
 * List of draggable item types required for React DnD
 */
export const DraggableItemTypes = {
    FLOOR: "floor",
    SORTABLE_FLOOR: "sortable-floor",
    GALLERY_THUMB_ROW: "gallery-thumb-row",
};
Object.freeze(DraggableItemTypes);

const GalleryThumb = ({ index, fileId, imageUrl, onDelete, onEdit, onMoveGalleryItem, text }) =>
{
    const ref = React.useRef(null);

    const handleOnDelete = React.useCallback(() =>
    {
        onDelete(fileId, index);
    }, [fileId, onDelete]);

    const handleOnEdit = React.useCallback(() =>
    {
        onEdit(fileId, imageUrl, index);
    }, [fileId, onEdit]);

    // This implementation is standard from ReactDbD sorting example
    const [{ canDrop }, drop] = useDrop({
        accept: DraggableItemTypes.GALLERY_THUMB_ROW,
        collect: (monitor) => ({
            canDrop: monitor.canDrop(),
        }),
        hover (item, monitor)
        {
            if (!ref.current)
            {
                return;
            }
            // @ts-ignore
            const dragIndex = item.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex)
            {
                return;
            }
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY)
            {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)
            {
                return;
            }
            onMoveGalleryItem(dragIndex, hoverIndex);
            // @ts-ignore
            item.index = hoverIndex;
        },
    });

    // This implementation is standard from ReactDbD sorting example
    const [{ isDragging }, drag] = useDrag({
        type: DraggableItemTypes.GALLERY_THUMB_ROW,
        item: { type: DraggableItemTypes.GALLERY_THUMB_ROW, fileId, index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const opacity = isDragging ? 0 : 1;
    const className = canDrop ? " galleryThumbDrag" : "";

    drag(drop(ref));

    return (
        <div ref={ref} className={`galleryThumb${className}`} style={{ opacity }}>
            <div className="thumb">
                <LoadableImage as="img" src={imageUrl} alt={`${text?.galleryThumbAlt} ${index + 1}`} />
            </div>
            <div className="actionsThumb">
                <DeleteImageModal onDelete={handleOnDelete} text={text} />
                <Divide vertical />
                {/*"UploadImageModal.Edit"*/}
                <ButtonUploadModal icon="icon-pencil" content={text?.edit} onClick={handleOnEdit} />
            </div>
        </div>
    );
};

const ButtonUploadModal = ({ icon, content, onClick }) => (
    <Button className="actionButton" onClick={onClick} >
        <img src={`/img/${icon}.svg`} alt="" />
        {content}
    </Button>
);

const DeleteImageModal = ({ onDelete, text }) =>
{
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    const handleOnDelete = React.useCallback(() =>
    {
        setOpen(false);
        onDelete();
    }, [onDelete]);

    return (
        <ModalLayout
            modalProps={{
                dimmer: "blurring",
                open,
                onClose: handleClose,
                trigger: <ButtonUploadModal icon="icon-trash-blue" content={text?.delete} onClick={handleOpen} />,
                className: "crop-image-modal actionModal deleteImg"
            }}
            heading={text?.deleteImg}
            actions={
                 <>
                     <Button content={text?.cancel} onClick={handleClose} />
                     <Button secondary content={text?.delete} onClick={handleOnDelete} />
                 </>
            }>
            <p>{text?.areYouSure}</p>
        </ModalLayout>
    );
};