// Utility functions needed for when two maps are plotted on the same page

/**
    * Finds if both route nodes are on map
    * @param {*} transition 
    * @returns isBothRouteNodeOnMap and routeNodesOnMapArray which contains information including if the route node is on the georeference map
    */
exports.isTransitionOnCurrentMap = (transition, navIds, georeferenceNavIds) =>
{
    let isBothRouteNodeOnMap = false;
    let isTransitionOnMainMap = false;
    let isTransitionOnGeoreferenceMap = false;
    let isTransitionOnSameMap = false;
    let routeNodesOnMapArray = [];

    // if transition is property level but there is a building selected return false
    if (transition.buildingId === -1 && (navIds.buildingId !== -1 && georeferenceNavIds.buildingId !== -1))
    {
        return { isBothRouteNodeOnMap, routeNodesOnMapArray };
    }

    // if transition is floor level but one of the maps is property level
    if (transition.buildingId !== -1 && (navIds.buildingId === -1 || georeferenceNavIds.buildingId === -1))
    {
        return { isBothRouteNodeOnMap, routeNodesOnMapArray };
    }

    if (transition.route[0].floorId === transition.route[1].floorId)
    {
        isTransitionOnSameMap = true;
    }

    transition.route.forEach((routeNode) =>
    {
        const { propertyId, buildingId, floorId } = routeNode;

        let isOnMap = false;
        let isGeoreference = false;
        let isMain = false;


        if (propertyId === navIds.propertyId && buildingId === navIds.buildingId && floorId === navIds.floorId)
        {
            isOnMap = true;
            isMain = true;
            isTransitionOnMainMap = true;

            if (isTransitionOnSameMap && georeferenceNavIds.floorId == navIds.floorId)
            {
                isTransitionOnGeoreferenceMap = true;
                isGeoreference = true;
            }
        }
        else if (propertyId === georeferenceNavIds.propertyId && buildingId === georeferenceNavIds.buildingId && floorId === georeferenceNavIds.floorId)
        {
            isOnMap = true;
            isGeoreference = true;
            isTransitionOnGeoreferenceMap = true;
        }

        routeNodesOnMapArray.push({ isOnMap, isGeoreference, isMain });
    });

    isBothRouteNodeOnMap = isTransitionOnMainMap && isTransitionOnGeoreferenceMap;

    return { isBothRouteNodeOnMap, routeNodesOnMapArray };

}