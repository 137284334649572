import React from "react";
import { ModalBox } from "../../common/ModalBox";
import { ButtonBasic } from "../../common/ButtonIcon";
import { get as getDeepValue } from "lodash";

import "./kioskPreviewModal.css";
import { DEFAULT_LANGUAGE_CODE } from "mapsted.maps/utils/map.constants";
import { filerUrl } from "../../../_utils/utils";
import CopyButton from "../../common/CopyButton";
import { ENVS } from "../../../_constants/constants";
import { PUBLISH_DATA_SCOPES } from "mapsted.utils/config";

export const KioskPreviewModal = ({
    isOpen,
    kiosks,
    onClose,
    kioskUrl,
    env,
}) => (
    <ModalBox 
        className="confirmActionModal kioskPreviewModal"
        open={isOpen}
        onClose={onClose}
        header="Select Kiosk to preview">
        <div className="kioskPreviewTable">
            <div className="kioskPreviewRow">
                <KioskPreviewTableItem content="NAME" />
                <KioskPreviewTableItem content="URL" />
                <KioskPreviewTableItem content="FLOOR" />
            </div>

            {kiosks.map((kiosk) =>
            {
                const floorName = getDeepValue(kiosk, `floor.shortName.${DEFAULT_LANGUAGE_CODE}`, "-");

                const kioskName = getDeepValue(kiosk, `entity.entityLabel.longName.${DEFAULT_LANGUAGE_CODE}`, "-");

                const filerId = getDeepValue(kiosk, `entity.entityLabel.iconImage.${DEFAULT_LANGUAGE_CODE}`, "");
                const iconUrl = filerUrl(filerId, 3600);

                const dataScope = env === ENVS.STAGING ? `?data=${PUBLISH_DATA_SCOPES.UAT}` : "";
                const kioskRedirectUrl = `${kioskUrl}/${kiosk.kioskId}${dataScope}`;

                return (
                    <div className="kioskPreviewRow" key={kiosk.kioskId}>
                        <KioskPreviewTableItem>
                            <img src={iconUrl} alt="" className="kioskImg"/>
                            <p>{kioskName}</p>
                        </KioskPreviewTableItem>
                        <KioskPreviewTableItem content={<CopyButton text={kioskRedirectUrl} />} />
                        <KioskPreviewTableItem content={floorName}>
                            <ButtonBasic icon="open" as="a" href={kioskRedirectUrl} target="_blank" />
                        </KioskPreviewTableItem>
                    </div>
                );
            })}
            
        </div>
    </ModalBox>
);


export const KioskPreviewTableItem = ({ content, children }) => (
    <div className="kioskpreviewtableItem">
        {content}
        {children}
    </div>
);