import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "semantic-ui-react";
import { TextGroup } from "../../../common/TextGroup";
import MapOverlaysOpacitySelector from "../mapOverlaysOpacitySelector/MapOverlaysOpacitySelector";
import { getPercentage, getValueFromPercentage } from "../utils/mapOverlayUtils";
import { PROPERTIES } from "../../../../_constants/config";

const OpacitySettings = ({ 
    color, 
    lineColor, 
    fillOpacity, 
    borderFillOpacity, 
    textOpactiy, 
    textColor,
    updateOpacitySettings, 
    disabled=false,
}) =>
{
    const [enteredFillOpacity, setEnteredFillOpacity] = useState(undefined);
    const [enteredBorderFillOpacity, setEnteredBorderFillOpacity] = useState(undefined);
    const [enteredTextOpacity, setEnteredTextOpacity] = useState(undefined);

    const trans = useTranslation().t;

    useEffect(() =>
    {
        setEnteredFillOpacity(undefined);
    }, [fillOpacity]);

    useEffect(() =>
    {
        setEnteredBorderFillOpacity(undefined);
    }, [borderFillOpacity]);

    useEffect(() =>
    {
        setEnteredTextOpacity(undefined);
    }, [textOpactiy]);

    const getValidOpacityValue = (value) =>
    {
        let validOpacityValue = value;
        if (value < 0)
        {
            validOpacityValue = 0; 
        }
        else if (value > 100)
        {
            validOpacityValue = 100;
        }
        return validOpacityValue;
    };

    let displayFillOpacity = getPercentage(fillOpacity);

    if (enteredFillOpacity || enteredFillOpacity === 0)
    {
        displayFillOpacity = enteredFillOpacity;
    }

    let displayBorderFillOpacity = getPercentage(borderFillOpacity);

    if (enteredBorderFillOpacity || enteredBorderFillOpacity === 0)
    {
        displayBorderFillOpacity = enteredBorderFillOpacity;
    }

    let displayTextOpacity = getPercentage(textOpactiy);

    if (enteredTextOpacity || enteredTextOpacity === 0)
    {
        displayTextOpacity = enteredTextOpacity;
    }

    return (
        <>
            <div className={"overlayTgRow"}>
                <p>{trans("OpacitySettings.Fill")} </p>
            </div>
            <div className="overlayTgRow">
                <div className="overlayTgColumn">
                    <TextGroup className="fillOpacityDisplay" >
                        <div className="overlayTgRow">
                            <div className="overlayTgColumn opacityInput">
                                <Input
                                    type="text" 
                                    disabled={disabled}
                                    pattern="[0-9]*"
                                    maxLength={PROPERTIES.MAX_OPACITY_LENGTH}
                                    value={displayFillOpacity} 
                                    onChange={(event, { value }) => setEnteredFillOpacity(+value)}
                                    onKeyPress={(event) =>
                                    {
                                        if (event.key === "Enter" && (enteredFillOpacity || enteredFillOpacity === 0))
                                        {
                                            let validOpacityValue = getValidOpacityValue(enteredFillOpacity);
                                            setEnteredFillOpacity(undefined);
                                            updateOpacitySettings("fillOpacity", getValueFromPercentage(validOpacityValue));
                                        } 
                                    }}
                                    onBlur={() =>
                                    {
                                        if (enteredFillOpacity || enteredFillOpacity === 0)
                                        {
                                            let validOpacityValue = getValidOpacityValue(enteredFillOpacity);
                                            setEnteredFillOpacity(undefined);
                                            updateOpacitySettings("fillOpacity", getValueFromPercentage(validOpacityValue));
                                        }
                                    }}
                                />
                            </div>
                            <div className="overlayTgColumn opacityInput percentSymbol">
                                <p>%</p>
                            </div>
                        </div>
                    </TextGroup>
                </div>
                <div className="overlayTgColumn opacityRangeContainer">
                    <MapOverlaysOpacitySelector 
                        backgroundColor={color} 
                        opacity={fillOpacity}
                        disabled={disabled}
                        onOpacityChange={(opacity) => updateOpacitySettings("fillOpacity", opacity)} />
                </div>
            </div>

            <div className={"overlayTgRow"}>
                <p>{trans("OpacitySettings.Border_Fill")} </p>
            </div>

            <div className="overlayTgRow">
                <div className="overlayTgColumn">
                    <TextGroup className="borderFillOpacityDisplay">
                        <div className="overlayTgRow">
                            <div className="overlayTgColumn opacityInput">
                                <Input
                                    type="text"
                                    pattern="[0-9]*"
                                    disabled={disabled}
                                    value={displayBorderFillOpacity}
                                    onChange={(event, { value }) => setEnteredBorderFillOpacity(+value)}
                                    onKeyPress={(event) =>
                                    {
                                        if (event.key === "Enter" && (enteredBorderFillOpacity || enteredBorderFillOpacity === 0))
                                        {
                                            let validOpacityValue = getValidOpacityValue(enteredBorderFillOpacity);
                                            setEnteredBorderFillOpacity(undefined);
                                            updateOpacitySettings("borderFillOpacity", getValueFromPercentage(validOpacityValue));
                                        } 
                                    }}
                                    onBlur={() =>
                                    {
                                        if (enteredBorderFillOpacity || enteredBorderFillOpacity === 0)
                                        {
                                            let validOpacityValue = getValidOpacityValue(enteredBorderFillOpacity);
                                            setEnteredBorderFillOpacity(undefined);
                                            updateOpacitySettings("borderFillOpacity", getValueFromPercentage(validOpacityValue));
                                        }
                                    }}
                                />
                            </div>
                            <div className="overlayTgColumn opacityInput percentSymbol">
                                <p>%</p>
                            </div>
                        </div>
                    </TextGroup>
                </div>
                <div className="overlayTgColumn opacityRangeContainer">
                    <MapOverlaysOpacitySelector 
                        backgroundColor={lineColor} 
                        opacity={borderFillOpacity}
                        disabled={disabled}
                        onOpacityChange={(opacity) => updateOpacitySettings("borderFillOpacity", opacity)} />
                </div>
            </div>


            <div className={"overlayTgRow"}>
                <p>{trans("OpacitySettings.Text")} </p>
            </div>

            <div className="overlayTgRow">
                <div className="overlayTgColumn">
                    <TextGroup className="textOpacityDisplay">
                        <div className="overlayTgRow">
                            <div className="overlayTgColumn opacityInput">
                                <Input
                                    type="text"
                                    pattern="[0-9]*"
                                    disabled={disabled}
                                    value={displayTextOpacity}
                                    onChange={(event, { value }) => setEnteredTextOpacity(+value)}
                                    onKeyPress={(event) =>
                                    {
                                        if (event.key === "Enter" && (enteredTextOpacity || enteredTextOpacity === 0))
                                        {
                                            let validOpacityValue = getValidOpacityValue(enteredTextOpacity);
                                            setEnteredTextOpacity(undefined);
                                            updateOpacitySettings("textOpacity", getValueFromPercentage(validOpacityValue));
                                        } 
                                    }}
                                    onBlur={() =>
                                    {
                                        if (enteredTextOpacity || enteredTextOpacity === 0)
                                        {
                                            let validOpacityValue = getValidOpacityValue(enteredTextOpacity);
                                            setEnteredTextOpacity(undefined);
                                            updateOpacitySettings("textOpacity", getValueFromPercentage(validOpacityValue));
                                        }
                                    }}
                                />
                            </div>
                            <div className="overlayTgColumn opacityInput percentSymbol">
                                <p>%</p>
                            </div>
                        </div>
                    </TextGroup>
                </div>
                <div className="overlayTgColumn opacityRangeContainer">
                    <MapOverlaysOpacitySelector 
                        backgroundColor={textColor} 
                        opacity={textOpactiy}
                        disabled={disabled}
                        onOpacityChange={(opacity) => updateOpacitySettings("textOpacity", opacity)} />
                </div>
            </div>
        </>);
};

export default OpacitySettings;