import React, { forwardRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "./ImageCropper.css";

const ImageCropper = forwardRef(({ aspectRatio, imageSrc }, ref) => (
    <div className="image-cropper-box">
        <Cropper
            ref={ref}
            style={{ height: "450px", width: "100%" }}
            autoCropArea={1}
            aspectRatio={aspectRatio}
            guides={false}
            viewMode={1}
            src={imageSrc}
        />
    </div>
));

ImageCropper.displayName = "ImageCropper";

export default ImageCropper;