import "flatpickr/dist/themes/material_green.css";
import React, { useEffect, useRef } from "react";
import Flatpickr from "react-flatpickr";
import "./ReactFlatPicker.css";

function formatAMPM(date) 
{
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0"+minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
}
  
export default function ReactFlatPicker({ name, value, onChange })
{
    const flatpickrRef = useRef(null);
    useEffect(() => 
    {
        // to remove time selector popup when scrolling 
        const handleScroll = () => 
        {
            const flatpickrInstance = flatpickrRef.current?.flatpickr;
            if (flatpickrInstance && flatpickrInstance.isOpen && flatpickrInstance.timeContainer) 
            {
                flatpickrInstance.close();
            }
        };
        window.addEventListener("scroll", handleScroll, true);
        return () => 
        {
            window.removeEventListener("scroll", handleScroll, true);
        };
    }, []);

    return (
        <Flatpickr
            options={{
                enableTime: true,
                noCalendar: true,
                dateFormat: "h:i K",
                time_24hr: false
            }}
            ref={flatpickrRef}
            data-enable-time
            id="reactFlatPicker"
            style={{
                padding: "5px",
                borderRadius: "5px",
                width: "90%"
            }}
            value={value}
            onChange={([date]) => 
            {
                let currVal = formatAMPM(date);
                onChange([date] , { name, value: currVal });
            }}
        />
    );
}