import React, { Component, useContext, useEffect, useMemo } from "react";
import ShowMoreText from "react-show-more-text";
import { Button, Header, Checkbox, TextArea, Input, Dropdown, Accordion, Popup, Icon } from "semantic-ui-react";
import { deepUpdateValue, deepValue } from "mapsted.utils/objects";
import { isEmpty } from "lodash";
import { DefaultDetailSection, DefaultSection, EditPencil, KeyWordsDisplay } from "./EditSideBarComponents";
import InformationBoxHeader from "./InformationBoxHeader";
import { handleNormalizeUrl, mergeOverlapingPartialHours, validatePartialHours, openingHoursPeriodsToGroupedList } from "../../../_utils/utils";
import { entityLabelSchema, entityLabelPOISchema } from "../../../_constants/validationSchemas";
import { ErrorLabel } from "../../elements/ErrorLabel";
import serverAPI from "../../../_api/server.api";
import { ViewWeekdayHours, EditWeekdayHours, updateOpeningHours, addPeriod, removePeriod } from "../../elements/OpeningHours";
import { weekdays } from "moment";

import "./EditSidebarPortal.css";
import css from "./common.module.css";
import style from "./EditSidebarPortal.module.css";
import { CategoryContext } from "../../../store/CategoryProvider";
import "./InformationBox.css";
import { accordionIndex } from "../../../_constants/branding";
import { VacantModal } from "../vacantModal/VacantModal";
import { DEFAULT_LANGUAGE_CODE, ENTITY_LABEL_TYPES, SINGLE_LANG_INPUT_CODE } from "../../../_constants/constants";
import { Transl } from "../../../_intl/i18n";
import { useTranslation, withTranslation } from "react-i18next";
import { OptionMapDropdown } from "./OptionMapDropdown";
import { getEntityLabelLanguages } from "../../../_intl/constants";
import { withRouter, useHistory } from "react-router-dom";
import { StoreSharingContext } from "../../../store/StoreSharingProvider";
import { CalendarModal } from "../../popups/CalendarModal";
import { useHolidayFunctions } from "../../../store/holidayFunctions";
import LanguageSelector from "../../common/LanguageSelector";
import { PROPERTIES, KEYWORDS } from "../../../_constants/config";
import { ButtonIcon } from "../../common/ButtonIcon";
import { useDeleteInventory, useEntityInvetories } from "../../pages/branding/listing/functions";
import { ModalLayout } from "../../popups/ModalLayout";
import { EntityType } from "mapsted.maps/utils/entityTypes";
import { ReadMoreButton } from "../../PropertyOverview/BuildingBoxHead";
import { useCurrentProperty } from "../../../store/BrandingContext";
import { OnlineKeywordUploadModal } from "../../pages/branding/listing/modals/OnlineKeywordUploadModal";
import CopyButton from "../../common/CopyButton";

const infoBoxTypesObj = "information box types";
const defaultTypesOptions = [
    { key: 1, text: <Transl componentObjName={infoBoxTypesObj} keyName="store" />, value: 1 },
    { key: 2, text: <Transl componentObjName={infoBoxTypesObj} keyName="office" />, value: 2 },
    { key: 3, text: <Transl componentObjName={infoBoxTypesObj} keyName="room" />, value: 3 },
];

const InventoryCount = ({ inventoryLength }) =>
{
    const trans = useTranslation().t;
    return (
        <div>
            {trans("InformationBox.Number_of_items", { count: inventoryLength })}
        </div>
    );
};

export const InformationBox = ({
    isEdit,
    isBuilding,
    canPlotImage,
    entityLabel,
    entity,
    entityStyle,
    buildingHours,
    onSave,
    onToggleEdit,
    onChangeStyle,
    displayLanguage,
    setDisplayLanguage,
    isStore
}) =>
{
    const trans = useTranslation().t;
    const ctx = React.useContext(StoreSharingContext);
    const { methods: { setState } } = ctx;
    const [entityLabelDisplay, setEntityLabelDisplay] = React.useState(entityLabel);
    const { getEntityHolidays, updateEntityHolidays, getBuildingHolidays, updateBuildingHolidays } = useHolidayFunctions();

    const { data: inventory } = useEntityInvetories(entity?.entityLabel?._id);
    const deleteInventoryMutation = useDeleteInventory();
    const property = useCurrentProperty();

    useEffect(() =>
    {
        setEntityLabelDisplay((prev) => ({ ...prev, subCategories: entityLabel.subCategories }));
    }, [entityLabel?.subCategories?.length]);

    useEffect(() =>
    {
        let entityLabelUpdate = { ...entityLabel };

        let partialOpeningHours = { periods: [] };

        if (!!entityLabelUpdate && !!entityLabelUpdate.partialOpeningHours)
        {
            partialOpeningHours = JSON.parse(JSON.stringify(entityLabelUpdate.partialOpeningHours));
        }

        let groupedList = openingHoursPeriodsToGroupedList(partialOpeningHours.periods);

        partialOpeningHours.partialOpeningHours = groupedList;

        delete partialOpeningHours.periods;

        entityLabelUpdate.partialOpeningHours = partialOpeningHours;

        setEntityLabelDisplay(JSON.parse(JSON.stringify(entityLabelUpdate)));

    }, [entityLabel, isEdit]);

    const isPointOfIntrest = (entity?.entityType === EntityType?.POINT_OF_INTEREST);

    const renderDetail = () =>
    {
        if (!isEdit) 
        {
            return (
                <InformationBoxDisplay
                    entityLabel={entityLabelDisplay}
                    displayLanguage={displayLanguage}
                    setDisplayLanguage={setDisplayLanguage}
                    getEntityHolidays={isStore ? getEntityHolidays : getBuildingHolidays}
                    updateEntityHolidays={isStore ? updateEntityHolidays : updateBuildingHolidays}
                    inventoryLength={inventory?.length || 0}
                    isStore={isStore}
                    isPointOfIntrest={isPointOfIntrest}
                    entityId={entity.entityId}
                    buildingId={entityLabel.buildingId}
                />
            );


        }
        else 
        {
            return (
                <InformationBoxEdit
                    isBuilding={isBuilding}
                    entityLabel={entityLabelDisplay}
                    canPlotImage={canPlotImage}
                    onSaveChanges={onSave}
                    buildingHours={buildingHours}
                    displayLanguage={displayLanguage}
                    setDisplayLanguage={setDisplayLanguage}
                    getEntityHolidays={isStore ? getEntityHolidays : getBuildingHolidays}
                    updateEntityHolidays={isStore ? updateEntityHolidays : updateBuildingHolidays}
                    inventoryLength={inventory?.length || 0}
                    deleteInventory={deleteInventoryMutation}
                    countryCode={property.country}
                    isStore={isStore}
                    isPointOfIntrest={isPointOfIntrest}
                    entityId={entity?.entityId}
                    buildingId={entityLabel?.buildingId}
                />
            );


        }
    };

    return (
        <DefaultSection>
            {(isStore) && (
                <Popup
                    position="bottom right"
                    offset="10px 10px"
                    className="shareStorePopover"
                    trigger={
                        <Button className="buttonIcon buttonOutside"
                            onClick={() =>
                            {
                                setState({ openWithStore: entity, openShareStores: true });
                            }}>
                            <img src="/img/icon-outside.svg" alt="" />
                        </Button>}
                    content={trans("InformationBox.Share_this_store_by_adding_it_to_a_new_S")}
                />
            )}
            <InformationBoxHeader
                edit={isEdit}
                entityLabel={entityLabelDisplay}
                canPlotImage={canPlotImage}
                isStore={isStore}
            />

            <div className="aligner">
                <EditPencil isTrigger={!isEdit ? "pencil" : "cross"} onClick={(onToggleEdit)} isInside />
                <OptionMapDropdown
                    values={entityStyle[displayLanguage]}
                    onChange={onChangeStyle}
                    canPlotImage={canPlotImage}
                    // behaviour={isPointOfIntrest ? OptionMapDropdown.BEHAVIOURS.AT_LEAST_ONE : OptionMapDropdown.BEHAVIOURS.FREE}
                />
                {renderDetail()}
            </div>

        </DefaultSection>
    );
};

const InformationBoxDisplay = ({ 
    entityLabel = {}, 
    displayLanguage, 
    setDisplayLanguage, 
    getEntityHolidays, 
    updateEntityHolidays, 
    inventoryLength, 
    isStore,
    isPointOfIntrest, 
    entityId,
    buildingId,
}) =>
{
    const genericTrans = useTranslation().t;
    /** @type {typeof import("../../../_intl/resources.json")["en"]["translation"]["information box display"]} */
    const trans = genericTrans("information box display");
    const [activeIndex, setActiveIndex] = React.useState(0);

    const { categoryFlatMap } = useContext(CategoryContext);
    const history = useHistory();

    const renderDisplaySubCategoryNames = React.useMemo(() =>
    {
        // we dont use categories for property entities.
        if (isStore)
        {
            const subCategoryNames = entityLabel.subCategories.map((cat) => cat._id)
                .map((id) => categoryFlatMap[id])
                .filter((cat) => cat)                       // filter out non-existing
                .map(({ name }) => name?.[displayLanguage] || name?.[DEFAULT_LANGUAGE_CODE] || genericTrans("utils.NO_DATA"))
                .join(", ");

            return subCategoryNames;
        }

    }, [entityLabel, isStore, displayLanguage]);

    const handleAccordionClick = (e, titleProps) =>
    {
        const { index } = titleProps;
        const newIndex = activeIndex === index ? -1 : index;

        setActiveIndex(newIndex);
    };

    const getEntityType = React.useCallback(() =>
    {
        if (!isStore)
        {
            return ENTITY_LABEL_TYPES[deepValue(entityLabel, "entityLabelType", 0)];
        }
        else
        {
            return ENTITY_LABEL_TYPES[deepValue(entityLabel, "entityLabelType", 1)];
        }
    }, [entityLabel, isStore]);

    const labelLanguages = useMemo(() => getEntityLabelLanguages(entityLabel), [entityLabel]);

    const category = categoryFlatMap[entityLabel?.category?._id];
    return (
        <Accordion fluid className="accordionBranding">
            <LanguageSelector
                activeLang={displayLanguage}
                setActiveLang={setDisplayLanguage}
                defaultDisplayedLangs={labelLanguages}
                allowAdditions={false}
            />
            <AccordionSection
                active={activeIndex === accordionIndex.BASIC_INFORMATION}
                index={accordionIndex.BASIC_INFORMATION}
                onClick={handleAccordionClick}
                title={trans["accordion title"]}
            >
                { 
                    !!entityId 
                    && <>
                        <p className="entityIdDisplay">EntityId: {entityId}</p>
                        <CopyButton 
                            text={entityId} 
                            btnClassname="entityIdCopyBtn"
                            popupText={trans["Copy_Id_Text"]}
                        />
                    </>
                }
                {
                    !!buildingId
                    && <>
                        <p className="buildingIdDisplay">BuildingId: {buildingId}</p>
                        <CopyButton 
                            text={buildingId} 
                            btnClassname="entityIdCopyBtn"
                            popupText={trans["Copy_Id_Text"]}
                        />
                    </>
                }
                <Header className="nameHead" content={entityLabel.longName?.[displayLanguage]} />
                <b className="short-heading">{entityLabel.shortName?.[displayLanguage]}</b>
                <p className="p-description">
                    <ShowMoreText
                        lines={4}
                        more={<ReadMoreButton />}
                        less={<ReadMoreButton collapse />}
                        keepNewLines
                    >
                        {entityLabel.description?.[displayLanguage] || ""}
                    </ShowMoreText>
                </p>

                <DefaultDetailSection title={trans["phone title"]} detail={entityLabel?.phoneNumber || entityLabel?.phoneNumbers?.[0]} />
                <DefaultDetailSection title={trans["website title"]} detail={entityLabel.website?.[displayLanguage]} />

                {(isStore) && (
                    <div>
                        <DefaultDetailSection
                            title={trans["category title"]}
                            detail={(category) && (category.name?.[displayLanguage] || category.name?.[DEFAULT_LANGUAGE_CODE] || genericTrans("utils.NO_DATA"))}
                        />
                        <DefaultDetailSection
                            title={trans["subcategories label"]}
                            detail={renderDisplaySubCategoryNames}
                        />
                    </div>
                )}

                { isPointOfIntrest && <DefaultDetailSection title={trans["type title"]} detail={"Point of Interest"} /> }
            </AccordionSection>
            {(isStore) && (
                <AccordionSection
                    active={activeIndex === accordionIndex.INVENTORY}
                    index={accordionIndex.INVENTORY}
                    onClick={handleAccordionClick}
                    title={<>{trans["inventory title"]}<Popup content={genericTrans("InformationBox.Inventory_changes_are_saved_separately_f")} trigger={<Icon name="help circle" color="blue" />} /></>}
                >
                    <div className={style.distributedText}>
                        <InventoryCount inventoryLength={inventoryLength} />
                        <ButtonIcon className="inventoryButton" icon="plus-add" onClick={() => history.push("/branding/inventory")} />
                    </div>
                </AccordionSection>
            )}
            <AccordionSection
                active={activeIndex === accordionIndex.KEYWORDS}
                index={accordionIndex.KEYWORDS}
                onClick={handleAccordionClick}
                title={trans["keywords title"] + ` (limit ${KEYWORDS.MAX_NUMBER})`}
            >
                <DefaultDetailSection className="nonEdited" title={trans["keywords desc"]}>
                    <KeyWordsDisplay options={entityLabel.keywords?.[displayLanguage]} />
                </DefaultDetailSection>
            </AccordionSection>

            <AccordionSection
                active={activeIndex === accordionIndex.ONLINE_KEYWORDS}
                index={accordionIndex.ONLINE_KEYWORDS}
                onClick={handleAccordionClick}
                title={trans["online_keywords_title"]}
            >
                <DefaultDetailSection className="nonEdited" title={trans["online_keywords_desc"]}>
                    <KeyWordsDisplay options={entityLabel.onlineKeywords?.[displayLanguage]} />
                </DefaultDetailSection>
            </AccordionSection>

            <AccordionSection
                active={activeIndex === accordionIndex.OPENING_HOURS}
                index={accordionIndex.OPENING_HOURS}
                onClick={handleAccordionClick}
                title={trans["hours title"]}
            >
                <PartialOpeningHours openingHours={entityLabel.partialOpeningHours} isEdit={false} />
                <CalendarModal
                    isProperty={false}
                    selectedObject={entityLabel}
                    onGetHolidays={(year) => getEntityHolidays(entityLabel._id, year)}
                    onEditHoliday={(holiday, remove) => updateEntityHolidays(entityLabel._id, holiday, remove)}
                    onNewHoliday={console.log}      // This one is not used
                    triggerClassName="edit-sidebar-calendar"
                />
            </AccordionSection>

            <AccordionSection active={activeIndex === accordionIndex.SOCIAL_LINKS} index={accordionIndex.SOCIAL_LINKS} onClick={handleAccordionClick}
                title={trans["social title"]}>
                <SocialLinks socialMedia={entityLabel.socialMedia || {}} />
            </AccordionSection>

        </Accordion>
    );
};

class InformationBoxEditImpl extends Component
{
    static contextType = CategoryContext;


    constructor(props)
    {
        super();
        // Default values
        props.entityLabel.description = props.entityLabel.description || { [DEFAULT_LANGUAGE_CODE]: "" };
        props.entityLabel.keywords = props.entityLabel.keywords || { [DEFAULT_LANGUAGE_CODE]: [] };
        props.entityLabel.shortName = props.entityLabel.shortName || { [DEFAULT_LANGUAGE_CODE]: "" };
        props.entityLabel.category = props.entityLabel.category || {};
        props.entityLabel.entityLabelType = props.entityLabel.entityLabelType || 1;
        props.entityLabel.onlineKeywords = props.entityLabel.onlineKeywords || { [DEFAULT_LANGUAGE_CODE]: [] };


        this.state = {
            entityLabelType: defaultTypesOptions[0].value,
            entityLabel: props.entityLabel,
            categoryOptions: [],
            selectedSubCategories: [],
            keywordInputText: "",
            keywordOptions: [],
            validationErrors: {},
            partialOpeningHoursValidationErrors: {},
            activeIndex: 0,
            subCategories: [],
            canPlotImage: props.canPlotImage,
            confirmInventoryOpen: false,
            isStore: props.isStore,
            onlineKeywordInputText: "",
            onlineKeywordOptions: [],
        };
    }

    componentDidMount()
    {
        const { entityLabel, isStore } = this.state;
        const { categoryFlatMap: categories, getParentOf } = this.context;
        const { displayLanguage } = this.props;

        let state = {};

        // we dont care about categories for non store entities.
        if (isStore)
        {
            let categoryList = Object.values(categories).filter((c) => !c.isRoot && getParentOf(c) && getParentOf(c).isRoot).sort();

            state.categoryOptions = categoryList
                .map((s) => ({
                    text: deepValue(s, `name.${displayLanguage}`, undefined) || deepValue(s, `name.${SINGLE_LANG_INPUT_CODE}`, ""),
                    value: s._id,
                    key: s._id
                }))
                .sort((a, b) => a.text.localeCompare(b.text));

            state.categoryOptions.unshift({ text: "None", value: "", key: "no category key" });


            this.updateSubCategoryOptions();

            state.selectedSubCategories = [];

            entityLabel.subCategories.forEach((subCategory) =>
            {
                state.selectedSubCategories.push(subCategory._id);
            });
        }

        this.updateKeywordOptions();

        this.updateOnlineKeywordOptions();

        this.setState(state);
    }

    componentDidUpdate(prevProps)
    {
        if (!deepValue(this.state, "entityLabel.partialOpeningHours.partialOpeningHours", undefined) && !!deepValue(this.props, "entityLabel.partialOpeningHours.partialOpeningHours", undefined))
        {
            let entityLabel = { ...this.state.entityLabel };

            entityLabel.partialOpeningHours = this.props.entityLabel.partialOpeningHours;

            this.setState({ entityLabel });
        }
        if (prevProps.displayLanguage !== this.props.displayLanguage)
        {
            this.updateCategoryOptions();
            this.updateSubCategoryOptions();
            this.updateKeywordOptions();
            this.updateOnlineKeywordOptions();
        }

    }

    updateKeywordOptions()
    {
        const { displayLanguage } = this.props;
        const { entityLabel } = this.state;

        const keywordOptions = this.createKeywordOptions(entityLabel.keywords?.[displayLanguage]);
        this.setState({ keywordOptions });
    }

    updateOnlineKeywordOptions ()
    {
        const { displayLanguage } = this.props;
        const { entityLabel } = this.state;

        const onlineKeywordOptions = this.createKeywordOptions(entityLabel.onlineKeywords?.[displayLanguage]);
        this.setState({ onlineKeywordOptions });
    }

    updateCategoryOptions ()
    {
        const { categoryFlatMap: categories, getParentOf } = this.context;
        const { displayLanguage } = this.props;
        let categoryList = Object.values(categories).filter((c) => !c.isRoot && getParentOf(c) && getParentOf(c).isRoot).sort();
        const updatedCategories = categoryList
            .map((s) => ({
                text: deepValue(s, `name.${displayLanguage}`, undefined) || deepValue(s, `name.${SINGLE_LANG_INPUT_CODE}`, ""),
                value: s._id,
                key: s._id
            }))
            .sort((a, b) => a.text.localeCompare(b.text));

        this.setState({ categories: updatedCategories });
    }

    updateSubCategoryOptions()
    {
        const { entityLabel } = this.state;
        const { displayLanguage } = this.props;
        const newOptions = Object.values(this.context.categoryFlatMap)
            .filter((sub) => sub._id !== entityLabel?.category?._id && !sub.isRoot)
            .map((s) =>
            {
                const text = deepValue(s, `name.${displayLanguage}`, undefined) || deepValue(s, `name.${SINGLE_LANG_INPUT_CODE}`, "");
                return {
                    text,
                    value: s._id,
                    key: s._id
                };
            })
            .sort((a, b) => a.text.localeCompare(b.text));

        this.setState({ subCategories: newOptions });
    }

    getSubCategoryOptions = () =>
    {
        const { subCategories, entityLabel } = this.state;

        const selectedCategoryId = deepValue(entityLabel, "category._id", undefined);

        return subCategories.filter((option) => option.value !== selectedCategoryId);
    }

    getEntityType = () =>
    {
        const { entityLabel, isStore } = this.state;
        if (!isStore)
        {
            return ENTITY_LABEL_TYPES[deepValue(entityLabel, "entityLabelType", 0)];
        }
        else
        {
            return ENTITY_LABEL_TYPES[deepValue(entityLabel, "entityLabelType", 1)];
        }
    };

    handleInputChange = (e, { name, value }) =>
    {
        //update entity label
        let entityLabel = { ...this.state.entityLabel };

        deepUpdateValue(entityLabel, name, value);

        if (name === "isHoursLinkedToRef" && value === true)
        {
            // get building hours, format to proper hours
            let partialOpeningHours = JSON.parse(JSON.stringify(this.props.buildingHours));

            let groupedList = openingHoursPeriodsToGroupedList(partialOpeningHours.periods);

            partialOpeningHours.partialOpeningHours = groupedList;

            delete partialOpeningHours.periods;

            entityLabel.partialOpeningHours = partialOpeningHours;

        }

        this.setState({ entityLabel }, () =>
        {
            this.validateAllData();
        });
    }

    handleSaveChanges = async () =>
    {
        let entityLabel = { ...this.state.entityLabel };

        const validateEntityLabel = await this.validateAllData(true);
        const { validation, partialOpeningHours } = await this.validatePartialOpeningHours();

        let partialOpeningHoursUpdate = entityLabel.partialOpeningHours;

        if (partialOpeningHoursUpdate)
        {
            partialOpeningHoursUpdate.partialOpeningHours = partialOpeningHours;
        }
        else
        {
            partialOpeningHoursUpdate = { partialOpeningHours };
        }

        if (!!validateEntityLabel && Object.keys(validation).length === 0)
        {
            const longNameChanged = this.props.entityLabel?.longName?.[DEFAULT_LANGUAGE_CODE] !== this.state.entityLabel?.longName?.[DEFAULT_LANGUAGE_CODE];
            const hasInventory = this.props?.inventoryLength > 0;
            if (longNameChanged && hasInventory && !this.state.confirmInventoryOpen)
            {
                this.setState({ confirmInventoryOpen: true });
            }
            else
            {
                entityLabel.longName[this.props.displayLanguage] = entityLabel.longName[this.props.displayLanguage]?.trim() || "";
                this.props.onSaveChanges(entityLabel);
                this.setState({ confirmInventoryOpen: false });
            }
        }
        else
        {
            this.setState({ entityLabel, partialOpeningHoursValidationErrors: validation });
        }
    }

    deleteInventory = () =>
    {
        this.props.deleteInventory.mutateAsync(this.state?.entityLabel?._id).then(() =>
        {
            const entityLabel = { ...this.state.entityLabel };
            entityLabel.inventory = [];
            this.setState({ entityLabel }, () => this.handleSaveChanges());
        });
    };

    handleChangeCategory = (e, { value }) =>
    {
        const { categoryFlatMap: categories } = this.context;
        let updatedSubCategories = deepValue(this.state, "subCategories", []);
        let updatedSelectedSubCategories = deepValue(this.state, "selectedSubCategories", []);

        let entityLabel = { ...this.state.entityLabel };
        if (value === "")
        {
            entityLabel.category = { _id: null, name: { [DEFAULT_LANGUAGE_CODE]: "None" } };
            return this.setState({ entityLabel });
        }

        const selectedCategory = categories[value];

        if (!Array.isArray(updatedSubCategories))
        {
            updatedSubCategories = [];
        }

        if (!Array.isArray(updatedSelectedSubCategories))
        {
            updatedSelectedSubCategories = [];
        }

        if (selectedCategory)
        {
            entityLabel.category = selectedCategory;
            this.setState({
                entityLabel,
                selectedSubCategories: updatedSelectedSubCategories.filter((sub) => sub !== selectedCategory._id),
            });
        }
    }

    handleChangeSubCategory = (e, { value: categoryUIDs }) =>
    {
        const { getCategoriesFromIds } = this.context;
        let entityLabel = { ...this.state.entityLabel };

        let selectedSubCategories = getCategoriesFromIds(categoryUIDs);

        entityLabel.subCategories = selectedSubCategories;

        this.setState({ entityLabel, selectedSubCategories: categoryUIDs });
    }

    handleChangeKeywords = (e, { value }) =>
    {
        let entityLabel = { ...this.state.entityLabel };

        entityLabel.keywords[this.props.displayLanguage] = value;

        const keywordOptions = this.createKeywordOptions(value);

        this.setState({ entityLabel, keywordOptions, keywordInputText: "" });
    }

    handleOnlineKeywordsChange = (e, { value }) =>
    {
        let entityLabel = { ...this.state.entityLabel };

        if (entityLabel.onlineKeywords)
        {
            entityLabel.onlineKeywords[this.props.displayLanguage] = value;
        }
        else
        {
            entityLabel.onlineKeywords = {
                [this.props.displayLanguage]: value
            };
        }

        const onlineKeywordOptions = this.createKeywordOptions(value);

        this.setState({ entityLabel, onlineKeywordOptions, onlineKeywordInputText: "" });
    };

    handleOnlineKeywordsUpload = (newOnlineKeywords, lang) =>
    {
        let entityLabel = { ...this.state.entityLabel };

        entityLabel.onlineKeywords[lang] = newOnlineKeywords;

        const onlineKeywordOptions = this.createKeywordOptions(newOnlineKeywords);

        this.setState({ entityLabel, onlineKeywordOptions, onlineKeywordInputText: "" });
    };

    createKeywordOptions = (keywords = []) =>
    {
        let keywordOptions = [];

        if (Array.isArray(keywords))
        {
            keywords.forEach((keyword, i) =>
            {
                keywordOptions.push({
                    text: keyword,
                    id: keyword + i,
                    value: keyword
                });
            });
        }

        return keywordOptions;
    }

    validateAllData = async (validatePhoneWithApi = false) =>
    {
        let validateEntityLabel = { ...this.state.entityLabel };
        try
        {
            const website = validateEntityLabel.website || {};
            website[this.props.displayLanguage] = handleNormalizeUrl(validateEntityLabel?.website?.[this.props.displayLanguage]);
            validateEntityLabel.website = website;

            Object.keys(validateEntityLabel.socialMedia || {}).forEach((mediaType) =>
            {
                if (validateEntityLabel.socialMedia[mediaType])
                {
                    validateEntityLabel.socialMedia[mediaType] = handleNormalizeUrl(validateEntityLabel.socialMedia[mediaType]);
                }
            });
            let phoneNumberCheck = !this.props.isBuilding ? validateEntityLabel.phoneNumbers?.[0] : validateEntityLabel.phoneNumber ;
            // Note phone is optional for entity label, but should be validated if given.
            if (!phoneNumberCheck || !validatePhoneWithApi)
            {
                validateEntityLabel.phoneCheck = true;
            }
            else if (validatePhoneWithApi)
            {
                validateEntityLabel.phoneCheck = await serverAPI.validatePhoneNumber(phoneNumberCheck, this.props?.countryCode);
            }

            await entityLabelSchema.validate(validateEntityLabel, { abortEarly: false });

            if (Object.keys(this.state.validationErrors).length !== 0)
            {
                this.setState({ validationErrors: {} });
            }

            return validateEntityLabel;
        }
        catch (err)
        {
            if (err && err.name === "ValidationError")
            {
                let validationErrors = {};

                err.inner.forEach((currentError) =>
                {
                    console.log("currentError", currentError);
                    validationErrors[currentError.path] = currentError.message;
                });

                this.setState({ validationErrors });
                return;
            }
        }
    }

    validatePartialOpeningHours = () =>
    {
        let entityLabel = { ...this.state.entityLabel };
        let partialOpeningHours = undefined;

        if (entityLabel.partialOpeningHours)
        {
            partialOpeningHours = JSON.parse(JSON.stringify(entityLabel.partialOpeningHours));
        }

        if (partialOpeningHours)
        {
            const mergedPartialHours = mergeOverlapingPartialHours(partialOpeningHours.partialOpeningHours);

            const validation = validatePartialHours(mergedPartialHours);

            partialOpeningHours.partialOpeningHours = mergedPartialHours;

            return ({ validation, partialOpeningHours: mergedPartialHours });

        }

        return { validation: [], partialOpeningHours };
    }

    handleHoursUpdate = (day, periodIndex, value, prop) =>
    {
        let entityLabel = { ...this.state.entityLabel };
        let partialOpeningHoursValidationErrors = { ...this.state.partialOpeningHoursValidationErrors };
        let partialOpeningHours = (entityLabel.partialOpeningHours) ? JSON.parse(JSON.stringify(entityLabel.partialOpeningHours)) : {};

        const result = updateOpeningHours({ openingHours: partialOpeningHours, validationErrors: partialOpeningHoursValidationErrors, day, periodIndex, value, prop });

        partialOpeningHoursValidationErrors = result.validationErrors;
        partialOpeningHours = result.openingHours;

        entityLabel.partialOpeningHours = partialOpeningHours;
        entityLabel.isHoursLinkedToRef = false;

        this.setState({ entityLabel, partialOpeningHoursValidationErrors });
    }

    handleAddPeriod = (day) =>
    {

        let entityLabel = { ...this.state.entityLabel };
        let partialOpeningHoursValidationErrors = { ...this.state.partialOpeningHoursValidationErrors };

        let partialOpeningHours = { partialOpeningHours: {} };

        if (entityLabel.partialOpeningHours)
        {
            partialOpeningHours = JSON.parse(JSON.stringify(entityLabel.partialOpeningHours));
        }

        const result = addPeriod({ openingHours: partialOpeningHours, partialOpeningHoursValidationErrors, day });

        partialOpeningHoursValidationErrors = result.validationErrors;
        partialOpeningHours = result.openingHours;

        entityLabel.partialOpeningHours = partialOpeningHours;
        entityLabel.isHoursLinkedToRef = false;

        this.setState({ entityLabel, partialOpeningHoursValidationErrors });
    }

    handleRemovePeriod = (day, periodIndex) =>
    {

        let entityLabel = { ...this.state.entityLabel };
        let partialOpeningHoursValidationErrors = { ...this.state.partialOpeningHoursValidationErrors };
        let partialOpeningHours = JSON.parse(JSON.stringify(entityLabel.partialOpeningHours));

        const result = removePeriod({ openingHours: partialOpeningHours, partialOpeningHoursValidationErrors, day, periodIndex });

        partialOpeningHoursValidationErrors = result.validationErrors;
        partialOpeningHours = result.openingHours;

        entityLabel.partialOpeningHours = partialOpeningHours;

        this.setState({ entityLabel, partialOpeningHoursValidationErrors });
    }

    handleAccordionClick = (e, titleProps) =>
    {
        const { index } = titleProps;
        const { activeIndex } = this.state;
        const newIndex = activeIndex === index ? -1 : index;

        this.setState({ activeIndex: newIndex });
    }

    doesSectionHaveAnError = (section) =>
    {
        const { keywords, socialMedia } = this.state.validationErrors;
        const validationErrorKeys = Object.keys(this.state.validationErrors);
        const partialOpeningHoursValidationErrors = this.state.partialOpeningHoursValidationErrors;

        switch (section)
        {
        case (accordionIndex.BASIC_INFORMATION):
        {
            const fields = ["longName", "shortName", "phoneCheck", "website", "category", "subCategories"];
            if (validationErrorKeys.some((key) => fields.some((field) => key.includes(field))))
            {
                console.log("!");
                return true;
            }
            break;
        }
        case (accordionIndex.KEYWORDS):
        {
            if (keywords)
            {
                return true;
            }

            break;
        }
        case (accordionIndex.OPENING_HOURS):
        {
            if (Object.keys(partialOpeningHoursValidationErrors).length > 0)
            {
                return true;
            }

            break;
        }
        case (accordionIndex.SOCIAL_LINKS):
        {
            if (!!socialMedia && Object.keys(socialMedia).length > 0)
            {
                return true;
            }

            break;
        }
        default:
            break;
        }

        return false;
    }

    render()
    {
        const { displayLanguage, setDisplayLanguage, history, getEntityHolidays, updateEntityHolidays, inventoryLength } = this.props;
        const {
            entityLabel,
            categoryOptions,
            selectedSubCategories,
            keywordInputText,
            keywordOptions,
            validationErrors,
            partialOpeningHoursValidationErrors,
            activeIndex,
            confirmInventoryOpen,
            isStore,
            onlineKeywordInputText,
            onlineKeywordOptions,
        } = this.state;
        /** @type {typeof import("../../../_intl/resources.json")["en"]["translation"]["information box edit"]} */
        const trans = this.props.t("information box edit");
        const genericTrans = this.props.t;
        const maxKeywords = entityLabel.keywords?.[displayLanguage]?.length >= KEYWORDS.MAX_NUMBER;

        const category = entityLabel?.category;
        let categoryText = deepValue(category, `name.${displayLanguage}`, deepValue(category, `name.${SINGLE_LANG_INPUT_CODE}`, ""));
        const isVacantstore = isStore && !!entityLabel.kind && !this.props.isPointOfIntrest;
        return (
            <div>
                <Accordion fluid className="accordionBranding">
                    <LanguageSelector
                        activeLang={displayLanguage}
                        setActiveLang={setDisplayLanguage}
                        defaultDisplayedLangs={getEntityLabelLanguages(entityLabel)}
                    />
                    {/* Drop down for options */}

                    <AccordionSection active={activeIndex === accordionIndex.BASIC_INFORMATION} index={accordionIndex.BASIC_INFORMATION} onClick={this.handleAccordionClick} error={true}
                        title={trans["accordion title"]} className={this.doesSectionHaveAnError(accordionIndex.BASIC_INFORMATION) ? "accordionHeaderError" : ""}>

                        { 
                            !!this.props.entityId 
                            && <>
                                <p className="entityIdDisplay">Id: {this.props.entityId}</p>
                                <CopyButton 
                                    text={this.props.entityId} 
                                    btnClassname="entityIdCopyBtn"
                                    popupText={trans["Copy_Id_Text"]}
                                />
                            </>
                        }

                        {
                            !!this.props.buildingId
                            && <>
                                <p className="buildingIdDisplay">BuildingId: {this.props.buildingId}</p>
                                <CopyButton 
                                    text={this.props.buildingId} 
                                    btnClassname="entityIdCopyBtn"
                                    popupText={trans["Copy_Id_Text"]}
                                />
                            </>
                        }

                        <DefaultDetailSection isEdit title={`${this.getEntityType()} ${trans["name"]}`}>
                            <Input
                                className={style.input}
                                placeholder={trans["name placeholder"]}
                                name={`longName.${displayLanguage}`}
                                value={entityLabel.longName?.[displayLanguage] || ""}
                                onChange={this.handleInputChange}
                                error={!!validationErrors[`longName.${displayLanguage}`]}
                                maxLength={this.props.isBuilding ? null : `${PROPERTIES.MAX_NAME_LENGTH}`}
                            />
                        </DefaultDetailSection>

                        <DefaultDetailSection isEdit title={`${this.getEntityType()} ${trans["nick"]}`} >
                            <Input
                                className={style.input}
                                placeholder={trans["nick placeholder"]}
                                name={`shortName.${displayLanguage}`}
                                value={entityLabel.shortName?.[displayLanguage] || ""}
                                onChange={this.handleInputChange}
                                error={!!validationErrors[`shortName.${displayLanguage}`]}
                                maxLength={PROPERTIES.MAX_SHORTNAME_LENGTH}
                            />
                        </DefaultDetailSection>

                        <DefaultDetailSection isEdit title={trans["desc"]} subTitle={genericTrans("InformationBox.Optional")} >
                            <TextArea
                                placeholder={trans["desc placeholder"]}
                                name={`description.${displayLanguage}`}
                                value={entityLabel.description?.[displayLanguage] || ""}
                                onChange={this.handleInputChange}
                            />
                        </DefaultDetailSection>

                        <DefaultDetailSection isEdit title={trans["phone"]}>
                            {(isStore) && (
                                <Input
                                    className={style.input}
                                    placeholder={trans["phone placeholder"]}
                                    name="phoneNumber"
                                    onBlur={() => this.validateAllData(true)}
                                    value={entityLabel.phoneNumber}
                                    onChange={this.handleInputChange}
                                    error={!!validationErrors.phoneCheck}
                                    maxLength={PROPERTIES.MAX_PHONE_NUMBER_LENGTH}
                                />
                            )}
                            {(!isStore) && (
                                <Input
                                    className={style.input}
                                    placeholder={trans["phone placeholder"]}
                                    name="phoneNumbers.0"
                                    onBlur={() => this.validateAllData(true)}
                                    value={entityLabel.phoneNumbers?.[0]}
                                    onChange={this.handleInputChange}
                                    error={!!validationErrors.phoneCheck}
                                    maxLength={PROPERTIES.MAX_PHONE_NUMBER_LENGTH}
                                />
                            )}
                        </DefaultDetailSection>

                        <DefaultDetailSection isEdit title={trans["website"]} >
                            <Input
                                className={style.input}
                                placeholder={trans["website placeholder"]}
                                name={`website.${displayLanguage}`}
                                value={entityLabel.website?.[displayLanguage] || ""}
                                onChange={(e, { value }) => this.setState((state) => ({
                                    entityLabel: {
                                        ...state.entityLabel, website: { ...state.entityLabel.website, [displayLanguage]: value }
                                    }
                                }))}
                                onBlur={() => this.validateAllData()}
                                error={!!validationErrors[`website.${displayLanguage}`]}
                            />
                        </DefaultDetailSection>

                        {(isStore) && (
                            <>
                                <DefaultDetailSection isEdit title={trans["category title"]}>
                                    <Dropdown
                                        className={`dropdownCategory dropdownCategoryAlt ${style.dropdown}`}
                                        selection
                                        search
                                        icon="angle down"
                                        name="categories"
                                        text={categoryText}
                                        options={categoryOptions}
                                        defaultValue="0"
                                        onChange={this.handleChangeCategory}
                                        error={!!validationErrors.category}
                                        noResultsMessage={isEmpty(categoryOptions) ? trans["no results"] : trans["no results found"]}
                                    />
                                </DefaultDetailSection>

                                <DefaultDetailSection isEdit title={trans["subcategories title"]}>
                                    <Dropdown
                                        className={`dropdownCategory ${style.dropdown}`}
                                        multiple
                                        selection
                                        search
                                        icon="angle down"
                                        name="subCategories"
                                        options={this.getSubCategoryOptions()}
                                        value={selectedSubCategories || []}
                                        onChange={this.handleChangeSubCategory}
                                        error={!!validationErrors.subCategories}
                                        noResultsMessage={isEmpty(categoryOptions) ? trans["no results"] : selectedSubCategories  && selectedSubCategories.length === this.getSubCategoryOptions().length ? trans["no more results"] : trans["no results found"]}
                                    />
                                </DefaultDetailSection>
                            </>
                        )}

                        {(entityLabel?.entityLabelType && isStore && !this.props.isPointOfIntrest) && (
                            <DefaultDetailSection isEdit title={trans["type title"]}>
                                <Dropdown
                                    className={style.dropdown}
                                    error={!!validationErrors.type}
                                    name="entityLabelType"
                                    options={defaultTypesOptions}
                                    value={deepValue(entityLabel, "entityLabelType", 1)}
                                    onChange={this.handleInputChange}
                                />
                            </DefaultDetailSection>
                        )}
                    </AccordionSection>

                    {(isStore) && (
                        <AccordionSection
                            active={activeIndex === accordionIndex.INVENTORY}
                            index={accordionIndex.INVENTORY}
                            onClick={this.handleAccordionClick}
                            title={<>{trans["inventory title"]}<Popup content={genericTrans("InformationBox.Inventory_changes_are_saved_separately_f")} trigger={<Icon name="help circle" color="blue" />} /></>}
                        >
                            <div className={style.distributedText}>
                                <InventoryCount inventoryLength={inventoryLength} />
                                <ButtonIcon disabled className="inventoryButton" icon="plus-add" onClick={() => history.push("/branding/inventory")} />
                            </div>
                        </AccordionSection>
                    )}
                    <AccordionSection active={activeIndex === accordionIndex.KEYWORDS} index={accordionIndex.KEYWORDS} onClick={this.handleAccordionClick}
                        title={trans["keywords title"] + ` (${trans["limit"]} ${KEYWORDS.MAX_NUMBER})`} className={this.doesSectionHaveAnError(accordionIndex.KEYWORDS) ? "accordionHeaderError" : ""}>
                        <DefaultDetailSection isEdit className="keywordsArea" title={trans["keywords desc"]} borderLess>
                            <Dropdown
                                className={`dropdownCategory ${style.dropdown} keywordsDropdown`}
                                placeholder={trans["keywords placeholder"]}
                                fluid
                                multiple
                                selection
                                search
                                icon=""
                                allowAdditions={!maxKeywords}
                                noResultsMessage={!maxKeywords ? trans["keywords no results msg"] : trans["keywords max number"]}
                                onChange={this.handleChangeKeywords}
                                value={entityLabel.keywords?.[displayLanguage] || []}
                                searchQuery={keywordInputText}
                                onSearchChange={(e, { searchQuery }) => searchQuery.length <= KEYWORDS.MAX_LENGTH ? this.setState({ keywordInputText: searchQuery }) : null}
                                options={keywordOptions}
                                error={!!validationErrors.keywords}
                            />
                        </DefaultDetailSection>
                    </AccordionSection>

                    {/* Online keywords */}
                    <AccordionSection
                        active={activeIndex === accordionIndex.ONLINE_KEYWORDS}
                        index={accordionIndex.ONLINE_KEYWORDS}
                        onClick={this.handleAccordionClick}
                        title={
                            <>
                                <span>{trans["online_keywords_title"]}</span>
                                <Button className="buttonIcon listViewUploadButton" onClick={() => this.setState({ openOnlineKeywordModal: true })}>
                                    <img className="onlineKeywordUploadBtn" src="/img/icon-upload-csv.svg" alt="" />
                                    {trans["Upload"]}
                                </Button>
                                <OnlineKeywordUploadModal
                                    isOpen={this.state.openOnlineKeywordModal}
                                    close={() => this.setState({ openOnlineKeywordModal: false })}
                                    onUpload={this.handleOnlineKeywordsUpload}
                                />
                            </>
                        }
                        className={this.doesSectionHaveAnError(accordionIndex.ONLINE_KEYWORDS) ? "accordionHeaderError" : ""}>
                        <DefaultDetailSection isEdit className="keywordsArea" title={trans["online_keywords_desc"]} borderLess>
                            <Dropdown
                                className={`dropdownCategory ${style.dropdown} keywordsDropdown`}
                                placeholder={trans["keywords placeholder"]}
                                fluid
                                multiple
                                selection
                                search
                                icon=""
                                allowAdditions={true}
                                noResultsMessage={trans["keywords no results msg"]}
                                onChange={this.handleOnlineKeywordsChange}
                                value={entityLabel.onlineKeywords?.[displayLanguage] || []}
                                searchQuery={onlineKeywordInputText}
                                onSearchChange={(e, { searchQuery }) => searchQuery.length <= KEYWORDS.MAX_LENGTH ? this.setState({ onlineKeywordInputText: searchQuery }) : null}
                                options={onlineKeywordOptions}
                                error={!!validationErrors.onlineKeywords}
                            />
                        </DefaultDetailSection>
                    </AccordionSection>

                    <AccordionSection active={activeIndex === accordionIndex.OPENING_HOURS} index={accordionIndex.OPENING_HOURS} onClick={this.handleAccordionClick}
                        title={trans["hours title"]} className={this.doesSectionHaveAnError(accordionIndex.OPENING_HOURS) ? "accordionHeaderError" : ""}>
                        {
                            (isStore) && (
                                <Checkbox className="customCheck" checked={entityLabel.isHoursLinkedToRef} label={trans["hours checkbox"]}
                                    onChange={(e, data) => this.handleInputChange(e, { name: "isHoursLinkedToRef", value: data.checked })} />
                            )
                        }

                        <PartialOpeningHours openingHours={entityLabel.partialOpeningHours} isEdit={true} validationErrors={partialOpeningHoursValidationErrors}
                            onChange={this.handleHoursUpdate} onAddPeriod={this.handleAddPeriod} onRemovePeriod={this.handleRemovePeriod} />
                        <CalendarModal
                            isProperty={false}
                            selectedObject={entityLabel}
                            onGetHolidays={(year) => getEntityHolidays(entityLabel._id, year)}
                            onEditHoliday={(holiday, remove) => updateEntityHolidays(entityLabel._id, holiday, remove)}
                            onNewHoliday={console.log}      // This one is not used
                            triggerClassName="edit-sidebar-calendar"
                        />
                    </AccordionSection>

                    <AccordionSection active={activeIndex === accordionIndex.SOCIAL_LINKS} index={accordionIndex.SOCIAL_LINKS} onClick={this.handleAccordionClick}
                        title={trans["social title"]} className={this.doesSectionHaveAnError(accordionIndex.SOCIAL_LINKS) ? "accordionHeaderError" : ""}>
                        <DefaultDetailSection
                            isEdit
                            title={trans["social insta"]}
                            icon="social-instagram"
                            placeholder={trans["social placeholder"]}
                            onChange={this.handleInputChange}
                            name="socialMedia.instagram"
                            value={deepValue(entityLabel, "socialMedia.instagram", "")}
                            error={deepValue(validationErrors, "socialMedia.instagram", false)}
                        />

                        <DefaultDetailSection
                            isEdit
                            title={trans["social fb"]}
                            icon="social-facebook"
                            placeholder={trans["social placeholder"]}
                            onChange={this.handleInputChange}
                            name="socialMedia.facebook"
                            value={deepValue(entityLabel, "socialMedia.facebook", "")}
                            error={deepValue(validationErrors, "socialMedia.facebook", false)}
                        />

                        <DefaultDetailSection
                            isEdit
                            title={trans["social twitter"]}
                            icon="social-twitter"
                            placeholder={trans["social placeholder"]}
                            onChange={this.handleInputChange}
                            name="socialMedia.twitter"
                            value={deepValue(entityLabel, "socialMedia.twitter", "")}
                            error={deepValue(validationErrors, "socialMedia.twitter", false)}
                        />
                    </AccordionSection>

                    {
                        (Object.keys(validationErrors).length > 0 || Object.keys(partialOpeningHoursValidationErrors).length > 0) && (
                            <ErrorLabel multiple={Object.keys(validationErrors).length > 0}>
                                {trans["validation error msg"]}
                                {(Object.keys(validationErrors).length > 0) && (
                                    <ul>
                                        {Object.entries(validationErrors).map(([key, error]) => <li key={key}>{genericTrans(error)}</li>)}
                                    </ul>
                                )}
                            </ErrorLabel>
                        )
                    }
                </Accordion>
                <div className="buttonWrapper" style={{ justifyContent: `${isVacantstore ? "space-around":"flex-end"}` }}>
                    {
                        isVacantstore && <VacantModal />                        
                    }
                    <Button className="saveBtn marginBtn" color="orange" content={trans["save button"]} onClick={this.handleSaveChanges} />
                </div>
                <ModalLayout
                    open={confirmInventoryOpen}
                    className="crop-image-modal deleteGroupModal"
                    heading="Keep Inventory Details"
                    onClose={() => this.setState({ confirmInventoryOpen: false })}
                    actions={
                        <>
                            <Button primary color="orange" content={genericTrans("InformationBox.Delete")} onClick={this.deleteInventory} />
                            <Button primary color="grey" content={genericTrans("InformationBox.Keep")} onClick={this.handleSaveChanges} />
                        </>
                    }>
                    <span style={{ padding: "10px", wordBreak: "break-word", whiteSpace: "pre-line" }} className="p-modal">
                        {genericTrans("InformationBox.You_are_changing_the_store_name__Would_y")}
                    </span>
                </ModalLayout>
            </div>
        );
    }
}

const InformationBoxEdit = withRouter(withTranslation()(InformationBoxEditImpl));


const PartialOpeningHours = ({ openingHours, isEdit, onChange, onAddPeriod, onRemovePeriod, validationErrors = {} }) =>
{
    const renderWeekdays = () =>
    {
        let partialOpeningHours = deepValue(openingHours, "partialOpeningHours", []);
        
        let content = [];

        for (let i = 1; i < weekdays().length; i++)
        {
            const weekday = weekdays()[i];

            content.push(renderPeriods(weekday, i, partialOpeningHours[i]));

        }
        // Sunday should be displayed at end of list
        content.push(renderPeriods(weekdays()[0], 0, partialOpeningHours[0]));

        return content;
    };

    const renderPeriods = (title, day, dayPeriods = []) =>
    {
        if (isEdit)
        {
            return (
                <EditWeekdayHours title={title} day={day} showStatus={false} validationErrors={validationErrors[day]} key={title + "editWeekdayHours"} periods={dayPeriods}
                    onChange={onChange} onAddPeriod={onAddPeriod} onRemovePeriod={onRemovePeriod} />
            );
        }
        else
        {
            return (
                <ViewWeekdayHours title={title} showStatus={false} periods={dayPeriods} key={title + "editWeekdayHours"} />
            );
        }
    };

    return (
        <div>
            {
                renderWeekdays()
            }
        </div>
    );

};

const SocialLinks = ({ socialMedia }) => (
    <div className={css.socialLinks}>
        {socialMedia.instagram
            && <a href={socialMedia.instagram} target="_blank" rel="noopener noreferrer"><img src="/img/social-instagram.png" alt="" /></a>
        }
        {socialMedia.facebook
            && <a href={socialMedia.facebook} target="_blank" rel="noopener noreferrer"><img src="/img/social-facebook.png" alt="" /></a>
        }
        {socialMedia.twitter
            && <a href={socialMedia.twitter} target="_blank" rel="noopener noreferrer"><img src="/img/social-twitter.png" alt="" /></a>
        }
    </div>
);


const AccordionSection = ({ title, active, index, onClick, children, className }) => (
    <>
        <Accordion.Title active={active} index={index} onClick={onClick} className={className}>
            {title}
            <img src="/img/caret.svg" alt="" />
        </Accordion.Title>

        <Accordion.Content active={active}>
            {children}
        </Accordion.Content>
    </>
);
