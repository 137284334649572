import React, { useState } from "react";
import ImageSkeleton from "../ImagesManagement/ImagesManagementMain/ImageSkeleton/ImageSkeleton";
import "./ImageComponent.css";

function ImageComponent({ imgSrc, alt, selected }) 
{
    const [isLoading, setIsLoading] = useState(true);

    
    function onLoad(e) 
    {
        setIsLoading(false);
    }

    function onError(e) 
    {
        setIsLoading(false);
    }

    return (
        <div className={`image-component-bx ${selected ? "selected" : ""}`}>
            {isLoading && <ImageSkeleton />}
            <div className={`image-component-img-bx ${isLoading ? "image-component-img-bx-loading" : ""}`}>
                <img
                    src={imgSrc}
                    alt={alt}
                    className="image-component-img"
                    onLoad={onLoad}
                    onError={onError}
                />
            </div>
        </div>
    );
}

export default ImageComponent;
