import React, { useEffect, useRef } from "react";
import { Header, Icon, Popup } from "semantic-ui-react";

const MapOverlayCreationError = ({ 
    open, 
    contextRef, 
    message, 
    onClick, 
    position, 
    onTimeout,
    timeoutDuration = 3000,
}) => 
{
    const timeoutRef = useRef();

    useEffect(() =>
    {
        // if opened, initate timeout
        if (open && onTimeout)
        {
            timeoutRef.current = setTimeout(() =>
            {
                onTimeout();
            }, timeoutDuration);
        }
        // if closed, clear the timeout 
        else if (timeoutRef.current)
        {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = undefined;
        }
    }, [open]);

    return (
        <Popup
            className="errorPopup"
            open={open}
            context={contextRef}
            position={position ? position : "bottom center"}
            hideOnScroll={false}
            onClick={onClick}
        >
            <Header className="errorPopupHead">
                <Icon name="exclamation circle" color="red" />
                {message}
            </Header>
        </Popup>
    );
};

export default MapOverlayCreationError;