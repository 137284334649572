const { LineStringAccess } = require("./lineStringAccess");
const { PointAccess } = require("./pointAccess");
const { PointRelativeToLine } = require("./enums");

// NOTE: DO NOT IMPORT FROM ANY OTHER GEOMETRY MODULES, AS IT WILL CAUSE INFINITE RECURSION
// IF YOU NEED TO IMPORT, MOVE THE FUNCTION OR CONSTANT TO THIS FILE.

/**
 * Checks if two values are close to each other within a certain maximum absolute error.
 *
 * @param {number} value1 - The first value to compare.
 * @param {number} value2 - The second value to compare.
 * @param {number} [maximumAbsoluteError=exports.IS_EQUAL_DELTA_TH] - The maximum absolute error allowed.
 * @return {boolean} Returns true if the values are close enough, false otherwise.
 */
exports.isClose = (value1, value2, maximumAbsoluteError = exports.IS_EQUAL_DELTA_TH) =>
{
    if (!isFinite(value1) || !isFinite(value2))
    {
        return value1 == value2;
    }

    if (isNaN(value1) || isNaN(value2))
    {
        return false;
    }

    const delta = value1 - value2;

    if (delta > maximumAbsoluteError || delta < -maximumAbsoluteError)
    {
        return false;
    }

    return true;
}

/**
 * Calculates the determinant of a 2D line and a point.
 * The determinant can be used to determine if a point lies on the left or right side of a line, or if it is above or below the line. It can also be used to calculate the distance between a point and a line, or to determine if two lines intersect.
 *
 * @param {LineStringAccess} line 
 * @param {PointAccess} point 
 * @return {number} The determinant of the line and the point.
 */
exports.getDeterminantBetweenLineAndPoint = (line, point) =>
{
    const startPoint = line.getStartPoint();
    const endPoint = line.getEndPoint();

    const determinant = (endPoint.getX() - startPoint.getX()) * (point.getY() - startPoint.getY()) - (endPoint.getY() - startPoint.getY()) * (point.getX() - startPoint.getX());

    return determinant;
}

/**
 * Calculates the point relative to a line based on its determinant value.
* @param {PointAccess} point
* @param {LineStringAccess} line 
* @return {PointRelativeToLine} The point relative to the line.
*/
exports.getPointRelativeToLine = (point, line) =>
{
    const determinant = exports.getDeterminantBetweenLineAndPoint(line, point);
    const lineStringLength = line.getLength();
    const deltaThreshold = exports.IS_EQUAL_DELTA_TH * lineStringLength;

    const result = exports.isClose(determinant, 0, deltaThreshold)
        ? PointRelativeToLine.On
        : Math.sign(determinant);

    return result;
}


/**
 * Calculates the distance between two points in a 2D plane.
 *
 * @param {PointAccess} point1 - The coordinates of the first point in the form [x, y].
 * @param {PointAccess} point2 - The coordinates of the second point in the form [x, y].
 * @return {number} The distance between the two points.
 */
exports.getDistanceToThePoint = (point1, point2) =>
{
    return Math.sqrt(exports.getSquaredDistanceToPoint(point1, point2));
}

/**
 * Calculates the squared distance between two points in a 2D plane.
 *
 * @param {PointAccess} point1 - The coordinates of the first point in the form [x, y].
 * @param {PointAccess} point2 - The coordinates of the second point in the form [x, y].
 * @return {number} The squared distance between the two points.
 */
exports.getSquaredDistanceToPoint = (point1, point2) =>
{
    return (point2.getX() - point1.getX()) * (point2.getX() - point1.getX()) + (point2.getY() - point1.getY()) * (point2.getY() - point1.getY());
}

/**
 * Calculates the azimuth angle between two points in a 2D plane.
 *
 * @param {PointAccess} p0 - The coordinates of the starting point in the form [x, y].
 * @param {PointAccess} p1 - The coordinates of the ending point in the form [x, y].
 * @return {number} The azimuth angle between the two points within the range of 0 to 2π.
 */
exports.getAzimuth = (p0, p1) =>
{
    const angle = Math.atan2(p1.getX() - p0.getX(), p1.getY() - p0.getY());

    return exports.modZeroToPi(angle);
}

/**
 * Modifies an angle to be within the range of 0 to 2π.
 *
 * @param {number} angle - The angle to be modified.
 * @return {number} The modified angle within the range of 0 to 2π.
 */
exports.modZeroToPi = (angle) =>
{
    const twoPi = 2 * Math.PI;

    while (angle < 0.0)
    {
        angle += twoPi;
    }
    while (angle > twoPi)
    {
        angle -= twoPi;
    }

    return angle;
}

/**
 * Sorts an array of points by their distance to a given point.
 *
 * @param {Array<PointAccess>} points - The array of points to sort. Each point is represented as an array of two numbers: [x, y].
 * @param {PointAccess} point - The reference point to calculate distances from. It is represented as an array of two numbers: [x, y].
 * @return {Array<PointAccess>} The sorted array of points.
 */
exports.sortPointArrayByDistanceToPoint = (points, point) =>
{
    return points.sort((a, b) => exports.getDistanceToThePoint(point, a) - exports.getDistanceToThePoint(point, b));
}

// threshold used to determine how close a point is to a line
exports.IS_EQUAL_DELTA_TH = 0.0001; // 1e-4

// threshold used in parallel calculation 
exports.IS_EQUAL_COS_RADIAN_TH = 0.999999995; // 1 - 5e-9
