import React from "react";
import InfiniteScrollGrid from "../../../InfiniteScrollGrid/InfiniteScrollGrid";
import { useImagesRecycleBinContext } from "../../contexts/ImagesRecycleBinContext";
import ImageBox from "../../ImagesManagementMain/ImageBox/ImageBox";
import "./ImagesRecycleBinMain.css";
import EmptyImagesStateFallback from "../../../EmptyImagesStateFallback/EmptyImagesStateFallback";
import ImageComponent from "../../../ImageComponent/ImageComponent";
import { filerUrl } from "../../../../../../_utils/utils";

function ImagesRecycleBinMain() 
{
    const { imagesQuery, imagesData, imagesSearchAndFilters } =
    useImagesRecycleBinContext();
    return (
        <InfiniteScrollGrid
            isSuccess={imagesQuery.isSuccess}
            className="images-recycle-bin-infinite-scroller"
            isFetchingNextPage={imagesQuery.isFetchingNextPage}
            fetchNextPage={imagesQuery.fetchNextPage}
            isLoading={
                imagesQuery.isLoading
                || imagesQuery.isFetchingNextPage
                || imagesQuery.isIdle
                || imagesQuery.isError
            }
            cellToRender={(item) => (
                <ImageComponent key={item._id} imgSrc={filerUrl(item.filerId, 1800)} alt={item.name} />
            )}
            data={imagesData}
            hasNextPage={imagesQuery.hasNextPage}
            noItemsFallback={
                <EmptyImagesStateFallback>
                    {imagesSearchAndFilters.searchValue
            && "No image matches that search."}
                </EmptyImagesStateFallback>
            }
        ></InfiniteScrollGrid>
    );
}

export default ImagesRecycleBinMain;
