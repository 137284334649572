import React from "react";
import { createContext, useEffect } from "react";
import { useSetState } from "ahooks";
import brandingApi from "../_api/branding.api";
import serverApi from "../_api/server.api";
import { DATA_QUERIES } from "../_utils/queries";
import { useQuery } from "react-query";

/** @type {import("../../types/store").IAccessGroups} */
const INITIAL_ACCESS_GROUPS = null;

/** @type {React.Context<Partial<import("../../types/store").ContextValues>>} */
const StoreSharingContext = createContext({});

const StoreSharingProvider = ({ children }) =>
{
    const [state, setState] = useSetState({
        fieldLanguage: "en",
        openShareStores: false,
        openWithStore: undefined
    });
    const { fieldLanguage, openShareStores, openWithStore } = state;

    const { data: accessGroups, refetch: reinitGroups, isFetched: groupsAreFetched } = useQuery({
        ...DATA_QUERIES.GET_ACCESS_GROUPS(serverApi.userData.user.userCompanyInfo.companyUID),
        initialData: INITIAL_ACCESS_GROUPS,
        enabled: openShareStores
    });

    const exportedState = {
        accessGroups,
        fieldLanguage,
        openShareStores,
        openWithStore,
        groupsAreFetched
    };

    const methods = {
        reinitGroups,
        setState
    };

    return (
        <StoreSharingContext.Provider value={{
            state: exportedState,
            methods
        }}>
            {children}
        </StoreSharingContext.Provider>
    );
};

export { StoreSharingProvider, StoreSharingContext };
