export const DEFAULT_DISTRIBUTION_ZONE_STYLE = {
    stroke: {
        color: "#3065CF",
        width: 1
    },
    fill: {
        color: "#3065CF33",
    }
};

export const HIGHLIGHTED_DISTRIBUTION_ZONE_STYLE = {
    stroke: {
        color: "#3065CF",
        width: 1
    },
    fill: {
        color: "#3065CF8C",
    }
};

export const ZONES_MAP_CONSTANTS = {
    DEFAULT_ZOOM: 3,
    MAX_ZOOM: 24,
    MIN_ZOOM_TEXT_LAYER: 14,
    MIN_ZOOM: 10,
    HIDDEN_LAYER: "-1",
    TEXT_LAYER: "text_layer",
    ANIMATION_DURATION: 200,
    FIT_PADDING_ENTITIES: [290, 290, 290, 290],
    FIT_PADDING_MAP_DATA: [20, 20, 20, 20],
    ZONE_FIT_PADDING: [100, 100, 100, 100],
};