import { MAPSTED_DARK_THEME, MAPSTED_LIGHT_THEME } from "mapsted.maps/utils/map.constants";
import React, { useContext, useMemo } from "react";
import { Header, Tab } from "semantic-ui-react";
import { StylesAndThemesContext } from "../../../store/StylesAndThemesContext";
import { 
    BUILDING_LOCATION_TYPE, 
    PROPERTY_LOCATION_TYPE, 
} from "../../../_constants/stylesAndThemesConstants";
import { getEntitiesHavingPolyonStyles, getEntityTypeNames, getNamableEntities } from "../../../_utils/stylesAndThemesUtil";
import { ButtonIcon } from "../../common/ButtonIcon";
import CancelConfirmationModal from "./modals/CancelConfirmationModal";
import PolygonStyleEditorTab from "./styleEditorTabs/PolygonStyleEditorTab";
import TextStyleEditorTab from "./styleEditorTabs/TextStyleEditorTab";
import { useTranslation } from "react-i18next";
import "./StylesAndThemes.css";

const StylesEditorSidebar = () => 
{
    const trans = useTranslation().t;
    const stylesAndThemesCtx = useContext(StylesAndThemesContext);
    const { 
        state: ctxState,
        handleStyleEditCancel,
        updateActiveStylePolygon,
        handlePreviewStateChange,
        updateActiveStyleText,
        handleRevertToDefault,
        handleSave,
    } = stylesAndThemesCtx;

    const entityTypeName = useMemo(() => 
    {
        const locationType = ctxState.isBuildingSelected ? BUILDING_LOCATION_TYPE : PROPERTY_LOCATION_TYPE;
        
        const entityTypeNames = getEntityTypeNames(locationType);

        let entityTypeName = "";
        if (ctxState.activeStyle)
        {
            const { entityType, subEntityType } = ctxState.activeStyle.target;
            entityTypeName =  entityTypeNames[`${entityType}:${subEntityType}`];
        }
        return entityTypeName;
    }, [ctxState.activeStyle, ctxState.isBuildingSelected]);

    // flag to indicate whether the current active theme is default company theme
    const isActiveThemeDefault = useMemo(() => ctxState.activeTheme === MAPSTED_LIGHT_THEME._id || ctxState.activeTheme === MAPSTED_DARK_THEME._id, [ctxState.activeTheme]);

    const panes = useMemo(() => 
    {
        let panes = [];

        if (ctxState.activeStyle)
        {
            
            const locationType = ctxState.isBuildingSelected ? BUILDING_LOCATION_TYPE : PROPERTY_LOCATION_TYPE;

            const entitiesHavingPolygonStyles = getEntitiesHavingPolyonStyles(locationType);

            const namableEntities = getNamableEntities();

            const currentStyleEntityType = `${ctxState.activeStyle.target.entityType}:${ctxState.activeStyle.target.subEntityType}`;
            if (entitiesHavingPolygonStyles.includes(currentStyleEntityType))
            {
                panes.push({
                    menuItem: trans("StylesAndThemesSidebar.Fill"), 
                    render: () => <PolygonStyleEditorTab 
                        style={ctxState.activeStyle} 
                        onChange={updateActiveStylePolygon} 
                        previewState={ctxState.previewState}
                        onPreviewStateChange={handlePreviewStateChange}
                        onRevertToDefault={handleRevertToDefault}
                        onSave={handleSave}
                        disableEdit={isActiveThemeDefault}
                    /> 
                });
            }

            if (namableEntities.includes(currentStyleEntityType))
            {
                panes.push({ 
                    menuItem: trans("StylesAndThemesSidebar.Text"), 
                    render: () => <TextStyleEditorTab 
                        style={ctxState.activeStyle}
                        onChange={updateActiveStyleText}
                        onRevertToDefault={handleRevertToDefault}
                        onSave={handleSave}
                        previewState={ctxState.previewState}
                        onPreviewStateChange={handlePreviewStateChange}
                        disableEdit={isActiveThemeDefault}
                    /> 
                });
                
            }
        }
        return panes;
    }, [ctxState.activeStyle, ctxState.previewState, ctxState.isBuildingSelected]);

    if (!ctxState.activeStyle)
    {
        return null;
    }

    return (
        <div className="layerMapStylesSidebar">
            <Header className="headingbold">
                <p>{entityTypeName}</p>
                {isActiveThemeDefault ? <ButtonIcon 
                    icon="cross" 
                    className="cancelButton" 
                    onClick={handleStyleEditCancel}/>: <CancelConfirmationModal onCancelClick={handleStyleEditCancel}/>}
            </Header>
            <Tab 
                menu={{ secondary: true, pointing: true }} 
                panes={panes} 
                className="layer-tab mapLayersTab"
                onTabChange={() => handlePreviewStateChange("default")}
            />
        </div>);
};

export default StylesEditorSidebar;