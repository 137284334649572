import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import { useClickAway } from "../../hooks/useClickAway";
import "./DropdownItem.css";
import { useTranslation } from "react-i18next";


const DropdownItem = ({ options, onChange, selected, disabled, header, className }) => 
{
    const [open, setOpen] = useState(false);
    useClickAway(open, setOpen, "dropdownItem");

    const selectedOption = Object.values(options).find((option) => option.text === selected);

    const trans = useTranslation().t;

    return (
        <div className={`dropdown ${className || ""}`}>
            <div onClick={() => setOpen((prev) => !prev)} className={`dropdownPopup ${disabled ? "dropdownDisabled" : ""}`}>
                <div className="dropdownTitle">{(!!selectedOption && trans(selectedOption.i18nKey)) || header}</div>
                <Button className="btnDropdownPopup" icon="angle down" />
            </div>
            {open && (
                <div className="dropdownList">
                    {options.map(({ key, text, value, i18nKey }) => (
                        <div
                            key={key}
                            className={`dropdownItem ${selected === text ? "selected" : ""
                            }`}
                            onClick={() => 
                            {
                                onChange({ key, text, value });
                                setOpen((prev) => !prev);
                            }}
                        >
                            {trans(i18nKey)}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default DropdownItem;