import React from "react";
import { useState } from "react";
import { forwardRef } from "react";
import DropdownItem from "../../../common/DropdownItem";
import ImagePickerAddBlock from "../ImagePickerAddBlock/ImagePickerAddBlock";
import ImageSearchBar from "../ImageSearchBar/ImageSearchBar";
import ImageWebAddressBar from "../ImageWebAddressBar/ImageWebAddressBar";
import styles from "./ImagePickerHeader.module.css";
import { useTranslation } from "react-i18next";

const ImagePickerHeader = forwardRef(
    (
        {
            searchValue,
            handleChange,
            acceptableImageFileFormats,
            handleFileChange,
            handleBulkFileChange,
            handleErrorShown,
            maxFileSize,
            handleWebAddressUpload,
            isDropdownDisabled,
            selectedDropdownItem,
            disableSearch,
            handleDropdownChange,
            aspectRatioFilterEnabled,
            bulkUploadEnabled,
            options,
            ...rest
        },
        inputRef
    ) => 
    {
        const [showWebAddress, setShowWebAddress] = useState(false);
        const trans = useTranslation().t;

        function handleWebAddress() 
        {
            setShowWebAddress(true);
        }

        return (
            <div className={styles.container}>
                <p className={styles.heading}>{trans("Gallery.Photos_added_here")}</p>
                <div className={`${styles.inner_container} ${!aspectRatioFilterEnabled ? `${styles.inner_container_mod}`: ""}`}>
                    <ImageSearchBar
                        searchValue={searchValue}
                        onChange={handleChange}
                        disableSearch={disableSearch}
                    ></ImageSearchBar>
                    {
                        aspectRatioFilterEnabled && <div className={styles.dropdown_container}>
                            <div className={styles.dropdown_title}>{trans("Gallery.Aspect_Ratios")}: </div>
                            <DropdownItem
                                disabled={isDropdownDisabled}
                                selected={selectedDropdownItem?.text}
                                onChange={(item) => handleDropdownChange(item)}
                                header={trans("Gallery.Size")}
                                options={options}
                            ></DropdownItem>
                        </div>
                    }
                    <ImagePickerAddBlock
                        acceptableImageFileFormats={acceptableImageFileFormats}
                        handleFileChange={handleFileChange}
                        handleBulkFileChange = {handleBulkFileChange}
                        handleWebAddress={handleWebAddress}
                        bulkUploadEnabled={bulkUploadEnabled}
                        ref={inputRef}
                    ></ImagePickerAddBlock>
                </div>

                {showWebAddress && (
                    <ImageWebAddressBar
                        handleWebAddressUpload={handleWebAddressUpload}
                        maxFileSize={maxFileSize}
                        handleErrorShown={handleErrorShown}
                        acceptableImageFileFormats={acceptableImageFileFormats}
                        {...rest}
                    ></ImageWebAddressBar>
                )}
            </div>
        );
    }
);

ImagePickerHeader.displayName = "ImagePickerHeader";

export default ImagePickerHeader;
