import React from "react";
import { Button } from "semantic-ui-react";

const MapButton = ({
    icon = undefined,
    content = undefined,
    isActive = false,
    onClick,
    disabled=false
}) => (
    <Button className={isActive ? "active" : ""} onClick={onClick} disabled={disabled}>
        {content}
        {(icon) && (<img src={`/img/icon-${icon}.svg`} alt="icon" />)}
    </Button>
);

export default MapButton;