import React, { useRef, useEffect, useContext, useMemo } from "react";
import { Overlay } from "ol";
import { useMapZoom } from "../../branding/map/mapHooks";
import { DynamicMapLayersContext } from "../../../store/DynamicMapLayersContext";
import { MouseInteraction } from "mapsted.maps/utils/interactionTemplates";
import "ol/ol.css";
import { getTopCenterOfExtent } from "./utils/dynamicMapLayersUtils";
import MapPopup from "./popup/MapPopup";
import { MapButtons } from "./mapButtons/MapButtons";
import { DEFAULT_LANGUAGE_CODE, DYNAMIC_MAP_LAYERS_MODES } from "../../../_constants/constants";
import useMap from "../../../hooks/useMap";
import BrandingContext from "../../../store/BrandingContext";
import { styleClassic } from "mapsted.maps/utils/defualtStyles";

const SIDE_BAR_OPEN_MODES = [
    DYNAMIC_MAP_LAYERS_MODES.CONFIRMATION_SIDEBAR,
    DYNAMIC_MAP_LAYERS_MODES.ACTIVITY_SIDEBAR, 
    DYNAMIC_MAP_LAYERS_MODES.CREATE_DYNAMIC_MAP_LAYER, 
    DYNAMIC_MAP_LAYERS_MODES.EDIT_DYNAMIC_MAP_LAYER,
    DYNAMIC_MAP_LAYERS_MODES.MULTI_CREATION
];

const DynamicLayersMap = () =>
{
    const dynamicMapLayerCtx = useContext(DynamicMapLayersContext);
    const brandingCtx = useContext(BrandingContext);

    const { updateTextPopupAutomationRuleId, state: { settingsConfig } } = dynamicMapLayerCtx;
        
    const popupOverlay = useRef();
    const popupRef = useRef(null);
    const displayVectorLayers = useRef(null);
    const activeInterations = useRef([]);

    const rotationAngle = useMemo(() => 
    {
        if (brandingCtx.state.buildingId)
        {
            const buildingMapRotation = settingsConfig?.buildingSettings?.[brandingCtx.state.buildingId]?.mapSettings?.mapRotation?.[DEFAULT_LANGUAGE_CODE];

            if (buildingMapRotation || buildingMapRotation === 0)
            {
                return buildingMapRotation;
            }
        }
        return settingsConfig?.mapRotation?.[DEFAULT_LANGUAGE_CODE] || 0;
    }, [settingsConfig, brandingCtx.state.buildingId]);

    const tileLayer = useMemo(() =>
    {
        if (brandingCtx.state?.mapData?.tileLayer)
        {
            return brandingCtx.state.mapData.tileLayer;
        }
        else
        {
            return styleClassic.tileLayer;
        }
    }, [brandingCtx.state.mapData?.tileLayer]);

    const { olMap, mapRef } = useMap({
        mapData: brandingCtx.state.mapData,
        options: { 
            mapOptions: {
                controlsOptions: { rotate: false },
                rotation: rotationAngle,
                tileLayer
            }
        },
    });

    useEffect(() =>
    {
        if (olMap)
        {
            // setups popup on olMap
            popupOverlay.current = new Overlay({
                element: popupRef.current,
                autoPan: false,
                offset: [0, 0],
                positioning: "top-center",
            });

            olMap.addOverlay(popupOverlay.current);

            activeInterations.current.forEach((interaction) =>
            {
                olMap.removeInteraction(interaction);
            });

            addInterations();
        }
    }, [olMap]);

    useEffect(() =>
    {
        if (displayVectorLayers.current)
        {
            Object.values(displayVectorLayers.current).map((vectoryLayers) =>
            {
                olMap.removeLayer(vectoryLayers);
            });
        }

        if (dynamicMapLayerCtx.state.displayVectorLayers)
        {
            Object.values(dynamicMapLayerCtx.state.displayVectorLayers).forEach((vectorLayer) =>
            {
                olMap.addLayer(vectorLayer);
            });
            
            displayVectorLayers.current = dynamicMapLayerCtx.state.displayVectorLayers;
        }
    }, [dynamicMapLayerCtx.state.displayVectorLayers]);

    const layerFilter = (layer) => layer.get("isDynamicMapLayer");

    const mouseHoverEventHandler = (event) =>
    {
        if (event.type === "pointermove")
        {
            let automationRuleId;
            let coordinates;

            olMap.forEachFeatureAtPixel(event.pixel, (feature) =>
            {                
                if (feature.get("isDynamicMapLayerText"))
                {
                    automationRuleId = feature.get("automationRuleId");
                    coordinates = getTopCenterOfExtent(feature.getGeometry().getExtent());
                }
                
                return true;
            }, { layerFilter });
            
            if (automationRuleId && coordinates)
            {
                popupOverlay.current.setOffset([0, 0]);
                popupOverlay.current.setPosition(coordinates);

                updateTextPopupAutomationRuleId(automationRuleId);
            }
            else
            {
                updateTextPopupAutomationRuleId(undefined);
            }
        }
        return true;
    };

    const addInterations = () =>
    {
        const mouseHoverInteraction = MouseInteraction({
            source: null,
            handleEvent: mouseHoverEventHandler
        });

        olMap.addInteraction(mouseHoverInteraction);
        activeInterations.current = [mouseHoverInteraction];
    };

    const { zoomIn, zoomOut } = useMapZoom(olMap);

    const isSidebarOpen = SIDE_BAR_OPEN_MODES.includes(dynamicMapLayerCtx.state.mode);

    return (
        <div className="maintenance-map">
            <div className="map-container" ref={mapRef}>
                <MapButtons
                    onZoomIn={zoomIn}
                    onZoomOut={zoomOut}
                    style={isSidebarOpen ? { right: "28rem" } : undefined} />
                <div ref={popupRef}>
                    <MapPopup />
                </div>
            </div>
        </div>
    );
};

export default DynamicLayersMap;