import React from "react";
import { Header, Button, Input, Checkbox } from "semantic-ui-react";
import BrandingContext from "../../../store/BrandingContext";
import CircularSlider from "@fseehawer/react-circular-slider";
import { deepValue } from "mapsted.utils/objects";
import { InputFormGroup } from "../../elements/InputFormGroup";
import { DEFAULT_LANGUAGE_CODE, KEY_CODES } from "../../../_constants/constants";
import { useTranslation } from "react-i18next";
import { MAX_ROTATION, MIN_ROTATION, rotationIsValid } from "../../../_utils/branding.utils";

export const RotatePopup = ({ selectedEntityId, allowNull }) =>
{
    /** @type {typeof import("../../../_intl/resources.json")["en"]["translation"]["rotate image popup"]} */
    const trans = useTranslation().t("rotate image popup");

    const brandingContext = React.useContext(BrandingContext);

    const { mapData } = brandingContext.state;

    const [textRotation, setTextRotation] = React.useState(null);

    // entityId used to check prev entity Id when selectedEntityId is changed from outside to revert rotation changes.
    const [entityId, setEntityId] = React.useState(undefined);

    const isOpen = React.useMemo(
        () => !!selectedEntityId && !!deepValue(mapData, `entities.${selectedEntityId}.entityLabel.longName.${DEFAULT_LANGUAGE_CODE}`, false),
        [selectedEntityId, mapData]
    );

    /**
     * We only want to call this when selectedEntityId through props changes
     */
    React.useEffect(() =>
    {
        if (selectedEntityId)
        {
            setTextRotation(deepValue({ ...mapData }, `entities.${selectedEntityId}.textRotation.${DEFAULT_LANGUAGE_CODE}`, 0));
        }

        if (entityId && entityId !== selectedEntityId)
        {
            const shouldRestSelectedEntity = !selectedEntityId;
            (entityId) && brandingContext.revertTextRotation(shouldRestSelectedEntity);
        }

        setEntityId(selectedEntityId);

    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedEntityId]);


    const handleTextRotationChange = React.useCallback((rotation) =>
    {
        if ((rotation !== textRotation) && rotationIsValid(rotation))
        {
            setTextRotation(rotation);
            brandingContext.updateTextRotation(selectedEntityId, rotation);
        }

    }, [selectedEntityId, brandingContext, textRotation]);

    const handleInputChange = React.useCallback((rotation) =>
    {
        if (rotation === "")
        {
            rotation = 0;
        }

        handleTextRotationChange(parseInt(rotation));

    }, [handleTextRotationChange]);

    const handleSaveChanges = React.useCallback(() =>
    {
        setEntityId(undefined);
        brandingContext.saveTextRotation(selectedEntityId, textRotation);

    }, [textRotation, brandingContext, selectedEntityId]);

    const handleCancelChanges = React.useCallback((isClose) =>
    {
        const textRotation = brandingContext.revertTextRotation(isClose);

        setTextRotation(textRotation);
    }, [brandingContext]);

    const shortCutHandler = React.useCallback((e) =>
    {
        const keyCode = e.keyCode;

        if (keyCode === KEY_CODES.ESCAPE)
        {
            handleCancelChanges(true);
        }
        else if (keyCode === KEY_CODES.ENTER)
        {
            handleSaveChanges();
        }

    }, [handleCancelChanges, handleSaveChanges]);


    React.useEffect(() =>
    {
        document.addEventListener("keydown", shortCutHandler, false);

        return () =>
        {
            document.removeEventListener("keydown", shortCutHandler, false);
        };
    }, [shortCutHandler]);

    if (!isOpen)
    {
        return null;
    }

    return (
        <div className="customPopup customRotate" >
            <Button className="close" onClick={() => handleCancelChanges(true)} />
            <Header>{trans["rotate txt"]}</Header>
            {(allowNull) && (
                <Checkbox
                    label={trans["rotate with map"]}
                    onChange={(e, { checked }) => handleTextRotationChange(checked ? null : 0)}
                    checked={textRotation === null}
                />
            )}
            <InputFormGroup label={trans["angle label"]} className="rotateGroup">
                <CircularSlider
                    min={MIN_ROTATION}
                    max={MAX_ROTATION}
                    knobPosition="right"
                    dataIndex={textRotation || 0}
                    onChange={(value) => (textRotation !== null && handleTextRotationChange(value))}
                    hideLabelValue
                    width={34}
                    knobColor="#005a58"
                    progressColorFrom="#eeeeee"
                    progressColorTo="#eeeeee"
                    progressSize={1}
                    trackColor="#eeeeee"
                    trackSize={1}
                    knobSize={25}
                    knobDraggable={textRotation !== null}
                >
                    <img src="/img/icon-arrow-cms.svg" alt="" />
                </CircularSlider>
                <Input
                    value={textRotation}
                    type="number"
                    onChange={(e, { value }) => handleInputChange(value)}
                    disabled={textRotation === null}
                />
            </InputFormGroup>
            <div className="actionsGroup">
                <Button content={trans["undo"]} onClick={() => handleCancelChanges(false)} />
                <Button primary content={trans["save"]} onClick={handleSaveChanges} />
            </div>
        </div>
    );
};
