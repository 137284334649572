import React, { useContext, useState, useEffect } from "react";
import { Footer } from "../../layout/Footer";
import { Header, Input, Button, Dropdown } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import "./Dashboard.css";
import { DashboardDataTable } from "../../dashboard/dashboardDataTable/DashboardDataTable";
import DashboardContext from "../../../store/DashboardContext";
import { usePagination } from "../../../_utils/hooks";
import { STATUS_DESCRIPTION, DEFAULT_LANGUAGE_CODE, MONTHS } from "../../../_constants/constants";
import cloneDeep from "lodash.clonedeep";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const propertiesPerPage = 8;

const Dashboard = ({ history }) =>
{
    const ctx = useContext(DashboardContext);

    const [displayProperties, setDisplayProperties] = useState([]);
    const [enableFilter, setEnableFilter] = useState(false);
    const [statusFilter, setStatusFilter] = useState("");
    const [nameFilter, setNameFilter] = useState("");
    const [countryFilter, setCountryFilter] = useState("");
    const [stateFilter, setStateFilter] = useState("");
    const [cityFilter, setCityFilter] = useState("");
    const [tenants, setTenants] = useState({});
    const [tenantSearchText, setTenantSearchText] = useState("");

    useEffect(() =>
    {
        initializeDisplayProperties();
        initializeTenants();
        setEnableFilter(false);
        clearAllFilters();
        setNameFilter("");
    }, [ctx.state.properties]);

    useEffect(() =>
    {
        let updatedDisplayProperties = getPropertiesFromCtx().map((property) => getPropertyDetails(property));

        if (statusFilter)
        {
            updatedDisplayProperties = updatedDisplayProperties.filter(applyStatusFilter);
        }

        if (nameFilter)
        {
            updatedDisplayProperties = updatedDisplayProperties.filter(applyNameFilter);
        }

        if (countryFilter)
        {
            updatedDisplayProperties = updatedDisplayProperties.filter(applyCountryFilter);
        }

        if (stateFilter)
        {
            updatedDisplayProperties = updatedDisplayProperties.filter(applyStateFilter);
        }

        if (cityFilter)
        {
            updatedDisplayProperties = updatedDisplayProperties.filter(applyCityFilter);
        }

        const selectedTenants = Object.values(tenants).filter((tenant) => tenant.checked)
            .map((tenant) => tenant.name);

        if (selectedTenants.length)
        {
            updatedDisplayProperties = updatedDisplayProperties.filter((property) => property.poiEntityLabels?.filter((poiEntityLabel) => selectedTenants.includes(poiEntityLabel)).length);
        }

        setDisplayProperties(updatedDisplayProperties);
    }, [statusFilter, nameFilter, countryFilter, stateFilter, cityFilter, tenants]);

    useEffect(() =>
    {
        const updatedTenants = Object.keys(tenants).length ? cloneDeep(tenants) : getTenantsFromCtx();

        if (tenantSearchText)
        {
            const matchedTenants = Object.keys(updatedTenants).filter((tenant) => tenant.toUpperCase().includes(tenantSearchText.toUpperCase()));
            Object.keys(updatedTenants).forEach((tenant) =>
            {
                updatedTenants[tenant].display = matchedTenants.includes(tenant);
            });
        }
        else
        {
            Object.keys(updatedTenants).forEach((tenant) =>
            {
                updatedTenants[tenant].display = true;
            });
        }

        setTenants(updatedTenants);

    }, [tenantSearchText]);

    /**
     * Reset all text and dropdown filters
     */
    const clearAllFilters = () =>
    {
        setStatusFilter("");
        setCountryFilter("");
        setStateFilter("");
        setCityFilter("");
        setTenantSearchText("");
    };

    /**
     * Get only required property object fields for list view
     * @param {Object} displayProperty
     * @returns {Object}
     */
    const getPropertyDetails = (displayProperty) =>
    {
        displayProperty.status = displayProperty.status === 4.5 ? 4 : displayProperty.status; // SET MAP DIGITIZATION STATUS TO  PROCESSING
        const propertyDetails = {
            _id: displayProperty._id,
            name: displayProperty.name?.[DEFAULT_LANGUAGE_CODE] || "-",
            dateCreated: displayProperty.createdAt ? formatDate(displayProperty.createdAt) : "-",
            dateProcessed: displayProperty.syncUpdateDate ? formatDate(displayProperty.syncUpdateDate) : "-",
            status: STATUS_DESCRIPTION[displayProperty.status]?.statusName,
            iconImage: displayProperty.iconImage?.[DEFAULT_LANGUAGE_CODE] || "",
            statusCode: displayProperty.status,
            poiEntityLabels: getPoiEntityLabels(displayProperty),
            ...getAddressComponents(displayProperty)
        };
        return propertyDetails;
    };

    /**
     * Get Point of Interest entity labels of input property
     * @param {Object} displayProperty
     * @returns {Array}
     */
    const getPoiEntityLabels = (displayProperty) =>
    {
        let poiEntityLabels = [];

        if (displayProperty.poiEntities)
        {
            Object.values(displayProperty.poiEntities).forEach((poiEntity) =>
            {
                if (poiEntity?.entityLabel?.longName?.[DEFAULT_LANGUAGE_CODE])
                {
                    poiEntityLabels.push(poiEntity.entityLabel.longName[DEFAULT_LANGUAGE_CODE]);
                }
            });
        }

        return poiEntityLabels;
    };

    /**
     * Initialize tenants for dropdown options
     */
    const initializeTenants = () =>
    {
        setTenants(getTenantsFromCtx());
    };

    const getTenantsFromCtx = () =>
    {
        let tenants = {};

        const propertyDetailsArray = getPropertiesFromCtx().map((property) => getPropertyDetails(property));

        const tenantNames = new Set();

        propertyDetailsArray.forEach((property) =>
        {
            property.poiEntityLabels.forEach((poiEntityLabel) => tenantNames.add(poiEntityLabel));
        });

        Array.from(tenantNames).forEach((tenantName) =>
        {
            tenants[tenantName] = {
                name: tenantName,
                checked: false,
                display: true
            };
        });

        return tenants;
    };

    /**
     * Get property address from addressComponents field
     * @param {Object} displayProperty
     * @returns {Object}
     */
    const getAddressComponents = (displayProperty) =>
    {
        let addressComponents = {
            country: "",
            countryShort: "",
            state: "",
            stateShort: "",
            city: "",
            cityShort: ""
        };

        if (displayProperty.addressComponents)
        {
            displayProperty.addressComponents.forEach((component) =>
            {
                if (component.types && component.types.includes("country"))
                {
                    addressComponents.country = component.long_name || "";
                    addressComponents.countryShort = component.short_name || "";
                }

                if (component.types && component.types.includes("administrative_area_level_1"))
                {
                    addressComponents.state = component.long_name || "";
                    addressComponents.stateShort = component.short_name || "";
                }

                if (component.types && component.types.includes("locality"))
                {
                    addressComponents.city = component.long_name || "";
                    addressComponents.cityShort = component.short_name || "";
                }
            });
        }

        return addressComponents;
    };

    /**
     * Format input date string
     * @param {String} inputDate
     * @returns {String}
     */
    const formatDate = (inputDate) =>
    {
        const date = new Date(inputDate);
        return `${MONTHS[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
    };

    /**
     * Initilize properties to be displayed after page loads
     */
    const initializeDisplayProperties = () =>
    {
        setDisplayProperties(getPropertiesFromCtx().map((property) => getPropertyDetails(property)));
    };

    /**
     * Get properties from Dashboard context
     * @returns {Object}
     */
    const getPropertiesFromCtx = () =>
    {
        let displayProperties = [];
        const { properties } = ctx.state;
        Object.values(properties).forEach((property) => displayProperties.push(property));
        return displayProperties;
    };

    const trans = useTranslation().t;

    // Functions to filter properties based on seleted filter dropdown options

    const applyStatusFilter = (displayProperty) => STATUS_DESCRIPTION[displayProperty.statusCode].statusName === statusFilter;

    const applyNameFilter = (displayProperty) => displayProperty.name.toUpperCase().includes(nameFilter.toUpperCase());

    const applyCountryFilter = (displayProperty) => displayProperty.country === countryFilter;

    const applyStateFilter = (displayProperty) => displayProperty.state.toString() === stateFilter.toString();

    const applyCityFilter = (displayProperty) => displayProperty.city === cityFilter;

    /**
     * Set propertyId in context and redirect to property details page
     * @param {String} propertyId
     */
    const handlePropertySelection = (propertyId) =>
    {
        ctx.changeSelectedProperty(propertyId);
        history.push("/propertyOverview");
    };

    // Get dropdown options for all the filters

    const getCountryOptions = (propertyDetailsArray) =>
    {
        const countryOptions = new Set();

        propertyDetailsArray.forEach((properyDetails) =>
        {
            if (properyDetails.country)
            {
                countryOptions.add(properyDetails.country);
            }
        });

        return Array.from(countryOptions).map((countryOption) => ({ text: countryOption, value: countryOption }));
    };

    const getStateOptions = (propertyDetailsArray) =>
    {
        const stateOptions = new Set();

        propertyDetailsArray.forEach((properyDetails) =>
        {
            if (properyDetails.state)
            {
                stateOptions.add(properyDetails.state);
            }
        });

        return Array.from(stateOptions).map((stateOption) => ({ text: stateOption, value: stateOption }));
    };

    const getCityOptions = (propertyDetailsArray) =>
    {
        const cityOptions = new Set();

        propertyDetailsArray.forEach((properyDetails) =>
        {
            if (properyDetails.city)
            {
                cityOptions.add(properyDetails.city);
            }
        });

        return Array.from(cityOptions).map((cityOption) => ({ text: cityOption, value: cityOption }));
    };

    const getStatusDropdownOptions = () =>
    {
        const statusOptions = Object.values(STATUS_DESCRIPTION).map((status) => ({
            text: trans(status.i18nKey),
            value: status.statusName
        }));

        return statusOptions;
    };



    const getFilterDropdownOptions = () =>
    {
        let filterDropdownOptions = {
            countryDropdownOptions: [],
            stateDropdownOptions: [],
            cityDropdownOptions: [],
            statusDropdownOptions: []
        };

        const propertyDetailsArray = getPropertiesFromCtx().map((property) => getPropertyDetails(property));

        filterDropdownOptions.countryDropdownOptions = getCountryOptions(propertyDetailsArray);
        filterDropdownOptions.stateDropdownOptions = getStateOptions(propertyDetailsArray);
        filterDropdownOptions.cityDropdownOptions = getCityOptions(propertyDetailsArray);
        filterDropdownOptions.statusDropdownOptions = getStatusDropdownOptions();

        return filterDropdownOptions;
    };

    const handleTenantUpdate = (tenantName, checked) =>
    {
        const updatedTenants = cloneDeep(tenants);
        updatedTenants[tenantName].checked = checked;
        setTenants(updatedTenants);
    };

    const handleCloseFilter = () =>
    {
        setEnableFilter(false);
        clearAllFilters();
        initializeTenants();
    };

    const { countryDropdownOptions, stateDropdownOptions, cityDropdownOptions, statusDropdownOptions } = getFilterDropdownOptions();

    const pagination = usePagination(propertiesPerPage, displayProperties.length || 0);

    const pageOfDisplayProperties = displayProperties.slice(pagination.startIndex, pagination.endIndex);

    return (
        <>
            <div className="dashboardListingWrapper">
                <DashboardSubheader
                    enableFilter={enableFilter}
                    onFilterButtonClick={() => setEnableFilter(true)}
                    onCloseFilterButtonClick={handleCloseFilter}
                    statusFilter={statusFilter}
                    statusDropdownOptions={statusDropdownOptions}
                    onStatusFilterChange={setStatusFilter}
                    nameFilter={nameFilter}
                    onNameFilterChange={setNameFilter}
                    countryDropdownOptions={countryDropdownOptions}
                    stateDropdownOptions={stateDropdownOptions}
                    cityDropdownOptions={cityDropdownOptions}
                    countryFilter={countryFilter}
                    stateFilter={stateFilter}
                    cityFilter={cityFilter}
                    onCountryFilterChange={setCountryFilter}
                    onStateFilterChange={setStateFilter}
                    onCityFilterChange={setCityFilter}
                    tenants={tenants}
                    onTenantUpdate={handleTenantUpdate}
                    tenantSearchText={tenantSearchText}
                    onTenantSearchTextChange={setTenantSearchText} />

                {!ctx.state.loading && <DashboardDataTable
                    displayProperties={pageOfDisplayProperties}
                    pagination={pagination}
                    onPropertySelection={handlePropertySelection} />}
            </div>
            <Footer />
        </>
    );
};

export default withRouter(Dashboard);

export const DashboardSubheader =
    (
        {
            enableFilter,
            onFilterButtonClick,
            onCloseFilterButtonClick,
            statusFilter,
            statusDropdownOptions,
            onStatusFilterChange,
            nameFilter,
            onNameFilterChange,
            countryDropdownOptions,
            stateDropdownOptions,
            cityDropdownOptions,
            countryFilter,
            stateFilter,
            cityFilter,
            onCountryFilterChange,
            onStateFilterChange,
            onCityFilterChange
        }) =>
    {

        const trans = useTranslation().t;

        let filtersDisplayElements = (
            <Button className="buttonIcon" onClick={onFilterButtonClick}>
                <img src="/img/icon-filter.svg" alt="" />
                {trans("Dashboard.Filter")}
            </Button>
        );

        if (enableFilter)
        {
            filtersDisplayElements = (
                <>
                    <div className="inputGroup">
                        <Dropdown
                            className="dropdownForm dropdownStatus"
                            placeholder={trans("Dashboard.Country")}
                            selectOnBlur={false}
                            options={countryDropdownOptions}
                            value={countryFilter}
                            onChange={(event, { value }) => onCountryFilterChange(value)}
                            clearable={true}
                            scrolling={true} />

                        <Dropdown
                            className="dropdownForm dropdownStatus"
                            placeholder={trans("Dashboard.State")}
                            selectOnBlur={false}
                            options={stateDropdownOptions}
                            value={stateFilter}
                            onChange={(event, { value }) => onStateFilterChange(value)}
                            clearable={true}
                            scrolling={true} />

                        <Dropdown
                            className="dropdownForm dropdownStatus"
                            placeholder={trans("Dashboard.City")}
                            selectOnBlur={false}
                            options={cityDropdownOptions}
                            value={cityFilter}
                            onChange={(event, { value }) => onCityFilterChange(value)}
                            clearable={true}
                            scrolling={true} />

                        <Dropdown
                            className="dropdownForm dropdownStatus"
                            placeholder={trans("Dashboard.Status")}
                            selectOnBlur={false}
                            options={statusDropdownOptions}
                            value={statusFilter}
                            onChange={(event, { value }) => onStatusFilterChange(value)}
                            clearable={true}
                            scrolling={true} />
                    </div>
                    <Button className="buttonIcon" content={trans("Dashboard.Close_Filter")} onClick={onCloseFilterButtonClick} />
                </>
            );
        }

        return (
            <div className="dashboardSubheader">
                <Header as="h3" className="pageHeader" content={trans("Dashboard.Properties")} />
                <Input
                    className="dashboardSearch"
                    icon={<img src="/img/icon-search.svg" alt="" />}
                    placeholder={trans("Dashboard.Search_properties")}
                    value={nameFilter}
                    onChange={(event, { value }) => onNameFilterChange(value)} />
                {filtersDisplayElements}
                <Button color="orange" className="property-button add-property-button">
                    <Link to="/dashboard/property">{trans("Subheader.Add_Property")}</Link>
                </Button>
            </div>
        );
    };
