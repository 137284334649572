import React, { useContext, useState, useEffect, useMemo } from "react";
import { Button, Dropdown } from "semantic-ui-react";
import { ModalBox } from "../../common/ModalBox";
import { CheckboxGroup } from "../../common/CheckboxGroup";
import { TextGroup } from "../../common/TextGroup";
import BrandingContext from "../../../store/BrandingContext";
import { DEFAULT_LANGUAGE_CODE } from "../../../_constants/constants";
import { useTranslation } from "react-i18next";
import { ErrorLabel } from "../../elements/ErrorLabel";
import { useOverlaysTemplateContext } from "../../../store/OverlaysTemplateContext";

import "../exportGroups/ExportGroups.css";

const ALL_PROPERTIES = "allProperties";

export const CopyTemplates = ({ openModal, onCloseModal, onSuccess }) =>
{
    const brandingCtx = useContext(BrandingContext);
    const { groupedOverlayTemplates, overlayTemplatesGroupNames, ungroupedOverlayTemplates } = useOverlaysTemplateContext();
    const trans = useTranslation().t;
    const [properties, setProperties] = useState([]);
    const [selectedProperties, setSeletedProperties] = useState("");
    const [copyTemplate, setCopyTemplate] = useState({});
    const [error, setError] = useState([]);
    const [selectAllCheck, setSelectAllCheck] = useState(false);

    useEffect(() =>
    {
        if (openModal) 
        {
            handleOnModalOpen();
        }
    }, [openModal]);

    /**
     * Sets properties from brandingContext.
     * This will be used in propery selection dropdown
     */
    const initializePropertyList = () => 
    {
        let displayProperties = [];
        const existingProperties = brandingCtx.state.properties;
        Object.keys(existingProperties)
            .filter((propertyId) => propertyId !== brandingCtx.state.propertyId)
            .map((propertyId) => displayProperties.push({
                key: propertyId,
                text: existingProperties[propertyId].name[DEFAULT_LANGUAGE_CODE],
                value: propertyId
            }));
        setProperties(displayProperties);
    };

    /**
     * Set initial copyTemplate value using templateGroups
     */
    const initializeCopyTemplate = () => 
    {
        let copyTemplate = {};
    
        const createTemplateGroup = (group, templates) => ({
            checked: false,
            name: group,
            templates: templates,
            isGroup: true,
            indeterminate: false
        });
    
        const createTemplate = (template) => ({
            checked: false,
            name: template.name[DEFAULT_LANGUAGE_CODE],
            templates: [],
            isGroup: false
        });
    
       
        overlayTemplatesGroupNames?.forEach((group) => 
        {
            const templates = getTemplatesFromGroup(group);
            copyTemplate[group] = createTemplateGroup(group, templates);
        });        
        
        ungroupedOverlayTemplates?.forEach((template) => 
        {
            copyTemplate[template._id] = createTemplate(template);
        });
        
    
        setCopyTemplate(copyTemplate);
    };
    
    const getTemplatesFromGroup = (group) =>
    {
        let templates = {};
        groupedOverlayTemplates[group].forEach((template) => 
        {
            if (template)
            {
                templates[template._id] = {
                    checked: false,
                    name: template.name[DEFAULT_LANGUAGE_CODE]
                };              
            }
        });
        return templates;
    };

    const handleOnModalOpen = () => 
    {
        initializePropertyList();
        initializeCopyTemplate();
        setSeletedProperties("");
        setSelectAllCheck(false);
        setError([]);
    };

    const handleExportButtonClick = () => 
    {
       
        brandingCtx.copyTemplate(selectedProperties, getSelectedGroups(), async (success,validationErrors) => 
        {
            if (success) 
            {
                onSuccess();
            }
            else 
            {
                setError(validationErrors);
            }
        });
    };

    /**
     * Checks/Unchecks selected group
     * and its nested templates
     * @param {Boolean} checked
     * @param {String} groupId
     */
    const handleGroupCheckboxChange = (checked, groupId) => 
    {
        const updatedCopyTemplate = { ...copyTemplate };

        updatedCopyTemplate[groupId].checked = checked;

        updateAllTemplates(checked, groupId, updatedCopyTemplate);

        setCopyTemplate(updatedCopyTemplate);            
        setError([]);
        if (selectAllCheck)
        {
            setSelectAllCheck(!selectAllCheck);
        } 
    };

    /**
     * Checks/Unchecks selected template under it
     * @param {Boolean} checked
     * @param {String} templateId
     * @param {String} groupId - Parent group
     */
    
    const handleTemplateCheckboxChange = (checked, templateId, groupId) => 
    {
        const updatedCopyTemplate = { ...copyTemplate };
    
        // Update the specific template's checked status
        updatedCopyTemplate[groupId].templates[templateId].checked = checked;
    
        // Get all template IDs for the group
        const templateIds = Object.keys(updatedCopyTemplate[groupId].templates);
        const allChecked = templateIds.every((id) => updatedCopyTemplate[groupId].templates[id].checked);
        const someChecked = templateIds.some((id) => updatedCopyTemplate[groupId].templates[id].checked);
    
        // Update the group's checked and indeterminate status based on the templates
        updatedCopyTemplate[groupId].checked = allChecked;
        updatedCopyTemplate[groupId].indeterminate = !allChecked && someChecked;
    
        setCopyTemplate(updatedCopyTemplate);
        setError([]);
        
        // Toggle selectAllCheck if it was previously true
        if (selectAllCheck) 
        {
            setSelectAllCheck(false);
        }
    };
    
  
    const handleSelectAllToggle = (event, { checked }) => 
    {
        const updatedCopyTemplate = { ...copyTemplate };

        Object.keys(updatedCopyTemplate).forEach((groupId) => 
        {
            updatedCopyTemplate[groupId].checked = checked;
            updateAllTemplates(checked, groupId, updatedCopyTemplate);
        });

        setCopyTemplate(updatedCopyTemplate);
        setSelectAllCheck(checked);
    };

    /**
     * Updates checked flag of all templates belonging to the given group
     * @param {Boolean} checked
     * @param {String} groupId
     * @param {Object} updatedCopyTemplate
     */
    const updateAllTemplates = (checked, groupId, updatedCopyTemplate) => 
    {
        Object.keys(updatedCopyTemplate[groupId].templates).forEach((templateId) => 
        {
            updatedCopyTemplate[groupId].templates[templateId].checked = checked;
        });
    };

    /**
     * Returns array with all groups which have checked  flag set as true
     * @returns {Object}
     */
    const getSelectedGroups = () => 
    {
        const selectedGroups = {};
    
        Object.entries(copyTemplate).forEach(([groupId, groupData]) => 
        {
            if (groupData.isGroup) 
            {
                Object.entries(groupData.templates).forEach(([templateId, templateData]) => 
                {
                    if (templateData.checked) 
                    {
                        selectedGroups[templateId] = templateData;
                    }
                });
            }
            else if (groupData.checked) 
            {
                selectedGroups[groupId] = groupData;
            }
        });
    
        return Object.keys(selectedGroups);
    };

    /**
     * Set the selected properties
     * @param {Array<String>} value 
     */
    const handlePropertySelect = (value) =>
    {
        if (value.includes(ALL_PROPERTIES))
        {
            // select all the properties
            const propertyIds = properties.map((property) => property.value);
            setSeletedProperties(propertyIds);
        }
        else
        {
            setSeletedProperties(value);
        }
        setError(false);
    };

    const overflowClass = selectedProperties?.length > 10 && "dropdown-auto";
  
    const CopyTemplateDisplay = (
        <div className="groupCategoryCover">
            {Object.keys(copyTemplate).map((groupId) => 
            {
                const groupDetails = copyTemplate[groupId];
                return (
                    <GroupTemplateItem
                        key={groupId}
                        content={groupDetails.name}
                        checked={groupDetails.checked}
                        indeterminate={groupDetails.indeterminate}
                        onChange={(event, { checked }) => handleGroupCheckboxChange(checked, groupId)}
                    >
                        {Object.keys(groupDetails.templates).map((templateId) => 
                        {
                            const templateDetails = groupDetails.templates[templateId];
                            return (
                                <GroupTemplateItem
                                    key={templateId}
                                    content={templateDetails.name}
                                    checked={templateDetails.checked}
                                    onChange={(event, { checked }) => handleTemplateCheckboxChange(checked, templateId, groupId)}
                                >
                                 
                                </GroupTemplateItem>
                            );
                        })}
                    </GroupTemplateItem>
                );
            })}
        </div>
    );

    const propertyOptions = useMemo(() =>
    {
        // if all the properties are not yet selected, provide 'Select All' option
        if (selectedProperties.length === properties.length)
        {
            return properties;
        }
        else
        {
            return [
                {
                    key: ALL_PROPERTIES, 
                    value: ALL_PROPERTIES, 
                    text: trans("CopyTemplates.Select_All")
                },
                ...properties
            ];
        }
    }, [selectedProperties, properties]);

    return (
        <ModalBox
            size="small"
            className="exportGroupModal"
            open={openModal}
            onClose={onCloseModal}
            header= {trans("CopyTemplates.Copy_Templates")}
            actions={<Button
                primary
                floated="right"
                content={trans("CopyTemplates.Copy")}
                onClick={handleExportButtonClick}
                disabled={!(selectedProperties && (Object.keys(getSelectedGroups()).length > 0))} />}
        >

            <p className="para">{trans("CopyTemplates.Modal_Text")}</p>

            <TextGroup title={trans("CopyTemplates.Select_Property")}>
                <Dropdown
                    multiple
                    selection
                    search
                    icon="angle down"
                    placeholder={trans("CopyTemplates.Property_Name")}
                    options={propertyOptions}
                    className={overflowClass}
                    style={{ marginTop: "7px" }}
                    onChange={(event, { value }) => 
                    {
                        handlePropertySelect(value);
                    }}
                    value={selectedProperties}
                    scrolling={true}
                />
             
            </TextGroup>

            <div className="select-all-checkbox">
                <CheckboxGroup
                    checked={selectAllCheck}
                    label={trans("CopyTemplates.Select_All")}
                    onChange={handleSelectAllToggle} />
            </div>

            {CopyTemplateDisplay}
        
            {error.length > 0 
            && <ErrorLabel  multiple={Object.keys(error).length > 0} >
                {trans("CopyTemplates.Error_Message")}
                <ul>
                    {error.map((validationError) => <li key={validationError}>{validationError}</li>)}
                </ul>
            </ErrorLabel>
            }
        </ModalBox>
    );
};

const GroupTemplateItem = ({ content, children, checked,indeterminate, onChange }) => (
    <div className="groupCategoryItem">
        <div className="groupCategoryItemButton">
            <CheckboxGroup
                checked={checked}
                indeterminate={indeterminate}
                onChange={onChange}/>
            <p>{content}</p>
        </div>
        {
            children && <div className="subGroupCategoryCover">{children}</div>
        }
    </div>
);

export const CopyTemplateSuccessModal = ({
    successMessageModalOpen,
    onSuccessMessageModalClose
}) =>
{
    const trans = useTranslation().t;

    return (
        <ModalBox 
            size="tiny"
            header={trans("CopyTemplates.Success_Heading")}
            open={successMessageModalOpen}
            className="exportGroupModal-success-message"
            onClose={onSuccessMessageModalClose}
            actions={<Button
                primary
                floated="right"
                className="exportGroupModal-success-action"
                content={trans("CopyTemplates.Success_Action")}
                onClick={onSuccessMessageModalClose} />}>
            <span style={{ fontSize: "16px" }}>{trans("CopyTemplates.Success_Body")}</span>        
        </ModalBox>
    );  
};
