import React from 'react';
import { Button } from 'semantic-ui-react';

export const ButtonIcon = ({className, icon, content, add, onClick}) =>
{
    return (
        <Button onClick={onClick} className={`buttonIcon${add ? " button-more":""}${className ? " "+className:""}`}>
            <img src={`/img/icon-${icon ? icon :""}.svg`} alt="" />
            {content}
        </Button>
    );
}