import React, { forwardRef } from "react";
import "./ImageSkeleton.css";
import { Placeholder, PlaceholderImage } from "semantic-ui-react";

const ImageSkeleton = forwardRef(({ className = "" }, ref) => (
    <div ref={ref} className={`image-skeleton ${className}`}>
        <Placeholder  >
            <PlaceholderImage square />
        </Placeholder>
    </div> 
)
);

ImageSkeleton.displayName = "ImageSkeleton";

export default ImageSkeleton;
