import React from "react";
import IconsManagementSidebar from "./IconsManagementSidebar/IconsManagementSidebar";
import IconsManagementMain from "./IconsManagementMain/IconsManagementMain";
import "./IconsManagement.css";
import { GmsActionMode, useGmsContext } from "../GmsContext";
import IconsManagementFooter from "./IconsManagementFooter/IconsManagementFooter";

function IconsManagement() 
{
    const gmsContext = useGmsContext();

    return (
        <div className="icons-management">
            <div className="icons-management-inner">
                <IconsManagementSidebar />
                <IconsManagementMain />
            </div>
            {gmsContext.gmsActionMode === GmsActionMode.LINK_ICON && <IconsManagementFooter></IconsManagementFooter>}  
        </div>
    );
}

export default IconsManagement;