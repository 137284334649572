import React from "react";
import { Scrollbars } from "react-custom-scrollbars-2";

export const AddPropertyLayout = (props) =>
{
    return (
        <Scrollbars autoHeight autoHeightMin={`calc(1vh)`} autoHeightMax={`calc(100vh - 170px)`}>
            <div className="aligner">
                {props.children}
            </div>
        </Scrollbars>
    );
}