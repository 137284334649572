import React from "react";
import {
    Button,
    Modal,
    ModalActions,
    ModalContent,
    ModalHeader,
} from "semantic-ui-react";
import { filerUrl } from "../../../../../_utils/utils";
import { IMAGE_ASPECT_RATIOS_TO_LABEL } from "../../constant";
import { bytesToMB } from "../../utils";
import moment from "moment";
import "./ImageViewModal.css";
import LinkedEntities from "../LinkedEntities/LinkedEntities";
import { useImagesBaseContext } from "../contexts/ImageBaseContext";
import { GmsActionMode, useGmsContext } from "../../GmsContext";
import { useImageDeleteContext } from "../contexts/ImageDeleteContext";
import { useImageEditContext } from "../contexts/ImageEditContext";

function ImageViewModal() 
{
    const gmsContext = useGmsContext();
    const { selectedImageInfo, handleCloseViewModal } =
        useImagesBaseContext();
    const imageDeleteContext = useImageDeleteContext();
    const imageEditContext = useImageEditContext();
    const hasLinkedEntities = !!selectedImageInfo?.usedIn?.length;

    return (
        <Modal
            open={true}
            onClose={handleCloseViewModal}
            className="image-view-modal"
            closeIcon={<Button onClick={gmsContext.handleCloseGms} className="close" />}
        >
            <ModalHeader className="image-view-modal-header">
                <Button onClick={handleCloseViewModal} className="goBack" >
                    <img src="/img/icon-arrow-cms.svg" alt="/" />
                </Button>
                <div className="image-view-modal-image-header-name">
                    {selectedImageInfo.name}
                </div>
            </ModalHeader>
            <ModalContent>
                <div className="image-container">
                    <img src={filerUrl(selectedImageInfo.filerId)}></img>
                </div>
                <div className="image-info-container">
                    <div className="image-info-section">
                        <ImageInfoSection>
                            <div className="image-info-label">Image Name:</div>
                            <div className="image-info-value">{selectedImageInfo.name}</div>
                        </ImageInfoSection>
                        <ImageInfoSection>
                            <div className="image-info-label">Uploaded file&apos;s name:</div>
                            <div className="image-info-value">
                                {selectedImageInfo.fileName}
                            </div>
                        </ImageInfoSection>
                        <ImageInfoSection>
                            <div className="image-info-label">Image Ratio:</div>
                            <div className="image-info-value">
                                {IMAGE_ASPECT_RATIOS_TO_LABEL[selectedImageInfo.aspectRatio]}
                            </div>
                        </ImageInfoSection>
                        <ImageInfoSection>
                            <div className="image-info-label linked-entities-label">Linked Entities:</div>
                            <LinkedEntities />
                        </ImageInfoSection>
                    </div>
                    <div className="image-info-section">
                        <ImageInfoSection>
                            <div className="image-info-label">Size:</div>
                            <div className="image-info-value">
                                {bytesToMB(selectedImageInfo.size)} MB
                            </div>
                        </ImageInfoSection>
                        <ImageInfoSection>
                            <div className="image-info-label">Uploaded:</div>
                            <div className="image-info-value">
                                {moment(selectedImageInfo.createdAt).format("MMM D, YYYY")}
                            </div>
                        </ImageInfoSection>
                    </div>
                </div>
            </ModalContent>
            {gmsContext.gmsActionMode === GmsActionMode.VIEW && <ModalActions>
                <Button onClick={() => imageEditContext.handleTriggerEditImage(selectedImageInfo._id)}
                    className="edit-n-btn" color="orange">Edit</Button>
                <Button 
                    onClick={() => 
                    {
                        imageDeleteContext.handleTriggerDeleteImage(selectedImageInfo._id);
                    }}
                    
                    className="delete-btn" color="orange"
                >
                    <div className="delete-img-wrapper">
                        <img src={"/img/icon-delete-red.svg"} />
                    </div>
                    Delete
                </Button>
            </ModalActions>}
        </Modal>
    );
}

function ImageInfoSection({ children }) 
{
    return <div className="image-info-layout">{children}</div>;
}



export default ImageViewModal;
