import React, { createContext, useContext, useMemo, useRef, useState } from "react";
import { ACCEPTABLE_IMAGE_FILE_FORMATS, ERROR_TYPES, FILE_UPLOAD_LIMIT, FILE_UPLOAD_STATUS, IMAGE_ASPECT_RATIOS, IMAGE_UPLOAD_ACTIONS, MAX_COVER_IMAGES_LIMIT, MAX_FILE_SIZE_BYTES } from "../../constant";
import { asyncReadFilesAsDataURL } from "../../utils";
import { useQueryClient } from "react-query";
import { v4 as uuidv4 } from "uuid";
import { GmsActionMode, useGmsContext } from "../../GmsContext";
import serverApi from "../../../../../_api/server.api";
import {  useCroppedImagesHistory } from "../hooks/useCroppedImageHistory";
import { useImagesBaseContext } from "./ImageBaseContext";
import { toast } from "react-toastify";
import { Trans, useTranslation } from "react-i18next";
import { getBase64FromImageUrl } from "../../../../../_utils/utils";
import { truncateText } from "../../../../../_utils/mapUtils";

/**
 * @typedef {Object} UploadedFileInfo
 * @property {string} id - Unique identifier for the file
 * @property {string} name - Name of the file
 * @property {string} originalName - Original name of the file
 * @property {string} type - MIME type of the file
 * @property {number} size - Size of the file in bytes
 * @property {string} imageSrc - Data URL of the image
 * @property {string} aspectRatio - Aspect ratio of the image
 * @property {string} webkitRelativePath - Relative path of the file
 * @property {number} lastModified - Last modified timestamp
 * @property {Date} lastModifiedDate - Last modified date
 * @property {boolean} isEdited - Whether the file has been edited
 * @property {string} uploadStatus - Current upload status
 */

/**
 * @typedef {Object} UploadStatus
 * @property {string} status - Current upload status (NOT_UPLOADED, UPLOADING, UPLOADED, FAILED)
 * @property {Error} [reason] - Error reason if upload failed
 */

/**
 * Context for managing image upload functionality
 * @type {React.Context<ImagesUploadContextValue>}
 */
const ImagesUploadContext = createContext();

/**
 * Custom hook to access the Images Upload context
 * @returns {ImagesUploadContextValue} The images upload context value
 * @throws {Error} When used outside of ImagesUploadProvider
 */
export const useImagesUploadContext = () => useContext(ImagesUploadContext);

/**
 * Provider component for image upload functionality
 * @param {Object} props
 * @param {React.ReactNode} props.children - Child components
 * @returns {JSX.Element} Provider component
 */
export function ImagesUploadProvider({ children }) 
{
    const imagesBaseContext = useImagesBaseContext();
    const gmsContext = useGmsContext();
    const cropperRef = useRef();
    const [uploadedFilesInfo, setUploadedFilesInfo] = useState([]);
    const [uploadedFilesUploadStatus, setUploadedFilesUploadStatus] = useState(
        {}
    );
    const [selectedImageIdFromBulkUpload, setSelectedImageIdFromBulkUpload] =
        useState(null);
    const [imageUploadAction, setImageUploadAction] = useState(null);
    const [showImageUploadProgressModal, setShowImageUploadProgressModal] =
        useState(false);
    const [url, setUrl] = useState("");
    const [urlCheckStatus, setUrlCheckStatus] = useState("idle");
    const { croppedImagesHistory, ...croppedImagesHistoryRest } = useCroppedImagesHistory();
    const queryClient = useQueryClient();
    const { loadingPool } = gmsContext;
    const { t: trans } = useTranslation();
    const [bulkImageUploadCompleteStatus, setBulkImageUploadCompleteStatus] = useState("idle");

    const selectedImageInfoFromBulkUpload = useMemo(() => 
    {
        if (!uploadedFilesInfo.length || !selectedImageIdFromBulkUpload)
            return null;
        return uploadedFilesInfo.find(
            (file) => file.id === selectedImageIdFromBulkUpload
        );
    }, [uploadedFilesInfo, selectedImageIdFromBulkUpload]);



    function getFileImageSrc(id) 
    {
        if (!id) return null;
        if (croppedImagesHistory?.[id]) 
        {
            return croppedImagesHistory[id].currentImage;
        }
        else 
        {
            const fileInfo = uploadedFilesInfo.find((file) => file.id === id);
            if (!fileInfo) return null;
            return fileInfo.imageSrc;
        }
    }

    async function handleTriggerSingleImageUpload(e) 
    {
        try 
        {
            const [file] = e.target.files;
            if (!ACCEPTABLE_IMAGE_FILE_FORMATS.includes(file.type)) 
            {
                toast.error(trans("gms.Invalid_File_Type"));
                return;
            }

            if (file.size > MAX_FILE_SIZE_BYTES) 
            {
                toast.error(trans("gms.image_max_size_err"));
                return;
            }

            const dataUrl = await asyncReadFilesAsDataURL([file])[0];
            if (!dataUrl) 
            {
                toast.error(trans("gms.File_read_err"));
                return;
            }

            const aspectRatio =  imagesBaseContext.imagesSearchAndFilters.filters.ratio  || IMAGE_ASPECT_RATIOS.RATIO_1;
            const uploadedFile = {
                name: file.name,
                webkitRelativePath: file.webkitRelativePath,
                type: file.type,
                lastModified: file.lastModified,
                size: file.size,
                lastModifiedDate: file.lastModifiedDate,
                id: uuidv4(),
                originalName: file.name,
                isEdited: false,
                uploadStatus: FILE_UPLOAD_STATUS.NOT_UPLOADED,
                imageSrc: dataUrl,
                aspectRatio
            };

            setUploadedFilesInfo([uploadedFile]);
            if (imagesBaseContext.croppingEnabled)
            {
                setImageUploadAction(IMAGE_UPLOAD_ACTIONS.UPLOAD_IMAGE);
            }
            else 
            {
                handleUploadRawImage({ uploadedFileDetails: uploadedFile });
            }
        }
        catch (err) 
        {
            console.log(err);
            toast.error(trans("gms.File_Upload_Failed"));

        }
    }

    async function handleTriggerBulkImageUpload(e) 
    {
        try 
        {
            const files = [...(e.target.files || [])];
            if (files.length > FILE_UPLOAD_LIMIT) 
            {
                toast.error(trans("gms.bulk_image_add_size_limit_err"));
                return;
            }

            const filesOfIncorrectFormat = files.filter((file) => !ACCEPTABLE_IMAGE_FILE_FORMATS.includes(file.type));

            if (filesOfIncorrectFormat.length)
            {
                toast.error(<>
                    <Trans 
                        i18nKey="gms.Invalid_File_Type_bulk"
                        values={{ images: filesOfIncorrectFormat.map((file) => truncateText(file.name, 22)).join(", ")  }}
                    /></>, {           
                });
            }

            const filesExceedingSizeLimit = files.filter(
                (file) => file.size > MAX_FILE_SIZE_BYTES
            );
            const filesWithinLimit = files.filter(
                (file) => file.size <= MAX_FILE_SIZE_BYTES && ACCEPTABLE_IMAGE_FILE_FORMATS.includes(file.type)
            );

            if (filesExceedingSizeLimit.length) 
            {
                toast.error(<>
                    <Trans 
                        i18nKey="gms.images_max_size_err"
                        values={{ images: filesExceedingSizeLimit.map((file) => truncateText(file.name, 22)).join(", ")  }}
                    /></>, {           
                });
            }

            if (filesWithinLimit.length) 
            {
                const dataUrls = await Promise.allSettled(
                    asyncReadFilesAsDataURL(filesWithinLimit)
                );

                const fulfilledFiles = dataUrls.filter(
                    (result) => result.status === "fulfilled"
                );
                const rejectedFiles = dataUrls.filter(
                    (result) => result.status === "rejected"
                );

                if (rejectedFiles.length) 
                {
                    toast.error(<>
                        <Trans 
                            i18nKey="gms.files_parsing_err"
                            values={{ images: rejectedFiles.map((file) => truncateText(file.name, 22)).join(", ")  }}
                        /></>);
                }

                if (fulfilledFiles.length) 
                {
                    const newUploadedFilesInfo = fulfilledFiles.map(
                        ({ value }) => 
                        {
                            const file =
                                filesWithinLimit[
                                    dataUrls.findIndex(
                                        (result) => result.value === value
                                    )
                                ];
                            const aspectRatio =  imagesBaseContext.imagesSearchAndFilters.filters.ratio || IMAGE_ASPECT_RATIOS.RATIO_1;

                            return {
                                name: file.name,
                                webkitRelativePath: file.webkitRelativePath,
                                type: file.type,
                                lastModified: file.lastModified,
                                size: file.size,
                                lastModifiedDate: file.lastModifiedDate,
                                id: uuidv4(),
                                originalName: file.name,
                                imageSrc: value,
                                aspectRatio
                            };
                        }
                    );
                    setUploadedFilesInfo(newUploadedFilesInfo);
                    setSelectedImageIdFromBulkUpload(
                        newUploadedFilesInfo[0].id
                    );
                    setImageUploadAction(IMAGE_UPLOAD_ACTIONS.BULK_UPLOAD);
                }
            }
        }
        catch (err) 
        {
            console.log(err);
            toast.error("gms.File_Upload_Failed");
        }
    }

    async function handleUploadRawImage({ uploadedFileDetails }) 
    {
        const loadingId = loadingPool.add();
        const uploadedFileInfo = uploadedFileDetails;
        try 
        {
            setUploadedFilesUploadStatus((prev) => ({
                ...prev,
                [uploadedFileInfo.id]: { status: FILE_UPLOAD_STATUS.UPLOADING },
            }));
            let currentDataURL = uploadedFileInfo.imageSrc;
            const blob = await (await fetch(currentDataURL)).blob();
            const filerId = await serverApi.filerUpload(blob);
            const payload = {
                name: uploadedFileInfo.name,
                fileName: uploadedFileInfo.originalName,
                size: uploadedFileInfo.size,
                filerId: filerId,
                mimeType: uploadedFileInfo.type,
                aspectRatio: +uploadedFileInfo.aspectRatio,
            };
            const response = await serverApi.createImageReference(payload);
            await imagesBaseContext.imagesQuery.refetch();
            setUploadedFilesUploadStatus((prev) => ({
                ...prev,
                [uploadedFileInfo.id]: { status: FILE_UPLOAD_STATUS.UPLOADED },
            }));
            if (gmsContext.gmsActionMode === GmsActionMode.LINK_ICON_IMAGE) 
            {
                imagesBaseContext.setImagesSelectedForLinking([response.result._id]);
            }
            toast.success(trans("gms.image_upload_success"));
            handleResetUpload();
        }
        catch (err) 
        {
            console.log(err);
            toast.error(trans("gms.image_upload_err"));
            setUploadedFilesUploadStatus((prev) => ({
                ...prev,
                [uploadedFileInfo.id]: { status: FILE_UPLOAD_STATUS.FAILED },
            }));
        }
        finally 
        {
            loadingPool.remove(loadingId);
        }
    }

    async function handleUploadImage() 
    {
        const loadingId = loadingPool.add();
        const uploadedFileInfo = uploadedFilesInfo[0];
        try 
        {
            setUploadedFilesUploadStatus((prev) => ({
                ...prev,
                [uploadedFileInfo.id]: { status: FILE_UPLOAD_STATUS.UPLOADING },
            }));
            let currentDataURL = "";

            if (!croppedImagesHistory?.[uploadedFileInfo.id]?.currentImage)
            {
                currentDataURL = cropperRef.current.cropper
                    .getCroppedCanvas()
                    .toDataURL();
            }
            else if (croppedImagesHistory?.[uploadedFileInfo.id]?.currentImage)
            {
                currentDataURL = croppedImagesHistory?.[uploadedFileInfo.id]?.currentImage;
            }
            else if (uploadedFileInfo.imageSrc)
            {
                currentDataURL = uploadedFileInfo.imageSrc;
            }
               
            const blob = await (await fetch(currentDataURL)).blob();
            const filerId = await serverApi.filerUpload(blob);
            const payload = {
                name: uploadedFileInfo.name,
                fileName: uploadedFileInfo.originalName,
                size: uploadedFileInfo.size,
                filerId: filerId,
                mimeType: uploadedFileInfo.type,
                aspectRatio: +uploadedFileInfo.aspectRatio,
            };
            const response = await serverApi.createImageReference(payload);
            await imagesBaseContext.imagesQuery.refetch();
            setUploadedFilesUploadStatus((prev) => ({
                ...prev,
                [uploadedFileInfo.id]: { status: FILE_UPLOAD_STATUS.UPLOADED },
            }));
            if (gmsContext.gmsActionMode === GmsActionMode.LINK_ICON_IMAGE) 
            {
                imagesBaseContext.setImagesSelectedForLinking([response.result._id]);
            }
            toast.success(trans("gms.image_upload_success"));
            handleResetUpload();
        }
        catch (err) 
        {
            console.log(err);
            toast.error(trans("gms.image_upload_err"));
            setUploadedFilesUploadStatus((prev) => ({
                ...prev,
                [uploadedFileInfo.id]: { status: FILE_UPLOAD_STATUS.FAILED },
            }));
        }
        finally 
        {
            loadingPool.remove(loadingId);
        }
    }

    async function handleBulkUploadImage() 
    {
        setBulkImageUploadCompleteStatus("loading");
        setUploadedFilesUploadStatus((prev) => ({
            ...prev,
            ...uploadedFilesInfo.reduce(
                (acc, file) => ({
                    ...acc,
                    [file.id]: { status: FILE_UPLOAD_STATUS.UPLOADING },
                }),
                {}
            ),
        }));
        setShowImageUploadProgressModal(true);
        setImageUploadAction(null);
        try 
        {
            const uploadedFilesPromises = uploadedFilesInfo.map(
                async (uploadedFileInfo) => 
                {
                    try 
                    {
                        const currentDataURL =
                            croppedImagesHistory?.[uploadedFileInfo.id]
                                ?.currentImage || uploadedFileInfo.imageSrc;
                        const blob = await (await fetch(currentDataURL)).blob();
                        const filerId = await serverApi.filerUpload(blob);
                        const payload = {
                            name: uploadedFileInfo.name,
                            fileName: uploadedFileInfo.originalName,
                            size: uploadedFileInfo.size,
                            filerId: filerId,
                            mimeType: uploadedFileInfo.type,
                            aspectRatio: +uploadedFileInfo.aspectRatio,
                        };
                        const createdImg = await serverApi.createImageReference(
                            payload
                        );

                        setUploadedFilesUploadStatus((prev) => ({
                            ...prev,
                            [uploadedFileInfo.id]: {
                                status: FILE_UPLOAD_STATUS.UPLOADED,
                            },
                        }));
                        return createdImg;
                    }
                    catch (err) 
                    {
                        setUploadedFilesUploadStatus((prev) => ({
                            ...prev,
                            [uploadedFileInfo.id]: {
                                status: FILE_UPLOAD_STATUS.FAILED,
                                reason: err,
                            },
                        }));
                        throw err;
                    }
                }
            );
            const results = await Promise.allSettled(uploadedFilesPromises);
            const rejectedResults = results.filter(
                (result) => result.status === "rejected"
            );
            const fulfilledResults = results.filter(
                (result) => result.status === "fulfilled"
            );
            const response= await imagesBaseContext.imagesQuery.refetch();
            const imagesData = response.data.pages[0].results;
            setBulkImageUploadCompleteStatus("ready");
            if (rejectedResults.length === uploadedFilesInfo.length) 
            {
                throw new Error();
            }
            
            if (gmsContext.gmsActionMode === GmsActionMode.LINK_COVER_IMAGES) 
            {
                const noOfImagesToBeSelectedForLinking =  MAX_COVER_IMAGES_LIMIT - imagesBaseContext.preAddedImageFilerIds.length;
                const imagesSelectedForLinking = imagesData.slice(0, noOfImagesToBeSelectedForLinking);
                imagesBaseContext.setImagesSelectedForLinking(
                    imagesSelectedForLinking.map((result) => result._id)
                );
            }
        }
        catch (err) 
        {
            console.log(err);
            setBulkImageUploadCompleteStatus("failed");
            toast.error(trans("gms.bulk_image_upload_err"));
        }
    }

    
    async function checkURLValidity(value) 
    {
        try 
        {
            setUrlCheckStatus("loading");

            const response =  await fetch("/api/internal/public/images/responseHeaders?link=" + value.trim());
            if (!response.ok)
            {
                toast.error(trans("gms.Url_read"));
                return;
            }
            const { headers } = await response.json();

            if (!headers)
            {
                toast.error(trans("gms.Url_read"));
                return;
            }
        
            if (!ACCEPTABLE_IMAGE_FILE_FORMATS.includes(headers?.["content-type"]))
            {
                toast.error(trans("gms.Invalid_File_Type"));
                return;            
            }

            if (headers?.["content-length"] > MAX_FILE_SIZE_BYTES)
            {
                toast.error(trans("gms.image_max_size_err"));
                return;                 
            }

            const fileName = value.split("/").pop().split("#")[0].split("?")[0];
            const dataUrl = await getBase64FromImageUrl({ url: value, imageType: headers["content-type"] });
            const uploadedFile = {
                name: fileName,
                type: headers["content-type"],
                size: +headers["content-length"],
                id: uuidv4(),
                originalName: fileName,
                isEdited: false,
                uploadStatus: FILE_UPLOAD_STATUS.NOT_UPLOADED,
                imageSrc: dataUrl,
                aspectRatio:
                    imagesBaseContext.imagesSearchAndFilters.filters.ratio
                    || IMAGE_ASPECT_RATIOS.RATIO_1,
            };

            setUploadedFilesInfo([uploadedFile]);
            setUrlCheckStatus("ready");
            if (imagesBaseContext.croppingEnabled)
            {
                setImageUploadAction(IMAGE_UPLOAD_ACTIONS.UPLOAD_IMAGE);
            }
            else 
            {
                handleUploadRawImage({ uploadedFileDetails: uploadedFile });
            }
        }
        catch (err)
        {
            console.error(err);
            setUrlCheckStatus("error");
            toast.error(trans("gms.Url_read"));
        }
        finally 
        {
            setUrlCheckStatus("idle");
        }
    }


    function handleChangeURL(value)
    {
        setUrl(value);
        if (value) checkURLValidity(value);
    }

    function handleTriggerUploadFromUrl() 
    {
        setImageUploadAction(IMAGE_UPLOAD_ACTIONS.UPLOAD_FROM_URL);
    }

    function handleChangeFileInfoValues({ id, value }) 
    {
        const newUploadedFilesInfo = uploadedFilesInfo.map((file) => file.id === id ? { ...file, ...value } : { ...file }
        );
        setUploadedFilesInfo(newUploadedFilesInfo);
    }

    function handleSelectImageFromBulkUpload(id) 
    {
        setSelectedImageIdFromBulkUpload(id);
    }

    function handleResetUpload()
    {
        setImageUploadAction(null);
        setUrl("");
        setUrlCheckStatus("idle") ;
        croppedImagesHistoryRest.setCroppedImagesHistory(null);
        setSelectedImageIdFromBulkUpload(null);
        setShowImageUploadProgressModal(false);
        setUploadedFilesUploadStatus({});
        setUploadedFilesInfo([]);
        setBulkImageUploadCompleteStatus("idle");
        cropperRef.current = null;
    }


    const value = {
        selectedImageInfoFromBulkUpload,
        uploadedFilesInfo,
        uploadedFilesUploadStatus,
        bulkImageUploadCompleteStatus,
        selectedImageIdFromBulkUpload,
        imageUploadAction,
        showImageUploadProgressModal,
        croppedImagesHistory,
        cropperRef,
        url,
        urlCheckStatus,
        handleResetUpload,
        handleChangeURL,
        getFileImageSrc,
        handleUploadImage,
        handleBulkUploadImage,
        handleTriggerSingleImageUpload,
        handleTriggerBulkImageUpload,
        handleTriggerUploadFromUrl,
        handleChangeFileInfoValues,
        handleSelectImageFromBulkUpload,
        ...croppedImagesHistoryRest,
    };

    return <ImagesUploadContext.Provider value={value}>{children}</ImagesUploadContext.Provider>;
}


/**
 * @typedef {Object} ImagesUploadContextValue
 * @property {UploadedFileInfo|null} selectedImageInfoFromBulkUpload - Currently selected image from bulk upload
 * @property {UploadedFileInfo[]} uploadedFilesInfo - Array of uploaded file information
 * @property {Object[]} uploadedFilesHavingError - Array of files that failed to upload
 * @property {Object.<string, UploadStatus>} uploadedFilesUploadStatus - Upload status for each file
 * @property {string|null} selectedImageIdFromBulkUpload - ID of selected image from bulk upload
 * @property {string|null} imageUploadAction - Current upload action
 * @property {boolean} showImageUploadProgressModal - Whether to show upload progress modal
 * @property {React.RefObject} cropperRef - Reference to image cropper
 * @property {function(string): string|null} getFileImageSrc - Get image source for file
 * @property {function(): Promise<void>} handleUploadImage - Handle single image upload
 * @property {function(): Promise<void>} handleBulkUploadImage - Handle bulk image upload
 * @property {function(Event): Promise<void>} handleTriggerSingleImageUpload - Handle single image upload trigger
 * @property {function(Event): Promise<void>} handleTriggerBulkImageUpload - Handle bulk image upload trigger
 * @property {function(): void} handleTriggerUploadFromUrl - Handle upload from URL trigger
 * @property {function({ id: string, value: Object }): void} handleChangeFileInfoValues - Update file info values
 * @property {function(string): void} handleSelectImageFromBulkUpload - Select image from bulk upload
 */