import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Button, Dropdown, Input, Modal, ModalActions, ModalContent } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useOverlaysTemplateContext } from "../../../store/OverlaysTemplateContext";
import { OVERLAYS_TEMPLATE_MODES, OVERLAY_TEMPLATE_DEFAULT_DYNAMIC_SETTINGS, OVERLAY_TEMPLATE_DEFAULT_ZOOM_LEVEL_SETTINGS } from "../../../_constants/overlaysTemplate";
import { TextGroup } from "../../common/TextGroup";
import DefaultOpacitySettings from "./mapOverlaysSidebars/DefaultOpacitySettings";
import { DividerLine } from "../../common/DividerLine";
import ZoomLevelSettings from "./mapOverlaysSidebars/ZoomLevelSettings";
import { CheckboxGroup } from "../../common/CheckboxGroup";
import { ButtonIcon } from "../../common/ButtonIcon";
import Scrollbars from "react-custom-scrollbars-2";
import { DEFAULT_LANGUAGE_CODE } from "mapsted.maps/utils/map.constants";
import { useSetState } from "ahooks";
import { checkAndConvertToHex, getShapeFromGeometry, prependDefault } from "./utils/mapOverlayUtils";
import { NEW_OVERLAY_TEMPLATE_DEFAULT } from "../../../_constants/overlaysTemplate";
import cloneDeep from "lodash.clonedeep";
import { v4 as uuid } from "uuid";
import { validateCurrentOverlayTemplate } from "../../../_utils/overlayTemplates";
import useMap from "../../../hooks/useMap";
import BrandingContext from "../../../store/BrandingContext";
import { styleClassic } from "mapsted.maps/utils/defualtStyles";
import { Vector as VectorSource } from "ol/source";
import { Vector as VectorLayer } from "ol/layer";
import { Feature, View } from "ol";
import { Style } from "ol/style";
import { Point } from "ol/geom";
import MapOverlaysDrawingComponent from "./mapOverlaysDrawingComponent/MapOverlaysDrawingComponent";
import { createActiveMapOverlayStyle, createStyle, createTextStyle, getTextFontOptions } from "mapsted.maps/mapFunctions/mapOverlay";
import { createEntityGeometry } from "mapsted.maps/mapFunctions/plotting";
import { ErrorLabel } from "../../elements/ErrorLabel";
import MapOverlayCreationError from "./mapOverlaysSidebars/MapOverlayCreationError";
import { getCenter } from "ol/extent";
import { ModalBox } from "../../common/ModalBox";
import isEqual from "lodash.isequal";

import "./overlayTemplateCreationModal.css";

const TEMPLATE_TEXT_FEATURE_ID = "template_text_feature_id";

const OverlaysTemplateCreationModal = () =>
{
    const trans = useTranslation().t;

    const scrollBarRef = useRef();

    const {
        handleOverlayTemplateMode,
        overlayTemplateMode,
        overlayCreateModalToggle,
        overlayTemplatesGroupNames,
        overlayTemplates,
        selectedOverlayTemplateId,
        updateOverlaysTemplate,
        createOverlaysTemplate,
    } = useOverlaysTemplateContext();

    const createOptionsFromArray = () => 
    {
        if (overlayTemplatesGroupNames.length)
        {
            return overlayTemplatesGroupNames.map((groupName) => ({ key: groupName, text: groupName, value: groupName }));
        }
        else 
        {
            return [];
        }
    };

    const [groupOptions, setGroupOptions] = useState(createOptionsFromArray);

    const contextRef = useRef();

    const [error, setError] = useState({ errorMessage: "", validationError: [] });

    const [state, setState] = useSetState({
        mapSharingMode: undefined,
        currentZoom: 15,
        drawnShape: undefined,
        previewText: "",
    });

    const initiateTemplateHandler = () => 
    {
        if (overlayTemplateMode==OVERLAYS_TEMPLATE_MODES.CREATE)
        {
            return {
                ...NEW_OVERLAY_TEMPLATE_DEFAULT,
            };
        }
        else 
        {
            const selectedOverlayTemplate = overlayTemplates.find((template) => template._id == selectedOverlayTemplateId);
            return cloneDeep(selectedOverlayTemplate);
        }
    };

    const { mapRef, olMap, mapController } = useOverlayTemplateMap({
        currentZoom: state.currentZoom,
        setCurrentZoom: (currentZoom) => setState({ currentZoom })
    });

    const [overlayTemplate, setOverlayTemplate] = useSetState(initiateTemplateHandler);

    const { updateLayer, updateStyle } = useTemplatePreviewShapeHandler({ olMap });

    // set the initial mapSharing mode if the selected template has dynamic zoom styling enabled
    useEffect(() =>
    {

        if (overlayTemplate?.dynamicOverlaySettings?.enabled 
            && overlayTemplate.dynamicOverlaySettings.zoomLevels?.length)
        {
            setState({
                mapSharingMode: overlayTemplate.dynamicOverlaySettings.zoomLevels[0].id
            });
        }
        else
        {
            setState({
                mapSharingMode: undefined
            });
        }
    }, [overlayTemplate?._id]);

    useEffect(() =>
    {
        // draw a new layer if shape is updated
        const { drawnShape, mapSharingMode, previewText } = state;
        updateLayer(drawnShape, overlayTemplate, mapSharingMode, previewText);
    }, [state.drawnShape]);

    useEffect(() =>
    {
        // update the style of the existing layer if only the overlayTemplate data or mapSharingMode is updated
        const { drawnShape, previewText, mapSharingMode } = state;
        updateStyle(drawnShape, overlayTemplate, mapSharingMode, previewText);
    }, [overlayTemplate, state.mapSharingMode, state.previewText]);

    const updateMapSharingMode = (newMode) =>
    {
        setState({
            mapSharingMode: newMode
        });

        // clear error messages
        setError({
            validationError: []
        });
    };

    const updateActiveOverlayTemplate = (overlayTemplate) =>
    {
        // update template state
        setOverlayTemplate(overlayTemplate);

        // clear error messages
        setError({
            validationError: []
        });
    }; 

    const updateOverlayTemplate = (key, value) =>
    {
        const updatedOverlayTemplate = { ...overlayTemplate };
        updatedOverlayTemplate[key] = value;
        updateActiveOverlayTemplate(updatedOverlayTemplate);
    };

    const updateEnableDynamicOverlayFlag = (checked) =>
    {
        let updatedDynamicOverlaySettings = { ...overlayTemplate.dynamicOverlaySettings };
        updatedDynamicOverlaySettings.enabled = checked;
        validateAndUpdateDynamicOverlaySettings(updatedDynamicOverlaySettings);
    };

    const validateAndUpdateDynamicOverlaySettings = (dynamicOverlaySettings) =>
    {
        let updatedDynamicOverlaySettings = { ...dynamicOverlaySettings };
        let updatedNewOverlayTemplate = { ...overlayTemplate };
        const { currentZoom } = state;
        const { dynamicOverlaySettings: currentDynamicOverlaySettings } = overlayTemplate;

        // If checkbox is unticked copy startZoom settings to default
        if (!updatedDynamicOverlaySettings.enabled && currentDynamicOverlaySettings.enabled)
        {
            const startZoom = updatedDynamicOverlaySettings.zoomLevels[0];
            updatedNewOverlayTemplate.defaultFillOpacity = startZoom.fillOpacity;
            updatedNewOverlayTemplate.defaultBorderFillOpacity = startZoom.borderFillOpacity;
            updatedNewOverlayTemplate.defaultTextOpacity = startZoom.textOpacity;

            updatedDynamicOverlaySettings = cloneDeep(OVERLAY_TEMPLATE_DEFAULT_DYNAMIC_SETTINGS);
        }
        // If checkbox is ticked for the first time copy default settings to startZoom
        else if (updatedDynamicOverlaySettings.enabled && !currentDynamicOverlaySettings.enabled)
        {
            const zoomLevels = [
                { ...cloneDeep(OVERLAY_TEMPLATE_DEFAULT_ZOOM_LEVEL_SETTINGS), id: uuid() },
                { ...cloneDeep(OVERLAY_TEMPLATE_DEFAULT_ZOOM_LEVEL_SETTINGS), id: uuid() }
            ];

            const startZoom = {
                value: +currentZoom,
                fillOpacity: overlayTemplate.defaultFillOpacity,
                borderFillOpacity: overlayTemplate.defaultBorderFillOpacity,
                textOpacity: overlayTemplate.defaultTextOpacity
            };

            zoomLevels[0] = { ...zoomLevels[0], ...startZoom };

            updatedDynamicOverlaySettings.zoomLevels = zoomLevels;

            updateMapSharingMode(zoomLevels[0].id);
        }

        updatedNewOverlayTemplate.dynamicOverlaySettings = updatedDynamicOverlaySettings;

        updateActiveOverlayTemplate(updatedNewOverlayTemplate);
    };

    const updateDynamicOverlaySettings = (zoomId, key, value) =>
    {
        let updatedDynamicOverlaySettings = { ...overlayTemplate.dynamicOverlaySettings };
        const selectedZoomLevel = updatedDynamicOverlaySettings.zoomLevels.find((zoomLevel) => zoomLevel.id === zoomId);
        selectedZoomLevel[key] = value;
        validateAndUpdateDynamicOverlaySettings(updatedDynamicOverlaySettings);
    };

    const addNewZoomLevel = (index) =>
    {
        let updatedDynamicOverlaySettings = { ...overlayTemplate.dynamicOverlaySettings };
        const zoomLevels =  updatedDynamicOverlaySettings.zoomLevels;
        zoomLevels.splice(index, 0, { ...cloneDeep(OVERLAY_TEMPLATE_DEFAULT_ZOOM_LEVEL_SETTINGS), id: uuid() });
        validateAndUpdateDynamicOverlaySettings(updatedDynamicOverlaySettings);
    };

    const removeZoomLevel = (zoomId) =>
    {
        let updatedDynamicOverlaySettings = { ...overlayTemplate.dynamicOverlaySettings };
        const zoomLevels =  updatedDynamicOverlaySettings.zoomLevels;
        const zoomIndex = zoomLevels.findIndex((zoomLevel) => zoomLevel.id === zoomId);
        zoomLevels.splice(zoomIndex, 1);
        validateAndUpdateDynamicOverlaySettings(updatedDynamicOverlaySettings);
    };

    const submitHandler= () =>  
    {
        const validationErrors = validateCurrentOverlayTemplate(overlayTemplate, overlayTemplates, trans);

        if (validationErrors) 
        {
            setError({ errorMessage: "Validation Failed", validationError: validationErrors });

            /* 
                initiate scroll to bottom after timeout to make sure the error messages
                are already rendered and the scroll length is updated in time,
                else it will scroll just above the error message component
            */
            setTimeout(() =>
            {
                scrollBarRef.current.scrollToBottom();
            }, 10);
            
        }
        else 
        {
            if (overlayTemplateMode == OVERLAYS_TEMPLATE_MODES.EDIT)
            {
                updateOverlaysTemplate({ overlayTemplateId: overlayTemplate._id, overlayTemplate });
            }
            else 
            {
                createOverlaysTemplate(overlayTemplate);
            }
        }

    };

    const handleShapeDraw = (polygonGeometry) =>
    {
        if (polygonGeometry)
        {
            const shape = getShapeFromGeometry(polygonGeometry);
            setState({
                drawnShape: shape
            });
        }
    };

    const handleAddition = (e, { value }) => 
    {
        setGroupOptions((prev) => ([{ text: value, value, key: value },...prev]));
    };
    
    const templateNotChanged = isEqual(overlayTemplate, overlayTemplates.find((template) => template._id == selectedOverlayTemplateId));

    const modalHeadings = {
        CREATE: "OverlaysTemplateSideBar.Create_Template",
        VIEW: "OverlaysTemplateSideBar.View_Template",
        EDIT: "OverlaysTemplateSideBar.Edit_Template"
    };

    const sidebarHeadings = {
        EDIT: "OverlaysTemplateSideBar.Template_Edit",
        VIEW: "OverlaysTemplateSideBar.Template_Details",
        CREATE: "OverlaysTemplateSideBar.Template_Create"
    };

    const isTemplateBtnDisabled = () => 
        overlayTemplateMode !== OVERLAYS_TEMPLATE_MODES.VIEW && 
        (!overlayTemplate.name[DEFAULT_LANGUAGE_CODE]?.trim() || templateNotChanged);
    
    return (
        <Modal
            size={"large"}
            className="templateModal"
            heading={"Overlay Template"}
            open={overlayCreateModalToggle}
            closeIcon={(templateNotChanged || overlayTemplateMode == OVERLAYS_TEMPLATE_MODES.CREATE) ? <Button className="close" />  : <OverlayTemplateCancelConfirmationModal
                trigger={<Button className="close" /> }
                onCancelClick={() => 
                {
                    handleOverlayTemplateMode(null);
                }}
                isCreate={!overlayTemplate._id}
            /> }
            closeOnEscape={false}
            onClose={() => 
            {
                handleOverlayTemplateMode(null);
            }}
        >
            <ModalContent className="templateContent">
                <div className="templateDivider">
                    <div className="templateLeftSide">
                        <div className="templateMapHeader">
                            <h3 className="templateHeader">{trans(modalHeadings[overlayTemplateMode])}</h3>
                        </div>
                        { !!state.currentZoom && <ZoomLevelIndicator currentZoom={state.currentZoom}/> }
                        <div className="templateMap" ref={mapRef} />
                    </div>
                    
                    <div className="templateSidebar">
                        <Scrollbars
                            autoHeight
                            autoHeightMin="calc(100vh - 230px)"
                            autoHeightMax={"calc(100vh - 230px)"}
                            ref={scrollBarRef}
                        >
                            <div className="alignerSidebar">

                                <div className="sidebarSection">
                                    <TextGroup
                                        className="tgOverlayConfig tabHeading"
                                        heading={
                                            <p style={{ fontWeight: "500" }}>
                                                {trans(sidebarHeadings[overlayTemplateMode])}
                                                <span style={{ fontWeight: "400" }}>({trans("CreateMapOverlaysSideBar.Internal")})</span>
                                            </p>
                                        }
                                    />

                                    <TextGroup
                                        className="tgOverlayConfig templateLabels"
                                        title={trans("OverlaysTemplateCreationModal.Template_Name")}
                                    >
                                        <Input
                                            autoFocus={true}
                                            value={overlayTemplate.name[DEFAULT_LANGUAGE_CODE] || ""}
                                            placeholder={trans("CreateMapOverlaysSideBar.Template_Name_Placeholder")}
                                            onChange={(event, { value }) => 
                                            {
                                                updateOverlayTemplate("name", {
                                                    ...overlayTemplate.name,
                                                    [DEFAULT_LANGUAGE_CODE]: value,
                                                });
                                            } }
                                            disabled={overlayTemplateMode == OVERLAYS_TEMPLATE_MODES.VIEW}
                                        />
                                    </TextGroup>

                                    <TextGroup
                                        className="tgOverlayConfig templateLabels"
                                        title={<>{trans("OverlaysTemplateCreationModal.Group_Name")} <span>({trans("OverlaysTemplateCreationModal.Optional")})</span></>}
                                    >
                                        <Dropdown
                                            className="overlayTemplate-groupName"
                                            options={groupOptions}
                                            placeholder={trans("CreateMapOverlaysSideBar.Group_Name_Placeholder")}
                                            selectOnBlur={false}
                                            search
                                            selection
                                            fluid
                                            clearable
                                            additionLabel={trans("CreateMapOverlaysSideBar.Add")}
                                            allowAdditions
                                            noResultsMessage={trans("CreateMapOverlaysSideBar.No_Groups_Found")}
                                            value={overlayTemplate?.group}
                                            onAddItem={handleAddition}
                                            onChange={(event, { value }) => 
                                            {
                                                updateOverlayTemplate("group", value);
                                                setGroupOptions(createOptionsFromArray());
                                            } }
                                            disabled={overlayTemplateMode == OVERLAYS_TEMPLATE_MODES.VIEW}
                                        />
                                    </TextGroup>

                                    {overlayTemplate.dynamicOverlaySettings.enabled && (
                                        <>
                                            <div
                                                className="overlayTgRow colorPickerRow"
                                                style={{ justifyContent: "flex-start", flexWrap: "wrap" }}
                                            >
                                                <div className="overlayTgColumn">
                                                    <TextGroup
                                                        flexible
                                                        className="tgKiosk mapOverlayColorPickerLaber"
                                                        title={trans("CreateMapOverlaysSideBar.MapOverlay_Fill_Color")}
                                                    >
                                                        <Input
                                                            className="colorPicker"
                                                            type="color"
                                                            value={checkAndConvertToHex(overlayTemplate.color)}
                                                            onChange={(event, { value }) => updateOverlayTemplate("color", value)
                                                            }
                                                            disabled={overlayTemplateMode == OVERLAYS_TEMPLATE_MODES.VIEW}
                                                        />
                                                    </TextGroup>
                                                </div>
                                                <div
                                                    className="overlayTgColumn"                                  
                                                >
                                                    <TextGroup
                                                        flexible
                                                        className="tgKiosk mapOverlayColorPickerLaber"
                                                        title={trans("CreateMapOverlaysSideBar.MapOverlay_Border_Color")}
                                                    >
                                                        <Input
                                                            className="colorPicker"
                                                            type="color"
                                                            value={checkAndConvertToHex(overlayTemplate.lineColor)}
                                                            onChange={(event, { value }) => updateOverlayTemplate("lineColor", value)
                                                            }
                                                            disabled={overlayTemplateMode == OVERLAYS_TEMPLATE_MODES.VIEW}
                                                        />
                                                    </TextGroup>
                                                </div>
                                                <div className="overlayTgColumn">
                                                    <TextGroup
                                                        flexible
                                                        className="tgKiosk mapOverlayColorPickerLaber"
                                                        title={trans("CreateMapOverlaysSideBar.MapOverlay_Text_Color")}
                                                    >
                                                        <Input
                                                            className="colorPicker"
                                                            type="color"
                                                            value={checkAndConvertToHex(overlayTemplate.textColor)}
                                                            onChange={(event, { value }) => updateOverlayTemplate("textColor", value)
                                                            }
                                                            disabled={overlayTemplateMode == OVERLAYS_TEMPLATE_MODES.VIEW}
                                                        />
                                                    </TextGroup>
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    {!overlayTemplate.dynamicOverlaySettings.enabled && (
                                        <DefaultOpacitySettings
                                            color={overlayTemplate.color}
                                            lineColor={overlayTemplate.lineColor}
                                            fillOpacity={overlayTemplate.defaultFillOpacity}
                                            borderFillOpacity={overlayTemplate.defaultBorderFillOpacity}
                                            textColor={overlayTemplate.textColor}
                                            textOpactiy={overlayTemplate.defaultTextOpacity}
                                            updateOverlay={updateOverlayTemplate}
                                            updateOpacitySettings={(fieldName, opacity) => updateOverlayTemplate(prependDefault(fieldName), opacity)}
                                            displayLang={DEFAULT_LANGUAGE_CODE}
                                            disabled={overlayTemplateMode == OVERLAYS_TEMPLATE_MODES.VIEW}
                                        />
                                    )}
                                </div>

                                

                                <div className="sidebarSection">
                                    <CheckboxGroup
                                        className="dynamicSelectBox"
                                        label={trans("CreateMapOverlaysSideBar.Dynamic_Zoom_Styling")}
                                        checked={overlayTemplate.dynamicOverlaySettings.enabled}
                                        onChange={(event, { checked }) => updateEnableDynamicOverlayFlag(checked)
                                        }
                                        disabled={overlayTemplateMode == OVERLAYS_TEMPLATE_MODES.VIEW}
                                    />

                                    {overlayTemplate.dynamicOverlaySettings.enabled && (
                                        <>
                                            <TextGroup className="tgOverlayConfig">
                                                <p className="mapOverlaySidebarZoomSettingInfo">
                                                    {trans("CreateMapOverlaysSideBar.Opacity_Slider_Instructions")}
                                                </p>
                                            </TextGroup>

                                            <ZoomLevelSettings
                                                zoomId={overlayTemplate.dynamicOverlaySettings.zoomLevels[0].id}
                                                color={overlayTemplate.color}
                                                lineColor={overlayTemplate.lineColor}
                                                textColor={overlayTemplate.textColor}
                                                zoomLevelSettingsValue={overlayTemplate.dynamicOverlaySettings.zoomLevels[0]}
                                                updateZoomLevelSettingsValue={updateDynamicOverlaySettings}
                                                title={trans("CreateMapOverlaysSideBar.Start_Zoom_Level")}
                                                onMapShareModeChange={updateMapSharingMode}
                                                mapSharingMode={state.mapSharingMode}
                                                displayLang={DEFAULT_LANGUAGE_CODE}
                                                enableOverrideText={false}
                                                disabled={overlayTemplateMode == OVERLAYS_TEMPLATE_MODES.VIEW}
                                            />

                                            <DividerLine gap="-10px 0px 16px" />

                                            <ButtonIcon
                                                className="addLevelBtn"
                                                icon="plus-filled"
                                                active={true}
                                                disabled={overlayTemplateMode == OVERLAYS_TEMPLATE_MODES.VIEW}
                                                content={trans("CreateMapOverlaysSideBar.Add_Layer")}
                                                onClick={() => addNewZoomLevel(1)}
                                            />

                                            {overlayTemplate.dynamicOverlaySettings.zoomLevels
                                                .filter((zoomLevel, index) => index !== 0 && index !== overlayTemplate.dynamicOverlaySettings.zoomLevels.length - 1)
                                                .map((zoomLevel, index) => (
                                                    <>
                                                        <DividerLine gap="19px 0px 16px" />

                                                        <ZoomLevelSettings
                                                            key={zoomLevel.id}
                                                            zoomId={zoomLevel.id}
                                                            color={overlayTemplate.color}
                                                            lineColor={overlayTemplate.lineColor}
                                                            textColor={overlayTemplate.textColor}
                                                            zoomLevelSettingsValue={zoomLevel}
                                                            updateZoomLevelSettingsValue={
                                                                updateDynamicOverlaySettings
                                                            }
                                                            title={`${index + 1}. ${trans("CreateMapOverlaysSideBar.Zoom_Level")}`}
                                                            onMapShareModeChange={updateMapSharingMode}
                                                            mapSharingMode={state.mapSharingMode}
                                                            onRemove={() => removeZoomLevel(zoomLevel.id)}
                                                            displayLang={DEFAULT_LANGUAGE_CODE}
                                                            enableOverrideText={false}
                                                            disabled={overlayTemplateMode == OVERLAYS_TEMPLATE_MODES.VIEW}
                                                        />

                                                        <DividerLine gap="13px 0px 16px" />

                                                        <ButtonIcon
                                                            className="addLevelBtn"
                                                            icon="plus-filled"
                                                            active={true}
                                                            disabled={overlayTemplateMode == OVERLAYS_TEMPLATE_MODES.VIEW}
                                                            content={trans("CreateMapOverlaysSideBar.Add_Layer")}
                                                            onClick={() => addNewZoomLevel(index + 2)}
                                                        />
                                                    </>
                                                ))}

                                            <DividerLine gap="13px 0px 20px" />

                                            <ZoomLevelSettings
                                                zoomId={
                                                    overlayTemplate.dynamicOverlaySettings.zoomLevels[
                                                        overlayTemplate.dynamicOverlaySettings.zoomLevels.length - 1
                                                    ].id
                                                }
                                                color={overlayTemplate.color}
                                                lineColor={overlayTemplate.lineColor}
                                                textColor={overlayTemplate.textColor}
                                                zoomLevelSettingsValue={overlayTemplate.dynamicOverlaySettings.zoomLevels[overlayTemplate.dynamicOverlaySettings.zoomLevels.length - 1]}
                                                updateZoomLevelSettingsValue={updateDynamicOverlaySettings}
                                                title={trans("CreateMapOverlaysSideBar.End_Zoom_Level")}
                                                onMapShareModeChange={updateMapSharingMode}
                                                mapSharingMode={state.mapSharingMode}
                                                displayLang={DEFAULT_LANGUAGE_CODE}
                                                enableOverrideText={false}
                                                disabled={overlayTemplateMode == OVERLAYS_TEMPLATE_MODES.VIEW}
                                            />
                                        </>
                                    )}
                                </div>
                                {!!error.validationError?.length && (
                                    <div className="sidebarSection">
                                        <ErrorLabel multiple={Object.keys(error.validationError)?.length > 0}>
                                            {trans("CreateMapOverlaysSideBar.Validation_Error_Header")}
                                            <ul>
                                                {error.validationError?.map((validationError) => (
                                                    <li key={validationError}>{validationError}</li>
                                                ))}
                                            </ul>
                                        </ErrorLabel>
                                    </div>
                                )}
                            </div>
                        </Scrollbars>
                    </div>
                </div>
            </ModalContent>

            <ModalActions>
                <div className="templateActions">
                    <div className="previewOptions">
                        <TextGroup
                            className="tgOverlayConfig templateLabels previewInput"
                            title={trans("CreateMapOverlaysSideBar.Preview_Options")}
                        >
                            <Input
                                value={state.previewText}
                                placeholder={trans("CreateMapOverlaysSideBar.Preview_Text")}
                                onChange={(event, { value }) => 
                                {
                                    setState({
                                        previewText: value
                                    });
                                } }
                            />
                            <MapOverlaysDrawingComponent
                                olMap={olMap}
                                mapController={mapController}
                                onSelect={handleShapeDraw}
                                className={overlayTemplate.dynamicOverlaySettings.enabled ? "dynamicPosition" : ""}
                            />
                        </TextGroup>
                    </div>
                    <div className="actionButtonsContainer">
                        {
                            (templateNotChanged || overlayTemplateMode == OVERLAYS_TEMPLATE_MODES.CREATE) ? <Button basic color="grey" content={trans("CreateMapOverlaysSideBar.Cancel")} onClick={() => 
                            {
                                handleOverlayTemplateMode(null);
                            }}/> : <OverlayTemplateCancelConfirmationModal
                                trigger={<Button basic color="grey" content={trans("CreateMapOverlaysSideBar.Cancel")}/>}
                                onCancelClick={() => 
                                {
                                    handleOverlayTemplateMode(null);
                                }}
                                isCreate={!overlayTemplate._id}
                            />  
                        }
                        <div ref={contextRef}>
                            <Button
                                color="orange"
                                content={overlayTemplateMode == OVERLAYS_TEMPLATE_MODES.VIEW ? trans("OverlaysTemplateSideBar.Edit"): trans("OverlaysTemplateSideBar.Save")}
                                disabled={isTemplateBtnDisabled()}
                                onClick={() => 
                                {
                                    if (overlayTemplateMode == OVERLAYS_TEMPLATE_MODES.VIEW) handleOverlayTemplateMode(OVERLAYS_TEMPLATE_MODES.EDIT);
                                    else submitHandler();
                                }}
                            />
                        </div>
                    </div>
                   
                    <MapOverlayCreationError
                        position="top center"
                        open={!!error.errorMessage}
                        contextRef={contextRef}
                        message={"Validation Failed"}
                        onClick={() => setError((prev) => ({ ...prev, errorMessage: "" }))}
                        onTimeout={() => setError((prev) => ({ ...prev, errorMessage: "" }))}

                    />
                </div>
          
            </ModalActions>
        </Modal>
    );
};

export default OverlaysTemplateCreationModal;

// custom hook to handle map rendering in modal popup
const useOverlayTemplateMap = ({
    currentZoom,
    setCurrentZoom,
}) =>
{
    const brandingCtx = useContext(BrandingContext);

    const rotationAngle = useMemo(() => 
    {
        if (brandingCtx.state.buildingId)
        {
            const buildingMapRotation = brandingCtx.state.settingsConfig?.buildingSettings?.[brandingCtx.state.buildingId]?.mapSettings?.mapRotation?.[DEFAULT_LANGUAGE_CODE];

            if (buildingMapRotation || buildingMapRotation === 0)
            {
                return buildingMapRotation;
            }
        }
        return brandingCtx.state.settingsConfig?.mapRotation?.[DEFAULT_LANGUAGE_CODE] || 0;
    }, [brandingCtx.state.settingsConfig, brandingCtx.state.buildingId]);

    const tileLayer = useMemo(() =>
    {
        if (brandingCtx.state?.mapData?.tileLayer)
        {
            return brandingCtx.state.mapData.tileLayer;
        }
        else
        {
            return styleClassic.tileLayer;
        }
    }, [brandingCtx.state.mapData?.tileLayer]);

    const { olMap, mapRef, mapController } = useMap({
        mapData: brandingCtx.state.mapData,
        options: { 
            mapOptions: {
                controlsOptions: { rotate: false },
                rotation: rotationAngle,
                tileLayer
            },
            onMapMoveEvent: (event, olMap) => 
            {
                if (olMap)
                {
                    const newZoomLevel = olMap.getView().getZoom().toFixed(2);
        
                    if (currentZoom !== newZoomLevel)
                    {
                        setCurrentZoom(newZoomLevel);
                    }
                }
            } 
        },
    });

    return {
        mapRef,
        olMap,
        mapController,
    };
};

// custom to handle shapes drawn on map for preview
const useTemplatePreviewShapeHandler = ({
    olMap,
}) =>
{
    const mapLayer = useRef(undefined);

    const updateLayer = (shape, overlayTemplate, mapSharingMode, text = "") =>
    {
        if (olMap)
        {
            // remove previously created layer
            if (mapLayer.current)
            {
                olMap.removeLayer(mapLayer.current);
            }

            // create new layer and apply it on map
            const vectorLayer = createNewLayer(shape, overlayTemplate, mapSharingMode, text);

            if (vectorLayer)
            {
                olMap.addLayer(vectorLayer);
                mapLayer.current = vectorLayer;
            }
        }
    };

    const createNewLayer = (shape, overlayTemplate, mapSharingMode, text = "") =>
    {
        const style = getStyleObject(overlayTemplate, mapSharingMode);

        if (style && shape)
        {
            const geometry = createEntityGeometry(shape);

            const feature = new Feature({
                geometry,
            });

            let features = [feature];

            if (text)
            {
                const textFeature = createTextFeature(shape, overlayTemplate, mapSharingMode, text);
                features.push(textFeature);
            }

            const vectorSource = new VectorSource({
                features
            });
        
            const vectorLayer = new VectorLayer({
                source: vectorSource,
                isOverlayTemplate: true,
            });
        
            vectorLayer.setStyle(style);

            return vectorLayer;
        }
    };

    const updateStyle = (shape, overlayTemplate, mapSharingMode, text = "") =>
    {
        if (mapLayer.current)
        {
            const style = getStyleObject(overlayTemplate, mapSharingMode);
            mapLayer.current.setStyle(style);

            updateTextFeature(shape, overlayTemplate, mapSharingMode, text);
        }
    };

    const getStyleObject = (overlayTemplate, mapSharingMode) =>
    {
        if (overlayTemplate)
        {
            return createActiveMapOverlayStyle(overlayTemplate, mapSharingMode);
        }
    };

    const updateTextFeature = (shape, overlayTemplate, mapSharingMode, text = "") =>
    {
        if (mapLayer.current)
        {
            // check if text feature is already created
            const textFeature = mapLayer.current?.getSource()?.getFeatureById(TEMPLATE_TEXT_FEATURE_ID);
            if (textFeature)
            {
                // update text in the existing text feature object
                if (text)
                {
                    const textFeatureStyle = getTextStyleFunction(overlayTemplate, mapSharingMode, text);
                    textFeature.setStyle(textFeatureStyle);
                }
                // if text is empty, remove the existing text feature
                else
                {
                    mapLayer.current.getSource()?.removeFeature(textFeature);
                }
            }
            /* if no text feature was created before and a new preview text is added,
               create a new text feature object and add it to layer 
            */
            else if (!textFeature && text)
            {
                const textFeature = createTextFeature(shape, overlayTemplate, mapSharingMode, text);
                mapLayer.current.getSource()?.addFeature(textFeature);
            }
        }
        
    };

    const createTextFeature = (shape, overlayTemplate, mapSharingMode, text) =>
    {
        // create text feature style
        const textStyleFunction = getTextStyleFunction(overlayTemplate, mapSharingMode, text);

        // create text feature
        const geometry = createEntityGeometry(shape);
        const textCoordinates = getCenter(geometry.getExtent());
        const textGeometry = new Point(textCoordinates);
        const textFeature = new Feature({
            geometry: textGeometry,
        });
   
        textFeature.setStyle(textStyleFunction);
        textFeature.setId(TEMPLATE_TEXT_FEATURE_ID);
    
        return textFeature;
    };

    const getTextStyleFunction = (overlayTemplate, mapSharingMode, text = "") =>
    {
        const { dynamicOverlaySettings, defaultTextOpacity, textColor } = overlayTemplate;
        const view = new View();

        return (feature, resolution) =>
        {
            const currentZoomLevel = view.getZoomForResolution(resolution).toFixed(2);
 
            const textStyleOptions = createStyle({
                stroke: "rgba(0, 0, 0, 0)",
                fill: "rgba(0, 0, 0, 0)",
            });
 
            let textOpacity;
            let displayTextLabel = text; 
 
            // if map is too zoomed out, hide text from map. This is the default behavior for all text on map
            if (currentZoomLevel < 14)
            {
                textOpacity = 0;
            }
            // if dynamic settings are enabled in template, use the appropriate zoomlevel's text opacity
            // else use the default global text opacity of template (defaultTextOpacity)
            else if (dynamicOverlaySettings.enabled)
            {
                const { zoomLevels } = dynamicOverlaySettings;

                const zoomLevelSettings = zoomLevels.find((zoomLevel) => zoomLevel.id === mapSharingMode);

                if (zoomLevelSettings)
                {
                    textOpacity = zoomLevelSettings.textOpacity;
                }
            }
            else
            {
                textOpacity = defaultTextOpacity;
            }
 
            let textFeatureStyle = new Style(textStyleOptions);
            const textFontOptions = getTextFontOptions(textOpacity, undefined, textColor);
            textFeatureStyle.setText(createTextStyle(displayTextLabel, 0, textFontOptions));
     
            return textFeatureStyle;
        };
    };

    return {
        updateLayer,
        updateStyle,
    };
};

const ZoomLevelIndicator = ({
    currentZoom
}) => 
{
    const trans = useTranslation().t;
    return  (
        <div className="overlayTemplateZoomLevelIndicator">
            {`${trans("MapstedMapsModule.Zoom_Level")}: ${currentZoom}`}
        </div>
    );
};

const OverlayTemplateCancelConfirmationModal = ({ trigger, onCancelClick, isCreate=true }) => 
{
    const trans = useTranslation().t;

    return (
        <ModalBox className="confirmActionModal"
            trigger={trigger}
            header={trans("Cancel_Confirmation_Modal.Confirm_Action")}
            actions={<Button color="orange" floated="right" content={trans("Cancel_Confirmation_Modal.Cancel")} onClick={onCancelClick} />}>
            <p className="p-modal">
                { isCreate ? trans("Cancel_Confirmation_Modal.Overlay_Template_Create_Cancel") : trans("Cancel_Confirmation_Modal.Overlay_Template_Update_Cancel") }
            </p>
        </ModalBox>
    );
};