import React from "react";
import "./InputGroupWrap.css";

export const InputGroupWrap = ({
    className = "",
    gap = "",
    label = false,
    children = undefined 
}) => (
    <div className={`inputGroupWrap ${className} ${gap}`}>
        {(label) && (<p>{label}</p>)}
        {children}
    </div>
);
