import React from "react";
import { Segment, Header, Button } from "semantic-ui-react";

export const BuildingBoxDetail = ({ heading, subHeading, shortHeading, langCode, trigger, children, profile, onClick }) => (
    <Segment basic className={`building-segment-cover${profile ? " profile" : ""}`}>
        {
            (heading || subHeading) && (
                <Header as={subHeading ? "h1" : "h3"}>
                    {subHeading || heading}
                    <Button className="edit-icon" onClick={onClick}>
                        <img src={`/img/icon-${trigger}.svg`} alt="" />
                    </Button>
                </Header>
            )
        }
        {shortHeading && <b className="short-heading">{shortHeading?.[langCode]? shortHeading?.[langCode] : shortHeading}</b>}
        {children}
    </Segment>
);