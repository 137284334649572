import React from "react";
import "./ImageCopyDetails.css";
import { useImageCopyContext } from "../../contexts/ImageCopyContext";
import { useImagesBaseContext } from "../../contexts/ImageBaseContext";
import { AspectRatioSection, CropImageSection, ImageNameEditSection, LinkedEntitiesSection } from "../../ImageUploads/ImageDetailSections/ImageDetailsSections";
import ContentSection from "../../ImageUploads/ContentSection/ContentSection";
import InfoSection from "../../InfoSection/InfoSection";
import { IMAGE_ASPECT_RATIOS_TO_LABEL } from "../../../constant";
import { bytesToMB } from "../../../utils";
import moment from "moment";
import { GmsActionMode, useGmsContext } from "../../../GmsContext";

function ImageCopyDetails() 
{
    const { gmsActionMode } = useGmsContext();
    const imageCopyContext = useImageCopyContext();
    const {
        croppedImagesHistory,
        imageData,
        handleChangeImageData,
        handleUndoCropImage,
        handleRedoCropImage,
        cropperRef,
        handleCropImage,
    } = imageCopyContext;
    const { selectedImageInfo } = useImagesBaseContext();
    const croppedImageHistory = croppedImagesHistory?.[selectedImageInfo?._id];

    function handleTriggerCropImage() 
    {
        const croppedDataUrl = cropperRef.current.cropper
            .getCroppedCanvas()
            .toDataURL();
        handleCropImage({ id: selectedImageInfo?._id, croppedImage: croppedDataUrl, currentImageSrc: imageData.dataUrl });
    }

    return <div className="image-edit-details">
        <CropImageSection
            onUndo={croppedImageHistory?.prevImages?.length ? () => handleUndoCropImage(selectedImageInfo?._id) : null}
            onRedo={croppedImageHistory?.futureImages?.length ? () => handleRedoCropImage(selectedImageInfo?._id) : null}
            onCrop={handleTriggerCropImage}
        />
        <ImageNameEditSection
            value={imageData.name}
            onChange={(e) => handleChangeImageData({ name: e.target.value })}
        />
        <AspectRatioSection
            disabled={gmsActionMode === GmsActionMode.LINK_COVER_IMAGES 
              || gmsActionMode === GmsActionMode.LINK_ICON_IMAGE}
            value={imageData.aspectRatio}
            onChange={(value) => 
            {
                handleChangeImageData(
                    { aspectRatio: value },
                );
                cropperRef?.current?.cropper?.setAspectRatio(value);
            }}
        />
        <LinkedEntitiesSection></LinkedEntitiesSection>
        <FileInfoSection fileInfo={selectedImageInfo} />
    </div>;
}

function FileInfoSection({ fileInfo }) 
{
    return (
        <ContentSection className="image-details-file-info-section">
            <ContentSection.Header>File Info</ContentSection.Header>
            <ContentSection.Main>
                <div className="info-section-bx">
                    <InfoSection>
                        <InfoSection.Label>Image Name</InfoSection.Label>
                        <InfoSection.Value>{fileInfo.name}</InfoSection.Value>
                    </InfoSection>
                    <InfoSection>
                        <InfoSection.Label>Uploaded file&apos;s name</InfoSection.Label>
                        <InfoSection.Value>{fileInfo.fileName}</InfoSection.Value>
                    </InfoSection>
                </div>
                <div className="info-section-bx">
                    <InfoSection>
                        <InfoSection.Label>Image Ratio</InfoSection.Label>
                        <InfoSection.Value>{IMAGE_ASPECT_RATIOS_TO_LABEL[fileInfo.aspectRatio]}</InfoSection.Value>
                    </InfoSection>
                    <InfoSection>
                        <InfoSection.Label>Size</InfoSection.Label>
                        <InfoSection.Value>{bytesToMB(fileInfo.size)} MB</InfoSection.Value>
                    </InfoSection>
                </div>
                <div className="info-section-bx"> 
                    <InfoSection>
                        <InfoSection.Label>Image Type</InfoSection.Label>
                        <InfoSection.Value>{fileInfo.mimeType}</InfoSection.Value>
                    </InfoSection>
                    <InfoSection>
                        <InfoSection.Label>Uploaded</InfoSection.Label>
                        <InfoSection.Value>{moment(fileInfo.createdAt).format("MMM D, YYYY")}</InfoSection.Value>
                    </InfoSection>
                </div>
            </ContentSection.Main>
        </ContentSection>
    );
}

export default ImageCopyDetails;