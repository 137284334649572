import React, { useContext, useMemo } from "react";
import { Button } from "semantic-ui-react";
import BrandingContext from "../../../../store/BrandingContext";
import { DYNAMIC_MAP_LAYERS_MODES, SINGLE_LANG_INPUT_CODE } from "../../../../_constants/constants";
import { DynamicMapLayersContext } from "../../../../store/DynamicMapLayersContext";

import "./MapButtons.css";

export const MapButtons = ({
    onZoomIn,
    onZoomOut,
    children = undefined,
    floorButtons = true,
    zoomButtons = true,
    style = undefined
}) =>
{

    const brandingContext = React.useContext(BrandingContext);

    return (
        <div className="buttons-wrap" style={style}>
            {(zoomButtons) && (
                <div className="buttons-group staysActive">
                    <MapButton content={<span className="zoomIn" />} onClick={onZoomIn} />
                    <MapButton content={<span className="zoomOut" />} onClick={onZoomOut} />
                </div>
            )}

            {(floorButtons && brandingContext.state.buildingId) && (
                <FloorButtons />
            )}
            {children}
        </div >
    );
};

const FloorButtons = () =>
{
    const brandingContext = useContext(BrandingContext);
    const dynamicMapLayerContext = useContext(DynamicMapLayersContext);

    const floors = useMemo(() =>
    {
        const { buildingId, propertyId, properties } = brandingContext.state;

        if (buildingId)
        {
            return properties[propertyId].buildings[buildingId].floors;
        }
        return [];
    }, [brandingContext.state]);

    const isCreateOrEditMode = dynamicMapLayerContext.state.mode === DYNAMIC_MAP_LAYERS_MODES.CREATE_DYNAMIC_MAP_LAYER 
        || dynamicMapLayerContext.state.mode === DYNAMIC_MAP_LAYERS_MODES.EDIT_DYNAMIC_MAP_LAYER
        || dynamicMapLayerContext.state.mode === DYNAMIC_MAP_LAYERS_MODES.MULTI_CREATION;

    return (
        <div className="buttons-group">
            {floors.map((floor) => (
                <MapButton
                    key={floor._id}
                    content={floor.shortName?.[SINGLE_LANG_INPUT_CODE]}
                    isActive={brandingContext.state.floorId === floor._id}
                    onClick={() => brandingContext.changeSelectedFloor(floor._id)}
                    disabled={isCreateOrEditMode && brandingContext.state.floorId !== floor._id}
                />
            ))}
        </div>
    );
};

export const MapButton = ({
    icon = undefined,
    content = undefined,
    isActive = false,
    onClick,
    disabled=false
}) => (
    <Button className={isActive ? "active" : ""} onClick={onClick} disabled={disabled}>
        {content}
        {(icon) && (<img src={`/img/icon-${icon}.svg`} alt="icon" />)}
    </Button>
);
