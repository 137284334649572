
exports.PointRelativeToLine = {
    Right: -1,
    On: 0,
    Left: 1
}

exports.PointRelativeToPolygon = {
    Inside: 1,
    Outside: 2,
    OnBoundary: 3
}