import React from "react";
import { Accordion, Image } from "semantic-ui-react";
import { ButtonBasic } from "../../common/ButtonIcon";
import { StoreBoxItemButton } from "./StoreBoxItem";

class StoreGroupsModalAccordion extends React.Component
{

    state = { activeIndex: 0 }

    handleClick = (e, titleProps) =>
    {
        const { index } = titleProps;
        const { activeIndex } = this.state;
        const newIndex = activeIndex === index ? -1 : index;

        this.setState({ activeIndex: newIndex });
    }

    render()
    {
        const { activeIndex } = this.state;

        return (
            <Accordion className="accordionStoreGroups">
                <AccordionSection active={activeIndex === 0} index={0} onClick={this.handleClick}/>
                <AccordionSection active={activeIndex === 1} index={1} onClick={this.handleClick}/>
                <AccordionSection active={activeIndex === 2} index={2} onClick={this.handleClick}/>
            </Accordion>
        );
    }
}

export default StoreGroupsModalAccordion;

const AccordionSection = ({ active, index, onClick }) => (
    <>
        <Accordion.Title active={active} index={index} onClick={onClick}>
            <div className="accordionTitleContent">
                <Image as="span" src="/img/icon-group.svg" />
                Group Name
            </div>
            <div className="accordionTitleAction">
                <span>4 stores available</span>
                <ButtonBasic icon="key" />
                <ButtonBasic icon="edit" />
                <ButtonBasic icon="trash-grey" />
            </div>
        </Accordion.Title>
        <Accordion.Content active={active}>
            <StoreBoxItemButton />
            <StoreBoxItemButton />
            <StoreBoxItemButton />
        </Accordion.Content>
    </>
);

