import { useContext } from "react";
import { getSelectedEntity } from "mapsted.maps/mapFunctions/features";

import BrandingContext from "../../../../store/BrandingContext";
import { DEFAULT_LANGUAGE_CODE } from "../../../../_constants/constants";
import { useMutation, useQuery, useQueryClient } from "react-query";
import brandingApi from "../../../../_api/branding.api";

export const BLANK_ITEM = {
    entityLabelId: "",
    code: "",
    name: { [DEFAULT_LANGUAGE_CODE]: "" },
    quantity: 0,
    link: { [DEFAULT_LANGUAGE_CODE]: "" }
};

export function useItemFunctions()
{
    const brandingContext = useContext(BrandingContext);

    const upsertItem = (entityLabel, index, item, callback = () => null) =>
    {
        item.updatedAt = new Date().toISOString();
        if (index === undefined)
        {
            entityLabel.inventory = [...entityLabel.inventory, item];
        }
        else
        {
            entityLabel.inventory[index] = item;
        }
        brandingContext.updateEntityLabel(entityLabel, () => callback());
    };

    const deleteItem = (entityLabel, index, callback = () => null ) =>
    {
        const newInventory = entityLabel?.inventory?.filter((item, i) => i !== index);
        entityLabel.inventory = newInventory;
        brandingContext.updateEntityLabel(entityLabel, () => callback());
    };

    return { upsertItem, deleteItem };
}

export function useSelectedEntity()
{
    const brandingContext = useContext(BrandingContext);
    const entities = brandingContext?.state?.mapData?.entities;
    const { selectedEntityId } = getSelectedEntity(brandingContext?.state?.selectedEntities || {});

    if (!selectedEntityId || !entities)
    {
        return null;
    }
    return entities[selectedEntityId];
}

export function useSelectedFloor()
{
    const brandingContext = useContext(BrandingContext);
    const { floorId, propertyId, buildingId, allProperties } = brandingContext?.state;
    const buildingObj = allProperties?.[propertyId]?.allBuildings?.[buildingId];

    return buildingObj?.floors?.find((floor) => floor?._id === floorId);
}

export function useEntityInvetories(entityLabelId)
{
    const inventoryData = useQuery(
        ["FIND_INVENTORIES", entityLabelId],
        () => brandingApi.findInventories({ entityLabelId }),
    );
    return inventoryData;
}

export function useDeleteInventory()
{
    const queryClient = useQueryClient();
    const deleteMutation = useMutation((entityLabelId) => brandingApi.deleteEntityLabelInventory(entityLabelId), {
        onSettled: () => queryClient.invalidateQueries(["FIND_INVENTORIES"])
    });
    return deleteMutation;
}
