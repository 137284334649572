import React from "react";
import "./BulkImagesList.css";
import ImageComponent from "../../../../ImageComponent/ImageComponent";
import { useImagesUploadContext } from "../../../contexts/ImagesUploadContext";

function BulkImagesList() 
{
    const { uploadedFilesInfo, croppedImagesHistory,handleSelectImageFromBulkUpload, selectedImageInfoFromBulkUpload,  } = useImagesUploadContext();

    return (
        <div className="bulk-images-list">
            {uploadedFilesInfo.map((uploadedFile) => 
            {
                const isCropped = croppedImagesHistory?.[uploadedFile.id]?.currentImage && croppedImagesHistory?.[uploadedFile.id]?.prevImages?.length;
                const selected = selectedImageInfoFromBulkUpload?.id === uploadedFile.id;

                return (
                    <div onClick={() => handleSelectImageFromBulkUpload(uploadedFile.id)} key={uploadedFile.id} className={"bulk-image-item"}>
                        <ImageComponent selected={selected} imgSrc={uploadedFile.imageSrc} alt={uploadedFile.name} />
                        <div className="bulk-image-item-checkbox-icon-bx">
                            <img className="bulk-image-item-checkbox-icon" src={`/img/${isCropped ? "icon-check-active.svg" : "icon-check.svg"}`} alt="" />
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default BulkImagesList;