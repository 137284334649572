import { useContext } from 'react';
import FloorPlanContext from "../FloorPlanContex";

const useFloorPlanUndoRedoConnector = () =>
{
    const { undoStepHandler, redoStepHandler } = useContext(FloorPlanContext);
    return {
        undoStepHandler,
        redoStepHandler
    };
};

export default useFloorPlanUndoRedoConnector;
