import { useEffect, useRef } from "react";
import { createPolygonVectorLayer } from "../_utils/mapUtils";

const POLYGON_HIGHLIGHT_LAYER_FLAG = "polygonHighlightLayerFlag";
const HIGHLIGHT_INTERVAL_TIME = 500;

const DEFAULT_STYLE_OBJECT = {
    fillColor: "#FFFFFF",
    fillOpacity: 0.5,
    strokeColor: "#fc0341",
    strokeOpacity: 1
};

const usePolygonHighlight = ({ 
    olMap, 
    polygonShape, 
    onHighlightComplete, 
    style=DEFAULT_STYLE_OBJECT, 
    layerKey=POLYGON_HIGHLIGHT_LAYER_FLAG 
}) =>
{

    const intervalRef = useRef(undefined);

    useEffect(() =>
    {
        if (olMap)
        {
            removeHighlightVectorLayer();
            if (polygonShape)
            {
                // create vector layer
                const layer = createPolygonVectorLayer({ 
                    polygonShape,
                    layerKey,
                    ...style
                });

                // add vector layer to olMap
                olMap.addLayer(layer);

                if (intervalRef.current)
                {
                    clearInterval(intervalRef.current);
                }

                // toggle layer visibility on interval
                let timesRun = 0;
                const interval = setInterval(() =>
                {
                    timesRun++;
                    if (timesRun >= 7)
                    {
                        clearInterval(interval);
                        intervalRef.current = undefined;
                        if (onHighlightComplete)
                        {
                            onHighlightComplete();
                        }
                    }
                    else
                    {
                        let visibility = layer.getVisible();
                        layer.setVisible(!visibility);
                    }
                    
                }, HIGHLIGHT_INTERVAL_TIME);

                intervalRef.current = interval;
            }
        }
    }, [olMap, polygonShape]);

    const removeHighlightVectorLayer = () =>
    {
        let highlighLayer = undefined;

        olMap.getLayers().forEach((layer) =>
        {
            if (layer.get(layerKey))
            {
                highlighLayer = layer;
            }
        });

        if (highlighLayer)
        {
            olMap.removeLayer(highlighLayer);
        }
    };
};

export default usePolygonHighlight;