import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Radio } from "semantic-ui-react";
import ContentSection from "../ContentSection/ContentSection";
import {
    IMAGE_ASPECT_RATIOS,
    IMAGE_ASPECT_RATIOS_TO_LABEL,
} from "../../../constant";
import LinkedEntities from "../../LinkedEntities/LinkedEntities";
import "./ImageDetailsSections.css";

function AspectRatioSection({ value, onChange, disabled }) 
{
    return (
        <ContentSection className="image-upload-details-aspect-ratio-section">
            <ContentSection.Header>Image Ratio</ContentSection.Header>
            <ContentSection.Main>
                <div className="image-upload-details-aspect-ratio-option">
                    <Radio
                        disabled={disabled}
                        className="gms-radio"
                        label={IMAGE_ASPECT_RATIOS_TO_LABEL[IMAGE_ASPECT_RATIOS.RATIO_1]}
                        checked={value === IMAGE_ASPECT_RATIOS.RATIO_1}
                        onChange={() => onChange(IMAGE_ASPECT_RATIOS.RATIO_1)}
                    ></Radio>
                </div>
                <div className="image-upload-details-aspect-ratio-option">
                    <Radio
                        disabled={disabled}
                        className="gms-radio"
                        label={IMAGE_ASPECT_RATIOS_TO_LABEL[IMAGE_ASPECT_RATIOS.RATIO_3]}
                        checked={value === IMAGE_ASPECT_RATIOS.RATIO_3}
                        onChange={() => onChange(IMAGE_ASPECT_RATIOS.RATIO_3)}
                    ></Radio>
                </div>
            </ContentSection.Main>
        </ContentSection>
    );
}

function ImageNameEditSection(props) 
{
    return (
        <ContentSection>
            <ContentSection.Header>Name</ContentSection.Header>
            <ContentSection.Main>
                <input
                    placeholder="Enter image name"
                    className="image-name-edit-input"
                    type="text"
                    {...props}
                />
            </ContentSection.Main>
        </ContentSection>
    );
}

function CropImageSection({ onCrop, onUndo, onRedo }) 
{
    const trans = useTranslation().t;
    return (
        <ContentSection className="image-upload-details-crop-image-section">
            <ContentSection.Header>Crop Image</ContentSection.Header>
            <ContentSection.Main>
                <Button onClick={onCrop} className="image-cropper-crop-btn">
                    <img
                        src="/img/icon-crop.svg"
                        className="btn-img"
                        alt={trans("CropImageModal.Crop_icon")}
                    />
                    <span className="image-cropper-btn-text">
                        {trans("CropImageModal.Crop_Image")}
                    </span>
                </Button>
                {onUndo && (
                    <Button onClick={onUndo} className="image-cropper-crop-btn">
                        <img
                            className="btn-img"
                            src="/img/icon-undo.svg"
                            alt={trans("CropImageModal.Crop_icon")}
                        />
                        <span className="image-cropper-btn-text">
                            {trans("CropImageModal.Undo")}
                        </span>
                    </Button>
                )}
                {onRedo && (
                    <Button onClick={onRedo} className="image-cropper-crop-btn">
                        <img
                            className="btn-img image-cropper-btn-redo"
                            src="/img/icon-undo.svg"
                            alt={trans("CropImageModal.Crop_icon")}
                        />
                        <span className="image-cropper-btn-text">
                            {trans("CropImageModal.Redo")}
                        </span>
                    </Button>
                )}
            </ContentSection.Main>
        </ContentSection>
    );
}



function LinkedEntitiesSection() 
{
    return <ContentSection className="image-details-file-info-section">
        <ContentSection.Header>Linked Entities</ContentSection.Header>
        <ContentSection.Main>
            <LinkedEntities />
        </ContentSection.Main>
    </ContentSection>;
}

export { AspectRatioSection, ImageNameEditSection, CropImageSection, LinkedEntitiesSection };
