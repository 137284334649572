import React from "react";
import { Button, Input } from "semantic-ui-react";
import { ButtonIcon } from "../common/ButtonIcon";
import { ModalLayout } from "../popups/ModalLayout";
import "./SyncStoreModal.css";
import "./SettingsModal.css";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import { useTranslation } from "react-i18next";

export const SyncStoreLastModal = ({ id, pin, modalProps, setLinkAndPinPopup }) => 
{
    const trans = useTranslation().t;
    
    return (
        <ModalLayout
            {...modalProps}
            className="syncStoreModal syncStoreModal2 syncStoreLastModal"
            heading={trans("SyncStoreLastModal.Sync_Stores")}
            actions={<Button primary color="orange" content={trans("SyncStoreLastModal.Done")} onClick={() => setLinkAndPinPopup({ open: false })} />}>
            <p className="p-modal">{trans("SyncStoreLastModal.Your_stores_have_been_shared_successfull")}</p>

            <div className="storeInput">
                <p>{trans("SyncStoreLastModal.Copy_link_")}</p>
                <Input value={id} />
                <CopyToClipboard text={id}>
                    <ButtonIcon />
                </CopyToClipboard>

                <p>{trans("SyncStoreLastModal.Copy_pin_")}</p>
                <Input value={pin} />
                <CopyToClipboard text={pin}>
                    <ButtonIcon />
                </CopyToClipboard>
            </div>

        </ModalLayout>
    );
};

export const ShareStoreGroupDeleteModal = ({ heading, groupsToBeDeleted, onDelete, onCancel, open, disableButtons = false }) =>
{
    const trans = useTranslation().t;

    const renderStoreListModal = React.useCallback(() => (
        <div className="aligner">
            <p className="p-modal">
                {trans("SyncStoreLastModal.Are_you_sure_you_want_to_delete", { count: groupsToBeDeleted?.length })}
                <br />
                {trans("SyncStoreLastModal.This_will_permanently_delete_all_selecte")}
            </p>
            <div className="relatedStores">
                <h4><span>{trans("SyncStoreLastModal.to_be_deleted", { count: groupsToBeDeleted?.length })}</span></h4>
                <ol>
                    {
                        groupsToBeDeleted?.map((group) => <li key={group?._id}>{group?.name}</li>)
                    }
                </ol>
            </div>
        </div>

    ), [groupsToBeDeleted]);

    return (
        <ModalLayout
            open={open}
            onClose={() => onCancel()}
            className={"crop-image-modal deleteGroupModal deleteCategoryModal"}
            heading={trans("SyncStoreLastModal.Delete_Store_Group", { count: groupsToBeDeleted?.length })}
            actions={(<>
                <Button floated="right" color="grey" content={trans("SyncStoreLastModal.Cancel")} onClick={() => onCancel()} disabled={disableButtons} />
                <Button color="orange" content={trans("SyncStoreLastModal.Delete_Groups")} onClick={() => onDelete()} disabled={disableButtons} />
            </>)}>
            {renderStoreListModal()}
        </ModalLayout>
    );
};