import React, { useContext, useEffect, useState } from "react";
import { Button, Checkbox, Flag, Popup } from "semantic-ui-react";
import { DEFAULT_LANGUAGE_CODE } from "../../_constants/constants";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../store/LanguageContext";
import "./LanguageSelector.css";

const LanguageSelector = ({
    activeLang,
    setActiveLang,
    defaultDisplayedLangs,
    allowAdditions = true,
    // added languages will be removed when refreshKey changes
    refreshKey = undefined
}) =>
{
    const languageCtx = useContext(LanguageContext);
    const { languages } = languageCtx;

    const trans = useTranslation().t;
    const [addedLangs, setAddedLangs] = useState(new Set());

    const addDisplayLng = (lng) => setAddedLangs((set) => new Set([...set, lng]));
    const removeDisplayLng = (lng) =>
    {
        if (activeLang === lng)
        {
            setActiveLang(DEFAULT_LANGUAGE_CODE);
        }
        setAddedLangs((set) =>
        {
            set.delete(lng);
            return new Set(set);
        });
    };

    useEffect(() =>
    {
        setAddedLangs(new Set());
        setActiveLang(DEFAULT_LANGUAGE_CODE);
    }, [refreshKey]);


    let displayed = allowAdditions ? Array.from(new Set([...defaultDisplayedLangs, ...addedLangs])) : defaultDisplayedLangs;
    return (
        <div className="previewLanguageGroup">
            <p>{trans("LanguageSelector.Choose_Language_for_Preview")}</p>
            <div className="previewLanguageContent">
                {displayed.map((l) => (
                    <Button
                        key={l}
                        content={l.toUpperCase()}
                        active={l === activeLang}
                        onClick={() => setActiveLang(l)}
                    />
                ))}
                <Popup
                    className="previewLanguagePopup"
                    trigger={<Button disabled={!allowAdditions} className="btnDropdownPopup" icon="angle down" />}
                    on="click"
                    hideOnScroll
                    position="top right"
                >
                    {Object.values(languages || {})
                        .map(({ title, value, icon }) => (
                            <CheckboxGroup
                                key={value}
                                checked={displayed.includes(value)}
                                name={title}
                                onChange={(checked) => checked ? addDisplayLng(value) : removeDisplayLng(value)}
                                onClick={() => setActiveLang(value)}
                                active={value === activeLang}
                                disabled={value === DEFAULT_LANGUAGE_CODE}
                            />
                        ))}
                </Popup>
            </div>
        </div>
    );
};

const CheckboxGroup = ({
    checked,
    name,
    disabled = false,
    onChange = (checked) => null,
    onClick,
    active,
}) => (
    <div style={{ display: "flex" }}>
        <Checkbox checked={checked} disabled={disabled} onChange={(e, { checked }) => onChange(checked)}/>
        <div onClick={onClick}>
            {(active) && <strong> {name}</strong>}
            {(!active) && <span> {name}</span>}
        </div>
    </div>
);

export default LanguageSelector;
