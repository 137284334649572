import React, { useContext, useLayoutEffect, useRef, useState } from "react";
import { CategoryContext } from "../../../../store/CategoryProvider";
import { DEFAULT_LANGUAGE_CODE, MAX_GROUPS_ALLOWED } from "../../../../_constants/constants";
import { useTranslation } from "react-i18next";
import "./RearrangeCategory.css";
import { Button, ButtonGroup, Icon, Image, Popup } from "semantic-ui-react";
import BrandingContext from "../../../../store/BrandingContext";
import {
    sortableContainer,
    sortableElement,
    sortableHandle,
} from "react-sortable-hoc";
import { arrayMove } from "mapsted.utils/arrays";
import { useMap, useRequest } from "ahooks";
import { ButtonIcon } from "../../../common/ButtonIcon";
import { StyledIcon } from "../../categoriesPortal/StyledIcon";
import Scrollbars from "react-custom-scrollbars-2";

const SortableContainer = sortableContainer(({ children }) => <div className="">{children}</div>);

const RearrangeCategory = ({ setRearrage, lang }) => 
{
    const trans = useTranslation().t;
    const newSortRef = useRef();
    const categoryContext = useContext(CategoryContext);
    const { state: { propertyId, properties } } = useContext(BrandingContext);
    const [openSort, setOpenSort] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const { categoryGroups, getIconInfo, getChildrenOf, getCategoriesFromIds, reorderCategories } = categoryContext;
    const categoryGroupIds = categoryGroups.map((group) => group._id);
    const parent = properties?.[propertyId];
    const today = new Date();
    /**
     * reorderedListsMap: A map of all lists in the tree that have been reordered so far.
     * - Map is of type {"id": {"order": {category}[], rootType: "category"}, ...}
     * - "order" property contains list of category objects in the new order.
     * - "id", the key of the map, can be one of two things:
     *      If the list that has been reordered is not a group, then key is a category id and rootType="category".
     *      Otherwise, key is the current property id and rootType="property".
     */
    const [reorderedListsMap, mapOps] = useMap();
    /**
     * expandedListsMap: A map storing whether each sublist in the tree is expanded.
     * - Ensures that sublists that the user has open for reordering remain expanded on reorder.
     * - Map is of type Key: id, Value: boolean
     */
    const [, expandedListsMapOps] = useMap([]);

    const reorderedObjList = reorderedListsMap.get(categoryContext.propertyId)?.order;
    const categoryObjList = reorderedObjList ? reorderedObjList : categoryContext.getCategoriesFromIds(categoryGroupIds);

    const sortCategories = (categories, option) => 
    {
        if (typeof categories[0] === "string") 
        {
            categories = categoryContext.getCategoriesFromIds(categories);
        }
        return categories.slice().sort((a, b) => 
        {
            const nameA = a.name?.[lang]?.toUpperCase();
            const nameB = b.name?.[lang]?.toUpperCase();

            if (option === "A-Z") 
            {
                return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
            }
            else 
            {
                return nameA > nameB ? -1 : nameA < nameB ? 1 : 0;
            }
        });
    };

    const handleSortChange = (value) => 
    {
        const newSortOption = value;

        // Sorting group
        const sortedGroups = sortCategories(categoryGroups, newSortOption);
        mapOps.set(propertyId, { order: sortedGroups, rootType: "property" });

        const catObj = categoryContext.getCategoriesFromIds(selectedGroup.subCategories);
        const sortedCategories = sortCategories(catObj, newSortOption);

        // Store the sorted categories in the reorderedListsMap for the current property or group
        mapOps.set(selectedGroup._id, { order: sortedCategories, rootType: "category" });

        sortedCategories.forEach((category) => 
        {
            const subCategoriesObj = categoryContext.getCategoriesFromIds(category.subCategories);
            const sortedSubCategories = sortCategories(subCategoriesObj, newSortOption);

            mapOps.set(category._id, { order: sortedSubCategories, rootType: "category" });
        });

        setOpenSort(false);
    };

    const onSortEnd = ({ parent, oldIndex, newIndex }) => 
    {
        if (oldIndex == newIndex) return;

        const { _id: parentId, categoryRoots } = parent;
        const rootType = categoryRoots ? "property" : "category";

        /**
         * If key parent's order array exists in map then get it from the map, otherwise get it from the category context:
         * Get parent's subcategory list and sort, otherwise parent is a property id, so get list of groups and sort them.
         */
        const arrayToReorder = (mapOps.get(parentId)?.order) || (getCategoriesFromIds([parentId])[0] ? getChildrenOf(getCategoriesFromIds([parentId])[0]) : categoryGroups);

        const rearranged = arrayMove(arrayToReorder, oldIndex, newIndex);
        const newValue = { order: rearranged, rootType };

        mapOps.set(parentId, newValue);
    };

    const onClose = () => 
    {
        mapOps.reset();
        expandedListsMapOps.reset();
        setRearrage(false);
    };

    const submitData = () => 
    {
        const putData = [];
        reorderedListsMap.forEach(({ order, rootType }, id) => 
        {
            putData.push({ rootId: id, rootType, order: order.map((c) => c._id) });
        });
        return reorderCategories(putData);
    };

    const { loading, run } = useRequest(() => submitData(), { manual: true, onSuccess: onClose });


    useLayoutEffect(() => 
    {
        if (selectedGroup === null && categoryGroups.length > 0) 
        {
            setSelectedGroup({ ...categoryGroups[0] });
        }
    }, [categoryGroups]);
    
    return (<>
        <div className="rearrangeCategory-header">
            <div className="rearrangeCategory-leftHeader">
                <div className="rearrangeCategory-leftHeaderTop">

                    <Icon name="arrow left" className="rearrangeCategory-back" onClick={() => setRearrage(false)} />

                    <span className="title">{trans("manageCategory.Rearrange")}</span>
                    <Popup
                        content={trans("manageCategory.rearrangeSubTitle")}
                        trigger={<img className="help" src="/img/icon-question-circle.svg" />}
                    />
                </div>
                <div className="rearrangeCategory-leftHeaderBottom">
                    {trans("manageCategory.rearrangeSubTitle")}
                </div>
            </div>
        </div>
        <div className="rearrangeCategoryContent">
            <div className="rearrangeCategoryGroups">
                <div className="header">
                    <div>
                        <div className="topHeading">
                            <div><strong>{trans("manageCategory.groups")}</strong> ({MAX_GROUPS_ALLOWED} {trans("manageCategory.maxAllowed")})</div>  </div>
                        <div className="sub-text">{trans("manageCategory.subHeading")}</div>
                    </div>
                </div>
                <SortableContainer onSortEnd={(args) => onSortEnd({ ...args, parent })}
                    lockAxis="y"
                    lockToContainerEdges
                    useDragHandle
                >
                    {
                        categoryObjList.map((category, index) => (
                            <GroupItem
                                key={category._id}
                                index={index}
                                value={category}
                                lang={lang}
                                trans={trans}
                                getCategoriesFromIds={getCategoriesFromIds}
                                isActive={selectedGroup?._id === category._id}
                                category={category}
                                getIconInfo={getIconInfo}
                                onClick={() => setSelectedGroup({ ...category })}
                            />
                        ))
                    }
                </SortableContainer>
            </div>
            <div className="manageCategory-Divider">
                <div className="vertical-line"></div>
                <div className="vertical-line"></div>
            </div>
            {selectedGroup !== null && <div className="rearrangeCategory-main">
                <div className="mainSectionHeading">
                    <ButtonGroup>
                        <Button className={"manageCategory-tab active"}>{trans("manageCategory.categoryTab")}</Button>
                    </ButtonGroup>

                    <div className="heading-rightSide">
                        <div className="addNewCategory" ref={newSortRef}>
                            <Button className="manageCategorySort" onClick={() => 
                            {
                                setOpenSort((prev) => !prev); 
                            }}>
                                {trans("manageCategory.sortBy")} <img src="/img/icon-chevron-down.svg" alt="expand" />
                            </Button>
                            <Popup
                                className="addNewCategoryPopup"
                                open={openSort}
                                context={newSortRef}
                                position="bottom right"
                                on="click"
                            >
                                <div>
                                    <div className="addNewCategoryItem" onClick={() => 
                                    {
                                        handleSortChange("A-Z");
                                    }}>{trans("manageCategory.sortAsc")}</div>
                                    <div className="addNewCategoryItem" onClick={() => 
                                    {
                                        handleSortChange("Z-A");
                                    }}>{trans("manageCategory.sortDesc")}</div>
                                </div>
                            </Popup>
                        </div>
                        <Button className={"rearrange-saveBtn active"} disabled={reorderedListsMap.size === 0} onClick={run} loading={loading}>{trans("reorder settings modal.save")}</Button>
                    </div>
                </div>
                <div className="sub-text">{trans("manageCategory.subCategory-subHeading")}</div>

                <Scrollbars className="categoryTree-Scrollbar" autoHeight autoHeightMin={"300px"} autoHeightMax={"100%"}>
                    <CategoryList
                        lang={lang}
                        categoryList={selectedGroup?.subCategories}
                        getIconInfo={getIconInfo}
                        reorderedListsMap={reorderedListsMap}
                        expandedListsMapOps={expandedListsMapOps}
                        onSortEnd={onSortEnd}
                        trans={trans}
                        parent={selectedGroup}
                    />
                </Scrollbars>

            </div>
            }
        </div>
        <footer>
            <p className="manageCategoryFooter">{trans("Footer.Copyright_©_2014-{{fullYear}}_Mapsted_Co", { fullYear: today.getFullYear() })}</p>
        </footer>
    </>);

};

export default RearrangeCategory;



const GroupItem = ({ index, isActive, category, lang, value, getIconInfo, trans, getCategoriesFromIds, onClick }) => 
{
    const DragHandle = sortableHandle(() => <div className="group-dragHandle"><ButtonIcon className="categoryItemIcon" icon="drag-handle" /></div>);
    
    const getSubCategoriesCount = (category) =>
    {
        if (category.subCategories.length > 0) 
        {
    
            const subCategories = getCategoriesFromIds(category.subCategories);
            return subCategories.reduce((a, e) => e.subCategories.length + a, 0);
        }
        return 0;
    };

    const SortableItem = sortableElement(() => 
    {
        const catName = category.name[lang] || category.name[DEFAULT_LANGUAGE_CODE];
        const { url, backgroundColour, iconColour } = getIconInfo(category);
        return <div className={`rearrangeCategoryGroupItem-root ${isActive ? "active" : ""}`} onClick={onClick}>
            <DragHandle />
            {
                <div className="icon">
                    <StyledIcon imageSrc={url}
                        backgroundColour={backgroundColour}
                        iconColour={iconColour}
                    />
                </div>
            }
            <div className="middle">
                <div className="name">
                    {catName}
                </div>
                <div className="sub-text">
                    {category.subCategories.length} {trans("branding.Categories")} | {getSubCategoriesCount(category)} {trans("branding.SubCategories")}
                </div>
            </div>
        </div>;
    });

    return (
        <SortableItem
            index={index}
            value={value}
            collection={parent._id}
        />
    );
};

const CategoryList = ({ categoryList, onSortEnd, reorderedListsMap, expandedListsMapOps, lang, parent, getIconInfo, trans }) => 
{
    const categoryContext = useContext(CategoryContext);
    const categories = categoryContext.getCategoriesFromIds(categoryList);

    const { _id: parentId } = parent;
    const reorderedObjList = reorderedListsMap.get(parentId)?.order;
    const categoryObjList = reorderedObjList ? reorderedObjList : categories;

    const SortableContainer = sortableContainer(({ children }) => <div>{children}</div>);

    if (categoryObjList.length === 0)
    {
        return <NoCategorysMessage trans={trans} />;
    }
    return (
        <SortableContainer
            onSortEnd={(args) => onSortEnd({ ...args, parent })}
            lockAxis="y"
            lockToContainerEdges
            useDragHandle
        >
            {
                categoryObjList.map((category, index) => (
                    <CategoryItem
                        index={index}
                        value={category}
                        category={category}
                        onSortEnd={onSortEnd}
                        key={category._id}
                        getIconInfo={getIconInfo}
                        reorderedListsMap={reorderedListsMap}
                        expandedListsMapOps={expandedListsMapOps}
                        lang={lang}
                        trans={trans}
                        parent={parent}
                        subCategoryList={category.subCategories}
                    />
                ))
            }
        </SortableContainer>

    );
};

const CategoryItem = ({ index, category, onSortEnd, lang, value, parent, reorderedListsMap, expandedListsMapOps, trans }) => 
{
    const { getCategoryStyledIcon } = useContext(CategoryContext);
    let open = expandedListsMapOps.get(category._id);
    const hasSubCategories = Array.isArray(category.subCategories) && category.subCategories.length > 0;
    const showSubCategories = open && hasSubCategories;
    
    const DragHandle = sortableHandle(({ children }) => <div style={{ display: "flex", width: "100%", padding: "10px" }}><div className="category-dragHandle"><ButtonIcon className="categoryItemIcon" icon="drag-handle" /></div> {children}</div>);
    const SortableItem = sortableElement(() => 
    {
        const catName = category.name[lang] || category.name[DEFAULT_LANGUAGE_CODE];
        const { url, backgroundColour, iconColour } = getCategoryStyledIcon(category);
        return <div className="categoryItemRoot">
            <div className={"rearrangecategoryItem-content"}>
                {(hasSubCategories) && <Icon style={{ position: "absolute", left: "30px", top: 10 }} name={`chevron ${showSubCategories ? "up" : "down"}`} onClick={() => 
                {
                    expandedListsMapOps.set(category._id, !showSubCategories); 
                }} />}

                <DragHandle>
                    <div style={{ userSelect: "none", display: "flex", gap: 10, marginLeft: hasSubCategories ? "35px": 10 }}>
                        {
                            <div>
                                <StyledIcon
                                    className="categoryItemRoot-icon"
                                    imageSrc={url}
                                    backgroundColour={backgroundColour}
                                    iconColour={iconColour}
                                />
                            </div>
                        }
                        <div className="middle">
                            <div className="name">
                                {catName}
                            </div>
                        </div>
                    </div>
                </DragHandle>
            </div>
            {
                (showSubCategories) && <div className="rearrangeSubList">
                    <CategoryList
                        onSortEnd={onSortEnd}
                        categoryList={category.subCategories}
                        parent={category}
                        lang={lang}
                        trans={trans}
                        reorderedListsMap={reorderedListsMap}
                        expandedListsMapOps={expandedListsMapOps}

                    />
                </div>
            }
        </div>;
    });

    return (
        <SortableItem
            index={index}
            value={value}
            collection={parent._id}
        />
    );
};

export const NoCategorysMessage = ({ trans }) => (
    <div className="rearrange-category-section-container">
        <div className="rearrange-category-section-no-data">
            <Image as="span" src="/img/icon-no-category.svg" alt="No Categorys" />
            <p>{trans("manageCategory.NO_CATEGORY_IN_SELECTED_GROUP_REARRANGE")}</p>
        </div>
    </div>
);
