import React, { useContext } from "react";
import { useSetState } from "ahooks";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Header, Image } from "semantic-ui-react";
import classNames from "classnames";
import { DYNAMIC_MAP_LAYERS_MODES, MAINTENANCE_MODES, MAP_OVERLAYS_MODES } from "../../../_constants/constants";
import { ButtonBasic } from "../../common/ButtonIcon";
import ZoomLevelIndicator from "../mapOverlays/zoomLevelIndicator/ZoomLevelIndicator";
import { MapOverlaysContext } from "../../../store/MapOverlaysContext";
import { Button } from "semantic-ui-react";
import { ModalBox } from "../../common/ModalBox";
import { DynamicMapLayersContext } from "../../../store/DynamicMapLayersContext";
import { useQuery } from "react-query";
import { UTIL_QUERIES } from "../../../_utils/queries";
import BrandingContext from "../../../store/BrandingContext";
import serverApi from "../../../_api/server.api";
import { useTranslation } from "react-i18next";


export const MaintenanceLeftbar = ({ sidebarExtended, onSidebarToggle, mode, onModeChange }) => 
{
    const [state, setState] = useSetState({
        mapOverlayCancelConfirmation: false,
        dynamicMapLayerCancelConfirmation: false,
        newMode: undefined
    });

    const trans = useTranslation().t;

    const { data: config } = useQuery(UTIL_QUERIES.CONFIG());

    const mapOverlayCtx = useContext(MapOverlaysContext);
    const { mode: mapOverlayMode } = mapOverlayCtx.state;
    const { dynamicMapLayerCreationState } = mapOverlayCtx;

    const dynamicMapLayerCtx = useContext(DynamicMapLayersContext);
    const { mode: dynamicMapLayerMode } = dynamicMapLayerCtx.state;

    const brandingCtx = useContext(BrandingContext);
    const { properties, propertyId, buildingId, floorId } = brandingCtx.state;

    const checkMapOverlayStatus = (newMode) =>
    {
        if (mapOverlayMode === MAP_OVERLAYS_MODES.CREATE_OVERLAY || mapOverlayMode === MAP_OVERLAYS_MODES.EDIT_OVERLAY)
        {
            setState({
                mapOverlayCancelConfirmation: true,
                newMode
            });
        }
        // if mapOverlay creation was initiated by dml 
        else if (dynamicMapLayerCreationState.enabled)
        {
            setState({
                dynamicMapLayerCancelConfirmation: true,
                newMode
            });
        }
        else
        {
            mapOverlayCtx.resetContext();
            onModeChange(newMode);
        }
    };

    const checkDynamicMapLayerStatus = (newMode) =>
    {
        if (dynamicMapLayerMode === DYNAMIC_MAP_LAYERS_MODES.CREATE_DYNAMIC_MAP_LAYER
            || dynamicMapLayerMode === DYNAMIC_MAP_LAYERS_MODES.MULTI_CREATION)
        {
            setState({
                dynamicMapLayerCancelConfirmation: true,
                newMode
            });
        }
        else
        {
            dynamicMapLayerCtx.resetContext();
            onModeChange(newMode);
        }
    };

    const modeChangeHandler = (newMode) =>
    {
        if (mode === MAINTENANCE_MODES.MAP_OVERLAYS)
        {
            checkMapOverlayStatus(newMode);
        }
        else if (mode === MAINTENANCE_MODES.DYNAMIC_MAP_LAYERS)
        {
            checkDynamicMapLayerStatus(newMode);
        }
        else
        {
            onModeChange(newMode);
        }
    };

    const handleAlertServicesClick = (alertPage) =>
    {
        let alertServicesUrl = new URL(`${config.ALERT_SERVICES_URL}/${alertPage}`);

        const navLocationIds = getNavLocationIds();

        if (navLocationIds.property)
        {
            alertServicesUrl.searchParams.set("property", navLocationIds.property);
            
            if (navLocationIds.building)
            {
                alertServicesUrl.searchParams.set("building", navLocationIds.building);
            }

            if (navLocationIds.floor)
            {
                alertServicesUrl.searchParams.set("floor", navLocationIds.floor);
            }
        }

        if (serverApi.token)
        {
            alertServicesUrl.searchParams.set("t", serverApi.token);
        }

        window.location = alertServicesUrl.toString();
    };

    const getNavLocationIds = () =>
    {
        let navLocationIds = {
            property: "",
            building: "",
            floor: ""
        };

        if (properties && propertyId)
        {
            const property = properties[propertyId];

            navLocationIds.property = property.propertyId;

            if (buildingId && properties[propertyId].buildings[buildingId])
            {
                const building = properties[propertyId].buildings[buildingId];

                navLocationIds.building = building.buildingId;

                let floor = floorId ? Object.values(properties[propertyId].buildings[buildingId].floors).find((floor) => floor._id === floorId) : undefined;

                if (floor)
                {
                    navLocationIds.floor = floor.floorId;
                }
            }
        }

        return navLocationIds;
    };

    return (
        <>
            <div className={classNames("maintenanceRightbar", { minimizedSidebar: !sidebarExtended })}>
                {(sidebarExtended) && <Header className="header-txt" as="h2" content={trans("Header.Maintenance")} />}

                {mode === MAINTENANCE_MODES.MAP_OVERLAYS && <ZoomLevelIndicator />}

                <ButtonBasic icon="arrow-right" className={classNames("triggerMinimize", { active: !sidebarExtended })} onClick={onSidebarToggle} />
                <Scrollbars autoHeight autoHeightMin="calc(1vh)" autoHeightMax="calc(100vh - 160px)">
                    <div className="alignerSidebar">
                        <MaintenanceTypeBox
                            expanded={sidebarExtended}
                            img="maintenance-style5"
                            heading={trans("Maintenance.Map_Overlays")}
                            description={trans("Maintenance.Map_Overlays_Description")}
                            active={mode === MAINTENANCE_MODES.MAP_OVERLAYS}
                            onClick={() => modeChangeHandler(MAINTENANCE_MODES.MAP_OVERLAYS)}
                        />
                        <MaintenanceTypeBox
                            expanded={sidebarExtended}
                            img="maintenance-style3"
                            heading={trans("Maintenance.Dynamic_Map_Layers")}
                            description={trans("Maintenance.Dynamic_Map_Layers_Description")}
                            active={mode === MAINTENANCE_MODES.DYNAMIC_MAP_LAYERS}
                            onClick={() => modeChangeHandler(MAINTENANCE_MODES.DYNAMIC_MAP_LAYERS)}
                        />
                        <MaintenanceTypeBox
                            expanded={sidebarExtended}
                            img="maintenance-style6"
                            heading={trans("Maintenance.Zones")}
                            description={trans("Maintenance.Zones_Description")}
                            active={mode === MAINTENANCE_MODES.ZONES}
                            onClick={() => modeChangeHandler(MAINTENANCE_MODES.ZONES)}
                        />
                        <MaintenanceTypeBox
                            expanded={sidebarExtended}
                            img="maintenance-style1"
                            heading={trans("Maintenance.Service_Alerts")}
                            description={trans("Maintenance.Service_Alerts_Description")}
                            // active={mode === MAINTENANCE_MODES.ALERT}
                            onClick={() => handleAlertServicesClick("alerts")}
                        />
                        <MaintenanceTypeBox
                            expanded={sidebarExtended}
                            img="maintenance-style2"
                            className="erpFeatureName"
                            heading={trans("Maintenance.Emergency_Response_Planning_(ERP)")}
                            description={trans("Maintenance.Emergency_Response_Planning_(ERP)_Description")}
                            // active={mode === MAINTENANCE_MODES.EMERGENCY}
                            onClick={() => handleAlertServicesClick("emergencyAlerts")}
                        />
                    </div>
                </Scrollbars>
            </div>
            <MapOverlayCancelConfirmationModal
                open={state.mapOverlayCancelConfirmation}
                onClose={() => setState({
                    mapOverlayCancelConfirmation: false,
                    newMode: undefined
                })}
                onCancel={() => 
                {
                    mapOverlayCtx.resetContext();
                    onModeChange(state.newMode);
                    setState({
                        mapOverlayCancelConfirmation: false,
                        newMode: undefined
                    });
                }}
                isCreate={mapOverlayMode === MAP_OVERLAYS_MODES.CREATE_OVERLAY}
            />
            <DynamicMapLayerCancelConfirmationModal
                open={state.dynamicMapLayerCancelConfirmation}
                onClose={() => setState({
                    dynamicMapLayerCancelConfirmation: false,
                    newMode: undefined
                })}
                onCancel={() =>
                {
                    dynamicMapLayerCtx.resetContext();
                    if (dynamicMapLayerCreationState.enabled)
                    {
                        mapOverlayCtx.resetContext();
                    }
                    onModeChange(state.newMode);
                    setState({
                        dynamicMapLayerCancelConfirmation: false,
                        newMode: undefined
                    });
                }}
            />
        </>
    );
};

const MaintenanceTypeBox = ({ active, expanded, img, heading, description, onClick, className }) => (
    <div className={classNames("maintenanceTypeBox", { active }, className)} onClick={onClick} title={!expanded && heading ? heading : ""} >
        <Image as="span" src={`/img/${img}.svg`} />
        {(expanded) && (
            <div className="maintenanceTypeBody">
                <b>{heading}</b>
                <p>{description}</p>
            </div>
        )}
    </div>
);

const MapOverlayCancelConfirmationModal = ({ open, isCreate, onCancel, onClose }) =>
{
    const trans = useTranslation().t;

    return (
        <ModalBox className="confirmActionModal"
            open={open}
            onClose={onClose}
            header={trans("Cancel_Confirmation_Modal.Confirm_Action")}
            actions={<Button color="orange" floated="right" content={trans("Cancel_Confirmation_Modal.Cancel")} onClick={onCancel} />}>
            <p className="p-modal">
                { isCreate ? trans("Cancel_Confirmation_Modal.Overlay_Create_Cancel") : trans("Cancel_Confirmation_Modal.Overlay_Update_Cancel") }
            </p>
        </ModalBox>
    );
};

const DynamicMapLayerCancelConfirmationModal = ({ open, onCancel, onClose }) =>
{
    const trans = useTranslation().t;

    return (
        <ModalBox className="confirmActionModal"
            open={open}
            onClose={onClose}
            header={trans("Cancel_Confirmation_Modal.Confirm_Action")}
            actions={<Button color="orange" floated="right" content={trans("Cancel_Confirmation_Modal.Cancel")} onClick={onCancel} />}>
            <p className="p-modal">
                {trans("Cancel_Confirmation_Modal.Dynamic_Map_Layer_Create_Cancel")}
            </p>
        </ModalBox>
    );
};
