import React from "react";
import { SubheaderTemplates } from "../../templates/components/SubheaderTemplates";
import TemplatesBody from "../../templates/components/TemplatesBody";
import TemplatesHeader from "../../templates/components/TemplatesHeader";
import { useOverlaysTemplateContext } from "../../../store/OverlaysTemplateContext";

import "./templates.css";
import { OVERLAYS_TEMPLATE_MODES } from "../../../_constants/overlaysTemplate";
import OverlaysTemplateCreationModal from "../../maintenance/mapOverlays/OverlayTemplateCreationModal";
import { TemplateSortingOptions } from "../../../_constants/constants";
import { useTranslation } from "react-i18next";

const Templates = () =>
{
    const {
        overlayTemplateMode,
        overlayCreateModalToggle,
    } = useOverlaysTemplateContext();

    const trans = useTranslation().t;

    const displayTemplateModal = (overlayTemplateMode === OVERLAYS_TEMPLATE_MODES.CREATE || overlayTemplateMode === OVERLAYS_TEMPLATE_MODES.VIEW || overlayTemplateMode ===  OVERLAYS_TEMPLATE_MODES.EDIT || overlayTemplateMode === OVERLAYS_TEMPLATE_MODES.CREATE ) 
    && overlayCreateModalToggle;

    const sortingTranslatedOptions = TemplateSortingOptions.map((template) => ({ ...template, text: trans(template.text) }));

    return (
        <>
            <SubheaderTemplates />
            <TemplatesHeader options={sortingTranslatedOptions} />

            <TemplatesBody />

            {displayTemplateModal && <OverlaysTemplateCreationModal />}
        </>
    );
};

export default Templates;
