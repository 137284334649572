import React, { useRef } from "react";
import { Button } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

const FileUploader = ({
    onChange,
    onError,
    className,
}) =>
{
    const fileRef = useRef();

    const acceptableFileFormats = ".csv";

    const trans = useTranslation().t;

    const fileChangeHandler = () =>
    {
        validateFile(fileRef.current.files);
    };

    const getFileExtention = (fileName) => fileName.substring(fileName.lastIndexOf("."));

    const validateFile = (uploadedFiles) =>
    {
        const fileFormats = acceptableFileFormats.split(",");
        const uploadFilesArray = Array.from(uploadedFiles);


        // Validate files based on file type and file size
        let files = uploadFilesArray.filter((file) => (fileFormats.includes(file.type) 
            || fileFormats.includes(getFileExtention(file.name))));

        if (files.length > 0)
        {
            onChange(files);
        }
        else
        {
            onError(["common.Invalid File"]);
        }
        
        fileRef.current.value = "";
    };

    const dropHandler = (e) =>
    {
        e.preventDefault();
        validateFile(e.dataTransfer.files);
    };

    return (
        <div className={`dragDropBox ${className? className: ""}`} onDrop={dropHandler} onDragOver={(e) => e.preventDefault()}>
            {trans("FileUploader.Drag_Drop_Files")}
            <span>{trans("FileUploader.Or")}</span>
            <Button 
                content={trans("UploadImageModal.browse")}
                onClick={() => fileRef.current.click()} />
            <input 
                type="file" 
                ref={fileRef} 
                className="hidden"
                accept={acceptableFileFormats} 
                onChange={fileChangeHandler} />
        </div>
    );
};

export default FileUploader;