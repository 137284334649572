import React, { useContext, useState } from "react";
import { MapOverlaysContext } from "../../../../store/MapOverlaysContext";
import { TextGroup } from "../../../common/TextGroup";
import { ModalLayout } from "../../../popups/ModalLayout";
import { DropdownForm } from "../../../common/DropdownForm";
import { DEFAULT_LANGUAGE_CODE, OVERLAY_MODAL_TEXT_LENGTH_LIMIT } from "mapsted.maps/utils/map.constants";
import { Button, Icon, Input } from "semantic-ui-react";
import { ButtonBasic, ButtonIcon } from "../../../common/ButtonIcon";
import { ErrorLabel } from "../../../elements/ErrorLabel";

import "./MapOverlaysBulkConfiguration.css";
import { ModalBox } from "../../../common/ModalBox";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../LanguageSelector";
import { getMapOverlayLangs } from "../utils/mapOverlayUtils";

const MapOverlaysBulkConfiguration = () =>
{
    const { 
        state: { 
            mapOverlays, 
            displayLang
        }, 
        bulkCreationState: { 
            bulkCreationModal, 
            templateMapOverlay,
            bulkMapOverlays,
            errorMessage,
        }, 
        cancelBulkCreation,
        handleTemplateMapOverlayChange,
        handleBulkMapOverlayEntryUpdate,
        handleGoBackToDrawingBoard,
        handleRemoveBulkEntry,
        handleFinish,
        handleGoToMapOverlay,
        updateDisplayLang,
        handleMultipleMapOverlayEntriesUpdate,
    } = useContext(MapOverlaysContext);

    const [displayCancelConfirmation, setDisplayCancelConfirmation] = useState(false);

    const trans = useTranslation().t;

    const mapOverlayOptions = mapOverlays.map((mapOverlay) => ({
        key: mapOverlay._id,
        value: mapOverlay._id,
        text: mapOverlay.name[DEFAULT_LANGUAGE_CODE].length >= OVERLAY_MODAL_TEXT_LENGTH_LIMIT ? `${mapOverlay.name[DEFAULT_LANGUAGE_CODE].slice(0, OVERLAY_MODAL_TEXT_LENGTH_LIMIT + 1)}...` : mapOverlay.name[DEFAULT_LANGUAGE_CODE]
    }));

    const handleCancelClick = () =>
    {
        setDisplayCancelConfirmation(true);
    };

    const clearNonDefaultLangsFromMapOverlays = () =>
    {
        let updatedMapOverlays = [ ...bulkMapOverlays ];

        updatedMapOverlays.forEach((mapOverlay) =>
        {
            mapOverlay.name = { [DEFAULT_LANGUAGE_CODE]: mapOverlay.name[DEFAULT_LANGUAGE_CODE] };
            mapOverlay.toolTipText = { [DEFAULT_LANGUAGE_CODE]: mapOverlay.toolTipText[DEFAULT_LANGUAGE_CODE] };
        });

        handleMultipleMapOverlayEntriesUpdate(updatedMapOverlays);
    };

    const onTemplateChange = (value) =>
    {
        handleTemplateMapOverlayChange(value);

        // change display lang back to default lang when template map overlay is changed
        updateDisplayLang(DEFAULT_LANGUAGE_CODE);

        // clear all the non default lang codes from text fields
        clearNonDefaultLangsFromMapOverlays();
    };

    const modalActions = (
        <div className="modalActions">
            <Button 
                className="bulkFinish"
                color="orange"
                floated="right"
                content={trans("MapOverlaysBulkConfiguration.Finish")}
                disabled={!templateMapOverlay || bulkMapOverlays.length === 0}
                onClick={handleFinish}
            />
            <Button 
                floated="right"
                content={trans("MapOverlaysBulkConfiguration.Go_Back")}
                onClick={handleGoBackToDrawingBoard}
            />
        </div>
    );

    let templateMapOverlayName;
    let displayLangArray = [DEFAULT_LANGUAGE_CODE];

    if (templateMapOverlay)
    {
        const templateOverlay = mapOverlays.find((mapOverlay) => mapOverlay._id === templateMapOverlay);
        templateMapOverlayName = templateOverlay.name[displayLang];
        displayLangArray = getMapOverlayLangs(templateOverlay);
    }


    return (
        <>
            <ModalLayout
                className="bulkMapOverlayModal"
                open={bulkCreationModal}
                onClose={handleCancelClick}
                heading={trans("MapOverlaysBulkConfiguration.Modal_Header")}
                actions={modalActions}
            >
                <TextGroup 
                    heading={trans("MapOverlaysBulkConfiguration.Source_Map_Overlay")} 
                    className="dropdownSelector mapOverlaySelector">
                    <DropdownForm 
                        className="mapOverlayPicker"
                        scrolling
                        selectOnBlur={false}
                        placeholder={trans("MapOverlaysBulkConfiguration.Choose_Map_Overlay")}
                        disabled={mapOverlayOptions.length === 0}
                        options={mapOverlayOptions}
                        value={templateMapOverlay || "" }
                        onChange={(e, { value }) => onTemplateChange(value)} 
                    />
                </TextGroup>

                <TextGroup 
                    heading={trans("LanguageSelector.Choose_Language")}
                    className="dropdownSelector languageSelector"
                >
                    <LanguageSelector 
                        activeLang={displayLang}
                        defaultDisplayedLangs={displayLangArray}
                        setActiveLang={(lang) => updateDisplayLang(lang)}
                        allowedLangs={displayLangArray}
                        allowAdditions={false}
                    />
                </TextGroup>

                <div className="bulkCreationEntires">
                    {bulkMapOverlays.map((mapOverlay, index) => <BulkCreationEntry 
                        key={mapOverlay._id} 
                        mapOverlay={mapOverlay}
                        index={index}
                        onUpdate={handleBulkMapOverlayEntryUpdate}
                        templateMapOverlayName={templateMapOverlayName}
                        onRemove={handleRemoveBulkEntry}
                        onGoToMapOverlay={handleGoToMapOverlay}
                        trans={trans}
                        displayLang={displayLang}
                    />)}
                </div>
            
            
                {errorMessage 
                && <div className="errors">
                    <ErrorLabel>
                        {errorMessage}
                    </ErrorLabel>
                </div>}
            
            </ModalLayout>

            <BulkCreationCancelConfirmation
                open={displayCancelConfirmation}
                onClose={() => setDisplayCancelConfirmation(false)}
                onCancelClick={() => 
                {
                    setDisplayCancelConfirmation(false);
                    cancelBulkCreation();
                }}
                trans={trans}
            />
        </>
    );
};

const BulkCreationEntry = ({ 
    mapOverlay, 
    index, 
    onUpdate, 
    onRemove, 
    templateMapOverlayName, 
    onGoToMapOverlay,
    trans,
    displayLang,
}) => (
    <div className="bulkCreationEntry">
        <TextGroup flexible title={`#${index + 1}`} className="bulkCreationEntryTextGroup">
            <Input 
                className="mapOverlayName"
                value={mapOverlay.name?.[displayLang] || "" }
                placeholder={templateMapOverlayName ? `${templateMapOverlayName}_${index + 1}`: trans("MapOverlaysBulkConfiguration.Enter_Name")}
                onChange={(event, { value }) => 
                {
                    const updatedName = { ...mapOverlay.name, [displayLang]: value };
                    onUpdate(mapOverlay._id, "name", updatedName);
                }} 
            />
            <Input
                className="mapOverlayLabel"
                value={mapOverlay.toolTipText?.[displayLang] || "" }
                placeholder={trans("MapOverlaysBulkConfiguration.Enter_Label")}
                onChange={(event, { value }) => 
                {
                    const updatedLabel = { ...mapOverlay.toolTipText, [displayLang]: value };
                    onUpdate(mapOverlay._id, "toolTipText", updatedLabel);
                }}
            />
         
            <ButtonBasic className="bulkCreationActions" icon={<Icon name="find" />} onClick={() => onGoToMapOverlay(mapOverlay._id)} />
            <ButtonIcon
                className="bulkCreationActions"
                icon="cross"
                onClick={() => onRemove(mapOverlay._id)} />

            
        </TextGroup>
    </div>
);

const BulkCreationCancelConfirmation = ({ open, onCancelClick, onClose, trans }) => (
    <ModalBox className="confirmActionModal"
        header={trans("MapOverlaysBulkConfiguration.Cancel_Modal_Header")}
        open={open}
        onClose={onClose}
        actions={<Button 
            color="orange" 
            floated="right" 
            content={trans("MapOverlaysBulkConfiguration.Cancel")} 
            onClick={onCancelClick} 
        />}>
        <p className="p-modal">
            {trans("MapOverlaysBulkConfiguration.Cancel_Confirmation_Message")} 
        </p>
    </ModalBox>);

export default MapOverlaysBulkConfiguration;