import React, { useEffect, useContext } from "react";
import { useSetState } from "ahooks";
import { MapOverlaysContext } from "../../../store/MapOverlaysContext";
import LinkButton from "../../common/LinkButton";
import { ModalLayout } from "../../popups/ModalLayout";
import FileUploader from "../dynamicMapLayers/dynamicMapLayersSidebars/FileUploader";

import "./MapOverlaysUploadCsvModal.css";
import { Button, Grid, Header } from "semantic-ui-react";
import { downloadMapOverlays, validateAndGetUploadedMapOverlays } from "./utils/fileProcessing";
import { ErrorLabel } from "../../elements/ErrorLabel";
import { MAP_OVERLAYS_MODES } from "../../../_constants/constants";
import { Trans, useTranslation } from "react-i18next";
import { TextGroup } from "../../common/TextGroup";
import LanguageDropdown from "../../common/languageDropdown/LanguageDropdown";
import { DEFAULT_LANGUAGE_CODE } from "mapsted.maps/utils/map.constants";

const MapOverlaysUploadCsvModal = () =>
{
    const {
        state: {
            mapOverlays,
            mode,
            consumedMapOverlays,
            allowedLangs,
        },
        overlayTemplates,
        uploadCsvMapOverlays,
        updateMode,
        getCSVFileName,
        propertyId,
        buildingId,
        floorId,
    } = useContext(MapOverlaysContext);

    const [state, setState] = useSetState({
        file: undefined,
        errors: [],
        lang: DEFAULT_LANGUAGE_CODE
    });

    const trans = useTranslation().t;

    useEffect(() =>
    {
        if (mode !== MAP_OVERLAYS_MODES.UPLOAD_CSV)
        {
            setState({
                file: undefined,
                errors: []
            });
        }
    }, [mode]);

    const fileChangeHandler = (files) =>
    {
        const file = files[0];
        setState({
            file,
            errors: []
        });
    };

    const fileUploadErrorHander = (errors) =>
    {
        setState(errors);
    };

    const downloadFileHandler = () =>
    {
        const currentBuildingId = buildingId && buildingId !== -1 ? buildingId : "";
        const currentFloorId = floorId && floorId !== -1 ? floorId : "";
        const fileName = getCSVFileName(propertyId, currentBuildingId, currentFloorId, state.lang, "mapoverlays");
        downloadMapOverlays(mapOverlays, fileName, consumedMapOverlays, state.lang, overlayTemplates);
    };

    const uploadClickHandler = async () =>
    {
        const { file } = state;
        
        if (file)
        {
            const { errors, uploadedMapOverlays } = await validateAndGetUploadedMapOverlays(file, mapOverlays, consumedMapOverlays, allowedLangs, overlayTemplates, trans);
            if (errors.length)
            {
                setState({
                    errors
                });
            }
            else
            {
                await uploadCsvMapOverlays(uploadedMapOverlays);
                setState({ lang: DEFAULT_LANGUAGE_CODE });
            }
        }
    };

    return (
        <ModalLayout
            size="mini"
            className="mapOverlayUploadModal"
            heading={trans("MapOverlaysUploadCsvModal.Upload_Csv")}
            open={mode===MAP_OVERLAYS_MODES.UPLOAD_CSV}
            onClose={() => 
            {
                setState({ lang: DEFAULT_LANGUAGE_CODE });
                updateMode(undefined);
            }}
            actions={<Button
                color="orange"
                content={trans("MapOverlaysUploadCsvModal.Upload")}
                disabled={!state.file}
                onClick={uploadClickHandler}
            />}
        >
            <p className="p-modal downloadLink">
                <Trans 
                    i18nKey="MapOverlaysUploadCsvModal.CSV_template" 
                    components={{ templateLink: <LinkButton onClick={downloadFileHandler} /> }}
                />
            </p>

            <TextGroup heading={trans("LanguageSelector.Choose_Language")} className="languageSelectorDropdown">
                <LanguageDropdown 
                    allowedLangs={allowedLangs}
                    activeLang={state.lang}
                    onChange={(lang) => setState({ lang })}
                />
            </TextGroup>

            <>
                {
                    state.file ? <div className="uploadFileInfo">
                        <Grid centered>
                            <Grid.Row>
                                <Header content={state.file.name} />
                            </Grid.Row>
                            <Grid.Row style={{ marginTop: "20px" }}>
                                <Button
                                    basic
                                    icon="refresh"
                                    content={trans("UploadCSVModal.Change")}
                                    onClick={() => setState({ file: undefined, errors: [] })}
                                />
                            </Grid.Row>
                        </Grid>
                    </div> : <FileUploader
                        onChange={fileChangeHandler}
                        onError={fileUploadErrorHander}
                        className="mapOverlayFileUploader"
                    />
                }
            </>
            { !!state.errors.length && <ErrorLabel multiple={state.errors.length > 0}>
                <ul className="error-message">
                    {state.errors.map((error) => <li  key={error}>{error}</li>)}
                </ul>
            </ErrorLabel>}
        </ModalLayout>
    );
};

export default MapOverlaysUploadCsvModal;