import React from "react";
import { useIconsManagementContext } from "../../IconsManagementContext";
import { Radio } from "semantic-ui-react";
import IconsManagementSection from "../../IconsManagementSection/IconsManagementSection";
import "./IconsManagementThemeFilters.css";
import { ICON_THEMES } from "../../constant";

function IconsManagementThemeFilters() 
{
    const { config, handleChangeIconsFilters, iconsSearchAndFilters } =
    useIconsManagementContext();
    return (
        <div className="icons-management-theme-filters">
            <IconsManagementSection>
                <IconsManagementSection.Header>Theme:</IconsManagementSection.Header>
                <IconsManagementSection.Content className="icons-management-theme-filters-content">
                    <Radio
                        label="None"
                        className="gms-radio"
                        onChange={() => handleChangeIconsFilters({ theme: ICON_THEMES.NONE })}
                        checked={iconsSearchAndFilters.filters.theme === ICON_THEMES.NONE}
                    ></Radio>
                    {config.CATEGORY_ICON_THEMES.map((theme) => (
                        <Radio
                            onChange={() => handleChangeIconsFilters({ theme }) }
                            checked={iconsSearchAndFilters.filters.theme === theme}
                            key={theme}
                            className="gms-radio"
                            label={theme}
                        ></Radio>
                    ))}
                </IconsManagementSection.Content>
            </IconsManagementSection>
        </div>
    );
}

export default IconsManagementThemeFilters;
