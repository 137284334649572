import React, { FC } from "react";
import { Button, ButtonProps } from "semantic-ui-react";

import style from "./EditButton.module.scss";

interface EditButtonProps extends ButtonProps {
    imageSrc: string;
}

const EditButton : FC<EditButtonProps> = ({
    className = "",
    imageSrc,
    content,
    ...rest
}) => (
    <Button
        secondary
        className={`${style.main} ${className}`}
        {...rest}
    >
        <img src={imageSrc} alt="" />
        {(content) && content}
    </Button>
);

export default EditButton;
