import React, { useState } from "react";
import { useListViewContext } from "../../../store/ListViewContext";
import { useTranslation } from "react-i18next";
import { ListViewUploadCSVModal } from "../../pages/branding/listing/modals/ListViewUploadCSVModal";
import { Button } from "semantic-ui-react";
import { EntityType, StructureEntityType } from "mapsted.maps/utils/entityTypes";
import { LIST_MODES } from "../../../_constants/listView";

function ListViewCsv() 
{
    const trans = useTranslation().t;
    const [openStoreUploadModal, setOpenStoreUploadModal] = useState(false);
    const { entities, listMode } =  useListViewContext();

    // display csv upload/download button only if there are non-building namable entities in the floor/property
    const displayUploadModal = entities.filter((entity) => entity.entityLabel && !(entity.entityType === EntityType.STRUCTURE && entity.subEntityType === StructureEntityType.BUILDING)).length !== 0;

    return (
        <div>
            {displayUploadModal
                &&<React.Fragment>
                    <Button disabled={listMode === LIST_MODES.BULK_EDIT} className="buttonIcon " onClick={() => setOpenStoreUploadModal(true)}>
                        <img src="/img/icon-upload-csv.svg" alt="" />
                        {trans("ListView.Upload_File")}
                    </Button>
                    <ListViewUploadCSVModal
                        isOpen={openStoreUploadModal}
                        close={() => setOpenStoreUploadModal(false)}
                    />
                </React.Fragment>
            }             

        </div>
    );
}

export default ListViewCsv;

