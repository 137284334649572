import React from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { Tab } from "semantic-ui-react";

export const TabPane = ({ children }) => (
    <Tab.Pane>
        <Scrollbars autoHeight autoHeightMin={"calc(1vh)"} autoHeightMax={"calc(100vh - 190px)"}>
            <div className="mapstyleContent">
                {children}
            </div>
        </Scrollbars>
    </Tab.Pane>
);

export default TabPane;