import React from "react";
import { useTranslation } from "react-i18next";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Header, Icon, Grid } from "semantic-ui-react";
import { GridTableColumn } from "../elements/GridTableColumn";
import { GridTable } from "../elements/GridTable";
import { formatDate } from "../../_utils/utils";

export const ViewAllDetailPopup = ({ detailObject, onClose }) =>
{
    const heading = detailObject.name || detailObject.longName;
    console.log("detailObject", detailObject)
    return (
        <div>
            <div className="aligner mt-3">
                <Header as="h4">
                    <Icon name="arrow left" color="blue" size="tiny" onClick={onClose} />
                    {heading}
                </Header>
            </div>
            <Scrollbars autoHeight autoHeightMin={`calc(1vh)`} autoHeightMax={`calc(100vh - 320px)`}>
                <div className="aligner">
                    <ViewAllDetailGridTable createdAt={detailObject.createdAt} />
                </div>
            </Scrollbars>
        </div>
    );
}

export const ViewAllDetailGridTable = ({ createdAt }) =>
{
    /** @type {typeof import("../../_intl/resources.json")["en"]["translation"]["ViewAllDetailGridTable"]} */
    const trans = useTranslation().t("ViewAllDetailGridTable");
    return (
        <GridTable>
            <Grid.Row>
                <GridTableColumn title={trans["created"]} />
                {/* <GridTableColumn title="Processed" /> */}
            </Grid.Row>
            <Grid.Row >
                <GridTableColumn detail={formatDate(createdAt, "DD MMM 'YY")} subDetail={formatDate(createdAt, "@ hh:mm A")} />
                {/* TODO: PENDING
                <GridTableColumn detail={"Pending"} />
                */}
            </Grid.Row>
        </GridTable>
    );
}
