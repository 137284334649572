import React, { createContext, useContext, useRef, useState } from "react";
import { useGmsContext } from "../../GmsContext";
import { useImagesBaseContext } from "./ImageBaseContext";
import { useQueryClient } from "react-query";
import { useCroppedImagesHistory } from "../hooks/useCroppedImageHistory";
import { DEFAULT_HIDDEN_MODALS, IMAGE_ACTION_MODES, IMAGE_ASPECT_RATIOS } from "../../constant";
import { convertImgUrlToDataURI } from "../../utils";
import { filerUrl } from "../../../../../_utils/utils";
import serverApi from "../../../../../_api/server.api";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";


const ImageCopyContext = createContext(null);

export const useImageCopyContext = () => useContext(ImageCopyContext);

export function ImageCopyProvider({ children }) 
{
    const DEFAULT_IMAGE_DATA = {
        name: "",
        dataUrl: "",
        aspectRatio: ""
    };
    const cropperRef = useRef(null);
    const gmsContext = useGmsContext();
    const { loadingPool } = gmsContext;
    const imagesBaseContext = useImagesBaseContext();
    const [imageData, setImageData] = useState(DEFAULT_IMAGE_DATA);
    const [imageEditorStatus, setImageEditorStatus] = useState("idle");
    const { croppedImagesHistory, ...croppedImagesHistoryRest } = useCroppedImagesHistory();
    const { selectedImageInfo } = imagesBaseContext;
    const trans = useTranslation().t;
    

    async function handleCopyImage()
    {
        const loadingId = loadingPool.add();
        try 
        {
            let currentDataURL = "";
            if (!croppedImagesHistory?.[selectedImageInfo?._id]?.currentImage)
            {
                currentDataURL = cropperRef.current.cropper
                    .getCroppedCanvas()
                    .toDataURL();
            }
            else if (croppedImagesHistory?.[selectedImageInfo?._id]?.currentImage)
            {
                currentDataURL = croppedImagesHistory?.[selectedImageInfo?._id]?.currentImage;
            }
            else if (imageData.dataUrl)
            {
                currentDataURL = imageData.dataUrl;
            }

            const blob = await (await fetch(currentDataURL)).blob();
            const filerId = await serverApi.filerUpload(blob);
            const payload = {
                name: imageData.name,
                fileName: selectedImageInfo.fileName,
                size: selectedImageInfo.size,
                filerId: filerId,
                mimeType: selectedImageInfo.mimeType,
                aspectRatio: +imageData.aspectRatio,
            };
            await serverApi.createImageReference(payload);
            await imagesBaseContext.imagesQuery.refetch();
            handleCloseCopyImageModal();
            toast.success(trans("gms.image_copied"));

        }
        catch (err)
        {
            console.log(err);
            toast.error(err.message || trans("gms.image_copy_err"));
            throw err;
        }
        finally 
        {
            loadingPool.remove(loadingId);
        }
    }

    function handleChangeImageData(value) 
    {
        setImageData((prev) => ({ ...prev, ...value }));
    }

    async function handleTriggerCopyImage(id) 
    {
        const loadingId = loadingPool.add();

        try 
        {
            const imageInfo = imagesBaseContext.imagesData.find((image) => image._id === id);
            if (imageInfo?.usedIn?.length) 
            {
                const isBeingUsedAsFloorPlanImage = imageInfo.usedIn.find((v) => v.usedAs === "floorPlanImage");
                if (isBeingUsedAsFloorPlanImage) 
                {
                    toast.error(trans("gms.Action cannot be on floor_plan_img"));
                    return;   
                }         
            }

            imagesBaseContext.setImageActionMode(IMAGE_ACTION_MODES.COPY);
            imagesBaseContext.setSelectedImageId(id);
            imagesBaseContext.setHideModals({
                ...DEFAULT_HIDDEN_MODALS,
                gmsModal: true,
            });
            const imageInfoDataUrl = await convertImgUrlToDataURI(
                filerUrl(imageInfo?.filerId)
            );
            setImageData({ ...imageData, dataUrl: imageInfoDataUrl, name: imageInfo.name, aspectRatio: imageInfo.aspectRatio });
        }
        catch (err) 
        {
            console.error(err);
            toast.error(err.message || trans("gms.image_load_err"));
        }
        finally 
        {
            loadingPool.remove(loadingId); 
        }
    }

    function handleCloseCopyImageModal() 
    {
        imagesBaseContext.setSelectedImageId(null);
        imagesBaseContext.setImageActionMode(null);
        imagesBaseContext.setHideModals(DEFAULT_HIDDEN_MODALS);
        croppedImagesHistoryRest.setCroppedImagesHistory(null);
        setImageData(DEFAULT_IMAGE_DATA);
        cropperRef.current = null;
    }


    const value = {
        imageData,
        imageEditorStatus,
        croppedImagesHistory,
        cropperRef,
        handleCopyImage,
        handleChangeImageData,
        handleTriggerCopyImage,
        handleCloseCopyImageModal,
        ...croppedImagesHistoryRest,
    };

    return <ImageCopyContext.Provider value={value}>{children}</ImageCopyContext.Provider>;
}

export default ImageCopyProvider;