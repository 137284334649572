import React from "react";
import { Button, Header } from "semantic-ui-react";

export const SettingsHeading = ({ 
    icon = undefined, 
    heading, linkHeading, 
    onHeadingClick = () => null, 
    subHeading, 
    children = undefined,
    className = ""
}) => (
    <div className={`settingsPageHeading ${className ? className : ""}`}>
        <Header as={icon ? "h2":""}>
            {(icon) && <img src={`/img/icon-${icon}.svg`} alt="" />}
            {heading && heading}
            {
                subHeading && <>
                    <span className="settingsPageSubheading">
                        <Button content={linkHeading} onClick={onHeadingClick} />
                        <img src="/img/icon-chevron-down.svg" alt="" />
                        {subHeading}
                    </span>
                </>
            }

        </Header>
        {(children) && <p className="paragraph">{children}</p>}
    </div>
);
