/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Image } from 'semantic-ui-react';

export const PermissionModal = ({ onLogout, onRediretToHub }) =>
{
    const trans = useTranslation().t;
    return (
        <Modal open size="small" className="welcome-modal">
            <Modal.Header>
                <Image src="/img/manage-m.png" as="span" />
            </Modal.Header>
            <Modal.Content>
                <p className="pModal">{trans("PermissionModal.You_are_not_authorized_to_use_this_produ")}<br />
                {trans("PermissionModal.Please_contact_your_plan_administrator_t")}</p>
            </Modal.Content>

            <Modal.Actions>
                <Button color="orange" size="big" content={trans("PermissionModal.Log_out")} onClick={onLogout} />
                <Button color="orange" size="big" content={trans("PermissionModal.Redirect_to_Hub")} onClick={onRediretToHub} />
                {/* Contact Plan Administrator */}
            </Modal.Actions>
        </Modal>
    );
}