/* eslint-disable react/display-name */
import React, { ReactNode, FC, useEffect } from "react";
import { Tab, Button } from "semantic-ui-react";
import { useSetState } from "ahooks";

import ModalLayout from "./ModalLayout";
import CropImageModal, { CropImageModalText } from "./CropImageModal";
import "./UploadModal.css";

import UploadTab, { UploadTabText } from "./UploadTab";
import WebTab, { WebTabText } from "./WebTab";
import { getBase64FromImageUrl, DEFAULT_MAX_FILE_SIZE_BYTES } from "./utils";
import { useMergedObjects } from "../hooks";

interface UploadImageModalProps {
    trigger?: ReactNode;
    heading: string;
    src?: string;
    description: string;
    aspectRatio: number;
    /** comma-separated list of formats, ex. `"image/x-png,image/png,image/jpeg"`*/
    acceptableImageFileFormats: string;
    /** returns the data url of the image on save */
    onSave: (data: string) => void;
    onOpen: Function;
    onClose: Function;
    /** url that image urls are fetched from to avoid CORS errors.
     * Example `"/api/v3/image/url?link="` would call `/api/v3/image/url?link=https://some.external.page/image.png`
     */
    proxyUrl: string;
    defaultFilename?: string;
    open?: boolean;
    text?: Partial<UploadImageModalText>;
    maxFileSizeBytes?: number;
    responseHeadersRetrievalUrl?: string
}

export interface UploadImageModalText {
    next: string;
    uploadTab: UploadTabText;
    webTab: WebTabText;
    cropModal: CropImageModalText;
}

const DEFAULT_TEXT : UploadImageModalText = {
    next: "Next",
    uploadTab: {
        title: "Upload",
        dragNDrop: "Drag and Drop files here",
        or: "or",
        browse: "Browse",
        filetypeError: "File type is not valid.",
        sizeError: "File exceeds maximum allowed size for upload."
    },
    webTab: {
        title: "Web Address (URL)",
        instruction: "Paste your image URL in the text field below.",
        pasteHere: "Paste link here...",
        sizeError: "File exceeds maximum allowed size for upload.",
        filetypeError: "File type is not valid."
    },
    cropModal: {
        title: "Crop Image",
        cropButton: "Crop Image",
        undo: "Undo",
        save: "Save",
        deleteButton: "Delete",
        deleteHeader: "Delete Image",
        deleteConfirmation: "Are you sure you want to delete this image?",
        newImage: "Upload New Image"
    }
};

const UploadImageModal : FC<UploadImageModalProps> = ({
    trigger,
    heading,
    src,
    description,
    aspectRatio,
    acceptableImageFileFormats,
    defaultFilename = "Image",
    onSave,
    onOpen = () => null,
    onClose = () => null,
    proxyUrl,
    text = {},
    open: openProp = undefined,
    maxFileSizeBytes = DEFAULT_MAX_FILE_SIZE_BYTES,
    responseHeadersRetrievalUrl
}) =>
{
    const mergedText : UploadImageModalText = useMergedObjects(DEFAULT_TEXT, text);
    const [state, setState] = useSetState({
        undoImage: undefined,
        image: undefined,
        fileName: undefined,
        isOpen: false,
        cropImageModalOpen: false,
        loading: false,
        error: false
    });

    const open = () =>
    {
        setState({ isOpen: true, undoImage: undefined, image: undefined, fileName: undefined });
        if (src)
        {
            openCropModal();
        }
        onOpen();
    };
    const close = () =>
    {
        setState({ isOpen: false, cropImageModalOpen: false });
        onClose();
    };

    useEffect(() =>
    {
        if (openProp)
        {
            open();
        }
        else if (openProp === false)
        {
            close();
        }
    }, [openProp]);

    const openCropModal = () => setState({ isOpen: false, cropImageModalOpen: true });
    const closeCropModal = () => setState({ cropImageModalOpen: false, isOpen: true });

    const handleOnChange = (name, value) =>
    {
        if (name === "image")
        {
            let undoImage;

            // This method gets called on initial image upload so we have to check that the undo image is not the same as the new image.
            if (state.image !== value)
            {
                undoImage = state.image;
            }

            setState({ [name]: value, undoImage });
            openCropModal();
        }
        else
        {
            setState({ [name]: value });
        }
    };

    const handleOnCropModelOpen = () =>
    {
        const { image, } = state;

        if (!image && src)
        {
            if (src.indexOf("/") === 0)
            {
                let pathParts = src.split("/");
                let fileName = pathParts[pathParts.length - 1];

                setState({ fileName });

                getBase64FromImageUrl(src, (image) => setState({ image }), null);
            }
            else
            {
                setState({
                    image: src,
                    fileName: defaultFilename
                });
            }
        }
    };

    useEffect(() =>
    {
        if (state.cropImageModalOpen)
        {
            handleOnCropModelOpen();
        }
    }, [state.cropImageModalOpen]);

    const handleUndoImage = () =>
    {
        const { undoImage } = state;

        setState({ image: undoImage, undoImage: undefined });
        openCropModal();
    };

    const handleOnDelete = () =>
    {
        setState({
            image: undefined,
            fileName: undefined,
            isOpen: false,
            cropImageModalOpen: false
        });
        onSave(undefined);
        onClose();
    };

    const { image, undoImage, fileName, isOpen, cropImageModalOpen, loading, error } = state;

    return (
        <>
            <ModalLayout
                modalProps={{
                    open: isOpen,
                    onClose: close,
                    trigger: trigger ? <span onClick={open}>{trigger}</span> : undefined,
                    className: "uploadModal",
                }}
                heading={heading}
                actions={(
                    <Button
                        content={mergedText.next}
                        onClick={!(loading || error)? openCropModal : () => null}
                        disabled={loading || error || !state.image}
                        loading={loading}
                    />
                )}
            >
                <p className="para">{description}</p>
                <Tab
                    className="uploadTab"
                    menu={{ secondary: true, pointing: true }}
                    panes={[
                        {
                            menuItem: mergedText.uploadTab.title,
                            render: () => (
                                <UploadTab
                                    onChange={handleOnChange}
                                    acceptableImageFileFormats={acceptableImageFileFormats}
                                    text={mergedText.uploadTab}
                                    maxFileSizeBytes={maxFileSizeBytes}
                                />
                            )
                        },
                        {
                            menuItem: mergedText.webTab.title,
                            render: () => (
                                <WebTab
                                    onChange={handleOnChange}
                                    setLoading={(value) => setState({ loading: value })}
                                    proxyUrl={proxyUrl}
                                    text={mergedText.webTab}
                                    maxFileSizeBytes={maxFileSizeBytes}
                                    acceptableImageFileFormats={acceptableImageFileFormats}
                                    responseHeadersRetrievalUrl={responseHeadersRetrievalUrl}
                                    setError={(value) => setState({error: value})}
                                />
                            )
                        }
                    ]}
                />
            </ModalLayout>
            <CropImageModal
                trigger={undefined}
                open={cropImageModalOpen}
                onClose={close}
                fileName={fileName}
                image={image}
                canUndo={!!undoImage}
                aspectRatio={aspectRatio}
                onUndo={handleUndoImage}
                onDelete={handleOnDelete}
                onSave={onSave}
                onCropImage={(image) => handleOnChange("image", image)}
                onGoBack={closeCropModal}
                text={mergedText.cropModal}
            />
        </>
    );
};

export default UploadImageModal;
