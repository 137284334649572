import React from "react";
import { Input, Popup } from "semantic-ui-react";

import "./MapOverlaysOpacitySelector.css";
import { useTranslation } from "react-i18next";

const MapOverlaysOpacitySelector = ({ backgroundColor, opacity, onOpacityChange, disabled=false, opacityTextPopup=false }) =>
{
    const trans = useTranslation().t
    const opacityChangeHandler = (event, { value }) =>
    {
        const newOpacityValue = value/100;
        onOpacityChange(newOpacityValue);
    };

    const rangeInputValue = opacity * 100;

    return (
        <>
            <Input 
                className="opacityRange" 
                type="range"
                value={rangeInputValue}
                disabled={disabled} 
                onChange={opacityChangeHandler}/>
                {
                    opacityTextPopup ? <Popup 
                    className="opacityTextPopup"
                    content={`${trans("OpacitySettings.Opacity")} ${opacity * 100}%`}
                    position='top center'
                    trigger={
                        <div>
                            <span className="transparentIcon">
                            <span className="opacityPreview" 
                                style={{ backgroundColor, opacity }}></span>
                            </span>
                        </div>
                    } 
                /> : <>
                    <span className="transparentIcon">
                     <span className="opacityPreview" 
                        style={{ backgroundColor, opacity }}></span>
                    </span>
                    </>
                }
                
           
        </>
    );
};

export default MapOverlaysOpacitySelector;