import * as turf from "@turf/turf";

import { ShapeTypes } from "mapsted.maps/utils/entityTypes";

export const mercatorToWgs84 = (mercatorCoordinates, type) =>
{

    switch (type)
    {
        case (ShapeTypes.LINE_STRING):
            {
                let mercatorLine = turf.lineString(mercatorCoordinates);
                let wgs84Line = turf.toWgs84(mercatorLine);

                return wgs84Line.geometry.coordinates;
            }
        case (ShapeTypes.POINT):
            {
                let mercatorPoint = turf.point(mercatorCoordinates);
                let wgs84Point = turf.toWgs84(mercatorPoint);
                return wgs84Point.geometry.coordinates;
            }
        case (ShapeTypes.POLYGON):
            {
                let mercatorPolygon = turf.polygon(mercatorCoordinates);
                let wgs84Polygon = turf.toWgs84(mercatorPolygon);
                return wgs84Polygon.geometry.coordinates;
            }
    }

};

export const wgs84ToMercator = (wgs84Coordinates, type) =>
{
    switch (type)
    {
        case (ShapeTypes.LINE_STRING):
            {
                let wgs84Line = turf.lineString(wgs84Coordinates);
                let mercatorLine = turf.toMercator(wgs84Line);

                return mercatorLine.geometry.coordinates;
            }
        case (ShapeTypes.POINT):
            {
                let wgs84Point = turf.point(wgs84Coordinates);
                let mercatorPoint = turf.toMercator(wgs84Point);

                return mercatorPoint.geometry.coordinates;
            }
        case (ShapeTypes.POLYGON):
            {
                let wgs84Polygon = turf.polygon(wgs84Coordinates);
                let mercatorPolygon = turf.toMercator(wgs84Polygon);

                return mercatorPolygon.geometry.coordinates;
            }
    }
};

/**
 *
 * @param {Array | turf.point} coord1
 * @param {Array | turf.point} coord2
 * @param {Number } units - units defaults to "millimeters"
 * @returns {Number} distance between two points in units provided
 */
export const getDistance = (coord1, coord2, units = "millimeters") =>
{
    const createTurfPoint = (coord) =>
    {
        return (coord?.geometry)
            ? coord
            : turf.point(coord);
    };

    const point1 = createTurfPoint(coord1);
    const point2 = createTurfPoint(coord2);

    return (turf.distance(point1, point2, { units: units }));
}
