import React, { useContext, useState } from "react";
import { DEFAULT_LANGUAGE_CODE } from "mapsted.maps/utils/map.constants";
import { ButtonIcon } from "../../../common/ButtonIcon";
import { Image, Icon } from "semantic-ui-react";
import { MapOverlaysContext } from "../../../../store/MapOverlaysContext";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import { useTranslation } from "react-i18next";
import "./MapOverlaysList.css";


function MapOverlaysList({ mapOverlays: mapOverlayIds }) 
{
    const { handleDeleteMapOverlay, editClickHandler, recenterMapOnOverlay, highlightLinkedOverlayClickHandler, state } = useContext(MapOverlaysContext);
    const { consumedMapOverlays, mapOverlays } = state;
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false); 
    const [selectedMapOverlayId, setSelectedMapOverlayId] = useState(null);
    const trans = useTranslation().t;

    const handleDeleteMapOverlayClick = async () => 
    {
        try 
        {
            await handleDeleteMapOverlay(selectedMapOverlayId);
        }
        catch (err)
        {
            console.error(err);
        }
        finally 
        {
            setShowDeleteConfirmationModal(false);
            setSelectedMapOverlayId(null);
        }
    };


    function handleEditMapOverlay(mapOverlayId)
    {
        editClickHandler(mapOverlayId);
        recenterMapOnOverlay(mapOverlayId);
    }

    function handleHighlightMapOverlay(mapOverlayId)
    {
        highlightLinkedOverlayClickHandler(mapOverlayId);
        recenterMapOnOverlay(mapOverlayId);
    }

    return (
        <div className="map-overlays-list">
            {showDeleteConfirmationModal && selectedMapOverlayId && (
                <DeleteConfirmationModal
                    mapOverlay={
                        mapOverlays.find(
                            (mapOverlay) => mapOverlay._id === selectedMapOverlayId
                        ) || {}
                    }
                    onDeleteClick={handleDeleteMapOverlayClick}
                    deleteModalOpen={showDeleteConfirmationModal}
                    onClose={() => 
                    {
                        setShowDeleteConfirmationModal(false);
                        setSelectedMapOverlayId(null);
                    }}
                    trans={trans}
                    showTrigger={false}
                />
            )}

            {mapOverlayIds.map((mapOverlayId) => 
            {
                const mapOverlay = mapOverlays.find(
                    (mapOverlay) => mapOverlay._id === mapOverlayId
                );
                if (!mapOverlay) return <></>;

                return (
                    <>
                        <div className="map-overlay" key={mapOverlay._id}>
                            <div className="map-overlay-info">
                                <Image as="span" src={"/img/maintenance-style5.svg"} />
                                <div className="map-overlay-name">
                                    {mapOverlay.name[DEFAULT_LANGUAGE_CODE]}
                                </div>
                            </div>
                            <div className="map-overlay-action-bx">
                                <Icon
                                    onClick={() => handleHighlightMapOverlay(mapOverlay._id)}
                                    name="find"
                                    className="map-overlay-highlight-icon"
                                />
                                <ButtonIcon
                                    disabled={consumedMapOverlays?.includes(mapOverlay._id)}
                                    icon="edit"
                                    onClick={() => handleEditMapOverlay(mapOverlay._id)}
                                ></ButtonIcon>
                                <ButtonIcon
                                    disabled={consumedMapOverlays?.includes(mapOverlay._id)}
                                    icon="delete"
                                    onClick={() => 
                                    {
                                        setSelectedMapOverlayId(mapOverlay._id);
                                        setShowDeleteConfirmationModal(true);
                                    }}
                                />
                            </div>
                        </div>
                    </>
                );
            })}
        </div>
    );
}

export default MapOverlaysList;
