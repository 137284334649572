import React from "react";
import { Button } from "semantic-ui-react";
import { DEFAULT_LANGUAGE_CODE } from "../../../_constants/constants";
import { ModalBox } from "../../common/ModalBox";
import { useTranslation } from "react-i18next";

export const DeleteKioskConfirmActionModal = ({ kiosk, onDelete, ...rest }) => 
{
    const trans = useTranslation().t;
    return (
        <ModalBox
            className="confirmActionModal deleteKioskModal"
            header={trans("Cancel_Confirmation_Modal.Confirm_Action")}
            actions={<Button color="orange" floated="right" content={trans("Cancel_Confirmation_Modal.Delete")} onClick={onDelete} />}
            {...rest}
        >
            <p className="p-modal">
                {trans("Cancel_Confirmation_Modal.Confirm_Delete", { name: kiosk?.entity?.entityLabel?.longName?.[DEFAULT_LANGUAGE_CODE] })}
            </p>
        </ModalBox>
    );
};