import React, { FC } from "react";

import "./ErrorLabel.css";

interface ErrorLabelProps {
    label : string,
    gap?: boolean,
    small?: boolean,
    content?: string,
    multiple?: boolean
}

export const ErrorLabel: FC<ErrorLabelProps> = ({label, gap, small, content, multiple, children}) =>
{
    if (content)
    {
        return ( <ErrorLabelWithContent multiple={multiple} label={label} gap={gap} small={small} content={content} /> )
    }
    else
    {
        return ( <ErrorLabelWithoutContent multiple={multiple} label={label} gap={gap} small={small} >{children}</ErrorLabelWithoutContent> )
    }
}


const ErrorLabelWithoutContent = ({label, gap, small, children, multiple}) =>
{
    return (        
        <div className={`error-box${gap ? " gap" :""}${small ? " error-box-small":""}${multiple ? " isMultiple":""}`}> 
            <div className="header">Error:</div>           
            {children}{label }      
        </div>
    );
}

const ErrorLabelWithContent = ({label, gap, small, content, multiple }) =>
{
    return (
        <div className={`error-box ${gap ? " gap" :""}${small ? " error-box-small": "error-box-large"}${multiple ? " isMultiple":""}`}>            
            <div className="header">Error: {label}</div>
            <p>
                {content}
            </p>
        </div>
    )
}
