import { Input, Button } from "semantic-ui-react";
import React from "react";
import styles from "./ImageWebAddressBar.module.css";
import { useState } from "react";
import { useEffect } from "react";
import { useFocus } from "../../../../hooks/useFocus";
import { useTranslation } from "react-i18next";
import { IMAGE_ERROR_TYPES } from "../../../../_constants/constants";

function ImageWebAddressBar({
    acceptableImageFileFormats,
    handleErrorShown,
    maxFileSize,
    handleWebAddressUpload,
    getBase64FromImageUrl,
}) 
{
    const [inputAddressValue, setInputAddressValue] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const inputRef = useFocus();
    const { t } = useTranslation();

    async function checkIfImageLinkIsValid(url) 
    {
        try 
        {
            setIsLoading(true);
            const response =  await fetch("/api/internal/public/images/responseHeaders?link=" + url.trim());
            if (!response.ok)
            {
                throw new Error(IMAGE_ERROR_TYPES.URL_READ);
            }

            const { headers } = await response.json();

            if (!headers)
            {
                throw new Error(IMAGE_ERROR_TYPES.URL_READ);
            }
            else if (!acceptableImageFileFormats.includes(headers?.["content-type"]))
            {
                throw new Error(IMAGE_ERROR_TYPES.INVALID);
            }
            else if (headers?.["content-length"] > maxFileSize)
            {
                throw new Error(IMAGE_ERROR_TYPES.FILE_SIZE_EXCEEDED);
            }
            else 
            {
                const fileName = url.split("/").pop().split("#")[0].split("?")[0];
                const dataUrl = await getBase64FromImageUrl({ url, imageType: headers["content-type"] });
                handleWebAddressUpload({
                    name: fileName,
                    fileName,
                    type: headers["content-type"],
                    size: headers["content-length"],
                    imageUrl: dataUrl,
                });
                setInputAddressValue("");
            }
        }
        catch (err)
        {
            console.error(err);
            let errMessage = err?.message;
            if (!Object.values(IMAGE_ERROR_TYPES).includes(errMessage))
            {
                errMessage = IMAGE_ERROR_TYPES.URL_READ;
            }
            handleErrorShown(errMessage);
        }
        finally 
        {
            setIsLoading(false);
        }
    }

    useEffect(() => 
    {
        if (isLoading && inputAddressValue) 
        {
            checkIfImageLinkIsValid(inputAddressValue);
        }
    }, [isLoading, inputAddressValue]);

    function handleChange(e) 
    {
        handleErrorShown("");
        setInputAddressValue(e.target.value);
        if (!e.target.value) 
        {
            setIsLoading(false);
            return;
        }
        setIsLoading(true);
    }

    return (
        <div className={styles.container}>
            <Input
                ref={inputRef}
                placeholder={t("UploadImageModal.Paste_link_here___")}
                className={`${styles.input_box} upload-url`}
                fluid
                name="url"
                loading={isLoading}
                value={inputAddressValue}
                focus
                onChange={handleChange}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="none"
                spellCheck={true}
            />
            {/* <Button disabled loading={isLoading} className={styles.upload_btn}>Upload</Button> */}
        </div>
    );
}

export default ImageWebAddressBar;
