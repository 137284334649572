
// This is deprecated as we are fetching language list using public api
// export const LANGUAGES = {
//     en: { flag: "uk", title: "English", value: "en" },
//     it: { flag: "it", title: "Italiana", value: "it" },
//     pt: { flag: "br", title: "Português (Brasil)", value: "pt" },
//     fr: { flag: "fr", title: "Français", value: "fr" },
//     nl: { flag: "nl", title: "Dutch", value: "nl" },
//     ja: { flag: "jp", title: "日本語", value: "ja" },
//     es: { flag: "es", title: "Español", value: "es" },
//     ru: { flag: "ru", title: "русский", value: "ru" },
//     uk: { flag: "ua", title: "Українська", value: "uk" },
//     ar: { flag: "sa", title: "العربية", value: "ar" },
//     hmn: { flag: "hmong", title: "Hmong", value: "hmn", customFlag: true }, 
//     // he: { flag: "ivrit", title: "עברית", value: "he" }
// };

import { DEFAULT_LANGUAGE_CODE } from "mapsted.maps/utils/map.constants";

export const GLOBAL_DROPDOWN_LANG_KEYS = ["en", "fr", "ja", "es", "hi", "kn", "ta", "en-gb", "mi"];

// if (process.env.NODE_ENV !== "production" && process.env.NODE_ENV !== "prod")
// {
//     LANGUAGES["gg"] = { flag: "bm", title: "Gobbledygook", value: "gg" };
// }

// export const LANGUAGE_OPTIONS = Object.values(LANGUAGES).map(({ value, title }) => ({ text: title, value, key: value }));

/**
 * @param obj {{}}
 * @param fieldsToCheck {string[]}
 * @returns {any[]}
 */
function getLanguages(obj, fieldsToCheck)
{
    const languages = new Set();
    fieldsToCheck.forEach((field) =>
    {
        const value = obj?.[field];
        if (typeof value === "object" && !Array.isArray(value) && value != null)
        {
            Object.entries(value).forEach(([language, value]) => value && languages.add(language));
        }
    });

    const langArray = Array.from(languages);

    const enIndex = langArray.findIndex((lang) => lang === DEFAULT_LANGUAGE_CODE);

    // move en lang to the first position
    if (enIndex !== -1 && enIndex !== 0)
    {
        langArray.splice(0, 0, langArray.splice(enIndex, 1)[0]);
    }

    return langArray;
}

export const getBuildingLanguages = (building) => getLanguages(building, ["longName", "shortName", "description", "website", "keywords", "coverImages", "iconImage", "lightIcon", "darkIcon", "mobileSettings"]);

export const getEntityLabelLanguages = (entityLabel) => getLanguages(entityLabel, ["longName", "shortName", "description", "website", "keywords", "coverImages", "iconImage", "lightIcon", "darkIcon"]);
