import classNames from "classnames";
import React, { memo } from "react";
import { Button } from "semantic-ui-react";

export const ValidationMessage = memo(({ message, date }) => <div className="errorbox-content-section">
    <div className="errorbox-message">
        {message}
        <strong>{date}</strong>
    </div>
</div>);

ValidationMessage.displayName = "ValidationMessage";



export const ValidationBoxHeader = memo(({ title, icon, onClose }) => (
    <div className="errorbox-heading">
        <h4 className="header">
            <img src={`/img/editor/icon-${icon}.svg`} />
            {title}
        </h4>
        <Button className="closeBtn" onClick={onClose} />
    </div>
));

ValidationBoxHeader.displayName = "ValidationBoxHeader";


export const ValidationBoxWrap = memo(({ children, className }) => (
    <div className={classNames("errorbox", className)}>
        {children}
    </div>
));
ValidationBoxWrap.displayName = "ValidationBoxWrap";


export const ValidationContentBox = memo(({ children }) => (
    <div className="errorbox-content">
        {children}
    </div>
));
ValidationContentBox.displayName = "ValidationContentBox";


export const ValidationLevel1Wrap = memo(({ children }) => (
    <div className="errorbox-level-col1">
        {children}
    </div>
));
ValidationLevel1Wrap.displayName = "ValidationLevel1Wrap";


export const ValidationLevelTwoWrap = memo(({ children }) => (
    <div className="errorbox-content-level2">
        {children}
    </div>
));
ValidationLevelTwoWrap.displayName = "ValidationLevelTwoWrap";


export const ValidationLevelThreeOrAnyWrap = memo(({ children }) => (
    <div className="errorbox-content-section">
        {children}
    </div>
));

ValidationLevelThreeOrAnyWrap.displayName = "ValidationLevelThreeOrAnyWrap";







export const ErrorBox = () => (
    <div className="errorbox">

        <div className="errorbox-content">
            <div className="errorbox-level-col1">
                <ErrorBoxItem active status="icon-normal" content="Mapsted Corp Building Validation Error (2)" />
                <ErrorBoxItem status="icon-blocked" content="Candian test Building Validation Error" />
                <ErrorBoxItem status="icon-high" content="Candian test Building Validation Error" />
                <ErrorBoxItem status="icon-very-high" content="Mapsted Corp Building Validation Error (2)" />
                <ErrorBoxItem status="icon-low" content="Candian test Building Validation Error" />
                <ErrorBoxItem status="icon-very-low" content="Candian test Building Validation Error" />
            </div>

            <div className="errorbox-content-level2">
                <ErrorBoxItem active content="Transition Default ID Error" />
                <ErrorBoxItem content="Transition Unknown Type Error" />
            </div>

            <div className="errorbox-content-section">
                <ErrorBoxItem active content="Transition Default ID Error" />
                <ErrorBoxItem content="Transition Unknown Type Error" />
            </div>




        </div>

    </div>
);

export const ErrorBoxItem = ({ icon, content, ...rest }) => (
    <Button className="buttonErrorboxItem" {...rest}>
        {icon && <span className={`statusIcon${icon ? " icon-" + icon : ""}`}><img src={`/img/editor/icon-${icon}.svg`} alt="" /></span>}
        <p>{content}</p>
    </Button>
);

export const ErrorBoxFinalMessage = ({ onMessageClick, info }) => <div className="errorbox-message"
    onClick={(e) => onMessageClick(e, info)}>
    {info.message + " "}
    <strong>{info.createdFormattedDate}</strong>
</div>;
