import React, { useContext, useState, useRef, useEffect } from "react";
import { Header, Icon, Popup } from "semantic-ui-react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { MapOverlaysContext } from "../../../../store/MapOverlaysContext";
import MapOverlaysCancelConfirmationModal from "../MapOverlaysCancelConfirmationModal";
import { TextGroup } from "../../../common/TextGroup";
import { Button, Input } from "semantic-ui-react";
import { CheckboxGroup } from "../../../common/CheckboxGroup";
import { ButtonIcon } from "../../../common/ButtonIcon";
import
{
    CACHED_MAPOVERLAY_LOCAL_STORAGE_KEY,
    DEFAULT_LANGUAGE_CODE,
    DEFAULT_VERTICES,
    MAP_OVERLAYS_MODES,
    REDIRECT_QUERY_KEY,
    REDIRECT_QUERY_MAINTENANCE_VALUE,
} from "../../../../_constants/constants";
import { ErrorLabel } from "../../../elements/ErrorLabel";
import { DividerLine } from "../../../common/DividerLine";
import ZoomLevelSettings from "./ZoomLevelSettings";
import DefaultOpacitySettings from "./DefaultOpacitySettings";
import MapOverlayCreationError from "./MapOverlayCreationError";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../LanguageSelector";
import { getMapOverlayLangs } from "../utils/mapOverlayUtils";
import { MULTI_LANG_MAP_OVERLAY_TEXT_FIELDS, MULTI_LANG_ZOOM_LEVEL_TEXT_FIELDS } from "../utils/fileProcessing";
import BrandingContext from "../../../../store/BrandingContext";
import { createEntityGeometry } from "mapsted.maps/mapFunctions/plotting";
import EntityList from "./entityList/EntityList";
import { useHistory } from "react-router";
import { getIntersectingEntities, getOverlayTemplatesDropdownOptions } from "../../../../_utils/mapOverlayUtils";
import { circular } from "ol/geom/Polygon";
import CopyButton from "../../../common/CopyButton";

import "./MapOverlaysSidebars.css";
import { DropdownForm } from "../../../common/DropdownForm";

const POINT_ENTITY_RADIUS = 5;

const CreateMapOverlaysSideBar = () => 
{
    const mapOverlayCtx = useContext(MapOverlaysContext);
    const brandingCtx = useContext(BrandingContext);
    
    const history = useHistory();

    const {
        state: ctxState,
        cancelCreationHandler,
        updateActiveOverlay,
        updateEnableDynamicOverlayFlag,
        updateDynamicOverlaySettings,
        updateMapSharingMode,
        previewClickHandler,
        setState,
        overlayTemplates,
        selectedOverlayTemplateId,
        saveDraftHandler,
        validateActiveMapOverlay,
        updateMode,
        dynamicMapLayerCreationState,
        checkIfModifiedInCreationMode,
        addNewZoomLevel,
        removeZoomLevel,
        initiateBulkCreation,
        updateDisplayLang,
        higlightOverlayClickHandler,
        overlayShapeToHighlight,
        setEntityShapeToHighlight,
        handleOverlayTemplateSelection,
        confirmationDoneHandler
    } = mapOverlayCtx;

    const {
        activeOverlay: overlay,
        mode,
        validationErrors,
        mapSharingMode,
        activeOverlayModified,
        displayLang,
        allowedLangs,
    } = ctxState;

    const trans = useTranslation().t;

    const scrollbarRef = useRef(null);


    useEffect(() =>
    {
        // when mode changes clear error message
        if (!mode)
        {
            setErrorMessage("");
        }
    }, [mode]);

    const [errorMessage, setErrorMessage] = useState("");

    const contextRef = useRef();


    useEffect(() => 
    {
        let timer;
        if (errorMessage) 
        {
            timer = setTimeout(() => 
            {
                setErrorMessage("");
            }, 4000);
        }

        return () => clearTimeout(timer);
    }, [errorMessage]);

    useEffect(() => 
    {
        if (validationErrors.length && scrollbarRef.current)
        {
            scrollbarRef.current.scrollToBottom();
        }
    }, [validationErrors]);

    const updateOverlay = (key, value) =>
    {
        const updatedOverlay = { ...overlay };
        updatedOverlay[key] = value;
        updateActiveOverlay(updatedOverlay);
        setState({ activeOverlayModified: true });
    };

    const addLangField = (lang) =>
    {
        const updatedOverlay = { ...overlay };

        // add an empty string to lang translation of map overlay name
        updatedOverlay.name = {
            ...updatedOverlay.name,
            [lang]: ""
        };

        updateActiveOverlay(updatedOverlay);
        setState({ activeOverlayModified: true });
    };

    const deleteLangFields = (lang) =>
    {
        const updatedOverlay = { ...overlay };

        // delete all lang enties in text field objects of map overlay
        MULTI_LANG_MAP_OVERLAY_TEXT_FIELDS.forEach((textField) =>
        {
            delete updatedOverlay[textField][lang];
        });

        // if dynamicOverlaySettings is enabled delete all lang entries in text field objects of zoom levels in map overlay
        if (overlay.dynamicOverlaySettings.enabled)
        {
            overlay.dynamicOverlaySettings.zoomLevels.forEach((zoomLevel) =>
            {
                MULTI_LANG_ZOOM_LEVEL_TEXT_FIELDS.forEach((textField) =>
                {
                    delete zoomLevel[textField][lang];
                });
            });
        }

        updateActiveOverlay(updatedOverlay);
        setState({ activeOverlayModified: true });
    };

    const prependDefault = (fieldName) => "default" + fieldName.charAt(0).toUpperCase() + fieldName.slice(1);

    const saveClickHandler = async () =>
    {
        const { success: validationSuccess } = validateActiveMapOverlay(trans);
        if (validationSuccess)
        {
            const { success } = await saveDraftHandler();
            if (success)
            {
                confirmationDoneHandler();
            }
            else
            {
                setErrorMessage(trans("CreateMapOverlaysSideBar.Error_Saving"));
            }
        }
        else
        {
            setErrorMessage(trans("CreateMapOverlaysSideBar.Validation_Failed"));
        }
    };

    const getSaveButtonDisableStatus = () => 
    {
        let disabledSaveButton = false;

        // disable save button if in create mode and overlay name is not provided and if no polygon is drawn.
        if (ctxState.mode === MAP_OVERLAYS_MODES.CREATE_OVERLAY)
        {
            disabledSaveButton = !(overlay.name[DEFAULT_LANGUAGE_CODE] && overlay.vectorLayer);
        }
        // disable save button if in edit mode and no changes are made (along with same conditions as create) and if no polygon is drawn.
        else if (ctxState.mode === MAP_OVERLAYS_MODES.EDIT_OVERLAY)
        {
            disabledSaveButton =
                !activeOverlayModified
                || !(overlay.name[DEFAULT_LANGUAGE_CODE] && overlay.vectorLayer);
        }

        return disabledSaveButton;
    };

    if (
        mode !== MAP_OVERLAYS_MODES.CREATE_OVERLAY
        && mode !== MAP_OVERLAYS_MODES.EDIT_OVERLAY
    ) 
    {
        return null;
    }

    const tabInfoType = () => 
    {
        if (mode === MAP_OVERLAYS_MODES.CREATE_OVERLAY) 
        {
            return trans("CreateMapOverlaysSideBar.Creation_Summary");
        }
        else if (mode === MAP_OVERLAYS_MODES.EDIT_OVERLAY) 
        {
            return trans("CreateMapOverlaysSideBar.Edit_Summary");
        }
    };

    const validateZoomLevels = (zoomLevels) => 
    {
        let isValid = true;

        for (let i = 0; i < zoomLevels.length; i++) 
        {
            if (!zoomLevels[i].value) 
            {
                isValid = false;
                break;
            }
        }

        return isValid;
    };

    function toHexConvert(str) 
    {
        var ctx = document.createElement("canvas").getContext("2d");
        ctx.fillStyle = str;
        return ctx.fillStyle;
    }

    function checkAndConvertToHex(color) 
    {
        return color && color.charAt(0) === "#" ? color : toHexConvert(color);
    }

    const disablePreviewButton =
        (overlay.dynamicOverlaySettings?.enabled
            && !validateZoomLevels(overlay.dynamicOverlaySettings.zoomLevels))
        || !overlay.vectorLayer;

    const enableCancelConfirmationModal =
        checkIfModifiedInCreationMode()
        || (mode === MAP_OVERLAYS_MODES.EDIT_OVERLAY && activeOverlayModified);

    const dynamicOverlaySettings = overlay.dynamicOverlaySettings;

    const displayLangArray = getMapOverlayLangs(overlay);


    const checkIfSameShape = (shape1, shape2) =>
    {
        let isSame = true;

        if (shape1.type === shape2.type && shape1.coordinates[0].length === shape2.coordinates[0].length)
        {
            const coordinates1 = shape1.coordinates[0];
            const coordinates2 = shape2.coordinates[0];

            for (let i=0; i < coordinates1.length; i++)
            {
                const coordPoints1 = coordinates1[i];
                const coordPoints2 =  coordinates2[i];

                if (!(coordPoints1[0].toFixed(5) === coordPoints2[0].toFixed(5) 
                    && coordPoints1[1].toFixed(5) === coordPoints2[1].toFixed(5)))
                {
                    isSame = false;
                    break;
                }
            }
        }
        else
        {
            isSame = false;
        }

        return isSame;
    };

    const getIntersectedEntities = () =>
    {
        const entities = brandingCtx?.state?.mapData?.entities || {};

        let filterCondition = {
            refType: "Property",
            ref: brandingCtx?.state?.propertyId
        };

        if (brandingCtx?.state?.floorId)
        {
            filterCondition = {
                refType: "Floor",
                ref: brandingCtx.state.floorId
            };
        }

        let intersectedEntities = [];

        if (overlay?.shape)
        {
            const filteredEntities = Object.values(entities).filter((entity) => entity.refType === filterCondition.refType 
            && entity.ref === filterCondition.ref
            && entity.shape); 

            const overlayGeometry = createEntityGeometry(overlay.shape);

            intersectedEntities = getIntersectingEntities(overlayGeometry, filteredEntities);

            // check if any entity's shape matches with overlay's shape which means that overlay was created using select entity tool on that one entity
            // this is probably a temporary solution as a workaround because getIntersectingEntities returns entities with intersecting vertices too
            // so this is to avoid getting unnecessary entites which happen to be next to selected entity in intersecting entities list
            const entityWithOverlayShape = intersectedEntities.find((entity) => checkIfSameShape({ ...entity.shape }, { ...overlay.shape }));
            if (entityWithOverlayShape)
            {
                intersectedEntities = [entityWithOverlayShape];
            }

            // filter out vacant entities
            intersectedEntities = intersectedEntities.filter((entity) => !!entity.entityLabel);
        }

        return intersectedEntities;
    };
    
    const handleEntityEdit = (entityId) =>
    {
        // cache the current active overlay state
        const cachedOverlay = { ...overlay };
        delete cachedOverlay.vectorLayer;
        localStorage.setItem(CACHED_MAPOVERLAY_LOCAL_STORAGE_KEY ,JSON.stringify(cachedOverlay));

        history.push(`/branding?${REDIRECT_QUERY_KEY}=${REDIRECT_QUERY_MAINTENANCE_VALUE}&entityId=${entityId}`);
    };

    const handleEntityFocus = (entityId) =>
    {
        const { entities } = brandingCtx.state.mapData;

        const entity = Object.values(entities).find((entity) => entity._id === entityId);

        if (entity && entity.shape)
        {
            let entityShape = { ...entity.shape };

            // if entity shape is of Point type (incase of POI entities)
            // create a small circle polygon shape with point as center 
            if (entityShape.type === "Point")
            {
                const polygon = circular(entityShape.coordinates, POINT_ENTITY_RADIUS, DEFAULT_VERTICES);
                entityShape = {
                    type: polygon.getType(),
                    coordinates: polygon.getCoordinates()
                };
            }

            setEntityShapeToHighlight(entityShape);
        }
    };

    const intersectedEntities = getIntersectedEntities();

    return (
        <div className="sidebarPortal mapOverlaysSidebar">
            <Scrollbars
                ref={scrollbarRef}
                autoHeight
                autoHeightMin="calc(100vh - 170px)"
                autoHeightMax={"calc(100vh - 170px)"}
            >
                <div className="alignerSidebar">
                    <Header as="h2" className="mapOverlaySidebarHeader">
                        {trans("CreateMapOverlaysSideBar.Heading")}
                        {!enableCancelConfirmationModal ? (
                            <ButtonIcon
                                icon="cross"
                                className="cancelButton"
                                onClick={cancelCreationHandler}
                            />
                        ) : (
                            <MapOverlaysCancelConfirmationModal
                                onCancelClick={cancelCreationHandler}
                                isCreate={mode === MAP_OVERLAYS_MODES.CREATE_OVERLAY}
                            />
                        )}
                    </Header>

                    <div className="sidebarSection">
                        <p className="mapOverlaySidebarTabInfo">{tabInfoType()}</p>
                    </div>

                    <div className="sidebarSection spaceBottom">
                        <ButtonIcon
                            icon="plus-filled"
                            active={mode === MAP_OVERLAYS_MODES.BULK_CREATION}
                            content={trans("CreateMapOverlaysSideBar.Create_Multiple_Overlays")}
                            disabled={mode === MAP_OVERLAYS_MODES.CONFIRMATION_SIDEBAR || mode === MAP_OVERLAYS_MODES.EDIT_OVERLAY || activeOverlayModified}
                            onClick={() => 
                            {
                                if (mode !== MAP_OVERLAYS_MODES.BULK_CREATION) 
                                {
                                    initiateBulkCreation();
                                }
                            }}
                        />
                    </div>

                    <div className="sidebarSection">
                        <TextGroup
                            className="tgOverlayConfig tabHeading"
                            heading={
                                <p style={{ fontWeight: "500" }}>
                                    {trans("CreateMapOverlaysSideBar.Configuration")}{" "}
                                    <span style={{ fontWeight: "400" }}>({trans("CreateMapOverlaysSideBar.Internal")})</span>
                                </p>
                            }
                        />

                        <TextGroup
                            title={trans("LanguageSelector.Preview_in_Language")}
                            className="languageSelector"
                        >
                            {/* preview language dropdown here */}
                            <LanguageSelector
                                activeLang={displayLang}
                                defaultDisplayedLangs={displayLangArray}
                                setActiveLang={(lang) => updateDisplayLang(lang)}
                                allowedLangs={allowedLangs}
                                onLangAdd={(lang) => addLangField(lang)}
                                onLangDelete={(lang) => deleteLangFields(lang)}
                            />
                        </TextGroup>

                        <TextGroup
                            title={trans("CreateMapOverlaysSideBar.Configure_Name")}
                            className="tgOverlayConfig"
                        >
                            <Input
                                value={overlay.name[displayLang] || ""}
                                placeholder={trans("CreateMapOverlaysSideBar.Enter_Configuration_Name")}
                                onChange={(event, { value }) => updateOverlay("name", {
                                    ...overlay.name,
                                    [displayLang]: value,
                                })}
                            />
                        </TextGroup>
                        <TextGroup
                            className="tgOverlayConfig"
                            title={trans("CreateMapOverlaysSideBar.Select_Overlay_Templates")}
                        >
                            <DropdownForm
                                className={"dropdown overlay-template-dropdown"}
                                placeholder={trans("CreateMapOverlaysSideBar.Select_Overlay_Templates")}
                                options={getOverlayTemplatesDropdownOptions(overlayTemplates)}
                                onChange={(e, { value }) => handleOverlayTemplateSelection(value)}
                                name="overlay-templates"
                                value={selectedOverlayTemplateId}
                                selectOnBlur={false}
                                showCustomIcon={false}
                                clearable
                            />
                        </TextGroup>
                    </div>

                    <div className="sidebarSection">
                        { mode === MAP_OVERLAYS_MODES.EDIT_OVERLAY && overlay._id
                        && <>
                            <p className="mapOverlayIdDisplay">Id: {overlay._id}</p>
                            <CopyButton 
                                text={overlay._id}
                                popupText={trans("CreateMapOverlaysSideBar.Copy_Entity_Id_Popup_Text")}
                                btnClassname="entityIdCopyBtn"
                                position={"top right"}
                            />
                        </> }

                        <TextGroup
                            title={trans("CreateMapOverlaysSideBar.MapOverlay_Text_Label")}                            className="tgOverlayConfig"
                        >
                            <Input
                                value={overlay.toolTipText[displayLang] || ""}
                                placeholder={trans("CreateMapOverlaysSideBar.Enter_Text_Label")}
                                onChange={(event, { value }) => updateOverlay("toolTipText", {
                                    ...overlay.toolTipText,
                                    [displayLang]: value,
                                })}
                            />
                        </TextGroup>

                        {overlay.dynamicOverlaySettings.enabled && (
                            <>
                                <TextGroup
                                    flexible
                                    className="tgKiosk"
                                    title={trans("CreateMapOverlaysSideBar.MapOverlay_Fill_Color")}
                                >
                                    <Input
                                        disabled= {!!selectedOverlayTemplateId}
                                        className="colorPicker"
                                        type="color"
                                        value={checkAndConvertToHex(overlay.color)}
                                        onChange={(event, { value }) => updateOverlay("color", value)
                                        }
                                    />
                                </TextGroup>
                                   
                                <TextGroup
                                    flexible
                                    className="tgKiosk"
                                    title={trans("CreateMapOverlaysSideBar.MapOverlay_Border_Color")}
                                >
                                    <Input
                                        disabled={!!selectedOverlayTemplateId}
                                        className="colorPicker"
                                        type="color"
                                        value={checkAndConvertToHex(overlay.lineColor)}
                                        onChange={(event, { value }) => updateOverlay("lineColor", value)
                                        }
                                    />
                                </TextGroup>
                                   
                                <TextGroup
                                    flexible
                                    className="tgKiosk"
                                    title={trans("CreateMapOverlaysSideBar.MapOverlay_Text_Color")}
                                >
                                    <Input
                                        disabled={!!selectedOverlayTemplateId}
                                        className="colorPicker"
                                        type="color"
                                        value={checkAndConvertToHex(overlay.textColor)}
                                        onChange={(event, { value }) => updateOverlay("textColor", value)
                                        }
                                    />
                                </TextGroup>
                            </>
                        )}

                        {!overlay.dynamicOverlaySettings.enabled && (
                            <DefaultOpacitySettings
                                color={overlay.color}
                                lineColor={overlay.lineColor}
                                fillOpacity={overlay.defaultFillOpacity}
                                borderFillOpacity={overlay.defaultBorderFillOpacity}
                                textColor={overlay.textColor}
                                textOpactiy={overlay.defaultTextOpacity}
                                updateOverlay={updateOverlay}
                                updateOpacitySettings={(fieldName, opacity) => updateOverlay(prependDefault(fieldName), opacity)}
                                displayLang={displayLang}
                                selectedOverlayTemplateId = {selectedOverlayTemplateId}
                            />
                        )}
                    </div>

                    <div className="sidebarSection">
                        {!dynamicMapLayerCreationState.enabled && (
                            <CheckboxGroup
                                className="dynamicSelectBox"
                                label={trans("CreateMapOverlaysSideBar.Dynamic_Zoom_Styling")}
                                disabled={!!selectedOverlayTemplateId}
                                checked={overlay.dynamicOverlaySettings.enabled}
                                onChange={(event, { checked }) => updateEnableDynamicOverlayFlag(checked)
                                }
                            />
                        )}

                        {overlay.dynamicOverlaySettings.enabled && (
                            <>
                                <TextGroup className="tgOverlayConfig">
                                    <p className="mapOverlaySidebarZoomSettingInfo">
                                        {trans("CreateMapOverlaysSideBar.Opacity_Slider_Instructions")}
                                    </p>
                                </TextGroup>

                                <ZoomLevelSettings
                                    zoomId={dynamicOverlaySettings.zoomLevels[0].id}
                                    color={overlay.color}
                                    lineColor={overlay.lineColor}
                                    textColor={overlay.textColor}
                                    zoomLevelSettingsValue={dynamicOverlaySettings.zoomLevels[0]}
                                    updateZoomLevelSettingsValue={updateDynamicOverlaySettings}
                                    title={trans("CreateMapOverlaysSideBar.Start_Zoom_Level")}
                                    onMapShareModeChange={updateMapSharingMode}
                                    mapSharingMode={mapSharingMode}
                                    displayLang={displayLang}
                                    selectedOverlayTemplateId={selectedOverlayTemplateId}
                                />

                                <DividerLine gap="-10px 0px 16px" />

                                <ButtonIcon
                                    className="addLevelBtn"
                                    icon="plus-filled"
                                    active={true}
                                    disabled={!!selectedOverlayTemplateId}
                                    content={trans("CreateMapOverlaysSideBar.Add_Layer")}
                                    onClick={() => addNewZoomLevel(1)}
                                />

                                {dynamicOverlaySettings.zoomLevels
                                    .filter((zoomLevel, index) => index !== 0 && index !== dynamicOverlaySettings.zoomLevels.length - 1)
                                    .map((zoomLevel, index) => (
                                        <>
                                            <DividerLine gap="19px 0px 16px" />

                                            <ZoomLevelSettings
                                                key={zoomLevel.id}
                                                zoomId={zoomLevel.id}
                                                color={overlay.color}
                                                lineColor={overlay.lineColor}
                                                textColor={overlay.textColor}
                                                zoomLevelSettingsValue={zoomLevel}
                                                updateZoomLevelSettingsValue={
                                                    updateDynamicOverlaySettings
                                                }
                                                title={`${index + 1}. ${trans("CreateMapOverlaysSideBar.Zoom_Level")}`}
                                                onMapShareModeChange={updateMapSharingMode}
                                                mapSharingMode={mapSharingMode}
                                                onRemove={() => removeZoomLevel(zoomLevel.id)}
                                                displayLang={displayLang}
                                                selectedOverlayTemplateId={selectedOverlayTemplateId}
                                            />

                                            <DividerLine gap="13px 0px 16px" />

                                            <ButtonIcon
                                                className="addLevelBtn"
                                                icon="plus-filled"
                                                active={true}
                                                disabled={!!selectedOverlayTemplateId}
                                                content={trans("CreateMapOverlaysSideBar.Add_Layer")}
                                                onClick={() => addNewZoomLevel(index + 2)}
                                            />
                                        </>
                                    ))}

                                <DividerLine gap="13px 0px 20px" />

                                <ZoomLevelSettings
                                    zoomId={
                                        dynamicOverlaySettings.zoomLevels[
                                            dynamicOverlaySettings.zoomLevels.length - 1
                                        ].id
                                    }
                                    color={overlay.color}
                                    lineColor={overlay.lineColor}
                                    textColor={overlay.textColor}
                                    zoomLevelSettingsValue={dynamicOverlaySettings.zoomLevels[dynamicOverlaySettings.zoomLevels.length - 1]}
                                    updateZoomLevelSettingsValue={updateDynamicOverlaySettings}
                                    title={trans("CreateMapOverlaysSideBar.End_Zoom_Level")}
                                    onMapShareModeChange={updateMapSharingMode}
                                    mapSharingMode={mapSharingMode}
                                    displayLang={displayLang}
                                    selectedOverlayTemplateId={selectedOverlayTemplateId}
                                />
                            </>
                        )}
                    </div>
                    {!!(mode === MAP_OVERLAYS_MODES.EDIT_OVERLAY && intersectedEntities?.length)
                    && <div className="sidebarSection">
                        <TextGroup
                            className="tgOverlayConfig tabHeading"
                            heading={
                                <p style={{ fontWeight: "500" }}>
                                    {trans("CreateMapOverlaysSideBar.Intersecting_Entities")}
                                </p>
                            }
                        />
                        <EntityList 
                            entities={intersectedEntities}
                            onEdit={handleEntityEdit}
                            onFocus={handleEntityFocus}
                        />
                    </div>}
                    {!!validationErrors.length && (
                        <div className="sidebarSection sidebarSection-error">
                            <ErrorLabel multiple={Object.keys(validationErrors).length > 0}>
                                {trans("CreateMapOverlaysSideBar.Validation_Error_Header")}
                                <ul>
                                    {validationErrors.map((validationError) => (
                                        <li key={validationError}>{validationError}</li>
                                    ))}
                                </ul>
                            </ErrorLabel>
                        </div>
                    )}
                </div>
            </Scrollbars>

            <div className="mapOverlays_sidebar_actions">
                
                <Popup
                    className="tooltipPopup"
                    inverted
                    position="top center"
                    on="hover"
                    trigger={<Button
                        basic
                        color="orange"
                        className="previewButton"
                        content={<Icon 
                            name="eye"
                            className="mapoverlay-action-icon" 
                        />}
                        disabled={disablePreviewButton}
                        onClick={previewClickHandler}
                    />}
                    content={trans("CreateMapOverlaysSideBar.Preview")} 
                />

                {!!(mode === MAP_OVERLAYS_MODES.EDIT_OVERLAY) 
                    && <Popup
                        className="tooltipPopup"
                        inverted
                        position="top center"
                        on="hover"
                        trigger={<Button
                            basic
                            color="orange"
                            className="previewButton"
                            content={<Icon 
                                name="find"
                                className="mapoverlay-action-icon" 
                            />}
                            disabled={overlayShapeToHighlight || !overlay.vectorLayer}
                            onClick={higlightOverlayClickHandler}
                        />}
                        content={trans("CreateMapOverlaysSideBar.Highlight_Mapoverlay")} 
                    />
                }
                
            </div>

            <div className="saveContext" ref={contextRef}>
                <Button
                    primary
                    className="saveButton mapOverlay"
                    onClick={saveClickHandler}
                    disabled={getSaveButtonDisableStatus()}
                >
                    {trans("CreateMapOverlaysSideBar.Save")}
                </Button>
                <MapOverlayCreationError
                    open={!!errorMessage}
                    contextRef={contextRef}
                    message={errorMessage}
                    onClick={() => setErrorMessage("")}
                />
            </div>
        </div>
    );
};

export default CreateMapOverlaysSideBar;
