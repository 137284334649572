import React, { useContext } from "react";
import { Header, Button, Input, Checkbox } from "semantic-ui-react";
import { InputFormGroup } from "../../elements/InputFormGroup";
import { DEFAULT_LANGUAGE_CODE, KEY_CODES } from "../../../_constants/constants";
import { useTranslation } from "react-i18next";
import { rotateImage } from "mapsted.maps/mapFunctions/features";
import CircularSlider from "@fseehawer/react-circular-slider";
import { MAX_ROTATION, MIN_ROTATION, rotationIsValid } from "../../../_utils/branding.utils";
import BrandingContext from "../../../store/BrandingContext";

export const RotateImagePopup = ({ selectedEntityId, selectedEntityImage, onSaveRotation, onClosePopup, onRotateChange, allowNull, selectedEntity, onOverrideIconRotation }) => 
{
    /** @type {typeof import("../../../_intl/resources.json")["en"]["translation"]["rotate image popup"]} */
    const trans = useTranslation().t("rotate image popup");
    const brandingContext = useContext(BrandingContext);

    const [imageRotation, setImageRotation] = React.useState(0);
    // entityId used to check prev entity Id when selectedEntityId is changed from outside to revert rotation changes.
    const [entityId, setEntityId] = React.useState(undefined);
    const [currentEntity, setCurrentEntity] = React.useState(selectedEntity);
    const [savedImage, setSavedImage] = React.useState(undefined);
    const [initialImageRotation, setInitialImageRotation] = React.useState(0); // Track initial rotation

    const isOpen = !!selectedEntityImage && !!selectedEntityId;

    React.useEffect(() =>
    {
        if (!isOpen)
        {
            return;
        }

        const shortCutHandler = (e) =>
        {
            const keyCode = e.keyCode;

            if (keyCode === KEY_CODES.ESCAPE)
            {
                handleCancelChanges(true);
            }
            else if (keyCode === KEY_CODES.ENTER)
            {
                handleSaveChanges();
            }
        };
        document.addEventListener("keydown", shortCutHandler, false);

        return () => document.removeEventListener("keydown", shortCutHandler, false);
    }, [isOpen]);

    /**
     * We only want to call this when selectedEntityId through props changes
     */
    React.useEffect(() =>
    {
        const loadImg = () =>
        {
            const { imageLayer } = selectedEntityImage;

            let source = imageLayer.getSource();

            let img = new Image();
            img.crossOrigin = "Anonymous";

            img.onload = () => setSavedImage(img);
            img.src = source.url_;
        };

        if (selectedEntityImage)
        {
            const mapData = brandingContext?.state?.mapData;

            let imageRotation = mapData?.entities?.[selectedEntityId]?.imageRotation?.[DEFAULT_LANGUAGE_CODE];
            imageRotation = imageRotation === undefined ? 0 : imageRotation;

            setImageRotation(imageRotation);
            setInitialImageRotation(imageRotation); // Set initial rotation when popup opens
            setCurrentEntity(selectedEntity);
            onRotateChange(selectedEntityImage.imageLayer, imageRotation || 0);
            loadImg();
        }

        // reset?
        if (entityId && entityId !== selectedEntityId)
        {
            const shouldResetSelectedEntity = !selectedEntityId;

            if (shouldResetSelectedEntity)
            {
                setSavedImage(undefined);
            }
        }

        setEntityId(selectedEntityId);

    }, [selectedEntityId, onRotateChange, selectedEntity]);

    const updateImageLayerRotation = (rotation) =>
    {
        const { imageLayer } = selectedEntityImage;

        let source = imageLayer.getSource();
        let canvas = source.image_.image_;

        if (savedImage)
        {
            rotateImage({ source, image: savedImage, canvas, degree: rotation });
        }

        onRotateChange(imageLayer, rotation);
    };

    // Generic function to check if any unsaved changes exist
    const hasUnsavedChanges = () => 
    {
        const rotationChanged = imageRotation !== initialImageRotation;
        let overrideChanged;
        if (currentEntity.overrideAllowIconRotation == null || currentEntity.overrideAllowIconRotation == undefined) overrideChanged = true;
        else overrideChanged = currentEntity.overrideAllowIconRotation !== selectedEntity.overrideAllowIconRotation;
        return rotationChanged || overrideChanged;
    };

    const handleImageRotationChange = (rotation) =>
    {
        console.log("onchange", rotation);
        console.log("check eq", rotation !== imageRotation);
        console.log("valid", rotationIsValid(rotation));
        if (rotation !== imageRotation)
        {
            if (rotationIsValid(rotation))
            {
                setImageRotation(rotation);
                updateImageLayerRotation(rotation || 0);
            }
        }

    };

    const handleInputChange = (rotation) =>
    {
        if (rotation === "")
        {
            rotation = 0;
        }
        handleImageRotationChange(parseInt(rotation));
    };

    const handleSaveChanges = () => 
    {
        let rotationChanged = imageRotation !== initialImageRotation; // Check if rotation has changed
        let overrideChanged = selectedEntity?.overrideAllowIconRotation ? currentEntity?.overrideAllowIconRotation !== selectedEntity?.overrideAllowIconRotation : true; // Check if override has changed

        // Call rotation API only if rotation has changed
        if (rotationChanged) 
        {
            onSaveRotation(imageRotation, savedImage);
        }
    
        // Call override API only if overrideAllowIconRotation has changed
        if (overrideChanged) 
        {
            onOverrideIconRotation(currentEntity);
        }
    };    

    const handleCancelChanges = (isClose) =>
    {
        handleImageRotationChange(initialImageRotation); // Revert to initial rotation
        if (isClose && onClosePopup) 
        {
            onClosePopup();
        }
    };

    if (!isOpen)
    {
        return null;
    }

    return (
        <div className="customPopup customRotate" >
            <Button className="close" onClick={() => handleCancelChanges(true)} />
            <Header>{trans["header"]}</Header>
            {(allowNull) && (
                <Checkbox
                    label={trans["rotate with map"]}
                    onChange={(e, { checked }) => handleImageRotationChange(checked ? null : 0)}
                    checked={imageRotation === null}
                />
            )}
            <Checkbox
                label={trans["Override_Allow_Icon_Rotation"]}
                onChange={(e, { checked }) => 
                {
                    setCurrentEntity({ ...selectedEntity, overrideAllowIconRotation: checked });
                }}
                checked={currentEntity?.overrideAllowIconRotation}
            />
            <InputFormGroup label={trans["angle label"]} className="rotateGroup">
                <CircularSlider
                    min={MIN_ROTATION}
                    max={MAX_ROTATION}
                    knobPosition="right"
                    dataIndex={imageRotation || 0}
                    onChange={(value) => (imageRotation !== null && handleImageRotationChange(value))}
                    hideLabelValue
                    width={34}
                    knobColor="#005a58"
                    progressColorFrom="#eeeeee"
                    progressColorTo="#eeeeee"
                    progressSize={1}
                    trackColor="#eeeeee"
                    trackSize={1}
                    knobSize={25}
                    knobDraggable={imageRotation !== null}
                >
                    <img src="/img/icon-arrow-cms.svg" alt="" />
                </CircularSlider>

                <Input
                    disabled={imageRotation === null}
                    value={imageRotation === null ? "" : imageRotation}
                    type="number"
                    onChange={(e, { value }) => handleInputChange(value)} />
            </InputFormGroup>
            <div className="actionsGroup">
                <Button content={trans["undo"]} onClick={() => handleCancelChanges(false)} />
                <Button
                    primary
                    content={trans["save"]}
                    onClick={handleSaveChanges}
                    // rotation cannot be displayed until the image is loaded
                    // so disable save button until then
                    disabled={!savedImage || !hasUnsavedChanges()} // Disable if no changes were made
                />
            </div>
        </div>
    );
};
