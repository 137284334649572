import React from "react";
import { useTranslation } from "react-i18next";
import { Dropdown, Checkbox } from "semantic-ui-react";

const BEHAVIOURS = {
    FREE: 0,        // any combination is fine
    EXACTLY_ONE: 1,
    AT_LEAST_ONE: 2
};

export const OptionMapDropdown = ({ values, onChange, canPlotImage, behaviour = BEHAVIOURS.FREE }) =>
{
    const trans = useTranslation().t;

    const handleChange = (e, { name, checked }) =>
    {
        if (behaviour === BEHAVIOURS.AT_LEAST_ONE)
        {
            const newValues = { ...values, [name]: checked };
            if (Object.values(newValues).some((i) => i))
            {
                return onChange({ [name]: checked });
            }
        }
        else if (behaviour === BEHAVIOURS.EXACTLY_ONE)
        {
            const newValues = { ...values, [name]: checked };
            if (Object.values(newValues).every((i) => i))
            {
                return onChange({ [name]: checked });
            }
        }
        else        // BEHAVIOUR.FREE
        {
            return onChange({ [name]: checked });
        }
    };

    return (
        <div className="optionMapDropdown">
            <Dropdown text={trans(canPlotImage ? "option map dropdown.text" : "option map dropdown.name_only")} icon="angle down">
                <Dropdown.Menu>
                    <Dropdown.Header content={trans("option map dropdown.header")} />
                    <DropdownItem
                        name="useText"
                        content={trans("option map dropdown.name")}
                        value={values?.useText}
                        onChange={handleChange}
                    />
                    {(canPlotImage) && (
                        <DropdownItem
                            name="useIcon"
                            content={trans("option map dropdown.logo")}
                            value={values?.useIcon}
                            onChange={handleChange}
                        />
                    )}
                </Dropdown.Menu>
            </Dropdown>
        </div>

    );
};
OptionMapDropdown.BEHAVIOURS = BEHAVIOURS;

const DropdownItem = ({ name, value, content, onChange }) => (
    <Dropdown.Item>
        <Checkbox name={name} label={content} checked={value} onChange={onChange} />
    </Dropdown.Item>
);
