import React, { useMemo, useState, useEffect } from "react";
import { Button, Modal } from "semantic-ui-react";
import { useListViewContext } from "../../../store/ListViewContext";
import { DropdownForm } from "../../common/DropdownForm";
import { getDropdownOptions } from "../../../_utils/listView";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import "./ListViewMultiInputEditorModal.css";

function ListViewMultiInputEditorModal() 
{
    const trans = useTranslation().t;
    const { showMultiInputEditor, handleShowMultiInputEditor, gridRef } = useListViewContext();

    const { rowInfo, columnInfo } = useMemo(() => 
    {
        const [column] = gridRef?.current?.api?.getEditingCells() || [];
        return {
            rowInfo: gridRef?.current?.api.getRowNode(column.rowIndex),
            columnInfo: column
        };
    }, [showMultiInputEditor]);

    const { getOptions, options: optionsProp,allowAdditions = true,multiple  = true,...rest } = columnInfo?.column?.colDef?.externalEditing?.dropdownComponentProps || {};
    const [value, setValue] = useState(() =>  columnInfo?.column?.colDef?.valueGetter(rowInfo) || []);

    // eslint-disable-next-line no-nested-ternary
    const options = useMemo(() => optionsProp?.length ? optionsProp:  getOptions ? getOptions(rowInfo) : getDropdownOptions(value), [value, getOptions, optionsProp]);

    function handleDropdownChange(e, { value })
    {

        setValue(value);
    }

    function handleSave ()
    {
        const { updateFn } = columnInfo?.column?.colDef?.externalEditing;
        const data = _.cloneDeep(rowInfo.data);
        updateFn(data, _.isArray(value)? value.filter((val) => val): value, options);
        rowInfo.setData(data);
        gridRef.current.api.stopEditing({
            rowIndex: columnInfo.rowIndex,
            colKey: columnInfo.column.colDef.headerInfo
        });
        handleShowMultiInputEditor();
    }

    function handleClose()
    {
        gridRef.current.api.stopEditing({
            rowIndex: columnInfo.rowIndex,
            colKey: columnInfo.column.colDef.headerInfo
        });
        handleShowMultiInputEditor();
    }

    function getValidationInfo()
    {
        const { validators }=  columnInfo?.column?.colDef?.externalEditing || {};
        if (!validators?.length) return "";

        for (const validatorFn of validators)
        {
            const validationMessage = validatorFn(value);
            return validationMessage  || "";
        }
    }

    const validationMessage = useMemo(() => getValidationInfo(), [value]) ;

    useEffect(() => 
    {
        document.querySelector(".list-view-multi-input-editor-modal .dropdown-multi .search")?.focus();
    }, []); 


    return (
        <Modal
            className="list-view-multi-input-editor-modal"
            open={showMultiInputEditor}
            onClose={handleClose}
        >
            <Modal.Header>{
                `${trans("ListView.Edit")} ${columnInfo?.column?.colDef?.colKey || columnInfo?.column?.colDef?.headerName}`
            }
            <Modal.Actions>
                <Button onClick={handleClose}>{trans("ListView.Cancel")}</Button>
                <Button className="ui orange" onClick={handleSave}>{trans("ListView.Save")}</Button>
            </Modal.Actions>
            </Modal.Header>
            <Modal.Content>
                <DropdownForm
                    fluid
                    selection
                    search
                    icon=""
                    className= {`dropdownCategory dropdown-multi ${validationMessage ? "dropdown-multi--disabled": ""}`}
                    placeholder={`${trans("ListView.Enter")} ${columnInfo?.colDef?.colKey || columnInfo?.column?.colDef?.headerName}`}
                    name={columnInfo?.colDef?.colKey}
                    value={value}
                    options={options}
                    onChange={handleDropdownChange}
                    allowAdditions ={ allowAdditions}
                    multiple={multiple}
                    noResultsMessage={`${!allowAdditions ? options?.length === value?.length ?  trans("ListView.No more result")  :  trans("ListView.No results found")  : `${trans("ListView.Add your")} ${(columnInfo?.colDef?.colKey || columnInfo?.column?.colDef?.headerName)?.toLowerCase()} ${trans("ListView.here")}...`} `}
                    {
                        ...(rest || {})
                    }
                />
                {!!validationMessage && <div className="err-mssg">{trans(validationMessage)}</div>}
            </Modal.Content>
           
        </Modal>
    );
}

export default ListViewMultiInputEditorModal;