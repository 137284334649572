import React, { useContext } from "react";
import { Header, Tab } from "semantic-ui-react";
import { DynamicMapLayersContext } from "../../../../store/DynamicMapLayersContext";
import { DYNAMIC_MAP_LAYERS_MODES } from "../../../../_constants/constants";
import { ButtonIcon } from "../../../common/ButtonIcon";
import DynamicMapLayerItem from "./DynamicMapLayerItem";
import ConfigurationItem from "./ConfigurationItem";

import "./DynamicMapLayerSidebar.css";
import { downloadMultiCreationFile } from "../utils/fileProcessing";
import { TextGroup } from "../../../common/TextGroup";
import Scrollbars from "react-custom-scrollbars-2";
import { useTranslation } from "react-i18next";

const ActivitySidebar = () => 
{
    const dynamicMapLayerCtx = useContext(DynamicMapLayersContext);

    const trans = useTranslation().t;

    const {
        deleteClickHandler,
        updateSelectedDynamicMapLayer,
        multiDeleteHandler,
        cancelHandler,
        state: ctxState
    } = dynamicMapLayerCtx;
    const { selectedDynamicMapLayer, dynamicMapLayers, mapOverlays, navPublicId } = ctxState;

    const selectionHandler = (dynamicMapLayerId, automationRuleId, configurationName) =>
    {
        if (dynamicMapLayerId === selectedDynamicMapLayer.dynamicMapLayerId
            && automationRuleId === selectedDynamicMapLayer.automationRuleId
            && configurationName === selectedDynamicMapLayer.configurationName)
        {
            updateSelectedDynamicMapLayer(undefined, undefined, undefined);
        }
        else
        {
            updateSelectedDynamicMapLayer(dynamicMapLayerId, automationRuleId, configurationName);
        }
    };

    const separateSingleAndMultipleMapLayers = () =>
    {
        let singleMapLayers = [];
        let configurationMap = {};

        dynamicMapLayers.forEach((dynamicMapLayer) =>
        {
            if (!dynamicMapLayer.configurationName)
            {
                singleMapLayers.push(dynamicMapLayer);
            }
            else
            {
                if (configurationMap[dynamicMapLayer.configurationName])
                {
                    configurationMap[dynamicMapLayer.configurationName].push(dynamicMapLayer);
                }
                else
                {
                    configurationMap[dynamicMapLayer.configurationName] = [dynamicMapLayer];
                }
            }
        });

        return { singleMapLayers, configurationMap };
    };

    const downloadHandler = (configurationName) =>
    {
        let selectedDynamicMapLayers = [];
        let selectedMapOverlays = [];

        dynamicMapLayers
            .filter((dynamicMapLayer) => dynamicMapLayer.configurationName === configurationName)
            .forEach((dynamicMapLayer) =>
            {
                selectedDynamicMapLayers.push(dynamicMapLayer);
                selectedMapOverlays.push(mapOverlays.find((mapOverlays) => mapOverlays._id === dynamicMapLayer.mapOverlayId));
            });

        downloadMultiCreationFile(selectedDynamicMapLayers, selectedMapOverlays, navPublicId, `${configurationName}.csv`);
    };

    if (ctxState.mode !== DYNAMIC_MAP_LAYERS_MODES.ACTIVITY_SIDEBAR)
    {
        return null;
    }

    const { singleMapLayers, configurationMap } = separateSingleAndMultipleMapLayers();

    return (
        <div className="sidebarPortal activitySidebar activitySidebarDynamicLayer">
            <div className="alignerSidebar">
                <Header as="h2">
                    <strong>{trans("MaintenanceDynamicLayerSidebar.Configure_Dynamic_Map_Layer")}</strong>
                    <ButtonIcon
                        icon="cross"
                        className="dynamicMapLayerActivityCloseButton"
                        onClick={cancelHandler} />
                </Header>
                <TextGroup title={trans("MaintenanceDynamicLayerSidebar.Map_Layer_Configuration")} />
            </div>
            <Tab menu={{ secondary: true, pointing: true }}
                panes={[
                    { menuItem: trans("MaintenanceDynamicLayerSidebar.Single_Map_Layer"), render: () => <SingleMapLayerTab dynamicMapLayers={singleMapLayers} onDelete={deleteClickHandler} onSelect={selectionHandler} selectedDynamicMapLayer={selectedDynamicMapLayer} /> },
                    { menuItem: trans("MaintenanceDynamicLayerSidebar.Multiple_Map_Layers"), render: () => <MultipleMapLayersTab configurationMap={configurationMap} onDelete={multiDeleteHandler} onSelect={selectionHandler} onDownload={downloadHandler} selectedDynamicMapLayer={selectedDynamicMapLayer} /> },
                ]}
                className="boxTabs activityTabs" />
        </div>
    );
};

export default ActivitySidebar;

const SingleMapLayerTab = ({ dynamicMapLayers, onDelete, onSelect, selectedDynamicMapLayer }) =>
{
    const trans = useTranslation().t;

    if (!dynamicMapLayers.length)
    {
        return (
            <div className="dynamicMapLayerConfigurationItem">
                <p>{trans("MaintenanceDynamicLayerSidebar.No_Map_Layers")}</p>
            </div>
        );
    }

    return (
        <>
            <Scrollbars className="inScroll" autoHide autoHeight autoHeightMin={"calc(1vh)"} autoHeightMax={"calc(100vh - 320px)"}>
                {
                    dynamicMapLayers.map((dynamicMapLayer) => (
                        <DynamicMapLayerItem
                            key={dynamicMapLayer._id}
                            dynamicMapLayer={dynamicMapLayer}
                            onDelete={onDelete}
                            onSelect={onSelect}
                            selectedDynamicMapLayer={selectedDynamicMapLayer}
                            enableCopyApiKey
                        />
                    ))
                }
            </Scrollbars>
        </>
    );
};

const MultipleMapLayersTab = ({ configurationMap, onSelect, onDelete, onDownload, selectedDynamicMapLayer }) =>
{
    const configurations = Object.entries(configurationMap).map(([key, value]) => ({
        name: key,
        dynamicMapLayers: value
    }));

    const trans = useTranslation().t;
    
    if (!configurations.length)
    {
        return (
            <div className="dynamicMapLayerConfigurationItem">
                <p>{trans("MaintenanceDynamicLayerSidebar.No_Map_Layers")}</p>
            </div>
        );
    }

    return (
        <>
            <Scrollbars className="inScroll" autoHide autoHeight autoHeightMin={"calc(1vh)"} autoHeightMax={"calc(100vh - 320px)"}>
                <div className="multiMapLayerTab">
                    {
                        configurations.map((configuration) => (
                            <ConfigurationItem
                                key={configuration.name}
                                configuration={configuration}
                                onSelect={onSelect}
                                onDelete={onDelete}
                                onDownload={onDownload}
                                selectedDynamicMapLayer={selectedDynamicMapLayer}
                            />)
                        )
                    }
                </div>
            </Scrollbars>
        </>
    );
};