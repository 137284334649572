import React, { useEffect, useRef } from "react";
import "./ImagesManagementMain.css";
import ImageBox from "./ImageBox/ImageBox";
import { useImagesBaseContext } from "../contexts/ImageBaseContext";
import InfiniteScrollGrid from "../../InfiniteScrollGrid/InfiniteScrollGrid";
import EmptyImagesStateFallback from "../../EmptyImagesStateFallback/EmptyImagesStateFallback";
import { useImagesUploadContext } from "../contexts/ImagesUploadContext";
import { IMAGE_UPLOAD_ACTIONS } from "../../constant";

function ImagesManagementMain() 
{
    const { imagesQuery, imagesData, imagesSearchAndFilters } = useImagesBaseContext();
    const { imageUploadAction } =
      useImagesUploadContext(); 
    return (
        <InfiniteScrollGrid
            isSuccess={imagesQuery.isSuccess}
            className={`images-management-main-inifinite-scroller ${imageUploadAction  === IMAGE_UPLOAD_ACTIONS.UPLOAD_FROM_URL? "images-management-main-inifinite-scroller-mod": ""}`}
            isFetchingNextPage={imagesQuery.isFetchingNextPage}
            fetchNextPage={imagesQuery.fetchNextPage}
            isLoading={imagesQuery.isLoading || imagesQuery.isError || imagesQuery.isIdle ||  imagesQuery.isFetchingNextPage}
            cellToRender={(item) => <ImageBox key={item._id} {...item}></ImageBox>}
            data={imagesData}
            hasNextPage={imagesQuery.hasNextPage}
            noItemsFallback={<EmptyImagesStateFallback>
                {imagesSearchAndFilters.searchValue && "No image matches that search."}
            </EmptyImagesStateFallback>}
        ></InfiniteScrollGrid>
    );
}

export default ImagesManagementMain;
