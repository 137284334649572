import React, { useMemo } from "react";
import { Button, Modal, ModalActions, ModalContent, ModalHeader } from "semantic-ui-react";
import ImageUploadMainWrapper from "../ImageUploadMainWrapper/ImageUploadMainWrapper";
import ImageCropper from "../ImageCropper/ImageCropper";
import "./BulkImageUploadModal.css";
import BulkImagesList from "./BulkImagesList/BulkImagesList";
import BulkImageUploadDetails from "./BulkImageUploadDetails/BulkImageUploadDetails";
import { useImagesUploadContext } from "../../contexts/ImagesUploadContext";
import { useGmsContext } from "../../../GmsContext";

function BulkImageUploadModal() 
{
    const gmsContext = useGmsContext();
    const { handleResetUpload, uploadedFilesInfo,getFileImageSrc, croppedImagesHistory,cropperRef, selectedImageInfoFromBulkUpload, handleBulkUploadImage } =
        useImagesUploadContext();

    const hasAllImagesBeenCropped = useMemo(() => uploadedFilesInfo.every((uploadedFile) => 
    {
        const isCropped = croppedImagesHistory?.[uploadedFile.id]?.currentImage && croppedImagesHistory?.[uploadedFile.id]?.prevImages?.length;
        return isCropped;
    }), [croppedImagesHistory, uploadedFilesInfo]);

    return (
        <Modal  closeIcon={<Button onClick={gmsContext.handleCloseGms} className="close" />} className="bulk-image-upload-modal" open>
            <ModalHeader>Upload Image</ModalHeader>
            <ModalContent>
                <BulkImagesList />
                <ImageUploadMainWrapper>
                    <ImageCropper
                        key={selectedImageInfoFromBulkUpload?.id}
                        ref={cropperRef}
                        aspectRatio={selectedImageInfoFromBulkUpload?.aspectRatio}
                        imageSrc={getFileImageSrc(selectedImageInfoFromBulkUpload?.id)}
                    />
                    <BulkImageUploadDetails />
                </ImageUploadMainWrapper>
            </ModalContent>
            <ModalActions>
                <Button onClick={handleResetUpload} className="gms-outline-btn" color="orange" floated="right">
                    Cancel
                </Button>
                <Button disabled={!hasAllImagesBeenCropped} color="orange" floated="right" onClick={handleBulkUploadImage}>
                    Upload All
                </Button>
            </ModalActions>
        </Modal>
    );
}

export default BulkImageUploadModal;