import React from "react";
import "./ImageEditModal.css";
import { Button, Modal, ModalActions, ModalContent, ModalHeader } from "semantic-ui-react";
import ImageCropper from "../ImageUploads/ImageCropper/ImageCropper";
import ImageUploadMainWrapper from "../ImageUploads/ImageUploadMainWrapper/ImageUploadMainWrapper";
import ImageEditDetails from "./ImageEditDetails/ImageEditDetails";
import { useTranslation } from "react-i18next";
import { ImageEditProvider, useImageEditContext } from "../contexts/ImageEditContext";
import { useImagesBaseContext } from "../contexts/ImageBaseContext";
import { useGmsContext } from "../../GmsContext";

function ImageEditModal() 
{
    const gmsContext = useGmsContext();
    const imageEditContext = useImageEditContext();
    const {
        imageData,
        croppedImagesHistory,
        handleCloseEditModal,
        cropperRef,
        handleEditImage,
    } = imageEditContext;
    const { selectedImageInfo } = useImagesBaseContext();
    const croppedImageHistory = croppedImagesHistory?.[selectedImageInfo?._id]; 
    const imageSrc = croppedImageHistory?.currentImage || imageData.dataUrl;
    const trans = useTranslation().t;
    
    return (
        <Modal             
            closeIcon={<Button onClick={gmsContext.handleCloseGms} className="close" />}
            className="single-image-upload-modal" open>
            <ModalHeader>Edit Image
            </ModalHeader>

            <ModalContent>
                <ImageUploadMainWrapper>
                    <ImageCropper
                        key={selectedImageInfo?._id}
                        ref={cropperRef}
                        aspectRatio={selectedImageInfo?.aspectRatio}
                        imageSrc={imageSrc}
                    />
                    <ImageEditDetails />
                </ImageUploadMainWrapper>
            </ModalContent>
            <ModalActions>
                <Button onClick={handleCloseEditModal} className="gms-outline-btn" color="orange" floated="right">
                    Cancel
                </Button>
                <Button disabled={(!croppedImageHistory?.currentImage && selectedImageInfo.name === imageData.name) || !imageSrc || !imageData.name} onClick={handleEditImage} color="orange" floated="right">
                    Save
                </Button>
            </ModalActions>
        </Modal>
    
    );
}


export default ImageEditModal;