import { useEffect, useRef } from "react";
import { 
    createZoneFeatureFromShape, 
    createZonesLayerFromFeatures 
} from "../_utils/mapUtils";
import { 
    DEFAULT_DISTRIBUTION_ZONE_STYLE, 
    ZONES_MAP_CONSTANTS, 
} from "../_constants/zones";
import { createEntityGeometry } from "mapsted.maps/mapFunctions/plotting";
import { changeMapCenterWithBoundaryPolygon } from "mapsted.maps/mapFunctions/interactions";
import { useZonesContext } from "../store/ZonesContext";
const { Pointer } = require("ol/interaction");

const useZoneMapHandler = ({ 
    olMap, 
}) =>
{
    const { visibleZones: zones, publishedTemplates } = useZonesContext();

    // ref to the layer containing all the visible zone features
    const zonesLayerRef = useRef();

    // ref containing all the visible zone feature
    const zoneFeaturesRef = useRef({});

    // ref to keep track of the mouse interaction added to map for selecting zones etc
    const mouseDownInteraction = useRef();

    // ref to layer of zone currently under edit or is being created
    const zoneUnderWorkLayer = useRef();


    useEffect(() =>
    {
        if (olMap)
        {
            removeZoneLayersFromMap();

            if (zones?.length)
            {
                addZonesToMap();
            }

            addMouseInteraction();
        }
        return () => 
        {
            removeMouseInteraction();
        };
    }, [olMap, zones, publishedTemplates]);

    const removeZoneLayersFromMap = () =>
    {
        try
        {
            if (zonesLayerRef?.current && olMap)
            {
                olMap?.removeLayer(zonesLayerRef.current);
            }
        }
        catch (err)
        {
            console.log("Error while removing layers");
        }
    };

    const addZonesToMap = () =>
    {
        let zoneFeatures = [];

        zones.forEach((zone) =>
        {
            const linkedTemplate = publishedTemplates?.find((template) => template.templateId === zone.metadata.templateId);

            const zoneFeature = createZoneFeatureFromShape(zone.boundary, zone.zoneGeofenceId, DEFAULT_DISTRIBUTION_ZONE_STYLE, linkedTemplate);
            // keep track of the created zone features using zoneFeaturesRef, so that it would be easier to remove it later
            zoneFeaturesRef.current[zone.zoneGeofenceId] = zoneFeature;
            zoneFeatures.push(zoneFeature);
        });

        if (zoneFeatures.length)
        {
            const zonesLayer = createZonesLayerFromFeatures(zoneFeatures);

            // keep track of the zone layer as well using zonesLayerRef
            zonesLayerRef.current = zonesLayer;

            olMap.addLayer(zonesLayer);
        }
    };

    const addMouseInteraction = () =>
    {
        const interaction = new Pointer({ 
            handleEvent: (e) => handleMouseEvent(e)
        });

        mouseDownInteraction.current = interaction;
        olMap.addInteraction(interaction);
    };

    const removeMouseInteraction = () =>
    {
        if (olMap && mouseDownInteraction?.current)
        {
            olMap.removeInteraction(mouseDownInteraction.current);
        }
    };


    const handleMouseEvent = (e) =>
    {
        if (e.type === "click")
        {
            let zoneId = undefined;
            
            const layerFilter = (layer) => !!layer.get("isDistributionZone");
            const options = {
                layerFilter
            };

            olMap?.forEachFeatureAtPixel(e.pixel, (feature) =>
            {
                zoneId = feature?.get("zoneId");
            }, options);
        }

        return true;
    };

    const centerOnZone = (distributionZoneId) =>
    {
        const distributionZone = zones.find((distributionZone) => distributionZone.zoneGeofenceId === distributionZoneId);

        if (olMap && distributionZone?.boundary)
        {
            const geometry = createEntityGeometry(distributionZone.boundary);

            changeMapCenterWithBoundaryPolygon({
                olMap: olMap,
                boundaryPolygon: geometry,
                padding: ZONES_MAP_CONSTANTS.ZONE_FIT_PADDING,
            });
        }
    };

    const updateZoneMapHandlerLayerVisibility = (isVisible) =>
    {
        if (zoneUnderWorkLayer.current)
        {
            zoneUnderWorkLayer.current.setVisible(isVisible);
        }
    };

    return {
        centerOnZone,
        updateZoneMapHandlerLayerVisibility,
    };
};

export default useZoneMapHandler;