import React from "react";
import "./IconsManagementSection.css";

function IconsManagementSection({ children })
{
    return <div className="icons-management-section">
        {children}
    </div>;
}

function IconsManagementSectionHeader({ children })
{
    return <div className="icons-management-section-title">
        {children}
    </div>;
}

function IconsManagementSectionContent({ children, className = "" })
{
    return <div className={`icons-management-section-content ${className}`}>
        {children}
    </div>;
}

IconsManagementSection.Header = IconsManagementSectionHeader;
IconsManagementSection.Content = IconsManagementSectionContent;

export default IconsManagementSection;