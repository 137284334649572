import "../MapEditor.css";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import ObjectId from "bson-objectid";
import { Point } from "ol/geom";
import
{
    lineIntersect as turfLineIntersect,
    lineString as turfLineString,
    point as turfPoint,
    polygon as turfPolygon,
    nearestPointOnLine,
    booleanPointInPolygon,
    pointToLineDistance as turfPointToLineDistance,
    circle as turfCircle
} from "@turf/turf";
import mapEditorLocalDb from "../../../_indexedDB/mapEditorLocal.db";
import MapEditorContext from "../../../store/MapEditiorContext";
import { NodeEditorMap } from "../map/NodeEditorMap";
import { EntityRefTypes, ShapeTypes, RoutableObstacleSubEntityTypes } from "mapsted.maps/utils/entityTypes";
import { getDistance, mercatorToWgs84 } from "../../../_utils/turf.utlils";

import { useKeyboardShortcut, useSingleKeyboardShortcut } from "../../../_utils/keyboardShortcuts";
import { EDITOR_ALERTS, FILTERED_ID_TYPES, NODE_EDITOR_FILTERS, MAP_EDITOR_SHORTCUTS, MAP_EDITOR_TOOLS, NODE_TOOL_SHORTCUTS, NODE_DISTANCE_THRESHOLD_MM } from "../../../_constants/mapEditor";
import { CollectionNameMap } from "../../../_indexedDB/collections/v1/collection";
import { createNodeLink, createLinkedId, extractNodeIdsFromNodeLinkId, findLastIdx, getNodeDataFromTopologyMapData, nodeLinkIndexOfId, nodeLinksIncludesId, getEntitiesFromMapData, createNodeLinkStepDataWithNodeLinkIdAndNodes } from "../../../_utils/mapEditorUtils";
import { CMSTopologyEntityAccess, CMSEntityAccess } from "mapsted.maps/mapFunctions/entityAccess";
import { deepCopy } from "../../../_utils/utils";
import { NodeStepData } from "../../../models/nodeStep";
import { createEntityGeometry } from "mapsted.maps/mapFunctions/plotting";
import { NODE_RADIUS } from "mapsted.maps/utils/map.constants";
import { filterEntityAccessArrayWithoutNodesLocked, filterEntityAccessArrayWithoutNodesUnlocked } from "../../../_utils/smartFilter.utils";
import { toast } from "sonner";

/**
 * https://docs.google.com/document/d/1V2HnBGlSVqgjVsYYUbLMRu-BOrE4LYhBQzNISVcmFPI
 * @param {String} propertyId - UID
 * @param {String} buildingId - UID
 * @param {String} floorId - (optional) Floor Id to start on
 * @returns 
 */
export const NodeEditor = ({ propertyId, buildingId, floorId, selectedFilterItem, onUndo, onRedo, onUpdateSmartFilter, onLocalDBUpdated, onSetSelectedFilterItem }) =>
{
    const mapEditorContext = useContext(MapEditorContext);

    const [isUndoLocked, setIsUndoLocked] = useState(false); // set locked when saving undo step to local 
    const [entities, setEntities] = useState([]);

    const nodesRef = useRef();
    const nodeLinksMapRef = useRef();
    const autoLinkedNodesRef = useRef([]);
    const autoLinkIdRef = useRef(ObjectId().toString()); // for undo redo ref

    // // on component load -
    // React.useEffect(() => { },
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    //     []);

    React.useEffect(() =>
    {
        mapEditorContext.handleSetLocalObjects(nodesRef.current);
    }, [nodesRef.current, mapEditorContext.state.propertyId, mapEditorContext.state.buildingId, mapEditorContext.state.floorId]);

    // create plottable node data
    useEffect(() =>
    {
        const mapData = mapEditorContext.state.mapData;
        const style = mapEditorContext.state.style;

        const { nodes, nodeLinksMap } = getNodeDataFromTopologyMapData(mapData);

        let entities = getEntitiesFromMapData(mapData);

        if (Array.isArray(entities))
        {
            // save entities to use if attach edited nodes to overlapping entities
            entities.forEach((entity) =>
            {
                if (entity.shape)
                {
                    let entityAccess = new CMSEntityAccess(entity);
                    entity.isRoutable = entityAccess.getIsRoutableEntity();
                    entity.isNodeForbidden = entityAccess.getIsNodeForbidden();
                    entity.priority = -1;

                    if (entity.isRoutable || entity.isNodeForbidden)
                    {
                        // create turf polygon to use for later functions
                        // create priority based off of layer idx
                        if (entity.shape.type === ShapeTypes.POLYGON)
                        {
                            entity.turfPolygon = turfPolygon(entity.shape.coordinates);
                        }
                        else if (entity.shape.type === ShapeTypes.POINT)
                        {
                            entity.turfPolygon = turfCircle(entity.shape.coordinates, .05, {
                                units: "meters"
                            });
                        }
                        //TODO STYLE IS UNDEFINED 
                        entity.priority = style?.[entity.entityType]?.[entity.subEntityType]?.layerIdx;
                    }
                }
                else
                {
                    console.log("DEBUG - Entity has no shape", entity);
                }
            });
        }

        // sort entities based off of priority descending 
        entities.sort((e1, e2) => e2.priority - e1.priority);

        setEntities(entities);

        nodesRef.current = nodes;
        nodeLinksMapRef.current = nodeLinksMap;

    }, [mapEditorContext.state.mapData]);

    /**
     * Reset autoLinkNode list on tool switch
     */
    useEffect(() =>
    {
        let autoLinkedNodes = deepCopy(autoLinkedNodesRef.current);

        if (autoLinkedNodes.length > 0)
        {
            autoLinkedNodesRef.current = [];
            autoLinkIdRef.current = ObjectId().toString();
        }

    }, [mapEditorContext?.state?.selectedTool]);

    /******************************************/
    /************ HELPER FUNCTIONS ************/
    /******************************************/

    const selectedFilter = useMemo(() => (
        mapEditorContext?.state?.selectedFilter
    ), [mapEditorContext?.state?.selectedFilter]);

    /**
    * Seems redundant but this needs to be accessed inside of a draw handler with updated state values. 
    * The useMemo above wont update inside of the handler function 
    * @returns last linked node
    */
    const handleGetPrevAutoLinkedNode = (step = 1) =>
    {
        let autoLinkedNodes = autoLinkedNodesRef.current;
        return autoLinkedNodes[autoLinkedNodes.length - step];
    };

    const convertFeatureToNode = useCallback((feature, mapClickData) =>
    {
        let ref = floorId || propertyId;
        let refType = floorId 
            ? EntityRefTypes.FLOOR 
            : EntityRefTypes.PROPERTY;

        let coordinates = feature.getGeometry().getCoordinates() || feature.getGeometry().getCenter();
        coordinates = mercatorToWgs84(coordinates, ShapeTypes.POINT);

        let shape = {
            type: ShapeTypes.POINT,
            coordinates: coordinates
        };

        let objectId = ObjectId();

        let node = {
            _id: objectId.toString(),
            nodeId: -1,
            ref: ref,
            refType: refType,
            nodeLinks: [],
            shape: shape,
            draft: true,
            entityId: mapClickData?.entityId
        };

        return node;

    }, [propertyId, floorId]);

    /**
     * links prev auto linked node with current node. 
     * mutates both node and and updated nodes
     * returns the auto link id for the feature
     */
    const autoLinkNewNodeHelper = useCallback(({ node, nodes, autoLinkedNodes, updatedNodesBefore, updatedNodesAfter, nodeLinksMap, isValidAutoLink }) =>
    {
        let prevAutoLinkedNode = autoLinkedNodes[autoLinkedNodes.length - 1];
        let nodeLinkId;

        // --> check if there is a linked node
        if (prevAutoLinkedNode)
        {
            // -->link new node with prev node
            let prevAutoLinkedNodeId = prevAutoLinkedNode._id;

            // check if the node is already linked to prev node
            // in cases where node is being drawn on node linkage 
            if (!nodeLinksIncludesId(node, prevAutoLinkedNodeId))
            {
                if (isValidAutoLink !== undefined && !isValidAutoLink)
                {
                    return { nodeLinkId, nodes, updatedNodesBefore, updatedNodesAfter, nodeLinksMap, isValidAutoLink };
                }

                node.nodeLinks.push(createNodeLink(prevAutoLinkedNode));

                // --> set nodeLinkId
                nodeLinkId = `${prevAutoLinkedNodeId}-${node._id}`;

                nodeLinksMap[nodeLinkId] = [node.shape.coordinates, prevAutoLinkedNode.shape.coordinates];

                // --> link prev node with new node
                let indexOfLastLinkedNode = nodes.findIndex((node) => node._id === prevAutoLinkedNodeId);

                // deep copy prev node for step data
                if (updatedNodesBefore)
                {
                    updatedNodesBefore.push(deepCopy(nodes[indexOfLastLinkedNode]));
                }

                nodes[indexOfLastLinkedNode].nodeLinks.push(createNodeLink(node));

                // copy prev node after update for step data
                if (updatedNodesAfter)
                {
                    updatedNodesAfter.push(nodes[indexOfLastLinkedNode]);
                }
            }
            else
            {
                isValidAutoLink = true;
            }
        }

        return { nodeLinkId, nodes, updatedNodesBefore, updatedNodesAfter, nodeLinksMap, isValidAutoLink };
    }, []);

    /**
     * Mutable snapping function that adjusts feature geometry
     */
    const handleOnSnapNode = useCallback((e, snappedData) =>
    {
        const { nodeId1: nodeLinkId1, nodeId2: nodeLinkId2, isNodeConnection, isNode, nodeFeature } = snappedData;
        let { feature } = e;

        let nodes = deepCopy(nodesRef.current);
        if (isNodeConnection)
        {

            let nodeIdx1 = nodes.findIndex((n) => n._id === nodeLinkId1);
            let nodeLink1 = nodes[nodeIdx1];

            let nodeIdx2 = nodes.findIndex((n) => n._id === nodeLinkId2);
            let nodeLink2 = nodes[nodeIdx2];

            let point = turfPoint(mercatorToWgs84(feature.getGeometry().getCoordinates(), ShapeTypes.POINT));
            let line = new turfLineString(([nodeLink1.shape.coordinates, nodeLink2.shape.coordinates]));

            let nearestPoint = nearestPointOnLine(line, point, { units: "millimeters" });

            let geometry = createEntityGeometry({ type: ShapeTypes.CIRCLE, coordinates: nearestPoint.geometry.coordinates }, NODE_RADIUS);

            feature.setGeometry(geometry);

        }
        else if (isNode)
        {
            feature.setGeometry(new Point([0, 0]));

            feature = nodeFeature;
        }

        return { feature, isSnapped: (isNodeConnection || isNode) };

    }, [nodesRef.current]);

    const updateLocalDB = useCallback(async (nodeStepData) =>
    {
        setIsUndoLocked(true);

        const { selectedTool } = mapEditorContext.state;

        let autoLinkId;
        if (selectedTool === MAP_EDITOR_TOOLS.AutoLink)
        {
            autoLinkId = autoLinkIdRef.current;
        }

        // update local node DB
        await mapEditorLocalDb.handleUpdateLocalNodes({
            propertyId,
            buildingId,
            floorId,
            selectedTool,
            autoLinkId,
            nodeStepData,
        });

        onLocalDBUpdated();
        setIsUndoLocked(false);

    }, [mapEditorContext.state.selectedTool, onLocalDBUpdated, propertyId, buildingId, floorId, setIsUndoLocked]);

    /**
     * Finds node and node index from the node array using nodeId
     */
    const findNodeById = useCallback((nodeId, nodes) =>
    {
        if (!nodes)
        {
            nodes = deepCopy(nodesRef.current);
        }

        let idx = nodes.findIndex((n) => n._id === nodeId);

        return { node: nodes[idx], nodeIdx: idx };
    }, [nodesRef.current]);

    /**
     * Creates a collection of filtered features based on the selected filter
     */
    const nodesSmartFilter = useMemo(() =>
    {
        const mapData = mapEditorContext.state?.mapData;
        const isLevelLockedByUser = mapEditorContext.state?.isLevelLockedByUser;
        const localNodes = nodesRef.current;

        let filteredIds = [];
        let filteredFeatures = [];
        let idType;

        if (!selectedFilter || !mapData?.topology || !localNodes)
        {
            return undefined;
        }

        const entities = mapData.topology.objects.entities.geometries;
        const entityAccessArray = entities.map((entity) => new CMSTopologyEntityAccess(entity, mapData.topology));

        switch (selectedFilter)
        {
        case (NODE_EDITOR_FILTERS.ROUTABLE_ENTITIES_WITHOUT_NODES): {

            let filteredEntityAccessArray = [];
            if (isLevelLockedByUser)
            {
                filteredEntityAccessArray = filterEntityAccessArrayWithoutNodesLocked(localNodes, entityAccessArray);
            }
            else
            {
                filteredEntityAccessArray = filterEntityAccessArrayWithoutNodesUnlocked(entityAccessArray);
            }

            filteredIds = filteredEntityAccessArray.map((entityAccess) =>(
                {
                    navId: entityAccess.getNavId(),
                    cmsId: entityAccess.getId()
                }));
            filteredFeatures = filteredEntityAccessArray.map((entityAccess) => entityAccess.get("feature"));

            idType = FILTERED_ID_TYPES.ENTITY;

            break;
        }
        case (NODE_EDITOR_FILTERS.LEAF_NODES):
        {
            // leaf node definition 
            // Node "A" Is a leaf node if it satisfies 1 of the following conditions
            // 1. Node "A" is in an inaccessible area
            // 2. Node "A" has only 1 node link "B" and  Nodes "A" and "B" are not connected to an entity or transition
            // 2 Note --> does it have to be connected to a routable entity or an entity? (as of time of writing, it is any entity)

            // loop through entities -> add inaccessible entityIds to bool map
            // loop through nodes -> add all nodes where entityId is in bool map
            let inaccessibleEntitiesBoolMap = {};

            entityAccessArray.forEach((entityAccess) =>
            {
                let isInaccessibleStructure = entityAccess.getIsEntityInaccessibleStructure();

                if (isInaccessibleStructure)
                {
                    inaccessibleEntitiesBoolMap[entityAccess.getId()] = true;
                }

            });

            const filteredNodes = localNodes.filter((node) =>
            {
                let isInaccessibleLeafNode = inaccessibleEntitiesBoolMap[node.entityId];

                if (isInaccessibleLeafNode)
                {
                    return true;
                }
                else 
                {
                    //check if node has only 1 linkage
                    // if node is not a transition or no entity Id is assigned it will also be considered a leaf node
                    if (node?.nodeLinks?.length === 1)
                    {
                        let isNodeConnectedToEntityOrTransition = (!!node.entityId && node.entityId !== -1) || !!mapEditorContext.state?.propertyTransitions?.transitionNodeIds[node._id];

                        // if the node or the node its connected to is either connected to an entity or a transition then it is not a leaf node
                        if (isNodeConnectedToEntityOrTransition)
                        {
                            return false;
                        }
                        else 
                        {
                            let connectedNodeId = node?.nodeLinks[0]?.linkedNode;
                            let connectedNode = localNodes.find(n => n._id === connectedNodeId);

                            let isConnectedNodeConnectedToEntityOrTransition = (!!connectedNode.entityId && connectedNode.entityId !== -1 && !inaccessibleEntitiesBoolMap[connectedNode.entityId]) || !!mapEditorContext.state?.propertyTransitions?.transitionNodeIds[connectedNode._id];

                            return !isConnectedNodeConnectedToEntityOrTransition;
                        }
                    }
                }
            });

            filteredIds = filteredNodes.map((node) => (
                {
                    navId: node.nodeId,
                    cmsId: node._id
                }));
            idType = FILTERED_ID_TYPES.NODE;

            break;
        }
        case (NODE_EDITOR_FILTERS.FLOATING_NODES): {
            // loop through nodes -> add all nodes where node link length = 0;
            const filteredNodes = localNodes.filter((node) => node.nodeLinks.length === 0);
            filteredIds = filteredNodes.map((node) => (
                {
                    navId: node.nodeId,
                    cmsId: node._id
                }));
            idType = FILTERED_ID_TYPES.NODE;
            break;
        }
        case (NODE_EDITOR_FILTERS.SEARCH):
        {
            const featureSearchResults = mapEditorContext.state?.featureSearchResults;
            filteredIds = featureSearchResults.map((result) => (
                {
                    navId: result.navId,
                    cmsId: result._id
                }));

            idType = FILTERED_ID_TYPES.NODE;

            if (featureSearchResults.length > 0 && featureSearchResults[0].type === FILTERED_ID_TYPES.ENTITY)
            {
                let filteredCmsIds = filteredIds.map((id) => id.cmsId);
                const filteredEntityAccessArray = entityAccessArray.filter((entityAccess) => filteredCmsIds.includes(entityAccess.getId()));
                filteredFeatures = filteredEntityAccessArray.map((entityAccess) => entityAccess.get("feature"));
                idType = FILTERED_ID_TYPES.ENTITY;
            }

            break;
        }
        }

        const smartFilter = {
            filter: selectedFilter,
            idType,
            filteredIds,
            filteredFeatures, // for entities
        };

        return smartFilter;

    }, [selectedFilter, mapEditorContext.state?.mapData, mapEditorContext.state?.propertyTransitions, mapEditorContext.state?.featureSearchResults, nodesRef.current]);

    React.useEffect(() =>
    {
        (onSetSelectedFilterItem) && onSetSelectedFilterItem(undefined);

        (onUpdateSmartFilter) && onUpdateSmartFilter(nodesSmartFilter);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [nodesSmartFilter]);


    /*********************************************/
    /* NODE/NODE-LINK CREATION/DELETION HANDLERS */
    /*********************************************/

    /**
     * Saving to local db
     * Added step logic
     */
    const handleDrawNode = useCallback((feature, mapClickData, isAutoLink) =>
    {
        let nodes = deepCopy(nodesRef.current);
        let autoLinkedNodes = deepCopy(autoLinkedNodesRef.current);
        let nodeLinksMap = deepCopy(nodeLinksMapRef.current);

        // step data
        let nodeStepData = new NodeStepData();
        nodeStepData.addedNodes = [];
        nodeStepData.updatedNodesAfter = [];
        nodeStepData.updatedNodesBefore = [];
        nodeStepData.addedNodeLinks = [];
        nodeStepData.autoLinkAddedNodes = [];

        if (Array.isArray(nodes))
        {
            let node = convertFeatureToNode(feature, mapClickData);
            let nodeLinkId = "";

            // -> add node to local node list
            nodes.push(node);
            nodeStepData.addedNodes.push(node);

            // -> handle auto link functionality
            if (isAutoLink)
            {
                let autoLinkHelperResponse = autoLinkNewNodeHelper({ node, nodes, autoLinkedNodes, updatedNodesBefore: nodeStepData.updatedNodesBefore, updatedNodesAfter: nodeStepData.updatedNodesAfter, nodeLinksMap });
                nodeLinkId = autoLinkHelperResponse.nodeLinkId;

                nodes = autoLinkHelperResponse.nodes;
                nodeLinksMap = autoLinkHelperResponse.nodeLinksMap;

                autoLinkedNodes.push(node);

                nodeStepData.autoLinkAddedNodes.push(node);
                nodeStepData.updatedNodesBefore = autoLinkHelperResponse.updatedNodesBefore;
                nodeStepData.updatedNodesAfter = autoLinkHelperResponse.updatedNodesAfter;
                if (nodeLinkId)
                {
                    const addedNodeLinkStepData = createNodeLinkStepDataWithNodeLinkIdAndNodes(nodeLinkId, nodes);
                    nodeStepData.addedNodeLinks.push(addedNodeLinkStepData);
                }
            }

            // set features properties with new nodes 
            nodeLinksMapRef.current = nodeLinksMap;
            nodesRef.current = nodes;
            autoLinkedNodesRef.current = autoLinkedNodes;

            console.log({nodeStepData, node});
            updateLocalDB(nodeStepData);

            return { node, nodeLinkId, nodeStepData, isValid: true };
        }
    }, [updateLocalDB, handleGetPrevAutoLinkedNode, entities, nodesRef.current, autoLinkedNodesRef.current, nodeLinksMapRef.current]);

    /**
     * Saving to local db
     * Added step logic
     */
    const handleDrawNodeSnappedToNode = useCallback((nodeId, isAutoLink, isValidAutoLink) =>
    {
        let nodes = deepCopy(nodesRef.current);
        let autoLinkedNodes = deepCopy(autoLinkedNodesRef.current);
        let nodeLinksMap = deepCopy(nodeLinksMapRef.current);

        // get node data fromId
        let nodeIdx = nodes.findIndex((n) => n._id === nodeId);
        let node = nodes[nodeIdx];
        let nodeLinkId;

        // step data
        let nodeStepData = new NodeStepData();
        nodeStepData.updatedNodesAfter = [];
        nodeStepData.updatedNodesBefore = [];
        nodeStepData.addedNodeLinks = [];
        nodeStepData.autoLinkAddedNodes = [];

        nodeStepData.updatedNodesBefore.push(deepCopy(node));

        if (isAutoLink)
        {
            // handle auto link logic
            let prevAutoLinkedNode = autoLinkedNodes[autoLinkedNodes.length - 1];

            // don't auto link if node we are snapping to is already linked to prev node or if prev auto link node doesn't exist
            if (prevAutoLinkedNode && !nodeLinksIncludesId(node, prevAutoLinkedNode._id))
            {
                if (!isValidAutoLink)
                {
                    return { isValidAutoLink };
                }

                let autoLinkHelperResponse = autoLinkNewNodeHelper({ node, nodes, autoLinkedNodes, updatedNodesBefore: nodeStepData.updatedNodesBefore, updatedNodesAfter: nodeStepData.updatedNodesAfter, nodeLinksMap });
                nodeLinkId = autoLinkHelperResponse.nodeLinkId;

                nodes = autoLinkHelperResponse.nodes;

                nodeStepData.updatedNodesBefore = autoLinkHelperResponse.updatedNodesBefore;
                nodeStepData.updatedNodesAfter = autoLinkHelperResponse.updatedNodesAfter;
                nodeLinksMap = autoLinkHelperResponse.nodeLinksMap;
            }
            else
            {
                // no need to check for validation 
                isValidAutoLink = true;
            }

            autoLinkedNodes.push(node);
            nodeStepData.autoLinkAddedNodes.push(node);

            // update node info in local
            nodes[nodeIdx] = node;

            nodeStepData.updatedNodesAfter.push(node);

            // set refs
            nodeLinksMapRef.current = nodeLinksMap;
            nodesRef.current = nodes;
            autoLinkedNodesRef.current = autoLinkedNodes;

            if (nodeLinkId)
            {
                const addedNodeLinkStepData = createNodeLinkStepDataWithNodeLinkIdAndNodes(nodeLinkId, nodes);
                nodeStepData.addedNodeLinks.push(addedNodeLinkStepData);
            }

            updateLocalDB(nodeStepData);
        }


        return { node, nodeLinkId, isValidAutoLink, nodeStepData };

    }, [updateLocalDB, nodesRef.current, autoLinkedNodesRef.current,]);

    /**
    * Saving to local db
    * Added step logic
    */
    const handleDrawNodeSnappedToNodeLink = useCallback((feature, nodeLinkId1, nodeLinkId2, mapClickData, isAutoLink, isValidAutoLink) =>
    {
        let nodes = deepCopy(nodesRef.current);
        let autoLinkedNodes = deepCopy(autoLinkedNodesRef.current);
        let nodeLinksMap = deepCopy(nodeLinksMapRef.current);

        let nodeIdx1 = nodes.findIndex((n) => n._id === nodeLinkId1);
        let nodeLink1 = nodes[nodeIdx1];

        let nodeIdx2 = nodes.findIndex((n) => n._id === nodeLinkId2);
        let nodeLink2 = nodes[nodeIdx2];

        // create node from feature
        let node = convertFeatureToNode(feature, mapClickData);
        let nodeLinkId; // auto link id

        // step data 
        let nodeStepData = new NodeStepData();
        nodeStepData.addedNodes = [];
        nodeStepData.updatedNodesAfter = [];
        nodeStepData.updatedNodesBefore = [];
        nodeStepData.addedNodeLinks = [];
        nodeStepData.deletedNodeLinks = [];
        nodeStepData.autoLinkAddedNodes = [];

        nodeStepData.updatedNodesBefore.push(deepCopy(nodeLink1), deepCopy(nodeLink2));

        const linkedId1 = createLinkedId(nodeLink1._id, nodeLink2._id);

        // const linkedPriorityMultiplier1 = nodeLink1.nodeLinks.find((n) => n.linkedNode === nodeLink2._id)?.linkedPriorityMultiplier;
        // const linkedPriorityMultiplier2 = nodeLink2.nodeLinks.find((n) => n.linkedNode === nodeLink1._id)?.linkedPriorityMultiplier;

        let deletedNodeLinkStep1 = createNodeLinkStepDataWithNodeLinkIdAndNodes(linkedId1, nodes);
        // let deletedNodeLinkStep2 = createNodeLinkStepDataWithNodeLinkIdAndNodes(linkedId2, nodes);
        nodeStepData.deletedNodeLinks.push(deletedNodeLinkStep1);

        // link node to nodeLink1 and nodeLink2
        node.nodeLinks.push(createNodeLink(nodeLink1), createNodeLink(nodeLink2));

        // link new node two previously linked nodes
        nodeLink1.nodeLinks.push(createNodeLink(node));
        nodeLink2.nodeLinks.push(createNodeLink(node));

        // remove prev link
        nodeLink1.nodeLinks.splice(nodeLinkIndexOfId(nodeLink1.nodeLinks, nodeLinkId2), 1);
        nodeLink2.nodeLinks.splice(nodeLinkIndexOfId(nodeLink2.nodeLinks, nodeLinkId1), 1);

        nodes[nodeIdx1] = nodeLink1;
        nodes[nodeIdx2] = nodeLink2;

        // add nodes to node data
        nodes.push(node);

        // handle auto link logic
        if (isAutoLink)
        {
            let autoLinkHelperResponse = autoLinkNewNodeHelper({ node, nodes, autoLinkedNodes, updatedNodesBefore: nodeStepData.updatedNodesBefore, updatedNodesAfter: nodeStepData.updatedNodesAfter, nodeLinksMap, isValidAutoLink });

            isValidAutoLink = autoLinkHelperResponse.isValidAutoLink;
            if (!isValidAutoLink)
            {
                return { isValidAutoLink };
            }

            nodeLinkId = autoLinkHelperResponse.nodeLinkId;

            nodes = autoLinkHelperResponse.nodes;
            nodeLinksMap = autoLinkHelperResponse.nodeLinksMap;
            nodeStepData.updatedNodesBefore = autoLinkHelperResponse.updatedNodesBefore;
            nodeStepData.updatedNodesAfter = autoLinkHelperResponse.updatedNodesAfter;

            if (nodeLinkId)
            {
                const addedNodeLinkStepData = createNodeLinkStepDataWithNodeLinkIdAndNodes(nodeLinkId, nodes);
                nodeStepData.addedNodeLinks.push(addedNodeLinkStepData);
            }
            autoLinkedNodes.push(node);
            nodeStepData.autoLinkAddedNodes.push(node);
        }

        // handle node links map
        // remove prev link from node link map
        delete nodeLinksMap[createLinkedId(nodeLinkId1, nodeLinkId2)];
        delete nodeLinksMap[createLinkedId(nodeLinkId2, nodeLinkId1)];

        // add two new links to node link map
        let linkedNodeId1 = `${node._id}-${nodeLinkId1}`;
        let linkedNodeId2 = `${node._id}-${nodeLinkId2}`;
        nodeLinksMap[linkedNodeId1] = [node.shape.coordinates, nodeLink1.shape.coordinates];
        nodeLinksMap[linkedNodeId2] = [node.shape.coordinates, nodeLink2.shape.coordinates];

        // set refs
        nodesRef.current = nodes;
        autoLinkedNodesRef.current = autoLinkedNodes;
        nodeLinksMapRef.current = nodeLinksMap;

        // update step data 
        nodeStepData.updatedNodesAfter.push(nodeLink1, nodeLink2);
        nodeStepData.addedNodes.push(node);

        if (linkedNodeId1)
        {
            const addedNodeLinkStepData = createNodeLinkStepDataWithNodeLinkIdAndNodes(linkedNodeId1, nodes);
            nodeStepData.addedNodeLinks.push(addedNodeLinkStepData);
        }
        if (linkedNodeId2)
        {
            const addedNodeLinkStepData = createNodeLinkStepDataWithNodeLinkIdAndNodes(linkedNodeId2, nodes);
            nodeStepData.addedNodeLinks.push(addedNodeLinkStepData);
        }

        updateLocalDB(nodeStepData);

        return ({ node, nodeLink1, nodeLink2, nodeLinkId, nodeStepData, isValidAutoLink });

    }, [updateLocalDB, nodesRef.current, autoLinkedNodesRef.current, nodeLinksMapRef.current]);

    /**
     * links two nodes to each other if possible, saves step in local browser DB.
     * @param {Array} nodeIds
     */
    const handleLinkNodes = useCallback((nodeIds) =>
    {
        // case 1 - link to nodes that don't have link
        // case 2 - link to nodes that already have a link
        // case 3 - link to nodes creates an invalid link
        if (Array.isArray(nodeIds) && nodeIds.length === 2)
        {
            if (nodeIds[0] === nodeIds[1])
            {
                toast.error(EDITOR_ALERTS.NOT_VALID_NODE_LINK);
                return;
            }

            // get both nodes
            let nodes = deepCopy(nodesRef.current);
            let nodeLinksMap = deepCopy(nodeLinksMapRef.current);

            // step data
            let nodeStepData = new NodeStepData();
            nodeStepData.updatedNodesBefore = [];
            nodeStepData.updatedNodesAfter = [];
            nodeStepData.addedNodeLinks = [];

            let [nodeId1, nodeId2] = nodeIds;

            let node1idx = nodes.findIndex((n) => n._id === nodeId1);
            let node2idx = nodes.findIndex((n) => n._id === nodeId2);

            let isLinkValid = validateNodeLinkage({ newNodeLink: [nodes[node1idx].shape.coordinates, nodes[node2idx].shape.coordinates] });

            if (!isLinkValid)
            {
                toast.error(EDITOR_ALERTS.NOT_VALID_NODE_LINK);
                return;
            }

            //update both nodes links if link doesn't already exist
            if (nodeLinksIncludesId(nodes[node1idx], nodeId2))
            {
                // link already exists so return nothing
                return;
            }

            // set updated nodes before
            nodeStepData.updatedNodesBefore.push(deepCopy(nodes[node1idx]));
            nodeStepData.updatedNodesBefore.push(deepCopy(nodes[node2idx]));

            nodes[node1idx].nodeLinks.push(createNodeLink(nodes[node2idx], nodes));
            nodes[node2idx].nodeLinks.push(createNodeLink(nodes[node1idx], nodes));

            // set updated nodes after
            nodeStepData.updatedNodesAfter.push({ ...nodes[node1idx] });
            nodeStepData.updatedNodesAfter.push({ ...nodes[node2idx] });

            // create node link id
            let nodeLinkId = createLinkedId(nodeId1, nodeId2);
            let nodeLinkCoords = [nodes[node1idx].shape.coordinates, nodes[node2idx].shape.coordinates];

            if (nodeLinkId)
            {
                const addedNodeLinkStepData = createNodeLinkStepDataWithNodeLinkIdAndNodes(nodeLinkId, nodes);
                nodeStepData.addedNodeLinks.push(addedNodeLinkStepData);
                nodeLinksMap[nodeLinkId] = nodeLinkCoords;
            }

            // update local db and refs
            updateLocalDB(nodeStepData);
            nodesRef.current = nodes;
            nodeLinksMapRef.current = nodeLinksMap;

            return nodeStepData;
        }

    }, [updateLocalDB, nodesRef.current, nodeLinksMapRef.current]);

    /**
    * deletes node from map click data and removes node from any existing node links. 
    * Saves step in local browser DB.
    * @param {Object} mapClickData
    * @param {Boolean} isReconnect
    */
    const handleDeleteNode = useCallback((mapClickData, isReconnect) =>
    {
        const { nodeId } = mapClickData;

        // check if node Id is transition
        // -> if it is, ignore deletion code and display
        if (mapEditorContext.state?.propertyTransitions?.transitionNodeIds[nodeId])
        {
            toast.error(EDITOR_ALERTS.CANT_DELETE_TRANSITION);
            return;
        }

        let nodes = deepCopy(nodesRef.current);
        let nodeLinksMap = deepCopy(nodeLinksMapRef.current);

        // step data 
        let nodeStepData = new NodeStepData();
        nodeStepData.deletedNodes = [];
        nodeStepData.updatedNodesAfter = [];
        nodeStepData.updatedNodesBefore = [];
        nodeStepData.addedNodeLinks = [];
        nodeStepData.deletedNodeLinks = [];

        // pull node using nodeId (deleteNodes)
        let nodeIdx = nodes.findIndex((n) => n._id === nodeId);
        let node = nodes[nodeIdx];

        nodeStepData.deletedNodes.push(deepCopy(node));

        // update all nodes linked to deleted node
        node.nodeLinks.forEach((nodeLink) =>
        {
            let linkedNodeId = nodeLink.linkedNode;

            // get node connections 
            // remove deleted node from other nodes node connections (updatedNodesBefore/After)
            let linkedNodeIDx = nodes.findIndex((n) => n._id === linkedNodeId);
            let linkedNode = nodes[linkedNodeIDx];

            if (linkedNode)
            {
                nodeStepData.updatedNodesBefore.push(deepCopy(linkedNode));

                linkedNode.nodeLinks = linkedNode.nodeLinks.filter(nodeLink =>
                {
                    if (nodeLink.linkedNode === nodeId)
                    {
                        let linkedId = createLinkedId(nodeId, linkedNodeId);
                        let deletedNodeLinkStepData = createNodeLinkStepDataWithNodeLinkIdAndNodes(linkedId, nodes);
                        nodeStepData.deletedNodeLinks.push(deletedNodeLinkStepData);
                        // might need to save a prev nodes state or use nodes ref here??? 
                        // nodeStepData.deletedNodeLinks.push({
                        //     nodeLinkId: createLinkedId(nodeId, linkedNodeId),
                        //     coordinates: [node.shape.coordinates, linkedNode.shape.coordinates],
                        //     linkedPriorityMultiplier: nodeLink.linkedPriorityMultiplier
                        // });
                    }
                    else 
                    {
                        return true;
                    }
                });

                nodes[linkedNodeIDx] = linkedNode;
                nodeStepData.updatedNodesAfter.push(deepCopy(linkedNode));

                delete nodeLinksMap[createLinkedId(nodeId, linkedNodeId)];
                delete nodeLinksMap[createLinkedId(linkedNodeId, nodeId)];
            }
            else
            {
                console.log("DEBUG: linked node undefined");
            }
        });

        // delete node from nodes ref
        nodes.splice(nodeIdx, 1);

        nodesRef.current = nodes;
        nodeLinksMapRef.current = nodeLinksMap;

        // reconnect node link code
        if (node.nodeLinks.length === 2 && isReconnect) 
        {
            // get ids of linked nodes
            let [nodeLink1, nodeLink2] = node.nodeLinks;
            let nodeLinkId1 = nodeLink1.linkedNode;
            let nodeLinkId2 = nodeLink2.linkedNode;

            let node1Idx = nodes.findIndex((n) => n._id === nodeLinkId1);
            let node1 = nodes[node1Idx];

            let node2Idx = nodes.findIndex((n) => n._id === nodeLinkId2);
            let node2 = nodes[node2Idx];

            // check if node1 and node 2 are already linked
            if (!nodeLinksIncludesId(node1, nodeLinkId2)) 
            {
                //  check if link is valid
                let isLinkValid = validateNodeLinkage({ newNodeLink: [node1.shape.coordinates, node2.shape.coordinates] });

                if (isLinkValid)
                {
                    // updated nodes after/before currently has two nodes that are about to be linked in the array
                    // we need to reset update node after array to avoid duplication 
                    // we don't need to add nodes to before array because they are already added
                    nodeStepData.updatedNodesAfter = [];

                    //a new node link between linked nodes and
                    let nodeLinkId = createLinkedId(nodeLinkId1, nodeLinkId2);

                    // update node linkages, each node should be linked to each other 
                    node1.nodeLinks.push(createNodeLink(node2));
                    node2.nodeLinks.push(createNodeLink(node1));

                    nodeStepData.updatedNodesAfter.push(node1);
                    nodeStepData.updatedNodesAfter.push(node2);

                    nodes[node1Idx] = node1;
                    nodes[node2Idx] = node2;

                    if (nodeLinkId)
                    {
                        const addedNodeLinkStepData = createNodeLinkStepDataWithNodeLinkIdAndNodes(nodeLinkId, nodes);
                        nodeStepData.addedNodeLinks.push(addedNodeLinkStepData);
                        nodeLinksMap[nodeLinkId] = [node1.shape.coordinates, node2.shape.coordinates];
                    }

                    nodesRef.current = nodes;
                    nodeLinksMapRef.current = nodeLinksMap;
                }
            }
        }

        updateLocalDB(nodeStepData);
        return nodeStepData;
    }, [updateLocalDB, nodesRef.current, nodeLinksMapRef.current, mapEditorContext.state?.propertyTransitions]);

    /**
    * deletes node link from nodeId1/nodeId2
    * Saves step in local browser DB.
    * @param {Object} mapClickData
    */
    const handleDeleteNodeLink = useCallback((mapClickData) =>
    {
        let { nodeId1, nodeId2 } = mapClickData;

        let nodes = deepCopy(nodesRef.current);
        let nodeLinksMap = deepCopy(nodeLinksMapRef.current);

        // step data 
        let nodeStepData = new NodeStepData();
        nodeStepData.updatedNodesAfter = [];
        nodeStepData.updatedNodesBefore = [];
        nodeStepData.deletedNodeLinks = [];

        let linkedId = createLinkedId(nodeId1, nodeId2);
        let deletedNodeLinkStepData = createNodeLinkStepDataWithNodeLinkIdAndNodes(linkedId, nodes);
        nodeStepData.deletedNodeLinks.push(deletedNodeLinkStepData);

        // pull node using nodeId (deleteNodes)
        let nodeIds = [nodeId1, nodeId2];

        nodeIds.forEach((nodeId) =>
        {
            // get node from node id
            let nodeIdx = nodes.findIndex((n) => n._id === nodeId);
            let node = nodes[nodeIdx];

            // save previous state 
            nodeStepData.updatedNodesBefore.push(deepCopy(node));

            // remove any node links that are in the nodeIds list
            node.nodeLinks = node.nodeLinks.filter(nodeLink => !nodeIds.includes(nodeLink.linkedNode));

            // save node in nodesRef
            nodes[nodeIdx] = node;

            // save current node state
            nodeStepData.updatedNodesAfter.push(deepCopy(node));
        });

        const deletedLinkedId1 = createLinkedId(nodeId1, nodeId2);
        const deletedLinkedId2 = createLinkedId(nodeId2, nodeId1);


        // create node link deletion step
        // nodeStepData.deletedNodeLinks.push(
        //     {
        //         nodeLinkId: deletedLinkedId1,
        //         coordinates: nodeLinksMap[deletedLinkedId1]
        //     },
        //     {
        //         nodeLinkId: deletedLinkedId2,
        //         coordinates: nodeLinksMap[deletedLinkedId2]
        //     });

        // delete node link from map
        delete nodeLinksMap[deletedLinkedId1];
        delete nodeLinksMap[deletedLinkedId2];

        //update refs
        nodesRef.current = nodes;
        nodeLinksMapRef.current = nodeLinksMap;

        // update step db 
        updateLocalDB(nodeStepData);

        // return outside action data to map 
        return nodeStepData;

    }, [updateLocalDB, nodesRef.current, nodeLinksMapRef.current]);

    /******************************************/
    /*** NODE/NODE-LINK VALIDATION HANDLERS ***/
    /******************************************/

    /**
     * validates node linkages
     * takes either array of node linkages or single node link to be validated
     * uses either state or passed nodeLinks/nodes
     * @param newNodeLink? - [point1, point2]
     * @param newNodeLinks? - [nodeLink] array of node links to validate
     * @param nodeLinks? - [nodeLink] array of existing node links
     * @param nodes? -  array of existing nodes
     */
    const validateNodeLinkage = useCallback(({ newNodeLink, newNodeLinks, nodeLinks, nodes }) => 
    {
        let isValid = true;

        let nodeLinksToValidate = [];
        let existingNodeLinks = [];
        let existingNodes = [];

        // create node links to validate array
        if (!!newNodeLink)
        {
            nodeLinksToValidate.push(turfLineString(newNodeLink));
        }
        else if (!!newNodeLinks)
        {
            newNodeLinks.forEach((nodeLink) =>
            {
                nodeLinksToValidate.push(turfLineString(nodeLink));
            });
        }

        // create existing node links array
        if (!!nodeLinks)
        {
            existingNodeLinks = nodeLinks;
        }
        else
        {
            const nodeLinksMap = deepCopy(nodeLinksMapRef.current);

            existingNodeLinks = Object.values(nodeLinksMap);
        }

        // create existing nodes array 
        if (!!nodes)
        {
            existingNodes = nodes;
        }
        else 
        {
            existingNodes = deepCopy(nodesRef.current);
        }

        const validateTwoNodeLinksHelper = (nodeLink1, nodeLink2) =>
        {
            let isValid = true;

            const [point1, point2] = nodeLink1.geometry.coordinates;

            const intersect = turfLineIntersect(nodeLink1, nodeLink2);

            if (intersect.features.length > 0)
            {
                // check if the intersect is an endpoint (would happen during snapps)
                // if (intersect.features.length === 1)
                // {
                const intersectCoord = intersect.features[0].geometry.coordinates;

                let isEndPoint = false;

                [point1, point2].forEach((coord) =>
                {
                    const distanceMM = getDistance(coord, intersectCoord);
                    if (distanceMM < 1) 
                    {
                        isEndPoint = true;
                    }
                });

                // if intersection is found but is endpoint, it is still valid 
                isValid = isEndPoint;
            }

            return isValid;
        };

        // check if node link intersects with another edited node link in the array
        for (let i = 0; i < nodeLinksToValidate.length - 1; i++)
        {
            const nodeLinkToValidate1 = nodeLinksToValidate[i];

            for (let j = i + 1; j < nodeLinksToValidate.length; j++)
            {
                const nodeLinkToValidate2 = nodeLinksToValidate[j];

                isValid = validateTwoNodeLinksHelper(nodeLinkToValidate1, nodeLinkToValidate2);

                if (!isValid)
                {
                    return false;
                }
            }
        }

        // check if node link line intersects another linkage
        for (let i = 0; i < existingNodeLinks.length; i++)
        {
            const existingNodeLinkLineString = turfLineString(existingNodeLinks[i]);

            // for each new node link to validate 
            for (let j = 0; j < nodeLinksToValidate.length; j++)
            {
                const newNodeLinkLineString = nodeLinksToValidate[j];

                isValid = validateTwoNodeLinksHelper(existingNodeLinkLineString, newNodeLinkLineString);

                if (!isValid)
                {
                    console.log("DEBUG - intersects with existing node link", existingNodeLinkLineString, newNodeLinkLineString);
                    return false;
                }
            }
        }

        // check if line intersects a node thats not an end point of the line
        for (let i = 0; i < existingNodes.length; i++)
        {
            // if point is within 1 mm from a line and the point is not one of the endpoints of the the new line
            // it is not valid

            // for each node link to be validated 
            for (let j = 0; j < nodeLinksToValidate; j++)
            {
                const newNodeLinkLineString = nodeLinksToValidate[j];
                const [point1, point2] = newNodeLinkLineString.geometry.coordinates;

                const nodeTurfPoint = turfPoint(existingNodes[i].shape.coordinates);
                const distanceFromLineCM = turfPointToLineDistance(nodeTurfPoint, newNodeLinkLineString, { units: "centimeters" });

                if (distanceFromLineCM < 10)
                {
                    let isEndPoint = false;

                    [point1, point2].forEach((coord) =>
                    {
                        const distanceMM = getDistance(coord, nodeTurfPoint);

                        if (distanceMM < 1) 
                        {
                            console.log("DEBUG - intersects with node", existingNodes[i], nodeLinksToValidate[j]);
                            isEndPoint = true;
                        }
                    });

                    // don't break if intersect is an endpoint
                    if (isEndPoint)
                    {
                        continue;
                    }

                    return false;

                }
            }
        }

        return isValid;
    }, [nodeLinksMapRef, nodesRef]);

    /**
     * check if new autolink line intersects any lines
     */
    const handleValidateAutoLink = (feature) =>
    {
        const geometry = feature.getGeometry();
        const coordinate = mercatorToWgs84(geometry.getCoordinates() || geometry.getCenter(), ShapeTypes.POINT);

        const prevAutoLinkedNode = handleGetPrevAutoLinkedNode();

        if (!prevAutoLinkedNode)
        {
            return true;
        }

        let point1 = prevAutoLinkedNode.shape.coordinates;
        let point2 = coordinate;

        return validateNodeLinkage({ newNodeLink: [point1, point2] });
    };

    /**
     * handles the translation of node features
     * @param {*} features 
     * @returns 
     */
    const handleEditNodes = (features) =>
    {
        // get updated nodes
        let editedNodes = [];
        let editedNodeLinksHash = {};
        let isValid = true;
        let validationError = undefined;

        let nodes = deepCopy(nodesRef.current);
        let nodeLinks = deepCopy(nodeLinksMapRef.current);

        // step data 
        let nodeStepData = new NodeStepData();
        nodeStepData.updatedNodesAfter = [];
        nodeStepData.updatedNodesBefore = [];
        nodeStepData.addedNodeLinks = [];
        nodeStepData.deletedNodeLinks = [];

        // add old node links to deleted list  
        const updatedNodeLinks = handleGetNodeLinksForSelectedNodes(features);
        updatedNodeLinks.forEach((nodeLink) =>
        {
            let addedNodeLinkStep = createNodeLinkStepDataWithNodeLinkIdAndNodes(nodeLink.nodeLinkId, nodes);
            nodeStepData.deletedNodeLinks.push(addedNodeLinkStep);
        });

        // for each feature in the set
        features.forEach((feature) => 
        {
            // get nodeId
            const nodeId = feature.getId();

            // get new geometry from feature
            const nodeGeometry = feature.getGeometry();

            const shape = {
                type: nodeGeometry.getType(),
                coordinates: mercatorToWgs84((nodeGeometry.getCoordinates() || nodeGeometry.getCenter()), ShapeTypes.POINT)
            };

            editedNodes.push({
                _id: nodeId,
                shape: shape,
            });
        });

        // update local nodes with edited nodes
        let editedNodeIdHash = {};

        // update nodes
        editedNodes.forEach((node) =>
        {
            const nodeIdx = nodes.findIndex((n) => n._id === node._id);

            let localNode = deepCopy(nodes[nodeIdx]);

            nodeStepData.updatedNodesBefore.push(deepCopy(localNode));

            // update node
            editedNodeIdHash[node._id] = true;
            localNode.shape.coordinates = node.shape.coordinates;
            localNode.entityId = -1;

            const nodePoint = turfPoint(localNode.shape.coordinates);

            // set new entityId if node is inside an entity
            // we are expecting nodes to be sorted by plotting level so that the highest plotting level entities will be checked first
            for (let i = 0; i < entities.length; i++)
            {
                const entityI = entities[i];

                if (!entityI?.turfPolygon)
                {
                    continue;
                }
                // if node is inside of a polygon
                if ((entityI.isRoutable || entityI.isNodeForbidden) && booleanPointInPolygon(nodePoint, entityI.turfPolygon))
                {
                    // check if node is placed over floor opening 
                    if (entityI.isNodeForbidden)
                    {
                        isValid = false;
                        validationError = EDITOR_ALERTS.NODE_FORBIDDEN;
                    }

                    // set entity id and break
                    localNode.entityId = entityI._id;
                    break;

                }
            }

            nodeStepData.updatedNodesAfter.push(deepCopy(localNode));

            nodes[nodeIdx] = localNode;
        });

        // update node links
        if (isValid)
        {
            editedNodes.forEach((node) =>
            {
                const nodeIdx = nodes.findIndex((n) => n._id === node._id);

                let localNode = deepCopy(nodes[nodeIdx]);

                const localNodeCoordinate = localNode.shape.coordinates;
                const nodeId = localNode._id;

                localNode.nodeLinks.forEach((nodeLink, i) =>
                {
                    const { linkedNode: linkedNodeId } = nodeLink;

                    // get node connections 
                    let linkedNodeIDx = nodes.findIndex((n) => n._id === linkedNodeId);

                    if (linkedNodeIDx === -1)
                    {
                        return;
                    }

                    let linkedNode = nodes[linkedNodeIDx];

                    let linkedNodeCoordinate = linkedNode.shape.coordinates;

                    let nodeLinkId = createLinkedId(nodeId, linkedNode._id);

                    if (!nodeLinks[nodeLinkId])
                    {
                        nodeLinkId = createLinkedId(linkedNode._id, nodeId);
                    }

                    // delete from node links map so we can check if any edited node link intersects with an existing node link
                    delete nodeLinks[nodeLinkId];
                    editedNodeLinksHash[nodeLinkId] = [localNodeCoordinate, linkedNodeCoordinate];
                });
            });

            // validate edited node links
            isValid = validateNodeLinkage({
                newNodeLinks: Object.values(editedNodeLinksHash),
                nodeLinks: Object.values(nodeLinks),
                nodes: nodes
            });

            if (!isValid)
            {
                validationError = EDITOR_ALERTS.NOT_VALID_NODE_LINK;
            }

            // validate edited nodes
            else
            {
                for (let i = 0; i < editedNodes.length; i++)
                {
                    const editedNode = editedNodes[i];
                    isValid = handleValidateNode(editedNode, nodes, editedNodeIdHash);

                    // break if not valid 
                    if (!isValid)
                    {
                        validationError = EDITOR_ALERTS.OVERLAPING_NODES;
                        break;
                    }
                }
            }
        }

        // update local data
        if (isValid)
        {
            // add old node links to deleted list  
            const updatedNodeLinks = handleGetNodeLinksForSelectedNodes(features);
            updatedNodeLinks.forEach((nodeLink) =>
            {
                let addedNodeLinkStep = createNodeLinkStepDataWithNodeLinkIdAndNodes(nodeLink.nodeLinkId, nodes);
                nodeStepData.addedNodeLinks.push(addedNodeLinkStep);
            });

            // add edited node links to node links map
            Object.assign(nodeLinks, editedNodeLinksHash);

            // update nodes ref
            nodesRef.current = nodes;

            // update node links
            nodeLinksMapRef.current = nodeLinks;

            // update local db 
            updateLocalDB(nodeStepData);
        }

        return { isValid, nodeStepData, validationError };
    };

    /**
     * get selected node links, make sure there are no duplicates in either direction
     * @param {*} features 
     * @returns 
     */
    const handleGetNodeLinksForSelectedNodes = (features) => 
    {
        const nodes = deepCopy(nodesRef.current);

        let selectedIdMap = {};
        let selectedNodeLinks = [];

        // loop through each filter to pull node links
        features.forEach((feature) =>
        {
            const nodeId = feature.getId();

            const node = nodes.find((n) => n._id === nodeId);

            node.nodeLinks.forEach((nodeLink) =>
            {
                const nodeI = nodes.find((n) => n._id === nodeLink.linkedNode);

                if (nodeI)
                {
                    selectedNodeLinks.push({
                        nodeLinkId: createLinkedId(nodeId, nodeLink.linkedNode),
                        coordinates: [node.shape.coordinates, nodeI.shape.coordinates],
                    });
                }
            });

        });

        // filter node links to ensure no duplicates added
        selectedNodeLinks = selectedNodeLinks.filter((nodeLink) =>
        {

            const { nodeId1, nodeId2 } = extractNodeIdsFromNodeLinkId(nodeLink.nodeLinkId);
            const linkedId2 = createLinkedId(nodeId2, nodeId1);

            if (selectedIdMap[nodeLink.nodeLinkId] || selectedIdMap[linkedId2])
            {
                return false;
            }
            else
            {
                selectedIdMap[nodeLink.nodeLinkId] = true;
                selectedIdMap[linkedId2] = true;
                return true;
            }
        });

        return selectedNodeLinks;
    };

    /**
     * This function handles getting the selected node link with the given node IDs.
     *
     * @param {String} nodeId1 - cms nodeId
     * @param {String} nodeId2 - cms nodeId
     * @return {Array} array of size 2 with the selected node link in both directions with attached node Ids
     */
    const handleGetNodeLinksWithNodeIds = useCallback((nodeId1, nodeId2, nodes) =>
    {
        if (!nodes)
        {
            nodes = deepCopy(nodesRef.current);
        }
        const node1 = nodes.find((n) => n._id === nodeId1);
        const node2 = nodes.find((n) => n._id === nodeId2);

        const getNodeLinkHelper = (startNode, endNode) =>
        {
            let nodeLink = {
                startNode_id: startNode._id,
                startNodeId: startNode.nodeId,
                endNode_id: endNode._id,
                endNodeId: endNode.nodeId,
            };

            startNode.nodeLinks.forEach((nodeLinkItt) =>
            {
                if (nodeLinkItt.linkedNode === endNode._id)
                {
                    Object.assign(nodeLink, nodeLinkItt);
                }
            });

            return nodeLink;
        }

        const nodeLink1 = getNodeLinkHelper(node1, node2);
        const nodeLink2 = getNodeLinkHelper(node2, node1);

        return [nodeLink1, nodeLink2];
    }, [nodesRef.current]);

    /**
     * Update local/indexDB nodes with updated node link priority data
     * return outside step action for map to update link styles
     */
    const handleUpdateNodeLinks = useCallback((nodeLinks) =>
    {
        let nodes = deepCopy(nodesRef.current);

        let nodeStepData = new NodeStepData();

        nodeStepData.deletedNodeLinks = [];
        nodeStepData.addedNodeLinks = [];
        nodeStepData.updatedNodesBefore = [];
        nodeStepData.updatedNodesAfter = [];

        let linkedNodeId;

        let node1, node2;

        nodeLinks.forEach((nodeLink) => 
        {
            let startNode_id = nodeLink.startNode_id;
            let endNode_id = nodeLink.endNode_id;

            linkedNodeId = createLinkedId(startNode_id, endNode_id);

            let startNode = nodes.find((node) => node._id === startNode_id);
            let endNode = nodes.find((node) => node._id === endNode_id);

            if (!node1)
            {
                node1 = startNode;
                node2 = endNode;
                let linkedId = createLinkedId(startNode_id, endNode_id);
                let deletedNodeLinkStep = createNodeLinkStepDataWithNodeLinkIdAndNodes(linkedId, nodes);
                nodeStepData.deletedNodeLinks.push(deletedNodeLinkStep);
                nodeStepData.updatedNodesBefore.push(deepCopy(node1), deepCopy(node2));
            }

            startNode.nodeLinks.forEach((startNodeLink) =>
            {
                if (startNodeLink.linkedNode === endNode_id)
                {
                    startNodeLink.linkedPriorityMultiplier = nodeLink.linkedPriorityMultiplier;
                }
            });

        });

        let addedNodeLinkStep = createNodeLinkStepDataWithNodeLinkIdAndNodes(linkedNodeId, nodes);
        nodeStepData.addedNodeLinks.push(addedNodeLinkStep);

        nodeStepData.updatedNodesAfter.push(deepCopy(node1), deepCopy(node2));

        // update local db 
        updateLocalDB(nodeStepData);
        // saved node ref
        nodesRef.current = nodes;
        // return step data to be applied to map
        return nodeStepData;

    }, [nodesRef]);

    /**
     * Returns list of node features attached to the entity
     * if nodeId is passed -> will also 
     * 
     * @param {String} entityId cms ID
     * @param {String} nodeId cms ID
     */
    const handleManageEntityNodes = useCallback((entityId, nodeId) =>
    {
        if (!entityId)
        {
            return;
        }

        // step data
        let nodeStepData = new NodeStepData();
        nodeStepData.updatedNodesAfter = [];
        nodeStepData.updatedNodesBefore = [];
      

        // fetch entity
        const mapData = mapEditorContext.state?.mapData;
        let localNodes = deepCopy(nodesRef.current);

        const entities = mapData.topology.objects.entities.geometries;
        const entityAccessArray = entities.map((entity) => new CMSTopologyEntityAccess(entity, mapData.topology));

        const entity = entityAccessArray.find((entityAccess) => entityAccess.getId() === entityId);

        // check if entity is routable 
        if (!entity.getIsRoutableEntity()) 
        {
            return;
        }

        // find all nodes attached to entity
        let associatedNodes = localNodes.filter((node) => node.entityId === entityId);

        if (nodeId)
        {
            // remove selected node if its in already in the list
            let updatedAssociatedNodes = associatedNodes.filter((node) => node._id !== nodeId);

            // if its not in the list find the node update it and add it to the list  
            if (updatedAssociatedNodes.length === associatedNodes.length)
            {
                const addedNodeIdx = localNodes.findIndex((node) => node._id === nodeId);
                if (addedNodeIdx)
                {
                    nodeStepData.updatedNodesBefore.push(localNodes[addedNodeIdx]);
                    let addedNode = deepCopy(localNodes[addedNodeIdx]);

                    addedNode.entityId = entityId;
                    updatedAssociatedNodes.push(addedNode);

                    localNodes[addedNodeIdx] = addedNode;
                    nodeStepData.updatedNodesAfter.push(addedNode);
                }
            }
            else
            {
                // update removed node 
                const removedNodeIdx = localNodes.findIndex((node) => node._id === nodeId);
                if (removedNodeIdx)
                {
                    nodeStepData.updatedNodesBefore.push(localNodes[removedNodeIdx]);
                    let removedNode = deepCopy(localNodes[removedNodeIdx]);
                    removedNode.entityId = undefined;

                    localNodes[removedNodeIdx] = removedNode;
                    nodeStepData.updatedNodesAfter.push(removedNode);
                }
            }

            associatedNodes = updatedAssociatedNodes;

            // update step data only if node is passed
            updateLocalDB(nodeStepData);
        }

        // update nodes ref
        nodesRef.current = localNodes;

        const associatedNodeIds = associatedNodes.map((node) => node._id);

        return associatedNodeIds;
    });

    /**
     * Checks if (edited) node is valid 
     * @param {*} node 
     * @param {*} nodes 
     * @param {*} ignoreHash 
     * @returns 
     */
    const handleValidateNode = (node, nodes, ignoreHash) =>
    {
        let isValid = true;

        const nodePoint = turfPoint(node.shape.coordinates);

        // move to function?
        for (let i = 0; i < nodes.length; i++)
        {
            const nodeI = nodes[i];

            // if node J is an edited node, ignore 
            if (ignoreHash[nodeI._id])
            {
                continue;
            }

            const distanceMM = getDistance(nodePoint, nodeI.shape.coordinates);


            if (distanceMM < NODE_DISTANCE_THRESHOLD_MM)
            {
                isValid = false;
                break;
            }
        }

        return isValid;
    };

    /******************************************/
    /********** UNDO / REDO HANDLERS **********/
    /******************************************/

    const handleUndoNodeChanges = useCallback(async (step) =>
    {
        if (!step)
        {
            return;
        }

        let nodes = deepCopy(nodesRef.current);
        let autoLinkedNodes = deepCopy(autoLinkedNodesRef.current);
        let nodeLinksMap = deepCopy(nodeLinksMapRef.current);

        let nodeStepData = new NodeStepData(step.data);

        let mapStepData = reverseStepData(step);

        // if added nodes - remove the added nodes from the map 
        if (Array.isArray(nodeStepData.addedNodes))
        {
            // remove each added node.
            nodeStepData.addedNodes.forEach((addedNode) =>
            {
                // remove the node from the local array for validation checks
                let idxOfAddedNode = nodes.findIndex((n) => n._id === addedNode._id);
                nodes.splice(idxOfAddedNode, 1);
            });

            // update local DB
            await mapEditorLocalDb.deleteMany(CollectionNameMap.nodes, nodeStepData.addedNodes);
        }
        // if deleted nodes -> add the deleted nodes back to the map 
        if (Array.isArray(nodeStepData.deletedNodes))
        {
            // if step tool was auto link (redoing undone auto linked changes) && is auto link  
            // -> add to auto link chain
            nodeStepData.deletedNodes.forEach((deletedNode) =>
            {
                nodes.push(deletedNode);
            });

            // update local DB
            await mapEditorLocalDb.insertMany(CollectionNameMap.nodes, nodeStepData.deletedNodes);
        }
        // if updated nodes -> revert the nodes to their state before the update
        if (Array.isArray(nodeStepData.updatedNodesBefore))
        {
            nodeStepData.updatedNodesBefore.forEach((updatedNode) =>
            {
                let idxOfupdatedNode = nodes.findIndex((n) => n._id === updatedNode._id);

                nodes[idxOfupdatedNode] = updatedNode;
            });

            await mapEditorLocalDb.updateMany(CollectionNameMap.nodes, nodeStepData.updatedNodesBefore);
        }

        // if added nodes to the auto link array -> remove nodes from auto link array
        if (Array.isArray(nodeStepData.autoLinkAddedNodes))
        {
            nodeStepData.autoLinkAddedNodes.forEach((node) =>
            {
                // remove node from autolink array
                // since a node can be in the auto link array multiple times (if user snaps to an existing node) we search for the last occurrence
                let lastIdxOfAddedNodeInAutoLink = findLastIdx(autoLinkedNodes, (n) => n._id === node._id);
                if (lastIdxOfAddedNodeInAutoLink !== -1)
                {
                    autoLinkedNodes.splice(lastIdxOfAddedNodeInAutoLink, 1);
                }
            });
        }

        // if removed node from auto link array -> add nodes to auto link array
        if (Array.isArray(nodeStepData.autoLinkRemovedNodes))
        {
            let reversedAutoLinkRemovedNodes = nodeStepData.autoLinkRemovedNodes.reverse();
            reversedAutoLinkRemovedNodes.forEach((node) =>
            {
                if (step.autoLinkId === autoLinkIdRef.current)
                {
                    autoLinkedNodes.push(node);
                }
            });
        }

        if (Array.isArray(nodeStepData.addedNodeLinks))
        {
            nodeStepData.addedNodeLinks.forEach((nodeLink) =>
            {
                // delete the node link from the map, check for both variations of the node link key
                const { nodeId1, nodeId2 } = extractNodeIdsFromNodeLinkId(nodeLink.nodeLinkId);

                delete nodeLinksMap[nodeLink.nodeLinkId];
                delete nodeLinksMap[createLinkedId(nodeId2, nodeId1)];
            });
        }
        if (Array.isArray(nodeStepData.deletedNodeLinks))
        {
            nodeStepData.deletedNodeLinks.forEach((nodeLink) =>
            {
                nodeLinksMap[nodeLink.nodeLinkId] = nodeLink.coordinates;
            });
        }

        // update refs
        nodesRef.current = nodes;
        autoLinkedNodesRef.current = autoLinkedNodes;
        nodeLinksMapRef.current = nodeLinksMap;

        mapEditorContext.handleAddStepToActionQueue(mapStepData);

    }, [nodesRef.current, autoLinkedNodesRef.current, nodeLinksMapRef.current, findNodeById, autoLinkIdRef, mapEditorContext.handleAddStepToActionQueue]);

    const handleRedoNodeChanges = useCallback(async (step) =>
    {
        // reverse the steps objects and run undo logic
        let stepData = reverseStepData(step);

        step.data = stepData;

        handleUndoNodeChanges(step);

    }, [handleUndoNodeChanges]);

    const reverseStepData = (step) =>
    // eslint-disable-next-line arrow-body-style
    {
        return new NodeStepData({
            addedNodes: step.data.deletedNodes,
            deletedNodes: step.data.addedNodes,
            updatedNodesAfter: step.data.updatedNodesBefore,
            updatedNodesBefore: step.data.updatedNodesAfter,

            addedNodeLinks: step.data.deletedNodeLinks,
            deletedNodeLinks: step.data.addedNodeLinks,

            autoLinkAddedNodes: step.data.autoLinkRemovedNodes,
            autoLinkRemovedNodes: step.data.autoLinkAddedNodes
        });
    };

    const handleUndoRedoClick = useCallback((shortcut) =>
    {
        if (!mapEditorContext?.state?.isLevelLockedByUser || isUndoLocked)
        {
            return;
        }
        switch (shortcut)
        {
            case MAP_EDITOR_SHORTCUTS.UNDO:
                {
                    onUndo(handleUndoNodeChanges);
                    break;
                }
            case MAP_EDITOR_SHORTCUTS.REDO:
                {
                    onRedo(handleRedoNodeChanges);
                    break;
                }
        }

    }, [handleUndoNodeChanges, handleRedoNodeChanges, isUndoLocked, mapEditorContext?.state?.isLevelLockedByUser]);

    // use effect to watch for outside undo redo
    useEffect(() =>
    {
        let undoRedoButtonClick = mapEditorContext?.state?.undoRedoButtonClick;

        if (undoRedoButtonClick === undefined)
        {
            return;
        }
        else 
        {
            handleUndoRedoClick(undoRedoButtonClick);
        }

        mapEditorContext.handleSetUndoRedoButtonClick(undefined);

    }, [mapEditorContext?.state?.undoRedoButtonClick]);

    const showNotLockedByUserAlert = useCallback((alertsMessage) =>
    {
        console.log("alert to trigger");
        toast.error(alertsMessage);
    }, []);

    const changeSelectedTool = useCallback((toolName) =>
    {
        mapEditorContext.handleChangeSelectedTool(toolName, (alertsMessage) =>
        {
            showNotLockedByUserAlert(alertsMessage);
        });
    }, [mapEditorContext, showNotLockedByUserAlert]);

    const handleEscapeClick = useCallback(() =>
    {
        changeSelectedTool(undefined);
    }, [changeSelectedTool]);


    /******************************************/
    /*********** KEYBOARD SHORTCUTS ***********/
    /******************************************/

    useKeyboardShortcut("Control", [MAP_EDITOR_SHORTCUTS.UNDO, MAP_EDITOR_SHORTCUTS.REDO], (shortcut) => handleUndoRedoClick(shortcut));
    useSingleKeyboardShortcut("Escape", handleEscapeClick);

    useKeyboardShortcut("shift", Object.values(NODE_TOOL_SHORTCUTS), (shortcut) => 
    {
        switch (shortcut)
        {
        case NODE_TOOL_SHORTCUTS.NODE:
        {
            changeSelectedTool(MAP_EDITOR_TOOLS.Create);
            break;
        }
        case NODE_TOOL_SHORTCUTS.AUTO_LINK:
        {
            changeSelectedTool(MAP_EDITOR_TOOLS.AutoLink);
            break;
        }
        case NODE_TOOL_SHORTCUTS.DELETE:
        {
            changeSelectedTool(MAP_EDITOR_TOOLS.Delete);
            break;
        }
        case NODE_TOOL_SHORTCUTS.DELETE_RECONNECT:
        {
            changeSelectedTool(MAP_EDITOR_TOOLS.Delete_Reconnect);
            break;
        }
        case NODE_TOOL_SHORTCUTS.LINK:
        {
            changeSelectedTool(MAP_EDITOR_TOOLS.Link);
            break;
        }
        case NODE_TOOL_SHORTCUTS.HAND:
        {
            changeSelectedTool(undefined);
            break;
        }
        default:
        {
            break;
        }
        }
    });

    return (
        <NodeEditorMap
            isFloor={!!floorId}
            smartFilter={nodesSmartFilter}
            onGetPrevAutoLinkedNode={handleGetPrevAutoLinkedNode}
            onDrawNode={handleDrawNode}
            onDrawNodeSnappedToNode={handleDrawNodeSnappedToNode}
            onDrawNodeSnappedToNodeLink={handleDrawNodeSnappedToNodeLink}
            onDeleteNode={handleDeleteNode}
            onDeleteNodeLink={handleDeleteNodeLink}
            onLinkNodes={handleLinkNodes}
            onValidateAutoLink={handleValidateAutoLink}
            onSnapNode={handleOnSnapNode}
            onEditNodes={handleEditNodes}
            onGetNodeLinksForSelectedNodes={handleGetNodeLinksForSelectedNodes}
            onGetNodeLinksWithNodeIds={handleGetNodeLinksWithNodeIds}
            onUpdateNodeLinks={handleUpdateNodeLinks}
            onManageEntityNodes={handleManageEntityNodes}
            selectedFilterItem={selectedFilterItem}
        />
    );
};