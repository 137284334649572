import { useMemo } from "react";

/**
 * Returns the memoized result of {...object1, ...object2}
 */
export function useMergedObjects<A, B>(object1 : A, object2 : B)
{
    const memo = useMemo(() => ({ ...object1, ...object2 }), [object2, object1]);
    return memo;
}
