import React, { useCallback, useState } from "react";
import MapButton from "../../components/MapButton";
import { Dropdown } from "semantic-ui-react";
import { NO_RESULTS_FOUND, ZONE_MAP_OPTION_TYPS } from "../../../../_constants/constants";
import { useTranslation } from "react-i18next";

const ZoneMapSearchBar = ({ 
    dropdownOptions = [], 
    onEntitySelect,
    onZoneSelect, 
}) =>
{
    const [isOpen, setIsOpen] = useState(false);
    const [searchOptions, setSearchOptions] = useState([]);
    const [isSearch, setIsSearch] = useState(false);

    const toggleSearch = () => setIsOpen((current) => !current);

    const handleChange = (e, { value }) =>
    {
        
        const valueSplitParts = value.split("__");
        const optionType = valueSplitParts[valueSplitParts.length - 1]; 
        
        if (optionType === ZONE_MAP_OPTION_TYPS.ENTITY)
        {
            onEntitySelect(valueSplitParts[0]);
        }
        else if (optionType === ZONE_MAP_OPTION_TYPS.ZONE)
        {
            onZoneSelect(valueSplitParts[0]);
        }

        setSearchOptions([]);
        toggleSearch();
        setIsSearch(false);
    };

    const handleSearchChange = useCallback((e, { searchQuery }) =>
    {
        if (searchQuery === "")
        {
            setSearchOptions([]);
            setIsSearch(false);

        }
        else
        {
            searchQuery = searchQuery.toLowerCase();

            const searchOptions = dropdownOptions.filter((option) => 
            {
                if (option.type === ZONE_MAP_OPTION_TYPS.ENTITY)
                {
                    return option.text.toLowerCase().includes(searchQuery) 
                    || option.description.toLowerCase().includes(searchQuery)
                    || option.entityId === +searchQuery
                    || (option.buildingId && option.buidlingId === +searchQuery);
                }
                else if (ZONE_MAP_OPTION_TYPS.ZONE)
                {
                    return option.text === searchQuery
                    || option.text.toLowerCase().includes(searchQuery);
                }
                
            });

            searchOptions.sort((a, b) =>
            {
                let aDist = a.text.toLowerCase().indexOf(searchQuery);
                let bDist = b.text.toLowerCase().indexOf(searchQuery);

                if (aDist === -1)
                {
                    aDist = a.description.toLowerCase().indexOf(searchQuery);
                }

                if (bDist === -1)
                {
                    bDist = b.description.toLowerCase().indexOf(searchQuery);
                }

                return aDist - bDist;
            });

            setSearchOptions(searchOptions);
            setIsSearch(true);

        }

    }, [dropdownOptions]);

    const handleSearchOverwrite = () => searchOptions;

    const trans = useTranslation().t;

    return (
        <>
            <MapButton icon="search-default" onClick={toggleSearch} /> {/* this is trigger */}

            {
                (isOpen) && (
                    <Dropdown className="searchDropdown"
                        search={handleSearchOverwrite}
                        placeholder={trans("Inventory.Search")}
                        searchInput={{ autoFocus: true }}
                        selectOnBlur={false}
                        onBlur={toggleSearch}
                        options={searchOptions}
                        onChange={handleChange}
                        onSearchChange={handleSearchChange}
                        openOnFocus={false}
                        noResultsMessage={isSearch ? NO_RESULTS_FOUND : null }
                        selectOnNavigation={false}
                    />
                )
            }

        </>
    );
};

export default ZoneMapSearchBar;