import React from "react";
import { Trans } from "react-i18next";

const { StructureEntityType, ObstacleEntityType } = require("mapsted.maps/utils/entityTypes");

export const nonNamableStructureSubEntityTypes = [
    StructureEntityType.FLOOR_OPENING,
    StructureEntityType.INACCESSIBLE,
    StructureEntityType.PATHWAY,
    StructureEntityType.PATHWAY_INNER_BOUNDARY,
    StructureEntityType.RAMP,
    StructureEntityType.STAIRS,
    StructureEntityType.STEPS,
    StructureEntityType.MOVING_WALKWAY
];

export const nonNamableObstacleSubEntityTypes = [
    ObstacleEntityType.WALL
];

export const entityRefTypes = {
    PROPERTY: <Trans i18nKey="branding.Property" />,
    FLOOR: <Trans i18nKey="branding.Floor" />
};

export const CATEGORY_EDIT_TYPE = {
    GROUP: {
        isRoot: true,
        title: "category",
        type: "group",
        heading: {
            new: <Trans i18nKey="branding.Add_Group" />,
            edit: <Trans i18nKey="branding.Edit_Group" />
        },
        subHeading: {
            new: <Trans i18nKey="branding.Group_Name" />,
            edit: <Trans i18nKey="branding.Group_Name" />,
        },
        deleteModal: {
            title: <Trans i18nKey="branding.Delete_Group" />,
            body: <Trans i18nKey="branding.Are_you_sure_you_want_to_delete_the_grou" />
        },
        subListHeader: <Trans i18nKey="branding.Assign_Categories" />,
        duplicationError: <Trans i18nKey="branding.Group_Duplication_Error" />
    },
    CATEGORY: {
        title: "sub-category",
        type: "category",
        heading: {
            new: <Trans i18nKey="branding.Add_Category" />,
            edit: <Trans i18nKey="branding.Edit_Category" />
        },
        subHeading: {
            new: <Trans i18nKey="branding.Category_Name" />,
            edit: <Trans i18nKey="branding.Category_Name" />,
        },
        deleteModal: {
            title: <Trans i18nKey="branding.Delete_Category" />,
            body: <Trans i18nKey="branding.Are_you_sure_you_want_to_delete_the_cate" />
        },
        subListHeader: <Trans i18nKey="branding.Assign_SubCategories" />,
        duplicationError: <Trans i18nKey="branding.Category_Duplication_Error" />
    },
    SUB_CATEGORY: {
        type: "subCategory",
        heading: {
            new: <Trans i18nKey="branding.Add_Subcategory" />,
            edit: <Trans i18nKey="branding.Edit_Subcategory" />
        },
        subHeading: {
            new: <Trans i18nKey="branding.Subcategory_Name" />,
            edit: <Trans i18nKey="branding.Subcategory_Name" />,
        },
        deleteModal: {
            title: <Trans i18nKey="branding.Delete_Subcategory" />,
            body: <Trans i18nKey="branding.Are_you_sure_you_want_to_delete_the_subc" />
        },
        duplicationError: <Trans i18nKey="branding.Subcategory_Duplication_Error" />
    }
};

export const DEFAULT_CATEGORY_ICON = {
    _id: "5f62696980b73761f04da1b8",
    deleted: false,
    kind: "CategoryIcon",
    name: "Photo",
    iconImage: "47856b10-f7ec-11ea-bd79-4174b943bc5a",
    createdBy: "jay",
    format: "png",
    createdAt: "2020-09-16T19:37:13.685Z",
    updatedAt: "2020-09-16T19:37:13.685Z",
    __v: 0
};

export const COLORS = [
    "#ffffff", "#000000", "#333333", "#666666", "#aaaaaa", "#DB3E00", "#FCB900", "#FFEB3B", "#4CAF50", "#0693e3", "#7b1fa2"
];

export const MAX_MERGE_SIZE = 3;

export const DRAW_LAYER = "drawLayer";

export const CLUSTER_DISTANCE = 30;

export const accordionIndex = {
    BASIC_INFORMATION: 0,
    KEYWORDS: 1,
    ONLINE_KEYWORDS: 2,
    OPENING_HOURS: 3,
    SOCIAL_LINKS: 4,
    INVENTORY: 5
};

export const BRANDING_STATUS_DESCRIPTION = {
    1: { code: 1, name: <Trans i18nKey="branding.Draft_version_active" />, className: "operational", incomplete: false, i18nKey: "branding.Draft_version_active" },
    2: { code: 2, name: <Trans i18nKey="branding.Published" />, className: "operational", incomplete: false, i18nKey: "branding.Published" },
};

export const BRANDING_STATUSES = {
    DRAFT: 1,
    PUBLISHED: 2
};

export const MIN_SPLIT_AREA = 1;

export const ENTITY_STYLES = {
    USE_ICON: "useIcon",
    USE_TEXT: "useText",
};

export const DELETE_CATEGORY_MODAL = {
    NONE: 0,
    STORE_LIST: 1,
    WARNING: 2,
    SUB_CATEGORY_STORE_LIST: 3,
};

export const IMAGE_TYPE = {
    iconImage: "Default Icon Image",
    darkIcon: "Dark Icon Image",
    lightIcon: "Light Icon Image",
    coverImages: "Cover Image"
};
