import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Tab, Button, Icon } from "semantic-ui-react";
import { setWith as setDeepValue, isEmpty } from "lodash";
import { SettingsHeading } from "../SettingsHeading";
import { SettingsContext } from "../SettingsProvider";
import { ErrorLabel } from "../../elements/ErrorLabel";
import ConfigurationOptions from "./ConfigurationOptions";
import CustomizationOptions from "./CustomizationOptions";
import AdvancedCustomizationOptions from "./AdvancedCustomizationOptions";

const MapstedMapsModule = ({ activeMenu, onChangeActiveMenu, onChangeZoomMode, setIsBuilding, setZoomData, setRotationData, setShowModeSwitcher, setShowMaxZoomLevelAdjuster }) =>
{
    const trans = useTranslation().t;
    const { mergedSettings: settings, mutate } = useContext(SettingsContext);

    const handleChangeValue = (path, value) =>
    {
        const updates = setDeepValue({ ...settings }, path, value, Object);
        mutate(updates);
    };

    const OPTIONS = {
        CONFIGURATION: {
            id: "configuration",
            name: trans("Settings.Config_Options"), 
            detail: trans("Settings.Configuration_Options_Detail"),
            nameTransKey: "Settings.Config_Options",
            detailsTransKey: "Settings.Configuration_Options_Detail"
        },
        CUSTOMIZATION: { 
            id: "customization",
            name: trans("Settings.Customization_Options"), 
            detail: trans("Settings.Customization_Options_Detail"),
            nameTransKey: "Settings.Customization_Options",
            detailsTransKey: "Settings.Customization_Options_Detail"
        },
        ADVANCED_CUSTOMIZATION: { 
            id: "advancedCustomization",
            name: trans("Settings.Advanced_Customization_Options"), 
            detail: trans("Settings.Advanced_Customization_Options_Detail"),
            nameTransKey: "Settings.Advanced_Customization_Options",
            detailsTransKey: "Settings.Advanced_Customization_Options_Detail"
        }
    };

    const renderErrors = (errors) =>
    {
        if (isEmpty(errors)) return null;

        return (
            <ErrorLabel multiple>
                {trans("CreateMapOverlaysSideBar.Validation_Error_Header")}
                <ul>
                    {errors.map((error) => <li key={error}>{trans(error)}</li>)}
                </ul>
            </ErrorLabel>
        );
    };

    const renderHeading = () =>
    {
        const showHeading = !activeMenu;
        const showLinkHeading = !!activeMenu;
        return (
            <SettingsHeading
                heading={showHeading ? trans("MapstedMapsModule.Mapsted_Maps_Module") : ""}
                linkHeading={showLinkHeading ? trans("MapstedMapsModule.Mapsted_Maps_Module") : null}
                subHeading={showLinkHeading ? trans(activeMenu.nameTransKey) : null}
                icon="heading-mapsted-maps-module"
                onHeadingClick={() => onChangeActiveMenu(undefined)}
            >
                {!activeMenu ? trans("MapstedMapsModule.Customize_your_personal_preferences_for_") : trans(activeMenu.detailsTransKey)}
            </SettingsHeading>
        );
    };

    const renderOptions = () => (
        <div className="settingsPageTabCover">
            <OptionItem
                content={trans("Settings.Config_Options")}
                detail={trans("Settings.Configuration_Options_Detail")}
                onClick={() => onChangeActiveMenu(OPTIONS.CONFIGURATION)}
            />
            <OptionItem
                content={trans("Settings.Customization_Options")}
                detail={trans("Settings.Customization_Options_Detail")}
                onClick={() => onChangeActiveMenu(OPTIONS.CUSTOMIZATION)}
            />
            <OptionItem
                content={trans("Settings.Advanced_Customization_Options")}
                detail={trans("Settings.Advanced_Customization_Options_Detail")}
                onClick={() => onChangeActiveMenu(OPTIONS.ADVANCED_CUSTOMIZATION)}
            />
        </div>
    );

    return (
        <Tab.Pane>
            <div className="settingsPageTabPane settingsPageTabPaneModule">
                {renderHeading()}

                {!activeMenu && renderOptions()}
            </div>

            <ConfigurationOptions
                renderErrors={renderErrors}
                isOpen={activeMenu?.id === OPTIONS.CONFIGURATION.id}
                onChangeZoomMode={onChangeZoomMode}
                onChangeValue={handleChangeValue}
                setIsBuilding={setIsBuilding}
                setZoomData = { setZoomData }
                setRotationData = { setRotationData }
                setShowModeSwitcher={setShowModeSwitcher}
                setShowMaxZoomLevelAdjuster={setShowMaxZoomLevelAdjuster}
            />

            <CustomizationOptions
                renderErrors={renderErrors}
                isOpen={activeMenu?.id === OPTIONS.CUSTOMIZATION.id}
                onChangeValue={handleChangeValue}
            />

            <AdvancedCustomizationOptions
                renderErrors={renderErrors}
                isOpen={activeMenu?.id === OPTIONS.ADVANCED_CUSTOMIZATION.id}
                onChangeValue={handleChangeValue}
            />

        </Tab.Pane>
    );
};
export default MapstedMapsModule;

const OptionItem = ({ content, detail, onClick }) => (
    <Button className="mapstedModuleSectionItem" onClick={onClick}>
        {content}
        <span>{detail}</span>
        <Icon name="caret right" />
    </Button>
);
