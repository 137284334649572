import React from "react";
import { ButtonIcon } from "../../common/ButtonIcon";
import { useListViewContext } from "../../../store/ListViewContext";
import { LIST_MODES } from "../../../_constants/listView";
import ListViewCsv from "../csvUpload/ListViewCsv";
import { ModalBox } from "../../common/ModalBox";
import { Button } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

import "./ListViewSubheader.css";

function ListViewSubHeader() 
{
    const trans = useTranslation().t;
    const { listMode, handleUpdateListMode, handleSaveBulkEntities, fallbackMessage, isColumnEditing } = useListViewContext();
    return (
        <div className="list-view-subheader">
            <ListViewCsv/>
            <div>
                {listMode === LIST_MODES.VIEW && (
                    <ButtonIcon
                        className={"list-view-action-btn"}
                        onClick={() => handleUpdateListMode(LIST_MODES.BULK_EDIT)}
                        icon={"edit"}
                        disabled={fallbackMessage}
                    >
                        {trans("ListViewSubHeader.Edit")}
                    </ButtonIcon>
                )}
                {listMode === LIST_MODES.BULK_EDIT && (
                    <div className="list-view-subheader-action-btn-bx">
                        <ButtonIcon
                            disabled={isColumnEditing}
                            onClick={handleSaveBulkEntities}
                            className={"list-view-action-btn list-view-action-btn--save"}>
                            {trans("ListViewSubHeader.Save")}
                        </ButtonIcon>
                        <EditCancelConfirmationModal 
                            onConfirm={() => handleUpdateListMode(LIST_MODES.VIEW)}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

export default ListViewSubHeader;

const EditCancelConfirmationModal = ({
    onConfirm,
}) => 
{
    const trans = useTranslation().t;
    return  <ModalBox className="confirmActionModal"
        trigger={<Button className={"list-view-action-btn"}
            color="white" content={trans("ListViewSubHeader.Cancel")} />}
        header={trans("Cancel_Confirmation_Modal.Confirm_Action")}
        actions={<Button color="orange" floated="right" content={trans("ListViewSubHeader.Cancel")} onClick={onConfirm} />}>
        <p className="p-modal">
            {trans("ListViewSubHeader.Cancel_Confirmation")}
        </p>
    </ModalBox>;
};
