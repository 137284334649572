import { createVectorLayer, getCommonGoeRefFloorPlanStyles, getStylesForGeoRefFloorPlanIncludingTextStyles } from "mapsted.maps/mapFunctions/plotting";
import { ShapeTypes } from "mapsted.maps/utils/entityTypes";
import { DrawInteraction } from "mapsted.maps/utils/interactionTemplates";
import { markAsImageFeature } from "mapsted.maps/utils/map.utils";
import { Map, View } from "ol";
import GeoImageLayer from "ol-ext/layer/GeoImage";
import GeoImageSrc from "ol-ext/source/GeoImage";
import { Attribution, defaults as defaultControls } from "ol/control";
import { Modify } from "ol/interaction";
import React, { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import { FLOOR_PLAN_IMAGE_MAP, GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES } from "../../../_constants/mapEditor";
import { ButtonIcon } from "../../common/ButtonIcon";
import { isDeleteActionTriggedForFloorPlanPoint, updateFeaturesFromHahsOnVS } from "./utils/floorPlan.utils";
import "./floorPlanGeoReference.css";
import { getPixelProjection } from "./utils/image.utils";
import useFloorPlanCommon from "./hooks/useFloorPlanCommon";


const FloorPlanImagePixelProjectionMap = () =>
{
    const { handleRemoveFeatureFromState, handleAddFeatureToState, handleModifyFeatureOnState, activeTab, goToFloorPlanTab, activeFloorPlanUrl, markedImageFeaturesHash, rotation: goeRefImageRotation } = useFloorPlanCommon();
    const [imageOlMap, setOlImageMap] = useState(null);
    const [center, setCenter] = useState(FLOOR_PLAN_IMAGE_MAP.defaultStateData.center);
    const [zoom, setZoom] = useState(FLOOR_PLAN_IMAGE_MAP.defaultStateData.zoom);
    const [imageOpacity, setImageOpacity] = useState(FLOOR_PLAN_IMAGE_MAP.defaultStateData.imageOpacity);
    const [imageRotate, setImageRotate] = useState(FLOOR_PLAN_IMAGE_MAP.defaultStateData.imageRotation);
    const [imageScale, setImageScale] = useState(FLOOR_PLAN_IMAGE_MAP.defaultStateData.imageScale);
    const containerRef = useRef(null);
    const imgMapDomRef = useRef(null);
    /**
     * Get the image layer from the image map.
     *
     * @param {object} imageMap - The map containing the image data.
     * @return {any} The image layer from the map, or undefined if not found.
     */
    const getLayer = (imageMap, layerName) => imageMap?.getLayers()?.forEach(function (layer)
    {
        // Check if the layer's title matches the desired title
        if (layer.get("name") === layerName)
        {
            return layer;
        }
    });

    const getImageLayer = (imageMap) =>
    {
        if (imageMap)
        {
            return imageMap.get("imageLayer");
        }
    };

    /**
     * Sets the image layer in the given image map.
     *
     * @param {Map} imageMap - The image map to set the image layer in.
     * @param {string} imageLayer - The image layer to set.
     * @return {void}
     */
    const addImageLayerToMap = (imageOlMap, imageLayer) =>
    {
        imageOlMap.set("imageLayer", imageLayer);
        imageOlMap?.getLayers().insertAt(0, imageLayer);
    };
    const updateImageLayerSrc = (imageLayer, imageUrl) =>
    {
        imageLayer.setSource(new GeoImageSrc(
            {
                name: FLOOR_PLAN_IMAGE_MAP.imageSrcId,
                url: imageUrl,
                imageCenter: center,
                imageRotate: imageRotate,
                imageScale: [1, 1],
                projection: getPixelProjection(),
            }
        ));
    };

    const getInteractionVectorLayer = (map) =>
    {
        if (map)
        {
            return map.get("interactionVectorLayer");
        }
    };

    /**
     * This function generates an index based on the length of the features array obtained from the input vs object.
     *
     * @param {type} vs - description of parameter
     * @return {type} the index value incremented by 1
     */
    const generateIndex = (vs) =>
    {
        return vs.getFeatures().length + 1;
    };
    const onDrawEnd = (e) =>
    {
        const { feature, target } = e;
        markAsImageFeature(feature);
        const index = generateIndex(target.source_, false);
        feature.set("index", index);
        feature.setStyle(getStylesForGeoRefFloorPlanIncludingTextStyles(feature));
        setTimeout(() => handleAddFeatureToState(feature));
        return feature;
    };

    const isCursorOverImageLayer = (event) =>
    {
        const map = imageOlMap;
        const imageLayer = getImageLayer(map);

        const pixel = map.getEventPixel(event);
        if (map.forEachLayerAtPixel(pixel, (layer) => layer === imageLayer))
        {

            map.getInteractions().forEach((interaction) =>
            {
                interaction.setActive(true);
            });
        }
        else
        {
            map.getInteractions().forEach((interaction) =>
            {
                interaction.setActive(false);
            });

        }
    };


    const onModifyInteraction = (e) =>
    {
        const { features, mapBrowserEvent } = e;

        if (!features) return;

        let modifiedFeature = features?.getArray()[0];
        if (!modifiedFeature) return;

        if (isDeleteActionTriggedForFloorPlanPoint(mapBrowserEvent))
        {
            handleRemoveFeatureFromState(modifiedFeature);
            return;
        }
        else
        {
            handleModifyFeatureOnState(modifiedFeature);
        }

    };

    const addInteractionToMap = (imageMap) =>
    {
        const layer = getInteractionVectorLayer(imageMap);
        if (!layer)
        {

            const vl = createVectorLayer({
                id: FLOOR_PLAN_IMAGE_MAP.vectorLayerId,
                name: FLOOR_PLAN_IMAGE_MAP.vectorLayerName,
                style: getCommonGoeRefFloorPlanStyles

            });
            vl.set("name", FLOOR_PLAN_IMAGE_MAP.vectorLayerName);
            const vs = vl.getSource();
            const drawInteraction = DrawInteraction({
                source: vs,
                type: ShapeTypes.POINT,
                handleDrawEndEvent: onDrawEnd

            });
            const mdin = new Modify({
                source: vs,
                deleteCondition: isDeleteActionTriggedForFloorPlanPoint
            });
            vl.setStyle(getCommonGoeRefFloorPlanStyles);
            imageMap.addInteraction(drawInteraction);
            imageMap.addInteraction(mdin);
            imageMap.addLayer(vl);
            imageMap.set("interactionVectorLayer", vl);
            // vs.on("addfeature", onDrawEnd);
            mdin.on("modifyend", onModifyInteraction);
        }
    };

    const removeImageLayer = (imageMap) =>
    {
        const imageLayer = getLayer(imageMap, FLOOR_PLAN_IMAGE_MAP.imageLayerName);
        if (imageLayer)
        {
            imageMap.removeLayer(imageLayer);
            return true;
        }
        return false;
    };

    const createImageLayer = () => new GeoImageLayer({
        id: FLOOR_PLAN_IMAGE_MAP.imageLayerId,
        name: FLOOR_PLAN_IMAGE_MAP.imageLayerName,
        opacity: imageOpacity,
        source: new GeoImageSrc(
            {
                name: FLOOR_PLAN_IMAGE_MAP.imageSrcId,
                url: activeFloorPlanUrl,
                imageCenter: center,
                imageRotate: imageRotate,
                imageScale: imageScale,
                projection: getPixelProjection(),
            })
    });

    const resetAllImageDependentStates = () =>
    {
        setZoom(FLOOR_PLAN_IMAGE_MAP.defaultStateData.zoom);
        setCenter(FLOOR_PLAN_IMAGE_MAP.defaultStateData.center);
        setImageOpacity(FLOOR_PLAN_IMAGE_MAP.defaultStateData.imageOpacity);
        setImageRotate(FLOOR_PLAN_IMAGE_MAP.defaultStateData.imageRotation);
    };


    const enablePointerEventsOnMap = (map, active) =>
    {
        if (!map) return;
        const mapELe = map.getViewport();
        mapELe.style.pointerEvents = active ? "auto" : "none";
    };


    useEffect(() =>
    {
        if (activeTab === GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES.geoReference)
        {
            containerRef.current.style.visibility = "visible";
        }
        else
        {
            containerRef.current.style.visibility = "hidden";
        }
    }, [activeTab]);

    //init image map
    useEffect(() =>
    {
        const attribution = new Attribution({
            collapsible: true
        });
        const imageMap = new Map({
            target: null,
            controls: defaultControls({
                attribution: false,
                zoom: false,
                rotate: false
            }).extend([attribution]),
            view: new View({
                projection: getPixelProjection(),
                target: null,
                zoom: zoom,
                center: center,
            }),
        });
        imageMap.setTarget(imgMapDomRef.current);
        setOlImageMap(imageMap);

        const setCursorStyle = (isOverMap) =>
        {
            imgMapDomRef.current.style.cursor = isOverMap ? 'pointer' : 'default';
        };

        imgMapDomRef.current.addEventListener('pointerenter', () => setCursorStyle(true));
        imgMapDomRef.current.addEventListener('pointerleave', () => setCursorStyle(false));

    }, []);


    //add interaction vector layer
    useEffect(() =>
    {
        if (imageOlMap)
        {
            addInteractionToMap(imageOlMap);


            imageOlMap.getViewport().addEventListener("mousedown", (event) =>
            {
                event.stopPropagation();
            });
            imageOlMap.getViewport().addEventListener("mousemove", (e) => isCursorOverImageLayer(e, imageOlMap, getLayer(imageOlMap, FLOOR_PLAN_IMAGE_MAP.imageLayerName)));
        }


        return () =>
        {
            if (imageOlMap)
            {
                imageOlMap.getViewport().removeEventListener("mousedown", (event) =>
                {
                    event.stopPropagation();
                });
                imageOlMap.getViewport().removeEventListener("mousemove", (e) => isCursorOverImageLayer(e, imageOlMap, getLayer(imageOlMap, FLOOR_PLAN_IMAGE_MAP.imageLayerName)));
            }
        };
    }, [imageOlMap]);

    //updates the image layer when the image url changes
    useEffect(() =>
    {
        if (imageOlMap && activeFloorPlanUrl)
        {
            let imageLayer = getImageLayer(imageOlMap);
            if (!imageLayer)
            {
                imageLayer = createImageLayer();
                addImageLayerToMap(imageOlMap, imageLayer);
                // const extent = imageLayer.getSource().getExtent();

                // // If the extent is defined (meaning the layer has features)
                // if (extent)
                // {
                //     // Fit the view to the extent with a small padding (optional)
                //     imageOlMap.getView().fit(extent, { padding: [20, 20, 20, 20] }); // Adjust padding as needed
                // }
            }
            else
            {
                updateImageLayerSrc(imageLayer, activeFloorPlanUrl);
                resetAllImageDependentStates();
            }
            setOlImageMap(imageOlMap);
            enablePointerEventsOnMap(imageOlMap, true);
        }
        else if (imageOlMap)
        {
            removeImageLayer(imageOlMap);
            enablePointerEventsOnMap(imageOlMap, false);

        }

    }, [activeFloorPlanUrl, imageOlMap]);

    //update the interaction vector layer with update features from the state
    useEffect(() =>
    {
        updateFeaturesFromHahsOnVS(markedImageFeaturesHash, getInteractionVectorLayer(imageOlMap)?.getSource());
    }, [markedImageFeaturesHash]);

    useEffect(() =>
    {
        const imageLayer = getImageLayer(imageOlMap);
        if (imageLayer)
        {
            imageOlMap.getView().setRotation(goeRefImageRotation);
        }


    }, [goeRefImageRotation]);



    return (
        <>
            <Draggable nodeRef={containerRef}>
                <div ref={containerRef} className="absolute img-wrapper" style={{ height: "550px", padding: "10px", width: "520px", paddingBottom: "40px" }}>
                    <div className="ui grid">
                        <div className="sixteen wide column">
                            <ButtonIcon onClick={goToFloorPlanTab}>ChangeFloorPlan</ButtonIcon>
                        </div>
                        <div className="sixteen wide column">
                            <div className="img" ref={imgMapDomRef} style={{ height: "510px", width: "500px" }}>

                            </div>
                        </div>
                    </div>
                </div>
            </Draggable>
        </>
    );

};

export default FloorPlanImagePixelProjectionMap;
