import React, { useState } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useTranslation } from "react-i18next";
import { Button, Input, Popup, Item, Label, Icon } from "semantic-ui-react";
import { ViewAllDetailPopup } from "./ViewAllDetailPopup";
import { imageUrl, filterList, formatDate } from "../../_utils/utils";
import DashboardContext from "../../store/DashboardContext";
import { Link, useHistory } from "react-router-dom";
import { LANGUAGE_CODE, SINGLE_LANG_INPUT_CODE, STATUS_DESCRIPTION, WARNING_MESSAGES } from "../../_constants/constants";
import { ErrorModal } from "./ErrorModal";
import { LoadableImage } from "../elements/LoadableImage";

export const ViewAllPopup = () =>
{
    const fullTrans = useTranslation().t;
    /** @type {typeof import("../../_intl/resources.json")["en"]["translation"]["viewallpopup"]} */
    const trans = fullTrans("viewallpopup");

    const dashboardContext = React.useContext(DashboardContext);

    const [isOpen, setIsOpen] = React.useState(false);
    const [deleteInfo, setDeleteInfo] = React.useState({});
    const [isViewVersions, setIsViewVersions] = React.useState(false);
    const [viewVersionsObject, setViewVersionsObject] = React.useState(undefined);
    const autoClose = true;

    // This is current view all selected property ID to view all buildings. (might need a name change)
    const [selectedPropertyId, setSelectedPropertyId] = useState(undefined);

    const handleOpen = React.useCallback(() =>
    {
        setIsOpen(true);
    }, []);

    const handleClose = React.useCallback(() =>
    {
        setIsOpen(false);
        setIsViewVersions(false);
    }, []);


    /**
     * Sets view all to property view when modal is closed/opened.
     */
    React.useEffect(() =>
    {
        setSelectedPropertyId(undefined);
    }, [isOpen]);

    /**
     * Gets the information to show on the bottom of the property/building card.
     */
    const handleGetBottomNames = React.useCallback((objectList) =>
    {
        if (objectList)
        {
            let names = [];

            const keys = Object.keys(objectList);

            let loopCount = 3;
            let notShownCount = keys.length - 3;

            if (keys.length < 3)
            {
                loopCount = keys.length;
                notShownCount = 0;
            }

            for (let i = 0; i < loopCount; i++)
            {
                names.push(objectList[keys[i]].longName?.[SINGLE_LANG_INPUT_CODE]);
            }

            let totalCount = loopCount + notShownCount;
            let propertyBuildingNumString = trans["no_building"];

            if (totalCount === 1)
            {
                propertyBuildingNumString = trans["one_building"];
            }
            else if (totalCount > 1)
            {
                propertyBuildingNumString = `${totalCount} ${trans["buildings"]}`;
            }

            return { names, notShownCount, propertyBuildingNumString };
        }
        return { names: [], notShownCount: 0 };
    }, [trans]);

    /**
     * Navigates to all buildings view and updates provider with the selected property.
     * @param {String} propertyId
     */
    const handleSelectProperty = React.useCallback((propertyId) =>
    {
        setSelectedPropertyId(propertyId);

        propertyId && dashboardContext.state.propertyId !== propertyId && dashboardContext.changeSelectedProperty(propertyId);
    }, [dashboardContext]);

    /**
     * Updates the provider with the selected building.
     * @param {String} buildingId
     */
    const handleSelectBuilding = React.useCallback((buildingId) =>
    {
        dashboardContext.changeSelectedBuilding(buildingId);
    }, [dashboardContext]);

    /**
     * Handles deselecting property from building view.
     * Triggred when navigating back from all buildings view.
     */
    const handleDeselectProperty = React.useCallback(() =>
    {
        handleSelectProperty(undefined);
        handleSelectBuilding(undefined);
    }, [handleSelectProperty, handleSelectBuilding]);

    const handleOpenViewVersions = (propertyId, buildingId) =>
    {
        if (buildingId)
        {
            setViewVersionsObject(dashboardContext.state.properties[propertyId].buildings[buildingId]);
        }
        else
        {
            setViewVersionsObject(dashboardContext.state.properties[propertyId]);
        }
        setIsViewVersions(true);
    };

    const handleCloseViewVersions = () =>
    {
        setIsViewVersions(false);
        setViewVersionsObject(undefined);
    };

    const handleOpenDeletePopup = (propertyId, buildingId) =>
    {
        let warning = "";
        let heading = "";
        if (buildingId)
        {
            const buildingName = dashboardContext.state.properties[propertyId].buildings[buildingId].longName?.[SINGLE_LANG_INPUT_CODE];
            warning = buildingName + " " + fullTrans(WARNING_MESSAGES.deleteBuilding.body);
            heading = fullTrans(WARNING_MESSAGES.deleteBuilding.heading);
        }
        else
        {
            const propertyName = dashboardContext.state.properties[propertyId].name?.[SINGLE_LANG_INPUT_CODE];
            warning = propertyName + " " + fullTrans(WARNING_MESSAGES.deleteProperty.body);
            heading = fullTrans(WARNING_MESSAGES.deleteProperty.heading);
        }

        const deleteInfo = {
            isOpen: true,
            error: {
                warning: {
                    body: warning,
                    heading: heading
                }
            },
            propertyId: propertyId,
            buildingId: buildingId,
        };

        setDeleteInfo(deleteInfo);
    };

    const handleStopDelete = () =>
    {
        setDeleteInfo({});
    };

    const handleDelete = () =>
    {
        const { propertyId, buildingId } = deleteInfo;

        if (buildingId)
        {
            dashboardContext.handleDeleteBuilding(propertyId, buildingId);
        }
        else
        {
            dashboardContext.handleDeleteProperty(propertyId);
        }

        setDeleteInfo({});
    };

    const renderView = () =>
    {
        if (isViewVersions)
        {
            return (
                <ViewAllDetailPopup detailObject={viewVersionsObject} onClose={handleCloseViewVersions} />
            );
        }
        else if (selectedPropertyId)
        {
            return (
                <BuildingView property={dashboardContext.state.properties[selectedPropertyId]}
                    selectedBuildingId={dashboardContext.state.buildingId}
                    onGetBottomNames={handleGetBottomNames} onDeselectProperty={handleDeselectProperty}
                    onSelectBuilding={handleSelectBuilding} onOpenViewVersions={handleOpenViewVersions}
                    onDelete={handleOpenDeletePopup} />
            );
        }
        else
        {
            return (
                <PropertyView properties={dashboardContext.state.properties}
                    selectedPropertyId={dashboardContext.state.propertyId}
                    onGetBottomNames={handleGetBottomNames} onSelectProperty={handleSelectProperty}
                    onOpenViewVersions={handleOpenViewVersions} onDelete={handleOpenDeletePopup} />
            );
        }
    };

    return (
        <div>

            <ErrorModal open={deleteInfo.isOpen} error={deleteInfo.error} onContinue={handleDelete} onBack={handleStopDelete} />

            <Popup open={(isOpen && !deleteInfo.isOpen)} hideOnScroll={false} on="click" pinned onClose={handleClose}
                closeOnDocumentClick={autoClose} closeOnEscape={autoClose}
                className="view-all-popup" position="bottom center"

                trigger={
                    <Button className="btn-view-popup" onClick={handleOpen} >
                        <span className="icon-bars"></span>
                        {trans["view_all"]}
                    </Button>}>


                <Button className="close" onClick={handleClose} />

                {
                    renderView()
                }

            </Popup>

        </div>

    );
};

export const PropertyView = ({ properties, selectedPropertyId, onGetBottomNames, onSelectProperty, onOpenViewVersions, onDelete }) =>
{
    /** @type {typeof import("../../_intl/resources.json")["en"]["translation"]["property_view"]} */
    const trans = useTranslation().t("property_view");


    const [filter, setFilter] = React.useState("");
    const filteredList = React.useMemo(() => Object.values(properties) && filterList(Object.values(properties), filter, "name", LANGUAGE_CODE),
        [filter, properties]);

    const handleFilterChange = React.useCallback((e, { name, value }) =>
    {
        setFilter(value);
    }, []);

    return (
        <>
            <div className="aligner">
                <Input className="search-header"
                    label={<Label><strong>{trans["all"]} <span>({filteredList.length})</span></strong></Label>}
                    icon="search" placeholder={trans["search"]} size="mini" value={filter} onChange={handleFilterChange} />
            </div>
            <Scrollbars autoHeight autoHeightMin="calc(1vh)" autoHeightMax="330px" >
                <div className="aligner">
                    <Item.Group className="view-building-group">
                        {
                            /*If no query, place selected property on top of list*/
                            (selectedPropertyId && filter === "") && (
                                <ViewAllPopupItem
                                    img={properties[selectedPropertyId].coverImages?.[SINGLE_LANG_INPUT_CODE]?.[0]}
                                    onSelect={() => onSelectProperty(selectedPropertyId)}
                                    propertyId={selectedPropertyId}
                                    createdAt={properties[selectedPropertyId].createdAt}
                                    status={properties[selectedPropertyId].status}
                                    onGetbottomNames={() => onGetBottomNames(properties[selectedPropertyId].buildings)}
                                    heading={properties[selectedPropertyId].name?.[SINGLE_LANG_INPUT_CODE]} buildingView={false}
                                    onOpenViewVersions={onOpenViewVersions}
                                    onDelete={onDelete} />
                            )
                        }
                        {filteredList
                            /*If no query, remove selected property from list since it is being displayed on top*/
                            .filter((property) => selectedPropertyId !== property._id || filter !== "")
                            .map((property) => (
                                <ViewAllPopupItem
                                    key={property._id}
                                    img={property.coverImages?.[SINGLE_LANG_INPUT_CODE]?.[0]}
                                    heading={property.name?.[SINGLE_LANG_INPUT_CODE]}
                                    buildingView={false}
                                    propertyId={property._id}
                                    status={property.status}
                                    createdAt={property.createdAt}
                                    onSelect={() => onSelectProperty(property._id)}
                                    onGetbottomNames={() => onGetBottomNames(property.buildings)}
                                    onOpenViewVersions={onOpenViewVersions}
                                    onDelete={onDelete}
                                />
                            ))
                        }
                    </Item.Group>
                </div>
            </Scrollbars>
        </>
    );
};

export const BuildingView = ({ property, selectedBuildingId, onGetBottomNames, onSelectBuilding, onDeselectProperty, onOpenViewVersions, onDelete }) =>
{
    /** @type {typeof import("../../_intl/resources.json")["en"]["translation"]["building_view"]} */
    const trans = useTranslation().t("building_view");

    const [filter, setFilter] = React.useState("");
    const [filteredList, setFilteredList] = React.useState(Object.values(property.buildings) || []);

    React.useEffect(() =>
    {
        if (Object.values(property.buildings))
        {
            setFilteredList(filterList(Object.values(property.buildings), filter, "longName", LANGUAGE_CODE));
        }
    }, [filter, property.buildings]);

    const handleFilterChange = React.useCallback((e, { value }) =>
    {
        setFilter(value);
    }, []);

    return (
        <>
            <div className="aligner">
                <Input className="search-header sh2"
                    label=
                        {
                            <Label
                                onClick={onDeselectProperty}
                                style={{ cursor: "pointer" }}>
                                <Icon name="arrow left" />
                                <span>{property.name?.[SINGLE_LANG_INPUT_CODE]}</span> {">"} <strong>{trans["all"]}<span>({filteredList.length})</span></strong>
                            </Label>
                        }
                    icon="search" placeholder={trans["search"]} size="mini" value={filter} onChange={handleFilterChange} />
            </div>
            <Scrollbars autoHeight autoHeightMin="calc(1vh)" autoHeightMax="calc(100vh - 270px)" >
                <div className="aligner">
                    <Item.Group className="view-building-group buildingItem">
                        {
                            /*If no query, place selected building on top of list*/
                            (selectedBuildingId && filter === "") && (
                                <ViewAllPopupItem
                                    img={property.buildings[selectedBuildingId].coverImages?.[SINGLE_LANG_INPUT_CODE]?.[0]}
                                    heading={property.buildings[selectedBuildingId].longName?.[SINGLE_LANG_INPUT_CODE]}
                                    propertyId={property._id}
                                    buildingId={property.buildings[selectedBuildingId]._id}
                                    createdAt={property.buildings[selectedBuildingId].createdAt}
                                    status={property.buildings[selectedBuildingId].status}
                                    onGetbottomNames={() => onGetBottomNames(property.buildings[selectedBuildingId].floors)}
                                    onSelect={() => onSelectBuilding(selectedBuildingId)}
                                    onOpenViewVersions={onOpenViewVersions}
                                    onDelete={onDelete}
                                />
                            )
                        }
                        {filteredList
                            .filter((building) => selectedBuildingId !== building._id || filter !== "")
                            .map((building) => (
                                <ViewAllPopupItem
                                    key={building._id}
                                    img={building.coverImages?.[SINGLE_LANG_INPUT_CODE]?.[0]}
                                    heading={building.longName?.[SINGLE_LANG_INPUT_CODE]}
                                    createdAt={building.createdAt}
                                    propertyId={property._id} buildingId={building._id} status={building.status}
                                    onGetbottomNames={() => onGetBottomNames(building.floors)}
                                    onSelect={() => onSelectBuilding(building._id)}
                                    onOpenViewVersions={onOpenViewVersions}
                                    onDelete={onDelete}
                                />
                            ))
                        }

                    </Item.Group>
                </div>
            </Scrollbars>
        </>
    );
};

export const ViewAllPopupItem = ({ img, heading, onSelect, onGetbottomNames, propertyId, buildingId, createdAt, status, onOpenViewVersions, onDelete }) =>
{
    /** @type {typeof import("../../_intl/resources.json")["en"]["translation"]["viewallpopup"]} */
    const trans = useTranslation().t("viewallpopup");

    const history = useHistory();
    const [bottomNames, setBottomNames] = React.useState({ names: [], notShownCount: 0, propertyBuildingNumString: trans["no_buildings"] });
    const [imgUrl, setImgUrl] = React.useState(undefined);
    const [link, setLink] = React.useState("");
    const [createdAtFormated, setCreatedAtFormated] = React.useState("");

    /**
     * Set edit link depending on propertyId, buildingId.
     */
    React.useEffect(() =>
    {
        if (buildingId && STATUS_DESCRIPTION[status].incomplete)
        {
            setLink(`building?propertyId=${propertyId}&buildingId=${buildingId}`);
        }
        else if (buildingId)
        {
            setLink(`floorplans?propertyId=${propertyId}&buildingId=${buildingId}`);
        }
        else if (propertyId && STATUS_DESCRIPTION[status].incomplete)
        {
            setLink(`property?propertyId=${propertyId}`);
        }
        else if (propertyId)
        {
            setLink(`building?propertyId=${propertyId}`);
        }
    }, [propertyId, buildingId, status]);

    React.useEffect(() =>
    {
        let url = (!img) ? undefined : imageUrl(img);

        setImgUrl(url);
    }, [img]);

    React.useEffect(() =>
    {
        // Returns names = {names, notShownCount, totalCount}
        const names = onGetbottomNames();

        setBottomNames(names);
    }, [onGetbottomNames]);

    React.useEffect(() =>
    {
        createdAt && setCreatedAtFormated(formatDate(createdAt, "DD MMM YYYY"));
    }, [createdAt]);

    const handlePropertyClick = React.useCallback(() =>
    {
        onSelect && onSelect();
    }, [onSelect]);

    const routeTo = React.useCallback(() =>
    {
        history.push("/dashboard/" + link);
    }, [history, link]);

    const handleDelete = React.useCallback((e) =>
    {
        e.stopPropagation();
        onDelete(propertyId, buildingId);
    }, [propertyId, buildingId, onDelete]);

    const renderExtraLabel = () =>
    {
        if (buildingId)
        {
            if (bottomNames.names.length > 0)
            {
                return (
                    bottomNames.names.map((name, index) => (<span key={index}> {name} </span>))
                );
            }
            else
            {
                return trans["no_floors"];
            }
        }
        else
        {
            return bottomNames.propertyBuildingNumString;
        }
    };

    return (
        <Item onClick={handlePropertyClick} style={onSelect && { cursor: "pointer" }}>
            <div className="image">
                <LoadableImage src={imgUrl} defaultSrc="/img/default-building.jpg" />
            </div>

            <Item.Content>

                <Button color="orange" size="mini" className="property-button" onClick={routeTo} >
                    <Link to={"#"}>{trans["update"]}</Link>
                </Button>


                <Button color="orange" size="mini" disabled className="property-button versions" onClick={() => onOpenViewVersions(propertyId, buildingId)} content={trans["versions"]} />

                {
                    (STATUS_DESCRIPTION[status].code === 1) && (
                        <Button className="link-btn link-delete" onClick={handleDelete}>
                            <img src="/img/icon-trash-blue.svg" alt={trans["trash_alt"]} />
                            {trans["delete"]}
                        </Button>
                    )
                }


                <Item.Header>{heading}</Item.Header>
                <Item.Meta>
                    <div className={"status " + STATUS_DESCRIPTION[status].className}>{STATUS_DESCRIPTION[status].name}</div>
                </Item.Meta>
                <Item.Description>
                    <p>{trans["created"]}<strong>{createdAtFormated}</strong></p>
                    {/* TODO: PROCESSED DATE */}
                    {/* <p>Processed : <strong>Pending</strong></p> */}
                </Item.Description>
                <Item.Extra>
                    {
                        renderExtraLabel()
                    }

                    {
                        (bottomNames.notShownCount > 0 && !!buildingId) && (
                            <span className="extra-numbers">+{bottomNames.notShownCount}</span>
                        )
                    }
                </Item.Extra>
            </Item.Content>
        </Item>
    );
};
