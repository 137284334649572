import React from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { Button, Grid, Segment, Label, } from "semantic-ui-react";
import { deepValue, deepUpdateValue } from "mapsted.utils/objects";
import AddPropertyContext from "../../../store/AddPropertyContext";
import { HeadingProperty } from "../../elements/HeadingProperty";
import { AddPropertyLayout } from "../../addProperty/AddPropertyLayout";
import { InputFormGroup } from "../../elements/InputFormGroup";
import { InfoHeadingPopup } from "../../popups/InfoHeadingPopup";
import { handleUploadFile, getSearchParams, handleNormalizeUrl, convertManualAddressToAddressComponents, convertUserAddressToFullAddress, getCountryInfoFromName, isAddressComponentValid, debounce } from "../../../_utils/utils";
import { propertySchema, userManualAddressSchema } from "../../../_constants/validationSchemas";
import { PropertyDetailBox } from "../../addProperty/PropertyDetailBox";
import GoogleLookup from "../../elements/googleLookup/GoogleLookup";
import MarkerMap from "../../addProperty/MarkerMap";
import { floorPlanAcceptableFileFormats } from "../../../_constants/config";
import { InitialDetailBox } from "../../addProperty/InitialDetailBox";
import serverAPI from "../../../_api/server.api";
import { PropertyEditBox } from "../../addProperty/PropertyEditBox";
import { PropertyManualEditBox } from "../../addProperty/PropertyManualEditBox";
import ChangeAddressPortal from "../../popups/ChangeAddressPortal";
import { ErrorModal } from "../../popups/ErrorModal";
import { DEFAULT_LANGUAGE_CODE, SINGLE_LANG_INPUT_CODE, WARNING_MESSAGES } from "../../../_constants/constants";
import { withTranslation } from "react-i18next";
import {ArchibusPropertyImporter} from "./archibus/ArchibusPropertyImporter";

// import { MapBuildingPopup } from "../../popups/MapBuildingPopup";



class AddProperty extends React.Component
{
    static contextType = AddPropertyContext;

    state = {
        property: {
            name: { [DEFAULT_LANGUAGE_CODE]: "" },
            fullAddress: "",
            type: 0,
            website: { [DEFAULT_LANGUAGE_CODE]: "" },
            phoneNumbers: "",
            floorPlan: undefined,
            centroid: {},
            addressComponents: {}
        },
        userAddress: {
            streetNumber: "",
            unitNumber: "",
            street: "",
            city: "",
            province: "",
            postalCode: "",
            country: "",
            countryAlpha2Code: ""
        },
        validationErrors: {},
        searchCompleted: false,
        edit: false,
        editManually: false,
        lookoutAddress: "",
        googleLookupError: false,
        isMapReset: true,
        undoAddress: undefined,
        showAddressChangeConfirmation: false,
        undoCentroid: undefined,
    }

    floorPlanRef = React.createRef();


    /**
     * Loads property ID if passed through url param.
     */
    componentDidMount ()
    {
        const { propertyId } = getSearchParams(["propertyId"]);

        // If property passed through url param, load property.
        // If propertyId is not valid or not in draft, getProperty() method will route to dashboard.
        if (propertyId)
        {
            // If property is loaded and is same property, pull from state.
            if (this.context.state.propertyloaded && (this.context.state.propertyId === propertyId))
            {
                this.handleInitStatePropertyFromObject(this.context.state.property);
            }
            // Else handle fetch.
            this.context.getProperty({ propertyId, isPropertyEdit: true }, ({ property }) =>
            {
                this.handleInitStatePropertyFromObject(property);
            });
        }
        // Else reset provider state and local state. Set coordinates to users coords.
        else
        {
            this.context.resetState(() => this.setState({ property: this.context.state.property }));

            this.getUserLocation();
        }
    }

    /**
     * Navigate to next page /dashboard/building.
     * If propertyId is already created pass it as a param.
     */
    handleGoFoward = () =>
    {
        let link = "./building";
        if (this.context.state.propertyId)
        {
            link += `?propertyId=${this.context.state.propertyId}`;
        }

        this.props.history.push(link);
    }

    /**
     * Routes back to dashboard.
     */
    handleGoToDashboard = () => this.props.history.push("/dashboard")


    /**
     * Handles local state initialization.
     */
    handleInitStatePropertyFromObject = (property) => this.setState({ property, searchCompleted: true, lookoutAddress: property.name?.[SINGLE_LANG_INPUT_CODE] })


    /**
     * Gets users location and sets it as properties location.
     */
    getUserLocation = () =>
    {
        if (navigator.geolocation)
        {
            navigator.geolocation.getCurrentPosition((position) =>
            {
                this.handleCoordinatesChange([position.coords.longitude, position.coords.latitude]);
            });
        }
    }

    /**
     * Handles changes made to the property object.
     * @param {Event} e - Change event
     * @param {String} name - Name of the property to be changed.
     * @param {any} value - The new value for the param.
     */
    handlePropertyChange = (e, { name, value }) =>
    {
        const validationErrors = { ...this.state.validationErrors };
        const property = { ...this.state.property };

        deepUpdateValue(property, name, value);

        // This is for phoneValidation checks. It uses a diffrent property to check than phoneNumbers.
        if (name === "phoneNumbers")
        {
            name = "phoneCheck";
        }

        delete validationErrors[name];

        this.setState({ property, validationErrors });
    }

    handleUserAddressChange = async (e, { name, value }) =>
    {
        const property = { ...this.state.property };
        const validationErrors = { ...this.state.validationErrors };
        const userAddress = { ...this.state.userAddress };
        let { isMapReset } = this.state;

        userAddress[name] = value;

        if (name === "country" && !deepValue(property, "centroid.coordinates", false))
        {
            const countryInfo = await getCountryInfoFromName(value);

            property.centroid = {
                type: "Point",
                coordinates: countryInfo.latlng.reverse()
            };

            userAddress.countryAlpha2Code = countryInfo.alpha2Code;

            isMapReset = true;
        }

        delete validationErrors[name];
        this.setState({ userAddress, validationErrors, isMapReset, property });
    }

    handleOnGoogleLookup = ({ address, place, coordinates, timeZone }) =>
    {
        const validationErrors = { ...this.state.validationErrors };
        let property = { ...this.state.property };

        const { userAddress, isValid: isValidAddress } = isAddressComponentValid(place.address_components || []);

        if (!property.centroid)
        {
            property.centroid = {};
        }

        property.centroid.coordinates = coordinates;
        property.centroid.type = "Point";
        property.name = { [DEFAULT_LANGUAGE_CODE]: place.name || "" };
        property.fullAddress = place.formatted_address || "";
        property.addressComponents = place.address_components || [];
        property.website = { [DEFAULT_LANGUAGE_CODE]: place.website || "" };
        property.phoneNumbers = place.international_phone_number || "";
        property.timeZone = timeZone;


        delete validationErrors[`name.${DEFAULT_LANGUAGE_CODE}`];
        delete validationErrors.fullAddress;
        delete validationErrors.phoneCheck;
        delete validationErrors[`website.${DEFAULT_LANGUAGE_CODE}`];

        this.setState({
            property,
            validationErrors,
            lookoutAddress: address,
            searchCompleted: true,
            googleLookupError: false,
            userAddress,
            edit: false,
            editManually: !isValidAddress,
            isMapReset: true
        });
    }

    /**
     * Opens file select.
     */
    handleOnFloorPlanClick = () => this.floorPlanRef.current.click()

    /**
     * Updates the selected floor plan in local state.
     */
    handleSelectFloorPlan = () =>
    {
        const validationErrors = { ...this.state.validationErrors };
        const property = { ...this.state.property };

        handleUploadFile(this.floorPlanRef.current, undefined, (file) =>
        {
            property.floorPlan = file;

            delete validationErrors.floorPlan;

            this.setState({ property, validationErrors });
        });
    }

    handleCoordinatesChange = (coordinates) =>
    {
        const property = JSON.parse(JSON.stringify((this.state.property)));

        let state = {};

        if (!this.state.undoCentroid)
        {
            state.undoCentroid = property.centroid;
        }

        property.centroid = {
            type: "Point",
            coordinates
        };

        if (property.name)
        {
            this.handleCheckAddressByCoordinates(coordinates);
        }

        state.property = property;

        this.setState(state);
    }

    handleCheckAddressByCoordinates = debounce(async (coordinates) =>
    {
        const latlng = {
            lat: coordinates[1],
            lng: coordinates[0]
        };

        const pinAddress = await serverAPI.getGoogleReverseGeocodingAddress(latlng);

        const property = { ...this.state.property };
        const userAddress = { ...this.state.userAddress };
        let undoAddress = this.state.undoAddress;

        if (!undoAddress)
        {
            undoAddress = {
                placeid: property.placeid,
                fullAddress: property.fullAddress,
                addressComponents: property.addressComponents,
                userAddress: Object.assign({}, userAddress)
            };
        }

        const { userAddress: newUserAddress } = isAddressComponentValid(pinAddress.address_components || []);

        property.placeid = pinAddress.placeid || "";
        property.fullAddress = pinAddress.formatted_address || "";
        property.addressComponents = pinAddress.address_components || [];

        this.setState({
            property,
            validationErrors: {},
            userAddress: newUserAddress,
            undoAddress
        });
    }, 1000);

    handleUndoPinAddress = () =>
    {
        const { undoAddress } = this.state;
        let property = JSON.parse(JSON.stringify(this.state.property));

        property.placeid = undoAddress.placeid || "";
        property.fullAddress = undoAddress.fullAddress || "";
        property.addressComponents = undoAddress.addressComponents || [];
        property.centroid = this.state.undoCentroid || {};

        const userAddress = Object.assign({}, undoAddress.userAddress);

        this.setState({
            property,
            userAddress,
            validationErrors: {},
            undoAddress: undefined,
            showAddressChangeConfirmation: false,
            undoCentroid: undefined,
        });
    }

    handleConfirmPinAddress = () =>
    {
        this.setState({ undoAddress: undefined, showAddressChangeConfirmation: false, undoCentroid: undefined, });
    }

    /**
     * If validation is passed, the property is then sent to be saved in the DB as the user moves
     * to the next page.
     *
     * On fail the user will be navigated back to this page with a notification of an error.
     */
    handleOnSave = async (waitUntilSaved) =>
    {
        let property = { ...this.state.property };
        let userAddress = { ...this.state.userAddress };
        let { editManually, undoAddress } = this.state;
        const trans = this.props.t;

        if (undoAddress)
        {
            this.setState({ showAddressChangeConfirmation: true });
            return false;
        }

        try
        {
            // If waitUntilSaved, wait for creation in DB then navigate back to dashboard.

            // Normalize website if valid.
            property.website = { [DEFAULT_LANGUAGE_CODE]: handleNormalizeUrl(property.website?.[DEFAULT_LANGUAGE_CODE]) };
            property.phoneCheck = await serverAPI.validatePhoneNumber(property.phoneNumbers);

            if (editManually)
            {
                property.addressComponents = await convertManualAddressToAddressComponents(userAddress);
                property.fullAddress = convertUserAddressToFullAddress(userAddress);
                property.timeZone = undefined;

                userAddress.streetNumber = userAddress.streetNumber || 0;
                await userManualAddressSchema.validate(userAddress, { abortEarly: false });
            }

            await propertySchema.validate(property, { abortEarly: false });

            if (waitUntilSaved)
            {
                this.context.saveProperty(property, waitUntilSaved, (success) =>
                {
                    if (success)
                    {
                        this.props.history.push("/dashboard");
                    }
                    else // If the creation fails.
                    {
                        window.alert(trans("AddProperty.Something_went_wrong__Please_try_again_"));
                    }
                });
            }
            // If not waitUntilSaved, send the property to the DB and move to the next page.
            else
            {
                this.context.saveProperty(property, waitUntilSaved, (success) =>
                {
                    // If the creation fails.
                    if (!success)
                    {
                        window.alert(trans("AddProperty.Something_went_wrong__Please_try_again_"));
                        this.props.history.push("/dashboard/property");
                    }
                });

                this.handleGoFoward();
            }
        }
        catch (err)
        {
            // Handle validation errors.
            if (err && err.name === "ValidationError")
            {
                const validationErrors = {};

                err.inner.forEach((currentError) =>
                {
                    validationErrors[currentError.path] = currentError.message;
                });

                //updates website on UI if website doesn"t have an error
                !validationErrors[`website.${DEFAULT_LANGUAGE_CODE}`] && this.setState({ property });

                this.setState({ edit: !editManually, validationErrors });
            }
        }

        return false;

    }

    handleOnGoogleLookupError = () => this.setState({ googleLookupError: true, searchCompleted: false, edit: false, editManually: false });

    handleShouldMapCenterReset = () =>
    {
        const { isMapReset } = this.state;

        if (isMapReset)
        {
            this.setState({ isMapReset: false });
            return true;
        }
        else
        {
            return false;
        }
    }

    handleManualEditActivation = () =>
    {
        this.getUserLocation();
        this.setState({ googleLookupError: false, editManually: true, searchCompleted: true, edit: false });
    }

    handleSetEditMode = () => this.setState({ edit: true });

    render ()
    {
        const { isPropertyEdit, propertyTypes } = this.context.state;
        const { edit, property, lookoutAddress, validationErrors, googleLookupError, searchCompleted, editManually, userAddress, undoAddress, showAddressChangeConfirmation } = this.state;
        const trans = this.props.t;
        const enableSave = (searchCompleted || editManually) && property.name;

        return (
            <Grid columns="equal" className="property-cover2">
                <Grid.Column width="5">

                    <Segment basic className="property-box">

                        <span onClick={this.handleGoToDashboard} className="close">
                            <Link to="#" />
                        </span>

                        <HeadingProperty heading={(isPropertyEdit) ? trans("AddProperty.Edit_Property") : trans("AddProperty.Add_Property")} info={
                            <InfoHeadingPopup header={(isPropertyEdit) ? trans("AddProperty.Edit_Property") : trans("AddProperty.Add_Property")} showLearnMore={false}>
                                {trans("AddProperty.Fill_in_the_required_fields_below_to_add")}
                            </InfoHeadingPopup>
                        } />
                        <AddPropertyLayout>
                            <p>
                                {trans("AddProperty.Enter_your_property_information_below_to")}
                            </p>
                            <p className="p">{trans("AddProperty.Once_you_are_finished,_click_the_Next_")}</p>
                            <div className="form-property">

                                {
                                    (!searchCompleted)
                                    && <GoogleLookup label={trans("AddProperty.Search_Property")} placeholder={trans("AddProperty.e_g__BEO_Shopping_Mall")}
                                        value={lookoutAddress} error={edit ? "" : validationErrors[`name.${DEFAULT_LANGUAGE_CODE}`]}
                                        extraLabel={trans("AddProperty.Search_the_property_name_by_using_the_fi")}
                                        onChange={this.handleOnGoogleLookup}
                                        onError={this.handleOnGoogleLookupError}
                                    />
                                }

                                {
                                    (searchCompleted && !(edit || editManually)) && (
                                        <>
                                            <PropertyDetailBox edit={edit} setEdit={this.handleSetEditMode}
                                                property={property} propertyTypes={propertyTypes}
                                            />
                                            <InputFormGroup label={trans("AddProperty.Property_Type")} list={propertyTypes} scrolling search
                                                onChange={this.handlePropertyChange} name="type" listValue={property.type} error={validationErrors["type"]}
                                                extraLabel={trans("AddProperty.Select_the_property_type_from_the_dropdo")} />
                                        </>
                                    )
                                }

                                {
                                    (googleLookupError) && (
                                        <InitialDetailBox error>
                                            <p>{trans("AddProperty.No_results_found")}</p>
                                            <Button color="orange" content={trans("AddProperty.Add_Property_Manually")} onClick={this.handleManualEditActivation} />
                                        </InitialDetailBox>
                                    )
                                }

                                {
                                    (editManually) && (
                                        <PropertyManualEditBox property={property} propertyTypes={propertyTypes} userAddress={userAddress}
                                            onChange={this.handlePropertyChange} onChangeUserAddress={this.handleUserAddressChange}
                                            validationErrors={validationErrors} />
                                    )
                                }

                                {
                                    (edit) && (
                                        <PropertyEditBox property={property} onChange={this.handlePropertyChange} propertyTypes={propertyTypes} validationErrors={validationErrors} />
                                    )
                                }

                                {
                                    (searchCompleted || edit || editManually) && (
                                        <>
                                            <p className="p-property">{(property.floorPlanImages && property.floorPlanImages.length > 0 ? trans("AddProperty.CHANGE") : trans("AddProperty.UPLOAD")) + trans("AddProperty._PROPERTY_FLOOR_PLAN")}</p>
                                            <div className="default-flex">
                                                <Button size="small" color="grey" content={trans("AddProperty.Upload")} onClick={this.handleOnFloorPlanClick} />
                                                <input type="file" ref={this.floorPlanRef} className="hidden"
                                                    accept={floorPlanAcceptableFileFormats} onChange={this.handleSelectFloorPlan} />
                                                {
                                                    property.floorPlan && <div>{property.floorPlan.name}</div>
                                                }
                                            </div>


                                            {
                                                validationErrors["floorPlan"]
                                                && <Label basic pointing="left" color="red" content={validationErrors["floorPlan"]} />
                                            }
                                        </>
                                    )
                                }
                            </div>
                            <div className="property-actions">
                                <Button disabled={!enableSave} size="small" color="grey" content={trans("AddProperty.Save_&_Exit")} onClick={() => this.handleOnSave(true)} />
                                <Button disabled={!enableSave} onClick={() => this.handleOnSave(false)}
                                    color="orange" content={trans("AddProperty.Next")} />
                            </div>
                            <div className="form-property lookupcover">
                                <Segment basic className="form-group">
                                    <p>INTEGRATION PARTNER</p>
                                    <div className="content">
                                        <ArchibusPropertyImporter />
                                    </div>
                                </Segment>
                            </div>
                        </AddPropertyLayout>
                    </Segment>
                </Grid.Column>
                <Grid.Column>
                    <MarkerMap markerCoordinates={property.centroid && property.centroid.coordinates}
                        onMarkerCoordinatesChange={this.handleCoordinatesChange}
                        editable={searchCompleted}
                        onShouldMapCenterReset={this.handleShouldMapCenterReset} />

                    <ChangeAddressPortal open={!!undoAddress} onUndo={this.handleUndoPinAddress} onConfirm={this.handleConfirmPinAddress} />

                    <ErrorModal open={showAddressChangeConfirmation} error={{ confirmAddressChanges: WARNING_MESSAGES.confirmAddressChanges }}
                        backButtonContent={trans("AddProperty.Undo")} continueButtonContent={trans("AddProperty.Confirm")}
                        onBack={this.handleUndoPinAddress} onContinue={this.handleConfirmPinAddress} />
                </Grid.Column>
            </Grid>
        );
    }
}

AddProperty.propTypes = {
    history: PropTypes.object
};

export default withTranslation()(withRouter(AddProperty));

