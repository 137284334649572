import React, { useState } from "react";
import { DEFAULT_LANGUAGE_CODE } from "mapsted.maps/utils/map.constants";
import { Button } from "semantic-ui-react";
import RevertToDefaultConfirmModal from "../modals/RevertToDefaultConfirmModal";
import PolygonStyleSettings from "../PolygonStyleSettings";
import TabPane from "./TabPane";
import { useTranslation } from "react-i18next";

const PolygonStyleEditorTab = ({ 
    style, 
    onChange, 
    previewState, 
    onPreviewStateChange,
    onRevertToDefault,
    onSave,
    lang=DEFAULT_LANGUAGE_CODE,
    disableEdit,
}) => 
{
    const trans = useTranslation().t;
    // state field to manage revert to default style confirmation modal
    const [openRevertModal, setOpenRevertModal] = useState(false);

    /**
     * 
     * @param {String} type entity state type (default, selected or vacant)
     * @param {String} field 
     * @param {*} value 
     */
    const handlePolygonStyleChange = (type, field, value) => 
    {
        let updatedPolygonStyle = { ...style.styleSettings[lang].polygon[type] };
        updatedPolygonStyle[field] = value;
        onChange(type, updatedPolygonStyle);
    };

    const polygonStyle = style.styleSettings[lang].polygon;

    return (
        <TabPane>

            <PolygonStyleSettings 
                handlePolygonStyleChange={handlePolygonStyleChange}
                heading={trans("StylesAndThemesSidebar.Default")}
                entityStateKey="default"
                style={polygonStyle.default}
                checked={previewState === "default"}
                disabled={previewState !== "default" || disableEdit}
                onCheckboxClick={() => onPreviewStateChange("default")}
            />

            {polygonStyle.selected && <PolygonStyleSettings 
                handlePolygonStyleChange={handlePolygonStyleChange}
                heading={trans("StylesAndThemesSidebar.Selected")}
                entityStateKey="selected"
                style={polygonStyle.selected}
                checked={previewState === "selected"}
                disabled={previewState !== "selected" || disableEdit}
                onCheckboxClick={() => onPreviewStateChange("selected")}
            />}

            {polygonStyle.vacant && <PolygonStyleSettings 
                handlePolygonStyleChange={handlePolygonStyleChange}
                heading={trans("StylesAndThemesSidebar.Vacant")}
                entityStateKey="vacant"
                style={polygonStyle.vacant}
                checked={previewState === "vacant"}
                disabled={previewState !== "vacant" || disableEdit}
                onCheckboxClick={() => onPreviewStateChange("vacant")}
            />}

            <div className="stylesEditorActions">
                <Button 
                    primary 
                    floated="right" 
                    content={trans("StylesAndThemesSidebar.Apply")}
                    onClick={onSave} 
                    disabled={disableEdit}
                />
                <Button 
                    basic 
                    color="orange" 
                    floated="left" 
                    className="defaultReverBtn" 
                    content={trans("StylesAndThemesSidebar.Revert_chnges_to_default")}
                    onClick={() => setOpenRevertModal(true)}
                    disabled={disableEdit}
                />
                <RevertToDefaultConfirmModal
                    open={openRevertModal}
                    onConfirm={() => 
                    {
                        onRevertToDefault(lang);
                        setOpenRevertModal(false);
                    }}
                    onClose={() => setOpenRevertModal(false)}
                />
            </div>

        </TabPane>);
};

export default PolygonStyleEditorTab;