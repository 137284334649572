/* eslint-disable multiline-ternary */
import React from "react";
import { deepValue } from "mapsted.utils/objects";
import { useQuery } from "react-query";
import brandingApi from "../_api/branding.api";
import serverApi from "../_api/server.api";
import { BRANDING_QUERIES } from "../_api/branding.queries";
import BrandingContext from "./BrandingContext";
import { filerUrl } from "../_utils/utils";
import { arrayToHash } from "mapsted.utils/arrays";
import { addParentCategoriesForCategories } from "../_utils/branding.utils";
import { appendObjectListsWithoutDuplicate, toDropDownList } from "../_utils/utils";
import { DEFAULT_CATEGORY_ICON } from "../_constants/branding";
import { LanguageContext } from "./LanguageContext";
import { DEFAULT_LANGUAGE_CODE } from "mapsted.maps/utils/map.constants";


const CategoryContext = React.createContext();

/**
 * Provides a common state and functions for all components
 * in the category sidebar.
 */
const CategoryProvider = ({ children }) =>
{
    const brandingContext = React.useContext(BrandingContext);
    // Map of category ids to objects. Objects contain children and parent ids.
    const [categoryFlatMap, setCategoryFlatMap] = React.useState({});
    // An array of root category objects.
    const [categoryGroups, setCategoryGroups] = React.useState([]);
    const [ entites, setEntites] = React.useState([]);

    const [lastUpdated, setLastUpdated] = React.useState(new Date().valueOf());

    const languageCtx = React.useContext(LanguageContext);
    const { languages } = languageCtx;

    const { getCurrentPropertyLangOptions, updateState  } = brandingContext;

    const { loading, properties, propertyId, mapData } = brandingContext.state;
    const { data: categoryList, isLoading: categoriesIsLoading, refetch: refetchCategories } = useQuery(BRANDING_QUERIES.PROPERTY_CATEGORIES(propertyId, ...(properties?.[propertyId]?.categoryRoots || [])));
    React.useEffect(() =>
    {
        if (!loading && !categoriesIsLoading)
        {
            // Get category root ids of current property
            const property = properties[propertyId];
            const categoryRootIds = deepValue(property, "categoryRoots", []);

            // Init categories
            const categories = arrayToHash(categoryList, "_id");
            if (categoryRootIds)
            {
                addParentCategoriesForCategories(categories, categoryRootIds || []);
            }
            setCategoryFlatMap({ ...categories });

            // Init categoryGroups
            const categoryGroups = categoryRootIds.map((id) => categories[id]);
            setCategoryGroups(categoryGroups);
        }
    }, [loading, lastUpdated, properties, categoryList, propertyId, categoriesIsLoading]);

    React.useEffect( () =>
    {       
        const fetchEntitesWithKeywords =async() =>
        {        
            let EntitesWithKeywords = await brandingApi.getEntitesWithKeywords(propertyId);
            setEntites(EntitesWithKeywords);        
        };
        
        if (propertyId) 
        {
            fetchEntitesWithKeywords();
        }
        
    },[propertyId, JSON.stringify(mapData?.entities)]);


    const buildingOptions = React.useMemo(() => 
    {
        const buildings = properties[propertyId]?.buildings;
        if (!buildings || Object.keys(buildings).length === 0) return [];

        return Object.values(buildings)?.map(({ longName, buildingId }) => ({
            text: longName[DEFAULT_LANGUAGE_CODE], 
            value: buildingId, 
            key: buildingId
        }));
    }, [propertyId, properties]);  
   
    /**
     * @returns {Array<Object>} list of all subcategory objects of category object `category`.
     * @param {Object} category
     */
    const getChildrenOf = (category) => (category.subCategories || []).map((id) => categoryFlatMap[id]).filter((cat) => !!cat);

    /**
     * @returns {Object} parent category object of category object `category`.
     * @param {Object} category
     */
    const getParentOf = (category) => category.parentCategories && category.parentCategories.map((id) => categoryFlatMap[id])[0];

    /**
     * @returns {Array<Object>} list of category objects matching `categoryIds`
     * @param {Array<String>} categoryIds
     */
    const getCategoriesFromIds = (categoryIds) => categoryIds.map((id) => categoryFlatMap[id]).filter((cat) => !!cat);

    /**
     * @returns {Object} The category's StyleIconObject or the nearest ancestor's styleIconObject with filer url
     * @param {Object} category with url or default icon url if it category doesnt exist
     */
    const getCategoryStyledIcon = (category) =>
    {
        const defaultIcon = { ...DEFAULT_CATEGORY_ICON, url: filerUrl(DEFAULT_CATEGORY_ICON.iconImage) };
        if (!category || !category.styledIcon) return defaultIcon;
        // let currentCategory = category;
        let styleIconObject = category.styledIcon;

        // while (!styleIconObject)
        // {
        //     // if no styled icon object exists in category, get parent's styled icon object
        //     const parentCategory = getParentOf(currentCategory);
        //     if (!parentCategory) return defaultIcon;
        //     styleIconObject = parentCategory.styledIcon;
        //     currentCategory = parentCategory;
        // }
        
        const result = { url: filerUrl(styleIconObject.iconImage), ...styleIconObject };
        if (!result.iconImage)
        {
            result.iconImage = DEFAULT_CATEGORY_ICON.iconImage;
            result.url = filerUrl(DEFAULT_CATEGORY_ICON.iconImage);
        }
        return result;
    };

    /**
     * For a given category returns the appropiate icon and background and icon colours
     * @param {Object} category
     * @returns {Object}
     */
    const getIconInfo = (category) =>
    {
        if (!category)
        {
            return DEFAULT_CATEGORY_ICON;
        }

        const icon = getCategoryStyledIcon(category) || DEFAULT_CATEGORY_ICON;
        const iconColour = category.iconColour || icon.iconColour;
        const backgroundColour = category.backgroundColour || icon.backgroundColour;

        return { ...icon, iconColour, backgroundColour };
    };

    /**
     * Calls an api to fetch all categories that have similar names to the searched name.
     * Appends the given appendedList (meant for selected categories) to the result.
     * appendedList is expected to follow the same format as the recieved categories.
     * @param {String} name
     * @param {Array} appendedList
     *
     * @returns {data, options } where data is the result from the api + appended list, and options is a dropdown friendly version of data.
     */
    const searchCategories = async (name, appendedList) =>
    {
        const test = await brandingApi.getCategories(name);
        let { data, success } = test;

        if (success)
        {
            // add the appended list to the array.
            if (Array.isArray(appendedList))
            {
                data = appendObjectListsWithoutDuplicate(data, appendedList);
            }

            const options = toDropDownList({ data: data, text: "name", value: "_id", key: "_id" });

            return { data, options };
        }
        else
        {
            return { options: [] };
        }
    };

    const createCategory = async (categoryData) =>
    {
        console.log({ categoryData });

        const { success, data, isCategoryDeleted } = await brandingApi.createCategory(propertyId, categoryData);

        if (success)
        {
            refetchCategories();
            if (categoryData.isRoot)
            {
                await brandingContext.updateProperties();
            }
            setLastUpdated(new Date().valueOf());

            if (isCategoryDeleted)
            {
                await brandingContext.handleRefreshMapData();
            }
        }
        return data;
    };

    const deleteCategory = async (categoryId, isRoot) =>
    {
        const success = await brandingApi.deleteCategory(categoryId);

        if (success)
        {
            refetchCategories();
            if (isRoot)
            {
                await brandingContext.updateProperties();
            }
            setLastUpdated(new Date().valueOf());

            await brandingContext.handleRefreshMapData();
            return true;
        }
        else
        {
            return false;
        }
    };

    const handleGetStoresFromCategories = async (categories) => await brandingContext.handleGetStoresFromCategories(categories);

    const reorderCategories = (data) => serverApi.put("/api/internal/categories/order", data, true)
        .then(() => brandingContext.updateProperties())
        .then(() => setLastUpdated(new Date().valueOf()))
        .then(() => refetchCategories());

    const getEntitiesKeywords = ({ categoriesIds, lang,selectedBuilding }) => 
    {
        // Return early if no entities are available
        if (entites.length === 0) return [];

        // Filter entities based on the selected building
        const filteredEntities = selectedBuilding ? entites.filter((entity) => entity?.buildingId === selectedBuilding) : entites;         
        let keywordsSet = new Set();      
            
        // Iterate over entities to extract subcategories and keywords
        Object.values(filteredEntities).forEach((entity) => 
        {
            const { category, subCategories, keywords } = entity ;
                
            // Extract category and subcategory IDs
            const entityCategoryId = category?._id;
            const subCategoryIds = subCategories?.map((subCat) => subCat?._id) || [];
                
            // Extract keywords for the given language
            const entityKeywords = keywords?.[lang];          
                
            // Skip if no relevant keywords are found
            if (!entityKeywords?.length) return;
                
            // Collect all relevant category IDs (main + subcategories)
            const allCategoryIds = [entityCategoryId, ...subCategoryIds];          
                
            // Check if any category or subcategory matches the given category IDs
            const hasMatchingCategory = allCategoryIds.some((id) => categoriesIds.includes(id));
                
            if (hasMatchingCategory) 
            {
                // Add keywords to the Set for uniqueness
                entityKeywords.forEach((keyword) => keywordsSet.add(keyword));
            }
        });
            
        // Convert the Set back to an array and map it to the desired format
        return Array.from(keywordsSet)?.map((keyword) => ({ name: keyword, selected: false }));
    };
            
    const groupByKeywordAlphabet = (keywords, sortOrder = "asc") => 
    {
        // Use reduce to group keywords by the first letter of the 'name' property
        const grouped = keywords?.reduce((acc, keyword) => 
        {
            const trimmedItem = keyword.name.trim(); 
            if (trimmedItem.length === 0) return acc;
    
            const firstLetter = trimmedItem.charAt(0).toUpperCase();
            acc[firstLetter] = acc[firstLetter] || [];
            acc[firstLetter].push(keyword); 
    
            return acc;
        }, {});
    
        // Sorting function for the alphabet
        const sortAlphabetByOrder = (a, b) => sortOrder === "asc" ? a.localeCompare(b) : b.localeCompare(a);
    
        // Convert the grouped object to the desired array of objects
        return grouped
            ? Object.entries(grouped)
                .sort(([letterA], [letterB]) => sortAlphabetByOrder(letterA, letterB)) 
                .map(([letter, keywords]) => ({
                    label: letter,
                    options: keywords
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((keyword) => ({ label: keyword.name, value: keyword.name, selected: keyword.selected, disabled: keyword.disabled })),
                }))
            : [];
    };    

    const updateCategoryKeywords = async (categoryId, assignedKeywordsData) =>
    {
        try 
        {
            updateState({ loading: true });
            const success = await brandingApi.categoryKeywordsUpdate(categoryId, assignedKeywordsData);
    
            if (success)
            {
                refetchCategories();    
                setLastUpdated(new Date().valueOf());
                setTimeout(() => updateState({ loading: false }), 3000);

            }
            return;          
        }  
        catch (err)
        {
            console.log(err?.message);
            updateState({ loading: false });

        } 
           
        
    };
        

    const copyOrMoveCategory = async (categoryId, newParentId, oldParentId, move=false) =>
    {
        const { success, message } = await brandingApi.copyOrMoveCategory(categoryId, newParentId, oldParentId, move);
        
        if (success)
        {  
            refetchCategories();
            setLastUpdated(new Date().valueOf());
        }            
        
        return { success, message };
    };

    const value = {
        getEntitiesKeywords,
        groupByKeywordAlphabet,
        updateCategoryKeywords,
        searchCategories,
        getChildrenOf,
        getParentOf,
        getCategoriesFromIds,
        getCategoryStyledIcon,
        handleGetStoresFromCategories,
        createCategory,
        deleteCategory,
        reorderCategories,
        copyOrMoveCategory,
        categoryFlatMap,
        categoryGroups,
        getIconInfo,
        lastUpdated,
        categoriesIsLoading,
        propertyId,
        languages,
        getCurrentPropertyLangOptions,
        buildingOptions
    };

    return (
        <CategoryContext.Provider value={value}>
            {children}
        </CategoryContext.Provider>
    );
};

export { CategoryProvider, CategoryContext };
