import React from "react";
import { Accordion, Button, Image } from "semantic-ui-react";
import { GEO_REFERENCING_FLOOR_PLAN_TABS_INFO, GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES } from "../../../_constants/mapEditor";
import "./floorPlanGeoReference.css";
import useFloorPlanCommon from "./hooks/useFloorPlanCommon";
export const MapEditorSidebarItem = ({ className, icon, content, name, onClick, active, disabled, title }) => (
    <Button title={title} className={`mapEditorSidebarItem ${className}`} name={name} onClick={onClick} active={active} disabled={disabled}>
        <Image as="span" src={`/img/icon-${icon}.svg`} alt="" />
        {content}
    </Button>
);

const AccordionSection = ({ title, children, active, index, onClick }) => (
    <>
        <Accordion.Title active={active} index={index} onClick={onClick}>
            {title}
        </Accordion.Title>
        <Accordion.Content active={active}>
            {children}
        </Accordion.Content>
    </>
);

const FloorGeoReferencingNavigationSideBar = ({ index, active, onClick }) =>
{

    const { onTabActionWithToast, activeTab, allowEditingForTab } = useFloorPlanCommon();


    return <AccordionSection title="Floor Plan" index={index} active={active} onClick={onClick}>
        <div className="alignerMapEditor">
            <div className="mapEditorSidebarGroup">

                <MapEditorSidebarItem
                    content={GEO_REFERENCING_FLOOR_PLAN_TABS_INFO[GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES.floorPlanImage].name}
                    icon={"add-floor-plan"}
                    active={activeTab === GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES.floorPlanImage}
                    onClick={() => onTabActionWithToast(GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES.floorPlanImage)}
                    title={allowEditingForTab.floorPlanImage ? "Import Floor Plan (Gallery or PDF)" : "Lock for Editing"}
                />

                <MapEditorSidebarItem
                    content={GEO_REFERENCING_FLOOR_PLAN_TABS_INFO[GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES.geoReference].name}
                    icon={"geo-referencing"}
                    active={activeTab === GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES.geoReference}
                    onClick={() => onTabActionWithToast(GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES.geoReference)}
                    title={allowEditingForTab.geoReference ? "Match points on the floor plan with their real-world locations on the map" : "Lock for Editing"}
                />

                <MapEditorSidebarItem
                    content={GEO_REFERENCING_FLOOR_PLAN_TABS_INFO[GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES.geoReferenceEdit].name}
                    icon={"edit"}
                    active={activeTab === GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES.geoReferenceEdit}
                    onClick={() => onTabActionWithToast(GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES.geoReferenceEdit)}
                    title={allowEditingForTab.geoReferenceEdit ? "Rotate, scale, translate, or distort the floor plan" : "Lock for Editing"}
                />
            </div>

        </div>

    </AccordionSection >;
};

export default FloorGeoReferencingNavigationSideBar;
