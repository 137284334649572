import { DEFAULT_LANGUAGE_CODE } from "mapsted.maps/utils/map.constants";
import React, { useRef, useContext, useEffect } from "react";
import { StylesAndThemesContext } from "../../../store/StylesAndThemesContext";
// import { MapButtons } from "../map/MapButtons";
import { useMapZoomMethods, useStylesEditorMap } from "../map/mapHooks";
import { MapButtons } from "./MapButtons";

const StylesAndThemesMap = () =>
{
    const ctx = useContext(StylesAndThemesContext);

    const { state: ctxState } = ctx;
    const { tileLayer } = ctxState;

    const mapContainerRef = useRef(null);

    const { mapRef: olMap } = useStylesEditorMap(
        mapContainerRef,
        { 
            mapOptions: {
                controlsOptions: { rotate: false },
            },
        },
    );

    useEffect(() =>
    {
        // update base map
        if (olMap && tileLayer && tileLayer.url)
        {
            const layers = olMap.current.getLayers()?.getArray();
            if (layers)
            {
                const baseMapLayer = layers.find((layer) => layer.get("isBaseMapLayer"));
                if (baseMapLayer && baseMapLayer.getSource())
                {
                    baseMapLayer.getSource().setUrl(tileLayer.url);
                }
            }
        }

    }, [olMap, tileLayer]);

    useEffect(() => 
    {
        let mapRotation = 0;

        const buildingSettings = ctxState.propertySettings?.buildingSettings;
        if (ctxState.buildingId && buildingSettings?.[ctxState.buildingId]?.mapSettings?.mapRotation)
        {
            mapRotation = buildingSettings[ctxState.buildingId].mapSettings.mapRotation;
        }
        else if (ctxState.propertySettings?.mapRotation)
        {
            mapRotation = ctxState.propertySettings?.mapRotation;
        }

        handleMapRotation(mapRotation);

    }, [ctxState.propertySettings, ctxState.buildingId]);

    const handleMapRotation = (mapRotation) => 
    {
        const rotationAngle = mapRotation[DEFAULT_LANGUAGE_CODE];
        if (rotationAngle || rotationAngle === 0) 
        {
            olMap.current.getView().setRotation(rotationAngle);
        }
    };


    const { zoomIn, zoomOut } = useMapZoomMethods(olMap);

    return (
        <div className="styles-themes-map">
            <div className="map-container" ref={mapContainerRef}>
                <MapButtons
                    onZoomIn={zoomIn}
                    onZoomOut={zoomOut}
                />
            </div>
        </div>
    );
};

export default StylesAndThemesMap;