import ObjectId from "bson-objectid";
import { useCallback, useContext, useMemo } from "react";
import { toast } from "sonner";
import { EDIT_TYPES, FLOOR_PLAN_ALERT_MESSAGES, FLOOR_PLAN_LAYER_IDS, GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES, MAP_EDITOR_TOOLS, MIN_FEATURES_TO_SELECT_FOR_GEO_REFERENCE } from "../../../../_constants/mapEditor";
import FloorPlanContext from "../FloorPlanContex";
import { defaultGeoReferencingFloorPlan } from "../FloorPlanProvider";
import { processAddNewFeature, processModifiedFeature, processRemoveFeature } from "../utils/floorPlan.utils";


export const ACTIONS_TYPES_HASH = {
    ADD_FEATURE_TO_LOCAL: "ADD_FEATURE_TO_LOCAL",
    REMOVE_FEATURE_FROM_LOCAL: "REMOVE_FEATURE_FROM_LOCAL",
    UPDATE_FEATURE_INTO_LOCAL: "UPDATE_FEATURE_INTO_LOCAL",
    TOGGLE_IMAGE_UPLOAD_MODAL: "TOGGLE_IMAGE_UPLOAD_MODAL",
    TOGGLE_PDF_UPLOAD_MODAL: "TOGGLE_PDF_UPLOAD_MODAL",
    CHANGE_OR_TOGGLE_TABS: "CHANGE_OR_TOGGLE_TABS",
    ADD_FLOOR_PLAN_IMAGE: "ADD_FLOOR_PLAN_IMAGE",
    ADD_INTERACTION_TO_OL_MAP: "ADD_INTERACTION_TO_OL_MAP",
    REMOVE_INTERACTION_FROM_OL_MAP: "REMOVE_INTERACTION_FROM_OL_MAP",
    UPDATE_OL_MAP_PARENT: "UPDATE_OL_MAP_PARENT",
    UPDATE_CENTER: "UPDATE_CENTER",
    UPDATE_ROTATION: "UPDATE_ROTATION",
    UPDATE_SCALE: "UPDATE_SCALE",
    UPDATE_OPACITY: "UPDATE_OPACITY",
    FORCE_SET: "FORCE_SET",
    REFRESH_STATE: "REFRESH_STATE",
};

//right not needed but will be used in future in required
// export const ACTIONS_HASH_THAT_NEEDS_SYNC_TO_DB = {
//     [ACTIONS_TYPES_HASH.ADD_FEATURE_TO_LOCAL]: true,
//     [ACTIONS_TYPES_HASH.REMOVE_FEATURE_FROM_LOCAL]: true,
//     [ACTIONS_TYPES_HASH.UPDATE_FEATURE_INTO_LOCAL]: true,
//     [ACTIONS_TYPES_HASH.ADD_FLOOR_PLAN_IMAGE]: true,
//     [ACTIONS_TYPES_HASH.UPDATE_OPACITY]: true,
//     [ACTIONS_TYPES_HASH.UPDATE_SCALE]: true,
//     [ACTIONS_TYPES_HASH.UPDATE_CENTER]: true,
//     [ACTIONS_TYPES_HASH.UPDATE_ROTATION]: true,
//     [ACTIONS_TYPES_HASH.CHANGE_OR_TOGGLE_TABS]: true,
// };



/**
 * Reducer function for updating the floor plan state based on the given action and payload.
 *
 * @param {Object} prevState - The previous state of the floor plan.
 * @param {string} actionName - The name of the action to be performed.
 * @param {Object} payload - The data to be used for updating the state.
 * @return {Object} The updated state of the floor plan.
 */
export const floorPlanStateReducer = (prevState, actionName, payload) =>
{
    let dataCopy = { ...prevState };

    const callBackHash = {
        [ACTIONS_TYPES_HASH.ADD_FEATURE_TO_LOCAL]: () =>
        {
            const feature = payload;
            let result = processAddNewFeature(dataCopy, feature);


            return {
                ...dataCopy,
                ...result
            };
        },
        [ACTIONS_TYPES_HASH.REMOVE_FEATURE_FROM_LOCAL]: () =>
        {
            const featureToDelete = payload;
            const result = processRemoveFeature(dataCopy, featureToDelete);

            return {
                ...dataCopy,
                ...result
            };
        },
        [ACTIONS_TYPES_HASH.UPDATE_FEATURE_INTO_LOCAL]: () =>
        {
            const modifiedFeature = payload;
            const result = processModifiedFeature(dataCopy, modifiedFeature);

            return {
                ...dataCopy,
                ...result
            };

        },
        [ACTIONS_TYPES_HASH.CHANGE_OR_TOGGLE_TABS]: () =>
        {
            const tabValue = payload;
            if (dataCopy.activeTab === tabValue)
            {
                dataCopy.activeTab = "";
            }
            else
            {
                dataCopy.activeTab = tabValue;
            }
            return dataCopy;
        },
        [ACTIONS_TYPES_HASH.TOGGLE_PDF_UPLOAD_MODAL]: () =>
        {
            let isPdfModalOpen = payload;
            if (isPdfModalOpen === undefined)
            {
                isPdfModalOpen = !dataCopy.isPdfModalOpen;
            }
            dataCopy.isPdfModalOpen = isPdfModalOpen;
            return dataCopy;
        },
        [ACTIONS_TYPES_HASH.TOGGLE_IMAGE_UPLOAD_MODAL]: () =>
        {
            let isImageModalOpen = payload;
            if (isImageModalOpen === undefined)
            {
                isImageModalOpen = !dataCopy.isImageModalOpen;
            }
            dataCopy.isImageModalOpen = isImageModalOpen;
            return dataCopy;
        },
        [ACTIONS_TYPES_HASH.ADD_FLOOR_PLAN_IMAGE]: () =>
        {
            const floorPlanImageFilerId = payload;
            //close any open modal,
            dataCopy.isPdfModalOpen = false;
            dataCopy.isImageModalOpen = false;
            dataCopy.selectedFloorPlanImageId = floorPlanImageFilerId;


            return {
                ...defaultGeoReferencingFloorPlan,
                selectedFloorPlanImageId: floorPlanImageFilerId,
                activeTab: dataCopy.activeTab
            };

        },
        [ACTIONS_TYPES_HASH.UPDATE_OPACITY]: () =>
        {
            const newOpacity = payload;
            dataCopy.opacity = newOpacity;
            return dataCopy;
        },
        [ACTIONS_TYPES_HASH.UPDATE_CENTER]: () =>
        {
            const newCenter = payload;
            dataCopy.center = newCenter;
            return dataCopy;
        },
        [ACTIONS_TYPES_HASH.UPDATE_ROTATION]: () =>
        {
            const newRotation = payload;
            dataCopy.rotation = newRotation;
            return dataCopy;
        },
        [ACTIONS_TYPES_HASH.UPDATE_SCALE]: () =>
        {
            const newScale = payload;
            dataCopy.scale = newScale;
            return dataCopy;

        },
        [ACTIONS_TYPES_HASH.FORCE_SET]: () => payload
    };


    //if its a valid action then our state has been updated and will generate new id for for state...
    if (callBackHash[actionName])
    {
        dataCopy = callBackHash[actionName]();
        dataCopy._id = ObjectId().toString();
    }


    return dataCopy;
};




/**
 * This hooks return all the common  handlers  and state for floor plan geo referencing
 * @returns {*}
 */
const useFloorPlanCommon = () =>
{

    const {
        editType,
        geoReferencingFloorPlan,
        handleUpdateFloorPlanState,
        onActiveToolChange,
        onLayersVisibilityChange,
        activeTool,
        getLevelNotLockedAlertMessage,
        geoRefLayersVisibility: geoRefLayersVisibilityHash,
        isEditMode,
        isInjectingStateDataToLayersAllowed,
        setInjectingLayersDataFromState,
        setLoading,
        floorPlanUrlBase64,
        floorPlanUrlBase64Query
    } = useContext(FloorPlanContext);

    const { isFloorPlanGeoReferncingIsActive, activeTab } = useMemo(() =>
    {
        const activeTab = geoReferencingFloorPlan.activeTab;
        return {
            isFloorPlanGeoReferncingIsActive: Object.values(GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES).includes(activeTab) && activeTool === MAP_EDITOR_TOOLS.FLoorPlan,
            activeTab
        };
    }, [geoReferencingFloorPlan.activeTab, activeTool]);
    const allowEditingForTab = useMemo(() =>
    {
        const defaultInfoObj = { allowEditing: false, message: FLOOR_PLAN_ALERT_MESSAGES.MISSING_FLOOR_PLAN_IMAGE };

        if (!isEditMode) return {};

        const info = Object.values(GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES).reduce((acc, tabName) =>
        {
            acc[tabName] = { ...defaultInfoObj };
            return acc;
        }, {});

        info[GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES.floorPlanImage].allowEditing = true;
        info[GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES.floorPlanImage].message = null;

        if (geoReferencingFloorPlan.selectedFloorPlanImageId)
        {
            info[GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES.geoReference].allowEditing = true;
            info[GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES.geoReference].message = null;
            if (geoReferencingFloorPlan.geoRefFeatures.length >= 2 * MIN_FEATURES_TO_SELECT_FOR_GEO_REFERENCE)
            {
                info[GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES.geoReferenceEdit].allowEditing = true;
                info[GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES.geoReferenceEdit].message = null;
            }
            else
            {
                info[GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES.geoReferenceEdit].message = FLOOR_PLAN_ALERT_MESSAGES.MISSING_GEO_REFERENCES;
            }
        }
        return info;
    }, [geoReferencingFloorPlan]);

    const handleGeoreferncingFloorPlanTabChange = useCallback((activeTab) =>
    {
        setInjectingLayersDataFromState(true);
        const alertMessage = getLevelNotLockedAlertMessage();
        if (alertMessage)
        {
            toast.message(alertMessage);
            return;
        }
        if (activeTool !== MAP_EDITOR_TOOLS.FLoorPlan)
        {
            //this will make the selected tool state as floor plan
            onActiveToolChange(MAP_EDITOR_TOOLS.FLoorPlan);

            //when floor plan tab is being selected update the floor_plan_image_layer visibility
            onLayersVisibilityChange({ [FLOOR_PLAN_LAYER_IDS.FLOOR_PLAN_IMAGE_LAYER]: true });

        }

        handleUpdateFloorPlanState((prev) =>
        {
            if (prev.activeTab === activeTab)
            {
                //this will toggle the selected tool state
                onActiveToolChange(MAP_EDITOR_TOOLS.FLoorPlan);
                //when floor plan tab is being unselected update the floor_plan_image_layer visibility
                onLayersVisibilityChange({ [FLOOR_PLAN_LAYER_IDS.FLOOR_PLAN_IMAGE_LAYER]: false });
                return {
                    ...prev,
                    activeTab: "" // unselect the floor plan tab
                };
            }
            return {
                ...prev,
                activeTab
            };
        });
    }, [activeTool, onLayersVisibilityChange, getLevelNotLockedAlertMessage, handleUpdateFloorPlanState]);



    const { perSelectImageId } = useMemo(() =>
    {
        const filterId = geoReferencingFloorPlan?.selectedFloorPlanImageId;
        return { preSelectImageId: filterId };
    }, [geoReferencingFloorPlan?.selectedFloorPlanImageId]);

    const handleAddFeatureToState = useCallback((feature) =>
    {
        setInjectingLayersDataFromState(true);
        const cb = (prevState) => floorPlanStateReducer(prevState, ACTIONS_TYPES_HASH.ADD_FEATURE_TO_LOCAL, feature);
        handleUpdateFloorPlanState(cb);
    }, [handleUpdateFloorPlanState]);

    const handleRemoveFeatureFromState = useCallback((feature) =>
    {
        setInjectingLayersDataFromState(true);
        const cb = (prevState) => floorPlanStateReducer(prevState, ACTIONS_TYPES_HASH.REMOVE_FEATURE_FROM_LOCAL, feature);
        handleUpdateFloorPlanState(cb);
    }, [handleUpdateFloorPlanState]);

    const handleModifyFeatureOnState = useCallback((feature) =>
    {
        setInjectingLayersDataFromState(true);
        const cb = (prevState) => floorPlanStateReducer(prevState, ACTIONS_TYPES_HASH.UPDATE_FEATURE_INTO_LOCAL, feature);
        handleUpdateFloorPlanState(cb);
    }, [handleUpdateFloorPlanState]);

    const isImageModalOpen = useMemo(() => geoReferencingFloorPlan.isImageModalOpen, [geoReferencingFloorPlan?.isImageModalOpen]);

    const toggleImageUploadModal = useCallback(() =>
    {
        handleUpdateFloorPlanState((prev) => floorPlanStateReducer(prev, ACTIONS_TYPES_HASH.TOGGLE_IMAGE_UPLOAD_MODAL));
    }, [handleUpdateFloorPlanState]);

    const onCLoseImageSelectorModal = useCallback(() =>
    {
        handleUpdateFloorPlanState((prev) => floorPlanStateReducer(prev, ACTIONS_TYPES_HASH.TOGGLE_IMAGE_UPLOAD_MODAL, false));
    }, [handleUpdateFloorPlanState]);


    const onOpenImageSelectorModal = useCallback(() =>
    {
        handleUpdateFloorPlanState((prev) => floorPlanStateReducer(prev, ACTIONS_TYPES_HASH.TOGGLE_IMAGE_UPLOAD_MODAL, true));
    }, [handleUpdateFloorPlanState]);

    //add's selected image to state and set's isImageModalOpen to false
    const onImportFloorPlan = useCallback((filerId) =>
    {
        setInjectingLayersDataFromState(true);
        handleUpdateFloorPlanState((prev) => floorPlanStateReducer(prev, ACTIONS_TYPES_HASH.ADD_FLOOR_PLAN_IMAGE, filerId));
    }, [handleUpdateFloorPlanState]);


    const isEntityEditTypeActive = useMemo(() => editType === EDIT_TYPES.ENTITY, [editType]);

    const isPdfImportModalIsOpen = useMemo(() => geoReferencingFloorPlan.isPdfModalOpen, [geoReferencingFloorPlan?.isPdfModalOpen]);
    const togglePdfUploadModal = useCallback(() =>
    {
        handleUpdateFloorPlanState((prev) => floorPlanStateReducer(prev, ACTIONS_TYPES_HASH.TOGGLE_PDF_UPLOAD_MODAL));
    }, [handleUpdateFloorPlanState]);

    const onCLosePdfSelectorModal = useCallback(() =>
    {
        handleUpdateFloorPlanState((prev) => floorPlanStateReducer(prev, ACTIONS_TYPES_HASH.TOGGLE_PDF_UPLOAD_MODAL, false));
    }, [handleUpdateFloorPlanState]);


    const onOpenImagePdfSelectorModal = useCallback(() =>
    {
        handleUpdateFloorPlanState((prev) => floorPlanStateReducer(prev, ACTIONS_TYPES_HASH.TOGGLE_PDF_UPLOAD_MODAL, true));

    }, [handleUpdateFloorPlanState]);


    const goToFloorPlanTab = useCallback(() =>
    {
        handleGeoreferncingFloorPlanTabChange(GEO_REFERENCING_FLOOR_PLAN_TAB_VALUES.floorPlanImage);
    }, [handleGeoreferncingFloorPlanTabChange]);

    const onCenterChange = useCallback((value, syncWithLayers = false) =>
    {

        setInjectingLayersDataFromState(syncWithLayers);
        handleUpdateFloorPlanState((prev) => floorPlanStateReducer(prev, ACTIONS_TYPES_HASH.UPDATE_CENTER, value));
    }, [handleUpdateFloorPlanState]);

    const onScaleChange = useCallback((value, syncWithLayers = false) =>
    {

        setInjectingLayersDataFromState(syncWithLayers);
        handleUpdateFloorPlanState((prev) => floorPlanStateReducer(prev, ACTIONS_TYPES_HASH.UPDATE_SCALE, value));
    }, [handleUpdateFloorPlanState]);

    const onRotateChange = useCallback((value, syncWithLayers = false) =>
    {

        setInjectingLayersDataFromState(syncWithLayers);
        handleUpdateFloorPlanState((prev) => floorPlanStateReducer(prev, ACTIONS_TYPES_HASH.UPDATE_ROTATION, value));
    }, [handleUpdateFloorPlanState]);

    const onOpacityChange = useCallback((value) =>
    {
        setInjectingLayersDataFromState(true);
        handleUpdateFloorPlanState((prev) => floorPlanStateReducer(prev, ACTIONS_TYPES_HASH.UPDATE_OPACITY, value));
    }, [handleUpdateFloorPlanState]);

    const setForceSetStateTo = useCallback((fields) =>
    {
        handleUpdateFloorPlanState((prev) => floorPlanStateReducer(prev, ACTIONS_TYPES_HASH.FORCE_SET, { ...prev, ...fields }));
    }, [setInjectingLayersDataFromState]);


    //this handlers check if edit mode is on or not the let calls provided call back function
    const callBackWithEditCheckAndToast = useCallback((cb, showToast = true, toastMessage) =>
    {
        if (isEditMode)
        {
            cb();
        }
        else if (showToast)
        {

            let message = toastMessage;

            if (!message)
            {
                message = getLevelNotLockedAlertMessage();
            }
            toast.error(message);
        }
    }, [isEditMode, getLevelNotLockedAlertMessage]);

    const onTabActionWithToast = useCallback((tabName) =>
    {
        const cb = () =>
        {
            if (allowEditingForTab[tabName]?.allowEditing)
            {
                handleGeoreferncingFloorPlanTabChange(tabName);
            }
            else if (allowEditingForTab[tabName]?.message)
            {
                const message = allowEditingForTab[tabName]?.message;
                toast.error(message);
            }
        };

        callBackWithEditCheckAndToast(cb);

    }, [handleGeoreferncingFloorPlanTabChange, callBackWithEditCheckAndToast, allowEditingForTab]);

    const refreshState = useCallback(() =>
    {
        setInjectingLayersDataFromState(true);
        handleUpdateFloorPlanState((prev) => floorPlanStateReducer(prev, ACTIONS_TYPES_HASH.REFRESH_STATE));
    }, [handleUpdateFloorPlanState]);






    return {
        ...geoReferencingFloorPlan,
        isImageSelectorOpen: isImageModalOpen,
        onCLoseImageSelectorModal,
        onOpenImageSelectorModal,
        onImportFloorPlan,
        activeTab,
        allowEditingForTab,
        handleGeoreferncingFloorPlanTabChange,
        isEntityEditTypeActive,
        isFloorPlanGeoReferncingIsActive,
        toggleImageUploadModal,
        handleUpdateFloorPlanState,
        activeFloorPlanUrl: floorPlanUrlBase64,
        perSelectImageId,
        isPdfImportModalIsOpen,
        togglePdfUploadModal,
        onOpenImagePdfSelectorModal,
        onCLosePdfSelectorModal,
        goToFloorPlanTab,
        handleModifyFeatureOnState,
        handleRemoveFeatureFromState,
        handleAddFeatureToState,
        geoRefLayersVisibilityHash,
        onCenterChange,
        onScaleChange,
        onRotateChange,
        onOpacityChange,
        setInjectingLayersDataFromState,
        isInjectingStateDataToLayersAllowed,
        setForceSetStateTo,
        onTabActionWithToast,
        refreshState,
        setLoading,
        floorPlanUrlBase64Query
    };
};

export default useFloorPlanCommon;
