import React from "react";
import { useSetState } from "ahooks";
import { ButtonIcon } from "../../../common/ButtonIcon";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import { DEFAULT_LANGUAGE_CODE } from "mapsted.maps/utils/map.constants";
import { Popup } from "semantic-ui-react";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";

import "./DynamicMapLayerSidebar.css";
import { AUTOMATION_RULE_CONDITIONS } from "../../../../_constants/constants";
import { useTranslation } from "react-i18next";

const DynamicMapLayerItem = ({
    dynamicMapLayer,
    onEdit,
    onDelete,
    onSelect,
    className,
    selectedDynamicMapLayer,
    enableCopyApiKey
}) =>
{
    const [state, setState] = useSetState({
        expanded: false,
        deleteModalOpen: false
    });

    const toggleExpandAndCollapse = () =>
    {
        const { expand } = state;
        setState({
            expand: !expand
        });
    };

    const active = selectedDynamicMapLayer.dynamicMapLayerId === dynamicMapLayer._id
        && !selectedDynamicMapLayer.configurationName
        && !selectedDynamicMapLayer.automationRuleId;

    const trans = useTranslation().t;

    return (
        <>
            <div className={`dynamicMapLayerConfigurationItem ${state.expand ? "expanded" : ""} ${className ? className : ""} ${active ? "active" : ""}`}>
                <ButtonIcon
                    icon="angle-down"
                    className={`expandIcon ${state.expand ? "" : "iconOpen"}`}
                    onClick={toggleExpandAndCollapse} />
                <p
                    className="activitySidebar-mapLayerName"
                    onClick={() => onSelect(dynamicMapLayer._id, undefined, undefined)} >{dynamicMapLayer.dataStreamName[DEFAULT_LANGUAGE_CODE]}</p>
                <div className="overlayConfigActions">
                    {onEdit && <ButtonIcon icon="edit" onClick={() => onEdit(dynamicMapLayer._id)} />}
                    {enableCopyApiKey
                        && <Popup
                            content={trans("MaintenanceCreateDynamicMapSidebar.Copy_API_Key")}
                            basic
                            trigger={
                                <CopyToClipboard text={dynamicMapLayer.apiKey}>
                                    <ButtonIcon icon="copy2" />
                                </CopyToClipboard>
                            } />}
                    {onDelete
                        && <DeleteConfirmationModal
                            onDeleteIconClick={() => setState({
                                deleteModalOpen: true,
                            })}
                            onDeleteClick={() => 
                            {
                                setState({
                                    deleteModalOpen: false,
                                });
                                onDelete(dynamicMapLayer._id);
                            }}
                            deleteModalOpen={state.deleteModalOpen}
                            onClose={() => setState({ deleteModalOpen: false })}
                            message={trans("MaintenanceDynamicLayerSidebar.Delete_Confirmation_Message", { overlayName: dynamicMapLayer.dataStreamName[DEFAULT_LANGUAGE_CODE] })}
                        />
                    }
                </div>
            </div>
            {state.expand
                && <div className="automationRuleItems">
                    {dynamicMapLayer.automationRules.map((automationRule, index) => (
                        <AutomationRuleItem
                            key={automationRule._id}
                            automationRule={automationRule}
                            index={index}
                            onSelect={(automationRuleId) => onSelect(dynamicMapLayer._id, automationRuleId, undefined)}
                            selectedDynamicMapLayer={selectedDynamicMapLayer}
                            dataPointName={dynamicMapLayer.dataPointName}
                        />
                    ))}
                </div>}
        </>
    );
};

const AutomationRuleItem = ({ automationRule, index, onSelect, selectedDynamicMapLayer, dataPointName }) =>
{
    const active = selectedDynamicMapLayer.automationRuleId === automationRule._id;

    const trans = useTranslation().t;
    return (
        <div
            key={automationRule._id}
            className="dynamicMapLayerAutomationRuleItem"
            onClick={() => onSelect(automationRule._id)}
        >
            <span className={`automationRuleLabel ${active ? "active" : ""}`}>{`${trans("MaintenanceCreateDynamicMapSidebar.Rule") } ${index + 1}`}</span> <span className={"automationRuleItem"}>{`${getRuleDescription(automationRule, dataPointName)}`}</span>
        </div>);
};

const getRuleDescription = (automationRule, dataPointName) =>
{
    const { condition, value } = automationRule.dataPointCondition;

    let conditionSign = "";

    if (condition === AUTOMATION_RULE_CONDITIONS.GREATER)
    {
        conditionSign = ">";
    }
    else if (condition === AUTOMATION_RULE_CONDITIONS.EQUAL)
    {
        conditionSign = "=";
    }
    else if (condition === AUTOMATION_RULE_CONDITIONS.LESSER)
    {
        conditionSign = "<";
    }

    return `${dataPointName} ${conditionSign} ${value}`;
};

export default DynamicMapLayerItem;