import React from "react";
import { useTranslation } from "react-i18next";
import "./ErrorLabel.css";

export const ErrorLabel = ({ label, gap, small, content, multiple, children, large }) =>
{
    if (content)
    {
        return ( <ErrorLabelWithContent multiple={multiple} label={label} gap={gap} small={small} content={content} /> );
    }
    else
    {
        return ( <ErrorLabelWithoutContent multiple={multiple} label={label} gap={gap} small={small} large={large}>{children}</ErrorLabelWithoutContent> );
    }
};


const ErrorLabelWithoutContent = ({ label, gap, small, children, multiple, large }) =>
{
    const trans = useTranslation().t;
    return (        
        <div className={`error-box${gap ? " error-box-web" :""}${small ? " error-box-small":""}${large? " error-box-large": ""}${multiple ? " isMultiple":""}`}> 
            <div className="header">{trans("ErrorLabel.Error_")}</div>           
            {children}{label }      
        </div>
    );
};

const ErrorLabelWithContent = ({ label, gap, small, content, multiple }) =>
{
    const trans = useTranslation().t;
    return (
        <div className={`error-box ${gap ? " error-box-web" :""}${small ? " error-box-small": "error-box-large"}${multiple ? " isMultiple":""}`}>            
            <div className="header">{trans("ErrorLabel.Error_")} {label}</div>
            <p>
                {content}
            </p>
        </div>
    );
};
