import React from "react";
import { isObject } from "lodash";
import _ from "lodash";

import {
    COLUMN_IDS,
    DEFAULT_COLUMN_IDS,
    ENITITY_LABEL_UPDATE_FIELDS,
    ENTITY_UPDATE_FIELDS,
    LIST_MODES,
    LIST_VIEW_ADVANCED_FILTERATION_MODEL,
    LIST_VIEW_BASIC_FILTERATION_MODEL,
    LIST_VIEW_COLUMNS_STORAGE_KEY,
} from "../_constants/listView";
import { deepValue } from "mapsted.utils/objects";
import {
    DEFAULT_LANGUAGE_CODE,
    ENTITY_LABEL_TYPES,
} from "../_constants/constants";
import { EntityType, EntityTypes, StructureEntityType } from "mapsted.maps/utils/entityTypes";
import EditEntityButton from "../components/listView/listViewTable/EditEntityButton";
import Input from "../components/listView/listViewCustomComps/Input/Input";
import { filerUrl } from "./utils";

function keyExists(obj, key) 
{
    if (_.has(obj, key)) 
    {
        return true;
    }

    for (const prop in obj) 
    {
        if (_.isObject(obj[prop])) 
        {
            if (keyExists(obj[prop], key)) 
            {
                return true;
            }
        }
    }

    return false;
}

const filterByLanguages = (languageCode, entities) => 
{
    if (!languageCode) return entities;
    return entities.filter((entity) => keyExists(entity, languageCode));
};

export const filterBySearch = (searchValue, entities, languageCode = "en") => 
{
    if (!searchValue) return entities;
    let filteredEntities = [];
    if (!isNaN(Number(searchValue))) 
    {
        const searchValueNum = Number(searchValue);

        filteredEntities = entities.filter((entity) => 
        {
            const longNameNum = Number(
                entity?.entityLabel?.longName?.[languageCode] || undefined
            );
            const shortNameNum = Number(
                entity?.entityLabel?.shortName?.[languageCode] || undefined
            );

            return (
                entity?.entityId === searchValueNum
        || (!isNaN(longNameNum) && longNameNum === searchValueNum)
        || (!isNaN(shortNameNum) && shortNameNum === searchValueNum)
            );
        });
    }
    else 
    {
        let searchValueLowerCase = searchValue.toLowerCase();
        filteredEntities = entities.filter(
            (entity) => entity?.entityLabel?.longName?.[languageCode]
                ?.toLowerCase()
                ?.includes(searchValueLowerCase)
        || entity?.entityLabel?.shortName?.[languageCode]
            ?.toLowerCase()
            ?.includes(searchValueLowerCase)
        );
    }

    return filteredEntities;
};

const filterByLongName = (longName, entities, languageCode) => 
{
    if (!longName) return entities;
    return entities.filter((entity) => entity?.entityLabel?.longName?.[languageCode]
        ?.toLowerCase()
        ?.includes(longName.toLowerCase())
    );
};

const filterByShortName = (shortName, entities, languageCode) => 
{
    if (!shortName) return entities;
    return entities.filter((entity) => entity?.entityLabel?.shortName?.[languageCode]
        ?.toLowerCase()
        ?.includes(shortName.toLowerCase())
    );
};

const filterByType = (type, entities) => 
{
    if (!type) return entities;
    return entities.filter((entity) => getEntityType(entity) === type);
};

const filterByKeywords = (keywords, entities, languageCode) => 
{
    if (!keywords.length) return entities;
    return entities.filter((entity) => entity?.entityLabel?.keywords?.[languageCode]?.find((keyword) => keywords.includes(keyword)
    )
    );
};

const filterByOnlineKeywords = (onlineKeywords, entities, languageCode) => 
{
    if (!onlineKeywords.length) return entities;
    return entities.filter((entity) => entity?.entityLabel?.onlineKeywords?.[languageCode]?.find((onlineKeyword) => onlineKeywords.includes(onlineKeyword)
    )
    );
};

const filterBySubCategories = (subCategories, entities, languageCode) => 
{
    if (!subCategories.length) return entities;
    return entities.filter((entity) => entity?.entityLabel?.subCategories.find((subCategory) => subCategories.includes(subCategory?.name?.[DEFAULT_LANGUAGE_CODE])
    )
    );
};

const filterByCategory = (category, entities, languageCode) => 
{
    if (!category) return entities;
    return entities.filter(
        (entity) => entity?.entityLabel?.category?.name?.[DEFAULT_LANGUAGE_CODE] === category
    );
};

const filterByWebsite = (website, entities, languageCode) => 
{
    if (!website) return entities;
    return entities.filter((entity) => entity?.entityLabel?.website?.[languageCode]
        ?.toLowerCase()
        ?.includes(website.toLowerCase())
    );
};

const filterByPhoneNumber = (phoneNumber, entities) => 
{
    if (!phoneNumber) return entities;
    return entities.filter((entity) => entity?.entityLabel?.phoneNumber?.includes(phoneNumber)
    );
};

const filterByEntityId = (entityId, entities) => 
{
    if (!entityId) return entities;
    return entities.filter((entity) => entity.entityId === Number(entityId));
};

const composeFilters =
  (...filterFns) => ({ entities, languageCode }) => filterFns.reduce(
      (acc, filterFn) => filterFn(acc, languageCode) || [],
      entities
  );

export const filterEntitiesByBasicSearch = ({ entities, basicFilteration }) => filterBySearch(basicFilteration.searchValue, entities, basicFilteration.languageCode);

export const filterEntitiesByAdvancedSearch = ({
    entities,
    advancedFilteration,
}) => 
{
    const {
        languageCode,
        longName,
        shortName,
        entityId,
        phoneNumber,
        website,
        category,
        subCategory,
        type,
        keywords,
        onlineKeywords,
    } = advancedFilteration;

    return composeFilters(
        filterByLongName.bind(null, longName),
        filterByShortName.bind(null, shortName),
        // filterByWebsite.bind(null, website),
        filterByCategory.bind(null, category),
        filterBySubCategories.bind(null, subCategory),
        filterByKeywords.bind(null, keywords),
        filterByType.bind(null, type),
        filterByOnlineKeywords.bind(null, onlineKeywords),
        filterByPhoneNumber.bind(null, phoneNumber),
        filterByEntityId.bind(null, entityId)
    )({ entities, languageCode });
};

export const getSortedEntities = (entities) => entities.sort((a, b) => (a?.entityLabel?.longName?.[DEFAULT_LANGUAGE_CODE] || "").localeCompare(
    b?.entityLabel?.longName?.[DEFAULT_LANGUAGE_CODE] || ""
)
);

export const getCategoriesList = ({
    getParentOf,
    categories,
}) => 
{
    const filteredCategories = Object.values(categories).filter(
        (c) => !c.isRoot && getParentOf(c) && getParentOf(c).isRoot
    );

    const sortedCategoryNames = filteredCategories
        .sort((a, b) => a?.value?.localeCompare(b?.value));

    return sortedCategoryNames;
};

export const getSubCategoryList = ({
    data,
    categories,
}) => 
{
    const filteredSubCategories = Object.values(categories).filter(
        (sub) => sub._id !== data?.entityLabel?.category?._id && !sub.isRoot
    );
    const sortedSubCategoryNames = filteredSubCategories
        .map((subCat) => 
        {
            const value = _.get(subCat, `name.${DEFAULT_LANGUAGE_CODE}`);
            return { key: subCat._id, text: value, value };
        })
        .sort((a, b) => a?.value?.localeCompare(b?.value));
    return sortedSubCategoryNames || [];
};

export const getProcessedEntities = ({
    entities,
    basicFilteration,
    advancedFilteration,
}) => 
{
    let fEntities = entities;
    if (
        !Object.values(_.omit(advancedFilteration, "languageCode")).every(
            (item) => !item || !item.length
        )
    ) 
    {
        fEntities = filterEntitiesByAdvancedSearch({
            entities: fEntities,
            advancedFilteration,
        });
        fEntities = getSortedEntities(fEntities);
    }
    else 
    {
        fEntities = filterEntitiesByBasicSearch({
            entities: fEntities,
            basicFilteration,
        });
        fEntities = getSortedEntities(fEntities);
    }

    return fEntities.map((entity) => _.cloneDeep(entity));
};

export const getListViewBasicFilterationModel = ({ floorId } = {}) => ({
    ...LIST_VIEW_BASIC_FILTERATION_MODEL,
});

export const getListViewAdvancedFilterationModel = () => ({
    ...LIST_VIEW_ADVANCED_FILTERATION_MODEL,
});

export const extractLangCodes = (data) => 
{
    let languages = [];
    for (let key in data) 
    {
        const dataValue = data[key];
        if (isObject(dataValue) && "en" in dataValue) 
        {
            languages = [...languages, ...Object.keys(dataValue)];
        }
        else if (isObject(dataValue)) 
        {
            languages = [...languages, ...extractLangCodes(dataValue)];
        }
    }

    return languages;
};

export const getLanguageCodes = (entities, propertyId, buildingId, properties) => 
{
    let entitiesLangCodes = extractLangCodes(entities);

    let propertyLangCodes = [];
    let buildingLangCodes = [];
    if (propertyId && properties && properties[propertyId])
    {
        propertyLangCodes = extractLangCodes(properties[propertyId]);

        if (buildingId && properties[propertyId]?.buildings?.[buildingId])
        {
            buildingLangCodes = extractLangCodes(properties[propertyId].buildings[buildingId]);
        }
    }

    return [...new Set([ ...entitiesLangCodes, ...propertyLangCodes, ...buildingLangCodes ])];
};

export const isAdvancedFilterationActive = (advancedFilteration) => 
{
    const advancedFilterationVal = _.omit(advancedFilteration, "languageCode");
    return Object.values(advancedFilterationVal).some((val) => !_.isEmpty(val));
};

export const generateFallbackMessage = ({ basicFilteration, advancedFilteration, entities, trans }) => 
{

    if (
        (basicFilteration.searchValue
            || isAdvancedFilterationActive(advancedFilteration))
        && _.isEmpty(entities)
    ) 
    {
        return trans("ListView.There_are_no_results_matching_your_searc");
    }
    else if (_.isEmpty(entities)) 
    {
        return trans("ListView.No_rows_to_show");
    }
    else 
    {
        return "";
    }
};


export const getExtractedValues = (data, languageCode) => 
{
    let categories = [];
    let subCategories = [];
    let keywords = [];
    let onlineKeywords = [];
    let entityTypes = [];
    data.forEach((dataValue) => 
    {
        if (dataValue?.entityLabel?.category?.name?.[DEFAULT_LANGUAGE_CODE]) 
        {
            categories.push(dataValue?.entityLabel?.category?.name?.[DEFAULT_LANGUAGE_CODE]);
        }
        if (dataValue?.entityLabel?.subCategories?.length) 
        {
            subCategories = subCategories.concat(
                dataValue?.entityLabel?.subCategories.reduce(
                    (prev, subCategory) => subCategory?.name?.[DEFAULT_LANGUAGE_CODE]? [...prev, subCategory?.name?.[DEFAULT_LANGUAGE_CODE]]: [...prev],
                    []
                )
            );
        }

        if (dataValue.entityLabel?.keywords?.[languageCode]) 
        {
            keywords = keywords.concat(
                dataValue.entityLabel?.keywords?.[languageCode]
            );
        }

        if (dataValue.entityLabel?.onlineKeywords?.[languageCode]) 
        {
            onlineKeywords = onlineKeywords.concat(
                dataValue.entityLabel?.onlineKeywords?.[languageCode]
            );
        }

        if (dataValue.entityType) 
        {
            entityTypes.push(getEntityType(dataValue));
        }
    });

    return {
        categories: [...new Set(categories)],
        subCategories: [...new Set(subCategories)],
        keywords: [...new Set(keywords)],
        onlineKeywords: [...new Set(onlineKeywords)],
        entityTypes: [...new Set(entityTypes)],
    };
};

export const getDropdownOptions = (data) => data.map((dataValue, idx) => ({
    key: dataValue?._id || idx,
    text: dataValue,
    value: dataValue,
}));

export const getLanguageDropdownOptions = (languages = ["en"]) => languages.map((lang, idx) => ({
    key: idx,
    text: lang.toUpperCase(),
    value: lang,
}));

export const getFloorOptions = (floors) => floors?.map((floor) => ({
    key: floor.floorId,
    text: (
        <div className="chip-bx">
            <div>{floor?.shortName?.en}</div>
            <div className="chip">{floor.floorId}</div>
        </div>
    ),
    value: floor._id,
}));

const getEntityType = (entity) => 
{
    const { entityType, entityLabel } = entity;
    if (entityType !== EntityType.POINT_OF_INTEREST) 
    {
        return entityLabel?.buildingId? ENTITY_LABEL_TYPES[0]: ENTITY_LABEL_TYPES[deepValue(entity.entityLabel, "entityLabelType", 1)];
    }
    return EntityTypes[EntityType.POINT_OF_INTEREST].name;
};

export const getListColumnDefs = ({
    listMode,
    columnsVisible,
    languageCode,
    categoryList,
    categories,
    propertyId,
    buildingId,
    trans
}) => 
{
    let columnDefs = [];
    if (listMode === LIST_MODES.VIEW) 
    {
        columnDefs = getViewListColumnDefs(languageCode, propertyId, buildingId, trans);
    }
    else if (listMode === LIST_MODES.BULK_EDIT) 
    {
        columnDefs = getEditListColumnDefs(
            languageCode,
            categoryList,
            categories,
            propertyId,
            buildingId,
            trans
        );
    }

    return columnDefs
        .filter((columnDef) => columnsVisible.includes(columnDef.id))
        .map((columnDef) => ({ ...columnDef, suppressMovable: true }));
};

export const getViewListColumnDefs = (languageCode = "en", propertyId, buildingId, trans) => 
{
    const columnDefs = [
        {
            id: COLUMN_IDS.ICON,
            headerName: trans("ListViewColumns.Logo"),
            cellRenderer: (param) => param.data.entityLabel?.iconImage?.[DEFAULT_LANGUAGE_CODE] ? <div className="list-view-logo"><img src={filerUrl(param.data.entityLabel?.iconImage?.[DEFAULT_LANGUAGE_CODE])}></img></div> :<></>,
            sortable: false,
            minWidth: 70,
            maxWidth: 70,
        },
        {
            id: COLUMN_IDS.STORE_LONG_NAME,
            headerName: trans("ListViewColumns.Store_Long_Name"),
            valueGetter: (param) => param.data.entityLabel?.longName?.[languageCode] || "",
            sortable: false,
            minWidth: 200,

        },
        {
            id: COLUMN_IDS.STORE_SHORT_NAME,
            headerName: trans("ListViewColumns.Store_Short_Name"),
            valueGetter: (param) => param.data.entityLabel?.shortName?.[languageCode] || "",
            sortable: false,
            minWidth: 200,

        },
        {
            id: COLUMN_IDS.PROPERTY_ID,
            valueGetter: () => propertyId || "",
            headerName: trans("ListViewColumns.Property_Id"),
            sortable: false,
            minWidth: 110,
        },
        {
            id: COLUMN_IDS.BUILDING_ID,
            valueGetter: (param) =>
            {
                let buildingIdValue = "";

                if (buildingId)
                {
                    buildingIdValue = buildingId;
                }
                else if (param.data.entityLabel?.buildingId)
                {
                    buildingIdValue = param.data.entityLabel.buildingId;
                }

                return buildingIdValue;
            },
            headerName: trans("ListViewColumns.Building_Id"),
            sortable: false,
            minWidth: 110,
        },
        {
            id: COLUMN_IDS.ENTITY_ID,
            field: "entityId",
            headerName: trans("ListViewColumns.Entity_Id"),
            sortable: false,
            minWidth: 95,
        },
        {
            id: COLUMN_IDS.DESCRIPTION,
            valueGetter: (param) => param.data.entityLabel?.description?.[languageCode] || "",
            headerName: trans("ListViewColumns.Description"),
            sortable: false,
            minWidth: 300,

        },
        {
            id: COLUMN_IDS.PHONE_NUMBER,
            valueGetter: (param) => (param.data.entityType === EntityType.STRUCTURE && param.data.subEntityType === StructureEntityType.BUILDING ? param.data?.entityLabel?.phoneNumbers?.[0] : param.data?.entityLabel?.phoneNumber) || "",
            headerName: trans("ListViewColumns.Phone_Number"),
            sortable: false,
            minWidth: 200,

        },
        {
            id: COLUMN_IDS.WEBSITE,
            headerName: trans("ListViewColumns.Website"),
            valueGetter: (param) => param.data.entityLabel?.website?.[languageCode] || "",
            sortable: false,
            minWidth: 200,

        },
        {
            id: COLUMN_IDS.CATEGORY,
            valueGetter: (param) => param.data.entityLabel?.category?.name?.[DEFAULT_LANGUAGE_CODE] || "",
            headerName: trans("ListViewColumns.Category"),
            sortable: false,
            minWidth: 200,

        },
        {
            id: COLUMN_IDS.SUBCATEGORY,
            valueGetter: (param) => param.data.entityLabel?.subCategories
                ?.map((subCategory) => subCategory?.name?.[DEFAULT_LANGUAGE_CODE])
                ?.join(", ") || "",
            headerName: trans("ListViewColumns.Sub_Category"),
            sortable: false,
            minWidth: 300,

        },
        {
            id: COLUMN_IDS.ENTITY_TYPE,
            headerName: trans("ListViewColumns.Type"),
            valueGetter: (param) => getEntityType(param.data),
            sortable: false,
            minWidth: 200,

        },
        {
            id: COLUMN_IDS.KEYWORDS,
            headerName: trans("ListViewColumns.Keywords"),
            valueGetter: (param) => param.data.entityLabel?.keywords?.[languageCode]?.join(", ") || "",
            sortable: false,
            minWidth: 200,

        },
        {
            id: COLUMN_IDS.ONLINE_KEYWORDS,
            headerName: trans("ListViewColumns.Online_Keywords"),
            valueGetter: (param) => param.data.entityLabel?.onlineKeywords?.[languageCode]?.join(", ")
        || "",
            sortable: false,
            minWidth: 300,

        },
        {
            id: COLUMN_IDS.FACEBOOK,
            valueGetter: (param) => param.data.entityLabel?.socialMedia?.facebook || "",
            headerName: trans("ListViewColumns.Facebook"),
            sortable: false,
            minWidth: 200,

        },
        {
            id: COLUMN_IDS.INSTAGRAM,
            valueGetter: (param) => param.data.entityLabel?.socialMedia?.instagram || "",
            headerName: trans("ListViewColumns.Instagram"),
            sortable: false,
            minWidth: 200,

        },
        {
            id: COLUMN_IDS.TWITTER,
            valueGetter: (param) => param.data.entityLabel?.socialMedia?.twitter || "",
            headerName: trans("ListViewColumns.Twitter"),
            sortable: false,
            minWidth: 200,

        },
        {
            id: COLUMN_IDS.ICON_ROTATION,
            headerName: trans("ListViewColumns.Icon_Rotation"),
            valueGetter: (param) => param.data?.imageRotation?.[DEFAULT_LANGUAGE_CODE] || 0,
            sortable: false,
            minWidth: 200,

        },
        {
            id: COLUMN_IDS.TEXT_ROTATION,
            headerName: trans("ListViewColumns.Text_Rotation"),
            valueGetter: (param) => param.data?.textRotation?.[DEFAULT_LANGUAGE_CODE] || 0,
            sortable: false,
            minWidth: 200,

        },
        {
            id: COLUMN_IDS.SHOW_LOGO,
            headerName: trans("ListViewColumns.Show_Logo"),
            valueGetter: (param) => param.data?.style?.[DEFAULT_LANGUAGE_CODE]?.useIcon || false,
            sortable: false,
            minWidth: 200,

        },

        {
            id: COLUMN_IDS.SHOW_TEXT,
            headerName: trans("ListViewColumns.Show_Text"),
            valueGetter: (param) => param.data?.style[DEFAULT_LANGUAGE_CODE]?.useText || false,
            sortable: false,
            minWidth: 200,

        },
        {
            id: COLUMN_IDS.EDIT,
            headerName: trans("ListViewColumns.Action"),
            cellRenderer: EditEntityButton,
            flex: 0,
            width: 80,
            pinned: "right",
        },
    ];

    return columnDefs;
};

export const suppressKeyboardEvent = (params) => params.editing
  && (params.event.key === "Enter" || params.event.key === "Tab");

export const checkIsValidURL = (value) => value && !/^(?:(?:(?:https?|ftp):)?\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value) && "Enter a valid URL";
export const checkIsEmpty = (value) => !value.trim() && "Field cannot be empty";
export const checkRotation = (value) => !(value >= 0 && value <= 360) && "Rotation angle must be between 0 and 360";

export const getEditListColumnDefs = (
    languageCode = "en",
    categoryList,
    categories,
    propertyId,
    buildingId,
    trans
) => 
{
    const columnDefs = [
        {
            id: COLUMN_IDS.ICON,
            headerName: trans("ListViewColumns.Logo"),
            cellRenderer: (param) => param.data.entityLabel?.iconImage?.[DEFAULT_LANGUAGE_CODE] ? <div className="list-view-logo"><img src={filerUrl(param.data.entityLabel?.iconImage?.[DEFAULT_LANGUAGE_CODE])}></img></div> : <></>,
            sortable: false,
            editable: false,
            minWidth: 70,
            maxWidth: 70,
        },
        {
            id: COLUMN_IDS.STORE_LONG_NAME,
            headerName: trans("ListViewColumns.Store_Long_Name"),
            valueGetter: (param) => param.data.entityLabel?.longName?.[languageCode] || "",
            cellEditor: Input,
            cellEditorParams: {
                validators: [checkIsEmpty],
                valueModifier: (data, value) => (data.entityLabel.longName[languageCode] = value),
            },
            suppressKeyboardEvent: suppressKeyboardEvent,
            sortable: false,
            editable: true,
            minWidth: 200,

        },
        {
            id: COLUMN_IDS.STORE_SHORT_NAME,
            headerName: trans("ListViewColumns.Store_Short_Name"),
            valueGetter: (param) => param.data.entityLabel?.shortName?.[languageCode] || "",
            valueSetter: (param) => (param.data.entityLabel.shortName[languageCode] = param.newValue),
            sortable: false,
            editable: true,
            minWidth: 200,

        },
        {
            id: COLUMN_IDS.PROPERTY_ID,
            valueGetter: () => propertyId || "",
            headerName: trans("ListViewColumns.Property_Id"),
            sortable: false,
            minWidth: 110,
        },
        {
            id: COLUMN_IDS.BUILDING_ID,
            valueGetter: (param) =>
            {
                let buildingIdValue = "";

                if (buildingId)
                {
                    buildingIdValue = buildingId;
                }
                else if (param.data.entityLabel?.buildingId)
                {
                    buildingIdValue = param.data.entityLabel.buildingId;
                }

                return buildingIdValue;
            },
            headerName: trans("ListViewColumns.Building_Id"),
            sortable: false,
            minWidth: 110,
        },
        {
            id: COLUMN_IDS.ENTITY_ID,
            field: "entityId",
            headerName: trans("ListViewColumns.Entity_Id"),
            width: 95,
            sortable: false,
            minWidth: 95,

        },
        {
            id: COLUMN_IDS.DESCRIPTION,
            cellEditor: "agLargeTextCellEditor",
            cellEditorPopup: true,
            minWidth: 300,
            valueGetter: (param) => param.data.entityLabel?.description?.[languageCode] || "",
            valueSetter: (param) => (param.data.entityLabel.description[languageCode] = param.newValue),
            headerName: trans("ListViewColumns.Description"),
            sortable: false,
            editable: true,
            cellEditorParams: { maxLength: 1000 }

        },
        {
            id: COLUMN_IDS.PHONE_NUMBER,
            headerName: trans("ListViewColumns.Phone_Number"),
            valueGetter: (param) => (param.data.entityType === EntityType.STRUCTURE && param.data.subEntityType === StructureEntityType.BUILDING ? param.data?.entityLabel?.phoneNumbers?.[0] : param.data?.entityLabel?.phoneNumber) || "",
            valueSetter: (param) => 
            {
                if (param.data.entityType === EntityType.STRUCTURE && param.data.subEntityType === StructureEntityType.BUILDING)
                {
                    param.data.entityLabel.phoneNumbers = [param.newValue];
                }
                else 
                {
                    param.data.entityLabel.phoneNumber = param.newValue;  
                }
            },
            sortable: false,
            editable: true,
            minWidth: 200,

        },
        {
            id: COLUMN_IDS.WEBSITE,
            headerName: trans("ListViewColumns.Website"),
            valueGetter: (param) => param.data.entityLabel?.website?.[languageCode] || "",
            cellEditor: Input,
            cellEditorParams: {
                validators: [checkIsValidURL],
                valueModifier: (data, value) => (data.entityLabel.website[languageCode] = value),
            },
            suppressKeyboardEvent: suppressKeyboardEvent,
            sortable: false,
            editable: true,
            minWidth: 200,
        },
        {
            id: COLUMN_IDS.CATEGORY,
            valueGetter: (param) => param.data.entityLabel?.category?.name?.[DEFAULT_LANGUAGE_CODE] || "",
            valueSetter: (param) => 
            {  
                if (param.newValue === "None") 
                {
                    param.data.entityLabel.category= null;
                    return;
                
                }
                const detailedValue = categoryList.find(
                    (category) => category?.name?.[DEFAULT_LANGUAGE_CODE] === param.newValue
                );
                param.data.entityLabel.category = {
                    name: {
                        ...(param.data?.entityLabel?.category?.name || {}),
                        [DEFAULT_LANGUAGE_CODE]: detailedValue.name[DEFAULT_LANGUAGE_CODE],
                    },
                    _id: detailedValue._id 
                }; 
                param.data.entityLabel.subCategories = param?.data?.entityLabel?.subCategories?.filter((subCat) => subCat._id !== detailedValue._id) || [];
            },
            cellEditor: "agSelectCellEditor",
            cellEditorParams: {
                values: ["None",...categoryList.map((category) => category?.name?.[DEFAULT_LANGUAGE_CODE])],
            },
            headerName: trans("ListViewColumns.Category"),
            sortable: false,
            minWidth: 200,
            editable: (param) => !(param.data.entityType === EntityType.STRUCTURE && param.data.subEntityType === StructureEntityType.BUILDING),
        },
        {
            id: COLUMN_IDS.SUBCATEGORY,
            valueGetter: (param) => param.data.entityLabel?.subCategories?.length ? param.data.entityLabel?.subCategories
                    ?.map((subCat) =>  subCat?.name?.[DEFAULT_LANGUAGE_CODE]) : ""
            ,
            externalEditing: {
                updateFn: (data, newValue, options) => 
                {
                    const detailedValues = newValue.map((val) => options.find((option) => option.value === val)
                    );

                    data.entityLabel.subCategories = detailedValues.reduce((prev, detVal) => 
                    {
                        const existingSubCat = data.entityLabel.subCategories.find(
                            (subCat) => detVal.key === subCat._id
                        );
                        if (existingSubCat) 
                        {
                            return [...prev, ({
                                ...existingSubCat,
                                [DEFAULT_LANGUAGE_CODE]: detVal.value,
                            })];
                        }
                        else 
                        {
                            return [...prev, {
                                _id: detVal.key,
                                name: { [DEFAULT_LANGUAGE_CODE]: detVal.value },
                            }
                            ];
                        }
                    }, []);

                },
                dropdownComponentProps: {
                    allowAdditions: false,
                    getOptions: (param) =>  getSubCategoryList({ data: param.data, categories }),
                },
            },
            headerName: trans("ListViewColumns.Sub_Category"),
            sortable: false,
            minWidth: 300,
            editable: (param) => !(param.data.entityType === EntityType.STRUCTURE && param.data.subEntityType === StructureEntityType.BUILDING),
        },
        {
            id: COLUMN_IDS.ENTITY_TYPE,
            headerName: trans("ListViewColumns.Type"),
            valueGetter: (param) => getEntityType(param.data),
            sortable: false,
            minWidth: 200,

        },
        {
            id: COLUMN_IDS.KEYWORDS,
            headerName: trans("ListViewColumns.Keywords"),
            valueGetter: (param) => param.data.entityLabel?.keywords?.[languageCode]?.length ? param.data.entityLabel?.keywords?.[languageCode] : "",
            externalEditing: {
                updateFn: (data, newValue) => 
                {
                    if (!data?.entityLabel?.keywords) 
                    {
                        data.entityLabel.keywords = {
                            [languageCode]: newValue
                        };
                    }
                    else 
                    {
                        data.entityLabel.keywords[languageCode] = newValue;
                    }
                },
                validators: [
                    (value) => value?.length >= 20? "Keywords has a limit of 20": "",
                ],
            },
            sortable: false,
            editable: true,
            minWidth: 200,

        },
        {
            id: COLUMN_IDS.ONLINE_KEYWORDS,
            headerName: trans("ListViewColumns.Online_Keywords"),
            externalEditing: {
                updateFn: (data, newValue) => 
                {
                    if (!data?.entityLabel?.onlineKeywords)
                    {
                        data.entityLabel.onlineKeywords = {
                            [languageCode]: newValue
                        };
                    }
                    else 
                    {
                        data.entityLabel.onlineKeywords[languageCode] = newValue;
                    }
                },
            },
            valueGetter: (param) => param.data.entityLabel?.onlineKeywords?.[languageCode]?.length ? param.data.entityLabel?.onlineKeywords?.[languageCode]: "",
            sortable: false,
            editable: true,
            minWidth: 300,

        },
        {
            id: COLUMN_IDS.FACEBOOK,
            valueGetter: (param) => param.data.entityLabel?.socialMedia?.facebook || "",
            valueSetter: (param) => 
            {
                if (!param.data.entityLabel?.socialMedia) 
                {
                    param.data.entityLabel.socialMedia = {};
                }
                param.data.entityLabel.socialMedia.facebook = param.newValue;
            },
            headerName: trans("ListViewColumns.Facebook"),
            sortable: false,
            minWidth: 200,
            editable: true,
        },
        {
            id: COLUMN_IDS.INSTAGRAM,
            valueGetter: (param) => param.data.entityLabel?.socialMedia?.instagram || "",
            valueSetter: (param) => 
            {
                if (!param.data.entityLabel?.socialMedia) 
                {
                    param.data.entityLabel.socialMedia = {};
                }
                param.data.entityLabel.socialMedia.instagram = param.newValue;
            },
            headerName: trans("ListViewColumns.Instagram"),
            sortable: false,
            editable: true,
            minWidth: 200,


        },
        {
            id: COLUMN_IDS.TWITTER,
            valueGetter: (param) => param.data.entityLabel?.socialMedia?.twitter || "",
            valueSetter: (param) => 
            {
                if (!param.data.entityLabel?.socialMedia)
                {
                    param.data.entityLabel.socialMedia = {};
                } 
                param.data.entityLabel.socialMedia.twitter = param.newValue;
            },
            headerName: trans("ListViewColumns.Twitter"),
            sortable: false,
            editable: true,
            minWidth: 200,

        },
        {
            id: COLUMN_IDS.ICON_ROTATION,
            headerName: trans("ListViewColumns.Icon_Rotation"),
            valueGetter: (param) => param.data?.imageRotation?.[DEFAULT_LANGUAGE_CODE] || 0,
            suppressKeyboardEvent,
            cellEditor: Input,
            cellEditorParams: {
                validators: [checkRotation],
                inputProps: {
                    type: "number",
                    min: 0,
                    max: 360,
                },
                valueModifier: (data, value) => 
                {
                    let newValue;

                    if (value === undefined) 
                    {
                        newValue = 0;
                    }
                    else 
                    {
                        newValue = value;
                    }
                    data.imageRotation = {
                        ...(data.imageRotation || {}),
                        [DEFAULT_LANGUAGE_CODE]: newValue,
                    };
                },
            },
            sortable: false,
            minWidth: 200,

            editable: true,
        },
        {
            id: COLUMN_IDS.TEXT_ROTATION,
            headerName: trans("ListViewColumns.Text_Rotation"),
            valueGetter: (param) => param.data?.textRotation?.[DEFAULT_LANGUAGE_CODE] || 0,
            suppressKeyboardEvent,
            cellEditor: Input,
            cellEditorParams: {
                validators: [checkRotation],
                inputProps: {
                    type: "number",
                    min: 0,
                    max: 360,
                },
                valueModifier: (data, value) => 
                {
                    let newValue;
                    if (value === undefined) 
                    {
                        newValue = 0;
                    }
                    else 
                    {
                        newValue = value;
                    }

                    data.textRotation = {
                        ...(data.textRotation || {}),
                        [DEFAULT_LANGUAGE_CODE]: newValue,
                    };
                },
            },
            sortable: false,
            editable: true,
            minWidth: 200,

        },
        {
            id: COLUMN_IDS.SHOW_LOGO,
            headerName: trans("ListViewColumns.Show_Logo"),
            valueGetter: (param) => param.data?.style?.[DEFAULT_LANGUAGE_CODE]?.useIcon || false,
            valueSetter: (param) => (param.data.style[DEFAULT_LANGUAGE_CODE].useIcon = param.newValue),
            cellEditor: "agSelectCellEditor",
            cellEditorParams: {
                values: [true, false],
            },
            sortable: false,
            editable: true,
            minWidth: 200,

        },
        {
            id: COLUMN_IDS.SHOW_TEXT,
            headerName: trans("ListViewColumns.Show_Text"),
            valueGetter: (param) => param.data?.style[DEFAULT_LANGUAGE_CODE]?.useText || false,
            valueSetter: (param) => (param.data.style[DEFAULT_LANGUAGE_CODE].useText = param.newValue),
            cellEditor: "agSelectCellEditor",
            cellEditorParams: {
                values: [true, false],
            },
            sortable: false,
            editable: true,
            minWidth: 200,

        },
    ];

    return columnDefs;
};

export const getUpdatePayload = (entityData) =>
{
    let entityLabelDocUpdate = {};
    let entityDocUpdate = {};
    let buildingDocUpdate = {};

    let labelUpdate = {};

    if (entityData.entityLabel)
    {
        const { entityLabel } = entityData;

        ENITITY_LABEL_UPDATE_FIELDS.forEach((fieldName) =>
        {
            // any custom logic for fields will be resolved here
            if (fieldName === "category")
            {
                const category = entityLabel[fieldName];
                if (category)
                {
                    labelUpdate[fieldName] = category._id;
                }
                else 
                {
                    labelUpdate[fieldName] = category;
                } 
            }
            else if (fieldName === "subCategories")
            {
                const subCategories = entityLabel[fieldName];
                labelUpdate[fieldName] =  subCategories?.map((subCategory) => subCategory._id);
            }
            else if (Object.hasOwn(entityLabel, fieldName))
            {
                labelUpdate[fieldName] = entityLabel[fieldName];
            }
        });

        labelUpdate._id = entityLabel._id;
    }

    ENTITY_UPDATE_FIELDS.forEach((fieldName) =>
    {
        entityDocUpdate[fieldName] = entityData[fieldName];
    });

    entityDocUpdate._id = entityData._id;

    // if the entity represents building set the label updates in 'buildingDocUpdate' 
    // else set if it represents other non-building enitities (including POIs), set it in 'entityLabelDocUpdate'
    if (entityData.entityType === EntityType.STRUCTURE && entityData.subEntityType === StructureEntityType.BUILDING)
    {
        delete labelUpdate.subCategories;
        delete labelUpdate.category;
        delete labelUpdate.phoneNumber;
        buildingDocUpdate = labelUpdate;
    }
    else
    {
        entityLabelDocUpdate = labelUpdate;
    }

    return {
        entityDocUpdate,
        entityLabelDocUpdate,
        buildingDocUpdate
    };
};

export const getColumnIdsFromStorage = () => 
{
    let columnIds = DEFAULT_COLUMN_IDS;
    
    if (sessionStorage.getItem(LIST_VIEW_COLUMNS_STORAGE_KEY))
    {
        columnIds = JSON.parse(sessionStorage.getItem(LIST_VIEW_COLUMNS_STORAGE_KEY));
    }

    return columnIds;
};

export const setColumnIdsToStorage = (columnsIds) => sessionStorage.setItem(LIST_VIEW_COLUMNS_STORAGE_KEY, JSON.stringify(columnsIds));