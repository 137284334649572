import React from "react";
import PropTypes from "prop-types";
import { InputFormGroup } from "../elements/InputFormGroup";
import { useTranslation } from "react-i18next";
import { DEFAULT_LANGUAGE_CODE, SINGLE_LANG_INPUT_CODE } from "../../_constants/constants";


export const PropertyEditBox = React.memo(({ property, validationErrors, propertyTypes, onChange }) =>
{
    /** @type {typeof import("../../_intl/resources.json")["en"]["translation"]["property edit box"]} */
    const trans = useTranslation().t("property edit box");
    const transMain = useTranslation().t;
    return (
        <>
            <InputFormGroup label={trans["name label"]} placeholder={trans["name placeholder"]}
                value={property.name?.[SINGLE_LANG_INPUT_CODE]} onChange={onChange} name={`name.${SINGLE_LANG_INPUT_CODE}`} error={transMain(validationErrors[`name.${DEFAULT_LANGUAGE_CODE}`])}
                extraLabel={trans["name extra label"]} />

            <InputFormGroup label={trans["address label"]} placeholder={trans["address placeholder"]}
                value={property.fullAddress} onChange={onChange} name="fullAddress" error={transMain(validationErrors["fullAddress"])}
                extraLabel={trans["address extra label"]} />

            <InputFormGroup label={trans["property type label"]} list={propertyTypes} scrolling search
                onChange={onChange} name="type" listValue={property.type} error={transMain(validationErrors["type"])}
                extraLabel={trans["property type extra label"]} />

            <InputFormGroup label={trans["website label"]} placeholder={trans["website placeholder"]} error={transMain(validationErrors[`website.${DEFAULT_LANGUAGE_CODE}`])}
                value={property.website?.[SINGLE_LANG_INPUT_CODE]} onChange={onChange} name={`website.${SINGLE_LANG_INPUT_CODE}`}
                extraLabel={trans["website extra label"]} />

            <InputFormGroup label={trans["phone label"]} placeholder={trans["phone placeholder"]} error={transMain(validationErrors["phoneCheck"])}
                value={property.phoneNumbers} onChange={onChange} name="phoneNumbers"
                extraLabel={trans["phone extra label"]} />
        </>
    );
});

PropertyEditBox.displayName = "PropertyEditBox";

PropertyEditBox.propTypes = {
    property: PropTypes.object.isRequired,
    propertyTypes: PropTypes.array.isRequired,
    validationErrors: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
};
