import React from "react";
import { ModalBox } from "../../../common/ModalBox";

const DeleteErrorModal = ({ deleteErrorModalOpen, onClose, trans, multipleDelete = false }) => (
    <ModalBox className="confirmActionModal"
        header={trans("Cancel_Confirmation_Modal.Confirm_Action")}
        open={deleteErrorModalOpen}
        onClose={onClose} >
        <p className="p-modal">
            {multipleDelete ? trans("MapOverlaysActivitySideBar.Delete_Multiple_Error_Message") : trans("MapOverlaysActivitySideBar.Delete_Error_Message")}
        </p>
    </ModalBox>);

export default DeleteErrorModal;