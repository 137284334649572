import { cartoLayer } from "mapsted.maps/mapFunctions/plotting";
import { styleClassic } from "mapsted.maps/utils/defualtStyles";
import { useEffect, useRef } from "react";
import { defaults as defaultControls, Attribution } from "ol/control";
import { Map, View } from "ol";
import { BRANDING_MAP_CONSTANTS } from "../_constants/constants";
import { useSetState } from "ahooks";
import EntityMapController from "mapsted.maps";
import { filerUrl } from "../_utils/utils";
import { CMSEntityAccess } from "mapsted.maps/mapFunctions/entityAccess";
import { MAP_THEMES } from "mapsted.maps/utils/map.constants";
import { isEmpty } from "lodash";
import { changeMapCenterWithBoundaryPolygon } from "mapsted.maps/mapFunctions/interactions";
import { deepValue } from "mapsted.utils/objects";
import cloneDeep from "lodash.clonedeep";

const useMap = ({ mapData, options={}, cloneMapData = true }) =>
{
    const { mapOptions = {}, onMapMoveEvent } = options;
    const { viewOptions = {}, controlsOptions = {}, tileLayer = styleClassic.tileLayer, rotation } = mapOptions;

    const [state, setState] = useSetState({
        olMap: undefined,
        entityLayers: {},
        imageLayers: {},
        boundaryPolygon: undefined,
        mapController: new EntityMapController({
            features: { text: true, images: true },
            filerUrl: filerUrl(""),
            accessor: CMSEntityAccess,
            theme: MAP_THEMES.CLASSIC
        })
    });

    const mapRef = useRef();
    const entityLayersRef = useRef({});

    useEffect(() =>
    {
        const map = new Map({
            target: mapRef.current,
            layers: [cartoLayer(tileLayer)],
            controls: defaultControls({
                attribution: false,
                zoom: false,
                ...controlsOptions
            }).extend([new Attribution({ collapsible: false })]),
            view: new View({
                center: [0, 0],
                zoom: BRANDING_MAP_CONSTANTS.DEFAULT_ZOOM,
                maxZoom: BRANDING_MAP_CONSTANTS.MAX_ZOOM,
                minZoom: BRANDING_MAP_CONSTANTS.MIN_ZOOM,
                ...viewOptions
            })
        });
        
        setState({
            olMap: map
        });

    }, []);

    useEffect(() =>
    {
        const handleMapMoveEvent = (event) =>
        {
            if (state.olMap)
            {
                const zoom = state.olMap.getView().getZoom();
        
                const textLayer = deepValue(entityLayersRef.current, `${BRANDING_MAP_CONSTANTS.TEXT_LAYER}`, undefined);
        
                let textLayerVisible = undefined;
                if (zoom < BRANDING_MAP_CONSTANTS.MIN_ZOOM_TEXT_LAYER && !!textLayer)
                {
                    textLayerVisible = false;
                }
                else if (textLayer)
                {
                    textLayerVisible = true;
                }
                if (textLayerVisible !== undefined && entityLayersRef.current)
                {
                    textLayer.setVisible(textLayerVisible);
                }
        
                if (onMapMoveEvent)
                {
                    onMapMoveEvent(event, state.olMap);
                }
            }
        };

        if (state.olMap)
        {
            state.olMap.on("moveend", handleMapMoveEvent);
        }
        
        return () => 
        {
            if (state.olMap)
            {
                state.olMap.un("moveend", handleMapMoveEvent);
            }
        };
    }, [onMapMoveEvent, state.olMap]);

    useEffect(() =>
    {
        if (mapData?.entities && state.mapController)
        {
            let mapDataToRender = mapData;

            if (cloneMapData)
            {
                mapDataToRender = cloneDeep(mapData);
            }

            const {
                entityLayers, boundaryPolygon, imageLayers
            } = state.mapController.initialize(mapDataToRender);

            setState({
                entityLayers,
                imageLayers,
                boundaryPolygon
            });
        }
        else
        {
            setState({
                entityLayers: {},
                imageLayers: {},
                boundaryPolygon: undefined
            });
        }
    }, [mapData, state.mapController]);

    useEffect(() =>
    {
        // update base map
        if (state.olMap && tileLayer && tileLayer.url)
        {
            const layers = state.olMap.getLayers()?.getArray();
            if (layers)
            {
                const baseMapLayer = layers.find((layer) => layer.get("isBaseMapLayer"));
                if (baseMapLayer && baseMapLayer.getSource())
                {
                    baseMapLayer.getSource().setUrl(tileLayer.url);
                }
            }
        }

    }, [state.olMap, tileLayer]);

    useEffect(() =>
    {
        if (state.olMap)
        {
            const { entityLayers, imageLayers } = state;
            // remove any previously added layers i.e. layers of previously selected property/building
            removeEntityLayers();
    
            addEntityLayers({ ...entityLayers, ...imageLayers });
        }
    }, [state.entityLayers, state.imageLayers, state.olMap]);

    useEffect(() =>
    {
        if (state.olMap && state.boundaryPolygon && (rotation || rotation === 0))
        {
            state.olMap.getView().setRotation(rotation);
            changeMapCenterWithBoundaryPolygon({ 
                olMap: state.olMap, 
                boundaryPolygon: state.boundaryPolygon, 
                padding: BRANDING_MAP_CONSTANTS.FIT_PADDING_MAP_DATA 
            });
        }
    }, [state.boundaryPolygon, state.olMap, rotation]);

    const addEntityLayers = (layers) =>
    {
        const { olMap } = state;

        if (isEmpty(layers)) return;

        entityLayersRef.current = {};

        // clone array to avoid any possible olMap issues
        const existingLayers = [...olMap.getLayers().getArray()];

        // add layers iff not already on map and layer not equal to -1
        Object.entries(layers).forEach(([layerId, layer]) =>
        {
            if (layerId !== BRANDING_MAP_CONSTANTS.HIDDEN_LAYER && layerId !== BRANDING_MAP_CONSTANTS.BOUNDARY_LAYER)
            {
                const alreadyExists = existingLayers.some((l) => l === layer);
                if (!alreadyExists)
                {
                    olMap.addLayer(layer);
                    // keep track of the newly added layers
                    entityLayersRef.current[layerId] = layer;
                }
            }
        });
    };

    const removeEntityLayers = () =>
    {
        const { olMap } = state;

        if (isEmpty(entityLayersRef.current)) return;

        Object.values(entityLayersRef.current).forEach((layer) =>
        {
            olMap.removeLayer(layer);
        });

        entityLayersRef.current = undefined;
    };

    return { 
        olMap: state.olMap, 
        mapRef, 
        mapController: state.mapController
    };
};

export default useMap;