import React, { useContext } from "react";
import BrandingContext from "../../../store/BrandingContext";
import { SubheaderLeft } from "../../layout/Subheader";
import { PublishPopup } from "../../branding/publish/PublishPopup";
import { useOverlaysTemplateContext } from "../../../store/OverlaysTemplateContext";

export const SubheaderTemplates = () =>
{
    const brandingCtx = useContext(BrandingContext);

    return (
        <div className="subheaderMaintenance subheader">
            <div className="subheader-left">
                <SubheaderLeft dynamicContext={brandingCtx} />
            </div>
            <div className="subheader-right">
                <SubheaderRight />
            </div>
        </div>
    );
};


const SubheaderRight = () =>
{
    const { 
        loadingPool,
    } = useOverlaysTemplateContext();

    return (
        <>
            <PublishPopup
                globalLoader={true}
                loadingPool={loadingPool}
            />
        </>
    );
};