import React, { useCallback, useMemo, useState, useContext, useEffect } from "react";
import { Checkbox, Input } from "semantic-ui-react";
import MapEditorContext from "../../../store/MapEditiorContext";
import { EDIT_TYPES, ENTITY_SEARCH_OPTIONS, FILTERED_ID_TYPES, NODE_SEARCH_OPTIONS } from "../../../_constants/mapEditor";

/**
 * Component handles searching through features (entities and nodes) by pulling data from provider
 * updates search results in provider to be used by smart filter component
 */
export const FeatureSearch = () =>
{
    // returns toggle option between entity/node/transition and an input field for search

    const editorContext = useContext(MapEditorContext);
    const [filter, setFilter] = useState("");
    const [searchType, setSearchType] = useState(FILTERED_ID_TYPES.ENTITY);

    const optionsMemo = useMemo(() =>
    {
        let options = [];
        switch (searchType)
        {
            case FILTERED_ID_TYPES.ENTITY: {
                options = editorContext.entityIdsArrayMemo;
                break;
            }
            case FILTERED_ID_TYPES.NODE: {
                options = editorContext.nodeIdsArrayMemo;
                break;
            }
            default: {
                options = [];
            }
        }

        return options;
    }, [searchType, editorContext.entityIdsArrayMemo, editorContext.nodeIdsArrayMemo]);

    /**
     * @param {string} e
     * @param {Object} data - value
     * callback for input change, set filter state
     */
    const handleFilterChange = useCallback((e, { value }) =>
    {
        setFilter(value);
    }, []);

    /**
     * @param {string} e
     * @param {Object} data - value
     * callback for radio change, set filter type state
     */
    const handleSearchTypeChange = useCallback((e, data) =>
    {
        setSearchType(data.label);
    }, []);


    /**
     * Returns filtered results based on search type and filter
     * @returns {Array}
     */
    const filteredResults = useMemo(() =>
    {
        if (filter.length === 0)
        {
            return [];
        }

        return optionsMemo.filter((option) =>
        {
            return (`${option.navId}`.toLowerCase().includes(filter.toLowerCase()))
            // the bellow line adds a condition to search using cms ids as well
            //||option._id.toLowerCase().includes(filter.toLowerCase()));
        });
    }, [filter, optionsMemo]);

    /**
     * on filtered results change, update editor provider featureSearchResults
     */
    useEffect(() =>
    {
        editorContext.handleSetFeatureSearchResults(filteredResults);
    }, [filteredResults]);


    /**
     * renders radio options depending on edit type
     * entity type only has entity search
     * node type has entity and node search
     */
    const renderRadioButtons = useCallback(() =>
    {
        const editType = editorContext.state.editType;

        let searchOptions = [];
        if (editType === EDIT_TYPES.ENTITIES)
        {
            searchOptions = ENTITY_SEARCH_OPTIONS;
        }
        else if (editType === EDIT_TYPES.NODES)
        {
            searchOptions = NODE_SEARCH_OPTIONS;
        }

        return searchOptions.map((option) =>
        {
            return (
                <Checkbox
                    className="search-radio-checkbox"
                    key={option}
                    radio
                    checked={searchType === option}
                    label={option}
                    onChange={handleSearchTypeChange}
                />
            );
        });
    }, [editorContext.state.editType, searchType, handleSearchTypeChange]);

    return (
        <div>
            {renderRadioButtons()}
            {/* <Checkbox radio checked={searchType === SEARCH_TYPES.TRANSITION} label={SEARCH_TYPES.TRANSITION} onChange={handleSearchTypeChange} /> */}

            {/*a search bar that filters through results */}
            <Input
                className="featureSearchBar"
                type="text"
                value={filter}
                onChange={handleFilterChange}
                placeholder="Search..."
            />

            {/* List of results */}
            {/* <ul>
                {filteredResults.map((result, index) => (
                    <div key={index} onClick={() => handleOptionClick(null, result)}>{searchType} ID: {result.navId}
                       
                    </div>
                ))}
            </ul> */}

        </div>
    );
};