import React, { useContext, useEffect, useRef, useState } from "react";
import { Tab, Button, Icon } from "semantic-ui-react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Prompt } from "react-router-dom";
import "./Settings.css";
import { SubheaderBranding } from "../../layout/Subheader";
import MapstedMapsModule from "../../settings/mapstedMapsModule";
import AppContext from "../../../store/AppContext";
import { usePropertySettings } from "../../settings/settings.queries";
import { SettingsContext, SettingsProvider } from "../../settings/SettingsProvider";
import { Loader } from "../../elements/loader";
import ZoomScreen from "./ZoomScreen";
import { useBeforeUnload } from "../../../_utils/hooks";
import { useTranslation } from "react-i18next";
import { MapstedKioskModule } from "../../settings/mapstedKioskModule";
import BrandingContext from "../../../store/BrandingContext";
import { get as getDeepValue } from "lodash";
import { BRANDING_MAP_CONSTANTS } from "../../../_constants/constants";

const Settings = () =>
{
    const trans = useTranslation().t;
    const appContext = useContext(AppContext);
    const { dirty } = useContext(SettingsContext);
    useBeforeUnload(dirty);

    const [activeIndex, setActiveIndex] = useState(1);
    const [zoomMode, setZoomMode] = useState(false);
    const [activeMapsMenu, setActiveMapsMenu] = useState();
    const [isBuilding, setIsBuilding] = useState();
    const [zoomData, setZoomData] = useState();
    const [rotationData, setRotationData] = useState();
    const [showModeSwitcher, setShowModeSwitcher] = useState(true);
    const [showMaxZoomLevelAdjuster, setShowMaxZoomLevelAdjuster] = useState(false);


    const [, { isLoading }] = usePropertySettings();

    const { mutate, mergedSettings: settings } = useContext(SettingsContext);
    const { state: { buildingId, propertyId, properties } } = useContext(BrandingContext);
    const building = getDeepValue(properties, `${propertyId}.buildings.${buildingId}`, {});

    const [scrollValue, setScrollValue] = useState(0);
    const scrollbarsRef = useRef(null);  
  
    useEffect(() => 
    {
        if (scrollbarsRef.current && activeMapsMenu) 
        {
            scrollbarsRef.current.scrollTop(scrollValue);
        }
    }, [activeMapsMenu, zoomMode]);

    if (isLoading)
    {
        return <Loader active />;
    }
    const panes = [
        {
            menuItem: {
                content: trans("Settings.Property_Settings"),
                disabled: true,
                id: "settingsMenuTitle",
                key: "title",
            },
            key: "title",
        },
        {
            menuItem: trans("Settings.Mapsted_Map_Module"),
            render: () => (
                <MapstedMapsModule
                    onChangeZoomMode={setZoomMode}
                    activeMenu={activeMapsMenu}
                    onChangeActiveMenu={setActiveMapsMenu}
                    setIsBuilding={ setIsBuilding }
                    setZoomData = { setZoomData }
                    setRotationData ={ setRotationData }
                    setShowModeSwitcher={setShowModeSwitcher}
                    setShowMaxZoomLevelAdjuster={setShowMaxZoomLevelAdjuster}
                />
            ),
            key: "mapModule"
        },
        {
            menuItem: trans("Settings.Mapsted_Kiosk_Module"),
            render: () => <MapstedKioskModule />,
            key: "kiosk"
        },
        // commented out these panes as per requirement, refer MW-1639 JIRA ticket.
        // {
        //     menuItem: trans("Settings.Additional_Customizations"),
        //     render: () => <AdditionalCustomizations />,
        //     key: "additional"
        // },
        // {
        //     menuItem: trans("Settings.External_Brand_Sharing"),
        //     render: () => <ExternalBrandSharing />,
        //     key: "external"

        // },
        {
            menuItem: {
                content: <>
                    {trans("Settings.Account_Settings")} <Icon name="external" />
                </>,
                link: true,
                key: "hub"
            }
        },
    ];
    const accountSettingIndex = panes.length - 1;

    const handleTabChange = (e, { activeIndex }) =>
    {
        // open hub if that tab is selected
        if (parseInt(activeIndex) === accountSettingIndex)
        {
            appContext.openHubSettings();
        }
        else        // just switch tabs
        {
            setActiveIndex(parseInt(activeIndex));
        }
    };

    const handlesave =(updateBuildingSettings) =>
    {
        let zoomSettings = {};
        if (showMaxZoomLevelAdjuster)
        {
            zoomSettings.maxZoomWeb = Math.round(updateBuildingSettings.zoomWeb * 100) / 100;
        }
        else if (isBuilding) 
        {
            const buildingId = building._id;
            const buildingSettings = settings?.buildingSettings?.[buildingId];
      
            if (buildingSettings) 
            {
                const updateBuilding = {
                    ...buildingSettings.mapSettings,
                    ...updateBuildingSettings,
                };
      
                settings.buildingSettings[buildingId].mapSettings = { ...updateBuilding };
                zoomSettings.buildingSettings = settings.buildingSettings;
            }
        } 
        else
        {
            zoomSettings = { ...updateBuildingSettings };
        }

        mutate(zoomSettings);
        setZoomMode(undefined);
        setShowMaxZoomLevelAdjuster(false);
        setShowModeSwitcher(true);
        setIsBuilding();     

    };

    if (zoomMode || showMaxZoomLevelAdjuster)
    {
        return (
            <ZoomScreen
                zoomMode={zoomMode}
                onChangeZoomMode={setZoomMode}
                onClose={() => 
                {
                    setZoomMode(undefined);
                    setShowMaxZoomLevelAdjuster(false); 
                    setShowModeSwitcher(true);
                }}
                zoomData={zoomData}
                showModeSwitcher={showModeSwitcher}
                rotationData ={rotationData}
                handleSaveZoom={handlesave}
                settings={settings}
                isBuilding={isBuilding}
            />
        ); 
    }

    return (
        <>
            <Prompt when={dirty} message="Some changes you've made have not been saved. Are you sure you want to leave?" />
            <SubheaderBranding
                showTools={false}
                showCategories={false}
                showShareButton={false}
                showGallery={false}
                action={<SaveButton />}
            />
            <Scrollbars
                autoHeight
                autoHeightMin="calc(1vh)"
                autoHeightMax="calc(100vh - 109px)"
                ref={scrollbarsRef}
                onUpdate={(values) => setScrollValue(values.scrollTop)}
            >
                <div className="settingsPageContainer">
                    <Tab
                        activeIndex={activeIndex}
                        onTabChange={handleTabChange}
                        menu={{ secondary: true, pointing: true }}
                        panes={panes}
                        className="settingsPageTab"
                    />
                </div>
            </Scrollbars>
        </>
    );
};

const SaveButton = () =>
{
    const trans = useTranslation().t;
    const { dirty, handleSaveChanges, saveMutation } = useContext(SettingsContext);

    return (
        <Button onClick={handleSaveChanges} loading={saveMutation.isLoading} disabled={!dirty} color={dirty ? "orange" : undefined} >{trans("common.Save")}</Button>
    );
};

// eslint-disable-next-line react/display-name
const withProvider = (Comp) => () => (
    <SettingsProvider>
        <Comp />
    </SettingsProvider>
);
export default withProvider(Settings);
