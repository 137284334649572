import React, { FC } from "react";
import { Button } from "semantic-ui-react";

let Cropper;
try
{
    Cropper = require("react-cropper").default;
    require("cropperjs/dist/cropper.css");
}
catch
{
    console.log("optional dependency 'react-cropper' not installed");
}

import EditButton from "./EditButton";
import ModalLayout from "./ModalLayout";

import style from "./CropImageModal.module.scss";
import cropIcon from "./media/icon-crop.svg";
import undoIcon from "./media/icon-undo.svg";
import trashIcon from "./media/icon-trash-blue.svg";

interface CropImageModalProps {
    open: boolean;
    fileName: string;
    trigger: React.ReactNode;
    image: string | undefined;
    aspectRatio: number;
    canUndo: boolean;
    onUndo: () => void;
    onCropImage: Function;
    onDelete: Function;
    onSave: Function;
    onClose: Function;
    onGoBack: () => void;
    text: CropImageModalText;
}

export interface CropImageModalText extends DeleteCropImageModalText {
    title: string;
    cropButton: string;
    undo: string;
    save: string;
}

const CropImageModal : FC<CropImageModalProps> = ({
    open,
    fileName,
    image,
    trigger,
    aspectRatio,
    canUndo,
    onUndo,
    onCropImage,
    onDelete,
    onSave,
    onClose,
    onGoBack,
    text
 }) =>
{
    if (!Cropper)
    {
        throw Error("react-cropper must be installed to use the UploadImageModal")
    }
    const cropperRef = React.useRef<HTMLImageElement>(null);

    const handleOnSave = React.useCallback(() =>
    {
        if (cropperRef.current)
        {
            const { cropper } : any = cropperRef.current;
            onSave(cropper.getCroppedCanvas().toDataURL());
        }
        else
        {
            onSave(undefined);
        }
        onClose();

    }, [onSave, onClose]);

    const handleOnCropClick = React.useCallback(() =>
    {
        if (cropperRef.current)
        {
            const { cropper } : any = cropperRef.current;
            onCropImage(cropper.getCroppedCanvas().toDataURL());
        }
    }, [onCropImage]);

    return (
        <ModalLayout
            modalProps={{
                trigger,
                open,
                onClose,
                dimmer: "blurring",
                className: "crop-image-modal"
            }}
            heading={text.title}
            onGoBack={onGoBack}
            actions={<>
                <EditButton content={text.cropButton} imageSrc={cropIcon} onClick={handleOnCropClick} />
                {(canUndo) && <div className={style.divider} />}
                {(canUndo) && <EditButton content={text.undo} imageSrc={undoIcon} onClick={onUndo} />}
                <Button floated="right" content={text.save} onClick={handleOnSave} />
            </>}
        >
            <div className="aligner">
                <div className="label-crop">
                    <span>{fileName}</span>
                    <DeleteCropImageModal onDelete={onDelete} onUploadNewImage={onGoBack} text={text} />
                </div>
            </div>

            <div className="crop-bg">
                {(image) && (
                    <Cropper
                        ref={cropperRef}
                        src={image}
                        style={{ height: "360px", width: "810px" }}
                        aspectRatio={aspectRatio}
                        initialAspectRatio={aspectRatio}
                        autoCropArea={1}
                        guides={false}
                    />
                )}
            </div>
        </ModalLayout>
    );
};

export default CropImageModal;


interface DeleteCropImageModalProps {
    onDelete: Function;
    onUploadNewImage: Function;
    text: DeleteCropImageModalText;
};

interface DeleteCropImageModalText {
    deleteButton: string;
    deleteHeader: string;
    deleteConfirmation: string;
    newImage: string;
}


const DeleteCropImageModal : FC<DeleteCropImageModalProps> = ({
    onDelete,
    onUploadNewImage,
    text
}) =>
{
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    const handleonUploadNew = React.useCallback(() =>
    {
        setOpen(false);
        onUploadNewImage();
    }, [onUploadNewImage]);

    const handleOnDelete = React.useCallback(() =>
    {
        setOpen(false);
        onDelete();
    }, [onDelete]);

    return (
        <ModalLayout
            modalProps={{
                open,
                dimmer: "blurring",
                onClose: handleClose,
                trigger: <EditButton content={text.deleteButton} imageSrc={trashIcon} onClick={handleOpen} />,
                className: "actionModal deleteImg"
            }}
            heading={text.deleteHeader}
            actions={<>
                <Button secondary basic content={text.newImage} onClick={handleonUploadNew} />
                <Button content={text.deleteButton} onClick={handleOnDelete} />
            </>}
        >
            <p className="pModal">{text.deleteConfirmation}</p>
        </ModalLayout>
    );
};
