import ObjectID from "bson-objectid";
import cloneDeep from "lodash.clonedeep";
import { EntityRefTypes, EntityType, PointOfInterestType, ShapeTypes } from "mapsted.maps/utils/entityTypes";
import { cutPolygon, mergePolygons } from "mapsted.maps/utils/geometry.utils";
import { default as React, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "sonner";
import { EntityOverlapAccess, } from "../../../_access/entityOverlapAccess";
import { EntitySelfFilterAccess, SELF_FILTER_TYPES } from "../../../_access/entitySelfFilterAccess";
import { EDITOR_ALERTS, ENTITY_EDITOR_FILTERS, ENTITY_SELF_FILTERS, FILTERED_ID_TYPES, MAP_EDITOR_SHORTCUTS, MAP_EDITOR_TOOLS } from "../../../_constants/mapEditor";
import { CollectionNameMap } from "../../../_indexedDB/collections/v1/collection";
import mapEditorLocalDb from "../../../_indexedDB/mapEditorLocal.db";
import { useKeyboardShortcut, useSingleKeyboardShortcut } from "../../../_utils/keyboardShortcuts";
import { createTemplateFromEntity, getEntitiesFromMapData } from "../../../_utils/mapEditorUtils";
import { mercatorToWgs84 } from "../../../_utils/turf.utlils";
import { deepCopy } from "../../../_utils/utils";
import { EntityStep } from "../../../models/entityStep";
import MapEditorContext from "../../../store/MapEditiorContext";
import { EntityEditorMap } from "../map/EntityEditorMap";
import useFloorPlanUndoRedoConnector from "../FloorPlanGeoReferencing/hooks/useFloorPlanUndoRedoConnector";

export const EntityEditor = ({ propertyId, buildingId, floorId, selectedFilterItem, onUpdateSmartFilter, onSetSelectedFilterItem, onUndo, onRedo, onLocalDBUpdated }) =>
{
    const mapEditorContext = useContext(MapEditorContext);
    const entitiesRef = useRef();
    const selectedFilterRef = useRef();
    const activeToolRef = useRef(mapEditorContext.state.selectedTool);
    const floorPropertyBuildingIdRef = useRef({ propertyId, buildingId, floorId });


    useEffect(() =>
    {
        activeToolRef.current = mapEditorContext.state.selectedTool;
    }, [mapEditorContext.state.selectedTool]);
    useEffect(() =>
    {
        floorPropertyBuildingIdRef.current = { propertyId, buildingId, floorId };
    }, [propertyId, buildingId, floorId]);

    const [isUndoLocked, setIsUndoLocked] = useState(false);


    const [overlapAccess, setOverlapAccess] = useState(undefined);
    const [entitySelfFilterAccess, setEntitySelfFilterAccess] = useState(undefined);


    const selectedFilter = useMemo(() =>
    {
        selectedFilterRef.current = mapEditorContext?.state?.selectedFilter;
        return mapEditorContext?.state?.selectedFilter;
    }, [mapEditorContext?.state?.selectedFilter]);

    const {
        undoStepHandler: floorPlanUndoHandler,
        redoStepHandler: floorPlanRedoHandler
    } = useFloorPlanUndoRedoConnector();


    useEffect(() =>
    {
        mapEditorContext.handleSetLocalObjects(entitiesRef.current);
    }, [entitiesRef.current, mapEditorContext.state.propertyId, mapEditorContext.state.buildingId, mapEditorContext.state.floorId]);

    // create plottable entity data
    useEffect(() =>
    {
        const mapData = mapEditorContext.state.mapData;

        const entities = getEntitiesFromMapData(mapData);

        entitiesRef.current = entities;

    }, [mapEditorContext.state.mapData]);

    // use effect to watch for outside undo redo
    useEffect(() =>
    {
        let undoRedoButtonClick = mapEditorContext?.state?.undoRedoButtonClick;

        if (undoRedoButtonClick === undefined)
        {
            return;
        }
        else
        {
            handleUndoRedoClick(undoRedoButtonClick);
        }

        mapEditorContext.handleSetUndoRedoButtonClick(undefined);

    }, [mapEditorContext?.state?.undoRedoButtonClick]);

    useEffect(() =>
    {
        if (mapEditorContext?.state?.featureSearchResults)
        {
            const deepCopiedSearchResults = deepCopy(mapEditorContext.state.featureSearchResults);
            updateSelfFilterAccessOptions({ featureSearchResults: deepCopiedSearchResults });
        }
    }, [mapEditorContext?.state?.featureSearchResults]);

    // initialize access when is selected
    // delete when not selected
    useEffect(() =>
    {
        if (selectedFilter === ENTITY_EDITOR_FILTERS.PARTIALLY_OVERLAPPING_ENTITIES)
        {
            let newOverlapAccess = new EntityOverlapAccess(entitiesRef.current);

            setOverlapAccess(newOverlapAccess);
        }
        else
        {
            setOverlapAccess(undefined);
        }

        // check if filter is a self filter
        if (ENTITY_SELF_FILTERS.includes(selectedFilter))
        {
            let filterType;
            let options = undefined;

            if (selectedFilter === ENTITY_EDITOR_FILTERS.POLYGONS_WITH_SHARP_ANGLES)
            {
                filterType = SELF_FILTER_TYPES.ANGLE;
            }
            else if (selectedFilter === ENTITY_EDITOR_FILTERS.SELF_INTERSECTING_POLYGONS)
            {
                filterType = SELF_FILTER_TYPES.SELF_INTERSECTION;
            }
            else if (selectedFilter === ENTITY_EDITOR_FILTERS.AREA_FILTER)
            {
                filterType = SELF_FILTER_TYPES.AREA;
            }
            else if (selectedFilter === ENTITY_EDITOR_FILTERS.SIDE_LENGTH_FILTER)
            {
                filterType = SELF_FILTER_TYPES.SIDE_LENGTH;
            }
            else if (selectedFilter === ENTITY_EDITOR_FILTERS.SEARCH)
            {
                filterType = SELF_FILTER_TYPES.SEARCH;
                const deepCopiedSearchResults = deepCopy(mapEditorContext.state.featureSearchResults);
                options = { featureSearchResults: deepCopiedSearchResults };
            }

            let selfFilterAccess = new EntitySelfFilterAccess(entitiesRef.current, filterType, options);

            setEntitySelfFilterAccess(selfFilterAccess);
        }
        else
        {
            setEntitySelfFilterAccess(undefined);
        }

    }, [selectedFilter]);


    const updateIndexedDB = useCallback(async (entityStepData) =>
    {
        setIsUndoLocked(true);

        const selectedTool = activeToolRef.current;
        const {
            propertyId,
            buildingId,
            floorId, 
        } = floorPropertyBuildingIdRef.current;

        await mapEditorLocalDb.handleUpdateLocalEntities(
            {
                propertyId,
                buildingId,
                floorId,
                selectedTool,
                entityStepData,
            });

        onLocalDBUpdated();

        setIsUndoLocked(false);

    }, [mapEditorContext.state, propertyId, buildingId, floorId, setIsUndoLocked]);

    const convertFeatureToEntity = useCallback((feature) =>
    {
        let ref = floorId || propertyId;
        let refType = floorId
            ? EntityRefTypes.FLOOR
            : EntityRefTypes.PROPERTY;

        let geometry = feature.getGeometry();

        let type = geometry.getType();
        let coordinates = geometry.getCoordinates();

        //fix coordinates for entities since the custom geometry function does not add last point
        if (type === ShapeTypes.POLYGON)
        {
            let coords = coordinates[0];
            let firstCoord = coords[0];
            let lastCord = coords[coords.length - 1];

            if (firstCoord[0] !== lastCord[0] && firstCoord[1] !== lastCord[1])
            {
                coordinates[0].push(firstCoord);
            }
        }

        coordinates = mercatorToWgs84(coordinates, type);

        const shape = {
            type,
            coordinates
        };

        let entity = {
            _id: ObjectID().toString(),
            entityType: 0,
            subEntityType: 0,
            draft: true,
            shape,
            ref,
            refType,
        };

        if (type === ShapeTypes.POINT)
        {
            entity.entityType = EntityType.POINT_OF_INTEREST;
            entity.subEntityType = PointOfInterestType.UNKNOWN_POI;
        }

        return entity;
    }, [propertyId, floorId]);

    //#region SMART FILTERS

    const getSmartFilter = useCallback(
        /**
         * @returns a smart filter
         */
        () =>
        {
            let smartFilter = {
                filter: selectedFilter,
                idType: FILTERED_ID_TYPES.ENTITY,
                filteredIds: [],
                highlightOnlyWhenSelected: false,
                entityIdHighlightMap: {},
            };

            if (selectedFilter === ENTITY_EDITOR_FILTERS.PARTIALLY_OVERLAPPING_ENTITIES)
            {
                if (overlapAccess instanceof EntityOverlapAccess)
                {
                    smartFilter.filteredIds = overlapAccess.getOverlappingEntityFilteredIdList();
                    smartFilter.entityIdHighlightMap = overlapAccess.getOverlapMap();
                    smartFilter.highlightOnlyWhenSelected = true;
                }
            }
            else if (ENTITY_SELF_FILTERS.includes(selectedFilter))
            {
                if (entitySelfFilterAccess)
                {
                    smartFilter.filteredIds = entitySelfFilterAccess.getListOfFilteredEntityIds();
                    smartFilter.entityIdHighlightMap = entitySelfFilterAccess.getFilteredEntitiesMap();
                    smartFilter.highlightOnlyWhenSelected = false;
                }
            }

            return smartFilter;
        }, [selectedFilter, overlapAccess, entitySelfFilterAccess]
    );

    /**
     * Updates self filter access options
     * used to update feature search results in above use effect
     */
    const updateSelfFilterAccessOptions = useCallback((options) =>
    {
        if (!entitySelfFilterAccess)
        {
            return;
        }

        const selfFilterAccess = new EntitySelfFilterAccess(entitiesRef.current, SELF_FILTER_TYPES.SEARCH, options);
        setEntitySelfFilterAccess(selfFilterAccess);

    }, [entitySelfFilterAccess, setEntitySelfFilterAccess]);

    const updateSmartFilterAccess = useCallback(
        /**
         * handles update logic to the current filter access
         * @param {*} entity
         * @param {*} selectedTool
         */
        (entity, selectedTool) =>
        {
            if (selectedFilterRef.current == ENTITY_EDITOR_FILTERS.PARTIALLY_OVERLAPPING_ENTITIES)
            {
                // update overlap access depending on tool
                setOverlapAccess((oldOverlapAccess) =>
                {
                    let newOverlapAccess = cloneDeep(oldOverlapAccess);

                    if (newOverlapAccess instanceof EntityOverlapAccess)
                    {
                        switch (selectedTool)
                        {
                        case (MAP_EDITOR_TOOLS.Polygon):
                        {
                            newOverlapAccess.handleAddNewEntityToActiveOverlapMap(entity);
                            break;
                        }
                        case (MAP_EDITOR_TOOLS.DeleteEntities):
                        {
                            newOverlapAccess.handleRemoveEntityFromActiveOverlapMap(entity);
                            break;
                        }
                        case (MAP_EDITOR_TOOLS.EditEntities):
                        {
                            newOverlapAccess.handleEditEntityInActiveOverlapMap(entity);
                            break;
                        }
                        }
                    }

                    return newOverlapAccess;
                });
            }
            else if (ENTITY_SELF_FILTERS.includes(selectedFilterRef.current))
            {
                // update angle access
                setEntitySelfFilterAccess((oldEntitySelfFilterAccess) =>
                {
                    let newEntityAngleAccess = cloneDeep(oldEntitySelfFilterAccess);

                    if (newEntityAngleAccess instanceof EntitySelfFilterAccess)
                    {
                        switch (selectedTool)
                        {
                        case (MAP_EDITOR_TOOLS.DeleteEntities):
                        {
                            newEntityAngleAccess.handleRemoveEntity(entity);
                            break;
                        }
                        default:
                        {
                            newEntityAngleAccess.handleEntityUpdate(entity);
                            break;
                        }
                        }
                    }

                    return newEntityAngleAccess;
                });
            }
        }, [setOverlapAccess, setEntitySelfFilterAccess, selectedFilter]);
 
    const smartFilter = useMemo(() => getSmartFilter(), [getSmartFilter]);

    React.useEffect(() =>
    {
        (onSetSelectedFilterItem) && onSetSelectedFilterItem(undefined);

        (onUpdateSmartFilter) && onUpdateSmartFilter(smartFilter);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [smartFilter]);

    //#endregion


    //#region  MAP CALLBACK HANDLERS

    const handleDrawEntity = useCallback(
        /**
         * Handles the creation of an entity from the map
         * @param {*} feature
         * @param {*} callback
         * @returns
         */
        (feature) =>
        {
            let entities = deepCopy(entitiesRef.current);

            // step data;
            let stepData = new EntityStep({
                addedEntities: []
            });

            if (Array.isArray(entities))
            {
                let entity = convertFeatureToEntity(feature);

                entities.push(entity);
                stepData.addedEntities.push(entity);

                entitiesRef.current = entities;
                updateIndexedDB(stepData);

                // keep track of overlap access if selected
                updateSmartFilterAccess(entity, mapEditorContext.state.selectedTool);

                return entity;
            }

        }, [entitiesRef.current, mapEditorContext.state.selectedTool, convertFeatureToEntity, updateSmartFilterAccess]);

    const handleDeleteEntity = useCallback(
        /**
         * Handles the deletion of entity
         * @param {*} entityId
         * @returns
         */
        (entityId) =>
        {
            let entities = deepCopy(entitiesRef.current);

            // step data;
            let stepData = new EntityStep({
                deletedEntities: []
            });

            // map step
            let entitiesToDelete = [];

            if (Array.isArray(entities))
            {

                let idxOfEntity = entities.findIndex((entity) => entity._id === entityId);
                let deletedEntity = entities[idxOfEntity];

                // check if entity is boundary
                // -> if it is, ignore deletion code and display alert
                if (deletedEntity.entityType === EntityType.BOUNDARY)
                {
                    toast(EDITOR_ALERTS.CANT_DELETE_BOUNDARY);
                    return false;
                }

                entities.splice(idxOfEntity, 1);

                stepData.deletedEntities.push(deletedEntity);

                entitiesRef.current = entities;
                updateIndexedDB(stepData);

                entitiesToDelete.push(deletedEntity);

                // keep track of overlap access if selected
                updateSmartFilterAccess(deletedEntity, mapEditorContext.state.selectedTool);

                return { entitiesToDelete };
            }

        }, [entitiesRef.current, mapEditorContext.state.selectedTool, updateSmartFilterAccess]);

    const handleEditEntityInfo = useCallback((entityId, entityInfo) =>
    {
        let entities = deepCopy(entitiesRef.current);

        // step data;
        let stepData = new EntityStep({
            updatedEntitiesBefore: [],
            updatedEntitiesAfter: []
        });

        if (Array.isArray(entities))
        {

            let idxOfEntity = entities.findIndex((entity) => entity._id === entityId);
            let entityToUpdate = deepCopy(entities[idxOfEntity]);

            stepData.updatedEntitiesBefore.push(entityToUpdate);

            stepData.updatedEntitiesAfter.push(entityInfo);

            entities[idxOfEntity] = deepCopy(entityInfo);
            entitiesRef.current = entities;

            // keep track of overlap access if selected
            updateSmartFilterAccess(entityInfo, MAP_EDITOR_TOOLS.EditEntities);

            updateIndexedDB(stepData);
        }

    }, [entitiesRef.current, updateSmartFilterAccess]);

    /**
     * Finds and returns entity in entities array using entity Id
     */
    const handleGetSelectedEntity = useCallback((entityId) =>
    {
        let entities = deepCopy(entitiesRef.current);
        let selectedEntity;

        if (Array.isArray(entities))
        {
            let idxOfEntity = entities.findIndex((entity) => entity._id === entityId);

            if (idxOfEntity >= 0)
            {
                selectedEntity = entities[idxOfEntity];
            }
        }

        return selectedEntity;
    }, [entitiesRef.current]);


    /**
     * Checks if any of the entities in the given array have a node attached to a transition.
     * @param {Array} entities - An array of entities.
     * @returns {boolean} - Returns true if any entity has a node attached to a transition, false otherwise.
     */
    const doesTransitionNodeExistInEntities = useCallback((entities) =>
    {   
        // check if any of the entities has a transition node
        for (let i = 0; i < entities.length; i++)
        {
            const nodeIds = entities[i].nodeIds;
            
            if (Array.isArray(nodeIds))
            {
                // check if any of the nodes is attached to a transition
                for (let j = 0; j < nodeIds.length; j++)
                {
                    const nodeId = nodeIds[j];
                    if (mapEditorContext.state?.propertyTransitions?.transitionNodeIds[nodeId])
                    {
                        return true;
                    }
                }
            }
        }

        return false;
    },[mapEditorContext?.state?.propertyTransitions?.transitionNodeIds]);

    /**
     * Splits the entity based off of the given line if possible.
     * will delete the split entity and add the "upper" and "lower" entity splits
     * returns outsideStepData for map to render the change
     * @param {*} entityId
     * @param {*} splitLineShape
     * @returns outsideStepData for map to render the change {entitiesToAdd, entitiesToDelete}
     */
    const handleSplitEntity = useCallback((entityId, splitLineShape) =>
    {
        let entities = deepCopy(entitiesRef.current);

        // step data;
        let stepData = new EntityStep({
            deletedEntities: [],
            addedEntities: []
        });

        if (Array.isArray(entities))
        {
            let idxOfEntity = entities.findIndex((entity) => entity._id === entityId);
            let entityToSplit = deepCopy(entities[idxOfEntity]);

            // check if transition node exist in entity array
            const hasTransitionNode = doesTransitionNodeExistInEntities([entityToSplit]);

            if (hasTransitionNode)
            {
                toast(EDITOR_ALERTS.CANT_SPLIT_ENTITY_WITH_TRANSITION);
                return;
            }

            // check if split is valid
            const entityToSplitShape = entityToSplit.shape;

            const upperCut = cutPolygon(entityToSplitShape, splitLineShape, 1, "upper");
            const lowerCut = cutPolygon(entityToSplitShape, splitLineShape, -1, "lower");

            // check if split is valid and possible
            if (upperCut && lowerCut && upperCut.geometry.type !== "MultiPolygon" && lowerCut.geometry.type !== "MultiPolygon")
            {

                let entityBeingSplitTemplate = createTemplateFromEntity(entityToSplit);

                const upperEntity = deepCopy(entityBeingSplitTemplate);
                upperEntity.shape = upperCut.geometry;
                upperEntity._id = ObjectID().toString();

                const lowerEntity = deepCopy(entityBeingSplitTemplate);
                lowerEntity.shape = lowerCut.geometry;
                lowerEntity._id = ObjectID().toString();

                stepData.deletedEntities.push(entityToSplit);
                entities.splice(idxOfEntity, 1);
                stepData.addedEntities.push(upperEntity, lowerEntity);
                entities.push(upperEntity, lowerEntity);

                entitiesRef.current = entities;

                updateSmartFilterAccess(entityToSplit, MAP_EDITOR_TOOLS.DeleteEntities);
                updateSmartFilterAccess(upperEntity, MAP_EDITOR_TOOLS.Polygon);
                updateSmartFilterAccess(lowerEntity, MAP_EDITOR_TOOLS.Polygon);

                updateIndexedDB(stepData);

                return {
                    entitiesToAdd: stepData.addedEntities,
                    entitiesToDelete: stepData.deletedEntities,
                };
            }
        }

        return undefined;

    }, [entitiesRef.current]);

    const handleMergeEntities = useCallback((selectedEntityId1, selectedEntityId2) =>
    {
        // get both entity data
        let entities = deepCopy(entitiesRef.current);

        // step data;
        let stepData = new EntityStep({
            deletedEntities: [],
            addedEntities: []
        });

        if (Array.isArray(entities))
        {
           
            let idxOfEntity1 = entities.findIndex((entity) => entity._id === selectedEntityId1);
            let idxOfEntity2 = entities.findIndex((entity) => entity._id === selectedEntityId2);
            let entityToMerge1 = deepCopy(entities[idxOfEntity1]);
            let entityToMerge2 = deepCopy(entities[idxOfEntity2]);

            // check if entities are connected to a transition
            const hasTransitionNode = doesTransitionNodeExistInEntities([entityToMerge1, entityToMerge2]);
            if (hasTransitionNode)
            {
                toast(EDITOR_ALERTS.CANT_MERGE_ENTITIES_WITH_TRANSITION);
                return;
            }


            const mergedPolygon = mergePolygons(entityToMerge1.shape, entityToMerge2.shape);

            if (mergedPolygon && mergedPolygon.geometry.type !== "MultiPolygon")
            {
                let mergedEntity = createTemplateFromEntity(entityToMerge1);

                mergedEntity.shape = mergedPolygon.geometry;
                mergedEntity._id = ObjectID().toString();

                // check entity types
                if (entityToMerge1.entityType !== entityToMerge2.entityType || entityToMerge1.subEntityType !== entityToMerge2.subEntityType)
                {
                    mergedEntity.entityType = 0;
                    mergedEntity.subEntityType = 0;
                }

                stepData.deletedEntities.push(entityToMerge1, entityToMerge2);
                entities.splice(idxOfEntity1, 1);
                // need to re fin idx of entity2 after its been spliced
                idxOfEntity2 = entities.findIndex((entity) => entity._id === selectedEntityId2);
                entities.splice(idxOfEntity2, 1);

                stepData.addedEntities.push(mergedEntity);
                entities.push(mergedEntity);

                entitiesRef.current = entities;

                updateSmartFilterAccess(entityToMerge1, MAP_EDITOR_TOOLS.DeleteEntities);
                updateSmartFilterAccess(entityToMerge2, MAP_EDITOR_TOOLS.DeleteEntities);
                updateSmartFilterAccess(mergedEntity, MAP_EDITOR_TOOLS.Polygon);

                updateIndexedDB(stepData);

                return {
                    entitiesToAdd: stepData.addedEntities,
                    entitiesToDelete: stepData.deletedEntities,
                };
            }
        }

        return undefined;
    }, [entitiesRef.current]);

    //#endregion

    //#region UNDO/REDO HANDLERS
    const handleUndoEntityChanges = useCallback(async (step) =>
    {
        if (!step)
        {
            return;
        }
        if (step.selectedTool !== MAP_EDITOR_TOOLS.FLoorPlan)
        {

            let entities = deepCopy(entitiesRef.current);

            let entityStepData = new EntityStep(step.data);

            // arrays for the map to add/edit/delete
            let entitiesToAdd = [];
            let entitiesToUpdate = [];
            let entitiesToDelete = [];

            // if entities were added - remove the added entities from the array
            if (Array.isArray(entityStepData.addedEntities))
            {
                // remove each added entity
                entityStepData.addedEntities.forEach((addedEntity) =>
                {
                    let idxOfAddedEntity = entities.findIndex((e) => e._id === addedEntity._id);
                    entities.splice(idxOfAddedEntity, 1);

                    entitiesToDelete.push(addedEntity);

                    // update overlap access
                    updateSmartFilterAccess(addedEntity, MAP_EDITOR_TOOLS.DeleteEntities);

                });

                await mapEditorLocalDb.deleteMany(CollectionNameMap.entities, entityStepData.addedEntities);
            }
            // if entities were deleted - add the entities to the array
            if (Array.isArray(entityStepData.deletedEntities))
            {
                entityStepData.deletedEntities.forEach((deletedEntity) =>
                {
                    entities.push(deletedEntity);

                    entitiesToAdd.push(deletedEntity);

                    updateSmartFilterAccess(deletedEntity, MAP_EDITOR_TOOLS.Polygon);
                });

                await mapEditorLocalDb.insertMany(CollectionNameMap.entities, entityStepData.deletedEntities);
            }
            // if entities were updated - set their values to their old state
            if (Array.isArray((entityStepData.updatedEntitiesBefore)))
            {
                entityStepData.updatedEntitiesBefore.forEach((updatedEntity, i) =>
                {
                    let idxOfUpdatedEntity = entities.findIndex((e) => e._id === updatedEntity._id);
                    entities[idxOfUpdatedEntity] = deepCopy(updatedEntity);

                    let prevEntity = entityStepData.updatedEntitiesAfter[i];
                    let newEntity = updatedEntity;
                    entitiesToUpdate.push({ prevEntity, newEntity });


                    updateSmartFilterAccess(newEntity, MAP_EDITOR_TOOLS.EditEntities);
                });

                await mapEditorLocalDb.updateMany(CollectionNameMap.entities, entityStepData.updatedEntitiesBefore);
            }

            // update entity ref
            entitiesRef.current = entities;

            mapEditorContext.handleAddStepToActionQueue({
                entitiesToUpdate,
                entitiesToAdd,
                entitiesToDelete,
            });

            if (step.selectedTool && step.selectedTool !== activeToolRef.current)
            {
                mapEditorContext.handleChangeSelectedTool(step.selectedTool);
            }
        }
        else if (step.selectedTool === MAP_EDITOR_TOOLS.FLoorPlan)
        {
            floorPlanUndoHandler(step);
        }

    }, [entitiesRef.current, floorPlanUndoHandler, mapEditorContext.handleAddStepToActionQueue, updateSmartFilterAccess]);

    const handleRedoEntityChanges = useCallback((step) =>
    {
        if (step.selectedTool !== MAP_EDITOR_TOOLS.FLoorPlan)
        {
            // reverse the steps and run undo logic
            let entityData = new EntityStep({
                addedEntities: step.data.deletedEntities,
                deletedEntities: step.data.addedEntities,
                updatedEntitiesBefore: step.data.updatedEntitiesAfter,
                updatedEntitiesAfter: step.data.updatedEntitiesBefore
            });

            step.data = entityData;

            handleUndoEntityChanges(step);
        }
        else if (step.selectedTool === MAP_EDITOR_TOOLS.FLoorPlan)
        {

            floorPlanRedoHandler(step);
        }
    }, [handleUndoEntityChanges, floorPlanRedoHandler]);

    const handleEscapeClick = useCallback(() =>
    {
        mapEditorContext.handleChangeSelectedTool(undefined);
    }, [mapEditorContext]);

    const handleUndoRedoClick = useCallback((shortcut) =>
    {
        if (!mapEditorContext?.state?.isLevelLockedByUser || isUndoLocked)
        {
            return;
        }
        switch (shortcut)
        {
        case MAP_EDITOR_SHORTCUTS.UNDO:
        {
            onUndo(handleUndoEntityChanges);
            break;
        }
        case MAP_EDITOR_SHORTCUTS.REDO:
        {
            onRedo(handleRedoEntityChanges);
            break;
        }
        }
    }, [handleUndoEntityChanges, handleRedoEntityChanges, isUndoLocked, mapEditorContext?.state?.isLevelLockedByUser]);

    useKeyboardShortcut("Control", [MAP_EDITOR_SHORTCUTS.UNDO, MAP_EDITOR_SHORTCUTS.REDO], (shortcut) => handleUndoRedoClick(shortcut));
    useSingleKeyboardShortcut("Escape", handleEscapeClick);
    //#endregion
    return (

        <EntityEditorMap
            isFloor={!!floorId}
            smartFilter={smartFilter}
            selectedFilterItem={selectedFilterItem}
            onDrawEntity={handleDrawEntity}
            onDeleteEntity={handleDeleteEntity}
            onEditEntityInfo={handleEditEntityInfo}
            onGetSelectedEntity={handleGetSelectedEntity}
            onSplitEntity={handleSplitEntity}
            onMergeEntities={handleMergeEntities}
        />
    );
};
