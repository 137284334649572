import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Image } from 'semantic-ui-react';
import { ModalLayout } from './ModalLayout';

export const GroundFloorModal = () =>
{
    const trans = useTranslation().t;
    return (
        <ModalLayout heading={trans("GroundFloorModal.What_is_the_Ground_Floor?")}
        size="small"
        trigger={<Icon name="question circle" color="blue"/>}>
            <div className="aligner">
                <p className="p-modal">{trans("GroundFloorModal.The_Ground_Floor_is_the_floor_of_a_build")}
                    <br /><br />
                    {trans("GroundFloorModal.Some_of_the_most_common_names_for_the_Gr")}
                </p>
                <Image className="ground-floor-graph" as="span" src="/img/popup-img-groundfloor.png" alt="" />
            </div>    
        </ModalLayout>
    );
}