import React from "react";
import MapButton from "./MapButton";
import { useMapZoom } from "../../branding/map/mapHooks";

const ZoomControl = ({ olMap }) =>
{
    const { zoomIn, zoomOut } = useMapZoom(olMap);

    return (
        <div className="buttons-group staysActive">
            <MapButton content={<span className="zoomIn" />} onClick={zoomIn} />
            <MapButton content={<span className="zoomOut" />} onClick={zoomOut} />
        </div>
    );
};

export default ZoomControl;