import { Popup, PopupContent } from "semantic-ui-react";
import "./categoryUses.css";
import { useTranslation } from "react-i18next";
import React, { useContext } from "react";
import { CategoryContext } from "../../../../store/CategoryProvider";
import { DEFAULT_LANGUAGE_CODE } from "../../../../_constants/constants";
import { ButtonIcon } from "../../../common/ButtonIcon";

const CategoryUses = ({ className="", popupContentClass="", category, lang=DEFAULT_LANGUAGE_CODE, position="bottom right", expanded=false }) => 
{
    const trans = useTranslation().t;
    const categoryCtx = useContext(CategoryContext);
    const useCount = category?.parentCategories?.length || 0;
    const parents = useCount > 0 ? categoryCtx.getCategoriesFromIds(category?.parentCategories) : [];
    
    const getParent = (category) =>
    {
        if (category.isRoot)
        {
            return <div>{category.name[lang] || category.name[DEFAULT_LANGUAGE_CODE]}</div>;
        }
        else if (category.parentCategories.length > 0)
        {
            const parents = categoryCtx.getCategoriesFromIds(category.parentCategories);
            return parents.map((parent) => <div key={parent.name[lang] || parent.name[DEFAULT_LANGUAGE_CODE]}>{`${parent.name[lang] || parent.name[DEFAULT_LANGUAGE_CODE]} > ${category.name[lang] || category.name[DEFAULT_LANGUAGE_CODE]}`}</div>);
        }
    };
    const count = parents.map((parent) => parent.isRoot ? 1 : parent.parentCategories.length).reduce((acc, current) => acc+current, 0);
    if (count <= 0) return <></>;
    if (expanded)
    {
        return <div className="expandedCategoryUseRoot">
            <Popup
                trigger={
                    <div key={`uses-${category?._id}`} className={`category-uses-trigger ${className}`}>
                        <ButtonIcon className="category-icons" icon="link-blue" /> <div>{count} {trans("common.uses")}</div>
                    </div>
                }
                position={position}
            >
                <PopupContent>
                    <div className={popupContentClass}>
                        {
                            parents.map((parent) => (
                                <div key={`parent-${parent?._id}`}>
                                    {getParent(parent)}
                                </div>
                            ))
                        }
                    </div>    
                </PopupContent>      
            </Popup>
            <p>
            This category has {useCount} more occurrences as a category or sub-category in the same or other groups. Any changes made to the category will be reflected at all places
            </p>
        </div>;
    }
    return ( <>
        <Popup
            trigger={
                <div key={`uses-${category?._id}`} className={`category-uses-trigger ${className}`}>
                    <ButtonIcon className="category-icons" icon="link-blue" /> <div>{count} {trans("common.uses")}</div>
                </div>
            }
            position={position}
        >
            <PopupContent>
                <div className={popupContentClass}>
                    {
                        parents.map((parent) => (
                            <div key={`parent-${parent?._id}`}>
                                {getParent(parent)}
                            </div>
                        ))
                    }
                </div>    
            </PopupContent>      
        </Popup>
    </> );
};
 
export default CategoryUses;