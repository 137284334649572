import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import styles from "./Search.module.css";

function SearchBar({ onChange, value }) 
{
    const inputRef = useRef(null);

    const trans = useTranslation().t;

    useEffect(() => 
    {
        inputRef.current.focus();
    }, []);

    return (
        <div className={styles.searchBar}>
            <input
                ref={inputRef}
                value={value}
                className={styles.input}
                type={"text"}
                placeholder={trans("MapOverlaysActivitySideBar.Search")}
                onChange={onChange}
            />
            <img className={styles.icon} src="/img/icon-search-default.svg" alt="icon" />
        </div>
    );
}

export default SearchBar;
