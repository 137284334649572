import React from "react";
import IconsSearch from "./IconsSearch/IconsSearch";
import IconsTypeSelector from "./IconsTypeSelector/IconsTypeSelector";
import IconsViewSection from "./IconsViewSection/IconsViewSection";
import "./IconsManagementMain.css";
import { Button } from "semantic-ui-react";
import { GmsActionMode, useGmsContext } from "../../GmsContext";
import { useIconsManagementContext } from "../IconsManagementContext";

function IconsManagementMain()
{

    return <div className="icons-management-main">
        <div className="icons-management-main-top-bx">
            <IconsSearch />
            <IconsTypeSelector />
        </div>
        <div className="icons-management-main-icons-bx">
            <IconsViewSection />
        </div>       
    </div>;
}

export default IconsManagementMain;