import React from "react";
import { useTranslation } from "react-i18next";
import "./Mapsted.css";

export default function Mapsted({ small })
{
    const className = small ? "mapsted-splash-small" : "mapsted-splash";
    const trans = useTranslation().t;

    return (
        <div className={`loading-container ${className}`}>
            <img src="/img/loading-m.gif" alt={trans("Mapsted.loading___")}/>
        </div>
    );
}
