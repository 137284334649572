import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "semantic-ui-react";
import { get as getDeepValue, isEmpty, kebabCase } from "lodash";
import { useDebounceEffect } from "ahooks";
import { ButtonIcon } from "../../common/ButtonIcon";
import { CheckboxGroup } from "../../common/CheckboxGroup";
import { TextGroup } from "../../common/TextGroup";
import { SettingsHeading } from "../SettingsHeading";
import { SettingsContext } from "../SettingsProvider";
import { DEFAULT_LANGUAGE_CODE } from "mapsted.maps/utils/map.constants";
import { ErrorLabel } from "../../elements/ErrorLabel";
import BrandingContext from "../../../store/BrandingContext";
import { DividerLine } from "../../common/DividerLine";
import { DropdownForm } from "../../common/DropdownForm";
import { useMemo } from "react";
import { EntityType, PointOfInterestType } from "mapsted.maps/utils/entityTypes";
import { useQuery } from "react-query";
import { BRANDING_QUERIES } from "../../../_api/branding.queries";
import brandingApi from "../../../_api/branding.api";
import FocusPoint from "../../pages/settings/FocusPoint";

const CustomizationOptions = ({ isOpen, onChangeValue }) =>
{
    const { mergedSettings: settings, mutate } = useContext(SettingsContext);
    const trans = useTranslation().t;
    const { validationErrors = [] } = settings;

    const [isUrlDirty, setIsUrlDirty] = useState(false);
    useDebounceEffect(() =>
    {
        // this prevents conversion on initial page load
        if (isUrlDirty)
        {
            handleChangeValue("publicSubdomain", kebabCase(settings.publicSubdomain));
        }
    }, [settings.publicSubdomain, isUrlDirty], { wait: 1000 });


    const handleChangeValue = (path, value) =>
    {
        if (path === "publicSubdomain")
        {
            setIsUrlDirty(true);
        }
        onChangeValue(path, value);
    };

    const renderPageUrl = () => (
        <>
            <SettingsHeading heading={trans("MapstedMapsModule.Public_URL")}>
                {trans("MapstedMapsModule.Create_a_customized_URL_for_people_to_ea")}
                <br />
                {`(${trans("MapstedMapsModule.Alphanumeric values only")})`}
            </SettingsHeading>
            <div className="publicUrlWrap">
                <TextGroup title={trans("MapstedMapsModule.URL_")} flexible>
                    <Input
                        placeholder={trans("Settings.Ex-create-slug-123")}
                        value={settings.publicSubdomain || ""}
                        onChange={(e, { value }) => handleChangeValue("publicSubdomain", value)}
                    />
                </TextGroup>
                <TextGroup flexible className="publicUrlNumber" title="Example:">
                    https://maps.mapsted.com/<span>{settings.publicSubdomain || trans("Settings.create-slug-123")}</span>
                </TextGroup>
            </div>
        </>
    );


    const renderBaseMap = () => (
        <div className="settingsPageTabCover">
            <SettingsHeading heading={trans("Settings.Basemap")}>
                {trans("Settings.Hide_either_the_property_building_or_both")}
            </SettingsHeading>
            <CheckboxGroup fluid label={trans("Settings.Hide_property")}
                checked={settings.hiddenBaseMap?.property}
                onChange={(e, { checked }) => onChangeValue("hiddenBaseMap.property", checked)}
            />
            <CheckboxGroup fluid label={trans("Settings.Hide_building")}
                checked={settings.hiddenBaseMap?.building}
                onChange={(e, { checked }) => onChangeValue("hiddenBaseMap.building", checked)}
            />
        </div>
    );

    const renderIdentification = () => (
        <div className="settingsPageTabCover">
            <div className="settingsPageContentRow settingsPropertyRow">
                <div className="settingsPageContentBody">
                    <SettingsHeading heading={trans("Settings.Property_Building_Identification")}>
                        {trans("Settings.Edit_property_and_building_names_on_the_display_card")}
                    </SettingsHeading>
                    <TextGroup flexible title={`${trans("Settings.Store_Name")} `}>
                        <Input placeholder={trans("Settings.Ex_Store_Name")}
                            value={settings.storeName}
                            onChange={(e, { value }) => onChangeValue("storeName", value)}
                        />
                    </TextGroup>
                    <TextGroup flexible title={`${trans("Settings.Building_Name")} `}>
                        <Input placeholder={trans("Settings.Ex_Building_Name")}
                            value={settings.buildingName}
                            onChange={(e, { value }) => onChangeValue("buildingName", value)}
                        />
                    </TextGroup>
                </div>
                <div className="settingsPreviewBox settingsPropertyPreviewBox">
                    <div className="previewPlaceholderBoxInner">
                        <div className="placeholderExample small dark"></div>
                    </div>
                    <div className="placeholderBanner">
                        <div className="previewLogoImg">
                            <img src="/img/placeholder-logo.svg" alt="" />
                        </div>
                    </div>
                    <div className="previewPlaceholderBoxInner">
                        <div className="placeholderExample small dark"></div>
                        <div className="placeholderExample"></div>
                        <DividerLine isEmpty gap="5px 0" />
                        <div className="placeholderExample small dark"></div>
                        <div className="placeholderExample"></div>
                    </div>
                </div>
            </div>
        </div>
    );

    const renderPromotions = () => (
        <div className="settingsPageTabCover">
            <SettingsHeading heading={trans("Settings.Display_Promotions_Notifications_for_Stores_Rooms")}>
                {
                    trans("Settings.Allow_individual_stores_to_display_their_promotions_created_in_the_Notify_platform")
                }
            </SettingsHeading>
            <CheckboxGroup
                fluid
                label={trans("Settings.Enable_Promotions")}
                checked={settings.allowPromotions}
                onChange={(e, { checked }) => onChangeValue("allowPromotions", checked)}
            />
        </div>
    );

    const renderErrors = (errors) =>
    {
        if (isEmpty(errors)) return null;

        return (
            <ErrorLabel multiple>
                {trans("CreateMapOverlaysSideBar.Validation_Error_Header")}
                <ul>
                    {errors.map((error) => <li key={error}>{trans(error)}</li>)}
                </ul>
            </ErrorLabel>
        );
    };

    if (!isOpen) return null;
    return (
        <div className="spaceCover">
            <div className="settingsPageTabPane settingsPageTabPaneModule">

                <div className="settingsPageTabCover">
                    {renderPageUrl()}
                    {renderErrors(getDeepValue(validationErrors, "publicSubdomain", []))}
                </div>          

                {renderBaseMap()}

                {renderIdentification()}

                <CustomizePOI onChangeValue={onChangeValue} />

                {renderPromotions()}

            </div>
        </div>
    );
};

const CustomizePOI = ({ onChangeValue }) =>
{
    const { state: { buildingId, propertyId, properties } } = useContext(BrandingContext);
    const { mergedSettings: settings } = useContext(SettingsContext);

    const propertyPois = useMemo(() => Object.values(getDeepValue(properties, `${propertyId}.poiEntities`, {})), [propertyId]);
    const building = getDeepValue(properties, `${propertyId}.buildings.${buildingId}`, {});
    const selectedPois = settings.selectedPois || [];

    const trans = useTranslation().t;

    const { data: buildingPois = [], isLoading: buildingPoisLoading } = useQuery({
        ...BRANDING_QUERIES.GET_BUILDING_ENTITIES(buildingId),
        queryFn: async () =>
        {
            const { entities } = await brandingApi.getBuildingMapData(buildingId);
            return entities.filter(isPoi);
        },
        select: undefined
    });

    const poiOptions = useMemo(() =>
    {
        const floors = isEmpty(building) ? [] : building.floors;

        if (!isEmpty(building) && buildingPoisLoading) return [];

        return (isEmpty(building) ? propertyPois : buildingPois)
            .map(({ entityId, entityLabel, refType, ref }) => ({
                key: entityId,
                text: entityLabel?.longName?.[DEFAULT_LANGUAGE_CODE],
                value: {
                    entityId,
                    buildingId: refType === "Property" ? -1: building.buildingId,
                    floorId: refType === "Property" ? -1: floors.find((f) => f._id === ref)?.floorId,
                }
            }));

    }, [buildingId, propertyId, buildingPoisLoading]);

    const handleAddPoi = (e, { value: poi }) =>
    {
        if (selectedPois.some((p) => areSamePois(p, poi))) return;

        onChangeValue("selectedPois", [...selectedPois, poi]);
    };

    const handleRemovePoi = (poi) =>
    {
        const poisLeft = selectedPois.filter((p) => !areSamePois(p, poi));
        onChangeValue("selectedPois", poisLeft);
    };

    const renderSelectedPois = () => selectedPois.map((selectedPoi) =>
    {
        const isFloorPoi = selectedPoi.buildingId !== -1 && selectedPoi.floorId !== -1;


        let poiEntity;
        if (isEmpty(building))
        {
            if (isFloorPoi) return null;
            poiEntity = propertyPois.find((p) => p.refType === "Property" && p.entityId === selectedPoi.entityId);
        }
        else
        {
            if (!isFloorPoi || selectedPoi.buildingId !== building.buildingId) return null;

            const poiFloor = building.floors.find((f) => f.floorId === selectedPoi.floorId);
            poiEntity = buildingPois.find((p) => p.ref === poiFloor._id && p.entityId === selectedPoi.entityId);
        }

        return poiEntity && (
            <div key={poiEntity._id} className="tagPOI">
                <span>{poiEntity?.entityLabel?.longName[DEFAULT_LANGUAGE_CODE]}</span>
                <ButtonIcon icon="cross-bold" onClick={() => handleRemovePoi(selectedPoi)} />
            </div>
        );
    });

    return (
        <div className="settingsPageTabCover">
            <SettingsHeading heading={trans("Settings.Customize_Point_of_Interest_POI_for_Search_Results_Web")}>
                {trans("Settings.Add_specific_POI_to_the_frequently_searched_option_specific_to_your_property_Select_POI")}
            </SettingsHeading>
            <div className="customizedPOICover">

                <TextGroup heading={trans("Settings.Select_POI")}>
                    <DropdownForm
                        disabled={isEmpty(poiOptions)}
                        hasCaret
                        search
                        selectOnNavigation={false}
                        selectOnBlur={false}
                        text={trans("Settings.Enter_point_of_interest")}
                        icon="caret down"
                        options={poiOptions}
                        value={null}
                        onChange={handleAddPoi}
                    />
                    <div className="tagPOIGroup">
                        {renderSelectedPois()}
                    </div>
                </TextGroup>
            </div>
        </div>
    );
};






export default CustomizationOptions;

const isPoi = ({ entityType, subEntityType }) => entityType === EntityType.POINT_OF_INTEREST && subEntityType === PointOfInterestType.UNKNOWN_POI;
const areSamePois = (poi1, poi2) => poi1.entityId === poi2.entityId && poi1.floorId === poi2.floorId && poi1.buildingId === poi2.buildingId;
