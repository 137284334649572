import React, { useState } from "react";
import IconSkeleton from "../IconSkeleton/IconSkeleton";
import { filerUrl } from "../../../../../../../_utils/utils";
import "./IconComponent.css";
import { Popup } from "semantic-ui-react";
import { useIconsManagementContext } from "../../../IconsManagementContext";
import ImageComponent from "../../../../ImageComponent/ImageComponent";

export default function IconComponent({ iconInfo }) 
{
    const { iconImage, name } = iconInfo;
    const { handleSelectIcon } = useIconsManagementContext();

    return (
        <div className="icon-component-bx">
            <Popup
                className="icon-popup"
                trigger={
                    <div  
                        onClick={() => handleSelectIcon(iconInfo)}
                        className="icon-component-popup-trigger-bx">
                        <ImageComponent
                            imgSrc={filerUrl(iconImage, 1800)}
                            alt={name}
                        />
                    </div>
                 
                }
                position="top center"
                content={
                    <div className="icon-component-img-popup-content">
                        <p>{name}</p>
                    </div>
                }
            ></Popup>
        </div>
    );
}
