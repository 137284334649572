import React, { createContext, useContext, useEffect } from "react";
import { useSetState } from "ahooks";
import BrandingContext from "../store/BrandingContext";
import serverAPI from "../_api/server.api";
import { 
    NEW_DYNAMIC_MAP_LAYER_DEFAULT, 
    DYNAMIC_MAP_LAYERS_MODES, 
    NEW_DYNAMIC_MAP_LAYER_ID,
    NEW_AUTOMATION_RULE,
    DEFAULT_LANGUAGE_CODE,
    MAINTENANCE_MODES,
    DEFAULT_DYNAMIC_MAP_LAYER_MULTI_CREATION
} from "../_constants/constants";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
import { createMapOverlayLayers, getMapOverlayLayerMapById } from "../components/maintenance/mapOverlays/utils/mapOverlayUtils";
import { v4 as uuid } from "uuid";
import { createVectorLayer } from "../components/maintenance/dynamicMapLayers/utils/dynamicMapLayersUtils";
import { MaintenanceContext } from "./MaintenanceContext";
import { MapOverlaysContext } from "./MapOverlaysContext";
import { parseFile, validateAndReturnJson, getMapOverlaysAndDynamicMapLayers } from "../components/maintenance/dynamicMapLayers/utils/fileProcessing";
import { useTranslation } from "react-i18next";

export const DynamicMapLayersContext = createContext({});

export const DynamicMapLayersProvider = ({ children }) =>
{

    const trans = useTranslation().t;

    const { loadingPool, updateMode: updatedMaintenanceMode, state: maintenanceCtxState } = useContext(MaintenanceContext);
    const { mode: maintenanceCtxMode } = maintenanceCtxState;
    const brandingCtx = useContext(BrandingContext);
    const { redirectFromDynamicMapLayer, dynamicMapLayerCreationState, setDyamicMapLayerCreationState, updateMapOverlays, updateConsumedMapOverlays, state: mapOverlayCtxState } = useContext(MapOverlaysContext);
    const { mapOverlays, allowedLangs, settingsConfig } = mapOverlayCtxState;
    const { propertyId, buildingId, floorId } = brandingCtx.state;

    const [state, setState] = useSetState({
        mode: undefined,
        activeDynamicMapLayer: undefined, // to store dynamic map layer currently under creation or editing
        activeAutomationRule: undefined, // to store automation rule currently under creation or editing
        displayVectorLayers: {}, // to store vector layers currently displayed on map, updating this should remove or add layers to the map
        automationRuleToDisplay: undefined, // to store automation rule that needs to displayed in map, mainly used during dynamic map layer creation or editing
        textPopupAutomationRuleId: undefined, // to store the id of automationRule whose text popup needs to be displayed in map
        dynamicMapLayers: [], // all the dynamic map layers persisted for the current floor
        lastMode: undefined, // mainly used in confirmation sidebar
        selectedDynamicMapLayer: {
            dynamicMapLayerId: undefined,
            automationRuleId: undefined,
            configurationName: undefined
        },
        activeMultiCreation: DEFAULT_DYNAMIC_MAP_LAYER_MULTI_CREATION, // to store configuration currently under creation
        displayLang: DEFAULT_LANGUAGE_CODE,
    });

    useEffect(() =>
    {
        if (propertyId && buildingId && floorId)
        {
            getDynamicMapLayers();
        }
    }, [propertyId, buildingId, floorId]);

    useEffect(() =>
    {
        if (maintenanceCtxMode === MAINTENANCE_MODES.DYNAMIC_MAP_LAYERS
            && dynamicMapLayerCreationState.enabled
            && dynamicMapLayerCreationState.newlyCreatedMapOverlayId
            && state.activeDynamicMapLayer)
        {
            const updatedActiveDynamicMapLayer = cloneDeep(state.activeDynamicMapLayer);
            updatedActiveDynamicMapLayer.mapOverlayId = dynamicMapLayerCreationState.newlyCreatedMapOverlayId;

            setDyamicMapLayerCreationState({
                enabled: false,
                mapOverlayId: undefined
            });

            updateActiveDynamicMapLayer(updatedActiveDynamicMapLayer);
        }
    }, [maintenanceCtxMode, dynamicMapLayerCreationState.enabled, dynamicMapLayerCreationState.newlyCreatedMapOverlayId]);

    useEffect(() =>
    {
        const { dynamicMapLayers: stateDynamicMapLayers, selectedDynamicMapLayer, mode, activeMultiCreation } = state;

        const { dynamicMapLayerId, automationRuleId, configurationName } = selectedDynamicMapLayer;

        let dynamicMapLayers = stateDynamicMapLayers;
        let consumedMapOverlays = mapOverlays;

        // if currently in multi creation mode, use dynamic map layers and map overlays from activeMultiCreation object
        if (mode === DYNAMIC_MAP_LAYERS_MODES.MULTI_CREATION)
        {
            dynamicMapLayers = activeMultiCreation.dynamicMapLayers;
            consumedMapOverlays = activeMultiCreation.mapOverlays;
        }

        // if a specific automation rule is selected
        if (dynamicMapLayerId && automationRuleId)
        {
            const selectedDynamicMapLayer = dynamicMapLayers.find((dynamicMapLayer) => dynamicMapLayer._id === dynamicMapLayerId);
            const selectedAutomationRule = selectedDynamicMapLayer?.automationRules.find((automationRule) => automationRule._id === automationRuleId);
            if (selectedDynamicMapLayer && selectedAutomationRule)
            {
                const consumedMapOverlay = consumedMapOverlays.find((mapOverlay) => mapOverlay._id === selectedDynamicMapLayer.mapOverlayId);
                updateVectorLayerFromAutomationRule(selectedDynamicMapLayer, selectedAutomationRule, consumedMapOverlay);
            }
        }
        // if only the dynamic map layer selected 
        else if (dynamicMapLayerId)
        {
            const selectedDynamicMapLayer = dynamicMapLayers.find((dynamicMapLayer) => dynamicMapLayer._id === dynamicMapLayerId);
            if (selectedDynamicMapLayer)
            {
                const consumedMapOverlay = consumedMapOverlays.find((mapOverlay) => mapOverlay._id === selectedDynamicMapLayer.mapOverlayId);
                updateVectorLayerWithMapOverlaySettings(selectedDynamicMapLayer, false, consumedMapOverlay);
            }
        }
        // if only configuration is selected
        else if (configurationName)
        {
            const configDynamicMapLayers = dynamicMapLayers.filter((dynamicMapLayer) => dynamicMapLayer.configurationName === configurationName);
            const mapOverlayIds = configDynamicMapLayers.map((dynamicMapLayer) => dynamicMapLayer.mapOverlayId);
            const mapOverlaysToDisplay = consumedMapOverlays.filter((mapOverlay) => mapOverlayIds.includes(mapOverlay._id));
            const mapOverlayVectorLayerMap = createDisplayLayersFromMapOverlays(mapOverlaysToDisplay);
            setState({
                displayVectorLayers: mapOverlayVectorLayerMap
            });
        }

        // if currently in multi creation mode and nothing is selected display all the uploaded mapOverlays from activeMultiCreation object
        if (mode === DYNAMIC_MAP_LAYERS_MODES.MULTI_CREATION
            && !automationRuleId
            && !dynamicMapLayerId
            && !configurationName)
        {
            const mapOverlayVectorLayerMap = createDisplayLayersFromMapOverlays(consumedMapOverlays);
            setState({
                displayVectorLayers: mapOverlayVectorLayerMap
            });
        }

    }, [state.selectedDynamicMapLayer.dynamicMapLayerId, state.selectedDynamicMapLayer.automationRuleId, state.selectedDynamicMapLayer.configurationName]);

    const getDynamicMapLayers = async () =>
    {
        serverAPI.getDynamicMapLayers(propertyId, buildingId, floorId)
            .then((result) =>
            {
                if (result.success)
                {
                    const { dynamicMapLayers } = result;
                    setState({
                        dynamicMapLayers
                    });
                    updateConsumedMapOverlays(getConsumedMapOverlays(dynamicMapLayers));
                }
            });
    };

    const updateMode = (mode) =>
    {
        if (mode !== state.mode)
        {
            setState({ mode });
        }
        else
        {
            setState({ mode: undefined });
        }
    };

    const initiateCreation = () =>
    {
        const newApiKey = uuid();
        setState({
            activeDynamicMapLayer: { ...NEW_DYNAMIC_MAP_LAYER_DEFAULT, _id: NEW_DYNAMIC_MAP_LAYER_ID, apiKey: newApiKey },
            mode: DYNAMIC_MAP_LAYERS_MODES.CREATE_DYNAMIC_MAP_LAYER,
            displayVectorLayers: {}
        });
    };

    const updateActiveDynamicMapLayer = (updatedDynamicMapLayer) =>
    {

        const { activeAutomationRule, automationRuleToDisplay } = state;
        let automationRule = undefined;
        if (activeAutomationRule)
        {
            automationRule = activeAutomationRule;
        }
        else if (automationRuleToDisplay)
        {
            automationRule = updatedDynamicMapLayer.automationRules.find((automationRule) => automationRule._id === automationRuleToDisplay);
        }

        if (automationRule)
        {
            updateVectorLayerFromAutomationRule(updatedDynamicMapLayer, automationRule);
        }
        else
        {
            updateVectorLayerWithMapOverlaySettings(updatedDynamicMapLayer);
        }

        setState({
            activeDynamicMapLayer: updatedDynamicMapLayer
        });
    };

    const cancelHandler = () =>
    {
        setState({
            mode: undefined,
            activeDynamicMapLayer: undefined,
            activeAutomationRule: undefined,
            displayVectorLayers: {},
            selectedDynamicMapLayer: {
                dynamicMapLayerId: undefined,
                automationRuleId: undefined,
                configurationName: undefined
            },
            displayLang: DEFAULT_LANGUAGE_CODE
        });
    };

    const cancelMultiCreationHandler = () =>
    {
        setState({
            activeAutomationRule: undefined,
            selectedDynamicMapLayer: {
                dynamicMapLayerId: undefined,
                automationRuleId: undefined,
                configurationName: undefined
            }
        });
        initiateCreation();
    };

    const createNewAutomationRule = (ruleNumber) =>
    {
        const newAutomationRule = { ...NEW_AUTOMATION_RULE, _id: `${state.activeDynamicMapLayer._id}_${ruleNumber}` };

        updateVectorLayerFromAutomationRule(state.activeDynamicMapLayer, newAutomationRule);

        setState({
            activeAutomationRule: newAutomationRule,
            automationRuleToDisplay: undefined
        });
    };

    const updateActiveAutomationRule = (updatedAutomationRule) =>
    {
        const { activeDynamicMapLayer, displayLang } = state;

        updateVectorLayerFromAutomationRule(activeDynamicMapLayer, updatedAutomationRule, undefined, displayLang);

        setState({
            activeAutomationRule: updatedAutomationRule
        });
        
    };

    const saveActiveAutomationRule = () =>
    {
        const { activeDynamicMapLayer, activeAutomationRule } = state;
        const updatedActiveDynamicMapLayer =  cloneDeep(activeDynamicMapLayer);
        const { automationRules } = updatedActiveDynamicMapLayer;

        // make sure the current automation rule is added to automation rule array if its not already part of it.
        activeAutomationRule.dataPointCondition.value = +activeAutomationRule.dataPointCondition.value;
        activeAutomationRule.textLabel[DEFAULT_LANGUAGE_CODE] =  activeAutomationRule.textLabel[DEFAULT_LANGUAGE_CODE].trim();
        activeAutomationRule.textPopup[DEFAULT_LANGUAGE_CODE] = activeAutomationRule.textPopup[DEFAULT_LANGUAGE_CODE].trim();
        
        const ruleIndex = automationRules.findIndex((automationRule) => automationRule._id === activeAutomationRule._id);
        if (ruleIndex !== -1)
        {
            automationRules[ruleIndex] = cloneDeep(activeAutomationRule);
        }
        else
        {
            automationRules.push(cloneDeep(activeAutomationRule));
        }

        // restore default mapOverlay settings for styling after saving automationRule
        updateVectorLayerWithMapOverlaySettings(activeDynamicMapLayer);
        
        setState({
            activeDynamicMapLayer: updatedActiveDynamicMapLayer,
            activeAutomationRule: undefined
        });
    };

    const editAutomationRuleClickHandler = (automationRuleId) =>
    {
        const { activeDynamicMapLayer } = state;

        const selectedAutomationRule = activeDynamicMapLayer.automationRules.find((automationRule) => automationRule._id === automationRuleId);

        updateVectorLayerFromAutomationRule(activeDynamicMapLayer, selectedAutomationRule);

        if (selectedAutomationRule)
        {
            setState({
                activeAutomationRule: cloneDeep(selectedAutomationRule),
                automationRuleToDisplay: undefined
            });
        }
    };

    const cancelAutomationRuleCreateAndEdit = () =>
    {
        const { activeDynamicMapLayer } = state;

        // restore default mapOverlay settings for styling after cancel automationRule edit/creation
        updateVectorLayerWithMapOverlaySettings(activeDynamicMapLayer);

        setState({
            activeAutomationRule: undefined
        });
    };

    const createDisplayLayersFromMapOverlays = (mapOverlays, lang=DEFAULT_LANGUAGE_CODE) =>
    {
        const clonedMapOverlays = mapOverlays.map((mapOverlay) => cloneDeep(mapOverlay));
        const mapOverlayLayers = createMapOverlayLayers(clonedMapOverlays, lang);
        return getMapOverlayLayerMapById(mapOverlayLayers); 
    };

    const updateVectorLayerWithMapOverlaySettings = (dynamicMapLayer, clearMap=false, consumedMapOverlay) =>
    {
        const { displayVectorLayers } = state;
        let updatedDisplayVectorLayers = { ...displayVectorLayers };

        // if clearMap remove all the dynamic map layers first
        if (clearMap)
        {
            updatedDisplayVectorLayers = {};
        }

        // if consumedMapOveraly is not provided, search in mapOverlay array
        let mapOverlay = consumedMapOverlay;
        if (!mapOverlay)
        {
            mapOverlay = mapOverlays.find((mapOverlay) => mapOverlay._id === dynamicMapLayer.mapOverlayId);
        }
        
        if (mapOverlay)
        {
            const mapOverlayLayerMap = createDisplayLayersFromMapOverlays([cloneDeep(mapOverlay)]);
            updatedDisplayVectorLayers[dynamicMapLayer._id] = mapOverlayLayerMap[dynamicMapLayer.mapOverlayId];
        }
        else
        {
            delete updatedDisplayVectorLayers[dynamicMapLayer._id];
        }
        setState({
            displayVectorLayers: updatedDisplayVectorLayers,
        });
    };

    const updateVectorLayerFromAutomationRule = (dynamicMapLayer, automationRule, consumedMapOverlay, lang=DEFAULT_LANGUAGE_CODE) =>
    {
        const { displayVectorLayers } = state;
        
        const updatedDisplayVectorLayers = { ...displayVectorLayers };

        // if consumedMapOverlay is not provided, search in mapOverlay array
        let mapOverlay = consumedMapOverlay;
        if (!mapOverlay)
        {
            const { mapOverlayId } = dynamicMapLayer;
            mapOverlay = mapOverlays.find((mapOverlay) => mapOverlay._id === mapOverlayId);
        }

        if (mapOverlay)
        {
            const styleFields = {
                fillColor: automationRule.fillColor,
                borderColor: automationRule.borderColor,
                fillOpacity: automationRule.fillOpacity,
                borderFillOpacity: automationRule.borderOpacity,
                textColor: automationRule.textColor,
                textOpacity: automationRule.textOpacity,
            };

            updatedDisplayVectorLayers[dynamicMapLayer._id] = createVectorLayer(
                dynamicMapLayer._id,
                automationRule._id,
                mapOverlay.shape, 
                styleFields, 
                automationRule.textLabel[lang]);

            setState({
                displayVectorLayers: updatedDisplayVectorLayers
            });
        }
    };

    const updateAutomationRuleToDisplay = (automationRuleId) =>
    {
        const { automationRuleToDisplay, activeAutomationRule, activeDynamicMapLayer, displayLang } = state;

        // if automation rule is under update do not update automationRuleDisplay flag
        if (!activeAutomationRule)
        {
            // if same automation rule is clicked again toggle back
            if (automationRuleId === automationRuleToDisplay)
            {
                automationRuleId = undefined;
            }
            
            if (automationRuleId) 
            {
                const automationRule = activeDynamicMapLayer.automationRules.find((automationRule) => automationRule._id === automationRuleId);
                updateVectorLayerFromAutomationRule(activeDynamicMapLayer, automationRule, undefined, displayLang);
            }
            else
            {
                // use default style settings from mapOverlay
                updateVectorLayerWithMapOverlaySettings(activeDynamicMapLayer);
            }

            setState({
                automationRuleToDisplay: automationRuleId
            });
        }
    };

    const updateTextPopupAutomationRuleId = (automationRuleId) =>
    {
        setState({
            textPopupAutomationRuleId: automationRuleId
        });
    };

    const createDynamicMapLayerHandler = async () =>
    {
        let creationSuccess = false;
        const dynamicMapLayer = cloneDeep(state.activeDynamicMapLayer);
        dynamicMapLayer.propertyId = propertyId;
        dynamicMapLayer.buildingId = buildingId;
        dynamicMapLayer.floorId = floorId;

        const loadingId = loadingPool.add();

        try
        {
            const response = await serverAPI.createDynamicMapLayer(dynamicMapLayer);
            if (response.success)
            {
                const createdDynamicMapLayer = response.createdDynamicMapLayer;

                const updatedDynamicMapLayersArray = [...state.dynamicMapLayers];
                updatedDynamicMapLayersArray.push(createdDynamicMapLayer);

                setState({
                    dynamicMapLayers: updatedDynamicMapLayersArray,
                    activeDynamicMapLayer: createdDynamicMapLayer,
                    mode: DYNAMIC_MAP_LAYERS_MODES.CONFIRMATION_SIDEBAR,
                    lastMode: DYNAMIC_MAP_LAYERS_MODES.CREATE_DYNAMIC_MAP_LAYER,
                    displayLang: DEFAULT_LANGUAGE_CODE
                });

                updateConsumedMapOverlays(getConsumedMapOverlays(updatedDynamicMapLayersArray));
            }
            creationSuccess = response.success;
        }
        catch (error)
        {
            console.log(error);
        }
        finally
        {
            loadingPool.remove(loadingId);
        }

        return creationSuccess;
    };

    const confirmationDoneClickHandler = () =>
    {
        setState({
            activeDynamicMapLayer: undefined,
            activeAutomationRule: undefined,
            mode: undefined,
            displayVectorLayers: {},
            lastMode: undefined,
            activeMultiCreation: DEFAULT_DYNAMIC_MAP_LAYER_MULTI_CREATION
        });
    };

    const initiateEdit = (dynamicMapLayerId) =>
    {
        const selectedDynamicMapLayer = state.dynamicMapLayers.find((dynamicMapLayer) => dynamicMapLayer._id === dynamicMapLayerId);

        if (selectedDynamicMapLayer)
        {
            setState({
                mode: DYNAMIC_MAP_LAYERS_MODES.EDIT_DYNAMIC_MAP_LAYER,
                activeDynamicMapLayer: cloneDeep(selectedDynamicMapLayer)
            });

            // display layer in map with default map overlay settings
            updateVectorLayerWithMapOverlaySettings(selectedDynamicMapLayer, true);
        }
    };

    const editClickHandler = async () =>
    {
        let updateSuccess = false;
        const updatedDynamicMapLayer = cloneDeep(state.activeDynamicMapLayer);
        const id = updatedDynamicMapLayer._id;
        delete updatedDynamicMapLayer._id;
        const loadingId = loadingPool.add();
        try
        {
            const response = await serverAPI.updateDynamicMapLayer(id, updatedDynamicMapLayer);
            if (response.success)
            {
                const updatedDynamicMapLayer = response.updatedDynamicMapLayer;
                const updatedDynamicMapLayersArray = [...state.dynamicMapLayers];
                const index = updatedDynamicMapLayersArray.findIndex((dynamicMapLayer) => dynamicMapLayer._id === id);
                if (index !== -1)
                {
                    updatedDynamicMapLayersArray[index] = updatedDynamicMapLayer;
                }
                setState({
                    dynamicMapLayers: updatedDynamicMapLayersArray,
                    activeDynamicMapLayer: updatedDynamicMapLayer,
                    mode: DYNAMIC_MAP_LAYERS_MODES.CONFIRMATION_SIDEBAR,
                    lastMode: DYNAMIC_MAP_LAYERS_MODES.EDIT_DYNAMIC_MAP_LAYER,
                    displayLang: DEFAULT_LANGUAGE_CODE
                });
            }
            updateSuccess = response.success;
        }
        catch (error)
        {
            console.log(error);
        }
        finally
        {
            loadingPool.remove(loadingId);
        }

        return updateSuccess;
    };

    const deleteClickHandler = async (id) =>
    {
        let deleteSuccess = false;
        const loadingId = loadingPool.add();
        try
        {
            const response = await serverAPI.deleteDynamicMapLayer(id);
            if (response.success)
            {
                const { dynamicMapLayers } = state;
                const updatedDynamicMapLayers = dynamicMapLayers.filter((dynamicMapLayer) => dynamicMapLayer._id !== id);
                setState({
                    dynamicMapLayers: updatedDynamicMapLayers,
                    displayVectorLayers: {}
                });
                updateConsumedMapOverlays(getConsumedMapOverlays(updatedDynamicMapLayers));
            }
            deleteSuccess = response.success;
        }
        catch (error)
        {
            console.log(error);
        }
        finally
        {
            loadingPool.remove(loadingId);
        }
        return deleteSuccess;
    };

    const redirectToMapOverlayCreation = () =>
    {
        redirectFromDynamicMapLayer();
        updatedMaintenanceMode(MAINTENANCE_MODES.MAP_OVERLAYS);
    };

    const updateSelectedDynamicMapLayer = (dynamicMapLayerId, automationRuleId, configurationName) =>
    {
        const selectedDynamicMapLayer = {
            dynamicMapLayerId,
            automationRuleId,
            configurationName
        };

        // clear map while updating selected dynamic map layers (or automation rule)
        setState({
            displayVectorLayers: {},
            selectedDynamicMapLayer
        });
    };

    const clearMapLayers = () =>
    {
        setState({
            displayVectorLayers: {},
        });
    };

    const updateActiveMultiCreation = (activeMultiCreation) =>
    {
        setState({
            activeMultiCreation
        });
    };

    const initiateMultiCreation = () =>
    {
        setState({
            activeMultiCreation: DEFAULT_DYNAMIC_MAP_LAYER_MULTI_CREATION,
            mode: DYNAMIC_MAP_LAYERS_MODES.MULTI_CREATION,
            displayVectorLayers: {},
            activeDynamicMapLayer: undefined,
            activeAutomationRule: undefined,
        });
    };

    const validateAndParseMultiCreationFile = async (file) =>
    {
        const csvString = await parseFile(file);
        return validateAndReturnJson(csvString, mapOverlays, trans);
    };

    const saveMultiCreationDataRows = (dataRows, file) =>
    {
        const { mapOverlays, dynamicMapLayers } = getMapOverlaysAndDynamicMapLayers(dataRows);

        const mapOveraysVectorLayerMap = createDisplayLayersFromMapOverlays(mapOverlays);
        const updatedActiveMultiCreation = { ...state.activeMultiCreation, file, dynamicMapLayers, mapOverlays };

        setState({
            activeMultiCreation: updatedActiveMultiCreation,
            displayVectorLayers: mapOveraysVectorLayerMap
        });
    };

    const createMultipleClickHandler = async () =>
    {
        const { mapOverlays: newMapOverlays, dynamicMapLayers, configurationName } = state.activeMultiCreation;

        const existingMapOverlaysCount = mapOverlays.length;

        newMapOverlays.forEach((mapOverlay, index) =>
        {
            mapOverlay.propertyId = propertyId;
            mapOverlay.buildingId = buildingId;
            mapOverlay.floorId = floorId;
            mapOverlay.index = existingMapOverlaysCount + index;
        });

        dynamicMapLayers.forEach((dynamicMapLayer) =>
        {
            dynamicMapLayer.configurationName = configurationName;
            dynamicMapLayer.propertyId = propertyId;
            dynamicMapLayer.buildingId = buildingId;
            dynamicMapLayer.floorId = floorId;

            dynamicMapLayer.automationRules.forEach((automationRule) =>
            {
                delete automationRule._id;
            });
        });

        const loadingId = loadingPool.add();

        try
        {
            const response = await serverAPI.createMultiDynamicLayers(newMapOverlays, dynamicMapLayers);
            if (response.success)
            {
                const { createdMapOverlays, createdDynamicMapLayers } = response;
                const updatedDynamicMapLayers = [...state.dynamicMapLayers, ...createdDynamicMapLayers];
                const updatedMapOverlays = [...mapOverlays, ...createdMapOverlays];

                setState({
                    dynamicMapLayers: updatedDynamicMapLayers,
                    mode: DYNAMIC_MAP_LAYERS_MODES.CONFIRMATION_SIDEBAR,
                    lastMode: DYNAMIC_MAP_LAYERS_MODES.MULTI_CREATION
                });

                updateMapOverlays(updatedMapOverlays);
                updateConsumedMapOverlays(getConsumedMapOverlays(updatedDynamicMapLayers));
            }
        }
        catch (error)
        {
            console.log(error);
        }
        finally
        {
            loadingPool.remove(loadingId);
        }
    };

    const getConsumedMapOverlays = (dynamicMapLayers) => dynamicMapLayers.map((dynamicMapLayer) => dynamicMapLayer.mapOverlayId);

    const multiDeleteHandler = async (configurationName) =>
    {
        let deleteSuccess = false;
        const loadingId = loadingPool.add();
        try
        {
            const response = await serverAPI.deleteDynamicMapLayerConfiguration(configurationName, propertyId, buildingId, floorId);
            if (response.success)
            {
                const { dynamicMapLayers } = state;
                const updatedDynamicMapLayers = dynamicMapLayers.filter((dynamicMapLayer) => dynamicMapLayer.configurationName !== configurationName);
                setState({
                    dynamicMapLayers: updatedDynamicMapLayers,
                    displayVectorLayers: {}
                });
                updateConsumedMapOverlays(getConsumedMapOverlays(updatedDynamicMapLayers));
            }
        }
        catch (error)
        {
            console.log(error);
        }
        finally
        {
            loadingPool.remove(loadingId);
        }
        return deleteSuccess;
    };

    const clearMultiCreation = () =>
    {
        const { configurationName } = state.activeMultiCreation;
        setState({
            activeMultiCreation: { ...DEFAULT_DYNAMIC_MAP_LAYER_MULTI_CREATION, configurationName },
            displayVectorLayers: []
        });
    };

    const deleteAutomationRuleHandler = (automationRuleId) =>
    {
        const { activeDynamicMapLayer } = state;
        const automationRules = activeDynamicMapLayer.automationRules;

        const filteredAutomationRules = automationRules.filter((automationRule) => automationRule._id !== automationRuleId);

        activeDynamicMapLayer.automationRules = filteredAutomationRules;

        setState({
            activeDynamicMapLayer
        });
    };

    const resetContext = () =>
    {
        setState({
            mode: undefined,
            activeDynamicMapLayer: undefined,
            activeAutomationRule: undefined,
            selectedDynamicMapLayer: {
                dynamicMapLayerId: undefined,
                automationRuleId: undefined,
                configurationName: undefined
            },
            activeMultiCreation: DEFAULT_DYNAMIC_MAP_LAYER_MULTI_CREATION
        });
    };

    const checkIfModifiedInCreationMode = () =>
    {
        const { mode } = state;
        let isModified = false;

        if (mode === DYNAMIC_MAP_LAYERS_MODES.CREATE_DYNAMIC_MAP_LAYER)
        {
            const activeDynamicMapLayer = { ...state.activeDynamicMapLayer };

            // reset fields which were added in initiateCreation
            delete activeDynamicMapLayer._id;
            activeDynamicMapLayer.apiKey = NEW_DYNAMIC_MAP_LAYER_DEFAULT.apiKey;

            isModified = !isEqual(activeDynamicMapLayer, NEW_DYNAMIC_MAP_LAYER_DEFAULT);
        }
        else if (mode === DYNAMIC_MAP_LAYERS_MODES.MULTI_CREATION)
        {
            const activeMultiCreation = { ...state.activeMultiCreation };
            isModified = !isEqual(activeMultiCreation, DEFAULT_DYNAMIC_MAP_LAYER_MULTI_CREATION);
        }

        return isModified;
    };

    // ----------------------------------Multi language support-------------------------------------------------

    const updateDisplayLang = (lang) =>
    {
        const { mode, activeDynamicMapLayer, activeAutomationRule, automationRuleToDisplay } = state;

        setState({
            displayLang: lang
        });

        // if currently in creation or edit mode update dynamic map layer text labels and popups in map to reflect selected language
        if (mode === DYNAMIC_MAP_LAYERS_MODES.CREATE_DYNAMIC_MAP_LAYER 
            || mode == DYNAMIC_MAP_LAYERS_MODES.EDIT_DYNAMIC_MAP_LAYER)
        {
            let automationRule;
            if (activeAutomationRule)
            {
                automationRule = activeAutomationRule;
            }
            else if (automationRuleToDisplay)
            {
                automationRule = activeDynamicMapLayer.automationRules.find((automationRule) => automationRule._id === automationRuleToDisplay);
            }

            if (automationRule)
            {
                updateVectorLayerFromAutomationRule(activeDynamicMapLayer, automationRule, undefined, lang);
            }

        }
    };

    const exportedValues = {
        state: { ...state, mapOverlays, navPublicId: maintenanceCtxState.propertyId, allowedLangs, settingsConfig },
        updateMode,
        initiateCreation,
        updateActiveDynamicMapLayer,
        cancelHandler,
        createNewAutomationRule,
        updateActiveAutomationRule,
        saveActiveAutomationRule,
        editAutomationRuleClickHandler,
        cancelAutomationRuleCreateAndEdit,
        updateAutomationRuleToDisplay,
        updateTextPopupAutomationRuleId,
        createDynamicMapLayerHandler,
        confirmationDoneClickHandler,
        initiateEdit,
        editClickHandler,
        deleteClickHandler,
        redirectToMapOverlayCreation,
        updateSelectedDynamicMapLayer,
        clearMapLayers,
        updateActiveMultiCreation,
        initiateMultiCreation,
        createMultipleClickHandler,
        validateAndParseMultiCreationFile,
        saveMultiCreationDataRows,
        multiDeleteHandler,
        clearMultiCreation,
        deleteAutomationRuleHandler,
        resetContext,
        checkIfModifiedInCreationMode,
        cancelMultiCreationHandler,
        updateDisplayLang,
        loadingPool,
    };

    return (
        <DynamicMapLayersContext.Provider value={exportedValues}>
            {children}
        </DynamicMapLayersContext.Provider>
    );
};