import React, { useContext, useRef, useState, useMemo, useEffect, useCallback, } from "react";
import { cartoLayer, createEntityStyle } from "mapsted.maps/mapFunctions/plotting";
import { MapEditorConstants, } from "mapsted.maps/utils/map.constants";
import { changeMapCenterWithBoundaryPolygon, getNodeDataFromClick, } from "mapsted.maps/mapFunctions/interactions";
import { Map, View, } from "ol";
import { defaults as defaultControls, Attribution } from "ol/control";
import MapEditorContext from "../../../store/MapEditiorContext";
import { MapEditorMapButtons } from "./MapEditorMapButtons";
import { setLayerVisability } from "../../../_utils/mapEditorUtils";
import { isTransitionOnCurrentMap } from "mapsted.maps/utils/georeference.utils";
import { setMapLayers } from "mapsted.maps/mapFunctions/publicVectorLayers";
import { MouseInteraction } from "mapsted.maps/utils/interactionTemplates";
import { MAP_EDITOR_TOOLS, SELECTED_NODES_STYLE, NODE_TRANSITION_LAYER_ID } from "../../../_constants/mapEditor";
import { TRANSITION_BOUNDING_BOX_STYLE, styleClassicNodes } from "mapsted.maps/utils/defualtStyles";
import { createTransitionTextFeature, updateFeatureStyle, getNodeTextStyle } from "mapsted.maps/mapFunctions/cmsVectorLayers";
import { toast } from "sonner";
import { CreateEditTransitionModal } from "../mapOverlayComponents/CreateEditTransitionModal";

export const TransitionsEditorMap = ({
    navIds,
    isSamePlottingLevel,
    georeferenceNavIds,
    onGetTransitionFromMapClick,
    onGetTransitionFromId,
    onCreateTransition,
    onEditTransition,
    onDeleteTransition
}) =>
{
    const mapRef = useRef(null);
    const mapRef2 = useRef(null);

    const mapEditorContext = useContext(MapEditorContext);

    const selectedNodeRef = useRef(undefined);
    const selectedNodeGeoreferenceRef = useRef(undefined);
    const localEntityLayersRef = useRef(undefined);
    const localGeoreferenceEntityLayersRef = useRef(undefined);
    const selectedTransitionTextRef = useRef(undefined);
    const selectedGeoreferenceTransitionTextRef = useRef(undefined);

    const [olMap, setOlMap] = useState(undefined);
    const [olMapGeoreference, setOlMapGeoreference] = useState(undefined);
    const [localEntityLayers, setLocalEntityLayers] = useState(undefined);
    const [localGeoreferenceEntityLayers, setLocalGeoreferenceEntityLayers] = useState(undefined);
    const [activeInteraction, setActiveInteraction] = useState(undefined);
    const [activeInteractionGeoreference, setActiveInteractionGeoreference] = useState(undefined);
    const [selectedNode, setSelectedNode] = useState(undefined);
    const [selectedNodeGeoreference, setSelectedNodeGeoreference] = useState(undefined);
    const [selectedTransition, setSelectedTransition] = useState(undefined);
    const [selectedTransitionText, setSelectedTransitionText] = useState(undefined);
    const [selectedGeoreferenceTransitionText, setSelectedGeoreferenceTransitionText] = useState(undefined);

    //https://stackoverflow.com/questions/57847594/react-hooks-accessing-up-to-date-state-from-within-a-callback
    // tldr: use ref for watcher, use set state to update value
    selectedNodeRef.current = selectedNode;
    selectedNodeGeoreferenceRef.current = selectedNodeGeoreference;
    selectedTransitionTextRef.current = selectedTransitionText;
    selectedGeoreferenceTransitionTextRef.current = selectedGeoreferenceTransitionText;
    localEntityLayersRef.current = localEntityLayers;
    localGeoreferenceEntityLayersRef.current = localGeoreferenceEntityLayers;

    const mapData = useMemo(() => mapEditorContext?.state?.mapData, [mapEditorContext]);

    const tileLayer = useMemo(() =>
    {
        const tileStyle = mapData?.style?.tileLayer;
        return cartoLayer(tileStyle); // TODO: add translation 
    }, [mapData]);

    const tileLayerGeoreference = useMemo(() =>
    {
        const tileStyle = mapData?.style?.tileLayer;
        return cartoLayer(tileStyle); // TODO: add translation 
    }, [mapData]);

    const interactions = useMemo(() => ({
        [MAP_EDITOR_TOOLS.Create_Transition]: (source, callback) => MouseInteraction({ source, handleEvent: callback }),
        [MAP_EDITOR_TOOLS.Edit_Transition]: (source, callback) => MouseInteraction({ source, handleEvent: callback }),
    }), []);

    /**
     * On Mount 
     * -> set up olmap
     */
    useEffect(() =>
    {
        const attribution = new Attribution({
            collapsible: true
        });

        const view = new View({
            center: [0, 0],
            zoom: 4,
            maxZoom: MapEditorConstants.MAX_ZOOM,
            minZoom: MapEditorConstants.MIN_ZOOM
        });

        const newOlMap = new Map({
            layers: [tileLayer],
            controls: defaultControls({
                attribution: false,
                zoom: false,
                rotate: false
            }).extend([attribution]),
            view: view,
        });

        const newolMapGeoreference = new Map({
            layers: [tileLayer],
            controls: defaultControls({
                attribution: false,
                zoom: false,
                rotate: false
            }).extend([attribution]),
            view: view
        });

        newOlMap.setTarget(mapRef.current);

        newolMapGeoreference.setTarget(mapRef2.current);

        setOlMap(newOlMap);

        setOlMapGeoreference(newolMapGeoreference);

    }, []);

    /**
     * on layers change
     * -> plot new layers
     */
    useEffect(() =>
    {
        const layers = mapEditorContext?.state?.layers;
        const transitionsTextLayer = mapEditorContext.state.transitionsTextLayer;
        if (!!layers && !!olMap)
        {
            const { boundaryPolygon, entityLayers, nodeLayers, imageLayers, idLayers } = layers;
            drawEntityLayers(entityLayers, imageLayers, nodeLayers, transitionsTextLayer, idLayers);

            changeMapCenterWithBoundaryPolygon({ olMap, boundaryPolygon, padding: MapEditorConstants.FIT_PADDING_MAP_DATA });
        }

    }, [mapEditorContext.state?.layers, mapEditorContext.state?.transitionsTextLayer, olMap]);

    useEffect(() =>
    {
        const georeferenceLayers = mapEditorContext.state.georeferenceLayers;
        const georeferenceTransitionsTextLayer = mapEditorContext.state.georeferenceTransitionsTextLayer;

        if (georeferenceLayers && olMapGeoreference)
        {
            const { boundaryPolygon, entityLayers, nodeLayers, imageLayers, idLayers } = georeferenceLayers;
            drawEntityLayers(entityLayers, imageLayers, nodeLayers, georeferenceTransitionsTextLayer, idLayers, true);

            changeMapCenterWithBoundaryPolygon({ olMap: olMapGeoreference, boundaryPolygon, padding: MapEditorConstants.FIT_PADDING_MAP_DATA });
        }
    }, [mapEditorContext.state?.georeferenceLayers, mapEditorContext.state.georeferenceLayers, olMapGeoreference]);

    /**
     * update layer visibility on change
     **/
    useEffect(() =>
    {
        if (!olMap || !localEntityLayers)
        {
            return;
        }

        const mapLayersVisablityMap = mapEditorContext.state.mapLayersVisablityMap;

        setLayerVisability(localEntityLayers, mapLayersVisablityMap);
        setLayerVisability(localGeoreferenceEntityLayers, mapLayersVisablityMap);

    }, [mapEditorContext.state?.mapLayersVisablityMap, localEntityLayers, olMap]);

    /**
    * on selected tool change
    * -> remove the previous interaction and add the new interaction to the map
    */
    useEffect(() =>
    {
        const { selectedTool } = mapEditorContext.state;
        handleChangeTransitionInteraction(selectedTool);

        // reset selected node styles
        resetNodeFeatureStyles([selectedNodeGeoreferenceRef.current, selectedNodeRef.current]);
        resetTextFeatureStyles([selectedTransitionTextRef.current, selectedGeoreferenceTransitionTextRef.current], { ...selectedTransition });

        setSelectedNode(undefined);
        setSelectedNodeGeoreference(undefined);
        setSelectedTransitionText(undefined);
        setSelectedGeoreferenceTransitionText(undefined);
        setSelectedTransition(undefined);

    }, [mapEditorContext.state?.selectedTool]);

    /**
     * removes old layers and adds new layers to map
     * @param {*} entityLayers 
     * @param {*} imageLayers 
     * @param {*} nodeLayers 
     */
    const drawEntityLayers = (entityLayers, imageLayers, nodeLayers, transitionLayer, idLayers, isGeoreference) =>
    {
        if (!entityLayers)
        {
            return;
        }

        let layersToAdd = [];
        let newLocalLayerMap = {};

        if (isGeoreference)
        {
            layersToAdd.push(tileLayerGeoreference);
        }
        else
        {
            layersToAdd.push(tileLayer);

        }

        Object.keys(entityLayers).forEach((layerId) =>
        {
            if (layerId !== MapEditorConstants.HIDDEN_LAYER)
            {
                const layer = entityLayers[layerId];

                newLocalLayerMap[layerId] = layer;
            }
        });

        Object.keys(nodeLayers).forEach((layerId) =>
        {
            const layer = nodeLayers[layerId];

            newLocalLayerMap[layerId] = layer;
        });

        if (transitionLayer)
        {
            newLocalLayerMap[NODE_TRANSITION_LAYER_ID] = transitionLayer;
        }

        // add id layers to transition map
        if (idLayers)
        {
            Object.keys(idLayers).forEach((layerId) =>
            {
                const layer = idLayers[layerId];
                newLocalLayerMap[layerId] = layer;
            });
        }

        layersToAdd = layersToAdd.concat(Object.values(newLocalLayerMap));

        if (isGeoreference)
        {
            // setMapLayers(olMap, layersToAdd);
            setMapLayers(olMapGeoreference, layersToAdd);
            setMapLayers(olMapGeoreference, localEntityLayers);
            setLocalGeoreferenceEntityLayers(newLocalLayerMap);
        }
        else
        {
            setMapLayers(olMap, layersToAdd);
            setMapLayers(olMapGeoreference, localGeoreferenceEntityLayers);
            setLocalEntityLayers(newLocalLayerMap);
        }
    };

    //#region  MAP INTERACTIONS 

    /**
     * Highlights list of node features 
     * @param {*} nodeFeature 
     * @param {*} node 
     */
    const highlightNodeFeatures = (nodeFeatures) =>
    {
        nodeFeatures.forEach((feature) =>
        {
            if (!feature)
            {
                return;
            }

            const selectedNodeStyle = createEntityStyle(SELECTED_NODES_STYLE);
            const textStyle = getNodeTextStyle(feature);
            selectedNodeStyle.setText(textStyle);
            feature.setStyle(selectedNodeStyle);
        });
    };

    const highlightTextFeatures = (textFeatures) =>
    {
        textFeatures.forEach((feature) =>
        {
            const textStyle = getNodeTextStyle(feature);
            updateFeatureStyle(feature, TRANSITION_BOUNDING_BOX_STYLE["Selected"], textStyle);
        });
    };

    const resetNodeFeatureStyles = (nodeFeatures) =>
    {
        if (!Array.isArray(nodeFeatures))
        {
            return;
        }

        const nodeStyle = createEntityStyle(styleClassicNodes.node);


        nodeFeatures.forEach((feature) =>
        {
            if (feature)
            {
                feature.setStyle(nodeStyle);
            }
        });

    };

    const resetTextFeatureStyles = (textFeatures) =>
    {
        if (!Array.isArray(textFeatures))
        {
            return;
        }

        textFeatures.forEach((feature) =>
        {
            if (feature)
            {
                //TODO: update style
                const transition = onGetTransitionFromId(feature.get("transitionId"));
                updateFeatureStyle(feature, TRANSITION_BOUNDING_BOX_STYLE[transition?.transitionType || 0]);
            }
        });
    };

    /**
    *  // possibly move this to local utils 
    * @param {Event} e - Interaction event
    * @param {Function} onClick - ({isNode, isNodeConnection, nodeId,}) - map click data from getNodeDataFromClick
    * @returns 
    */
    const selectNodeHelper = (e, onClick, map) =>
    {
        let { type } = e;

        if (!e.pixel)
        {
            return;
        }

        const mapClickData = getNodeDataFromClick({ pixel: e.pixel_, olMap: map });

        // on hover
        if (type === "pointermove")
        {
            handleHoverOverNodeOnPointerMove(mapClickData, map);
        }
        // on click
        else if (type === "click")
        {
            let shiftKey = e.originalEvent.shiftKey;

            (!!onClick) && (
                onClick(mapClickData, shiftKey)
            );
        }
        else
        {
            return true;
        }
    };

    /**
     * If cursor is hovering a node, 
     * // possibly move this to local utils 
     * @param {*} mapClickData 
     */
    const handleHoverOverNodeOnPointerMove = (mapClickData, map) =>
    {
        const { isNode, isNodeConnection } = mapClickData;

        if (isNode && !isNodeConnection)
        {
            map.getViewport().style.cursor = "pointer";
        }
        else
        {
            map.getViewport().style.cursor = "";
        }
    };

    /**
     * Logic for map click when create tool is selected
     * @param {*} mapClickData 
     * @param {*} isGeoreference 
     */
    const handleMapClickCreate = (mapClickData, isGeoreference) =>
    {
        const { isNode, isNodeConnection, nodeId, nodeFeature } = mapClickData;
        if (isNode && !isNodeConnection)
        {
            // if same plotting level, check if a node that is selected was clicked to unselect
            if (isSamePlottingLevel && selectedNodeRef?.current?.getId() === nodeId)
            {
                resetNodeFeatureStyles([selectedNodeRef.current]);
                setSelectedNode(undefined);
            }
            else if (isSamePlottingLevel && selectedNodeGeoreferenceRef?.current?.getId() === nodeId)
            {
                resetNodeFeatureStyles([selectedNodeGeoreferenceRef.current]);
                setSelectedNodeGeoreference(undefined);
            }
            // set selected node
            else if (isGeoreference || (isSamePlottingLevel && !!selectedNodeRef.current))
            {
                resetNodeFeatureStyles([selectedNodeGeoreferenceRef.current]);
                setSelectedNodeGeoreference(nodeFeature);
                highlightNodeFeatures([nodeFeature]);

            }
            else
            {
                resetNodeFeatureStyles([selectedNodeRef.current]);
                setSelectedNode(nodeFeature);
                highlightNodeFeatures([nodeFeature]);
            }

        }
    };

    /**
     * Logic for map click when edit tool is selected
     * @param {*} mapClickData 
     */
    const handleMapClickEdit = (mapClickData) =>
    {
        if (!mapClickData?.isNode)
        {
            return;
        }

        const { transition, routeNodesOnMapArray } = onGetTransitionFromMapClick(mapClickData);

        if (transition)
        {
            // reset prev styles
            resetNodeFeatureStyles([selectedNodeGeoreferenceRef.current, selectedNodeRef.current]);
            resetTextFeatureStyles([selectedTransitionTextRef.current, selectedGeoreferenceTransitionTextRef.current], { ...selectedTransition });

            let { node: nodeLayer, [NODE_TRANSITION_LAYER_ID]: transitionLayer } = localEntityLayersRef.current;
            let { node: georeferenceNodeLayer, [NODE_TRANSITION_LAYER_ID]: georeferenceTransitionLayer } = localGeoreferenceEntityLayersRef.current;

            let nodeLayerSource = nodeLayer.getSource();
            let georeferenceNodeLayerSource = georeferenceNodeLayer.getSource();
            let transitionSource = transitionLayer.getSource();
            let georeferenceTransitionSource = georeferenceTransitionLayer.getSource();

            let nodeFeaturesToHighlight = [];
            let textFeaturesToHighlight = [];
            // set selected nodes and selected transition
            transition.route.forEach((routeNode, i) =>
            {
                const routeNodeMapInfo = routeNodesOnMapArray[i];

                if (isSamePlottingLevel && i === 1)
                {
                    const nodeFeature = nodeLayerSource.getFeatureById(routeNode.node);
                    const transitionTextFeature = transitionSource.getFeatureById(routeNode._id);

                    setSelectedNodeGeoreference(nodeFeature);
                    setSelectedGeoreferenceTransitionText(transitionTextFeature);
                    nodeFeaturesToHighlight.push(nodeFeature);
                    textFeaturesToHighlight.push(transitionTextFeature);
                }
                else if (routeNodeMapInfo.isMain)
                {
                    const nodeFeature = nodeLayerSource.getFeatureById(routeNode.node);
                    const transitionTextFeature = transitionSource.getFeatureById(routeNode._id);

                    setSelectedNode(nodeFeature);
                    setSelectedTransitionText(transitionTextFeature);
                    nodeFeaturesToHighlight.push(nodeFeature);
                    textFeaturesToHighlight.push(transitionTextFeature);
                }
                else if (routeNodeMapInfo.isGeoreference)
                {
                    const nodeFeature = georeferenceNodeLayerSource.getFeatureById(routeNode.node);
                    const transitionTextFeature = georeferenceTransitionSource.getFeatureById(routeNode._id);

                    setSelectedNodeGeoreference(nodeFeature);
                    setSelectedGeoreferenceTransitionText(transitionTextFeature);
                    nodeFeaturesToHighlight.push(nodeFeature);
                    textFeaturesToHighlight.push(transitionTextFeature);
                }


            });

            highlightNodeFeatures(nodeFeaturesToHighlight);
            highlightTextFeatures(textFeaturesToHighlight);
            setSelectedTransition(transition);
        }
    };

    /**
     * Get node from mouse click
     * reset prev selected node on the respected map
     * set new selected node to the selected map
     * @param {*} e 
     * @param {*} isGeoreference 
     * @returns 
     */
    const onSelectNodeMouseEvent = (e, isGeoreference, isEdit) =>
    {
        let map = (isGeoreference)
            ? olMapGeoreference
            : olMap;

        return selectNodeHelper(e, (mapClickData) =>
        {
            // on click logic for link mouse event
            if (isEdit)
            {
                handleMapClickEdit(mapClickData);
            }
            else
            {
                handleMapClickCreate(mapClickData, isGeoreference);
            }
        }, map);
    };

    const handleChangeTransitionInteraction = useCallback((selectedTool) =>
    {
        if (!olMap || !olMapGeoreference)
        {
            return;
        }

        if (activeInteraction)
        {
            olMap.removeInteraction(activeInteraction);
        }

        if (activeInteractionGeoreference)
        {
            olMapGeoreference.removeInteraction(activeInteractionGeoreference);
        }

        const createAndAddInteractionsToMap = (selectedTool, dynamicMap, isGeoreference) =>
        {
            if (!selectedTool)
            {
                return;
            }

            let mapSource = dynamicMap.getSource;
            let interaction;

            switch (selectedTool)
            {
            case (MAP_EDITOR_TOOLS.Create_Transition):
            {
                const isEdit = false;
                interaction = interactions[selectedTool](mapSource, (e) => onSelectNodeMouseEvent(e, isGeoreference, isEdit));
                break;
            }
            case (MAP_EDITOR_TOOLS.Edit_Transition):
            {
                const isEdit = true;
                interaction = interactions[selectedTool](mapSource, (e) => onSelectNodeMouseEvent(e, isGeoreference, isEdit));
                break;
            }
            }

            if (interaction)
            {
                dynamicMap.addInteraction(interaction);

                return interaction;
            }
        };

        const newMapInteraction = createAndAddInteractionsToMap(selectedTool, olMap, false);
        const newMapGeoreferenceInteraction = createAndAddInteractionsToMap(selectedTool, olMapGeoreference, true);

        setActiveInteraction(newMapInteraction);
        setActiveInteractionGeoreference(newMapGeoreferenceInteraction);


    }, [olMap, olMapGeoreference, isSamePlottingLevel, activeInteraction, activeInteractionGeoreference, setActiveInteraction, setActiveInteractionGeoreference]);

    const handleOutsideStepAction = (step) =>
    {
        let { [NODE_TRANSITION_LAYER_ID]: transitionLayer, node: nodeLayer } = localEntityLayers;
        let { [NODE_TRANSITION_LAYER_ID]: georeferenceTransitionLayer, node: georeferenceNodeLayer } = localGeoreferenceEntityLayers;


        let transitionLayerSource = transitionLayer.getSource();
        let georeferenceTransitionLayerSource = georeferenceTransitionLayer.getSource();

        const { addedTransitions, updatedTransitionsAfter, deletedTransitions } = step;

        if (Array.isArray(addedTransitions))
        {
            addedTransitions.forEach((transition) =>
            {
                const { isBothRouteNodeOnMap, routeNodesOnMapArray } = isTransitionOnCurrentMap(transition, navIds, georeferenceNavIds);
                if (isBothRouteNodeOnMap)
                {
                    transition.route.forEach((trajectoryRouteNode, i) =>
                    {
                        if (routeNodesOnMapArray[i].isMain)
                        {
                            const transitionFeature = createTransitionTextFeature({ transition, trajectoryRouteNode, nodeLayer: nodeLayer });
                            transitionLayerSource.addFeature(transitionFeature);
                        }
                        if (routeNodesOnMapArray[i].isGeoreference)
                        {
                            const transitionFeature = createTransitionTextFeature({ transition, trajectoryRouteNode, nodeLayer: georeferenceNodeLayer });
                            georeferenceTransitionLayerSource.addFeature(transitionFeature);
                        }
                    });
                }
            });
        }

        if (Array.isArray(deletedTransitions))
        {
            deletedTransitions.forEach((transition) =>
            {
                // remove transition text feature from both maps
                const { isBothRouteNodeOnMap, routeNodesOnMapArray } = isTransitionOnCurrentMap(transition, navIds, georeferenceNavIds);
                if (isBothRouteNodeOnMap)
                {
                    transition.route.forEach((routeNode, i) =>
                    {
                        // find text feature by ID

                        if (routeNodesOnMapArray[i].isMain)
                        {
                            const transitionFeatureToDelete = transitionLayerSource.getFeatureById(routeNode._id);
                            transitionLayerSource.removeFeature(transitionFeatureToDelete);
                        }
                        if (routeNodesOnMapArray[i].isGeoreference)
                        {
                            const transitionFeatureToDelete = georeferenceTransitionLayerSource.getFeatureById(routeNode._id);
                            georeferenceTransitionLayerSource.removeFeature(transitionFeatureToDelete);
                        }
                    });
                }
            });
        }

        if (Array.isArray(updatedTransitionsAfter) && selectedTransition)
        {
            updatedTransitionsAfter.forEach((transition) =>
            {
                if (selectedTransition._id === transition._id)
                {
                    setSelectedTransition(transition);
                }
            });
        }
    };

    useEffect(() =>
    {
        const stepAction = mapEditorContext?.state?.stepAction;

        if (olMap && stepAction)
        {
            // call undo step for correct edit type
            handleOutsideStepAction(stepAction);
            mapEditorContext.handleCompleteStepAction();

        }

    }, [mapEditorContext?.state.stepAction]);
    //#endregion

    //#region RENDER 

    const resetSelectedTransition = useCallback(() =>
    {
        resetNodeFeatureStyles([selectedNodeGeoreferenceRef.current, selectedNodeRef.current]);
        resetTextFeatureStyles([selectedTransitionTextRef.current, selectedGeoreferenceTransitionTextRef.current], { ...selectedTransition });

        setSelectedNode(undefined);
        setSelectedNodeGeoreference(undefined);
        setSelectedTransition(undefined);
        setSelectedTransitionText(undefined);
        setSelectedGeoreferenceTransitionText(undefined);
    }, [selectedNodeGeoreferenceRef.current, selectedNodeRef.current, selectedTransitionTextRef.current, selectedGeoreferenceTransitionTextRef.current]);

    const handleCreateTransition = useCallback((transitionOptions) =>
    {
        const { isValid, stepData, validationError } = onCreateTransition([selectedNode, selectedNodeGeoreference], transitionOptions);

        if (isValid)
        {
            handleOutsideStepAction(stepData);
            resetSelectedTransition();
        }
        else
        {
            toast.error(validationError);
        }

    }, [selectedNode, selectedNodeGeoreference, onCreateTransition]);

    const handleEditTransition = useCallback((selectedTransition, transitionOptions) =>
    {
        onEditTransition(selectedTransition, transitionOptions);
        resetSelectedTransition();
    }, [onEditTransition, resetSelectedTransition]);

    const handleDeleteTransition = useCallback((selectedTransition) =>
    {
        const { isValid, stepData } = onDeleteTransition(selectedTransition);

        if (isValid)
        {
            handleOutsideStepAction(stepData);
            resetSelectedTransition();
        }

    }, [onDeleteTransition, resetSelectedTransition]);

    const renderCreateTransitionComponent = useCallback(() =>
    {
        const { selectedTool } = mapEditorContext.state;

        if (selectedTool === MAP_EDITOR_TOOLS.Create_Transition)
        {
            return (
                <CreateEditTransitionModal
                    selectedNode={selectedNode}
                    selectedNodeGeoreference={selectedNodeGeoreference}
                    onCreateTransition={handleCreateTransition}
                    navIds={navIds}
                    georeferenceNavIds={georeferenceNavIds} />
            );
        }
        if (selectedTool === MAP_EDITOR_TOOLS.Edit_Transition && selectedTransition)
        {
            return (
                <CreateEditTransitionModal
                    selectedTransition={selectedTransition}
                    onEditTransition={handleEditTransition}
                    onDeleteTransition={handleDeleteTransition}
                    navIds={navIds}
                    georeferenceNavIds={georeferenceNavIds}
                    isEdit
                />
            );
        }
    }, [selectedNode, selectedNodeGeoreference, selectedTransition, navIds, georeferenceNavIds, handleCreateTransition, handleEditTransition, handleDeleteTransition, mapEditorContext.state?.selectedTool]);

    /**
     * Renders the transitions maps
     * either displays 2 maps or 1 if the its the same plotting level
     */
    const renderTransitionMaps = useCallback(() =>
    {
        const updateMapHelper = (olMap, olMapGeoreference) =>
        {
            // Update container size
            olMap && olMap.updateSize();
            olMapGeoreference && olMapGeoreference.updateSize();
        };

        if (!isSamePlottingLevel)
        {
            updateMapHelper(olMap, olMapGeoreference);
            return (
                <>
                    <div className="map-container-split" ref={mapRef}>
                        {/*  floor buttons*/}
                    </div>
                    <div className="map-container-split" ref={mapRef2}>
                        <MapEditorMapButtons isCenter />
                        <MapEditorMapButtons isGeoreference />
                    </div>
                </>
            );
        }
        else
        {
            updateMapHelper(olMap, olMapGeoreference);
            return (
                <>
                    <div className="map-container-split100" ref={mapRef}>
                        <MapEditorMapButtons isGeoreference />

                        {/*  floor buttons*/}
                    </div>
                    <div className="map-container-split0" ref={mapRef2}>
                    </div>
                </>
            );
        }
    }, [isSamePlottingLevel]);

    return (
        <div className="map-container-split-wrapper">

            {renderTransitionMaps()}

            {renderCreateTransitionComponent()}

            {/* Map editor buttons should show property view, building select, floor options for each map */}
        </div>
    );
    //#endregion
};
