import React from "react";
import MapButton from "./MapButton";

const FloorButtons = ({
    floors = [],
    disabled = false,
    activeFloor,
    onChange
}) => (
    <div className="buttons-group">
        {floors.map((floor) => (
            <MapButton
                key={floor.key}
                content={floor.text}
                isActive={activeFloor === floor.key}
                onClick={() => onChange(floor.key)}
                disabled={disabled}
            />
        ))}
    </div>
);

export default FloorButtons;