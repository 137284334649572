import React from "react";
import { Input } from "semantic-ui-react";
import MapOverlaysOpacitySelector from "../../../maintenance/mapOverlays/mapOverlaysOpacitySelector/MapOverlaysOpacitySelector";

import "./ColorAndOpacitySetter.css";

const ColorAndOpacitySetter = ({ title, color, opacity, onChange, className, disabled }) => (
    <div className={`setterRow ${className ? className : ""}`}>
        <div className="setterColumn heading">
            <p>{`${title}:`}</p>
        </div>
        <div className="setterInnerRow">
            <div className="setterColumn colorInput">
                <Input
                    className="colorPicker"
                    type="color"
                    disabled={disabled}
                    value={color}
                    onChange={(event, { value }) => onChange("color", value)} />
            </div>
            <div className="setterColumn opacitySetter">
                <MapOverlaysOpacitySelector
                    backgroundColor={color}
                    opacity={opacity}
                    disabled={disabled}
                    onOpacityChange={(value) => onChange("opacity", value)}
                    opacityTextPopup={true}
                />
            </div>
        </div>
     
    </div>);

export default ColorAndOpacitySetter;