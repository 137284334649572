import React from "react";
import { Button, Input } from "semantic-ui-react";
import { ModalBox } from "../../common/ModalBox";
import { TextGroup } from "../../common/TextGroup";
import { DropdownForm } from "../../common/DropdownForm";
import { StoreBoxItem } from "./StoreBoxItem";

export const CreateGroupModal = () =>
{
    return (
        <ModalBox
            className="createGroupModal"
            size="small"
            trigger={<Button primary content="Create Group" style={{margin : "0 10px 10px 0"}}/>}
            header="Create Group"
            actions={<Button primary floated="right" content="Create" />}>
            
            <TextGroup title="Group Name">
                <Input placeholder="Enter Group Name" />
            </TextGroup>
            <TextGroup title="Stores">
                <DropdownForm text="Select Stores" />
            </TextGroup>
            <div className="createGroupStoreCover">
                <StoreBoxItem />
                <StoreBoxItem />
                <StoreBoxItem />
                <StoreBoxItem />
            </div>
        </ModalBox>
    );
}