
export const COLORS_TO_HEX_MAP = {
    Blue: "#0782fa",
    Green: "#00c625",
    Grey: "#babbcb",
    Purple: "#727afa",
    Red: "#e75330",
    Yellow: "#ffb72b",
    "Navy Blue": "#000080"
};

export const ICON_THEMES = {
    LIGHT: "Light",
    DARK: "Dark",
    NONE: "None"
};

export const ICON_COLORS = {
    BLUE: "Blue",
    GREEN: "Green",
    GREY: "Grey",
    PURPLE: "Purple",
    RED: "Red",
    YELLOW: "Yellow",
    NAVY_BLUE: "Navy Blue"
};

export const ICON_TYPES = {
    CATEGORIES: "CategoryIcon",
    ALERTS: "AlertIcon",
    EMERGENCY_ALERTS: "EmergencyIcon",
    KIOSKS: "KioskIcon"
};

export const ICON_TYPE_OPTIONS =[
    {
        i18nKey: "Gallery.Categories",
        value: ICON_TYPES.CATEGORIES,
    },
    {
        i18nKey: "Gallery.Alerts",
        value: ICON_TYPES.ALERTS,
    },
    {
        i18nKey: "Gallery.Emergency_Alerts",
        value: ICON_TYPES.EMERGENCY_ALERTS,
    },
    {
        i18nKey: "Gallery.Kiosk",
        value: ICON_TYPES.KIOSKS,
    },
];


export const ICONS_LIMIT = 40;