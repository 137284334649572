import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import Feature from "ol/Feature";
import { createEntityGeometry, createTextStyle } from "mapsted.maps/mapFunctions/plotting";
import { addLineBreaksToString, createStyle, createStyleOptions, getTextFontOptions } from "mapsted.maps/mapFunctions/mapOverlay";
import { getCenter, getTopRight } from "ol/extent";
import Point from "ol/geom/Point";
import Style from "ol/style/Style";
import View from "ol/View";
import Polygon from "ol/geom/Polygon";

const DYNAMIC_MAP_LAYER_TEXT_FIELDS = ["dataStreamName"];
const AUTOMATION_RULE_TEXT_FILEDS = ["textLabel", "textPopup"];

export const createVectorLayer = (dynamicMapLayerId, automationRuleId, shape, styleFields, textLabel) =>
{
    const { fillColor, borderColor, fillOpacity, borderFillOpacity } = styleFields;
     
    const styleOptions = createStyleOptions(fillColor, borderColor, fillOpacity, borderFillOpacity);

    const geometry = createEntityGeometry(shape);

    const feature = new Feature({
        geometry,
    });

    feature.setId(dynamicMapLayerId);

    const displayTextLabel = addLineBreaksToString(textLabel)[1];

    const textFeature = createTextFeature(dynamicMapLayerId, automationRuleId, shape, displayTextLabel, styleFields.textOpacity, styleFields.textColor);

    let features = [feature, textFeature];

    const vectorSource = new VectorSource({
        features
    });

    const vectorLayer = new VectorLayer({
        source: vectorSource,
        isMapOverlay: true,
        id: dynamicMapLayerId
    });

    vectorLayer.setStyle(createStyle(styleOptions));

    vectorLayer.set("isDynamicMapLayer", true);

    return vectorLayer;
};

export const createTextFeature = (dynamicMapLayerId, automationRuleId, shape, textLabel, textOpacity, textColor) =>
{
    const geometry = createEntityGeometry(shape);
    let textCoordinates = getCenter(geometry.getExtent());

    const textGeometry = new Point(textCoordinates);
    const textFeature = new Feature({
        geometry: textGeometry,
    });

    const textStyleOptions = createStyle({
        stroke: "rgba(0, 0, 0, 0)",
        fill: "rgba(0, 0, 0, 0)",
    });

    const view = new View();

    const textFeatureStyleFunction = (feature, resolution) =>
    {
        const currentZoomLevel = view.getZoomForResolution(resolution).toFixed(2);
        let finalTextOpacity = textOpacity;
        if (currentZoomLevel < 14)
        {
            finalTextOpacity = 0;
        }
        let textFeatureStyle = new Style(textStyleOptions);
        const textFontOptions = getTextFontOptions(finalTextOpacity, undefined, textColor);
        textFeatureStyle.setText(createTextStyle(textLabel, 0, textFontOptions));
        return textFeatureStyle;
    };
    
    textFeature.setStyle(textFeatureStyleFunction);
    textFeature.setId(`${automationRuleId}_text`);
    textFeature.set("isDynamicMapLayerText", true);
    textFeature.set("dynamicMapLayerId", dynamicMapLayerId);
    textFeature.set("automationRuleId", automationRuleId);
 
    return textFeature;
};

export const getTopCenterOfExtent = (extent) =>
{
    const topRight = getTopRight(extent);
    const center = getCenter(extent);

    return [center[0], topRight[1]];
};

export const getCoordinatesAndRadius = (shape) =>
{
    const polygon = new Polygon(shape.coordinates);
    const center = getCenter(polygon.getExtent());
    const x = center[0];
    const y = center[1];

    const r = shape.radius || "";

    return { x, y, r };
};

export const getAllAvailableDynamicMapLayerLangs = (dynamicMapLayer, activeAutomationRule) =>
{
    let langs = new Set();

    // add all the lang codes present in dynamic map layer text fields
    DYNAMIC_MAP_LAYER_TEXT_FIELDS.forEach((field) =>
    {
        Object.keys(dynamicMapLayer[field]).forEach((langCode) => langs.add(langCode));
    });

    // add all the lang codes present in text fields of automation rules of dynamic map layer
    dynamicMapLayer.automationRules.forEach((automationRule) =>
    {
        AUTOMATION_RULE_TEXT_FILEDS.forEach((field) =>
        {
            Object.keys(automationRule[field]).forEach((langCode) => langs.add(langCode));
        });
    });

    // if active automation rule is provided check for lang codes in it
    if (activeAutomationRule)
    {
        AUTOMATION_RULE_TEXT_FILEDS.forEach((field) =>
        {
            Object.keys(activeAutomationRule[field]).forEach((langCode) => langs.add(langCode));
        });  
    }

    return [...langs];
};

export const removeLangCodeFromDynamicMapLayer = (langCode, dynamicMapLayer) =>
{
    DYNAMIC_MAP_LAYER_TEXT_FIELDS.forEach((field) =>
    {
        delete dynamicMapLayer[field][langCode];
    });

    dynamicMapLayer.automationRules.forEach((automationRule) =>
    {
        removeLangCodeFromAutomationRule(langCode, automationRule);
    });

    return dynamicMapLayer;
};

export const removeLangCodeFromAutomationRule = (langCode, automationRule) =>
{
    AUTOMATION_RULE_TEXT_FILEDS.forEach((field) =>
    {
        delete automationRule[field][langCode];
    });

    return automationRule;
};