import React, { useRef, useEffect, useContext, useMemo } from "react";
import { MapButtons } from "./mapButtons/MapButtons";
import { useMapZoom } from "../../branding/map/mapHooks";
import { MapOverlaysContext } from "../../../store/MapOverlaysContext";
import { 
    DEFAULT_LANGUAGE_CODE, 
    MAPOVERLAY_DETAILS_FEATURE_KEY, 
    MAP_OVERLAYS_MODES 
} from "../../../_constants/constants";
import MapOverlaysDrawingComponent from "./mapOverlaysDrawingComponent/MapOverlaysDrawingComponent";
import { changeMapCenterWithBoundaryPolygon } from "mapsted.maps/mapFunctions/interactions";
import MapOverlaysBulkDrawingComponent from "./mapOverlayBulkCreation/MapOverlaysBulksDrawingComponent";
import MapOverlaysBulkConfiguration from "./mapOverlayBulkCreation/MapOverlaysBulkConfiguration";
import { MouseDownInteraction } from "mapsted.maps/utils/interactionTemplates";
import usePolygonHighlight from "../../../hooks/usePolygonHighlight";
import BrandingContext from "../../../store/BrandingContext";
import useMap from "../../../hooks/useMap";

import "ol/ol.css";
import { styleClassic } from "mapsted.maps/utils/defualtStyles";

const PREVIEW_DURATIONS = {
    TIME_INTERVAL: 100,
    ZOOM_CHANGE_STEP: 0.2
};

const SIDE_BAR_OPEN_MODES = [
    MAP_OVERLAYS_MODES.ACTIVITY_SIDEBAR, 
    MAP_OVERLAYS_MODES.CREATE_OVERLAY, 
    MAP_OVERLAYS_MODES.EDIT_OVERLAY,
    MAP_OVERLAYS_MODES.TEMPLATES
];

const PREVIEW_MAX_ZOOM = 24;
const PREVIEW_MIN_ZOOM = 10;

const OverlaysMap = () =>
{
    const mapOverlaysCtx = useContext(MapOverlaysContext);
    const brandingCtx = useContext(BrandingContext);
        
    const mouseInteractionRef = useRef();

    const displayVectorLayers = useRef({});

    const rotationAngle = useMemo(() => 
    {
        if (brandingCtx.state.buildingId)
        {
            const buildingMapRotation = mapOverlaysCtx.state.settingsConfig?.buildingSettings?.[brandingCtx.state.buildingId]?.mapSettings?.mapRotation?.[DEFAULT_LANGUAGE_CODE];

            if (buildingMapRotation || buildingMapRotation === 0)
            {
                return buildingMapRotation;
            }
        }
        return mapOverlaysCtx.state.settingsConfig?.mapRotation?.[DEFAULT_LANGUAGE_CODE] || 0;
    }, [mapOverlaysCtx.state.settingsConfig, brandingCtx.state.buildingId]);

    const tileLayer = useMemo(() =>
    {
        if (brandingCtx.state?.mapData?.tileLayer)
        {
            return brandingCtx.state.mapData.tileLayer;
        }
        else
        {
            return styleClassic.tileLayer;
        }
    }, [brandingCtx.state.mapData?.tileLayer]);

    const { olMap, mapRef, mapController } = useMap({
        mapData: brandingCtx.state.mapData,
        options: { 
            mapOptions: {
                controlsOptions: { rotate: false },
                rotation: rotationAngle,
                tileLayer
            },
            onMapMoveEvent: (event, olMap) => 
            {
                if (olMap)
                {
                    const { currentZoom: ctxZoom } = mapOverlaysCtx.state;
                    const currentZoom = olMap.getView().getZoom().toFixed(2);
        
                    if (ctxZoom !== currentZoom)
                    {
                        mapOverlaysCtx.updateCurrentZoom(currentZoom);
                    }
                }
            } 
        },
    });

    // used to highlight map overlays
    usePolygonHighlight({
        olMap: olMap,
        polygonShape: mapOverlaysCtx.overlayShapeToHighlight,
        onHighlightComplete: () => mapOverlaysCtx.setOverlayShapeToHightlight(undefined)
    });

    // used to higlight entites (similar to map overlay hightlight but different color styles)
    usePolygonHighlight({
        olMap: olMap,
        polygonShape: mapOverlaysCtx.entityShapeToHighlight,
        onHighlightComplete: () => mapOverlaysCtx.setEntityShapeToHighlight(undefined),
        style: {
            fillColor: "#FFFFFF",
            fillOpacity: 0.5,
            strokeColor: "#1663de",
            strokeOpacity: 1
        },
        layerKey: "POLYGON_HIGHLIGHT_LAYER_FLAG_ENTITY"
    });

    useEffect(() =>
    {
        if (mapOverlaysCtx.mapUpdateState.centerOnGeometry && olMap) 
        {
            const [width, height] = olMap.getSize();
            // pad relative to screen size up to a maximum [top, right, bottom, left]
            const padding = [height * 0.1, width * 0.1, height * 0.2, width * 0.1].map((n) => Math.min(n, 290));
            changeMapCenterWithBoundaryPolygon({ olMap: olMap, boundaryPolygon: mapOverlaysCtx.mapUpdateState.centerOnGeometry, padding });
            mapOverlaysCtx.setMapUpdateState({
                centerOnGeometry: undefined
            });
        }
    }, [mapOverlaysCtx.mapUpdateState.centerOnGeometry]);

    useEffect(() => 
    {
        if (mapOverlaysCtx.mapUpdateState.recenter && olMap)
        {
            olMap.getView().setCenter(mapOverlaysCtx.mapUpdateState.recenter);
            mapOverlaysCtx.setMapUpdateState({
                recenter: undefined
            });
        }
    }, [mapOverlaysCtx.mapUpdateState.recenter]);

    useEffect(() =>
    {
        if (mapOverlaysCtx.mapUpdateState.previewOnCenter && olMap)
        {
            const view = olMap.getView();

            let zoomOutComplete = false;
            let zoomInComplete = false;

            view.setCenter(mapOverlaysCtx.mapUpdateState.previewOnCenter);

            const clearPreviewSettings = () =>
            {
                clearInterval(timeIntervalId);
                mapOverlaysCtx.setMapUpdateState({
                    previewOnCenter: undefined
                });
                mapOverlaysCtx.updateMapSharingModeToStartZoom();
                document.removeEventListener("mousedown", clearPreviewSettings, false);
            };

            document.addEventListener("mousedown", clearPreviewSettings, false);

            const timeIntervalId = setInterval(() =>
            {
                const currentZoom = view.getZoom();
                if (!zoomOutComplete)
                {
                    if (currentZoom > PREVIEW_MIN_ZOOM)
                    {
                        view.setZoom(currentZoom - PREVIEW_DURATIONS.ZOOM_CHANGE_STEP);
                        mapOverlaysCtx.updateCurrentZoom(olMap.getView().getZoom().toFixed(2));
                    }
                    else
                    {
                        zoomOutComplete = true;
                    }
                }
                else if (!zoomInComplete)
                {
                    if (currentZoom < PREVIEW_MAX_ZOOM)
                    {
                        view.setZoom(currentZoom + PREVIEW_DURATIONS.ZOOM_CHANGE_STEP);
                        mapOverlaysCtx.updateCurrentZoom(olMap.getView().getZoom().toFixed(2));
                    }
                    else 
                    {
                        zoomInComplete = true;
                    }
                }
                else
                {
                    clearPreviewSettings();
                }
            }, PREVIEW_DURATIONS.TIME_INTERVAL);

        }
    }, [mapOverlaysCtx.mapUpdateState.previewOnCenter]);

    useEffect(() =>
    {
        if (olMap)
        {
            if (displayVectorLayers.current)
            {
                Object.values(displayVectorLayers.current).map((vectoryLayers) =>
                {
                    olMap.removeLayer(vectoryLayers);
                });
            }
    
            if (mapOverlaysCtx.state.displayVectorLayers)
            {
                Object.values(mapOverlaysCtx.state.displayVectorLayers).forEach((vectorLayer) =>
                {
                    olMap.addLayer(vectorLayer);
                });
                
                displayVectorLayers.current = mapOverlaysCtx.state.displayVectorLayers; 
            }
        }

    }, [mapOverlaysCtx.state.displayVectorLayers, olMap]);

    // useEffect(() =>
    // {
    //     if (olMap)
    //     {
    //         const currentZoom = olMap.getView().getZoom().toFixed(2);
    //         if (currentZoom !== mapOverlaysCtx.state.currentZoom && !mapOverlaysCtx.mapUpdateState.previewOnCenter)
    //         {
    //             olMap.getView().setZoom(mapOverlaysCtx.state.currentZoom);
    //         }
    //     }
    // }, [mapOverlaysCtx.state.currentZoom, olMap]);

    useEffect(() =>
    {
        const { clickEditTool } = mapOverlaysCtx.state;
        if (clickEditTool)
        {
            addClickEditInteraction();
        }
        else
        {
            removeClickEditInteraction();
        }
    }, [mapOverlaysCtx.state.clickEditTool]);

    const onMouseDownEvent = (e) =>
    {
        let mapOverlayFeatures = [];

        const layerFilter = (layer) => layer?.get("isMapOverlay");

        olMap.forEachFeatureAtPixel(e.pixel, (feature) =>
        {
            mapOverlayFeatures.push(feature);
        }, { layerFilter });

        // filter out mapoverlays if they don't contain mapOverlayDetails and if they are consumed in dynamic map layers creation
        mapOverlayFeatures = mapOverlayFeatures.filter((mapOverlayFeature) => mapOverlayFeature.get(MAPOVERLAY_DETAILS_FEATURE_KEY))
            .filter((mapOverlayFeature) =>
            {
                const mapOverlayDetails =  mapOverlayFeature.get(MAPOVERLAY_DETAILS_FEATURE_KEY);
                return !mapOverlaysCtx.state.consumedMapOverlays.includes(mapOverlayDetails._id);                  
            });

        if (mapOverlayFeatures.length)
        {
            let mapOverlayFeature;
            
            // sort mapoverlays based on index if more than one is found at a point and select the one with lowest index
            if (mapOverlayFeatures.length > 1)
            {
                mapOverlayFeatures.sort((feature_1, feature_2) =>
                {
                    const index1 = feature_1.get(MAPOVERLAY_DETAILS_FEATURE_KEY).index;
                    const index2 = feature_2.get(MAPOVERLAY_DETAILS_FEATURE_KEY).index;

                    if (index1 < index2)
                    {
                        return -1;
                    }

                    if (index1 > index2)
                    {
                        return 1;
                    }

                    return 0;
                }); 
            }

            mapOverlayFeature = mapOverlayFeatures[0];

            const mapOverlayDetails = mapOverlayFeature.get(MAPOVERLAY_DETAILS_FEATURE_KEY);

            mapOverlaysCtx.editClickHandler(mapOverlayDetails._id);
        }
    };

    const addClickEditInteraction = () =>
    {
        if (olMap)
        {
            const interaction = MouseDownInteraction({ handleDownEvent: onMouseDownEvent });
            olMap.addInteraction(interaction);
            mouseInteractionRef.current = interaction;
        }
    };

    const removeClickEditInteraction = () =>
    {
        if (olMap && mouseInteractionRef.current)
        {
            olMap.removeInteraction(mouseInteractionRef.current);
        }
    };

    const handleDrawComponentSelect = (drawGeometry) =>
    {
        mapOverlaysCtx.createNewPolygon(drawGeometry);
    };

    const { zoomIn, zoomOut } = useMapZoom(olMap);

    const { mode: ctxMode, creationToolStatus } = mapOverlaysCtx.state;
    const { bulkCreationTools } = mapOverlaysCtx.bulkCreationState;
    const isSidebarOpen = SIDE_BAR_OPEN_MODES.includes(ctxMode);

    return (
        <div className="maintenance-map">
            <div className="map-container" ref={mapRef}>
                <MapButtons
                    onZoomIn={zoomIn}
                    onZoomOut={zoomOut}
                    style={isSidebarOpen ? { right: "28rem" } : undefined}
                >
                </MapButtons>
            </div>

            {creationToolStatus && (
                <MapOverlaysDrawingComponent
                    olMap={olMap}
                    mapController={mapController}
                    onSelect={handleDrawComponentSelect}
                />
            )}

            {bulkCreationTools && (
                <MapOverlaysBulkDrawingComponent
                    olMap={olMap}
                    mapController={mapController}
                />
            )}

            <MapOverlaysBulkConfiguration />
        </div>
    );
};

export default OverlaysMap;