import React from "react";
import { ShareGroupModal } from "./ShareGroupModal";
import { ShareGroupLockedModal } from "./ShareGroupLockedModal";
import "./ShareModals.css";
import { StoreGroupsModal } from "./StoreGroupsModal";
import { CreateGroupModal } from "./CreateGroupModal";

export const ExternalShareGroupModals = () => (
    <div style={{ width: "100%", float: "left", padding: "40px 0" }}>
        <ShareGroupModal />
        <ShareGroupLockedModal />
        <StoreGroupsModal />
        <CreateGroupModal />
    </div>
);
