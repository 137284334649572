import React from "react";
import { Button, Modal, ModalContent, ModalHeader } from "semantic-ui-react";
import ImagesRecycleBinMain from "./ImagesRecycleBinMain/ImagesRecycleBinMain";
import "./ImagesRecycleBinModal.css";
import ImagesRecycleBinHeader from "./ImagesRecycleBinHeader/ImagesRecycleBinHeader";
import ImagesRecycleBinProvider from "../contexts/ImagesRecycleBinContext";
import { useImagesBaseContext } from "../contexts/ImageBaseContext";
import { useGmsContext } from "../../GmsContext";

function ImagesRecycleBinModal() 
{
    const gmsContext = useGmsContext();
    const imageBaseContext = useImagesBaseContext();
    return (
        <Modal
            open
            closeIcon={<Button className="close" onClick={gmsContext.handleCloseGms} />}
            className="images-recycle-bin-modal"
        >
            <ModalHeader>
                <Button onClick={imageBaseContext.handleCloseRecycleBin} className="goBack" >
                    <img src="/img/icon-arrow-cms.svg" alt="/" />
                </Button>
                Recycle Bin</ModalHeader>
            <ModalContent>
                <ImagesRecycleBinHeader/>
                <ImagesRecycleBinMain />
            </ModalContent>
        </Modal>
    );
}

function ImagesRecycleBinModalComp()
{
    return <ImagesRecycleBinProvider>
        <ImagesRecycleBinModal></ImagesRecycleBinModal>
    </ImagesRecycleBinProvider>;
}

export default ImagesRecycleBinModalComp;
