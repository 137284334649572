
export const CollectionNameMap = {
    lock: "lock",
    nodes: "nodes",
    steps: "steps",
    entities: "entities",
    transitions: "transitions",
    floorPlan: "floorPlan",
};

export const CollectionList = [
    {
        name: CollectionNameMap.lock,
        options: {
            keyPath: "_id"
        }
    },
    {
        name: CollectionNameMap.nodes,
        options: {
            keyPath: "_id",
        }
    },
    {
        name: CollectionNameMap.entities,
        options: {
            keyPath: "_id",
        }
    },
    {
        name: CollectionNameMap.steps,
        options: {
            keyPath: "_id"
        }
    },
    {
        name: CollectionNameMap.transitions,
        options: {
            keyPath: "_id"
        }
    },
    {
        name: CollectionNameMap.floorPlan,
        options: {
            keyPath: "_id"
        }
    }
];
