import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";

import "semantic-ui-css/semantic.min.css";
// import 'react-semantic-toasts/styles/react-semantic-alert.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

import "./index.css";
import "./manage.ui.css";
import "./manage.ui.responsive.css";
import "./_intl/i18n";

import "./branding.css";

import "./DataTable.css";

import "./mapsted-version-3.4.css";

import App from "./components/App";

console.log("%cStop!", "color:red;font-size:50px;text-shadow:1px 1px black;");
console.log("%cThis is a browser feature intended for developers. If someone told you to copy-paste something here, it is a scam.", "font-size:20px;");

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
