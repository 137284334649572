import React from "react";
import "./EmptyGallery.css";
import { useTranslation } from "react-i18next";

function EmptyGallery({ children }) 
{
    const trans = useTranslation().t;
    return (
        <div className="empty-gallery-container">
            <img
                className="empty-gallery-image"
                src="/img/icon-no-image.svg"
                alt="not-found"
            />
            <div>{children || trans("UploadImageModal.No_Images_Uploaded")}</div>
        </div>
    );
}

export default EmptyGallery;
