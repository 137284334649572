import React from "react";
import { Button } from "semantic-ui-react";
import { ModalBox } from "../../common/ModalBox";
import { useTranslation } from "react-i18next";

export const ConfirmCloseActionModal = ({ onConfirm, ...rest }) =>
{
    const trans = useTranslation().t;
    
    return (
        <ModalBox
            className="confirmActionModal deleteKioskModal"
            header={trans("Cancel_Confirmation_Modal.Confirm_Action")}
            actions={<Button color="orange" floated="right" content={trans("Cancel_Confirmation_Modal.Yes")} onClick={onConfirm} />}
            {...rest}
        >
            <p className="p-modal">{trans("Cancel_Confirmation_Modal.Discard_Changes")}s</p>
        </ModalBox>
    );
};
