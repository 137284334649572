import React, { createContext, useContext, useEffect, useState } from "react";
import { useSetState } from "ahooks";
import BrandingContext from "../store/BrandingContext";
import { getCenter } from "ol/extent";
import cloneDeep from "lodash.clonedeep";
import _ from "lodash";
import {
    MAP_OVERLAY_ZOOM_CONSTANTS,
    MAP_OVERLAY_NEW_OVERLAY_FEATURE_ID,
    DEFAULT_LANGUAGE_CODE,
    NEW_OVERLAY_DEFAULT,
    MAP_OVERLAYS_MODES,
    MAP_OVERLAY_DEFAULT_DYNAMIC_SETTINGS,
    MAINTENANCE_MODES,
    DEFAULT_MAP_OVERLAY_BULK_CREATION_STATE,
    BULK_MAP_OVERLAY_COPY_FROM_TEMPLATE_FIELDS,
    MAP_OVERLAY_DEFAULT_ZOOM_LEVEL_SETTINGS,
    MAP_OVERLAY_TEXT_COLOR_DEFAULT,
    CACHED_MAPOVERLAY_LOCAL_STORAGE_KEY,
    RESTORE_QUERY_KEY,
    FIELDS_TO_HEX_CONVERT,
} from "../_constants/constants";
import {
    getShape,
    getCenterOfVectorLayer,
    createMapOverlayLayers,
    getShapeFromGeometry,
    getMapOverlayIdToIndexMap,
    extractOverlayInfoFromTemplateId,
    mapOverlayToDefaultVal
} from "../components/maintenance/mapOverlays/utils/mapOverlayUtils";
import serverAPI from "../_api/server.api";
import validateMapOverlay from "../components/maintenance/mapOverlays/utils/mapOverlayValidation";
import { MaintenanceContext } from "./MaintenanceContext";
import { getMobileAppDetails } from "../_utils/utils";
import {
    createActiveMapOverlayTextStyleFunction,
    createActiveMapOverlayStyle,
    createMapOverlayTextStyleFunction,
    createStyleFunction
} from "mapsted.maps/mapFunctions/mapOverlay";
import {
    extractLanguageCodeFromMapOverlay,
} from "mapsted.maps/utils/publicMetaData";
import isEqual from "lodash.isequal";
import { v4 as uuid } from "uuid";
import { MAPOVERLAY_DETAILS_FEATURE_KEY } from "../_constants/constants";
import { useHistory, useLocation, withRouter } from "react-router";
import tinycolor from "tinycolor2";
import { toast } from "react-toastify";
import { truncateText } from "../_utils/mapUtils";
import { Trans } from "react-i18next";
import { useOverlaysTemplateContext } from "./OverlaysTemplateContext";

export const MapOverlaysContext = createContext({});

export const MapOverlaysProvider = withRouter(({ children }) =>
{
    const { loadingPool, updateMode: updateMaintenanceMode, state: maintenanceCtxState } = useContext(MaintenanceContext);
    const brandingCtx = useContext(BrandingContext);
    const { propertyId, buildingId =-1, floorId =-1, properties, settingsConfig } = brandingCtx.state;
    const { getCSVFileName } = brandingCtx;
    const [selectedOverlayTemplateId, setSelectedOverlayTemplateId] = useState(null);

    const { overlayTemplates } = useOverlaysTemplateContext();

    const { search } = useLocation();
    const history = useHistory();

    const [state, setState] = useSetState({
        mode: undefined,
        mapOverlays: [],
        mapOverlaysStatus: "uninitialized",
        displayVectorLayers: {},
        creationToolStatus: false,
        currentZoom: MAP_OVERLAY_ZOOM_CONSTANTS.MIN_ZOOM,
        activeOverlay: {},
        validationErrors: [],
        recentlyWorkedOverlayDetails: {},
        mapSharingMode: undefined,
        selectedMapOverlayIds: [],
        appDetails: {},
        activeOverlayModified: false, // Flag to specify if changes were made in edit mode or not. Make sure to set in all the places active overlay is updated and unset it once saved/cancelled.
        consumedMapOverlays: [], // Array of mapOverlays used in dynamic map layer creation
        displayLang: DEFAULT_LANGUAGE_CODE, // Used in map overlay creation and edititing modules,
        allowedLangs: [DEFAULT_LANGUAGE_CODE],
        isOverlaysTemplateSideBar: false,

        isActivitySideBar: false,
        clickEditTool: false, // flag to indicate whether edit on click is enabled or not
    });

    // State fields to update overlay map behaviour such recentering on a polygon or creating preview animation
    const [mapUpdateState, setMapUpdateState] = useSetState({
        recenter: undefined,
        previewOnCenter: undefined,
        centerOnGeometry: undefined
    });

    // State fields used in bulk map overlay creation mode
    const [bulkCreationState, setBulkCreationState] = useSetState({
        bulkCreationIndex: 0,
        bulkMapOverlays: [],
        bulkCreationTools: false,
        bulkCreationModal: false,
        templateMapOverlay: undefined,
        errorMessage: "",
    });

    const [dynamicMapLayerCreationState, setDyamicMapLayerCreationState] = useSetState({
        enabled: false,
        newlyCreatedMapOverlayId: undefined
    });

    // state field to handle map overlay blink and hightlight feature
    const [overlayShapeToHighlight, setOverlayShapeToHightlight] = useState(undefined);

    // state field to handle entity blink and hightlight feature
    const [entityShapeToHighlight, setEntityShapeToHighlight] = useState(undefined);
 
    useEffect(() => 
    {
        if (propertyId) 
        {
            // fetchOverlayTemplates({ propertyId });
            setState({ isOverlaysTemplateSideBar: false });
            setState({ isActivitySideBar: false });
        }
    }, [propertyId]);

    useEffect(() =>
    {
        if (propertyId && buildingId && floorId)
        {
            getOverlaysForCurrentFloor();
        }
        else
        {
            setState({
                displayVectorLayers: {}
            });
        }

        let allowedLangs = [DEFAULT_LANGUAGE_CODE];

        // if property or building is changed update allowedLang array
        if (buildingId && buildingId !== -1 && propertyId)
        {
            allowedLangs = getAllowedLangsFromBuilding(buildingId, propertyId);
        }
        else if (propertyId)
        {
            allowedLangs = getAllowedLangsFromPropertyId(propertyId);
        }

        setState({
            selectedMapOverlayIds: [],
            mode: undefined,
            activeOverlay: {},
            allowedLangs
        });

    }, [propertyId, buildingId, floorId]);


    useEffect(() =>
    {
        if (propertyId && settingsConfig)
        {
            getPropertyPublicSettings(propertyId, settingsConfig);
        }
    }, [propertyId, settingsConfig]);

    useEffect(() =>
    {
        if (!mapUpdateState.previewOnCenter)
        {
            updateActiveOverlayLayer(state.activeOverlay, state.mapSharingMode, state.displayLang);
        }
    }, [state.mapSharingMode]);

    useEffect(() =>
    {
        if (maintenanceCtxState?.restoreMapOverlays && state.mapOverlays.length)
        {
            restorePreviousMapOverlay();
        }
    }, [maintenanceCtxState?.restoreMapOverlays, state.mapOverlays]);


    useEffect(() => 
    {
        if (state.mode === MAP_OVERLAYS_MODES.TEMPLATES) 
        {
            setState({
                mode: MAP_OVERLAYS_MODES.TEMPLATES,
            });
        }
    }, [floorId]);


    const handleOverlayTemplateSelection = (id) => 
    {
        setSelectedOverlayTemplateId(id);
        if (!id)
        {
            const newOverlay = { ...state.activeOverlay, ..._ .omit(NEW_OVERLAY_DEFAULT, ["vectorLayer", "name", "toolTipText", "_id", "id"]), templateId: "", templateOverwrites: {} };
            const { updatedActiveOverlay, updatedMapSharingMode } = prepareMapOverlayForEdit(newOverlay);
            setState({
                activeOverlay: updatedActiveOverlay,
                mapSharingMode: updatedMapSharingMode,
                activeOverlayModified: true
            });
        }
        else if (!_.isEmpty(state.activeOverlay))
        {
            const newOverlay = extractOverlayInfoFromTemplateId({ overlay: { ..._.omit(state.activeOverlay, "vectorLayer"), templateId: id }, overlayTemplates });
            newOverlay.vectorLayer = state.activeOverlay.vectorLayer;
            const { updatedActiveOverlay, updatedMapSharingMode } = prepareMapOverlayForEdit(newOverlay);

            setState({
                activeOverlay: updatedActiveOverlay,
                mapSharingMode: updatedMapSharingMode,
                activeOverlayModified: true 
            });
        }
        else 
        {
            let newOverlay = extractOverlayInfoFromTemplateId({ overlay: { ...NEW_OVERLAY_DEFAULT, templateId: id }, overlayTemplates });
            newOverlay?.dynamicOverlaySettings?.zoomLevels.map((zoomLevel) => ({ ...zoomLevel, name: { DEFAULT_LANGUAGE_CODE: "" } }));
            setState({ mapSharingMode: newOverlay?.dynamicOverlaySettings?.zoomLevels[0]?.id, activeOverlay: newOverlay, activeOverlayModified: true });
        } 
    };

    const restorePreviousMapOverlay = () =>
    {
        // if map overlay is to be restored, check cachedMapoverlay in local storage to get the overlay info
        const cachedMapOverlayString = localStorage.getItem(CACHED_MAPOVERLAY_LOCAL_STORAGE_KEY);

        if (cachedMapOverlayString)
        {
            const cachedMapOverlay = JSON.parse(cachedMapOverlayString);

            // get the vector layer object from state
            let selectedMapOverlay = state.mapOverlays.find((mapOverlay) => mapOverlay._id === cachedMapOverlay._id);
            cachedMapOverlay.vectorLayer = selectedMapOverlay.vectorLayer;
            const { updatedActiveOverlay, updatedMapSharingMode } = prepareMapOverlayForEdit(cachedMapOverlay);

            setState({
                mode: MAP_OVERLAYS_MODES.EDIT_OVERLAY,
                activeOverlay: updatedActiveOverlay,
                mapSharingMode: updatedMapSharingMode,
                clickEditTool: false,
            });

            // clear cachedMapOverlay from localstorage and reset url
            localStorage.removeItem(CACHED_MAPOVERLAY_LOCAL_STORAGE_KEY);
            clearMapOverlayRestoreSearch();
        }
    };

    const clearMapOverlayRestoreSearch = () =>
    {
        if (search && history)
        {
            const searchParams = new URLSearchParams(search);
            searchParams.delete(RESTORE_QUERY_KEY);
            history.replace({ search: searchParams.toString() });
        }
    };

    const getPropertyPublicSettings = async (propertyId, publicSettings) =>
    {
        const loadingId = loadingPool.add();
        try
        {
            const config = await serverAPI.fetchConfig();
            // const publicSettings = await serverAPI.fetchPropertySettings(propertyId);

            const mapstedApps = await serverAPI.fetchMapstedAppList();

            const mapBaseProd = config?.MAPSTED_MAPS_URL_MAIN;


            const mapUrlProd = mapBaseProd && publicSettings?.publicSubdomain && `${mapBaseProd}/${publicSettings?.publicSubdomain}`;

            const { androidAppName, iosAppName, androidLink, iosLink } = getMobileAppDetails(mapstedApps, publicSettings);


            setState({
                appDetails: {
                    mapUrlProd,
                    androidAppName,
                    iosAppName,
                    androidLink,
                    iosLink
                },
                settingsConfig: publicSettings
            });
        }
        catch (error)
        {
            console.log(error);
        }
        finally
        {
            loadingPool.remove(loadingId);
        }
    };

    const updateMode = (mode) =>
    {
        if (mode !== state.mode)
        {
            setState({ mode, selectedMapOverlayIds: [], clickEditTool: false });
        }
        else
        {
            setState({ mode: undefined, selectedMapOverlayIds: [], clickEditTool: false });
        }
    };

    const updateMapOverLaysIds=async (mapOverlays) =>
    {
        const mapOverlaysUpdated=mapOverlays.map((overlay) => createOverlayObject(overlay,false));
        const loadingId = loadingPool.add();
        try
        {
            let response= await serverAPI.reorderMultipleOverlays(mapOverlaysUpdated);
            if (!response.success)
            {
                throw new Error("Server Error");
            }
            reorderOverLays(mapOverlays);

        }
        catch (err)
        {
            console.log(err.message);
        }
        finally
        {
            loadingPool.remove(loadingId);
        }
    };

    const reorderOverLays=(value) =>
    {

        let updatedMapOverlays = [];
        let mapOverlays=value;
        const mapOverlayLayers = createMapOverlayLayers(cloneDeep(mapOverlays));

        const mapOverlayLayerMap = getMapOverlayLayerMapById(mapOverlayLayers);
        mapOverlays.forEach((mapOverlay) =>
        {
            updatedMapOverlays.push({
                ...mapOverlay,
                vectorLayer: mapOverlayLayerMap[mapOverlay._id]
            });
        });

        setState({
            mapOverlays: updatedMapOverlays,
            displayVectorLayers: mapOverlayLayerMap
        });

    };

    const getOverlaysForCurrentFloor = () =>
    {
        if (propertyId && buildingId && floorId)
        {
            setState({ mapOverlaysStatus: "loading" });
            serverAPI.getMapOverlays(propertyId, buildingId, floorId)
                .then((result) =>
                {
                    if (result.success)
                    {

                        let updatedMapOverlays = [];
                        let { mapOverlays } = result;
                        mapOverlays = mapOverlays.map((overlay) => extractOverlayInfoFromTemplateId({ overlay, overlayTemplates }));

                        const mapOverlayLayers = createMapOverlayLayers(cloneDeep(mapOverlays));

                        const mapOverlayLayerMap = getMapOverlayLayerMapById(mapOverlayLayers);

                        mapOverlays.forEach((mapOverlay) =>
                        {
                            updatedMapOverlays.push({
                                ...mapOverlay,
                                vectorLayer: mapOverlayLayerMap[mapOverlay._id]
                            });
                        });

                        setState({
                            mapOverlays: updatedMapOverlays,
                            mapOverlaysStatus: "ready",
                            displayVectorLayers: mapOverlayLayerMap
                        });
                    }
                })
                .catch((err) => 
                {
                    setState({
                        mapOverlaysStatus: "failed",
                    });
                    console.log("error", err);
                });
        }
    };

    const getMapOverlayLayerMapById = (mapOverlayLayers) =>
    {
        let mapOverlaysMap = {};

        mapOverlayLayers.forEach((mapOverlayLayer) =>
        {
            if (mapOverlayLayer.values_?.id)
            {
                mapOverlaysMap[mapOverlayLayer.values_.id] = mapOverlayLayer;
            }
        });

        return mapOverlaysMap;
    };

    const initiateCreation = () =>
    {
        let activeOverlay = { ...state.activeOverlay };

        if (!Object.keys(activeOverlay).length)
        {
            activeOverlay = { ...NEW_OVERLAY_DEFAULT };

            updateActiveOverlay(activeOverlay);

            setState({
                mode: MAP_OVERLAYS_MODES.CREATE_OVERLAY,
                creationToolStatus: true,
                activeOverlayModified: false,
                clickEditTool: false
            });
        }
    };

    const createNewPolygon = (polygonGeometry) =>
    {
        if (polygonGeometry)
        {

            const shape = getShapeFromGeometry(polygonGeometry);

            let activeOverlay = { ...state.activeOverlay };

            if (!Object.keys(activeOverlay).length)
            {
                activeOverlay = { ...NEW_OVERLAY_DEFAULT };
            }

            activeOverlay.shape = shape;

            const newMapOverlayLayer = createMapOverlayLayers([cloneDeep(activeOverlay)])[0];

            let updatedDisplayVectorLayers = { ...state.displayVectorLayers };
            updatedDisplayVectorLayers[activeOverlay._id] = newMapOverlayLayer;

            activeOverlay.vectorLayer = newMapOverlayLayer;

            updateActiveOverlay(activeOverlay);

            setState({
                displayVectorLayers: updatedDisplayVectorLayers,
                creationToolStatus: false,
                activeOverlayModified: true
            });
        }
    };

    const clearPolygon = () =>
    {
        let updatedDisplayVectorLayers = { ...state.displayVectorLayers };
        const activeOverlay = { ...state.activeOverlay };

        if (Object.keys(activeOverlay).length)
        {
            activeOverlay.vectorLayer = undefined;
        }

        delete updatedDisplayVectorLayers[activeOverlay._id];

        setState({
            displayVectorLayers: updatedDisplayVectorLayers,
            activeOverlay,
            validationErrors: [],
            activeOverlayModified: true
        });
    };

    const cancelCreationHandler = () =>
    {
        const { activeOverlay, mode, mapOverlays } = state;
        let updatedDisplayVectorLayers = { ...state.displayVectorLayers };
        let updatedMapOverlays = [...state.mapOverlays];

        if (mode === MAP_OVERLAYS_MODES.CREATE_OVERLAY)
        {
            delete updatedDisplayVectorLayers[activeOverlay._id];
        }
        else if (mode === MAP_OVERLAYS_MODES.EDIT_OVERLAY)
        {
            // recreate vector layer from overlay list
            const selectedMapOverlay = mapOverlays.find((mapOverlay) => mapOverlay._id === activeOverlay._id);

            const newVectorLayer = createMapOverlayLayers([cloneDeep(selectedMapOverlay)])[0];

            updatedDisplayVectorLayers[activeOverlay._id] = newVectorLayer;
            const selectedMapOverlayIndex = updatedMapOverlays.findIndex((mapOverlay) => mapOverlay._id === activeOverlay._id);
            updatedMapOverlays[selectedMapOverlayIndex].vectorLayer = newVectorLayer;
        }

        setSelectedOverlayTemplateId(null);
        setState({
            mode: undefined,
            displayVectorLayers: updatedDisplayVectorLayers,
            activeOverlay: {},
            validationErrors: [],
            mapOverlays: updatedMapOverlays,
            activeOverlayModified: false,
            creationToolStatus: false,
            displayLang: DEFAULT_LANGUAGE_CODE,
        });

        if (dynamicMapLayerCreationState.enabled)
        {
            setDyamicMapLayerCreationState({
                enabled: false,
                newlyCreatedMapOverlayId: undefined
            });

            updateMaintenanceMode(MAINTENANCE_MODES.DYNAMIC_MAP_LAYERS);
        }
    };

    const addNewZoomLevel = (index) =>
    {
        let updatedDynamicOverlaySettings = { ...state.activeOverlay.dynamicOverlaySettings };
        const zoomLevels =  updatedDynamicOverlaySettings.zoomLevels;
        zoomLevels.splice(index, 0, { ...cloneDeep(MAP_OVERLAY_DEFAULT_ZOOM_LEVEL_SETTINGS), id: uuid() });
        validateAndUpdateDynamicOverlaySettings(updatedDynamicOverlaySettings);
        setState({ activeOverlayModified: true });
    };

    const removeZoomLevel = (zoomId) =>
    {
        let updatedDynamicOverlaySettings = { ...state.activeOverlay.dynamicOverlaySettings };
        const zoomLevels =  updatedDynamicOverlaySettings.zoomLevels;
        const zoomIndex = zoomLevels.findIndex((zoomLevel) => zoomLevel.id === zoomId);
        zoomLevels.splice(zoomIndex, 1);
        validateAndUpdateDynamicOverlaySettings(updatedDynamicOverlaySettings);
    };

    const updateMapSharingModeToStartZoom = () =>
    {
        if (state.activeOverlay?.dynamicOverlaySettings?.enabled)
        {
            const { dynamicOverlaySettings } = state.activeOverlay;
            const startZoom = dynamicOverlaySettings.zoomLevels[0];
            setState({
                mapSharingMode: startZoom.id
            });
        }
    };

    const updateEnableDynamicOverlayFlag = (checked) =>
    {
        let updatedDynamicOverlaySettings = { ...state.activeOverlay.dynamicOverlaySettings };
        updatedDynamicOverlaySettings.enabled = checked;
        validateAndUpdateDynamicOverlaySettings(updatedDynamicOverlaySettings);
        setState({ activeOverlayModified: true });
    };

    const updateDynamicOverlaySettings = (zoomId, key, value) =>
    {
        let updatedDynamicOverlaySettings = { ...state.activeOverlay.dynamicOverlaySettings };
        const selectedZoomLevel = updatedDynamicOverlaySettings.zoomLevels.find((zoomLevel) => zoomLevel.id === zoomId);
        selectedZoomLevel[key] = value;


        // if overrideGlobalTextLabel is unchecked clear zoom level text label
        if (key === "overrideGlobalTextLabel" && !value)
        {
            selectedZoomLevel["textLabel"] = { [DEFAULT_LANGUAGE_CODE]: "" };
        }

        validateAndUpdateDynamicOverlaySettings(updatedDynamicOverlaySettings);

        setState({ activeOverlayModified: true });
    };

    const validateAndUpdateDynamicOverlaySettings = (dynamicOverlaySettings) =>
    {
        let updatedDynamicOverlaySettings = { ...dynamicOverlaySettings };
        let updatedNewOverlay = { ...state.activeOverlay };
        const { activeOverlay, currentZoom } = state;
        const { dynamicOverlaySettings: currentDynamicOverlaySettings } = activeOverlay;

        // If checkbox is unticked copy startZoom settings to default
        if (!updatedDynamicOverlaySettings.enabled && currentDynamicOverlaySettings.enabled)
        {
            const startZoom = updatedDynamicOverlaySettings.zoomLevels[0];
            updatedNewOverlay.defaultFillOpacity = startZoom.fillOpacity;
            updatedNewOverlay.defaultBorderFillOpacity = startZoom.borderFillOpacity;
            updatedNewOverlay.defaultTextOpacity = startZoom.textOpacity;

            updatedDynamicOverlaySettings = cloneDeep(MAP_OVERLAY_DEFAULT_DYNAMIC_SETTINGS);
        }
        // If checkbox is ticked for the first time copy default settings to startZoom
        else if (updatedDynamicOverlaySettings.enabled && !currentDynamicOverlaySettings.enabled)
        {
            const zoomLevels = [
                { ...cloneDeep(MAP_OVERLAY_DEFAULT_ZOOM_LEVEL_SETTINGS), id: uuid() },
                { ...cloneDeep(MAP_OVERLAY_DEFAULT_ZOOM_LEVEL_SETTINGS), id: uuid() }
            ];

            const startZoom = {
                value: +currentZoom,
                fillOpacity: activeOverlay.defaultFillOpacity,
                borderFillOpacity: activeOverlay.defaultBorderFillOpacity,
                textOpacity: activeOverlay.defaultTextOpacity
            };

            zoomLevels[0] = { ...zoomLevels[0], ...startZoom };

            updatedDynamicOverlaySettings.zoomLevels = zoomLevels;

            updateMapSharingMode(zoomLevels[0].id);
        }

        updatedNewOverlay.dynamicOverlaySettings = updatedDynamicOverlaySettings;

        updateActiveOverlay(updatedNewOverlay);
    };

    const updateCreationToolStatus = (status) => setState({ creationToolStatus: status });

    const updateCurrentZoom = (newZoom) =>
    {
        let updatedState = {
            currentZoom: newZoom
        };

        setState({
            ...updatedState
        });
    };

    const updateActiveOverlay = (overlay) =>
    {
        updateActiveOverlayLayer(overlay, state.mapSharingMode, state.displayLang);
        setState({
            activeOverlay: overlay,
            validationErrors: []
        });
    };

    const updateActiveOverlayLayer = (activeOverlay, mapSharingMode, lang) =>
    {
        const { vectorLayer } = activeOverlay;

        if (vectorLayer)
        {
            const languageExtractedActiveOverlay = extractLanguageCodeFromMapOverlay(cloneDeep(activeOverlay), lang);
            const newStyle = createActiveMapOverlayStyle(languageExtractedActiveOverlay, mapSharingMode);
            const newTextStyle = createActiveMapOverlayTextStyleFunction(languageExtractedActiveOverlay, mapSharingMode);

            const textFeature = vectorLayer.getSource().getFeatureById(`${languageExtractedActiveOverlay._id}_text`);

            vectorLayer && vectorLayer.setStyle(newStyle);
            textFeature && textFeature.setStyle(newTextStyle);
        }
    };

    const saveDraftHandler = async () =>
    {
        const { mode, activeOverlay } = state;

        if (mode === MAP_OVERLAYS_MODES.CREATE_OVERLAY)
        {
            const result = await createNewOverlay(activeOverlay);

            if (result.success)
            {
                const { updatedMapOverlays, updatedActiveOverlay, updatedDisplayVectors } = getUpdatedStateWithNewOverlay(result.createdMapOverlay);
                setState({
                    activeOverlay: updatedActiveOverlay,
                    displayVectorLayers: updatedDisplayVectors,
                    recentlyWorkedOverlayDetails: {
                        mode: MAP_OVERLAYS_MODES.CREATE_OVERLAY,
                        name: updatedActiveOverlay.name[DEFAULT_LANGUAGE_CODE]
                    },
                    mapOverlays: updatedMapOverlays,
                    activeOverlayModified: false,
                    displayLang: DEFAULT_LANGUAGE_CODE,
                });

                if (dynamicMapLayerCreationState.enabled)
                {
                    setDyamicMapLayerCreationState({
                        newlyCreatedMapOverlayId: updatedActiveOverlay._id
                    });
                
                    updateMaintenanceMode(MAINTENANCE_MODES.DYNAMIC_MAP_LAYERS);
                }
                    
                toast.success(
                    <>
                        <Trans 
                            i18nKey="CreateMapOverlaysSideBar.Create_Overlay_Success"
                            values={{ overlayName: truncateText(`${result.createdMapOverlay.name[DEFAULT_LANGUAGE_CODE]}`, 22) }}
                        />
                    </>
                );
            }
            return result;
        }
        else if (mode === MAP_OVERLAYS_MODES.EDIT_OVERLAY)
        {
            const result = await editOverlay(activeOverlay);
            if (result.success)
            {
                const { updatedDisplayVectors } = getUpdatedStateWithNewOverlay(result.updatedMapOverlay);
                const { updatedMapOverlays } = getUpdateStateWithEditedOverlay(result.updatedMapOverlay);

                setState({
                    displayVectorLayers: updatedDisplayVectors,
                    recentlyWorkedOverlayDetails: {
                        mode: MAP_OVERLAYS_MODES.EDIT_OVERLAY,
                        name: result.updatedMapOverlay.name[DEFAULT_LANGUAGE_CODE]
                    },
                    mapOverlays: updatedMapOverlays,
                    activeOverlayModified: false,
                    displayLang: DEFAULT_LANGUAGE_CODE,
                });
                toast.success(
                    <>
                        <Trans 
                            i18nKey="CreateMapOverlaysSideBar.Update_Overlay_Success"
                            values={{ overlayName: truncateText(`${result.updatedMapOverlay.name[DEFAULT_LANGUAGE_CODE]}`, 22) }}
                        />
                    </>
                );

            }
            return result;
        }
    };

    const editOverlay = async (mapOverlay) =>
    {
        let editedMapOverlay = createOverlayObject(mapOverlay);
        if (selectedOverlayTemplateId) 
        {
            editedMapOverlay = mapOverlayToDefaultVal({ overlay: editedMapOverlay, selectedOverlayTemplateId });
        }
        const mapOverlayId = mapOverlay._id;
        const loadingId = loadingPool.add();
        try
        {
            const result = await serverAPI.updateMapOverlay(mapOverlayId, editedMapOverlay);
            if (!result?.success)
            {
                return { success: false };
            }
            let { updatedMapOverlay } = result;
            if (selectedOverlayTemplateId)
            {
                updatedMapOverlay = extractOverlayInfoFromTemplateId({ overlay: updatedMapOverlay, overlayTemplates });
            }
            return { success: true, updatedMapOverlay };
        }
        catch (error)
        {
            return { success: false };
        }
        finally
        {
            loadingPool.remove(loadingId);
        }
    };

    const getUpdateStateWithEditedOverlay = (editedOverlay) =>
    {
        const updatedMapOverlays = [...state.mapOverlays];

        let selectedMapOverlayIndex = updatedMapOverlays.findIndex((overlay) => overlay._id === editedOverlay._id);

        updatedMapOverlays[selectedMapOverlayIndex] = { ...updatedMapOverlays[selectedMapOverlayIndex], ...editedOverlay };

        // update style of vectorlayer so that it behaves like a non active overlay (mainly changes in opacity with zoom)
        updateMapOverlayLayerToNonActiveState(updatedMapOverlays[selectedMapOverlayIndex]);

        return { updatedMapOverlays };
    };

    const updateMapOverlayLayerToNonActiveState = (mapOverlay, lang=DEFAULT_LANGUAGE_CODE) =>
    {
        const { vectorLayer } = mapOverlay;

        const textFeature = vectorLayer.getSource().getFeatureById(`${mapOverlay._id}_text`);

        const languageExtractedOverlay = extractLanguageCodeFromMapOverlay(cloneDeep(mapOverlay), lang);

        const newTextStyleFunction = createMapOverlayTextStyleFunction(languageExtractedOverlay);
        const newStyleFunction = createStyleFunction(languageExtractedOverlay);

        vectorLayer && vectorLayer.setStyle(newStyleFunction);
        textFeature && textFeature.setStyle(newTextStyleFunction);
    };

    const createNewOverlay = async (mapOverlay) =>
    {
        let newOverlay = createOverlayObject({ ...mapOverlay, index: state.mapOverlays.length });
        if (selectedOverlayTemplateId) 
        {
            newOverlay = mapOverlayToDefaultVal({ overlay: newOverlay, selectedOverlayTemplateId });
        }
        const loadingId = loadingPool.add();
        try
        {
            const result = await serverAPI.createMapOverlay(newOverlay);
            if (!result?.success)
            {
                return { success: false };
            }
            const { createdMapOverlay } = result;
            return {
                success: true, 
                createdMapOverlay: selectedOverlayTemplateId ? extractOverlayInfoFromTemplateId({ overlay: createdMapOverlay, overlayTemplates }) : createdMapOverlay 
            };
        }
        catch (error)
        {
            return { success: false };
        }
        finally
        {
            loadingPool.remove(loadingId);
        }
    };

    const getUpdatedStateWithNewOverlay = (newOverlay) =>
    {
        const newId = newOverlay._id;

        let updatedActiveOverlay = { ...state.activeOverlay };
        let updatedDisplayVectors = { ...state.displayVectorLayers };

        updatedActiveOverlay._id = newId;

        const vectorLayer = updatedActiveOverlay.vectorLayer;

        const features = vectorLayer.getSource().getFeatures();

        features.forEach((feature) =>
        {
            if (feature.getId() === MAP_OVERLAY_NEW_OVERLAY_FEATURE_ID)
            {
                feature.setId(newId);
                feature.set(MAPOVERLAY_DETAILS_FEATURE_KEY, newOverlay);
            }
            else if (feature.getId() === `${MAP_OVERLAY_NEW_OVERLAY_FEATURE_ID}_text`)
            {
                feature.setId(`${newId}_text`);
            }
        });

        newOverlay.vectorLayer = vectorLayer;
        updateMapOverlayLayerToNonActiveState(newOverlay);

        let updatedMapOverlays = [...state.mapOverlays ];
        updatedMapOverlays.push({
            ...newOverlay
        });

        updatedDisplayVectors[newId] = vectorLayer;
        delete updatedDisplayVectors[MAP_OVERLAY_NEW_OVERLAY_FEATURE_ID];

        return { updatedMapOverlays, updatedActiveOverlay, updatedDisplayVectors };
    };


    const confirmationDoneHandler = () =>
    {
        setSelectedOverlayTemplateId(null);
        setState({
            activeOverlay: {},
            mode: undefined,
            recentlyWorkedOverlayDetails: {}
        });
    };

    const createOverlayObject = (mapOverlay, deleteId=true) =>
    {
        const { vectorLayer } = mapOverlay;

        let newOverlayObject = { ...mapOverlay, propertyId, buildingId, floorId };

        const features = vectorLayer.getSource().getFeatures();

        const polygonFeature = features.find((feature) => feature.getId() === mapOverlay._id);

        const shape = getShape(polygonFeature);

        newOverlayObject.shape = shape;

        if (newOverlayObject.dynamicOverlaySettings?.enabled)
        {
            const { fillOpacity, borderFillOpacity, textOpacity } = newOverlayObject.dynamicOverlaySettings.zoomLevels[0];
            newOverlayObject.defaultFillOpacity = fillOpacity;
            newOverlayObject.defaultBorderFillOpacity = borderFillOpacity;
            newOverlayObject.defaultTextOpacity = textOpacity;

            // delete ids from zoomLevels
            newOverlayObject.dynamicOverlaySettings.zoomLevels.forEach((zoomLevel) =>
            {
                delete zoomLevel.id;
            });
        }
        else
        {
            // clear zoomLevels if dynamic settings is disabled
            newOverlayObject.dynamicOverlaySettings.zoomLevels = [];
        }

        if (deleteId)
        {
            delete newOverlayObject._id;
        }
        delete newOverlayObject.vectorLayer;

        return newOverlayObject;
    };

    const validateActiveMapOverlay = (trans) =>
    {
        const { activeOverlay } = state;

        const validationErrors = validateMapOverlay(activeOverlay, trans);

        if (validationErrors.length)
        {
            setState({
                validationErrors
            });
            return { success: false };
        }
        else
        {
            return { success: true };
        }
    };

    const editClickHandler = (id) =>
    {
        let selectedMapOverlay = state.mapOverlays.find((mapOverlay) => mapOverlay._id === id);

        const { updatedActiveOverlay, updatedMapSharingMode } = prepareMapOverlayForEdit(selectedMapOverlay);
        if (selectedMapOverlay.templateId)
        {
            setSelectedOverlayTemplateId(updatedActiveOverlay.templateId);
        }
        setState({
            mode: MAP_OVERLAYS_MODES.EDIT_OVERLAY,
            activeOverlay: updatedActiveOverlay,
            mapSharingMode: updatedMapSharingMode,
            clickEditTool: false,
        });
    };

    const prepareMapOverlayForEdit = (mapOverlay) =>
    {
        // retain the same vector layer from mapOverlay list but deepclone other fields
        const updatedActiveOverlay = { ...cloneDeep(mapOverlay), vectorLayer: mapOverlay.vectorLayer };

        let updatedMapSharingMode = state.mapSharingMode;

        // add ids to all zoomLevels if dynamic settings is enabled
        if (updatedActiveOverlay.dynamicOverlaySettings.enabled)
        {
            updatedActiveOverlay.dynamicOverlaySettings.zoomLevels.forEach((zoomLevel) =>
            {
                zoomLevel.id = uuid();
            });
            // update the mapSharingMode to first (start zoom) level
            updatedMapSharingMode = updatedActiveOverlay.dynamicOverlaySettings.zoomLevels[0].id;
        }

        // set default text color in map overlay if the field does not exist.
        // mainly used to provide backward compatibilty to older map overlays created before textColor was implemented
        if (!updatedActiveOverlay.textColor)
        {
            updatedActiveOverlay.textColor = MAP_OVERLAY_TEXT_COLOR_DEFAULT;
        }

        // update the styling to have a different behaviour compared to non active overlays (mainly how opacity will change with zoom level)
        updateActiveOverlayLayer(updatedActiveOverlay, updatedMapSharingMode, state.displayLang);

        return { updatedActiveOverlay, updatedMapSharingMode };
    };

    const updateMapSharingMode = (newMode) =>
    {
        setState({
            mapSharingMode: newMode
        });
    };

    const recenterMapOnOverlay = (id, hightlight=false) =>
    {
        const { mapOverlays } = state;
        const selectedMapOverlay = mapOverlays.find((mapOverlay) => mapOverlay._id === id);

        const vectorLayer = selectedMapOverlay?.vectorLayer;

        if (vectorLayer)
        {
            // Get geometry extent of the selected map overlay
            const geometry = vectorLayer.getSource()?.getFeatures()?.find((feature) => feature.getId() === id)?.getGeometry();
            const extent = geometry?.getExtent();

            if (extent)
            {
                setMapUpdateState({
                    recenter: getCenter(extent)
                });
            }

            if (geometry && hightlight)
            {
                const shape = getShapeFromGeometry(geometry);
                setOverlayShapeToHightlight(shape);
            }
        }
    };

    const previewClickHandler = () =>
    {
        const { activeOverlay, displayLang } = state;

        if (activeOverlay.vectorLayer)
        {
            const center = getCenterOfVectorLayer(activeOverlay.vectorLayer, activeOverlay._id);

            if (center)
            {
                updateMapOverlayLayerToNonActiveState(activeOverlay, displayLang);

                setState({
                    mapSharingMode: undefined
                });

                setMapUpdateState({
                    previewOnCenter: center
                });
            }
        }
    };

    const activitySectionPreviewClickHandler = (mapOverlayId) =>
    {
        const { displayVectorLayers } = state;


        if (displayVectorLayers[mapOverlayId])
        {
            const vectorLayer = displayVectorLayers[mapOverlayId];

            const center = getCenterOfVectorLayer(vectorLayer, mapOverlayId);
            if (center)
            {
                setMapUpdateState({
                    previewOnCenter: center
                });
            }
        }
    };

    const updateSeletedMapOverlayIds = (selectedMapOverlayIds) =>
    {
        setState({
            selectedMapOverlayIds
        });
    };

    const handleCopyMapOverlay = (mapOverlayId) =>
    {
        const { mapOverlays } = state;

        const selectedMapOverlay = mapOverlays.find((mapOverlay) => mapOverlay._id === mapOverlayId);

        if (selectedMapOverlay)
        {
            const newActiveOverlay = {
                ...selectedMapOverlay,
                dynamicOverlaySettings: cloneDeep(selectedMapOverlay.dynamicOverlaySettings),
                vectorLayer: undefined
            };

            let updatedMapSharingMode = undefined;

            // add ids to all zoom levels if dynamic settings is enabled
            if (newActiveOverlay.dynamicOverlaySettings?.enabled)
            {
                newActiveOverlay.dynamicOverlaySettings.zoomLevels.forEach((zoomLevel) =>
                {
                    zoomLevel.id = uuid();
                });

                updatedMapSharingMode = newActiveOverlay.dynamicOverlaySettings.zoomLevels[0].id;
            }

            delete newActiveOverlay._id;

            newActiveOverlay._id = MAP_OVERLAY_NEW_OVERLAY_FEATURE_ID;
            if (newActiveOverlay.templateId)
            {
                setSelectedOverlayTemplateId(newActiveOverlay.templateId);
            }
            setState({
                activeOverlay: newActiveOverlay,
                mode: MAP_OVERLAYS_MODES.CREATE_OVERLAY,
                validationErrors: [],
                mapSharingMode: updatedMapSharingMode,
            });

        }
    };

    const handleDeleteMapOverlay = async (mapOverlayId) =>
    {
        const loadingId = loadingPool.add();
        let deleteResult = {};
        const mapOverlay = state.mapOverlays.find((mapOverlay) => mapOverlay._id === mapOverlayId);
        try
        {
            const result = await serverAPI.deleteOverlay(mapOverlayId);

            if (result.success)
            {
                let updatedMapOverlays = [ ...state.mapOverlays ];
                let updatedDisplayVectors = { ...state.displayVectorLayers };

                updatedMapOverlays = updatedMapOverlays.filter((mapOverlay) => mapOverlay._id !== mapOverlayId);

                delete updatedDisplayVectors[mapOverlayId];

                setState({
                    mapOverlays: updatedMapOverlays,
                    displayVectorLayers: updatedDisplayVectors
                });

                toast.success(
                    <>
                        <Trans 
                            i18nKey="CreateMapOverlaysSideBar.Delete_Overlay_Success"
                            values={{ overlayName: truncateText(`${mapOverlay.name[DEFAULT_LANGUAGE_CODE]}`, 22) }}
                        />
                    </>
                );
            }
            deleteResult = result;
        }
        catch (error)
        {
            deleteResult.success = false;
            toast.error(
                "Deletion failed"
            );
        }
        loadingPool.remove(loadingId);
        return deleteResult;
    };

    const handleDeleteMultipleMapoverlays = async (mapOverlayIds) =>
    {
        const loadingId = loadingPool.add();
        let deleteResult = {};
        try
        {
            const result = await serverAPI.deleteMultipleOverlays(mapOverlayIds);

            if (result.success)
            {
                let updatedMapOverlays = [ ...state.mapOverlays ];
                let updatedDisplayVectors = { ...state.displayVectorLayers };

                updatedMapOverlays = updatedMapOverlays.filter((mapOverlay) => !mapOverlayIds.includes(mapOverlay._id));

                mapOverlayIds.forEach((mapOverlayId) =>
                {
                    delete updatedDisplayVectors[mapOverlayId];
                });

                setState({
                    mapOverlays: updatedMapOverlays,
                    displayVectorLayers: updatedDisplayVectors
                });
            }
            deleteResult = result;
        }
        catch (error)
        {
            deleteResult.success = false;
        }
        loadingPool.remove(loadingId);
        return deleteResult;
    };

    const handleSearch = (geometry) =>
    {
        setMapUpdateState({
            centerOnGeometry: geometry
        });
    };

    const resetContext = () =>
    {
        clearPolygon();
        setState({
            mode: undefined,
            activeOverlay: {},
            creationToolStatus: false
        });

        cancelBulkCreation();

        // if creation was initiated in DML, reset it
        if (dynamicMapLayerCreationState.enabled)
        {
            setDyamicMapLayerCreationState({
                enabled: false,
                newlyCreatedMapOverlayId: undefined
            });
        }
    };

    const redirectFromDynamicMapLayer = () =>
    {
        setDyamicMapLayerCreationState({
            enabled: true
        });
        initiateCreation();
    };

    const updateMapOverlays = (mapOverlays) =>
    {
        const mapOverlayLayers = createMapOverlayLayers(cloneDeep(mapOverlays));
        const mapOverlayLayerMap = getMapOverlayLayerMapById(mapOverlayLayers);
        let updatedMapOverlays = [];
        mapOverlays.forEach((mapOverlay) =>
        {
            updatedMapOverlays.push({
                ...mapOverlay,
                vectorLayer: mapOverlayLayerMap[mapOverlay._id]
            });
        });
        setState({
            mapOverlays: updatedMapOverlays,
            displayVectorLayers: mapOverlayLayerMap
        });
    };

    const updateConsumedMapOverlays = (consumedMapOverlays) =>
    {
        setState({
            consumedMapOverlays
        });
    };

    const checkIfModifiedInCreationMode = () =>
    {
        const { mode } = state;
        let isModified = false;

        if (mode === MAP_OVERLAYS_MODES.CREATE_OVERLAY)
        {
            const activeOverlay = { ...state.activeOverlay };

            isModified = !isEqual(activeOverlay, NEW_OVERLAY_DEFAULT);
        }

        return isModified;
    };

    //---------------------------------- Bulk Creation Functions --------------------------------------

    const initiateBulkCreation = () =>
    {
        cancelCreationHandler();
        setState({
            mode: MAP_OVERLAYS_MODES.BULK_CREATION,
        });
        setBulkCreationState({
            ...DEFAULT_MAP_OVERLAY_BULK_CREATION_STATE,
            bulkCreationTools: true,
            errorMessage: ""
        });

    };

    const cancelBulkCreation = () =>
    {
        const { bulkMapOverlays } = bulkCreationState;
        const bulkOverlayIds = bulkMapOverlays.map((bulkMapOverlay) => bulkMapOverlay._id);

        let updatedDisplayVectorLayers = { ...state.displayVectorLayers };

        bulkOverlayIds.forEach((bulkOverlayId) => delete updatedDisplayVectorLayers[bulkOverlayId]);

        setState({
            mode: undefined,
            displayVectorLayers: updatedDisplayVectorLayers
        });
        setBulkCreationState({
            ...DEFAULT_MAP_OVERLAY_BULK_CREATION_STATE
        });
    };

    const addPolygonToBulkList = (polygonGeometry) =>
    {
        const { bulkCreationIndex } = bulkCreationState;
        const bulkMapOverlays = cloneDeep(bulkCreationState.bulkMapOverlays);

        if (bulkMapOverlays[bulkCreationIndex])
        {
            let currentMapOverlay = cloneDeep(bulkMapOverlays[bulkCreationIndex]);

            copyDataFromTemplateOverlay(currentMapOverlay);

            currentMapOverlay = updateMapOverlayShape(currentMapOverlay, polygonGeometry);
            bulkMapOverlays[bulkCreationIndex] = currentMapOverlay;
            setBulkCreationState({
                bulkMapOverlays,
                errorMessage: ""
            });

            updateDisplayVectorLayers(currentMapOverlay);
        }
        else
        {
            let newMapOverlay = { ...NEW_OVERLAY_DEFAULT, _id: uuid() };

            copyDataFromTemplateOverlay(newMapOverlay);

            newMapOverlay = updateMapOverlayShape(newMapOverlay, polygonGeometry);
            bulkMapOverlays.push(newMapOverlay);
            setBulkCreationState({
                bulkMapOverlays,
                errorMessage: ""
            });

            updateDisplayVectorLayers(newMapOverlay);
        }
    };

    const copyDataFromTemplateOverlay = (targetMapOverlay) =>
    {
        const { templateMapOverlay } = bulkCreationState;
        const { mapOverlays } = state;
        const templateOverlay = mapOverlays.find((mapOverlay) => mapOverlay._id === templateMapOverlay);

        if (templateOverlay)
        {
            BULK_MAP_OVERLAY_COPY_FROM_TEMPLATE_FIELDS.forEach((field) =>
            {
                targetMapOverlay[field] = cloneDeep(templateOverlay[field]);
            });
        }
    };

    const updateMapOverlayShape = (mapOverlay, geometry) =>
    {
        let updatedMapOverlay = { ...mapOverlay };
        const shape = getShapeFromGeometry(geometry);
        updatedMapOverlay.shape = shape;
        const newMapOverlayLayer = createMapOverlayLayers([cloneDeep(updatedMapOverlay)])[0];
        updatedMapOverlay.vectorLayer = newMapOverlayLayer;
        return updatedMapOverlay;
    };

    const updateDisplayVectorLayers = (mapOverlay) =>
    {
        let updatedDisplayVectorLayers = { ...state.displayVectorLayers };
        updatedDisplayVectorLayers[mapOverlay._id] = mapOverlay.vectorLayer;
        setState({
            displayVectorLayers: updatedDisplayVectorLayers
        });
    };

    const updateBulkIndex = (newBulkIndex) =>
    {
        const { bulkCreationIndex } = bulkCreationState;
        setBulkCreationState({
            bulkCreationIndex: bulkCreationIndex + newBulkIndex
        });
    };

    const handleBulkSelectionDone = () =>
    {
        setBulkCreationState({
            bulkCreationModal: true,
            bulkCreationTools: false
        });
    };

    const handleTemplateMapOverlayChange = (newTemplateMapOverlayId) =>
    {
        const bulkMapOverlays = [ ...bulkCreationState.bulkMapOverlays ];
        const { mapOverlays } = state;

        const templateOverlay = mapOverlays.find((mapOverlay) => mapOverlay._id === newTemplateMapOverlayId);

        if (templateOverlay)
        {
            let updatedDisplayVectorLayers = { ...state.displayVectorLayers };
            bulkMapOverlays.forEach((mapOverlay) =>
            {
                BULK_MAP_OVERLAY_COPY_FROM_TEMPLATE_FIELDS.forEach((field) =>
                {
                    mapOverlay[field] = cloneDeep(templateOverlay[field]);
                });

                const newMapOverlayLayer = createMapOverlayLayers([cloneDeep(mapOverlay)])[0];
                mapOverlay.vectorLayer = newMapOverlayLayer;

                updatedDisplayVectorLayers[mapOverlay._id] = mapOverlay.vectorLayer;
            });

            setBulkCreationState({
                templateMapOverlay: newTemplateMapOverlayId,
                bulkMapOverlays,
                errorMessage: ""
            });

            setState({
                displayVectorLayers: updatedDisplayVectorLayers
            });
        }
    };

    const handleBulkMapOverlayEntryUpdate = (id, key, value) =>
    {
        const bulkMapOverlays = [ ...bulkCreationState.bulkMapOverlays];

        const selectedMapOverlay = bulkMapOverlays.find((mapOverlay) => mapOverlay._id === id);

        if (selectedMapOverlay)
        {
            selectedMapOverlay[key] = value;

            // if toolTipText (AKA label) is updated, need to update display vector layer as well
            if (key === "toolTipText")
            {
                const newMapOverlayLayer = createMapOverlayLayers([cloneDeep(selectedMapOverlay)])[0];
                selectedMapOverlay.vectorLayer = newMapOverlayLayer;
                updateDisplayVectorLayers(selectedMapOverlay);
            }
        }

        setBulkCreationState({
            bulkMapOverlays,
            errorMessage: ""
        });
    };

    const handleMultipleMapOverlayEntriesUpdate = (mapOverlays) =>
    {
        setBulkCreationState({
            bulkMapOverlays: mapOverlays,
        });
    };

    const handleGoBackToDrawingBoard = () =>
    {
        setBulkCreationState({
            bulkCreationModal: false,
            bulkCreationTools: true
        });
    };

    const handleRemoveBulkEntry = (mapOverlayId) =>
    {
        let bulkMapOverlays = [ ...bulkCreationState.bulkMapOverlays ];
        let updatedDisplayVectorLayers = { ...state.displayVectorLayers };

        bulkMapOverlays = bulkMapOverlays.filter((mapOverlay) => mapOverlay._id !== mapOverlayId);
        delete updatedDisplayVectorLayers[mapOverlayId];

        // move the bulkCreationIndex to the end after a new entry is removed
        const newBulkCreationIndex = bulkMapOverlays.length;

        setBulkCreationState({
            bulkCreationIndex: newBulkCreationIndex,
            bulkMapOverlays,
            errorMessage: ""
        });

        setState({
            displayVectorLayers: updatedDisplayVectorLayers
        });
    };

    const handleFinish = async () =>
    {
        const bulkMapOverlays = cloneDeep(bulkCreationState.bulkMapOverlays);
        const { templateMapOverlay } = bulkCreationState;
        const { mapOverlays } = state;

        const templateOverlay = mapOverlays.find((mapOverlay) => mapOverlay._id === templateMapOverlay);

        bulkMapOverlays.forEach((mapOverlay, index) =>
        {
            Object.keys(templateOverlay.name).forEach((langCode) =>
            {
                if (!mapOverlay.name[langCode])
                {
                    mapOverlay.name = {
                        ...mapOverlay.name,
                        [langCode]: `${templateOverlay.name[langCode]}_${index + 1}`
                    };
                }
            });

        });

        const mapOvelayObjects = bulkMapOverlays.map((mapOverlay) => createOverlayObject(mapOverlay));

        // add indexes to new map overlays
        const indexOffset = mapOverlays.length;
        mapOvelayObjects.forEach((mapOverlay, index) => mapOverlay.index = indexOffset + index);

        const { success, createdMapOverlays } = await createMultipleMapOverlays(mapOvelayObjects);
        if (success)
        {
            //add new map overlays to state fields
            let updatedMapOverlays = [ ...state.mapOverlays ];
            let updatedDisplayVectorLayers = { ...state.displayVectorLayers };

            const mapOverlayLayers = createMapOverlayLayers(cloneDeep(createdMapOverlays));
            const mapOverlayLayerMap = getMapOverlayLayerMapById(mapOverlayLayers);

            createdMapOverlays.forEach((mapOverlay) =>
            {
                updatedMapOverlays.push({
                    ...mapOverlay,
                    vectorLayer: mapOverlayLayerMap[mapOverlay._id]
                });

                updatedDisplayVectorLayers[mapOverlay._id] = mapOverlayLayerMap[mapOverlay._id];
            });

            // remove previous temp vector layers from display layer array
            bulkMapOverlays.forEach((mapOverlay) =>
            {
                delete updatedDisplayVectorLayers[mapOverlay._id];
            });

            setState({
                mapOverlays: updatedMapOverlays,
                displayVectorLayers: updatedDisplayVectorLayers,
                mode: undefined
            });

            setBulkCreationState({
                ...DEFAULT_MAP_OVERLAY_BULK_CREATION_STATE
            });
        }

    };

    const createMultipleMapOverlays = async (mapOverlays) =>
    {
        const loadingId = loadingPool.add();
        try
        {
            const result = await serverAPI.createMultipleMapOverlays(mapOverlays);
            if (!result?.success)
            {
                return { success: false };
            }
            const { createdMapOverlays } = result;
            return { success: true, createdMapOverlays };
        }
        catch (error)
        {
            return { success: false };
        }
        finally
        {
            loadingPool.remove(loadingId);
        }
    };

    const centerOnNewMapOverlayEntry = (mapOverlayId) =>
    {
        const { bulkMapOverlays } = bulkCreationState;

        const mapOverlay = bulkMapOverlays.find((mapOverlay) => mapOverlay._id === mapOverlayId);

        const extent = mapOverlay?.vectorLayer?.getSource()?.getFeatures()?.find((feature) => feature.getId() === mapOverlayId)?.getGeometry()?.getExtent();

        if (extent)
        {
            setMapUpdateState({
                recenter: getCenter(extent)
            });
        }
    };

    const handleGoToMapOverlay = (mapOverlayId) =>
    {
        const { bulkMapOverlays } = bulkCreationState;
        const mapOverlay = bulkMapOverlays.find((mapOverlay) => mapOverlay._id === mapOverlayId);

        if (mapOverlay)
        {
            const index = bulkMapOverlays.findIndex((mapOverlay) => mapOverlay._id == mapOverlayId);

            setBulkCreationState({
                bulkCreationModal: false,
                bulkCreationTools: true,
                bulkCreationIndex: index
            });

            centerOnNewMapOverlayEntry(mapOverlayId);
        }
    };

    // ----------------------------------------Upload CSV functions -----------------------------------------------

    const uploadCsvMapOverlays = async (uploadedMapOverlays) =>
    {
        
        uploadedMapOverlays.forEach((mapOverlay) =>
        {
            // remove index field since it is not required anymore
            delete mapOverlay.index;
            // update color fields to hex format
            FIELDS_TO_HEX_CONVERT.forEach((field) => 
            {
                if (mapOverlay[field]) 
                {
                    const color = tinycolor(mapOverlay[field]);
                    mapOverlay[field] = color.toHexString();
                }
            });
        });

        const { success, updatedMapOverlays } = await editMultipleMapOverlays(uploadedMapOverlays);
        if (success)
        {
            const mapOverlays = [ ...state.mapOverlays ];
            const updatedDisplayVectorLayers = { ...state.displayVectorLayers };
            const mapOverlayIdToIndexMap = getMapOverlayIdToIndexMap(mapOverlays);

            const mapOverlayIds = Object.keys(mapOverlayIdToIndexMap);

            updatedMapOverlays.forEach((mapOverlay) =>
            {
                if (mapOverlayIds.includes(mapOverlay._id))
                {
                    let mapOverlayVectorLayer = createMapOverlayLayers([cloneDeep(mapOverlay)])[0];

                    if (mapOverlay.templateId)
                    {
                        const existingMapOverlay = mapOverlays[mapOverlayIdToIndexMap[mapOverlay._id]];
                        if (existingMapOverlay.templateId !== mapOverlay.templateId)
                        {
                            const newOverlay = extractOverlayInfoFromTemplateId({
                                overlay: {
                                    ..._.omit({
                                        ...existingMapOverlay,
                                        name: mapOverlay.name,
                                        toolTipText: mapOverlay.toolTipText,
                                        templateOverwrites: {}
                                    }, "vectorLayer"),
                                    templateId: mapOverlay.templateId 
                                },
                                overlayTemplates 
                            });
                            newOverlay.vectorLayer = mapOverlayVectorLayer;
                            const { updatedActiveOverlay } = prepareMapOverlayForEdit(newOverlay);
                            mapOverlays[mapOverlayIdToIndexMap[mapOverlay._id]] = updatedActiveOverlay;
                            updatedDisplayVectorLayers[mapOverlay._id] = updatedActiveOverlay.vectorLayer;
                        }
                        else 
                        {
                            mapOverlays[mapOverlayIdToIndexMap[mapOverlay._id]] = {
                                ...existingMapOverlay,
                                name: mapOverlay.name,
                                toolTipText: mapOverlay.toolTipText,
                                vectorLayer: mapOverlayVectorLayer
                            };
                            updatedDisplayVectorLayers[mapOverlay._id] = mapOverlayVectorLayer;
                        }
                    }
                    else 
                    {
                        mapOverlays[mapOverlayIdToIndexMap[mapOverlay._id]] = {
                            ...mapOverlay,
                            vectorLayer: mapOverlayVectorLayer
                        };

                        updatedDisplayVectorLayers[mapOverlay._id] = mapOverlayVectorLayer;
                    }
                
                }

            });

            setState({
                mapOverlays,
                displayVectorLayers: updatedDisplayVectorLayers,
                mode: undefined
            });
        }
    };

    const editMultipleMapOverlays = async (mapOverlays) =>
    {
        let editedMapOverlays = [];
        mapOverlays.forEach((mapOverlay) => editedMapOverlays.push(createOverlayObject(mapOverlay, false)));

        const loadingId = loadingPool.add();
        try
        {
            const result = await serverAPI.updateMultipleOverlays(editedMapOverlays);
            if (!result?.success)
            {
                return { success: false };
            }
            const { updatedMapOverlays } = result;
            return { success: true, updatedMapOverlays };
        }
        catch (error)
        {
            return { success: false };
        }
        finally
        {
            loadingPool.remove(loadingId);
        }
    };

    //----------------------------------------Multi language support functions----------------------------------------------

    const updateDisplayLang = (lang) =>
    {
        // if currently in creation or edit mode update text label content in map based on selected displayLang
        if (state.mode === MAP_OVERLAYS_MODES.CREATE_OVERLAY || state.mode === MAP_OVERLAYS_MODES.EDIT_OVERLAY)
        {
            updateActiveOverlayLayer(state.activeOverlay, state.mapSharingMode, lang);
        }

        setState({
            displayLang: lang
        });
    };

    const getAllowedLangsFromBuilding = (buildingId, propertyId) =>
    {
        let allowedLangs = [DEFAULT_LANGUAGE_CODE];

        if (properties[propertyId]?.buildings?.[buildingId]?.longName)
        {
            allowedLangs = Object.keys(properties[propertyId].buildings[buildingId].longName);
        }

        return allowedLangs;
    };

    const getAllowedLangsFromPropertyId = (propertyId) =>
    {
        let allowedLangs = [DEFAULT_LANGUAGE_CODE];

        if (properties[propertyId]?.name)
        {
            allowedLangs = Object.keys(properties[propertyId].name);
        }

        return allowedLangs;
    };

    const updateClickEditToolStatus = (clickEditTooStatus) =>
    {
        setState({
            clickEditTool: clickEditTooStatus
        });
    };

    const higlightOverlayClickHandler = () =>
    {
        if (state.mode === MAP_OVERLAYS_MODES.EDIT_OVERLAY && state.activeOverlay?.vectorLayer)
        {
            const { vectorLayer, _id } = state.activeOverlay;
            const geometry = vectorLayer.getSource()?.getFeatures()?.find((feature) => feature.getId() === _id)?.getGeometry();
            const shape = getShapeFromGeometry(geometry);
            setOverlayShapeToHightlight(shape);
        }
    };


    const highlightLinkedOverlayClickHandler = (mapOverlayId) => 
    {
        const mapOverlay = state.mapOverlays.find((mapOverlay) => mapOverlay._id === mapOverlayId);
        if (!mapOverlay) return;
        const { vectorLayer, _id } = mapOverlay;
        const geometry = vectorLayer.getSource()?.getFeatures()?.find((feature) => feature.getId() === _id)?.getGeometry();
        const shape = getShapeFromGeometry(geometry);
        setOverlayShapeToHightlight(shape);
    };

    const exportedValues = {
        state,
        overlayTemplates,
        selectedOverlayTemplateId,
        mapUpdateState,
        bulkCreationState,
        propertyId,
        reorderOverLays,
        buildingId,
        floorId,
        setState,
        updateMapOverLaysIds,
        updateMode,
        createNewPolygon,
        clearPolygon,
        cancelCreationHandler,
        updateCreationToolStatus,
        updateCurrentZoom,
        updateActiveOverlay,
        updateEnableDynamicOverlayFlag,
        updateDynamicOverlaySettings,
        confirmationDoneHandler,
        editClickHandler,
        updateMapSharingMode,
        recenterMapOnOverlay,
        setMapUpdateState,
        previewClickHandler,
        handleOverlayTemplateSelection,
        updateSeletedMapOverlayIds,
        handleCopyMapOverlay,
        handleDeleteMapOverlay,
        validateActiveMapOverlay,
        saveDraftHandler,
        handleSearch,
        activitySectionPreviewClickHandler,
        resetContext,
        getOverlaysForCurrentFloor,
        initiateCreation,
        dynamicMapLayerCreationState,
        setDyamicMapLayerCreationState,
        redirectFromDynamicMapLayer,
        updateMapOverlays,
        updateConsumedMapOverlays,
        checkIfModifiedInCreationMode,
        initiateBulkCreation,
        cancelBulkCreation,
        addPolygonToBulkList,
        updateBulkIndex,
        handleBulkSelectionDone,
        handleTemplateMapOverlayChange,
        handleBulkMapOverlayEntryUpdate,
        handleGoBackToDrawingBoard,
        handleRemoveBulkEntry,
        handleFinish,
        centerOnNewMapOverlayEntry,
        handleGoToMapOverlay,
        addNewZoomLevel,
        removeZoomLevel,
        updateMapSharingModeToStartZoom,
        uploadCsvMapOverlays,
        getCSVFileName,
        updateDisplayLang,
        handleMultipleMapOverlayEntriesUpdate,
        loadingPool,
        updateClickEditToolStatus,
        highlightLinkedOverlayClickHandler,
        higlightOverlayClickHandler,
        overlayShapeToHighlight,
        setOverlayShapeToHightlight,
        entityShapeToHighlight,
        setEntityShapeToHighlight,
        handleDeleteMultipleMapoverlays,
    };

    return (
        <MapOverlaysContext.Provider value={exportedValues}>
            {children}
        </MapOverlaysContext.Provider>
    );
});
