import React from "react";
import { Button, Input } from "semantic-ui-react";
import { ButtonBasic } from "../../common/ButtonIcon";
import { ModalBox } from "../../common/ModalBox";
import { TextGroup } from "../../common/TextGroup";
import { TagLabelShare } from "../../common/TagLabel";

export const ShareGroupLockedModal = () => (
    <ModalBox
        trigger={<Button primary content="Share Modal Locked" style={{ margin: "0 10px 10px 0" }}/>}
        header={<>Share Group
            <ButtonBasic content="Store Groups" />
        </>}
        actions={<Button primary floated="right" content="Share" />}>
        <div className="shareUrlCover">
            <ShareUrlBox icon="link" content="https://example.com/GoupLink/StoreName" />
            <ButtonBasic icon="locked-grey" className="unlockButton" />
        </div>
        <div className="shareUrlCover">
            <ShareUrlBox icon="lock" content="1234" maxWidth="248px" />
        </div>
        <TextGroup flexible title="Share with:">
            <Input placeholder="Enter email address" />
        </TextGroup>

        {/* emails share tag component */}

        <TagLabelShare content="mail@email.com"/>
        <TagLabelShare content="mail@email.com"/>
    </ModalBox>
);

const ShareUrlBox = ({ icon, content, button, maxWidth }) => (
    <div className="shareUrlBox" style={{ maxWidth: maxWidth }}>
        <img src={`/img/icon-${icon}.svg`} alt="" />
        <span>{content}</span>
        {(button) && <ButtonBasic content={button} />}
    </div>
);

