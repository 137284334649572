import { range } from "lodash";
import { 
    EntityTypes, 
    EntityType, 
    StructureEntityType, 
    PointOfInterestType, 
    ObstacleEntityType 
} from "mapsted.maps/utils/entityTypes";

export const STYLE_TYPES = {
    ENTITY: "entity"
};

export const BUILDING_STYLES_ENTITY_CLASSIFICATIONS = {
    Polygons: [
        {
            entityType: EntityType.STRUCTURE,
            subEntityType: StructureEntityType.ROOM,
            name: EntityTypes[EntityType.STRUCTURE].subTypes[StructureEntityType.ROOM].name
        },
        {
            entityType: EntityType.STRUCTURE,
            subEntityType: StructureEntityType.ENTRANCE_EXIT,
            name: EntityTypes[EntityType.STRUCTURE].subTypes[StructureEntityType.ENTRANCE_EXIT].name
        },
        {
            entityType: EntityType.STRUCTURE,
            subEntityType: StructureEntityType.PARKING,
            name: EntityTypes[EntityType.STRUCTURE].subTypes[StructureEntityType.PARKING].name
        },
        {
            entityType: EntityType.STRUCTURE,
            subEntityType: StructureEntityType.MOVING_WALKWAY,
            name: EntityTypes[EntityType.STRUCTURE].subTypes[StructureEntityType.MOVING_WALKWAY].name
        },
        {
            entityType: EntityType.STRUCTURE,
            subEntityType: StructureEntityType.PLATFORM,
            name: EntityTypes[EntityType.STRUCTURE].subTypes[StructureEntityType.PLATFORM].name
        },
        {
            entityType: EntityType.STRUCTURE,
            subEntityType: StructureEntityType.RAMP,
            name: EntityTypes[EntityType.STRUCTURE].subTypes[StructureEntityType.RAMP].name
        },
        {
            entityType: EntityType.STRUCTURE,
            subEntityType: StructureEntityType.STEPS,
            name: EntityTypes[EntityType.STRUCTURE].subTypes[StructureEntityType.STEPS].name
        },
        {
            entityType: EntityType.STRUCTURE,
            subEntityType: StructureEntityType.WASHROOM,
            name: EntityTypes[EntityType.STRUCTURE].subTypes[StructureEntityType.WASHROOM].name
        },
        {
            entityType: EntityType.STRUCTURE,
            subEntityType: StructureEntityType.UNKNOWN_STRUCTURE,
            name: EntityTypes[EntityType.STRUCTURE].subTypes[StructureEntityType.UNKNOWN_STRUCTURE].name
        },
        {
            entityType: EntityType.STRUCTURE,
            subEntityType: StructureEntityType.FLOOR_OPENING,
            name: EntityTypes[EntityType.STRUCTURE].subTypes[StructureEntityType.FLOOR_OPENING].name
        },
        {
            entityType: EntityType.STRUCTURE,
            subEntityType: StructureEntityType.INACCESSIBLE,
            name: EntityTypes[EntityType.STRUCTURE].subTypes[StructureEntityType.INACCESSIBLE].name
        },
        {
            entityType: EntityType.STRUCTURE,
            subEntityType: StructureEntityType.PATHWAY,
            name: EntityTypes[EntityType.STRUCTURE].subTypes[StructureEntityType.PATHWAY].name
        },
        {
            entityType: EntityType.STRUCTURE,
            subEntityType: StructureEntityType.PATHWAY_INNER_BOUNDARY,
            name: EntityTypes[EntityType.STRUCTURE].subTypes[StructureEntityType.PATHWAY_INNER_BOUNDARY].name
        },

        {
            entityType: EntityType.OBSTACLE,
            subEntityType: ObstacleEntityType.BAGGAGE_CAROUSEL,
            name: EntityTypes[EntityType.OBSTACLE].subTypes[ObstacleEntityType.BAGGAGE_CAROUSEL].name
        },
        {
            entityType: EntityType.OBSTACLE,
            subEntityType: ObstacleEntityType.DESK,
            name: EntityTypes[EntityType.OBSTACLE].subTypes[ObstacleEntityType.DESK].name
        },
        {
            entityType: EntityType.OBSTACLE,
            subEntityType: ObstacleEntityType.EQUIPMENT,
            name: EntityTypes[EntityType.OBSTACLE].subTypes[ObstacleEntityType.EQUIPMENT].name
        },
        {
            entityType: EntityType.OBSTACLE,
            subEntityType: ObstacleEntityType.FURNITURE,
            name: EntityTypes[EntityType.OBSTACLE].subTypes[ObstacleEntityType.FURNITURE].name
        },
        {
            entityType: EntityType.OBSTACLE,
            subEntityType: ObstacleEntityType.KIOSK,
            name: EntityTypes[EntityType.OBSTACLE].subTypes[ObstacleEntityType.KIOSK].name
        },
        {
            entityType: EntityType.OBSTACLE,
            subEntityType: ObstacleEntityType.OBSTRUCTION,
            name: EntityTypes[EntityType.OBSTACLE].subTypes[ObstacleEntityType.OBSTRUCTION].name
        },
        {
            entityType: EntityType.OBSTACLE,
            subEntityType: ObstacleEntityType.SHELVING,
            name: EntityTypes[EntityType.OBSTACLE].subTypes[ObstacleEntityType.SHELVING].name
        },
        {
            entityType: EntityType.OBSTACLE,
            subEntityType: ObstacleEntityType.STAGE,
            name: EntityTypes[EntityType.OBSTACLE].subTypes[ObstacleEntityType.STAGE].name
        },
        {
            entityType: EntityType.OBSTACLE,
            subEntityType: ObstacleEntityType.VEGETATION,
            name: EntityTypes[EntityType.OBSTACLE].subTypes[ObstacleEntityType.VEGETATION].name
        },
        {
            entityType: EntityType.OBSTACLE,
            subEntityType: ObstacleEntityType.WALL,
            name: EntityTypes[EntityType.OBSTACLE].subTypes[ObstacleEntityType.WALL].name
        },
        {
            entityType: EntityType.OBSTACLE,
            subEntityType: ObstacleEntityType.WATER,
            name: EntityTypes[EntityType.OBSTACLE].subTypes[ObstacleEntityType.WATER].name
        }
    ],
    Transitions: [
        {
            entityType: EntityType.STRUCTURE,
            subEntityType: StructureEntityType.ELEVATOR,
            name: EntityTypes[EntityType.STRUCTURE].subTypes[StructureEntityType.ELEVATOR].name
        },
        {
            entityType: EntityType.STRUCTURE,
            subEntityType: StructureEntityType.ESCALATOR,
            name: EntityTypes[EntityType.STRUCTURE].subTypes[StructureEntityType.ESCALATOR].name
        },
        {
            entityType: EntityType.STRUCTURE,
            subEntityType: StructureEntityType.STAIRS,
            name: EntityTypes[EntityType.STRUCTURE].subTypes[StructureEntityType.STAIRS].name
        }
    ],
    POI: [
        {
            entityType: EntityType.POINT_OF_INTEREST,
            subEntityType: PointOfInterestType.UNKNOWN_POI,
            name: EntityTypes[EntityType.POINT_OF_INTEREST].subTypes[PointOfInterestType.UNKNOWN_POI].name
        }
    ]
};

export const PROPERTY_STYLES_ENTITY_CLASSIFICATIONS = {
    ...BUILDING_STYLES_ENTITY_CLASSIFICATIONS,
    Polygons: [
        ...BUILDING_STYLES_ENTITY_CLASSIFICATIONS.Polygons,
        {
            entityType: EntityType.STRUCTURE,
            subEntityType: StructureEntityType.BUILDING,
            name: EntityTypes[EntityType.STRUCTURE].subTypes[StructureEntityType.BUILDING].name
        }
    ]
};

export const ENTITIES_WITH_POLYGON_STYLES = [...BUILDING_STYLES_ENTITY_CLASSIFICATIONS.Polygons, ...BUILDING_STYLES_ENTITY_CLASSIFICATIONS.Transitions]
    .map((entityTypes) => `${entityTypes.entityType}:${entityTypes.subEntityType}`);

export const ENTITY_TYPE_NAMES = Object.values(BUILDING_STYLES_ENTITY_CLASSIFICATIONS)
    .reduce((prevValue, currentValue) => [...prevValue, ...currentValue], [])
    .reduce((prevValue, currentValue) => ({ ...prevValue, [`${currentValue.entityType}:${currentValue.subEntityType}`]: currentValue.name }), {});

export const MAX_STROKE_WIDTH = 5;

export const STROKE_WIDTH_OPTIONS = range(1, MAX_STROKE_WIDTH + 1, 1).map((num) => ({
    key: num,
    value: num,
    text: num
}));

export const MIX_FONT_SIZE = 10;
export const MAX_FONT_SIZE = 16;

export const FONT_SIZE_OPTIONS = range(MIX_FONT_SIZE, MAX_FONT_SIZE + 1, 1).map((num) => ({
    key: num,
    value: num,
    text: num
}));

export const MIN_TEXT_STROKE_WIDTH = 1;
export const MAX_TEXT_STROKE_WIDTH = 5;

export const TEXT_STROKE_WIDTH_OPTIONS = range(MIN_TEXT_STROKE_WIDTH, MAX_TEXT_STROKE_WIDTH + 1, 1).map((num) => ({
    key: num,
    value: num,
    text: num
}));

export const BUILDING_LOCATION_TYPE = "building";

export const PROPERTY_LOCATION_TYPE = "property";

export const TEXT_FONTS = [
    "Arial, sans-serif",
    "Verdana, sans-serif",
    "Tahoma, sans-serif",
    "'Trebuchet MS', sans-serif",
    "'Times New Roman', serif",
    "Georgia, serif",
    "Garamond, serif",
    "'Courier New', monospace",
    "'Brush Script MT', cursive",
];

export const TEXT_FONTS_OPTIONS = TEXT_FONTS.map((textFont) => ({
    key: textFont,
    value: textFont,
    text: textFont
}));

export const BASE_MAPS = [
    {
        baseMapId: "voyager",
        label: "Voyager",
        imageName: "basemap_voyager",
        tileLayer: {
            url: "https://basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png",
            projection: "EPSG:3857",
            attributions:
                `
                      <a href="https://openlayers.org" target="_blank" rel="noopener noreferrer">Open Layers</a> |
                    © <a href="https://cartodb.com/attributions" target="_blank" rel="noopener noreferrer">Carto Voyager</a> | 
                    © <a href="https://www.openstreetmap.org/copyright" target="_blank" rel="noopener noreferrer">OpenStreetMap</a> 
                `
        }
    },
    {
        baseMapId: "positron",
        label: "Positron",
        imageName: "basemap_positron",
        tileLayer: {
            url: "https://basemaps.cartocdn.com/rastertiles/light_all/{z}/{x}/{y}.png",
            projection: "EPSG:3857",
            attributions:
                `
                      <a href="https://openlayers.org" target="_blank" rel="noopener noreferrer">Open Layers</a> |
                    © <a href="https://cartodb.com/attributions" target="_blank" rel="noopener noreferrer">Carto Light All</a> | 
                    © <a href="https://www.openstreetmap.org/copyright" target="_blank" rel="noopener noreferrer">OpenStreetMap</a> 
                `
        }
    },
    {
        baseMapId: "darkMatter",
        label: "Dark Matter",
        imageName: "basemap_darkmatter",
        tileLayer: {
            url: "https://basemaps.cartocdn.com/rastertiles/dark_all/{z}/{x}/{y}.png",
            projection: "EPSG:3857",
            attributions:
                `
                      <a href="https://openlayers.org" target="_blank" rel="noopener noreferrer">Open Layers</a> |
                    © <a href="https://cartodb.com/attributions" target="_blank" rel="noopener noreferrer">Carto Dark All</a> | 
                    © <a href="https://www.openstreetmap.org/copyright" target="_blank" rel="noopener noreferrer">OpenStreetMap</a> 
                `
        }
    },
];

export const HIDDEN_POLYGON_STYLE = {
    fill: {
        color: "rgb(255, 255, 255, 0)"
    },
    stroke: {
        color: "rgb(255, 255, 255, 0)",
        width: 0
    },
};

export const HIDDEN_TEXT_STYLE = {
    fill: {
        color: "rgb(255, 255, 255, 0)"
    },
    stroke: {
        color: "rgb(255, 255, 255, 0)",
        width: 0
    },
    fontName: "Arial, sans-serif",
    size: "0px",
    font: "bold 0px Arial, sans-serif",
};