import React, { useContext, useState } from "react";
import { ButtonIcon } from "../../common/ButtonIcon";
import BrandingContext from "../../../store/BrandingContext";
import { SubheaderLeft, ToolButton } from "../../layout/Subheader";
import { MAINTENANCE_MODES, MAP_OVERLAYS_MODES, DYNAMIC_MAP_LAYERS_MODES } from "../../../_constants/constants";
import { MaintenanceContext } from "../../../store/MaintenanceContext";
import { MapOverlaysContext } from "../../../store/MapOverlaysContext";
import { DynamicMapLayersContext } from "../../../store/DynamicMapLayersContext";
import { PublishPopup } from "../../branding/publish/PublishPopup";
import MaintainenanceSubheaderLeft from "./MaintainenanceSubheaderLeft";
import MapOverlaysUploadCsvModal from "../mapOverlays/MapOverlaysUploadCsvModal";
import { useTranslation } from "react-i18next";

const renderRightSubHeader = (mode) =>
{
    switch (mode)
    {
    case MAINTENANCE_MODES.MAP_OVERLAYS: 
        return <MaintenanceMapOverlaysMenuGroup />;
    case MAINTENANCE_MODES.DYNAMIC_MAP_LAYERS:
        return <MaintenanceDynamicMapLayersMenuGroup />;
    default:
        return <></>;
    }
};

const renderLeftSubHeader = (mode, dynamicContext) =>
{
    if (mode === MAINTENANCE_MODES.MAP_OVERLAYS || mode === MAINTENANCE_MODES.DYNAMIC_MAP_LAYERS)
    {
        return <MaintainenanceSubheaderLeft dynamicContext={dynamicContext} />;
    }
    else
    {
        return <SubheaderLeft dynamicContext={dynamicContext} />;
    }
};

export const SubheaderMaintenance = () =>
{
    const brandingCtx = useContext(BrandingContext);
    const maintenanceCtx = useContext(MaintenanceContext);

    return (
        <div className="subheaderMaintenance subheader">
            <div className="subheader-left">
                {renderLeftSubHeader(maintenanceCtx.state.mode, brandingCtx)}  
            </div>
            <div className="subheader-right">
                { renderRightSubHeader(maintenanceCtx.state.mode) }
            </div>
        </div>
    );
};


const MaintenanceMapOverlaysMenuGroup = () =>
{
    const { 
        state, 
        updateMode, 
        updateCreationToolStatus, 
        getOverlaysForCurrentFloor, 
        initiateCreation,
        loadingPool,
        updateClickEditToolStatus,
        setState,
    } = useContext(MapOverlaysContext);
    const { state: brandingState } = useContext(BrandingContext);
    const { propertyId, buildingId, floorId } = brandingState;
    const { mode } = state;

    const trans = useTranslation().t;

    const [disableButtons, setDisableButtons] = useState(false); 

    const beforePublishHandler = () =>
    {
        setDisableButtons(true);
    };

    const afterPublishHandler = () =>
    {
        setDisableButtons(false);
        getOverlaysForCurrentFloor();
    };

    // Display Creation/Selection and Activity button only if property, building and floor are selected or only property is selected
    const displayOptions = (!!propertyId && !!buildingId && !!floorId) || propertyId;

    return (
        <>
            {displayOptions && <div className="maintenanceMenuGroup">
                <MapOverlaysUploadCsvModal /> 

                <ButtonIcon
                    icon="edit"
                    content={trans("MaintenanceMapOverlaysMenuGroup.Edit")}
                    active={state.clickEditTool}
                    disabled={mode === MAP_OVERLAYS_MODES.CREATE_OVERLAY 
                        || mode === MAP_OVERLAYS_MODES.EDIT_OVERLAY 
                        || mode === MAP_OVERLAYS_MODES.CONFIRMATION_SIDEBAR
                        || mode === MAP_OVERLAYS_MODES.BULK_CREATION
                        || mode === MAP_OVERLAYS_MODES.ACTIVITY_SIDEBAR || mode === MAP_OVERLAYS_MODES.TEMPLATES || disableButtons}
                    onClick={() => updateClickEditToolStatus(!state.clickEditTool)} />

                {(mode === MAP_OVERLAYS_MODES.CREATE_OVERLAY || mode === MAP_OVERLAYS_MODES.EDIT_OVERLAY) 
                    && <ButtonIcon
                        icon="menu-selection"
                        content={trans("MaintenanceMapOverlaysMenuGroup.Selection")}
                        active={state.creationToolStatus}
                        disabled={disableButtons}
                        onClick={() => updateCreationToolStatus(!state.creationToolStatus)} />}

                <ButtonIcon
                    icon="menu-activity"
                    content={trans("MaintenanceMapOverlaysMenuGroup.Activity")}
                    active={mode === MAP_OVERLAYS_MODES.ACTIVITY_SIDEBAR}
                    disabled={mode === MAP_OVERLAYS_MODES.CREATE_OVERLAY 
                        || mode === MAP_OVERLAYS_MODES.EDIT_OVERLAY 
                        || mode === MAP_OVERLAYS_MODES.CONFIRMATION_SIDEBAR
                        || mode === MAP_OVERLAYS_MODES.BULK_CREATION || mode === MAP_OVERLAYS_MODES.TEMPLATES || disableButtons}
                    onClick={() => 
                    {

                        updateMode(MAP_OVERLAYS_MODES.ACTIVITY_SIDEBAR);
                        setState({ isActivitySideBar: !state.isActivitySideBar });
                     
                    }} />

                {/* <ButtonIcon
                    icon="overlay-templates"
                    content={trans("MaintenanceMapOverlaysMenuGroup.Templates")}
                    active={mode === MAP_OVERLAYS_MODES.TEMPLATES}
                    disabled={(mode && mode !== MAP_OVERLAYS_MODES.TEMPLATES) || disableButtons}
                    onClick={() => 
                    { 
                        updateMode(MAP_OVERLAYS_MODES.TEMPLATES);
                        setState({ isOverlaysTemplateSideBar: !state.isOverlaysTemplateSideBar });
                        
                    }} /> */}

                <ButtonIcon
                    icon="plus-filled"
                    active={mode === MAP_OVERLAYS_MODES.CREATE_OVERLAY}
                    content={trans("MaintenanceMapOverlaysMenuGroup.Create_Map_Overlay")}
                    disabled={mode === MAP_OVERLAYS_MODES.CONFIRMATION_SIDEBAR
                        || mode === MAP_OVERLAYS_MODES.BULK_CREATION 
                        || disableButtons}
                    onClick={() =>
                    {
                        if (mode !== MAP_OVERLAYS_MODES.CREATE_OVERLAY && mode !== MAP_OVERLAYS_MODES.CONFIRMATION_SIDEBAR)
                        {
                            initiateCreation();

                            if (state.isOverlaysTemplateSideBar)
                            {
                                setState({ isOverlaysTemplateSideBar: !state.isOverlaysTemplateSideBar });
                            }
                        }
                    }}
                />
            </div>}

            <PublishPopup
                onPublish={afterPublishHandler}
                disabled={!!mode || !!state.clickEditTool}
                beforePublish={beforePublishHandler}
                onPublishError={afterPublishHandler}
                globalLoader={true}
                loadingPool={loadingPool}
            />

        </>);
};

const MaintenanceDynamicMapLayersMenuGroup = () =>
{
    const { 
        state, 
        updateMode, 
        initiateCreation, 
        clearMapLayers,
        loadingPool,
    } = useContext(DynamicMapLayersContext);
    const { mode } = state;

    const trans = useTranslation().t;

    const { state: brandingState } = useContext(BrandingContext);
    const { propertyId, buildingId, floorId } = brandingState;

    const [disableButtons, setDisableButtons] = useState(false);

    const beforePublishHandler = () =>
    {
        setDisableButtons(true);
    };

    const afterPublishHandler = () =>
    {
        setDisableButtons(false);
    };

    // Display Creation and Activity button only if property, building and floor are selected
    const displayOptions = !!propertyId && !!buildingId && !!floorId;

    return (
        <>
            {displayOptions 
            && <div className="maintenanceMenuGroup">
                <ButtonIcon
                    icon="menu-activity"
                    content={trans("MaintenanceDynamicMapMenuGroup.Activity")}
                    active={mode === DYNAMIC_MAP_LAYERS_MODES.ACTIVITY_SIDEBAR}
                    onClick={() => 
                    {
                        updateMode(DYNAMIC_MAP_LAYERS_MODES.ACTIVITY_SIDEBAR);
                        clearMapLayers();
                    }}
                    disabled={mode === DYNAMIC_MAP_LAYERS_MODES.CREATE_DYNAMIC_MAP_LAYER
                    || mode === DYNAMIC_MAP_LAYERS_MODES.MULTI_CREATION
                    || disableButtons}
                />
                <ButtonIcon
                    icon="plus-filled"
                    content={trans("MaintenanceDynamicMapMenuGroup.Create_Dynamic_Map_Layer")}
                    active={mode === DYNAMIC_MAP_LAYERS_MODES.CREATE_DYNAMIC_MAP_LAYER 
                        || mode === DYNAMIC_MAP_LAYERS_MODES.MULTI_CREATION}
                    disabled={disableButtons}
                    onClick={() =>
                    {
                        if (mode !== DYNAMIC_MAP_LAYERS_MODES.CREATE_DYNAMIC_MAP_LAYER
                            && mode !== DYNAMIC_MAP_LAYERS_MODES.MULTI_CREATION)
                        {
                            initiateCreation();
                        }
                    }} />
            </div>
            }
            <PublishPopup 
                disabled={!!mode}
                beforePublish={beforePublishHandler}
                onPublish={afterPublishHandler}
                onPublishError={afterPublishHandler}
                globalLoader={true}
                loadingPool={loadingPool}
            />
        </>);
};
