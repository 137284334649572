import React from 'react';
import { Segment, Button } from 'semantic-ui-react';

export const InitialDetailBox = ({error, action, onClick, children}) =>
{
    return (
        <Segment basic className={error ? "detail-box no-result" : "detail-box"} style={{paddingTop : action && "40px"}}>
            {
                action && 
                <Button className="edit-btn" onClick={onClick}>
                    <img src={`/img/icon-${action}.svg`} alt="Icon Pencil" />
                </Button>
            }
            {children}
        </Segment>
    );
}

