import React, { useContext, useState } from "react";
import { Button, Popup } from "semantic-ui-react";
import { DynamicMapLayersContext } from "../../../../store/DynamicMapLayersContext";
import { ButtonIcon } from "../../../common/ButtonIcon";
import { ModalBox } from "../../../common/ModalBox";
import { useTranslation } from "react-i18next";

const CreateMultiDynamicMapLayerButton = () =>
{
    const dynamicMapLayerCtx = useContext(DynamicMapLayersContext);
    const { checkIfModifiedInCreationMode, initiateMultiCreation } = dynamicMapLayerCtx;

    const [openModal, setOpenModal] = useState(false);

    const addMultipleClickHandler = () =>
    {
        const enableCancelConfirmationModal = checkIfModifiedInCreationMode(); 

        if (enableCancelConfirmationModal)
        {
            setOpenModal(true);
        }
        else
        {
            initiateMultiCreation();
        }
    };

    const trans = useTranslation().t;

    return (
        <>
            <Popup className="multiplePopover"
                content={trans("MaintenanceCreateDynamicMapSidebar.Create_Multiple_Dynamic_Map_Layers")}
                basic
                trigger={<ButtonIcon 
                    icon="plus-filled" 
                    content={trans("MaintenanceCreateDynamicMapSidebar.Add_Multiple")}
                    onClick={addMultipleClickHandler} />
                }
            />
            <CancelConfirmationModal 
                open={openModal}
                onClose={() => setOpenModal(false)}
                initiateMultiCreation={initiateMultiCreation} />
        </>
    );
};

const CancelConfirmationModal = ({ open, onClose, initiateMultiCreation }) =>
{
    const trans = useTranslation().t;

    return (
        <ModalBox className="confirmActionModal"
            open={open}
            onClose={onClose}
            header={trans("Cancel_Confirmation_Modal.Confirm_Action")}
            actions={<Button color="orange" floated="right" content={trans("Cancel_Confirmation_Modal.Proceed")} onClick={initiateMultiCreation} />}>
            <p className="p-modal">
                {trans("Cancel_Confirmation_Modal.Single_To_Multilayer_Creation_Switch")}
            </p>
        </ModalBox>
    );
};

export default CreateMultiDynamicMapLayerButton;
