import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { TransitionsEditorMap } from "../map/TransitionsEditorMap"
import MapEditorContext from "../../../store/MapEditiorContext"
import ObjectId from "bson-objectid";
import { ShapeTypes } from "mapsted.maps/utils/entityTypes";
import { isTransitionOnCurrentMap } from "mapsted.maps/utils/georeference.utils";
import { mercatorToWgs84 } from "../../../_utils/turf.utlils";
import mapEditorLocalDb from "../../../_indexedDB/mapEditorLocal.db";
import { TransitionStep } from "../../../models/transitionsStep";
import { deepCopy } from "mapsted.utils/objects";
import { EDITOR_ALERTS, MAP_EDITOR_SHORTCUTS, NON_REVERSIBLE_DIRECTION } from "../../../_constants/mapEditor";
import { CollectionNameMap } from "../../../_indexedDB/collections/v1/collection";
import { useKeyboardShortcut, useSingleKeyboardShortcut } from "../../../_utils/keyboardShortcuts";


//https://openlayers.org/en/latest/examples/side-by-side.html 
export const TransitionsEditor = ({
    onLocalDBUpdated,
    onUndo,
    onRedo,
}) =>
{
    const editorContext = useContext(MapEditorContext);

    const [transitions, setTransitions] = useState([]);
    const [isUndoLocked, setIsUndoLocked] = useState(false);

    const transitionsRef = useRef();
    const renderedNodeIdToTransitionHashRef = useRef();
    const mainNavIdsRef = useRef();
    const georeferenceIdRef = useRef();

    transitionsRef.current = transitions;

    // get nav IDs of both maps
    const mainNavIdsMemo = useMemo(() =>
    {
        return editorContext.mainNavIdsMemo;
    }, [editorContext.mainNavIdsMemo]);
    mainNavIdsRef.current = mainNavIdsMemo;

    const georeferenceNavIdsMemo = useMemo(() =>
    {
        return editorContext.georeferenceNavIdsMemo;
    }, [editorContext.georeferenceNavIdsMemo]);

    georeferenceIdRef.current = georeferenceNavIdsMemo;

    const renderedNodeIdToTransitionHashMemo = useMemo(() =>
    {
        let hash = {};

        transitionsRef.current.forEach((transition) =>
        {
            const { isBothRouteNodeOnMap } = isTransitionOnCurrentMap(transition, mainNavIdsMemo, georeferenceNavIdsMemo);

            if (isBothRouteNodeOnMap)
            {
                let nodeId1 = transition.route[0].node;
                let nodeId2 = transition.route[1].node;

                hash[nodeId1] = transition;
                hash[nodeId2] = transition;
            }
        });

        return hash;

    }, [mainNavIdsMemo, georeferenceNavIdsMemo, transitionsRef.current]);
    renderedNodeIdToTransitionHashRef.current = renderedNodeIdToTransitionHashMemo;

    const isSamePlottingLevelMemo = useMemo(() =>
    {
        return mainNavIdsMemo.floorId === georeferenceNavIdsMemo.floorId;
    }, [mainNavIdsMemo, georeferenceNavIdsMemo]);

    useEffect(() =>
    {
        setTransitions(editorContext.state.transitionsData);
    }, [editorContext.state.transitionsData]);

    const updateLocalDB = useCallback(async (transitionStepData) =>
    {
        setIsUndoLocked(true);

        const { propertyId, buildingId, floorId, selectedTool,
            georeferenceBuildingId, georeferenceFloorId } = editorContext.state;

        // update local node DB
        await mapEditorLocalDb.handleUpdateLocalTransitions({
            propertyId,
            buildingId,
            floorId,
            georeferenceBuildingId,
            georeferenceFloorId,
            selectedTool,
            transitionStepData,
        });

        onLocalDBUpdated();
        setIsUndoLocked(false);

    }, [editorContext.state.selectedTool, editorContext.state.propertyId, editorContext.state.buildingId, editorContext.state.floorId,
    editorContext.state.georeferenceBuildingId, editorContext.state.georeferenceFloorId, setIsUndoLocked]);

    //#region Map Callbacks

    /**
     * @param nodeFeature
     */
    const getNodeFromFeature = useCallback((nodeFeature) =>
    {
        if (!nodeFeature)
        {
            return undefined;
        }

        let node = nodeFeature.getProperties();

        console.log({ nodeFeature })
        node.shape = {
            type: ShapeTypes.POINT,
            coordinates: mercatorToWgs84(nodeFeature.getGeometry().getCenter(), ShapeTypes.POINT)
        }

        return node;
    }, []);

    /**
     * Create a transition with the given nodes and options
     * @param {Array} nodeFeatures - [mainMapNode, georeferenceMapNode]
     * @param {Object} options - accessible, restricted, reversible, transitionType 
     */
    const handleCreateTransition = useCallback((nodeFeatures, options = {}) =>
    {
        let transitions = deepCopy(transitionsRef.current);

        let transitionStepData = new TransitionStep();
        transitionStepData.addedTransitions = [];

        // route array of following objects - propertyId, buildingId, floorId, node, nodeId, propertyId, shape, _id ]
        let route = [];
        let validationError;

        nodeFeatures.forEach((nodeFeature, i) =>
        {
            const node = getNodeFromFeature(nodeFeature);
            let navIds;

            if (i === 0)
            {
                navIds = mainNavIdsMemo;
            }
            else
            {
                navIds = georeferenceNavIdsMemo
            }

            route.push({
                ...navIds,
                node: node._id,
                nodeId: node.nodeId,
                shape: node.shape,
                _id: ObjectId().toString()
            });

            // check if one of the nodes is already a transition on the current set of maps
            if (renderedNodeIdToTransitionHashRef.current[node._id])
            {
                validationError = EDITOR_ALERTS.NODE_USED_IN_ACTIVE_TRANSITION;
            }
        });

        if (validationError)
        {
            return { isValid: false, validationError }
        }

        if (route[0].node === route[1].node)
        {
            return { isValid: false, validationError: EDITOR_ALERTS.TRANSITION_SAME_NODE };
        }

        // create transition object
        let propertyId = -1, buildingId = mainNavIdsMemo.buildingId, dataType = 2;

        propertyId = mainNavIdsMemo.propertyId;

        if (mainNavIdsMemo.buildingId === -1)
        {
            buildingId = -1;
            dataType = 1; // property transition
        }
        else if (georeferenceNavIdsMemo.buildingId === -1)
        {
            buildingId = -1;
            dataType = 1; // property transition
        }

        if (options.nonReversibleDirectionForCreation === NON_REVERSIBLE_DIRECTION.GEOREFERENCE_TO_MAIN)
        {
            route.reverse();
        }

        const transition = {
            propertyId: propertyId,
            buildingId: buildingId,
            dataType: dataType,
            route: route,
            accessible: options.accessible,
            reversible: options.reversible,
            restricted: options.restricted,
            transitionType: options.transitionType,
            dataType: 1,
            deltaHeight: 0,
            draft: true,
            transitionId: -1,
            _id: ObjectId().toString()
        };

        //save to local transitions & create undo redo step
        transitions.push(transition);
        transitionStepData.addedTransitions.push(transition);

        updateLocalDB(transitionStepData);
        setTransitions(transitions);

        return { stepData: transitionStepData, isValid: true };
    }, [transitionsRef.current, renderedNodeIdToTransitionHashRef.current, mainNavIdsMemo, georeferenceNavIdsMemo, getNodeFromFeature]);

    /**
   * Edit a transition with the given options
   * @param {Object} transition - [mainMapNode, georeferenceMapNode]
   * @param {Object} options - accessible, restricted, reversible, transitionType 
   */
    const handleEditTransition = useCallback((transition, transitionOptions = {}) =>
    {
        if (!transition)
        {
            return;
        }

        let transitions = deepCopy(transitionsRef.current);

        let transitionStepData = new TransitionStep();
        transitionStepData.updatedTransitionsBefore = [];
        transitionStepData.updatedTransitionsAfter = [];

        // add prev data to step
        transitionStepData.updatedTransitionsBefore.push(deepCopy(transition));

        // update transition and add to step 
        const updatedTransition = Object.assign(transition, transitionOptions);
        transitionStepData.updatedTransitionsAfter.push({ ...updatedTransition });

        // update in local transitions array
        let transitionIndex = transitions.findIndex((existingTransition) => existingTransition._id === transition._id);
        transitions[transitionIndex] = updatedTransition;

        // set transitions and send step data to local DB
        setTransitions(transitions)
        updateLocalDB(transitionStepData);
    }, [transitionsRef.current, mainNavIdsMemo, georeferenceNavIdsMemo, getNodeFromFeature]);

    /**
    * Delete a transition with the given options
    * @param {Object} transition - [mainMapNode, georeferenceMapNode]
    */
    const handleDeleteTransition = useCallback((transition) =>
    {
        if (!transition)
        {
            return;
        }

        let transitions = deepCopy(transitionsRef.current);

        let transitionStepData = new TransitionStep();
        transitionStepData.deletedTransitions = [];

        // add deleted transition to step 
        transitionStepData.deletedTransitions.push({ ...transition });

        // remove transition from local transitions
        let transitionIndex = transitions.indexOf((existingTransition) => existingTransition._id === transition._id);
        transitions.splice(transitionIndex, 1);

        // set transitions and local objects
        setTransitions(transitions)
        updateLocalDB(transitionStepData);
        return ({ isValid: true, stepData: transitionStepData })
    }, [transitionsRef.current, mainNavIdsMemo, georeferenceNavIdsMemo, getNodeFromFeature]);

    const handleGetTransitionFromMapClick = useCallback((mapClickData) =>
    {
        const nodeIdToTransitionHash = renderedNodeIdToTransitionHashRef.current;
        const { nodeId } = mapClickData;

        if (!nodeId)
        {
            return;
        }

        const transition = nodeIdToTransitionHash[nodeId];
        let routeNodesOnMapArray = [];

        if (!!transition)
        {
            const data = isTransitionOnCurrentMap(transition, mainNavIdsRef.current, georeferenceIdRef.current);
            routeNodesOnMapArray = data.routeNodesOnMapArray;
        }

        return { transition, routeNodesOnMapArray };

    }, [renderedNodeIdToTransitionHashRef.current, mainNavIdsRef.current, georeferenceIdRef.current]);

    const handleGetTransitionFromId = useCallback((transitionId) =>
    {
        if (!transitionId)
        {
            return;
        }

        return transitionsRef.current.find((transition) => transition._id === transitionId);

    }, [transitionsRef.current]);
    //#endregion

    //#region UNDO REDO HANDLERS

    const handleUndoTransitionChanges = useCallback(async (step) =>
    {
        if (!step)
        {
            return;
        }

        let transitions = deepCopy(transitionsRef.current);

        let transitionStepData = new TransitionStep(step.data);

        let deletedTransitions = [];
        let addedTransitions = [];
        let updatedTransitionsAfter = [];

        // if added transitions - remove the added transitions from the map 
        if (Array.isArray(transitionStepData.addedTransitions))
        {
            // remove each added transition.
            transitionStepData.addedTransitions.forEach((addedTransition) =>
            {
                // remove the transition from the local array for validation checks
                let idxOfAddedTransition = transitions.findIndex((transition) => transition._id === addedTransition._id);
                transitions.splice(idxOfAddedTransition, 1);

                // for the map 
                deletedTransitions.push(addedTransition);
            });

            // update local DB
            await mapEditorLocalDb.deleteMany(CollectionNameMap.transitions, transitionStepData.addedTransitions);
        }
        // if deleted transitions -> add the deleted transitions back to the map 
        if (Array.isArray(transitionStepData.deletedTransitions))
        {
            // if step tool was auto link (redoing undone auto linked changes) && is auto link  
            // -> add to auto link chain
            transitionStepData.deletedTransitions.forEach((deletedTransition) =>
            {
                transitions.push(deletedTransition);

                // for the map
                addedTransitions.push(deletedTransition);
            });

            // update local DB
            await mapEditorLocalDb.insertMany(CollectionNameMap.transitions, transitionStepData.deletedTransitions);
        }
        // if updated transitions -> revert the transitions to their state before the update
        if (Array.isArray(transitionStepData.updatedTransitionsBefore))
        {
            transitionStepData.updatedTransitionsBefore.forEach((updatedTransition) =>
            {
                let idxOfUpdatedTransition = transitions.findIndex((transition) => transition._id === updatedTransition._id);

                transitions[idxOfUpdatedTransition] = updatedTransition;

                updatedTransitionsAfter.push(updatedTransition);

            });

            await mapEditorLocalDb.updateMany(CollectionNameMap.transitions, transitionStepData.updatedTransitionsBefore);
        }

        // update refs
        setTransitions(transitions);

        editorContext.handleAddStepToActionQueue({
            addedTransitions,
            updatedTransitionsAfter,
            deletedTransitions,
        });

    }, [transitionsRef.current, editorContext.handleAddStepToActionQueue]);

    const handleRedoTransitionChanges = useCallback(async (step) =>
    {
        // reverse the steps objects and run undo logic
        let stepData = new TransitionStep({

            addedTransitions: step.data.deletedTransitions,
            deletedTransitions: step.data.addedTransitions,
            updatedTransitionsAfter: step.data.updatedTransitionsBefore,
            updatedTransitionsBefore: step.data.updatedTransitionsAfter,

        });

        step.data = stepData;

        handleUndoTransitionChanges(step);

    }, [handleUndoTransitionChanges]);

    const handleUndoRedoClick = useCallback((shortcut) =>
    {
        if (!editorContext?.state?.isLevelLockedByUser || isUndoLocked)
        {
            return;
        }
        switch (shortcut)
        {
            case MAP_EDITOR_SHORTCUTS.UNDO:
                {
                    onUndo(handleUndoTransitionChanges);
                    break;
                }
            case MAP_EDITOR_SHORTCUTS.REDO:
                {
                    onRedo(handleRedoTransitionChanges);
                    break;
                }
        }

    }, [handleUndoTransitionChanges, handleRedoTransitionChanges, isUndoLocked, editorContext?.state?.isLevelLockedByUser]);

    // use effect to watch for outside undo redo
    useEffect(() =>
    {
        let undoRedoButtonClick = editorContext?.state?.undoRedoButtonClick;

        if (undoRedoButtonClick === undefined)
        {
            return;
        }
        else 
        {
            handleUndoRedoClick(undoRedoButtonClick);
        }

        editorContext.handleSetUndoRedoButtonClick(undefined);

    }, [editorContext?.state?.undoRedoButtonClick]);

    const handleEscapeClick = useCallback(() =>
    {
        editorContext.handleChangeSelectedTool(undefined);
    }, [editorContext]);

    useKeyboardShortcut("Control", [MAP_EDITOR_SHORTCUTS.UNDO, MAP_EDITOR_SHORTCUTS.REDO], (shortcut) => handleUndoRedoClick(shortcut));
    useSingleKeyboardShortcut("Escape", handleEscapeClick);

    //#endregion

    return (
        <TransitionsEditorMap
            isSamePlottingLevel={isSamePlottingLevelMemo}
            navIds={mainNavIdsMemo}
            georeferenceNavIds={georeferenceNavIdsMemo}
            onGetTransitionFromMapClick={handleGetTransitionFromMapClick}
            onGetTransitionFromId={handleGetTransitionFromId}
            onCreateTransition={handleCreateTransition}
            onDeleteTransition={handleDeleteTransition}
            onEditTransition={handleEditTransition} />
    )
}