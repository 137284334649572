import { useQuery } from "react-query";
import mapEditiorApi from "../../../_api/mapEditior.api";

const useMapEditorPermission = () =>
{
    const permissionsQuery = useQuery(["map-editor-permission"], async () =>
    {

        let hasPermissions = false;
        try
        {
            const response = await mapEditiorApi.mapEditPermission();

            if (response.success)
            {
                hasPermissions = true;
            }

        }
        catch (err)
        {
            console.error("err", err);

        }

        return { hasPermissions };

    }, { enabled: true });


    return { permissionsQuery };
};

export default useMapEditorPermission;
