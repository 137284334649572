import React from "react";
import IconsManagementSelectedIcon from "./IconsManagementSelectedIcon/IconsManagementSelectedIcon";
import IconsManagementColorsFilters from "./IconsManagementColorsFilters/IconsManagementColorsFilters";
import IconsManagementThemeFilters from "./IconsManagementThemeFilters/IconsManagementThemeFilters";
import "./IconsManagementSidebar.css";
import { useIconsManagementContext } from "../IconsManagementContext";
import { ICON_THEMES } from "../constant";

function IconsManagementSidebar() 
{
    const { iconsSearchAndFilters } = useIconsManagementContext();
    return (
        <div className="icons-management-sidebar">
            <IconsManagementSelectedIcon />
            <IconsManagementThemeFilters />
            {iconsSearchAndFilters.filters.theme !== ICON_THEMES.NONE && (
                <IconsManagementColorsFilters />
            )}
        </div>
    );
}

export function IconsInfoSection({ children }) 
{
    return <div className="icons-info-section">{children}</div>;
}

export default IconsManagementSidebar;
