import React, { useContext } from "react";
import { useSetState } from "ahooks";
import { useTranslation } from "react-i18next";
import { DynamicMapLayersContext } from "../../../store/DynamicMapLayersContext";
import { MaintenanceContext } from "../../../store/MaintenanceContext";
import { MapOverlaysContext } from "../../../store/MapOverlaysContext";
import { DYNAMIC_MAP_LAYERS_MODES, MAINTENANCE_MODES, MAP_OVERLAYS_MODES, SINGLE_LANG_INPUT_CODE } from "../../../_constants/constants";
import { DropdownhSubheader } from "../../layout/Subheader";
import DynamicMapLayerCancelConfirmationModal from "./DynamicMapLayerCancelConfirmationModal";
import MapOverlayCancelConfirmationModal from "./MapOverlayCancelConfirmationModal";

const MaintainenanceSubheaderLeft = ({ dynamicContext }) =>
{

    const [state, setState] = useSetState({
        mapOverlayCancelConfirmation: false,
        dynamicMapLayerCancelConfirmation: false,
        newProperty: undefined,
        newBuilding: undefined
    });

    const maintenanceCtx = useContext(MaintenanceContext);
    const { mode: maintenanceMode } = maintenanceCtx.state;

    const mapOverlayCtx = useContext(MapOverlaysContext);
    const { mode: mapOverlayMode } = mapOverlayCtx.state;
    const { dynamicMapLayerCreationState } = mapOverlayCtx;

    const dynamicMapLayerCtx = useContext(DynamicMapLayersContext);
    const { mode: dynamicMapLayerMode } = dynamicMapLayerCtx.state;

    const checkMapOverlayStatus = (newProperty, newBuilding) =>
    {
        if (mapOverlayMode === MAP_OVERLAYS_MODES.CREATE_OVERLAY 
            || mapOverlayMode === MAP_OVERLAYS_MODES.EDIT_OVERLAY
            || mapOverlayMode === MAP_OVERLAYS_MODES.BULK_CREATION)
        {
            setState({
                mapOverlayCancelConfirmation: true,
                newProperty,
                newBuilding
            });
        }
        // if mapOverlay creation was initiated by dml 
        else if (dynamicMapLayerCreationState.enabled)
        {
            setState({
                dynamicMapLayerCancelConfirmation: true,
                newProperty,
                newBuilding
            });
        }
        else
        {
            mapOverlayCtx.resetContext();
            changeSelectedLocationIds(newProperty, newBuilding);
        }
    };

    const checkDynamicMapLayerStatus = (newProperty, newBuilding) =>
    {
        if (dynamicMapLayerMode === DYNAMIC_MAP_LAYERS_MODES.CREATE_DYNAMIC_MAP_LAYER 
            || dynamicMapLayerMode === DYNAMIC_MAP_LAYERS_MODES.MULTI_CREATION)
        {
            setState({
                dynamicMapLayerCancelConfirmation: true,
                newProperty,
                newBuilding
            });
        }
        else
        {
            dynamicMapLayerCtx.resetContext();
            changeSelectedLocationIds(newProperty, newBuilding);
        }
    };

    const onLocationChange = (newProperty, newBuilding) =>
    {
        if (maintenanceMode === MAINTENANCE_MODES.MAP_OVERLAYS)
        {
            checkMapOverlayStatus(newProperty, newBuilding);
        }
        else if (maintenanceMode === MAINTENANCE_MODES.DYNAMIC_MAP_LAYERS)
        {
            checkDynamicMapLayerStatus(newProperty, newBuilding);
        }
        else
        {
            changeSelectedLocationIds(newProperty, newBuilding);
        }
    };

    const changeSelectedLocationIds = (newProperty, newBuilding) =>
    {
        if (newProperty)
        {
            dynamicContext.changeSelectedProperty(newProperty);
        }
        else if (newBuilding)
        {
            if (newBuilding === -1)
            {
                dynamicContext.changeSelectedBuilding(undefined);
            }
            else
            {
                dynamicContext.changeSelectedBuilding(newBuilding);
            }
        }
    };

    const trans = useTranslation().t;
    return (
        <>
            <DropdownhSubheader
                title={trans("Subheader.Property")}
                dropdownContent={dynamicContext.state.properties}
                nameKey="name"
                langKey={SINGLE_LANG_INPUT_CODE}
                selectedKey={dynamicContext.state.propertyId}
                isActive={!dynamicContext.state.buildingId}
                disabledThreshold={1}
                onOptionClick={(propertyId) => onLocationChange(propertyId, undefined)}
                idToDisplayKey="propertyId" 
            />

            <DropdownhSubheader
                title={trans("Subheader.Building")}
                dropdownContent={dynamicContext.state.properties && dynamicContext.state.properties[dynamicContext.state.propertyId]
                    && dynamicContext.state.properties[dynamicContext.state.propertyId].buildings}
                nameKey="longName"
                langKey={SINGLE_LANG_INPUT_CODE}
                selectedKey={dynamicContext.state.buildingId}
                isActive={!!dynamicContext.state.buildingId}
                disabledThreshold={0}
                onOptionClick={(buildingId) => 
                {
                    // to facilitate building deselection
                    let newBuildingId = -1;
                    if (buildingId)
                    {
                        newBuildingId = buildingId;
                    }
                    onLocationChange(undefined, newBuildingId);
                }}
                disabledText={trans("Subheader.No_Buildings")}
                unselect={true} 
                idToDisplayKey="buildingId"
            />

            <MapOverlayCancelConfirmationModal
                open={state.mapOverlayCancelConfirmation}
                onClose={() => setState({
                    mapOverlayCancelConfirmation: false,
                    newMode: undefined
                })}
                onCancel={() => 
                {
                    mapOverlayCtx.resetContext();
                    setState({
                        mapOverlayCancelConfirmation: false,
                        newMode: undefined
                    });
                    changeSelectedLocationIds(state.newProperty, state.newBuilding);
                }}
                isCreate={mapOverlayMode === MAP_OVERLAYS_MODES.CREATE_OVERLAY || mapOverlayMode === MAP_OVERLAYS_MODES.BULK_CREATION}
            />

            <DynamicMapLayerCancelConfirmationModal
                open={state.dynamicMapLayerCancelConfirmation}
                onClose={() => setState({
                    dynamicMapLayerCancelConfirmation: false,
                    newMode: undefined
                })}
                onCancel={() =>
                {
                    dynamicMapLayerCtx.resetContext();
                    if (dynamicMapLayerCreationState.enabled)
                    {
                        mapOverlayCtx.resetContext();
                    }
                    setState({
                        dynamicMapLayerCancelConfirmation: false,
                        newMode: undefined
                    });
                    changeSelectedLocationIds(state.newProperty, state.newBuilding);
                }}
            />
        </>
    );
};

export default MaintainenanceSubheaderLeft;