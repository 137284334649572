import { POSSIBLE_VALIDATION_STATUS, VALIDATION_STATUS_META_DATA, possibleValidationDataIssueErrorsHash } from "../_constants/ValidationInfo";
import { DATE_TIME_FORMAT } from "../components/maintenance/utils";
import { formatDate } from "./utils";



/**
 * Merges data issue errors into the info hash.
 *
 * @param {object} dataIssuesHash - The info hash to merge data issue errors into
 * @param {array} dataIssueErrors - The array of data issue errors to merge
 * @return {object} The updated info hash with merged data issue errors
 */
export const mergeDataIssuesIntoInfoHash = (dataIssuesHash, dataIssueErrors) =>
{

    if (Array.isArray(dataIssueErrors) && dataIssueErrors.length > 0)
    {
        dataIssueErrors.forEach((de) =>
        {
            const pre = getValidationItemDetails(de);
            if (dataIssuesHash[pre.dataIssueCode])
            {
                dataIssuesHash[pre.dataIssueCode].push(pre);
            }
            else
            {
                dataIssuesHash[pre.dataIssueCode] = [pre];
            }
        });
    }

    return dataIssuesHash;
};


export const updateBuildingFloorHashErrors = (hash, dataIssueErrors) =>
{

    if (Array.isArray(dataIssueErrors) && dataIssueErrors.length > 0)
    {
        dataIssueErrors.forEach((de) =>
        {
            const { floorId, floorName, buildingId } = de;

            if (floorId > -1)
            {
                if (hash[buildingId].floorsHash?.[floorId])
                {
                    hash[buildingId].floorsHash[floorId].dataIssuesHash = mergeDataIssuesIntoInfoHash(hash[buildingId].floorsHash[floorId].dataIssuesHash, [de]);
                }
                else
                {
                    hash[buildingId].floorsHash[floorId] = {
                        title: "Floor: " + floorName,
                        floorsHash: {},
                        dataIssuesHash: {},
                        totalCount: 0,
                        className: "errorbox-content-level2",
                    };
                    hash[buildingId].floorsHash[floorId].dataIssuesHash = mergeDataIssuesIntoInfoHash(hash[buildingId].floorsHash[floorId].dataIssuesHash, [de]);
                }
                hash[buildingId].floorsHash[floorId].totalCount++;
            }
            else if (floorId == -1)
            {
                hash[buildingId].dataIssuesHash = mergeDataIssuesIntoInfoHash(hash[buildingId].dataIssuesHash, [de]);
            }
            hash[buildingId].totalCount++;
        });
    }



    return hash;


};


/**
 * Creates a new data issue with the given parameters.
 *
 * @param {string} dataIssueCode - The code of the data issue
 * @param {number} dataIssuePriority - The priority of the data issue
 * @param {string} message - The message describing the data issue
 * @param {object} validationPropertyMetaInfo - The validationPropertyMetaInfo object containing additional properties:
 *                           - {number} cmsPropertyId - The ID of the CMS property
 *                           - {number} cmsBuildingId - The ID of the CMS building
 *                           - {number|null} cmsFloorId - The ID of the CMS floor (default: null)
 *                           - {number} floorId - The ID of the floor (default: -1)
 *                           - {string|null} floorName - The name of the floor (default: null)
 *                           - {number} propertyId - The ID of the property (default: -1)
 *                           - {number} buildingId - The ID of the building (default: -1)
 *                           - {string|null} buildingName - The name of the building (default: null)
 *                           - {string|null} propertyName - The name of the property (default: null)
 * @return {object} A new data issue object with the specified properties
 */
export const createNewDataIssue = (dataIssueCode, dataIssuePriority, message, { createdAt, updatedAt, cmsPropertyId, cmsBuildingId, cmsFloorId = null, floorId = -1, floorName = null, propertyId = -1, buildingId = -1, buildingName = null, propertyName = null }) => ({
    dataIssueCode,
    dataIssuePriority,
    floorId,
    propertyId,
    buildingId,
    buildingName,
    propertyName,
    message,
    floorName,
    cmsPropertyId,
    cmsBuildingId,
    cmsFloorId,
    createdAt,
    updatedAt
});

/**
 * Creates a revalidation data issue based on the provided parameters.
 *
 * @param {object} validationPropertyMetaInfo - The validationPropertyMetaInfo object with the following properties:
 *   - {number} propertyId - The ID of the property (default is -1)
 *   - {number} buildingId - The ID of the building (default is -1)
 *   - {string} buildingName - The name of the building (default is null)
 *   - {string} propertyName - The name of the property (default is null)
 *   - {number} cmsPropertyId - The CMS ID of the property
 *   - {number} cmsBuildingId - The CMS ID of the building
 * @return {object} The created revalidation data issue
 */
export const createRevalidationItem = ({ propertyId = -1, buildingId = -1, buildingName = null, propertyName = null, cmsPropertyId, cmsBuildingId, createdAt, updatedAt }) =>
{
    let item;
    if (buildingId == -1)
    {
        item = createNewDataIssue("PROPERTY_RE_VALIDATION_REQUIRED", "warning", "Looks like their are some edits on property Please revalidate", { cmsPropertyId, cmsBuildingId, propertyId, buildingId, buildingName, propertyName, createdAt, updatedAt });
    }
    else
    {
        item = createNewDataIssue("BUILDING_RE_VALIDATION_REQUIRED", "warning", "Looks like their are some edits on building Please revalidate", { cmsPropertyId, cmsBuildingId, propertyId, buildingId, buildingName, propertyName, createdAt, updatedAt });
    }

    return getValidationItemDetails(item);
};



/**
 * Returns the validation status of a record based on its validity and data issue errors.
 *
 * @param {object} param0 - An object containing isValid and dataIssueErrors
 * @return {string} The validation status of the record
 */
export const getValidationStatusOfRecord = ({ isValid, dataIssueErrors }) =>
{
    if (!isValid)
    {
        if (dataIssueErrors.length > 0)
        {
            return POSSIBLE_VALIDATION_STATUS.RECORDS_FOUND_WITH_ERRORS;
        }
        else
        {
            return POSSIBLE_VALIDATION_STATUS.RECORDS_HAS_IN_VALID_VALIDATION;
        }
    }
    else
    {
        return POSSIBLE_VALIDATION_STATUS.RECORDS_HAS_SUCCESS_FUL_VALIDATION;
    }
};


/**
 * Creates a successful validation item based on the provided parameters.
 *
 * @param {Object} param - An object containing the parameters for creating the validation item
 * @param {number} param.propertyId - The ID of the property
 * @param {number} param.buildingId - The ID of the building
 * @param {string} param.buildingName - The name of the building
 * @param {string} param.propertyName - The name of the property
 * @param {string} param.cmsPropertyId - The ID of the property in the CMS
 * @param {string} param.cmsBuildingId - The ID of the building in the CMS
 * @return {Object} The created validation item
 */
export const createSuccessFulValidationItem = ({ propertyId = -1, buildingId = -1, buildingName = null, propertyName = null, cmsPropertyId, cmsBuildingId, createdAt, updatedAt }) =>
{
    let item;
    if (buildingId == -1)
    {
        item = createNewDataIssue("PROPERTY_VALIDATION_SUCCESS", "success", `Property : ${propertyName}  Validation Successful`, { cmsPropertyId, cmsBuildingId, propertyId, buildingId, buildingName, propertyName, createdAt, updatedAt });
    }
    else
    {
        item = createNewDataIssue("BUILDING_VALIDATION_SUCCESS", "success", `Building : ${buildingName}  Validation Successful`, { cmsPropertyId, cmsBuildingId, propertyId, buildingId, buildingName, propertyName, createdAt, updatedAt });
    }
    return getValidationItemDetails(item);
};


export const createNoRecordsFoundValidationItem = () => getValidationItemDetails(createNewDataIssue("NO_RECORDS_FOUND", "warning", "No records found", { cmsPropertyId: -1, cmsBuildingId: -1, propertyId: -1, buildingId: -1, buildingName: null, propertyName: null, createdAt: new Date().toISOString(), updatedAt: new Date().toISOString() }));


/**
 * Retrieves details for a validation item.
 *
 * @param {object} dataIssuePriority - the priority of the data issue
 * @param {string} message - the message associated with the data issue
 * @param {string} dataIssueCode - the code of the data issue
 * @return {object} the details of the validation item
 */
export function getValidationItemDetails(vdItem)
{
    const { dataIssuePriority, message, dataIssueCode: validationItemCode, createdAt, updatedAt } = vdItem;
    if (!validationItemCode)
    {
        return {};
    }


    function replaceUnderscoreAndCapitalize(text)
    {
        // Replace underscores with spaces
        let withoutUnderscore = text.replace(/_/g, " ");

        // Capitalize the first letter of each word
        let capitalizedText = withoutUnderscore.toLowerCase().replace(/\b\w/g, (match) => match.toUpperCase());

        return capitalizedText;
    }
    const validationDataIssueDetails = possibleValidationDataIssueErrorsHash?.[validationItemCode] ?? possibleValidationDataIssueErrorsHash["Unknown"];
    const title = replaceUnderscoreAndCapitalize(validationItemCode);

    const preparedValidationDataIssueDetails = {
        ...vdItem,
        key: validationItemCode,
        title,
        shortInfo: validationDataIssueDetails?.["Missing_Data_Issues"] ?? "-NA-",
        errorCategory: validationDataIssueDetails?.["Category"] ?? "-NA-",
        messageContent: message.trim().length ? message : validationDataIssueDetails?.["Missing_Data_Issues"] ?? "-NA-",
        itemType: dataIssuePriority.toLowerCase(),
        createdFormattedDate: formatDate(new Date(createdAt), DATE_TIME_FORMAT),
        updatedFormattedDate: formatDate(new Date(updatedAt), DATE_TIME_FORMAT),
        className: "errorbox-content-section",
    };

    return preparedValidationDataIssueDetails;
}
/**
 * Returns a new object with filtered validation information based on the building ID.
 *
 * @param {object} validationInfo - The original validation information object
 * @param {string} buildingId - The ID of the building to filter by
 * @return {object} The filtered validation information object
 */
export const getFilteredValidationInfoByBuildingId = (validationInfo, buildingId) =>
{
    if (!validationInfo) return {};

    if (!buildingId) return validationInfo;

    return validationInfo[buildingId] ?? {};
};





/**
 * Returns the top priority validation info meta hash based on the given validation status's array.
 *
 * @param {Array} validationStatus - The array of validation statuses
 * @return {string} The top priority validation info meta hash
 */
export const getTopPriorityValidationInfoMetaHash = (validationStatus = []) =>
{

    if (validationStatus.length == 0)
    {
        return VALIDATION_STATUS_META_DATA[POSSIBLE_VALIDATION_STATUS.RECORDS_NOT_FOUND];
    }

    const sortedDataBasedOnPriority = validationStatus.sort((vka, vkb) =>
    {
        const vsa = VALIDATION_STATUS_META_DATA[vka];
        const vsb = VALIDATION_STATUS_META_DATA[vkb];

        return vsa.priory - vsb.priory;

    });


    const topPriorityKey = sortedDataBasedOnPriority[0];
    return VALIDATION_STATUS_META_DATA[topPriorityKey];
};




/**
 * Processes the validation query and generates a prepared building hash and a set of validation statuses.
 *
 * @param {Object} query - the validation query object
 * @return {Object} an object containing the prepared building hash and a list of unique validation statuses
 */
export const processValidationQuery = (query) =>
{
    let preparedBuildingHash = {};
    let validationStatusSet = new Set();

    const { data: validationRecords, isSuccess } = query;

    if (isSuccess && Array.isArray(validationRecords) && validationRecords.length > 0)
    {


        validationRecords.forEach((validationRecord) =>
        {
            const validationStatus = getValidationStatusOfRecord(validationRecord);
            validationStatusSet.add(validationStatus);
            const { dataIssueErrors, propertyId, buildingId, buildingName, propertyName, createdAt, updatedAt } = validationRecord;

            if (buildingId == -1)
            {
                if (!preparedBuildingHash[buildingId])
                {

                    preparedBuildingHash[buildingId] = {
                        className: "errorbox-level-col1",
                        buildingId,
                        title: `${propertyName} Property Validation ${VALIDATION_STATUS_META_DATA[validationStatus].name}`,
                        dataIssuesHash: {},
                        floorsHash: {},
                        validationStatusMeta: VALIDATION_STATUS_META_DATA[validationStatus],
                        totalCount: 0,
                        validationStatus,
                        info: null

                    };
                    if (validationStatus === POSSIBLE_VALIDATION_STATUS.RECORDS_FOUND_WITH_ERRORS)
                    {
                        preparedBuildingHash[buildingId].dataIssuesHash = mergeDataIssuesIntoInfoHash(preparedBuildingHash[buildingId].dataIssuesHash, dataIssueErrors);
                        preparedBuildingHash[buildingId].totalCount += dataIssueErrors.length;

                    }

                    if (validationStatus === POSSIBLE_VALIDATION_STATUS.RECORDS_HAS_IN_VALID_VALIDATION)
                    {
                        preparedBuildingHash[buildingId].dataIssuesHash = {};
                        const invalidItem = createRevalidationItem(validationRecord);
                        preparedBuildingHash[buildingId].info = invalidItem;
                        preparedBuildingHash[buildingId].totalCount += 1;
                    }

                    if (validationStatus === POSSIBLE_VALIDATION_STATUS.RECORDS_HAS_SUCCESS_FUL_VALIDATION)
                    {
                        const successItem = createSuccessFulValidationItem(validationRecord);
                        preparedBuildingHash[buildingId].info = successItem;
                        preparedBuildingHash[buildingId].totalCount += 1;
                    }
                }
            }
            else if (buildingId > -1)
            {
                if (!preparedBuildingHash[buildingId])
                {
                    const buildingNameWords = buildingName.split(" ");
                    const isLastItemHashBuildingString = buildingNameWords[buildingNameWords.length - 1].toLowerCase() === "building";
                    preparedBuildingHash[buildingId] = {
                        className: "errorbox-level-col1",
                        buildingId,
                        title: `${buildingName} ${isLastItemHashBuildingString ? "Validation" : "Building Validation"}  ${VALIDATION_STATUS_META_DATA[validationStatus].name}`,
                        dataIssuesHash: {},
                        floorsHash: {},
                        validationStatusMeta: VALIDATION_STATUS_META_DATA[validationStatus],
                        totalCount: 0,
                        validationStatus,
                        info: null,
                    };
                }
                if (validationStatus === POSSIBLE_VALIDATION_STATUS.RECORDS_FOUND_WITH_ERRORS)
                {
                    preparedBuildingHash = updateBuildingFloorHashErrors(preparedBuildingHash, dataIssueErrors);
                }

                if (validationStatus === POSSIBLE_VALIDATION_STATUS.RECORDS_HAS_IN_VALID_VALIDATION)
                {
                    preparedBuildingHash[buildingId].dataIssuesHash = {};
                    const invalidItem = createRevalidationItem(validationRecord);
                    invalidItem.className = "errorbox-content-section";
                    preparedBuildingHash[buildingId].info = invalidItem;
                    preparedBuildingHash[buildingId].totalCount += 1;
                }

                if (validationStatus === POSSIBLE_VALIDATION_STATUS.RECORDS_HAS_SUCCESS_FUL_VALIDATION)
                {
                    const successItem = createSuccessFulValidationItem(validationRecord);
                    successItem.className = "errorbox-content-section";
                    preparedBuildingHash[buildingId].info = successItem;
                    preparedBuildingHash[buildingId].totalCount += 1;
                }
            }

        });
    }

    return { preparedBuildingHash, validationStatus: [...validationStatusSet] };

};


const getOnlyValidationErrorHash = (validationInfoHash) => Object.keys(validationInfoHash).reduce((acc, key) =>
{
    if (validationInfoHash[key].validationStatus === POSSIBLE_VALIDATION_STATUS.RECORDS_FOUND_WITH_ERRORS)
    {
        acc[key] = validationInfoHash[key];
    }
    return acc;
}, {});


export const getFilterValidationErrorsBasedOnBuildingId = (validationInfoHash, navBuildingId = null) =>
{
    const errorHash = getOnlyValidationErrorHash(validationInfoHash);

    if (navBuildingId)
    {
        return errorHash?.[navBuildingId] ?? {};
    }

    return errorHash;

};
/**
 * Updates the active selected keys array based on the newly selected key,
 * the index of the newly selected key, and the prepared validation hash.
 * The updated active selected keys are returned.
 *
 * @param {Array} activeSelectedKeys - The array of active selected keys.
 * @param {string} newlySelectedKey - The newly selected key.
 * @param {number} newlySelectedLevelIndex - The index of the newly selected key.
 * @param {object} preparedValidationHash - The prepared validation hash.
 * @return {Array} The updated active selected keys.
 */
export const updateActiveSelectionItemsKeys = (
    activeSelectedKeys,
    newlySelectedKey,
    newlySelectedLevelIndex,
    preparedValidationHash) =>
{
    // Update the active selected keys array
    let updatedActiveSelectedKeys = activeSelectedKeys.map((e, i) =>
    {
        if (newlySelectedLevelIndex === i)
        {
            return newlySelectedKey;
        }

        if (i > newlySelectedLevelIndex)
        {
            return null;
        }
        return e;
    });

    // Filter out the null values from the active selected keys array
    const filteredKeys = updatedActiveSelectedKeys.filter((e) => !!e);

    // Get the active level items from the selected keys
    const activeLevelItemsFormSelectedKeys = filteredKeys.reduce((acc, keyHash) =>
    {
        const keys = keyHash.split(".");
        return keys.reduce((a, b) => a[b], acc);
    }, preparedValidationHash);

    // If active level items exist, update the active selected keys based on the active level items
    if (activeLevelItemsFormSelectedKeys)
    {
        if (activeLevelItemsFormSelectedKeys?.dataIssuesHash
            && Object.keys(activeLevelItemsFormSelectedKeys.dataIssuesHash).length > 0)
        {
            updatedActiveSelectedKeys[filteredKeys.length] =
                "dataIssuesHash." + Object.keys(activeLevelItemsFormSelectedKeys.dataIssuesHash)[0];
        }
        else if (activeLevelItemsFormSelectedKeys?.floorsHash
            && Object.keys(activeLevelItemsFormSelectedKeys.floorsHash).length > 0)
        {
            const defaultFloorKey = Object.keys(activeLevelItemsFormSelectedKeys.floorsHash)[0];
            updatedActiveSelectedKeys[filteredKeys.length] =
                "floorsHash." + defaultFloorKey;

            const floorData = activeLevelItemsFormSelectedKeys.floorsHash[defaultFloorKey];
            if (floorData.dataIssuesHash && Object.keys(floorData.dataIssuesHash).length > 0)
            {
                updatedActiveSelectedKeys[filteredKeys.length + 1] =
                    "dataIssuesHash." + Object.keys(floorData.dataIssuesHash)[0];
            }
        }
    }

    return updatedActiveSelectedKeys;
};
