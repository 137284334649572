import React, { useState, useRef, useMemo } from "react";
import { object, string, number } from "yup";
import { Input, Button } from "semantic-ui-react";
import { useMutation, useQueryClient } from "react-query";
import { SINGLE_LANG_INPUT_CODE } from "../../../../../_constants/constants";
import { useCloseTriggerModal, useDeepState, useLoadingPool } from "../../../../../_utils/hooks";
import { InputGroupWrap } from "../../../../branding/InputGroupWrap";
import { ModalLayout } from "../../../../popups/ModalLayout";
import { BLANK_ITEM } from "../functions";
import "./AddInventoryModal.css";
import { ErrorLabel } from "../../../../elements/ErrorLabel";
import brandingApi from "../../../../../_api/branding.api";
import { Loader } from "../../../../elements/loader";
import { useTranslation } from "react-i18next";
import serverApi from "../../../../../_api/server.api";
import { handleNormalizeUrl } from "../../../../../_utils/utils";
import { useSelectedEntity } from "../../../../../store/BrandingContext";
import { INVENTORY } from "../../../../../_constants/config";

const FORM_SCHEMA = object().shape({
    code: string().required("Inventory Code is required"),
    name: object().shape({
        [SINGLE_LANG_INPUT_CODE]: string().required("Inventory Name is required")
    }),
    quantity: number().typeError("Please enter a valid number").integer("Please enter a valid number").max(999999).min(0).required("Please enter a valid number"),
    link: object().shape({
        [SINGLE_LANG_INPUT_CODE]: string().test(
            "TEST_WEBSITE",
            "Please enter a valid URL",
            (value) => value === "" || serverApi.validate("website", value).catch(() => false)
        )
    })
});


export const AddInventoryModal = ({ trigger, index = undefined, inventory }) =>
{
    const queryClient = useQueryClient();
    const trans = useTranslation().t;
    const loadingPool = useLoadingPool();

    const entity = useSelectedEntity();
    const [state, updateState, setState] = useDeepState(BLANK_ITEM);
    const inputRefs = useRef({});

    const isEdit = useMemo(() => index !== undefined, [index]);

    const [errors, setErrors] = useState([]);
    const [open, close] = useCloseTriggerModal();

    const {
        mutate: createInventory,
        ...createInvMutation
    } = useMutation(
        brandingApi.createInventory,
        { mutationKey: "CREATE_INVENTORY", onSuccess: close, onError: console.error, onSettled: () => queryClient.refetchQueries("FIND_INVENTORIES")  }
    );
    const {
        mutate: updateInventory,
        ...updateInvMutation
    } = useMutation(
        ({ _id, ...body }) => brandingApi.updateInventory(_id, body),
        { mutationKey: "UPDATE_INVENTORY", onSuccess: close, onError: console.error, onSettled: () => queryClient.refetchQueries("FIND_INVENTORIES") }
    );

    const isLoading = useMemo(
        () => [createInvMutation.isLoading, updateInvMutation.isLoading].some((loading) => loading),
        [createInvMutation.isLoading, updateInvMutation.isLoading]
    );

    const resetState = () =>
    {
        if (!isEdit)
        {
            setState(BLANK_ITEM);
        }
        else
        {
            setState(inventory);
        }
        setErrors([]);
    };

    const handleSave = () =>
    {
        const loadingId = loadingPool.add();
        Object.values(inputRefs.current).forEach((input) =>
        {
            input.inputRef.current.classList.remove("error");
        });
        setErrors([]);

        if (!entity?._id)
        {
            console.log("no entity");
            return;
        }
        const entityLabel = entity?.entityLabel;

        state.link[SINGLE_LANG_INPUT_CODE] = handleNormalizeUrl(state.link[SINGLE_LANG_INPUT_CODE]);
        FORM_SCHEMA.validate(state, { abortEarly: false })
            .then((values) =>
            {
                values["entityLabelId"] = entityLabel._id;
                values["companyUID"] = entityLabel.companyUID;

                if (!isEdit)
                {
                    createInventory(values, { onSuccess: () => queryClient.refetchQueries("FIND_INVENTORIES") });
                }
                else
                {
                    updateInventory(values, { onSuccess: () => queryClient.refetchQueries("FIND_INVENTORIES") });
                }
            })
            .catch((errors) =>
            {
                console.log(errors);
                const schemaErrors = errors.inner.map((err, index) =>
                {
                    if (index === 0)
                    {
                        inputRefs.current[err.path].focus();

                    }

                    inputRefs.current[err.path].inputRef.current.classList.add("error");
                    return ({ [err.path]: err.message });
                });
                setErrors(schemaErrors);
            })
            .finally(() => loadingPool.remove(loadingId));
    };

    return (
        <>
            <ModalLayout
                className="addInventoryModal"
                trigger={trigger}
                heading={isEdit ? trans("AddInventoryModal.Edit_Inv") : trans("AddInventoryModal.Add_Inv")}
                actions={<Button secondary content={isEdit ? trans("AddInventoryModal.Edit_Inv") : trans("AddInventoryModal.Add_Inv")} onClick={handleSave} />}
                open={open}
                onOpen={() => resetState()}
            >
                <p className="p-modal">{trans("AddInventoryModal.Add_items_to_create_and_keep_track_of_pr")}</p>

                {errors.length > 0 && <ErrorLabel small content={Object.values(errors[0])[0]} />}

                <InputGroupWrap label={trans("AddInventoryModal.Inventory_Code")}>
                    <Input
                        placeholder={trans("AddInventoryModal.e_g__123")}
                        value={state.code}
                        onChange={(e, { value }) => updateState("code", value)}
                        ref={(el) => inputRefs.current["code"] = el}
                        maxLength={INVENTORY.MAX_CODE_LENGTH}
                    />
                </InputGroupWrap>

                <div className="inventoryFlex">
                    <InputGroupWrap label={trans("AddInventoryModal.Inventory_Name")}>
                        <Input
                            placeholder={trans("AddInventoryModal.e_g__Adjustable_bracelet_-_silver")}
                            value={state.name?.[SINGLE_LANG_INPUT_CODE]}
                            onChange={(e, { value }) => updateState(`name.${SINGLE_LANG_INPUT_CODE}`, value)}
                            ref={(el) => inputRefs.current[`name.${SINGLE_LANG_INPUT_CODE}`] = el}
                            maxLength={INVENTORY.MAX_NAME_LENGTH}
                        />
                    </InputGroupWrap>
                    <InputGroupWrap label={trans("AddInventoryModal.#_of_Units")}>
                        <Input
                            placeholder={trans("AddInventoryModal.e_g__1")}
                            type="number"
                            value={state.quantity}
                            onChange={(e, { value }) => updateState("quantity", value)}
                            ref={(el) => inputRefs.current["quantity"] = el}
                        />
                    </InputGroupWrap>
                </div>

                {/* <InputGroupWrap label="Upload Inventory Images">
                <div className="dashedBox uploadDashedBox">
                    <Header icon content="Drag and Drop files here" subheader="or" />
                    <Button basic primary content="Browse" />
                </div>

                <FilePlaceholder />
                <FilePlaceholder />
            </InputGroupWrap> */}

                <InputGroupWrap label={trans("AddInventoryModal.Purchase_Link")}>
                    <Input
                        placeholder={trans("AddInventoryModal.Link_Placeholder")}
                        value={state.link?.[SINGLE_LANG_INPUT_CODE]}
                        onChange={(e, { value }) => updateState(`link.${SINGLE_LANG_INPUT_CODE}`, value)}
                        ref={(el) => inputRefs.current[`link.${SINGLE_LANG_INPUT_CODE}`] = el}
                        maxLength={INVENTORY.MAX_LINK_LENGTH}
                        onBlur={(e) => updateState(`link.${SINGLE_LANG_INPUT_CODE}`, handleNormalizeUrl(e.target.value))}
                    />
                </InputGroupWrap>

            </ModalLayout>
            <Loader active={isLoading || loadingPool.loading} />
        </>
    );
};
