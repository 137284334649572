import React, { useContext } from "react";
import { MapOverlaysContext } from "../../../../store/MapOverlaysContext";

import "./ZoomLevelIndicator.css";
import { useTranslation } from "react-i18next";

const ZoomLevelIndicator = () =>
{

    const mapOverlayCtx = useContext(MapOverlaysContext);

    const { currentZoom } = mapOverlayCtx.state;

    const trans = useTranslation().t;

    return (
        <div className="zoomLevelIndicator">
            {`${trans("MapstedMapsModule.Zoom_Level")}: ${currentZoom}`}
        </div>
    );
};

export default ZoomLevelIndicator;