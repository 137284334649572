import React from "react";
import { forwardRef } from "react";
import styles from "./ImagePickerAddBlock.module.css";
import { Button } from "semantic-ui-react";
import { useState } from "react";
import { useClickAway } from "../../../../hooks/useClickAway";
import { useTranslation } from "react-i18next";

const ImagePickerAddBlock = forwardRef(
    ({ handleFileChange, handleBulkFileChange, acceptableImageFileFormats, handleWebAddress, bulkUploadEnabled }, ref) => 
    {
        const [showDropdown, setShowDropdown] = useState(false);
        useClickAway(showDropdown, setShowDropdown, styles.dropdown_item);
        const trans = useTranslation().t;

        function toggleDropdown() 
        {
            setShowDropdown((prev) => !prev);
        }

        return (
            <div className={styles.addBlock}>
                <Button onClick={toggleDropdown} className={` ${styles.uploadTrigger}`}>
                    <span>{trans("Gallery.Upload")}</span>
                    <img src="/img/icon-chevron-down-white.svg" alt="expand" />
                </Button>

                <div style=
                    {{
                        display: showDropdown?"block":"none"
                    }} className={styles.dropdown}>
                    <div className={styles.dropdown_item}>
                        <label className={styles.label} htmlFor="img-upload">
                            {trans("Gallery.Upload_Image")}
                        </label>
                        <input
                            onChange={(e) => 
                            {
                                toggleDropdown();
                                handleFileChange(e);
                            }}
                            className={styles.uploadInput}
                            type="file"
                            id="img-upload"
                            ref={ref}
                            accept={acceptableImageFileFormats}
                        />
                    </div>
                    {
                        bulkUploadEnabled && <div className={styles.dropdown_item}>
                            <label className={styles.label} htmlFor="bulk-img-upload">
                                {trans("Gallery.Bulk_Upload")}
                            </label>
                            <input
                                onChange={(e) => 
                                {
                                    toggleDropdown();
                                    handleBulkFileChange(e);
                                }}
                                multiple
                                className={styles.uploadInput}
                                type="file"
                                id="bulk-img-upload"
                                accept={acceptableImageFileFormats}
                            />
                        </div>
                    }
                  
                    <div
                        onClick={() => 
                        {
                            toggleDropdown();
                            handleWebAddress();
                        }}
                        className={styles.dropdown_item}
                    >
                        {trans("Gallery.Web_Address")}
                    </div>

                </div>
            </div>
        );
    }
);

ImagePickerAddBlock.displayName = "ImagePickerAddBlock";

export default ImagePickerAddBlock;
