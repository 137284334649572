import React, {FC} from "react";
import { Placeholder, Image } from "semantic-ui-react";

interface LoadableImageProps {
    as: string;
    src: any;
    alt: string;
    defaultSrc?: any;
    className?: string;
    defaultClass?: string;
}

export const LoadableImage: FC<LoadableImageProps> = ({ as, src, defaultSrc, className, alt, defaultClass, ...props }) =>
{
    const [loading, setLoading] = React.useState(true);
    const [image, setImage] = React.useState(undefined);
    const [error, setError] = React.useState(false);

    React.useEffect(() =>
    {
        setError(false);

        if (src)
        {
            setLoading(true);
            //getBase64FromImageUrl(src, setImage);
            setImage(`${src}?t=${Date.now()}`);
        }
        else
        {
            setImage(undefined);
            setLoading(false);
        }

    }, [src]);

    const handleImageLoaded = React.useCallback( () => setLoading(false), []);
    const handleImageLoadedError = React.useCallback( () =>
    {
        setLoading(false);
        setError(true);
    }, []);

    return (
        <>
            {loading
            && <Placeholder className={`${className}-placeholder`}>
                <Placeholder.Image />
            </Placeholder>
            }
            <Image src={(!error) ? (image || defaultSrc) : defaultSrc}
                   alt={alt}
                   as={as}
                   {...props}
                   style={{ display: loading ? "none" : undefined }}
                   className={`${className} ${error ? defaultClass : ""}`}
                   onLoad={handleImageLoaded}
                   onError={handleImageLoadedError}
            />
        </>
    );
};