import { possibleValidationDataIssueErrorsHash } from "../_constants/ValidationInfo";
import { DEFAULT_LANGUAGE_CODE } from "../_constants/constants";
const { openingHoursGroupedListToPeriods } = require("./utils");


/**
 * Returns an empty structure of entityLabel
 */
export const getDefaultEntityLabel = () =>
{
    let d = {
        longName: { [DEFAULT_LANGUAGE_CODE]: "" },
        shortName: { [DEFAULT_LANGUAGE_CODE]: "" },
        description: { [DEFAULT_LANGUAGE_CODE]: "" },
        category: undefined,
        subCategories: [],
        website: { [DEFAULT_LANGUAGE_CODE]: "" },
        keywords: { [DEFAULT_LANGUAGE_CODE]: [] },
        onlineKeywords: { [DEFAULT_LANGUAGE_CODE]: [] },
        phoneNumber: "",
        isHoursLinkedToRef: true,
        socialMedia: {
            instagram: "",
            facebook: "",
            twitter: ""
        },
        partialOpeningHours: { periods: [] }
    };

    return d;
};

/**
 * Formats entityLabel object to an api friendly version of the object.
 * @param {Object} entityLabel
 */
export const convertToUpdatedEntityLabelInfo = (entityLabel) =>
{
    let subCategories = [];

    (entityLabel.subCategories) && entityLabel.subCategories.forEach((subCategory) =>
    {
        subCategories.push(subCategory._id);
    });


    if (entityLabel.partialOpeningHours)
    {
        let openingHours = entityLabel.partialOpeningHours;
        const periods = openingHoursGroupedListToPeriods(openingHours.partialOpeningHours);
        delete openingHours.partialOpeningHours;

        openingHours.periods = periods;

        entityLabel.partialOpeningHours = openingHours;
    }

    if (entityLabel.isHoursLinkedToRef === undefined)
    {
        entityLabel.isHoursLinkedToRef = true;
    }
    const result = {
        _id: entityLabel._id,
        parentEntityLabel: entityLabel.parentEntityLabel,
        category: (entityLabel.category) && entityLabel.category._id,
        subCategories: subCategories,
        longName: entityLabel.longName || { [DEFAULT_LANGUAGE_CODE]: "" },
        description: entityLabel.description || { [DEFAULT_LANGUAGE_CODE]: "" },
        website: entityLabel.website || { [DEFAULT_LANGUAGE_CODE]: "" },
        shortName: entityLabel.shortName || { [DEFAULT_LANGUAGE_CODE]: "" },
        keywords: entityLabel.keywords || { [DEFAULT_LANGUAGE_CODE]: [] },
        phoneNumber: entityLabel.phoneNumber || "",
        socialMedia: entityLabel.socialMedia,
        entityLabelType: entityLabel.entityLabelType,
        partialOpeningHours: entityLabel.partialOpeningHours,
        isHoursLinkedToRef: entityLabel.isHoursLinkedToRef,
        isImageOnMap: entityLabel.isImageOnMap,
        inventory: entityLabel?.inventory || [],
        onlineKeywords: entityLabel.onlineKeywords || { [DEFAULT_LANGUAGE_CODE]: [] },
        overrideAllowIconRotation: entityLabel.overrideAllowIconRotation || false
    };
    const images = ["iconImage", "lightIcon", "darkIcon"];
    images.forEach((image) =>
    {
        if (entityLabel[image])
        {
            result[image] = entityLabel[image];
        }
    });
    return result;
};

/**
 * Formats entityLabel POI object to an api friendly version of the object.
 * @param {Object} entityLabel
 */
export const convertToUpdatedEntityLabelPOIInfo = (entityLabel) =>
{

    let subCategories = [];
    (entityLabel.subCategories) && entityLabel.subCategories.forEach((subCategory) =>
    {
        subCategories.push(subCategory._id);
    });

    const result = {
        _id: entityLabel._id,
        longName: entityLabel.longName || { [DEFAULT_LANGUAGE_CODE]: "" },
        description: entityLabel.description || { [DEFAULT_LANGUAGE_CODE]: "" },
        website: entityLabel.website || { [DEFAULT_LANGUAGE_CODE]: "" },
        shortName: entityLabel.shortName || { [DEFAULT_LANGUAGE_CODE]: "" },
        category: (entityLabel.category) && entityLabel.category._id,
        subCategories: subCategories,
        keywords: entityLabel.keywords || { [DEFAULT_LANGUAGE_CODE]: [] },
        onlineKeywords: entityLabel.onlineKeywords || { [DEFAULT_LANGUAGE_CODE]: [] },
        //  isImageOnMap: entityLabel.isImageOnMap,
    };

    const images = ["iconImage", "lightIcon", "darkIcon"];
    images.forEach((image) =>
    {
        if (entityLabel[image])
        {
            result[image] = entityLabel[image];
        }
    });
    return result;
};

export const addParentCategoriesForCategories = (categories, rootCategories) =>
{

    Object.values(categories).forEach((c) =>
    {
        c.isRoot = false;
        c.parentCategories = [];
    });

    rootCategories.forEach((categoryUID) =>
    {
        if (categories?.[categoryUID])
        {
            categories[categoryUID].isRoot = true;
        }
    });

    Object.values(categories).forEach((c) =>
    {
        if (!c.subCategories)
        {
            c.subCategories = [];
        }

        c.subCategories.forEach((cUID) =>
        {
            (categories[cUID]) && categories[cUID].parentCategories.push(c._id);
        });
    });
};

export const getEmptyCategory = (isRoot) =>
{
    const category = {
        isRoot,
        name: "",
        parentCategories: [],
        subCategories: [],
        styledIcon: getEmptyStyledIcon()
    };

    return category;
};

export const getEmptyStyledIcon = () =>
{
    const styledIcon = {
        iconImage: undefined,
        iconColour: "#99a9c4",
        backgroundColour: "#f3f3f3",
    };

    return styledIcon;
};

export const MIN_ROTATION = 0;
export const MAX_ROTATION = 360;
/**
 * @param {number | null} rotation
 * @returns {boolean}
 */
export function rotationIsValid(rotation)
{
    const isNull = rotation === null;
    const isWithinBounds = MIN_ROTATION <= rotation && rotation <= MAX_ROTATION;
    return isNull || isWithinBounds;
}
