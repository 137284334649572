import { DEFAULT_LANGUAGE_CODE } from "mapsted.maps/utils/map.constants";
import cloneDeep from "lodash.clonedeep";

export const OVERLAY_TEMPLATE_DEFAULT_SETTINGS = {
    fillOpacity: 0.5,
    borderFillOpacity: 0.5,
    textOpactiy: 1
};

export const OVERLAYS_TEMPLATE_MODES = {
    VIEW: "VIEW",
    CREATE: "CREATE",
    EDIT: "EDIT",
    DELETE: "DELETE",
};

export const OVERLAY_TEMPLATE_DEFAULT_ZOOM_LEVEL_SETTINGS = {
    value: undefined,
    fillOpacity: OVERLAY_TEMPLATE_DEFAULT_SETTINGS.fillOpacity,
    borderFillOpacity: OVERLAY_TEMPLATE_DEFAULT_SETTINGS.borderFillOpacity,
    textOpacity: OVERLAY_TEMPLATE_DEFAULT_SETTINGS.textOpactiy,
    id: null,
    zoomLevelId: null,
};

export const OVERLAY_TEMPLATE_DEFAULT_DYNAMIC_SETTINGS = {
    enabled: false,
    zoomLevels: [
        cloneDeep(OVERLAY_TEMPLATE_DEFAULT_ZOOM_LEVEL_SETTINGS),
        cloneDeep(OVERLAY_TEMPLATE_DEFAULT_ZOOM_LEVEL_SETTINGS)
    ]
};

export const NEW_OVERLAY_TEMPLATE_DEFAULT = {
    polygon: undefined,
    name: { [DEFAULT_LANGUAGE_CODE]: "" },
    group: null,
    color: "#6BFF7C",
    lineColor: "#4BD70F",
    textColor: "#000000",
    defaultFillOpacity: OVERLAY_TEMPLATE_DEFAULT_SETTINGS.fillOpacity,
    defaultBorderFillOpacity: OVERLAY_TEMPLATE_DEFAULT_SETTINGS.borderFillOpacity,
    defaultTextOpacity: OVERLAY_TEMPLATE_DEFAULT_SETTINGS.textOpactiy,
    dynamicOverlaySettings: OVERLAY_TEMPLATE_DEFAULT_DYNAMIC_SETTINGS,
    propertyId: undefined,
    buildingId: undefined,
    floorId: undefined
};