import React from "react";
import PropTypes from "prop-types";
import { Button, Input } from "semantic-ui-react";
import { isEmpty } from "lodash";
import { ModalLayout } from "../../popups/ModalLayout";
import { DropdownGroup } from "../../elements/InputFormGroup";
import { ButtonIcon } from "../../elements/ButtonIcon";
import { InputGroupWrap } from "../InputGroupWrap";
import { CategoryItemBox } from "./CategoryItemBox";
import { CATEGORY_EDIT_TYPE, DEFAULT_CATEGORY_ICON, DELETE_CATEGORY_MODAL } from "../../../_constants/branding";
import { getEmptyCategory } from "../../../_utils/branding.utils";
import { CategoryContext } from "../../../store/CategoryProvider";
import "./EditCategoryModal.css";
import { withTranslation } from "react-i18next";
import { DeleteCategoryModal } from "./DeleteCategoryModal";
import { DEFAULT_LANGUAGE_CODE, ICON_PICKER_OPTIONS, SINGLE_LANG_INPUT_CODE } from "../../../_constants/constants";
import { CATEGORIES } from "../../../_constants/config";
import { ErrorLabel } from "../../elements/ErrorLabel";
import { DropdownForm } from "../../common/DropdownForm";
import CategoryDropdownItem from "../manageCategory/categoryDropdownItem/CategoryDropdownItem";
import CategoryUses from "../manageCategory/categoryUses/categoryUses";
import { toast } from "react-toastify";
import { Gms } from "../Gms/Gms";

class EditCategoryModalImpl extends React.Component 
{
    static contextType = CategoryContext;
    constructor(props, context) 
    {
        super(props, context);

        const { iconImage, _id } = DEFAULT_CATEGORY_ICON;
        this.gmsRef = React.createRef();
        this.state = {
            name: { [DEFAULT_LANGUAGE_CODE]: "" },
            iconImage,
            iconId: _id,
            iconColour: undefined,
            backgroundColour: undefined,
            orignialSubCategories: [],
            selectedSubCategories: [],
            newlyCreatedSubCategories: [],
            currentlySelectedCategories: [],
            isEditIconCategoryOpen: false,
            styledIcon: DEFAULT_CATEGORY_ICON,
            open: undefined,
            availableLangOptions: [],
            duplicationErrorLang: [],
            groupId: props.groupId,
            categoryId: props.categoryId,

            deleteModal: DELETE_CATEGORY_MODAL.NONE, // which modal is open
            deleteStoreList: [],
            loading: false
        };
    }

    componentDidMount() 
    {
        this.setStateFromCategory(this.props.category, this.props.groupId, this.props.categoryId);
    }

    componentDidUpdate(prevProps, prevState) 
    {
        if (prevProps.category._id !== this.props.category._id || prevProps.groupId !== this.props.groupId || prevProps.categoryId !== this.props.categoryId) 
        {
            this.setStateFromCategory(this.props.category, this.props.groupId, this.props.categoryId);
        }
        if (prevState.open !== this.state.open && !this.state.open) 
        {
            this.handleClose();
        }
    }

    setStateFromCategory(category, groupId, categoryId) 
    {
        const styledIcon = this.context.getCategoryStyledIcon(category);
        const iconInfo = this.context.getIconInfo(category);

        const selectedSubCategories = category.subCategories;

        this.setState({
            name: category.name || {},
            iconImage: iconInfo.iconImage,
            iconColour: iconInfo.iconColour,
            iconId: iconInfo._id,
            backgroundColour: iconInfo.backgroundColour,
            orignialSubCategories: selectedSubCategories ? [...selectedSubCategories] : [],
            selectedSubCategories,
            isEditIconCategoryOpen: false,
            styledIcon,
            deleteModal: DELETE_CATEGORY_MODAL.NONE, // which modal is open
            deleteStoreList: [],
            availableLangOptions: this.getAvailableLangOptions(category),
            duplicationErrorLang: [],
            groupId,
            categoryId
        });
    }

    getAvailableLangOptions(category) 
    {
        return category.name ? Object.keys(category.name) : [DEFAULT_LANGUAGE_CODE];
    }

    getFormattedSubcategories(category, trans) 
    {
        const { categoryFlatMap } = this.context;
        const categoryId = category._id;

        const format = (cat) => 
        {
            let text = cat.name[SINGLE_LANG_INPUT_CODE];

            // let parentId = cat.parentCategories[0];
            // if (parentId && parentId !== this.props.category._id) {
            //     text = `${cat.name[SINGLE_LANG_INPUT_CODE]} (${trans["assigned to"]} ${categoryFlatMap[parentId].name[SINGLE_LANG_INPUT_CODE]})`;
            // }
            return { key: cat._id, value: cat._id, text, category: cat };
        };

        if (this.props.editConfig === CATEGORY_EDIT_TYPE.GROUP) 
        {
            return Object.values(categoryFlatMap)
                .filter((cat) => !cat.isRoot && cat._id !== categoryId)
                .map(format);
        }
        else if (this.props.editConfig === CATEGORY_EDIT_TYPE.CATEGORY) 
        {
            return Object.values(categoryFlatMap)
                .filter((cat) => !cat.isRoot && cat.subCategories.length === 0 && cat._id !== categoryId)
                .map(format);
        }
    }

    handleOnChange = (e, { name, value }) => 
    {
        this.setState({ [name]: value });
    }

    handleIconCategoryModalChanges = ({ backgroundColor, iconColour, styledIcon }) => 
    {
        this.setState({
            backgroundColour: backgroundColor,
            iconColour,
            styledIcon,
            iconId: styledIcon._id,
            iconImage: styledIcon.iconImage,
            isEditIconCategoryOpen: false
        });
    }

    handleChildCreated(child) 
    {
        this.setState({
            selectedSubCategories: [...(this.state.selectedSubCategories || []), child._id],
            newlyCreatedSubCategories: [...(this.state.newlyCreatedSubCategories || []), child._id]
        });
    }

    handleDelete() 
    {
        this.setState({ loading: true });

        this.context.deleteCategory(this.props.category._id, this.props.editConfig.isRoot)
            .then((success) => 
            {
                if (success) 
                {
                    this.setState({ open: false }, () => this.setState({ open: undefined }));
                    if (this.props.editConfig.isRoot)
                    {
                        toast.success(this.props.t("CATEGORY_TOAST_MSG.GROUP_DELETED"));            
                    }
                    else if (this.props.editConfig.type === "category")
                    {
                        toast.success(this.props.t("CATEGORY_TOAST_MSG.CATEGORY_DELETED"));
                    }
                    else 
                    {
                        toast.success(this.props.t("CATEGORY_TOAST_MSG.SUB_CATEGORY_DELETED"));
                    }
                }
                else
                {
                    if (this.props.editConfig.isRoot)
                    {
                        toast.error(this.props.t("CATEGORY_TOAST_MSG.GROUP_DELETE_FAILED"));            
                    }
                    else if (this.props.editConfig.type === "category")
                    {
                        toast.error(this.props.t("CATEGORY_TOAST_MSG.CATEGORY_DELETE_FAILED"));
                    }
                    else 
                    {
                        toast.error(this.props.t("CATEGORY_TOAST_MSG.SUB_CATEGORY_DELETE_FAILED"));
                    }
                }
            })
            .finally(() => this.setState({ loading: false }));
    }

    handleClose() 
    {
        // if an uncreated category
        let unusedCategories = this.state.newlyCreatedSubCategories?.filter((nc) => !this.state.currentlySelectedCategories?.includes(nc));
        if (unusedCategories.length) 
        {
            // delete all newly created subcategories on close
            Promise.all(
                unusedCategories
                    .map((subId) => this.context.deleteCategory(subId, false))
            );
        }
        if (this.props.onClose) 
        {
            this.props.onClose();
        }
        this.setState({ newlyCreatedSubCategories: [] });
        this.setStateFromCategory({});
    }

    handleOnDoneClick = async () => 
    {
        const deletedSubCategories = this.getRemovedSubcategories();

        if (deletedSubCategories.length === 0) 
        {
            this.handleDone();
        }
        else 
        {
            // check if any stores contain category id
            const storeNames = await this.context.handleGetStoresFromCategories(deletedSubCategories);
            // if, display modal containing store list
            if (Array.isArray(storeNames) && storeNames.length > 0) 
            {
                this.setState({
                    deleteModal: DELETE_CATEGORY_MODAL.SUB_CATEGORY_STORE_LIST,
                    deleteStoreList: storeNames,
                });
            }
            else 
            {
                this.handleDone();
            }
        }
    }

    // Check if any language translations of name is dupliate (i.e. same as name of another category in same language)
    getLangsWithDuplicates = () => 
    {
        const { name, availableLangOptions } = this.state;
        const { categoryFlatMap } = this.context;
        const { category } = this.props;

        let duplicationErrorLang = new Set();

        availableLangOptions.forEach((lang) => 
        {
            const catsToLang = Object.values(categoryFlatMap)
                .filter((cat) => cat._id !== category._id)
                .map((cat) => cat.name[lang]?.trim().toUpperCase());

            if (name[lang] && catsToLang.includes(name[lang]?.trim().toUpperCase())) 
            {
                duplicationErrorLang.add(lang);
            }
        });

        return Array.from(duplicationErrorLang);
    };

    handleDone = () => 
    {
        const { name, iconId, iconColour, backgroundColour, selectedSubCategories, groupId, categoryId } = this.state;
        const { editConfig, category } = this.props;

        // Reset duplicationErrorLang array
        this.setState({
            duplicationErrorLang: []
        });

        const duplicationErrorLang = this.getLangsWithDuplicates();
        if (duplicationErrorLang.length > 0) 
        {
            this.setState({
                duplicationErrorLang
            });
            return;
        }

        const cat = {
            name: this.removeEmptyLangTranslations({ ...name }),
            subCategories: selectedSubCategories,
            _id: category._id,
            iconId,
            iconColour,
            backgroundColour,
            isRoot: editConfig.isRoot,
            groupId,
            categoryId,
            type: editConfig.type
        };

        this.setState({ loading: true });

        this.context.createCategory(cat)
            .then((data) => 
            {
                if (!data) 
                {
                    console.log("post request failed");
                    this.props.onDone(false);
                    this.setState({ currentlySelectedCategories: [] });
                    if (this.props.editConfig.isRoot)
                    {
                        toast.error(category._id ? this.props.t("CATEGORY_TOAST_MSG.GROUP_UPDATE_FAILED") : this.props.t("CATEGORY_TOAST_MSG.GROUP_CREATE_FAILED"));            
                    }
                    else if (this.props.editConfig.type === "category")
                    {
                        toast.error(category._id ? this.props.t("CATEGORY_TOAST_MSG.CATEGORY_UPDATE_FAILED") : this.props.t("CATEGORY_TOAST_MSG.CATEGORY_CREATE_FAILED"));
                    }
                    else 
                    {
                        toast.error(category._id ? this.props.t("CATEGORY_TOAST_MSG.SUB_CATEGORY_UPDATE_FAILED") : this.props.t("CATEGORY_TOAST_MSG.SUB_CATEGORY_CREATE_FAILED"));
                    }
                }
                else 
                {
                    // Pass newly created category up to parent's category selection
                    this.props.onDone(data);
                    this.setState({
                        open: false,
                        currentlySelectedCategories: selectedSubCategories
                    },
                    () => this.setState({ open: undefined })
                    );

                    if (this.props.editConfig.isRoot)
                    {
                        toast.success(category._id ? this.props.t("CATEGORY_TOAST_MSG.GROUP_UPDATED") : this.props.t("CATEGORY_TOAST_MSG.GROUP_CREATED"));            
                    }
                    else if (this.props.editConfig.type === "category")
                    {
                        toast.success(category._id ? this.props.t("CATEGORY_TOAST_MSG.CATEGORY_UPDATED") : this.props.t("CATEGORY_TOAST_MSG.CATEGORY_CREATED"));
                    }
                    else 
                    {
                        toast.success(category._id ? this.props.t("CATEGORY_TOAST_MSG.SUB_CATEGORY_UPDATED") : this.props.t("CATEGORY_TOAST_MSG.SUB_CATEGORY_CREATED"));
                    }
                }
            })
            .finally(() => this.setState({ loading: false }));
    }

    // Removes language keys with empty values from name
    removeEmptyLangTranslations = (name) => 
    {
        const langs = Object.keys(name);
        langs
            .filter((lang) => !name[lang].trim())
            .forEach((lang) => delete name[lang]);
        return Object.fromEntries(Object.entries(name).map(([lang, value]) => ([lang, value.trim()])));
    }

    handleOnDeleteModalDone = () => 
    {
        const { deleteModal } = this.state;

        if (deleteModal === DELETE_CATEGORY_MODAL.STORE_LIST || deleteModal === DELETE_CATEGORY_MODAL.WARNING) 
        {
            this.handleDelete();
        }
        else 
        {
            this.handleDone();
        }
    }

    handleDeleteIconClicked = async () => 
    {
        const categoryId = this.props.category._id;
        // check if any stores contain category id
        const storeNames = await this.context.handleGetStoresFromCategories([categoryId]);


        // if, display modal containing store list
        if (Array.isArray(storeNames) && storeNames.length > 0) 
        {
            this.setState({
                deleteModal: DELETE_CATEGORY_MODAL.STORE_LIST,
                deleteStoreList: storeNames,
            });
        }
        // else display warning modal
        else 
        {
            this.setState({
                deleteModal: DELETE_CATEGORY_MODAL.WARNING,
                deleteStoreList: []
            });
        }
    }

    handleCancelDelete = () => 
    {
        this.setState({
            deleteModal: DELETE_CATEGORY_MODAL.NONE,
            deleteStoreList: []
        });
    }

    getRemovedSubcategories = () => 
    {
        const { orignialSubCategories, selectedSubCategories, newlyCreatedSubCategories } = this.state;

        let removedSubcategories = [];
        [...(orignialSubCategories || []), ...(newlyCreatedSubCategories || [])].forEach((currentCategory) => 
        {
            if (!selectedSubCategories.includes(currentCategory)) 
            {
                removedSubcategories.push(currentCategory);
            }
        });
        return removedSubcategories;
    }

    // Get language options not yet filled, it will push the currentLang to the top of the array
    getLangOptions = (currentLang) => 
    {
        let langOptions = [];
        langOptions.push({
            key: currentLang,
            text: `${this.context.languages[currentLang]?.title} (${currentLang.toUpperCase()})`,
            value: currentLang
        });
        const unAddedLangOptions = this.getUnAddedLangOptions();
        unAddedLangOptions.forEach((lang) => 
        {
            langOptions.push({
                key: lang,
                text: `${this.context.languages?.[lang]?.title} (${lang.toUpperCase()})`,
                value: lang
            });
        });
        return langOptions;
    };

    getCategoryOptions = (groupId) => 
    {
        if (groupId)
        {
            const selectedGroup = this.context.categoryGroups.filter((group) => group._id === groupId)?.[0];
        
            if (selectedGroup?.subCategories.length > 0)
            {
                const subCats = this.context.getCategoriesFromIds(selectedGroup.subCategories);
                
                return subCats?.map((cat) => ({
                    key: cat._id,
                    value: cat._id,
                    text: cat.name[SINGLE_LANG_INPUT_CODE]
                })) || [];
            }
        }
        return [];
    }

    // Get array of property Languages codes not yet used
    getUnAddedLangOptions = () => this.context.getCurrentPropertyLangOptions().filter((lang) => !this.state.availableLangOptions.includes(lang));

    addNewLang = () => 
    {
        let newName = { ...this.state.name };
        let newAvailableLangOptions = [...this.state.availableLangOptions];
        const newLangOption = this.getUnAddedLangOptions()[0];
        newName[newLangOption] = "";
        newAvailableLangOptions.push(newLangOption);
        this.setState({
            name: newName,
            availableLangOptions: newAvailableLangOptions
        });
    };

    handleChangeLangOption = (newLang, oldLang) => 
    {
        let newName = { ...this.state.name };
        let newAvailableLangOptions = [...this.state.availableLangOptions];
        newName[newLang] = newName[oldLang];
        delete newName[oldLang];
        newAvailableLangOptions[newAvailableLangOptions.findIndex((lang) => lang === oldLang)] = newLang;
        this.setState({
            name: newName,
            availableLangOptions: newAvailableLangOptions
        });
    };

    handleDeleteLangOption = (lang) => 
    {
        let newName = { ...this.state.name };
        let newAvailableLangOptions = [...this.state.availableLangOptions];
        delete newName[lang];
        newAvailableLangOptions.splice(newAvailableLangOptions.findIndex((language) => lang === language), 1);

        this.setState({
            name: newName,
            availableLangOptions: newAvailableLangOptions
        });
    }

    handleNameChange = (newName, lang) => 
    {
        let duplicationErrorLang = [...this.state.duplicationErrorLang];
        let nameObj = { ...this.state.name };
        nameObj[lang] = newName.trimStart();
        if (duplicationErrorLang.includes(lang)) 
        {
            duplicationErrorLang.splice(duplicationErrorLang.findIndex((duplicateLang) => duplicateLang === lang), 1);
        }
        this.setState({
            name: nameObj,
            duplicationErrorLang
        });
    }

    render() 
    {
        const {
            loading,
            name,
            iconColour,
            iconImage,
            backgroundColour,
            isEditIconCategoryOpen,
            selectedSubCategories,
            styledIcon,
            open,
            deleteModal,
            deleteStoreList,
            groupId,
            categoryId
        } = this.state;
        const { category, trigger, deleteIconClass, addDeleteIcon, disableParentInput, t } = this.props;
        const editConfig = this.props.editConfig || CATEGORY_EDIT_TYPE.GROUP;

        const heading = (category._id) ? editConfig.heading.edit : editConfig.heading.new;
        const subHeading = (category._id) ? editConfig.subHeading.edit : editConfig.subHeading.new;


        /** @type {typeof import("../../../_intl/resources.json")["en"]["translation"]["edit category modal"]} */
        const trans = t("edit category modal");

        const subCategories = this.getFormattedSubcategories(category, trans);

        const parentInput = (
            <>
                {
                    !disableParentInput && editConfig !== CATEGORY_EDIT_TYPE.GROUP && <div className="flexibleWrap">
                        {
                            <>

                                <InputGroupWrap label={trans["group"]}>
                                    <DropdownForm
                                        value={groupId || ""}
                                        placeholder={this.props.t("MapstedMapsModule.Select")}
                                        options={this.context.categoryGroups.map((group) => ({
                                            key: group._id,
                                            value: group._id,
                                            text: group.name[SINGLE_LANG_INPUT_CODE]
                                        }))}
                                        onChange={(event, { value }) => 
                                        {
                                            this.setState(
                                                {
                                                    groupId: value,
                                                }
                                            );
                                        }}
                                        disabled={this.props.groupId}
                                    />
                                </InputGroupWrap>
                                {editConfig === CATEGORY_EDIT_TYPE.SUB_CATEGORY && <InputGroupWrap label={trans["category"]} popupMessage={groupId && this.getCategoryOptions(groupId)?.length <=0 && this.props.t("manageCategory.NO_CATEGORY_IN_SELECTED_GROUP")}>
                                    <DropdownForm
                                        value={categoryId || ""}
                                        placeholder={this.props.t("MapstedMapsModule.Select")}
                                        options={this.getCategoryOptions(groupId)}
                                        onChange={(event, { value }) => 
                                        {
                                            this.setState(
                                                {
                                                    categoryId: value
                                                }
                                            );
                                        }}
                                        disabled={this.getCategoryOptions(groupId)?.length <=0 || groupId === null || this.props.categoryId}
                                    />
                                </InputGroupWrap>}
                            </>
                        }
                    </div>
                }
            </>
        );

        const nameInput = (
            <div>
                {this.state.availableLangOptions.map((lang, index) => (
                    <div className="flexibleWrap" key={lang}>
                        <InputGroupWrap label={subHeading}>
                            <Input
                                name="name"
                                value={name[lang]}
                                placeholder={trans[editConfig.type]}
                                onChange={(e, { value }) => this.handleNameChange(value, lang)}
                                maxLength={CATEGORIES.MAX_NAME_LENGTH}
                                className={this.state.duplicationErrorLang.includes(lang) ? " categoryNameHighlight" : ""}
                                autoComplete="off"
                            />
                        </InputGroupWrap>
                        <InputGroupWrap label={trans["language"]}>
                            <DropdownForm
                                text={`${this.context.languages[lang]?.title} (${lang.toUpperCase()})`}
                                options={this.getLangOptions(lang)}
                                value={lang}
                                onChange={(event, { value }) => this.handleChangeLangOption(value, lang)}
                                disabled={lang === DEFAULT_LANGUAGE_CODE}
                            />
                        </InputGroupWrap>
                        {index > 0 && <ButtonIcon className="editCategoryModalNameDelete" icon="delete-red" onClick={() => this.handleDeleteLangOption(lang)} />}
                    </div>
                ))}
                {this.getUnAddedLangOptions().length > 0 && <ButtonIcon className="buttonAddLang" icon="plus-filled" content={trans["add languages"]} onClick={this.addNewLang.bind(this)} />}
            </div>
        );

        const shouldDisableSave = () => 
        {
            if (name && name[DEFAULT_LANGUAGE_CODE])
            {
                if (editConfig.isRoot || disableParentInput)
                {
                    return false;
                }
                else 
                {
                    if (editConfig.type === "subCategory")
                    {
                        if (groupId && categoryId)
                        {
                           
                            return false;
                                
                        }
                    }
                    else 
                    {
                        if (groupId)
                        {
                            return false;
                        }
                    }
                }
            }
            return true;
        };

        return (<>
            <ModalLayout
                trigger={trigger}
                open={open}
                onClose={this.handleClose.bind(this)}
                onOpen={() => this.setStateFromCategory(this.props.category, this.props.groupId, this.props.categoryId)}
                className="categoryModal addEditGroupModal"
                heading={heading}
                actions={
                    <div className="actionContent">
                        {
                            editConfig === CATEGORY_EDIT_TYPE.GROUP &&  (category._id) && (
                                <>
                                    {/* Delete modal depends on result from api */}
                                    <ButtonIcon className="deleteAction" icon="delete-red" content={trans["delete group"]} onClick={this.handleDeleteIconClicked} />
                                </>
                            )
                        }
                        <Button primary color="orange"
                            content={trans["save"]}
                            onClick={() => this.handleOnDoneClick()}
                            disabled={loading || shouldDisableSave()}
                        />
                    </div>
                }
            >
                { category._id && <CategoryUses category={category} expanded={true} /> }
                <CategoryItemBox onClick={() => 
                {
                    this.gmsRef.current.linkIcon({
                        iconType: "CategoryIcon",
                        iconImage,
                        disableIconTypeSelection: true,
                    });
                }} iconImage={iconImage} iconColour={iconColour} backgroundColour={backgroundColour} name={category.name}>
                    { this.state.iconId && this.state.iconId !== DEFAULT_CATEGORY_ICON._id && <ButtonIcon icon="cross-bg-red" className="uploadImgCloseIcon" onClick={() => this.handleIconCategoryModalChanges({ styledIcon: DEFAULT_CATEGORY_ICON })}/>}
                    <div className="groupActions">
                        <Gms onAddIcon={(icon) => this.handleIconCategoryModalChanges({ styledIcon: icon })} ref={this.gmsRef}></Gms>
                    </div>
                </CategoryItemBox>

                <div className="alignWrap">
                    {parentInput}

                    {nameInput}

                    {
                        (editConfig.subListHeader) && (

                            <InputGroupWrap label={editConfig.subListHeader}>
                                <div className="multiDropdown">
                                    <DropdownGroup dropdownProps={{
                                        name: "selectedSubCategories",
                                        value: selectedSubCategories,
                                        options: subCategories,
                                        text: (selectedSubCategories || []).length === 0 ? trans["none"] : "",
                                        onChange: this.handleOnChange,
                                        multiple: true,
                                        renderLabel: (item) => <CategoryDropdownItem category={item.category} onCancel={(category) => 
                                        {
                                            this.setState({
                                                selectedSubCategories: [...this.state.selectedSubCategories.filter((cat) => cat !== category._id)]
                                            });
                                        }} />,
                                        // eslint-disable-next-line no-nested-ternary
                                        noResultsMessage: isEmpty(subCategories) ? trans["no results"] : selectedSubCategories && subCategories && selectedSubCategories.length === subCategories.length ? trans["no more results"] : trans["no results found"],
                                    }} />
                                </div>
                                <EditCategoryModal
                                    category={getEmptyCategory(false)}
                                    disableParentInput={true}
                                    editConfig={editConfig === CATEGORY_EDIT_TYPE.GROUP ? CATEGORY_EDIT_TYPE.CATEGORY : CATEGORY_EDIT_TYPE.SUB_CATEGORY}
                                    trigger={<ButtonIcon add icon="plus-filled" content={trans[`create new ${editConfig.title}`]} />}
                                    onDone={(created) => this.handleChildCreated(created)}
                                />
                            </InputGroupWrap>
                        )
                    }

                    {this.state.duplicationErrorLang.length > 0 && <ErrorLabel large label={editConfig.duplicationError} />}


                </div>
            </ModalLayout>

            {addDeleteIcon && <ButtonIcon className={deleteIconClass} icon="trash-blue" onClick={this.handleDeleteIconClicked} />}

            <DeleteCategoryModal
                loading={loading}
                deleteModal={deleteModal}
                editConfig={editConfig.deleteModal}
                relatedStores={deleteStoreList}
                onDelete={this.handleOnDeleteModalDone}// on done
                onCancel={this.handleCancelDelete}
            />
        </>
        );
    }
}

export const EditCategoryModal = withTranslation()(EditCategoryModalImpl);

EditCategoryModal.propTypes = {
    category: PropTypes.object,
    trigger: PropTypes.element.isRequired,
    editConfig: PropTypes.object,
    onDone: PropTypes.func,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    addDeleteIcon: PropTypes.bool,
    deleteIconClass: PropTypes.string,
    groupId: PropTypes.any,
    categoryId: PropTypes.any,
    disableParentInput: PropTypes.bool
};

EditCategoryModal.defaultProps = {
    onDone: () => null,
    category: {},
    addDeleteIcon: false,
    disableParentInput: false
};
