import React, { useRef, useState } from "react";
import { set as setDeepValue, get as getDeepValue } from "lodash";
import { Button, Icon, Input } from "semantic-ui-react";
import { filerUrl, radiansToDegees } from "../../../_utils/utils";
import { TextGroup } from "../../common/TextGroup";
import { ButtonIcon } from "../../common/ButtonIcon";
import { useTranslation } from "react-i18next";
import { Gms } from "../../branding/Gms/Gms";


export const KioskDetailsSection = ({ details, language, onChangeDetails, onResetRotation }) =>
{
    const gmsRef = useRef(null);
    const [isIconPickerOpen, setIsIconPickerOpen] = useState(false);

    const trans = useTranslation().t; 

    const handleChangeValue = (path, value) =>
    {
        const updates = setDeepValue({ ...details }, path, value);
        onChangeDetails(updates);
    };

    const handleIconChange = (icon) =>
    {
        handleChangeValue(`entity.entityLabel.iconImage.${language}`, icon.iconImage);
    };

    const renderSelectedIcon = () =>
    {
        const iconImage = details?.entity?.entityLabel?.iconImage?.[language];
        return (
            <>
                <TextGroup className="tgKiosk symbolWrapCover" title={trans("KioskDetailsSection.Icon_Symbol")}>
                    {iconImage ? (
                        <img
                            className="selected-icon"
                            src={filerUrl(iconImage)}
                            alt=""
                            onClick={() => gmsRef.current.linkIcon({
                                iconType: "KioskIcon",
                                iconImage,
                                disableIconTypeSelection: true,
                            })} />
                    ) : <ButtonIcon icon="plus-circle" content={trans("KioskDetailsSection.Add_Icon")} 
                        onClick={() => gmsRef.current.linkIcon({
                            iconType: "KioskIcon",
                            iconImage,
                            disableIconTypeSelection: true,
                        })}
                    />}

                </TextGroup>
                <Gms ref={gmsRef} onAddIcon={(icon) => handleIconChange(icon)}></Gms>            
            </>
        );
    };

    return (
        <div>
            <TextGroup heading={trans("KioskDetailsSection.Kiosk_Name")}>
                <Input
                    placeholder={trans("KioskDetailsSection.Kiosk_Name_Placeholder")}
                    value={getDeepValue(details, `entity.entityLabel.longName.${language}`)}
                    onChange={(e, { value }) => handleChangeValue(`entity.entityLabel.longName.${language}`, value)}
                />
            </TextGroup>

            {renderSelectedIcon()}

            <TextGroup className="tgMapOrientation" heading={trans("KioskDetailsSection.Map_Orienation")}>
                ({radiansToDegees(getDeepValue(details, `mapRotation.${language}`))} {trans("Settings.Degrees")})

                <Button className="rotateResetButton" onClick={onResetRotation}>
                    <Icon name="repeat" />
                    {trans("KioskDetailsSection.Reset")}
                </Button>
            </TextGroup>

        </div>
    );
};
