import React, { useCallback } from "react";
import { Button } from "semantic-ui-react";
import { ButtonBasic } from "../common/ButtonIcon";
import { ModalLayout } from "../popups/ModalLayout";

export const UnlockModal = ({ disabled, isLockedOnDifferentMachine, onUnlockWithoutSaving, onUnlockAndSave }) =>
{
    const unlockMessage = useCallback(() =>
    {
        if (isLockedOnDifferentMachine)
        {
            return (<p className="aligner">
                Oops! The edited data you're trying to access was saved on a different machine or browser. To view and save the changes, please use the same machine/browser that the level was locked in.
            </p>)
        }
    }, [isLockedOnDifferentMachine]);
    return (
        <ModalLayout className="modalTransitionEdit"
            heading="Unlock Level"
            trigger={
                <ButtonBasic className="lockSize" icon="lock-grey" content="Locked" disabled={disabled} />
            }
            actions={
                <>
                    <Button primary floated="left" content="Unlock Without Saving" onClick={onUnlockWithoutSaving} />
                    <Button disabled={isLockedOnDifferentMachine} secondary content="Unlock And Save" onClick={onUnlockAndSave} />
                </>
            }
        >
            {unlockMessage()}
        </ModalLayout>
    )
}



{/* <ModalLayout dimmer="blurring"
    open={open}
    onClose={handleClose}
    topAligned={false}
    trigger={
        <Button className="link-btn" onClick={handleOpen}>
            <img src="/img/icon-trash-blue.svg" alt={trans("CropImageModal.Trash_icon")} />
            {trans("CropImageModal.Delete")}
        </Button>
    }
    className="crop-image-modal actionModal deleteImg"
    heading={trans("CropImageModal.Delete_Image")}
    actions={
        <>
            <Button content={trans("CropImageModal.Upload_New_Image")} onClick={handleonUploadNew} />
            <Button secondary content={trans("CropImageModal.Delete")} onClick={handleOnDelete} />
        </>
    }>
    <p>{trans("CropImageModal.Are_you_sure_you_want_to_delete_this_ima")}</p>
</ModalLayout> */}