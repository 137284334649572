import React from "react";
import { useTranslation } from "react-i18next";
import { Header, Button } from "semantic-ui-react";
import moment from "moment";

export const HeaderPortal = ({ history, className, header, undo, info, onClick, onClickUndu, lastUpdated = undefined }) =>
{
    const trans = useTranslation().t;

    return (
        <Header className={`portal-header${history ? " history-header" : ""}${className ? " " + className : ""}`}>
            <Button className="back-arrow" onClick={onClick}>
                <img src="/img/icon-arrow-cms.svg" alt="" />
            </Button>
            {header}
            {(undo) && <>
                <UndoButton onClick={onClickUndu} />
                <UndoButton undoAll />
            </>}
            {info}
            {(lastUpdated) && (
                <span className="lastUpdated">{trans("HeaderPortal.Last_updated", { numDays: moment().diff(lastUpdated, "days") })}</span>
            )}
        </Header>
    );
};

const UndoButton = ({ onClick, undoAll = false }) =>
{
    const trans = useTranslation().t;
    return (
        <Button className="undoAction" onClick={onClick}>
            <img src={`/img/undo${undoAll ? "-all" : ""}.svg`} alt="" />
            {trans("HeaderPortal.Undo")}{undoAll && ` ${trans("HeaderPortal.All")}`}
        </Button>
    );
};
