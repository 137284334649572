import React from "react";
import { useTranslation } from "react-i18next";
import { SettingsHeading } from "../../settings/SettingsHeading";
import { ButtonBasic } from "../../common/ButtonIcon";
import { ZOOM_SELECT_MODES } from "../../branding/map/ZoomMap";
import { BRANDING_MAP_CONSTANTS } from "../../../_constants/constants";

function MaxZoomConfig({ onChangeMaxZoom, maxZoomLevel }) 
{
    const trans = useTranslation().t;
    return (
        <div className="settingsPageTabCover">
            <SettingsHeading heading={ trans("MapstedMapsModule.Max_Zoom_Level")}>
                {trans("MapstedMapsModule.Manually_adjust_the_max_zoom_level_for_your_")}
            </SettingsHeading>
            <div className="zoomLevelItem">
                <p>{trans("MapstedMapsModule.Max_Zoom_Level")}: {printZoom(maxZoomLevel)}</p>          
                <ButtonBasic content={trans("MapOverlaysUploadCsvModal.Change")} onClick={() => onChangeMaxZoom(ZOOM_SELECT_MODES.DESKTOP)} />
            </div>
           
        </div>
    );
}

export default MaxZoomConfig;

const printZoom = (zoom) => 
{
    const rangeSize = BRANDING_MAP_CONSTANTS.MAX_ZOOM - BRANDING_MAP_CONSTANTS.MIN_ZOOM;
    const percentage = (zoom - BRANDING_MAP_CONSTANTS.MIN_ZOOM) / rangeSize;
    return `${(100 * percentage).toFixed(0)}%`;
};