import React, { useMemo } from "react";
import { Checkbox, Dropdown } from "semantic-ui-react";
import { DropdownForm } from "../DropdownForm";
import { useTranslation } from "react-i18next";
import "./CheckboxDropdown.css";

function CheckboxDropdown({
    options: optionsProp,
    values,
    onChange,
    checkboxProps = {},
    className,
    showSelectAllOption,
    ...rest
}) 
{
    const trans = useTranslation().t;
    const options = useMemo(() => 
    {
        let options = optionsProp;
        if (showSelectAllOption)
        {
            options = [
                { 
                    id: "all", 
                    label: trans("ListViewColumns.All") 
                },
                ...options
            ];
        }
        return options;
    }, [optionsProp, showSelectAllOption]);

    function handleChange(e, { checked, id })
    {
        if (id === "all") 
        {
            if (checked) 
            {
                onChange(options.map(({ id }) => id));
            }
            else 
            {
                onChange(options.filter((option) => option?.disabled).map((option) => option.id));
            }
            return;
        }

        if (checked) 
        {
            onChange([...(values || []), id]);
        }
        else 
        {
            onChange([...(values || []).filter((columnId) => columnId !== id)]);
        }
    }

    return (
        <DropdownForm
            {...rest}
            className={`checkbox-dropdown ${className || ""}`}
        >
            <Dropdown.Menu>
                {options.map(({ id, label, disabled }) => (
                    <Dropdown.Item onClick={(e) => e.stopPropagation()} key={id}>
                        <Checkbox
                            checked={!!values.find((value) => value === id)}
                            label={label}
                            disabled={disabled}
                            onChange={(e, data) => 
                            {
                                e.stopPropagation();
                                handleChange(e, { ...data,  id });
                            }}
                            {...checkboxProps}
                        />
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </DropdownForm>
    );
}

export default CheckboxDropdown;
