import React, {
    memo,
    forwardRef,
    useState,
    useEffect,
    useImperativeHandle,
    useRef,
} from "react";
import { Input as InputComp, Popup } from "semantic-ui-react";
import _ from "lodash";
import "./listViewEditorInput.css";
import { useListViewContext } from "../../../../store/ListViewContext";


function Input({ value, colDef, data, stopEditing, inputProps,...rest }, ref) 
{
    const { cellEditorParams = {} } = colDef;
    const [inputValue, setInputValue] = useState(value);
    const refInput = useRef(null);
    const { handleShowConfirmationModalInfo, confirmationModalInfo } = useListViewContext();

    useImperativeHandle(ref, () => ({
        getValue() 
        {
            return inputValue;
        },
        isCancelAfterEnd(derivedValue) 
        {
            const value = this.getValue() || derivedValue;
            cellEditorParams.valueModifier(data, value);
            return true;
        },
    }));

    const getValidationMessage = () => 
    {
        if (!cellEditorParams?.validators?.length) return "";
        for (const validator of cellEditorParams.validators) 
        {
            let validationMessage = validator(inputValue);
            if (validationMessage) 
            {
                return validationMessage;
            }
        }
    };

    const handleKeyDown=(event) => 
    {
        if (event.key === "Enter" || event.key === "Tab")
        {
            const validationMessage = getValidationMessage();
            if (validationMessage)
            {
                handleShowConfirmationModalInfo({
                    type: "Error",
                    message: validationMessage,
                });
                return;
            }
            stopEditing();
        }
    };

    function handleBlur(e)
    {
        e.stopPropagation();
        const validationMessage = getValidationMessage();
        if (validationMessage) 
        {
            handleShowConfirmationModalInfo({
                type: "Error",
                message: validationMessage,
            });
            return;
        }
        stopEditing();
    }

    useEffect(() => 
    {
        refInput.current.focus();
    }, [confirmationModalInfo]);

    return (
        <InputComp
            ref={refInput}
            disabled={!!confirmationModalInfo}
            value={inputValue}
            className={ `list-view-input ${getValidationMessage() ? "list-view-input-error" : ""}`}
            type={cellEditorParams?.type || "text"}
            onChange={(event) =>  setInputValue(event.target.value)}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            {
                ...inputProps
            }
        />     
    );
}

export default memo(forwardRef(Input));
