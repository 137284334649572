import { useCallback, useEffect, useMemo, useReducer, useState } from "react";

/**
 * Calls callback function when a shortcutkey is pressed down while the shortcut trigger key is being pressed down
 * @param {string} shortcutTriggerKey
 * @param {Array<string>} shortcutKeys
 * @param {function} callback
 */
export const useKeyboardShortcut = (shortcutTriggerKey, shortcutKeys, callback) =>
{
    // check if parameters are ok
    if (typeof shortcutTriggerKey !== "string" || !shortcutTriggerKey.length)
    {
        throw new Error(
            "The first parameter to `useKeyboardShortcut` must be a string of at least length 1"
        );
    }
    if (!Array.isArray(shortcutKeys))
    {
        throw new Error(
            "The second parameter to `useKeyboardShortcut` must be an ordered array of `KeyboardEvent.key` strings."
        );
    }
    if (!shortcutKeys.length)
    {
        throw new Error(
            "The second parameter to `useKeyboardShortcut` must contain at least one `KeyboardEvent.key` string."
        );
    }
    if (!callback || typeof callback !== "function")
    {

        throw new Error(
            "The third parameter to `useKeyboardShortcut` must be a function that will be invoked when the keys are pressed."
        );
    }

    const [isTriggerKeyDown, setIsTriggerKeyDown] = useState(false);

    const shortcutKeysLowerCase = useMemo(() =>
    {
        let lowercaseKeys = [];

        shortcutKeys.forEach((key) =>
        {
            lowercaseKeys.push(key.toLowerCase());
        });

        return lowercaseKeys;

    }, [shortcutKeys]);

    const shortcutTriggerKeyLowerCase = useMemo(() =>
    {
        return shortcutTriggerKey.toLowerCase();
    }, [shortcutTriggerKey]);

    /**
     * 1. if repeating key ->  do nothing
     * 2. if trigger key -> update state
     * 3. if trigger key is down and a shortcut key is pressed -> call callback function with shortcut key pressed
     */
    const keydownListener = useCallback((keydownEvent) =>
    {
        const { key, repeat } = keydownEvent;

        let lowercaseKey = key?.toLowerCase();

        if (repeat)
        {
            return;
        }
        else if (shortcutTriggerKeyLowerCase === lowercaseKey)
        {
            setIsTriggerKeyDown(true);
        }
        else if (shortcutKeysLowerCase.includes(lowercaseKey) && isTriggerKeyDown)
        {
            callback(lowercaseKey);
        }

    }, [shortcutKeysLowerCase, isTriggerKeyDown]);


    const keyupListener = useCallback(keyupEvent =>
    {
        const { key } = keyupEvent;

        let lowercaseKey = key?.toLowerCase();

        if (shortcutTriggerKeyLowerCase === lowercaseKey)
        {
            setIsTriggerKeyDown(false);
        }

    }, [shortcutTriggerKeyLowerCase]);


    // add keydown event
    useEffect(() =>
    {
        window.addEventListener("keydown", keydownListener, true);
        return () => window.removeEventListener("keydown", keydownListener, true);
    }, [keydownListener]);

    // add keyup event
    useEffect(() =>
    {
        window.addEventListener("keyup", keyupListener, true);
        return () => window.removeEventListener("keyup", keyupListener, true);
    }, [keyupListener]);

};

/**
 * Calls callback function when a shortcutkey is pressed down while the shortcut trigger key is being pressed down
 * @param {string} shortcutTriggerKey
 * @param {Array<string>} shortcutKeys
 * @param {function} callback
 */
export const useSingleKeyboardShortcut = (shortcutTriggerKey, callback) =>
{
    // check if parameters are ok
    if (typeof shortcutTriggerKey !== "string" || !shortcutTriggerKey.length)
    {
        throw new Error(
            "The first parameter to `useKeyboardShortcut` must be a string of at least length 1"
        );
    }
    if (!callback || typeof callback !== "function")
    {

        throw new Error(
            "The third parameter to `useKeyboardShortcut` must be a function that will be invoked when the keys are pressed."
        );
    }

    const shortcutTriggerKeyLowerCase = useMemo(() =>
    {
        return shortcutTriggerKey.toLowerCase();
    }, [shortcutTriggerKey]);

    /**
     * 1. if repeating key ->  do nothing
     * 2. if trigger key -> update state
     * 3. if trigger key is down and a shortcut key is pressed -> call callback function with shortcut key pressed
     */
    const keydownListener = useCallback((keydownEvent) =>
    {
        const { key, repeat } = keydownEvent;

        let lowercaseKey = key?.toLowerCase();

        if (repeat)
        {
            return;
        }
        else if (shortcutTriggerKeyLowerCase === lowercaseKey)
        {
            callback(lowercaseKey);
        }


    }, [shortcutTriggerKeyLowerCase]);


    // add keydown event
    useEffect(() =>
    {
        window.addEventListener("keydown", keydownListener, true);
        return () => window.removeEventListener("keydown", keydownListener, true);
    }, [keydownListener]);

};
