const { toMercatorFromArray } = require("../utils/map.utils");
const { POI_IMAGE_SIZE, INDOORS_REF_TYPE } = require("../utils/map.constants");
const { rotateImage } = require("./features");
const { default: ImageLayer } = require("ol/layer/Image");
const { default: Static } = require("ol/source/ImageStatic");
const turf = require("@turf/turf");
const PolygonController = require("./polygons");

class ImageController
{
    constructor({ accessor, theme, filerUrl })
    {
        this.accessor = accessor;
        this.theme = theme;
        this.filerUrl = filerUrl;

        this.polygonController = new PolygonController({ accessor });
    }

    createEntityImageLayer(entity, feature)
    {
        const access = new this.accessor(entity);

        const isImageOnMap = access.getIsImageOnMap();
        let imgLayer = undefined;

        if (isImageOnMap)
        {
            // check if entity has image extent
            // convert to mercator
            imgLayer = this.createImageLayer({
                imgId: access.getImage({ theme: this.theme }),
                entityShape: access.getShape(),
                rotation: access.getImageRotation(),
                extent: access.getImageExtent(),
                feature,
                refType: access.getRefType()
            });
        }

        return imgLayer;
    }

    createImageLayer({ imgId, entityShape, rotation = 0, extent, feature = undefined, refType })
    {
        if (!imgId)
        {
            return undefined;
        }

        let convertedExtent = undefined;

        if (Array.isArray(extent))
        {
            let minPoint = [extent[0], extent[1]];
            let maxPoint = [extent[2], extent[3]];

            if (minPoint[0] === maxPoint[0] && minPoint[1] === maxPoint[1])
            {
                // set converted extent to undefined if extent size is 0
                // create image source will handle the creation of the extent depending on entity shape
                convertedExtent = undefined;
            }
            else
            {
                convertedExtent = [...toMercatorFromArray(minPoint), ...toMercatorFromArray(maxPoint)];
            }
        }

        let imageSource = this.createImageSource({ imgId, entityShape, extent: convertedExtent, rotation, refType });

        let imageLayer = new ImageLayer({
            source: imageSource,
            properties: { feature },
            crossOrigin: 'Anonymous',
        });

        imageLayer.set('properties', { feature })

        return imageLayer;
    }

    createImageSource({ imgId, entityShape, extent, rotation, refType })
    {
        let imgUrl = this.filerUrl ? `${this.filerUrl}${imgId}` : imgId;

        let imageExtent;
        if (extent)
        {
            imageExtent = extent;
        }
        else if (entityShape)
        {
            imageExtent = this.getImageBox(entityShape, refType);
        }

        // Manually create canvas because images that are too small do not create a canvas.
        // We need the image to be in a canvas to transform the image (rotations, draw border, resize, etc);
        const loadImageFunction = async (image, src) =>
        {

            const canvasImg = new Image();
            canvasImg.crossOrigin = "Anonymous";

            canvasImg.onload = () =>
            {
                let canvas = rotateImage({ image: canvasImg, degree: rotation, source: imageSource });

                image.setImage(canvas);
            };

            // race condition fix
            image.getImage().onload = () =>
            {
                canvasImg.src = src;
            };

            image.getImage().src = src; // although we change image to canvas, this line is needed to register changed event. image.changed() does not seem to work.

        };

        let imageSource = new Static({
            url: imgUrl,
            imageExtent: imageExtent,
            imageLoadFunction: loadImageFunction,
        });


        return imageSource;
    }

    getImageBox(entityShape, refType)
    {
        if (entityShape.type === "Polygon")
        {
            const scale = .5;

            // scale image to a percentage of the polygon
            let polygon = turf.transformScale(entityShape, scale);

            polygon.coordinates.forEach((coordSet) =>
            {
                coordSet.forEach((coord, j) =>
                {
                    coordSet[j] = toMercatorFromArray(coord);
                });
            });

            let imageBox = turf.bbox(polygon);
            let width = imageBox[2] - imageBox[0];
            let height = imageBox[3] - imageBox[1];

            if (width > height)
            {
                const diff = width - height;
                imageBox[2] = imageBox[2] - (diff / 2);
                imageBox[0] = imageBox[0] + (diff / 2);
            }
            else
            {
                const diff = height - width;
                imageBox[3] = imageBox[3] - (diff / 2);
                imageBox[1] = imageBox[1] + (diff / 2);
            }
            // let imageBox = turf.square(turf.bbox(polygon));
            return imageBox;
        }
        else if (entityShape.type === "Point")
        {
            const mercator = turf.toMercator(entityShape);
            const size = refType === INDOORS_REF_TYPE ? POI_IMAGE_SIZE.INDOOR : POI_IMAGE_SIZE.OUTDOOR;
            const [x, y] = mercator.coordinates;
            return [x - size / 2, y - size / 2, x + size / 2, y + size / 2];
        }

        return imageBox;
    }

    removeEntityImage(entityId, imageLayers)
    {
        const _imageLayers = { ...imageLayers };
        delete _imageLayers[entityId];
        return _imageLayers;
    }

    /**
     * Mutable function that translates the image layer with the given start and end coordinate.
     * @param {*} imageLayer
     * @param {*} startCoordiante
     * @param {*} endCoordinate
     */
    translateImageLayer(imageLayer, startCoordiante, endCoordinate)
    {
        // translate image box
        const imageSource = imageLayer.getSource();

        this.translateImageSource(imageSource, startCoordiante, endCoordinate);

    }

    translateImageSource(imageSource, startCoordiante, endCoordinate)
    {
        // IMPORTANT
        // imageSource.imageExtent_ = orginal image extent size
        // imageSource.image_.extent = warped image extent to fit rotation

        // translate image box
        imageSource.image_.extent = this.polygonController.getTranslatedExtent(imageSource.image_.extent, startCoordiante, endCoordinate);
        imageSource.imageExtent_ = this.polygonController.getTranslatedExtent(imageSource.imageExtent_, startCoordiante, endCoordinate);
        imageSource.changed();
    }

    getImageBoundingPolygonWithRotation(imageLayer, rotation)
    {
        const imageSource = imageLayer.getSource();
        // check if latlng is needed before rotate
        const imageExtent = imageSource.getImageExtent();

        const WGS84ExtentMin = turf.toWgs84([imageExtent[0], imageExtent[1]]);
        const WGS84ExtentMax = turf.toWgs84([imageExtent[2], imageExtent[3]]);

        let imageBoundingPolygon = turf.bboxPolygon([WGS84ExtentMin[0], WGS84ExtentMin[1], WGS84ExtentMax[0], WGS84ExtentMax[1]]);

        if (rotation)
        {
            imageBoundingPolygon = turf.transformRotate(imageBoundingPolygon, rotation);
        }

        imageBoundingPolygon.geometry.coordinates.forEach((coordSet, i) =>
        {
            let coordSetMercator = [];

            coordSet.forEach((coords) =>
            {
                coordSetMercator.push(turf.toMercator(coords));
            });

            imageBoundingPolygon.geometry.coordinates[i] = coordSetMercator;
        });

        return imageBoundingPolygon;
    }
}

module.exports = ImageController;
