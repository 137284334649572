import React from 'react';
import { useTranslation } from 'react-i18next';
import './layout.css';

export const Footer = () =>
{
    const trans = useTranslation().t;
    var today = new Date();
      return (
          <div className="footer">
              {`${trans("Footer.Copyright")} © 2014-${today.getFullYear()} ${trans("Footer.Mapsted_Corp__All_rights_reserved_")}`}
          </div>
      );
}

export default Footer;