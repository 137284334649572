const { fromLonLat } = require("ol/proj");
const { Circle } = require("ol/geom");
const { deepValue } = require("mapsted.utils/objects");
const { MapConstants, ENTITY_CLICK_THRESHOLD } = require("../utils/map.constants");
const { toMercatorFromArray } = require("../utils/map.utils");
const { getFeatureTopCoordinate, getBoundingBoxPolygonFromExtent, isPointsInPolygon } = require("./features");
const { EntityType, StructureEntityType } = require("../utils/entityTypes");
const { CMSEntityAccess } = require("./entityAccess");
const { default: ImageLayer } = require("ol/layer/Image");

/**
 * The selected object
 * @typedef {object} getSelectedFromClickReturn
 * @property {string} entityId
 * @property {Array} coordinate
 * @property {object} entityFeature
 * @property {boolean} canName
 * @property {boolean} [imageCheck]
 */

/**
* Takes pointer event and returns selectedObjectId and coordinate clicked.
*
* If a feature is not clicked, returns undefined.
* @param {object} params
* @param {Event} params.pointerEvent
* @param {object} params.olMap
* @param {object} params.options
*
* @returns {getSelectedFromClickReturn} selectedObject.selectedObjectId
*/
exports.getSelectedEntityFromClick = ({ pointerEvent, olMap, options = {} }) =>
{
    let entityId = undefined;
    let coordinate = undefined;
    let entityFeature = undefined;
    let canName = false;

    olMap.forEachFeatureAtPixel(pointerEvent.pixel, (feature, layer) =>
    {
        const isNode = feature.get("isNode");
        const ignoreClick = feature.get("ignoreClick");
        if (isNode || ignoreClick)
        {
            return false;
        }

        const clusterConnectedFeature = (feature.get("features")) && feature.get("features")[0];
        const connectedFeature = feature.get("connectedFeature");

        if (clusterConnectedFeature || connectedFeature)
        {
            feature = clusterConnectedFeature || connectedFeature;
        }

        entityId = feature.get("id");
        canName = feature.get("canName");

        coordinate = getFeatureTopCoordinate(feature);
        entityFeature = feature;
        return true;
    }, options);

    if (!entityId)
    {
        const clickCircle = new Circle(pointerEvent.coordinate, ENTITY_CLICK_THRESHOLD);
        olMap.forEachLayerAtPixel(pointerEvent.pixel, (layer) =>
        {
            const feature = deepValue(layer.get("properties"), "feature", undefined);
            if (feature && clickCircle.intersectsExtent(feature.get("geometry").getExtent()))
            {

                entityId = feature.get("id");
                canName = feature.get("canName");
                coordinate = getFeatureTopCoordinate(feature);
                entityFeature = feature;
                return true;
            }
        });
    }

    return { entityId, coordinate, entityFeature, canName };
};


exports.getSelectedEntityInfoFromClick = ({ pointerEvent, olMap, options = {} }) =>
{
    let entityId = undefined;
    let coordinate = undefined;
    let entityFeature = undefined;
    let canName = false;

    // This method of detecting entity using forEachLayerAtPixel and click circle is best used for only POI entity types
    const clickCircle = new Circle(pointerEvent.coordinate, ENTITY_CLICK_THRESHOLD);
    olMap.forEachLayerAtPixel(pointerEvent.pixel, (layer) =>
    {
        // check if clicked on a POI image
        if (layer instanceof ImageLayer)
        {
            const imgExtent = layer.getSource().getImageExtent();
            const imgBox = getBoundingBoxPolygonFromExtent(imgExtent);
            const ptsInImgBox = isPointsInPolygon([pointerEvent.coordinate], imgBox);

            if (ptsInImgBox)
            {
                const feature = deepValue(layer.get("properties"), "feature", undefined);
                if (feature
                    && clickCircle.intersectsExtent(feature.get("geometry").getExtent())
                    && feature.get("entityType") === EntityType.POINT_OF_INTEREST)
                {
                    entityId = feature.get("id");
                    canName = feature.get("canName");
                    coordinate = getFeatureTopCoordinate(feature);
                    entityFeature = feature;
                    return true;
                }
            }

        }
    });

    // For other non-POI polygon entity types better to use this method
    if (!entityId)
    {
        olMap.forEachFeatureAtPixel(pointerEvent.pixel, (feature, layer) =>
        {
            const clusterConnectedFeature = (feature.get("features")) && feature.get("features").connectedFeature;
            const connectedFeature = feature.get("connectedFeature");

            if (clusterConnectedFeature || connectedFeature)
            {
                feature = clusterConnectedFeature || connectedFeature;
            }

            entityId = feature.get("id");
            canName = feature.get("canName");

            coordinate = getFeatureTopCoordinate(feature);
            entityFeature = feature;
            return true;
        }, options);
    }

    return { entityId, coordinate, entityFeature, canName };
};

/**
 * Takes pointer event and map, returns selected entity text feature.
 * Used when text needs to be interactable
 * @param {object} params
 * @param {Event} params.pointerEvent
 * @param {object} params.olMap
 * @param {boolean} params.checkForImage
 *
 * @returns {getSelectedFromClickReturn} selectedObject.selectedObjectId
 */
exports.getSelectedTextFromClick = ({ pointerEvent, olMap, checkForImage }) =>
{
    let entityId = undefined;
    let coordinate = undefined;
    let entityFeature = undefined;
    let canName = false;
    let imageCheck = false;

    olMap.forEachFeatureAtPixel(pointerEvent.pixel, (feature, layer) =>
    {
        const cluster = feature.get("features");

        if (Array.isArray(cluster))
        {
            feature = cluster.displayedTextFeature;

            entityId = feature.get("entityId");
            canName = feature.get("canName");
            coordinate = getFeatureTopCoordinate(feature);
            entityFeature = feature;
        }
        else if (!!checkForImage && feature.get("canName"))
        {
            entityId = feature.get("id");
            canName = feature.get("canName");
            coordinate = pointerEvent.coordinate;
            entityFeature = feature;
            imageCheck = true;
        }

        return true;
    });

    if (!entityId)
    {
        const clickCircle = new Circle(pointerEvent.coordinate, ENTITY_CLICK_THRESHOLD);
        olMap.forEachLayerAtPixel(pointerEvent.pixel, (layer) =>
        {
            const feature = deepValue(layer.get("properties"), "feature", undefined);
            if (feature && feature.get("canName") && clickCircle.intersectsExtent(feature.get("geometry").getExtent()))
            {
                entityId = feature.get("id");
                canName = feature.get("canName");
                coordinate = pointerEvent.coordinate;
                entityFeature = feature;
                imageCheck = true;
                return true;
            }
        });
    }


    return { entityId, coordinate, entityFeature, canName, imageCheck };
};

exports.getNodeDataFromClick = ({ pixel, olMap }) =>
{
    let isNode;
    let nodeId;
    let nodeId1;
    let nodeId2;
    let isNodeConnection;
    let nodeFeature;
    let isNodeForbidden;

    let entityId = -1;

    olMap.forEachFeatureAtPixel(pixel, (feature, layer) =>
    {
        if (layer)
        {
            if (entityId === -1)
            {
                const ignoreClick = feature.get("ignoreClick");
                if (ignoreClick)
                {
                    return false;
                }

                const cluster = feature.get("features");

                if (Array.isArray(cluster))
                {
                    // avoid attaching nodes to an entity based off of text location 
                    // text location can overlap entities
                    return;
                }

                let connectedFeature = feature.get("connectedFeature");

                if (connectedFeature)
                {
                    // avoid attaching nodes to an entity based off of text location 
                    // text location can overlap entities
                    return;
                }

                let entityAccess = new CMSEntityAccess(feature.values_);
                // if we can name the entity then it should have node IDs associated with it
                let isRoutable = entityAccess.getIsRoutableEntity();
                let isInaccessible = entityAccess.getIsEntityInaccessibleStructure();
                isNodeForbidden = entityAccess.getIsNodeForbidden();

                if (isRoutable || isNodeForbidden)
                {
                    // we need to make sure we pul the entity on top
                    entityId = feature.get("id");
                }
                // if we haven't found an entityId already
                else if (isInaccessible)
                {
                    entityId = feature.get("id");
                }

            }
        }
    });

    olMap.forEachFeatureAtPixel(pixel, (feature, layer) =>
    {
        if (feature)
        {
            const ignoreClick = feature.get("ignoreClick");
            if (ignoreClick)
            {
                return false;
            }
            isNode = feature.get("isNode");
            let connectedFeature = feature.get("connectedFeature");

            if (!isNode && connectedFeature && connectedFeature.get("isNode"))
            {
                feature = connectedFeature;
                isNode = connectedFeature.get("isNode")
            }

            if (isNode)
            {
                nodeId = feature.getId();
                nodeId1 = feature.get("nodeId1");
                nodeId2 = feature.get("nodeId2");
                isNodeConnection = feature.get("isNodeConnection");

                nodeFeature = feature;

                // prioritize nodes over node connections (links)
                if (!isNodeConnection)
                {
                    return true;
                }
            }
        }


    });

    return { isNode, nodeId, nodeId1, nodeId2, isNodeConnection, isNodeForbidden, nodeFeature, entityId };
}

//*****************************************************//
// ************** Map Mutable Functions ************** //
//*****************************************************//

/**
 * Changes map zoom with boundary polygon
 * @param {object} params
 * @param {any} params.olMap
 * @param {any} params.boundaryPolygon
 * @param {number | null} params.duration
 * @param {Array | null} params.padding
 * @param {number | null} params.maxZoom
 * @param {number | null} params.zoom
 * @param {number | null} params.rotation
 * @param {{coordinates: Array<number>} | null} params.mapCenter
 */
exports.changeMapCenterWithBoundaryPolygon = ({ olMap, boundaryPolygon, duration = undefined, padding = undefined, maxZoom = undefined, zoom = undefined, mapCenter = undefined, rotation = undefined }) =>
{
    const updateDefaultZoom = (params) =>
    {
        if (params)
        {
            const animationConfig = {};
            if (zoom)
            {
                animationConfig.zoom = zoom;
            }
            if (mapCenter)
            {
                animationConfig.center = fromLonLat(mapCenter.coordinates);
            }
            if (rotation !== undefined)
            {
                animationConfig.rotation = rotation;
            }
            if (Object.keys(animationConfig).length > 0)
            {
                olMap.getView().animate(animationConfig);
            }

        }
    };

    const options = { duration, padding, callback: updateDefaultZoom };
    if (!duration)
    {
        options["duration"] = MapConstants.ANIMATION_DURATION;
    }

    if (!padding)
    {
        options["padding"] = MapConstants.FIT_PADDING_ENTITIES;
    }

    if (maxZoom)
    {
        options["maxZoom"] = maxZoom;
    }

    if (boundaryPolygon)
    {
        olMap.getView().fit(boundaryPolygon, options);
    }
};

/**
 * Changes map location with gps coordinates
 * @param {object} params
 * @param {object} params.olMap
 * @param {Array<number>} params.coordinates
 * @param {number} [params.zoom=17]
 */
exports.changeMapCenterWithGpsCoords = ({ olMap, coordinates, zoom = 17, rotation }) =>
{
    olMap.getView().animate({ zoom: zoom, duration: MapConstants.ANIMATION_DURATION, center: toMercatorFromArray(coordinates), rotation });
};

/**
 * Changes map location with mercator points
 * @param {object} params
 * @param {object} params.olMap
 * @param {Array<number>} params.mercator
 * @param {number} params.zoom
 */
exports.changeMapCenterWithMercator = ({ olMap, mercator, zoom, rotation, }) =>
{
    olMap.getView().animate({ zoom: zoom, duration: MapConstants.ANIMATION_DURATION, center: mercator, rotation });
};
