import React from "react";
import { useTranslation } from "react-i18next";
import "./style.css";
import { createPortal } from "react-dom";

export const Loader = ({ active, animation=true }) =>
{
    const trans = useTranslation().t;
    return createPortal(
        <div className="loading-container" hidden={!active}>
            {animation && <img src="/img/loading-m.gif" alt={trans("index.Loading___")} />}
        </div>,
        document.body
    );
};
