import React, { useContext, useRef, useState, useEffect } from "react";
import { Image, Button } from "semantic-ui-react";
import { isEmpty } from "lodash";
import { ButtonBasic } from "../../common/ButtonIcon";
import BrandingContext from "../../../store/BrandingContext";
import { ModalBox } from "../../common/ModalBox";
import { MapButtons } from "../../branding/map/MapButtons";
import { useBrandingMap, useMapZoomMethods, usePropertyBrandingMap } from "../../branding/map/mapHooks";
import { transformExtent } from "ol/proj";
import { SettingsHeading } from "../../settings/SettingsHeading";
import { degreesToRadians, radiansToDegees } from "../../../_utils/utils";
import { useTranslation } from "react-i18next";

const FocusPoint = ({ focusPoint, focusPointMobile, handleSave, isBuilding, mapRotation, mapRotationMobile }) => 
{
    const [focusPointData, setFocusPointData] = useState({ open: false, mode: FOCUSPOINT_SELECT_MODES.DESKTOP });
    const trans = useTranslation().t;
    return (
        <div className="settingsPageTabCover">
            <SettingsHeading heading={isBuilding ? trans("Settings.Building_Focus_Point") : trans("Settings.Property_Focus_Point")}>
                {trans("Settings.Focus_Point_Desc")}
            </SettingsHeading>
            <div className="zoomLevelItem">
                <Image as="span" src="/img/icon-device-web2.svg" alt="" />
                <p>{trans("Settings.Desktop_View")}</p>
                <ButtonBasic content={trans("MapOverlaysUploadCsvModal.Change")} onClick={() => setFocusPointData({ mode: FOCUSPOINT_SELECT_MODES.DESKTOP,open: true })} />
            </div>
            <div className="zoomLevelItem">
                <Image as="span" src="/img/icon-device-mobile2.svg" alt="" />
                <p>{trans("Settings.Mobile_View")}</p>
                <ButtonBasic content={trans("MapOverlaysUploadCsvModal.Change")} onClick={() => setFocusPointData({ mode: FOCUSPOINT_SELECT_MODES.MOBILE,open: true })} />
            </div>
            {focusPointData.open && (
                <FocusPointModal
                    open={focusPointData.open}
                    onClose={() => setFocusPointData((prev) => ({ ...prev, open: false }))}
                    handleSave={handleSave}
                    focusPoint={focusPoint}
                    focusPointMobile={focusPointMobile}
                    mode={focusPointData.mode}
                    isBuilding={isBuilding}
                    mapRotation={mapRotation}
                    mapRotationMobile ={mapRotationMobile}
                />
            )}
        </div>
    );
};

export default FocusPoint;

export const FocusPointModal = ({
    open,
    onClose,
    focusPoint,
    focusPointMobile,
    handleSave,
    isBuilding,
    mode,
    mapRotation, 
    mapRotationMobile
}) => 
{      
    const trans = useTranslation().t;
    // Get branding state from context
    const { state: brandingState } = useContext(BrandingContext); // Make sure BrandingContext is defined

    // Map related hooks
    const desktopRef = useRef();
    const mobileRef = useRef();
    const mapRefs = {
        [FOCUSPOINT_SELECT_MODES.DESKTOP]: desktopRef,
        [FOCUSPOINT_SELECT_MODES.MOBILE]: mobileRef
    };

    // Get the appropriate map and zoom methods based on building type
    // let options ={ 
    //     mapOptions: {
    //         controlsOptions: { rotate: false },
    //         viewOptions: { enableRotation: false }

    //     },
    // };
    const { mapRef: brandingMapRef, recenter } = isBuilding ? useBrandingMap(mapRefs[mode]) : usePropertyBrandingMap(mapRefs[mode]);
    const { zoomIn, zoomOut } = useMapZoomMethods(brandingMapRef);

    // useEffect to handle map rendering and focus
    useEffect(() => 
    {
        if (!open) return;

        // Set the map target to the current mode's reference
        brandingMapRef.current.setTarget(mapRefs[mode].current);

        // Focus on the selected point
        focusMapToPoint();
    }, [open, mode]);    

    // useEffect to handle map focus when brandingState.loading changes
    // useEffect(() => 
    // {
    //     if (!brandingState.loading) 
    //     {
    //         focusMapToPoint();
    //     }
    // }, [brandingState.loading]);

    // Function to focus the map on a selected point
    const focusMapToPoint = () => 
    {
        const selectedFocusPoint = mode === FOCUSPOINT_SELECT_MODES.DESKTOP ? focusPoint : focusPointMobile;
        const rotation = mode === FOCUSPOINT_SELECT_MODES.DESKTOP ? mapRotation : mapRotationMobile;

        if (isEmpty(selectedFocusPoint)) 
        {
            recenter();
        }
        else 
        {        
            const extent = transformExtent(selectedFocusPoint, "EPSG:4326", "EPSG:3857"); 
            brandingMapRef.current.getView().fit(extent, { 
                duration: 500,
                callback: () =>
                {
                    const currentRotation = brandingMapRef.current.getView().getRotation();
                    const newRotation = (360 - radiansToDegees(currentRotation)) + radiansToDegees(rotation);
                    brandingMapRef.current.getView().adjustRotation(degreesToRadians(newRotation));
                }
            });
        }
    };

    // Function to handle the "Apply" button click
    const handleApply = () => 
    {
        // rotate back the map to 0 deg to get correct extent
        const currentRotation = brandingMapRef.current.getView().getRotation();
        const currentRotationDeg = radiansToDegees(currentRotation);
        brandingMapRef.current.getView().adjustRotation(degreesToRadians(360 - currentRotationDeg));

        const size = brandingMapRef.current.getSize();
        let extent = brandingMapRef.current.getView().calculateExtent(size);

        const isDesktopMode = mode === FOCUSPOINT_SELECT_MODES.DESKTOP;
        let focusPointKey;

        if (isBuilding) 
        {
            focusPointKey = isDesktopMode ? "focusPoint" : "focusPointMobile";
        }
        else 
        {
            focusPointKey = isDesktopMode ? "propertyFocusPoint" : "propertyFocusPointMobile";
        }

        // Update the focus point in the mutate function
        const focusPointUpdate = {
            [focusPointKey]: transformExtent(extent, "EPSG:3857", "EPSG:4326")
        };

        // Apply the updates and close the modal
        handleSave(focusPointUpdate);
        onClose();
    };

    return (
        <ModalBox
            className="orientationModal"
            open={open}
            onClose={onClose}
            header={isBuilding ? trans("Settings.Building_Focus_Point") : trans("Settings.Property_Focus_Point")}
            actions={<Button className="wide" color="orange" floated="right" content={trans("Settings.Apply")} onClick={handleApply} />}
        >
            <p className="paraModal paraDesignatedModal">
                {
                    trans("Settings.Select_a_portion_of_the_map_that_will_become_your_primary_map_view")
                }            
            </p>

            <div className="orientationModalbody disableRotation">
                {/* <span className="instructionTooltip">Use Shift + Alt and Drag to rotate the map</span> */}

                {mode === FOCUSPOINT_SELECT_MODES.DESKTOP && (
                    <div className="map-container" ref={mapRefs[mode]}>
                        <MapButtons onZoomIn={zoomIn} onZoomOut={zoomOut} floorButtons={ isBuilding ? true : false } />
                    </div>
                )}
                {mode === FOCUSPOINT_SELECT_MODES.MOBILE && (
                    <PhoneOverlay mapRef={mapRefs[mode]} />
                )}
            </div> 
        </ModalBox>
    );
};


export const FOCUSPOINT_SELECT_MODES = {
    MOBILE: "mobile",
    DESKTOP: "desktop"
};

const PhoneOverlay = ({ mapRef }) => (
    <div className="phoneOverlayViewBox">
        <div className="screenViewPhoneBox" ref={mapRef}>
            <div className="screenViewActions">
                <img src="/img/mobile-view-search.svg" alt="" />
                <img src="/img/mobile-view-left.svg" alt="" />
                <img src="/img/mobile-view-right.svg" alt="" />
            </div>
            <span className="actionView"></span>
        </div>
    </div>
);



