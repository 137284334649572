import { useContext } from "react";
import { useQuery } from "react-query";
import BrandingContext from "../../store/BrandingContext";
import serverApi from "../../_api/server.api";
import { DATA_QUERIES } from "../../_utils/queries";

export const SETTINGS_QUERIES = {
    SAVE_SETTINGS: (propertyId) => ({
        mutationKey: ["get_property_settings", propertyId],
        mutationFn: (patch) => serverApi.post(`/api/internal/properties/${propertyId}/settings`, patch, true)
            .then(({ success, result, message }) =>
            {
                if (!success)
                {
                    throw new Error(message);
                }
                return result;
            })
    }),
    GET_KIOSK_ICONS: (theme, color) => ({
        enabled: !!theme,
        queryKey: ["GET_KIOSK_ICONS", theme, color],
        queryFn: () => serverApi.get(`/api/internal/styled-icons/kiosk?color=${color}&theme=${theme}`, true),
        select: (data) => data?.success ? data.kioskIcons : [],
        initialData: []
    }),
    GET_KIOSKS: (propertyId, buildingId, floorId) => ({
        enabled: !!(propertyId || buildingId || floorId),
        queryFn: () => serverApi.post("/api/internal/kiosk/getKiosks", { propertyId, buildingId, floorId }, true),
        select: (data) => data?.success ? data.kiosks : [],
    })
};

export const SETTINGS_MUTATIONS = {
    CREATE_KIOSK: () => ({
        mutationFn: (data) => serverApi.post("/api/internal/kiosk/createKiosk", data, true),
    }),
    DELETE_KIOSK: () => ({
        mutationFn: (id) => serverApi.delete(`/api/internal/kiosk/deleteKiosk/${id}`, true)
    }),
    UPDATE_KIOSK: () => ({
        mutationFn: (data) => serverApi.patch("/api/internal/kiosk/updateKiosk", data, true),
    })
};


export function usePropertySettings()
{
    const brandingContext = useContext(BrandingContext);
    const { data, ...rest } = useQuery(DATA_QUERIES.GET_PROPERTY_SETTINGS(brandingContext.state.propertyId));

    return [data, rest];
}
