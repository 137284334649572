import axios from "axios";
import { get as getValue } from "lodash";
import serverApi from "../_api/server.api";
import contactUsMessages from "../_constants/contactUsMessages";
import { OPEN_24H_MINS, SINGLE_LANG_INPUT_CODE } from "../_constants/constants";
import { fieldSortComparator } from "mapsted.utils/arrays";
import { endOfDay, startOfDay, subDays, } from "date-fns";
import _ from "lodash";


const moment = require("moment");
const normalizeUrl = require("normalize-url");
const { parsePhoneNumberFromString } = require("libphonenumber-js");
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;


/**
 *  Given website url and message type returns full url with user and company info in url params.
 *  @type {(websitePrefix?: string; messageType?: keyof contactUsMessages) => string}
 */
export const getContactUsParamsUrl = (websitePrefix = "https://mapsted.com/contact-us?", messageType) =>
{
    const user = serverApi.userData.user;
    const { firstName, lastName, email, phoneNumber } = user.userInfo;
    const { name } = user.userCompanyInfo;

    const params = new URLSearchParams();
    firstName && params.set("fname", firstName);
    lastName && params.set("lname", lastName);
    name && params.set("company", name);
    phoneNumber && params.set("phone", phoneNumber);
    email && params.set("email", email);
    messageType && params.set("message", contactUsMessages[messageType]);

    const targetUrl = `${websitePrefix}${params}`;
    return targetUrl;
};


/**
 * Converts number of minutes to formated time using moment.
 * @param {number} mins - Number of minutes.
 * @param {string} [format="h:mm A"] - Format to be used.
 */
export const getTimeFromMins = (mins, format = "hh:mm A") =>
{
    // do not include the first validation check if you want, for example,
    // getTimeFromMins(1530) to equal getTimeFromMins(90) (i.e. mins rollover)
    if (mins >= 24 * 60 || mins < 0)
    {
        throw new RangeError("Valid input should be greater than or equal to 0 and less than 1440.");
    }
    var h = mins / 60 | 0,
        m = mins % 60 | 0;
    return moment.utc().hours(h).minutes(m).format(format);
};

/**
 * Takes a string time in "hh:mm a" format and converts it to mins.
 * @param {string} time - "hh:mm a" format;
 */
export const getMinsFromTime = (time) =>
{
    const t = moment(time, "hh:mm a");
    return (t.hour() * 60) + t.minutes();
};

/**
 * Formats a moment date.
 * @param {moment} date - date to be formatted.
 * @param {*} format - the new format of the date.
 */
export const formatDate = (date = moment(), format = "LLLL") => moment(date).format(format);

/**
 * Returns a list of hours used for dropdowns.
 */
export const getHoursList = () =>
{
    const options = [];
    for (let i = 0; i < 24; i++)
    {
        const mins = i * 60;
        options[i] = { key: mins, value: mins, text: getTimeFromMins(mins) };
    }

    return options;
};

/**
 * Returns the current the next available date.
 * @param {Array} selectedDates - If selected dates, checks next available date with selected dates.
 */
export const getCalanderStart = (selectedDates) =>
{
    let initDate = moment().add(1, "days").startOf("day");

    if (Array.isArray(selectedDates))
    {
        for (let i = 0; i < selectedDates.length; i++)
        {
            if (initDate.isSame(selectedDates[i]))
            {
                initDate = initDate.add(1, "days");
            }
            else
            {
                return initDate;
            }
        }
    }
    return initDate;
};

/**
 * for analytics
 */
export const getAnalyticsDateRanges = () =>
{
    const startDate = startOfDay(subDays(new Date(), 30));
    const endDate = endOfDay(mostRecentAvailableDate());

    const compareStartDate = subDays(startDate, 30);

    return {
        startDate: startDate.toUTCString(),
        endDate: endDate.toUTCString(),
        compareStartDate: compareStartDate.toUTCString(),
        compareEndDate: startDate.toUTCString()
    };
};

export const mostRecentAvailableDate = () =>
{
    let date = new Date();
    date = subDays(date, 1);

    return date;
};

/**
 * for analytics
 * @param {} filter
 * @param {*} apiKey
 * @returns
 */
export const filterToUrlParams = (filter) =>
{


    let urlPrams = "?";

    Object.keys(filter).forEach((param) =>
    {
        if (filter[param])
        {
            urlPrams += `${param}=${filter[param]}&`;
        }
    });

    urlPrams = urlPrams.slice(0, -1);

    return urlPrams;
};
/**
 * Returns the last available date.
 */
export const getCalanderEnd = () =>
{
    let endDate = moment().endOf("year").add(1, "years");
    return endDate;
};

/**
 * Rounds number to num of decimal places.
 * @param {Int} value
 * @param {Int} decimals
 */
export const round = (value, decimals) => Number(Math.round(value + "e" + decimals) + "e-" + decimals);

/**
 * Returns the percentage increase or decrease from value / comparedToValue
 * @param {Int} value
 * @param {Int} comparedToValue
 */
export const getPercentageDifference = (value, comparedToValue) =>
{
    let percentage = value / comparedToValue;
    return round((percentage - 1) * 100, 2);
};

/**
 * Callback for returning uploaded image.
 * @callback setImageCallBack
 * @param {string} image - Parsed image
 */

/**
 * Parses and returns uploaded image.
 * @param {String} fileInputId - ID of the file input.
 * @param {Function} setImageAsDataURL, setFile - A callback to return the parsed image.
 */
export const handleUploadFile = (domObject, setImageAsDataURL, setFile) =>
{
    if (!domObject)
    {
        return;
    }

    const reader = new FileReader();

    reader.addEventListener("load", (e) =>
    {
        setImageAsDataURL && setImageAsDataURL(e.target.result);
        domObject.value = "";

    }, false);

    const files = domObject.files;

    if (files && files.length > 0)
    {
        const file = files[0];

        setFile(file);
        reader.readAsDataURL(file);
    }
};

export const readFilesAsDataURL = (files) => files.map((file, idx) => new Promise((resolve, reject) => 
{
    const reader = new FileReader();
    reader.onload = ((ev) => resolve(ev.target.result));
    reader.onerror = ((err) => reject(err));
    reader.readAsDataURL(file);
}));


export const formatDateTime = (timeString) => 
{
    const date = new Date(timeString);
    return date.toLocaleString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "numeric",
        minute: "2-digit",
        hour12: true
    }).replace(/\//g, "-").replace("am", "AM").replace("pm", "PM");
};

export const sortTemplates = (templates, selectedFilter) => templates.sort((a, b) => 
{
    const aName = a.name?.en || "";
    const bName = b.name?.en || "";

    const isANumeric = /^\d/.test(aName); // Check if 'aName' starts with a digit
    const isBNumeric = /^\d/.test(bName); // Check if 'bName' starts with a digit

    switch (selectedFilter) 
    {
    case "newest":
        return new Date(b.createdAt || 0) - new Date(a.createdAt || 0);
                
    case "oldest":
        return new Date(a.createdAt || 0) - new Date(b.createdAt || 0);

    case "ascending":
        // Numerical comparison first
        if (isANumeric && isBNumeric) 
        {
            return aName.localeCompare(bName, undefined, { numeric: true });
        }
        else if (isANumeric) 
        {
            return -1; // Move numeric names ahead of alphabetic ones
        }
        else if (isBNumeric) 
        {
            return 1;
        }
        else 
        {
            // Alphabetical comparison if both are non-numeric
            return aName.localeCompare(bName);
        }

    case "descending":
        // Numerical comparison first in reverse order
        if (isANumeric && isBNumeric) 
        {
            return bName.localeCompare(aName, undefined, { numeric: true });
        }
        else if (isANumeric) 
        {
            return -1; // Move numeric names ahead of alphabetic ones
        }
        else if (isBNumeric) 
        {
            return 1;
        }
        else 
        {
            // Alphabetical comparison in reverse order if both are non-numeric
            return bName.localeCompare(aName);
        }

    default:
        return 0;
    }
});

export const filterTemplates = (templates, searchFilter) => 
{
    if (!searchFilter) return templates;

    return templates.filter((template) => 
    {
        const name = template.name?.en?.toLowerCase() || ""; // Handle null or undefined values
        const group = template.group?.toLowerCase() || "";   // Handle null or undefined values
        
        const nameMatches = name.includes(searchFilter.toLowerCase());
        const groupMatches = group.includes(searchFilter.toLowerCase());

        return nameMatches || groupMatches;
    });
};


export const updatePropertiesPerPage = (setPropertiesPerPage) => 
{
    const width = window.innerWidth;
    if (width >= 2560) 
    {
        setPropertiesPerPage(12); // Show 12 properties on larger screens
    }
    else if (width >= 1920) 
    {
        setPropertiesPerPage(10); // Show 10 properties for medium screens
    }
    else 
    {
        setPropertiesPerPage(7);  // Default value for smaller screens
    }
};

export const checkValidImageFormat = async (url, acceptableImageFileFormats, getData) => 
{
    const data = await (await fetch("/api/internal/public/images/responseHeaders?link=" + url)).json();

    if (data?.error) 
    {
        getData({ isValidFileFormat: false, data: null });
    }
    else if (data?.headers?.["content-type"]) 
    {
        const imageType = data.headers["content-type"];
        if (acceptableImageFileFormats.split(",").includes(imageType)) 
        {
            getData({ isValidFileFormat: true, data });
        }
        else 
        {
            getData({ isValidFileFormat: false, data: null });
        }
    }
};

// https://stackoverflow.com/questions/934012/get-image-data-url-in-javascript
export  const getBase64FromImageUrl = ({ url, imageType }) => new Promise((resolve, reject) => 
{
    const img = new Image();
    img.setAttribute("crossOrigin", "anonymous");

    img.onload = function () 
    {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(this, 0, 0, img.width, img.height);

        const dataURL = canvas.toDataURL(imageType);

        resolve(dataURL);
    };

    img.onerror = function (err) 
    {
        reject(err);
    };

    img.src = url;
});

export const imageUrl = (id) => `/api/internal/public/images/${id}`;

// pass cacheDuration (in seconds) to cache image files
export const filerUrl = (id, cacheDuration) =>
{
    const config = serverApi.getConfig() || {};
    let url = `${config.FILER_URL}?key=${config.FILER_DOWNLOAD_KEY}&id=${id}`;
    if (cacheDuration)
    {
        url += `&cache=${cacheDuration}`;
    }
    return url;
};

export const getSearchParams = (searchParams) =>
{
    let url = window.location.href;
    url = new URL(url);

    let data = {};

    searchParams.forEach((searchParam) =>
    {
        data[searchParam] = url.searchParams.get(searchParam) || undefined;
    });
    return data;
};

/**
 * Filters an object list and sorts on best match.
 * Best match in this case are words beginning with the filter.
 * @param {Array} list - List of objects to be filtered.
 * @param {String} filter - The filter being applied to the list.
 * @param {String} param - Object param to be called. i.e. property.["Name"]
 */
export const filterList = (list, filter, param, languageCode) =>
{
    filter = filter.toUpperCase();
    const upper = (s) => String(s).toUpperCase();
    if (languageCode)
    {
        let filteredList = list.filter((item) => upper(item[param][languageCode]).indexOf(filter) > -1);
        filteredList.sort((a, b) => upper(a[param][languageCode]).indexOf(filter) - upper(b[param][languageCode]).indexOf(filter));
        return filteredList;
    }
    else
    {
        let filteredList = list.filter((item) => upper(item[param]).indexOf(filter) > -1);
        filteredList.sort((a, b) => upper(a[param]).indexOf(filter) - upper(b[param]).indexOf(filter));
        return filteredList;
    }

};

/**
 * Creates a dropdown friendly list of objects.
 * @param {Array<Object>} data - Orginal list of objects.
 * @param {String} value - data refrence for dropdown value.
 * @param {String} text - data refrence for dropdown text.
 * @param {String} description - data refrence for dropdown description.
 */
export const toDropDownList = ({ data, value, text, description, key }) =>
{
    let list = [];

    if (Array.isArray(data))
    {
        data.forEach((entry) =>
        {
            list.push({ key: entry[key], value: entry[value], text: entry[text], description: entry[description] });
        });

        return list;
    }
    else
    {
        return list;
    }
};

export const toMercator = ({ lat, lng }) =>
{
    var dLon = 0.017453292519943295 * lng;
    var x = 6378137.0 * dLon;

    var dLat = 0.017453292519943295 * lat;
    var y = 3189068.5 * Math.log((1.0 + Math.sin(dLat)) / (1.0 - Math.sin(dLat)));

    return [x, y];
};

export const toMercatorFromArray = (coordinates) => toMercator({ lat: coordinates[1], lng: coordinates[0] });

export const toGpsLocation = ([x, y]) =>
{
    var dx = x / 6378137.0;
    var t4 = dx * 57.295779513082323;
    var t5 = Math.floor((t4 + 180.0) / 360.0);
    var lng = t4 - (t5 * 360.0);

    var t7 = 1.5707963267948966 - (2.0 * Math.atan(Math.exp((-1.0 * y) / 6378137.0)));
    var lat = t7 * 57.295779513082323;

    return [lng, lat];
};

export const handleNormalizeUrl = (url, params = { defaultProtocol: "https:" }) =>
{
    try
    {
        return normalizeUrl(url, params);
    }
    catch (err)
    {
        return url;
    }
};

export const phoneValidation = (value) =>
{
    const phoneNumber = parsePhoneNumberFromString(value);

    // Checks if string contains a character
    if (/[a-zA-Z]/.test(value))
    {
        return false;
    }
    // If country code not given, use regex check
    else if (phoneNumber === undefined)
    {
        return value.trim().match(phoneRegExp);
    }
    // If country code given, use googles check.
    else
    {
        return phoneNumber.isValid();
    }
};

/**
 * Sorts a hashmap based on sort property.
 *
 * @param {Object} hashMap
 * @param {String} key
 * @param {Function} getter
 */
export const hashSort = (hashMap, key, getter) =>
{
    let list = Object.values(hashMap);
    list.sort((a, b) =>
    {
        const a_val = getter(a);
        const b_val = getter(b);

        if (a_val < b_val)
        {
            return -1;
        }
        else if (a_val > b_val)
        {
            return 1;
        }
        return 0;
    });

    return Object.fromEntries(list.map((object) => [object[key], object]));
};

/**
 * Returns file size in bytes
 * https://stackoverflow.com/questions/13378815/base64-length-calculation
 **/
export const getFileSizeFromBase64 = (base64) =>
{
    let n = base64.length - "data:image/png;base64,".length;
    let y = 0;

    // Find paddinf
    if (base64[n - 3] === "=")
    {
        y = 3;
    }
    else if (base64[n - 2] === "=")
    {
        y = 2;
    }
    else if (base64[n - 1] === "=")
    {
        y = 1;
    }

    let sizeInBytes = 4 * (n / 3) - y;
    return sizeInBytes;
};

/**
 * Formats Bytes to kb, mb, gb
 * https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
 **/
export const formatBytes = (bytes, decimals = 2) =>
{
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};


/**
 * Based on documentation: https://developers.google.com/places/web-service/details
 * @param {Object} userAddress
 * @param {Number} userAddress.streetNumber
 * @param {Number} userAddress.unitNumber
 * @param {String} userAddress.street
 * @param {String} userAddress.city
 * @param {String} userAddress.province
 * @param {String} userAddress.postalCode
 * @param {String} userAddress.country
 */
export const convertManualAddressToAddressComponents = async (userAddress) =>
{
    const addressComponents = [];

    const countryInfo = await getCountryInfoFromName(userAddress.country);

    addressComponents.push({
        long_name: userAddress.streetNumber,
        short_name: userAddress.streetNumber,
        types: ["street_number"]
    });

    // TODO: make sure it is unit_number
    addressComponents.push({
        long_name: userAddress.unitNumber,
        short_name: userAddress.unitNumber,
        types: ["subpremise"]
    });

    addressComponents.push({
        long_name: userAddress.street,
        short_name: userAddress.street,
        types: ["route"]
    });

    addressComponents.push({
        long_name: userAddress.city,
        short_name: userAddress.city,
        types: [
            "locality",
            "political"
        ]
    });

    addressComponents.push({
        long_name: userAddress.province,
        short_name: userAddress.province,
        types: [
            "administrative_area_level_1",
            "political"
        ]
    });

    addressComponents.push({
        long_name: (countryInfo) ? countryInfo.name : userAddress.country,
        short_name: (countryInfo) ? countryInfo.alpha2Code : userAddress.country,
        types: [
            "country",
            "political"
        ]
    });

    addressComponents.push({
        long_name: userAddress.postalCode,
        short_name: userAddress.postalCode,
        types: [
            "postal_code"
        ]
    });

    return addressComponents;
};

/**
 *
 * @param {Object[]} addressComponents
 * @param {String} addressComponents[].long_name
 * @param {String} addressComponents[].short_name
 * @param {String[]} addressComponents[].types
 */
export const convertAddressComponentsToUserAddress = (addressComponents) =>
{
    let userAddress = {
        streetNumber: "",
        unitNumber: "",
        street: "",
        city: "",
        province: "",
        postalCode: "",
        country: "",
        countryAlpha2Code: ""
    };

    if (addressComponents)
    {
        addressComponents.forEach(({ long_name, short_name, types }) =>
        {
            if (types.includes("street_number"))
            {
                userAddress.streetNumber = long_name;
            }
            else if (types.includes("subpremise"))
            {
                userAddress.unitNumber = long_name;
            }
            else if (types.includes("route"))
            {
                userAddress.street = long_name;
            }
            else if (types.includes("locality"))
            {
                userAddress.city = long_name;
            }
            else if (types.includes("administrative_area_level_1"))
            {
                userAddress.province = long_name;
            }
            else if (types.includes("country"))
            {
                userAddress.country = long_name;
                userAddress.countryAlpha2Code = short_name;
            }
            else if (types.includes("postal_code"))
            {
                userAddress.postalCode = long_name;
            }
        });
    }

    return userAddress;
};

/**
 *
 * @param {Object[]} addressComponents
 * @param {String} addressComponents[].long_name
 * @param {String} addressComponents[].short_name
 * @param {String[]} addressComponents[].types
 */
export const isAddressComponentValid = (addressComponents) =>
{
    const userAddress = convertAddressComponentsToUserAddress(addressComponents);

    // Minimum required fields
    const isValid = isValidAddress(userAddress);

    return { userAddress, isValid };
};

export const isValidAddress = (userAddress) => !!userAddress.street && !!userAddress.city && !!userAddress.country;

export const getCountryInfoFromName = async (countryName) =>
{
    try
    {
        const listOfMatchingCountries = await serverApi.get(`/api/internal/countries?search=${countryName}`);

        let result = listOfMatchingCountries.find((countryObj) => countryObj.name === countryName || countryObj.alpha2Code === countryName || countryObj.alpha3Code === countryName);

        if (!result && listOfMatchingCountries.length === 1)
        {
            result = listOfMatchingCountries[0];
        }

        return result;
    }
    catch (err)
    {
        console.log("Error: could not find country", countryName, err);
        return undefined;
    }
};


/**
 * Based on documentation: https://developers.google.com/places/web-service/details
 * @param {Object} userAddress
 * @param {Number} userAddress.streetNumber
 * @param {Number} userAddress.unitNumber
 * @param {String} userAddress.street
 * @param {String} userAddress.city
 * @param {String} userAddress.province
 * @param {String} userAddress.postalCode
 * @param {String} userAddress.country
 */
export const convertUserAddressToFullAddress = (userAddress) =>
{
    let fullAddress = `${userAddress.streetNumber} ${userAddress.street}, ${userAddress.city}, ${userAddress.province} ${userAddress.postalCode}, ${userAddress.country}`;

    // replace double space with one space
    fullAddress = fullAddress.replace("  ", " ");
    fullAddress = fullAddress.replace(" ,", ",");

    if (userAddress.unitNumber)
    {
        fullAddress = userAddress.unitNumber + "-" + fullAddress;
    }

    return fullAddress;
};

/**
 * Gets the floor validation object, any floor index found in this object fails the validation.
 *
 * The validation checks if there are any matching long or short names.
 * @param {Array} floors - Optional array of floors, grabs from state if empty
 */
export const getFloorValidation = (uploadedFloors, key) =>
{
    if (uploadedFloors)
    {
        let floorLongNames = [];
        let floorShortNames = [];
        let validationErrors = {};

        for (let i = 0; i < uploadedFloors.length; i++)
        {
            const floor = uploadedFloors[i];

            if (!!floor && (floor.longName[SINGLE_LANG_INPUT_CODE] !== "" && floor.shortName[SINGLE_LANG_INPUT_CODE] !== ""))
            {
                const longName = floor.longName[SINGLE_LANG_INPUT_CODE].toLowerCase();
                const shortName = floor.shortName[SINGLE_LANG_INPUT_CODE].toLowerCase();
                // Check for long name duplicate
                if (floorLongNames.includes(longName))
                {
                    if (key)
                    {
                        validationErrors[floor[key]] = true;

                        const matchedFloorIndex = floorLongNames.indexOf(longName);
                        validationErrors[uploadedFloors[matchedFloorIndex][key]] = true;
                    }
                    else
                    {
                        validationErrors[i] = true;
                        validationErrors[floorLongNames.indexOf(longName)] = true;
                    }

                }
                else
                {
                    floorLongNames.push(longName);
                }

                // check for short name duplicate
                if (floorShortNames.includes(shortName))
                {
                    if (key)
                    {
                        validationErrors[floor[key]] = true;

                        const matchedFloorIndex = floorShortNames.indexOf(shortName);
                        validationErrors[uploadedFloors[matchedFloorIndex][key]] = true;
                    }
                    else
                    {
                        validationErrors[i] = true;
                        validationErrors[floorShortNames.indexOf(shortName)] = true;
                    }

                }
                else
                {
                    floorShortNames.push(shortName);
                }
            }
        }

        return validationErrors;
    }
    else
    {
        return {};
    }
};

/**
 * Takes two object lists of the same structure and appends them without adding duplicates.
 * @param {Array} list1
 * @param {Array} list2
 * @param {String} key
 */
export const appendObjectListsWithoutDuplicate = (list1, list2, key) =>
{
    let list1Keys = [];

    list1.forEach((item) => list1Keys.push(item[key]));

    list2.forEach((item) =>
    {
        if (!list1Keys.includes(item[key]))
        {
            list1.push(item);
        }
    });

    return list1;
};

/**
 * https://chrisboakes.com/how-a-javascript-debounce-function-works/
 * @param {Function} callback
 * @param {Number} wait
 */
export const debounce = (callback, wait) =>
{
    let timeout;
    return (...args) =>
    {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => callback.apply(context, args), wait);
    };
};

/**
 * Returns a list of opening hours in the following format.
 *
 * openingHours = {
 *  0: [
 *       {PERIOD_0}
 *       ...
 *       {PERIOD_N}
 *     ]
 *  ...
 * }
 *
 * NOTE: If opening hours does not have periods for day 'n' it is considered closed.
 *
 * i.e.  isClosed = (openingHours[n] === undefined)
 * @param {*} periods
 */
export const openingHoursPeriodsToGroupedList = (periods) =>
{
    let openingHours = [];

    if (Array.isArray(periods))
    {
        periods.forEach((period) =>
        {
            const day = period.close.day;

            period.open.time = getTimeFromMins(period.open.time);
            period.close.time = getTimeFromMins(period.close.time);

            // If opening hours for that day already has a period, add to the list.
            if (openingHours[day])
            {
                openingHours[day].push(period);
            }
            // Else make start the list.
            else
            {
                openingHours[day] = [period];
            }
        });
    }

    return openingHours;
};


/**
 * Returns a list of opening hours (periods) in google api format.
 * @param {} openingHours
 */
export const openingHoursGroupedListToPeriods = (openingHours = []) =>
{
    let periods = [];

    openingHours.forEach((dayHours) =>
    {
        // Each item in opening hours should be a list of periods, this is just for saftey.
        if (Array.isArray(dayHours))
        {
            dayHours.forEach((period) =>
            {
                period.open.time = getMinsFromTime(period.open.time);
                period.close.time = getMinsFromTime(period.close.time);
                periods.push(period);
            });
        }
    });

    return periods;
};

/**
 * Creates a default period for a given day.
 * @param {Number} day
 */
export const getDefaultPeriod = (day, openTime = 0, closeTime = 0) =>
{
    const period = {
        open: {
            day: day,
            time: getTimeFromMins(openTime)
        },
        close: {
            day: day,
            time: getTimeFromMins(closeTime)
        }
    };

    return period;
};

/**
 * Creates an open 24 hour period for a given day.
 * @param {Number} day
 */
export const getOpen24HoursPeriod = (day, isMins = false) =>
{
    const period = {
        open: {
            day: day,
            time: isMins ? OPEN_24H_MINS.OPEN : getTimeFromMins(OPEN_24H_MINS.OPEN)
        },
        close: {
            day: day,
            time: isMins ? OPEN_24H_MINS.CLOSE : getTimeFromMins(OPEN_24H_MINS.CLOSE)
        }
    };

    return period;
};

/**
 * Checks if the period is open 24 hours.
 * @param {Array} periods
 */
export const isPeriodOpen24Hours = (periods, isMins = false) =>
{
    if (Array.isArray(periods) && periods.length === 1)
    {

        let openTime = isMins ? periods[0].open.time : getMinsFromTime(periods[0].open.time);
        let closeTime = isMins ? periods[0].close.time : getMinsFromTime(periods[0].close.time);

        if (openTime === OPEN_24H_MINS.OPEN && closeTime === OPEN_24H_MINS.CLOSE)
        {
            return true;
        }
    }

    return false;
};

export const convertOpeningHoursToPartial = ({ openTime, closeTime, isOpen, isOpen24Hours, date }) =>
{
    // get day of week from date
    const dow = moment(date).day();
    let day = dow;

    if (isOpen24Hours)
    {
        // create default 24 hours period
        return [getOpen24HoursPeriod(day, true)];
    }
    else if (isOpen)
    {
        // use start time and close time to create periods
        let defaultPeriod = getDefaultPeriod(day);

        defaultPeriod.open.time = openTime;
        defaultPeriod.close.time = closeTime;

        return [defaultPeriod];

    }
    else
    {
        // create closed period
        return [];
    }
};

/**
 * Checks if any of the period have open times that are greater or equal to close times.
 * @param {Array} partialHours
 */
export const validatePartialHours = (partialHours) =>
{
    let validation = {};
    for (let i = 0; i < partialHours.length; i++)
    {
        let dayValidation = [];

        partialHours[i].forEach((period, j) =>
        {
            const openTime = moment(period.open.time, "hh:mm A");
            const closeTime = moment(period.close.time, "hh:mm A");

            if (openTime.isSameOrAfter(closeTime))
            {
                dayValidation[j] = true;
            }
        });

        if (dayValidation.length > 0)
        {
            validation[i] = (dayValidation);
        }

    }

    return validation;
};

/**
 * Sorts and Merges overlapping periods.
 * @param {Array} partialHours
 */
export const mergeOverlapingPartialHours = (partialHours) =>
{
    for (let i = 0; i < partialHours.length; i++)
    {
        let dayPeriods = partialHours[i] || [];

        // console.log(dayPeriods);
        dayPeriods.sort(function (period1, period2)
        {
            const open1 = moment(period1.open.time, "hh:mm A");
            const open2 = moment(period2.open.time, "hh:mm A");

            if (open1.isBefore(open2))
            {
                return -1;
            }
            if (open1.isAfter(open2))
            {
                return 1;
            }

            return 0;
        });

        const mergedPeriods = mergeSortedOverlapingPeriodsRecursive(JSON.parse(JSON.stringify(dayPeriods)));

        partialHours[i] = mergedPeriods;
    }

    return partialHours;
};

/**
 * A recursive helper to merge periods for a given day.
 * @param {Array} periods
 * @param {Number} i
 */
const mergeSortedOverlapingPeriodsRecursive = (periods, i = 0) =>
{
    if (!Array.isArray(periods) || periods.length === 0)
    {
        return periods;
    }
    if (i + 1 >= periods.length)
    {
        return periods;
    }

    const close1 = moment(periods[i].close.time, "hh:mm A");
    const open2 = moment(periods[i + 1].open.time, "hh:mm A");

    if (open2.isSameOrBefore(close1))
    {
        const close2 = moment(periods[i + 1].close.time, "hh:mm A");

        if (close1.isBefore(close2))
        {
            periods[i].close.time = periods[i + 1].close.time;
        }

        periods.splice(i + 1, 1);
    }
    else
    {
        i++;
    }

    return mergeSortedOverlapingPeriodsRecursive(periods, i);
};


export const sortByName = fieldSortComparator();

export function utcStringToFormattedDateTime(userData, utcString)
{
    const date = new Date(utcString);
    if (isNaN(date))
    {
        // date is invalid, return whatever we got
        return utcString;
    }

    const timeFormat = userData?.user?.userInfo?.timeFormat;
    const timezone = userData?.user?.userInfo?.timezone;

    let options = {};
    const defaultOptions = { year: "2-digit", month: "2-digit", day: "2-digit" };
    try
    {
        const timeFormatData = JSON.parse(timeFormat);
        if (options)
        {
            options = timeFormatData;
        }
        else
        {
            options = defaultOptions;
        }
    }
    catch (err)
    {
        // timeFormat is invalid, use default format instead
        options = defaultOptions;
    }

    try
    {
        // will throw if timezone is invalid
        new Date().toLocaleDateString(undefined, { timeZone: timezone });
        options.timeZone = timezone;
    }
    catch (error)
    {
        // timezone was invalid do nothing
    }

    return date.toLocaleDateString(undefined, options || undefined);        // null crashes toLocaleDateString, undefined doesn't
}


/**
 * Calculates the percentage of change from oldValue to newValue
 * for analytics
 * @param {*} newValue
 * @param {*} oldValue
 * @returns {Number}
 */
export const calculatePercentageOfChange = (newValue, oldValue) =>
{
    newValue = Number(newValue);
    oldValue = Number(oldValue);

    // case 1 -> new == old;
    if (newValue === oldValue)
    {
        return 0;
    }
    // case 2 -> no previous value
    else if (oldValue === 0)
    {
        return "N/A";
    }
    // case 3 -> no current value
    else if (newValue === 0)
    {
        return -100;
    }
    // case 4 -> calculate the % of change
    else
    {
        let change = newValue - oldValue;

        return ((change / oldValue) * 100).toFixed(1);
    }
};

/**
 * Calculates the percentage of value over sum
 * @param {number} value
 * @param {number} sum
 * @returns {string}
 */
export const calculatePercentage = (value, sum) =>
{
    if (value === 0)
    {
        return 0;
    }

    return ((value / sum) * 100).toFixed(2);
};

export const printTitleCase = (value = "", everyWord, separator = " ") =>
{
    let words = _.words(_.capitalize(value));

    if (everyWord)
    {
        words = words.map((word) => _.capitalize(word));
    }

    return words.join(separator);
};

/**
 * Parses the mobile app details from property settings
 * @param {object} publicSettings
 * @param {object} mapstedApps
 * @returns {object}
 */
export const getMobileAppDetails = (mapstedApps, publicSettings) =>
{
    if (_.isEmpty(mapstedApps)) return {};

    const {  mobileApp, playStoreLink, appStoreLink } = getValue(publicSettings, "mobileAppSync.web.downloadApp.popup", {});

    if (!mobileApp) return {};

    let mobileAppName, androidAppName, iosAppName, androidLink, iosLink;

    if (mobileApp === "CUSTOM")
    {
        androidAppName = "Android";
        iosAppName = "iOS";
        androidLink = playStoreLink;
        iosLink = appStoreLink;
    }
    else
    {
        const { name, links } = mapstedApps[mobileApp] || {};
        mobileAppName = name;
        androidAppName = `${name} - Android`;
        iosAppName = `${name} - iOS`;
        androidLink = links?.android;
        iosLink = links?.ios;
    }

    return { mobileApp, mobileAppName, androidAppName, iosAppName, androidLink, iosLink };
};


/**
 * Compares two entites. Use this instead of relying on mongoIds
 * @param {object} entityObj1
 * @param {object} entityObj2
 * @returns {boolean}
 */
export const areSameEntities = (entityObj1, entityObj2) => (
    entityObj1.entityId === entityObj2.entityId
    && entityObj1.propertyId == entityObj2.propertyId
    && entityObj1.buildingId == entityObj2.buildingId
    && entityObj1.floorId == entityObj2.floorId
);

/**
 * Generate Unique ID from and entity (since mongoId can't be used)
 * @param {object} entity
 * @returns {string}
 */
export const getIdFromEntity = (entity) =>
{
    const { entityId, propertyId, buildingId, floorId } = entity;

    return [entityId, propertyId, buildingId, floorId].join("-");
};

/**
 * Converts radians to degrees
 * @param {object} entity
 * @returns {string}
 */

const setInRange = (MIN, MAX, V) => 
{
    const range = MAX - MIN;
    const normalized = (V - MIN) % range;
    return (normalized < 0 ? normalized + range : normalized) + MIN;

};
export const degreesToRadians=(degrees) => 
{
    let radians = (degrees * Math.PI) / 180;
        
    radians %= 2 * Math.PI;
    if (radians < 0) 
    {
        radians += 2 * Math.PI;
    }
    
    return radians;
};
  
export const radiansToDegees = (radians) => (setInRange(0, 360, (radians * 180) / Math.PI)).toFixed(0);

export const deepCopy = (value) => (JSON.parse(JSON.stringify(value)));


/**
 * Crop text based on the given max length
 * @param {string} textString 
 * @param {number} maxLength 
 * @returns {string}
 */
export const cropText = (textString, maxLength=20) =>
{
    let outputText = textString;
    if (textString.length > maxLength)
    {
        outputText = textString.substring(0, maxLength) + "...";
    }
    return outputText;
};
