import React from "react";
import { Input as SInput, InputProps as SInputProps } from "semantic-ui-react";

interface InputProps extends SInputProps {
    darkText?: boolean;
    maxWidth: React.ReactText;
};

const Input : React.FC<InputProps> = ({
    className = "",
    maxWidth = undefined,
    darkText = false,
    style,
    ...rest
}) => (
    <SInput
        className={`inputForm ${darkText ? "darkText" : ""} ${className}`}
        style={{ maxWidth, ...style }}
        {...rest}
    />
);

export default Input;
