import React from "react";
import {
    Button,
    Modal,
    ModalActions,
    ModalContent,
    ModalHeader,
} from "semantic-ui-react";
import ImageCropper from "../ImageCropper/ImageCropper";
import "./SingleImageUploadModal.css";
import ImageUploadDetails from "./ImageUploadDetails/ImageUploadDetails";
import ImageUploadMainWrapper from "../ImageUploadMainWrapper/ImageUploadMainWrapper";
import { useImagesUploadContext } from "../../contexts/ImagesUploadContext";
import { useGmsContext } from "../../../GmsContext";

function SingleImageUploadModal() 
{
    const gmsContext = useGmsContext();
    const { uploadedFilesInfo, getFileImageSrc, cropperRef, handleUploadImage, handleResetUpload } =
        useImagesUploadContext();
    const fileInfo = uploadedFilesInfo[0];


    return (
        <Modal closeIcon={<Button onClick={gmsContext.handleCloseGms} className="close" />} className="single-image-upload-modal" open>
            <ModalHeader>Upload Image</ModalHeader>
            <ModalContent>
                <ImageUploadMainWrapper>
                    <ImageCropper
                        ref={cropperRef}
                        aspectRatio={fileInfo.aspectRatio}
                        imageSrc={getFileImageSrc(fileInfo.id)}
                    />
                    <ImageUploadDetails />
                </ImageUploadMainWrapper>
            </ModalContent>
            <ModalActions>
                <Button onClick={handleResetUpload} className="gms-outline-btn" color="orange" floated="right">
                    Cancel
                </Button>
                <Button disabled={!fileInfo.name} onClick={handleUploadImage} color="orange" floated="right">
                    Upload
                </Button>
            </ModalActions>
        </Modal>
    );
}


export default SingleImageUploadModal;
