import {
    Modal,
    Button,
    Input,
    Dimmer,
    Segment,
    Image
} from "semantic-ui-react";
import { ErrorLabel } from "../../../elements/ErrorLabel";
import React from "react";
import { useSetState } from "ahooks";
import { InputGroupWrap } from "../../../common/InputGroupWrap";
import serverApi from "../../../../_api/server.api";
import { Loader } from "./loader";
import { useTranslation } from "react-i18next";

const AuthScreen = ({ isLoading, onAuth, setLoading }) =>
{
    const [state, setState] = useSetState({
        instanceURL: "",
        accessTokenURL: "",
        clientID: "",
        clientSecret: "",
        error: "",
    });

    const handleAuthenticate = async () =>
    {
        setLoading(true);

        const credentials = {
            settings: {
                instanceURL: state.instanceURL,
                clientID: state.clientID,
                clientSecret: state.clientSecret,
                accessTokenURL: state.accessTokenURL
            },
            createdBy: serverApi.userData.user.userInfo.id
        };

        const resp = await serverApi.post("/api/internal/integrations/archibusSettings", credentials, true);

        setLoading(false);

        if (resp.success)
        {
            onAuth();
        }
        else
        {
            setState({ error: resp.error });
        }
    };

    const trans = useTranslation().t;

    return (
        <Dimmer.Dimmable className="dimmableLoader" as={Segment} dimmed={isLoading}>
            <Dimmer active={isLoading} inverted>
                <Loader active={isLoading} />
            </Dimmer>
            <div className="aligner archibusModal">
                <Modal.Header>{trans("PropertyOverview.Archibus_Integration")}</Modal.Header>

                <Image className="archibusLogo" as="span" src="/img/archibus.png" alt="" />
                <p className="p-modal">{trans("PropertyOverview.Archibus_Description")}</p>
                <p className="p-modal">{trans("PropertyOverview.Your_Credential_Will_Be_Stored")}</p>
                <InputGroupWrap label={trans("PropertyOverview.Archibus_Instance_Url")}>
                    <Input
                        placeholder={"https://<company>.imsconsulting.host/archibus/api/internal/"}
                        value={state.instanceURL || ""}
                        onChange={(e, { value }) => setState({ instanceURL: value })}
                    />
                </InputGroupWrap>
                <InputGroupWrap label={trans("PropertyOverview.Access_Token_Url")}>
                    <Input
                        placeholder={"https://dev-111111.okta.com/oauth2/default/v1/token"}
                        value={state.accessTokenURL || ""}
                        onChange={(e, { value }) => setState({ accessTokenURL: value })}
                    />
                </InputGroupWrap>
                <InputGroupWrap label={trans("PropertyOverview.Client_Id")}>
                    <Input
                        placeholder={"SyUPc8UP20UrgFb5Pt2i"}
                        value={state.clientID || ""}
                        onChange={(e, { value }) => setState({ clientID: value })}
                    />
                </InputGroupWrap>
                <InputGroupWrap label={trans("PropertyOverview.Client_Secret")}>
                    <Input
                        placeholder={"ywp3H7cIGi8q1GfTUQPHuFkfQNbx9dkp28JPadRD"}
                        value={state.clientSecret || ""}
                        onChange={(e, { value }) => setState({ clientSecret: value })}
                    />
                </InputGroupWrap>

                { state.error === "ERR_NOT_AUTH" &&  <ErrorLabel small content={"Invalid Credentials. Please try again."} /> }
                { state.error === "ERR_SETTINGS" && <ErrorLabel small content={"Error saving credentials. Please try again."} />}

                <Modal.Actions>
                    {/* <Button primary content="Temporary Button" floated="left" onClick={() => setState({ screen: "CONTACT_ADMIN" })} /> */}
                    <Button primary content={trans("PropertyOverview.Authenticate")} floated="right" onClick={() => handleAuthenticate()} />
                </Modal.Actions>
            </div>
        </Dimmer.Dimmable>);
};

export default AuthScreen;