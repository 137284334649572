const { EntityType } = require("mapsted.maps/utils/entityTypes");

/**
 * Filters the entity access array to only include entities that have nodes for a locked level.
 *
 * @param {Array} nodeArray - array of nodes
 * @param {Array} entityAccessArray - array of entity accesses
 * @return {Array} filtered entity access array
 */
exports.filterEntityAccessArrayWithoutNodesLocked = (nodeArray, entityAccessArray) =>
{
    // loop through nodes -> add all entityIds to a bool map
    // loop through entities -> add entity where id is not in bool map
    let entitiesWithNodesBoolMap = {};

    nodeArray.forEach((node) =>
    {
        let entityId = node.entityId;

        if (entityId)
        {
            entitiesWithNodesBoolMap[entityId] = true;
        }
    });

    const filteredEntityAccessArray = entityAccessArray.filter((entityAccess) => 
    {
        if (entityAccess.getIsRoutableEntity())
        {
            if (entityAccess.get("entityType") === EntityType.OBSTACLE)
            {
                let name = entityAccess.getName();

                if (!name)
                {
                    return false;
                }
            }

            let doesEntityHaveNode = entitiesWithNodesBoolMap[entityAccess.getId()];
            if (!doesEntityHaveNode)
            {
                return true;
            }
        }

        return false;
    });

    return filteredEntityAccessArray;
};

/**
 * Filters the entity access array to only include entities that have nodes for an unlocked level.
 *
 * @param {Array} nodeArray - array of nodes
 * @param {Array} entityAccessArray - array of entity accesses
 * @return {Array} filtered entity access array
 */
exports.filterEntityAccessArrayWithoutNodesUnlocked = (entityAccessArray) =>
{
    const filteredEntityAccessArray = entityAccessArray.filter((entityAccess) => 
    {
        if (entityAccess.getIsRoutableEntity())
        {
            if (entityAccess.get("entityType") === EntityType.OBSTACLE)
            {
                let name = entityAccess.getName();

                if (!name)
                {
                    return false;
                }
            }

            let doesEntityHaveNode = entityAccess?.data?.nodeIds?.length > 0;
            if (!doesEntityHaveNode)
            {
                return true;
            }
        }

        return false;
    });

    return filteredEntityAccessArray;
}