import React from "react";
import "./InfoSection.css";

function InfoSection({ children, className = "" }) 
{
    return (
        <div className={`info-section ${className}`}>
            {children}
        </div>
    );
}

function InfoSectionLabel({ children })
{
    return (
        <div className="info-section-label">
            {children}
        </div>
    );
}

function InfoSectionValue({ children })
{
    return (
        <div className="info-section-value">
            {children}
        </div>
    );
}

InfoSection.Label = InfoSectionLabel;
InfoSection.Value = InfoSectionValue;

export default InfoSection;