import React from "react";
import { Button, Image } from "semantic-ui-react";
import { ButtonBasic } from "../../common/ButtonIcon";

export const StoreBoxItemButton = () =>
{
    return (
        <div className="storeBoxItemCover">
            <Button className="groupStoreBox">
                <Image as="span" src="/img/icon-building-store.svg" />
                <p>
                    Store Name
                    <span>Sq1 Mall - L1</span>
                </p>
            </Button>
            <ButtonBasic icon="trash-grey" />
        </div>
    );
}

export const StoreBoxItem = () =>
{
    return (
        <div className="storeBoxItem">
            <Image as="span" src="/img/icon-building-store.svg" />
            <p>
                Store Name
                <span>Sq1 Mall - L1</span>
            </p>
            <ButtonBasic icon="cross-red-narrow" />
        </div>
    );
}