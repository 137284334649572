/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import { Popup, Header, Icon } from "semantic-ui-react";
import { getContactUsParamsUrl } from "../../_utils/utils";
import AppContext from "../../store/AppContext";
import { useTranslation } from "react-i18next";

export const InfoHeadingPopup = ({ learnMore, disabled, showLearnMore = true, header, children, contactUsMessageType }) =>
{
    const appContext = React.useContext(AppContext);

    const handleClick = () =>
    {
        const targetUrl = getContactUsParamsUrl(undefined, contactUsMessageType);
        appContext.userRedirect(targetUrl, true);
    }

    const trans = useTranslation().t;

    return (
        <Popup
            className="info-heading"
            on="click"
            position="right center"
            hideOnScroll={true}
            trigger={<Icon name="question circle" color="blue" size="small" />}>
            {disabled &&
                <p className="disabled-content">
                    <span>
                        <strong>{trans("InfoHeadingPopup.Note_")}</strong> {trans("InfoHeadingPopup.Temporarily_unavailable_until_the_proper")}</span>
                    {trans("InfoHeadingPopup.For_more_info__please_contact")} 
                    <a onClick={() => handleClick()} rel="noopener noreferrer" target="_blank" style={{cursor: "pointer"}}>{trans("InfoHeadingPopup.Support")}</a>.
                </p>
            }
            <Header>{header}</Header>
            <p>
                {children}
                {
                    (showLearnMore) && (
                        <a onClick={() => handleClick()} rel="noopener noreferrer" target="_blank" style={{cursor: "pointer"}}>
                            {learnMore ? learnMore : trans("InfoHeadingPopup.Learn_more_")}
                        </a>
                    )
                }
            </p>
        </Popup>
    )
};