import React, { useContext, useMemo } from "react";
import { Button } from "semantic-ui-react";
import BrandingContext from "../../../store/BrandingContext";
import { MAP_TOOLS, SINGLE_LANG_INPUT_CODE } from "../../../_constants/constants";
import { getSelectedEntity } from "mapsted.maps/mapFunctions/features";
import { StylesAndThemesContext } from "../../../store/StylesAndThemesContext";

export const MapButtons = ({
    onZoomIn,
    onZoomOut,
    children = undefined,
    floorButtons = true,
    zoomButtons = true,
}) =>
{
    const brandingContext = React.useContext(BrandingContext);

    const isOpen = React.useMemo(() =>
    {
        const { selectedTool, selectedEntities } = brandingContext.state;
        const { selectedEntityId } = getSelectedEntity(selectedEntities);
        let open = true;

        if (selectedTool === MAP_TOOLS.EDIT && selectedEntityId)
        {
            open = false;
        }

        return open;
    }, [brandingContext.state]);

    return (
        <div className={`buttons-wrap ${(!isOpen) ? "buttons-edit-active" : ""}`}>
            {(zoomButtons) && (
                <div className="buttons-group staysActive">
                    <MapButton content={<span className="zoomIn" />} onClick={onZoomIn} />
                    <MapButton content={<span className="zoomOut" />} onClick={onZoomOut} />
                </div>
            )}

            {(floorButtons && brandingContext.state.buildingId) && (
                <FloorButtons />
            )}
            {children}
        </div >
    );
};

const FloorButtons = () =>
{
    const brandingContext = useContext(BrandingContext);
    const { state: stylesAndThemesCtxState } = useContext(StylesAndThemesContext);

    const floors = useMemo(() =>
    {
        const { buildingId, propertyId, properties } = brandingContext.state;

        if (buildingId)
        {
            return properties[propertyId].buildings[buildingId].floors;
        }
        return [];
    }, [brandingContext.state]);

    return (
        <div className="buttons-group">
            {floors.map((floor) => (
                <MapButton
                    key={floor._id}
                    content={floor.shortName?.[SINGLE_LANG_INPUT_CODE]}
                    isActive={brandingContext.state.floorId === floor._id}
                    onClick={() => brandingContext.changeSelectedFloor(floor._id)}
                    disabled={stylesAndThemesCtxState.activeStyle && brandingContext.state.floorId !== floor._id}
                />
            ))}
        </div>
    );
};

export const MapButton = ({
    icon = undefined,
    content = undefined,
    isActive = false,
    onClick,
    disabled
}) => (
    <Button className={isActive ? "active" : ""} onClick={onClick} disabled={disabled}>
        {content}
        {(icon) && (<img src={`/img/icon-${icon}.svg`} alt="icon" />)}
    </Button>
);
