import React from "react";
import ListViewBasicFilteration from "./listViewBasicFilteration/ListViewBasicFilteration";
import ListViewAdvancedFilteration from "./listViewAdvancedFilteration/ListViewAdvancedFilteration";
import "./ListViewFilteration.css";
import { ModeButtonGroup } from "../../pages/branding/listing/ModeButtonGroup";
import { ACTIVE } from "../../pages/branding/listing/ModeButtonGroup";
import { useListViewContext } from "../../../store/ListViewContext";
import { LIST_MODES } from "../../../_constants/listView";

function ListViewFilteration() 
{
    const {
        showAdvancedFilterationSidebar,
        handleShowAdvanceFilterationSidebar,
        listMode
    } = useListViewContext();
    return (
        <div className="listview-n-filteration">
            <ListViewBasicFilteration />
            <ModeButtonGroup
                className="modeButtonGroupListing list-view-modeButtonGroupListing"
                active={ACTIVE.LIST}
                disabled={listMode === LIST_MODES.BULK_EDIT}
            />
            {
                showAdvancedFilterationSidebar && <ListViewAdvancedFilteration
                    open={showAdvancedFilterationSidebar}
                    handleClose={handleShowAdvanceFilterationSidebar}
                />
            }
           
        </div>
    );
}

export default ListViewFilteration;
