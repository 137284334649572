import React, { useContext, useState, useEffect, useCallback } from "react";
import { Button, Image, Message } from "semantic-ui-react";
import { ModalBox } from "../../common/ModalBox";
import BrandingContext from "../../../store/BrandingContext";
import { useTranslation } from "react-i18next";
import { ErrorLabel } from "../../elements/ErrorLabel";
import "./ExportGroups.css";
import CategoryTreeDisplay from "../../common/componentTreeDisplay/CategoryTreeDisplay";
import PropertyList from "../../common/propertyList/PropertyList";
import { CheckboxGroup } from "../../common/CheckboxGroup";
import { ButtonIcon } from "../../common/ButtonIcon";
import { DEFAULT_LANGUAGE_CODE } from "../../../_constants/constants";

export const ExportGroups = ({ openModal, onCloseModal, onSuccess }) => 
{
    const brandingCtx = useContext(BrandingContext);
    const trans = useTranslation().t;
    const [selectedProperties, setSeletedProperties] = useState([]);
    const [categoryTree, setCategoryTree] = useState({});
    const [error, setError] = useState(false);
   
    useEffect(() => 
    {
        if (openModal) 
        {
            handleOnModalOpen();
        }
    }, [openModal]);

    const handleOnModalOpen = () => 
    {
        setSeletedProperties([]);
        setError(false);
    };

    const handleExportButtonClick = () => 
    {
        brandingCtx.exportCategoryTree(selectedProperties, getSelectedGroups(), async (success) => 
        {
            if (success) 
            {
                onSuccess();
            }
            else 
            {
                setError(true);
            }
        });
    };

    /**
     * Returns object with all groups which have checked or indeterminate flag set as true
     * @returns {Object}
     */
    const getSelectedGroups = () => 
    {
        let selectedGroups = {};

        Object.keys(categoryTree).forEach((groupId) => 
        {

            if (categoryTree[groupId].checked) 
            {
                selectedGroups[groupId] = categoryTree[groupId];
            }

            if (categoryTree[groupId].indeterminate) 
            {

                selectedGroups[groupId] = { ...categoryTree[groupId] };
                delete selectedGroups[groupId].categories;

                selectedGroups[groupId].categories = getSelectedCategories(groupId);
            }
        });

        return selectedGroups;
    };

    /**
     * Returns object with all the categories which have checked or indeterminate flag set as true
     * belonging to the given group
     * @param {String} groupId
     * @returns {Object}
     */
    const getSelectedCategories = (groupId) => 
    {
        let categories = {};
        Object.keys(categoryTree[groupId].categories).forEach((categoryId) => 
        {

            if (categoryTree[groupId].categories[categoryId].checked) 
            {
                categories[categoryId] = { ...categoryTree[groupId].categories[categoryId] };
            }

            if (categoryTree[groupId].categories[categoryId].indeterminate) 
            {

                categories[categoryId] = { ...categoryTree[groupId].categories[categoryId] };
                delete categories[categoryId].subCategories;

                categories[categoryId].subCategories = getSelectedSubCategories(categoryId, groupId);
            }
        });
        return categories;
    };

    /**
     * Returns object with all the sub categories which have checked or indeterminate flag set as true
     * belonging to the given category and group
     * @param {String} categoryId
     * @param {String} groupId
     * @returns {Object}
     */
    const getSelectedSubCategories = (categoryId, groupId) => 
    {
        let subCategories = {};
        Object.keys(categoryTree[groupId].categories[categoryId].subCategories).forEach((subCategoryId) => 
        {
            if (categoryTree[groupId].categories[categoryId].subCategories[subCategoryId].checked) 
            {
                subCategories[subCategoryId] = { ...categoryTree[groupId].categories[categoryId].subCategories[subCategoryId] };
            }
        });
        return subCategories;
    };


    return (
        <ModalBox
            className="exportGroupModal"
            open={openModal}
            onClose={onCloseModal}
            header={<div className="copyGroupModal-header">
                <div className="title">{trans("ExportGroups.Export_Groups")}</div>
                <div className="subTitle">{trans("ExportGroups.Sub_Title")}</div>
            </div>}
            actions={<Button
                primary
                floated="right"
                content={trans("ExportGroups.Export")}
                onClick={handleExportButtonClick}
                disabled={!(selectedProperties.length > 0 && (Object.keys(getSelectedGroups()).length > 0))} />}
        >

            <Message warning className="modalWarning">
                <strong>{trans("ExportGroups.Warning")}: </strong> <br />
                {trans("ExportGroups.Modal_Warning")}
            </Message>

            <div className="copyModal-content">
                <div>
                    <div className="copyModal-categoryside">{trans("ExportGroups.Select_Categories")}</div>
                    <div className="copyModal-categorySection">
                        <div className="copyModal-categoryHeading">{trans("ExportGroups.CategoryHeading")}</div>
                        <CategoryTreeDisplay
                            CategoryItem={GroupCategoryItem}
                            showAllCategories={true}
                            showCheckBox={true}
                            searchable={true}
                            enableSelectAll={true}
                            onChange={({ categoryTree }) => setCategoryTree(categoryTree)}
                        />
                    </div>
                </div>
                <div>
                    <div className="copyModal-categoryside">{trans("ExportGroups.Select_Properties")}</div>
                    <div className="copyModal-categorySection">
                        <div className="copyModal-categoryHeading">{trans("ExportGroups.Properties")}</div>
                        <PropertyList 
                            selectedProperties={selectedProperties}
                            setSeletedProperties={setSeletedProperties}
                            searchable={true}
                            enableSelectAll={true}
                            // disabled={!Object.values(categoryTree).some(({ checked, indeterminate }) => checked || indeterminate)}
                        />
                    </div>
                </div>
            </div>

            {error && <ErrorLabel label={trans("ExportGroups.Error_Message")} />}
        </ModalBox>
    );
};

export const ExportSuccessModal = ({
    successMessageModalOpen,
    onSuccessMessageModalClose
}) => 
{
    const trans = useTranslation().t;

    return (
        <ModalBox
            size="tiny"
            header={trans("ExportGroups.Success_Heading")}
            open={successMessageModalOpen}
            className="exportGroupModal-success-message"
            onClose={onSuccessMessageModalClose}
            actions={<Button
                primary
                floated="right"
                className="exportGroupModal-success-action"
                content={trans("ExportGroups.Success_Action")}
                onClick={onSuccessMessageModalClose} />}>
            <span style={{ fontSize: "16px" }}>{trans("ExportGroups.Success_Body")}</span>
        </ModalBox>
    );
};


const GroupCategoryItem = ({ content, detail, lang=DEFAULT_LANGUAGE_CODE, children, checked, indeterminate, onChange, iconInfo }) => 
{
    const [showChildren, setShowChildren] = useState(true);
    const { url } = iconInfo;
    return (
        <div className="groupCategoryItem">
            <div className={"groupCategoryItemButton"}>
                <CheckboxGroup
                    checked={checked}
                    indeterminate={indeterminate}
                    onChange={onChange} />
                <Image as="span" src={url} />
                <p title={detail.name?.[lang]}>{content}</p>
                {children?.length > 0 && <ButtonIcon className="groupCategoryItemIcon" icon={`chevron-${showChildren ? "up" : "down"}`} onClick={() => setShowChildren((prev) => !prev)} />}

            </div>
            {
                showChildren && children && <div className="subGroupCategoryCover">{children}</div>
            }
        </div>
    );
};