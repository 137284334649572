import React from "react";
import { Dropdown, DropdownItem, DropdownMenu } from "semantic-ui-react";
import { IMAGES_ASPECT_RATIO_OPTIONS } from "../../../constant";
import { useTranslation } from "react-i18next";
import "./ImagesRatioSelector.css";
import { useImagesBaseContext } from "../../contexts/ImageBaseContext";
import { GmsActionMode, useGmsContext } from "../../../GmsContext";

function ImagesRatioSelector() 
{
    const gmsContext = useGmsContext();
    const { imagesSearchAndFilters, handleChangeImageFilters } =
    useImagesBaseContext();
    const trans = useTranslation().t;
    const selectedRatioInfo = IMAGES_ASPECT_RATIO_OPTIONS.find(
        (option) => option.value === imagesSearchAndFilters.filters.ratio
    );
    const dropdownText = `Ratio - ${trans(selectedRatioInfo?.i18nKey)}`;


    return (
        <Dropdown
            disabled={gmsContext.gmsActionMode === GmsActionMode.LINK_ICON_IMAGE || gmsContext.gmsActionMode === GmsActionMode.LINK_COVER_IMAGES || gmsContext.gmsActionMode === GmsActionMode.REPLACE_IMAGE}
            text={dropdownText}
            icon={"angle down"}
            className="images-ratio-selector gms-dropdown"
        >
            <DropdownMenu className="gms-dropdown-menu">
                {IMAGES_ASPECT_RATIO_OPTIONS.map((option) => (
                    <DropdownItem
                        className="gms-dropdown-item"
                        onClick={() => handleChangeImageFilters({ ratio: option.value })}
                        key={option.value}
                    >
                        {trans(option.i18nKey)}
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </Dropdown>
    );
}

export default ImagesRatioSelector;
