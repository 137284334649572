import React, { useEffect, useMemo } from "react";
import { AgGridReact } from "ag-grid-react"; // React Grid Logic
import { useListViewContext } from "../../../store/ListViewContext";
import { Icon, Pagination } from "semantic-ui-react";
import {
    COLUMN_IDS,
    CUSTOM_INTEGRATED_EDITOR_IDS,
} from "../../../_constants/listView";
import { useDynamicTablePageSize, usePagination } from "../../../_utils/hooks";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import "./ListViewTable.css";

const ROW_HEIGHT = 40;
const MIN_PAGE_SIZE = 10;
const MAX_PAGE_SIZE = 25;
const MIN_SCREEN_HEIGHT = 850;

function ListViewTable() 
{
    const {
        columnDefs,
        entities,
        handlePageChange,
        gridRef,
        handleGridReady,
        handleCellClicked,
        fallbackMessage,
        handleEditedRows,
        confirmationModalInfo,
        setIsColumnEditing
    } = useListViewContext();

    const { pageSize } = useDynamicTablePageSize({
        rowHeight: ROW_HEIGHT,
        minPageSize: MIN_PAGE_SIZE,
        maxPageSize: MAX_PAGE_SIZE,
        minScreenHeight: MIN_SCREEN_HEIGHT,
    });

    useEffect(() => 
    {
        const target = document.querySelector(".listview-n-table");
        document.addEventListener("click", (event) => 
        {
            const withinBoundaries = event.composedPath().includes(target);
            if (!withinBoundaries) 
            {
                const [column] = gridRef?.current?.api?.getEditingCells() || [];
                if (!CUSTOM_INTEGRATED_EDITOR_IDS.includes(column?.column.colDef?.id) && !confirmationModalInfo) 
                {
                    gridRef?.current?.api?.stopEditing();
                }
            }
        });
    }, []);

    return (
        <div className="listview-n-table-wrapper">
            <div
                className="ag-theme-quartz listview-n-table"
                style={{ width: "100%" }}
            >
                <AgGridReact
                    suppressPaginationPanel={true}
                    paginationPageSize={pageSize}
                    pagination={true}
                    domLayout="autoHeight"
                    rowData={entities}
                    columnDefs={columnDefs}
                    onGridReady={handleGridReady}
                    singleClickEdit
                    ref={gridRef}
                    defaultColDef={{
                        flex: 1,
                    }}
                    onCellClicked={handleCellClicked}
                    overlayNoRowsTemplate={fallbackMessage}
                    onCellEditingStarted={(value) => 
                    {
                        if (CUSTOM_INTEGRATED_EDITOR_IDS.includes(value.colDef.id)) 
                        {
                            document
                                .querySelectorAll(".ag-cell.ag-cell-not-inline-editing")
                                .forEach((elem) => 
                                {
                                    elem.classList.add("block");
                                });
                        }
                    }}
                    onCellEditingStopped={(value) => 
                    {
                        setIsColumnEditing(false);
                        handleEditedRows(value.rowIndex);
                        if (CUSTOM_INTEGRATED_EDITOR_IDS.includes(value.colDef.id)) 
                        {
                            document
                                .querySelectorAll(".ag-cell.ag-cell-not-inline-editing")
                                .forEach((elem) => 
                                {
                                    elem.classList.remove("block");
                                });
                        }
                    }}
                />
            </div>
            {
                !fallbackMessage && <PaginationBar
                    onPageChange={handlePageChange}
                    nElements={entities?.length || 0}
                    pageSize={pageSize}
                />
            }
        </div>
    );
}

export default ListViewTable;

const PaginationBar = ({ onPageChange, nElements, pageSize }) => 
{
    const totalPages = Math.ceil(nElements / pageSize);
    const trans = useTranslation().t;

    const { currentPage, startIndex, endIndex, setPage } = usePagination(
        pageSize,
        nElements || 0
    );

    useEffect(() => 
    {
        onPageChange(0);
    }, [nElements]);

    const handlePageChange = (e, { activePage }) => 
    {
        setPage(activePage - 1);
        onPageChange(activePage - 1);
    };

    return (
        <div className="paginationBar">
            <Pagination
                className="paginationDashboard"
                activePage={currentPage + 1}
                boundaryRange={1}
                firstItem={null}
                lastItem={null}
                siblingRange={1}
                totalPages={totalPages}
                prevItem={{ content: <Icon name="angle left" />, icon: true }}
                nextItem={{ content: <Icon name="angle right" />, icon: true }}
                onPageChange={handlePageChange}
            />
            <div className="displayText">
                {nElements > 0 ? (
                    <span>
                        {trans("ListView.Showing")}{" "}
                        <strong>
                            {startIndex + 1} - {Math.min(endIndex, nElements)}
                        </strong>{" "}
                        {trans("ListView.of")}{" "} {nElements}
                    </span>
                ) : (
                    <span>
                        {trans("ListView.Showing")}{" "}
                        <strong>0</strong> {trans("ListView.of")} 0
                    </span>
                )}
            </div>
        </div>
    );
};
