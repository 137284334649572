import { useSetState } from "ahooks";
import { isEmpty, toNumber, set as setDeepValue, get as getDeepValue, cloneDeep } from "lodash";
import { deepValue } from "mapsted.utils/objects";
import React, { useEffect, useContext, useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Header, Input, Tab } from "semantic-ui-react";
import BrandingContext from "../../../store/BrandingContext";
import serverApi from "../../../_api/server.api";
import { DEFAULT_LANGUAGE_CODE, SINGLE_LANG_INPUT_CODE, YOUTUBE_BASE_URL, YOUTUBE_IDS } from "../../../_constants/constants";
import { filerUrl } from "../../../_utils/utils";
import { ButtonIcon } from "../../common/ButtonIcon";
import { CheckboxGroup } from "../../common/CheckboxGroup";
import CopyButton from "../../common/CopyButton";
import { DividerLine } from "../../common/DividerLine";
import { DropdownForm } from "../../common/DropdownForm";
import { SETTINGS_MUTATIONS, SETTINGS_QUERIES } from "../settings.queries";
import { SettingsHeading } from "../SettingsHeading";
import { SettingsContext } from "../SettingsProvider";
import { AddEditKioskModal } from "./AddEditKioskModal";
import { ContentGroup } from "./ContentGroup";
import { DeleteKioskConfirmActionModal } from "./DeleteKioskConfirmActionModal";
import { UTIL_QUERIES } from "../../../_utils/queries";
import { useTranslation } from "react-i18next";

// in seconds
const DURATION = {
    DEFAULT: 10,
    MIN: 0,
    MAX: 200
};

export const MapstedKioskModule = () =>
{
    // context stuff
    const { state: { propertyId, buildingId }, changeSelectedProperty, changeSelectedFloor } = useContext(BrandingContext);
    const { loadingPool } = useContext(SettingsContext);
    const trans = useTranslation().t;

    // local state
    const [state, setState] = useSetState({
        addKioskModalOpen: false,
        selectedKiosk: undefined,
        deleteKioskModalOpen: false,
        themes: [],
    });

    // queries & mutations
    const kioskQueryKey = ["GET_KIOSKS", propertyId, buildingId];
    const queryClient = useQueryClient();
    const { data: kiosksArr, isLoading: isKiosksLoading } = useQuery({
        ...SETTINGS_QUERIES.GET_KIOSKS(propertyId, buildingId, undefined),
        queryKey: kioskQueryKey,
    });
    const { mutateAsync: deleteKiosk, isLoading: isDeleteKioskLoading } = useMutation({
        ...SETTINGS_MUTATIONS.DELETE_KIOSK(),
        queryKey: kioskQueryKey,
        onSuccess: () =>
        {
            queryClient.invalidateQueries(kioskQueryKey);
            closeModals();
        }
    });

    const kiosks = useMemo(() => kiosksArr?.filter((kiosk) => kiosk.entity  && buildingId ? kiosk.buildingId : !kiosk.buildingId ), [kiosksArr, buildingId]);

    useEffect(() =>
    {
        if (isKiosksLoading || isDeleteKioskLoading)
        {
            const loadingId = loadingPool.add();
            return () => loadingPool.remove(loadingId);
        }
    }, [isKiosksLoading, isDeleteKioskLoading]);

    useEffect(() =>
    {
        if (propertyId)
        {
            getPropertyThemes(propertyId);
        }
    }, [propertyId]);

    const getPropertyThemes = async (propertyId) =>
    {
        const loadingId = loadingPool.add();
        try
        {
            const res = await serverApi.getThemes(propertyId, true);

            if (res.success)
            {
                setState({
                    themes: res.themes
                });
            }
        }
        catch (error)
        {
            console.log(error);
        }
        finally
        {
            loadingPool.remove(loadingId);
        }
    };

    const handleEditKiosk = (kiosk) =>
    {
        // change to kiosk's property building & floor
        if (isEmpty(kiosk.floor))
        {
            // set propertyId to given value and buildingId & floorId to undefined
            changeSelectedProperty(kiosk.propertyId);
        }
        else
        {
            // set buildingId & floorId
            changeSelectedFloor(kiosk.floor._id, kiosk.buildingId, true);
        }
        setState({ selectedKiosk: cloneDeep(kiosk), addKioskModalOpen: true });
    };

    const closeModals = () =>
    {
        setState({ addKioskModalOpen: false, deleteKioskModalOpen: false, selectedKiosk: undefined });
    };

    return (
        <Tab.Pane>
            <div className="settingsPageTabPane settingsPageTabPaneKioskModule">
                <SettingsHeading
                    icon="heading-mapsted-kiosk-module"
                    heading={trans("Settings.Mapsted_Kiosk_Module")}
                />
                <ContentGroup>
                    <p className="paragraph paraContent">{trans("Settings.Manage_share_integrate_and_edit_the_kiosks_on_your_property")}</p>
                    <div className="contentGroupRow">
                        <div className="contentGroupBody">
                            <KioskList
                                isLoading={isKiosksLoading}
                                kiosks={kiosks}
                                onEditKiosk={handleEditKiosk}
                                onDeleteKiosk={(selectedKiosk) => setState({ deleteKioskModalOpen: true, selectedKiosk })}
                            />
                            <AddEditKioskModal
                                kioskQueryKey={kioskQueryKey}
                                open={state.addKioskModalOpen}
                                selectedKiosk={state.selectedKiosk}
                                onClose={closeModals}
                                existingKiosks={kiosks}
                            />
                            <ButtonIcon
                                className="buttonAddKiosk"
                                icon="plus-filled"
                                content={trans("Settings.Add_new_kiosk")}
                                onClick={() => setState({ addKioskModalOpen: true })}
                            />

                            <DeleteKioskConfirmActionModal
                                open={state.deleteKioskModalOpen}
                                onClose={closeModals}
                                kiosk={state.selectedKiosk}
                                onDelete={() => deleteKiosk(state.selectedKiosk._id)}
                            />

                        </div>
                        <VideoBoxThumb />
                    </div>

                </ContentGroup>

                <DividerLine gap="30px 0 40px" />

                <AutoCancellation />

                <Primarytheme themes={state.themes} />

            </div>
        </Tab.Pane>
    );
};


const DEFAULT_PARAMS = {
    rel: "0",
    modestBranding: "0"
};
const VideoBoxThumb = ({ width = 300, height = 200, params = {} }) =>
{
    const trans = useTranslation().t;
    const src = useMemo(() =>
    {
        const mergedParams = { ...DEFAULT_PARAMS, ...params };
        const url = new URL(YOUTUBE_IDS.ADD_KIOSK, YOUTUBE_BASE_URL);
        const paramsStr = new URLSearchParams(mergedParams).toString();

        return `${url}?${paramsStr}`;
    }, [params]);

    return (
        <div className="videoBoxThumb">
            <div className="videoThumb">
                <iframe
                    width={width}
                    height={height}
                    src={src}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            </div>
            <p>
                {trans("Settings.How_to_integrate_Kiosk_Module_Instructional_Video")}
            </p>
        </div>
    );
};

const KioskList = ({ kiosks = [], isLoading, onEditKiosk, onDeleteKiosk }) =>
{
    const language = DEFAULT_LANGUAGE_CODE;
    const { data: config } = useQuery(UTIL_QUERIES.CONFIG());
    const trans = useTranslation().t;

    if (isLoading) return <div className="emptyBox">{trans("Settings.Loading_Kiosk_Devices")}</div>;
    if (isEmpty(kiosks)) return <div className="emptyBox">{trans("Settings.No_Kiosk_Devices")}</div>;

    const renderKiosk = (kiosk) =>
    {
        const { entity, floor, kioskId } = kiosk;
        // if no floor that means its a property kiosk
        const floorName = getDeepValue(floor, `shortName.${SINGLE_LANG_INPUT_CODE}`, "-");
        const name = getDeepValue(entity, `entityLabel.longName.${language}`, "");

        const filerId = getDeepValue(entity, `entityLabel.iconImage.${language}`, "");
        const iconUrl = filerUrl(filerId, 3600);
        const kioskUrl = `${config.KIOSK_URL}/${kioskId}`;

        return (
            <div key={kiosk._id} className="kioskModuleTableRow">
                <div className="kioskModuleTableColumn">
                    <img src={iconUrl} alt="" style={{ maxHeight: "30px" }} />
                    <p>{name}</p>
                </div>
                <div className="kioskModuleTableColumn">
                    <p className="kioskUrl">{kioskUrl}</p>
                    <CopyButton text={kioskUrl} />
                </div>
                <div className="kioskModuleTableColumn">
                    {floorName}
                    <ButtonIcon icon="edit" onClick={() => onEditKiosk(kiosk)} />
                    <ButtonIcon icon="delete" onClick={() => onDeleteKiosk(kiosk)} />
                </div>
            </div>
        );
    };

    return (
        <div className="kioskModuleTable">
            <div className="kioskModuleTableRow">
                {[trans("Settings.Kiosk_Table_Name"), trans("Settings.Kiosk_Table_URL"), trans("Settings.Kiosk_Table_Floor")].map((text) => (
                    <div key={text} className="kioskModuleTableColumn">
                        {text}
                    </div>
                ))}
            </div>

            {kiosks.map(renderKiosk)}
        </div>
    );
};

const AutoCancellation = () =>
{
    const trans = useTranslation().t;
    const { mergedSettings: settings, mutate } = useContext(SettingsContext);
    const autoCancelEnabled = getDeepValue(settings, "kiosk.autoCancellation.enabled", false);
    const autoCancelDuration = getDeepValue(settings, "kiosk.autoCancellation.duration", DURATION.DEFAULT);

    const tooogleAutoCancel = (e, { checked }) =>
    {
        const updates = setDeepValue({ ...settings }, "kiosk.autoCancellation.enabled", checked);
        mutate(updates);
    };

    const handleChangeDuration = (e, { value }) =>
    {
        let duration = toNumber(value);
        if (!Number.isInteger(duration))
        {
            duration = DURATION.MIN;
        }
        if (duration > DURATION.MAX) return;

        const updates = setDeepValue({ ...settings }, "kiosk.autoCancellation.duration", duration);
        mutate(updates);
    };

    return (
        <div className="settingsPageTabCover">
            <SettingsHeading heading={trans("Settings.Auto_cancellation_of_Route_Message")} />
            <p className="paragraph paraContent">
                {
                    trans("Settings.Enter_a_time_frame_for_when_the_routing_popup_disappears_after_a_specified_time_of_inactivity")
                }            
            </p>
            <div className="contentGroupRow">
                <div className="contentGroupBody">
                    <CheckboxGroup label={trans("Settings.Enable_auto_cancellation")}
                        checked={autoCancelEnabled}
                        onChange={tooogleAutoCancel}
                    />
                    <div className="sessionRefreshTime">
                        {trans("Settings.Cancel_popup_after")}
                        <Input
                            disabled={!autoCancelEnabled}
                            value={autoCancelDuration}
                            onChange={handleChangeDuration}
                        /> {trans("Settings.Sec_of_inactivity")}
                    </div>
                </div>

                <div className="previewEmbedBox sessionRefreshEmbed">
                    <div className="sessionRefreshCounter">10</div>
                    <div className="staticPlaceholder dark" style={{ maxWidth: "85px" }}></div>
                    <div className="staticPlaceholder" style={{ maxWidth: "140px" }}></div>
                    <DividerLine isEmpty gap="5px 0 5px" />
                    <div className="staticPlaceholder" style={{ maxWidth: "174px" }}></div>
                    <div className="staticPlaceholder" style={{ maxWidth: "174px" }}></div>
                    <div className="staticPlaceholder" style={{ maxWidth: "85px" }}></div>
                </div>

            </div>
        </div>
    );
};

const Primarytheme = ({ themes }) =>
{
    const trans = useTranslation().t;
    const { mergedSettings: settings, mutate } = useContext(SettingsContext);

    const primaryTheme = deepValue(settings, "kiosk.primaryTheme", undefined);

    const handlePrimaryThemeChange = (newPrimaryTheme) =>
    {
        const updates = setDeepValue({ ...settings }, "kiosk.primaryTheme", newPrimaryTheme);
        mutate(updates);
    };

    const themesOptions = useMemo(() => themes
        .filter((theme) => theme.active)
        .map((theme) => ({
            key: theme._id,
            value: theme._id,
            text: theme.name[DEFAULT_LANGUAGE_CODE],
        })), [themes]);

    return (
        <div className="settingsPageTabCover">
            <div className="settingsPageHeading">
                <Header as="h2" content={trans("Settings.Map_Theme")} />
                <p className="paragraph">{trans("Settings.Select_a_theme_that_will_be_reflected_across_Mapsted_Kiosks")}</p>
            </div>
            <DropdownForm
                className="dropdownSelectTheme"
                placeholder={trans("Settings.Select_Theme")}
                options={themesOptions}
                value={primaryTheme}
                onChange={(e, { value }) => handlePrimaryThemeChange(value)}
            />
        </div>
    );
};
