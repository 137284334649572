import React, { useState } from "react";
import { Button, Menu, Popup } from "semantic-ui-react";
import { useOverlaysTemplateContext } from "../../../store/OverlaysTemplateContext";
import { useTranslation } from "react-i18next";
import { ButtonIcon } from "../../common/ButtonIcon";
import { ModalBox } from "../../common/ModalBox";

export default function TemplatesAction({ templateDetails }) 
{
    const { deleteOverlaysTemplate, handleEditOverLayTemplate, handleViewOverLayTemplate, zonesTemplates, mapOverlays } = useOverlaysTemplateContext();
    const [actionDropdownOpen, setActionDropdownOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const { t: trans } = useTranslation();
    
    const hasLinkedMapOverlays = mapOverlays.some((overlay) => overlay.templateId === templateDetails.templateId);
    const hasLinkedZones = zonesTemplates?.includes(templateDetails.templateId);

    const handleDeleteClick = async () => 
    {
        try 
        {
            await deleteOverlaysTemplate(templateDetails._id);
            setDeleteModalOpen(false);
        }
        catch (error) 
        {
            console.error("Error in deletion", error);
        }
    };

    return (
        <>
            <Popup
                open={actionDropdownOpen}
                onOpen={() => setActionDropdownOpen(true)}
                onClose={() => setActionDropdownOpen(false)} 
                trigger={
                    <ButtonIcon
                        icon="action-dots"
                        onClick={() => setActionDropdownOpen(true)}
                    />
                }
                on="click"
                className="actionModal"
                position="top right"
                
            >
                <Menu vertical>
                    <Menu.Item
                        name="view"
                        className="actionItemMenu"
                        onClick={() => 
                        {
                            handleViewOverLayTemplate(templateDetails._id);
                            setActionDropdownOpen(false);
                        }}
                    >
                        <ButtonIcon className={"menuItem"} icon="eye-outline"/>
                        {trans("Templates.View_Template")}
                    </Menu.Item>
                    <Menu.Item
                        name="edit"
                        className="actionItemMenu"
                        onClick={() => 
                        {
                            handleEditOverLayTemplate(templateDetails._id);
                            setActionDropdownOpen(false);
                        }}
                    >
                        <ButtonIcon className={"editItemMenu"} icon="pencil-outline"/>
                        {trans("Templates.Edit_Template")}
                    </Menu.Item>
                    {
                        (hasLinkedMapOverlays || hasLinkedZones) ? <Popup
                            content={trans("OverlaysTemplateSideBar.Cannot_Delete_Message")}
                            basic
                            trigger={<Menu.Item
                                disabled={true}
                                name="delete"
                                className="actionItemMenu"
                            >
                                <ButtonIcon className="menuItem" icon="trash-red" disabled={true}/>
                                {trans("Templates.Delete_Template")}
                            </Menu.Item>} /> : <Menu.Item
                            disabled={hasLinkedMapOverlays || hasLinkedZones}
                            name="delete"
                            onClick={() => 
                            {
                                setDeleteModalOpen(true);
                                setActionDropdownOpen(false);
                            }}
                            className="actionItemMenu"
                        >
                            {(hasLinkedMapOverlays || hasLinkedZones) ? (
                                <Popup
                                    content={trans("OverlaysTemplateSideBar.Cannot_Delete_Message")}
                                    basic
                                    trigger={<span><ButtonIcon icon="delete" disabled /></span>} />
                            ) : (
                                <ButtonIcon className="menuItem" icon="trash-red" disabled={hasLinkedMapOverlays || hasLinkedZones}/>
                            )}
                        
                            {trans("Templates.Delete_Template")}
                        </Menu.Item>
                    }
                    
                </Menu>
            </Popup>

            <ModalBox
                className="confirmActionModal template-group-delete-modal"
                open={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                header={
                    <div style={{ margin: "auto" }}>
                        <div className="delete-icon-container">
                            <img src="/img/icon-delete.svg" alt="Delete Templates" />
                        </div>
                        <h2 className="modal-header-text">
                            {trans("OverlaysTemplateSideBar.Delete_Confirm_Modal_Header")}
                        </h2>
                    </div>
                }
                actions={
                    <Button
                        color="orange"
                        content={trans("OverlaysTemplateSideBar.Delete")}
                        onClick={handleDeleteClick}
                    />
                }
            >
                <p className="deleteModal-text">
                    {trans("OverlaysTemplateSideBar.Delete_Confirmation_Message")}
                </p>
            </ModalBox>
        </>
    );
}
