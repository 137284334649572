import React from "react";
import { Footer } from "../../layout/Footer";
import { withRouter } from "react-router-dom";
import { usePagination } from "../../../_utils/hooks";
import { TemplatesDataTable } from "./TemplatesDataTable";
import { useOverlaysTemplateContext } from "../../../store/OverlaysTemplateContext";

const propertiesPerPage = 7;

const TemplatesBody = () => 
{
    const { filteredOverlayTemplates } = useOverlaysTemplateContext();
    const pagination = usePagination(propertiesPerPage, filteredOverlayTemplates.length || 0);
    const pageOfDisplayProperties = [ ...filteredOverlayTemplates].slice(pagination.startIndex, pagination.endIndex);

    return (
        <>
            <div className="dashboardListingWrapper templatesBody">
                <TemplatesDataTable
                    displayProperties={pageOfDisplayProperties}
                    pagination={pagination}
                />
            </div>
            <Footer />
        </>
    );
};

export default withRouter(TemplatesBody);