import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useMutation, useQuery } from "react-query";
import { Button, Image, Modal, Popup } from "semantic-ui-react";
import { toast } from "sonner";
import serverApi from "../../../_api/server.api";
import { Loader } from "../../../components/elements/loader";
import "./index.css";
import { prepareImagesFromPdfUrl } from "./util";


const PdfToImageViewer = ({
    multiple = false,
    onAddImage,
    onImageAddError,
    accepts = {
        "application/*": [".pdf"]
    },
    preSelectFirstItemAfterPdfUpload = true,
    autoOpenFileDrawerWhenNoFiles = true,

}) =>
{

    const [files, setFiles] = useState([]);
    const [activeImageItem, setActiveImageItem] = useState(undefined);
    const uploadButtonRef = useRef();

    const { mutate, isLoading: isUploadingToGallery } = useMutation({
        mutationFn: async (imageDataToUpload) =>
        {
            try
            {
                const blob = await (await fetch(imageDataToUpload.data64Url)).blob();
                const filerId = await serverApi.filerUpload(blob);
                const { status, result } = await serverApi.createImageReference({
                    name: imageDataToUpload.name,
                    fileName: imageDataToUpload.name,
                    size: blob.size,
                    filerId: filerId,
                    mimeType: imageDataToUpload.type,
                    aspectRatio: imageDataToUpload.aspectRatio,
                });

                if (status !== "success")
                {
                    throw new Error("Error while Uploading ");
                }

                return result;
            }
            catch (err)
            {
                console.error(err);

            }
        },
        onSuccess: (data) =>
        {
            onAddImage(data);
        },
        onError(err)
        {
            onImageAddError?.(err);
        }
    });



    const query = useQuery(files, () => prepareImagesFromPdfUrl(files[0]), {
        enabled: files.length > 0
    });


    useEffect(() =>
    {

        if (uploadButtonRef.current && autoOpenFileDrawerWhenNoFiles && files.length === 0)
        {
            uploadButtonRef.current.click();
        }
    }, [files, uploadButtonRef.current]);

    useEffect(() =>
    {
        // when ever we have new imageList prepared form pdf preSelect
        if (query.isSuccess && preSelectFirstItemAfterPdfUpload)
        {
            const imageData = query.data;
            const preselectFirstImage = { ...imageData[0], previewIdx: 0 };
            setActiveImageItem(preselectFirstImage);
        }
        else
        {
            setActiveImageItem(undefined);
        }
    }, [query.dataUpdatedAt, preSelectFirstItemAfterPdfUpload]);


    const onDrop = useCallback(async (acceptedFiles) =>
    {
        const file = acceptedFiles[0];
        const { type } = file;
        if (type === "application/pdf")
        {
            const blobUrl = URL.createObjectURL(file);
            Object.assign(file, { blobUrl });
            setFiles([file]);
        }
        else
        {
            toast.error("Please upload Pdf file");
        }
    }, []);

    const onRemoveFile = (idx) =>
    { }; // TODO

    /**
     * A description of the entire function.
     *
     * @param {type} e - description of parameter
     * @param {type} images - description of parameter
     * @param {type} previewIdx - description of parameter
     * @param {type} imageBlobObj - description of parameter
     * @return {type} description of return value
     */
    const onPreviewItemClick = useCallback((e, data) =>
    {
        setActiveImageItem(data);
    }, []);

    const onAddButtonCLick = useCallback((e) =>
    {
        if (activeImageItem && onAddImage && typeof onAddImage === "function")
        {
            mutate(activeImageItem);
        }

    }, [onAddImage, mutate, activeImageItem, query.dataUpdatedAt]);


    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        multiple,
        accepts
    });

    const isLoading = useMemo(() => [isUploadingToGallery, query.isLoading].some((v) => v), [isUploadingToGallery, query.isLoading]);

    const renderImagePreview = useCallback(() =>
    {
        if (files.length === 0)
        {
            return (<p className="no-file-added"> Click on upload button to upload PDF</p>);
        }
        if (query.data?.length)
        {
            const imageData = query.data;
            const imageGridItems = (<div className="pdfPreviewGroup"> {imageData.map((d, idx) =>
            {
                const { data64Url } = d;
                const url = data64Url;
                return (
                    <div key={data64Url} className={idx === activeImageItem?.previewIdx ? "pdfPreviewColumn image-active" : "pdfPreviewColumn"} onClick={(e) => onPreviewItemClick(e, { ...d, previewIdx: idx })}>
                        <Popup
                            trigger={<Image src={url} />}
                            content={<img src={url} height={300} width={"auto"} alt={"pdf" + idx + 1} />}
                            on="hover"
                        />
                    </div>
                );
            })}
            </div>);

            let itemToDisplay = imageGridItems;

            return itemToDisplay;

        }
        if (query.isError)
        {
            return <div className="error-message">Error: {query.error.message}</div>;
        }

        return null;
    }, [query.dataUpdatedAt, files, activeImageItem, isUploadingToGallery]);

    return (
        <div className="pdf-modal-body">
            <Loader active={isLoading} />
            {
                renderImagePreview()
            }
            <div className="uploadPdfPlaceholder" {...getRootProps()}>
                <input {...getInputProps()} accept=".pdf" />
                <div ref={uploadButtonRef}>
                    <Button primary size="small">Upload Floor Plan</Button>
                </div>
            </div>

            <Modal.Actions>
                <Button primary floated="right" content="Add" onClick={onAddButtonCLick} disabled={!activeImageItem} />
            </Modal.Actions>
        </div >);
};


export default PdfToImageViewer;
