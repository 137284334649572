import React from "react";
import { IconsInfoSection } from "../IconsManagementSidebar";
import { useIconsManagementContext } from "../../IconsManagementContext";
import { Radio } from "semantic-ui-react";
import IconsManagementSection from "../../IconsManagementSection/IconsManagementSection";
import "./IconsManagementColorsFilters.css";
import { COLORS_TO_HEX_MAP } from "../../constant";

function IconsManagementColorsFilters() 
{
    const { config, handleChangeIconsFilters, iconsSearchAndFilters } =
    useIconsManagementContext();
    return (
        <div className="icons-management-colors-filters">
            <IconsManagementSection>
                <IconsManagementSection.Header>Colors:</IconsManagementSection.Header>
                <IconsManagementSection.Content className="icons-management-colors-filters-content">
                    {config.CATEGORY_ICON_COLORS.map((color) => (
                        <div
                            className="icons-management-colors-filters-color-bx"
                            key={color}
                        >
                            <Radio
                                id={color}
                                onChange={() => handleChangeIconsFilters({ color })}
                                checked={iconsSearchAndFilters.filters.color === color}
                                className="gms-radio"
                            ></Radio>
                            <div onClick={() => handleChangeIconsFilters({ color })} className="icons-management-colors-filters-label-bx">
                                <div style={{ backgroundColor: COLORS_TO_HEX_MAP[color] }} className="icons-management-colors-filters-label-color">
                                </div>
                                <div className="icons-management-colors-filters-label-bx-label">
                                    {color}
                                </div>
                            </div>
                           
                         
                        </div>
                    ))}
                </IconsManagementSection.Content>
            </IconsManagementSection>
        </div>
    );
}

export default IconsManagementColorsFilters;
