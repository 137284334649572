import React, { useContext } from "react";
import { Button, Header } from "semantic-ui-react";
import { DynamicMapLayersContext } from "../../../../store/DynamicMapLayersContext";
import { DEFAULT_LANGUAGE_CODE, DYNAMIC_MAP_LAYERS_MODES } from "../../../../_constants/constants";
import { useTranslation } from "react-i18next";

const ConfirmationSidebar = () =>
{
    const dynamicMapLayerCtx = useContext(DynamicMapLayersContext);

    const trans = useTranslation().t;

    const { confirmationDoneClickHandler, state: ctxState } = dynamicMapLayerCtx;
    const { activeDynamicMapLayer, mode, lastMode, activeMultiCreation } = ctxState;

    if (mode !== DYNAMIC_MAP_LAYERS_MODES.CONFIRMATION_SIDEBAR)
    {
        return null;
    }

    let message = "";

    if (lastMode === DYNAMIC_MAP_LAYERS_MODES.CREATE_DYNAMIC_MAP_LAYER)
    {
        
        message = trans("MaintenanceCreateDynamicMapSidebar.Dynamic_Map_Layer_Created", { activeDynamicMapLayer: activeDynamicMapLayer.dataStreamName[DEFAULT_LANGUAGE_CODE] });
    }
    else if (lastMode === DYNAMIC_MAP_LAYERS_MODES.EDIT_DYNAMIC_MAP_LAYER)
    {
        message = trans("MaintenanceCreateDynamicMapSidebar.Dynamic_Map_Layer_Edited", { activeDynamicMapLayer: activeDynamicMapLayer.dataStreamName[DEFAULT_LANGUAGE_CODE] });
    }
    else if (lastMode === DYNAMIC_MAP_LAYERS_MODES.MULTI_CREATION)
    {
        message = trans("MaintenanceCreateDynamicMapSidebar.Dynamic_Map_Layer_Multi", { activeMultiCreation: activeMultiCreation.configurationName });
    }

    return (
        <div className="sidebarPortal mapOverlaysSidebar">
            <div className="alignerSidebar">
                <Header as="h2" className="successHeading">
                    <img src="/img/icon-check-circle.svg" />
                    {message}
                </Header>
                <Button primary className="mapOverlay confirmationDone" onClick={confirmationDoneClickHandler}>{trans("MapOverlaysBulkDrawingComponent.Done")}</Button>
            </div>
        </div>
    );
};

export default ConfirmationSidebar;