import React from "react";
import { Placeholder, Image } from "semantic-ui-react";

export const LoadableImage = ({
    src,
    defaultSrc,
    className,
    defaultClass = "",
    loading: load,
    ...props
}) =>
{
    const [loading, setLoading] = React.useState(true);
    const [image, setImage] = React.useState(undefined);
    const [error, setError] = React.useState(false);

    React.useEffect(() =>
    {
        setError(false);

        if (src)
        {
            setLoading(true);
            //getBase64FromImageUrl(src, setImage);
            setImage(`${src}`);
            // setImage(`${src}?t=${Date.now()}`);
        }
        else
        {
            setImage(undefined);
            setLoading(false);
        }

    }, [src]);

    const handleImageLoaded = React.useCallback(() => setLoading(false), []);
    const handleImageLoadedError = React.useCallback(() =>
    {
        setLoading(false);
        setError(true);
    }, []);


    return (
        <div 
            style={{
                width: "100%",
                height: "100%"
            }}
        >
            {(loading || load) && (
                <Placeholder className={`${className}-placeholder`}>
                    <Placeholder.Image />
                </Placeholder>
            )}
            <Image src={(!error) ? (image || defaultSrc) : defaultSrc}
                {...props}
                style={{ display: (loading || load) ? "none" : undefined }}
                className={`${className} ${error ? defaultClass : ""}`}
                onLoad={handleImageLoaded}
                onError={handleImageLoadedError}
            />
        </div>
    );
};
