import React from "react";
import { CheckboxGroup } from "../../common/CheckboxGroup";
import styles from "./AspectRatioSelector.module.css";

function AspectRatioSelector({ selected, disabled, aspectRatios,handleSelection }) 
{
    return (
        <div className={styles.container}>
            <div className={styles.header}>Select Aspect Ratio</div>
            <div className={styles.inner_container}>
                {aspectRatios.map(({ key, value,text }) => (
                    <div key={key} className={styles.selection_box}>
                        <CheckboxGroup
                            radio
                            className={styles.radio_btn}
                            checked={selected === value}
                            disabled={disabled}
                            onChange={() => handleSelection(value)}
                        />
                        <div className={styles.text}>{text}</div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default AspectRatioSelector;
