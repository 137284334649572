"use strict";

exports.removeTrailingDigitsFromLatLngCoordinates = (coordinates) =>
{
    if (typeof (coordinates) === "number")
    {
        return Number.parseFloat(coordinates.toFixed(9));
    }
    else if (Array.isArray(coordinates))
    {
        const result = [];

        coordinates.forEach(c =>
        {
            result.push(exports.removeTrailingDigitsFromLatLngCoordinates(c));
        });

        return result;
    }
}

exports.convertMercatorToLatLng = (x, y) =>
{
    var dx = x / 6378137.0;
    var t4 = dx * 57.295779513082323;
    var t5 = Math.floor((t4 + 180.0) / 360.0);
    var lng = t4 - (t5 * 360.0);

    var t7 = 1.5707963267948966 - (2.0 * Math.atan(Math.exp((-1.0 * y) / 6378137.0)));
    var lat = t7 * 57.295779513082323;

    return { lat, lng }
}

exports.convertLatLngToMercator = ({ lat, lng }) =>
{
    var dLon = 0.017453292519943295 * lng;
    var x = 6378137.0 * dLon;

    var dLat = 0.017453292519943295 * lat;
    var y = 3189068.5 * Math.log((1.0 + Math.sin(dLat)) / (1.0 - Math.sin(dLat)));

    return { x: x, y: y };
}

