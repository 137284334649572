import React, { useRef, useState, useEffect } from "react";
import { Input, Image, Button } from "semantic-ui-react";
import { debounce } from "lodash";
import { ButtonBasic } from "../../common/ButtonIcon";
import { TextGroup } from "../../common/TextGroup";
import { degreesToRadians, radiansToDegees } from "../../../_utils/utils";
import { DEFAULT_LANGUAGE_CODE } from "mapsted.maps/utils/map.constants";
import { ModalBox } from "../../common/ModalBox";
import { MapButtons } from "../../branding/map/MapButtons";
import { useBrandingMap, useMapZoomMethods, usePropertyBrandingMap } from "../../branding/map/mapHooks";
import { SettingsHeading } from "../../settings/SettingsHeading";
import { useTranslation } from "react-i18next";

const Orientation = ({ mapRotation, mapRotationMobile, building, handleSave, isBuilding }) =>
{
    const trans = useTranslation().t;
    const [orientation, setOrientation] = useState({ isOrientModalOpen: false, mode: ORIENTATION_SELECT_MODES.DESKTOP });

    return (
        <div className="settingsPageTabCover">           

            <SettingsHeading heading={isBuilding ? trans("Settings.Building_Orientation") : trans("Settings.Property_Orientation") }>
                {trans("Settings.Orientation_desc")}
            </SettingsHeading>

            <div className="zoomLevelItem">
                <Image as="span" src="/img/icon-device-web2.svg" alt="" />
                <p className="rotationContent" >{`${trans("Settings.Desktop_Map_Rotation")}: ` + (mapRotation ? `${radiansToDegees(mapRotation)} ${trans("Settings.Degrees")}` : "Default")}</p>                           
                <ButtonBasic content={trans("MapOverlaysUploadCsvModal.Change")} onClick={() => setOrientation({ isOrientModalOpen: true, mode: ORIENTATION_SELECT_MODES.DESKTOP })} />
            </div>

            <div className="zoomLevelItem">
                <Image as="span" src="/img/icon-device-mobile2.svg" alt="" />
                <p className="rotationContent">
                    {`${trans("Settings.Mobile_Map_Rotation")}: ${mapRotationMobile ? `${radiansToDegees(mapRotationMobile)} ${trans("Settings.Degrees")}` : "Default"}`}
                </p>
                <ButtonBasic content={trans("MapOverlaysUploadCsvModal.Change")} onClick={() => setOrientation({ isOrientModalOpen: true, mode: ORIENTATION_SELECT_MODES.MOBILE })} />
            </div>               
            
            {
                orientation.isOrientModalOpen ? <OrientationModal
                    open={orientation.isOrientModalOpen}
                    onClose={() => setOrientation({ isOrientModalOpen: false })}
                    onChange={handleSave}
                    rotation={mapRotation}
                    mobileRotation={mapRotationMobile}
                    mode={orientation.mode}
                    building = {building}
                    isBuilding = {isBuilding}
                /> :null
            } 
          
        </div>
    );
};


export default Orientation;





export const OrientationModal = ({ open, rotation: currentRotation = 0, mobileRotation: currentMobileRotation = 0, onClose, mode=ORIENTATION_SELECT_MODES.DESKTOP,building, onChange, isBuilding }) =>
{
       
    const [rotations, setRotations] = useState(
        {
            [ORIENTATION_SELECT_MODES.DESKTOP]: currentRotation,
            [ORIENTATION_SELECT_MODES.MOBILE]: currentMobileRotation
        } 
    );
    const trans = useTranslation().t;
    
    const desktopRef = useRef();
    const mobileRef = useRef();
    const mapRefs = {
        [ORIENTATION_SELECT_MODES.DESKTOP]: desktopRef,
        [ORIENTATION_SELECT_MODES.MOBILE]: mobileRef
    };
    // to keep track of all misc. things added to map
    const mapDataRef = useRef({ rotationInteraction: undefined });

    // map related hooks

    const { mapRef: brandingMapRef, recenter } = isBuilding? useBrandingMap(mapRefs[mode]): usePropertyBrandingMap(mapRefs[mode]);
    
    const { zoomIn, zoomOut } = useMapZoomMethods(brandingMapRef);
    const [rotationValue, setRotationValue] = useState();

    const handleRotationSubmit = () => 
    {
        let rotation = degreesToRadians(rotationValue); 

        setRotations((prev) => ({ ...prev,[mode]: rotation }));                
    };  


    useEffect(() =>
    {
        if (!open) return;
        // need to have this useEffect else map doesn't renders
        brandingMapRef.current.setTarget(mapRefs[mode].current);
       
        setRotations(
            {
                [ORIENTATION_SELECT_MODES.DESKTOP]: currentRotation,
                [ORIENTATION_SELECT_MODES.MOBILE]: currentMobileRotation
            });   
        
        recenter();
        addRotationInteraction();
        return removeRotationInteraction;  

    }, [open]);    


    useEffect(() =>
    {
        let rotation =radiansToDegees(rotations[mode]);      

        setRotationValue(rotation);    
        setMapRotation(rotation);  
      
    }, [mode, currentRotation,currentMobileRotation,building?._id]);


    useEffect(() =>
    {
        setMapRotation(rotations[mode]);   
        
    }, [rotations[mode]]);

    const setMapRotation = (rotation) =>
    {
        brandingMapRef.current.getView().setRotation(rotation);
    };

    const addRotationInteraction = () =>
    {
        const interaction = debounce((e) =>
        {
            const rotation = e.target.getRotation() % (2 * Math.PI);

            setRotationValue(radiansToDegees(rotation ));                
            setRotations((prev) => ({ ...prev,[mode]: rotation }));

        }, 300);
        brandingMapRef.current.getView().on("change:rotation", interaction);
        mapDataRef.current.rotationInteraction = interaction;
    };

    const removeRotationInteraction = () =>
    {
        brandingMapRef.current.getView().un("change:rotation", mapDataRef.current.rotationInteraction);
    };

    const handleSave = () =>
    {
        const rotationKey =
          mode === ORIENTATION_SELECT_MODES.DESKTOP? "mapRotation": "mapRotationMobile";
      
        const updateSettings = {

            [rotationKey]: { [DEFAULT_LANGUAGE_CODE]: rotations[mode] },

        };
      
        // Apply the updateSettings and close the modal
        onChange(updateSettings);
        onClose();
    };

    return (
        <ModalBox
            open={open}
            onClose={onClose}
            className="orientationModal"
            header={isBuilding ? trans("Settings.Building_Orientation") : trans("Settings.Property_Orientation") }
            actions={
                <Button
                    className="wide"
                    color="orange"
                    floated="right"
                    content={trans("Settings.Save")}
                    onClick={handleSave}
                />
            }
        >
            <p className="paraModal paraDesignatedModal">
                {trans("Settings.Orienation_Description")}
            </p>

            <div className="overlayTgRow">
                <div className="overlayTgColumn">
                    <TextGroup flexible title={trans("Settings.Enter_Orienatiation_Value")} className="borderFillOpacityDisplay">
                        <div className="overlayTgRow">
                            <div className=" opacityInput">
                                <Input
                                    type="text"
                                    pattern="[0-9]*"
                                    className="zoomPercentageBox"
                                    value={rotationValue}
                                    onChange={(event, { value }) => setRotationValue(value)}
                                    onKeyPress={(event) =>
                                    {
                                        if (event.key === "Enter" && rotationValue)
                                        {
                                            handleRotationSubmit();
                                        } 
                                    }}
                                    onBlur={() =>
                                    {
                                        if (rotationValue)
                                        {
                                            handleRotationSubmit();
                                        }
                                    }}
                                />
                            </div>
                            <div className="overlayTgColumn opacityInput percentSymbol">
                                <p>{trans("Settings.Degrees")}</p>
                            </div>
                        </div>
                    </TextGroup>
                </div>
            
            </div>           
           

            <div className="orientationModalbody"> 
                
                <div className="modalMapBody">
                    <span className="instructionTooltip">{trans("Settings.Map_Rotate_Instructions")}</span>
                 
                    {(mode === ORIENTATION_SELECT_MODES.DESKTOP) && (
                        <div className="map-container" ref={mapRefs[mode]}>
                            <MapButtons onZoomIn={zoomIn} onZoomOut={zoomOut}  floorButtons={ isBuilding ? true : false } />
                        </div>
                    )}
                    {(mode === ORIENTATION_SELECT_MODES.MOBILE) && (
                        <PhoneOverlay mapRef={mapRefs[mode]} />
                    )}                 
                 
                </div>
            </div>

        </ModalBox>
    );
};

export const ORIENTATION_SELECT_MODES = {
    MOBILE: "mobile",
    DESKTOP: "desktop"
};

const PhoneOverlay = ({ mapRef }) => (
    <div className="phoneOverlayViewBox">
        <div className="screenViewPhoneBox" ref={mapRef}>
            <div className="screenViewActions">
                <img src="/img/mobile-view-search.svg" alt="" />
                <img src="/img/mobile-view-left.svg" alt="" />
                <img src="/img/mobile-view-right.svg" alt="" />
            </div>
            <span className="actionView"></span>
        </div>
    </div>
);

