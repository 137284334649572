import React from "react";
import { Button } from "semantic-ui-react";
import classNames from "classnames";
import { StyledIcon } from "../branding/categoriesPortal/StyledIcon";
import { CategoryContext } from "../../store/CategoryProvider";

export const ButtonIcon = ({ className,icon, content, category, children, add, ...rest }) =>
{
    const { getIconInfo } = React.useContext(CategoryContext);

    const { url, backgroundColour, iconColour } = getIconInfo(category);

    return (
        <Button
            className={classNames("buttonIcon", { "button-more": add }, className)}
            {...rest}
        >
            {icon && <img src={`/img/icon-${icon}.svg`} alt="" style={{ margin: !content && "0" }} />}
            {content}
            {children}
            {category && (
                <StyledIcon
                    className={"categoryImg"}
                    imageSrc={url}
                    iconColour={iconColour}
                    backgroundColour={backgroundColour}
                    style={{ paddingTop: 8, paddingBottom: 8 }}
                />
            )}
        </Button>
    );
};

// ButtonIcon is not working. delete below later

export const ButtonBasic = ({
    className = "",
    icon = undefined,
    content = undefined,
    children = undefined,
    add = false,
    ...rest
}) => (
    <Button
        className={classNames("buttonIcon", { "button-more": add }, className)}
        {...rest}
    >
        {icon && typeof icon === "string" ? <img src={`/img/icon-${icon}.svg`} alt="" /> : icon}
        {content}
        {children}
    </Button>
);
