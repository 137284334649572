const { GeoJsonAccess } = require("./geoJsonAccess");
const { LineStringAccess } = require("./lineStringAccess");
const { PointAccess } = require("./pointAccess");
const { ShapeTypes } = require("../utils/entityTypes");
const { PointRelativeToPolygon, PointRelativeToLine } = require("./enums");
const { isClose, getDistanceToThePoint, getPointRelativeToLine, sortPointArrayByDistanceToPoint } = require("./mathExtensions");
const { gpsCoordinateArrayToMercatorLocations, mercatorCoordinateArrayToGpsLocations } = require("../utils/map.utils");

// NOTE: Always import PolygonAccess before PolygonAccess and PointAccess

const PolygonAccess = class extends GeoJsonAccess
{
    constructor(coordinates, isMercator = true)
    {
        super();

        this.geometry = {
            type: ShapeTypes.POLYGON,
            coordinates
        };

        this.isMercator = isMercator;
    };

    /**
     * Retrieves the edges of a polygon.
     *
     * @return {Array<LineStringAccess>} An array of LineString objects representing the edges of the polygon.
     */
    getEdges()
    {
        let edges = [];
        const coordinates = this.getCoordinates();
        for (let i = 0; i < coordinates.length - 1; i++)
        {
            edges.push(new LineStringAccess([coordinates[i], coordinates[(i + 1)]]));
        }

        return edges;
    };

    /**
     * Retrieves the coordinates of the geometry.
     *
     * @return {Array} The coordinates of the geometry.
     */
    getCoordinates()
    {
        return this.geometry.coordinates[0];
    };

    /**
     * Retrieves an array of points from the geometry coordinates.
     *
     * @return {Array<PointAccess>} An array of Point objects representing the points of the geometry.
     */
    getPoints()
    {
        let coords = this.getCoordinates();
        return coords.map((coordinate) => new PointAccess(coordinate));
    };

    /**
     * Retrieves the bounding box of a polygon.
     *
     * The bounding box is the smallest rectangle that completely contains the polygon.
     * It is defined by the minimum and maximum x and y coordinates of the polygon's points.
     *
     * @return {Array} An object containing the minimum and maximum x and y coordinates of the bounding box.
     */
    getBoundingBox()
    {
        const points = this.getPoints();

        let xMax = points[0].getX();
        let yMax = points[0].getY();
        let xMin = points[0].getX();
        let yMin = points[0].getY();

        for (let i = 1; i < points.length; i++)
        {
            const point = points[i];
            xMax = Math.max(xMax, point.getX());
            yMax = Math.max(yMax, point.getY());
            xMin = Math.min(xMin, point.getX());
            yMin = Math.min(yMin, point.getY());
        }

        return [xMin, yMin, xMax, yMax]
    };

    /**
     * Calculates the signed area of a polygon.
     *
     * The signed area is calculated using the Shoelace formula, which is a method for calculating the area of a simple polygon whose vertices are described by ordered pairs in the plane.
     *
     * @return {number} The signed area of the polygon.
     */
    getAreaSigned()
    {
        if (this.getPoints().length < 4)
        {
            return 0;
        }

        const { local, offset } = this.getLocalPolygon();

        let areaSigned = 0;

        // first point and last point is the same so ignore first point
        for (let i = 1; i < local.getPoints().length - 1; i++)
        {
            const point = local.getPoints()[i];
            const nextPoint = local.getPoints()[(i + 1) % local.getPoints().length];

            areaSigned += (point.getX() * nextPoint.getY()) - (nextPoint.getX() * point.getY());
        }

        areaSigned *= 0.5;

        return areaSigned;
    };


    /**
     * 
     * Calculates the midpoint of a polygon.
     *
     * @return {PointAccess} - The midpoint of the polygon as a point object.
     */
    getMidpoint() 
    {
        // since the polygon is closed, the first and last, we cut the first item out of the list
        let coords = this.getCoordinates()
        coords = coords.slice(1);
        const xAvg = coords.reduce((accumulator, currentPoint) => accumulator + currentPoint[0], 0) / (coords.length);
        const yAvg = coords.reduce((accumulator, currentPoint) => accumulator + currentPoint[1], 0) / (coords.length);

        return new PointAccess([xAvg, yAvg]);
    };

    /**
    * @typedef {Object} LocalPolygon
    * @property {PolygonAccess} local - The local polygon coordinates.
    * @property {PointAccess} offset - The offset point.
    */
    /**
     * Calculates the local polygon coordinates and returns them along with the offset.
     *
     * @return {LocalPolygon} An object containing the local polygon and the offset point.
     * 
     */
    getLocalPolygon() 
    {
        let localPolygonCoords = [];

        const offset = this.getMidpoint();

        const offsetCoords = offset.getCoordinates();
        const polygonCoords = this.getCoordinates();

        for (let i = 0; i < polygonCoords.length; i++)
        {
            const coordI = polygonCoords[i];
            localPolygonCoords.push([coordI[0] - offsetCoords[0], coordI[1] - offsetCoords[1]]);
        }

        return {
            local: new PolygonAccess([localPolygonCoords]),
            offset
        };
    };


    /**
     * Checks whether the polygon is clockwise.
     *
     * @return {boolean} True if the polygon is clockwise, false otherwise.
     */
    isClockWise()
    {
        return this.getAreaSigned() <= 0;
    };


    /**
     * Projects a given point onto the boundary of a polygon.
     * Will return the nearest point on the boundary of the polygon.
     *
     * @param {PointAccess} point - The point to be projected.
     * @return {PointAccess} The projected point.
     */
    projectPointOnPolygonBoundary(point) 
    {
        // project the point onto the polygon boundary
        const edges = this.getEdges();
        let closestProjectedPoint;
        let currentDistanceBetweenProjectedPointAndPoint = Infinity;

        for (let i = 0; i < edges.length; i++)
        {
            const edge = edges[i];

            // project point onto line segment or end point
            const projectedPoint = edge.getProjectedPointOntoLineSegmentOrEndPoint(point);
            const distanceToPoint = getDistanceToThePoint(point, projectedPoint);

            if (!closestProjectedPoint)
            {
                closestProjectedPoint = projectedPoint;
                currentDistanceBetweenProjectedPointAndPoint = distanceToPoint;
            }
            else if (distanceToPoint < currentDistanceBetweenProjectedPointAndPoint)
            {
                closestProjectedPoint = projectedPoint;
                currentDistanceBetweenProjectedPointAndPoint = distanceToPoint;
            }
        }

        return closestProjectedPoint;
    };


    /**
     * Finds the intersection points between a line and a polygon.
     *
     * @param {PolygonAccess} line - The line to intersect with the polygon.
     * @return {Map<Number, PointAccess>} An mapping of polygonEdgeIdx and its intersecting points with the line.
     */
    getLineIntersectionWithPolygon(line) 
    {
        /**
         * @type {Map<Number, PointAccess>}
         */
        let intersectingPoints = {};

        let polygonPoints = this.getPoints();
        let polygonRelativeToLine = 0;

        // check if the line will even intersect with the polygon at any point
        for (let i = 0; i < polygonPoints.length; i++)
        {
            const point = polygonPoints[i];
            const pointRel2Line = getPointRelativeToLine(point, line);

            polygonRelativeToLine += Number(pointRel2Line);
        }

        // if this is equal to the number of points in the polygon, this means all the points of the polygon are on one side of the line
        // resulting in no intersections
        if (Math.abs(polygonRelativeToLine) == polygonPoints.length)
        {
            return {};
        }

        const polygonEdges = this.getEdges();

        for (let i = 0; i < polygonEdges.length; i++)
        {
            const edgeI = polygonEdges[i];

            const intersection = line.getLineIntersectionWithLineSegment(edgeI);

            if (intersection)
            {
                let uniqueIntersection = true;

                // check if the intersection is close to an existing intersection
                // we want a true intersection: an intersection not on the endpoints of a polygon edge

                /**
                 * @type {Array<PointAccess>}
                 */
                const currentIntersections = Object.values(intersectingPoints);
                for (let j = 0; j < currentIntersections.length; j++)
                {
                    if (currentIntersections[j].isClose(intersection))
                    {
                        uniqueIntersection = false;
                        break;
                    }
                }

                if (uniqueIntersection)
                {
                    intersectingPoints[i] = intersection;
                }

            }
        }

        return intersectingPoints;
    };

    /**
     * Calculates the raw centroid of a polygon.
     *
     * @return {PointAccess} The raw centroid point object.
     */
    getRawCentroid()  
    {
        const { local, offset } = this.getLocalPolygon();

        let accumulatedArea = 0;
        let centerX = 0;
        let centerY = 0;

        const localPoints = local.getPoints();

        // skip first point since its always a closed polygon 
        for (let i = 0; i < localPoints.length - 1; i++)
        {
            const p0 = localPoints[i];
            const p1 = localPoints[i + 1];

            let temp = p1.getX() * p0.getY() - p0.getX() * p1.getY();
            accumulatedArea += temp;
            centerX += (p0.getX() + p1.getX()) * temp;
            centerY += (p0.getY() + p1.getY()) * temp;
        }

        if (Math.abs(accumulatedArea < 0.0000001))
        {
            return this.getMidpoint();
        }

        accumulatedArea *= 3;

        return new PointAccess([
            centerX / accumulatedArea + offset.getX(),
            centerY / accumulatedArea + offset.getY()
        ]);
    };


    /**
     * Checks if a point is inside or on the boundary of a polygon.
     *
     * @param {PointAccess} point - The point to check.
     * @return {Boolean} True if the point is inside or on the boundary of the polygon, false otherwise.
     */
    isPointInPolygon(point)
    {
        const pointRelativeToPolygon = this.getPointRelativeToPolygon(point);

        return pointRelativeToPolygon == PointRelativeToPolygon.Inside || pointRelativeToPolygon == PointRelativeToPolygon.OnBoundary;
    };

    /**
     * Calculates the position of a point relative to a polygon.
     *
     * @param {PointAccess} point - The point to calculate the position for.
     * @return {PointRelativeToPolygon} The position of the point relative to the polygon.
     */
    getPointRelativeToPolygon(point) 
    {
        const edges = this.getEdges();
        const BoundingBox = this.getBoundingBox();

        const minX = BoundingBox[0];
        const minY = BoundingBox[1];
        const maxX = BoundingBox[2];
        const maxY = BoundingBox[3];

        if (isClose(Math.abs(point.getX() - maxX), 0))
        {
            maxX = point.getX();
        }

        if (isClose(Math.abs(point.getX() - minX), 0))
        {
            minX = point.getX();
        }

        if (isClose(Math.abs(point.getY() - maxY), 0))
        {
            maxY = point.getY();
        }

        if (isClose(Math.abs(point.getY() - minY), 0))
        {
            minY = point.getY();
        }

        if (point.getX() < minX || point.getX() > maxX || point.getY() < minY || point.getY() > maxY)
        {
            return PointRelativeToPolygon.Outside;
        }

        let isInside = false;

        for (let i = 0; i < edges.length; i++)
        {
            const edgeI = edges[i];

            if (edgeI.isPointOnLineSegment(point))
            {
                return PointRelativeToPolygon.OnBoundary;
            }

            // Jordan curve theorem
            const p0 = edgeI.getStartPoint();
            const p1 = edgeI.getEndPoint();

            if (p1.getY() > point.getY() !== p0.getY() > point.getY() &&
                point.getX() < ((p0.getX() - p1.getX()) * (point.getY() - p1.getY()) / (p0.getY() - p1.getY()) + p1.getX()))
            {
                isInside = !isInside;
            }
        }

        return isInside ? PointRelativeToPolygon.Inside : PointRelativeToPolygon.Outside;
    };


    /**
     * Calculates the centroid of a polygon.
     * 
     *
     * @return {PointAccess} The coordinates of the centroid.
     */
    getCentroidProjectedIntoPolygon() 
    {
        // find the mean centroid of the polygon 
        let centroid = this.getRawCentroid();

        if (!this.isPointInPolygon(centroid))
        {
            // project the centroid onto the polygon boundary
            const projectedPointOnPolygon = this.projectPointOnPolygonBoundary(centroid);

            // create a line from the centroid to the projected point that will be used to intersect the polygon
            const projectedLine = new LineStringAccess([centroid.getCoordinates(), projectedPointOnPolygon.getCoordinates()]);

            // intersect the polygon with an "infinite" projected line.
            const projectedLineIntersectionsWithPolygon = this.getLineIntersectionWithPolygon(projectedLine);

            // get perpendicular line to the projected line
            const perpendicularPointToCentroid = projectedLine.getPointToTheLeftOfLine(centroid);
            const perpendicularLineToProjected = new LineStringAccess([centroid.getCoordinates(), perpendicularPointToCentroid.getCoordinates()]);

            let pointsToTheRight = [];

            // loop through intersecting points
            Object.values(projectedLineIntersectionsWithPolygon).forEach((intersectingPoint) =>
            {
                const pointRelativeToLine = getPointRelativeToLine(intersectingPoint, perpendicularLineToProjected);
                if (pointRelativeToLine == PointRelativeToLine.Right)
                {
                    pointsToTheRight.push(intersectingPoint);
                }
            });

            if (pointsToTheRight.length > 1)
            {
                const sortedPointsToTheRightByDistanceToCentroid = sortPointArrayByDistanceToPoint(pointsToTheRight, centroid);
                const firstPoint = sortedPointsToTheRightByDistanceToCentroid[0];
                const secondPoint = sortedPointsToTheRightByDistanceToCentroid[1];
                centroid = new PointAccess(
                    [
                        (firstPoint.getX() + secondPoint.getX()) / 2,
                        (firstPoint.getY() + secondPoint.getY()) / 2
                    ]);
            }
        }

        return centroid;
    };

    /**
     * Converts a polygon from the current coordinate system to the WGS84 coordinate system.
     *
     * @return {PolygonAccess} A new PolygonAccess object representing the point in the WGS84 coordinate system.
     */
    toWGS84()
    {
        let coordinates = this.getCoordinates();

        if (!this.isMercator)
        {
            return new PolygonAccess(coordinates, false);

        }

        const gpsCoords = mercatorCoordinateArrayToGpsLocations(coordinates);
        return new PolygonAccess([gpsCoords], false);
    };

    /**
     * Converts a Polygon from the current coordinate system to the Mercator coordinate system.
     *
     * @return {PolygonAccess} A new PolygonAccess object representing the point in the Mercator coordinate system.
     */
    toMercator()
    {
        let coordinates = this.getCoordinates();

        if (this.isMercator)
        {
            return new PolygonAccess(coordinates);
        }

        const mercatorCoords = gpsCoordinateArrayToMercatorLocations(coordinates);
        return new PolygonAccess([mercatorCoords]);
    };
}

exports.PolygonAccess = PolygonAccess;