exports.emails = {

    API_KEY: "A8B2B9E3EF094BE9BA6056705BB5A335",

    templates: {
        inquiryEmail: {
            templateName: "Inquiry Email",
        },
        verificationEmail: {
            templateName: "Verification Email",
        },
        smsRegistrationEmail: {
            templateName: "SMS Registration Email"
        }
    }
}

exports.PUBLISH_DATA_SCOPES = {
    PROD: "prod",
    UAT: "uat"
};