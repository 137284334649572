import React, { useContext, useState, useEffect } from "react";
import { MapOverlaysContext } from "../../../../store/MapOverlaysContext";
import { ButtonIcon } from "../../../common/ButtonIcon";
import { Scrollbars } from "react-custom-scrollbars-2";
import { TextGroup } from "../../../common/TextGroup";
import { Checkbox, Header } from "semantic-ui-react";
import { MAP_OVERLAYS_MODES } from "../../../../_constants/constants";
import { DEFAULT_LANGUAGE_CODE } from "mapsted.maps/utils/map.constants";
import { Button } from "semantic-ui-react";
import { arrayMove } from "react-sortable-hoc";
import "./MapOverlaysSidebars.css";
import SearchBar from "./Search/SearchBar";
import Message from "./Search/Message";
import { useTranslation } from "react-i18next";
import ReorderModal from "./ReorderModal";
import { useMemo } from "react";
import OverlayConfigurationItem from "./OverlayConfigurationItem";
import MultipleDeleteConfirmationModal from "./MultipleDeleteConfirmationModal";
import DeleteErrorModal from "./DeleteErrorModal";

const MapOverlaysActivitySideBar = () => 
{
    const [showreorderModal, setShowReorderModal] = useState(false);
    const mapOverlayCtx = useContext(MapOverlaysContext);
    // used to prevent user from unchecking checkboxes after publish button is clicked
    const [disableCheckBoxChanges, setDisableCheckBoxChanges] = useState(false);
    const {
        state: ctxState,
        editClickHandler,
        recenterMapOnOverlay,
        updateSeletedMapOverlayIds,
        handleCopyMapOverlay,
        handleDeleteMapOverlay,
        updateMode,
        propertyId,
        buildingId,
        updateMapOverLaysIds,
        floorId,
        activitySectionPreviewClickHandler,
        handleDeleteMultipleMapoverlays,
        setState
    } = mapOverlayCtx;
    let { mapOverlays: mapOverlaysList, selectedMapOverlayIds, consumedMapOverlays, mode } = ctxState;
    const [mapOverlaysModalState, setMapOverLaysModalState] = useState([]);
    const [inputValue, setInputValue] = useState("");

    const [deleteList, setDeleteList] = useState([]);
    const [multiDeleteModalOpen, setMultiDeleteModalOpen] = useState(false);
    const [multiDeleteErrorModalOpen, setMultiDeleteErrorModalOpen] = useState(false);

    const [selectAllState, setSelectAllState] = useState({
        checked: false,
        indeterminate: false,
    });

    const trans = useTranslation().t;

    function isOverLayTextMatchingWith(overlay, searchVal) 
    {
        return (
            overlay?.name?.[DEFAULT_LANGUAGE_CODE]?.toLowerCase().includes(
                searchVal
            )
        || overlay?.toolTipText?.[DEFAULT_LANGUAGE_CODE]?.toLowerCase().includes(
            searchVal
        )
        || (overlay.dynamicOverlaySettings?.enabled
          && overlay.dynamicOverlaySettings?.zoomLevels.some(
              (zoomLevel) => zoomLevel.overrideGlobalTextLabel
              && zoomLevel?.textLabel?.[
                  DEFAULT_LANGUAGE_CODE
              ]?.toLowerCase().includes(searchVal)
          ))
        );
    }

    const mapOverlays = useMemo(() => 
    {
        let overlays = mapOverlaysList;
       
        if (inputValue)
        {
            const inputValueMod = inputValue.trim().toLowerCase();
            overlays = mapOverlaysList.filter((overlay) => isOverLayTextMatchingWith(overlay, inputValueMod) || overlay._id.toLowerCase() === inputValueMod);
        }

        return overlays;
       
    }, [inputValue, mapOverlaysList]);

    function handleChange(e)
    {
        setInputValue(e.target.value);
    }

    const resetDeleteList = () =>
    {
        //check the updated mapoverlay list and update delete list
        const currentOverlays = mapOverlaysList.map((mapOverlay) => mapOverlay._id);

        let updatedDeleteList = [];
        
        deleteList.forEach((mapOverlayId) =>
        {
            if (currentOverlays.includes(mapOverlayId))
            {
                updatedDeleteList.push(mapOverlayId);
            }
        });

        setDeleteList(updatedDeleteList);
    };

    useEffect(() =>
    {
        // clear delete list when activity sidebar is closed
        if (mode !== MAP_OVERLAYS_MODES.ACTIVITY_SIDEBAR)
        {
            setDeleteList([]);
        }
    }, [mode]);

    useEffect(() => 
    {
        setInputValue("");
        resetDeleteList();
    }, [mapOverlaysList]);

    useEffect(() => 
    {
        if (showreorderModal)
        {
            setMapOverLaysModalState([...mapOverlaysList]);
        }
        else 
        {
            setMapOverLaysModalState([]);
        }
    }, [showreorderModal]);

    // reset disable flag if property, building or floor is changed
    useEffect(() => 
    {
        setDisableCheckBoxChanges(false);
    }, [propertyId, buildingId, floorId]);

    // update 'Select All' checkbox status based on search result and current delete list
    useEffect(() =>
    {
        let matchedOverlays = 0;
        let checked = false;
        let indeterminate = false;

        const nonConsumedMapOverlays = mapOverlays.filter((mapOverlay) => !consumedMapOverlays.includes(mapOverlay._id));

        nonConsumedMapOverlays.forEach((mapOverlay) =>
        {
            if (deleteList.includes(mapOverlay._id))
            {
                matchedOverlays++;
            }
        });

        // if the number of overlays checked is equal to non consumed overlays listed in result, show select all as checked
        if (nonConsumedMapOverlays.length && matchedOverlays === nonConsumedMapOverlays.length)
        {
            checked = true;
            indeterminate = false;
        }
        // if all the non consumed overlays is unchecked in result, show select all as unchecked 
        else if (matchedOverlays === 0)
        {
            checked = false;
            indeterminate = false;
        }
        // in every other cases show select all as indeterminate
        else
        {
            checked = false;
            indeterminate = true;
        }

        setSelectAllState({
            checked,
            indeterminate
        });

    }, [mapOverlays, deleteList]);

    const editHandler = (id) => 
    {
        editClickHandler(id);
        recenterMapOnOverlay(id);
    };

    if (mapOverlayCtx.state.mode !== MAP_OVERLAYS_MODES.ACTIVITY_SIDEBAR && !mapOverlayCtx.state.isActivitySideBar) 
    {
        return null;
    }

    function handleModalClose()
    {
        setShowReorderModal(false);
    }

    function handleOnSortEnd({ oldIndex, newIndex })
    {
        let updatedOverlays = arrayMove(mapOverlaysModalState, oldIndex, newIndex).map((item, idx) => ({ ...item, index: idx }));
        setMapOverLaysModalState([...updatedOverlays]);
    }

    function handleOnSubmit()
    {
        updateMapOverLaysIds([...mapOverlaysModalState]).then((_) => 
        {
            setShowReorderModal(false);
        });
    }

    const addToDeleteList = (mapOverlayId) =>
    {
        setDeleteList((prev) => [...prev, mapOverlayId]);
    };

    const removeFromDeleteList = (mapOverlayId) =>
    {
        setDeleteList((prev) => prev.filter((existingOverlayId) => existingOverlayId !== mapOverlayId));
    };

    const handleMultipleDelete = async () =>
    {
        const deleteResult = await handleDeleteMultipleMapoverlays(deleteList);
    
        setMultiDeleteModalOpen(false);
    
        if (!deleteResult?.success) 
        {
            setMultiDeleteErrorModalOpen(true);
        }
    };

    const handleSelectAllClick = async (checked) =>
    {
        const mapOverlayIds = mapOverlays.map((mapOverlay) => mapOverlay._id);

        let updatedDeleteList = new Set([ ...deleteList ]);

        if (checked)
        {
            mapOverlayIds.forEach((mapOverlayId) =>
            {
                if (!consumedMapOverlays.includes(mapOverlayId))
                {
                    updatedDeleteList.add(mapOverlayId);
                }
            });
        }
        else
        {
            mapOverlayIds.forEach((mapOverlayId) =>
            {
                updatedDeleteList.delete(mapOverlayId);
            });
        }

        setDeleteList(Array.from(updatedDeleteList));
    };

    function renderOverLayList(overlays, inModal = false)
    {
        if (!overlays.length)
        {
            if (inputValue)
            {
                return <Message>{trans("MapOverlaysActivitySideBar.No_Results_Found")}</Message>;
            }
            else 
            {
                return <Message>No Overlays</Message>;
            }
        } 

        return overlays.map((mapOverlay, index) => <OverlayConfigurationItem
            showreorderModal={inModal}
            key={mapOverlay._id}
            index={index}
            mapOverlay={mapOverlay}
            recenterMapOnOverlay={recenterMapOnOverlay}
            onEdit={editHandler}
            isPublished={mapOverlay.status === 2}
            updateSeletedMapOverlayIds={updateSeletedMapOverlayIds}
            selectedMapOverlayIds={selectedMapOverlayIds}
            handleCopyMapOverlay={handleCopyMapOverlay}
            handleDeleteMapOverlay={handleDeleteMapOverlay}
            disableCheckbox={disableCheckBoxChanges}
            onPreviewClick={activitySectionPreviewClickHandler}
            disableEditAndDelete={consumedMapOverlays.includes(
                mapOverlay._id
            )}
            trans={trans}
            isChecked={deleteList.includes(mapOverlay._id)}
            onCheck={addToDeleteList}
            onUnCheck={removeFromDeleteList}
        />);
    }

    return (
        <div className="sidebarPortal mapOverlaysSidebar activitySidebarDynamicLayer activity-sidebar">
            <ReorderModal 
                submitHandler={handleOnSubmit} 
                onClose={handleModalClose} 
                handleOnSortEnd={handleOnSortEnd} 
                trans={trans} 
                showreorderModal={showreorderModal}
            >
                {renderOverLayList(mapOverlaysModalState, showreorderModal)}
            </ReorderModal>

            <Scrollbars autoHeight autoHeightMin="calc(100vh - 170px)" autoHeightMax={"calc(100vh - 170px)"}>
                <div className="alignerSidebar">
                    <Header as="h2">
                        <strong>{trans("MapOverlaysActivitySideBar.Configure_Map_Overlay")}</strong>
                        <div style={{ position: "absolute", right: 40 }}>
                            <ButtonIcon 
                                icon="settings-blue" 
                                className="editCategoryTrigger"
                                disabled={!mapOverlaysList.length} 
                                onClick={() => setShowReorderModal(true)} />
                        </div>
                        <ButtonIcon
                            icon="cross"
                            className="cancelButton"
                            onClick={() => 
                            {
                                updateMode(undefined);
                                setState({ isActivitySideBar: !ctxState.isActivitySideBar });
                            }}
                        />
                    </Header>
                    <div className="sidebarSection">
                        <TextGroup className="tgMapOverlay">
                            <p className="tgTitle">
                                {trans("MapOverlaysActivitySideBar.Map_Overlay_Configuration")}
                                <ButtonIcon
                                    icon="upload-csv"
                                    content={trans("MapOverlaysActivitySideBar.Upload_Csv")}
                                    active={mode === MAP_OVERLAYS_MODES.UPLOAD_CSV}
                                    onClick={() => updateMode(MAP_OVERLAYS_MODES.UPLOAD_CSV)}
                                    disabled={!mapOverlaysList.length}
                                />
                            </p>
                            <SearchBar onChange={handleChange} value={inputValue}></SearchBar>

                            {!!mapOverlays.length && <Checkbox 
                                className="checkboxGroup"
                                label={trans("MapOverlaysActivitySideBar.Select_All")}
                                checked={selectAllState.checked}
                                indeterminate={selectAllState.indeterminate}
                                onChange={(e, data) => handleSelectAllClick(data.checked)}
                            />}

                            {renderOverLayList(mapOverlays)}
                        </TextGroup>
                    </div>
                </div>
            </Scrollbars>
            <Button
                primary
                className="saveButton mapOverlay"
                onClick={() => setMultiDeleteModalOpen(true)}
                disabled={!deleteList.length}
            >
                {trans("MapOverlaysActivitySideBar.Delete")}
            </Button>
            <MultipleDeleteConfirmationModal
                open={multiDeleteModalOpen}
                deleteList={deleteList}
                onDeleteClick={handleMultipleDelete}
                onClose={() => setMultiDeleteModalOpen(false)}
                trans={trans}
            />
            <DeleteErrorModal
                deleteErrorModalOpen={multiDeleteErrorModalOpen}
                onClose={() => setMultiDeleteErrorModalOpen(false)}
                trans={trans}
                multipleDelete={true}
            />
        </div>
    );

};

export default MapOverlaysActivitySideBar;
