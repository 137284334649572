import React, { createContext, useContext, useEffect } from "react";
import BrandingContext from "../store/BrandingContext";
import { useSetState } from "ahooks";
import serverAPI from "../_api/server.api";
import { useLoadingPool } from "../_utils/hooks";
import { Loader } from "../components/elements/loader";
import { 
    BASE_MAPS,
    BUILDING_LOCATION_TYPE, 
    HIDDEN_POLYGON_STYLE, 
    HIDDEN_TEXT_STYLE, 
    PROPERTY_LOCATION_TYPE, 
    STYLE_TYPES 
} from "../_constants/stylesAndThemesConstants";
import { getDefaultEntityStyle } from "../_utils/stylesAndThemesUtil";
import cloneDeep from "lodash.clonedeep";
import EntityMapController from "mapsted.maps";
import { filerUrl } from "../_utils/utils";
import { CMSEntityAccess } from "mapsted.maps/mapFunctions/entityAccess";
import { 
    DEFAULT_LANGUAGE_CODE, 
    MAP_THEMES,
    MAPSTED_LIGHT_THEME,
    MAPSTED_DARK_THEME
} from "mapsted.maps/utils/map.constants";
import { createPolygonStyleObject, createTextStyleObject } from "mapsted.maps/mapFunctions/plotting";
import { toast } from "react-toastify";
import { Trans } from "react-i18next";
import { truncateText } from "../_utils/mapUtils";

export const StylesAndThemesContext = createContext();

export const useStyleAndThemesContext = () => useContext(StylesAndThemesContext);

export const StylesAndThemesProvider = ({ children }) =>
{
    const { state: brandingCtxState, updateMapData: updateBrandingMapData } = useContext(BrandingContext);
    const { mapData: brandingMapData } = brandingCtxState;
    const { propertyId, buildingId, settingsConfig } = brandingCtxState;

    const loadingPool = useLoadingPool();

    const [state, setState] = useSetState({
        themes: [],
        activeTheme: undefined,
        styles: [],
        activeStyle: undefined,
        entityTypeMap: {},
        mapData: {},
        entityLayers: {},
        imageLayers: {},
        boundaryPolygon: undefined,
        previewState: "default",
        mapController: new EntityMapController({
            features: { text: true, images: true },
            filerUrl: filerUrl(""),
            accessor: CMSEntityAccess,
            theme: MAP_THEMES.CLASSIC
        }),
        isBuildingSelected: false,
        tileLayer: {}, // used to update the base map according to theme,
        hiddenEntityTypes: [],
        propertySettings: {},
    });

    useEffect(() =>
    {
        let isBuildingSelected = !!buildingId && buildingId !== "-1";
        setState({
            isBuildingSelected
        });
    }, [buildingId]);

    useEffect(() =>
    {
        const { entities } = state.mapData;

        if (entities)
        {
            let entityTypeMap = {};

            Object.values(entities).forEach((entity) =>
            {
                const entityClassification = `${entity.entityType}:${entity.subEntityType}`;
                if (entityTypeMap[entityClassification])
                {
                    entityTypeMap[entityClassification].push(entity);
                }
                else
                {
                    entityTypeMap[entityClassification] = [entity];
                }
            });

            setState({
                entityTypeMap
            });
        }
    }, [state.mapData.entities]);

    useEffect(() =>
    {
        initializeStylesMapData();
    }, [brandingMapData, settingsConfig]);

    const initializeStylesMapData = async () =>
    {
        if (brandingMapData && settingsConfig)
        {
            // store a copy of mapdata from branding context in this context
            const mapData = cloneDeep(brandingMapData);
                    
            if (mapData?.entities)
            {
                // create map layers separately from branding context, so that changes done in this map does
                // not impact branding page map
                const {
                    entityLayers, boundaryPolygon, imageLayers
                } = state.mapController.initialize(mapData);

                // get all the themes for the current property
                const themes = await getPropertyThemes(propertyId);

                // get property settings for the current property
                // const propertySettings = await getPropertySettings(propertyId);

                let activeTheme = state.activeTheme;

                // set activeTheme based on primary theme field in property settings if no active theme was selected before
                if (!activeTheme)
                {
                    activeTheme = settingsConfig?.primaryTheme;
                }

                setState({
                    mapData,
                    entityLayers,
                    imageLayers,
                    boundaryPolygon,
                    themes,
                    activeStyle: undefined,
                    propertySettings: settingsConfig,
                    activeTheme,
                });
            }
        }
    };

    useEffect(() =>
    {
        if (brandingCtxState.propertyId)
        {
            initializeStylesMapData();
        }
    }, [propertyId, settingsConfig]);

    useEffect(() =>
    {

        const { activeTheme, themes, mapController, mapData } = state;
        let updatedImageLayers = { ...state.imageLayers };
        
        if (activeTheme)
        {
            getStyles(state.activeTheme);

            const currentTheme = themes.find((theme) => theme._id === activeTheme);

            // update mapController theme to switch to dark if current theme is dark to create display dark logos in the map if available
            if (currentTheme?.dark)
            {
                mapController.setTheme(MAP_THEMES.DARK);
            }
            else
            {
                mapController.setTheme(MAP_THEMES.CLASSIC);
            }

            if (mapData.entities)
            {
                updatedImageLayers = mapController.createImageLayers(Object.values(mapData.entities));
            }


            // set base map tile layer using tileLayer from current theme
            setState({
                tileLayer: currentTheme?.tileLayer,
                mapController,
                imageLayers: updatedImageLayers,
            });
        }
        else
        {
            // if no theme is selected use default tile layer in base map
            const tileLayer = BASE_MAPS[0].tileLayer;

            // set mapController theme to default to create default light logos and display in map
            mapController.setTheme(MAP_THEMES.CLASSIC);

            if (mapData.entities)
            {
                updatedImageLayers = mapController.createImageLayers(Object.values(mapData.entities));
            }

            setState({
                styles: [],
                tileLayer,
                mapController,
                imageLayers: updatedImageLayers,
            });
        }
    }, [state.activeTheme]);

    useEffect(() =>
    {
        applyCurrentThemeStylesInMap();
    }, [state.styles, state.entityTypeMap, state.hiddenEntityTypes, state.themes, state.isBuildingSelected]);

    const applyCurrentThemeStylesInMap = (lang=DEFAULT_LANGUAGE_CODE) =>
    {
        const { styles, entityTypeMap, isBuildingSelected, hiddenEntityTypes } = state;

        // get dark profile status based on current theme (if any theme is selected else use default status of false)
        const dark = getCurrentThemeDarkStatus();

        if (styles && entityTypeMap)
        {
            const locationType = isBuildingSelected ? BUILDING_LOCATION_TYPE : PROPERTY_LOCATION_TYPE;

            const filteredStyles = styles.filter((style) => style.target.locationType === locationType);

            const styleMap = {};

            filteredStyles.forEach((style) =>
            {
                styleMap[`${style.target.entityType}:${style.target.subEntityType}`] = style;
            });

            Object.keys(entityTypeMap).forEach((key) =>
            {
                let polygonStyle;
                let textStyle;

                if (styleMap[key])
                {
                    polygonStyle = styleMap[key]?.styleSettings?.[lang]?.polygon?.default;
                    textStyle = styleMap[key]?.styleSettings?.[lang]?.text?.default;
                }
                else
                {
                    // if no theme is selected or the style does not exist for this entity type, use default style settings
                    const entityTypeParts = key.split(":");
                    const defaultStyle = getDefaultEntityStyle({
                        entityType: +entityTypeParts[0], 
                        subEntityType: +entityTypeParts[1], 
                        locationType,
                        dark,
                        isNew: true,
                    });

                    polygonStyle = defaultStyle?.styleSettings?.[lang]?.polygon?.default;
                    textStyle = defaultStyle?.styleSettings?.[lang]?.text?.default;
                }

                // check if entity type is hidden, if so make its polygon and text opacity to zero
                const isHidden = hiddenEntityTypes.includes(key);

                if (polygonStyle && isHidden)
                {
                    polygonStyle = HIDDEN_POLYGON_STYLE;
                }

                if (textStyle && isHidden)
                {
                    textStyle = HIDDEN_TEXT_STYLE;
                }

                // hide/unhide image based on status of entity display flag
                toggleImageDisplay(key, isHidden);

                if (polygonStyle)
                {
                    updateEntityPolygonStyleInMap(key, polygonStyle);
                }

                if (textStyle)
                {
                    updateEntityTextStyleInMap(key, textStyle);
                }

            });
        }
    };

    const toggleImageDisplay = (entityClassification, isHidden) =>
    {
        const { entityTypeMap, imageLayers } = state;

        const selectedEntityClassification = entityTypeMap[entityClassification];

        if (selectedEntityClassification)
        {
            selectedEntityClassification.forEach((entity) =>
            {
                if (imageLayers[entity._id])
                {
                    imageLayers[entity._id].setVisible(!isHidden);
                }
            });
        }
    };

    const getPropertyThemes = async (propertyId) =>
    {
        let themes = [MAPSTED_LIGHT_THEME, MAPSTED_DARK_THEME];
        const loadingId = loadingPool.add();
        try
        {
            const res = await serverAPI.getThemes(propertyId, false);

            if (res.success && res.themes)
            {
                themes = [ ...themes, ...res.themes ];
            }
        }
        catch (error)
        {
            console.log(error);
        }
        finally
        {
            loadingPool.remove(loadingId);
        }
        return themes;
    };

    const getPropertySettings = async (propertyId) =>
    {
        let propertySettings = {};
        const loadingId = loadingPool.add();
        try
        {
            const res = await serverAPI.fetchPropertySettings(propertyId);
            if (res)
            {
                propertySettings = res;
            }
        }
        catch (error)
        {
            console.log(error);
        }
        finally
        {
            loadingPool.remove(loadingId);
        }
        return propertySettings;
    };

    const getStyles = async (themeId) =>
    {
        const loadingId = loadingPool.add();
        try
        {
            const res = await serverAPI.getStyles(themeId);

            let styles = [];

            if (res.success && res.styles)
            {
                styles = res.styles;
            }

            setState({
                styles
            });
        }
        catch (error)
        {
            // TODO: notify about the error while trying to retrieve styles for the selected theme
            console.log(error);
        }
        finally
        {
            loadingPool.remove(loadingId);
        }
    };

    const updateActiveTheme = (themeId) =>
    {
        setState({
            activeTheme: themeId
        });
    };

    const handleEntityStyleSelection = (entityType, subEntityType, locationType, lang=DEFAULT_LANGUAGE_CODE) =>
    {
        const { styles } = state;

        let activeStyle;

        const selectedStyle = styles.find((style) => style.styleType === STYLE_TYPES.ENTITY
            && style.target.entityType === entityType
            && style.target.subEntityType === subEntityType
            && style.target.locationType === locationType);

        const dark = getCurrentThemeDarkStatus();

        // if style exists for the selected entity and sub entity type use that else create a new one using default style settings
        if (selectedStyle)
        {
            activeStyle = cloneDeep(selectedStyle);
        }
        else
        {
            // create a style object using default style settings
            activeStyle = getDefaultEntityStyle({ 
                entityType, 
                subEntityType, 
                locationType, 
                themeId: state.activeTheme,
                dark,
                isNew: true,
            });
        }

        const entityClassification = `${entityType}:${subEntityType}`;

        const { styleSettings } = activeStyle;

        if (styleSettings[lang].polygon?.default)
        {
            updateEntityPolygonStyleInMap(entityClassification, styleSettings[lang].polygon.default);
        }

        if (styleSettings[lang].text?.default)
        {
            updateEntityTextStyleInMap(entityClassification, styleSettings[lang].text.default);
        }

        setState({
            activeStyle
        });
    };

    const getCurrentThemeDarkStatus = () =>
    {
        const { activeTheme, themes } = state;

        let dark = false;

        if (activeTheme)
        {
            const currentTheme = themes.find((theme) => theme._id === activeTheme);
            dark = currentTheme?.dark;
        } 

        return dark;
    };

    const handleStyleEditCancel = () =>
    {
        applyPreviousStyleInMap();
        
        setState({
            activeStyle: undefined,
            previewState: "default",
        });
    };

    const getPreviousStyle = (entityType, subEntityType, locationType) =>
    {
        const { styles } = state;

        let prevStyle;

        const selectedStyle = styles.find((style) => style.styleType === STYLE_TYPES.ENTITY
            && style.target.entityType === entityType
            && style.target.subEntityType === subEntityType
            && style.target.locationType === locationType);

        const dark = getCurrentThemeDarkStatus();
            
        // if style already exists, return it else create and return a new defualt style object
        if (selectedStyle)
        {
            prevStyle = selectedStyle;
        }
        else
        {
            prevStyle = getDefaultEntityStyle({ 
                entityType, 
                subEntityType, 
                locationType, 
                themeId: state.activeTheme,
                dark,
                isNew: true
            });
        }

        return prevStyle;
    };

    const applyPreviousStyleInMap = (lang=DEFAULT_LANGUAGE_CODE) =>
    {
        const { entityType, subEntityType, locationType } = state.activeStyle.target;
        const { styleSettings } =  getPreviousStyle(entityType, subEntityType, locationType);
        const entityClassification = `${entityType}:${subEntityType}`;

        if (styleSettings[lang].polygon?.default)
        {
            updateEntityPolygonStyleInMap(entityClassification, styleSettings[lang].polygon.default);
        }
        
        if (styleSettings[lang].text?.default)
        {
            updateEntityTextStyleInMap(entityClassification, styleSettings[lang].text.default);
        }
    };

    const updateActiveStylePolygon = (type, polygonStyle, lang=DEFAULT_LANGUAGE_CODE) =>
    {
        const updatedActiveStyle = { ...state.activeStyle };
        updatedActiveStyle.styleSettings[lang].polygon[type] = polygonStyle;

        const entityClassification = `${updatedActiveStyle.target.entityType}:${updatedActiveStyle.target.subEntityType}`;
        updateEntityPolygonStyleInMap(entityClassification, polygonStyle);

        setState({
            activeStyle: updatedActiveStyle
        });
    };

    const updateActiveStyleText = (type, textStyle, lang=DEFAULT_LANGUAGE_CODE) =>
    {
        const updatedActiveStyle = { ...state.activeStyle };
        updatedActiveStyle.styleSettings[lang].text[type] = textStyle;

        const entityClassification = `${updatedActiveStyle.target.entityType}:${updatedActiveStyle.target.subEntityType}`;
        updateEntityTextStyleInMap(entityClassification, textStyle);

        setState({
            activeStyle: updatedActiveStyle
        });
    };

    const updateEntityPolygonStyleInMap = (entityClassification, polygonStyle) =>
    {
        const { entityTypeMap } = state;

        const selectedEntityClassification = entityTypeMap[entityClassification];

        if (selectedEntityClassification)
        {
            const style = createPolygonStyleObject(polygonStyle);
            selectedEntityClassification.forEach((entity) =>
            {
                const feature = entity.feature;

                if (feature)
                {
                    feature.setStyle(style);
                }
            });
        }
    };

    const updateEntityTextStyleInMap = (entityClassification, textStyle) =>
    {
        const { entityTypeMap } = state;

        const selectedEntityClassification = entityTypeMap[entityClassification];

        if (selectedEntityClassification)
        {
            const { fill, stroke, font } = createTextStyleObject(textStyle);

            selectedEntityClassification.forEach((entity) =>
            {
                const text = entity.textFeature?.getStyle()?.getText();
                if (text)
                {
                    text.setFill(fill);
                    text.setStroke(stroke);
                    text.setFont(font);
                    entity.textFeature.changed();
                }
            });
        }
    };

    const handleRevertToDefault = (lang=DEFAULT_LANGUAGE_CODE) =>
    {
        const { entityType, subEntityType, locationType } = state.activeStyle.target;

        const dark = getCurrentThemeDarkStatus();

        const defaultStyle = getDefaultEntityStyle({
            entityType, 
            subEntityType, 
            locationType, 
            themeId: state.activeTheme,
            dark,
        });

        const entityClassification = `${entityType}:${subEntityType}`;

        if (defaultStyle.styleSettings?.[lang]?.polygon?.default)
        {
            updateEntityPolygonStyleInMap(entityClassification, defaultStyle.styleSettings[lang].polygon.default);
        }

        if (defaultStyle.styleSettings?.[lang]?.text?.default)
        {
            updateEntityTextStyleInMap(entityClassification, defaultStyle.styleSettings[lang].text.default);
        }

        defaultStyle._id = state.activeStyle._id;

        setState({
            activeStyle: defaultStyle
        });
    };

    const handlePreviewStateChange = (newPreviewState, lang=DEFAULT_LANGUAGE_CODE) =>
    {
        const { previewState, activeStyle } = state;

        if (previewState !== newPreviewState)
        {
            const target = activeStyle.target;
            const entityClassification = `${target.entityType}:${target.subEntityType}`;
            const polygonStyle = getPolygonStyleForPreviewState(activeStyle, newPreviewState, lang);
            const textStyle = getTextStyleForPreviewState(activeStyle, newPreviewState, lang);

            if (polygonStyle)
            {
                updateEntityPolygonStyleInMap(entityClassification, polygonStyle);
            }

            if (textStyle)
            {
                updateEntityTextStyleInMap(entityClassification, textStyle);
            }

            setState({
                previewState: newPreviewState
            });
        }

    };

    const getPolygonStyleForPreviewState = (style, previewState, lang=DEFAULT_LANGUAGE_CODE) =>
    {
        let polygonStyle;

        if (style.styleSettings[lang]?.polygon?.[previewState])
        {
            polygonStyle = style.styleSettings[lang].polygon[previewState];
        }
        else
        {
            polygonStyle = style.styleSettings[lang].polygon?.default;
        }

        return polygonStyle;
    };

    const getTextStyleForPreviewState = (style, previewState, lang=DEFAULT_LANGUAGE_CODE) =>
    {
        let textStyle;

        if (style.styleSettings[lang]?.text?.[previewState])
        {
            textStyle = style.styleSettings[lang].text[previewState];
        }
        else
        {
            textStyle = style.styleSettings[lang]?.text?.default;
        }
        
        return textStyle;
    };

    const handleSave = async () =>
    {
        const { activeStyle, activeTheme, propertySettings } = state;
        const updatedStyles = [ ...state.styles ];

        let result = {};
        if (activeStyle.newStyleObject)
        {
            activeStyle.property = propertyId;

            // create new style
            result = await createNewStyle(activeStyle);
        }
        else
        {
            // edit existing style
            result = await updateStyle(activeStyle);
        }

        if (result.success)
        {
            // update styles array in state with created/edited style
            const { style: styleToUpdate } = result;

            if (styleToUpdate)
            {
                const selectedStyleIndex = updatedStyles.findIndex((style) => style._id === styleToUpdate._id);

                // if style was newly created push it to styles array else update the existing entry in it
                if (selectedStyleIndex === -1)
                {
                    updatedStyles.push(styleToUpdate);
                }
                else
                {
                    updatedStyles.splice(selectedStyleIndex, 1, styleToUpdate);
                }
            }

            // If current theme is primary theme update map data in branding page as well
            if (activeTheme === propertySettings.primaryTheme)
            {
                updateBrandingMapData();
            }

            // close style editor if creation/update process is successfull
            setState({
                activeStyle: undefined,
                styles: updatedStyles,
                previewState: "default",
            });
        }
    };

    const createNewStyle = async (style) =>
    {
        const loadingId = loadingPool.add();
        let result = {};
        try
        {
            const res = await serverAPI.createStyle(style);
            result = {
                success: res.success,
                style: res.createdStyle
            };
        }
        catch (error)
        {
            // TODO: notify if the creation process fails
            console.log(error);
        }
        finally
        {
            loadingPool.remove(loadingId);
        }
        return result;
    };

    const updateStyle = async (style) =>
    {
        const loadingId = loadingPool.add();
        let result = {};
        try
        {
            const res = await serverAPI.updateStyle(style);
            result = {
                success: res.success,
                style: res.updatedStyle
            };
        }
        catch (error)
        {
            // TODO: notify if style update fails
            console.log(error);
        }
        finally
        {
            loadingPool.remove(loadingId);
        }
        return result;
    };

    const handleThemeEdit = async (themeId, themeName, baseMapId, dark) =>
    {
        let tileLayer = BASE_MAPS.find((baseMap) => baseMap.baseMapId === baseMapId)?.tileLayer;

        tileLayer.baseMapId = baseMapId;

        let updateFields = {
            name: {
                [DEFAULT_LANGUAGE_CODE]: themeName
            },
            tileLayer,
            dark,
        };

        const { success, updatedTheme } = await updateTheme(themeId, updateFields, propertyId);

        if (success)
        {
            const themes = [ ...state.themes ];

            let currentThemeIndex = themes.findIndex((theme) => theme._id === updatedTheme._id);

            themes[currentThemeIndex] = {
                ...themes[currentThemeIndex],
                ...updatedTheme,
            };

            setState({
                themes: themes,
                tileLayer: updatedTheme.tileLayer
            });
        }

        return { success };
    };

    const handleThemeActiveStatusChange = async (themeId, activeStatus) =>
    {
        const updateFields = {
            active: activeStatus
        };

        const { success, updatedTheme } = await updateTheme(themeId, updateFields, propertyId);

        if (success)
        {
            const themes = [ ...state.themes ];

            let currentThemeIndex = themes.findIndex((theme) => theme._id === updatedTheme._id);

            themes[currentThemeIndex] = {
                ...themes[currentThemeIndex],
                ...updatedTheme,
            };

            setState({
                themes: themes
            });
        }

        return { success };
    };

    const updateTheme = async (themeId, updateFields, propertyId) =>
    {
        const loadingId = loadingPool.add();
        let result = {
            success: false
        };

        try
        {
            result = await serverAPI.updateTheme(themeId, updateFields, propertyId);

        }
        catch (error)
        {
            console.log("Error while creating theme", error);
        }
        finally
        {
            loadingPool.remove(loadingId);
        }

        return result;
    };

    const handleThemeCreation = async (themeName, baseMapId, dark) =>
    {
        let tileLayer = BASE_MAPS.find((baseMap) => baseMap.baseMapId === baseMapId)?.tileLayer;

        tileLayer.baseMapId = baseMapId;

        let newTheme = {
            name: {
                [DEFAULT_LANGUAGE_CODE]: themeName
            },
            tileLayer,
            property: propertyId,
            active: true,
            dark,
        };

        const { success, createdTheme } = await createTheme(newTheme, propertyId);

        if (success)
        {
            let updatedThemesArray = [ ...state.themes, createdTheme ];

            // set newly created theme as activeTheme and update base map with its tile layer
            setState({
                activeTheme: createdTheme._id,
                themes: updatedThemesArray,
                tileLayer: createdTheme.tileLayer
            });
        }

        return { success };
    };

    const createTheme = async (theme, propertyId) =>
    {
        const loadingId = loadingPool.add();
        let result = {
            success: false
        };

        try
        {
            result = await serverAPI.createTheme(theme, propertyId);

        }
        catch (error)
        {
            console.log("Error while creating theme", error);
        }
        finally
        {
            loadingPool.remove(loadingId);
        }

        return result;
    };

    const handleDeleteTheme = async (themeId) => 
    {
        const loadingId = loadingPool.add();
        const themeInfo = state.themes.find((theme) => theme._id === themeId);

        try 
        {
            await serverAPI.deleteTheme({ themeId, propertyId });
            const newThemes = state.themes.filter((theme) => theme._id !== themeId);
            updateActiveTheme(state.propertySettings.primaryTheme || MAPSTED_LIGHT_THEME._id);
            setState({
                themes: newThemes
            });
            toast.success(<>
                <Trans
                    i18nKey="ThemeCreateAndEditModal.Delete_Theme_Success"
                    values={{ themeName: truncateText(themeInfo.name.en, 22) }}
                /></>);
        }
        catch (err)
        {
            console.log("Error while deleting theme", err);
            toast.error(<>
                <Trans
                    i18nKey="ThemeCreateAndEditModal.Delete_Theme_Success"
                    values={{ themeName: truncateText(themeInfo.name.en, 22) }}
                /></>);
            return Promise.reject(err);
        }
        finally 
        {
            loadingPool.remove(loadingId);
        }
    };

    const handleHideToggleClick = (entityClassification) =>
    {
        const hiddenEntityTypes = [ ...state.hiddenEntityTypes ];

        const hiddenEntityTypeIndex = hiddenEntityTypes.findIndex((entityType) => entityType === entityClassification);

        if (hiddenEntityTypeIndex === -1)
        {
            hiddenEntityTypes.push(entityClassification);
        }
        else
        {
            hiddenEntityTypes.splice(hiddenEntityTypeIndex, 1);
        }

        setState({
            hiddenEntityTypes
        });
    };

    const exportedValues = {
        state: { ...state, propertyId, buildingId },
        updateActiveTheme,
        handleEntityStyleSelection,
        handleStyleEditCancel,
        updateActiveStylePolygon,
        handlePreviewStateChange,
        updateActiveStyleText,
        handleRevertToDefault,
        handleSave,
        handleThemeCreation,
        handleThemeEdit,
        handleHideToggleClick,
        handleDeleteTheme,
        handleThemeActiveStatusChange,
    };

    return (
        <>
            <Loader active={loadingPool.loading}/>
            <StylesAndThemesContext.Provider value={exportedValues}>{children}</StylesAndThemesContext.Provider>
        </>
    );
};