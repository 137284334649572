import ObjectId from "bson-objectid";
import { Helmert } from "mapsted.maps/mapFunctions/cmshelmerttransformGeoRef";
import { createEntityGeometry, getStylesForGeoRefFloorPlanIncludingTextStyles } from "mapsted.maps/mapFunctions/plotting";
import { ShapeTypes } from "mapsted.maps/utils/entityTypes";
import { getIndexOfFeature, isImageFeature, isTransformed, markAsGeoRef, markAsImageFeature, markAsTransformed, setIndex, unMarkAsGeoRef, unMarkAsImageFeature } from "mapsted.maps/utils/map.utils";
import GeoImageLayer from "ol-ext/layer/GeoImage";
import GeoImageSrc from "ol-ext/source/GeoImage";
import { altKeyOnly, click, singleClick } from "ol/events/condition";
import { boundingExtent } from "ol/extent";
import Feature from "ol/Feature";
import { GeoJSON } from "ol/format";
import { GeometryCollection, Point } from "ol/geom";
import { fromExtent as PolygonFromExtent } from "ol/geom/Polygon";
import { Fill, Stroke, Style } from "ol/style";
import { MAP_LAYERS, MIN_FEATURES_TO_SELECT_FOR_GEO_REFERENCE } from "../../../../_constants/mapEditor";
import { mercatorToWgs84, wgs84ToMercator } from "../../../../_utils/turf.utlils";
import { filerUrl } from "../../../../_utils/utils";
import { defaultGeoReferencingFloorPlan } from "../FloorPlanProvider";

const transformAlgObj = new Helmert();


export const floorPlanFilerImageIdToBase64 = async (filerId) =>
{
    const url = filerUrl(filerId);
    const data = await fetch(url);
    const blob = await data.blob();
    try
    {
        const d = await new Promise((resolve, reject) =>
        {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () =>
            {
                const base64data = reader.result;
                resolve(base64data);
            };
            reader.onerror = reject;
        });

        // console.log("floorplan image base64 url: ", d);
        return d;

    }
    catch (err)
    {
        console.error(err);
        return url;
    }


};


export const updateFeaturesFromHahsOnVS = (featuresHash, vs) =>
{
    try
    {
        const copyFeaturesHash = { ...featuresHash };
        if (!vs || !featuresHash) return;
        vs.clear();
        Object.values(copyFeaturesHash).forEach((f) => vs.addFeature(f));
    }
    catch (err)
    {

        console.error(err);
    }

};



/**
 * Process the marked features and update the geo reference information.
 *
 * @param {object} prevFeaturesHash - the previous features hash
 * @param {object} newFeature - the new feature to be processed
 * @return {object} the updated geo reference information
 */
export const processAddNewFeature = (prevState, newFeature,) =>
{
    let { markedImageFeaturesHash, markedMapFeaturesHash } = prevState;
    markedImageFeaturesHash = { ...markedImageFeaturesHash };
    markedMapFeaturesHash = { ...markedMapFeaturesHash };
    const imageFeatures = Object.values(markedImageFeaturesHash);
    const mapFeatures = Object.values(markedMapFeaturesHash);



    let geoRefEditInfo = { ...prevState.geoReferenceEdit };

    //our algo only works with equal number of features
    if (doesMarkedPointsSatisfiesGeoRefCondition(imageFeatures, mapFeatures) && hasEqualImageAndMapPoints(imageFeatures, mapFeatures))
    {
        const xy = imageFeatures.sort((f1, f2) => f1.get("index") - f2.get("index")).map((f) => f.getGeometry().getCoordinates());
        const XY = mapFeatures.sort((f1, f2) => f1.get("index") - f2.get("index")).map((f) => f.getGeometry().getCoordinates());

        //once we have the control points we can do the transformation of the image features -> map features , map features -> image features
        transformAlgObj.setControlPoints(xy, XY);

        // is a point plotted on image layer then we also need to plot it on map layer
        // we do that by cloning the feature and changing the geometry
        // we will update the feature in the next step
        if (isImageFeature(newFeature))
        {
            markedImageFeaturesHash[getIndexOfFeature(newFeature)] = newFeature;
            const xy = newFeature.getGeometry().getCoordinates();
            const XY = transformAlgObj.transform(xy);
            const clonedF = newFeature.clone();
            clonedF.setGeometry(new Point(XY));
            unMarkAsImageFeature(clonedF);
            markAsGeoRef(clonedF);
            clonedF.setStyle(getStylesForGeoRefFloorPlanIncludingTextStyles(clonedF));
            markedMapFeaturesHash[getIndexOfFeature(clonedF)] = clonedF;

        }
        else
        {
            markedMapFeaturesHash[getIndexOfFeature(newFeature)] = newFeature;
            const XY = newFeature.getGeometry().getCoordinates();
            const xy = transformAlgObj.revers(XY);
            const clonedF = newFeature.clone();
            clonedF.setGeometry(new Point(xy));
            unMarkAsGeoRef(clonedF);
            markAsImageFeature(clonedF);
            clonedF.setStyle(getStylesForGeoRefFloorPlanIncludingTextStyles(clonedF));
            markedImageFeaturesHash[getIndexOfFeature(clonedF)] = clonedF;

        }

    }
    else
    {
        if (isImageFeature(newFeature))
        {
            markedImageFeaturesHash[getIndexOfFeature(newFeature)] = newFeature;
        }
        else
        {
            markedMapFeaturesHash[getIndexOfFeature(newFeature)] = newFeature;

        }
    }
    geoRefEditInfo = syncFeaturesWIthTransFromAndGetGeoRefenceEditInfo({ markedImageFeaturesHash, markedMapFeaturesHash }, geoRefEditInfo);


    return {
        markedImageFeaturesHash,
        markedMapFeaturesHash,
        ...geoRefEditInfo
    };

};


/**
 * Generate a new state after removing a feature from the given previous state.
 *
 * @param {object} prevState - The previous state object
 * @param {object} featureToDelete - The feature to be deleted
 * @return {object} The updated state after removing the feature
 */
export const processRemoveFeature = (prevState, featureToDelete) =>
{
    let { markedImageFeaturesHash, markedMapFeaturesHash, ...geoRefEditInfo } = prevState;
    markedImageFeaturesHash = { ...markedImageFeaturesHash };
    markedMapFeaturesHash = { ...markedMapFeaturesHash };
    const imageFeatures = Object.values(markedImageFeaturesHash);
    const mapFeatures = Object.values(markedMapFeaturesHash);

    const isGeoReferenced = doesMarkedPointsSatisfiesGeoRefCondition(imageFeatures, mapFeatures);

    if (isImageFeature(featureToDelete))
    {
        delete markedImageFeaturesHash[getIndexOfFeature(featureToDelete)];
        if (isGeoReferenced)
        {
            delete markedMapFeaturesHash[getIndexOfFeature(featureToDelete)];
            markedMapFeaturesHash = Object.values(markedMapFeaturesHash).sort((a, b) => a.get("index") - b.get("index")).reduce((acc, f, idx) =>
            {
                const newIndex = idx + 1;
                setIndex(f, newIndex);
                acc[newIndex] = f;
                f.setStyle(getStylesForGeoRefFloorPlanIncludingTextStyles(f));
                return acc;
            }, {});
        }
        const updatedImageFeatures = Object.values(markedImageFeaturesHash);
        markedImageFeaturesHash = updatedImageFeatures.sort((a, b) => a.get("index") - b.get("index")).reduce((acc, f, idx) =>
        {
            const newIndex = idx + 1;
            f.set("index", newIndex);
            acc[newIndex] = f;
            f.setStyle(getStylesForGeoRefFloorPlanIncludingTextStyles(f));
            return acc;
        }, {});

    }
    else
    {
        delete markedMapFeaturesHash[getIndexOfFeature(featureToDelete)];
        const updatedMapFeatures = Object.values(markedMapFeaturesHash);
        if (isGeoReferenced)
        {
            delete markedImageFeaturesHash[getIndexOfFeature(featureToDelete)];
            markedImageFeaturesHash = Object.values(markedImageFeaturesHash).sort((a, b) => a.get("index") - b.get("index")).reduce((acc, f, idx) =>
            {
                const newIndex = idx + 1;
                f.set("index", newIndex);
                acc[newIndex] = f;
                f.setStyle(getStylesForGeoRefFloorPlanIncludingTextStyles(f));
                return acc;
            }, {});
        }

        markedMapFeaturesHash = updatedMapFeatures.sort((a, b) => a.get("index") - b.get("index")).reduce((acc, f, idx) =>
        {
            const newIndex = idx + 1;
            f.set("index", newIndex);
            acc[newIndex] = f;
            f.setStyle(getStylesForGeoRefFloorPlanIncludingTextStyles(f));
            return acc;
        }, {});

    }

    geoRefEditInfo = syncFeaturesWIthTransFromAndGetGeoRefenceEditInfo({ markedImageFeaturesHash, markedMapFeaturesHash }, geoRefEditInfo);




    return {
        markedImageFeaturesHash,
        markedMapFeaturesHash,
        ...geoRefEditInfo
    };
};


/**
 * Process the modified feature and update the image and map features accordingly if the floor plan is geo referenced.
 *
 * @param {object} prevFeaturesHash - The hash containing the previous image and map features
 * @param {object} modifiedFeature - The modified feature to be processed
 * @return {object} The updated image and map features hash along with the geo reference edit information
 */
export const processModifiedFeature = (prevState, modifiedFeature) =>
{
    let { markedImageFeaturesHash, markedMapFeaturesHash, ...geoRefEditInfo } = prevState;
    const imageFeatures = Object.values(markedImageFeaturesHash);
    const mapFeatures = Object.values(markedMapFeaturesHash);
    const index = getIndexOfFeature(modifiedFeature);
    markedImageFeaturesHash = { ...markedImageFeaturesHash };
    markedMapFeaturesHash = { ...markedMapFeaturesHash };


    if (isImageFeature(modifiedFeature))
    {
        if (isTransformed(modifiedFeature))
        {
            const xyHash = {};
            const XYHash = {};
            const xy = imageFeatures.sort((f1, f2) => f1.get("index") - f2.get("index")).map((f) =>
            {
                let c = f.getGeometry().getCoordinates();
                xyHash[f.get("index")] = c;
                return c;
            });
            const XY = mapFeatures.sort((f1, f2) => f1.get("index") - f2.get("index")).map((f) =>
            {
                let C = f.getGeometry().getCoordinates();
                XYHash[f.get("index")] = C;
                return C;

            });
            transformAlgObj.setControlPoints(xy, XY);
            markedImageFeaturesHash[index].setGeometry(new Point(transformAlgObj.revers(modifiedFeature.getGeometry().getCoordinates())));
        }
        else
        {
            markedImageFeaturesHash[index].setGeometry(new Point(modifiedFeature.getGeometry().getCoordinates()));
        }
    }
    else
    {
        markedMapFeaturesHash[index].setGeometry(new Point(modifiedFeature.getGeometry().getCoordinates()));
    }
    geoRefEditInfo = syncFeaturesWIthTransFromAndGetGeoRefenceEditInfo({ markedImageFeaturesHash, markedMapFeaturesHash }, geoRefEditInfo);



    return {
        markedImageFeaturesHash,
        markedMapFeaturesHash,
        ...geoRefEditInfo
    };




};



/**
 * Function to get geo reference information.
 *
 * @param {Object} markedImageFeaturesHash - hash of image features
 * @param {Object} markedMapFeaturesHash - hash of map features
 * @return {Object} result containing geo reference floor plan information
 */
export const syncFeaturesWIthTransFromAndGetGeoRefenceEditInfo = ({ markedImageFeaturesHash, markedMapFeaturesHash, ...prevEditInfoData }) =>
{


    const imageFeatures = Object.values(markedImageFeaturesHash);
    const mapFeatures = Object.values(markedMapFeaturesHash);
    const result = { ...prevEditInfoData };
    result.geoRefFeatures = mapFeatures;
    if (doesMarkedPointsSatisfiesGeoRefCondition(imageFeatures, mapFeatures) && hasEqualImageAndMapPoints(imageFeatures, mapFeatures))
    {
        const xy = imageFeatures.sort((f1, f2) => f1.get("index") - f2.get("index")).map((f) => f.getGeometry().getCoordinates());
        const XY = mapFeatures.sort((f1, f2) => f1.get("index") - f2.get("index")).map((f) => f.getGeometry().getCoordinates());
        transformAlgObj.setControlPoints(xy, XY);
        const center = transformAlgObj.getTranslation();
        const scale = transformAlgObj.getScale();
        const rotation = transformAlgObj.getRotation();
        const tfFeatures = imageFeatures.map((f) =>
        {
            const clonedF = f.clone();
            const xy = (clonedF.getGeometry().getCoordinates());
            clonedF.setGeometry(new Point(transformAlgObj.transform(xy)));
            markAsTransformed(clonedF);
            clonedF.setStyle(getStylesForGeoRefFloorPlanIncludingTextStyles(clonedF));

            return clonedF;
        });
        result.center = center;
        result.scale = scale;
        result.rotation = rotation;
        result.geoRefFeatures = [
            ...mapFeatures,
            ...tfFeatures
        ];
    }

    return result;

};


const doesMarkedPointsSatisfiesGeoRefCondition = (imageFeatures, mapFeatures) => imageFeatures.length >= MIN_FEATURES_TO_SELECT_FOR_GEO_REFERENCE && mapFeatures.length >= MIN_FEATURES_TO_SELECT_FOR_GEO_REFERENCE;
const hasEqualImageAndMapPoints = (imageFeatures, mapFeatures) => imageFeatures.length === mapFeatures.length;

export const canGeoReferenceTheState = ({ markedImageFeaturesHash, markedMapFeaturesHash }) =>
{
    const imageFeatures = Object.values(markedImageFeaturesHash);
    const mapFeatures = Object.values(markedMapFeaturesHash);
    return doesMarkedPointsSatisfiesGeoRefCondition(imageFeatures, mapFeatures) && hasEqualImageAndMapPoints(imageFeatures, mapFeatures);
};



/**
 * Creates a floor plan layer with the given floor plan URL, center, rotation, scale, and opacity.
 *
 * @param {object} options - The options object containing floorPlanUrl, center, rotation, scale, and opacity.
 * @return {GeoImageLayer} The created floor plan layer.
 */
export const createFloorPlanLayer = ({ floorPlanUrl, center, rotation, scale, opacity = .7 }) =>
{
    const imageSource = new GeoImageSrc({
        url: floorPlanUrl,
        imageCenter: center,
        imageRotate: rotation,
        imageScale: scale,
    });
    const floorPlanLayer = new GeoImageLayer({
        id: MAP_LAYERS.floor_plan_layer,
        name: MAP_LAYERS.floor_plan_layer,
        opacity: opacity,
        source: imageSource,
    });
    //imageSource.setCrop();
    return floorPlanLayer;
};


export const isDeleteActionTriggedForFloorPlanPoint = (mapBrowserEvent) => altKeyOnly(mapBrowserEvent) && (click(mapBrowserEvent) || singleClick(mapBrowserEvent));

export const addFeaturesToVectorLayer = (layer, features = []) =>
{

    if (!layer) return;
    layer.getSource().clear();
    layer.getSource().addFeatures(features);

    return layer;


};

export const convertStateDataToLocalGeoJson = (stateData) =>
{
    const preparedData = { ...stateData };
    const { markedImageFeaturesHash, markedMapFeaturesHash, geoRefFeatures } = preparedData;

    if (!markedImageFeaturesHash || !markedMapFeaturesHash) return;


    const imageFeatureGeometries = Object.values(markedImageFeaturesHash).sort((a, b) => a.get("index") - b.get("index")).map((f) =>
    {
        const cf = f.clone();
        const cg = cf.getGeometry();
        cg.setCoordinates(mercatorToWgs84(cg.getCoordinates(), cg.getType()));
        return cg;
    });
    const mapFeatureGeometries = Object.values(markedMapFeaturesHash).sort((a, b) => a.get("index") - b.get("index")).map((f) =>
    {
        const cf = f.clone();
        const cg = cf.getGeometry();
        cg.setCoordinates(mercatorToWgs84(cg.getCoordinates(), cg.getType()));
        return cg;
    });
    const goeJson = new GeoJSON();
    const markedGoeJsonImageFeatures = goeJson.writeGeometryObject(new GeometryCollection(imageFeatureGeometries));
    const markedGoeJsonMapFeatures = goeJson.writeGeometryObject(new GeometryCollection(mapFeatureGeometries));
    preparedData.markedImagePoints = markedGoeJsonImageFeatures;
    preparedData.markedGeoRefPoints = markedGoeJsonMapFeatures;
    preparedData.geoRefGeoJsonFeatures = JSON.parse(goeJson.writeFeatures(geoRefFeatures));
    preparedData.center = {
        type: ShapeTypes.POINT,
        coordinates: mercatorToWgs84(preparedData.center, ShapeTypes.POINT),
    };

    delete preparedData.markedImageFeaturesHash;
    delete preparedData.markedMapFeaturesHash;
    delete preparedData.geoRefFeatures;
    return preparedData;
};

export const convertLocalSavedGeoJsonToState = (data, skipSettingActiveTab) =>
{
    if (!data) return;
    const d = (data);
    const {
        markedImagePoints,
        markedGeoRefPoints,
        geoRefGeoJsonFeatures,
        center,
        ...rest
    } = d;
    const markedImageFeaturesHash = markedImagePoints.geometries.reduce((acc, s, index) =>
    {
        const f = new Feature({
            geometry: createEntityGeometry({
                type: s.type,
                coordinates: s.coordinates,
            }),
        });

        markAsImageFeature(f);
        setIndex(f, index + 1);
        f.setStyle(getStylesForGeoRefFloorPlanIncludingTextStyles(f));
        acc[getIndexOfFeature(f)] = f;
        return acc;
    }, {});


    const markedMapFeaturesHash = markedGeoRefPoints.geometries.reduce((acc, s, index) =>
    {
        const f = new Feature({
            geometry: createEntityGeometry({
                type: s.type,
                coordinates: s.coordinates,
            }),
        });
        markAsGeoRef(f);
        setIndex(f, index + 1);
        f.setStyle(getStylesForGeoRefFloorPlanIncludingTextStyles(f));
        acc[getIndexOfFeature(f)] = f;
        return acc;
    }, {});

    const geoJson = new GeoJSON();

    rest.markedImageFeaturesHash = markedImageFeaturesHash;
    rest.markedMapFeaturesHash = markedMapFeaturesHash;
    rest.geoRefFeatures = geoJson.readFeatures(geoRefGeoJsonFeatures).map((f) =>
    {
        f.setStyle(getStylesForGeoRefFloorPlanIncludingTextStyles(f));
        return f;
    });

    rest.center = wgs84ToMercator(center.coordinates, center.type);

    if (skipSettingActiveTab)
    {
        rest.activeTab = "";
    }

    return rest;
};

export const convertStepDataToBackendFormat = (data) =>
{
    const d = (data);
    const {
        markedImagePoints,
        markedGeoRefPoints,
        center,
        opacity,
        rotation,
        scale,
        selectedFloorPlanImageId: floorPlanImage,
    } = d;

    const dataToCommit = {
        markedImagePoints,
        markedGeoRefPoints,
        center,
        opacity,
        rotation,
        scale,
        floorPlanImage
    };

    return dataToCommit;
};

export const convertBackendSavedFloorPlanGoeRefToState = (data) =>
{
    const savedData = (data);
    if (!savedData) return defaultGeoReferencingFloorPlan;
    const {
        markedImagePoints,
        markedGeoRefPoints,
        floorPlanImage,
        center,
    } = savedData;

    const markedImageFeaturesHash = markedImagePoints.geometries.reduce((acc, s, index) =>
    {
        const f = new Feature({
            geometry: createEntityGeometry({
                type: s.type,
                coordinates: s.coordinates,
            }),
        });

        markAsImageFeature(f);
        setIndex(f, index + 1);
        f.setStyle(getStylesForGeoRefFloorPlanIncludingTextStyles(f));
        acc[getIndexOfFeature(f)] = f;

        return acc;
    }, {});


    const markedMapFeaturesHash = markedGeoRefPoints.geometries.reduce((acc, s, index) =>
    {
        const f = new Feature({
            geometry: createEntityGeometry({
                type: s.type,
                coordinates: s.coordinates,
            }),
        });
        markAsGeoRef(f);
        setIndex(f, index + 1);
        f.setStyle(getStylesForGeoRefFloorPlanIncludingTextStyles(f));
        acc[getIndexOfFeature(f)] = f;
        return acc;
    }, {});

    const selectedFloorPlanImageId = floorPlanImage;
    const geoRefEditInfo = syncFeaturesWIthTransFromAndGetGeoRefenceEditInfo({ markedImageFeaturesHash, markedMapFeaturesHash, });



    return {
        ...defaultGeoReferencingFloorPlan,
        center: wgs84ToMercator(center.coordinates, center.type),
        rotation: savedData.rotation,
        scale: savedData.scale,
        opacity: savedData.opacity,
        selectedFloorPlanImageId,
        markedImageFeaturesHash,
        markedMapFeaturesHash,
        geoRefFeatures: geoRefEditInfo.geoRefFeatures,
        _id: savedData?._id ?? ObjectId().toString()

    };
};




export const getActiveFloorPlanDataFromEditorState = (stateData) =>
{
    const { properties, propertyId: activePropertyCmsId, buildingId: activeBuildingCMSId, floorId: activeFloorCMSId } = stateData;
    if (!properties || !activePropertyCmsId)
    {
        //console.info("no active property to process saved floor Plan...");
    }
    let floorPlanGeoReferences;

    if (activePropertyCmsId && activeBuildingCMSId && activeFloorCMSId)
    {
        //console.info("found floor plan for propertyId: ", activePropertyCmsId, " buildingId: ", activeBuildingCMSId, " floorId: ", activeFloorCMSId);
        floorPlanGeoReferences = properties?.[activePropertyCmsId]?.buildings?.[activeBuildingCMSId]?.floors?.find((f) => f._id === activeFloorCMSId)?.floorPlanGeoReferences ?? [];
    }
    else if (activePropertyCmsId && !activeBuildingCMSId)
    {
        //console.info("found floor plan for propertyId: ", activePropertyCmsId);
        floorPlanGeoReferences = properties?.[activePropertyCmsId]?.floorPlanGeoReferences ?? [];
    }

    // either we have a floor id selected or propertyId select will return a default data or data that been saved on db...
    if (Array.isArray(floorPlanGeoReferences))
    {
        return convertBackendSavedFloorPlanGoeRefToState(floorPlanGeoReferences[0]);
    }

    return;

};


export function getPolygonFeaturesFromImage(data, isSource)
{
    var source = isSource ? data : data.getSource();
    if (!source) return [];
    var center = source.getCenter();
    var scale = source.getScale();
    var width = source.getGeoImage().width * scale[0];
    var height = source.getGeoImage().height * scale[1];
    var p1 = [center[0] - width / 2, center[1] - height / 2];
    var p2 = [center[0] + width / 2, center[1] + height / 2];
    var extent = boundingExtent([p1, p2]);
    var polygon = PolygonFromExtent(extent);
    // The resulting polygon
    polygon.rotate(-source.getRotation(), center);
    const f = new Feature({
        geometry: polygon,
    });
    //adding transparent polygon styles for our polygon feature
    f.setStyle(floorPlanImagePolygonStyle);
    return [f];
}


export const convertMeterScaleValueToPixelScaleValue = (scale, resolution) => scale / resolution;


export const cloneImageSrc = (imageSrc) => new GeoImageSrc({
    image: imageSrc.getGeoImage(),
    imageCenter: imageSrc.getCenter(),
    imageScale: imageSrc.getScale(),
    imageRotate: imageSrc.getRotation(),


});


export function getPolygonScaleXY(polygonFeature, initialExtent = null)
{
    const polygon = polygonFeature.getGeometry();

    // Get the current extent of the polygon
    const currentExtent = polygon.getExtent();

    // If an initial extent is provided, use it for comparison
    let initialWidth, initialHeight;
    if (initialExtent)
    {
        initialWidth = initialExtent[2] - initialExtent[0];
        initialHeight = initialExtent[3] - initialExtent[1];
    }
    else
    {
        // If no initial extent, assume no scaling initially
        initialWidth = currentExtent[2] - currentExtent[0];
        initialHeight = currentExtent[3] - currentExtent[1];
    }

    const scaleX = (currentExtent[2] - currentExtent[0]) / initialWidth;
    const scaleY = (currentExtent[3] - currentExtent[1]) / initialHeight;

    return [scaleX, scaleY];
}


export const getActiveToolFromStepData = (stepData) => stepData?.selectedTool;

//adding transparent polygon styles for our polygon feature
export const floorPlanImagePolygonStyle = new Style({
    stroke: new Stroke({
        color: "rgba(250,250,250,.1)",
        width: 1,
    }),
    fill: new Fill({
        color: "rgba(250,250,250,.1)",
    }),
});


export const geoRefFeaturesRenderOrder = (f1, f2) => isImageFeature(f1) ? -1 : 1;




/**
 * Converts an inverse cosine radius to degrees.
 *
 * @param {number} icr - The inverse cosine radius to be converted.
 * @return {number} The angle in degrees.
 */
export const convertInverseCosineRadiusToDegree = (icr) =>
{
    let angle = (icr * 180) / Math.PI;
    while (angle < 0)
    {
        angle += 360;
    }
    // Ensure angle is between 0 and 360
    while (angle >= 360)
    {
        angle -= 360;
    }

    return angle;
};


export const convertDegreesTotInverseCosineRadius = (degree) => (degree * Math.PI) / 180;
