import React from "react";
import { Label } from "semantic-ui-react";
import { ButtonBasic, ButtonIcon } from "./ButtonIcon";

export const TagLabel = ({content}) =>
{
    return (
        <Label className="tagLabel">
            {content}
            <ButtonIcon icon="cross-bold" />
        </Label>
    );
}

export const TagLabelShare = ({content}) =>
{
    return (
        <Label className="tagLabelShare">
            {content}
            <ButtonBasic icon="cross-red-narrow" />
        </Label>
    );
}