import React from "react";
import { Button } from "semantic-ui-react";
import { ButtonIcon } from "../../../common/ButtonIcon";
import { ModalBox } from "../../../common/ModalBox";

import "./DynamicMapLayerSidebar.css";
import { useTranslation } from "react-i18next";

const DeleteConfirmationModal = ({ message, onDeleteClick, deleteModalOpen, onDeleteIconClick, onClose }) => 
{
    const trans = useTranslation().t;
    return (
        <ModalBox className="confirmActionModal"
            trigger={<ButtonIcon icon="delete" onClick={onDeleteIconClick}/>}
            header={trans("Cancel_Confirmation_Modal.Confirm_Action")}
            open={deleteModalOpen}
            onClose={onClose}
            actions={<Button color="orange" floated="right" content={trans("Cancel_Confirmation_Modal.Delete")} onClick={onDeleteClick} />}>
            <p className="p-modal">
                {message}
            </p>
        </ModalBox>);
};

export default DeleteConfirmationModal;