import React, { useCallback, useContext } from 'react';
import BrandingContext from '../../../../store/BrandingContext';
import { roundWithPrecision } from "mapsted.utils/numbers";
import "./BoundaryPolygonArea.css";

/**
 * Renders the active boundary polygon area.
 * i.e
 * if only property is active finds the area for that
 * if building is active finds the area
 * if any floor is active finds the are of floor
 *
 * @return {JSX.Element} The rendered area in square feet.
 */
const ActiveBoundaryPolygonArea = () =>
{
    const { state: { boundaryPolygonAreaInSqMt } } = useContext(BrandingContext);
    const renderArea = useCallback(() =>
    {
        if (boundaryPolygonAreaInSqMt)
        {

            const sqFeet = roundWithPrecision(boundaryPolygonAreaInSqMt * 10.764, 2);
            return <div className='active-map-square-foot'> Area : {sqFeet} sq. ft</div>;
        }

        return null;;
    }, [boundaryPolygonAreaInSqMt]);

    return <>{renderArea()}</>;
};

export default ActiveBoundaryPolygonArea;
