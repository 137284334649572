import CategoryItem from "../../../../common/categoryItem/CategoryItem";
import "./CategorySection.css";
import CategoryUses from "../../categoryUses/categoryUses";
import { CATEGORY_EDIT_TYPE } from "../../../../../_constants/branding";
import { EditCategoryModal } from "../../../categoriesPortal/EditCategoryModal";
import { ButtonIcon } from "../../../../common/ButtonIcon";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { CategoryContext } from "../../../../../store/CategoryProvider";
import CopyOrMoveCategory from "../../copyOrMoveCategory/CopyOrMoveCategory";
import { DEFAULT_LANGUAGE_CODE } from "../../../../../_constants/constants";
import { TextGroup } from "../../../../common/TextGroup";
import { Icon, Input } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { debounce } from "../../../../../_utils/utils";
import Scrollbars from "react-custom-scrollbars-2";
import { isEmpty } from "lodash";

const CategorySection = ({ group, lang }) => 
{
    const trans = useTranslation().t;
    const [copyCategory, setCopyCategory] = useState(null);
    const { categoryFlatMap, getIconInfo } = useContext(CategoryContext);
    const [inputValue, setInputValue] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [categoryTree, setCategoryTree] = useState({});

    const highlightText = (text, term) => 
    {
        if (!term) return text;
        
        // Create a regex to match the search term, case-insensitive
        const regex = new RegExp(`(${term})`, "gi");
    
        // Split the text by the regex match and wrap the matched term with a span
        const parts = text?.split(regex);
    
        return parts?.map((part, index) => regex.test(part) ? (
            <span key={index} style={{ color: "#757fff" }}>
                {part}
            </span>
        ) : (
            part
        )
        );
    };

    useEffect(() => 
    {
        initializeCategoryTree();
    }, [group?._id, categoryFlatMap]);

    const initializeCategoryTree = () => 
    {
        let categoryTree = {};
           
        // Get categories of group
        const categories = getCategoriesFromGroup(group, categoryFlatMap);
        categoryTree = categories;
      
        setCategoryTree({ ...categoryTree });
    };

    const getCategoriesFromGroup = (group, categoryFlatMap) => 
    {
        let categories = {};
        (group?.subCategories || []).forEach((category) => 
        {
            const categoryDetails = categoryFlatMap[category];
        
            if (categoryDetails) 
            {
        
                categories[categoryDetails._id] = {
                    checked: false,
                    indeterminate: false,
                    name: categoryDetails.name,
                    _id: categoryDetails._id,
                    keywords: categoryDetails.keywords,
                    parentCategories: categoryDetails.parentCategories,
                    subCategories: categoryDetails.subCategories
                };
        
                         
                // Get sub categories of category
                const subCategories = getSubcategoriesFromCategory(categoryDetails, categoryFlatMap);
        
                categories[categoryDetails._id].subCategories = subCategories;
                        
            }
        });
        return categories;
    };
        
    const getSubcategoriesFromCategory = (categoryDetails, categoryFlatMap) => 
    {
        let subCategories = {};
        categoryDetails.subCategories.forEach((subCategory) => 
        {
            const subCategoryDetails = categoryFlatMap[subCategory];
        
            if (subCategoryDetails) 
            {
                subCategories[subCategoryDetails._id] = {
                    checked: false,
                    indeterminate: false,
                    _id: subCategoryDetails._id,
                    name: subCategoryDetails.name,
                    keywords: subCategoryDetails.keywords,
                    parentCategories: subCategoryDetails.parentCategories,
                    isSubCategory: true,
                    subCategories: subCategoryDetails.subCategories
                };
            }
        });
        return subCategories;
    };

    const searchCategories = (queryString, categoriesTree) => 
    {
        if (!queryString || !categoriesTree) return categoriesTree;
    
        const regex = new RegExp(queryString, "i");
    
        const result = Object.fromEntries(Object.entries(categoriesTree)
            .map(([key, category]) => 
            {
                let newCategory = null;
                const subCat = Object.entries(category?.subCategories || {}).filter(([, subCategories]) => subCategories && regex.test(subCategories.name?.[lang] || subCategories.name?.[DEFAULT_LANGUAGE_CODE]));

                if (subCat.length > 0) 
                {
                    newCategory = {
                        ...category,
                        expanded: true,
                        subCategories: Object.fromEntries(subCat)
                    };
                }
                else if (regex.test(category.name?.[lang] || category.name?.[DEFAULT_LANGUAGE_CODE])) 
                {
                    newCategory = {
                        ...category,
                        subCategories: {}
                    };
                }

                return [key, newCategory];
            }).filter(([_, cat]) => cat !== null));
    
        return result;
    };

    const handleCategorySearchChange = useCallback(
        debounce((value) => 
        {
            setSearchTerm(value);
        }, 500),
        []
    );
    
    const filteredCategories = searchCategories(searchTerm, categoryTree);
    
    return <>
        <TextGroup>
            <Input
                icon={searchTerm ? <ButtonIcon className="categorySectionSearchIcon" icon="cross-dark" onClick={() => 
                {
                    setInputValue(""); 
                    handleCategorySearchChange("");
                }} /> : <Icon name="search" />}
                iconPosition="right"
                className={"categorySection-search manageCategory-CopyCatSearch"}
                placeholder={trans("ExportGroups.Search_Category")}
                value={inputValue}
                onChange={(e) => 
                {
                    const value = e.target.value;
                    setInputValue(value);
                    handleCategorySearchChange(value);
                }
                }
            />
        </TextGroup>
        {
            isEmpty(filteredCategories) ? <div className="NoCategorySearchResult">{trans("manageCategory.searchResultNotFound")} <strong style={{ marginLeft: 5 }}>{searchTerm}</strong></div> : <Scrollbars className="categoryTree-Scrollbar" autoHeight autoHeightMin={"350px"} autoHeightMax={"520px"}>
                {
                    Object.keys(filteredCategories || []).map((categoryId, index) => 
                    {
                        const categoryDetails = filteredCategories[categoryId];
                        return <CategoryItem 
                            key={categoryId || index} 
                            iconInfo={getIconInfo(categoryFlatMap?.[categoryId])}
                            content={highlightText(categoryDetails.name?.[lang] || categoryDetails.name?.[DEFAULT_LANGUAGE_CODE], searchTerm)}
                            expanded={categoryDetails.expanded}
                            sideComponents={
                                <div className="categorySectionItem">
                                    <ButtonIcon icon="move" className="category-icons visibleOnlyOnHover" onClick={() => setCopyCategory({ ...categoryFlatMap[categoryId], move: true })}/>
                                    <ButtonIcon icon="copy" className="category-icons visibleOnlyOnHover" onClick={() => setCopyCategory({ ...categoryFlatMap[categoryId], })} />

                                    <EditCategoryModal
                                        editConfig={CATEGORY_EDIT_TYPE.CATEGORY}
                                        category={categoryFlatMap[categoryId]}
                                        groupId={group?._id || null}
                                        categoryId={categoryId || null}
                                        trigger={
                                            <ButtonIcon icon="edit" className="category-icons visibleOnlyOnHover" />
                                        }
                                        addDeleteIcon={true}
                                        deleteIconClass="category-icons visibleOnlyOnHover"
                                    />
                                    <CategoryUses category={categoryFlatMap?.[categoryId]} lang={lang} />
                                </div>
                            }> 
                            {
                                Object.keys(categoryDetails?.subCategories || []).map((subCategoryId, index) => 
                                {
                                    const subCategoryDetails = categoryDetails.subCategories[subCategoryId];
                                    return <CategoryItem categoryId={categoryId} key={subCategoryId || index} 
                                        iconInfo={getIconInfo(categoryFlatMap?.[subCategoryId])}    
                                        content={highlightText(subCategoryDetails.name?.[lang] || subCategoryDetails    .name?.[DEFAULT_LANGUAGE_CODE], searchTerm)}
                                        sideComponents={
                                            <div className="categorySectionItem">
                                                <ButtonIcon icon="move" className="category-icons visibleOnlyOnHover" onClick={() => setCopyCategory({ ...categoryFlatMap[subCategoryId], parentCategory: categoryDetails, move: true })}/>
                                                <ButtonIcon icon="copy" className="category-icons visibleOnlyOnHover" onClick={() => setCopyCategory({ ...categoryFlatMap[subCategoryId], parentCategory: categoryDetails })} />

                                                <EditCategoryModal
                                                    editConfig={CATEGORY_EDIT_TYPE.SUB_CATEGORY}
                                                    category={categoryFlatMap[subCategoryId]}
                                                    groupId={group?._id || null}
                                                    categoryId={categoryId || null}
                                                    trigger={
                                                        <ButtonIcon icon="edit" className="category-icons visibleOnlyOnHover" />
                                                    }
                                                    addDeleteIcon={true}
                                                    deleteIconClass="category-icons visibleOnlyOnHover"
                                                />
                                                <CategoryUses category={categoryFlatMap?.[subCategoryId]} lang={lang} />
                                            </div>
                                        } />; 
                                })
                            }
                        </CategoryItem>;
                    })
                }
            </Scrollbars>
        }
        <CopyOrMoveCategory
            openModal={copyCategory !== null}
            category={copyCategory}
            group={group}
            action={copyCategory?.move ? "move" : "copy"}
            onCloseModal={() => setCopyCategory(null)}
            lang={lang}
        />
    </>;
};

export default CategorySection;

