import React, { useContext, useMemo } from "react";
import ZoomControl from "../../components/ZoomControl";
import { PublicMapDataContext } from "../../../../store/PublicMapDataContext";
import FloorButtons from "../../components/FloorButtons";
import { DEFAULT_LANGUAGE_CODE } from "mapsted.maps/utils/map.constants";
import { useZonesContext } from "../../../../store/ZonesContext";
import { ZONE_MAP_OPTION_TYPS } from "../../../../_constants/constants";
import ZoneMapSearchBar from "./ZoneMapSearchBar";

const ZoneMapButtons = ({
    olMap,
    zoomControl = true,
    floorButtons = true,
    onHighlightShape,
}) =>
{
    const publicMapDataCtx = useContext(PublicMapDataContext);
    const { visibleZones: zones } = useZonesContext();

    const { handleFloorChange } = publicMapDataCtx;
    const { buildingId, floorId, mapData } = publicMapDataCtx.state;

    const floors = useMemo(() =>
    {
        let floors = [];

        if (mapData?.building)
        {
            const { levels } = mapData.building;

            floors = levels.map((level) => ({
                key: level.floorId,
                text: level?.shortName?.[DEFAULT_LANGUAGE_CODE] || ""
            }));
        }

        return floors;

    }, [mapData?.building]);

    const searchOptions = useMemo(() =>
    {
        let searchOptions = [];

        // add entities to search options
        if (mapData?.entities?.length)
        {
            let entities = mapData.entities;

            if (floorId)
            {
                entities = entities.filter((entity) =>  !!entity.longName 
                    && entity.floorId === floorId
                    && entity.entityId !== -1);
            }

            entities.forEach((entity) =>
            {
                searchOptions.push({
                    key: `${entity.entityId}__${ZONE_MAP_OPTION_TYPS.ENTITY}`,
                    value: `${entity.entityId}__${ZONE_MAP_OPTION_TYPS.ENTITY}`,
                    text: entity.longName || "",
                    type: ZONE_MAP_OPTION_TYPS.ENTITY,
                    description: entity.description || "",
                    entityId: entity.entityId,
                    buildingId: entity.buildingId,
                });
            });
        }


        // add zones to search options
        if (zones?.length)
        {
            zones.forEach((zone) =>
            {
                searchOptions.push({
                    key: `${zone.zoneGeofenceId}__${ZONE_MAP_OPTION_TYPS.ZONE}`,
                    value: `${zone.zoneGeofenceId}__${ZONE_MAP_OPTION_TYPS.ZONE}`,
                    text: zone.label,
                    type: ZONE_MAP_OPTION_TYPS.ZONE
                });
            });
        }

        return searchOptions;

    }, [floorId, mapData?.entities, zones]);

    const handleZoneSelect = (zoneId) =>
    {
        if (zones?.length)
        {
            const selectedZone = zones.find((zone) => zone.zoneGeofenceId === +zoneId);

            if (selectedZone)
            {
                onHighlightShape(selectedZone.boundary);
            }
        }
    };

    const handleEntitySelect = (entityId) =>
    {
        if (mapData?.entities)
        {
            const selectedEntity =  mapData.entities.filter((entity) =>  
            {
                let matched = !!entity.longName && entity.entityId !== -1;

                if (floorId)
                {
                    matched =  matched && entity.floorId === floorId;
                }

                return matched;
                
            }).find((entity) => entity.entityId === +entityId);

            if (selectedEntity)
            {
                onHighlightShape(selectedEntity.shape);
            }
        }
    };

    return (
        <div className="buttons-wrap">
            <div className="buttons-group searchMap">
                <ZoneMapSearchBar 
                    dropdownOptions={searchOptions}
                    onEntitySelect={handleEntitySelect}
                    onZoneSelect={handleZoneSelect}
                />
            </div>
            {zoomControl && <ZoomControl olMap={olMap} />}
            {(buildingId && !!floors.length && floorButtons) && <FloorButtons
                floors={floors}
                activeFloor={floorId}
                onChange={handleFloorChange}
            />}
        </div>
    );
};

export default ZoneMapButtons;