import React, { useContext } from "react";
import ZoomAndFloorSelector from "./ZoomAndFloorSelector";
import Orientation from "./Orientation";
import FocusPoint from "./FocusPoint";
import { DEFAULT_LANGUAGE_CODE } from "mapsted.maps/utils/map.constants";
import { get as getDeepValue } from "lodash";
import { SettingsContext } from "../../settings/SettingsProvider";
import { useTranslation } from "react-i18next";

const BuildingSettings = ({
    settings,
    building,
    onChangeZoomMode,
    setIsBuilding,
    buildingName,
    setZoomData,
    setRotationData,

}) => 
{

    const buildingId = building?._id;
    const buildingSettings = settings?.buildingSettings?.[buildingId];

    const {  mutate } = useContext(SettingsContext);  
    const trans = useTranslation().t;  

    const getInitialOrientation = () => 
    {
        const  getMapRotation =(settingsObj, path) => getDeepValue(settingsObj, path, undefined);
                
        const desktopPath = `mapRotation.${DEFAULT_LANGUAGE_CODE}`;
        const mobilePath = `mapRotationMobile.${DEFAULT_LANGUAGE_CODE}`;

        const mapRotation =  buildingSettings?.mapSettings?.[desktopPath] || getMapRotation(buildingSettings?.mapSettings, desktopPath);
        const mapRotationMobile = buildingSettings?.mapSettings?.[mobilePath] || getMapRotation(buildingSettings?.mapSettings, mobilePath);
       
        return { mapRotation, mapRotationMobile, building, isBuilding: true };
    };

    const getInitialBuildingFoucusPoint = () => 
    {
        const { propertyFocusPoint, propertyFocusPointMobile } = settings;        
      
        const focusPoint =  buildingSettings?.mapSettings?.focusPoint || propertyFocusPoint;                    
        const focusPointMobile = buildingSettings?.mapSettings?.focusPointMobile || propertyFocusPointMobile;  
                            
        const  { mapRotation,mapRotationMobile }= getInitialOrientation();

        return { mapRotation, mapRotationMobile, focusPoint, focusPointMobile, isBuilding: true };
    };

    const getInitialZoomAndFloorSelector = () => 
    {
        const zoomWeb = buildingSettings?.mapSettings?.zoomWeb || settings.zoomWeb;    
        const zoomMobile = buildingSettings?.mapSettings?.zoomMobile || settings.zoomMobile;

        const centerWeb = buildingSettings?.mapSettings?.centerWeb?.coordinates || settings?.centerWeb?.coordinates;
        const centerMobile = buildingSettings?.mapSettings?.centerMobile?.coordinates || settings?.centerMobile?.coordinates;
       
        const  { mapRotation,mapRotationMobile }= getInitialOrientation();

        return { mapRotation, mapRotationMobile, centerWeb, centerMobile, zoomWeb, zoomMobile, settings, building, buildingSettings, isBuilding: true };
    };


    const handleSave = (updateBuildingSettings) => 
    {      
        const updateBuilding =
        {
            ...buildingSettings.mapSettings,
            ...updateBuildingSettings, 
        }; 

        settings.buildingSettings[buildingId].mapSettings = { ...updateBuilding };   
             
        mutate({ buildingSettings: settings.buildingSettings });           
       
    };

    const handleChangeDefaultFloor = (updateBuildingSettings ) =>
    {
        settings.buildingSettings[buildingId].defaultFloorId = updateBuildingSettings.defaultFloorId;
        mutate({ buildingSettings: settings.buildingSettings });
    };  
  
    return (
        <div className="buildingSettingCover">
            <h2 className="phoneOverlayViewBox"> {trans("Settings.Building_Settings")} ( {buildingName} )</h2>

            <ZoomAndFloorSelector
                setIsBuilding={setIsBuilding}
                handleChangeDefaultFloor={handleChangeDefaultFloor}
                onChangeZoomMode={onChangeZoomMode}
                handleSave={ handleSave }
                setZoomData = { setZoomData }
                setRotationData = { setRotationData }

                {...getInitialZoomAndFloorSelector()}
            />

            <Orientation
                handleSave={handleSave}
                {...getInitialOrientation()}
            />

            <FocusPoint
                handleSave={handleSave}
                {...getInitialBuildingFoucusPoint()}        
            />

        </div>
    );
};
  
export default BuildingSettings;
  
