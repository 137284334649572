import React, { useContext } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { QueryClientProvider, QueryClient, useQuery } from "react-query";
import { HeaderBar } from "./layout/Header";
import AppContext from "../store/AppContext";
import AppProvider from "../store/AppProvider";
import AddPropertyProvider from "../store/AddPropertyProvider";
import PropertyOverview from "./pages/propertyOverview/PropertyOverview";
import Branding from "./pages/branding/Branding";
import Mapsted from "./elements/mapsted/Mapsted";
import AddProperty from "./pages/propertyOverview/AddProperty";
import AddBuilding from "./pages/propertyOverview/AddBuilding";
import OrganizeFloorPlans from "./pages/propertyOverview/OrganizeFloorPlans";
import { Error404 } from "./pages/error404/404";
import { AddFloorPlan } from "./pages/propertyOverview/AddFloorPlan";
import { FloorPlanFinishModal } from "./pages/propertyOverview/FloorPlanFinish";
import DashboardProvider from "../store/DashboardProvider";
import { WelcomeModal } from "./popups/WelcomeModal";
import { PermissionModal } from "./popups/PermissionModal";
import serverApi from "../_api/server.api";
import Dashboard from "./pages/dashboard/Dashboard";
import GlobalGroup from "./pages/globalGroup/GlobalGroup";
import ModalsPopups from "./pages/modalsPopups/ModalsPopups";
import Settings from "./pages/settings/Settings";
import BrandingProvider from "../store/BrandingProvider";
import { StoreSharingProvider } from "../store/StoreSharingProvider";
import { CategoryProvider } from "../store/CategoryProvider";
import { UTIL_QUERIES } from "../_utils/queries";
import Maintenance from "./pages/maintenance/Maintenance";
import ClearCacheButton from "./clearCacheButton";
import { LangaugeProvider } from "../store/LanguageContext";
import { Toaster } from "sonner";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Templates from "./pages/templates/Templates";
import OverlaysTemplateProvider from "../store/OverlaysTemplateContext";

// Create a client
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false
        }
    }
});

const App = () =>
{
    const context = useContext(AppContext);
    if (context.state.validatingToken || !serverApi.getConfig())
    {
        return <Mapsted />;
    }
    else if (!context.state.permission)
    {
        return (
            <PermissionModal
                onLogout={context.logout}
                onRediretToHub={context.redirectToHub}
            />
        );
    }
    else if (!context.state.termsAndConditions)
    {
        return <WelcomeModal onAccepted={context.acceptTermsAndConditions} />;
    }

    return (
        <>
            <InnerProviders>
                <Toaster position="top-center" expand visibleToasts={3} richColors theme="dark" toastOptions={{
                    style: {
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                    },
                    className: "toastContainer",
                }} />
                <HeaderBar />
                <ToastContainer limit={1} position={"top-center"} hideProgressBar={true} />
                <Switch>

                    <Redirect exact from="/" to="/dashboard" />

                    {/* Remove when done testing */}

                    <Route path="/branding" component={Branding} />
                    <Route path="/settings" component={Settings} />
                    <Route path="/maintenance" component={Maintenance} />
                    <Route path="/templates" component={Templates} />

                    <Route path="/globalGroup" component={GlobalGroup} />
                    <Route path="/modalsPopups" component={ModalsPopups} />

                    <Route path="/404" component={Error404} />
                    <DashboardProvider>
                        <Route exact path="/dashboard" component={Dashboard} />
                        <Route exact path="/propertyOverview" component={PropertyOverview} />

                        <AddPropertyProvider>
                            <Route path="/dashboard/property" component={AddProperty} />
                            <Route path="/dashboard/building" component={AddBuilding} />
                            <Route path="/dashboard/floorplans" component={AddFloorPlan} />
                            <Route
                                path="/dashboard/organize-floorplans"
                                component={OrganizeFloorPlans}
                            />
                            <Route
                                path="/dashboard/upload"
                                component={FloorPlanFinishModal}
                            />
                        </AddPropertyProvider>
                    </DashboardProvider>
                </Switch>
            </InnerProviders>

            {process.env.NODE_ENV == "development" && <ClearCacheButton />}
        </>
    );
};

const InnerProviders = ({ children }) => (
    <BrandingProvider>
        <LangaugeProvider>
            <CategoryProvider>
                <StoreSharingProvider>
                    <OverlaysTemplateProvider>
                        {children}
                    </OverlaysTemplateProvider>
                </StoreSharingProvider>
            </CategoryProvider>
        </LangaugeProvider>
    </BrandingProvider>
);

const ConfigInitializer = ({ children }) =>
{
    const { isLoading: isConfigLoading } = useQuery(UTIL_QUERIES.CONFIG());
    if (isConfigLoading)
    {
        return <Mapsted />;
    }
    return children;
};

// providers that we can add before the client is authenticated
const withOuterProviders = (App) => () => (
    <DndProvider backend={HTML5Backend}>
        <QueryClientProvider client={queryClient}>
            <Router>
                <ConfigInitializer>
                    <AppProvider>
                        <App />
                    </AppProvider>
                </ConfigInitializer>
            </Router>
        </QueryClientProvider>
    </DndProvider>
);

export default withOuterProviders(App);
