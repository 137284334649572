import { useState } from "react";

export function useCroppedImagesHistory() 
{
    const [croppedImagesHistory, setCroppedImagesHistory] = useState(null);

    function handleCropImage({ id, croppedImage, currentImageSrc }) 
    {
        setCroppedImagesHistory((prev) => 
        {
            const newPrevImages = [...(prev?.[id]?.prevImages || [])];
            const newFutureImages = [...(prev?.[id]?.futureImages || [])];
            if (!prev?.[id]?.currentImage) 
            {
                newPrevImages.push(currentImageSrc);
            }
            else 
            {
                newPrevImages.push(prev?.[id]?.currentImage);
            }

            return {
                ...prev,
                [id]: {
                    prevImages: newPrevImages,
                    currentImage: croppedImage,
                    futureImages: newFutureImages,
                },
            };
        });
    }

    function handleUndoCropImage(id) 
    {
        setCroppedImagesHistory((prev) => 
        {
            const imageHistory = prev[id];
            const newCurrentImage =
                imageHistory.prevImages[imageHistory.prevImages.length - 1];
            const newPrevImages = imageHistory.prevImages.slice(0, -1);

            return {
                ...prev,
                [id]: {
                    prevImages: newPrevImages,
                    currentImage: newCurrentImage,
                    futureImages: [
                        imageHistory.currentImage,
                        ...imageHistory.futureImages,
                    ],
                },
            };
        });
    }

    function handleRedoCropImage(id) 
    {
        setCroppedImagesHistory((prev) => 
        {
            const imageHistory = prev[id];
            const newCurrentImage = imageHistory.futureImages[0];
            const newFutureImages = imageHistory.futureImages.slice(1);

            return {
                ...prev,
                [id]: {
                    prevImages: [
                        ...imageHistory.prevImages,
                        imageHistory.currentImage,
                    ],
                    currentImage: newCurrentImage,
                    futureImages: newFutureImages,
                },
            };
        });
    }

    return {
        croppedImagesHistory,
        setCroppedImagesHistory,
        handleCropImage,
        handleUndoCropImage,
        handleRedoCropImage,
    };
}