import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Image, Header, Icon } from "semantic-ui-react";
import { ModalLayout } from "../../popups/ModalLayout";

export const CategoryInfoFirstModal = () =>
{
    /** @type {typeof import("../../../_intl/resources.json")["en"]["translation"]["settings info first modal"]} */
    const trans = useTranslation().t("settings info first modal");
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <ModalLayout
                open={open}
                onClose={handleClose}
                trigger={
                    <div style={{ position: "absolute", right: 12 }}>
                        <Icon className="infoIconCategory" name="question circle" color="blue" size="small" onClick={handleOpen}  />
                    </div>
                }
                className="categoryModal categoryInfoModal"
                heading={trans["heading"]}
                actions={<Button primary color="orange" content={trans["understood"]} onClick={handleClose} />}>
                <Header className="headingModal" content={trans["groups h2"]} />
                <Image as="span" src="/img/example-group.png" alt="" />
                <p className="p-modal">{trans["p1"]}</p>
                <Header className="headingModal hmLarge" content={trans["cats and subcats"]} />
                <Image as="span" src="/img/example-categories.png" alt="" />
                <p className="p-modal">{trans["p2"]}</p>

                <Header className="headingModal" content={trans["note"]} />
                <p className="p-modal">{trans["p3"]}</p>
            </ModalLayout>
        </>
    );
};
