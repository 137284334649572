import { CMSTopologyEntityAccess, CMSTopologyNodeConnectionAccess } from "mapsted.maps/mapFunctions/entityAccess";
import * as topojson from "topojson-client";
import { DEFAULT_ENTITY_VISABILITY, DEFAULT_MAP_TOGGLABLE_LAYERS, DEFAULT_NODE_VISABILITY, DEFAULT_TRANSITION_VISABILITY, EDIT_TYPES, ENTITIES_TOGGLABLE_LAYERS, NODES_TOGGLABLE_LAYERS, TRANSITIONS_TOGGLABLE_LAYERS } from "../_constants/mapEditor";
import * as turf from "@turf/turf";
import { createNodeLinkFeatures, getGeometryCenter } from "mapsted.maps/mapFunctions/cmsVectorLayers";
import { createEntityGeometry } from "mapsted.maps/mapFunctions/plotting";
import { ShapeTypes } from "mapsted.maps/utils/entityTypes";
import { NODE_GEOMETRY_OPTIONS } from "mapsted.maps/utils/map.constants";
import { deepCopy } from "mapsted.utils/objects";
import { Feature } from "ol";
import { LinkPriorityMultiplier } from "mapsted.maps/utils/node.constants";

export const extractNodeIdsFromNodeLinkId = (nodeLinkId) =>
{
    let nodeIds = nodeLinkId.split("-");

    return { nodeId1: nodeIds[0], nodeId2: nodeIds[1] };
}

export const createLinkedId = (linkedId1, linkedId2) =>
{
    return `${linkedId1}-${linkedId2}`;
}

export const findLastIdx = (array, compareFunction) =>
{
    if (Array.isArray(array))
    {
        for (let idx = array.length - 1; idx >= 0; idx--)
        {
            if (compareFunction(array[idx]))
            {
                return idx;
            }
        }
    }

    return -1;
}

/**
 * determines if node.nodeLinks includes a node with the given id, returns the result
 * @param {*} node 
 * @param {*} cmsNodeId 
 */
export const nodeLinksIncludesId = (node, cmsNodeId) =>
{
    let nodeLinks = node.nodeLinks;
    if (!Array.isArray(nodeLinks) || typeof cmsNodeId !== "string")
    {
        console.log("nodeLinksIncludesId failed");
        return false;
    }

    let nodeIdsArray = nodeLinks.map((nodeLink) => nodeLink.linkedNode);
    nodeIdsArray.push(node._id);

    return nodeIdsArray.includes(cmsNodeId);
}

/**
 * Returns the index of the given node id in nodeLinks array
 * @param {*} nodeLinks 
 * @param {*} cmsNodeId 
 */
export const nodeLinkIndexOfId = (nodeLinks, cmsNodeId) =>
{
    if (!Array.isArray(nodeLinks) || typeof cmsNodeId !== "string")
    {
        console.log("nodeLinkIndexOfId  failed");
        return false;
    }

    let index = -1;

    for (let i = 0; i < nodeLinks.length; i++)
    {
        let nodeLink = nodeLinks[i];

        if (nodeLink.linkedNode == cmsNodeId)
        {
            index = i;
            break;
        }
    }

    return index;
}

/**
 * Returns created nodeLink from node
 * @param {*} node 
 */
export const createNodeLink = (node) =>
{
    return {
        linkedNode: node._id, // controller should convert linked node to,
        linkedPriorityMultiplier: 0 // default is 0
    }
}

/**
 * Generates node link step data using the provided node link ID and nodes.
 *
 * @param {string} nodeLinkId - The ID of the node link.
 * @param {Array} nodes - An array of nodes.
 * @return {Object} An object containing coordinates, node link ID, and linked priority multiplier.
 */
export const createNodeLinkStepDataWithNodeLinkIdAndNodes = (nodeLinkId, nodes) =>
{
    const { nodeId1, nodeId2 } = extractNodeIdsFromNodeLinkId(nodeLinkId);
    const node1 = nodes.find((n) => n._id === nodeId1);
    const node2 = nodes.find((n) => n._id === nodeId2);

    let linkedPriorityMultiplier1, linkedPriorityMultiplier2 = LinkPriorityMultiplier.Normal;

    node1.nodeLinks.forEach((nodeLink) =>
    {
        if (nodeLink.linkedNode === nodeId2)
        {
            linkedPriorityMultiplier1 = nodeLink?.linkedPriorityMultiplier || LinkPriorityMultiplier.Normal;
        }
    });

    node2.nodeLinks.forEach((nodeLink) =>
    {
        if (nodeLink.linkedNode === nodeId1)
        {
            linkedPriorityMultiplier2 = nodeLink?.linkedPriorityMultiplier || LinkPriorityMultiplier.Normal;
        }
    });

    let shape = { type: ShapeTypes.LINE_STRING, coordinates: [node1.shape.coordinates, node2.shape.coordinates] };

    return { shape, nodeLinkId, nodeId1, nodeId2, linkedPriorityMultiplier1, linkedPriorityMultiplier2 };
}

/**
 * Creates a node link feature from the added node link step data.
 *
 * @param {Object} nodeLinkStepData - The step data containing coordinates, linked priority multiplier, and node link ID
 * @return {Array<Feature>} The created node link feature(s)
 */
export const createNodeLinkFeaturesFromAddedNodeLinkStepData = (nodeLinkStepData) =>
{
    let nodeLinkAccessor = new CMSTopologyNodeConnectionAccess(nodeLinkStepData);

    let features = createNodeLinkFeatures(nodeLinkAccessor);

    return features;
}

/**
 * @param {*} mapData - should contain topology
 * @param {*} nodeIdToEntityIdMap - optional map that will attach entity IDs to nodes if given
 * @returns  "{nodes, nodeLinksMap}"
 */
export const getNodeDataFromTopologyMapData = (mapData, nodeIdToEntityIdMap = undefined) =>
{
    let nodes = [];
    let nodeLinksMap = {};

    if (mapData && mapData.topology)
    {
        mapData.topology.objects.nodes.geometries.forEach(nodeObject =>
        {
            let nodeProperties = nodeObject.properties;
            if (!nodeProperties.isNodeConnection)
            {

                let node = nodeProperties;
                node.shape = {
                    type: nodeObject.type,
                    coordinates: nodeObject.coordinates
                }

                if (!!nodeIdToEntityIdMap)
                {
                    let entityId = nodeIdToEntityIdMap[node.nodeId] || nodeIdToEntityIdMap[node._id];
                    node.entityId = entityId;
                }

                nodes.push(node);
            }
            else
            {
                let { nodeId1, nodeId2 } = nodeProperties;
                let geoJson = topojson.feature(mapData.topology, nodeObject);
                nodeLinksMap[`${nodeId1}-${nodeId2}`] = geoJson.geometry.coordinates;
            }
        });
    }

    return { nodes, nodeLinksMap }
}

export const getEntitiesFromMapData = (mapData) =>
{
    let entities = [];

    if (mapData && mapData.topology)
    {

        const topologyEntities = mapData.topology.objects.entities.geometries;

        // entity access is just a class with reusable entity based functions, we use this to convert topology to our format
        // we can then save that format in indexeedDB and open layers
        const entityAccessArray = topologyEntities.map(entity => new CMSTopologyEntityAccess(entity, mapData.topology));

        entities = entityAccessArray.map(EA => EA.getEntityInfo());
    }

    return entities;
}

/**
 * Sets plotted layer visability depending on boolean values in map
 * @param {*} plottedLayers 
 * @param {*} mapLayersVisablityMap 
 * @returns 
 */
export const setLayerVisability = (plottedLayers, mapLayersVisablityMap) =>
{
    if (!plottedLayers)
    {
        return;
    }

    Object.keys(mapLayersVisablityMap).forEach((layerId) =>
    {
        let plottedLayer = plottedLayers[layerId];

        if (!plottedLayer)
        {
            return;
        }

        plottedLayer.setVisible(mapLayersVisablityMap[layerId]);
    });
}
/**
 * Returns a map of entity layers to display as visible/togglable depending on edit type
 * @param {*} editType 
 * @returns defaultLayers
 */
export const getDefaultMapLayerVisability = (editType) =>
{
    let defaultLayers;
    let layerVisabilityMap = {};
    switch (editType)
    {
        case (EDIT_TYPES.NODES):
            {
                layerVisabilityMap = DEFAULT_NODE_VISABILITY;
                break;
            }
        case (EDIT_TYPES.ENTITIES):
            {
                layerVisabilityMap = DEFAULT_ENTITY_VISABILITY;
                break;
            }
        case (EDIT_TYPES.TRANSITIONS):
            {
                layerVisabilityMap = DEFAULT_TRANSITION_VISABILITY;
                break;
            }
        default:
            {
                defaultLayers = DEFAULT_MAP_TOGGLABLE_LAYERS;
                defaultLayers.forEach(layer => layerVisabilityMap[layer] = true);
            }
    }



    return layerVisabilityMap;
}

/**
 * Takes coordinates, using turf libraries returns true or false if coordinates contain an intersection
 * @param {Array} coordinates 
 */
export const doesCoordinatesContainAnIntersection = (coordinates) =>
{
    // check for self intersection
    let turfPolyline = turf.lineString(coordinates);
    let kinks = turf.kinks(turfPolyline);

    //if kink exist return true
    if (kinks.features.length > 0)
    {
        return true;
    }

    return false;
}

/**
 * Takes two coordinates, returns true or false if booth coordinates are equal;
 * @param {Array} coord1 
 * @param {Array} coord2 
 * @returns 
 */
export const areCoordinatesEqual = (coord1, coord2) =>
{
    if (coord1[0] == coord2[0] && coord1[1] == coord2[1])
    {
        return true;
    }

    return false;
}

/**
 * Updates text feature geomtry with attachted features geometry
 * @param {*} textFeature - ol text feature
 * @param {*} attachedFeature - ol feature
 */
export const updateTextFeatureLocation = (textFeature, attachedFeature) =>
{
    let textStyle = textFeature.getStyle();
    let centerPoint = getGeometryCenter(attachedFeature.getGeometry());
    const entityTextGeometry = createEntityGeometry({ type: "TextPoint", coordinates: centerPoint });
    textStyle.setGeometry(entityTextGeometry);
    textFeature.setStyle(textStyle);
}

/**
 * Sets text feature location to 0 to temporally "hide" text feature while editing or for similar use cases.
 * @param {*} textFeature 
 */
export const setTextFeatureLocationToZero = (textFeature) =>
{
    let textStyle = textFeature.getStyle();
    const zeroCord = createEntityGeometry({ type: "TextPoint", coordinates: [0, 0] });
    textStyle.setGeometry(zeroCord);
    textFeature.setStyle(textStyle);
}

/**
 * Creates a new node geometry based on the given node.
 *
 * @param {Object} node - The node object to create the geometry from
 * @return {Object} The newly created node geometry
 */
export const createNewNodeGeometry = (node) =>
{
    let shape = { ...node.shape };
    shape.type = ShapeTypes.CIRCLE;
    const nodeGeometry = createEntityGeometry(shape, NODE_GEOMETRY_OPTIONS);

    return nodeGeometry;
}

/**
 * Creates a template from the given entity. Removing all meta data
 *
 * @param {Object} entity - the entity to create a template from
 * @return {Object} the created entity template
 */
export const createTemplateFromEntity = (entity) =>
{
    let entityTemplate = deepCopy(entity);
    entityTemplate.entityLabel = undefined;
    entityTemplate.entityId = -1;
    entityTemplate.textRotation = undefined;
    entityTemplate.openingHours = undefined;
    entityTemplate._id = undefined;
    entityTemplate.nodeIds = undefined;
    entityTemplate.polygonId = undefined;
    entityTemplate.imageRotation = undefined;
    entityTemplate.imageExtent = undefined;

    return entityTemplate;
}