import React, { createContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { PUBLIC_QUERIES } from "../_utils/queries";

export const LanguageContext = createContext({});

export const LangaugeProvider = ({ children }) =>
{
    const [languages, setLangauges] = useState({
        en: {
            title: "English",
            flag: "uk",
            value: "en"
        } 
    });

    const { data: globalSettings } = useQuery(PUBLIC_QUERIES.GLOBAL_SETTINGS());

    useEffect(() =>
    {
        if (globalSettings?.languages)
        {
            let languages = {};
            Object.entries(globalSettings.languages).forEach(([key, value]) => 
            {
                languages[key] = {
                    ...value,
                    value: key,
                    customFlag: !value.flag
                };
            });
            setLangauges(languages);
        }
    }, [globalSettings]);

    const value = {
        languages
    };

    return (
        <LanguageContext.Provider value={value}>
            {children}
        </LanguageContext.Provider>
    );
};