import React from "react";
import { Modal, Button } from "semantic-ui-react";

export const ModalLayout = ({
    topAligned = false,
    scrolling = false,
    goBack = false,
    heading,
    actions,
    children,
    onGoBack,
    onClose,
    errors,
    ...rest
}) => (
    <Modal
        centered={!topAligned}
        closeIcon={<Button className="close" onClick={onClose} />}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        onClose={onClose}
        {...rest}
    >
        {(heading) && (
            <div className="aligner">
                <Modal.Header className="modalLayoutHeading">
                    {(goBack) && (
                        <Button className="goBack" onClick={onGoBack}>
                            <img src="/img/icon-arrow-cms.svg" alt="/" />
                        </Button>
                    )}
                    {heading}
                </Modal.Header>
            </div>
        )}
        <Modal.Content scrolling={scrolling}>
            {children}
        </Modal.Content>
        {(errors) && <Modal.Content> {errors} </Modal.Content>}
        {(actions) && <Modal.Actions> {actions} </Modal.Actions>}
    </Modal>
);
