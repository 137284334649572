import React from "react";
import { Dropdown } from "semantic-ui-react";

export const MapModalContainer = ({ className, children }) =>
{
    return (
        <div className={className ? className : "transitions-box"}>
            {children}
        </div>
    );
}

export const MapModalCheckBoxContainer = ({ children }) =>
{
    return (
        <div className="transitionCheckboxGroup">
            {children}
        </div>
    )
}

export const MapModalDropdown = ({ options, name, value, onChange }) =>
{
    return (
        <Dropdown
            name={name}
            options={options}
            value={value}
            onChange={onChange} />
    )
}

export const MapModalActionButtonsContainer = ({ children }) =>
{
    return (
        <div className="transition-box-actions">
            {children}
        </div>
    )
}

export const MapModalTitle = ({ title }) =>
{
    return (
        <div className="transition-box-title">
            {title}
        </div>
    )
}