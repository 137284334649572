
import React from "react";
import { Button } from "semantic-ui-react";

function EditEntityButton({ data, onEditClick }) 
{
    return (
        <Button onClick={() => onEditClick &&  onEditClick(data) }  className="buttonIcon entityEditButton" >
            <img src={"/img/icon-edit.svg"} alt="" />
        </Button>      
    );
}

export default EditEntityButton;