import cloneDeep from "lodash.clonedeep";
import { styleClassic, styleDark } from "mapsted.maps/utils/defualtStyles";
import { DEFAULT_LANGUAGE_CODE } from "mapsted.maps/utils/map.constants";
import { 
    BUILDING_STYLES_ENTITY_CLASSIFICATIONS, 
    PROPERTY_LOCATION_TYPE, 
    PROPERTY_STYLES_ENTITY_CLASSIFICATIONS, 
} from "../_constants/stylesAndThemesConstants";
import { 
    getFillObjFromFields, 
    getStrokeObjFromFields, 
    getTextFontSettings 
} from "mapsted.maps/mapFunctions/stylesAndThemes";
import { 
    EntityType, 
    EntityTypes, 
    NonNamableObstacleSubEntityTypes, 
    NonNamableStructureSubEntityTypes, 
    PointOfInterestType 
} from "mapsted.maps/utils/entityTypes";

export const getDefaultEntityStyle = ({
    entityType, 
    subEntityType, 
    locationType, 
    themeId, 
    lang=DEFAULT_LANGUAGE_CODE,
    dark=false,
    isNew=false,
}) =>
{
    let entityStyle = {
        target: {
            entityType,
            subEntityType,
            locationType
        },
        styleType: "entity",
        styleSettings: {
            [lang]: {
                polygon: {},
                text: {},
            }
        }
    };

    // if dark flag is set use styleDark as fallback style set
    const defaultStyles = dark ? styleDark : styleClassic;

    // add polygon styles to styleSettings
    const entityHavingPolygonStyles = getEntitiesHavingPolyonStyles(locationType);

    if (entityHavingPolygonStyles.includes(`${entityType}:${subEntityType}`))
    {
        entityStyle.styleSettings[lang].polygon = getPolygonStyles(entityType, subEntityType, defaultStyles[locationType]);
    }

    const namableEntities = getNamableEntities();

    // add text style to styleSettings
    if (namableEntities.includes(`${entityType}:${subEntityType}`))
    {
        entityStyle.styleSettings[lang].text = getTextStyle(defaultStyles[locationType]);
    }

    entityStyle.theme = themeId;
    
    // add flag to indicate that this is a new style object, which will be helpful
    // later to determine whether to create or update style (if this flag is true create new style object in db)
    entityStyle.newStyleObject = isNew;

    return entityStyle;
};

const getTextStyle = (defaultStyles) => 
{
    const defaultTextStyle = defaultStyles.default.text;

    let textStyle = {
        default: getTextStyleFields(defaultTextStyle.default),
        selected: getTextStyleFields(defaultTextStyle.default)
    };

    return textStyle;  
};

const getPolygonStyles = (entityType, subEntityType, defaultStyles) =>
{
    let polygonStyles = {
        default: {},
    };

    const defaultPolygonStyle = defaultStyles[entityType][subEntityType].polygon;

    polygonStyles.default = getPolygonStyleFields(defaultPolygonStyle.default);

    // if default classic style settings has 'selected' use that, else use the 'default' settings itself
    if (defaultPolygonStyle.selected)
    {
        polygonStyles.selected = getPolygonStyleFields(defaultPolygonStyle.selected);
    }
    else
    {
        polygonStyles.selected = cloneDeep(polygonStyles.default);
    }

    polygonStyles.vacant = cloneDeep(polygonStyles.default);

    return polygonStyles;
};

const getTextStyleFields = (entityTextStyle) =>
{
    let textStyle = {
        fill: getFillObjFromFields({
            fillColor: entityTextStyle.fill.color,
            fillOpacity: 1,
        }),
        stroke: getStrokeObjFromFields({
            strokeColor: entityTextStyle.stroke.color,
            strokeOpacity: 1,
            strokeWidth: entityTextStyle.stroke.width
        }),
        size: entityTextStyle.size,
        fontName: entityTextStyle.fontName,
        font: getTextFontSettings({ 
            fontName: entityTextStyle.fontName, 
            size: parseInt(entityTextStyle.size.replace("px", "")) 
        })
    };

    return textStyle;
};

const getPolygonStyleFields = (entityStyle) =>
{
    let polygonStyle = {
        fill: getFillObjFromFields({
            fillColor: entityStyle.fill.color,
            fillOpacity: 1,
        }),
        stroke: getStrokeObjFromFields({
            strokeColor: entityStyle.stroke.color,
            strokeOpacity: 1,
            strokeWidth: entityStyle.stroke.width
        })
    };

    return polygonStyle;
};

export const getEntityTypeNames = (locationType) => Object.values(locationType === PROPERTY_LOCATION_TYPE ? PROPERTY_STYLES_ENTITY_CLASSIFICATIONS : BUILDING_STYLES_ENTITY_CLASSIFICATIONS)
    .reduce((prevValue, currentValue) => [...prevValue, ...currentValue], [])
    .reduce((prevValue, currentValue) => ({ ...prevValue, [`${currentValue.entityType}:${currentValue.subEntityType}`]: currentValue.name }), {});


export const getEntitiesHavingPolyonStyles = (locationType) =>
{
    const entityClassification = locationType === PROPERTY_LOCATION_TYPE ? PROPERTY_STYLES_ENTITY_CLASSIFICATIONS : BUILDING_STYLES_ENTITY_CLASSIFICATIONS;

    return [...entityClassification.Polygons, ...entityClassification.Transitions]
        .map((entityTypes) => `${entityTypes.entityType}:${entityTypes.subEntityType}`);
};

export const getNamableEntities = () =>
{
    const strunctureSubTypes = Object.keys(EntityTypes[EntityType.STRUCTURE].subTypes);

    const namableSturctureEntities = strunctureSubTypes
        .filter((subType) => !NonNamableStructureSubEntityTypes.includes(+subType))
        .map((subType) => `${EntityType.STRUCTURE}:${subType}`);

    const obstacleSubTypes = Object.keys(EntityTypes[EntityType.OBSTACLE].subTypes);
    const namableObstacleEntities = obstacleSubTypes
        .filter((subType) => !NonNamableObstacleSubEntityTypes.includes(+subType))
        .map((subType) => `${EntityType.OBSTACLE}:${subType}`);

    return [ 
        ...namableSturctureEntities, 
        ...namableObstacleEntities, 
        `${EntityType.POINT_OF_INTEREST}:${PointOfInterestType.UNKNOWN_POI}` 
    ];
};