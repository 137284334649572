import React from "react";
import { Footer } from "../../layout/Footer";
import { ExternalShareGroupModals } from "../../modalsPopups/externalShareGroupModals/ExternalShareGroupModals";
import { CopyStoreInfo } from "../../modalsPopups/copyStoreInfoModal/CopyStoreInfoModal";
import { CopyVenueInfo } from "../../modalsPopups/copyVenueInfo/CopyVenueInfo"; 

const ModalsPopups = () => (
    <>
        <div className="globalGroupContainer">
            <ExternalShareGroupModals />
            <CopyStoreInfo />
            <CopyVenueInfo />
        </div>
        <Footer />
    </>
);

export default ModalsPopups;
