import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";
import { ModalLayout } from "../../popups/ModalLayout";

/**
 * @deprecated use DeleteCategoryModal instead
 * @param {} param0
 */
export const CategoryDeleteModal = ({ editConfig, isOpen, onDelete, onCancel }) =>
{
    const { t: trans } = useTranslation();
    return (
        <ModalLayout
            open={isOpen}
            onClose={onCancel}
            className="crop-image-modal deleteGroupModal"
            heading={editConfig.title}
            actions={
                <>
                    <Button primary color="grey" content={trans("delete")} onClick={() => onDelete()} />
                </>
            }>
            <span style={{ padding: "10px", wordBreak: "break-word", whiteSpace: "pre-line" }} className="p-modal">{editConfig.body}</span><br />
        </ModalLayout>
    );
};
