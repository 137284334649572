const { Feature } = require("ol");
const { Style, } = require("ol/style");
const { getCenter } = require("ol/extent");
const { GeoJSON, } = require("ol/format");
const Geometry = require("ol/geom/Geometry");
const turf = require("@turf/turf");
const { toMercatorFromArray, getTextFeatureIdWithEntityId, isFloorPlanGeoReferenceLayer } = require("../utils/map.utils");
const { BoundaryEntityType } = require("../utils/entityTypes");
const { MapConstants, POI_IMAGE_SIZE, INDOORS_REF_TYPE, TOPOLOGY_OBJECTS, NODE_GEOMETRY_OPTIONS, EDITOR_POI_GEOMETRY_OPTIONS, NODE_LINK_POINTER_RADIUS, NODE_LINK_OFFSET, FLOOR_PLAN_LAYERS_IDS, FLOOR_PLAN_LAYERS_RENDER_ORDER, TRANSITION_BOX_RADIUS } = require("../utils/map.constants");
const { EntityType } = require("../utils/entityTypes");
const { createEntityStyle, createArrowStylesOnLineFeature, createEntityGeometry, createEntityLayers, removeFeatureFromLayer, createVectorLayer, createTextStyle, } = require("./plotting");
const { rotateImage } = require("./features");
const { default: ImageLayer } = require("ol/layer/Image");
const { default: Static } = require("ol/source/ImageStatic");
const { CMSEntityAccess, AbstractEntityAccess, CMSTopologyEntityAccess, CMSTopologyNodeConnectionAccess, CMSTopologyNodeAccess, DigitizationGeoJSONFeatureAccess } = require("./entityAccess");
const { default: VectorLayer } = require("ol/layer/Vector");
const { default: VectorSource } = require("ol/source/Vector");
const { createEntityTextStyle, createEntityAccessStyle } = require("./entityAccessFunctions");
const booleanIntersects = require("@turf/boolean-intersects").default;
const { isTransitionOnCurrentMap } = require("../utils/georeference.utils");
const { MAP_TEXT_STYLE, TRANSITION_BOUNDING_BOX_STYLE, SELECTED_MAP_TEXT_STYLE, NODE_LINK_COLORS, NODE_LINK_STYLE } = require("../utils/defualtStyles");
const { createSquareWithRadius, shrinkLineByBuffer } = require("../utils/geometry.utils");
const { createStyle } = require("./publicVectorLayers");
const { feature } = require("topojson-client");
const { createCustomTextFeature, createCustomTextStyle } = require("./plotting");
const { default: GeoImageLayer } = require("ol-ext/layer/GeoImage");
const { default: GeoImageSource } = require("ol-ext/source/GeoImage");
const topojson = require("topojson-client");

/**
 * Creates entityLayers to be drawn on the map given mapData.
 * Uses mapData from cms
 * @param {Object} mapData
 * @param {Object} mapData.entities
 * @param {Object} mapData.style
 */
exports.createEntityVectorLayers = (mapData, filerUrl, options = {}) =>
{
    if (!mapData)
    {
        return {};
    }

    const { entities, style } = mapData;

    let entityLayers = createEntityLayers(style.layerStyles);
    let imageLayers = {};

    let boundaryPolygon = findBoundaryPolygon(Object.values(entities));

    Object.values(entities).forEach((entity) =>
    {
        exports.addEntity(entity, style, entityLayers, imageLayers, options.theme, filerUrl);
    });

    //  ----------test cluster source || TODO: MOVE TO FUNCTION ----------
    // const oldSource = entityLayers[MapConstants.TEXT_LAYER].getSource();

    // const clusterSource = new ClusterSource({
    //     source: oldSource,
    //     distance: MapConstants.CLUSTER_DISTANCE,
    // });

    // entityLayers[MapConstants.TEXT_LAYER].setSource(clusterSource);

    return { entityLayers, boundaryPolygon, imageLayers };
};


/**
 * Creates entityLayers using topology to be drawn on the map given mapData.
 * Uses mapData from cms
 * @param {Object} topology - {arcs, bbox, objects}
 * @param {Object} style
 */
exports.createEntityVectorLayersTopology = ({ topology, style, filerUrl, entryExitTopology, options = {} }) =>
{
    if (!topology)
    {
        return {};
    }

    // 1. create entity layers
    const entityAccessArray = topology.objects[TOPOLOGY_OBJECTS.ENTITIES].geometries.map((entity) =>
    {
        return new CMSTopologyEntityAccess(entity, topology);
    });

    let entityLayers = createEntityLayers(style.layerStyles);

    let newEntitiesLayer = new VectorLayer(({
        source: new VectorSource()
    }));

    let imageLayers = {};

    // find boundary polygon
    let boundaryPolygon;

    // id layers
    let idLayers = {
        entityIds: new VectorLayer({
            source: new VectorSource(),
        }),
        nodeIds: new VectorLayer({
            source: new VectorSource(),
        }),
    };

    // extract entities from topology
    // we can use the the names set up in map constants to pull entities from topology object
    // check if entity is boundary polygon
    // attach feature to entity for front end functionality
    topology.objects[TOPOLOGY_OBJECTS.ENTITIES].geometries.forEach((entity) =>
    {
        let entityAccess = new CMSTopologyEntityAccess(entity, topology);

        // don't plot virtual entities
        if (entityAccess.isVirtual())
        {
            return;
        }

        exports.addEntity(entityAccess, style, entityLayers, imageLayers, options.theme, filerUrl, newEntitiesLayer, EDITOR_POI_GEOMETRY_OPTIONS);
        exports.addEntityIdFeature(entityAccess, style, idLayers);

        if (isEntityABoundary(entityAccess))
        {
            boundaryPolygon = createEntityGeometry(entityAccess.getShape());
        }
        entity.properties.feature = entityAccess.feature;
    });

    // 2. create additional layers needed for editor
    let nodeLayers = {
        nodeConnection: new VectorLayer({
            source: new VectorSource(),
            style: exports.nodeLinkStyle,
        }),
        node: new VectorLayer({
            source: new VectorSource(),
            style: createEntityStyle(style.nodeStyles.node),
        }),
        exitEntranceNode: new VectorLayer({
            source: new VectorSource(),
            style: createEntityStyle(style.nodeStyles.exitEntranceNode),
        })
    };

    let { nodeAccessArray, nodeConnectionAccessArray } = exports.extractNodeAccessArrayFromTopology(topology);

    let exitEntranceNodeAccessArray;

    if (entryExitTopology)
    {
        const entryExitAccessData = exports.extractNodeAccessArrayFromTopology(entryExitTopology.topology);
        exitEntranceNodeAccessArray = entryExitAccessData.nodeAccessArray;
    }

    exports.createNodeLayers(nodeAccessArray, nodeConnectionAccessArray, style, nodeLayers, exitEntranceNodeAccessArray);

    exports.createNodeIdsLayers(nodeAccessArray, idLayers);

    return { entityLayers, nodeLayers, newEntitiesLayer, boundaryPolygon, imageLayers, entityAccessArray, idLayers };
};

/**
 * Creates digitization topology vector layers based on the provided topology and style.
 *
 * @param {Object} topology - The input topology object
 * @param {Object} style - The style object containing layer styles
 * @return {Object} The entity layers created based on the provided topology and style
 */
exports.createDigitizationTopologyVectorLayers = ({ topology, style }) =>
{
    if (!topology)
    {
        return;
    }

    let entityLayers = createEntityLayers(style.layerStyles);

    // converts topology objects to geojson
    let geoJsonFeatures = [];

    // convert topology objects to geojson features
    Object.values(topology.objects).forEach((topologyObject) =>
    {
        const featureCollection = topojson.feature(topology, topologyObject);
        geoJsonFeatures.push(...featureCollection.features);
    });

    // create an entity for each feature
    geoJsonFeatures.forEach((feature) =>
    {
        const entityAccess = new DigitizationGeoJSONFeatureAccess(feature);

        exports.addEntity(entityAccess, style, entityLayers);

    });

    return { entityLayers };
}

/**
 *
 * @param {*} topology
 * @returns
 */
exports.extractNodeAccessArrayFromTopology = (topology) =>
{
    let nodeAccessArray = [];
    let nodeConnectionAccessArray = [];
    topology.objects[TOPOLOGY_OBJECTS.NODES].geometries.forEach((nodeObject) =>
    {
        const { properties } = nodeObject;

        if (properties.isNodeConnection)
        {
            nodeConnectionAccessArray.push(new CMSTopologyNodeConnectionAccess(nodeObject, topology));
        }
        else
        {
            nodeAccessArray.push(new CMSTopologyNodeAccess(nodeObject, topology));
        }
    });

    return { nodeAccessArray, nodeConnectionAccessArray };
};
exports.createTransitionsTextLayer = ({ transitionsData,
    navIds,
    georeferenceNavIds,
    mapData,
    georeferenceMapData,
    nodeLayer,
    georeferenceNodeLayer }) =>
{
    // create two vector layers 
    let transitionsTextLayer = createVectorLayer("transitionsTextLayer");
    let georeferenceTransitionsTextLayer = createVectorLayer("georeferenceTransitionsTextLayer");

    const { nodeAccessArray } = exports.extractNodeAccessArrayFromTopology(mapData.topology);
    const { nodeAccessArray: georeferenceNodeAccessArray } = exports.extractNodeAccessArrayFromTopology(georeferenceMapData.topology);

    let nodeIdToShapeHash = {};

    [...nodeAccessArray, ...georeferenceNodeAccessArray].forEach((nodeAccess) =>
    {
        const id = nodeAccess.getId();
        const shape = nodeAccess.getShape();

        nodeIdToShapeHash[id] = shape;
    });

    // for each transition, both nodes are in either of the two plotting levels.
    //  --> create a text feature of each node in their respected plotting layer
    // text content should include "transition ${transitionId}"

    transitionsData.forEach((transition) =>
    {
        let route = transition.route;

        // add shape object to route object
        for (let i = 0; i < route.length; i++)
        {
            // if route does not have shape object, add it from map
            if (!route[i].shape)
            {
                route[i].shape = nodeIdToShapeHash[route[i].node] || {};
            }
        }

        // check if transition is on both maps
        const { isBothRouteNodeOnMap, routeNodesOnMapArray } = isTransitionOnCurrentMap(transition, navIds, georeferenceNavIds);

        if (isBothRouteNodeOnMap)
        {
            for (let i = 0; i < route.length; i++)
            {
                const trajectoryRouteNode = route[i];

                if (routeNodesOnMapArray[i].isMain)
                {
                    const textFeature = exports.createTransitionTextFeature({ transition, trajectoryRouteNode, nodeLayer: nodeLayer });
                    if (textFeature)
                    {
                        transitionsTextLayer.getSource().addFeature(textFeature);
                    }
                }
                else
                {
                    const textFeature = exports.createTransitionTextFeature({ transition, trajectoryRouteNode, nodeLayer: georeferenceNodeLayer });
                    if (textFeature)
                    {
                        georeferenceTransitionsTextLayer.getSource().addFeature(textFeature);
                    }
                }
            }
        }
    });

    return { transitionsTextLayer, georeferenceTransitionsTextLayer };
};

/**
 * Creates a transition text feature.
 *
 * @param {Object} options - The options object.
 * @param {Object} options.transition - The transition object.
 * @param {Object} options.trajectoryRouteNode - The trajectory route node object.
 * @param {Object} options.nodeLayer - The node layer object.
 * @return {Object} The created text feature.
 */
exports.createTransitionTextFeature = ({ transition, trajectoryRouteNode, nodeLayer }) =>
{
    if (!trajectoryRouteNode.shape.coordinates)
    {
        console.log({ trajectoryRouteNode })
        return;
    }

    const squareGeometry = createSquareWithRadius(trajectoryRouteNode.shape.coordinates, TRANSITION_BOX_RADIUS);

    // create square geometry
    const textGeometry = createEntityGeometry(squareGeometry.geometry);

    let textId = transition.transitionId;

    if (textId === -1)
    {
        textId = transition._id;
    }

    // create text style
    const name = `Transition - ${textId}`;
    let textStyle = createEntityStyle(TRANSITION_BOUNDING_BOX_STYLE[transition.transitionType]);

    // hide text for now
    const text = createTextStyle("", 0, MAP_TEXT_STYLE);
    textStyle.setText(text);

    // attach node feature if node layer exists
    let connectedFeature;

    if (nodeLayer)
    {
        const nodeLayerSource = nodeLayer.getSource();
        connectedFeature = nodeLayerSource.getFeatureById(trajectoryRouteNode.node);
    }

    // create text feature
    const textFeature = new Feature({
        geometry: textGeometry,
        type: "TextPoint",
        id: trajectoryRouteNode._id,
        transitionId: transition._id,
        isText: true,
        text: name,
        connectedFeature,
    });

    textFeature.setStyle(textStyle);
    textFeature.setId(trajectoryRouteNode._id);
    return textFeature;
};

/**
 * Updates the text feature style.
 *
 * @param {Object} feature - The feature object to update the style for.
 * @param {Object} textStyleObject - The object containing the new text style properties.
 * @return {void} No return value.
 */
exports.updateTextFeatureStyle = (feature, textStyleObject) =>
{
    if (!feature)
    {
        return;
    }

    const textStyle = new Style(MAP_TEXT_STYLE);

    const oldTextStyle = feature.getStyle();
    const oldText = oldTextStyle.getText();
    const name = oldText.getText();

    const text = createTextStyle(name, 0, textStyleObject);
    textStyle.setText(text);
    feature.setStyle(textStyle);
};

/**
 * Updates the style of a feature.
 *
 * @param {Object} feature - The feature to update the style of.
 * @param {Object} styleObject - The style object to apply to the feature.
 * @return {void} This function does not return a value.
 */
exports.updateFeatureStyle = (feature, styleObject, textStyle) =>
{
    if (!feature)
    {
        return;
    }

    const style = createStyle(styleObject);

    if (textStyle)
    {
        style.setText(textStyle);
    }

    feature.setStyle(style);
};

/**
 * Returns the node text style name from the given node/transition feature.
 *
 * @param {Object} feature - The feature object.
 * @return {string} The transition name.
 */
exports.getNodeTextStyle = (feature) =>
{
    if (!feature)
    {
        return;
    }

    const name = exports.getNodeTextNameFromFeature(feature);

    const textStyle = createTextStyle(name, 0, SELECTED_MAP_TEXT_STYLE);

    return textStyle;
};

/**
 * Generate the name of a node based on a given node/transition feature.
 *
 * @param {Object} feature - The feature object.
 * @return {string} The name of the node.
 */
exports.getNodeTextNameFromFeature = (feature) =>
{
    if (!feature)
    {
        return;
    }

    let nodeId = feature.get("nodeId") || feature.get("_id");

    if (!nodeId)
    {
        const connectedNode = feature.get("connectedFeature");
        if (connectedNode)
        {
            return exports.getNodeTextNameFromFeature(connectedNode);
        }
    }

    let name = `Node-${nodeId}`;

    return name;
};

exports.createImageLayer = ({ imgId, entityShape, rotation = 0, filerUrl, extent, feature = undefined, refType }) =>
{
    if (!imgId)
    {
        return undefined;
    }

    let convertedExtent = undefined;

    if (Array.isArray(extent))
    {
        let minPoint = [extent[0], extent[1]];
        let maxPoint = [extent[2], extent[3]];

        if (minPoint[0] === maxPoint[0] && minPoint[1] === maxPoint[1])
        {
            // set converted extent to undefined if extent size is 0
            // create image source will handle the creation of the extent depending on entity shape
            convertedExtent = undefined;
        }
        else
        {
            convertedExtent = [...toMercatorFromArray(minPoint), ...toMercatorFromArray(maxPoint)];
        }
    }

    let imageSource = exports.createImageSource({ imgId, entityShape, filerUrl, extent: convertedExtent, rotation, refType });

    let imageLayer = new ImageLayer({
        source: imageSource,
        crossOrigin: 'Anonymous',
        properties: { feature , initialRotation: rotation}
    });

    return imageLayer;
};

exports.createImageSource = ({ imgId, entityShape, filerUrl, extent, rotation, refType }) =>
{
    // let imageBox = entityShape && exports.getImageBox(entityShape);
    let imgUrl = filerUrl ? `${filerUrl}${imgId}` : imgId;

    let imageExtent;
    if (extent)
    {
        imageExtent = extent;
    }
    else if (entityShape)
    {
        imageExtent = exports.getImageBox(entityShape, refType);
    }

    // Manually create canvas because images that are too small do not create a canvas.
    // We need the image to be in a canvas to transform the image (rotations, draw border, resize, etc);
    const loadImageFunction = async (image, src) =>
    {

        const canvasImg = new Image();
        canvasImg.crossOrigin = "Anonymous";

        canvasImg.onload = () =>
        {
            let canvas = rotateImage({ image: canvasImg, degree: rotation, source: imageSource });

            image.setImage(canvas);
        };

        // race condition fix
        image.getImage().onload = () =>
        {
            canvasImg.src = src;
        };

        image.getImage().src = src; // although we change image to canvas, this line is needed to register changed event. image.changed() does not seem to work.

    };

    let imageSource = new Static({
        url: imgUrl,
        imageExtent: imageExtent,
        imageLoadFunction: loadImageFunction,
        crossOrigin: 'Anonymous',
    });


    return imageSource;
};

exports.getImageBox = (entityShape, refType) =>
{
    if (entityShape.type === "Polygon")
    {
        const scale = .5;

        // scale image to a percentage of the polygon
        let polygon = turf.transformScale(entityShape, scale);

        polygon.coordinates.forEach((coordSet) =>
        {
            coordSet.forEach((coord, j) =>
            {
                coordSet[j] = toMercatorFromArray(coord);
            });
        });

        let imageBox = turf.bbox(polygon);
        let width = imageBox[2] - imageBox[0];
        let height = imageBox[3] - imageBox[1];

        if (width > height)
        {
            const diff = width - height;
            imageBox[2] = imageBox[2] - (diff / 2);
            imageBox[0] = imageBox[0] + (diff / 2);
        }
        else
        {
            const diff = height - width;
            imageBox[3] = imageBox[3] - (diff / 2);
            imageBox[1] = imageBox[1] + (diff / 2);
        }
        // let imageBox = turf.square(turf.bbox(polygon));
        return imageBox;
    }
    else if (entityShape.type === "Point")
    {
        const mercator = turf.toMercator(entityShape);
        let size = POI_IMAGE_SIZE.OUTDOOR;
        if (refType === INDOORS_REF_TYPE)
        {
            size = POI_IMAGE_SIZE.INDOOR;
        }
        const [x, y] = mercator.coordinates;
        return [x - size / 2, y - size / 2, x + size / 2, y + size / 2];
    }

    return imageBox;
};

exports.getImageBoxForAlertIcon = (entityShape) =>
{
    if (entityShape.type === "Polygon")
    {
        const scale = .25;

        // scale image to a percentage of the polygon
        let polygon = turf.transformScale(entityShape, scale);

        polygon.coordinates.forEach((coordSet) =>
        {
            coordSet.forEach((coord, j) =>
            {
                coordSet[j] = toMercatorFromArray(coord);
            });
        });

        let imageBox = turf.bbox(polygon);
        let width = imageBox[2] - imageBox[0];
        let height = imageBox[3] - imageBox[1];

        if (width > height)
        {
            const diff = width - height;
            imageBox[2] = imageBox[2] - (diff / 2);
            imageBox[0] = imageBox[0] + (diff / 2);
        }
        else
        {
            const diff = height - width;
            imageBox[3] = imageBox[3] - (diff / 2);
            imageBox[1] = imageBox[1] + (diff / 2);
        }

        return imageBox;
    }
};

exports.createEntityFeature = (entityAccess, style, createGeometryOptions) =>
{
    let entityStyle = createEntityAccessStyle(entityAccess, style);

    // Create Geometry
    const entityGeometry = createEntityGeometry(entityAccess.getShape(), createGeometryOptions);
    const entityArea = turf.area(entityAccess.getShape());

    //Create feature
    let featureId = entityAccess.getId();
    let feature = new Feature({
        geometry: entityGeometry,
        // style: entityStyle,
        id: featureId,
        canName: entityAccess.getCanNameEntity(),
        area: entityArea,
        entityType: entityAccess.getEntityType(),
        subEntityType: entityAccess.getSubEntityType(),
    });
    feature.setId(featureId);

    (entityStyle) && feature.setStyle(entityStyle);

    return feature;
};

/**
 * Create a text feature for an entity with the given access and style.
 *
 * @param {entityAccess} entityAccess - the access to the entity
 * @param {style} style - the style for the text feature
 * @param {feature} feature - the feature to which the text is connected
 * @param {Object} options - optional object with text property
 * @param {string} options.text - custom text for the text feature
 * @return {Feature} the created text feature
 */
exports.createEntityTextFeature = (entityAccess, style, feature, options = { text: undefined }) =>
{
    let entityTextStyle;

    // if custom text is set use that instead of entities text
    // this is used in the case to plot entity IDs
    if (!!options && !!options.text)
    {
        entityTextStyle = createCustomTextStyle(options.text);
    }
    else
    {
        entityTextStyle = createEntityTextStyle(entityAccess, style, options.text);
    }

    const entityGeometry = createEntityGeometry(entityAccess.getShape());
    let textCoordinate = exports.getGeometryCenter(entityGeometry);

    if (entityAccess.getTextCoordinate())
    {
        textCoordinate = toMercatorFromArray(entityAccess.getTextCoordinate());
    }

    // Create text geometry
    const entityTextGeometry = createEntityGeometry({ type: "TextPoint", coordinates: textCoordinate });

    // attach it to style
    entityTextStyle.setGeometry(entityTextGeometry);

    let textId = getTextFeatureIdWithEntityId(entityAccess.getId());

    let textFeature = new Feature({
        geometry: entityTextGeometry,
        type: "TextPoint",
        coordinates: textCoordinate,
        id: textId,
        entityId: entityAccess.getId(),
        canName: entityAccess.getCanNameEntity(),
        isText: true,
        connectedFeature: feature,
    });

    textFeature.setId(textId);

    (entityTextStyle) && textFeature.setStyle(entityTextStyle);

    return textFeature;
};

/**
 * might need a refactor to not use create entity text feature directly.
 * @param {*} entityAccess
 * @param {*} style
 * @param {*} feature
 * @returns
 */
exports.createEntityIdFeature = (entityAccess, style, feature) =>
{
    const entityIdText = entityAccess.getNavId()
        ? `${entityAccess.getNavId()}`
        : "";
    return exports.createEntityTextFeature(entityAccess, style, feature, { text: entityIdText });
};

/**
 *
 * @param {*} nodeId
 * @param {*} coordinates
 * @returns
 */
exports.createNodeIdFeature = (nodeId, coordinates) =>
{
    return createCustomTextFeature(`${nodeId}`, coordinates);
};

/**
 * @param {Geometry} geometry
 */
exports.getGeometryCenter = (geometry) =>
{
    if (!!geometry)
    {
        return getCenter(geometry.getExtent());
    }
};

/**
 * Creates a node feature with the given node access and style.
 *
 * @param {NodeAccess} nodeAccess - The node access object.
 * @param {Style} style - The style object.
 * @return {Feature} The created node feature.
 */
const createNodeFeature = (nodeAccess, style, featureOptions = {}) =>
{
    // create style
    const isNodeConnection = nodeAccess.getIsNodeConnection();
    if (isNodeConnection)
    {
        return;
    }

    // create geometry
    const nodeGeometry = createEntityGeometry(nodeAccess.getShape(), NODE_GEOMETRY_OPTIONS);

    // create feature
    let feature = new Feature({
        geometry: nodeGeometry,
        isNodeConnection: nodeAccess.getIsNodeConnection(),
        isNode: true,
        ...nodeAccess.data,
        ...featureOptions
    });

    feature.setId(nodeAccess.getId());

    // set style
    return feature;
};

/**
 * Generates node link features based on the provided node link access and style.
 * This will modify the geometry for different factors noted below
 * - if a link has two different linked priorities it will create two features both offset by the original geometry
 * - we end the node links length just before the edge of the nodes circle
 *
 * @param {NodeAccess} nodeLinkAccess -
 * @param {Object} style
 * @return {Array} array of node link feature(s)
 */
exports.createNodeLinkFeatures = (nodeLinkAccess) =>
{
    const linkedPriorityMultiplier1 = nodeLinkAccess.getLinkedPriorityMultiplier1();
    const linkedPriorityMultiplier2 = nodeLinkAccess.getLinkedPriorityMultiplier2();
    const shape = nodeLinkAccess.getShape();
    const node1Coord = shape.coordinates[0];
    const node2Coord = shape.coordinates[1];
    const linkedId1 = nodeLinkAccess.getLinkedId1();
    const linkedId2 = nodeLinkAccess.getLinkedId2();

    let nodeLinkFeatures = [];

    if (linkedPriorityMultiplier1 === linkedPriorityMultiplier2)
    {
        let link1Coords = shrinkLineByBuffer(node1Coord, node2Coord);
        let link1Shape = turf.lineString(link1Coords);
        let nodeLinkGeometry = createEntityGeometry(link1Shape.geometry);

        const pointerFeature1 = createPointerFeatureAtEndOfLine(link1Coords, {
            id: `${linkedId1}-pointer`,
            linkedPriorityMultiplier: linkedPriorityMultiplier1
        });

        const pointerFeature2 = createPointerFeatureAtEndOfLine([link1Coords[1], link1Coords[0]], {
            id: `${linkedId2}-pointer`,
            linkedPriorityMultiplier: linkedPriorityMultiplier1
        });

        let feature = new Feature({
            geometry: nodeLinkGeometry,
            isNodeConnection: true,
            isNode: true,
            linkedPriorityMultiplier: linkedPriorityMultiplier1,
            connectedPointerFeatures: [pointerFeature1, pointerFeature2],
            ...nodeLinkAccess.data
        });

        feature.setId(linkedId1);

        nodeLinkFeatures.push(pointerFeature1, pointerFeature2, feature,);
    }
    else
    {
        // check which x is to the left for the offset
        // if x is the same then use y to decide which is to the left
        let link1Coords = shrinkLineByBuffer(node1Coord, node2Coord);
        let link2Coords = shrinkLineByBuffer(node2Coord, node1Coord);
        let link1Shape = turf.lineString(link1Coords);
        let link2Shape = turf.lineString(link2Coords);

        let offset = NODE_LINK_OFFSET;
        // check if node 1 coord is to the right of node 2, or if its above node 2
        if (node1Coord[0] > node2Coord[0] || (node1Coord[0] === node2Coord[0] && node1Coord[1] > node2Coord[1]))
        {
            offset = offset * -1;
        }

        // offset the lines using turf offset
        link1Shape = turf.lineOffset(link1Shape, offset, { units: 'meters' });
        link2Shape = turf.lineOffset(link2Shape, offset, { units: 'meters' });

        let link1Geometry = createEntityGeometry(link1Shape.geometry);
        let link2Geometry = createEntityGeometry(link2Shape.geometry);

        const pointerFeature1 = createPointerFeatureAtEndOfLine(link1Shape.geometry.coordinates, {
            id: `${linkedId1}-pointer`,
            linkedPriorityMultiplier: linkedPriorityMultiplier1
        });

        let feature1 = new Feature({
            geometry: link1Geometry,
            isNodeConnection: true,
            isNode: true,
            linkedPriorityMultiplier: linkedPriorityMultiplier1,
            connectedPointerFeatures: [pointerFeature1],
            ...nodeLinkAccess.data
        });
        feature1.setId(linkedId1);

        const pointerFeature2 = createPointerFeatureAtEndOfLine(link2Shape.geometry.coordinates, {
            id: `${linkedId2}-pointer`,
            linkedPriorityMultiplier: linkedPriorityMultiplier2
        });

        let feature2 = new Feature({
            geometry: link2Geometry,
            isNodeConnection: true,
            isNode: true,
            linkedPriorityMultiplier: linkedPriorityMultiplier2,
            connectedPointerFeatures: [pointerFeature2],
            ...nodeLinkAccess.data,
            nodeId1: nodeLinkAccess.data.nodeId2,
            nodeId2: nodeLinkAccess.data.nodeId1
        });
        feature2.setId(linkedId2);

        // create and return features
        nodeLinkFeatures.push(pointerFeature1, pointerFeature2, feature1, feature2,);
        // nodeLinkFeatures.push(feature1, feature2, pointerFeature1, pointerFeature2);
    }

    return nodeLinkFeatures;
};

/**
 * Creates a pointer feature at the end of a line.
 *
 * @param {Array<Array<number>>} lineCoordinates - The coordinates of the line.
 * @param {Object} properties - The properties of the feature.
 * @return {Feature} The created pointer feature.
 */
const createPointerFeatureAtEndOfLine = (lineCoordinates, properties) =>
{
    try
    {

        // finds angle of pointer, since im creating  a sector or a disc. I will need the reverse angle so I calculate it in the opposite direction
        var bearing2 = turf.bearing(lineCoordinates[1], lineCoordinates[0]);

        // create a disk, I would like to create a triangle in the future to optimize this
        const trianglePointerGeometry = turf.sector(lineCoordinates[1], NODE_LINK_POINTER_RADIUS, bearing2 - 25, bearing2 + 25);
        const featureGeometry = createEntityGeometry(trianglePointerGeometry.geometry);

        const triangleFeature = new Feature({
            geometry: featureGeometry,
            ignoreClick: true,
            ...properties
        });

        return triangleFeature;
    }
    catch (error)
    {
        console.log(error, lineCoordinates, properties)
        return undefined;
    }
}

const createEntityImageLayer = (entityAccess, feature, theme, filerUrl) =>
{
    const isImageOnMap = entityAccess.getIsImageOnMap();
    let imgLayer = undefined;

    if (isImageOnMap)
    {
        // check if entity has image extent
        // convert to mercator
        imgLayer = exports.createImageLayer({
            imgId: entityAccess.getImage({ theme }),
            entityShape: entityAccess.getShape(),
            rotation: entityAccess.getImageRotation(),
            extent: entityAccess.getImageExtent(),
            filerUrl,
            feature
        });
    }

    return imgLayer;
};

/**
 * Adds an entity to the specified layers based on the provided entity, style, layers, theme, filer URL, and new entities layer.
 *
 * @param {any} entity - the entity to be added
 * @param {any} style - the style of the entity
 * @param {any} entityLayers - the layers for the entity
 * @param {any} imageLayers - the layers for the image
 * @param {any} theme - the theme for the entity
 * @param {any} filerUrl - the URL for the filer
 * @param {any} newEntitiesLayer - the new entities layer
 */
exports.addEntity = function addEntity(entity, style, entityLayers, imageLayers, theme, filerUrl, newEntitiesLayer, createGeometryOptions)
{
    let entityAccess;

    if (entity instanceof AbstractEntityAccess)
    {
        entityAccess = entity;
    }
    else
    {
        entityAccess = new CMSEntityAccess(entity);
    }

    const feature = exports.createEntityFeature(entityAccess, style, createGeometryOptions);
    const textFeature = exports.createEntityTextFeature(entityAccess, style, feature);


    // REMOVED.. update entity text coordinate - why is this done?
    // entity.textCoordinate = textCoordinate;

    // Add feature to the correct layer
    const styleObject = entityAccess.getStyleObject(style);

    if (styleObject && styleObject.layerIdx)
    {
        entityLayers[styleObject.layerIdx].getSource().addFeature(feature);
        entityLayers[MapConstants.TEXT_LAYER].getSource().addFeature(textFeature);
    }
    else
    {
        if (!!newEntitiesLayer)
        {
            newEntitiesLayer.getSource().addFeature(feature);
        }
        else
        {
            entityLayers[-1].getSource().addFeature(feature);
        }
    }

    if (imageLayers)
    {
        // create image layer;
        const imgLayer = createEntityImageLayer(entityAccess, feature, theme, filerUrl);

        if (imgLayer)
        {
            imageLayers[entityAccess.getId()] = imgLayer;
        }
    }



    entity.feature = feature;
    entity.textFeature = textFeature;
};

/**
 * Function to add an entity nav ID text feature to id layers.
 *
 * @param {Object} entity - the entity to add the feature to
 * @param {string} style - the style of the feature
 * @param {Array} idLayers - the ID layers for the feature
 * @return {void}
 */
exports.addEntityIdFeature = function addEntityIdFeature(entity, style, idLayers)
{
    let entityAccess;

    if (entity instanceof AbstractEntityAccess)
    {
        entityAccess = entity;
    }
    else
    {
        entityAccess = new CMSEntityAccess(entity);
    }

    if (entity.getNavId())
    {
        const textFeature = exports.createEntityIdFeature(entityAccess, style, feature);

        idLayers.entityIds.getSource().addFeature(textFeature);
    }
};

exports.createNodeLayers = function addNode(nodeAccessArray, nodeConnectionAccessArray, style, nodeLayers, exitEntranceNodeAccessArray)
{
    // create features (style and shape)

    nodeAccessArray.forEach((nodeAccess) =>
    {
        let nodeFeature = createNodeFeature(nodeAccess, style);
        nodeLayers.node.getSource().addFeature(nodeFeature);
    });

    nodeConnectionAccessArray.forEach((nodeConnectionAccess) =>
    {
        let nodeConnectionsFeatures = exports.createNodeLinkFeatures(nodeConnectionAccess, style);

        nodeConnectionsFeatures.forEach((nodeConnectionsFeature) =>
        {
            nodeLayers.nodeConnection.getSource().addFeature(nodeConnectionsFeature);
        });

    });

    if (exitEntranceNodeAccessArray)
    {
        exitEntranceNodeAccessArray.forEach((nodeAccess) =>
        {
            let nodeFeature = createNodeFeature(nodeAccess, style, { ignoreClick: true });

            nodeLayers.exitEntranceNode.getSource().addFeature(nodeFeature);
        });
    }
};

/**
 * Create node ids layers.
 *
 * @param {Array} nodeAccessArray - array of node access
 * @param {Object} idLayers - layers of node ids
 * @return {void}
 */
exports.createNodeIdsLayers = function addNodeId(nodeAccessArray, idLayers)
{
    nodeAccessArray.forEach((nodeAccess) =>
    {
        // create text feature with node id and add to idLayers.nodeIds
        const nodeId = nodeAccess.getNavId();
        if (nodeId)
        {
            if (!nodeAccess.getShape())
            {
                return;
            }

            const nodeCoordinates = nodeAccess.getShape().coordinates;
            const nodeIdTextFeature = exports.createNodeIdFeature(nodeId, nodeCoordinates);
            idLayers.nodeIds.getSource().addFeature(nodeIdTextFeature);
        }

    });
};

function isEntityABoundary(entity)
{
    let entityAccess;

    if (entity instanceof AbstractEntityAccess)
    {
        entityAccess = entity;
    }
    else
    {
        entityAccess = new CMSEntityAccess(entity);
    }



    const isBoundaryType = entityAccess.getEntityType() === EntityType.BOUNDARY;
    const isBoundarySubType = entityAccess.getSubEntityType() === BoundaryEntityType.PROPERTY_BOUNDARY
        || entityAccess.getSubEntityType() === BoundaryEntityType.FLOOR_BOUNDARY;

    if (isBoundaryType && isBoundarySubType)
    {
        return true;
    }

    return false;
}

/**
 * @param {Array} entities
 * @returns boundary polygon or undefined
 */
function findBoundaryPolygon(entities)
{
    for (const entity of entities)
    {


        if (isEntityABoundary(entity))
        {
            let entityAccess = new CMSEntityAccess(entity);
            return createEntityGeometry(entityAccess.getShape());
        }
    }
    return undefined;
}

exports.removeEntity = function removeEntity(entities, entityId, entityLayers, imageLayers, style)
{
    let _entities = { ...entities };
    let _entityLayers = { ...entityLayers };

    const entityAccess = new CMSEntityAccess(entities[entityId]);
    const previousEntity = _entities[entityAccess.getId()];
    const styleObject = entityAccess.getStyleObject(style);

    if (styleObject && styleObject.layerIdx)
    {
        removeFeatureFromLayer(_entityLayers[styleObject.layerIdx], previousEntity.feature);
        removeFeatureFromLayer(_entityLayers[MapConstants.TEXT_LAYER].getSource(), previousEntity.textFeature);
    }
    else
    {
        _entityLayers[-1].getSource().addFeature(previousEntity.feature);
    }

    delete _entities[entityAccess.getId()].textFeature;
    delete _entities[entityAccess.getId()].feature;

    const _imageLayers = exports.removeEntityImage(entityAccess.getId(), imageLayers);

    return {
        entities: _entities,
        entityLayers: _entityLayers,
        imageLayers: _imageLayers,
    };
};

exports.removeEntityImage = (entityId, imageLayers) =>
{
    const _imageLayers = { ...imageLayers };
    delete _imageLayers[entityId];
    return _imageLayers;
};

exports.refreshEntity = (entities, entity, style, entityLayers, imageLayers, theme, filerUrl) =>
{
    exports.removeEntity(entities, entity._id, entityLayers, imageLayers, style);
    exports.addEntity(entity, style, entityLayers, imageLayers, theme, filerUrl);
};

exports.checkIfIntercepts = (geometry1, geometry2) =>
{
    const geoJSON1 = convertGeometryToGeoJSON(geometry1);
    const geoJSON2 = convertGeometryToGeoJSON(geometry2);
    return booleanIntersects(geoJSON1, geoJSON2);
};

exports.nodeLinkStyle = (feature) =>
{
    if (!feature)
    {
        return;
    }

    const priority = feature.get("linkedPriorityMultiplier");

    // generate random int between -2 and 2
    let linkColor = NODE_LINK_COLORS[priority];

    let linkStyle = NODE_LINK_STYLE(linkColor);

    const lineStyle = createEntityStyle(linkStyle);
    const arrowStyles = createArrowStylesOnLineFeature(feature);

    return [lineStyle, ...arrowStyles];
};

const convertGeometryToGeoJSON = (featureGeometry) =>
{
    let format = new GeoJSON();
    return format.writeGeometryObject(featureGeometry);
};






/**===========================GEO REFERENCING FLOOR PLAN LAYER================================================**/


/**
 * Create a layer for a floor plan image.
 * @param {Object} options - The options for creating the floor plan image layer.
 * @param {string} [options.id=MapEditorConstants.FLOOR_PLAN_IMAGE_LAYER] - The id of the layer.
 * @param {number} [options.opacity=0.7] - The opacity of the layer.
 * @param {string} options.imageUrl - The url of the image.
 * @param {number} options.imageRotation - The rotation of the image in radians.
 * @param {Array<number>} options.imageCenter - The center of the image in the form [x, y].
 * @param {Array<number>} options.imageScale - The scale of the image in the form [x, y].
 * @throws {Error} Throws an error if any of the required options are missing.
 * @return {GeoImageLayer} The created GeoImageLayer.
 */
const createFloorPlanImageLayer = ({
    id = MapEditorConstants.FLOOR_PLAN_IMAGE_LAYER,
    opacity = .7,
    imageUrl,
    imageRotate,
    imageCenter = [],
    imageScale = [],
    projection,
}) =>
{

    const layer = new GeoImageLayer();
    const src = new GeoImageSource({
        projection,
        imageUrl,
        imageRotate,
        imageCenter,
        imageScale
    });

    // Set the properties of the GeoImageLayer.
    layer.set("id", id);
    layer.set("opacity", opacity);

    // Set the source of the GeoImageLayer and return the created layer.
    layer.set("source", src);
    layer.setSource(src);
    return layer;
};

exports.createFloorPlanImageLayer = createFloorPlanImageLayer;



/**
 * Updates the floor plan on the geo-referenced image layer.
 *
 * @param {Object} geoRefFloorPlanImageLayer - The geo-referenced floor plan image layer object.
 * @param {string} floorPlanImageUrl - The URL of the floor plan image.
 * @param {Object} options - An object containing center, rotation, scale, and opacity properties.
 * @param {Object} options.center - The center coordinate.
 * @param {number} options.rotation - The rotation angle.
 * @param {number} options.scale - The scale factor.
 * @param {number} options.opacity - The opacity value.
 */
exports.updateFloorPlanOnGeoRefImageLayer = (geoRefFloorPlanImageLayer, floorPlanImageUrl, { center, rotation, scale, opacity }) =>
{
    if (!geoRefFloorPlanImageLayer) return;
    geoRefFloorPlanImageLayer.set("id", FLOOR_PLAN_LAYERS_IDS.FLOOR_PLAN_GEO_REF_IMAGE_LAYER);
    const imageSource = new GeoImageSource({
        url: floorPlanImageUrl,
        imageCenter: center,
        imageRotate: rotation,
        imageScale: scale,
    });
    geoRefFloorPlanImageLayer.setSource(imageSource);
    opacity && geoRefFloorPlanImageLayer.setOpacity(opacity);
    // geoRefFloorPlanImageLayer.setZIndex(1);
    return geoRefFloorPlanImageLayer;
};


/**
 * Sets the floor plan layers on the OpenLayers map based on the provided layers hash.
 *
 * @param {ol.Map} olMap - The OpenLayers map.
 * @param {Object} layersHash - A hash of floor plan layers, where the key is the layer ID and the value is the layer.
 */
exports.setFloorPlanLayers = (olMap, layersHash) =>
{
    // Get the layer IDs and sort them based on the layer render order.
    const floorPlanLayerIds = Object.keys(layersHash).sort((a, b) => FLOOR_PLAN_LAYERS_RENDER_ORDER[a] - FLOOR_PLAN_LAYERS_RENDER_ORDER[b]);

    // Remove existing floor plan layers from the map.
    const mapLayers = olMap.getLayers().getArray();



    mapLayers && [...mapLayers].forEach((layer) =>
    {
        if (isFloorPlanGeoReferenceLayer(layer))
        {
            olMap.removeLayer(layer);
        }
    });

    // Add the sorted layers to the map.
    floorPlanLayerIds.forEach(layerId =>
    {
        const layer = layersHash[layerId];
        olMap.addLayer(layer);
    });
};
