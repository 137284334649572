import { Input } from "semantic-ui-react";
import { TextGroup } from "../TextGroup";
import { debounce } from "../../../_utils/utils";
import React, { useCallback, useContext, useEffect, useState } from "react";
import BrandingContext from "../../../store/BrandingContext";
import { useTranslation } from "react-i18next";
import Scrollbars from "react-custom-scrollbars-2";
import { CheckboxGroup } from "../CheckboxGroup";
import { DEFAULT_LANGUAGE_CODE } from "../../../_constants/constants";
import { isEmpty } from "lodash";

const PropertyList = ({ removeCurrentProperty = true, disabled=false, selectedProperties, setSeletedProperties, className, searchable = false, enableSelectAll = false, autoHeightMin = "250px", autoHeightMax = "250px", onChange }) => 
{
    const trans = useTranslation().t;
    const [propertySearchQuery, setPropertySerachQuery] = useState(null);
    const [properties, setProperties] = useState([]);
    const brandingCtx = useContext(BrandingContext);
    const handlePropertySearchChange = useCallback(
        debounce((value) => 
        {
            setPropertySerachQuery(value);
        }, 500),
        []
    );
    
    useEffect(() => 
    {
        initializePropertyList();
    }, []);

    /**
    * Sets properties from brandingContext.
    * This will be used in propery selection dropdown
    */
    const initializePropertyList = () => 
    {
        let displayProperties = [];
        const existingProperties = brandingCtx.state.properties;
        Object.keys(existingProperties)
            .filter((propertyId) => removeCurrentProperty ? propertyId !== brandingCtx.state.propertyId : true)
            .map((propertyId) => displayProperties.push({
                key: propertyId,
                text: existingProperties[propertyId].name[DEFAULT_LANGUAGE_CODE],
                value: propertyId
            }));
        setProperties(displayProperties);
    };

    const filteredProperties = properties.filter(({ text }) => 
    {
        if (!propertySearchQuery) return true;
        const regex = new RegExp(propertySearchQuery, "i");
        return regex.test(text);
    });

    return (<div className={className}>
        {searchable && <TextGroup>
            <Input
                icon="search"
                iconPosition="right"
                placeholder={trans("ExportGroups.Search_Property")}
                defaultValue={propertySearchQuery}
                onChange={(e) => 
                {
                    const value = e.target.value;
                    handlePropertySearchChange(value);
                }
                }
            />
        </TextGroup>}


        <Scrollbars className="copyModal-propertyScroll" autoHeight autoHeightMin={autoHeightMin} autoHeightMax={autoHeightMax}>
            {enableSelectAll && !propertySearchQuery && <div className="select-all-checkbox">
                <CheckboxGroup
                    checked={properties.length === selectedProperties.length}
                    indeterminate={selectedProperties?.length > 0 && properties.length !== selectedProperties.length}
                    label={trans("ExportGroups.Select_All")}
                    disabled={disabled}
                    onChange={() => 
                    {
                        if (properties.length === selectedProperties.length) 
                        {
                            setSeletedProperties([]);
                        }
                        else 
                        {
                            setSeletedProperties([...properties.map((e) => e.value)]);
                        }
                    }
                    } />
            </div>}
            {
                isEmpty(filteredProperties) ? <div className="NoCategorySearchResult">{trans("manageCategory.searchResultNotFound")} <strong style={{ marginLeft: 5 }}>{propertySearchQuery}</strong></div>  : <>
                    {
                        filteredProperties.map(({ key, text, value }) => <CheckboxGroup
                            key={key}
                            checked={selectedProperties.includes(value)}
                            label={text}
                            disabled={disabled}
                            onChange={() => 
                            {
                                const index = selectedProperties.indexOf(value);
                                if (index === -1) 
                                {
                                    setSeletedProperties([...selectedProperties, value]);
                                }
                                else 
                                {
                                    const arr = selectedProperties;
                                    arr.splice(index, 1);
                                    setSeletedProperties([...arr]);
                                }
                            }}

                        />)
                    }
                </>
            }
        </Scrollbars>
    </div>);
};

export default PropertyList;   