import React from "react";
import { Button, } from "semantic-ui-react";
import BrandingContext from "../../../store/BrandingContext";
import './MergePopup.css';
import { KEY_CODES } from "../../../_constants/constants";
import { useTranslation } from "react-i18next";


export const MergePopup = ({ selectedEntities }) =>
{
    /** @type {typeof import("../../../_intl/resources.json")["en"]["translation"]["merge button content"]} */
    const trans = useTranslation().t("merge button content");
    const brandingContext = React.useContext(BrandingContext);

    const isOpen = React.useMemo(() =>
    {
        return (Object.keys(selectedEntities).length >= 2)
    }, [selectedEntities]);

    const handleCancelChanges = React.useCallback(() =>
    {
        brandingContext.changeSelectedEntity(undefined);
    }, [brandingContext]);

    const handleMerge = React.useCallback(() =>
    {
        brandingContext.mergeSelectedEntities();
    }, [brandingContext]);

    const shortCutHandler = React.useCallback((e) =>
    {
        const keyCode = e.keyCode;

        if (keyCode === KEY_CODES.ESCAPE)
        {
            handleCancelChanges();
        }
        else if (keyCode === KEY_CODES.ENTER)
        {
            handleMerge();
        }
    }, [handleCancelChanges, handleMerge]);

    React.useEffect(() =>
    {
        document.addEventListener("keydown", shortCutHandler, false);

        return () =>
        {
            document.removeEventListener("keydown", shortCutHandler, false);
        }
    }, [shortCutHandler]);

    return (
        <div>
            {
                (isOpen) && (
                    <div className="mergeActions">
                        <Button primary content={trans} onClick={handleMerge} />
                        <Button className="close closeBtn" onClick={handleCancelChanges} />
                    </div>
                )
            }
        </div>

    );
}