import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "semantic-ui-react";

import EditButton from "./EditButton";

import styles from "./ModalLayout.module.scss";
import arrowIcon from "./media/icon-arrow.svg";

const ModalLayout = ({ heading, actions, children, onGoBack, modalProps }) =>
{
    const defaultModalProps = {
        centered: true,
        closeIcon: <Button className={styles.closeButton} />,
        closeOnEscape: false,
        closeOnDimmerClick: false,
    };

    const mergedModalProps = { ...defaultModalProps, ...modalProps };

    return (
        <Modal {...mergedModalProps} >
            {heading
                && <div className="aligner">
                    <Modal.Header>
                    {onGoBack && <EditButton className={styles.goBack} imageSrc={arrowIcon} onClick={onGoBack} />}
                        {heading}</Modal.Header>
                </div>
            }
            <Modal.Content>
                {children}
            </Modal.Content>
            {actions
                && <Modal.Actions>
                    {actions}
                </Modal.Actions>
            }
        </Modal>
    );
};

export default ModalLayout;

ModalLayout.propTypes = {
    heading: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    actions: PropTypes.node,
    children: PropTypes.node,
    onGoBack: PropTypes.func,
    modalProps: PropTypes.object
};

ModalLayout.defaultProps = {
    heading: "",
    actions: undefined,
    children: undefined,
    onGoBack: undefined,
    modalProps: {}
};
