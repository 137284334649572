import React from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Header, Popup } from "semantic-ui-react";
import { ENTITIES_TOGGLABLE_LAYERS, FILTERED_ID_TYPES, MAP_EDITOR_TOOLS, SMART_FILTER_OPTIONS } from "../../../_constants/mapEditor";
import { ButtonIcon } from "../../common/ButtonIcon";
import { MapEditorSidebarItem, ShortCutsPopupItem } from "./MapEditorLeftbar";
import MapEditorSidebarAccordion from "./MapEditorSidebarAccordion";
import FloorPlanShoutCutsRender from "../FloorPlanGeoReferencing/FloorPlanShoutCuts";
// import { ValidationErrorInfoToolBar } from '../../branding/ValidationInfo/ValidationInfo';

export const MapEditorSidebarEntities = ({ smartFilter, selectedTool, selectedFilterItem, onSelectFilter, onSelectFilterItem, onToolClick, onShowNotLockedByUserAlert }) =>
// eslint-disable-next-line arrow-body-style
{

    return (
        <div className="mapEditorSidebar">
            <Header>Create</Header>
            <Scrollbars className="mapEditorScroll" autoHeight autoHeightMin="calc(100vh - 220px)" autoHeightMax="calc(100vh - 220px)" >
                <div className="alignerMapEditor">
                    <div className="mapEditorSidebarGroup">

                        <MapEditorSidebarItem
                            icon="icon-editor-create-polygon"
                            name={MAP_EDITOR_TOOLS.Polygon}
                            active={selectedTool === MAP_EDITOR_TOOLS.Polygon}
                            onClick={onToolClick}
                            content={MAP_EDITOR_TOOLS.Polygon} />

                        <MapEditorSidebarItem
                            icon="icon-editor-create-point"
                            name={MAP_EDITOR_TOOLS.Point}
                            active={selectedTool === MAP_EDITOR_TOOLS.Point}
                            onClick={onToolClick}
                            content={MAP_EDITOR_TOOLS.Point} />

                        <MapEditorSidebarItem
                            icon="icon-editor-create-polyline"
                            name={MAP_EDITOR_TOOLS.Polyline}
                            active={selectedTool === MAP_EDITOR_TOOLS.Polyline}
                            onClick={onToolClick}
                            content={MAP_EDITOR_TOOLS.Polyline} />

                        <MapEditorSidebarItem
                            icon="icon-editor-create-delete"
                            name={MAP_EDITOR_TOOLS.DeleteEntities}
                            active={selectedTool === MAP_EDITOR_TOOLS.DeleteEntities}
                            onClick={onToolClick}
                            content={MAP_EDITOR_TOOLS.Delete} />

                        <MapEditorSidebarItem
                            icon="icon-edit"
                            name={MAP_EDITOR_TOOLS.EditEntities}
                            active={selectedTool === MAP_EDITOR_TOOLS.EditEntities}
                            onClick={onToolClick}
                            content={MAP_EDITOR_TOOLS.EditEntities} />

                        <MapEditorSidebarItem
                            icon="editor/icon-editor-split"
                            name={MAP_EDITOR_TOOLS.Split}
                            active={selectedTool === MAP_EDITOR_TOOLS.Split}
                            onClick={onToolClick}
                            content={MAP_EDITOR_TOOLS.Split} />

                        <MapEditorSidebarItem
                            icon="editor/icon-editor-merge"
                            name={MAP_EDITOR_TOOLS.Merge}
                            active={selectedTool === MAP_EDITOR_TOOLS.Merge}
                            onClick={onToolClick}
                            content={MAP_EDITOR_TOOLS.Merge} />

                    </div>

                    <MapEditorSidebarAccordion
                        showFloorPlanAccordion={true}
                        smartFilter={smartFilter}
                        smartFilterOptions={SMART_FILTER_OPTIONS[FILTERED_ID_TYPES.ENTITY]}
                        mapLayerOptions={ENTITIES_TOGGLABLE_LAYERS}
                        selectedFilterItem={selectedFilterItem}
                        onSelectFilter={onSelectFilter}
                        onSelectFilterItem={onSelectFilterItem}
                        onShowNotLockedByUserAlert={onShowNotLockedByUserAlert}
                    />
                    {/* <div className="validationErrorGroup">
                        <ValidationErrorInfoToolBar isFilterByActiveBuilding />
                    </div> */}
                </div>

            </Scrollbars>
            <ShortCutsPopup />
        </div>
    );
};



const ShortCutsPopup = () => (
    <Popup className="shortcutsPopup"
        trigger={<ButtonIcon className="shortcutsPopupTrigger" icon="editor-control" content="Shortcuts" />}
        basic
        on="click"
    >
        {/* if floor plan is active render floor plan shoutcuts else renders children */}
        <FloorPlanShoutCutsRender>
            <>
                <ShortCutsPopupItem title="Ctrl + Z" content="Undo" />
                <ShortCutsPopupItem title="Ctrl + Y" content="Redo" />
                <div><strong>While Editing</strong></div><br />
                <ShortCutsPopupItem title="Shift" content="Translate" />
                <ShortCutsPopupItem title="Ctrl" content="Scale and Rotate" />
                <ShortCutsPopupItem title="Alt" content="Delete Vertex" />
            </>
        </FloorPlanShoutCutsRender>


    </Popup>
);
