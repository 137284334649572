import { useRequest, useSetState, useMap, useDebounceEffect } from "ahooks";
import React from "react";
import { Button, Checkbox, Icon, Message, Popup, Label, Dropdown, Input } from "semantic-ui-react";
import { StoreSharingContext } from "../../store/StoreSharingProvider";
import serverApi from "../../_api/server.api";
import brandingApi from "../../_api/branding.api";
import { ModalLayout } from "../popups/ModalLayout";
import { ShareStoreGroupDeleteModal, SyncStoreLastModal } from "./SyncStoreLastModal";
import { SyncStoreModal } from "./SyncStoreModal";
import "./SyncStoreModal.css";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import { filerUrl } from "../../_utils/utils";
import { DEFAULT_STORE_LOGO } from "../../_constants/constants";
import { useTranslation, Trans } from "react-i18next";
import { useLoadingPool } from "../../_utils/hooks";
import { withConfig } from "../../_utils/hoc";

export const SyncStoreTypeModal = withConfig(({ config }) =>
{
    const ctx = React.useContext(StoreSharingContext);
    const trans = useTranslation().t;
    const { state: { accessGroups, fieldLanguage, openShareStores, openWithStore, groupsAreFetched }, methods: { reinitGroups, setState } } = ctx;

    const [selectedGroupId, setSelectedGroupId] = React.useState("");
    const [openCredentialsModal, setOpenCredentialsModal] = React.useState(false);
    const [openCreateStoreModal, setOpenCreateStoreModal] = React.useState({ open: false, isEditMode: false });
    const [search, setSearch] = React.useState("");

    const selectedGroupObj = accessGroups?.find((group) => group._id === selectedGroupId);

    // Show store list if user's company has access groups
    const hideStoreList = !Array.isArray(accessGroups) || !accessGroups.length;

    const [linkAndPinPopup, setLinkAndPinPopup] = useSetState({ open: false, pin: "", _id: "" });
    const [deleteGroupsPopup, setDeleteGroupsPopup] = useSetState({ open: false, disabled: false });

    const handleSuccessfulCreateGroup = (openObj) =>
    {
        setLinkAndPinPopup(openObj);
        reinitGroups();
    };

    const BRANDING_URL = config?.BRANDING_URL;

    const [checkedGroups, checkedGroupsOps] = useMap();
    React.useEffect(() =>
    {
        // Sort accessGroups by alphabetical order
        if (accessGroups)
        {
            accessGroups.sort((groupA, groupB) =>
            {
                const nameA = groupA.name.toUpperCase();
                const nameB = groupB.name.toUpperCase();
                if (nameA < nameB)
                {
                    return -1;
                }
                if (nameA > nameB)
                {
                    return 1;
                }
                return 0;
            });
        }
        const initialCheckedGroups = accessGroups?.map((group) => [group?._id, false]);
        checkedGroupsOps.setAll(initialCheckedGroups);
        setSelectedGroupId(accessGroups?.[0]?._id);
    }, [accessGroups]);

    const deselectAllExcept = (thisId, checked = true) => checkedGroupsOps.setAll(accessGroups?.map((group) => [group?._id, group?._id === thisId ? checked : false]));

    const [checkedError, setCheckedError] = useSetState({ open: false, errorMsg: "" });

    const handleEditGroup = () =>
    {
        const numberOfCheckedGroups = Array.from(checkedGroups.values()).filter((checked) => checked === true).length;
        if (numberOfCheckedGroups === 0)
        {
            setCheckedError({ open: true, errorMsg: trans("SyncStoreTypeModal.Select_a_group_to_edit") });
        }
        else if (numberOfCheckedGroups > 1)
        {
            setCheckedError({ open: true, errorMsg: trans("SyncStoreTypeModal.Editing_multiple_groups_is_not_supported") });
        }
        else
        {
            setCheckedError({ open: false });
            setOpenCreateStoreModal({ open: true, isEditMode: true });
        }
    };

    const handleDeleteGroups = () =>
    {
        if (Array.from(checkedGroups.values()).every((checked) => !checked))
        {
            setCheckedError({ open: true, errorMsg: trans("SyncStoreTypeModal.Select_one_or_more_groups_to_delete") });
        }
        else
        {
            setCheckedError({ open: false });
            setDeleteGroupsPopup({ open: true });
        }
    };

    const handleSubmitDeleteGroups = () =>
    {
        setDeleteGroupsPopup({ disabled: true });
        brandingApi.accessGroupsDelete(Array.from(checkedGroups.entries()).filter((group) => group[1]).map((g) => g[0]))
            .then(() =>
            {
                setDeleteGroupsPopup({ open: false, disabled: false });
                reinitGroups();
            }).catch((err) => console.log(err));
    };

    const isAnyChecked = React.useMemo(() => Array.from(checkedGroups.values()).some((checked) => checked), [checkedGroups]);

    const currentCheckedGroupObjs = accessGroups?.filter((group) => checkedGroups.get(group._id));
    const currentSingleCheckedGroupToEdit = currentCheckedGroupObjs?.[0];
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));

    React.useEffect(() =>
    {
        if (openWithStore && openShareStores)
        {
            delay(1000).then(() => setOpenCreateStoreModal({ open: true, isEditMode: false }));
        }
    }, [openShareStores]);

    const handleModalClose = () =>
    {
        setState({ openShareStores: false, openWithStore: undefined });
        // Unset selected group on modal close
        checkedGroupsOps.setAll(accessGroups?.map((group) => [group?._id, false]));
        setSelectedGroupId(accessGroups?.[0]?._id);
        setSearch("");
    };

    return (
        <ModalLayout
            open={openShareStores}
            onClose={handleModalClose}
            className="syncStoreModal syncStoreTypeModal"
            heading={trans("SyncStoreTypeModal.Share_Store_Groups")}
        >
            <div className="aligner" style={{ minHeight: "60vh" }}>
                <GroupCredentialsModal
                    open={openCredentialsModal}
                    setOpen={setOpenCredentialsModal}
                    credentials={{
                        id: selectedGroupObj?._id,
                        pin: selectedGroupObj?.pin || trans("SyncStoreTypeModal.No_Pin_Needed")
                    }}
                    BRANDING_URL={BRANDING_URL}
                />
                <SyncStoreModal
                    open={openCreateStoreModal.open}
                    isEditMode={openCreateStoreModal.isEditMode}
                    groupToEdit={currentSingleCheckedGroupToEdit}
                    openWithStore={openWithStore}
                    setOpen={setOpenCreateStoreModal}
                    setLinkAndPinPopup={setLinkAndPinPopup}
                />
                <SyncStoreLastModal
                    id={`${BRANDING_URL}/${linkAndPinPopup._id}`}
                    pin={linkAndPinPopup.pin}
                    modalProps={{
                        open: linkAndPinPopup.open,
                        onClose: () => setLinkAndPinPopup({ open: false })
                    }}
                    setLinkAndPinPopup={handleSuccessfulCreateGroup}
                />
                <ShareStoreGroupDeleteModal
                    open={deleteGroupsPopup.open}
                    onCancel={() => setDeleteGroupsPopup({ open: false })}
                    groupsToBeDeleted={currentCheckedGroupObjs}
                    onDelete={handleSubmitDeleteGroups}
                    disableButtons={deleteGroupsPopup.disabled}
                />
                <Message size="small"><i><b>{trans("SyncStoreTypeModal.Share_access_to_Store_Groups_and_the_abi")}</b></i></Message>
                <p className="p-modal">
                    {trans("SyncStoreTypeModal.Share_groups_composed_of_one_or_more_sto")}
                    <br /><br />
                    {trans("SyncStoreTypeModal.Tip__To_revoke_access_to_a_group_without")}
                </p>
                <h3 className="subHeading3 alignLeft">{trans("SyncStoreTypeModal.Your_Saved_Groups")}</h3>


                {(hideStoreList) && (
                    <div style={{ textAlignLast: "center", marginBottom: "35px" }}>
                        <br />
                        {(!groupsAreFetched) && (
                            <h3>Loading...</h3>
                        )}
                        {(groupsAreFetched) && (
                            <p>{trans("SyncStoreTypeModal.You_don’t_have_any_store_groups_here_yet")} &nbsp; <br />
                                <Button
                                    style={{ marginTop: "5px" }} 
                                    onClick={() => setOpenCreateStoreModal({ open: true, isEditMode: false })}
                                    color="teal"
                                >
                                    {trans("SyncStoreTypeModal.Create_Store_Group")}
                                </Button>
                            </p>
                        )}
                    </div>
                )}

                {(!hideStoreList) && (
                    <div>
                        <Dropdown
                            className="icon groupSearch"
                            icon="search" search value={undefined}
                            options={
                                accessGroups
                                    .filter((group) => group.name.toUpperCase().includes(search.toUpperCase()))
                                    .map((group) => ({ key: group._id, text: group.name, value: group._id }))
                            }
                            onChange={(e, { value }) =>
                            {
                                deselectAllExcept(value);
                                setSelectedGroupId(value);
                            }}
                            searchQuery={search}
                            onSearchChange={(e, { searchQuery }) => setSearch(searchQuery)}
                            text={trans("SyncStoreTypeModal.Search_and_Select_Groups")}
                            noResultsMessage={trans("SyncStoreTypeModal.We_couldn't_find_that_group__Please_try_")}
                        />
                        <div style={{ float: "right" }}>
                            {checkedError.open
                            && <Label as="a" basic color="red" pointing="right" style={{ float: "left" }}>
                                {checkedError.errorMsg}
                            </Label>}
                            <Button.Group
                                basic
                                style={{ float: "left" }}
                                className="actionButtonGroup"
                                size="small"
                                buttons={[
                                    { key: "add", disabled: isAnyChecked, icon: "plus", onClick: () => setOpenCreateStoreModal({ open: true, isEditMode: false }) },
                                    { key: "edit", disabled: !isAnyChecked, icon: "edit", onClick: () => handleEditGroup() },
                                    { key: "delete", disabled: !isAnyChecked, icon: "trash", onClick: () => handleDeleteGroups() },
                                ]}
                            />
                        </div>
                        <div className="tabStoreCover" style={{ marginBottom: "35px" }}>
                            <div className="tabStoreColumn">
                                <span>
                                    <Trans
                                        i18nKey="SyncStoreTypeModal.Stores_available_under"
                                        components={{ bolded: <strong /> }}
                                        values={{ groupName: `${selectedGroupObj?.name}:` }}
                                    />
                                </span>
                                { selectedGroupObj?.resources?.map((res) => <SyncListStoreItem
                                    key={`${res?.entityId} ${res?.floorId}`}
                                    text={{
                                        content: res?.entity?.entityLabel?.longName?.[fieldLanguage],
                                        mallNumber: res?.property?.name?.[fieldLanguage],
                                        floorName: res?.floor?.shortName?.[fieldLanguage] || res?.floor?.longName?.[fieldLanguage]
                                    }}
                                    logoUrl={res?.entity?.entityLabel?.iconImage?.[fieldLanguage] ? filerUrl(res?.entity?.entityLabel?.iconImage?.[fieldLanguage]) : undefined}
                                />
                            )}
                            </div>
                            <div className="tabStoreColumn">
                                <div id="spacer" style={{ height: "32px" }}><span style={{ marginLeft: "10px", borderBottom: "1px solid #d3d2de", padding: "0 0 10px" }}>{trans("SyncStoreTypeModal.Store_Groups")}</span></div>
                                <div className="tabStoreScroll">
                                    { accessGroups.map((group) => <SyncListGroupItem
                                        setOpenCredentialsModal={setOpenCredentialsModal}
                                        key={`${group?.name}-${group?._id}`}
                                        text={{ content: group?.name || trans("SyncStoreTypeModal.No_Name") }}
                                        isActive={selectedGroupId === group._id}
                                        divProps={{ onClick: () => setSelectedGroupId(group._id) }}
                                        checkBoxProps={{
                                            checked: checkedGroupsOps.get(group._id) || false,
                                            onChange: (e, { checked }) => deselectAllExcept(group._id, checked)
                                        }}
                                    />
                                    )}
                                </div>
                            </div>

                        </div>
                    </div>
                )}
            </div>
        </ModalLayout>
    );
});

const SyncListStoreItem = ({ isActive, text, logoUrl }) => (
    <div className={`syncListingItem syncListTypeItem${isActive ? " active":""}`}>
        <span className="syncListingImg">
            <img src={logoUrl || DEFAULT_STORE_LOGO} alt="" />
        </span>
        <div className="syncListingBody">
            {/* {text.content ? text.content : "Urban Outfitters"} <br /> <Popup content={<List items={["Yorkdale x2", "Vaughan Mills", "Dufferin Mall"]} />} trigger={<span>{text.mallNumber}</span>} /> */}
            {text.content ? text.content : "Urban Outfitters"} <br /> <span>{`${text.mallNumber} - ${text.floorName}`}</span>
        </div>
    </div>
);

const SyncListGroupItem = ({ isActive, text, setOpenCredentialsModal, divProps, checkBoxProps }) =>
{
    const trans = useTranslation().t;

    return (
        <div {...divProps} className={`syncListingItem syncListTypeItem${isActive ? " active":""}`}>
            <Checkbox {...checkBoxProps} />
            <div className="syncListingBody" style={{ marginLeft: "8px" }}>
                {text.content ? text.content : "Urban Outfitters"}
            </div>
            <Popup
                inverted
                size="mini"
                position="right center"
                content={trans("SyncStoreTypeModal.Show_Access_Credentials")}
                trigger={<Button icon="key" onClick={() => setOpenCredentialsModal(true)} />} />
        </div>
    );
};

function GroupCredentialsModal({ open, setOpen, credentials, BRANDING_URL })
{
    const trans = useTranslation().t;
    const [pinCopied, setPinCopied] = React.useState(false);
    const handlePinCopied = () =>
    {
        setPinCopied(true);
        setTimeout(() =>
        {
            setPinCopied(false);
        }, 700);
    };

    const [linkCopied, setLinkCopied] = React.useState(false);
    const handleLinkCopied = () =>
    {
        setLinkCopied(true);
        setTimeout(() =>
        {
            setLinkCopied(false);
        }, 700);
    };

    const email = useEmail();

    const groupURL = `${BRANDING_URL}/${credentials.id}`;
    return (
        <ModalLayout
            onClose={() => setOpen(false)}
            open={open}
            size="mini"
            className="modalKeyAccess"
            heading={<>
                <Icon name="key" />
                {trans("SyncStoreTypeModal.Access_Credentials")}
            </>}
            actions={
                <Button positive onClick={() => setOpen(false)}>
                    <Icon name="checkmark" /> {trans("SyncStoreTypeModal.Done")}
                </Button>
            }
        >
            <CopyToClipboard text={groupURL} onCopy={() => handleLinkCopied()}>
                <Button
                    className="quoteBox"
                    size="tiny"
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between"
                    }}
                >
                    <div>
                        <Icon name="chain" /> {groupURL}
                    </div>
                    <div>{linkCopied ? trans("SyncStoreTypeModal.Copied!"): trans("SyncStoreTypeModal.Copy_Link")}</div>
                </Button>
            </CopyToClipboard>
            <CopyToClipboard text={credentials.pin} onCopy={() => handlePinCopied()}>
                <Button
                    className="quoteBox"
                    size="tiny"
                    style={{
                        width: "17rem",
                        marginTop: "2rem",
                        display: "flex",
                        justifyContent: "space-between"
                    }}
                >
                    <div>
                        <Icon name="lock" /> {credentials.pin}
                    </div>
                    <div>{pinCopied ? trans("SyncStoreTypeModal.Copied!"): trans("SyncStoreTypeModal.Copy_Pin")}</div>
                </Button>
            </CopyToClipboard>

            <Input
                label={trans("SyncStoreTypeModal.Share_With")}
                placeholder="jane@example.com"
                style={{ margin: "2rem 0" }}
                value={email.value}
                onChange={(e, { value }) => email.setValue(value)}
                fluid
                action={{
                    icon: "send",
                    color: "blue",
                    loading: email.loading,
                    disabled: !email.validated || email.loading,
                    onClick: () => email.sendEmail(credentials.id)
                }}
            />
        </ModalLayout>
    );
}


function useEmail()
{
    const [{ value, validated }, setState] = useSetState({
        value: "",
        validated: false
    });

    const loadingPool = useLoadingPool();

    useDebounceEffect(() =>
    {
        serverApi.validate("email", value).then((isValid) => setState({ validated: isValid }));
    }, [value], { wait: 500 });

    const setValue = (newValue) => setState({ value: newValue,  validated: false });

    const sendEmail = (groupId) =>
    {
        const loadingToken = loadingPool.add();
        return serverApi.shareStoreGroup(groupId, value)
            .finally(() => loadingPool.remove(loadingToken));
    };

    return { value, setValue, sendEmail, loading: loadingPool.loading, validated };
}
