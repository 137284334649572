import React from 'react';
import { useDrop } from 'react-dnd'
import { useTranslation } from 'react-i18next';
import { DraggableItemTypes } from '../../_constants/constants';

export const AddFloorButton = ({ content, floorNumber, onDrop }) =>
{
    const handleDrop = () =>
    {
        onDrop(floorNumber);
    }

    const [{ isOver }, drop] = useDrop({
        accept: DraggableItemTypes.FLOOR,
        drop: handleDrop,
        collect: monitor => ({
          isOver: !!monitor.isOver(),
        }),
    })
    const trans = useTranslation().t;
    
    return (
        <div className={ "add-floor-box " + (isOver ? " hovered " : "") } ref={drop} >
            {content ? content : 
                <>
                    <img src="/img/icon-plus.svg" alt={trans("AddFloorButton.Icon_add")} />
                    {trans("AddFloorButton.Drag_and_drop_floor_plan_here")}
                </>
            }
        </div>
    );
}