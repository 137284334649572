const { Map } = require("ol");
const { default: GeometryType } = require("ol/geom/GeometryType");
const { default: Draw, createBox, createRegularPolygon } = require("ol/interaction/Draw");
const { Vector: VectorSource } = require("ol/source");
const { Vector: VectorLayer } = require("ol/layer");

class DrawController
{
    constructor()
    {
        this.drawInteraction = undefined;
        this.source = new VectorSource({ wrapX: false });
        this.layer = new VectorLayer({ source: this.source });
    }

    /**
     * @param {Map} map
     * @param {object} options
     */
    addInteraction(map, options = {}, drawEndCallback)
    {
        const defaultOptions = {
            source: this.source,
            type: GeometryType.POLYGON,
            shapeLimit: undefined
        };
        const mergedOptions = { ...defaultOptions, ...options };

        const hasLayer = map.getLayers().getArray().includes(this.layer);

        if (hasLayer)
        {
            map.removeLayer(this.layer);
        }

        map.addLayer(this.layer);

        map.removeInteraction(this.drawInteraction);

        if (mergedOptions.type === "Rectangle")
        {
            mergedOptions.type = "Circle";
            mergedOptions.geometryFunction = createBox();
        }
        else if (mergedOptions.type === "Square")
        {
            mergedOptions.type = "Circle";
            mergedOptions.geometryFunction = createRegularPolygon(4);
        }

        this.drawInteraction = new Draw(mergedOptions);
        if (mergedOptions.shapeLimit > 0)
        {
            this.drawInteraction.addEventListener("drawstart", () =>
            {
                const currentNumOfFeatures = this.source.getFeatures().length;
                if (currentNumOfFeatures >= mergedOptions.shapeLimit)
                {
                    this.drawInteraction.abortDrawing();
                }
                return true;
            });
        }

        if (drawEndCallback)
        {
            this.drawInteraction.addEventListener("drawend", drawEndCallback);
        }

        map.addInteraction(this.drawInteraction);
    }

    clearDrawing(map)
    {
        this.source.clear();
        this.removeInteraction(map)
    }

    removeInteraction(map)
    {
        map.removeInteraction(this.drawInteraction);
    };

    getFeatures()
    {
        return this.source.getFeatures();
    }

}

module.exports = DrawController;
