import { io } from "socket.io-client";
import packageJson from "../../package.json";

export const isDev = () => !process.env.NODE_ENV || process.env.NODE_ENV === "development";

// for dev only 
const SOCKET_CONNECTION_OPTIONS = {
    "force new connection": true,
    "reconnectionAttempts": "Infinity", //avoid having user reconnect manually in order to prevent dead clients after a server restart
    "timeout": 10000,                  //before connect_error and connect_timeout are emitted.
    "transports": ["websocket"],
    // "auth": "test",
    // withCredentials: true
    // reconnectionDelayMax: 10000,
}

// expo
const socket = isDev() ? io(packageJson.proxy, SOCKET_CONNECTION_OPTIONS) : io(SOCKET_CONNECTION_OPTIONS);

console.log("isDev", isDev());
socket.onAny((event, ...args) =>
{
    // for debugging
    console.log(event, args);
});

export default socket;