import axios from "axios";
import { DEFAULT_LANGUAGE_CODE, DEFAULT_LANGUAGE_CODE as DEF_LANG } from "../_constants/constants";
import { filterToUrlParams, getAnalyticsDateRanges } from "../_utils/utils";
import { deepValue } from "mapsted.utils/objects";

export const silentLoginToken = (remove = true) =>
{
    var searchParams = new URLSearchParams(window.location.search);
    const userToken = searchParams.get("t");
    if (userToken && remove)
    {
        searchParams.delete("t");
        if (window.history.replaceState)
        {
            let params = searchParams.toString();
            if (params.length > 0) params = `?${params}`;
            window.history.replaceState({}, null, `${window.location.origin}${params}`);
        }
    }

    return userToken;
};

export const serializeQuery = (obj) =>
{
    let str = [];
    for (let p in obj)
        if (obj.hasOwnProperty(p))
        {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
};

class storage
{
    static local = "localStorage";
    static session = "sessionStorage";

    //localStorage, sessionStorage
    constructor(storageType)
    {
        this.storage = window[storageType];
    }

    clear(key)
    {
        this.storage.removeItem(key);
    }

    readJSON(key)
    {
        let data = this.storage.getItem(key);
        return data ? JSON.parse(data) : undefined;
    }

    writeJSON(key, data)
    {
        // console.log("writeJSON -> ", key, data)
        this.storage.setItem(key, JSON.stringify(data));
    }

}


export class webAPI
{
    localStorage = new storage(storage.local);
    sessionStorage = new storage(storage.session);

    clear()
    {
        this.sessionStorage.clear("user-data");
    }

    get token()
    {
        const userData = this.userData;
        return userData ? userData.token : undefined;
    }

    set token(token)
    {
        const userData = this.userData;

        userData.token = token;

        this.userData = userData;
    }

    get archibusToken()
    {
        const userData = this.userData;
        return userData ? userData.archibusToken : undefined;
    }

    set archibusToken(token)
    {
        const userData = this.userData;

        userData.archibusToken = token;

        this.userData = userData;
    }

    //#region localStorage
    get userData()
    {
        return this.sessionStorage.readJSON("user-data");
    }

    set galleryChoices(value)
    {
        const userData = this.userData;

        userData.galleryChoices = value;

        this.userData = userData;
    }

    get galleryChoices()
    {
        return this.userData.galleryChoices;
    }

    set userData(value)
    {
        this.sessionStorage.writeJSON("user-data", value);
    }

    createHeaders(authorization, hasBody = true)
    {
        let headers = { "Content-Type": "application/json; charset=utf-8" };

        if (authorization === true)
            headers["Authorization"] = "Bearer " + this.token;

        return headers;
    }

    async get(url, authorization)
    {
        let json = {};
        let headers = this.createHeaders(authorization);
        const response = await fetch(url, { headers: headers });
        if (response.status === 200)
        {
            json = await response.json();
        }

        return json;
    }

    /**
     * Will reject promise on error, used to trigger react-query error states
     */
    async getQuery(url, authorization)
    {
        let headers = this.createHeaders(authorization);
        return fetch(url, { headers: headers }).then((res) => res.json());
    }

    async put(url, data, authorization)
    {
        return this.sendData(url, "PUT", data, authorization);
    }

    async post(url, data, authorization)
    {
        return this.sendData(url, "POST", data, authorization);
    }

    async patch(url, data, authorization)
    {
        return this.sendData(url, "PATCH", data, authorization);
    }

    /**
     * Delete a specific object
     * @param {String} url
     * @param {Boolean} authorization
     */
    async delete(url, authorization)
    {
        return this.sendData(url, "DELETE", undefined, authorization);
    }

    /**
     * Delete multiple specific objects
     * @param {String} url
     * @param {Object} body
     * @param {Boolean} authorization
     */
    async deleteMultiple(url, body, authorization)
    {
        return this.sendData(url, "DELETE", body, authorization);
    }

    async sendData(url, method, data, authorization, resultMethod, triggerBack = undefined)
    {
        const fetchMethod = method.toUpperCase();

        let headers = this.createHeaders(authorization);
        // "Content-Type": "application/x-www-form-urlencoded"
        let response = await fetch(url, {
            method: fetchMethod, // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, same-origin, *omit
            headers: headers,
            redirect: "follow", // manual, *follow, error
            referrer: "no-referrer", // no-referrer, *client
            body: fetchMethod !== "GET" && JSON.stringify(data), // body data type must match "Content-Type" header
        });

        let result = {};
        if (response.status === 200)
        {
            result = (resultMethod && typeof response[resultMethod] === "function") ? await response[resultMethod]() : await response.json();
            if (triggerBack)
            {
                resultMethod(result);
            }
        }
        else
        {
            return Promise.reject(response);
        }

        return result;
    }

    /**
     * Send File(s) to server
     * @param {string} url
     * @param {object} formData
     * @param {boolean} authorization
     * @param {function} callback not in use
     * @returns Promise
     *
     * @example
     *     let formData = new FormData();
     *  formData.append(filename, base64 file);
     *  sendFile(<api endpoint>, formData, true);
     */
    async sendFile(url, formData, authorization, callback = () => null)
    {
        return new Promise((resolve, reject) =>
        {
            try
            {
                let request = new XMLHttpRequest();

                request.open("POST", url);

                // request.onprogress = callback;
                // request.onloadstart = callback;
                // request.upload.onprogress = callback;

                request.onload = () =>
                {
                    if (request.status === 200)
                    {
                        const result = request.response ? JSON.parse(request.response) : {};
                        callback(result);
                        resolve(result);
                    }
                    else
                    {
                        const result = { success: false, status: request.status, response: request.response };
                        callback(result);
                        resolve(result);
                    }
                };

                authorization && request.setRequestHeader("Authorization", `Bearer ${this.token}`);

                request.send(formData);
            }
            catch (err)
            {
                reject(err);
            }
        });
    }

    getUserDisplayName()
    {
        let displayName = "";
        let userData = this.userData;

        if (userData?.user?.userInfo)
        {
            displayName = `${userData.user.userInfo.firstName}, ${userData.user.userInfo.lastName}`;
        }

        return displayName;
    }

    getUserInitials()
    {
        let initials = "";
        let userData = this.userData;

        if (userData?.user?.userInfo)
        {
            initials = `${userData.user.userInfo.firstName.charAt(0) || ""}${userData.user.userInfo.lastName.charAt(0) || ""}`.toLocaleUpperCase();
        }

        return initials;
    }

    /**
     * Get the user's unique identifier.
     *
     * @return {type} The user's unique identifier
     */
    getUserUID()
    {
        if (this.userData)
        {
            return this.userData.user.userInfo.id;
        }
    }
}


class serverAPI extends webAPI
{
    config = undefined;
    constructor()
    {
        super();
        this.fetchConfig()
            .then((config) => this.config = config);
    }
    get dashboardData()
    {
        return this.localStorage.readJSON("dashboard-data");
    }

    set dashboardData(value)
    {
        this.localStorage.writeJSON("dashboard-data", value);
    }

    get openingHoursLinkedData()
    {
        return this.localStorage.readJSON("opening-hours-linked-data");
    }

    set openingHoursLinkedData(value)
    {
        this.localStorage.writeJSON("opening-hours-linked-data", value);
    }

    async silentLogin(token)
    {
        return new Promise((resolve, reject) =>
        {
            this.post("/api/internal/silent-login", { token: token }, false)
                .then((userData) =>
                {
                    resolve((this.userData = userData));
                })
                .catch((err) => reject(err));
        });
    }

    async validateToken()
    {
        return new Promise((resolve, reject) =>
        {
            if (this.token)
            {
                this.post("/api/internal/token", { token: this.token }, false)
                    .then((response) =>
                    {
                        if (!response.token)
                        {
                            reject("token is missing");
                        }
                        else
                        {
                            this.token = response.token;
                            resolve(true);
                        }
                    })
                    .catch((err) => reject(err));
            }
            else
            {
                reject("no token");
            }
        });
    }

    async acceptTermsAndConditions(url)
    {
        try
        {
            const { success } = await this.post("/api/internal/user/accept-terms", { url }, true);

            return success;
        }
        catch (err)
        {

            console.log("Accept terms and condition", err);
        }

        return false;
    }

    async areTermsAndConditionsAccepted()
    {
        try
        {
            const { success } = await this.get("/api/internal/user/accept-terms", true);

            return success;
        }
        catch (err)
        {
            console.log("Accepted terms and condition", err);
        }

        return false;
    }

    /**
     * Updates dashbaord data stored in browser.
     * @param {String} prop - Property to update, "propertyId" "buildingId" "floorId"
     * @param {String} value - New value for the property given.
     */
    handleUpdateDashboardData = (prop, value) =>
    {
        let dashboardData = this.dashboardData;

        if (dashboardData)
        {
            dashboardData[prop] = value;
        }
        else
        {
            dashboardData = { [prop]: value };
        }

        this.dashboardData = dashboardData;
    };

    handleUpdateOpeningHoursLinkedData = (id, value) =>
    {
        let openingHoursLinkedData = {};

        if (this.openingHoursLinkedData)
        {
            openingHoursLinkedData = this.openingHoursLinkedData;
        }


        if (value)
        {
            openingHoursLinkedData[id] = value;
        }
        else
        {
            openingHoursLinkedData[id] && delete openingHoursLinkedData[id];
        }

        this.openingHoursLinkedData = openingHoursLinkedData;
    };

    async getUserNotifications(until)
    {
        try
        {
            const params = (until) ? `?until=${until}` : "";
            const { success, data } = await this.get(`/api/internal/notifications${params}`, true);

            return success ? data : [];
        }
        catch (err)
        {
            console.log("Failed to get user notifications", err);
        }

        return [];
    }

    async readNotification(notification)
    {
        try
        {
            await this.put(`/api/internal/notifications/${notification._id}/read`, {}, true);

            return true;
        }
        catch (err)
        {
            console.log("Failed to read notification", err);
        }

        return false;
    }

    async readAllNotification()
    {
        try
        {
            await this.post("/api/internal/notifications/all/read", {}, true);

            return true;
        }
        catch (err)
        {
            console.log("Failed to read all notification", err);
        }

        return false;
    }

    //#region API calls
    async getProperties({ buildings = true, poiEntities = false, syncUpdateDate = false })
    {
        try
        {
            const properties = await this.get(`/api/internal/properties?buildings=${buildings}&poiEntities=${poiEntities}&syncUpdateDate=${syncUpdateDate}`, true);
            return properties;
        }
        catch (err)
        {
            console.log("getProperties: ", err);
        }

        return {};
    }

    async getProperty(propertyId)
    {
        try
        {
            const propertyResult = await this.get(`/api/internal/properties/${propertyId}`, true);
            return propertyResult;
        }
        catch (err)
        {
            console.log("getProperty: ", err);
            throw err;
        }
    }

    async getBuilding(buildingId)
    {
        try
        {
            const buildingResult = await this.get(`/api/internal/buildings/${buildingId}`, true);
            console.log("api call", buildingResult);

            return buildingResult;
        }
        catch (err)
        {
            console.log("getBuilding: ", err);
            throw err;
        }
    }

    async getPropertyBuildings(propertyId)
    {
        try
        {
            const buildings = await this.get(`/api/internal/buildings?propertyId=${propertyId}`, true);
            return buildings;
        }
        catch (err)
        {
            console.log("getPropertyBuildings: ", err);
            throw err;
        }
    }

    async getPropertyHolidays(propertyId, year)
    {
        try
        {
            const { holidays } = await this.get(`/api/internal/properties/${propertyId}/holidays?year=${year}`, true);
            return holidays || [];
        }
        catch (err)
        {
            console.log("getPropertyHolidays:", err);
            return [];
        }
    }

    async getBuildingHolidays(buildingId, year)
    {
        try
        {
            const { holidays } = await this.get(`/api/internal/buildings/${buildingId}/holidays?year=${year}`, true);
            return holidays || [];
        }
        catch (err)
        {
            console.log("getPropertyHolidays:", err);
            return [];
        }
    }

    getEntityHolidays(entityLabelId, year)
    {
        return this.get(`/api/internal/labels/${entityLabelId}/holidays?year=${year}`, true)
            .then(({ holidays }) => holidays || [])
            .catch((err) =>
            {
                console.log("getEntityHolidays:", err);
                return [];
            });
    }

    async getPublicHolidays(year, country)
    {
        try
        {
            const holidays = await this.get(`/api/internal/holidays?country=${country || "CA"}&year=${year}`, true);
            return holidays;
        }
        catch (err)
        {
            console.log("getPublicHolidays", err);
            return [];
        }
    }

    async getTypes(route)
    {
        try
        {
            const types = await this.get(`/api/internal/${route}/types`, true);
            return types;
        }
        catch (err)
        {
            console.log("getTypes:", err);
        }
    }

    updateProperty({ _id, name, description = {}, phoneNumbers = "", email = "", website = {}, fullAddress, iconImage = {}, lightIcon = {}, darkIcon = {} }, callback)
    {
        try
        {
            let formData = new FormData();
            formData.append("_id", _id);
            formData.append("name", JSON.stringify(name));
            formData.append("description", JSON.stringify(description));
            formData.append("phoneNumbers", phoneNumbers.join(","));
            formData.append("email", email);
            formData.append("website", JSON.stringify(website));
            formData.append("iconImage", JSON.stringify(iconImage));
            formData.append("lightIcon", JSON.stringify(lightIcon));
            formData.append("darkIcon", JSON.stringify(darkIcon));
            formData.append("fullAddress", fullAddress);

            this.sendFile(`/api/internal/properties/${_id}`, formData, true, callback);
        }
        catch (err)
        {
            console.log("updateProperty: ", err);
        }
    }

    updatePropertyImage({ _id, filerId, imageType, languageCode = DEFAULT_LANGUAGE_CODE }, callback)
    {
        try
        {
            // let formData = new FormData();
            // fileId && formData.set("fileId", fileId);
            // coverImageFile && formData.append("coverImage", coverImageFile, coverImageFile.name);
            // displayImageFile && formData.append("iconImage", displayImageFile, displayImageFile.name);
            this.sendData(`/api/internal/properties/${_id}/image?languageCode=${languageCode}`, "POST", { filerId, imageType }, true, callback, true);
        }
        catch (err)
        {
            console.log("updateProperty: ", err);
            callback({ success: false });
        }


    }

    reorderPropertyImages = async (propertyId, coverImages, languageCode = DEFAULT_LANGUAGE_CODE) =>
    {
        try
        {
            const result = await this.post(
                `/api/internal/properties/${propertyId}/image/reorder?languageCode=${languageCode}`,
                { coverImages },
                true
            );

            return result;
        }
        catch (err)
        {
            console.log("Failed to reorder cover image list", err);
        }

        return { success: false };
    };

    deletePropertyImage = async (propertyId, filerId, languageCode = DEFAULT_LANGUAGE_CODE) =>
    {
        try
        {
            const result = await this.post(
                `/api/internal/properties/${propertyId}/image?delete=true&languageCode=${languageCode}`,
                { filerId },
                true
            );

            return result;
        }
        catch (err)
        {
            console.log("Failed to delete image");
        }

        return { success: false };
    };

    updateBuilding({ _id, longName, shortName, description, phoneNumbers, email, website, fullAddress, coverImageFile, displayImageFile }, callback)
    {
        try
        {
            let formData = new FormData();
            formData.append("_id", _id);
            formData.append("longName", longName);
            formData.append("shortName", shortName);
            formData.append("description", description);
            formData.append("phoneNumbers", phoneNumbers.join(","));
            formData.append("email", email);
            formData.append("website", website);
            formData.append("fullAddress", fullAddress);

            coverImageFile && formData.append("coverImageFile", coverImageFile, coverImageFile.name);
            displayImageFile && formData.append("displayImageFile", displayImageFile, displayImageFile.name);

            this.sendFile(`/api/internal/buildings/${_id}`, formData, true, callback);
        }
        catch (err)
        {
            console.log("updateBuilding: ", err);
            throw err;
        }
    }

    updateBuildingWithoutImages = async ({
        _id,
        longName = { [DEF_LANG]: "" },
        shortName = { [DEF_LANG]: "" },
        description = { [DEF_LANG]: "" },
        phoneNumbers = [],
        email = "",
        website = { [DEF_LANG]: "" },
        fullAddress,
        socialMedia,
        keywords = { [DEF_LANG]: [] },
        onlineKeywords = { [DEF_LANG]: [] },
        isImageOnMap,
        iconImage = {},
        lightIcon = {},
        darkIcon = {}
    }) =>
    {
        const building = {
            _id,
            longName,
            shortName: shortName || { [DEF_LANG]: "" },
            description: description || { [DEF_LANG]: "" },
            phoneNumbers: phoneNumbers.join(","),
            email: email || "",
            website: website || { [DEF_LANG]: "" },
            fullAddress,
            socialMedia,
            keywords: keywords || { [DEF_LANG]: [] },
            onlineKeywords: onlineKeywords,
            isImageOnMap,
            iconImage: iconImage || {},
            lightIcon: lightIcon || {},
            darkIcon: darkIcon || {}
        };
        return this.post(`/api/internal/buildings/${building._id}`, building, true)
            .catch((err) => console.log("updateBuildingWithoutImages", err));
    };

    editBuildingImage({ _id, fileId, coverImageFile, displayImageFile, languageCode = DEFAULT_LANGUAGE_CODE }, callback)
    {
        try
        {
            let formData = new FormData();
            formData.append("fileId", fileId);
            coverImageFile && formData.append("coverImage", coverImageFile, coverImageFile.name);
            displayImageFile && formData.append("iconImage", displayImageFile, displayImageFile.name);

            this.sendFile(`/api/internal/buildings/${_id}/image?edit=true&languageCode=${languageCode}&filerId=${encodeURIComponent(fileId)}`, formData, true, callback);
        }
        catch (err)
        {
            console.log("updateBuilding: ", err);
            callback({ success: false });
        }

    }

    async updateBuildingImage({ _id, filerId, imageType, languageCode = DEFAULT_LANGUAGE_CODE }, callback)
    {
        try
        {
            await this.sendData(`/api/internal/buildings/${_id}/image?languageCode=${languageCode}`, "POST", { filerId, imageType }, true, callback, true);
        }
        catch (err)
        {
            console.log("updateBuilding: ", err);
            callback({ success: false });
        }

    }

    reorderBuildingImages = async (buildingId, coverImages, languageCode = DEFAULT_LANGUAGE_CODE) =>
    {
        try
        {
            const result = await this.post(
                `/api/internal/buildings/${buildingId}/image/reorder?languageCode=${languageCode}`,
                { coverImages },
                true
            );

            return result;
        }
        catch (err)
        {
            console.log("Failed to reorder cover image list", err);
        }

        return { success: false };
    };

    deleteBuildingImage = async (buildingId, filerId, languageCode = DEFAULT_LANGUAGE_CODE) =>
    {
        try
        {
            const result = await this.post(
                `/api/internal/buildings/${buildingId}/image?delete=true&languageCode=${languageCode}`,
                { filerId },
                true
            );

            return result;
        }
        catch (err)
        {
            console.log("Failed to delete image");
        }

        return { success: false };
    };

    saveProperty(property, callback)
    {
        try
        {
            let formData = new FormData();
            property._id && formData.append("_id", property._id);
            formData.append("name", JSON.stringify(property.name));
            formData.append("type", property.type);
            formData.append("website", JSON.stringify(property.website));
            formData.append("fullAddress", property.fullAddress);
            formData.append("centroid", JSON.stringify(property.centroid));
            formData.append("addressComponents", JSON.stringify(property.addressComponents));
            formData.append("phoneNumbers", property.phoneNumbers);
            formData.append("timeZone", JSON.stringify(property.timeZone));

            property.floorPlan && formData.append("floorPlanImageFile", property.floorPlan, property.floorPlan.name);

            this.sendFile("/api/internal/properties/create", formData, true, callback);
        }
        catch (err)
        {
            console.log("saveProperty - ----: ", err);
            callback({ success: false });
        }
    }

    updateBuildingIconImage(buildingId, data )
    {
        return this.patch(`/api/internal/buildings/${buildingId}/updateBuildingIconImage`, data, true);
    }

    updateBuildingCoverImages( buildingId, data )
    {
        return this.patch(`/api/internal/buildings/${buildingId}/updateBuildingCoverImages`, data, true);
    }

    async saveBuilding(building, propertyId)
    {
        try
        {
            const buildingToSave = {
                _id: building._id,
                longName: building.longName,
                type: building.type,
                centroid: building.centroid,
                fullAddress: building.fullAddress,
                website: building.website,
                phoneNumbers: Array.isArray(building.phoneNumbers) ? building.phoneNumbers.join(",") : building.phoneNumbers,
                addressComponents: building.addressComponents,
                timeZone: building.timeZone,
            };

            const success = await this.post(`/api/internal/buildings/create${propertyId ? `?propertyId=${propertyId}` : ""}`, buildingToSave, true);
            return success;
        }
        catch (err)
        {
            console.log("saveProperty: ", err);
        }
    }

    /**
     *
     * @param {Object} floor
     * @param {String} buildingId
     * @param {String} operationId - a unique identifier to aggreagate the floors upload with
     * @param {Function} callback
     */
    async saveFloor(floor, buildingId, operationId, callback)
    {
        try
        {
            let formData = new FormData();
            floor._id && formData.append("_id", floor._id);
            formData.append("longName", JSON.stringify(floor.longName));
            formData.append("shortName", JSON.stringify(floor.shortName));
            formData.append("floorNumber", floor.floorNumber);
            formData.append("operationId", operationId);

            floor.floorPlan && formData.append("floorPlanImageFile", floor.floorPlan, floor.floorPlan.name);

            this.sendFile(`/api/internal/floors/create${buildingId && `?buildingId=${buildingId}`}`, formData, true, callback);
        }
        catch (err)
        {
            console.log("saveFloor: ", err);
        }
    }

    async updateFloor(floor, buildingId)
    {
        try
        {
            const data = {
                _id: floor._id,
                longName: floor.longName,
                shortName: floor.shortName
            };

            const result = await this.put(`/api/internal/floors/${floor._id}?buildingId=${buildingId}`, data, true);

            return result;
        }
        catch (err)
        {
            console.error("Update floor: ", err);
        }
    }

    finishUploadFloors(buildingId, operationId, floorIds)
    {
        try
        {
            const data = {
                floorIds
            };

            this.post(`/api/internal/floors/end/${operationId}?buildingId=${buildingId}`, data, true);
        }
        catch (err)
        {
            console.error("Failed to finish upload floors", err);
        }
    }

    async updatePartialPropertyHours(partialOpeningHours, allBuildings)
    {
        try
        {
            const result = await this.put(`/api/internal/properties/${partialOpeningHours._id}/partialOpeningHours?buildings=${allBuildings}`, partialOpeningHours, true);
            return result;
        }
        catch (err)
        {
            console.log("updatePropertyHours: ", err);
            throw err;
        }
    }

    async updatePartialBuildingHours(partialBuildingHours)
    {
        try
        {
            const result = await this.put(`/api/internal/buildings/${partialBuildingHours._id}/partialOpeningHours`, partialBuildingHours, true);
            return result;
        }
        catch (err)
        {
            console.log("updateBuildingHours: ", err);
            throw err;
        }
    }

    async updatePropertyHoliday(holiday, allBuildings)
    {
        try
        {
            const success = await this.put(`/api/internal/properties/${holiday._id}/holidays?buildings=${allBuildings}`, holiday, true);
            return success;
        }
        catch (err)
        {
            console.log("updatePropertyHoliday: ", err);
            throw err;
        }
    }

    async updateBuildingHoliday(holiday)
    {
        try
        {
            const success = await this.put(`/api/internal/buildings/${holiday._id}/holidays`, holiday, true);
            return success;
        }
        catch (err)
        {
            console.log("updateBuildingHoliday: ", err);
            throw err;
        }
    }

    updateEntityHoliday(holiday)
    {
        return this.put(`/api/internal/labels/${holiday._id}/holidays`, holiday, true)
            .catch((err) =>
            {
                console.log("updateEntityHoliday: ", err);
                return Promise.reject(err);
            });
    }

    async getGoogleLookoutPlace(placeid)
    {
        try
        {
            const data = await this.get(`/api/internal/lookout/${placeid}`, true);
            return data;
        }
        catch (err)
        {
            console.log("Failed to fetch place info", err);
            return {};
        }
    }

    async getGoogleTimeZone({ lat, lng })
    {
        try
        {
            const data = await this.get(`/api/internal/lookout/timezone?latlng=${lat},${lng}`, true);

            return data;
        }
        catch (err)
        {
            console.log("Failed to fetch timezone info", err);
            return {};
        }
    }

    async getGoogleReverseGeocodingAddress({ lat, lng })
    {
        try
        {
            const data = await this.get(`/api/internal/lookout/geocode?latlng=${lat},${lng}`, true);

            return data;
        }
        catch (err)
        {
            console.log("Failed to fetch place by latlng", err);
        }

        return undefined;
    }

    /**
     * @param {string} propertyId
     *
     * @returns List of two analytics objects. List[0] last months analytics. List[1] current months Analytics.
     */
    async getDashboardStats(propertyId, analyticsConstants)
    {
        try
        {
            const { analyticsApiKey, analyticsApi } = analyticsConstants;

            // get dates in utc
            let { startDate, endDate, compareStartDate, compareEndDate } = getAnalyticsDateRanges();

            const urlParamsCompare = filterToUrlParams({ startTimeUTC: compareStartDate, endTimeUTC: compareEndDate, api_key: analyticsApiKey, propertyId });
            const urlParamsCurrentAndroid = filterToUrlParams({ deviceType: "android", startTimeUTC: startDate, endTimeUTC: endDate, api_key: analyticsApiKey, propertyId });
            const urlParamsCurrentIos = filterToUrlParams({ deviceType: "ios", startTimeUTC: startDate, endTimeUTC: endDate, api_key: analyticsApiKey, propertyId });

            console.log("getDashboardStats - API INFO", { analyticsApi, urlParamsCompare, urlParamsCurrentAndroid, urlParamsCurrentIos });
            const resultCompare = await axios
                .get(`${analyticsApi}/analytics/Widget/DashboardSummary/${urlParamsCompare}`);

            const resultCurrentAndroid = await axios
                .get(`${analyticsApi}/analytics/Widget/DashboardSummary/${urlParamsCurrentAndroid}`);

            const resultCurrentIos = await axios
                .get(`${analyticsApi}/analytics/Widget/DashboardSummary/${urlParamsCurrentIos}`);

            let data = {
                compareAnalytics: resultCompare.data,
                analyticsAndroid: resultCurrentAndroid.data,
                analyticsIos: resultCurrentIos.data
            };

            return { success: !!data, data: data };
        }
        catch (err)
        {
            console.log(`get analytics err:  ${err}`);
            return { success: false };
        }
    }

    /**
     * Validates a value based on type.
     *
     * @param {string} type - "website", "phone", "email"
     * @param {*} value - value to validate
     */
    async validate(type, value)
    {
        try
        {
            if (value === undefined || value === "")
            {
                return undefined;
            }
            const success = await this.get(`/api/internal/validate/?type=${type}&val=${value}`);
            return success.success;
        }
        catch (err)
        {
            console.log("validate: ", err);
            throw err;
        }
    }

    validatePhoneNumber(number, countryCode)
    {
        if (number === undefined || number === "")
        {
            return undefined;
        }
        return Promise.resolve(true);
    }

    async deleteProperty(propertyId)
    {
        try
        {
            const { success } = await this.delete(`/api/internal/properties/${propertyId}`, true);

            return success;
        }
        catch (err)
        {
            console.log("deleteProperty: ", err);
            throw err;
        }
    }

    async deleteBuilding(buildingId)
    {
        try
        {
            const { success } = await this.delete(`/api/internal/buildings/${buildingId}`, true);

            return success;
        }
        catch (err)
        {
            console.log("deleteBuilding: ", err);
            throw err;
        }
    }

    async doesUserHaveCMSPermission()
    {
        try
        {
            const { success } = await this.get("/api/internal/permission", true);
            return success;
        }
        catch (err)
        {
            console.log("permission", err);
            throw err;
        }
    }

    /**
     * Async, always returns the config
     * @returns {Promise}
     */
    fetchConfig()
    {
        return this.config ? Promise.resolve(this.config) : this.get("/api/internal/config");
    }

    /**
     * Synchronous returns the config or undefined if it is not yet loaded
     * @returns
     */
    getConfig()
    {
        return this.config;
    }

    /**
     *
     * @param {Blob} file
     * @param {string} [fileId]
     * @returns
     */
    filerUpload(file, fileId = undefined)
    {
        let formData = new FormData();
        formData.append("file", file, file.name);
        if (fileId)
        {
            formData.append("fileId", fileId);
        }
        return this.sendFile("/api/internal/public/images", formData, true, () => null)
            .then((result) => result?.filerId);
    }

    filerDelete(fileId)
    {
        return this.delete(`/api/internal/public/images/${fileId}`, true);
    }

    async sendNewFloorEmail({ propertyId, buildingId, emailDataArray, userData, propertyTypes, buildingTypes })
    {
        try
        {
            console.log({ propertyId, buildingId, emailDataArray });
            const { success } = await this.post(`/api/internal/email/floorsUploaded/${propertyId}/${buildingId}`, { emailDataArray, userData, propertyTypes, buildingTypes }, true);
            return success;
        }
        catch (err)
        {
            console.log("new floor email", err);
            throw err;
        }
    }

    /**
     *
     * @param {String} filename
     * @return {Promise}
     */
    async download(filename)
    {
        try
        {
            await fetch(`/api/public/${filename}`).then((res) => res.blob()).then((file) =>
            {
                const url = window.URL.createObjectURL(file);
                const a = document.createElement("a");
                a.href = url;
                a.download = filename;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();
            });
        }
        catch (error)
        {
            console.error(error);
        }
    }

    shareStoreGroup(groupId, email)
    {
        const companyName = this.userData?.user?.userCompanyInfo?.name;
        return this.post(`/api/internal/branding/accessGroup/${groupId}/share`, { email, companyName }, true);
    }

    async analyticsConstants()
    {
        const headers = this.createHeaders(true);

        try
        {
            let analyticsConstantsResponse = await axios.get(`${this.config.ANALYTICS_URL}/api/v1/cms/analyticsConstant`, { headers: headers });

            return analyticsConstantsResponse?.data?.data;
        }
        catch (err)
        {
            console.log(err);
        }
    }

    async getWebAnalyticsData({ propertyId })
    {
        try
        {
            return await this.get(`/api/internal/analytics/webAnalytics/${propertyId}`, true);
        }
        catch (err)
        {
            console.log(err);
        }
    }

    fetchPropertySettings(propertyId, env)
    {
        return this.get(`/api/internal/properties/${propertyId}/settings${env ? `?data=${env}` : ""}`, true);
    }

    fetchMapstedAppList()
    {
        return this.get(`${this.config.MAPSTED_PUBLIC_URL_MAIN}/apps`);
    }

    getPublicEntities(propertyId, buildingId, floorId)
    {
        let queryParams = `?propertyId=${propertyId}${buildingId ? `&buildingId=${buildingId}` : ""}${floorId ? `&floorId=${floorId}` : ""}`;
        return this.get("/api/internal/publicAccess/entities" + queryParams, true);
    }

    getPublicPropertyEntities(propertyId)
    {
        return this.get("/api/internal/publicAccess/propertyEntities?propertyId=" + propertyId, true);
    }

    getPublicSettings(propertyId, data)
    {
        return this.get(`/api/internal/publicAccess/publicSettings?propertyId=${propertyId}${data ? `&data=${data}` : ""}`, true);
    }

    importArchibusBuilding(archibusBuildingId, instanceUrl)
    {
        const userInfo = this.userData;
        let userData = {
            companyName: deepValue(userInfo, "user.userCompanyInfo.name", "N/A"),
            userFullName: `${deepValue(userInfo, "user.userInfo.firstName", "")} ${deepValue(userInfo, "user.userInfo.lastName", "")}`,
            userEmail: deepValue(userInfo, "user.userInfo.email", "N/A")
        };

        const postBody = {
            instanceUrl,
            archibusBuildingId,
            archibusToken: this.archibusToken.access_token,
            userData
        };
        return this.post("/api/internal/integrations/importProperty", postBody, true);
    }

    getMapOverlays(propertyId, buildingId, floorId)
    {
        return this.get(`/api/internal/mapOverlays?propertyId=${propertyId}&buildingId=${buildingId}&floorId=${floorId}`, true);
    }

    reorderMultipleOverlays(mapOverlays)
    {
        return this.post("/api/internal/mapOverlays/multiIdsUpdate", { mapOverlays }, true);
    }

    createMapOverlay(newOverlay)
    {
        return this.post("/api/internal/mapOverlays", newOverlay, true);
    }

    updateMapOverlay(mapOverlayId, updatedMapOverlay)
    {
        return this.post(`/api/internal/mapOverlays/updateOverlay?id=${mapOverlayId}`, updatedMapOverlay, true);
    }

    updateMultipleOverlays(mapOverlays)
    {
        return this.post("/api/internal/mapOverlays/multiUpdate", { mapOverlays }, true);
    }

    deleteOverlay(mapOverlayId)
    {
        return this.get(`/api/internal/mapOverlays/delete?id=${mapOverlayId}`, true);
    }

    deleteMultipleOverlays(mapOverlayIds)
    {
        return this.post("/api/internal/mapOverlays/multiDelete", { mapOverlayIds }, true);
    }

    createDynamicMapLayer(newDynamicMapLayer)
    {
        return this.post("/api/internal/dynamicMapLayer", newDynamicMapLayer, true);
    }

    getDynamicMapLayers(propertyId, buildingId, floorId)
    {
        return this.get(`/api/internal/dynamicMapLayer?propertyId=${propertyId}&buildingId=${buildingId}&floorId=${floorId}`, true);
    }

    updateDynamicMapLayer(id, updatedDynamicMapLayer)
    {
        return this.post(`/api/internal/dynamicMapLayer/update?id=${id}`, updatedDynamicMapLayer, true);
    }

    deleteDynamicMapLayer(id)
    {
        return this.get(`/api/internal/dynamicMapLayer/delete?id=${id}`, true);
    }

    createMultiDynamicLayers(mapOverlays, dynamicMapLayers)
    {
        return this.post("/api/internal/dynamicMapLayer/createMultiple", { mapOverlays, dynamicMapLayers }, true);
    }

    deleteDynamicMapLayerConfiguration(configurationName, propertyId, buildingId, floorId)
    {
        return this.get(`/api/internal/dynamicMapLayer/deleteConfiguration?configurationName=${configurationName}&propertyId=${propertyId}&buildingId=${buildingId}&floorId=${floorId}`, true);
    }

    createMultipleMapOverlays(mapOverlays)
    {
        return this.post("/api/internal/mapOverlays/multiCreation", { mapOverlays }, true);
    }

    async getPropertyValidation(propertyId)
    {
        return this.get(`/api/internal/navigation/publish/propertyValidation/${propertyId}`, true);
    }

    /**
     * Retrieves validation information for a given navigation property.
     *
     * @param {string} navPropertyId - The ID of the navigation property.
     * @param {boolean} [latestRecordOnly=true] - Flag indicating whether to retrieve
     * validation information for the latest record only default value is true.
     * @return {Promise} - A promise that resolves with the validation information.
     */
    getValidationInfo(navPropertyId, latestRecordOnly = true)
    {
        return this.get(`/api/internal/navigation/publish/4.7/propertyValidationsInfo?propertyId=${navPropertyId}&latestRecordOnly=${latestRecordOnly}`, true);
    }

    async validateEnitreProperty(propertyId)
    {
        console.log("validate entire property");

        try
        {
            let displayName = this.getUserDisplayName();
            let initials = this.getUserInitials();
            return await this.post(`/api/internal/navigation/publish/4.7/entireProperty/validateAndSave/${propertyId}`, { displayName, initials }, true);
        }
        catch (err)
        {
            console.log("validateEnitreProperty Error:", err);
            return;
        }

    }

    async validateBuilding(propertyId, buildingId)
    {
        try
        {
            let displayName = this.getUserDisplayName();
            let initials = this.getUserInitials();

            return await this.post(`/api/internal/navigation/publish/4.7/building/validateAndSave/${propertyId}/${buildingId}`, { displayName, initials }, true);
        }
        catch (err)
        {
            console.log("validateBuilding Error:", err);
            return;
        }
    }

    updateOnlineKeywords(entityLabelId, lang, onlineKeywords, isBuilding)
    {
        return this.post(`/api/internal/labels/updateOnlineKeywords?entityLabelId=${entityLabelId}&lang=${lang}&isBuilding=${isBuilding}`, { onlineKeywords }, true);
    }

    getImagesReference({ page, pageSize = 25, search = "", aspectRatio, sortBy, order, toBeDeleted= false })
    {
        return this.get(`/api/internal/gallery?page=${page}&page_size=${pageSize}${search ? `&search=${search}` : ""}${aspectRatio ? `&aspectRatio=${aspectRatio}` : ""}${sortBy ? `&sortBy=${sortBy}` : ""}${order ? `&order=${order}` : ""}${toBeDeleted ? `&toBeDeleted=${toBeDeleted}` : ""}`, true);
    }

    getLinkedEntities(id)
    {
        return this.get(`/api/internal/gallery/linkedEntities/${id}`, true);
    }

    getIcons(params)
    {
        const { color, theme, kind, searchText, limit = 25, page } = params;
        return this.get(`/api/internal/styled-icons?color=${color}&theme=${theme}&kind=${kind}&searchText=${searchText}&limit=${limit}&page=${page}`, true);
    }

    updateImageReference(id, data)
    {
        return this.patch(`/api/internal/gallery/${id}`, data, true);
    }

    replaceImage(id, data)
    {
        return this.post(`/api/internal/gallery/replaceImage/${id}`, data, true);
    }

    createImageReference(imageReferenceInfo)
    {
        return this.post("/api/internal/gallery", imageReferenceInfo, true);
    }

    deleteImageReference(imageReferenceId) 
    {
        return this.delete(`/api/internal/gallery/${imageReferenceId}`, true);
    }

    softDeleteImage(imageReferenceId, data)
    {
        return this.patch(`/api/internal/gallery/${imageReferenceId}/softDeleteImage`, data, true);
    }

    getThemes(propertyId, defaultMapstedThemes = true)
    {
        return this.get(`/api/internal/themes?propertyId=${propertyId}&defaultMapstedThemes=${defaultMapstedThemes}`, true);
    }

    getStyles(themeId)
    {
        return this.get(`/api/internal/styles?themeId=${themeId}`, true);
    }

    createStyle(style)
    {
        return this.post("/api/internal/styles", style, true);
    }

    updateStyle(style)
    {
        return this.post("/api/internal/styles/update", style, true);
    }

    createTheme(theme, propertyId)
    {
        return this.post(`/api/internal/themes?propertyId=${propertyId}`, theme, true);
    }

    deleteTheme({ themeId, propertyId }) 
    {
        return this.delete(`/api/internal/themes/${themeId}?propertyId=${propertyId}`, true);
    }

    updateTheme(themeId, updateFields, propertyId)
    {
        return this.post(`/api/internal/themes/update?themeId=${themeId}&propertyId=${propertyId}`, updateFields, true);
    }

    getPublishedKiosks(propertyId, buildingId, data)
    {
        return this.get(`/api/internal/publicAccess/kiosks?propertyId=${propertyId}${buildingId ? `&buildingId=${buildingId}` : ""}${data ? `&data=${data}` : ""}`, true);
    }

    getGlobalSettings()
    {
        return this.get(`${this.config.MAPSTED_PUBLIC_URL_MAIN}/api/v2/global/settings`);
    }

    getZones({ propertyId })
    {
        return this.get(`/api/internal/zones?propertyId=${propertyId}`, true);
    }

    getZonesTemplates ({ propertyId })
    {
        return this.get(`/api/internal/zones/getZonesTemplates?propertyId=${propertyId}`, true);
    }

    getOverlayTemplates({ propertyId })
    {
        return this.get(`/api/internal/overlayTemplates?propertyId=${propertyId}`, true);
    }

    getPulishedTemplates({ propertyId })
    {
        return this.get(`/api/internal/overlayTemplates/published?propertyId=${propertyId}`, true);
    }

    createOverlayTemplate(overlayTemplate, propertyId)
    {
        return this.post(`/api/internal/overlayTemplates?propertyId=${propertyId}`, overlayTemplate, true);
    }

    updateOverlayTemplate(overlayTemplateId, overlayTemplate)
    {
        return this.patch(`/api/internal/overlayTemplates/${overlayTemplateId}`, overlayTemplate, true);
    }

    deleteOverlayTemplate(overlayTemplateId)
    {
        return this.delete(`/api/internal/overlayTemplates/${overlayTemplateId}`, true);
    }
    updatePropertyIconImage(propertyId, data)
    {
        return this.patch(`/api/internal/properties/${propertyId}/updatePropertyIconImage`, data, true);
    }

    updatePropertyCoverImages(propertyId, data)
    {
        return this.patch(`/api/internal/properties/${propertyId}/updatePropertyCoverImages`, data, true);
    }
}



export default new serverAPI();
