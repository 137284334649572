import React from 'react';
import { Scrollbars } from "react-custom-scrollbars-2";
import { Header, Popup, } from "semantic-ui-react";
import { MAP_EDITOR_TOOLS, TRANSITIONS_TOGGLABLE_LAYERS, } from '../../../_constants/mapEditor';
import { ButtonIcon } from '../../common/ButtonIcon';
import { MapEditorSidebarItem } from './MapEditorLeftbar';
import MapEditorSidebarAccordion from './MapEditorSidebarAccordion';
import { GET_TRANSITION_TYPE_LEGEND } from 'mapsted.maps/utils/defualtStyles';

const legendOptions = GET_TRANSITION_TYPE_LEGEND().map((legend) => ({
    ...legend,
    iconUrl: `/img/transitions/${legend.label.replace(/([a-z])([A-Z])/g, "$1 $2").split("_").map((w) => w.toLocaleLowerCase()).join("-")}.svg`
}));
export const MapEditorSidebarTransitions = ({ selectedTool, onToolClick, onShowNotLockedByUserAlert }) =>(
    <div className="mapEditorSidebar">
        <Header>Nodes</Header>
        <Scrollbars className="mapEditorScroll" autoHeight autoHeightMin="calc(100vh - 220px)" autoHeightMax="calc(100vh - 220px)" >
            <div className="alignerMapEditor">
                <div className="mapEditorSidebarGroup">
                    {/* TODO map this to avoid duplicate code */}

                    <MapEditorSidebarItem
                        icon="icon-editor-create-transitions"
                        name={MAP_EDITOR_TOOLS.Create_Transition}
                        content={MAP_EDITOR_TOOLS.Create_Transition}
                        active={selectedTool === MAP_EDITOR_TOOLS.Create_Transition}
                        onClick={onToolClick} />

                    <MapEditorSidebarItem
                        icon="icon-edit"
                        name={MAP_EDITOR_TOOLS.Edit_Transition}
                        active={selectedTool === MAP_EDITOR_TOOLS.Edit_Transition}
                        onClick={onToolClick}
                        content={MAP_EDITOR_TOOLS.Edit_Transition} />

                </div>

                <MapEditorSidebarAccordion
                    mapLayerOptions={TRANSITIONS_TOGGLABLE_LAYERS}
                    onShowNotLockedByUserAlert={onShowNotLockedByUserAlert}
                    legendTitle="Transition Types"
                    legendOptions={legendOptions}
                />
                {/* <div className="validationErrorGroup">
                    <ValidationErrorInfoToolBar isFilterByActiveBuilding />
                </div> */}
            </div>
        </Scrollbars>
        {/* <ShortCutsPopup /> */}
    </div>
);

const ShortCutsPopup = () => (
    <Popup className="shortcutsPopup"
        trigger={<ButtonIcon className="shortcutsPopupTrigger" icon="editor-control" content="Shortcuts" />}
        basic
        on="click"
    >
        {/* <ShortCutsPopupItem title="Ctrl + Z" content="Undo" />
        <ShortCutsPopupItem title="Ctrl + Y" content="Redo" />
        <ShortCutsPopupItem title="ESC" content="Escape" />
        <ShortCutsPopupItem title="Shift + L" content="Link" />
        <ShortCutsPopupItem title="Shift + N" content="Node" />
        <ShortCutsPopupItem title="Shift + A" content="Auto Link" />
        <ShortCutsPopupItem title="Shift + D" content="Delete" />
        <ShortCutsPopupItem title="Shift + R" content="Delete & Reconnect" />
        <ShortCutsPopupItem title="Alt + Mouse" content="Selection" /> */}
    </Popup>
);  
