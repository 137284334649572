import React from "react";
import classNames from "classnames";
import "./TextGroup.css";

export const TextGroup = ({
    className = undefined,
    flexible = false,
    title = undefined,
    heading = undefined,
    content = undefined,
    children = undefined
}) => (
    <div className={classNames("textGroup", { textGroupFlexible: flexible }, className)}>
        {(title) && (<p>{title}</p>)}
        {(heading) && (<b>{heading}</b>)}
        <div className="textGroupContent">
            {content}
            {children}
        </div>
    </div>
);
