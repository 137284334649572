import React from 'react';
import { Grid } from 'semantic-ui-react';

export const GridTableColumn = ({title,detail,subDetail,children}) =>
{
    return (
        <Grid.Column>
            {title && <strong>{title}</strong>}
            {detail && detail}
            {subDetail && <span>{subDetail}</span>}
            {children}
        </Grid.Column>
    );
}