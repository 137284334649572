import { kinks, polygon, area, length, lineString } from "@turf/turf";
import { EntityType } from "mapsted.maps/utils/entityTypes";

export const SELF_FILTER_TYPES =
{
    SEARCH: "search",
    SELF_INTERSECTION: "self intersection", // finds all entities that contain a self intersection.
    ANGLE: "angle", // finds all entities that contain an angle within the range given in options
    AREA: "area", // finds all entities that have an area less than the range in square meters 
    SIDE_LENGTH: "side length", // finds all entities that have a side length less than the range in  meters 
}

export const SELF_FILTER_DEFAULT_OPTIONS =
{
    [SELF_FILTER_TYPES.SELF_INTERSECTION]: {},
    [SELF_FILTER_TYPES.ANGLE]: {
        minValue: 0, // degrees
        maxValue: 5, // degrees 
    },
    [SELF_FILTER_TYPES.AREA]: {
        maxValue: 0, // meters
    },
    [SELF_FILTER_TYPES.SIDE_LENGTH]: {
        maxValue: .001, // meters
    }

}

/**
 * Class for finding all entities that contain an angle within the given range
 * used for finding entities with sharp angles
 */
export class EntitySelfFilterAccess
{
    /**
     * 
     * @param {Array} entities 
     * @param {String} filterType - selfIntersection, angle 
     * @param {Object} options - selfIntersection = {}, angle = {minValue, maxValue}
     */
    constructor(entities, filterType, options)
    {
        // null check
        if (!Array.isArray(entities) || !Object.values(SELF_FILTER_TYPES).includes(filterType))
        {
            return;
        }

        this.filteredEntities = {};
        this.options = options || SELF_FILTER_DEFAULT_OPTIONS[filterType];
        this.filterType = filterType;

        this.#initializeEntitySelfFilterAccess(entities);
    }

    /**
     * Check if the entity should be filtered out depending on filter type and options.
     * @param {*} entity 
     * @returns 
     */
    isEntityFiltered = (entity) =>
    {
        // get polygon from entity
        const { filterType, options } = this;
        const { minValue, maxValue, featureSearchResults } = options;

        let entityPolygon = polygon(entity.shape.coordinates);

        switch (filterType)
        {
            case (SELF_FILTER_TYPES.SELF_INTERSECTION):
                {
                    // Filter entities based off self intersections
                    // turf kinks returns all self intersections in a polygon 
                    let selfIntersects = kinks(entityPolygon);

                    if (selfIntersects.features.length > 0)
                    {
                        return true;
                    }
                    break;
                }
            case (SELF_FILTER_TYPES.ANGLE):
                {
                    // Filter entities based off of min, max angle

                    // check if there exists an angle that exists between min and max angle 
                    for (let i = 0; i < entity.shape.coordinates.length; i++)
                    {
                        let polygonRing = entity.shape.coordinates[i];

                        let edges = this.#getEdgesFromCoordinates(polygonRing);

                        // check every two points of the polygon
                        let prevEdge = edges[0];

                        // need to make sure the last edge and the first edge are compared
                        edges.push(prevEdge);

                        for (let j = 1; j < edges.length; j++)
                        {
                            let edge = edges[j];

                            let angle = Math.abs(this.#getAngleBetweenEdges(prevEdge, edge));

                            if (angle >= minValue && angle <= maxValue)
                            {
                                return true;
                            }
                            prevEdge = edge;
                        }
                    };
                    break;
                }
            case (SELF_FILTER_TYPES.AREA):
                {
                    // filters entities based off of max area in sqr meters 

                    let polygonArea = area(entityPolygon);

                    if (polygonArea <= maxValue)
                    {
                        return true;
                    }

                    break;
                }
            case (SELF_FILTER_TYPES.SIDE_LENGTH):
                {
                    // check if there exists an angle that exists between min and max angle 
                    for (let i = 0; i < entity.shape.coordinates.length; i++)
                    {
                        let polygonRing = entity.shape.coordinates[i];

                        let edges = this.#getEdgesFromCoordinates(polygonRing);

                        for (let i = 0; i < edges.length; i++)
                        {
                            let polygonEdge = lineString(edges[i]);
                            let edgeLength = length(polygonEdge, { units: "meters" });

                            if (edgeLength <= maxValue)
                            {
                                return true;
                            }
                        }
                    }
                    break;
                }
            case (SELF_FILTER_TYPES.SEARCH):
                {
                    const entityInSearchResults = featureSearchResults.find((searchResult) =>
                    {
                        return searchResult._id === entity._id
                    });

                    return !!entityInSearchResults;
                }
            default: {
                return false
            }
        }

        return false;
    }

    /**
     * Removes entity from filtered list using entity._id
     * @param {Object} entity - entity to be removed,  
     */
    handleRemoveEntity = (entity) =>
    {
        let filteredEntities = this.filteredEntities;

        delete filteredEntities[entity._id];

        this.filteredEntities = filteredEntities;
    }

    /**
     * Checks if the entity should still be in the filtered list 
     * to be used after an entity's geometry changed
     * @param {Object} entity 
     */
    handleEntityUpdate = (entity) =>
    {
        this.handleRemoveEntity(entity);

        let filteredEntities = this.filteredEntities;

        let isEntityFiltered = this.isEntityFiltered(entity);

        if (isEntityFiltered)
        {
            filteredEntities[entity._id] = entity;
        }

        this.filteredEntities = filteredEntities;
    }

    /**
    * @returns list of filtered ids from smart filter results
    */
    getListOfFilteredEntityIds = () =>
    {

        return Object.values(this.filteredEntities || {}).map((entity) =>
        {
            return {
                navId: entity.entityId || -1,
                cmsId: entity._id
            }
        });
    }

    /**
     * 
     * @returns filtered entities map 
     */
    getFilteredEntitiesMap = () =>
    {
        return this.filteredEntities;
    }


    //#region Helpers

    /**
     * Initializes the object by filling out filtered entities with all entities that match the give filter
     * @param {*} entities 
     * @returns 
     */
    #initializeEntitySelfFilterAccess(entities)
    {
        let startTime = performance.now();
        let { filteredEntities, } = this;

        // IF Entities is not an array, return...
        if (!Array.isArray(entities))
        {
            return;
        }

        // loop through all the entities 
        // add all entities that can check for polygon intersect to rTreeMap that will be loaded into a rTree
        for (let i = 0; i < entities.length; i++)
        {
            let entityI = entities[i];

            if (!this.canFilterEntity(entityI))
            {
                continue;
            }

            // check polygon angles
            let isEntityFiltered = this.isEntityFiltered(entityI);

            if (isEntityFiltered)
            {
                filteredEntities[entityI._id] = entityI;
            }
        }

        let endTime = performance.now();
        console.log(`find all polygons that match filter of length {(${entities.length})} took ${endTime - startTime} milliseconds `);
        this.filteredEntities = filteredEntities;
    }

    /**
     * Checks if the entity is valid to check for a polgon intersect
     * @param {*} entity 
     * @returns boolean
     */
    canFilterEntity = (entity) =>
    {
        if (entity.entityType !== EntityType.BOUNDARY)
        {
            return entity?.shape?.type === "Polygon";
        }
    }

    /**
   * https://stackoverflow.com/questions/42159032/how-to-find-angle-between-two-straight-lines-paths-on-a-svg-in-javascript
   * @param {Array} EdgeA - two coordinate pairs
   * @param {Array} EdgeB - two coordinate pairs 
   * @returns 
   */
    #getAngleBetweenEdges = (EdgeA, EdgeB) =>
    {
        // currently giving us outside angle
        let [A1, A2] = EdgeA;
        let [B1, B2] = EdgeB;

        let dAx = A2[0] - A1[0];
        let dAy = A2[1] - A1[1];
        let dBx = B2[0] - B1[0];
        let dBy = B2[1] - B1[1];
        let angle = Math.atan2(dAx * dBy - dAy * dBx, dAx * dBx + dAy * dBy);
        if (angle < 0) { angle = angle * -1; }
        let degree_angle = 180 - (angle * (180 / Math.PI));

        return degree_angle;
    }

    #getEdgesFromCoordinates = (coordinates) =>
    {
        let edges = [];
        for (let i = 0; i < coordinates.length - 1; i++)
        {
            edges.push([coordinates[i], coordinates[i + 1]]);
        }

        return edges;
    }
    //#endregion Helpers
}



