import React from "react";
import { Trans } from "react-i18next";

const contactUsMessages =
{
  floorsMessage: <Trans i18nKey="contactUsMessages.My_building_has_more_than_20_floors__Ple" />,

  advancedPushNotifcationMessage: <Trans i18nKey="contactUsMessages.Hey_there__I'm_interested_in_learning_mo" />,

  analyticsMessage: <Trans i18nKey="contactUsMessages.Hey_there__I’m_interested_in_learning_mo" />,

  marketingMessage: <Trans i18nKey="contactUsMessages.Hey_there__Im_interested_in_learning_mor" />,

  positioningMessage: <Trans i18nKey="contactUsMessages.Hey_there__I'm_interested_in_learning_mo" />,

  // newLineExample: encodeURI("Hey there, \n\rmy building has more than 20 floors. I was wondering if there's any way to upload them in the Manage CMS system. Please give me a call/ send me a message to schedule a time. Cheers!"),

}

export default contactUsMessages;