const { Vector: VectorLayer, } = require("ol/layer");
const { Vector: VectorSource, } = require("ol/source");
const { Point, LineString, Polygon, SimpleGeometry, } = require("ol/geom");
const { GeoJSON, } = require("ol/format");
const { Style, Fill, Stroke, } = require("ol/style");
const { PolygonAccess } = require("../geometry/polygonAccess");
const { toMercatorFromArray, toGpsLocation } = require("../utils/map.utils");
const { ShapeTypes, EntityRefTypes, EntityType, BoundaryEntityType } = require("../utils/entityTypes");
const { MapConstants, DEFAULT_LANGUAGE_CODE } = require("../utils/map.constants");
const { deepValue } = require("mapsted.utils/objects");
const { styleClassic, STYLE_OPTIONS } = require("../utils/defualtStyles");
const { Feature } = require("ol");
const { v4: uuidv4 } = require("uuid");
const turf = require("@turf/turf");
const { fromCircle } = require("ol/geom/Polygon");
const booleanIntersects = require("@turf/boolean-intersects").default;


class PolgyonController
{
    constructor({ accessor })
    {
        this.accessor = accessor;
    }

    /**
     * Calculates the center of a open layers polygon.
     *
     * @param {ol.geom.Polygon} polygon - The polygon, using mercator coords.
     * @return {Array} The centroid coordinates of the polygon.
     */
    getOLPolygonCenter(polygon)
    {
        const coordinates = polygon.getCoordinates();

        const polygonAccessor = new PolygonAccess(coordinates);

        const centroid = polygonAccessor.getCentroidProjectedIntoPolygon();

        const centroidCoords = centroid.getCoordinates();

        return centroidCoords;
    }

    /**
     * @param {Array} entities
     * @returns boundary polygon or undefined
     */
    findBoundaryPolygon(entities)
    {
        for (const entity of entities)
        {
            const access = new this.accessor(entity);
            // Find boundary polygon
            const isBoundaryType = access.getEntityType() === EntityType.BOUNDARY;
            const isBoundarySubType = access.getSubEntityType() === BoundaryEntityType.PROPERTY_BOUNDARY
                || access.getSubEntityType() === BoundaryEntityType.FLOOR_BOUNDARY;

            if (isBoundaryType && isBoundarySubType)
            {
                const entityGeometry = this.createEntityGeometry(entity);
                return entityGeometry;
            }
        }
        return undefined;
    }

    /**
     * Iterates over the entities to find and return boundary polygon information.
     *
     * @param {Array} entities - The list of entities to iterate over.
     * @return {{boundaryPolygon: Object, boundaryPolygonAreaInSqMt: number, boundaryPolygonEntity: Object}|{}} An object containing boundary polygon information, or an empty object if no boundary polygon is found.
     * boundaryPolygon - The boundary polygon geometry.
     * boundaryPolygonAreaInSqMt - The area of the boundary polygon in square meters.
     * boundaryPolygonEntity - The entity that contains the boundary polygon.
     */
    findBoundaryPolygonInformation(entities)
    {
        for (const entity of entities)
        {
            const access = new this.accessor(entity);
            // Find boundary polygon
            const isBoundaryType = access.getEntityType() === EntityType.BOUNDARY;
            const isBoundarySubType = access.getSubEntityType() === BoundaryEntityType.PROPERTY_BOUNDARY
                || access.getSubEntityType() === BoundaryEntityType.FLOOR_BOUNDARY;

            if (isBoundaryType && isBoundarySubType)
            {
                const entityGeometry = this.createEntityGeometry(entity);
                return { boundaryPolygon: entityGeometry, boundaryPolygonAreaInSqMt: turf.area(access.getShape()), boundaryPolygonEntity: entity };
            }
        }
        return {};
    }

    createEntityGeometry(entity)
    {
        const access = new this.accessor(entity);
        const shape = access.getShape();

        let geometry = undefined;

        switch (shape.type)
        {
            case ShapeTypes.POINT:
                {
                    let coordinates = toMercatorFromArray(shape.coordinates);
                    geometry = new Point(coordinates);
                    break;
                }

            case ShapeTypes.LINE_STRING:
                {
                    let coordinates = [];
                    (shape.coordinates) && shape.coordinates.forEach((coordinate) =>
                    {
                        coordinates.push(toMercatorFromArray(coordinate));
                    });

                    geometry = new LineString(coordinates);
                    break;
                }


            case ShapeTypes.POLYGON:
                {
                    let coordinates = [];
                    (shape.coordinates) && shape.coordinates[0].forEach((coordinate) =>
                    {
                        coordinates.push(toMercatorFromArray(coordinate));
                    });
                    coordinates = [coordinates];
                    geometry = new Polygon(coordinates);
                    break;
                }

            // TEXT POINT are points that are already in mercator
            case ShapeTypes.TEXT_POINT:
                {
                    geometry = new Point(shape.coordinates);
                    break;
                }

            default:
                {
                    geometry = {};
                    break;
                }
        }

        return geometry;
    }

    /**
     * Creats a style objects based on the params given.
     *
     * @param {Object} style
     * @param {Object} [style.stroke]
     * @param {Object} [style.fill]
     */
    createEntityStyle(style = {})
    {
        const { stroke, fill } = style;

        if (!!stroke && !!fill)
        {
            let style = new Style({
                stroke: new Stroke(stroke),
                fill: new Fill(fill),
            });

            return style;
        }
        else
        {
            return undefined;
        }
    }

    createLayersFromStyle(style)
    {
        let entityLayers = {};

        style.layerStyles.forEach((layerStyle) =>
        {
            const vectorLayer = new VectorLayer({
                minZoom: layerStyle.minZoomLevel,
                maxZoom: layerStyle.maxZoomLevel,
                opacity: layerStyle.defaultOpacity,
                id: parseInt(layerStyle.layerIdx),
            });

            vectorLayer.setSource(new VectorSource());
            entityLayers[parseInt(layerStyle.layerIdx)] = vectorLayer;

        });

        return entityLayers;
    }

    createEntityFeature(entity, style)
    {
        const access = new this.accessor(entity);

        /* 
         if entity is non-nameable prefer vacant style first,
         if vacant style option is not available in the current style object fallback to default style, this is handled in getStyleObject method
        */
        const styleOption = !access.getCanNameEntity() ? STYLE_OPTIONS.VACANT : STYLE_OPTIONS.DEFAULT;

        // Create Style
        const styleObject = access.getStyleObject(style, styleOption);

        const entityStyle = this.createEntityStyle(styleObject);

        // Create Geometry
        const entityGeometry = this.createEntityGeometry(entity);

        const entityArea = turf.area(access.getShape());

        //Create feature
        let featureId = access.getId();
        let feature = new Feature({
            geometry: entityGeometry,
            // style: entityStyle,
            id: featureId,
            canName: access.getCanNameEntity(),
            area: entityArea,
            entityType: access.getEntityType(),
            subEntityType: access.getSubEntityType(),
        });
        feature.setId(featureId);

        (entityStyle) && feature.setStyle(entityStyle);

        return feature;
    }

    removeFeatureFromLayer(layer, feature)
    {
        const source = layer.getSource();
        source.removeFeature(feature);
    }

    /**
     * Updates the entity's style with the options given.
     *
     * @param {Object} feature - feature to be updated
     * @param {Object} styleOptions - new style options
     */
    changeEntityStyle(feature, styleOptions)
    {
        const entityStyle = this.createEntityStyle(styleOptions);

        (feature) && feature.setStyle(entityStyle);
    }

    getTranslatedExtent(extent, startCoordiante, endCoordinate)
    {
        let imgBox = this.getBoundingBoxPolygonFromExtent(extent);

        let translatedImgBox = this.getTranslatedPolygon(imgBox, startCoordiante, endCoordinate);

        if (!translatedImgBox)
        {
            return;
        }

        // set new extent
        const newExtent = turf.bbox(translatedImgBox);

        return newExtent;
    }

    getBoundingBoxPolygonFromExtent(extent)
    {
        const bbox = turf.bboxPolygon(extent);
        return bbox;
    }

    getTranslatedPolygon(polygon, startCoordinate, endCoordinate)
    {
        try
        {
            const geometry = new Polygon(polygon.geometry.coordinates);

            this.translateGeometry(geometry, startCoordinate, endCoordinate);

            const translatedPolygon = this.convertFeatureGeometryToGeoJSON(geometry);

            return translatedPolygon;
        }
        catch (err)
        {
            console.log("err", err);
            return undefined;
        }
    }

    /**
     * MUTATES the given geometry
     * @param {object} geometry
     * @param {Array<number>} startCoordinate
     * @param {Array<number>} endCoordinate
     */
    translateGeometry(geometry, startCoordinate, endCoordinate)
    {
        geometry.translate(endCoordinate[0] - startCoordinate[0], endCoordinate[1] - startCoordinate[1]);
    }

    convertFeatureGeometryToGeoJSON(featureGeometry)
    {
        let format = new GeoJSON();
        return format.writeGeometryObject(featureGeometry);
    }

    /**
     *
     * @param {Array<Array<number>>} points
     * @param {*} polygon
     * @param {undefined | number} scaleFactor scale factor applied to the points before testing them, undefined leaves the points unmodified
     * @returns {boolean}
     */
    isPointsInPolygon(points, polygon, scaleFactor = undefined)
    {
        const turfPoints = turf.points(points);
        let pointsToTest = turfPoints;
        if (scaleFactor && Number.isFinite(scaleFactor))
        {
            const wgs84Poly = turf.toWgs84(turf.combine(turfPoints));
            const squishedPoints = turf.explode(turf.toMercator(turf.transformScale(wgs84Poly, 0.5, { origin: "center" })));
            pointsToTest = squishedPoints;
        }

        let ptsWithin = turf.pointsWithinPolygon(pointsToTest, polygon);
        if (ptsWithin.features.length === pointsToTest.features.length)
        {
            return true;
        }
        return false;
    }

    /**
     * Creates entity labels for property entities using building information.
     * @param {Object} entities
     * @param {Object} property
     */
    linkBuildingsToEntityLabel(entities, property)
    {
        if (!!entities && !!property)
        {
            Object.values(entities).forEach((entity) =>
            {
                // check that the entity belongs to a property and is linked to a building.
                if (entity.refType === EntityRefTypes.PROPERTY && !!entity.building)
                {
                    entity.entityLabel = deepValue(property, `allBuildings.${entity.building}`, {});
                }
            });
        }
        return;
    }

    /**
     * creates a merged polygon from the set of selected polygons.
     *
     * note polygons are expected to be interecting.
     *
     * @param {Object} entities - selected entites to be merged
     *
     * @returns The merged polygon in GeoJSON format, or undefined if the polygons given are not connected.
     */
    getMergedPolygon(entities)
    {
        // Object structure of entities;
        //     entities = {
        //          [entityId]:{
        //              feature,
        //              textFeature,
        //         }
        //     }

        let entityIds = Object.keys(entities);
        let format = new GeoJSON();

        let geoPolygonList = [];
        let mergedPolygon = undefined;

        entityIds.forEach((entityId) =>
        {
            const polygon = format.writeFeatureObject(entities[entityId].feature);
            geoPolygonList.push(polygon);
        });

        mergedPolygon = geoPolygonList.pop();

        // merge all polygons one by one.
        while (geoPolygonList.length > 0)
        {
            const intersectionIdx = this.indexOfIntersectingPolygon(geoPolygonList, mergedPolygon);

            if (intersectionIdx !== undefined)
            {
                mergedPolygon = turf.union(mergedPolygon, geoPolygonList[intersectionIdx]);
                geoPolygonList.splice(intersectionIdx, 1);
            }
            else
            {
                // If the merged polygon does not find an intersection, break from loop.
                break;
            }
        }

        // all polygons merged - return merged polygon.
        if (geoPolygonList.length === 0)
        {
            // convert coords to gps. (loop  through each set if we want to support multipolygon in the future)
            mergedPolygon.geometry.coordinates.forEach((set, i) =>
            {
                let coords = [];
                set.forEach((coordinate) =>
                {
                    coords.push(toGpsLocation(coordinate));
                });

                mergedPolygon.geometry.coordinates[i] = coords;
            });

            return mergedPolygon;
        }
        // some polygons where not connected - return undefined.
        else
        {
            return undefined;
        }
    }

    /**
     * finds index of the polygon in polygonList intersects with polygon polygon.
     *
     * @param {Array} polygonList - List of polygons
     * @param {Object} polygon - Polygon loking for an intersection
     */
    indexOfIntersectingPolygon(polygonList, polygon)
    {

        if (polygonList.length === 0)
        {
            return undefined;
        }

        for (let i = 0; i < polygonList.length; i++)
        {
            let intersection = turf.intersect(polygon, polygonList[i]);

            if (intersection)
            {
                return i;
            }
        }

        return undefined;
    }

    /**
     * Checks if the two features given are connected;
     * @param {Array<object>} features [feature1, feature2]
     * @returns {boolean}
     */
    areFeaturesConnected([feature1, feature2])
    {
        let format = new GeoJSON();
        let entityGeo1 = format.writeFeatureObject(feature1);
        let entityGeo2 = format.writeFeatureObject(feature2);
        const intersect = turf.booleanIntersects(entityGeo1, entityGeo2);
        return intersect;
    }

    /**
     * Checks if the two entites given are connected;
     * @param {Array<object>} features [entityFeature1, entityFeature2]
     *
     */
    areEntitiesConnected([entityFeature1, entityFeature2])
    {
        return this.areFeaturesConnected([entityFeature1.feature, entityFeature2.feature]);
    }

    /**
 * Splits a polygon using openlayer features
 * @param {*} polygonFeature
 * @param {*} lineFeature
 */
    getSplitPolygons(polygonFeature, lineFeature)
    {
        let format = new GeoJSON();

        let polygon = format.writeFeatureObject(polygonFeature);
        let line = format.writeFeatureObject(lineFeature).geometry;

        return this.splitPolygon(polygon, line);
    }

    /**
     * https://gis.stackexchange.com/questions/344068/splitting-a-polygon-by-multiple-linestrings-leaflet-and-turf-js
     *
     * Splits polygon with geoJSON objects
     * @param {*} polygon
     * @param {*} line
     */
    splitPolygon(polygon, line)
    {
        const THICK_LINE_UNITS = "meters";
        const THICK_LINE_WIDTH = 0.001;

        let offsetLine = [];

        let splitPolygons = [];


        // Check if the line intersects the polygon, must intersect at two points,
        // or mod 2 ===0 for more complex splits.
        const intersectPoints = turf.lineIntersect(polygon, line);

        if (intersectPoints.features.length === 0 || ((intersectPoints.features.length % 2) != 0))
        {
            return undefined;
        }

        // Check if start and end coords are in the polygon.
        // If start or end point are in the polygon, return undefined.
        const lineCoords = turf.getCoords(line);

        if ((turf.booleanWithin(turf.point(lineCoords[0]), polygon)
            || (turf.booleanWithin(turf.point(lineCoords[lineCoords.length - 1]), polygon))))
        {
            console.log("return undefined");
            return undefined;
        }

        // Create split two split lines, for top and bottom half.
        offsetLine[0] = turf.lineOffset(line, THICK_LINE_WIDTH, { units: THICK_LINE_UNITS });
        offsetLine[1] = turf.lineOffset(line, -THICK_LINE_WIDTH, { units: THICK_LINE_UNITS });


        for (let i = 0; i <= 1; i++)
        {
            // Case 1 (cutIdx = 0, selectIdx =1)
            // Case 2 (cutIdx = 1, selectIdx =0)
            let cutIdx = i;
            let selectIdx = (i + 1) % 2;

            // Create polygon out of the line with current offset
            // We need to turn our intersecting line into a polygon because of we are using turf.diffrence to cut the polygon
            let polyCoords = [];

            // Creates side 1 of cutting polygon
            for (let j = 0; j < line.coordinates.length; j++)
            {
                polyCoords.push(line.coordinates[j]);
            }

            // Creates side 2 of cutting polygon
            for (let j = (offsetLine[cutIdx].geometry.coordinates.length - 1); j >= 0; j--)
            {
                polyCoords.push(offsetLine[cutIdx].geometry.coordinates[j]);
            }

            // Close the polygon
            polyCoords.push(line.coordinates[0]);

            // Turn the collection of points into a line string then a polygon
            let thickLineString = turf.lineString(polyCoords);
            let thickLinePolygon = turf.lineToPolygon(thickLineString);

            // Get our difference polygon
            // We should receive a polygon on each side of the thick line  polygon. one side has missing area due to the thick line cut.
            let clipped = turf.difference(polygon, thickLinePolygon);

            let cutPolyGeoms = [];

            // Check if clipped polygon intersects with the opposite offsetLine to avoid picking the side that lost area due to the think line polygon cut.
            for (let j = 0; j < clipped.geometry.coordinates.length; j++)
            {
                let clippedPolygon = turf.polygon(clipped.geometry.coordinates[j]);
                let intersect = turf.lineIntersect(clippedPolygon, offsetLine[selectIdx]);

                if (intersect.features.length > 0)
                {
                    cutPolyGeoms.push(clippedPolygon.geometry.coordinates);
                }
            }

            cutPolyGeoms.forEach((coordinates) =>
            {
                // convert coords to gps. (loop  through each set if we want to support multipolygon in the future)
                coordinates.forEach((set, i) =>
                {
                    let coords = [];
                    set.forEach((coordinate) =>
                    {
                        coords.push(toGpsLocation(coordinate));
                    });

                    coordinates[i] = coords;
                });

                const splitPolygon = turf.polygon(coordinates);

                console.log("split area", turf.area(splitPolygon));
                if (turf.area(splitPolygon) < MapConstants.MIN_SPLIT_AREA)
                {
                    return undefined;
                }

                splitPolygons.push(turf.polygon(coordinates));
            });
        }

        return splitPolygons;
    }

    convertGeoPolygonToEntity(polygon, entityTemplate)
    {
        // Create new entity
        let entityId = uuidv4();
        let polygonId = uuidv4();

        return {
            entityType: entityTemplate.entityType,
            subEntityType: entityTemplate.subEntityType,
            textRotation: 0,
            refType: entityTemplate.refType,
            ref: entityTemplate.ref,
            entityId,
            polygonId,
            draft: true,
            shape: polygon.geometry,
            style: {
                [DEFAULT_LANGUAGE_CODE]: {
                    useText: true
                }
            }
        };
    }

    intersects(shape1, shape2)
    {
        return booleanIntersects(shape1, shape2);
    }

    /**
     *
     * @param {SimpleGeometry} geometry
     * @param {Array} entities
     */
    intersectingEntities(geometry, entities)
    {
        let intersectingGeometry = geometry;
        if (geometry.getType() === "Circle")
        {
            intersectingGeometry = fromCircle(geometry);
        }

        const intersectingGeoJSON = this.convertFeatureGeometryToGeoJSON(intersectingGeometry);

        return entities
            .filter((entity) => entity && entity.feature)
            .filter((entity) =>
            {
                const entityGeometry = entity.feature.getGeometry();
                const entityGeoJSON = this.convertFeatureGeometryToGeoJSON(entityGeometry);
                return this.intersects(entityGeoJSON, intersectingGeoJSON);
            });
    }
}

module.exports = PolgyonController;
