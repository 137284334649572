import React, { useCallback, useEffect, useRef, useState } from "react";
import { Input } from "semantic-ui-react";
import { useFocus } from "../../../../hooks/useFocus";
import { useTranslation } from "react-i18next";

function ImageSearchBar({ searchValue, onChange, disableSearch }) 
{
    const [inputValue, setInputValue] = useState("");
    const inputRef = useFocus();
    const trans = useTranslation().t;

    useEffect(() => 
    {
        setInputValue(searchValue);
    }, [searchValue]);

    useEffect(() => 
    {
        let timer;
        if (searchValue !== inputValue)
        {
            timer = setTimeout(() => 
            {
                onChange(inputValue);
            }, 1000);
        }

        return () => 
        {
            clearTimeout(timer);
        };
    }, [inputValue]);


    return (
        <Input
            ref={inputRef}
            disabled={disableSearch}
            className="searchIcon searchIcon--mod"
            placeholder={trans("Gallery.Search")}
            icon={<img src="/img/icon-search.svg" />}
            value={inputValue}
            onChange={(e) => 
            {
                setInputValue(e.target.value);
            }}
        />
    );
}

export default ImageSearchBar;
