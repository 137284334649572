import React, { useRef, useState, FC } from "react";
import { Tab, Segment, Header, Button } from "semantic-ui-react";

import { handleUploadFile, DEFAULT_MAX_FILE_SIZE_BYTES } from "./utils";

import { ErrorLabel } from './ErrorLabel';

interface UploadTabProps {
    acceptableImageFileFormats: string;
    onChange: Function;
    text: UploadTabText;
    returnRawFile?: boolean;
    maxFileSizeBytes?: number;
}

export interface UploadTabText {
    title: string;
    dragNDrop: string;
    or: string;
    browse: string;
    filetypeError: string;
    sizeError: string;
}

export const UploadTab : FC<UploadTabProps> = ({
    onChange,
    acceptableImageFileFormats,
    text,
    returnRawFile = false,
    maxFileSizeBytes = DEFAULT_MAX_FILE_SIZE_BYTES
}) =>
{
    const fileRef = useRef<HTMLInputElement>();
    const [state, setState] = useState({
        uploadError: false,
        sizeError: false,
    });

    const handleUpdateImage = (image) => onChange("image", image);

    const handleUpdateFile = (file) =>
    {
        onChange("fileName", file.name);
        fileRef.current.value = "";
    };

    const handleFileChange = () => validateAndUploadFiles(fileRef.current.files);

    const handleOnBrowsClick = () => fileRef.current.click();

    const handleDrop = (e) =>
    {
        e.preventDefault();
        validateAndUploadFiles(e.dataTransfer.files);
    };

    const handleDragOver = (e) => e.preventDefault();

    /**
     * Checks if uploadFiles are a file type from the acceptable file types.
     * If they are it continues to upload the files.
     * Else it sets error state to true.
     * @param {} uploadFiles
     */
    const validateAndUploadFiles = (uploadFiles : FileList) =>
    {
        const fileFormats = acceptableImageFileFormats.split(",");
        const uploadFilesArray = Array.from(uploadFiles);

        // Validate files based on file type and file size
        let files = uploadFilesArray.filter((file) =>
        {
            const validFileType = fileFormats.includes(file.type) || fileFormats.includes(getFileExtention(file.name));
            const underMaxSize = file.size <= maxFileSizeBytes;
            return validFileType && underMaxSize;
        });

        if (files.length > 0)
        {
            if (returnRawFile)
            {
                onChange(files);
            }
            else
            {
                handleUploadFile({ files }, handleUpdateImage, handleUpdateFile);
            }
            setState({ uploadError: false, sizeError: false });
        }
        else
        {
            // Check for error to display.
            const uploadError = uploadFilesArray.some((file) => !fileFormats.includes(file.type));
            const sizeError = uploadFilesArray.some((file) => file.size >= DEFAULT_MAX_FILE_SIZE_BYTES);
            setState({ uploadError, sizeError });
        }
    };

    const getFileExtention = (fileName) => fileName.substring(fileName.lastIndexOf("."));

    const { uploadError, sizeError } = state;

    return (
        <Tab.Pane>
            <Segment placeholder className="brandUpload" onDrop={handleDrop} onDragOver={handleDragOver} >
                <Header icon content={text.dragNDrop} subheader={text.or} />
                <Button basic primary size="large" content={text.browse} onClick={handleOnBrowsClick} />
                <input type="file"
                    ref={fileRef}
                    style={{ display: "none" }}
                    accept={acceptableImageFileFormats}
                    onChange={handleFileChange}
                />
            </Segment>
            {(uploadError) && <ErrorLabel label={text.filetypeError}/>}
            {(sizeError) && <ErrorLabel label={text.sizeError}/>}
        </Tab.Pane>
    );
};

export default UploadTab;
