export const IMAGE_ASPECT_RATIOS = {
    RATIO_1: 1,
    RATIO_3: 3,
};

export const ACCEPTABLE_IMAGE_FILE_FORMATS = "image/x-png,image/png,image/jpeg,image/jpg,image/webp";

export const IMAGES_LIMIT = 40;

export const MAX_FILE_SIZE_BYTES = 5242880;

export const DEFAULT_ASPECT_RATIO = 1;

export const FILE_UPLOAD_LIMIT = 10;

export const MAX_COVER_IMAGES_LIMIT = 9;

export const ERROR_TYPES = {
    INVALID_FILE_FORMAT: "INVALID_FILE_FORMAT",
    FILE_SIZE_EXCEEDED: "FILE_SIZE_EXCEEDED",
    FILE_UPLOAD_FAILED: "FILE_UPLOAD_FAILED",
    FILE_UPLOAD_LIMIT_EXCEEDED: "FILE_UPLOAD_LIMIT_EXCEEDED",
    FILE_READ: "FILE_READ",
    IMAGE_ADD_LIMIT_EXCEEDED: "IMAGE_ADD_LIMIT_EXCEEDED",
    IMAGE_ALREADY_ADDED: "IMAGE_ALREADY_ADDED",
};

export const FILE_UPLOAD_STATUS = {
    NOT_UPLOADED: "not_uploaded",
    UPLOADING: "uploading",
    UPLOADED: "uploaded",
    FAILED: "failed",
};


export const IMAGE_ASPECT_RATIOS_TO_LABEL = {
    [IMAGE_ASPECT_RATIOS.RATIO_1]: "1:1",
    [IMAGE_ASPECT_RATIOS.RATIO_3]: "3:1",
};

export const IMAGE_SORTING_ACTIONS = {
    NEWEST_UPLOADED: "DATE_DESC",
    OLDEST_UPLOADED: "DATE_ASC",
    "0-9, A-Z": "NAME_ASC",
    "9-0, Z-A": "NAME_DESC",
};

export const IMAGE_UPLOAD_ACTIONS = {
    UPLOAD_IMAGE: "UPLOAD_IMAGE",
    BULK_UPLOAD: "BULK_UPLOAD",
    UPLOAD_FROM_URL: "UPLOAD_FROM_URL",
};

export const DELETE_IMAGE_STRATEGY_ACTIONS = {
    DELETE_IMAGE_AND_UNLINK_ENTITIES: "remove_and_unlink",
    CHOOSE_NEW_IMAGE_AND_DELETE_OLD_ONE: "transfer_and_remove_and_unlink",
};

export const IMAGE_ACTION_MODES = {
    VIEW: "VIEW",
    COPY: "COPY",
    EDIT: "EDIT",
    DELETE_WITH_NO_LINKED_ENTITIES: "DELETE_WITH_NO_LINKED_ENTITIES",
    DELETE_WITH_LINKED_ENTITIES: "DELETE_WITH_LINKED_ENTITIES",
};

export const DEFAULT_IMAGES_SEARCH_AND_FILTERS = {
    searchValue: "",
    filters: {
        ratio: null,
        sort: IMAGE_SORTING_ACTIONS.NEWEST_UPLOADED,
    },
};

export const IMAGE_SORTING_SESSION_KEY = "imageSortFilter";

export const DEFAULT_HIDDEN_MODALS = {
    imageEditModal: false,
    imageDeleteModalWithNoLinkedEntities: false,
    imageViewModal: false,
    gmsModal: false,
};


export const IMAGES_ASPECT_RATIO_OPTIONS = [
    {
        i18nKey: "Gallery.All",
        value: null
    }, 
    {
        i18nKey: "Gallery.1",
        value: IMAGE_ASPECT_RATIOS.RATIO_1,
    },
    {
        i18nKey: "Gallery.3",
        value: IMAGE_ASPECT_RATIOS.RATIO_3,
    },
];

export const IMAGE_SORTING_OPTIONS = [
    {
        value: IMAGE_SORTING_ACTIONS.NEWEST_UPLOADED,
        i18nKey: "Gallery.Newest Uploaded",
    },
    {
        value: IMAGE_SORTING_ACTIONS.OLDEST_UPLOADED,
        i18nKey: "Gallery.Oldest Uploaded",
    },
    {
        value: IMAGE_SORTING_ACTIONS["0-9, A-Z"],
        i18nKey: "Gallery.Name_0-9_A-Z",
    },
    {
        value: IMAGE_SORTING_ACTIONS["9-0, Z-A"],
        i18nKey: "Gallery.Name_9-0_Z-A",
    },
];

export const IMAGE_UPLOAD_ACTIONS_OPTIONS = [
    {
        value: IMAGE_UPLOAD_ACTIONS.UPLOAD_IMAGE,
        i18nKey: "Gallery.Upload_Image",
    },
    {
        value: IMAGE_UPLOAD_ACTIONS.BULK_UPLOAD,
        i18nKey: "Gallery.Bulk_Upload",
    },
    {
        value: IMAGE_UPLOAD_ACTIONS.UPLOAD_FROM_URL,
        i18nKey: "Gallery.Web_Address",
    },
];
