import React, { useContext, useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { Trans, useTranslation } from "react-i18next";
import { Header, Input, Image } from "semantic-ui-react";
import { get as getDeepValue, isEmpty } from "lodash";
import { useSetState } from "ahooks";
import { ASPECT_RATIOS, BRANDING_MAP_CONSTANTS } from "../../../_constants/constants";
import { ButtonIcon } from "../../common/ButtonIcon";
import { CheckboxGroup } from "../../common/CheckboxGroup";
import { TextGroup } from "../../common/TextGroup";
import { SettingsHeading } from "../SettingsHeading";
import { SettingsContext } from "../SettingsProvider";
import { filerUrl } from "../../../_utils/utils";
import { DEFAULT_LANGUAGE_CODE } from "mapsted.maps/utils/map.constants";
import serverApi from "../../../_api/server.api";
import BrandingContext from "../../../store/BrandingContext";
import { DividerLine } from "../../common/DividerLine";
import { DropdownForm } from "../../common/DropdownForm";
import { PUBLIC_QUERIES } from "../../../_utils/queries";
import Orientation from "../../pages/settings/Orientation";
import ZoomAndFloorSelector from "../../pages/settings/ZoomAndFloorSelector";
import BuildingSettings from "../../pages/settings/BuildingSettings";
import ImageSelectionModal from "../../branding/ImageSelectionModal/ImageSelectionModal";
import ImagePicker from "../../branding/ImagePicker/ImagePicker";
import FocusPoint from "../../pages/settings/FocusPoint";
import MaxZoomConfig from "../../pages/settings/MaxZoomConfig";
import { Gms } from "../../branding/Gms/Gms";

const DUMMY_HEADING = "Main Heading";
const DUMMY_SUBHEADING = "Sub Heading";
const DUMMY_TEXT = "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam";
const LOGO_ACTION = { UPDATE: "update", DELETE: "delete" };

const DYNAMIC_LOGO_PROPS = {
    iconImage: {
        heading: <Trans i18nKey={"LogoTypePopup.Add_Logo"} />,
        aspectRatio: ASPECT_RATIOS.ICON_IMAGE,
        description: <Trans i18nKey={"LogoTypePopup.Add_or_update_your_store_logo__Your_logo"} />
    },
    darkIcon: {
        heading: <Trans i18nKey={"LogoTypePopup.Add_Logo_for_Dark_Mode"} />,
        aspectRatio: ASPECT_RATIOS.LOGO,
        description: <Trans i18nKey={"LogoTypePopup.Add_or_update_your_store_logo_to_make_su"} />
    },
    lightIcon: {
        heading: <Trans i18nKey={"LogoTypePopup.Add_Logo_for_Light_Mode"} />,
        aspectRatio: ASPECT_RATIOS.LOGO,
        description: <Trans i18nKey={"LogoTypePopup.Add_or_update_your_store_logo_to_make_su"} />
    }
};

const ConfigurationOptions = ({ isOpen, onChangeZoomMode, onChangeValue, renderErrors, setIsBuilding, setZoomData, setRotationData, setShowModeSwitcher, setShowMaxZoomLevelAdjuster }) =>
{
    const trans = useTranslation().t;
    const { getBuildingName, state: { buildingId, propertyId, properties } } = useContext(BrandingContext);
    const { mergedSettings: settings, mutate } = useContext(SettingsContext);
    const gmsRef = useRef(null);
    const [state, setState] = useSetState({
        isUploadModalOpen: false,
        isOrientModalOpen: false,
        themes: [],
    }); 
    const { isLoading, data: mapstedApps } = useQuery(PUBLIC_QUERIES.MAPSTED_APPS(true));

    const building = getDeepValue(properties, `${propertyId}.buildings.${buildingId}`, {});
    const { mobileAppSync = {}, validationErrors = [] } = settings;
    const isBuilding = !isEmpty(building) ;

    useEffect(() =>
    {
        if (propertyId)
        {
            getPropertyThemes(propertyId);
        }
    }, [propertyId]);

    const getPropertyThemes = async (propertyId) =>
    {
        const res = await serverApi.getThemes(propertyId, true);
        
        if (res.success)
        {
            setState({
                themes: res.themes
            });
        }
    };

    const getAppList = () =>
    {
        if (isLoading) return [];

        const list = Object.entries(mapstedApps).map(([key, value]) => ({ key, value: key, text: value.name }));

        return [...list, { key: "CUSTOM", value: "CUSTOM", text: "CUSTOM" }];
    };

    const handleChangeLogo = async (action, filerId) =>
    {
        const path = `mobileAppSync.mobile.downloadApp.popup.logo.${DEFAULT_LANGUAGE_CODE}`;
        onChangeValue(path, filerId);
    };     

    const renderPrimaryMapTheme = () =>
    {
        const { themes } = state;

        const themesOptions = themes
            .filter((theme) => theme.active)
            .map((theme) => ({
                key: theme._id,
                value: theme._id,
                text: theme.name[DEFAULT_LANGUAGE_CODE],
            }));

        return (
            <div className="settingsPageTabCover">
                <div className="settingsPageHeading">
                    <Header as="h2" content={trans("Settings.Map_Theme")} />
                    <p className="paragraph">{trans("Settings.Map_Theme_desc")}</p>
                </div>
                <DropdownForm 
                    className="dropdownSelectTheme" 
                    placeholder="Select theme"
                    options={themesOptions}
                    value={settings.primaryTheme}
                    onChange={(e, { value }) => onChangeValue("primaryTheme", value)}
                />
            </div>
        );
    };

    const renderSearchView = () =>
    {
        const path = "isSearchExpanded";
        const { isSearchExpanded } = settings;

        return (
            <div className="settingsPageTabCover">
                <SettingsHeading heading={trans("Settings.Default_Search_View_(Web)")}>
                    {trans("MapstedMapsModule.Choose_between_an_expanded_or_collapsed_")}
                </SettingsHeading>
                <div className="settingsPageHalfCover">
                    <CheckboxGroup
                        radio
                        label={trans("MapstedMapsModule.Expand")}
                        checked={isSearchExpanded}
                        onChange={() => onChangeValue(path, true)}
                    />
                    <CheckboxGroup
                        radio
                        label={trans("MapstedMapsModule.Collapse")}
                        checked={!isSearchExpanded}
                        onChange={() => onChangeValue(path, false)}
                    />
                </div>
                <div className="placeholderSearchMap">
                    <TextGroup
                        title={trans("MapstedMapsModule.Expand")}
                        content={<img src="/img/placeholder-expand.svg" alt="expand" />}
                    />
                    <TextGroup
                        title={trans("MapstedMapsModule.Collapse")}
                        content={<img src="/img/placeholder-collapse.svg" alt="expand" />}
                    />
                </div>
            </div>
        );
    };

    const renderSyncMobileHeader = () => (
        <>
            <div className="settingsPageHeading">
                <Header as="h2" content={trans("Settings.Sync_Mobile_App")} />
            </div>

            <SettingsHeading heading={trans("Settings.Configure_the_dwnld_popup")}>
                {
                    trans("Settings.Config_Download_Popup_desc")
                }
            </SettingsHeading>

            <DividerLine isEmpty gap="0 0 10px" />

            <CheckboxGroup
                label={trans("Settings.Sync_Mobile_App")}
                checked={mobileAppSync.active}
                name="active"
                onChange={(e, { checked }) => onChangeValue("mobileAppSync.active", checked)}
            />
        </>
    );

    const renderConfigureDownloadPopup = () =>
    {
        const path = "mobileAppSync.web.downloadApp.popup";

        const { 
            heading = "", 
            subHeading = "", 
            mobileApp = "", 
            playStoreLink = "", 
            appStoreLink = "",
            deepLinkId = "",
        } = getDeepValue(settings, path, {});

        return (
            <div className="settingsPageContentRow">
                {/* Form */}
                <div className="settingsPageContentBody">
                    <TextGroup heading={trans("Settings.Main_Heading")}>
                        <Input
                            placeholder={trans("Settings.Ex_Download_Mapsted_Map")}
                            value={heading}
                            onChange={(e, { value }) => onChangeValue(`${path}.heading`, value)}
                        />
                    </TextGroup>
                    <TextGroup heading={trans("Settings.Sub_Heading")}>
                        <Input
                            placeholder={trans("Settings.Ex_Point_Your_Camera")}
                            value={subHeading}
                            onChange={(e, { value }) => onChangeValue(`${path}.subHeading`, value)}
                        />
                    </TextGroup>
                    <TextGroup heading={trans("Settings.Mapsted_Hosted_Applications")}>
                        <DropdownForm
                            isLoading={isLoading}
                            placeholder="Select type"
                            options={getAppList()}
                            value={mobileApp}
                            onChange={(e, { value }) => onChangeValue(`${path}.mobileApp`, value)} />
                    </TextGroup>
                    {mobileApp === "CUSTOM" && (
                        <>
                            <TextGroup heading={trans("Settings.Deep_Link_Id")}>
                                <Input
                                    placeholder={trans("Settings.Deep_Link_Id")}
                                    value={deepLinkId}
                                    onChange={(e, { value }) => onChangeValue(`${path}.deepLinkId`, value)}
                                />
                            </TextGroup>
                            <TextGroup heading={trans("Settings.Google_PlayStore_Link")}>
                                <Input
                                    placeholder={trans("Settings.Input_URL")}
                                    value={playStoreLink}
                                    onChange={(e, { value }) => onChangeValue(`${path}.playStoreLink`, value)}
                                />
                            </TextGroup>
                            <TextGroup heading={trans("Settings.Apple_App_Store_Link")}>
                                <Input
                                    placeholder={trans("Settings.Input_URL")}
                                    value={appStoreLink}
                                    onChange={(e, { value }) => onChangeValue(`${path}.appStoreLink`, value)}
                                />
                            </TextGroup>
                        </>
                    )}
                </div>
                {/* Preview */}
                <div className="settingsPreviewBox">
                    <Header>
                        {heading || DUMMY_HEADING}
                    </Header>
                    <p>
                        {subHeading || `${DUMMY_SUBHEADING} - ${DUMMY_TEXT}`}
                    </p>
                    <div className="previewLogoImg">
                        <img src="/img/qrcode.svg" alt="" />
                    </div>
                    <p>{trans("Settings.Text_yourself_link")}</p>

                    <div className="previewPlaceholderBox">
                        <div className="previewButtonGrey"></div>
                        <div className="placeholderExample"></div>
                        <div className="previewButtonOrange"></div>
                    </div>

                    <p>
                        {DUMMY_TEXT}
                    </p>
                    <div className="previewBoxActions">
                        <div className="previewBoxActionButton">
                            <img src="/img/icon-preview-playstore.svg" alt="" />
                            <span></span>
                        </div>
                        <div className="previewBoxActionButton">
                            <img src="/img/icon-preview-apple.svg" alt="" />
                            <span></span>
                        </div>
                    </div>

                </div>
            </div>
        );
    };

    const renderDownloadPopupTrigger = () =>
    {
        const path = "mobileAppSync.web.downloadApp.trigger.text";

        return (
            <div className="settingsPageContentRow">
                {/* Form */}
                <div className="settingsPageContentBody">
                    <TextGroup heading={trans("Settings.Download_Button_Text")}>
                        <Input
                            placeholder={trans("Settings.Ex_Mapsted_Maps_For_Mobile")}
                            value={getDeepValue(settings, path, "")}
                            onChange={(e, { value }) => onChangeValue(path, value)}
                        />
                    </TextGroup>
                </div>
                {/* Preview */}
                <div className="settingsPreviewBox">
                    <div className="previewPlaceholderBox previewCustomizeBox">
                        <img src="/img/icon-preview-mobile.svg" alt="" />
                        <div className="placeholderExample"></div>
                        <span className="crossPreviewIcon"></span>
                    </div>
                </div>
            </div>
        );
    };

    const renderCustomDownloadPopup = () =>
    {
        const path = "mobileAppSync.mobile.downloadApp.popup";

        const { mainHeading = "", subHeading = "", listItems = [], logo = {} } = getDeepValue(settings, path, {});
        const isListEmpty = !listItems.join("").trim();

        return (
            <>
                <SettingsHeading heading={trans("Settings.Custom_dwnld_popup")}>
                    {
                        trans("Settings.Custom_dwnld_popup_desc")
                    }                
                </SettingsHeading>

                <DividerLine isEmpty gap="0 0 10px" />
                <div className="settingsPageContentRow">
                    {/* Form */}
                    <div className="settingsPageContentBody">
                        <TextGroup heading={trans("Settings.App_Logo")}>
                            {isEmpty(logo?.[DEFAULT_LANGUAGE_CODE]) ? trans("Settings.Logo") : (
                                <Image className="app-logo-img" src={filerUrl(logo?.[DEFAULT_LANGUAGE_CODE])} />
                            )}
                            <ButtonIcon className="uploadLogoSettings" content={trans("Settings.UploadLogo")} onClick={() => 
                            {
                                const addedImageFilerIds = [];
                                const logo = settings?.mobileAppSync?.mobile?.downloadApp?.popup?.logo?.[DEFAULT_LANGUAGE_CODE];
                                if (logo)
                                {
                                    addedImageFilerIds.push(logo);
                                }
                                gmsRef.current.linkIconImage({ addedImageFilerIds });
                            }} />
                        </TextGroup>
                        <TextGroup heading={trans("Settings.Main_Heading")}>
                            <Input
                                placeholder={trans("Settings.Ex_Download_Mapsted_Map")}
                                value={mainHeading}
                                onChange={(e, { value }) => onChangeValue(`${path}.mainHeading`, value)}
                            />
                        </TextGroup>
                        <TextGroup heading="Sub Heading">
                            <Input
                                placeholder={trans("Settings.Ex_Point_Your_Camera")}
                                value={subHeading}
                                onChange={(e, { value }) => onChangeValue(`${path}.subHeading`, value)}
                            />
                        </TextGroup>
                        <TextGroup heading={trans("Settings.List_Item_1")}>
                            <Input
                                placeholder={trans("Settings.Ex_Fast_And_Secure")}
                                value={listItems[0] || ""}
                                onChange={(e, { value }) => onChangeValue(`${path}.listItems[0]`, value)}
                            />
                        </TextGroup>
                        <TextGroup heading={trans("Settings.List_Item_2")}>
                            <Input
                                placeholder={trans("Settings.Ex_Customizable")}
                                value={listItems[1] || ""}
                                onChange={(e, { value }) => onChangeValue(`${path}.listItems[1]`, value)}
                            />
                        </TextGroup>
                        {/* Removed for now */}
                        {/* <ButtonIcon className="addItemSettings" icon="plus-filled" content="Add item" /> */}
                    </div>
                    {/* Preview */}
                    <div className="settingsPreviewBox">
                        {/* LOGO */}
                        <div className="previewLogoImg">
                            <Gms  ref={gmsRef}
                                onAddImage={(filerId) => 
                                {
                                    handleChangeLogo(LOGO_ACTION.UPDATE, filerId);
                                }}>
                            </Gms>
                        </div>
                        <Header>
                            {mainHeading || DUMMY_HEADING}
                        </Header>
                        <p>
                            {subHeading || `${DUMMY_SUBHEADING} - ${DUMMY_TEXT}`}
                        </p>
                        <ul className="previewUl">

                            {(isListEmpty ? [DUMMY_TEXT, DUMMY_TEXT] : listItems).map((item, i) => (<li key={i}>{item}</li>))}
                        </ul>
                        <div className="previewButtonOrange"></div>
                    </div>
                </div>
            </>
        );
    };

    const renderExtLinkPopupTrigger = () =>
    {
        const path = "mobileAppSync.web.externalLink.trigger.text";

        return (
            <>

                <SettingsHeading heading={trans("Settings.External_Share_Link_Popup")}>
                    {trans("Settings.Fill_in_the_fields_below_to_customize_your_pop_up")}
                </SettingsHeading>

                <DividerLine isEmpty gap="0 0 10px" />
                <div className="settingsPageContentRow">
                    <div className="settingsPageContentBody">
                        <TextGroup heading={trans("Settings.Download_Button_Text")}>
                            <Input
                                placeholder={trans("Settings.Ex_Download_Mapsted_Map")}
                                value={getDeepValue(settings, path, "")}
                                onChange={(e, { value }) => onChangeValue(path, value)}
                            />
                        </TextGroup>
                    </div>
                    <div className="settingsPreviewBox settingsCustomizePreviewBox">
                        <div className="previewPlaceholderBoxInner">
                            <div className="placeholderExample"></div>
                            <div className="placeholderExample small"></div>
                        </div>
                        <div className="previewButtonOrange"></div>
                    </div>
                </div>
            </>
        );
    };

    const renderExtLinkPopup = () =>
    {
        const path = "mobileAppSync.web.externalLink.popup.url";

        return (
            <div className="settingsPageContentRow">
                <div className="settingsPageContentBody">
                    <TextGroup heading={trans("Settings.Settings_External_Link")}>
                        <Input
                            placeholder={trans("Settings.Checkout_This_App")}
                            value={getDeepValue(settings, path, "")}
                            onChange={(e, { value }) => onChangeValue(path, value)}
                        />
                    </TextGroup>
                </div>
                <div className="settingsPreviewBox settingsCustomizePreviewBox">
                    <div className="previewPlaceholderBoxInner">
                        <div className="placeholderExample small dark"></div>
                        <div className="placeholderExample"></div>

                        <div className="previewGap"></div>
                        <div className="placeholderExample small"></div>
                        <div className="previewGapBox">
                            <div className="placeholderExample"></div>
                            <div className="placeholderExample dot"></div>
                        </div>

                        <div className="placeholderExample small dark"></div>
                        <div className="placeholderExample"></div>
                        <div className="previewButtonOrange"></div>
                    </div>
                    <div className="previewCustomizeRightbar">
                        <div className="previewLogoImg">
                            <img src="/img/qrcode.svg" alt="" />
                        </div>
                        <div className="placeholderExample dot"></div>
                    </div>
                </div>
            </div>
        );
    };

    const getInitialOrientation =() =>
    {   
    
        const desktopPath = `mapRotation.${DEFAULT_LANGUAGE_CODE}`;
        const mobilePath = `mapRotationMobile.${DEFAULT_LANGUAGE_CODE}`;
        
        const mapRotation = getDeepValue(settings, desktopPath, undefined); 
        const mapRotationMobile =getDeepValue(settings, mobilePath, undefined);

    
        return { mapRotation, mapRotationMobile, building, settings };

    };

    const getInitialZoomAndFloorSelector = () => 
    {
        const zoomWeb =    settings.zoomWeb;    
        const zoomMobile = settings.zoomMobile;

        const centerWeb =    settings?.centerWeb?.coordinates;
        const centerMobile = settings?.centerMobile?.coordinates;
        const  { mapRotation,mapRotationMobile }= getInitialOrientation();

       
        return { mapRotation, mapRotationMobile, zoomWeb, zoomMobile, centerWeb, centerMobile, settings, building };
    };    
    
    const getInitialPropertyFoucusPoint = () => 
    {
        const { propertyFocusPoint, propertyFocusPointMobile } = settings;                              
        const  { mapRotation,mapRotationMobile }= getInitialOrientation();

        return { focusPoint: propertyFocusPoint, mapRotation, mapRotationMobile, focusPointMobile: propertyFocusPointMobile, building };
    };

    const getBuildingSettings = () => 
    {
        if (!isBuilding) return null;
        const buildingId = building?._id;
        const buildingSettings = settings?.buildingSettings?.[buildingId];
        return buildingSettings;
    };

    const handleChangeMaxZoom = (mode) => 
    {
        let centerWeb = settings?.centerWeb?.coordinates;
        let zoomWeb = settings.maxZoomWeb || BRANDING_MAP_CONSTANTS.MAX_ZOOM;
        if (isBuilding)
        {
            const buildingSettings = getBuildingSettings();
            centerWeb = buildingSettings?.mapSettings?.centerWeb?.coordinates;
            setIsBuilding(isBuilding);
        }

        const { mapRotation } = getInitialOrientation();
        setShowModeSwitcher(false);
        setShowMaxZoomLevelAdjuster(true);
        onChangeZoomMode(mode);
        setZoomData({ centerWeb, zoomWeb });
        setRotationData({ mapRotation });
    };
     
  
    if (!isOpen) return null;
    return (
        <div className="spaceCover">
            <div className="settingsPageTabPane settingsPageTabPaneModule">

                {/* building settings */}

                {isBuilding ? (

                    <BuildingSettings
                        buildingName={getBuildingName()}
                        setIsBuilding={setIsBuilding}
                        settings={settings}
                        building={building}
                        onChangeZoomMode={onChangeZoomMode}
                        setZoomData = { setZoomData }
                        setRotationData = { setRotationData }
                    />
                ) : null}  

                <ZoomAndFloorSelector
                    setIsBuilding={setIsBuilding}
                    onChangeZoomMode={onChangeZoomMode}
                    handleSave={(updateSettings) => mutate(updateSettings) }
                    setZoomData = { setZoomData }
                    setRotationData = { setRotationData }

                    {...getInitialZoomAndFloorSelector()}
                />

                <Orientation
                    handleSave={(updateSettings) => mutate(updateSettings) }
                    {...getInitialOrientation()}
                />    

                <FocusPoint
                    handleSave={(updateSettings) => mutate(updateSettings) }
                    {...getInitialPropertyFoucusPoint()}        
                />   

                <MaxZoomConfig
                    onChangeMaxZoom={handleChangeMaxZoom}
                    maxZoomLevel={settings.maxZoomWeb || BRANDING_MAP_CONSTANTS.MAX_ZOOM}
                />        
 
                            
                {renderPrimaryMapTheme()}     

                {/* <div className="settingsPageTabCover">
                    {renderErrors(getDeepValue(validationErrors, "publicSubdomain", []))}
                </div> */}

                <div className="settingsPageTabCover">
                    {renderSyncMobileHeader()}

                    <div className="settingsPageTabDefault">
                        {renderConfigureDownloadPopup()}

                        <DividerLine isEmpty gap="40px 0" />

                        {renderDownloadPopupTrigger()}

                        {renderErrors(getDeepValue(validationErrors, "mobileAppSync.web.downloadApp", []))}

                        <DividerLine />

                        {renderCustomDownloadPopup()}

                        {renderErrors(getDeepValue(validationErrors, "mobileAppSync.mobile.downloadApp.popup", []))}

                        <DividerLine />

                        {renderExtLinkPopupTrigger()}

                        {renderExtLinkPopup()}

                        {renderErrors(getDeepValue(validationErrors, "mobileAppSync.web.externalLink", []))}
                    </div>

                </div>

                {/* mapsted module latest starts */}
                {renderSearchView()}

            </div>
        </div>
    );
};
export default ConfigurationOptions;







