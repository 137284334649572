import React from "react";
import { Pagination, Icon } from "semantic-ui-react";

export const PaginationBar = () =>
{
    return (
        <div className="paginationBar">
            <Pagination
                className="paginationDashboard"
                boundaryRange={3}
                defaultActivePage={1}
                ellipsisItem={null}
                firstItem={null}
                lastItem={null}
                siblingRange={0}
                totalPages={5}
                prevItem={{ content: <Icon name='angle left' />, icon: true }}
                nextItem={{ content: <Icon name='angle right' />, icon: true }}
            />
            <div className="displayText">
                <span>Showing <strong>1 - 6</strong> of 44</span>
            </div>
        </div>
    );
}