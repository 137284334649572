import React, { useEffect } from "react";
import BrandingContext from "../../../store/BrandingContext";
import { Dropdown } from "semantic-ui-react";
import { MapButton } from "./MapButtons";
import { DEFAULT_LANGUAGE_CODE, NO_RESULTS_FOUND } from "../../../_constants/constants";
import { useTranslation } from "react-i18next";

export const MapSearch = ({ onSearch }) =>
{
    /** @type {typeof import("../../../_intl/resources.json")["en"]["translation"]["search placeholder"]} */
    const trans = useTranslation().t("search placeholder");
    const brandingContext = React.useContext(BrandingContext);
    const [isOpen, setIsOpen] = React.useState(false);
    const [searchOptions, setSearchOptions] = React.useState([]);
    const [isSearch, setIsSearch] = React.useState(false);


    // const value = React.useMemo(
    //     () => getSelectedEntity(brandingContext.state.selectedEntities)?.selectedEntityId || "",
    //     [brandingContext.state.selectedEntities]
    // );

    useEffect(() => 
    {
        if (!isOpen && searchOptions.length > 0)
        {
            setSearchOptions([]);
            setIsSearch(false);
        }
    }, [isOpen]);

    const dropdownOptions = React.useMemo(() =>
    {
        const { entities } = brandingContext?.state?.mapData;

        if (entities)        
        {
            return Object.values(entities)
                .filter((entity) => entity.entityLabel?.longName?.[DEFAULT_LANGUAGE_CODE])
                .map((entity) => ({
                    key: entity._id,
                    value: entity._id,
                    text: entity.entityLabel.longName[DEFAULT_LANGUAGE_CODE],
                    description: entity.entityLabel?.shortName?.[DEFAULT_LANGUAGE_CODE] || "",
                    feature: entity.feature,
                    entityId: entity.entityId,
                    buildingId: entity.buildingId
                }));
        }
        else
        {
            return [];
        }

    }, [brandingContext.state.mapData]);

    const toggleSearch = () => setIsOpen((current) => !current);

    const handleChange = React.useCallback((e, { value, options }) =>
    {
        let feature = options.find((option) => option.value === value).feature;

        onSearch(value, feature);
        setIsOpen(false);

    }, [onSearch]);

    const handleSearchChange = React.useCallback((e, { searchQuery }) =>
    {
        if (searchQuery === "")
        {
            setSearchOptions([]);
            setIsSearch(false);

        }
        else
        {
            searchQuery = searchQuery.toLowerCase();

            const searchOptions = dropdownOptions
                .filter((option) => (
                    option.text.toLowerCase().includes(searchQuery) 
                    || option.description.toLowerCase().includes(searchQuery)
                    || option.entityId === +searchQuery
                    || (option.buildingId && option.buidlingId === +searchQuery)
                ));

            searchOptions.sort((a, b) =>
            {
                let aDist = a.text.toLowerCase().indexOf(searchQuery);
                let bDist = b.text.toLowerCase().indexOf(searchQuery);

                if (aDist === -1)
                {
                    aDist = a.description.toLowerCase().indexOf(searchQuery);
                }

                if (bDist === -1)
                {
                    bDist = b.description.toLowerCase().indexOf(searchQuery);
                }

                return aDist - bDist;
            });

            setSearchOptions(searchOptions);
            setIsSearch(true);

        }

    }, [dropdownOptions]);

    const handleSearchOverwrite = () => searchOptions;

    return (
        <>
            <MapButton icon="search-default" onClick={toggleSearch} /> {/* this is trigger */}

            {
                (isOpen) && (
                    <Dropdown className="searchDropdown"
                        search={handleSearchOverwrite}
                        placeholder={trans}
                        searchInput={{ autoFocus: true }}
                        selectOnBlur={false}
                        onBlur={toggleSearch}
                        // value={value}
                        options={searchOptions}
                        onChange={handleChange}
                        onSearchChange={handleSearchChange}
                        openOnFocus={false}
                        noResultsMessage={isSearch ? NO_RESULTS_FOUND : null }
                        selectOnNavigation={false}
                    />
                )
            }

        </>
    );
};
