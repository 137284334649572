import brandingApi from "../_api/branding.api";
import serverApi from "../_api/server.api";

export const UTIL_QUERIES = {
    CONFIG: () => ({
        queryKey: "CONFIG",
        queryFn: () => serverApi.fetchConfig(),
    })
};

export const DATA_QUERIES = {
    GET_PROPERTIES: () => ({
        queryKey: "GET_PROPERTIES",
        queryFn: () => serverApi.getProperties({ buildings: true, poiEntities: true, syncUpdateDate: true })
    }),
    GET_PROPERTY_SETTINGS: (propertyId) => ({
        enabled: !!propertyId,
        queryKey: ["get_property_settings", propertyId],
        queryFn: () => serverApi.fetchPropertySettings(propertyId),
    }),
    GET_ACCESS_GROUPS: (companyUID) => ({
        queryKey: ["GET_ACCESS_GROUPS", companyUID],
        queryFn: () => brandingApi.accessGroupsGet(companyUID)
    })
};

export const PUBLIC_QUERIES = {
    PROPERTY_SETTINGS: (propertyMongoId) => ({
        queryKey: ["public_property_settings", propertyMongoId],
        enabled: !!propertyMongoId,
        queryFn: () => serverApi.fetchPropertySettings(propertyMongoId),
    }),
    MAPSTED_APPS: (publicOnly = true) => ({
        queryKey: "MAPSTED_APPS",
        queryFn: async () =>
        {
            const apps = await serverApi.fetchMapstedAppList();

            if (!publicOnly) return apps;

            const entries = Object.entries(apps).filter(([key, value]) => value.public);

            return Object.fromEntries(entries);
        },
    }),
    PUBLISHED_PROPERTY_SETTINGS: (propertyId, data) => ({
        queryKey: ["published_property_settings", propertyId, data],
        queryFn: () => serverApi.getPublicSettings(propertyId, data),
    }),
    PUBLISHED_KIOSKS: (propertyId, buildingId, data) => ({
        queryKey: ["published_kiosks", propertyId, buildingId, data],
        queryFn: async () => 
        {
            let kiosks = [];
            const resp = await serverApi.getPublishedKiosks(propertyId, buildingId, data);

            if (resp.success && resp.kiosks)
            {
                kiosks = resp.kiosks;
            }

            return kiosks;
        }
    }),
    GLOBAL_SETTINGS: () => ({
        queryKey: "GLOBAL_SETTINGS",
        queryFn: async () => serverApi.getGlobalSettings()
    }),
};

export const GENERIC_QUERIES = {
    COUNTRIES: () => ({
        queryKey: ["COUNTRIES"],
        queryFn: () => serverApi.get("/api/internal/countries"),
        initialData: []
    })
};

export const INTEGRATION_QUERIES = {
    ARCHIBUS_TOKEN: (companyUID) => ({
        queryKey: companyUID,
        queryFn: () => serverApi.getQuery(`/api/internal/integrations/archibusToken?token=${serverApi.archibusToken ? JSON.stringify(serverApi.archibusToken) : "{}"}`, true),
        onSuccess: (data) =>
        {
            if (data.newToken !== undefined)
            {
                serverApi.archibusToken = data.newToken;
            }
        }
    }),
    IS_COMPANY_ADMIN: (userId) => ({
        queryKey: userId,
        queryFn: () => serverApi.getQuery("/api/internal/integrations/isCompanyAdmin", true)
    }),
    // fetches archibus buildings. Our server performs the actual fetch because of cors.
    BUILDINGS: (companyUID, instanceURL, refetchToken) => ({
        queryKey: companyUID,
        queryFn: () => serverApi.post(`/api/internal/integrations/archibusBuildings?instanceURL=${instanceURL}`, serverApi.archibusToken, true),
        onError: (err) => console.log(err),
        onSuccess: (data) =>
        {
            // (data.code === 26) && refetchToken()
            console.log("archibusBuildings", data);
        }
    })
};

export const INTEGRATION_MUTATIONS = {
    CREATE_OR_UPDATE: (refetch) => ({
        mutationFn: (obj) => serverApi.post("/api/internal/integrations/archibusSettings", obj, true),
        onSuccess: () => refetch()
    })
};
